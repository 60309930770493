import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CampaignEventListResponse, CampaignTemplate, CampaignTemplateDataResponse } from '../../../models/campaign.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eCampaignEventType, eCampaignTemplateType, eFeatures, eUserRole } from '../../../models/enum.model';
import { IntDropDownItem, SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { CampaignService } from '../../../services/campaign.service';
import { GlobalService } from '../../../services/global.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
    selector: 'automation-process',
    templateUrl: './automation-process.component.html',
    styleUrls: ['./automation-process.component.css']
})
/** automation-process component*/
export class AutomationProcessComponent {
    isEnableReording: boolean = false;
    showSpinner: boolean = false;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    user: CLPUser;
    isViewMode: boolean = false;
    isMakeCopy: boolean = false;
    isShowDeleteModal: number = 0;
    campaignTemplateId: number = 0;
    campaignEventId: number = 0;
    campaignTemplateData: CampaignTemplate = <CampaignTemplate>{};
    ddDelayCycle: IntDropDownItem[] = [];
    private encryptedUser: string = '';
    isShowCampaignEvent: boolean = false;
    isShowCampaignEventList: boolean = true;
    campaignTemplateForm: FormGroup;
    newTemplateSaved: boolean;
    /** automation-process ctor */
    constructor(private route: ActivatedRoute, private fb: FormBuilder,
        private _notifyService: NotificationService,
        private _campaignService: CampaignService,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _router: Router,
        public _globalService: GlobalService

    ) {
        this._localService.isMenu = true;

        this.route.paramMap.subscribe(async params => {
            if (params.has('campaignTemplateId')) {
                this.campaignTemplateId = +params.get('campaignTemplateId');
            }
        })
    }


    ngOnInit(): void {
        this.campaignTemplateForm = this.prepareCampaignTemplateForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (!isNullOrUndefined(this.user)) {
                        if (this.campaignTemplateId > 0) {
                            this.newTemplateSaved = false
                            this.getCampaignEventList();
                            this.loadCampaignTemplateData();
                            this.isViewMode = true;
                        }
                        this.ddDelayCycle = this._localService.GetCampaignEventTimeSinceList(eCampaignEventType.Appointment);
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    prepareCampaignTemplateForm() {
        return this.fb.group({
            campaignTemplateName: new FormControl('', [Validators.required]),
            ownerType: new FormControl(2, [Validators.required]),
            immunityFlag: new FormControl(false),
            shareable: new FormControl(true),
            isViewInCM: new FormControl(true),
            cycleEvents: new FormControl(false),
            delayCycleValue: new FormControl(0, [Validators.required]),
            delayCycleUnit: new FormControl(8)
        });
    }


    patchCampaignTemplateFormValue() {
        const campaignTemp = this.campaignTemplateData;
        for (let key in campaignTemp) {
            let value = campaignTemp[key];
            if (this.campaignTemplateForm.get(key))
                this.campaignTemplateForm.get(key).setValue(value);
        }
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.AutomationProcesses)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("automation-process.authenticateR", err.message, null, 'Features ' + eFeatures.AutomationProcesses);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }


    copyCampaignTempFormValue(campaignTemplateId: number) {
        this.campaignTemplateData = <CampaignTemplate>{};
        this.campaignTemplateData.campaignTemplateID = campaignTemplateId;
        this.campaignTemplateData.cLPUserID = this.user?.cLPUserID;
        this.campaignTemplateData.cLPCompanyID = this.user?.cLPCompanyID;
        this.campaignTemplateData.campaignTemplateType = eCampaignTemplateType.Workflow;
        this.campaignTemplateData.campaignTemplateName = this.campaignTemplateForm.controls.campaignTemplateName.value;
        this.campaignTemplateData.shareable = this.campaignTemplateForm.controls.shareable.value;
        this.campaignTemplateData.cycleEvents = this.campaignTemplateForm.controls.cycleEvents.value;
        this.campaignTemplateData.delayCycleValue = Math.round(this.campaignTemplateForm.controls.delayCycleValue.value);
        this.campaignTemplateData.delayCycleUnit = this.campaignTemplateForm.controls.delayCycleUnit.value;
        this.campaignTemplateData.ownerType = this.campaignTemplateForm.controls.ownerType.value;
        this.campaignTemplateData.immunityFlag = this.campaignTemplateForm.controls.immunityFlag.value;
        this.campaignTemplateData.isViewInCM = this.campaignTemplateForm.controls.isViewInCM.value;
    }

    async saveCampaignTemplate(campaignTemplateId: number) {
        this.showSpinner = true;
        this.copyCampaignTempFormValue(campaignTemplateId);
        await this._campaignService.saveCampaignTemplate(this.encryptedUser, this.campaignTemplateData, this.user?.cLPUserID, this.user?.cLPCompanyID, this.isMakeCopy, this.campaignTemplateId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        this._notifyService.showSuccess(response.messageString ? response.messageString : "Template Saved Successfully.", "", 3000);
                        if (campaignTemplateId == 0) {
                            this.isShowCampaignEventList = true
                            this.newTemplateSaved = true
                            this.campaignTemplateId = response?.messageInt;
                            await this.loadCampaignTemplateData();
                            this.isViewMode = true;
                            this.isMakeCopy = false;
                        } else {
                            await this.loadCampaignTemplateData();
                            this.isViewMode = true;
                            this.isMakeCopy = false;
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("automation-process.authenticateR", err.message, this.campaignTemplateData);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async loadCampaignTemplateData() {
        await this._campaignService.loadCampaignTemplateByTempId(this.encryptedUser, this.campaignTemplateId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: CampaignTemplateDataResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this.campaignTemplateData = response?.campaignTemplate;
                    this.patchCampaignTemplateFormValue();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("automation-process.loadCampaignTemplateData", err.message, null, 'campaignTemplateId ' + this.campaignTemplateId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    campaignTemplateFormSubmit(campaignTemplateId: number) {
        if (this.campaignTemplateForm.valid) {
            this.saveCampaignTemplate(campaignTemplateId);
        } else {
            this.campaignTemplateForm.markAllAsTouched();
        }
    }

    onCancel() {
        this.isMakeCopy = false;
        this.campaignTemplateForm.reset();
        this.campaignTemplateForm = this.prepareCampaignTemplateForm();
        if (this.campaignTemplateId > 0) {
            this.isViewMode = true;
        }
    }

    getUnitDisplay(value: number) {
        return this.ddDelayCycle.find(x => x.id == value)?.text;
    }

    createNewEvent() {
        this.campaignEventId = 0;
        this.isShowCampaignEvent = false;
        setTimeout(() => this.isShowCampaignEvent = true, 0);
    }

    refereshEvents() {
        this.isShowCampaignEventList = false;
        this.isShowCampaignEvent = false;
        setTimeout(() => this.isShowCampaignEventList = true, 0);
    }

    onCancelEvent() {
        this.isShowCampaignEvent = false;
    }

    getCampaignEventId(id: number) {
        this.campaignEventId = id;
        this.isShowCampaignEvent = false;
        setTimeout(() => this.isShowCampaignEvent = true, 0);
    }

    async editCampaignTemp() {
        this.isShowDeleteModal = 0;
        await this.loadCampaignTemplateData();
        this.isViewMode = false;
    }

    async deleteCampaignTemplate() {
        await this._campaignService.deleteCampaignTemplate(this.encryptedUser, this.campaignTemplateId, this.user?.cLPCompanyID, this.user?.cLPUserID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result.messageBool) {
                        this._notifyService.showSuccess(result.messageString, '', 3000);
                        this._router.navigate(['/automation-process']);
                    } else {
                        this._notifyService.showError(result.errorMsg, '', 3000);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("automation-process.authenticateR", err.message, null, 'campaignTemplateId ' + this.campaignTemplateId + 'cLPCompanyID' + this.user?.cLPCompanyID + 'cLPUserID' + this.user?.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });

    }

    enableReording() {
        this.isEnableReording = !this.isEnableReording;
        this.refereshEvents();
    }

    getCampaignEventList() {
        this._campaignService.getCampaignEventList(this.encryptedUser, this.campaignTemplateId, eCampaignTemplateType.Unknown, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then((result: CampaignEventListResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        if (response?.campaignEvents?.length == 0)
                            this.isShowCampaignEventList = false;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("automation-process.getCampaignEventList", err.message, null, 'campaignTemplateId ' + this.campaignTemplateId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    onDelete() {
        if (!isNullOrUndefined(this.campaignTemplateData?.count) && +this.campaignTemplateData?.count > 0) {
            this.isShowDeleteModal = 1;
            this.onCancel();
        }
        else
            this.isShowDeleteModal = 2;
    }

    makeCopy() {
        this.isMakeCopy = true;
        this.isViewMode = true;
    }

    btnShowHide(value: string) {
        switch (value) {
            case "reordering": case "createNewEvent":
                if (this.campaignTemplateId > 0 && this.isViewMode && !this.isMakeCopy && this.campaignTemplateData?.cLPUserID == this.user?.cLPUserID) {
                    return true;
                } else {
                    return false;
                }
            case "delete":
                if (this.campaignTemplateId > 0 && !this.isViewMode && !this.isMakeCopy && this.campaignTemplateData?.cLPUserID == this.user?.cLPUserID) {
                    return true;
                } else {
                    return false;
                }
            case 'save': case 'cancel':
                if ((!this.isViewMode || this.isMakeCopy) && (this.campaignTemplateId == 0 || (this.isMakeCopy ? true : this.campaignTemplateData?.cLPUserID == this.user?.cLPUserID))) {
                    return true;
                } else {
                    return false;
                }
            case 'edit':
                if ((this.campaignTemplateId > 0 && !this.isMakeCopy) && this.campaignTemplateData?.cLPUserID == this.user?.cLPUserID) {
                    return true;
                } else {
                    return false;
                }
            case 'saveAs':
                if (this.campaignTemplateId > 0 && !this.isMakeCopy) {
                    return true;
                } else {
                    return false;
                }
        }
    }

    newAutomationProcess() {
        this._router.navigate(['/campaign-template'])
        this.campaignTemplateForm = this.prepareCampaignTemplateForm();
        this.isViewMode = false;
        this.campaignTemplateId = 0;
    }
}
