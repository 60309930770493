import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ClubUserPreferencesResponse, CMUserPref } from '../models/cm-user-pref.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class CmUserPrefService {

    private baseUrl: string;
    private api: string = "api/CMUserPref";


    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async clubUserPreferences_Load(encryptedUser: string, clpcompanyId: number, cLPUserID: number): Promise<ClubUserPreferencesResponse | void> {
        const a = await this.httpClient.get<ClubUserPreferencesResponse>(`${this.baseUrl}/ClubUserPreferences_Load/${clpcompanyId}/${cLPUserID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "r - " + encryptedUser, encryptedUser, "CmUserPrefService", "clubUserPreferences_Load") });
        return a;
    }

    async cMUserPref_Save(encryptedUser: string, userPref: CMUserPref): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/CMUserPref_Save`, userPref, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, userPref, "r - " + encryptedUser, encryptedUser, "CmUserPrefService", "clubUserPreferences_Load") });
        return a;
    }

}
