import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CLPUser, CLPUserProfile, UserListResponse, UserResponse, UserSetupResponse, IPLog,  UserDefaultLoadResponse, ContactSectionOrderSaveRequest, ProcessActiveHoursSaveRequest, DDFieldsResponse, CheckListLoadResponse, AllowedUserListResponse } from '../models/clpuser.model';
import { SimpleResponse, UserDD } from '../models/genericResponse.model';
import { AuditLog } from '../models/auditlog.model';
import { UtilityService } from './shared/utility.service';
import { catchError } from 'rxjs/operators';
import { delayedRetryHttp } from './shared/delayedRetry';
import { eDDField, eUserPermissions } from '../models/enum.model';
import { CLPUserPref, CLPUserPrefDDsList } from '../models/clpUserPref.model';
import { GenericRequest } from '../models/genericRequest.model';
import { CLPUserPrefList, CLPUserPrefResponse } from '../models/accountInformation.model';
import { HistoryListResponse } from '../models/contactHistory.model';
import { userListResponse } from '../models/report.model';
import { BlackListIpResponse, DeviceInfoFilterResponse, DeviceInfoListResponse, IPBlackList, LoginConfiguration, LoginConfigurationResposne } from '../models/device-info.model';
import { SimpleRquest } from '../models/AudioVedioRecorder/genericResponse.model';

@Injectable({
    providedIn: 'root'
})

export class UserService {
    private baseUrl: string;
    private api: string = "api/User";
    private baseUrlDDFeilds: string = "api/DDFields";
    private baseUrlGSCheckList: string = "api/GSCheckList";

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async authenticate(user: CLPUser): Promise<UserResponse | void> {

        const a = await this.httpClient
            .post<UserResponse>(`${this.baseUrl}/AuthenticateUser`, user, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, user, "UserService", "Authenticate"); });
        return a;
    }

    async resetPassword(user: CLPUser, r: string = ""): Promise<UserResponse | void> {
        const a = await this.httpClient
            .post<UserResponse>(`${this.baseUrl}/ResetPassword/`, user, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + r
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, user, "r = " + r, "UserService", "resetPassword"); });
        return a;
    }

    async forgotPassword(user: CLPUser): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/ForgotPassword/`, user, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "userName - " + user, "UserService", "forgotPassword"); });
        return a;
    }

    async validateMobile(simpleResponse: SimpleResponse): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/ValidateMobile`, simpleResponse, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "userMobile - " + simpleResponse.messageString, null, "UserService", "validateMobile"); });
        return a;
    }

    async authenticateR(r: string): Promise<UserResponse | void> {
        const a = await this.httpClient
            .get<UserResponse>(`${this.baseUrl}/AuthenticateR`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + r
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + r, "UserService", "AuthenticateR"); });
        return a;
    }

    sendCode(userId: number): Observable<SimpleResponse | void> {
        const http$ = this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/SendCode/${userId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(
                delayedRetryHttp(),
                catchError(error => this._utilityService.handleErrors(error, null, "userId - " + userId, "UserService", "sendCode")),
            );
        return http$;
    }

    setDeviceId(userId: number): Observable<SimpleResponse | void> {
        const http$ = this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/SetDeviceId/${userId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(
                delayedRetryHttp(),
                catchError(error => this._utilityService.handleErrors(error, null, "userId - " + userId, "UserService", "setDeviceId")),
            );
        return http$;
    }

    auditLog(actiontype: number, userId: number, message: string, beforeValue: string, afterValue: string) {
        let log: AuditLog = {
            actionType: actiontype,
            userId: userId,
            message: message,
            beforeValue: beforeValue,
            afterValue: afterValue
        }
        this.auditLog_Create(log);
    }

    async auditLog_Create(AuditLog): Promise<any | void> {
        const a = await this.httpClient.post<AuditLog>(`${this.baseUrl}/AuditLog/`, AuditLog, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic '
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, AuditLog, " ", "UserService", "auditLog_Create") });
    }

    async validateDevice(userId: number, deviceId: string): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/ValidateDevice/${userId}/${deviceId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "userId - " + userId + "," + "deviceId - " + deviceId, "UserService", "validateDevice") });

        return http$;
    }

    async updateRWithDeviceId(r: string, deviceId: string): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/UpdateRWithDeviceId/${r}/${deviceId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + r + "," + "deviceId - " + deviceId, "UserService", "UpdateRWithDeviceId") });

        return http$;
    }

    async clpuser_Create(clpuser: CLPUser): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/Clpuser_Create`, clpuser, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, clpuser, "", null, "UserService", "clpuser_Create") });

        return http$;
    }

    async getUsersSetup(encryptedUser: string, clpCompanyId: number, clpUserId: number): Promise<UserSetupResponse | void> {
        const http$ = await this.httpClient
            .get<UserSetupResponse>(`${this.baseUrl}/GetUsersSetup/${clpCompanyId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserId, encryptedUser, "UserService", "getUsersSetup") });

        return http$;
    }

    async sendWelcomeEmail(encryptedUser: string, clpUserId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/SendWelcomeEmail/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId, encryptedUser, "UserService", "sendWelcomeEmail") });

        return http$;
    }

    async updateUser(encryptedUser: string, clpUser: CLPUser, clpCompanyId: number, loggedUserId: number): Promise<UserSetupResponse | void> {
        const http$ = await this.httpClient
            .post<UserSetupResponse>(`${this.baseUrl}/UserSetup_Update/${clpCompanyId}/${loggedUserId}`, clpUser, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, clpUser, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "loggedUserId - " + loggedUserId, encryptedUser, "UserService", "updateUser") });

        return http$;
    }

    async createUser(encryptedUser: string, clpUser: CLPUser, clpCompanyId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/UserSetup_Create/${clpCompanyId}`, clpUser, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, clpUser, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId, encryptedUser, "UserService", "createUser") });

        return http$;
    }

    async deleteUser(encryptedUser: string, clpUserId: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<UserSetupResponse>(`${this.baseUrl}/Process_CLPUser_Delete/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId, encryptedUser, "UserService", "deleteUser") });

        return http$;
    }

    async clpuser_GetList_Lite(encryptedUser: string, clpCompanyId: number, officideCode: number = 0, teamCode: number = 0): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CLPUser_GetList_Lite/${clpCompanyId}/${officideCode}/${teamCode}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "officideCode - " + officideCode + ", teamcode" + teamCode, encryptedUser, "UserService", "clpuser_GetList_Lite") });

        return http$;
    }

    async clpUserGetLongCodeListDD(encryptedUser: string, companyId: number, intOfficeCode: number = 0, intTeamCode: number = 0, ePermissions: eUserPermissions = eUserPermissions.Active): Promise<UserListResponse | void> {
        const http$ = await this.httpClient
            .get<UserListResponse>(`${this.baseUrl}/CLPUser_GetLongCodeListDD/${companyId}?intOfficeCode=${intOfficeCode}&intTeamCode=${intTeamCode}&ePermissions=${ePermissions}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "companyId - " + companyId + "intOfficeCode - " + intOfficeCode + ", intTeamCode" + intTeamCode + ", ePermissions" + ePermissions, encryptedUser, "UserService", "clpUserGetLongCodeListDD") });

        return http$;
    }

    async authenticateWelcomeR(r: string): Promise<UserResponse | void> {
        const a = await this.httpClient
            .get<UserResponse>(`${this.baseUrl}/AuthenticateWelcomeR`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + r
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + r, "UserService", "AuthenticateWelcomeR"); });
        return a;
    }


    async getUserProfile(encryptedUser: string, clpUserName: string): Promise<CLPUserProfile | void> {
        const http$ = await this.httpClient
            .get<CLPUserProfile>(`${this.baseUrl}/GetUserByUserName/${clpUserName}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserName - " + clpUserName, encryptedUser, "UserService", "getUserProfile") });

        return http$;
    }

    async Clpuser_Update(encryptedUser: string, clpUser: CLPUserProfile): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/Clpuser_Update`, clpUser, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, clpUser, "r - " + encryptedUser, encryptedUser, "UserService", "Clpuser_Update") });

        return http$;
    }

    async clpuser_update_signup(encryptedUser: string, clpUser: CLPUser): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/Clpuser_Update_Signup`, clpUser, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, clpUser, "r - " + encryptedUser, encryptedUser, "UserService", "clpuser_update_signup") });

        return http$;
    }

    async getUserByUserId(encryptedUser: string, clpUserId: number): Promise<CLPUserProfile | void> {
        const http$ = await this.httpClient
            .get<CLPUserProfile>(`${this.baseUrl}/GetUserByUserId/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId, encryptedUser, "UserService", "getUserByUserId") });

        return http$;
    }
    async getNewUserList(encryptedUser: string, clpCompanyId: number): Promise<UserListResponse | void> {
        const http$ = await this.httpClient
            .get<UserListResponse>(`${this.baseUrl}/GetNewUserList/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId, encryptedUser, "UserService", "getNewUserList") });

        return http$;
    }

    async getCLPCompany_LoadUsers(encryptedUser: string, clpCompanyId: number, permissions: number, includeNewUsers: boolean): Promise<UserDD[] | void> {
        const http$ = await this.httpClient
            .get<UserDD[]>(`${this.baseUrl}/CLPCompany_LoadUsers/${clpCompanyId}?&permissions=${permissions}&includeNewUsers=${includeNewUsers}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "permissions - " + permissions + "," + "includeNewUsers - " + includeNewUsers, encryptedUser, "UserService", "getCLPCompany_LoadUsers") });

        return http$;
    }

    async clpUser_GetListByTeamCode(encryptedUser: string, companyId: number, teamCode: number): Promise<userListResponse | void> {
        const http$ = await this.httpClient
            .get<userListResponse>(`${this.baseUrl}/CLPUser_GetListByTeamCode/${companyId}/${teamCode}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + companyId + "," + "teamCode - " + teamCode, encryptedUser, "UserService", "clpUser_GetListByTeamCode") });

        return http$;
    }

    async CLPUserPref_Load(encryptedUser: string, clpUserId: number): Promise<CLPUserPref | void> {
        const http$ = await this.httpClient
            .get<CLPUserPref>(`${this.baseUrl}/CLPUserPref_Load/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId, encryptedUser, "UserService", "CLPUserPref_Load") });

        return http$;
    }

    async getCLPUserPrefDD(encryptedUser: string, clpCompanyId: number, clpUserId: number): Promise<CLPUserPrefDDsList | void> {
        const http$ = await this.httpClient
            .get<CLPUserPrefDDsList>(`${this.baseUrl}/GetCLPUserPrefDropdownData/${clpCompanyId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserId, encryptedUser, "UserService", "CLPUserPref_Load") });

        return http$;
    }

    async saveCLPUserPref(encryptedUser: string, clpUserPrefObj: CLPUserPref): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CLPUserPref_Save`, clpUserPrefObj, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, clpUserPrefObj, "r - " + encryptedUser, encryptedUser, "UserService", "saveCLPUserPref") });

        return http$;
    }

    async getUserPreferenceList(encryptedUser: string, genericRequest: GenericRequest, cLPCompanyID: number, intOfficeCode: number, intTeamCode: number, ePermissions: number, useAnd: boolean): Promise<CLPUserPrefResponse | void> {
        const http$ = await this.httpClient
            .get<CLPUserPrefResponse>(`${this.baseUrl}/CLPUserPrefs_GetList/${cLPCompanyID}?officeCode=${intOfficeCode}&teamCode=${intTeamCode}&ePermission=${ePermissions}&useAnd=${useAnd}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, genericRequest, "r - " + encryptedUser + " , " + "clpCompanyId - " + cLPCompanyID + " , " + "intOfficeCode - " + intOfficeCode + " , " + "intTeamCode - " + intTeamCode + " , " + "ePermissions - " + ePermissions + " , " + "useAnd - " + useAnd, encryptedUser, "UserService", "getUserPreferenceList") });

        return http$;
    }

    async userHistory_Get(encryptedUser: string, clpUserId: number, selUserId: number, startDate: string = "", endDate: string = "", noteTypeCode: number = -1): Promise<HistoryListResponse | void> {
        const a = await this.httpClient
            .get<HistoryListResponse>(`${this.baseUrl}/UserHistory_Get/${clpUserId}/${selUserId}?startDate=${startDate}&endDate=${endDate}&noteTypeCode=${noteTypeCode}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserId - " + clpUserId + " , " + "selUserId - " + selUserId + " , " + "startDate - " + startDate + " , " + "endDate - " + endDate + " , " + "noteTypeCode - " + noteTypeCode, encryptedUser, "UserService", "userHistory_Get"); }); //toPromise();
        return a;
    }

    async isDirectToVirtualVM(encryptedUser: string, CLPUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/DirectToVirtualVM/${CLPUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserId - " + CLPUserId, encryptedUser, "UserService", "isDirectToVirtualVM"); });
        return a;
    }

    async getTimeZoneById(encryptedUser: string, timeZoneId: number = 0): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/getTimeZoneById/${timeZoneId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).toPromise().catch(err => { this._utilityService.handleErrors(err, 'timeZoneId :' + timeZoneId, "r - " + encryptedUser, encryptedUser, "LocalService", "getTimeZoneById") });
        return a;
    }

    async clpUserGetListDD(encryptedUser: string, clpCompanyId: number, officeCode: number, teamCode: number, ePermissions: eUserPermissions): Promise<userListResponse | void> {
        const a = await this.httpClient
            .get<userListResponse>(`${this.baseUrl}/CLPUser_GetListDD/${clpCompanyId}/${officeCode}/${teamCode}/${ePermissions}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "officeCode - " + officeCode + " , " + "teamCode - " + teamCode + " , " + "ePermissions - " + ePermissions, encryptedUser, "UserService", "clpUserGetListDD"); }); //toPromise();
        return a;
    }
    createIPLog(ip: string, page: string, countryCode: string, spam: boolean, tor: boolean, city: string, detail: string): Observable<SimpleResponse | void> {
        let l: IPLog;

        l = {
            ip: ip,
            page: page,
            countryCode: countryCode,
            spam: spam,
            tor: tor,
            city: city,
            detail: detail
        };
        const httpErrorResponse = new HttpErrorResponse({});
        const http$ = this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/CreateIPLog`, l, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(
                delayedRetryHttp(),
                catchError(error => this._utilityService.handleErrors(httpErrorResponse, 'User',
                    'ip:' + ip
                    + '<br/>page:' + page
                    + '<br/>countryCode:' + countryCode
                    + '<br/>spam:' + spam
                    + '<br/>tor:' + tor
                    + '<br/>city:' + city
                    + '<br/>detail:' + detail,
                    error))
            );

        return http$;
    }

    async getUserDefaults(encryptedUser: string, CLPUserId: number): Promise<UserDefaultLoadResponse | void> {
        const a = await this.httpClient
            .get<UserDefaultLoadResponse>(`${this.baseUrl}/UserDefault_Load/${CLPUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserId - " + CLPUserId, encryptedUser, "UserService", "getUserDefaults"); });
        return a;
    }

    async userDefaultContactOrderSave(encryptedUser: string, contactSectionOrderSaveRequest: ContactSectionOrderSaveRequest): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/ContactOrderSave`, contactSectionOrderSaveRequest, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, contactSectionOrderSaveRequest, "r - " + encryptedUser, encryptedUser, "UserService", "userDefaultContactOrderSave"); });
        return a;
    }

    async processActiveHoursSave(encryptedUser: string, processActiveHoursSaveRequest: ProcessActiveHoursSaveRequest): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/ProcessActiveHours_Save`, processActiveHoursSaveRequest, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, processActiveHoursSaveRequest, "r - " + encryptedUser, encryptedUser, "UserService", "processActiveHoursSave"); });
        return a;
    }

    async pCalTempMMTClicksWebform_DefaultEntry(newClpCompanyId: number, newClpUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/PCalTempMMTClicksWebform_DefaultEntry/${newClpCompanyId}/${newClpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + '' + " , " + "newClpCompanyId - " + newClpCompanyId + " , " + "newClpUserId - " + newClpUserId, '', "UserService", "pCalTempMMTClicksWebform_DefaultEntry"); });
        return a;
    }

    async campaignTemplates_DefaultEntry(newClpCompanyId: number, newClpUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CampaignTemplates_DefaultEntry/${newClpCompanyId}/${newClpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + '' + " , " + "newClpCompanyId - " + newClpCompanyId + " , " + "newClpUserId - " + newClpUserId, '', "UserService", "campaignTemplates_DefaultEntry"); });
        return a;
    }

    async customActionScreens_DefaultEntry(newClpCompanyId: number, newClpUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CustomActionScreens_DefaultEntry/${newClpCompanyId}/${newClpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + '' + " , " + "newClpCompanyId - " + newClpCompanyId + " , " + "newClpUserId - " + newClpUserId, '', "UserService", "customActionScreens_DefaultEntry"); });
        return a;
    }

    async stitchUpSFA(newClpCompanyId: number, newClpUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/StitchUpSFA/${newClpCompanyId}/${newClpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + '' + " , " + "newClpCompanyId - " + newClpCompanyId + " , " + "newClpUserId - " + newClpUserId, '', "UserService", "stitchUpSFA"); });
        return a;
    }

    async cLPUserLoad(encryptedUser: string, userId: number): Promise<CLPUser | void> {
        const a = await this.httpClient
            .get<CLPUser>(`${this.baseUrl}/CLPUser_Load/${userId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "userId - " + userId, encryptedUser, "UserService", "cLPUserLoad"); });
        return a;
    }

    async ipBlackList_Update(ipBlackList: IPBlackList, r: string = ""): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/IPBlackList_Update/`, ipBlackList, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + r
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, ipBlackList, "r = " + r, "UserService", "ipBlackList_Update"); });
        return a;
    }

    async deviceInfo_Filter_Get(r: string): Promise<DeviceInfoFilterResponse | void> {
        const http$ = await this.httpClient
            .get<DeviceInfoFilterResponse>(`${this.baseUrl}/DeviceInfo_Filter_Get/`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + r
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + r + ",", "UserService", "deviceInfo_Filter_Get") });

        return http$;
    }

    async ipBlackList_GetList(r: string): Promise<BlackListIpResponse | void> {
        const http$ = await this.httpClient
            .get<BlackListIpResponse>(`${this.baseUrl}/IPBlackList_GetList/`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + r
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + r + ",", "UserService", "ipBlackList_GetList") });

        return http$;
    }

    async loginConfig_GetList(r: string): Promise<LoginConfigurationResposne | void> {
        const http$ = await this.httpClient
            .get<LoginConfigurationResposne>(`${this.baseUrl}/LoginConfiguration_Get/`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + r
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + r + ",", "UserService", "loginConfig_GetList") });

        return http$;
    }

    async loginConfig_Update(loginConfiguration: LoginConfiguration, r: string = ""): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/LoginConfiguration_Update/`, loginConfiguration, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + r
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, loginConfiguration, "r = " + r, "UserService", "loginConfig_Update"); });
        return a;
    }

    async deviceInfo_Logs_Search(request: SimpleRquest, r: string): Promise<DeviceInfoListResponse | void> {
        const http$ = await this.httpClient
            .post<DeviceInfoListResponse>(`${this.baseUrl}/DeviceInfo_Logs_Search/`, request, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + r
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, request, "r - " + r + ",", "UserService", "deviceInfo_Logs_Search") });

        return http$;
    }


    async getDDFieldList(encryptedUser: string, efield: eDDField, clpCompanyId: number): Promise<DDFieldsResponse | void> {
        const http$ = await this.httpClient
            .get<DDFieldsResponse>(`${this.baseUrlDDFeilds}/DDField_GetList/${efield}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "efield - " + efield + "clpCompanyId - " + clpCompanyId, encryptedUser, "UserService", "getDDFieldList") });
        return http$;
    }

    async GSCheckListLoad(encryptedUser: string, clpUserId: number): Promise<CheckListLoadResponse | void> {
        const http$ = await this.httpClient
            .get<CheckListLoadResponse>(`${this.baseUrlGSCheckList}/Load/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId, encryptedUser, "UserService", "cLPUserPrefsShowGSCheckList") });
        return http$;
    }

    async gSCheckListUserCreate(encryptedUser: string, clpUserId: number, gscheckListID: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrlGSCheckList}/GSCheckListUser_Create/${clpUserId}/${gscheckListID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId, encryptedUser, "UserService", "cLPUserPrefsShowGSCheckList") });
        return http$;
    }

    async gSCheckListUserDelete(encryptedUser: string, clpUserId: number, gscheckListID: number): Promise<SimpleResponse | void> {
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrlGSCheckList}/GSCheckListUser_Delete/${clpUserId}/${gscheckListID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId, encryptedUser, "UserService", "cLPUserPrefsShowGSCheckList") });
        return http$;
    }
    async GetAllowedCLPUserList(encryptedUser: string, clpUserId: number): Promise<AllowedUserListResponse | void> {
        const http$ = await this.httpClient
            .get<AllowedUserListResponse>(`${this.baseUrl}/CLPUser_GetAllowedUserList/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserId, encryptedUser, "UserService", "GetAllowedCLPUserList") });
        return http$;
    }

    async checkDuplicateUserName(encryptedUser: string, userName: string, cLPCompanyID: number): Promise<SimpleResponse | void> {
       const http$ = await this.httpClient
           .get<SimpleResponse>(`${this.baseUrl}/CheckDuplicateUserName/${userName}/${cLPCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "cLPCompanyID - " + cLPCompanyID, encryptedUser, "UserService", "CheckDuplicateUserName") });
        return http$;
    }

    async deleteUserTokenByUserId(encryptedUser: string, oldUserName: string, clpUserID: number): Promise<SimpleResponse | void>{
        const http$ = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/DeleteUserTokenByUserId/${oldUserName}/${clpUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserID - " + clpUserID, encryptedUser, "UserService", "deleteUserToken") });
        return http$;
    }
}
