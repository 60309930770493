import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';
import { EmailOptLogListResponse, EmailPromoOptLogListResponse, EmailSettings, EmailSettingsResponse, Note, NoteFilterResponse, NoteListResponse, NoteResponse, StatusEmailResponse } from '../models/note.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { eEmailOptType, eNoteOwnerType, eNoteStatus } from '../models/enum.model';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  private baseUrl: string;
  private api: string = "api/Notes";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async getNoteFilters(encryptedUser: string, cLPUserID: number, clpCompanyId: number): Promise<NoteFilterResponse | void> {
    const a = await this.httpClient
      .get<NoteFilterResponse>(`${this.baseUrl}/Note_Get_Filters/${cLPUserID}/${clpCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "cLPUserID - " + cLPUserID + "," + "clpCompanyId - " + clpCompanyId , encryptedUser,"NotesService", "getNoteFilters"); });
    return a;
  }

  async noteCreate(encryptedUser: string, note: Note): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/Note_Create`, note, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, note, "r - " + encryptedUser, encryptedUser, "NotesService", "noteCreate"); });
    return a;
  }

  async noteGetListByOwner(encryptedUser: string, clpCompanyID: number, clpUserId: number, ownerID: number, ownerType: number, isRecent: boolean): Promise<NoteListResponse | void> {
    const a = await this.httpClient
      .get<NoteListResponse>(`${this.baseUrl}/Note_GetListByOwner/${clpCompanyID}/${clpUserId}/${ownerID}/${ownerType}/${isRecent}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyID - " + clpCompanyID + "," + "clpUserId - " + clpUserId + "," + "ownerID - " + ownerID + "," + "ownerType - " + ownerType + ","  + "isRecent - " + isRecent, encryptedUser,"NotesService", "noteGetListByOwner"); });
    return a;
  }

  async noteDelete(encryptedUser: string, noteId: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/Note_Delete/${noteId}`,  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "noteId - " + noteId,encryptedUser, "NotesService", "noteDelete"); });
    return a;
  }

  async NoteCountGetByClpUserId(encryptedUser: string, clpUserId: number, startDate: string, endDate: string, noteTypeCode: number = -1, eType: eNoteOwnerType = eNoteOwnerType.Unknown, eStatus: eNoteStatus = eNoteStatus.Unknown): Promise<SimpleResponse> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/NoteByClpUserId_GetCount/${clpUserId}?startDate=${startDate}&endDate=${endDate}&noteTypeCode=${noteTypeCode}&eType=${eType}&eStatus=${eStatus}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserId - " + clpUserId + " , " + "startDate - " + startDate + " , " + "endDate - " + endDate + " , " + "noteTypeCode - " + noteTypeCode + " , " + "eType - " + eType + " , " + "eStatus - " + eStatus, encryptedUser, "NotesService", "NoteCountGetByClpUserId"); });
    return a;
  }

  async noteLoad(encryptedUser: string, noteId: number): Promise<NoteResponse | void> {
    const a = await this.httpClient.get<NoteResponse>(`${this.baseUrl}/Note_Load/${noteId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "noteId - " + noteId, encryptedUser, "NotesService", "noteLoad"); });
    return a;
  }

  async emailSettingsLoad(encryptedUser: string, clpCompanyId: number, email: string): Promise<EmailSettingsResponse | void> {
    const a = await this.httpClient.get<EmailSettingsResponse>(`${this.baseUrl}/EmailSettings_Load/${clpCompanyId}?email=${email}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "email - " + email, encryptedUser, "NotesService", "emailSettingsLoad"); });
    return a;
    }

    async emailSettingsSave(encryptedUser: string, emailSettings: EmailSettings): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/EmailSettings_Save`, emailSettings, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, emailSettings, "r - " + encryptedUser, encryptedUser, "NotesService", "emailSettingsSave"); });
        return a;
    }

  async statusEmailLoad(encryptedUser: string, clpCompanyId: number, email: string): Promise<StatusEmailResponse | void> {
    const a = await this.httpClient.get<StatusEmailResponse>(`${this.baseUrl}/StatusEmail_Load/${clpCompanyId}?email=${email}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "email - " + email, encryptedUser, "NotesService", "statusEmailLoad"); });
    return a;
  }

  async emailOptLogGetList(encryptedUser: string, clpCompanyId: number, email: string): Promise<EmailOptLogListResponse | void> {
    const a = await this.httpClient.get<EmailOptLogListResponse>(`${this.baseUrl}/EmailOptLog_GetList/${clpCompanyId}?email=${email}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "email - " + email, encryptedUser, "NotesService", "emailOptLogGetList"); });
    return a;
  }

  async emailPromoOptLogGetList(encryptedUser: string, clpCompanyId: number, email: string): Promise<EmailPromoOptLogListResponse | void> {
    const a = await this.httpClient.get<EmailPromoOptLogListResponse>(`${this.baseUrl}/EmailPromoOptLog_GetList/${clpCompanyId}?email=${email}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "email - " + email, encryptedUser, "NotesService", "emailPromoOptLogGetList"); });
    return a;
  }

  async emailOptLogCreate(encryptedUser: string, clpCompanyId: number, eType: eEmailOptType, email: string, strNote:string): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/EmailOptLog_Create/${clpCompanyId}/${eType}?email=${email}&strNote=${strNote}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "eType - " + eType + "," + "email - " + email + "," + "strNote - " + strNote, encryptedUser, "NotesService", "emailOptLogCreate"); });
    return a;
  }

  async emailPromoOptLogCreate(encryptedUser: string, clpCompanyId: number, eType: eEmailOptType, email: string, strNote: string): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/EmailPromoOptLog_Create/${clpCompanyId}/${eType}?email=${email}&strNote=${strNote}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "eType - " + eType + "," + "email - " + email + "," + "strNote - " + strNote, encryptedUser, "NotesService", "emailPromoOptLogCreate"); });
    return a;
  }

  async setInfoSubStatus(encryptedUser: string, clpCompanyId: number, eType: eEmailOptType, email: string): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/SetInfoSubStatus/${clpCompanyId}/${eType}?email=${email}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "eType - " + eType + "," + "email - " + email, encryptedUser, "NotesService", "setInfoSubStatus"); });
    return a;
  }

  async setPromoSubStatus(encryptedUser: string, clpCompanyId: number, eType: eEmailOptType, email: string): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/SetPromoSubStatus/${clpCompanyId}/${eType}?email=${email}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "eType - " + eType + "," + "email - " + email, encryptedUser, "NotesService", "setPromoSubStatus"); });
    return a;
    }

    async GetNotePreview(encryptedUser: string, noteId: number, rgd: number = 0): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/Get_NotePreview/${noteId}?rgd=${rgd}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "noteId - " + noteId + "," + "rgd - " + rgd , encryptedUser, "NotesService", "GetNotePreview"); });
    return a;
    }

}
