<div class="global-body-section" *ngIf="nonTotalScorecardData?.length>0">
    <kendo-grid #grid id="grid" class="score-card-common" *ngIf="_gridCnfgService.reloadGridGeneric"
                [kendoGridBinding]="nonTotalScorecardData"
                [sortable]="{mode: 'multiple'}"
                [sort]="_gridCnfgService.sort"
                [pageSize]="_gridCnfgService.pageSize"
                [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                [scrollable]="'false'"
                [reorderable]="true"
                [resizable]="true"
                [columnMenu]="{ filter: true }"
                (columnReorder)="_gridCnfgService.columnsOrderChanged('score_card_code_grid', $event)"
                (sortChange)="_gridCnfgService.sortChange('score_card_code_grid', $event)"
                (pageChange)="_gridCnfgService.pageChange('score_card_code_grid', $event)"
                (columnResize)="_gridCnfgService.columnResize(4,'score_card_code_grid', $event)"
                (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'score_card_code_grid',grid)">
        <kendo-grid-messages [pagerItemsPerPage]="'Items per page'" [pagerItems]="'Items'"></kendo-grid-messages>
        <ng-template kendoGridToolbarTemplate>
            <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
            <button type="button" class="k-button export-icon" title="Export list in excel" (click)="saveExcel(excelexport)" *ngIf="nonTotalScorecardData?.length>0">
                <img src="../../../../../assets/iconexcel.svg" class="mr-1" />
                <span>Download Excel</span>
            </button>
            <button type="button" kendoGridPDFCommand title="Export list in pdf" class="export-icon" *ngIf="nonTotalScorecardData?.length>0">
                <img src="../../../../../assets/iconpdf.svg" class="mr-1" />
                <span>Download PDF</span>
            </button>
        </ng-template>

        <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                           [field]="column.field"
                           [title]="column.title"
                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                           [width]="column.width | stringToNumber"
                           [filterable]="true"
                           [hidden]="_gridCnfgService.hiddenColumns?.indexOf(column.field) > -1 || hiddenColumns?.includes(column.field)"
                           [includeInChooser]="(column.field=='$' ? false : true) && (!hiddenColumns?.includes(column.field))">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="customer-name" *ngIf="column.field == '$' && column.title == ''">{{ rowIndex+1 }}</div>
                <div class="customer-name" *ngIf="column.field != 'bipRate'&& column.field != 'bipShow'&& column.field != 'bipShowRate'&& column.field != 'showsPerLead' && column.field != 'ppSOSold' && column.field != 'psSold'">{{ dataItem[column.field] }}</div>
                <div class="customer-name" *ngIf="column.field == 'bipRate'">{{ dataItem[column.field] | percent:'1.2'}}</div>
                <div class="customer-name" *ngIf="column.field == 'bipShow' || column.field == 'ppSOSold' || column.field == 'psSold'">{{ dataItem[column.field] | number}}</div>
                <div class="customer-name" *ngIf="column.field == 'bipShowRate'">{{ dataItem[column.field] | percent:'1.2'}}</div>
                <div class="customer-name" *ngIf="column.field == 'showsPerLead'">{{ dataItem[column.field] | percent:'1.2'}}</div>
            </ng-template>
            <ng-template kendoGridFooterTemplate let-column="column" let-columnIndex="columnIndex" *ngFor="let dataItem of scorecardTotal">
                <div class="customer-name text-white" *ngIf="column.field != 'showsPerLead'&& column.field != 'bipRate'&& column.field != 'bipShow'&& column.field != 'bipShowRate'&& column.field != 'keyword'&& column.field != 'officeDisplay'&& column.field != 'salesperson'&& column.field != 'teamDisplay'&& column.field != 'code'&& column.field != 'desc' && column.field != 'ppSOSold' && column.field != 'psSold'">{{dataItem[column.field] | number }}</div>
                <div class="customer-name text-white" *ngIf="column.field == 'bipRate'">{{ dataItem[column.field] | percent:'1.2'}}</div>
                <div class="customer-name text-white" *ngIf="column.field == 'bipShow'  || column.field == 'ppSOSold' || column.field == 'psSold'">{{ (dataItem[column.field] | isNull) ? (dataItem[column.field] | number) : ''}}</div>
                <div class="customer-name text-white" *ngIf="column.field == 'bipShowRate'">{{ (dataItem[column.field] | isNull) ? (dataItem[column.field]  | number:'1.2-2') : ''}}%</div>
                <div class="customer-name text-white" *ngIf="column.field == 'showsPerLead'">{{ (dataItem[column.field] | isNull) ? (dataItem[column.field]  | percent:'1.2') : ''}}</div>
            </ng-template>
        </kendo-grid-column>
        <kendo-excelexport #excelexport [data]="nonTotalScorecardData" fileName='Scorecard-Report'>
            <kendo-excelexport-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width">
                <ng-template let-value="rowIndex">
                    <div class="customer-name" *ngIf="col.field != '$'" [innerHtml]="dataItem[col.field]"></div>
                </ng-template>
            </kendo-excelexport-column>
        </kendo-excelexport>
        <kendo-grid-pdf fileName="Scorecard-Report.pdf" [scale]="0.8" [repeatHeaders]="true" [allPages]="true" [margin]="{ top: '1cm', left: '1cm', right: '1cm', bottom: '1cm' }">
            <kendo-grid-column *ngFor="let col of _gridCnfgService.columns" [field]="col.field" [title]="col.title" [width]="col.width | stringToNumber">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="customer-name" *ngIf="col.field != '$'" [innerHtml]="dataItem[col.field]"></div>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid-pdf>
    </kendo-grid>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
