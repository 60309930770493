import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, OnInit, Output } from '@angular/core';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { CLPUser } from '../../../../models/clpuser.model';
import { Contact, LoadContactCallResponse } from '../../../../models/contact.model';
import { DropDownItem } from '../../../../models/genericResponse.model';
import { UtilityService } from '../../../../services/shared/utility.service';
import { eButtonActions, eViewMode, eVoiceCall, eVoiceCallStatus } from '../../../../models/enum.model';
import { NotificationService } from '../../../../services/notification.service';
import { VoiceCallModel } from '../../../../models/voiceCall.model';
import { VoiceCallService } from '../../../../services/voice-call.service';
import { DatePipe } from '@angular/common';
import { LocalService } from '../../../../services/shared/local.service';
import { ContactService } from '../../../../services/contact.service';
import { interval, Subscription } from 'rxjs';
import { GlobalService } from '../../../../services/global.service';

@Component({
    selector: 'contact-new-call',
    templateUrl: './contact-new-call.component.html',
    styleUrls: ['./contact-new-call.component.css']
})

export class ContactNewCallComponent implements OnInit {

    @Input() contactData: Contact;
    @Input() callType: string;
    @Input() user: CLPUser;
    @Input() voiceCallID?: number = 0;

    messageCallHeading: string; /*pnlMessage or lblMsg*/
    encryptedUser: string;
    showSpinner: boolean;
    centerCallMsg: string;
    viewModeCall: eViewMode;

    intNumbers: number;
    newCallForm: FormGroup;
    viewMode: eVoiceCall;
    sbMsg: string = "";
    txtCallNotes: string;

    /*labels*/
    lblFromNumber: string;
    labelToContactNumber: string;
    lblUserPhone: string;
    lblScheduledCallTime: string;
    lblCenterMessage: string;

    /*DropDown*/
    ddToContactNumber: DropDownItem[];
    ddUserBPhone: DropDownItem[];

    /*hiding field configurations*/
    hideDDToContactNum: boolean
    hideLblToContactNum: boolean;
    hideLblUserPhone: boolean;
    hideDDUserBPhone: boolean
    hidePnlCallNotes: boolean;
    hidelblCallNotes: boolean;
    hideBtnConnect: boolean
    hidetxtPreCallScript: boolean;
    hidelblScheduledCallTime: boolean
    hideBtnChangeDate: boolean
    hidePnlButtonsMakeCall: boolean
    hidePnlButtonsEditCall: boolean;
    hideBtnSave: boolean;
    hidePnlChangeDate: boolean
    hideLblStatusDisplay: boolean;
    hideTrStatus: boolean;
    hideBtnEditEdit: boolean;
    hideBtnDelete: boolean;
    hideBtnlnkClose;
    hidetTxtCustomPhone: boolean;
    hideTxtCallNotes: boolean;
    hideBtnEditSave: boolean;
    hideBtnEditCancel: boolean;
    hidePnlMain: boolean;
    lblCallToUserNumber: string;

    isScheduled: boolean;
    hideLblPreCallScript: boolean;
    tmWait: boolean = false
    voiceCallFieldMappings = <VoiceCallModel>{}
    @Output() updatedCall = new EventEmitter<boolean>();
    buttonTypeOperation: eButtonActions;

    wch = "mp"
    callDetails: LoadContactCallResponse;
    private intervalSubscription: Subscription;
    voiceCall: VoiceCallModel;
    constructor(
        private _utilityService: UtilityService,
        private voiceCallService: VoiceCallService,
        private _notifyService: NotificationService,
        private fb: FormBuilder,
        public _localService: LocalService,
        private datepipe: DatePipe,
        private contactService: ContactService,
        private _globalService: GlobalService
    ) {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
            }
        });
        this.messageCallHeading = 'Please select the number you would like to use for this call.';
        this.ddToContactNumber = [];
        this.ddUserBPhone = [];
        this.labelToContactNumber = "";
        this.lblFromNumber = "";
        this.lblCenterMessage = "";
        this.lblCallToUserNumber = "";
        this.intNumbers = 0;
        this.buttonTypeOperation = eButtonActions.None;
        
    }

    ngOnInit(): void {
        this.newCallForm = this.prepareNewCallForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.loadContactCall()
            }
        });
        this.intervalSubscription = interval(5000).subscribe(() => {
            if (this.tmWait) {
                this.tmWaitTick();
            }
        });
    }

    async loadContactCall() {
        this.showSpinner = true;
        await this.contactService.loadContactCall(this.encryptedUser, this.contactData.contactID, this.wch, this.user?.cLPUserID, this.voiceCallID, this.user?.cLPCompanyID)
            .then(async (result: LoadContactCallResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.showSpinner = false;
                    this.callDetails = UtilityService.clone(result);
                    this.ddToContactNumber = this.callDetails?.ddToContactNumber
                    this.ddUserBPhone = this.callDetails?.ddUserPhone
                    this.voiceCall = this.callDetails?.voiceCallModel
                    this.setUpNewCall();
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-new-call.loadContactCall", err.message, null,
                    'contactID: ' + this.contactData.contactID
                    + ' wch: ' + this.wch
                    + ' cLPUserID: ' + this.user.cLPUserID
                    + ' voiceCallID: ' + this.voiceCallID
                    + ' cLPCompanyID: ' + this.user?.cLPCompanyID
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async newCallFormSubmit() {
        this.buttonTypeOperation = eButtonActions.Save;
        this.voiceCallFieldMappings.clpCompanyID = this.contactData.cLPCompanyID;
        this.voiceCallFieldMappings.clpUserID = this.user.cLPUserID;
        this.voiceCallFieldMappings.contactID = this.contactData.contactID;
        this.voiceCallFieldMappings.callID = '';
        this.voiceCallFieldMappings.fromNumber = this.newCallForm.get('bridgeContact').value;
        this.voiceCallFieldMappings.toNumber = this.newCallForm.get('toContact').value;
        this.voiceCallFieldMappings.preCallScript = this.newCallForm.get('preCallScript').value;
        this.voiceCallFieldMappings.status = eVoiceCallStatus.Pending;
        this.voiceCallFieldMappings.isScheduled = false;
        this.lblScheduledCallTime = "Immediately";
        this.voiceCallFieldMappings.duration = 0;
        if (this.newCallForm.get('callTime').value && this.isScheduled) {
            this.voiceCallFieldMappings.dtStart = this.datepipe.transform(this.newCallForm.get('callTime').value, 'MM/dd/yyyy HH:mm:ss');
        }
        else {
            this.voiceCallFieldMappings.dtStart = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm:ss');
        }

        await this.voiceCallService.saveVoiceCall(this.encryptedUser, this.voiceCallFieldMappings)
            .then(async (result: VoiceCallModel) => {
                if (!isNullOrUndefined(result)) {
                    this.buttonTypeOperation = eButtonActions.None;
                    this.voiceCallFieldMappings = UtilityService.clone(result);
                    this._notifyService.showSuccess('Call has been scheduled successful.', '', 3000);
                    this.closeModal()
                }
                else
                    this.buttonTypeOperation = eButtonActions.None;
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-new-call.newCallFormSubmit", err.message, this.voiceCallFieldMappings,
                    ''
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getVoiceCall() {
        this.showSpinner = true;
        await this.voiceCallService.tmWait_Tick(this.encryptedUser, this.voiceCallID)
            .then(async (result: VoiceCallModel) => {
                if (!isNullOrUndefined(result)) {
                    this.showSpinner = false;
                    var vc: VoiceCallModel = result;
                    if (!isNullOrUndefined(vc)) {
                        switch (vc.status) {
                            case eVoiceCallStatus.Pending:
                                this.lblCenterMessage = "Call has been placed in queue to be processed.";
                                break;
                            case eVoiceCallStatus.InProgress:
                                this.lblCenterMessage = "Call is being processed.";
                                break;

                            case eVoiceCallStatus.AwaitingAnswer:
                                this.lblCenterMessage = "Call is being handled now.";
                                break;
                            case eVoiceCallStatus.Initiated:
                                this.tmWait = false;
                                this.viewMode = eVoiceCall.MakeCallEdit;
                                this.vieModeSet();
                                this.lblCenterMessage = '';
                                this.messageCallHeading = 'Call connected.  Enter any call notes or close this window.';
                                break;

                            default:
                                break;
                        }
                    }
                    else {
                        this.tmWait = false;
                        this.viewMode = eVoiceCall.Hide;
                        this.vieModeSet();
                        this.messageCallHeading = 'Unable to find this voice call.';
                    }
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-new-call.getVoiceCall", err.message, null,
                    ' voiceCallID: ' + this.voiceCallID
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    ngOnDestroy(): void {
        this.intervalSubscription.unsubscribe();
    }

    tmWaitTick() {
        if (this.voiceCallID > 0) {
            this.getVoiceCall();
        }
    }

    prepareNewCallForm(): FormGroup {
        return this.fb.group({
            toContact: new FormControl(''),
            bridgeContact: new FormControl(''),
            preCallScript: new FormControl(''),
            txtCustomPhone: new FormControl(''),
            callTime: new FormControl(new Date()),
            callNotes: new FormControl('')
        });
    }

    setUpNewCall() {
        if (this.voiceCallID == 0) {
            
            this.centerCallMsg = '';
            if (this.contactData?.contactID > 0) {
                if (this.user.cLPUserID > 0) {
                    if (this.ddToContactNumber.length > 0) {
                        this.labelToContactNumber = this.ddToContactNumber[0].text;
                        if (!isNullOrUndefined(this.user.txtMsgLongCode) && this.user.txtMsgLongCode.trim() != "") {
                            this.lblFromNumber = this.user.firstName + " " + this.user.lastName + " " + this.maskNumber(this.user.txtMsgLongCode);
                            if (this.newCallForm.get('bridgeContact')) {
                                if (this.ddUserBPhone.length > 0)
                                    this.newCallForm.get('bridgeContact').setValue(this.ddUserBPhone[0].value);
                                else
                                    this.newCallForm.get('bridgeContact').setValue(-1);
                            }

                            if (this.newCallForm.get('toContact') && this.ddToContactNumber?.length > 0) {
                                if (!isNullOrUndefined(this.callType))
                                {                                   
                                    switch (this.callType) {
                                        case 'ph':
                                            this.newCallForm.get('toContact').setValue(this.ddToContactNumber.find(x => x.text.includes('Work')).value);
                                            break;
                                        case 'mp':
                                            this.newCallForm.get('toContact').setValue(this.ddToContactNumber.find(x => x.text.includes('Mobile')).value);
                                            break;
                                        case 'hp':
                                            this.newCallForm.get('toContact').setValue(this.ddToContactNumber.find(x => x.text.includes('Home')).value);
                                            break;
                                        case 'ap':
                                            this.newCallForm.get('toContact').setValue(this.ddToContactNumber.find(x => x.text.includes('Other')).value);
                                            break;
                                        case 'fx':
                                            this.newCallForm.get('toContact').setValue(this.ddToContactNumber.find(x => x.text.includes('Fax')).value);
                                            break;
                                    }
                                }
                                else
                                    this.newCallForm.get('toContact').setValue(this.ddToContactNumber[0].value);
                            }

                            this.lblScheduledCallTime = "Immediately";
                            this.viewMode = eVoiceCall.AddNew;
                            this.vieModeSet();
                        }
                        else
                            this.sbMsg += "A Click-To-Call enabled Long Code is required.";
                    }
                    else
                        this.sbMsg += "A valid Contact phone number is required.";
                }
                else
                    this.sbMsg += "User is required.";
            }
            else
                this.sbMsg += "Contact is required.";

            if (this.sbMsg.length > 0) {
                this.hidePnlMain = true;
                this._notifyService.showError(this.sbMsg, "", 3000);
                this.tmWait = false;
            }
        }
        else {
            this.viewMode = eVoiceCall.ViewExisting;
            this.vieModeSet();
            this.setupExistingVoiceCall();
        }
    }

    setupExistingVoiceCall() {
        if (this.voiceCallID > 0) {
            /*make voice call object*/
            var sbMsg: string = '';
            this.centerCallMsg = '';
            if (this.contactData?.contactID > 0) {
                if (this.user.cLPUserID > 0) {
                    this.labelToContactNumber = this.ddToContactNumber[0].text;
                    if (!isNullOrUndefined(this.user.txtMsgLongCode) && this.user.txtMsgLongCode.trim() != "") {
                        this.lblFromNumber = this.user.firstName + " " + this.user.lastName + " " + this.maskNumber(this.user.txtMsgLongCode);
                        this.newCallForm.controls.preCallScript.patchValue(this.voiceCall.preCallScript)
                        if (this.newCallForm.get('txtCustomPhone')) {
                            this.newCallForm.get('txtCustomPhone').patchValue(this.callDetails.userPhone);
                        }
                        if (this.newCallForm.get('toContact') && this.ddToContactNumber?.length > 0)
                            this.newCallForm.get('toContact').setValue(this.ddToContactNumber[0].value);

                        //this.lblScheduledCallTime = "Immediately";
                        this.viewMode = eVoiceCall.EditExisting
                        this.vieModeSet();
                    }
                    else
                        sbMsg += "A Click-To-Call enabled Long Code is required.";
                }
                else
                    sbMsg += "User is required.";
            }
            else
                sbMsg += "Contact is required.";
        }
        else
            this.viewMode = eVoiceCall.Hide;
        //this._notifyService.showError("This voice call no longer exists", "", 3000);
    }

    vieModeSet() {
        this.hidePnlCallNotes = false;
        this.hidelblCallNotes = false;
        this.hideTxtCallNotes = false;
        this.hideLblUserPhone = false;
        this.hideDDUserBPhone = false;
        this.hideDDToContactNum = false;
        this.hideLblPreCallScript = true;
        this.hidetxtPreCallScript = true;
        this.hideLblToContactNum = false;
        this.hideBtnConnect = false;
        this.hideBtnSave = false;
        this.hideBtnlnkClose = false;
        this.hidetTxtCustomPhone = false;
        this.hidelblScheduledCallTime = false;
        this.hidePnlChangeDate = false;
        this.hideBtnChangeDate = false;
        this.lblCenterMessage = '';
        this.messageCallHeading = '';
        this.tmWait = false;
        this.hidePnlButtonsMakeCall = false;
        this.hidePnlButtonsEditCall = false;
        this.hideBtnEditEdit = false;
        this.hideBtnEditCancel = false;
        this.hideBtnEditSave = false;
        this.hideBtnDelete = false;
        this.hideTrStatus = false;

        switch (this.viewMode) {
            case eVoiceCall.Invisible:
                /*hide all*/
                break;
            case eVoiceCall.MakeCallEdit:
                this.hideLblUserPhone = true;
                this.hideDDToContactNum = true;
                this.hidePnlCallNotes = true;
                this.hidelblCallNotes = true;
                this.hidePnlButtonsMakeCall = true;
                this.hidetxtPreCallScript = false;
                this.hideBtnSave = true;
                this.hideBtnlnkClose = true;
                break;

            case eVoiceCall.ConnectImmediately:
                this.hideLblUserPhone = true;
                this.hidetxtPreCallScript = false;
                this.hidelblScheduledCallTime = true;
                this.hideLblToContactNum = true;
                this.hidePnlButtonsMakeCall = true;
                this.lblScheduledCallTime = "Immediately";
                this.lblCenterMessage = "Call has been placed in queue to be processed.";
                this.tmWait = true;
                break;

            case eVoiceCall.AddNew:
                this.hideDDUserBPhone = true;
                this.hideBtnConnect = true;
                this.hidetxtPreCallScript = false;
                this.hideDDToContactNum = true;
                this.hidelblScheduledCallTime = true;
                this.hideBtnChangeDate = true;
                this.hidePnlButtonsMakeCall = true;
                this.messageCallHeading = 'Please select the number you would like to use for this call.';
                break;

            case eVoiceCall.ScheduleCall:
                this.hideLblUserPhone = true;
                this.hidePnlButtonsMakeCall = true;
                this.hideDDToContactNum = true;
                this.hidetxtPreCallScript = false;
                this.hideBtnSave = true;
                this.hideBtnlnkClose = true;
                break;

            case eVoiceCall.Hide:
                this.hideLblUserPhone = true;
                this.hidetxtPreCallScript = false;
                this.hidePnlButtonsMakeCall = true;
                this.hideBtnlnkClose = true;
                break;
            case eVoiceCall.ViewExisting:
                this.hideLblUserPhone = true;
                this.hidetxtPreCallScript = false;
                this.hidePnlCallNotes = true;
                this.hidelblCallNotes = true;
                this.hideLblToContactNum = true;
                this.hidelblScheduledCallTime = true;
                this.hidePnlChangeDate = true;
                this.hideLblStatusDisplay = true;
                this.hideTrStatus = true;
                this.hidePnlButtonsEditCall = true;
                this.hideBtnEditEdit = true;
                this.hideBtnDelete = true;
                break;
            case eVoiceCall.EditExisting:
                this.hideDDToContactNum = true;
                this.hidetTxtCustomPhone = true;
                this.hidetxtPreCallScript = false;
                this.hidePnlCallNotes = true;
                this.hideTxtCallNotes = true;
                this.hidelblScheduledCallTime = true;
                this.hidePnlChangeDate = true;
                this.hideLblStatusDisplay = true;
                this.hideTrStatus = true;
                this.hidePnlButtonsEditCall = true;
                this.hideBtnEditSave = true;
                this.hideBtnEditCancel = true;
                this.hideBtnDelete = true;
                break;
            default:
                break;
        }
    }

    maskNumber(num: string): string {
        var returnNum;
        if (num.length == 10)
            returnNum = "(" + num.substring(0, 3) + ")"+ " " + num.substring(3, 6) + '-' + num.substring(6, 10);
        else if (num.length == 11)
            returnNum = "(" + num.substring(0, 3) + ")" + " " + num.substring(3, 7) + '-' + num.substring(7, 11);
        return returnNum;
    }


    btnChangeDateClick() {
        if (this.newCallForm.get('bridgeContact')?.value == '-1' && this.newCallForm.get('txtCustomPhone')?.value?.trim() != '') {
            this.lblCallToUserNumber = this.newCallForm.get('txtCustomPhone').value.replace(/[-,. ext ()]/g, charactersToReplace => ({ '-': '', ',': '', '.': '', 'ext': '', ' ': '', '(': '', ')': '' })[charactersToReplace]);
            this.lblUserPhone = this.newCallForm.get('txtCustomPhone').value;
        }
        else {
            this.isScheduled = true;
            if (this.newCallForm.controls.bridgeContact.value != -1) {
                this.lblCallToUserNumber = this.newCallForm.controls.bridgeContact.value?.replace(/[-,. ext ()]/g, charactersToReplace => ({ '-': '', ',': '', '.': '', 'ext': '', ' ': '', '(': '', ')': '' })[charactersToReplace]);
            }
            if (!isNullOrUndefined(this.lblCallToUserNumber) && this.lblCallToUserNumber?.trim() != '') {
                var selectedValuetmp: DropDownItem[] = this.ddUserBPhone?.filter(item => {
                    var tempItemValue = item.value?.replace(/[-,. ext ()]/g, charactersToReplace => ({ '-': '', ',': '', '.': '', 'ext': '', ' ': '', '(': '', ')': '' })[charactersToReplace]);
                    return tempItemValue === this.lblCallToUserNumber;
                });
                if (selectedValuetmp.length > 0)
                    this.lblUserPhone = selectedValuetmp[0].text;
            }
        }

        if (this.lblCallToUserNumber?.trim() != '') {
            this.viewMode = eVoiceCall.ScheduleCall;
            this.vieModeSet();
            this.scheduleForLater();
            this.messageCallHeading = "Select a date and time for when you would like the call initiated.";
        }
    }

    lnkCancelScheduleClick() {
        this.hidePnlChangeDate = false;
        this.hidelblScheduledCallTime = true;
        this.hideBtnChangeDate = true;
        if (this.newCallForm.get('callTime'))
            this.newCallForm.get('callTime').setValue(new Date());
        this.lblScheduledCallTime = 'Immediately';
    }

    scheduleForLater() {
        this.hidePnlChangeDate = true;
        this.hidelblScheduledCallTime = false;
        this.hideBtnChangeDate = false;
    }

    btnConnectClick() {
        var blnGood: boolean = false;
        if (!isNullOrUndefined(this.newCallForm.get('bridgeContact').value)) {
            if (this.newCallForm.get('bridgeContact').value == "-1") {
                if (this.newCallForm.get('txtCustomPhone').value.trim() != "") {
                    var temptxtCustom = this.newCallForm.get('txtCustomPhone').value.replace(/[-,. ext ()]/g, charactersToReplace => ({ '-': '', ',': '', '.': '', 'ext': '', ' ': '', '(': '', ')': '' })[charactersToReplace]);
                    if (temptxtCustom.length == 10) {
                        this.lblCallToUserNumber = temptxtCustom;
                        this.lblUserPhone = this.newCallForm.get('txtCustomPhone').value;
                        blnGood = true;
                    }
                    else
                        this.messageCallHeading = 'Please enter a 10 digit phone number.';
                }
                else
                    this.messageCallHeading = 'Please select or enter a the number to use for this call.';
            }
            else {
                this.lblCallToUserNumber = this.newCallForm.get('bridgeContact').value;
                this.lblUserPhone = this.ddUserBPhone.find(x => x.value == (this.newCallForm.get('bridgeContact').value))?.text;
                blnGood = true;
            }

            if (blnGood)
                this.createVoiceCall();
        }
        else
            this.messageCallHeading = "Please select a Phone to call with.";
    }

    createVoiceCall() {
        var toContact = this.newCallForm.get('toContact').value;
        if (!isNullOrUndefined(toContact) && toContact.length == 10) {
            this.copyVoiceCall();
            this.onCreateVoiceCall();

        }
        else
            this._notifyService.showError('To contact requires 10 digit mobile number ', '', 2000);
    }

    async onCreateVoiceCall() {
        this.buttonTypeOperation = eButtonActions.Save;
        await this.voiceCallService.saveVoiceCall(this.encryptedUser, this.voiceCallFieldMappings)
            .then(async (result: VoiceCallModel) => {
                if (!isNullOrUndefined(result)) {
                    this.buttonTypeOperation = eButtonActions.None;
                    this.voiceCallID = result.messageInt;
                    if (this.isScheduled) {
                        this._notifyService.showSuccess('Call has been scheduled successful.', '', 3000);
                        this.messageCallHeading = 'Call has been scheduled successful.';
                        this.viewMode = eVoiceCall.ScheduleCall;
                        this.vieModeSet();
                        this.hideBtnSave = false;
                        this.hideBtnlnkClose = false;
                        this.tmWait = false;
                    }
                    else if (result.messageBool) {
                        this._notifyService.showSuccess('Call has been initiated to (' + this.contactData?.firstName + ' ' + this.contactData?.lastName + ') ' + this.labelToContactNumber + '.', '', 3000);
                        this.messageCallHeading = "Call has been initiated to " + this.labelToContactNumber + ".";
                        this.viewMode = eVoiceCall.ConnectImmediately
                        this.vieModeSet();
                        this.closeModal();
                    }
                    else {
                        this._notifyService.showError(result.messageString, '', 3000);
                        this.closeModal();
                    }
                    //this.updatedCall?.emit(false);
                    this.buttonTypeOperation = eButtonActions.None;
                }
                else {
                    this.buttonTypeOperation = eButtonActions.None;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-new-call.onCreateVoiceCall", err.message, this.voiceCallFieldMappings,'');
                this.buttonTypeOperation = eButtonActions.None;
                this._utilityService.handleErrorResponse(err);
            });
    }

    copyVoiceCall() {
        this.voiceCallFieldMappings = <VoiceCallModel>{};
        this.voiceCallFieldMappings.clpCompanyID = this.contactData.cLPCompanyID;
        this.voiceCallFieldMappings.clpUserID = this.user.cLPUserID;
        this.voiceCallFieldMappings.contactID = this.contactData.contactID;
        this.voiceCallFieldMappings.callID = '';
        this.voiceCallFieldMappings.fromNumber = this.newCallForm.get('bridgeContact').value;
        this.voiceCallFieldMappings.toNumber = this.newCallForm.get('toContact').value;
        this.voiceCallFieldMappings.preCallScript = this.newCallForm.get('preCallScript').value;
        this.voiceCallFieldMappings.status = eVoiceCallStatus.Pending;
        this.voiceCallFieldMappings.isScheduled = false;
        this.lblScheduledCallTime = "Immediately";
        this.voiceCallFieldMappings.duration = 0;
        if (this.newCallForm.get('callTime').value && this.isScheduled)
            this.voiceCallFieldMappings.dtStart = this.datepipe.transform(this.newCallForm.get('callTime').value, 'MM/dd/yyyy HH:mm:ss');
        else
            this.voiceCallFieldMappings.dtStart = null;
        /*= this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm:ss');*/
    }

    ddUserBPhoneSelectedIndexChanged(evt) {
        if (this.newCallForm.get('bridgeContact').value == '-1') {
            this.hideDDUserBPhone = false;
            this.hidetTxtCustomPhone = true;
            this.newCallForm.get('txtCustomPhone')?.setValue('');
            this.messageCallHeading = 'Enter a custom number to use for this call.';
        }
        else {
            this.hideDDUserBPhone = true;
            this.hidetTxtCustomPhone = false;
            var indexSelected = evt.selectedIndex;
            var ddValue = this.ddUserBPhone[indexSelected - 1];
            this.lblUserPhone = ddValue?.text;
        }
    }

    closeModal() {
        this.updatedCall?.emit(false);
    }

    async voiceCallDelete() {
        await this.voiceCallService.voiceCallDelete(this.encryptedUser, this.voiceCallID)
            .then(async (result: VoiceCallModel) => {
                if (!isNullOrUndefined(result)) {
                    this._notifyService.showSuccess('Call has been deleted successful.', '', 3000);
                    this.updatedCall?.emit(true);
                    this.closeModal()
                }
                else
                    this.buttonTypeOperation = eButtonActions.None;
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-new-call.voiceCallDelete", err.message, null,
                    ' voiceCallID: ' + this.voiceCallID
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async saveVoiceCall() {
        this.buttonTypeOperation = eButtonActions.Save;
        this.voiceCallFieldMappings.clpCompanyID = this.contactData.cLPCompanyID;
        this.voiceCallFieldMappings.clpUserID = this.user.cLPUserID;
        this.voiceCallFieldMappings.contactID = this.contactData.contactID;
        this.voiceCallFieldMappings.callID = '';
        this.voiceCallFieldMappings.fromNumber = this.newCallForm.get('txtCustomPhone').value;
        this.voiceCallFieldMappings.toNumber = this.newCallForm.get('toContact').value;
        this.voiceCallFieldMappings.preCallScript = this.newCallForm.get('preCallScript').value;
        this.voiceCallFieldMappings.status = eVoiceCallStatus.Pending;
        this.voiceCallFieldMappings.isScheduled = false;
        this.voiceCallFieldMappings.duration = 0;
        if (this.newCallForm.get('callTime').value && this.isScheduled) {
            this.voiceCallFieldMappings.dtStart = this.datepipe.transform(this.newCallForm.get('callTime').value, 'MM/dd/yyyy HH:mm:ss');
        }
        else {
            this.voiceCallFieldMappings.dtStart = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm:ss');
        }
        this.voiceCallFieldMappings.callNotes = this.newCallForm.get('callNotes').value;
        await this.voiceCallService.saveVoiceCall(this.encryptedUser, this.voiceCallFieldMappings)
            .then(async (result: VoiceCallModel) => {
                if (!isNullOrUndefined(result)) {
                    this.closeModal()
                }
                else
                    this.buttonTypeOperation = eButtonActions.None;
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-new-call.saveVoiceCall", err.message, this.voiceCallFieldMappings,'');
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getVoiceCallStatus(status) {
        let statusStr = ''
        switch (status) {
            case 0:
                statusStr = 'Completed'
                break;
            case 1:
                statusStr = 'Pending'
                break;
            case 2:
                statusStr = 'In Progress'
                break;
            case 3:
                statusStr = 'Awaiting Answer'
                break;
            case 4:
                statusStr = 'Check In Progress'
                break;
            case 5:
                statusStr = 'Failed'
                break;
            case 6:
                statusStr = 'Initiated'
                break;
            case 7:
                statusStr = 'Ended'
                break;
            case 8:
                statusStr = 'Missed'
                break;
            case 9:
                statusStr = 'To Be Deleted'
                break;
        }

        return statusStr
    }
}


