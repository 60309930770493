import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../shared/utils.js';
import { isNullOrUndefined } from 'util';
import { CLPUser, DDFields, DDFieldsResponse, UserResponse } from '../../models/clpuser.model';
import { ContactList } from '../../models/contact.model';
import { EmailSnippet, EmailSnippetListResponse, EmailSnippetResponse, EmailTemplate, EmailTemplateListResponse, EmailTemplateLoad, MailMergeTemplate, MailMergeTemplateListResponse } from '../../models/emailTemplate.model';
import { eDDField, eFeatures, eMailingCategory, eMailingFromType, eMailingStatus, eUserRole } from '../../models/enum.model';
import { DropDownItem, SimpleResponse } from '../../models/genericResponse.model';
import { EmailingSaveRequest, Mailing, MailingSaveRequest, subjectsArray } from '../../models/mailing.model';
import { MailingContact } from '../../models/mailingContact.model';
import { RoleFeaturePermissions } from '../../models/roleContainer.model';
import { ContactService } from '../../services/contact.service';
import { EmailBlastService } from '../../services/email-blast.service';
import { EmailTemplateService } from '../../services/email-template.service';
import { ContactCommonSearchService } from '../../services/shared/contact-common-search.service';
import { ContactSearchService } from '../../services/shared/contact-search.service';
import { GridConfigurationService } from '../../services/shared/gridConfiguration.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import { OutBoundEmailService } from '../../services/outBoundEmail.service';
import { DatePipe } from '@angular/common';
import { MyDocumentService } from '../../services/my-document.service';
import { Document, DocumentListResponse } from '../../models/document';
import { AccountSetupService } from '../../services/accountSetup.service';
import { ClpCompany, CompanyResponse } from '../../models/company.model';
import { SearchQueryResponse } from '../../models/search.model';
/*import { DropDownItem } from '../../models/location-manager.model';*/
import { GlobalService } from '../../services/global.service';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-email-blast',
    templateUrl: './email-blast.component.html',
    styleUrls: ['./email-blast.component.css'],
    providers: [GridConfigurationService, ContactCommonSearchService]
})
export class EmailBlastComponent {

    isProcess: boolean = false;
    queryDataLoaded: SearchQueryResponse = <SearchQueryResponse>{};
    eUserRole = eUserRole;
    movedEmailMailingData: ContactList[] = [];
    public mailMergeTemplateList: MailMergeTemplate[];
    emailingSaveRequest = <EmailingSaveRequest>{};
    mailingData = <Mailing>{};
    public step: number = 1;
    isShowTxtBody: boolean = true;
    mailingId: number = 0;
    emailSnippetList: EmailSnippet[];
    showSpinner: boolean = false;
    roleFeaturePermissions: RoleFeaturePermissions;
    user: CLPUser;
    private encryptedUser: string = '';
    userResponse: UserResponse;
    gridHeight;
    dateFormat: string = "MM/dd/yyyy";
    subjectList: any[] = [{ date: new Date(), subject: '', ddTime: '4:00 AM' }];
    emailBlastForm: FormGroup;
    documentList: any[] = [];
    public emailTemplateList: EmailTemplate[];
    mobileColumnNames: string[];
    isInvalidSubject: boolean = false;
    subjectWarning: string = '';
    htmlDisplay: string = '';
    public steps: any = { hour: 1, minute: 60 };
    companyData: ClpCompany = <ClpCompany>{};
    isShowLinkedDocs: boolean = false;
    ddMailingTypeCode: DDFields[] = [];
    documents: Document[] = [];
    mailingOwnerDD: DropDownItem;
    constructor(@Inject('BASE_URL') _baseUrl: string,
        public _contactService: ContactService,
        private datepipe: DatePipe,
        private _outBoundEmailService: OutBoundEmailService,
        private _emailBlastService: EmailBlastService,
        private _utilityService: UtilityService,
        private _documentService: MyDocumentService,
        public _localService: LocalService,
        private fb: FormBuilder,
        private _router: Router,
        private route: ActivatedRoute,
        private _emailTemplateService: EmailTemplateService,
        public _gridCnfgService: GridConfigurationService,
        public _contactSearchService: ContactSearchService,
        public _myDocumentService: MyDocumentService,
        private _accountSetupService: AccountSetupService,
        private _globalService: GlobalService,
        private userService: UserService) {
        this._localService.isMenu = true;
        this.route.paramMap.subscribe(async params => {
            if (params.has('id')) {
                this.mailingId = +params.get('id');
            } else
                this.mailingId = 0;
        });
    }

    apiResponse(event) {
        if (!isNullOrUndefined(event.body)) {
        }
    }

    ngOnInit() {

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.dateFormat = this.user.dateFormat;
                        this.getCompanyData(() => {
                            if (!this.companyData?.secMarketingTool) {
                                if (this.user?.userRole == eUserRole.General)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        })
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    async goToNext(id) {
        switch (id) {
            case 1:
                this.emailBlastForm = this.prepareEmailBlastForm();
                if (this.mailingId > 0)
                    this.loadMailingData();
                this.setUp();
                if (this.user?.emailTemplateID > 0)
                    this.loadEmailTemplate(this.user?.emailTemplateID);
                this.step = 2;
                //if ($(window).width() < 768)
                //  this.contactDocConfig.theme = '';
                break;
            case 2:
                this.emailBlastForm.markAllAsTouched();
                await this.getDocumentListByDocIds();
                if (this.emailBlastForm.valid)
                    this.checkSubjectListValid();
                break;
            case 3:
                this.emailingFinishAndConfirm();
                break;
        }
    }

    async loadMailingData() {
        await this._emailBlastService.mailingLoadByMailingId(this.encryptedUser, this.mailingId)
            .then(async (result: Mailing) => {
                if (!isNullOrUndefined(result)) {
                    this.mailingData = UtilityService.clone(result);
                    this.patchEmailBlastForm();
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    setUp() {
        if (this.companyData?.isHTMLEmailIncluded) {
            this.getEmailTemplateList();
            this.mailMergeTemplate_GetList();
            this.bindMailingOwnerDD()
            this.getEmailSnippetList();
            this.getDDFieldList();
        }
    }

    async getCompanyData(callback) {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.companyData = response.company;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
        callback();
    }

    async getDDFieldList() {
        await this.userService.getDDFieldList(this.encryptedUser, eDDField.MailingTypeCode, this.user?.cLPCompanyID)
            .then(async (result: DDFieldsResponse) => {
                if (!isNullOrUndefined(result))
                    this.ddMailingTypeCode = UtilityService.clone(result?.lstDDFields);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    isShowEmailTemplateOption() {
        if (this.user?.cLPCompanyID >= 1341)
            return false;
        else if (this.user?.cLPCompanyID == 959 || this.user?.cLPCompanyID == 802 || this.user?.cLPCompanyID == 294 || this.user?.cLPCompanyID == 1226 ||
            this.user?.cLPCompanyID == 184 || this.user?.cLPCompanyID == 1167 || this.user?.cLPCompanyID == 1220 || this.user?.cLPCompanyID == 1321 || this.user?.cLPCompanyID == 1285)
            return false;
        else
            return true;
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.CreateNewEmailBlastMailing)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse?.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("email-blast.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getEmailTemplateList() {
        await this._outBoundEmailService.emailTemplate_GetList(this.encryptedUser, this.user.cLPCompanyID, true, this.user?.cLPUserID)
            .then(async (result: EmailTemplateListResponse) => {
                if (!isNullOrUndefined(result))
                    this.emailTemplateList = UtilityService.clone(result?.emailTemplateList);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async mailMergeTemplate_GetList() {
        await this._outBoundEmailService.mailMergeTemplate_GetList(this.encryptedUser, this.user.cLPCompanyID, this.user?.cLPUserID, true)
            .then(async (result: MailMergeTemplateListResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.mailMergeTemplateList = response?.mailMergeTemplateList;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getEmailSnippetList() {
        await this._outBoundEmailService.getEmailSnippetList(this.encryptedUser, this.user?.cLPUserID)
            .then(async (result: EmailSnippetListResponse) => {
                var response = UtilityService.clone(result);
                if (!isNullOrUndefined(response)) {
                    this.emailSnippetList = response.emailSnippetList;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    prepareEmailBlastForm() {
        return this.fb.group({
            mailingTypeCode: new FormControl(0),
            fromType: new FormControl(0),
            toType: new FormControl(0),
            salutation: new FormControl(0),
            mailMergeTemplateID: new FormControl(0),
            rddMailingOwner: new FormControl(0),
            emailTemplateID: new FormControl(this.user?.emailTemplateID > 0 ? this.user?.emailTemplateID : ""),
            score: new FormControl(0, [Validators.max(100), Validators.min(-100), Validators.required]),
            body: new FormControl(this.user?.defaultSignature ?? ""),
        })
    }

    patchEmailBlastForm() {
        this.emailBlastForm.get("mailingTypeCode").setValue(this.mailingData?.mailingTypeCode);
        this.emailBlastForm.get("fromType").setValue(this.mailingData?.fromType);
        this.emailBlastForm.get("toType").setValue(this.mailingData?.toType);
        this.emailBlastForm.get("salutation").setValue(this.mailingData?.salutation);
        this.emailBlastForm.get("mailMergeTemplateID").setValue(this.mailingData?.mailMergeTemplateID);
        this.emailBlastForm.get("score").setValue(this.mailingData?.score);
        this.emailBlastForm.get("body").setValue(this.mailingData?.body);

        this.subjectList = [];
        this.subjectList.push(
            {
                date: new Date(new Date(this.mailingData?.mailingStartTime).getFullYear(), new Date(this.mailingData?.mailingStartTime).getMonth(), new Date(this.mailingData?.mailingStartTime).getDate() + 1),
                ddTime: this.datepipe.transform(new Date(this.mailingData?.mailingStartTime), "h:mm a"), subject: this.mailingData?.subject
            });
    }

    async copyEmailBlastFormValueToData() {
        this.mailingData.cLPUserID = this.user.cLPUserID;
        this.mailingData.cLPCompanyID = this.user.cLPCompanyID;
        this.mailingData.category = eMailingCategory.Email;
        this.mailingData.salutation = this.emailBlastForm.controls.salutation.value;
        this.mailingData.body = this.emailBlastForm.controls.body.value;
        this.mailingData.documentList = "";
        this.documentList.forEach(item => {
            this.mailingData.documentList += item + ',';
        })
        this.mailingData.documentList = this.mailingData.documentList.substring(0, this.mailingData.documentList.length - 1);
        this.mailingData.mailMergeTemplateID = this.emailBlastForm.controls.mailMergeTemplateID.value;
        this.mailingData.emailTemplateID = this.emailBlastForm.controls.emailTemplateID.value;
        this.mailingData.mailingTypeCode = this.emailBlastForm.controls.mailingTypeCode.value;
        if (this.companyData?.blnMailingFromType)
            this.mailingData.fromType = this.emailBlastForm.controls.fromType.value;
        else
            this.mailingData.fromType = eMailingFromType.MailingOwner;

        this.mailingData.toType = this.emailBlastForm.controls.toType.value;
        this.mailingData.customSearchID = 0;
        this.mailingData.score = this.emailBlastForm.controls.score.value;

        if (this.mailingData.mailMergeTemplateID > 0)
            this.mailingData.status = eMailingStatus.Awaiting_MMDoc;
        else
            this.mailingData.status = eMailingStatus.Pending;


        /* ----------- mailingContacts----------------*/
        var mailingContactList: MailingContact[] = [];
        await this.movedEmailMailingData.forEach((item) => {
            var mailingContact = <MailingContact>{};
            mailingContact.contactID = item?.contactID;
            mailingContact.fromAddress = item?.address;
            mailingContact.mailingID = this.mailingId;
            mailingContactList.push(mailingContact);
        });

        var selectedSubjectsArray: subjectsArray[] = [];
        await this.subjectList.forEach((item) => {
            var subjectArray: subjectsArray = <subjectsArray>{};
            const strDate = this.datepipe.transform(item?.date, 'MM/dd/yyyy') + ' ' + item?.ddTime;
            this.mailingData.mailingStartTime = new Date(strDate);
            const date = this.datepipe.transform(this.mailingData.mailingStartTime, 'MMddyyyy HHmmssa');
            subjectArray.strMailingStartTime = date;
            subjectArray.subject = item?.subject;
            selectedSubjectsArray.push(subjectArray);
        })

        this.emailingSaveRequest.mailing = this.mailingData;
        this.emailingSaveRequest.mailingContacts = mailingContactList;
        this.emailingSaveRequest.subjectsArray = selectedSubjectsArray;
        this.emailingSaveRequest.processAll = this.isProcess;
        this.emailingSaveRequest.customSearchID = 0;
        this.emailingSaveRequest.searchQueryResponse = this.queryDataLoaded;
    }

    async emailingFinishAndConfirm() {
        await this._emailBlastService.emailingFinishAndConfirm(this.encryptedUser, this.emailingSaveRequest)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (response?.messageBool) {
                        this.mailingId = response?.messageInt;
                        this.step = 4;
                    }
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async saveAllMailingContacts() {
        var mailingContactList: MailingContact[] = [];
        await this.movedEmailMailingData.forEach((item) => {
            var mailingContact = <MailingContact>{};
            mailingContact.contactID = item?.contactID;
            mailingContact.fromAddress = item?.address;
            mailingContact.mailingID = this.mailingId;
            mailingContactList.push(mailingContact);
        });
        this.saveMailingContact(mailingContactList);
    }

    async saveMailingContact(mailingContactList) {
        await this._emailBlastService.saveMailingContact(this.encryptedUser, mailingContactList)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    checkSubjectListValid() {
        this.subjectWarning = '';
        this.isInvalidSubject = false;
        const now = new Date().setHours(0, 0, 0, 0);
        this.subjectList.forEach((item, index) => {
            if (isNullOrUndefined(item?.date)) {
                this.isInvalidSubject = true;
                this.subjectWarning = this.subjectWarning + (this.subjectList.length > 1 ? ('<br>&bull; Please select a Date for mailing ' + (index + 1) + '.') :
                    ('<br>&bull; Please select a Date for this mailing.'));
            } else if (item.date < now) {
                this.isInvalidSubject = true;
                this.subjectWarning = this.subjectWarning + (this.subjectList.length > 1 ?
                    (`<br>&bull; The Date for mailing ${index + 1} cannot occur in the past.`) :
                    ('<br>&bull; The Date cannot occur in the past.'));
            }
            if (isNullOrEmptyString(item?.subject) && item.subject.trim() == '') {
                this.isInvalidSubject = true;
                this.subjectWarning = this.subjectWarning + (this.subjectList.length > 1 ?
                    ('<br>&bull; Please enter a Subject Line for mailing ' + (index + 1) + '.') :
                    ('<br>&bull; Please enter a Subject Line for this mailing.'));
            }
        })
        if (this.emailBlastForm.controls.emailTemplateID.value == 0) {
            this.isInvalidSubject = true;
            this.subjectWarning = this.subjectWarning + ('<br>&bull; Please select an HTML template.');
        }
        if (!this.isInvalidSubject) {
            this.copyEmailBlastFormValueToData()
            this.step = 3;
        }
    }

    addSubjectLine() {
        const preDate: Date = this.subjectList[this.subjectList.length - 1].date;
        if (!isNullOrUndefined(preDate))
            this.subjectList.push({ date: new Date(preDate.getFullYear(), preDate.getMonth(), preDate.getDate() + 1), ddTime: '4:00 AM', subject: '' });
    }

    deleteSubject(index) {
        this.subjectList.splice(index, 1);
    }

    getDisplayData(type: string, value: number) {
        switch (type) {
            case 'emailTemplate':
                var eTemplate = this.emailTemplateList?.filter(item => item.emailTemplateID == value);
                return eTemplate ? eTemplate[0]?.templateName ? eTemplate[0]?.templateName : '-None Selected-' : '-None Selected-';
            case 'mailMergeTemplate':
                var mailMergeTemplate = this.mailMergeTemplateList?.filter(item => item.mailMergeTemplateID == value);
                return mailMergeTemplate ? mailMergeTemplate[0]?.templateName ? mailMergeTemplate[0].templateName : '-Select One-' : '-Select One-';
            case 'mailingType':
                var mailType = this.ddMailingTypeCode?.filter(item => item.classCode == value);
                return mailType ? mailType[0]?.display ? mailType[0].display : '-None Selected-' : '-None Selected-';
            case 'emailTemplateHtml':
                var eTemplateHtml = this.emailTemplateList?.filter(item => item.emailTemplateID == value);
                return eTemplateHtml ? eTemplateHtml[0]?.htmlText : '';
        }
    }

    loadEmailTemplate(emailTemplateId) {
        this._emailTemplateService.getEmailTemplateById(this.encryptedUser, this.emailBlastForm?.controls?.emailTemplateID?.value)
            .then(async (result: EmailTemplateLoad) => {
                if (!isNullOrUndefined(result))
                    this.htmlDisplay = UtilityService.clone(result?.htmlText);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async downloadDocuments(dataItem) {
        await this._myDocumentService.downloadDocuments(this.encryptedUser, dataItem?.documentId)
            .then(async (result: Document[]) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    const fileType = response[0].documentName.split('.');
                    const file = new Blob([response[0].bytes], { type: fileType[1] });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(file);
                    link.download = response[0].documentName;
                    link.click();
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getAttachedList(value: any) {
        this.documentList = value;
    }


    async getDocumentListByDocIds() {
        await this._documentService.getDocumentListByDocIds(this.encryptedUser, this.documentList)
            .then(async (result: DocumentListResponse) => {
                if (!isNullOrUndefined(result))
                    this.documents = UtilityService.clone(result?.documents);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async onChangeEmailSnippet(event: any) {
        if (!isNullOrEmptyString(event?.target?.value)) {
            await this._outBoundEmailService.loadEmailSnippet(this.encryptedUser, event?.target?.value).
                then(async (result: EmailSnippetResponse) => {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        this.emailBlastForm.get("body").setValue(response.emailSnippet.snippetText);
                        this.isShowTxtBody = false;
                        /* this.bindPreview();*/
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        } else {
            this.emailBlastForm.get("body").setValue(this.user?.defaultSignature);
            this.isShowTxtBody = true;
        }
    }

    async onChangeEmailTemplate(value) {
        if (!isNullOrEmptyString(value)) {
            if (+value > 0)
                this.loadEmailTemplate(+value);
        }
        this.bindPreview();
    }

    bindPreview() {
        if (!isNullOrEmptyString(this.emailBlastForm.controls.emailTemplateID.value)) {
            if (this.emailBlastForm.controls.emailTemplateID.value > 0)
                this.loadEmailTemplate(+this.emailBlastForm.controls.emailTemplateID.value);
            else
                this.htmlDisplay = "";
        }
        else
            this.htmlDisplay = "";
    }

    onAddEmoji(event, index) {
        let value = event.target.value
        this.subjectList[index].subject = this.subjectList[index].subject + value;
    }

    bindMailingOwnerDD(OfficeCode?, teamCode?, permissions?) {
        this._emailBlastService.bindMailingOwnerDD(this.encryptedUser, this.user?.cLPCompanyID, OfficeCode, teamCode, permissions)
            .then((result: DropDownItem) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this.mailingOwnerDD = response
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    setIsProcess(event) {
        this.isProcess = event;
    }
}

