<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"> <span class="contact-create-header">Zapier Settings</span></div>
            </div>
            <div class="global-body-section">
                <div>
                    <div class="admin-row-flex">
                        <div class="admin-row-colunm">
                            <div class="wraper-main-section">
                                <div class="global-card-section">
                                    <div class="global-header-section">
                                        <div class="svg-icon-panel"><img src="../../../../assets/ddfieldtitle.svg" class="mr-1" />Zapier Event</div>
                                        <div class="header-button-panel">
                                            <div class="button-wrapper">
                                                <button type="button" [matTooltip]="isExpandedZapier ? 'Collapse' : 'Expand'" class="icon-btn" (click)="isExpandedZapier=!isExpandedZapier">
                                                    <i class="fa " [ngClass]="{ 'fa-chevron-up': isExpandedZapier, 'fa-chevron-down': !isExpandedZapier }"></i>
                                                    <span class="btn-text">Expand</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="margin-all-area">
                                        <div class="wraper-main-section">
                                            <div class="global-card-section" *ngIf="isExpandedZapier">
                                                <div class="common-inner-header">
                                                    <div class="inner-header-bg">
                                                        <div class="inner-cards-panel header-inner-hidden">
                                                            <span>Order</span>
                                                            <span>Display</span>
                                                            <span class="" [hidden]="roleFeaturePermissions?.edit == false && roleFeaturePermissions?.delete == false">Action</span>
                                                        </div>
                                                        <div class="inner-detail-bg">
                                                            <span>Details</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="global-body-section" *ngIf="zapierCodeEdit==1">
                                                    <div class="wraper-body-panel" [formGroup]="zapierForm">
                                                        <div class="wraper-body-left">
                                                            <div class="common-inner-cards">
                                                                <div cdkDropList
                                                                     #frmList="cdkDropList"
                                                                     [cdkDropListData]="zapierFormCtls.controls"
                                                                     (cdkDropListDropped)="dropZapier($event)">
                                                                    <div formArrayName="zapierConfigs" class="inner-cards-grid-height">
                                                                        <div *ngFor="let name of zapierFormCtls.controls;trackBy:identifyTeam; let i = index">
                                                                            <div *ngIf="i>=skipSize && i<skipSize+pageSize" [formGroupName]="i" cdkDrag>
                                                                                <div class="inner-cards-grid">
                                                                                    <div class="inner-card-mobile">
                                                                                        <label>Order</label>
                                                                                        <div class="mobile-view-design">
                                                                                            <div class="" [matTooltip]="_localService.matTooltipSetting.msg" [matTooltipPosition]="_localService.matTooltipSetting.position" [matTooltipShowDelay]="_localService.matTooltipSetting.delay">
                                                                                                {{i+1}}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="inner-card-mobile">
                                                                                        <label>Display</label>
                                                                                        <div class="mobile-view-design">
                                                                                            <div *ngIf="!sortZapierMode" class="" [matTooltip]="_localService.matTooltipSetting.msg" [matTooltipPosition]="_localService.matTooltipSetting.position" [matTooltipShowDelay]="_localService.matTooltipSetting.delay">
                                                                                                <input [ngClass]="{'has-error': zapierFormCtls.controls[i].invalid && (zapierFormCtls.controls[i].touched || zapierFormCtls.controls[i].dirty)}" formControlName="display" placeholder="Enter display" class="form-control" *ngIf="editRowIndex == -1" required (mousedown)="$event.stopPropagation()"
                                                   (touchstart)="$event.stopPropagation()">
                                                                                                <input [ngClass]="{'has-error': zapierFormCtls.controls[i].invalid && (zapierFormCtls.controls[i].touched || zapierFormCtls.controls[i].dirty)}" formControlName="display" placeholder="Enter display" class="form-control" *ngIf="editRowIndex > -1 && editRowIndex == i" required (mousedown)="$event.stopPropagation()"
                                                   (touchstart)="$event.stopPropagation()">
                                                                                                <span *ngIf="editRowIndex > -1 && editRowIndex != i">{{name.value.display}}</span>
                                                                                            </div>
                                                                                            <div *ngIf="sortZapierMode" class="" [matTooltip]="_localService.matTooltipSetting.msg" [matTooltipPosition]="_localService.matTooltipSetting.position" [matTooltipShowDelay]="_localService.matTooltipSetting.delay">
                                                                                                {{name.value.display}}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class=""></div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="team-example-box" *ngIf="zapierFormCtls.controls?.length === 0"><h6>No records found</h6></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="global-body-section" *ngIf="zapierCodeEdit==0">
                                                    <div class="wraper-body-panel" [formGroup]="zapierForm">
                                                        <div class="wraper-body-left">
                                                            <div class="common-inner-cards">
                                                                <div cdkDropList
                                                                     #frmList="cdkDropList"
                                                                     [cdkDropListData]="zapierFormCtls.controls"
                                                                     (cdkDropListDropped)="dropZapier($event)">
                                                                    <div formArrayName="zapierConfigs" class="inner-cards-grid-height">
                                                                        <div class="" *ngFor="let i of  zapierFormCtls.controls;trackBy:identifyZapier;let idx=index;">
                                                                            <div *ngIf="idx>=skipSize && idx<skipSize+pageSize" [formGroupName]="idx" cdkDrag>
                                                                                <div class="inner-cards-grid">
                                                                                    <div class="inner-card-mobile">
                                                                                        <label>Order</label>
                                                                                        <div class="mobile-view-design">
                                                                                            <div class="" [matTooltip]="_localService.matTooltipSetting.msg" [matTooltipPosition]="_localService.matTooltipSetting.position" [matTooltipShowDelay]="_localService.matTooltipSetting.delay">
                                                                                                {{idx+1}}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="inner-card-mobile">
                                                                                        <label>Display</label>
                                                                                        <div class="mobile-view-design">
                                                                                            <div class="" [matTooltip]="_localService.matTooltipSetting.msg" [matTooltipPosition]="_localService.matTooltipSetting.position" [matTooltipShowDelay]="_localService.matTooltipSetting.delay">
                                                                                                {{i.value.display}}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="inner-card-mobile">
                                                                                        <label>Action</label>
                                                                                        <div class="mobile-view-design">
                                                                                            <button type="button" [hidden]="roleFeaturePermissions?.edit == false" class="grid-common-btn" [disabled]="sortZapierMode || showSpinner" (click)="zapierItemtoEdit(idx)"> <i class="fa fa-pencil" aria-hidden="true" title="Edit"></i></button>
                                                                                            <button type="button" [hidden]="roleFeaturePermissions?.delete == false" class="grid-delete-btn" [disabled]="sortZapierMode || showSpinner" (click)="zapierItemtoDelete(idx)" data-toggle="modal" data-target="#zapierDeleteModal"> <i class="fa fa-trash-alt" aria-hidden="true" title="Delete"></i></button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="team-example-box" *ngIf="zapierFormCtls.controls?.length === 0"><h6>No records found</h6></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="reloadZapierCodes">
                                                    <app-pagination *ngIf="zapierEvents" (newData)="emitPagination($event)" [teamOffice]="'team'" [total]="zapierEvents.length" [data]="zapierEvents" [originalData]="originalZapierCodes"></app-pagination>
                                                </div>
                                                <div class="margin-all-area" *ngIf="zapierCodeEdit==1">
                                                    <button class="btn btn-primary" [disabled]="showSpinner || zapierFormCtls?.length<=0 || zapierFormCtls.invalid" (click)="saveZapierItems()" type="submit"> {{ zapierCodeEdit==1 ? 'Update' : zapierCodeEdit==2 ?  'Save' : 'Save' }}</button>
                                                    <button class="btn btn-cancel" (click)="cancelZapierCode()"> Cancel</button>
                                                </div>
                                                <div class="margin-all-area" *ngIf="!sortZapierMode && zapierCodeEdit==0">
                                                    <button class="btn btn-primary" (click)="editZapierCodes()" [hidden]="roleFeaturePermissions?.edit == false" [disabled]="zapierFormCtls.controls?.length<=0">
                                                        <!-- <i class="fa-star" [ngClass]="{ 'fas ': zapierCodeEdit, far: !zapierCodeEdit }"></i>-->Edit All Items
                                                    </button>
                                                    <button class="btn btn-primary" (click)="zapierCodeEdit = 2; scrollToNewZapier()" [hidden]="roleFeaturePermissions?.create == false"> Add Items</button>
                                                    <button class="btn btn-primary" (click)="sortAlphaZapierEvent()" [hidden]="roleFeaturePermissions?.edit == false" [disabled]="zapierFormCtls.controls?.length<=0"> Sort Alphabetically</button>
                                                </div>
                                                <div class="margin-all-area" *ngIf="sortZapierMode && zapierCodeEdit==0">
                                                    <button class="btn btn-primary" [disabled]="showSpinner" (click)="saveZapierItems(); cancleTeam = true;" type="submit">{{ zapierCodeEdit==1 ? 'Update' : zapierCodeEdit==2 ?  'Save' : 'Save' }}</button>
                                                    <button class="btn btn-cancel" (click)="cancelZapierCode()"> Cancel</button>
                                                </div>
                                                <div class="modal fade" id="zapierDeleteModal" tabindex="-1" role="dialog" aria-labelledby="zapierDeleteModalLabel" aria-hidden="true" *ngIf="zapierCodeEdit==0">
                                                    <div class="modal-dialog  modal-common-dialog" role="document">
                                                        <div class="modal-content modal-common-content">
                                                            <div class="modal-header modal-common-background">
                                                                <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
                                                            </div>
                                                            <div class="modal-body modal-common-body">
                                                                <h5>Are you sure to delete this Zapier record - <span style="font-weight:600;"> {{zapierCodeDisplay}} </span>?</h5>
                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" (click)="deleteZapierItems()" data-dismiss="modal" class="btn btn-primary">Confirm</button>
                                                                <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="isExpandedZapier && zapierCodeEdit==2 ">
                                                    <div>
                                                        <div>
                                                            <div class="common-inner-header">
                                                                <div class="inner-header-bg">
                                                                    <div class="inner-cards-panel header-inner-hidden">
                                                                        <span>Order</span>
                                                                        <span>Display</span>
                                                                        <span>Code</span>
                                                                        <span class="text-right"></span>
                                                                    </div>
                                                                    <div class="inner-detail-bg">
                                                                        <span>Details</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="global-body-section">
                                                                <div class="wraper-body-panel" [formGroup]="zapierForm">
                                                                    <div class="wraper-body-left">
                                                                        <div class="common-inner-cards">
                                                                            <div formArrayName="zapierConfigs" class="inner-cards-grid-height">
                                                                                <div class="" *ngFor="let i of  zapierFormCtls.controls;trackBy:identifyTeam; let idx=index;">
                                                                                    <div *ngIf="idx>=skipSize && idx<skipSize+pageSize">
                                                                                        <div class="inner-cards-grid">
                                                                                            <div class="inner-card-mobile">
                                                                                                <label>Order</label>
                                                                                                <div class="mobile-view-design">
                                                                                                    <div class="">{{idx+1}}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="inner-card-mobile">
                                                                                                <label>Display</label>
                                                                                                <div class="mobile-view-design">
                                                                                                    <div class="">{{i.value.display}}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="inner-card-mobile">
                                                                                                <label>Code</label>
                                                                                                <div class="mobile-view-design">
                                                                                                    <div class="">{{i.value.code}}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="inner-card-mobile">
                                                                                                <label>Action</label>
                                                                                                <div class="">
                                                                                                    <div class=""></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="team-example-box" *ngIf="zapierFormCtls.controls?.length === 0"><h6>No records found</h6></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="wraper-body-left" id="scrollId">
                                                                    <div class="cards-body-section">
                                                                        <div class="cards-colunm-left">
                                                                            <span class="control-label" for="teamSName">New Item List </span>
                                                                        </div>
                                                                        <div class="cards-colunm-right">
                                                                            <textarea [(ngModel)]="zapierCodeData" type="text" maxlength="250" class="form-control" id="teamSName"></textarea>
                                                                            <label><span class="text-danger">Note: Do not exceed 25 lines.</span></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <app-pagination *ngIf="zapierEvents" (newData)="emitPagination($event)" [teamOffice]="'team'" [total]="zapierEvents.length" [data]="zapierEvents" [originalData]="originalZapierCodes"></app-pagination>
                                                            <div class="margin-all-area">
                                                                <button class="btn btn-primary" [disabled]="showSpinner" (click)="saveBulkZapierEvents()" type="submit">{{ zapierCodeEdit==1 ? 'Update' : zapierCodeEdit==2 ?  'Save' : 'Save' }}</button>
                                                                <button class="btn btn-cancel" (click)="cancelZapierCode()"> Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="loader-body" *ngIf="showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
