<div class="margin-all-area">
    <div class="wraper-body-panel mb-3">
        <div class="wraper-body-left flex-width2">
            <div class="wraper-main-section">
                <div class="global-card-section">
                    <div class="common-inner-header">
                        <div class="inner-header-bg">
                            <div class="inner-cards-panel header-inner-hidden">
                                <span>From Status</span>
                                <span>To Status</span>
                                <span class="flex-width3">Date Changed</span>
                                <span>Action</span>
                            </div>
                        </div>
                    </div>
                    <div class="common-inner-cards">
                        <div class="inner-cards-grid">
                            <div class="inner-card-mobile">
                                <span class="space-manage">&nbsp;</span>
                                <label>From Status</label>
                                <div class="mobile-view-design">
                                    <select class="form-control" [(ngModel)]="fromStatus">
                                        <option [value]="-1">--Select--</option>
                                        <option *ngFor="let item of leadStatusCodeDD" [value]="item?.leadStatusCode">{{item?.display}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="inner-card-mobile">
                                <span class="space-manage">&nbsp;</span>
                                <label>To Status</label>
                                <div class="mobile-view-design">
                                    <select class="form-control" [(ngModel)]="toStatus">
                                        <option [value]="-1">--Select--</option>
                                        <option *ngFor="let item of leadStatusCodeDD" [value]="item?.leadStatusCode">{{item?.display}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="inner-container flex-width3">
                                <div class="inner-card-mobile">
                                    <span class="font-weight-bold">Start</span>
                                    <div class="mobile-view-design">
                                        <kendo-datepicker [(ngModel)]="dtStart"></kendo-datepicker>
                                    </div>
                                </div>
                                <div class="inner-card-mobile">
                                    <span class="font-weight-bold">End</span>
                                    <div class="mobile-view-design">
                                        <kendo-datepicker [(ngModel)]="dtEnd"></kendo-datepicker>
                                    </div>
                                </div>
                            </div>
                            <div class="inner-card-mobile">
                                <label>Action</label>
                                <div class="mobile-view-design">
                                    <button class="btn btn-primary" (click)="rptstatushistorytimespan_Get()">Run</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="wraper-body-right"></div>
    </div>
    <div class="wraper-main-section mt-3">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../assets/activity/config/userlisttitle.svg" class="mr-1" /> Lead Status History Timespan Report</div>
            </div>
            <div class="message-info" *ngIf="infoMsg">
                <span [innerHtml]="infoMsg | safeHtml"></span>
                <!--<button (click)="resetGridSetting()">Reset grid</button>-->
            </div>
            <div class="global-body-section">
                <kendo-grid class="create-user-grid" #grid id="gridId2"
                            [kendoGridBinding]="statusHistoryTimespan"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [sortable]="{mode: 'multiple'}"
                            [scrollable]="'scrollable'"
                            [sort]="_gridCnfgService.sort"
                            [columnMenu]="{ filter: true }"
                            [resizable]="true"
                            [reorderable]="true"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('rpt_statushistorytimespan_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('rpt_statushistorytimespan_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('rpt_statushistorytimespan_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(16,'rpt_statushistorytimespan_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'rpt_statushistorytimespan_grid',grid)">
                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title | titlecase"
                                       [width]="column?.width | stringToNumber"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [hidden]="(_gridCnfgService.hiddenColumns.indexOf(column.field) > -1)"
                                       [includeInChooser]="column.field.includes('$') ? false : true">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == '$'">{{ rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.field == 'LeadDesc'"><a class="contact-cursor-pointer" (click)="redirectTo('lead',dataItem)"> {{dataItem[column.field]}}</a></div>
                            <div class="customer-name" *ngIf="column.field == 'FirstName'"><a class="contact-cursor-pointer" (click)="redirectTo('contact',dataItem)"> {{dataItem[column.field]}} {{dataItem['LastName']}}</a></div>
                            <div class="customer-name" *ngIf="column.field == 'dtFrom'">{{dataItem[column.field]}}</div>
                            <div class="customer-name" *ngIf="column.field == 'dtTo'">{{dataItem[column.field]}}</div>
                            <div class="customer-name" *ngIf="column.field == 'Span'">{{dataItem[column.field]}}</div>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>
        </div>
    </div>
</div>
