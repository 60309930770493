import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, Inject, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitter } from '@angular/core';
import { FileRestrictions, ErrorEvent, SuccessEvent } from '@progress/kendo-angular-upload';
import { isNullOrUndefined } from 'util';
import { CLPUser } from '../../../models/clpuser.model';
import { DocumentResponse, Document, DocumentListResponse } from '../../../models/document';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { NotificationService } from '../../../services/notification.service';
import { OutBoundEmailService } from '../../../services/outBoundEmail.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { keyValue } from '../../../models/search.model';
import { eDocumentCategory, eDocumentType } from '../../../models/enum.model';
import { MyDocumentService } from '../../../services/my-document.service';
import { process } from '@progress/kendo-data-query';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { GlobalService } from '../../../services/global.service';
declare var $: any;

@Component({
  selector: 'linked-document',
  templateUrl: './linked-document.component.html',
  styleUrls: ['./linked-document.component.css']
})
/** linked-document component*/
export class LinkedDocumentComponent {

  @Input() user: CLPUser;
  private encryptedUser: string = '';
  baseUrl: string;
  documentRecentResponse: DocumentResponse;
  documents: Document[] = [];
  initDocuments: Document[] = [];
  isShowFileUploader: boolean = false;
  @Output() documentList: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Input() attachedDocIds: any[] = [];
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  uploadedFileResponse: SimpleResponse;
  documentGridTitle: string = 'Recent';
  @Input() ownerId: number = 0;
  @Input() ownerType: eDocumentCategory = eDocumentCategory.Unknown;

  columns = [{ field: 'documentType', title: '', width: '20' },
  { field: 'documentName', title: 'Document', width: '100' }];
  /* ------updated---------------*/
  private _utilityService: any;
  uploadSaveUrl: string;
  uploadRestrictions: FileRestrictions = {
    allowedExtensions: [".doc", ".docx", ".xls", ".xlsx", ".txt", ".pdf", ".html", ".htm", ".ppt", ".pptx", ".png", ".gif", ".jpg", ".tif"],
    maxFileSize: 3145728
  };
    fileUploadHeaders: HttpHeaders;
  constructor(@Inject('BASE_URL') _baseUrl: string,
      private notifyService: NotificationService,
      private _globalService: GlobalService,
    private _router: Router,
    private _outBoundEmailService: OutBoundEmailService,
    private _documentService: MyDocumentService,) {
    this.baseUrl = _baseUrl;
  }

  onDocumentSearchFilter(inputValue: string): void {
    this.documents = process(this.initDocuments, {
      filter: {
        logic: "or",
        filters: [
          { field: 'documentName', operator: 'contains', value: inputValue }
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
    if (inputValue == "") {
      this.documents.sort((b, a) => (a.documentId > b.documentId) ? 1 : -1);
      /*this.documents = this.documents.splice(0, 5);*/
    }
  }

  ngOnInit() {
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
        this.encryptedUser = localStorage.getItem("token");
        this.fileUploadHeaders = new HttpHeaders({
            'Authorization': 'Basic ' + this.encryptedUser
        });
      if (!isNullOrUndefined(this.user)) {
        this.getMyDocumentList(0, eDocumentCategory.Unknown);
      }
      else
        this._router.navigate(['/login']);
    }
    else
      this._router.navigate(['/login']);
  }

  loadAfuConfig() {
    this.uploadSaveUrl = this.baseUrl + 'api/OutBoundEmail/Document_Post/' + this.user.cLPCompanyID + '/' + this.ownerId + '/' + this.user.cLPUserID + '?documentCategory=' + this.ownerType;
  }

  fileUploadSuccess(e: SuccessEvent) {
    if (!isNullOrUndefined(e)) {
      var uploadList: keyValue[] = e.response.body.list;
      uploadList.forEach((x) => {
        this.attachedDocIds.push(x.key);
      })
      this.getMyDocumentList(0, eDocumentCategory.Unknown);
      this.documentList.emit(this.attachedDocIds);
    }
  }

  fileUploadError(e) {
    if (!isNullOrUndefined(e)) {
      this.notifyService.showError(e.response.message, "", 2000);
    }
  }

  attachDetachFile(type, data: Document, index?) {
    if (type) {
      switch (type) {
        case "add":
          data.isSelected = true;
          this.attachedDocIds.push(data.documentId);
          break;
        case "remove":
          data.isSelected = false;
          this.attachedDocIds = this.attachedDocIds.filter(x => { return x != data.documentId });
          break;
      }
    }
    this.documentList.emit(this.attachedDocIds);
  }

  async getMyDocumentList(ownerId: number, ownerType: eDocumentCategory) {
    if (ownerType != eDocumentCategory.Unknown) {
      this.documentGridTitle = eDocumentCategory[ownerType] + " Files";
      if (this.documents?.length > 0) {
        this.documents = this.documents.filter((item) => item.documentCategory == ownerType)
      }
    }
    else {
      this.documentGridTitle = "Recent";
      await this._documentService.getDocumentsList(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, ownerType, ownerId, true)
        .then(async (result: DocumentResponse) => {
          if (result) {
            this.documentRecentResponse = UtilityService.clone(result);
            this.initDocuments = UtilityService.clone(this.documentRecentResponse.documents);
            this.toggleAttachDetach();
            this.documents = this.initDocuments;
            this.documents.sort((b, a) => (a.documentId > b.documentId) ? 1 : -1);
            /*this.documents = this.documents.splice(0, 5);*/
          }
        })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("buzz-index.getLeadHistory", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "ownerType " + ownerType + "," + "ownerId " + ownerId);
          this._utilityService.handleErrorResponse(err);
        });
    }
  }

  toggleAttachDetach() {
    this.initDocuments.forEach(y => {
      if (!isNullOrUndefined(this.attachedDocIds?.find(x => x == y.documentId)))
        y.isSelected = true;
      else
        y.isSelected = false;
    })
  }

  onNewUpload() {
    this.loadAfuConfig();
    this.isShowFileUploader = true;
  }


  getSrcPath(type: eDocumentType) {
    switch (type) {
      case eDocumentType.doc: case eDocumentType.docx:
        return "../../../../assets/iconword.svg";
      case eDocumentType.xls: case eDocumentType.xlsx:
        return "../../../../assets/iconexcel.svg";
      case eDocumentType.txt:
        return "../../../../assets/icontext.svg";
      case eDocumentType.pdf:
        return "../../../../assets/iconpdf.svg";
      case eDocumentType.html: case eDocumentType.htm:
        return "../../../../assets/iconhtml.svg";
      case eDocumentType.ppt: case eDocumentType.pptx:
        return "../../../../assets/iconppt.svg";
      case eDocumentType.png: case eDocumentType.gif: case eDocumentType.jpg: case eDocumentType.tif:
        return "../../../../assets/iconimage.svg";
      default:
        return "../../../../assets/icontext.svg";
    }
  }
}
