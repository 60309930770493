<div class="custom-security-link">
    <a (click)="newAutomationProcess()">New Automation Process</a>
    <a [routerLink]="['/automation-process']" routerLinkActive="active">My Processes</a>
    <a [routerLink]="['/campaignlist']" routerLinkActive="active">All Campaigns</a>
</div>
<div class="margin-all-area">
    <div class="home-dashboard-section">
        <div [ngClass]="campaignTemplateId > 0 ? 'row-panel' : 'row-panel-full'">
            <div [ngClass]="campaignTemplateId > 0 ? 'col-left-panel' : ''">
                <div class="wraper-main-section">
                    <div class="global-card-section">
                        <div class="global-header-section">
                            <div class="svg-icon-panel"><img src="../../../../assets/automationprocesstitle.svg" class="mr-1" />{{campaignTemplateId > 0 ? ('Process: ' + (campaignTemplateData?.campaignTemplateName ? campaignTemplateData?.campaignTemplateName : '')) : 'Automation Process'}} </div>
                            <div class="header-button-panel">
                                <div class="button-wrapper">
                                    <button type="button" *ngIf="btnShowHide('edit')" matTooltip="Edit" class="icon-btn" (click)="editCampaignTemp()">
                                        <i class="fa fa-pencil-alt" aria-hidden="true"></i>
                                        <span class="btn-text">Edit</span>
                                    </button>
                                    <button type="button" *ngIf="btnShowHide('saveAs')" matTooltip="Save As" class="icon-btn" (click)="makeCopy()">
                                        <img src="../../../../assets/btnsaveas_sm.svg" class="mr-1" />
                                        <span class="btn-text">Save As</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="warning-alert" *ngIf="isShowDeleteModal == 1">
                            There are campaigns using this process.  These campaigns must be deleted before deleting this process.
                        </div>
                        <div class="global-body-section">
                            <div class="alert-panel" *ngIf="newTemplateSaved">
                                Template Saved
                            </div>
                            <app-referral-report-common *ngIf="isShowDeleteModal == 1" [campaignTemplateId]="campaignTemplateId" showNestedGridFor="automation" [user]="user"></app-referral-report-common>
                            <div class="wraper-body-panel">

                                <div class="wraper-body-left">
                                    <form [formGroup]="campaignTemplateForm" (ngSubmit)="isMakeCopy ? campaignTemplateFormSubmit(0) : campaignTemplateFormSubmit(campaignTemplateId)">
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <span class="control-label spantext">Process Name</span>
                                            </div>
                                            <div class="cards-colunm-right" *ngIf="!isViewMode || isMakeCopy">
                                                <input type="text" class="form-control" formControlName="campaignTemplateName" />
                                                <div *ngIf="campaignTemplateForm.controls.campaignTemplateName.errors && (campaignTemplateForm.controls.campaignTemplateName.touched || campaignTemplateForm.controls.campaignTemplateName.dirty)">
                                                    <div class="login-error" *ngIf="campaignTemplateForm.controls.campaignTemplateName.errors.required">Please enter a name for this process. </div>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right" *ngIf="isViewMode && !isMakeCopy">
                                                {{campaignTemplateData?.campaignTemplateName}}
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <span class="control-label spantext">Owner Type</span>
                                            </div>
                                            <div class="cards-colunm-right" *ngIf="!isViewMode">
                                                <select class="form-control" formControlName="ownerType">
                                                    <option value="">-Select One-</option>
                                                    <option value="2">Contact</option>
                                                    <option value="3">Lead</option>
                                                </select>
                                                <div *ngIf="campaignTemplateForm.controls.ownerType.errors && (campaignTemplateForm.controls.ownerType.touched || campaignTemplateForm.controls.ownerType.dirty)">
                                                    <div class="login-error" *ngIf="campaignTemplateForm.controls.ownerType.errors.required">Please enter a name for this process. </div>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right" *ngIf="isViewMode">
                                                {{campaignTemplateData?.ownerType == 2 ? 'Contact' : campaignTemplateData?.ownerType == 3 ? 'Lead' : ''}}
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <span class="control-label spantext">Immunity</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div>
                                                    <input *ngIf="!isViewMode" type="checkbox" class="checkbox" formControlName="immunityFlag" />
                                                    <div *ngIf="isViewMode" [ngStyle]="{'color': campaignTemplateForm.controls.immunityFlag.value ? '' : 'red'}"> {{campaignTemplateData?.immunityFlag ? 'Currently active' : 'Currently not active'}}</div>
                                                    <p>Activating this feature will render campaigns based on this automation process immune from any campaign triggers which are set to affect <I>-All Processes-</I>. <a href="http://help.salesoptima.com/help/default.aspx?htid=108" target=_blank>Click here to learn more.</a></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <span class="control-label spantext">Share</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div>
                                                    <input *ngIf="!isViewMode" type="checkbox" class="checkbox" formControlName="shareable" />
                                                    <div *ngIf="isViewMode" [ngStyle]="{'color': campaignTemplateForm.controls.shareable.value ? '' : 'red'}"> {{campaignTemplateData?.shareable ? 'Currently active' : 'Currently not active'}}</div>
                                                    <span>Activating this feature will enable other users to view, but not edit, this automation process and be able to make a copy of this process.</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <span class="control-label spantext">View in Automation Process Trigger Settings</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div>
                                                    <input *ngIf="!isViewMode" type="checkbox" class="checkbox" formControlName="isViewInCM" />
                                                    <div *ngIf="isViewMode" [ngStyle]="{'color': campaignTemplateForm.controls.isViewInCM.value ? '' : 'red'}"> {{campaignTemplateData?.isViewInCM ? 'Currently active' : 'Currently not active'}}</div>
                                                    <span>Activating this feature will allow general users to select this automation process from within the Automation Processes view of a Contact or Lead.</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <span class="control-label spantext">Cycle Events</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <input *ngIf="!isViewMode" type="checkbox" class="checkbox" formControlName="cycleEvents" />
                                                <span *ngIf="isViewMode" [ngStyle]="{'color': campaignTemplateForm.controls.cycleEvents.value ? '' : 'red'}"> {{campaignTemplateData?.cycleEvents ? 'Currently active' : 'Currently not active'}}</span>
                                            </div>
                                        </div>
                                        <div class="cards-body-section" *ngIf="campaignTemplateForm.controls.cycleEvents.value">
                                            <div class="cards-colunm-left">
                                                <span class="control-label spantext">Delay Cycle</span>
                                            </div>
                                            <div class="cards-colunm-right" *ngIf="!isViewMode">
                                                <div class="flex-align-panel">
                                                    <div class="mb-2">
                                                        <input type="text" class="form-control" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)" formControlName="delayCycleValue" />
                                                        <div *ngIf="campaignTemplateForm.controls.delayCycleValue.errors && (campaignTemplateForm.controls.delayCycleValue.touched || campaignTemplateForm.controls.delayCycleValue.dirty)">
                                                            <div class="login-error" *ngIf="campaignTemplateForm.controls.delayCycleValue.errors.required">Please enter a positive number for Delay Cycle.. </div>
                                                        </div>
                                                    </div>
                                                    <div class="margin-left10 mb-2">
                                                        <select class="form-control" formControlName="delayCycleUnit">
                                                            <option *ngFor="let item of ddDelayCycle; let i=index" [value]="item.id">{{item.text}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right" *ngIf="isViewMode">
                                                {{getUnitDisplay(campaignTemplateData?.delayCycleUnit)}} : {{campaignTemplateData?.delayCycleValue}}
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left"></div>
                                            <div class="cards-colunm-right">
                                                <div class="bottom-button-bar">
                                                    <!--*ngIf="campaignTemplateId > 0"-->
                                                    <button type="submit" class="btn btn-primary" *ngIf="btnShowHide('save')">Save</button>
                                                    <button type="button" class="btn btn-cancel" *ngIf="btnShowHide('cancel')" (click)="onCancel()">Cancel</button>
                                                    <button type="button" class="btn btn-danger" (click)="onDelete()" data-toggle="modal" data-target="#deleteCampaignTempModal" *ngIf="btnShowHide('delete')">Delete</button>
                                                    <button type="button" class="btn btn-primary" *ngIf="btnShowHide('createNewEvent')" (click)="createNewEvent()">Create New Event</button>
                                                    <button type="button" class="btn btn-primary" *ngIf="btnShowHide('reordering')" (click)="enableReording()">{{isEnableReording ? 'Disable': 'Enable'}} Re-Ordering</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left"></div>
                                            <div class="cards-colunm-right">
                                                <div class="full-width-container">
                                                    <div class="alert-panel" *ngIf="!isShowCampaignEventList">
                                                        No event exist.
                                                    </div>
                                                </div>
                                                <div class="instruction-box-panel full-width-container">
                                                    <campaign-event-list *ngIf="isShowCampaignEventList && isViewMode && !isMakeCopy" [enableReording]="isEnableReording" [campaignTemplateId]="campaignTemplateId" (sendCampaignEventId)="getCampaignEventId($event)" class="flex-width2"></campaign-event-list>
                                                    <campaign-event *ngIf="isShowCampaignEvent && isViewMode" [ownerType]="campaignTemplateData?.ownerType" [campaignTemplateId]="campaignTemplateId" [campaignEventId]="campaignEventId" class="flex-width2" (refreshEventList)="refereshEvents()" (cancelEvent)="onCancelEvent()"></campaign-event>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-right-panel" *ngIf="campaignTemplateId > 0">
                <webform [isFromAutomation]="true" [campaignTemplateId]="campaignTemplateId"></webform>
            </div>
        </div>

    </div>
</div>
<div *ngIf="isShowDeleteModal == 2" class="modal fade" id="deleteCampaignTempModal" tabindex="-1" role="dialog" aria-labelledby="deleteCampaignTempModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
            </div>
            <div class="modal-body modal-common-body">
                <h5>Are you sure you want to delete this process? </h5>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="deleteCampaignTemplate()" data-dismiss="modal" class="btn btn-primary">Confirm</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
