import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataBindingDirective, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { parseDate } from '@progress/kendo-angular-intl';
import { isNullOrUndefined } from 'util';
import { ClassCodes } from '../../../models/classCodes.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { Company, CompanyListResponse, ddField, ddFieldRespone, UploadCompanyBulkAction, UserCompanyBulkAction } from '../../../models/company.model';
import { ContactUploadFieldMappingResponse, LookUpItem } from '../../../models/contactExcelUpload';
import { CreateExportFor, eCustomSearchCategory, eEditType, eExportRequestObjectType, eExportRequestStatus, eFeatures, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { SearchItem, SearchItemListResponse, SearchQuery, SearchQueryResponse } from '../../../models/search.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { NotificationService } from '../../../services/notification.service';
import { SearchContactService } from '../../../services/Searchcontact.service';
import { ContactCommonSearchService } from '../../../services/shared/contact-common-search.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { CompanyCreateComponent } from '../company-create/company-create.component';
import { process } from '@progress/kendo-data-query';
import { CreateExportRequestObj, ExportRequest } from '../../../models/exportRequest.model';
import { ReportService } from '../../../services/report.service';
import { ExportRequestService } from '../../../services/export-request.service';
import { CompanyGridComponent } from '../company-grid/company-grid.component';
import { GlobalService } from '../../../services/global.service';
import { ContactCommonSearchComponent } from '../../contact-module/common/contact-common-search/contact-common-search.component';

declare var $: any;

@Component({
    selector: 'company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.css'],
    providers: [GridConfigurationService]
})
/** company component*/
export class CompanyComponent implements OnInit, OnDestroy {
    /*  recentDocConfig;*/
    @ViewChild(ContactCommonSearchComponent) contactCommonSearchComponent!: ContactCommonSearchComponent;
    @ViewChild(CompanyGridComponent) companyGridComponent: CompanyGridComponent;
    showSpinner: boolean = false;
    eUserRole = eUserRole;

    exportRequest: ExportRequest;
    private encryptedUser: string = '';
    isCompanyExportShow: boolean = false;
    @Input() user: CLPUser;
    @Input() isBulkCompany?: boolean = false;
    @Input() roleFeaturePermissions: RoleFeaturePermissions;
    eStat: number = eExportRequestStatus.None;
    eType: number = eExportRequestObjectType.Company;
    queryDataLoaded: SearchQueryResponse = <SearchQueryResponse>{};
    step: number = 1;
    subscriptionQueryList: any;
    stepper: string = 'view';
    dateFormat: string;
    headerTitleCompanyList: string = 'Companies Search Results';

    columns = [
        { field: '$', title: '', width: '30' },
        { field: '$$', title: '', width: '40' },
        { field: 'companyName', title: 'Company', width: '200' },
        { field: 'webSite', title: 'webSite', width: '250' },
        { field: 'phone', title: 'phone', width: '120' },
        { field: 'numContacts', title: '# of Contacts', width: '130' },
        { field: 'city', title: 'city', width: '90' },
        { field: 'state', title: 'state', width: '120' },
        { field: 'firstName', title: 'User', width: '70' },
        { field: 'dtModified', title: 'Modified', width: '90' },
        { field: 'dtCreated', title: 'Created', width: '90' }
    ];
    reorderColumnName: string = 'companyName,webSite,phone,numContacts,city,state,firstName,dtModified,dtCreated';
    columnWidth: string = 'companyName:200,webSite:250,phone:120,numContacts:130,city:90,state:120,firstName:70,dtModified:90,dtCreated:90';
    arrColumnWidth: any[] = ['companyName:200,webSite:250,phone:120,numContacts:130,city:90,state:120,firstName:70,dtModified:90,dtCreated:90']
    gridHeight;
    pageSize: number = 10;
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    @Input() companyList: Company[];
    @Input() initCompanyList: Company[];
    companyId: number = 0;
    @ViewChild(CompanyCreateComponent) companyCreateRef;
    mobileColumnNames: string[];
    @Input() isHomeSearch: boolean = true;

    /*bulk company*/
    formeFilledLevel: number;
    userFilterAssign: CLPUser[];
    isEditField: boolean = false;
    editMode: boolean = false;
    uploadEditFieldForm: FormGroup;
    messageStep3: string = "";
    enumForEditType;
    editTypeKeys: unknown[];
    initEditTypeKeys: unknown[];
    lookUpFields: ddField[] = [];
    saveConfirmation: boolean = false;
    classCode: ClassCodes[];
    tagList: LookUpItem[];
    tagData: LookUpItem[];
    contactUploadFieldMapResponse: ContactUploadFieldMappingResponse;
    actionPerformdType: number = -1;
    assignedUserTransfer = -1;
    public selectAllState: SelectAllCheckboxState = "unchecked";
    public mySelection: number[] = [];
    companyCreateMsg: string = '';
    userResponse: UserResponse;
    newownerFilledLevel: number = 1;
    newOwnerValue: any = false;

    customSearch: SearchQueryResponse;
    searchItemListResponse: SearchItemListResponse;
    searchItems: SearchItem[];
    showSaveSearch: boolean = false;
    showSaveSearchForm: boolean = false;
    searchName: string = '';
    saveSearchForm: FormGroup;
    constructor(private cd: ChangeDetectorRef, @Inject('BASE_URL') _baseUrl: string,
        private _localService: LocalService,
        private _notifyService: NotificationService,
        private _searchContactService: SearchContactService,
        private fb: FormBuilder,
        private _exportService: ExportRequestService,
        private _utilityService: UtilityService,
        public notifyService: NotificationService,
        private _reportService: ReportService,
        public _contactCommonSearchService: ContactCommonSearchService,
        private _ngZone: NgZone,
        public _gridCnfgService: GridConfigurationService,
        private _router: Router,
        private _accountSetupService: AccountSetupService,
        private _route: ActivatedRoute,
        private _globalService: GlobalService) {

        this.gridHeight = this._localService.getGridHeight('464px');
        this._localService.isMenu = true;
        this._route.queryParams.subscribe(params => {
            if (!isNullOrUndefined(params)) {
                this.companyCreateMsg = params?.txt;
            }
        })
    }

    defaultCompanySearch() {
        var searchQuery: SearchQuery = <SearchQuery>{};
        searchQuery.cLPUserID = this.user.cLPUserID;
        searchQuery.controlType = "md";
        searchQuery.operator = "IN";
        searchQuery.searchItem = "CLPUserID";
        searchQuery.searchItemValue = this.user.cLPUserID.toString();
        searchQuery.tableName = "clpuser";
        this.queryDataLoaded = <SearchQueryResponse>{};
        this.queryDataLoaded.searchQueryList = [];
        this.queryDataLoaded.searchQueryList.push(searchQuery);
        this.getQueryData();
    }

    ngOnInit() {
        this.saveSearchForm = this.prepareSaveSearchForm();
        this.headerTitleCompanyList = this.companyCreateMsg == 'cncl' ? 'My Recent Companies' : this.isBulkCompany ? 'Bulk Company Actions' : 'Companies Search Results';
        this.showSpinner = true
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                if (this.user) {
                    this.dateFormat = this.user.dateFormat;
                    this._gridCnfgService.user = this.user;
                    this.isCompanyExportShow = true;
                    this.getCompanySearch();
                    this.editField();
                    if (!this.isBulkCompany)
                        this.defaultCompanySearch();
                    this.getCompanyGridConfiguration();
                    this.getQueryDataLoad();
                    this.getCompanyDropDownData();
                    this.commonEditTypeForm()
                }
                else
                    this._router.navigate(['/login']);
            }
            else
                this._router.navigate(['/login']);
            this.showSpinner = false
        });
    }

    editField() {
        this.messageStep3 = "Please select the value you would like to add.";
        this.editMode = false;
        setTimeout(() => {
            if (!isNullOrUndefined(this.uploadEditFieldForm)) {
                this.uploadEditFieldForm.reset();
                this.uploadEditFieldForm.markAsUntouched();
            }
            this.uploadEditFieldForm = this.prepareUploadEditFieldForm();
        }, 1);
    }

    prepareUploadEditFieldForm() {
        return this.fb.group({
            ddField: new FormControl(""),
            editType: new FormControl(-1),
            textValue: new FormControl(""),
        });
    }

    changeDDFields() {
        this.editMode = true;
        switch (this.uploadEditFieldForm.controls.ddField.value) {
            case "Shareable": case "Class1Code": case "Class2Code": case "Class3Code": case "Class4Code": case "Class5Code": case "Class6Code": case "Class1Code : Status": case "Class2Code : Marketing Channel": case "Class3Code : Best time to call": case "Class4Code : DQ/Dead Factors": case "Class5Code : BEM ONLY (do not use)": case "Class6Code: Channel": case "Class7Code : Source": case "Class8Code : Campaign":
                this.editTypeKeys = this.initEditTypeKeys.filter(value => value != 1);
                break;
            case 'Tag':
                this.editTypeKeys = this.initEditTypeKeys.filter(value => value == 1);
                break;
            default:
                this.editTypeKeys = this.initEditTypeKeys.filter(value => +value <= 2);
                break;

        }
        this.formeFilledLevel = 2;
    }


    changeField() {
        this.renderConditionalType(this.uploadEditFieldForm.controls.ddField.value, this.uploadEditFieldForm.controls.editType.value);
        this.formeFilledLevel = 3;
    }

    renderConditionalType(ddField: any, editType: any) {
        switch (editType) {
            case "0": this.messageStep3 = "Please select the new value."; break;
            case "1": this.messageStep3 = "Please select the value you would like to add."; break;
            case "2": this.messageStep3 = "Please confirm that you would like to CLEAR the selected field for all checked companies."; break;
            default:
        }
        switch (ddField) {
            case "Shareable": case "Check1": case "Check2": case "Check3": case "Check4": case "Check5": case "Check6":
                this.messageStep3 = "Please select the new value.";
                this.uploadEditFieldForm.controls.textValue.setValue(false);
                break;
            case "Notes": case "Comments":
                break;
            case "CMCustomDate1": case "CMCustomDate2": case "CMCustomDate3": case "CMCustomDate1 : Issue": case "CMCustomDate2 : Request Info": case "CMCustomDate3 : Expiration":
                this.uploadEditFieldForm.controls.textValue.setValue(parseDate(new Date()));
                break;
            case "Class1Code": case "Class1Code : Status":
                this.classCode = this.contactUploadFieldMapResponse.lookup_Class1Code.lookup;
                break;
            case "Class2Code": case "Class2Code : Marketing Channel":
                this.classCode = this.contactUploadFieldMapResponse.lookup_Class2Code.lookup;
                break;
            case "Class3Code": case "Class3Code : Best time to call":
                this.classCode = this.contactUploadFieldMapResponse.lookup_Class3Code.lookup;
                break;
            case "Class4Code": case "Class4Code : DQ/Dead Factors":
                this.classCode = this.contactUploadFieldMapResponse.lookup_Class4Code.lookup;
                break;
            case "Class5Code": case "Class5Code : BEM ONLY (do not use)":
                this.classCode = this.contactUploadFieldMapResponse.lookup_Class5Code.lookup;
                break;
            case "Class6Code": case "Class6Code : Channel":
                this.classCode = this.contactUploadFieldMapResponse.lookup_Class6Code.lookup;
                break;
            case "Class7Code": case "Class7Code : Source":
                this.classCode = this.contactUploadFieldMapResponse.lookup_Class7Code.lookup;
                break;
            case "Class8Code": case "Class8Code : Campaign":
                this.classCode = this.contactUploadFieldMapResponse.lookup_Class8Code.lookup;
                break;
            case "Tag":
                this.tagList = this.contactUploadFieldMapResponse.lookUpTags;
                this.tagData = this.tagList?.slice();
                if (editType === "1")
                    this.messageStep3 = "Please select or enter a tag to add";
                break;
            default:
        }
    }


    commonEditTypeForm() {
        this.enumForEditType = eEditType;
        this.editTypeKeys = Object.values(this.enumForEditType).filter(k => !isNaN(Number(k)));
        this.initEditTypeKeys = this.editTypeKeys;
    }

    cancelSaveCompany() {
        this.isEditField = false;
        this.saveConfirmation = false;
        this.uploadEditFieldForm.controls['textValue'].enable();
        this.actionPerformdType = -1;
    }

    preSaveContact() {
        if (this.mySelection?.length <= 0) {
            this._notifyService.showWarning('Select atleast one company before transfer', 'No Company Selected');
            return;
        }
        else {
            if (this.uploadEditFieldForm.controls.textValue.value == "")
                this.uploadEditFieldForm.controls.textValue.markAsTouched();
            else {
                this.saveConfirmation = true;
                this.uploadEditFieldForm.controls['textValue'].disable();
                this.formeFilledLevel = 4;
            }

        }
    }

    updateBulkCompanyForm() {
        if (this.mySelection.length <= 0) {
            this._notifyService.showWarning('Select Companies Before Save', 'No Companies Selected');
            return;
        }
        else {
            var uploadContactBulkActionFinal: UploadCompanyBulkAction = this.copyValueFromBulkEditToData();
            this.uploadCompanyEditBulk(uploadContactBulkActionFinal);
        }
    }

    copyValueFromBulkEditToData() {
        var uploadContactBulkActionFinal: UploadCompanyBulkAction = <UploadCompanyBulkAction>{};
        uploadContactBulkActionFinal.companyIdsToProcess = this.mySelection ? this.mySelection : [];
        uploadContactBulkActionFinal.isProcessAll = false;
        uploadContactBulkActionFinal.eEdit = +this.uploadEditFieldForm.controls.editType.value;
        uploadContactBulkActionFinal.fieldName = this.uploadEditFieldForm.controls.ddField.value;
        uploadContactBulkActionFinal.fieldvalue = this.uploadEditFieldForm.controls.textValue.value;
        return uploadContactBulkActionFinal;
    }

    copyValueFromTransferEditToData(type) {
        var userCompanyBulkActionTransfer: UserCompanyBulkAction = <UserCompanyBulkAction>{};
        userCompanyBulkActionTransfer.companyIds = this.mySelection ? this.mySelection : [];
        userCompanyBulkActionTransfer.isProcessAll = false;
        userCompanyBulkActionTransfer.clpuserID = (type == 'transfer') ? this.assignedUserTransfer : this.user.cLPUserID;
        return userCompanyBulkActionTransfer;
    }

    public onSelectAllChange(checkedState: SelectAllCheckboxState): void {
        if (checkedState === "checked") {
            this.mySelection = this.companyList?.map((item) => item.companyID);
            this.selectAllState = "checked";
        } else {
            this.mySelection = [];
            this.selectAllState = "unchecked";
        }
    }


    getCompanyGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.user = this.user;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'company_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('company_grid').subscribe((value) => { }));
    }

    getQueryDataLoad() {
        this.subscriptionQueryList = this._contactCommonSearchService.getqueryCompanyListChangedChangeEmitter().subscribe((data) => {
            this._ngZone.run(() => {
                this.queryDataLoaded = data;
                if (isNullOrUndefined(this.queryDataLoaded)) {
                    this.queryDataLoaded = <SearchQueryResponse>{};
                    this.defaultCompanySearch();
                }
                else
                    this.getQueryData();
            })
        });
    }

    resetGridSetting() {
        if (!this.isBulkCompany) {
            this.companyGridComponent.resetGridSetting()
        }
        else {
            this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'company_grid').subscribe((value) => this.getCompanyGridConfiguration());
        }
    }

    ngOnDestroy() {
        this.isCompanyExportShow = false;
        this.subscriptionQueryList?.unsubscribe();
    }

    async getQueryData() {
        this.showSpinner = true;
        if (this.isHomeSearch)
            await this._searchContactService.searchSaveComany(this.encryptedUser, this.queryDataLoaded, this.user.cLPUserID)
                .then(async (result: CompanyListResponse) => {
                    if (!isNullOrUndefined(result)) {
                        var res = UtilityService.clone(result);
                        this.companyList = res.companies;
                        this.initCompanyList = this.companyList;
                        if (!isNullOrUndefined(this._gridCnfgService)) {
                            this._gridCnfgService.iterateConfigGrid(this.companyList, "company_grid");
                            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('company_grid');
                        }
                    }
                    this.showSpinner = false
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("company.getQueryData", err.message, this.queryDataLoaded, 'cLPUserID ' + this.user.cLPUserID);
                    this.showSpinner = false;
                    this._utilityService.handleErrorResponse(err);
                });
        this.showSpinner = false;
    }

    async getCompanyDropDownData() {
        this.showSpinner = true;
        await this._accountSetupService.companyBulkUpdateDDFieldsGet(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: ddFieldRespone) => {
                if (result) {
                    var res = UtilityService.clone(result);
                    this.lookUpFields = res.ddFields;
                    this.userFilterAssign = res.filterUsers;
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company.getCompanyDropDownData", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async uploadCompanyEditBulk(userCompanyBulkAction: UploadCompanyBulkAction) {
        this.showSpinner = true;
        await this._accountSetupService.uploadCompanyEditBulk(this.encryptedUser, this.user.cLPUserID, userCompanyBulkAction)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this.actionPerformdType = -1;
                    this.showSpinner = false;
                    this.saveConfirmation = false;
                    this.mySelection = [];
                    this.selectAllState = "unchecked";
                    this.getQueryData();
                }
                else
                    this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company.uploadCompanyEditBulk", err.message, userCompanyBulkAction, 'cLPUserID ' + this.user.cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }


    async companyBulkDeleteByIds(userCompanyBulkAction: UserCompanyBulkAction) {
        this.showSpinner = true;
        await this._accountSetupService.companyBulkDeleteByIds(this.encryptedUser, this.user.cLPUserID, userCompanyBulkAction)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var res = UtilityService.clone(result);
                    this.actionPerformdType = -1;
                    this.mySelection = [];
                    if (!res.messageBool)
                        this._notifyService.showWarning(res.messageString, 'Unable to delete');
                    else
                        this._notifyService.showSuccess('Select companies deleted', 'deleted successfully');
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company.companyBulkDeleteByIds", err.message, userCompanyBulkAction, 'cLPUserID ' + this.user.cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }


    async companyBulkActionTransfer() {
        var uploadContactBulkTransferFinal: UserCompanyBulkAction = this.copyValueFromTransferEditToData('transfer');
        this.showSpinner = true;
        await this._accountSetupService.companyBulkActionTransfer(this.encryptedUser, this.user.cLPUserID, uploadContactBulkTransferFinal)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var res = UtilityService.clone(result);
                    this.actionPerformdType = -1;
                    this.mySelection = [];
                    this.notifyService.showSuccess('All selected Companies have been successfully Transferred.', '', 3000);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company.getCompanyDropDownData", err.message, uploadContactBulkTransferFinal, 'cLPUserID ' + this.user.cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    viewCompanyList(eventData: { title: string, saved: boolean }) {
        this.stepper = eventData.title;
        if (eventData.saved)
            this.getQueryData();

    }

    rowSelectionCompanyChange(clpid) {
        this._router.navigateByUrl(`company-create?cid=${clpid}`);
    }

    rowSelectionCompanyChangeFromBulk(clpid) {
        this._router.navigateByUrl(`company-create?cid=${clpid}`);
        this.isBulkCompany = false;
    }

    changeFileApiResponse(event) {
        if (!isNullOrUndefined(event.body))
            this._notifyService.showSuccess(event.body.messageString ? event.body.messageString : 'File Successfuly Uploaded', "", 3000);
    }

    createNewCompany() {
        this.companyId = 0;
        this._router.navigateByUrl(`company-create?isNew=y`);
    }

    deleteBulkUser() {
        if (this.mySelection.length <= 0) {
            this._notifyService.showWarning('Select Companies Before Delete', 'No Companies Selected');
            return;
        }
        else {
            var uploadContactBulkTransferFinal: UserCompanyBulkAction = this.copyValueFromTransferEditToData('delete');
            this.companyBulkDeleteByIds(uploadContactBulkTransferFinal);
        }
    }

    transferBulkUser() {
        if (this.mySelection.length <= 0) {
            this._notifyService.showWarning('Select Companies Before Transfer', 'No Companies Selected');
            return;
        }
        else {
            if (this.assignedUserTransfer < 0) {
                this._notifyService.showWarning('Select User to be assigned', 'No User Selected');
                return;
            }
            else {
                this.saveConfirmation = true;
                this.newownerFilledLevel = 2;
                this.newOwnerValue = this.userFilterAssign.filter((item) => item.cLPUserID == this.assignedUserTransfer)
                 this.companyBulkActionTransfer();
            }
        }
    }

    onCompanySearchFilter(inputValue: string): void {
        if (this.initCompanyList) {
            this.companyList = process(this.initCompanyList, {
                filter: {
                    logic: "or",
                    filters: [
                        { field: 'companyName', operator: 'contains', value: inputValue },
                        { field: 'webSite', operator: 'contains', value: inputValue },
                        { field: 'phone', operator: 'contains', value: inputValue },
                        { field: 'numContacts', operator: 'contains', value: inputValue },
                        { field: 'city', operator: 'contains', value: inputValue },
                        { field: 'state', operator: 'contains', value: inputValue },
                        { field: 'firstName', operator: 'contains', value: inputValue },
                    ],
                }
            }).data;
            if (this.dataBinding) {
                this.dataBinding.skip = 0;
            }
        }
    }


    setExportRequest() {
        this.exportRequest = <ExportRequest>{};
        this.exportRequest.cLPUserID = this.user?.cLPUserID;
        this.exportRequest.cLPCompanyID = this.user?.cLPCompanyID;
    }


    async createExportRequest() {
        this.showSpinner = true;
        //this.setExportRequest();
        var exportRequest = <CreateExportRequestObj>{};
        exportRequest.searchQueryObj = <SearchQueryResponse>{};
        exportRequest.exportRequestObj = <ExportRequest>{};
        exportRequest.exportRequestObj.cLPUserID = this.user?.cLPUserID;
        exportRequest.exportRequestObj.whereClause = '';
        exportRequest.exportRequestObj.orderBy = '';
        exportRequest.exportRequestObj.objectType = eExportRequestObjectType.Company;
        exportRequest.exportRequestObj.cLPCompanyID = this.user.cLPCompanyID;
        exportRequest.exportRequestObj.fileName = "company_export";
        /* exportRequest.exportRequestObj.includeMetrics = this.includeMetrics;*/
        exportRequest.searchQueryObj = this.queryDataLoaded;
        await this._exportService.exportRequestCreate(this.encryptedUser, exportRequest, eExportRequestObjectType.Company)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result?.messageBool)
                        this.notifyService.showSuccess('Your export request has been submitted successfully.', '', 3000);
                    else
                        this.notifyService.showError(result?.errorMsg, '', 3000);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("company.createExportRequest", err.message, exportRequest, 'type ' + eExportRequestObjectType.Company);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    cancelBulkAction() {
        this.isEditField = false;
        this.companyList = [];
        this.mySelection = [];
        this.actionPerformdType = -1
        //this.getQueryData()
        this.getQueryDataLoad()
    }

    isRunSearch(event) {
        this.showSaveSearchForm = false;
        this.showSaveSearch = true;
        let cSearch = JSON.parse(localStorage.getItem('custom_company_search'));
        if (cSearch != undefined) {
            this.customSearch = cSearch;
            console.log(this.customSearch)
        }
    }

    getOperatorName(operator) {
        let opName = ''
        switch (operator) {
            case "=":
                opName = 'Equal'
                break;
            case "<>":
                opName = 'Not Equal'
                break;
            case "LIKE":
                opName = 'Contains'
                break;
            case "IN":
                opName = 'Contains Any'
                break;
            case "NOT IN":
                opName = 'Does Not Contain'
                break;
            case "CA":
                opName = 'Contains All'
                break;
            case "R":
                opName = 'Range'
                break;
            case "dt":
                opName = 'Date'
                break;
            case ">":
                opName = 'Greater Than'
                break;
            case "<":
                opName = 'Less Than'
                break;
            case ">=":
                opName = 'Greater Than Equal To'
                break;
            case "<=":
                opName = 'Less Than Equal To'
                break;
            case "NOT LIKE":
                opName = 'Does Not Contain'
                break;
            case "STARTS":
                opName = 'Starts With'
                break;
            case "ENDS":
                opName = 'Ends With'
                break;

        }
        return opName;
    }

    async getCompanySearch() {
        this.showSpinner = true;
        await this._searchContactService.getCompanySearchFields(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SearchItemListResponse) => {
                if (result) {
                    this.searchItemListResponse = UtilityService.clone(result);
                    this.searchItems = this.searchItemListResponse.searchItems.sort((a, b) => a.displayValue.localeCompare(b.displayValue));
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("contact-common-search.getCompanySearch", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                    + 'cLPUserID: ' + this.user.cLPUserID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getSearchItemName(searchItem) {
        let itemName = '';

        let searchObj = this.searchItems?.filter((item) => item?.columnName == searchItem?.searchItem)[0];
        switch (searchItem?.controlType) {
            case 'md':
                let valuesArr = searchItem?.searchItemValue.split(",").map(Number);
                itemName = searchObj?.itemData
                    .filter(item => valuesArr.includes(item.value))
                    .map(item => item.display)
                    .join(", ");
                break;
            case 't':
                itemName = searchItem?.searchItemValue;
                break;
            case 'dt':
                itemName = searchItem?.strDtStart + " - " + searchItem?.strDtEnd;
                break;
            case 'd':
                itemName = searchObj?.itemData
                    .filter(item => item?.value == searchItem?.searchItemValue)[0]?.display
                break;
        }

        return itemName;
    }

    private prepareSaveSearchForm(): FormGroup {
        return this.fb.group({
            SearchQueryName: ['', [Validators.required]]
        });
    }

    SubmitSearch() {

        if ((this.saveSearchForm.valid)) {
            this.showSpinner = true;
            let searchQueryName = this.saveSearchForm.controls['SearchQueryName'].value
            console.log(searchQueryName)
            this._searchContactService.SavedSearchQueries_Update(this.encryptedUser, this.customSearch, this.user.cLPUserID, 0, searchQueryName, eCustomSearchCategory.Company)
                .then(res => {
                    if (res) {
                        var response = UtilityService.clone(res);
                        console.log(response)
                        this.saveSearchForm.reset();
                        this.saveSearchForm.get('SearchQueryName').setValue('');
                        this.contactCommonSearchComponent.getCompanySearch();
                        this.notifyService.showSuccess("Search Query has been saved successfully!", "", 3000);
                        this.showSaveSearch = false;
                        this.showSpinner = false;
                    }
                    else {
                        this.notifyService.showError("Error during Submitting Search. Please contact administrator!", "", 3000);
                        this.showSpinner = false;
                    }
                }).catch((err: HttpErrorResponse) => {
                    this.showSpinner = false;
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            if (!this.saveSearchForm.valid)
                this.validateAllFormFields(this.saveSearchForm);
        }
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched();
                control.updateValueAndValidity();
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    get saveSearchFrm() {
        return this.saveSearchForm.controls;
    }
}
