import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit } from '@angular/core';
import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPAnnounce, CLPAnnounceListResponse } from '../../../models/announcements.model';
import { AppointmenTypeCodeByCategory, ShareableApptUI, UpdateAppt } from '../../../models/appt.model';
import { CheckListLoadResponse, CLPUser, GSCheckList, UserResponse } from '../../../models/clpuser.model';
import { CLPUserPref } from '../../../models/clpUserPref.model';
import { eApptCategory, eFeatures } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { AnnouncementsService } from '../../../services/announcements.service';
import { GlobalService } from '../../../services/global.service';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { LiveConnectService } from '../../../services/live-connect.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { UserService } from '../../../services/user.service';
import { DashboardCardComponent } from '../common/dashboard-card/dashboard-card.component';
declare var $: any;
@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit, AfterViewInit {

  isSRSManager: boolean = false;
  isLiveConnectClpUser: boolean = false;
  private encryptedUser: string ;
  roleFeaturePermissions: RoleFeaturePermissions;
  userResponse: UserResponse;
  user: CLPUser;
  selectedMonth: number ;
  selectedYear: number;
  sendMailInfo: any;
  isShowApptModal: boolean = false;
  shareableAppt: ShareableApptUI;
  isShowLiveConnectLink: boolean = false;
  @ViewChild('apptDash') apptDash: DashboardCardComponent;
  @ViewChild('apptTask') apptTask: DashboardCardComponent;
  @ViewChild('pinnedContact') pinnedContact: DashboardCardComponent;
  @ViewChild('closeInputButton') closeInputButton: ElementRef;
  isFromLead: boolean = false;
    announcements: CLPAnnounce[];
    userPrefData: CLPUserPref;
    gsCheckList: GSCheckList[];
    apptTypeCodes: AppointmenTypeCodeByCategory[];

    constructor(private _utilityService: UtilityService, private _liveConnectSrvc: LiveConnectService, public _localService: LocalService, private _router: Router, public notifyService: NotificationService, public _globalService: GlobalService,
        private _announcementsService: AnnouncementsService, private userService: UserService,
        private _leadSettingSrvc: LeadSettingService
    ) {
    this._localService.isMenu = true;
    this.encryptedUser == '';
    this.selectedMonth = (new Date()).getMonth() + 1;
    this.selectedYear = (new Date()).getFullYear();
    this.sendMailInfo = { isShow: false, contactId: 0 };
  }
    ngAfterViewInit(): void {

    }

    ngOnInit() {
        window.localStorage.removeItem("custom_search");
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.get_ClpUserPref();
                        this.cLPAnnounceGetListToShow()
                        this.cLPUserPrefsShowGSCheckList()
                        this.getApptFilters();
                        if (this.user && this.user.country) {
                            let country = this.user.country != null && this.user.country != "" ? this.user.country : "US"
                            window.localStorage.setItem('sc_country', country)
                        }

                        this.liveConnect_isLiveConnectSRSAccount().then(() => {
                            this.IsLiveConnectCLPUser().then(() => {
                                if (this.user.slurpyUserId > 0 || this.isSRSManager || this.isLiveConnectClpUser) {
                                    this.isShowLiveConnectLink = true;
                                }
                            });
                        });
                    }
                    else {
                        this._router.navigate(['/login']);
                    }
                });
            }
            else {
                this._router.navigate(['/login']);
            }
        });
    }

    async getApptFilters() {
        await this._leadSettingSrvc.apptFiltersGet(this.encryptedUser, this.user.cLPCompanyID, eApptCategory.Company)
            .then(async (result: AppointmenTypeCodeByCategory[]) => {
                if (result)
                    this.apptTypeCodes = UtilityService.clone(result);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

  private async authenticateR() {
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
            }
          }
        }
      })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error('Home.authenticateR', err.message, null, 'feature ' + eFeatures.None);
      });
  }

  async liveConnect_isLiveConnectSRSAccount() {
    await this._liveConnectSrvc.liveConnect_isLiveConnectSRSAccount(this.encryptedUser, this.user?.cLPCompanyID)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          this.isSRSManager = result?.messageBool;
        }
      })
      .catch((err: HttpErrorResponse) => {
        this._globalService.error('Home.authenticateR.liveConnect_isLiveConnectSRSAccount', err.message, null, 'cLPCompanyId ' + this.user?.cLPCompanyID);
      });
  }

  async IsLiveConnectCLPUser() {
    await this._liveConnectSrvc.IsLiveConnectCLPUser(this.encryptedUser, this.user?.cLPUserID)
      .then(async (result: SimpleResponse) => {
        if (!isNullOrUndefined(result)) {
          this.isLiveConnectClpUser = result?.messageBool;
        }
      })
      .catch((err: HttpErrorResponse) => {
        this._globalService.error('Home.authenticateR.IsLiveConnectCLPUser', err.message, null, 'cLPUserID ' + this.user?.cLPUserID);
      });
  }

  hideSendMail() {
    $('#sendEmailModal').modal('hide');
    this.sendMailInfo.isShow = false;
    this.sendMailInfo.contactId = 0;
  }

  sendEmailParent(ev) {
    $('#sendEmailModal').modal('show');
    this.sendMailInfo.isShow = true;
    this.isFromLead = ev?.leadID> 0 ? true : false;
    this.sendMailInfo.contactId = ev?.contactID;
  }

  async appointmentPopUp() {
    this.shareableAppt = {
      apptId: 0,
      rApptID: 0,
      leadContactId: 0,
      cat: 0,
      ownerId: 0,
      currentUrlAppt: 'home',
      isNewTab: true,
    };
    this.isShowApptModal = true;
  }

  hideQuickApptModal(updateAppt?: UpdateAppt) {
    if (!isNullOrUndefined(updateAppt)) {
      this.closeModalApptModal();
    }
    this.isShowApptModal = false;
    this.shareableAppt = null;
  }

  closeModalApptModal() {
    let inputElement: HTMLElement = this.closeInputButton.nativeElement as HTMLElement;
    inputElement.click();
  }

  openLiveConnect() {
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['/live-connect']));
    window.open(url, "_blank");
    }

    async cLPAnnounceGetListToShow() {
        await this._announcementsService.cLPAnnounceGetListToShow(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID)
            .then((result: CLPAnnounceListResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.announcements = response?.clpAnnouncements
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log('error in delete office code' + err);
            });
    }

    async cLPAnnounceDismissCreate(cLPAnnounceID) {
        if (cLPAnnounceID > 0) {
            await this._announcementsService.cLPAnnounceDismissCreate(this.encryptedUser, cLPAnnounceID, this.user?.cLPUserID)
                .then((result: SimpleResponse) => {
                    if (result) {
                        var response = UtilityService.clone(result);
                        //this._notifyService.showSuccess("Announcement deleted successfully", "", 3000);
                        this.cLPAnnounceGetListToShow()
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log('error in delete announcement' + err);
                });
        }
    }

    async cLPUserPrefsShowGSCheckList() {
        if (this.user?.cLPUserID > 0) {
            await this.userService.GSCheckListLoad(this.encryptedUser, this.user?.cLPUserID)
                .then((result: CheckListLoadResponse) => {
                    if (result) {
                        var response = UtilityService.clone(result);
                        this.gsCheckList = response?.sgCheckList
                        //this._notifyService.showSuccess("Announcement deleted successfully", "", 3000);
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log('error in delete announcement' + err);
                });
        }
    }

    async get_ClpUserPref() {
        await this.userService.CLPUserPref_Load(this.encryptedUser, this.user.cLPUserID)
            .then(async (result: CLPUserPref) => {
                this.userPrefData = UtilityService.clone(result);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-call.get_ClpUserPref", err.message, null,
                    'cLPUserID: ' + this.user.cLPUserID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async saveUserPref() {
        this.userPrefData.showGSCheckList = false
        await this.userService.saveCLPUserPref(this.encryptedUser, this.userPrefData)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response: SimpleResponse = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    onGSListCheckboxChange(event, item) {
        if (event.target.checked) {
            if (item?.gSCheckListID > 0) {
                this.gSCheckListUserCreate(item?.gSCheckListID)
            }
        }
        else {
            if (item?.gSCheckListID > 0) {
                this.gSCheckListUserDelete(item?.gSCheckListID)
            }
        }
    }

    async gSCheckListUserCreate(gscheckListID) {
        if (this.user?.cLPUserID > 0) {
            await this.userService.gSCheckListUserCreate(this.encryptedUser, this.user?.cLPUserID, gscheckListID)
                .then((result: SimpleResponse) => {
                    if (result) {
                        var response = UtilityService.clone(result);
                        //this._notifyService.showSuccess("Announcement deleted successfully", "", 3000);
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log('error in delete announcement' + err);
                });
        }
    }

    async gSCheckListUserDelete(gscheckListID) {
        if (this.user?.cLPUserID > 0) {
            await this.userService.gSCheckListUserDelete(this.encryptedUser, this.user?.cLPUserID, gscheckListID)
                .then((result: SimpleResponse) => {
                    if (result) {
                        var response = UtilityService.clone(result);
                        //this._notifyService.showSuccess("Announcement deleted successfully", "", 3000);
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log('error in delete announcement' + err);
                });
        }
    }
}
