import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataBindingDirective, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eButtonActions, eEditType, eFeatures, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { Lead, LeadListResponse } from '../../../models/lead.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { SearchQueryResponse } from '../../../models/search.model';
import { process } from '@progress/kendo-data-query';
import { NotificationService } from '../../../services/notification.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { LocalService } from '../../../services/shared/local.service';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { ContactCommonSearchService } from '../../../services/shared/contact-common-search.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LeadActionFiltersResponse, LookUpItem } from '../../../models/contactExcelUpload';
import { LeadBulkActions } from '../../../models/leadBulkActions.model';
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'lead-bulk-actions',
    templateUrl: './lead-bulk-actions.component.html',
    styleUrls: ['./lead-bulk-actions.component.css'],
    providers: [GridConfigurationService]
})

export class LeadBulkActionsComponent implements OnInit, OnDestroy {
    userResponse: UserResponse;
    eUserRole = eUserRole;
    user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    queryDataLoaded: SearchQueryResponse = <SearchQueryResponse>{};
    leadList: Lead[];
    initLeadList: Lead[];
    private encryptedUser: string = '';
    subscriptionQueryList: Subscription;
    showSpinner: boolean;
    gridHeight;
    baseUrl: string;
    isContactShow: boolean = false;
    isShowSearch: boolean = true;
    leadBulkActionMsg: string = '';
    mapRecentContact = [];
    showBulkGrid;

    columns = [{ field: '$', title: '', width: '40' },
    { field: '$$', title: '', width: '40' },
    { field: 'leadDesc', title: 'Lead', width: '100' },
    { field: 'lastFirst', title: 'Contact', width: '100' },
    { field: 'companyName', title: 'Company', width: '100' },
    { field: 'ufirstName', title: 'User', width: '100' },
    { field: 'dtStart', title: 'Start', width: '100' },
    { field: 'dtEnd', title: 'Close', width: '100' },
    { field: 'volume', title: 'Gross Revenue', width: '100' },
    { field: 'revenue', title: 'Net Revenue', width: '100' },
    { field: 'winProbability', title: 'Win Probability', width: '100' },
    { field: 'leadStatusCode', title: 'Status', width: '100' },
    { field: 'dtModified', title: 'Modified', width: '100' },
    { field: 'dtCreated', title: 'Created', width: '100' }];
    reorderColumnName: string = 'leadDesc,lastFirst,companyName,ufirstName,dtStart,dtEnd,volume,revenue,winProbability,leadStatusCode,dtModified,dtCreated';
    columnWidth: string = 'leadDesc:100,lastFirst:100,companyName:100,ufirstName:100,dtStart:100,dtEnd:100,volume,:100,revenue:100,winProbability:100,leadStatusCode:100,dtModified:100,dtCreated:100';
    arrColumnWidth: any[] = ['leadDesc:100,lastFirst:100,companyName:100,ufirstName:100,dtStart:100,dtEnd:100,volume:100,revenue:100,winProbability:100,leadStatusCode:100,dtModified:100,dtCreated:100'];
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

    mobileColumnNames: string[];
    isCheckAllLeads: boolean;
    bulkLeadSelection = [];
    leadBulkStep: number;
    public selectAllBulkLeadState: SelectAllCheckboxState;
    bulkEditLeadForm: FormGroup;
    bulkTransferBulkLeadForm: FormGroup;
    isEditBulkLeadAction: string;
    isSelectAllBulkLeads: boolean;

    /*dropdown*/
    leadBulkFieldDropdown: LookUpItem[];
    usersBulkLeadListDropDown: LookUpItem[];
    enumForEditType;
    editTypeKeys: unknown[];
    initEditTypeKeys: unknown[];
    classValueLeadDropDown: LookUpItem[];
    /*dropdown*/

    /* API data*/
    leadBulkActionsEdit: LeadBulkActions;
    eEditTypeBulkLead: eEditType;
    isShowSaveBulkLeadSubmit: boolean;
    initEditTypeKeysOriginal: any;
    isSaveBulkLeadEdit: boolean;
    /* API data*/
    dateFormat: string;
    buttonTypeOperation: eButtonActions = eButtonActions.None;
    showSelectedLeadCount: boolean;
    disableBtn: boolean;
    confirmTransfer: boolean = false;
    newOwnerToTransfer: string = '';
    constructor(private _notifyService: NotificationService,
        @Inject('BASE_URL') _baseUrl: string,
        public _gridCnfgService: GridConfigurationService,
        private _router: Router,
        private _localService: LocalService,
        private leadSettingService: LeadSettingService,
        private _globalService: GlobalService,
        private _utilityService: UtilityService,
        public notifyService: NotificationService,
        public _contactCommonSearchService: ContactCommonSearchService,
        private _ngZone: NgZone,
        private fb: FormBuilder) {
        this.gridHeight = this._localService.getGridHeight('464px');
        this._localService.isMenu = true;
        this.baseUrl = _baseUrl;
        this.leadList = [];
        this.initLeadList = [];
        this.classValueLeadDropDown = [];
        this.leadBulkStep = 1;
        this.isEditBulkLeadAction = 'default';
        this.showBulkGrid = false;
        this.isSelectAllBulkLeads = false;
        this.isShowSaveBulkLeadSubmit = false;
        this.isSaveBulkLeadEdit = false;
        this.enumForEditType = eEditType;
        this.editTypeKeys = Object.values(this.enumForEditType).filter(k => !isNaN(Number(k)));
        this.initEditTypeKeys = this.editTypeKeys;
        this.initEditTypeKeysOriginal = JSON.parse(JSON.stringify(this.editTypeKeys));
        this.leadBulkActionMsg = "Please select the field you would like to edit.";

    }
    ngOnDestroy(): void {
        this.subscriptionQueryList?.unsubscribe();
    }

    ngOnInit() {

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(async () => {
                    if (this.user) {
                        this.isContactShow = true;
                        this.getLeadList();
                        this.getGridConfiguration();
                        this.getQueryDataLoad();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    toogleCheckAllLeads() {
        !this.isCheckAllLeads ? this.onSelectLeadChange('checked') : this.onSelectLeadChange('unchecked')
        this.isCheckAllLeads = !this.isCheckAllLeads;
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.user = this.user;
        this._gridCnfgService.getGridColumnsConfiguration(this.user?.cLPUserID, 'lead_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('lead_grid').subscribe((value) => { }));
    }

    getQueryDataLoad() {
        this.subscriptionQueryList = this._contactCommonSearchService.getqueryLeadListChangedChangeEmitter().subscribe((data) => {
            this._ngZone.run(() => {
                this.queryDataLoaded = data;
                if (isNullOrUndefined(this.queryDataLoaded)) {
                    this.queryDataLoaded = <SearchQueryResponse>{};
                    this.leadList = [];
                    this.initLeadList = [];
                }
                else
                    this.getQueryData();
                this.leadBulkStep = 2;
                this.bulkLeadSelection = [];
                this.showBulkGrid = false;
            })
        });
    }

    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'lead_grid').subscribe((value) => this.getGridConfiguration());
    }


    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.BulkLeadActions)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    this.dateFormat = this.userResponse.user.dateFormat;
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lead-bulk-actions.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getQueryData() {
        this.showSpinner = true;
        await this.leadSettingService.searchSaveLead(this.encryptedUser, this.queryDataLoaded, this.user.cLPUserID)
            .then(async (result: LeadListResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.leadList = UtilityService.clone(result?.leads);
                    this.initLeadList = UtilityService.clone(result?.leads);
                    if (!isNullOrUndefined(this._gridCnfgService)) {
                        this._gridCnfgService.iterateConfigGrid(this.leadList, "lead_grid");
                        this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('lead_grid');
                    }
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    onLeadSearchFilter(inputValue: string): void {
        this.leadList = process(this.initLeadList, {
            filter: {
                logic: "or",
                filters: [
                    { field: 'leadDesc', operator: 'contains', value: inputValue },
                ],
            }
        }).data;
        if (!isNullOrUndefined(this.dataBinding) && !isNullOrUndefined(this.dataBinding.skip))
            this.dataBinding.skip = 0;
    }

    public gotoLink(columnName, dataItem) {
        switch (columnName) {
            case "address-card":
                const link = this._router.serializeUrl(this._router.createUrlTree([`/contact/${dataItem.cLPUserID}/${dataItem.contactID}`]));
                window.open(link, '_blank');
                break;
            case "name":
                if (this.user.timeZoneWinId != 0)
                    this._router.navigate(['/contact', dataItem.cLPUserID, dataItem.contactID]);
                else {
                    if (confirm("First , Please select your timezone!!!"))
                        this._router.navigate(['/edit-profile', dataItem.clpUserId]);
                    else
                        return;
                }
                break;
            case "userName":
                const userNameLink = this._router.serializeUrl(this._router.createUrlTree([`/edit-profile/${dataItem.cLPUserID}`]));
                window.open(userNameLink, '_blank');
                //this._router.navigate(['/edit-profile', dataItem.cLPUserID]);
                break;
            case "lead":
                const leadLink = this._router.serializeUrl(this._router.createUrlTree([`/lead-detail/${dataItem.leadID}`]));
                window.open(leadLink, '_blank');
                //this._router.navigate(['lead-detail', dataItem.leadID]);
                break;
            default:
                break;
        }
    }

    public onSelectLeadChange(checkedState: SelectAllCheckboxState): void {
        if (checkedState === "checked") {
            this.bulkLeadSelection = this.leadList.map((item) => item.leadID);
            this.selectAllBulkLeadState = "checked";
        } else {
            this.bulkLeadSelection = [];
            this.selectAllBulkLeadState = "unchecked";
        }
    }

    selectBulkLeads(type: string) {
        this.showSelectedLeadCount = true
        this.showBulkGrid = false;
        this.leadBulkStep = 3;
        this.isEditBulkLeadAction = 'default';
        this.showBulkGrid = true;
        this.editTypeKeys = JSON.parse(JSON.stringify(this.initEditTypeKeysOriginal));
        switch (type) {
            case 'all':
                this.isSelectAllBulkLeads = true;
                this.bulkLeadSelection = [];
                this.leadList?.forEach(lead => {
                    this.bulkLeadSelection.push(lead?.leadID)
                });
                break;
            case 'checked':
                this.isSelectAllBulkLeads = false;
                break;
            default:
                break;

        }
    }

    editLeadBulkField() {
        this.bulkEditLeadForm = this.prepareBulkEditLeadForm();
        this.isEditBulkLeadAction = 'edit';
        this.leadBulkActionMsg = "Please select the field you would like to edit.";
    }

    transferBulkLeadOwnerField() {
        this.bulkTransferBulkLeadForm = this.prepareBulkTransferBulkLeadForm();
        const bulkTransferLeadNewUser = this.bulkTransferBulkLeadForm.controls.ddValue.value;
        this.newOwnerToTransfer = this.usersBulkLeadListDropDown.find(x => x.value == bulkTransferLeadNewUser)?.text
        if (this.isSelectAllBulkLeads)
            this.leadBulkActionMsg = "You are about to transfer ownership of all " + this.bulkLeadSelection?.length + "selected leads to" + bulkTransferLeadNewUser + ".  Please confirm";
        else
            this.leadBulkActionMsg = "You are about to transfer ownership of all checked leads to" + bulkTransferLeadNewUser + ".  Please confirm";

        this.isEditBulkLeadAction = 'transfer';
    }

    confirmDeleteBulkLead() {
        if (this._utilityService.validateCheckbox(this.bulkLeadSelection, 'Lead')) {
            this.leadBulkActionsEdit = <LeadBulkActions>{};
            this.leadBulkActionsEdit.fieldName = "";
            this.leadBulkActionsEdit.fieldValue = "";
            this.leadBulkActionsEdit.fieldSelectedValue = "";
            this.leadBulkActionsEdit.leadsToRestore = [];

            this.eEditTypeBulkLead = 5;
            if (this.isSelectAllBulkLeads) {
                this.leadBulkActionsEdit.isRestoreAll = true;
                this.leadBulkActionsEdit.searchQuery = this.queryDataLoaded;
            }
            else {
                this.leadBulkActionsEdit.isRestoreAll = false;
                this.leadBulkActionsEdit.leadsToRestore = this.bulkLeadSelection;
            }
            this.buttonTypeOperation = eButtonActions.Delete;
            this.leadBulkActionsPerform('delete');
        }
    }

    prepareBulkEditLeadForm(): FormGroup {
        return this.fb.group({
            ddField: new FormControl(''),
            editType: new FormControl(-1),
            textValue: new FormControl(''),
        });
    }

    prepareBulkTransferBulkLeadForm(): FormGroup {
        return this.fb.group({
            ddValue: new FormControl('1'),
            trTransferSFA: new FormControl(false),
            cbTransferSFA: new FormControl(false),
        });
    }

    transferBulkLead() {
        this.leadBulkActionsEdit = <LeadBulkActions>{};
        this.leadBulkActionsEdit.fieldName = "";
        this.leadBulkActionsEdit.fieldValue = this.bulkTransferBulkLeadForm.controls.ddValue.value;
        this.leadBulkActionsEdit.fieldSelectedValue = "";
        this.leadBulkActionsEdit.leadsToRestore = [];
        this.eEditTypeBulkLead = 4;
        if (this.isSelectAllBulkLeads) {
            this.leadBulkActionsEdit.isRestoreAll = true;
            this.leadBulkActionsEdit.searchQuery = this.queryDataLoaded;
        }
        else {
            this.leadBulkActionsEdit.isRestoreAll = false;
            this.leadBulkActionsEdit.leadsToRestore = this.bulkLeadSelection;
        }
        this.buttonTypeOperation = eButtonActions.Save;
        this.leadBulkActionsPerform('transfer');

    }

    async saveBulkLeadsEdits() {
        const editValueTemp = +this.bulkEditLeadForm.controls.editType.value;
        this.copyValueBulkLeadEdit(editValueTemp);
        this.buttonTypeOperation = eButtonActions.Save;
        this.leadBulkActionsPerform('save');
    }

    copyValueBulkLeadEdit(editTypeValue) {
        this.leadBulkActionsEdit = <LeadBulkActions>{};
        this.leadBulkActionsEdit.fieldName = this.bulkEditLeadForm.controls.ddField.value;
        if (this.leadBulkActionsEdit.fieldName == 'Tag') {
            const bulkFieldValue = this.bulkEditLeadForm.controls.textValue.value;
            if (!this.isExistTagArray(bulkFieldValue)) {
                this.leadBulkActionsEdit.fieldValue = this.bulkEditLeadForm.controls.textValue.value;
                this.leadBulkActionsEdit.fieldSelectedValue = '-1';
            }
            else {
                this.leadBulkActionsEdit.fieldValue = this.bulkEditLeadForm.controls.textValue.value;
                this.leadBulkActionsEdit.fieldSelectedValue = this.leadBulkActionsEdit.fieldValue;
            }
        }
        else {
            this.leadBulkActionsEdit.fieldValue = this.bulkEditLeadForm.controls.textValue.value;
            this.leadBulkActionsEdit.fieldSelectedValue = this.leadBulkActionsEdit.fieldValue;
        }
        this.leadBulkActionsEdit.leadsToRestore = [];

        this.eEditTypeBulkLead = editTypeValue;
        if (this.isSelectAllBulkLeads) {
            this.leadBulkActionsEdit.isRestoreAll = true;
            this.leadBulkActionsEdit.searchQuery = this.queryDataLoaded;
        }
        else {
            this.leadBulkActionsEdit.isRestoreAll = false;
            this.leadBulkActionsEdit.leadsToRestore = this.bulkLeadSelection;
        }
    }

    isExistTagArray(bulkFieldValue: any) {
        const tagFilter: LookUpItem = this.leadBulkFieldDropdown.filter(val => val.value == 'Tag')[0];
        if (isNullOrUndefined(tagFilter))
            return false;
        else {
            const tagFilterMatchItem = tagFilter.items?.filter(val => val.value === 'bulkFieldValue')[0];
            (!isNullOrUndefined(tagFilterMatchItem)) ? true : false;
        }
    }

    changeBulkLeadEditField() {
        this.isShowSaveBulkLeadSubmit = false;
        this.renderConditionalType(this.bulkEditLeadForm.controls.ddField.value, this.bulkEditLeadForm.controls.editType.value)
    }

    changeBulkLeadDDFields() {
        this.initEditTypeKeys = JSON.parse(JSON.stringify(this.initEditTypeKeysOriginal));
        var indexRemove = this.initEditTypeKeys.indexOf(4); // 1
        var removedIndex = this.initEditTypeKeys.splice(indexRemove, 1);
        indexRemove = this.initEditTypeKeys.indexOf(5); // 1
        removedIndex = this.initEditTypeKeys.splice(indexRemove, 1);
        switch (this.bulkEditLeadForm?.controls?.ddField?.value) {
            case "LeadDesc": case "Scope": case "Comments": case "CustomText1": case "CustomText2": case "CustomText3": case "CustomText4": case "CustomText5": case "CustomText6":
                var indexEditThree = this.initEditTypeKeys.indexOf(3); // 1
                var removed = this.initEditTypeKeys.splice(indexEditThree, 1);
                this.editTypeKeys = this.initEditTypeKeys;
                break;
            case 'Tag':
                this.editTypeKeys = this.initEditTypeKeys.filter(value => value != 0 || value != 2);
                var indexEditZero = this.initEditTypeKeys.indexOf(0); // 1
                var removed = this.initEditTypeKeys.splice(indexEditZero, 1);
                var indexEditTwo = this.initEditTypeKeys.indexOf(2); // 1
                var removed = this.initEditTypeKeys.splice(indexEditTwo, 1);
                this.editTypeKeys = this.initEditTypeKeys;
                break;
            case 'Check1': case 'Check2': case 'Check3': case 'Check4': case 'Check5': case 'Check6':
                this.bulkEditLeadForm.controls.editType.setValue(0);
                this.bulkEditLeadForm.controls.textValue.setValue(true);
                break;
            default:
                var indexEditOne = this.initEditTypeKeys.indexOf(1); // 1
                var removed = this.initEditTypeKeys.splice(indexEditOne, 1);
                var indexEditThree = this.initEditTypeKeys.indexOf(3); // 1
                var removed = this.initEditTypeKeys.splice(indexEditThree, 1);
                this.editTypeKeys = this.initEditTypeKeys;
                break;
        }
    }
    renderConditionalType(ddField: any, editType: any) {
        var editTypeNum = +editType;
        switch (editTypeNum) {
            case eEditType.AddTo: this.leadBulkActionMsg = "Please select the value you would like to add.";
                break;
            case eEditType.Replace: this.leadBulkActionMsg = "Please select the new value.";
                break;
            case eEditType.Remove: this.leadBulkActionMsg = "Please select the item you would like to remove.";
                break;
            default:
                break;
        }
        switch (ddField) {
            case "Check1": case "Check2": case "Check3": case "Check4": case "Check5": case "Check6":
                this.bulkEditLeadForm.controls.textValue.setValue(false);
                break;
            case "Notes": case "Comments":
                break;
            case "dtStart": case "dtEnd": case "dtRevenue": case "dtCustom1": case "dtCustom2": case "dtCustom3":
                this.bulkEditLeadForm.controls.textValue.setValue(new Date());
                break;
            case "WinProbability": case "LeadStatusCode": case "LeadClass1Code": case "LeadClass2Code": case "LeadClass3Code": case "LeadClass4Code": case "LeadClass5Code": case "LeadClass6Code": case "LeadClass7Code": case "LeadClass8Code": case "LeadClass9Code":
                var currLeadFilter: LookUpItem = this.leadBulkFieldDropdown.filter(data => data?.value == ddField)[0];
                if (currLeadFilter?.items?.length > 0)
                    this.classValueLeadDropDown = currLeadFilter?.items;
                else
                    this.classValueLeadDropDown = [];
                this.bulkEditLeadForm.controls.textValue.setValue(0);
                break;
            case "Tag":
                var currLeadFilter: LookUpItem = this.leadBulkFieldDropdown.filter(data => data?.value == ddField)[0];
                if (currLeadFilter?.items?.length > 0)
                    this.classValueLeadDropDown = currLeadFilter?.items;
                else
                    this.classValueLeadDropDown = [];
                break;
            default:
                this.bulkEditLeadForm.controls.textValue.setValue("");
        }
    }

    bulkLeadConfirmEditSubmit() {
        const editLeadDDValue = +this.bulkEditLeadForm?.controls?.editType?.value;
        switch (editLeadDDValue) {
            case eEditType.Replace: case eEditType.AddTo: case eEditType.Remove:
                if (this.isSelectAllBulkLeads)
                    this.leadBulkActionMsg = "Please confirm the field and value you would like to edit for all  " + this.bulkLeadSelection?.length + "selected leads.";
                else
                    this.leadBulkActionMsg = "Please confirm the field and value you would like to edit for all  checked leads.";
                break;
            case eEditType.Clear:
                this.leadBulkActionMsg = "Please confirm that you would like to CLEAR the selected field for all " + this.isSelectAllBulkLeads ? this.bulkLeadSelection?.length + "selected leads." : 'checked leads.';
                break;
            default:
                break;
        }
        this.isShowSaveBulkLeadSubmit = true;
        this.disableValueField();
    }

    async getLeadList() {
        this.showSpinner = true;
        await this.leadSettingService.leadActionsFiltersLoad(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID)
            .then(async (result: LeadActionFiltersResponse) => {
                if (!isNullOrUndefined(result)) {
                    const filterLeadBulkResponse = UtilityService.clone(result);
                    this.leadBulkFieldDropdown = filterLeadBulkResponse?.leadFilter;
                    this.usersBulkLeadListDropDown = filterLeadBulkResponse?.filterManager;
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }
    onChangeNewOwner($event) {        
        this.newOwnerToTransfer = $event.target.options[$event.target.options.selectedIndex].text;
    }
    async leadBulkActionsPerform(actionType: string) {
        this.disableBtn = true
        await this.leadSettingService.leadBulkActions(this.encryptedUser, this.eEditTypeBulkLead, this.user?.cLPCompanyID, this.user?.cLPUserID, this.leadBulkActionsEdit)
            .then(async (result: SimpleResponse) => {
                this.buttonTypeOperation = eButtonActions.None;
                if (!isNullOrUndefined(result)) {
                    this.disableBtn = false
                    switch (actionType) {
                        case 'save':
                            if (this.isSelectAllBulkLeads)
                                this.leadBulkActionMsg = "Your bulk request has been submitted.We'll email you when it has been completed successfully.";
                            else
                                this.leadBulkActionMsg = "Leads have been successfully EDITED.";
                            this._notifyService.showSuccess('Lead Updated Successfully', '', 2000);
                            break;
                        case 'transfer':
                            this.leadBulkActionMsg = "Leads have been successfully TRANSFERRED.";
                            this._notifyService.showSuccess('Lead Transferred Successfully', '', 2000);
                            break;
                        case 'delete':
                            this.leadBulkActionMsg = "Leads have been successfully DELETED.";
                            this._notifyService.showSuccess('Lead Deleted Successfully', '', 2000);
                            break;
                    }

                    if (isNullOrUndefined(this.bulkLeadSelection) || this.bulkLeadSelection.length <= 0)
                        this.leadBulkActionMsg = "There were no leads checked.";
                    this.isSaveBulkLeadEdit = true;
                    this.leadBulkStep = 1;
                    this.bulkLeadSelection = [];
                    this.leadList = [];
                    this.isShowSearch = false;
                    setTimeout(() => this.isShowSearch = true, 0);
                    this.confirmTransfer = false
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.disableBtn = false
                console.log(err);
                this.buttonTypeOperation = eButtonActions.None;
                this._utilityService.handleErrorResponse(err);
            });
    }

    cancelBulkEditLeadAction() {
        this.isEditBulkLeadAction = 'default';
        this.leadBulkStep = 1;
        this.bulkLeadSelection = [];
        this.leadList = [];
        this.leadBulkActionMsg = "No changes have been made.";
        this.isSaveBulkLeadEdit = true;
        this.isShowSearch = false;
        setTimeout(() => this.isShowSearch = true, 0);
    }

    handleTagFilter(value) {
        this.classValueLeadDropDown = this.classValueLeadDropDown.filter(
            (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
    }

    startOver() {
        this.leadList = [];
        this.showBulkGrid = false;
        this.showSelectedLeadCount = false
        this.leadBulkStep = 1;
        this.isShowSearch = false;
        setTimeout(() => this.isShowSearch = true, 0);
    }

    checkColumns(value) {
        const classCodes = ['WinProbability', 'LeadStatusCode', 'LeadClass1Code', 'LeadClass2Code', 'LeadClass3Code', 'LeadClass4Code', 'LeadClass5Code', 'LeadClass6Code', 'LeadClass7Code', 'LeadClass8Code', 'LeadClass9Code'];
        const checks = ['Check1', 'Check2', 'Check3', 'Check4', 'Check5', 'Check6'];
        const textFields = ['Revenue', 'Volume', 'CustomMoney1', 'CustomMoney2', 'CustomMoney3', 'CustomMoney4'];
        const dateFields = ['dtStart', 'dtEnd', 'dtRevenue', 'dtCustom1', 'dtCustom2', 'dtCustom3'];
        switch (value) {
            case 'dateField':
                return dateFields.includes(this.bulkEditLeadForm.controls.ddField.value) ? true : false;
            case 'textField':
                return textFields.includes(this.bulkEditLeadForm.controls.ddField.value) ? true : false;
            case 'checkBox':
                return checks.includes(this.bulkEditLeadForm.controls.ddField.value) ? true : false;
            case 'dropDown':
                return classCodes.includes(this.bulkEditLeadForm.controls.ddField.value) ? true : false;
            default:
                return false;
        }
    }

    getSelectedFieldText(value: any) {
        return this.leadBulkFieldDropdown?.find(x => x.value == value)?.text;
    }

    disableValueField() {
        this.bulkEditLeadForm.controls['textValue'].disable();
    }
}
