<div class="custom-security-link">
  <a [routerLink]="['/account-setting']">SO Account Setup</a>
</div>
<div class="margin-all-area">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../../assets/salesoptimatitle.svg" class="mr-1" />Free Trial List</div>
      </div>
      <div *ngIf="strMessage != ''" class="message-info" [innerHTML]="strMessage"></div>
      <div class="global-body-section">
        <kendo-grid [kendoGridBinding]="freeTrialList" class="grid-height"
                    [sortable]="true"
                    [reorderable]="true"
                    [resizable]="true"
                    [columnMenu]="{ filter: true }"
                    [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                    [pageSize]="10"
                    [scrollable]="'scrollable'">

          <kendo-grid-column field="dtCreated" title="Created" [width]="60">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div>{{ dataItem.dtCreated | date : dateFormat}} &nbsp;{{ dataItem.dtCreated | date:'shortTime'}}</div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="contactSummary" title="Contact Summary" [width]="200">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [innerHTML]="dataItem.contactSummary"></div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="cLPCompanyID" title="SO Account ID" [width]="20"> </kendo-grid-column>

          <kendo-grid-column field="companyName" title="CompanyName" [width]="60">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div *ngIf="dataItem.companyName != null">{{ dataItem.companyName}}</div>
              <div *ngIf="dataItem.companyName == null">--</div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="referredBy" title="Referred By" [width]="60">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div *ngIf="dataItem.referredBy != null">{{ dataItem.referredBy}}</div>
              <div *ngIf="dataItem.referredBy == null">--</div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="$" title="Clone ID" [width]="60">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div><input type="text" id="cloneid" (keypress)="_localService.onKeyDown($event)" [(ngModel)]="dataItem.cloneClpCompanyId" /></div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="$$" title="" [width]="40">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div><button type="button" (click)="processOrder(dataItem.freeTrialID)" class="btn btn-primary">Create</button></div>
            </ng-template>
          </kendo-grid-column>

        </kendo-grid>
      </div>
    </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
