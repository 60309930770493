import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eFeatures } from '../../../models/enum.model';
import { GlobalService } from '../../../services/global.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { isNullOrUndefined, UtilityService } from '../../../services/shared/utility.service';
import { VoiceCallService } from '../../../services/voice-call.service';

@Component({
    selector: 'app-playaudio',
    templateUrl: './playaudio.component.html',
    styleUrls: ['./playaudio.component.css']
})
export class PlayaudioComponent {
    private encryptedUser: string = '';
    showSpinner: boolean = false;
    userResponse: UserResponse;
    user: CLPUser;
    dateFormat: string = "MM/dd/yyyy";
    dateFormatWithTime: string = "";
    routeParamsSubscription: Subscription;
    litOutPut: string = "Unrecognized format";
    litLog: string = "";
    id: number = 0;
    tpe: string = "";
    adm: number = 0;
    isFromRec: boolean = true;

    constructor(private _utilityService: UtilityService,
        private _notifyService: NotificationService,
        private _router: Router,
        private route: ActivatedRoute,
        public _localService: LocalService,
        private _globalService: GlobalService,
        private _voiceCallService: VoiceCallService) {

    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.dateFormat = this.user.dateFormat;
                        this.dateFormatWithTime = this.user.dateFormat + ' ' + ' h:mm a';
                        this.routeParamsSubscription = this.route.queryParams.subscribe(params => {
                            if (!isNullOrUndefined(params)) {
                                if (params?.id > 0) {
                                    this.id = +params?.id;
                                }
                                if (!isNullOrEmptyString(params?.tpe)) {
                                    this.tpe = params?.tpe;
                                }
                                if (params?.adm > 0) {
                                    this.adm = +params?.adm;
                                }
                            }
                        })
                        if (this.id > 0 && !isNullOrEmptyString(this.tpe)) {
                            this.initloadData();
                        }
                    }
                    else
                        this._router.navigate(['/login']);
                })
            }
            else
                this._router.navigate(['/login']);
        });
    }

    async initloadData() {
        await this._voiceCallService.playAudio(this.encryptedUser, this.tpe, this.id)
            .then(async (result) => {
                if (result) {
                    this.litOutPut = result?.messageString
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-activity-history.getVoiceCallLoad", err.message, null,
                    'id: ' + this.id
                );
                this._utilityService.handleErrorResponse(err);
            });
    }



    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this._localService.isMenu = false;
                            this._localService.isFooter = false;
                        }
                        else {
                            this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("playaudio.authenticateR", err.message, null);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });

    }
}
