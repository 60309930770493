import { Component } from '@angular/core';

@Component({
  selector: 'app-rpt-tworoadspipeline',
  templateUrl: './rpt-tworoadspipeline.component.html',
  styleUrl: './rpt-tworoadspipeline.component.css'
})
export class RptTworoadspipelineComponent {

}
