import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { Observable, Subscription, timer } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { eFeatures, eLiveConnectCheckedInMethod, eLiveConnectCLPUserStatus, eLiveConnectItemActionStatus, eLiveConnectItemObjectType, eLiveConnectItemStatus, eNoteOwnerType, eUserRole } from '../../../models/enum.model';
import { IntDropDownItem, SimpleResponse } from '../../../models/genericResponse.model';
import { LiveConnectItem } from '../../../models/live-connect-item.model';
import { ContactArchive, LiveConnectContact, LiveConnectContactObj, LiveConnectContactResponse, LiveConnectDashHeaderResponse, LiveConnectDashLoadRequest, LiveConnectDashLoadResponse, LiveConnectHeader, LiveConnectHeaderResponse } from '../../../models/live-connect.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { LiveConnectService } from '../../../services/live-connect.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { GlobalService } from '../../../services/global.service';
import { MailMergeTemplateLoad } from '../../../models/marketing.model';
import { MarketingService } from '../../../services/marketing.service';
@Component({
    selector: 'archive-liveconnect-dash',
    templateUrl: './archive-liveconnect-dash.component.html',
    styleUrls: ['./archive-liveconnect-dash.component.css']
})
/** alert-liveconnect-dash component*/
export class ArchiveLiveconnectDashComponent {
    /** liveconnect-dashboard ctor */
    showSection: number = 1;
    mailMergeTemplateResponse: MailMergeTemplateLoad;
    eLiveConnectItemObjectType = eLiveConnectItemObjectType;
    eLiveConnectItemStatus = eLiveConnectItemStatus;
    liveConnectDashboardLoadResponse: LiveConnectDashLoadResponse;
    liveConnectDashHeaderResponse: LiveConnectDashHeaderResponse;
    liveConnectHeader: LiveConnectHeader;
    liveConnectItemList: LiveConnectItem[] = [];
    isSRS: boolean = false;
    htmlDisplay: any;
    userResponse: UserResponse;
    lcClpuserDD: IntDropDownItem[]
    roleFeaturePermissions: RoleFeaturePermissions;
    lcContactList: LiveConnectContact[] = [];
    user: CLPUser;
    objectId: number = 0;
    objectType: eLiveConnectItemObjectType = eLiveConnectItemObjectType.Unknown;
    eLiveConnectItemActionStatus = eLiveConnectItemActionStatus;
    pinnedUserList: IntDropDownItem[] = [];
    @Input() mailMergeTemplateId: number;
    isStart: boolean = false;
    isShowAdvanced: boolean = false;

    //everyFiveSeconds: Observable<number> = timer(0, 2000);
    //subscription: Subscription;
    lcContactView: LiveConnectItem;
    filterLiveConnect: string[];

    dtStart: Date = new Date();
    dtEnd: Date = new Date();
    todaysDate = new Date();
    dateFormat: string = "MM/dd/yyyy";
    encryptedUser: string = '';
    txtNote: string = '';
    pinnedUserId: string = '0';
    ddLiveConnectName: string = "";
    ddRating: string = "";
    ddType: string = "0";
    txtSearchStr: string = "";
    editRowIndex: number = -1;
    eStatus: eLiveConnectItemStatus;
    /*variable for child component*/
    selectedLiveConnectObj: LiveConnectItem = <LiveConnectItem>{};
    isShowContactScreen: boolean = false;
    isShowReplyScreen: boolean = false;
    isShowApptScreen: boolean = false;
    isShowCallScreen: boolean = false;
    isShowNoteScreen: boolean = false;
    isShowEmailScreen: boolean = false;
    isShowMakeCallScreen: boolean = false;
    isShowHandleTmScreen: boolean = false;
    isShowEditContact: boolean = false;
    isShowContactHistory: boolean = false;
    isShowMergeMailScreen: boolean = false;
    showSpinner: boolean = false;
    isSRSManager: boolean = false;
    isLCUser: boolean = false;
    isUseImage: boolean = false;
    logo: string = "";
    company: ClpCompany = <ClpCompany>{};
    contactName: string = "";
    phoneNumber: string = "";
    contactEmail: string = "";
    selectLiveConnectId: string = "";
    showView: boolean = false;
    createCardUserList: IntDropDownItem[] = [];
    selectedLiveConnectClpUserId: number = 0;
    minDate: Date = new Date(1754, 1, 1);
    maxDate: Date = new Date(9998, 1, 1);
    lcClpUserDDValue: number = 2;
    constructor(private datePipe: DatePipe,
        private _utilityService: UtilityService, private _globalService: GlobalService, private _accountSetupService: AccountSetupService,
        private _router: Router, private _localService: LocalService, private _marketingService: MarketingService,
        private route: ActivatedRoute, private _liveConnectSrvc: LiveConnectService) {
        this.route.paramMap.subscribe(async params => {
            if (params.has('status')) {
                this.eStatus = +params.get('status');
            }
            if (!isNullOrUndefined(localStorage.getItem("contactID"))) {
                this.txtSearchStr = localStorage.getItem("contactID");
            }
        })
    }

    ngOnInit() {
        this._router.routeReuseStrategy.shouldReuseRoute = () => false;
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this._localService.isMenu = false;
                        this.dateFormat = this.user.dateFormat;
                        this.dtStart = new Date(this.dtStart.setDate(this.dtStart.getDate() - 14));
                        this.loadInit();
                    }
                    else {
                        this._router.navigate(['/login']);
                    }
                })
            }
            else {
                this._router.navigate(['/login']);
            }
        })
    }

    async loadInit() {
        this.showSpinner = true;
        await this.liveConnect_isLiveConnectSRSAccount();
        await this.liveConnectCLPUser_isLiveConnectCLPUser();
        await this.getCompanyData();
        if (this.user.cLPUserID > 0 && (this.isLCUser || this.user.slurpyUserId > 0 || this.isSRSManager)) {
            if (this.isUseImage)
                this.logo = this.company?.logoURL;
        }
        await this.getLiveConnectDashboardHeaderResponse(true);
        this.showSpinner = false;
    }


    async getCompanyData() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.company = response?.company;
                    this.isUseImage = response.company.useImage;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("archive-liveconnect.authenticateR", err.message, null, 'Features ' + eFeatures.None + 'cLPUserID ' + this.user.cLPUserID + 'slurpyUserId ' + this.user?.slurpyUserId);
                this._utilityService.handleErrorResponse(err);
            });
    }
    async liveConnect_isLiveConnectSRSAccount() {
        await this._liveConnectSrvc.liveConnect_isLiveConnectSRSAccount(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.isSRS = result?.messageBool;
                    this.isSRSManager = (this.isSRS && this.user?.userRole > eUserRole.Manager) ? true : false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("archive-liveconnect.authenticateR", err.message, null, 'Features ' + eFeatures.None + 'cLPUserID ' + this.user.cLPUserID + 'slurpyUserId ' + this.user?.slurpyUserId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async liveConnectCLPUser_isLiveConnectCLPUser() {
        await this._liveConnectSrvc.liveConnectCLPUser_isLiveConnectCLPUser(this.encryptedUser, this.user?.cLPUserID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.isLCUser = result?.messageBool;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("archive-liveconnect.authenticateR", err.message, null, 'Features ' + eFeatures.None + 'cLPUserID ' + this.user.cLPUserID + 'slurpyUserId ' + this.user?.slurpyUserId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse?.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("archive-liveconnect.authenticateR", err.message, null, 'Features ' + eFeatures.None + 'cLPUserID ' + this.user.cLPUserID + 'slurpyUserId ' + this.user?.slurpyUserId);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async getLiveConnectDashboardHeaderResponse(isFirstLoad: boolean = false) {
        if (isFirstLoad)
            this.showView = false;
        this.showSpinner = true;
        this._liveConnectSrvc.liveConnectDashBoardHeader_Load(this.encryptedUser)
            .then(async (result: LiveConnectDashHeaderResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.liveConnectDashHeaderResponse = UtilityService.clone(result);
                    this.liveConnectHeader = this.liveConnectDashHeaderResponse?.liveConnectHeader;
                    await this.getLiveConnectDashboardItemsList();
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;

                if (isFirstLoad)
                    this.showView = true;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("archive-liveconnect.getLiveConnectDashboardHeaderResponse", err.message, null);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getLiveConnectDashboardItemsList() {
        var liveConnectDashboardObj: LiveConnectDashLoadRequest = <LiveConnectDashLoadRequest>{};
        liveConnectDashboardObj.clpCompanyID = this.user?.cLPCompanyID;
        liveConnectDashboardObj.isFilerVisible = this.isStart ? false : true;
        liveConnectDashboardObj.eStatus = this.eStatus;

        if (+this.selectedLiveConnectClpUserId > 0)
            liveConnectDashboardObj.liveConnectClpuserID = +this.selectedLiveConnectClpUserId;
        else
            liveConnectDashboardObj.liveConnectClpuserID = this.user?.cLPUserID;

        if (this.eStatus == eLiveConnectItemStatus.Alerts || this.eStatus == eLiveConnectItemStatus.Closed)
            liveConnectDashboardObj.pinnedCLPUserID = 0;
        else if (+this.selectedLiveConnectClpUserId > 0)
            liveConnectDashboardObj.pinnedCLPUserID = +this.selectedLiveConnectClpUserId;
        else
            liveConnectDashboardObj.pinnedCLPUserID = this.user?.cLPUserID;
        /* liveConnectDashboardObj.pinnedCLPUserID = this.eStatus == eLiveConnectItemStatus.Alerts || this.eStatus == eLiveConnectItemStatus.Closed ? 0 : this.user?.cLPUserID;*/

        if (this.isShowAdvanced) {
            if (!isNullOrEmptyString(this.dtEnd) && !isNullOrEmptyString(this.dtStart)) {
                if (this.dtEnd > this.maxDate || this.dtEnd < this.minDate || this.dtStart > this.maxDate || this.dtStart < this.minDate) {
                    return;
                }
            }
            liveConnectDashboardObj.endDate = this.datePipe.transform(this.dtEnd, 'MMddyyyy');
            liveConnectDashboardObj.startDate = this.datePipe.transform(this.dtStart, 'MMddyyyy');
        }

        if (!isNullOrEmptyString(this.ddType) && this.ddType != "0")
            liveConnectDashboardObj.eType = eLiveConnectItemObjectType[this.ddType];

        if (!isNullOrEmptyString(this.ddLiveConnectName))
            liveConnectDashboardObj.selectedLiveConnectName = this.ddLiveConnectName;

        if (!isNullOrEmptyString(this.ddRating))
            liveConnectDashboardObj.selectedRating = this.ddRating;

        if (!isNullOrEmptyString(this.txtSearchStr))
            liveConnectDashboardObj.strSearchItem = this.txtSearchStr;
        this._liveConnectSrvc.liveConnectAlert_GetList(this.encryptedUser, liveConnectDashboardObj)
            .then(async (result: LiveConnectDashLoadResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.liveConnectDashboardLoadResponse = UtilityService.clone(result);
                    this.liveConnectItemList = this.liveConnectDashboardLoadResponse?.liveConnectItemList;
                    this.lcClpuserDD = this.liveConnectDashboardLoadResponse?.lcClpuserDD;
                    /*this.getCreateCardDD()*/
                    if (this.liveConnectItemList?.length == 0) {
                        if (this.txtSearchStr.includes("@") || this.txtSearchStr.length == 10) {
                            this.lcContactList = this.liveConnectDashboardLoadResponse?.lcContactList;
                            this.lcClpuserDD = this.liveConnectDashboardLoadResponse?.lcClpuserDD;
                            this.lcContactList.forEach(x => x.createCardLiveConnectId = x.liveConnectID);
                            if (this.lcContactList.length > 0) {
                                this.showSection = 2;
                            } else {
                                if (this.txtSearchStr.includes("@"))
                                    this.contactEmail = this.txtSearchStr;
                                if (this.txtSearchStr.length == 10)
                                    this.phoneNumber = this.txtSearchStr;
                                this.showSection = 3;
                            }
                        } else {
                            this.showSection = 0;
                        }
                    } else
                        this.showSection = 1;

                    this.pinnedUserList = this.liveConnectDashboardLoadResponse?.filterUser;
                    this.filterLiveConnect = this.liveConnectDashboardLoadResponse?.filterLiveConnect;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("archive-liveconnect.getLiveConnectDashboardItemsList", err.message, liveConnectDashboardObj);
                this._utilityService.handleErrorResponse(err);
            });
    }

    bindNewContactCard(liveConnectItemObj: LiveConnectItem) {
        this._liveConnectSrvc.bindNewContactCard(this.encryptedUser, liveConnectItemObj?.contactID, liveConnectItemObj?.liveConnectID)
            .then((result: LiveConnectContactResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.lcContactList = UtilityService.clone(result?.lcContactList);
                    if (this.lcContactList?.length > 0) {
                        this.showSection = 2;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("archive-liveconnect.bindNewContactCard", err.message, null, 'contactID ' + liveConnectItemObj?.contactID + 'liveConnectID ' + liveConnectItemObj?.liveConnectID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    onRefresh() {
        this.todaysDate = new Date();
        this.getLiveConnectDashboardItemsList();
    }

    async lcArchiveHandleEvent(liveConnectItemObj: LiveConnectItem, status: eLiveConnectItemActionStatus) {
        this._liveConnectSrvc.lcArchiveHandleEvent(this.encryptedUser, liveConnectItemObj, status, status == eLiveConnectItemActionStatus.Pinned ? (+ this.pinnedUserId) : 0, this.user?.cLPUserID)
            .then(async (result: any) => {
                if (!isNullOrUndefined(result)) {
                    if (status == eLiveConnectItemActionStatus.Pinned) {
                        this.editRowIndex = -1;
                        this.txtNote = liveConnectItemObj.notes;
                        this.pinnedUserId = '0';
                    }
                    this.getLiveConnectDashboardHeaderResponse();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("archive-liveconnect.lcArchiveHandleEvent", err.message, liveConnectItemObj, 'eStatus ' + status + 'toPinnedClpuserID ' + (status == eLiveConnectItemActionStatus.Pinned ? (+ this.pinnedUserId) : 0) + 'cLPUserID ' + this.user?.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    onChangePinnedUserList(liveConnectItemObj: LiveConnectItem) {
        if (+this.pinnedUserId == -1 || +this.pinnedUserId > 0) {
            liveConnectItemObj.notes = this.txtNote ?? "";
            this.lcArchiveHandleEvent(liveConnectItemObj, eLiveConnectItemActionStatus.Pinned)
        } else {
            this.editRowIndex = -1;
            this.txtNote = "";
            this.pinnedUserId = '0';
        }
    }

    getDataByObjectType(liveConnectItemObj: LiveConnectItem, value: string) {
        var typeDisplay: string = "";
        switch (+liveConnectItemObj?.objectType) {
            case eLiveConnectItemObjectType.VoiceCall:
                typeDisplay = "Call";
                break;
            case eLiveConnectItemObjectType.VoiceMail:
                typeDisplay = "Voicemail";
                break;
            case eLiveConnectItemObjectType.TxtMsgIB:
                typeDisplay = "SMS";
                break;
            case eLiveConnectItemObjectType.Slidecast:
                typeDisplay = "Slidecast";
                break;
            case eLiveConnectItemObjectType.Agreement:
                typeDisplay = "Agreement";
                break;
            case eLiveConnectItemObjectType.DynamicScheduledApptDep: case eLiveConnectItemObjectType.Appt:
                typeDisplay = "Event";
                break;
            case eLiveConnectItemObjectType.Contact:
                typeDisplay = "Contact";
                break;
            case eLiveConnectItemObjectType.AutomationAlert:
                typeDisplay = this.extractAutomationTypeDisplay(liveConnectItemObj.itemDetail);
                break;
        }
        switch (value) {
            case "typeDisplay":
                return typeDisplay;
        }
    }

    extractAutomationTypeDisplay(itemDetail: string) {
        var strTitle: string = "Alert";
        var isExist: boolean = itemDetail.includes("~Contact:");
        if (isExist) {
            strTitle = itemDetail.split("Contact:")[0].replace("~", "");
            return strTitle;
        }
    }

    extractAutomationItemDetails(liveConnectItemObj: LiveConnectItem) {
        var strOut: string = liveConnectItemObj?.itemDetail;
        var isExist: boolean = liveConnectItemObj?.itemDetail.includes("~Contact:");
        if (isExist) {
            strOut = liveConnectItemObj?.itemDetail.split("Contact:")[1];
        }
        if (!isNullOrEmptyString(liveConnectItemObj.rating)) {
            strOut += "<br /><span style='font-weight: bold; color:black; font-size:10pt;'><b>Rating:</b>" + liveConnectItemObj.rating.replace("SM", "<span style='color:red;'>SM</span>") + "</span>";
        }
        if (!isNullOrEmptyString(liveConnectItemObj.notes)) {
            strOut += "<br /><span style='font-weight: bold; color:green; font-size:10pt;'>" + liveConnectItemObj.notes + "</span>";
        }
        return strOut;
    }

    getHandledBy(liveConnectItemObj: LiveConnectItem) {
        var strOut: string = "";
        if (!isNullOrEmptyString(liveConnectItemObj?.dtHandled)) {
            strOut = "handled " + this.datePipe.transform(liveConnectItemObj?.dtHandled, "MM/dd/yyyy h:mm a");
            if (liveConnectItemObj?.isSRS && this.user?.slurpyUserId <= 0)
                strOut += " by SRS";
            else {
                if (!isNullOrEmptyString(liveConnectItemObj?.repName))
                    strOut += " by " + liveConnectItemObj.repName;
            }
        }
        return strOut;
    }

    onAdvance() {
        this.isShowAdvanced = !this.isShowAdvanced;
        if (this.isShowAdvanced == true) {
            this.dtStart = new Date(this.dtStart.setDate(this.dtStart.getDate() - 14));
            this.dtEnd = new Date();
            this.getLiveConnectDashboardItemsList();
        } else {
            this.getLiveConnectDashboardItemsList();
        }
    }

    redirectItem(liveConnectItemObj: LiveConnectItem, isFromOtherChild: boolean = false, objectType: eLiveConnectItemObjectType = eLiveConnectItemObjectType.Unknown) {
        this.editRowIndex = -1;
        this.isShowContactScreen = false;
        this.isShowApptScreen = false;
        this.isShowCallScreen = false;
        this.isShowReplyScreen = false;
        this.isShowNoteScreen = false;
        this.isShowEmailScreen = false;
        this.isShowMakeCallScreen = false;
        this.isShowHandleTmScreen = false;
        this.isShowEditContact = false;
        this.isShowContactHistory = false;
        this.isShowMergeMailScreen = false;
        this.selectedLiveConnectObj = UtilityService.clone(liveConnectItemObj);
        this.selectedLiveConnectObj.objectID = isFromOtherChild ? liveConnectItemObj.contactID : liveConnectItemObj.objectID;
        this.selectedLiveConnectObj.objectType = isFromOtherChild ? objectType : liveConnectItemObj.objectType;
        switch (this.selectedLiveConnectObj.objectType) {
            case eLiveConnectItemObjectType.TxtMsgIB:
                this.isShowReplyScreen = false;
                setTimeout(() => this.isShowReplyScreen = true, 0);
                break;
            case eLiveConnectItemObjectType.VoiceCall:
                this.isShowCallScreen = false;
                setTimeout(() => this.isShowCallScreen = true, 0);
                break;
            case eLiveConnectItemObjectType.Appt:
                this.isShowApptScreen = false;
                setTimeout(() => this.isShowApptScreen = true, 0);
                break;
            default:
                this.isShowContactScreen = false;
                setTimeout(() => this.isShowContactScreen = true, 0);
                break;
        }
    }


    openSubChilds(liveConnectItemObj: LiveConnectItem, show: string = 'note') {
        this.isShowContactScreen = false;
        this.isShowApptScreen = false;
        this.isShowCallScreen = false;
        this.isShowReplyScreen = false;
        this.isShowNoteScreen = false;
        this.isShowEmailScreen = false;
        this.isShowMakeCallScreen = false;
        this.isShowHandleTmScreen = false;
        this.isShowEditContact = false;
        this.isShowContactHistory = false;
        this.isShowMergeMailScreen = false;
        this.selectedLiveConnectObj = UtilityService.clone(liveConnectItemObj);
        this.selectedLiveConnectObj.objectID = liveConnectItemObj.contactID;
        this.selectedLiveConnectObj.objectType = eNoteOwnerType.Contact;
        switch (show) {
            case "note":
                this.isShowNoteScreen = false;
                setTimeout(() => this.isShowNoteScreen = true, 0);
                break;
            case "email":
                this.isShowEmailScreen = false;
                setTimeout(() => this.isShowEmailScreen = true, 0);
                break;
            case "makecall":
                this.isShowMakeCallScreen = false;
                setTimeout(() => this.isShowMakeCallScreen = true, 0);
                break;
            case "sendSMS":
                this.isShowHandleTmScreen = false;
                setTimeout(() => this.isShowHandleTmScreen = true, 0);
                break;
            case "editContact":
                this.isShowEditContact = false;
                setTimeout(() => this.isShowEditContact = true, 0);
                break;
            case "contactHistory":
                this.isShowContactHistory = false;
                setTimeout(() => this.isShowContactHistory = true, 0);
                break;
        }
    }

    //getCreateCardDD() {
    //    if (this.createCardUserList.length == 0) {
    //        this.createCardUserList.push({ id: 0, text: "-Contact Owner's Dash" })
    //        this.lcClpuserDD.forEach((num) =>
    //            this.createCardUserList.push({ id: num.id, text: num.text })
    //        )
    //    }
    //}

    getCreateCardDD(liveConnectId: number) {
        var createCardUserList: IntDropDownItem[] = [];
        if (!this.isSRS) {
            createCardUserList = UtilityService.clone(this.lcClpuserDD);
            if (createCardUserList?.length > 0) {
                createCardUserList.unshift({ id: liveConnectId, text: "-Contact Owner's Dash" })
                return createCardUserList;

            } else {
                createCardUserList = [];
                return createCardUserList;
            }
        } else {
            createCardUserList = [];
            return createCardUserList;
        }
    }

    onChangeUserDD(event) {
        this.lcClpUserDDValue = event?.target?.value;
    }

    async lcAlertArchive(data: LiveConnectContact) {
        var contactArchive: ContactArchive = <ContactArchive>{};
        contactArchive.contactID = data?.contactID;
        contactArchive.liveConnectID = data?.liveConnectID;
        contactArchive.clpUserId = this.user?.cLPUserID;
        if (!this.isSRS) {
            contactArchive.liveConnectID = this.lcClpUserDDValue;
        }
        this._liveConnectSrvc.lcAlertArchive(this.encryptedUser, contactArchive)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.txtSearchStr = "";
                    this.getLiveConnectDashboardHeaderResponse();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("archive-liveconnect.lcAlertArchive", err.message, contactArchive);
                this._utilityService.handleErrorResponse(err);
            });
    }


    createContact() {
        var contactbj: LiveConnectContactObj = <LiveConnectContactObj>{};
        contactbj.name = this.contactName;
        contactbj.mobile = this.phoneNumber;
        contactbj.email = this.contactEmail;
        contactbj.liveConnectId = +this.selectLiveConnectId;
        contactbj.clpCompanyId = this.user?.cLPCompanyID;
        contactbj.clpUserId = this.user?.cLPUserID;
        this._liveConnectSrvc.alertContactCreate(this.encryptedUser, contactbj)
            .then((result: SimpleResponse) => {
                if (result) {
                    this.txtSearchStr = "";
                    this.getLiveConnectDashboardItemsList();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("archive-liveconnect.createContact", err.message, contactbj);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getArchive(contactID: number) {
        const url = this._router.serializeUrl(
            this._router.createUrlTree(['/archive-live-connect', 3]));
        localStorage.setItem("contactID", contactID.toString());
        window.open(url, "_blank");
    }

    getBorderCssByEventType(liveConnectItemObj: LiveConnectItem) {
        switch (+liveConnectItemObj?.objectType) {
            case eLiveConnectItemObjectType.VoiceCall:
                return "voice-call-border";
            case eLiveConnectItemObjectType.VoiceMail:
                return "voice-mail-border";
            case eLiveConnectItemObjectType.TxtMsgIB:
                return "text-msg-border";
            case eLiveConnectItemObjectType.Slidecast:
                return "slidecast-border";
            case eLiveConnectItemObjectType.Agreement:
                return "agreement-border";
            case eLiveConnectItemObjectType.DynamicScheduledApptDep: case eLiveConnectItemObjectType.Appt:
                return "dynamic-border";
            case eLiveConnectItemObjectType.Contact:
                return "contact-border";
            case eLiveConnectItemObjectType.AutomationAlert:
                return "automation-alert-border";
        }
    }

    getBtnCssByEventType(liveConnectItemObj: LiveConnectItem) {
        switch (+liveConnectItemObj?.objectType) {
            case eLiveConnectItemObjectType.VoiceCall:
                return "voice-call-btn";
            case eLiveConnectItemObjectType.VoiceMail:
                return "voice-mail-btn";
            case eLiveConnectItemObjectType.TxtMsgIB:
                return "text-msg-btn";
            case eLiveConnectItemObjectType.Slidecast:
                return "slidecast-btn";
            case eLiveConnectItemObjectType.Agreement:
                return "agreement-btn";
            case eLiveConnectItemObjectType.DynamicScheduledApptDep: case eLiveConnectItemObjectType.Appt:
                return "dynamic-btn";
            case eLiveConnectItemObjectType.Contact:
                return "contact-btn";
            case eLiveConnectItemObjectType.AutomationAlert:
                return "automation-alert-btn";
        }
    }

    async loadMailMergeTemplate(mailMergeTemplateId: number) {
        this.mailMergeTemplateId = mailMergeTemplateId;

        await this._marketingService.loadMailMergeTemplate(this.encryptedUser, mailMergeTemplateId)
            .then(async (result: MailMergeTemplateLoad) => {
                if (result) {
                    this.mailMergeTemplateResponse = UtilityService.clone(result);
                    /*this.templateName = this.mailMergeTemplateResponse.templateName;*/
                    this.htmlDisplay = this.mailMergeTemplateResponse.hTMLText;

                    this.isShowMergeMailScreen = true;
                    this.isShowContactScreen = false;
                    this.isShowApptScreen = false;
                    this.isShowCallScreen = false;
                    this.isShowReplyScreen = false;
                    this.isShowNoteScreen = false;
                    this.isShowEmailScreen = false;
                    this.isShowMakeCallScreen = false;
                    this.isShowHandleTmScreen = false;
                    this.isShowEditContact = false;
                    this.isShowContactHistory = false;
                    //this.isShowActivityHistory = false;
                    //this.isShowUpperTabs = true;

                }

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);

            });
    }
}
