import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataBindingDirective, PageChangeEvent } from '@progress/kendo-angular-grid';
import { FileRestrictions, SuccessEvent } from '@progress/kendo-angular-upload';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserDD, UserResponse } from '../../models/clpuser.model';
import { eButtonActions, eFeatures, eTxtMsgSettingsStatus, eUserPermissions, eUserRole } from '../../models/enum.model';
import { RoleFeaturePermissions } from '../../models/roleContainer.model';
import { process } from '@progress/kendo-data-query';
import { TxtMsgTemplate, TxtMsgTemplateListResponse } from '../../models/textMsgTemplate.model';
import { GridColumnsConfigurationService } from '../../services/gridColumnsConfiguration.service';
import { NotificationService } from '../../services/notification.service';
import { GridConfigurationService } from '../../services/shared/gridConfiguration.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import { TextMsgTemplateService } from '../../services/text-msg-template.service';
import { SimpleResponse } from '../../models/genericResponse.model';
import { UserService } from '../../services/user.service';
import { ClpCompany, CompanyResponse } from '../../models/company.model';
import { AccountSetupService } from '../../services/accountSetup.service';
import { TxtMsgSettingLoadResponse, TxtMsgSettings } from '../../models/txtMsg.model';
import { TxtMsgService } from '../../services/textmsg.service';
import { GlobalService } from '../../services/global.service';

declare var $: any;

@Component({
    selector: 'app-text-msg-template',
    templateUrl: './text-msg-template.component.html',
    styleUrls: ['./text-msg-template.component.css'],
    providers: [GridConfigurationService]
})
export class TextMsgTemplateComponent {
    gridHeight;
    private encryptedUser: string = '';
    userResponse: UserResponse;
    textMsgTemplateResponse: TxtMsgTemplateListResponse;
    textMsgTemplateList: TxtMsgTemplate[];
    textMsgTemplateData = <TxtMsgTemplate>{};
    public userList: UserDD[] = [];
    public ownerList: UserDD[] = [];
    initTextMsgTemplateList: TxtMsgTemplate[];
    showSpinner: boolean = false;
    roleFeaturePermissions: RoleFeaturePermissions;
    isEnableEdit: boolean = false;
    isPreview: boolean = false;
    isShowGrid: boolean = true;
    isDeleteEnable: boolean = false;
    public isShowImage: boolean = true;
    user: CLPUser;
    selectedValue: number = 0;
    selectedUserId: number;
    selectedUserName: string;
    filterData = [];
    dateFormat: string;
    isViewMyTemplate: boolean = false
    currentPage: number = 1;

    columns = [{ field: '$', title: '', width: '20' },
    { field: 'templateName', title: 'Template Name', width: '120' },
    { field: 'messageText', title: 'Message Text', width: '300' },
    { field: 'userName', title: 'User', width: '40' },
    { field: 'shareable', title: 'Sharing', width: '40' },
    { field: 'dtCreated', title: 'Created', width: '30' },];
    reorderColumnName: string = 'templateName,messageText,userName,shareable,dtCreated';
    columnWidth: string = 'templateName:200,messageText:200,userName:40,shareable:40,dtCreated:40';
    arrColumnWidth: any[] = ['templateName:200,messageText:200,userName:40,shareable:40,dtCreated:40'];
    isSlurrpy: boolean = false;
    baseUrl: string;
    textMsgForm: FormGroup;
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    mobileColumnNames: string[];
    isShowUserDD: boolean = false;
    buttonTypeOperation: eButtonActions = eButtonActions.None;
    companyData: ClpCompany = <ClpCompany>{};
    txtMsgSettings: TxtMsgSettings = <TxtMsgSettings>{};
    uploadSaveUrl: string;
    uploadRestrictions: FileRestrictions = {
        allowedExtensions: [".jpg", ".png", ".eps", ".jpeg", ".gif"],
        maxFileSize: 10485760
    };
    fileUploadHeaders: HttpHeaders;
    includeShared: boolean;

    constructor(public _gridCnfgService: GridConfigurationService,
        @Inject('BASE_URL') _baseUrl: string,
        private _notifyService: NotificationService,
        private fb: FormBuilder,
        public _localService: LocalService,
        public _userService: UserService,
        private _utilityService: UtilityService,
        private _txtMsgService: TxtMsgService,
        private _globalService: GlobalService,
        private _accountSetupService: AccountSetupService,
        private _router: Router,
        private _textMsgTemplateService: TextMsgTemplateService,
        public _gridColumnsConfigurationService: GridColumnsConfigurationService) {
        this._localService.isMenu = true;
        this.gridHeight = this._localService.getGridHeight('493px');
        this.baseUrl = _baseUrl;
    }
    apiResponse(event: SuccessEvent) {
        if (!isNullOrUndefined(event)) {
            this.textMsgForm.get('mediaURL').setValue(event.response.body.messageString2);
        }
    }

    ngOnInit(): void {

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.fileUploadHeaders = new HttpHeaders({
                    'Authorization': 'Basic ' + this.encryptedUser
                });
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getCompanyData(() => {
                            if (this.companyData?.isHTMLEmailIncluded) {
                                this.setUp();
                            }
                        })
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    async getCompanyData(callback) {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.companyData = response.company;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("tectMsgTemplate.authenticateR", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
        callback();
    }

    async setUp() {
        if (this.user?.cLPUserID > 0) {
            this.getGridConfiguration();
            this.textMsgForm = this.prepareTextMsgForm();
            if (this.user?.cLPCompanyID == 0 || this.user?.slurpyUserId > 0)
                this.isSlurrpy = true;
            this.selectedUserId = this.user?.cLPUserID;
            this.isShowUserDD = false;
            if (this.user?.userRole >= eUserRole.Administrator || this.isSlurrpy) {
                await this.getCLPCompany_LoadUsers();
                this.isShowUserDD = true;
            }
            this.getTextMsgList();
        }
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.dateFormat = this.userResponse.user.dateFormat;
                            this._gridCnfgService.user = this.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("tectMsgTemplate.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'text_msg_template_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('text_msg_template_grid').subscribe((value) => { }));
    }

    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'text_msg_template_grid').subscribe((value) => this.getGridConfiguration());
    }

    async getCLPCompany_LoadUsers(forOwnerList?: boolean) {
        await this._userService.getCLPCompany_LoadUsers(this.encryptedUser, this.user?.cLPCompanyID, eUserPermissions.Unknown, false).
            then(async (result: UserDD[]) => {
                if (result)
                    if (forOwnerList)
                        this.ownerList = UtilityService.clone(result);
                    else
                        this.userList = UtilityService.clone(result);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("tectMsgTemplate.getCLPCompany_LoadUsers", err.message, null, 'clpCompanyId ' + this.user?.cLPCompanyID + 'permissions' + eUserPermissions.Unknown);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getTextMsgList() {
        this.showSpinner = true;
        this.isEnableEdit = false;
        this.includeShared = this.user?.cLPUserID == this.selectedUserId && this.isViewMyTemplate == false ? true : false;
        await this._textMsgTemplateService.getTextMsgTemplateList(this.encryptedUser, this.user.cLPCompanyID, this.user?.cLPUserID, this.isSlurrpy, this.selectedUserId, this.includeShared)
            .then(async (result: TxtMsgTemplateListResponse) => {
                if (result) {
                    this.textMsgTemplateResponse = UtilityService.clone(result);
                    this.textMsgTemplateList = this.textMsgTemplateResponse.txtMsgTemplateList;
                    this.initTextMsgTemplateList = this.textMsgTemplateList;
                    if (this._gridCnfgService) {
                        this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('text_msg_template_grid');
                        this._gridCnfgService.iterateConfigGrid(this.textMsgTemplateList, "text_msg_template_grid");
                    }
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("tectMsgTemplate.getTextMsgList", err.message, null, 'clpCompanyId ' + this.user?.cLPCompanyID + 'clpUserId' + this.user?.cLPUserID + 'isSlurrpy' + this.isSlurrpy + 'selectedUserId' + this.selectedUserId + 'viewShared' + (this.user?.cLPUserID == this.selectedUserId ? true : false));
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    getUserName(value: number) {
        if (value) {
            const name = this.userList?.filter(f => f.value == value)[0].text;
            return name;
        }
    }

    prepareTextMsgForm() {
        return this.fb.group({
            templateName: new FormControl('', [Validators.required]),
            messageText: new FormControl(''),
            mediaURL: new FormControl(''),
            userName: new FormControl(''),
            shareable: new FormControl(false),
            cLPUserid: new FormControl(this.user.cLPUserID),
        });
    }

    copytextMsgFormValueToData() {
        this.textMsgTemplateData.cLPCompanyID = this.user.cLPCompanyID;
        this.textMsgTemplateData.cLPUserid = this.textMsgForm.controls.cLPUserid.value;
        this.textMsgTemplateData.templateName = this.textMsgForm.controls.templateName.value;
        this.textMsgTemplateData.messageText = this.textMsgForm.controls.messageText.value;
        this.textMsgTemplateData.shareable = this.textMsgForm.controls.shareable.value;
        this.textMsgTemplateData.mediaURL = this.textMsgForm.controls.mediaURL.value;
        this.textMsgTemplateData.dtCreated = new Date();
        this.textMsgTemplateData.dtModified = new Date();
        this.filterData = this.userList.filter((data) => data.value == this.textMsgForm?.controls.cLPUserid.value);
        this.textMsgTemplateData.userName = this.filterData[0].value;
    }

    async updateTextMsgData() {

        this.textMsgForm.controls.templateName.markAsTouched();
        if (this.textMsgForm.valid) {
            this.showSpinner = true;
            this.copytextMsgFormValueToData();
            this.buttonTypeOperation = eButtonActions.Save;
            await this._textMsgTemplateService.saveTextMsgTemplate(this.encryptedUser, this.selectedValue, this.textMsgTemplateData)
                .then(async (result: SimpleResponse) => {
                    if (result) {
                        var response = UtilityService.clone(result);
                        this.showSpinner = false;
                        this.textMsgTemplateData.txtMsgTemplateID = response?.messageInt;
                        this.getTextMsgList();
                        this.selectedUserName = this.getUserName(this.textMsgTemplateData.cLPUserid)
                        this.isEnableEdit = false;
                        this.isPreview = true;
                        this.buttonTypeOperation = eButtonActions.None;
                    }
                    else {
                        this.buttonTypeOperation = eButtonActions.None;
                        this.showSpinner = false;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("tectMsgTemplate.updateTextMsgData", err.message, this.textMsgTemplateData, 'selectedValue ' + this.selectedValue);
                    this._utilityService.handleErrorResponse(err);
                    this.showSpinner = false;
                    this.buttonTypeOperation = eButtonActions.None;
                });
        }
    }

    async textMsgDelete() {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Delete;
        await this._textMsgTemplateService.textMsgTemplateDelete(this.encryptedUser, this.textMsgTemplateData.txtMsgTemplateID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this._notifyService.showSuccess(response.messageString ? response.messageString : "Text Message Template Delete Successfully.", "", 3000);
                    this.getTextMsgList();
                    this.isEnableEdit = false;
                    this.isDeleteEnable = false;
                    this.isPreview = false;
                    this.isShowGrid = true;
                    this.showSpinner = false;
                    this.buttonTypeOperation = eButtonActions.None;
                }
                else {
                    this.buttonTypeOperation = eButtonActions.None;
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                this._globalService.error("tectMsgTemplate.textMsgDelete", err.message, null, 'txtMsgTemplateID ' + this.textMsgTemplateData.txtMsgTemplateID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    patchFormControlValue(dataItem) {
        var textMsgTemplateData = dataItem;
        for (let key in textMsgTemplateData) {
            let value = textMsgTemplateData[key];
            if (this.textMsgForm.get(key))
                this.textMsgForm.get(key).setValue(value);
        }
    }

    showTextMsgData(dataItem) {
        this.getTextMsgConfig();
        this.textMsgTemplateData = dataItem;
        this.isPreview = true;
        this.isShowGrid = false;
        this.selectedUserName = dataItem.userName;
    }

    editTextMsgData() {
        this.patchFormControlValue(this.textMsgTemplateData);
        this.isEnableEdit = true;
        this.isPreview = false;
        this.isShowGrid = false;
        this.isDeleteEnable = true;
    }
    saveAsTextMsg() {
        this.editTextMsgData()
        this.textMsgForm.get('templateName').setValue('Copy of ' + this.textMsgForm.controls.templateName.value);
        this.textMsgTemplateData.txtMsgTemplateID = 0;
        this.isDeleteEnable = false;
    }

    backToList() {
        this.isEnableEdit = false;
        this.isPreview = false;
        this.isShowGrid = true;
        this.isDeleteEnable = false;
    }

    getTextMsgConfig() {
        this.uploadSaveUrl = this.baseUrl + 'api/TxtMsgTemplate/Upload_File/' + this.user.cLPCompanyID + '/' + this.user.cLPUserID;
    }

    async txtMsgSettingsGetById() {
        await this._txtMsgService.txtMsgSettingsGetById(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: TxtMsgSettingLoadResponse) => {
                if (!isNullOrUndefined(result))
                    this.txtMsgSettings = UtilityService.clone(result?.txtMsgSettings);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("tectMsgTemplate.txtMsgSettingsGetById", err.message, null, 'clpCompanyId ' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async addNew() {
        await this.txtMsgSettingsGetById();
        if (this.companyData?.isSFAIncluded && this.txtMsgSettings?.status == eTxtMsgSettingsStatus.Active) {
            await this.getCLPCompany_LoadUsers(true);
            this.isEnableEdit = true;
            this.isPreview = false;
            this.isShowGrid = false;
            this.isDeleteEnable = false;
            this.getTextMsgConfig();
            this.textMsgTemplateData.txtMsgTemplateID = 0;
            this.textMsgForm = this.prepareTextMsgForm();
        }
    }

    cancelTextMsg() {
        this.isEnableEdit = false;
        if (this.textMsgTemplateData.txtMsgTemplateID == 0) {
            this.isPreview = false;
            this.isShowGrid = true;
        }
        else {
            this.isPreview = true;
            this.isShowGrid = false;
        }
    }


    onTextMsgFilter(inputValue: string): void {
        this.textMsgTemplateList = process(this.initTextMsgTemplateList, {
            filter: {
                logic: "or",
                filters: [
                    { field: 'templateName', operator: 'contains', value: inputValue },
                    { field: 'messageText', operator: 'contains', value: inputValue },
                ],
            }
        }).data;
        this.dataBinding.skip = 0;
    }

    addText(value: string) {
        this.textMsgForm.get('messageText').patchValue(this.textMsgForm.controls.messageText?.value + ' ' + value);
    }
    viewMyTemplate(event) {
        this.isViewMyTemplate = event.target.checked;
        this.getTextMsgList();
    }
    pageChange(event: PageChangeEvent): void {
        let skip = event.skip;
        this.currentPage = (skip / this._gridCnfgService.pageSize) + 1;
    }
}
