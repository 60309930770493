import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, NgZone, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { CreateExportFor, eCustomSearchCategory, eExportRequestObjectType, eExportRequestStatus, eFeatures, eUserRole } from '../../../models/enum.model';
import { Lead, LeadByLeadStatus, LeadListResponse } from '../../../models/lead.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { SearchItem, SearchItemListResponse, SearchQuery, SearchQueryResponse } from '../../../models/search.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { NotificationService } from '../../../services/notification.service';
import { ContactCommonSearchService } from '../../../services/shared/contact-common-search.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { process } from '@progress/kendo-data-query';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { ReportService } from '../../../services/report.service';
import { CreateExportRequestObj, ExportRequest } from '../../../models/exportRequest.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { ExportRequestService } from '../../../services/export-request.service';
import { GlobalService } from '../../../services/global.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactCommonSearchComponent } from '../../contact-module/common/contact-common-search/contact-common-search.component';
import { SearchContactService } from '../../../services/Searchcontact.service';
@Component({
    selector: 'lead-list',
    templateUrl: './lead-list.component.html',
    styleUrls: ['./lead-list.component.css'],
    providers: [GridConfigurationService]
})
/** lead-list component*/
export class LeadListComponent implements OnInit, OnDestroy {
    @ViewChild(ContactCommonSearchComponent) contactCommonSearchComponent!: ContactCommonSearchComponent;
    userResponse: UserResponse;
    @Input() user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    queryDataLoaded: SearchQueryResponse = <SearchQueryResponse>{};
    @Input() leadList: Lead[];
    initLeadList: Lead[];
    private encryptedUser: string = '';
    subscriptionQueryList: any;
    showSpinner: boolean = false;
    gridHeight;
    baseUrl: string;
    isContactShow: boolean = false;
    isShowSearch: boolean = true;
    @Input() isHomeSearch: boolean = true;

    eStat: number = eExportRequestStatus.None;
    eType: number = eExportRequestObjectType.Lead;
    mapRecentContact = [];
    exportRequest: ExportRequest;
    createExportFor: number = CreateExportFor.leadCreate;
    dateFormat: string;

    columns = [{ field: '$', title: '', width: '40' },
    { field: '$$', title: '', width: '40' },
    { field: 'leadDesc', title: 'Lead', width: '100' },
    { field: 'lastFirst', title: 'Contact', width: '100' },
    { field: 'companyName', title: 'Company', width: '106' },
    { field: 'ufirstName', title: 'User', width: '100' },
    { field: 'dtStart', title: 'Start', width: '100' },
    { field: 'dtEnd', title: 'Close', width: '100' },
    { field: 'revenue', title: 'Net Revenue', width: '100' },
    { field: 'winProbability', title: 'Win Probability', width: '100' },
    { field: 'leadStatusCode', title: 'Status', width: '100' },
    { field: 'dtModified', title: 'Modified', width: '100' },
    { field: 'dtCreated', title: 'Created', width: '100' }];
    reorderColumnName: string = 'leadDesc,lastFirst,companyName,ufirstName,dtStart,dtEnd,revenue,winProbability,leadStatusCode,dtModified,dtCreated';
    columnWidth: string = 'leadDesc:100,lastFirst:100,companyName:106,ufirstName:100,dtStart:100,dtEnd:100,revenue:100,winProbability:100,leadStatusCode:100,dtModified:100,dtCreated:100';
    arrColumnWidth: any[] = ['leadDesc:100,lastFirst:100,companyName:106,ufirstName:100,dtStart:100,dtEnd:100,revenue:100,winProbability:100,leadStatusCode:100,dtModified:100,dtCreated:100'];
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    currentUrl: string;
    mobileColumnNames: string[];

    customSearch: SearchQueryResponse;
    searchItemListResponse: SearchItemListResponse;
    searchItems: SearchItem[];
    showSaveSearch: boolean = false;
    showSaveSearchForm: boolean = false;
    searchName: string = '';
    saveSearchForm: FormGroup;
    constructor(private _exportService: ExportRequestService, private _notifyService: NotificationService, @Inject('BASE_URL') _baseUrl: string, public router: Router, public _gridCnfgService: GridConfigurationService, private _router: Router, private _localService: LocalService, private leadSettingService: LeadSettingService, private _utilityService: UtilityService, public notifyService: NotificationService, public _contactCommonSearchService: ContactCommonSearchService, private _ngZone: NgZone, private _accountSetupService: AccountSetupService, private _globalService: GlobalService, private _reportService: ReportService,
        private fb: FormBuilder,
        private _searchContactService: SearchContactService,
    ) {
        this.gridHeight = this._localService.getGridHeight('464px');
        this._localService.isMenu = true;
        this.baseUrl = _baseUrl;
        this.currentUrl = window.location.pathname;
    }

    ngOnInit() {
        this.saveSearchForm = this.prepareSaveSearchForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(async () => {
                    if (this.user) {
                        this.getLeadSearch();
                        this.isContactShow = true;
                        this.defaultSearch();
                        this.getGridConfiguration();
                        this.getQueryDataLoad();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    defaultSearch() {
        var searchQuery: SearchQuery = <SearchQuery>{};
        searchQuery.cLPUserID = this.user.cLPUserID;
        searchQuery.controlType = "md";
        searchQuery.operator = "IN";
        searchQuery.searchItem = "CLPUserID";
        searchQuery.searchItemValue = this.user.cLPUserID.toString();
        searchQuery.tableName = "lead";
        this.queryDataLoaded = <SearchQueryResponse>{};
        this.queryDataLoaded.searchQueryList = [];
        this.queryDataLoaded.searchQueryList.push(searchQuery);
        this.currentUrl == '/active-leads' ? this.getActiveLeads() : this.getQueryData();
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.user = this.user;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, (this.currentUrl == '/active-leads') ? 'active_lead_grid' : 'lead_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration((this.currentUrl == '/active-leads') ? 'active_lead_grid' : 'lead_grid').subscribe((value) => { }));
    }

    getQueryDataLoad() {
        this.subscriptionQueryList = this._contactCommonSearchService.getqueryLeadListChangedChangeEmitter().subscribe((data) => {
            this._ngZone.run(() => {
                this.queryDataLoaded = data;
                if (isNullOrUndefined(this.queryDataLoaded)) {
                    this.queryDataLoaded = <SearchQueryResponse>{};
                    this.defaultSearch();
                }
                else
                    this.getQueryData();
            })
        });
    }

    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, (this.currentUrl == '/active-leads') ? 'active_lead_grid' : 'lead_grid').subscribe((value) => this.getGridConfiguration());
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, this.currentUrl == '/active-leads' ? eFeatures.MyActiveLeads : eFeatures.MyLeads)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    this.dateFormat = this.userResponse.user.dateFormat;
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lead-list.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getQueryData() {
        this.showSpinner = true;
        if (this.isHomeSearch)
            await this.leadSettingService.searchSaveLead(this.encryptedUser, this.queryDataLoaded, this.user.cLPUserID)
                .then(async (result: LeadListResponse) => {
                    if (result) {
                        var res = UtilityService.clone(result);
                        this.leadList = res.leads;
                        this.showSpinner = false;
                        this.initLeadList = res.leads;
                        if (!isNullOrUndefined(this._gridCnfgService)) {
                            this._gridCnfgService.iterateConfigGrid(this.leadList, "lead_grid");
                            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('lead_grid');
                        }
                    }
                    this.showSpinner = false
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this.showSpinner = false;
                    this._utilityService.handleErrorResponse(err);
                });
        this.showSpinner = false;
    }

    async getActiveLeads() {
        this.showSpinner = true;
        await this.leadSettingService.getActiveLeads(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: LeadListResponse) => {
                if (!isNullOrUndefined(result)) {
                    var res = UtilityService.clone(result);
                    this.leadList = res.leads;
                    this.initLeadList = res.leads;
                    this.showSpinner = false;

                    if (!isNullOrUndefined(this._gridCnfgService)) {
                        this._gridCnfgService.iterateConfigGrid(this.leadList, "active_lead_grid");
                        this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('active_lead_grid');
                    }
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    onLeadSearchFilter(inputValue: string): void {
        this.leadList = process(this.initLeadList, {
            filter: {
                logic: "or",
                filters: [
                    { field: 'leadDesc', operator: 'contains', value: inputValue },
                    { field: 'lastFirst', operator: 'contains', value: inputValue },
                    { field: 'companyName', operator: 'contains', value: inputValue },
                    { field: 'ufirstName', operator: 'contains', value: inputValue },
                    { field: 'revenue', operator: 'contains', value: inputValue },
                    { field: 'winProbability', operator: 'contains', value: inputValue },
                    { field: 'leadStatusCode', operator: 'contains', value: inputValue },
                ],
            }
        }).data;
        this.dataBinding.skip = 0;
    }

    public gotoLink(columnName, dataItem) {
        switch (columnName) {
            case "address-card":
            case "name":
                if (this.user.timeZoneWinId != 0)
                    this._router.navigate(['/contact', dataItem.cLPUserID, dataItem.contactID]);
                else {
                    if (confirm("First , Please select your timezone!!!"))
                        this._router.navigate(['/edit-profile', dataItem.clpUserId]);
                    else
                        return;
                }
                break;

            case "userName":
                this._router.navigate(['/edit-profile', dataItem.cLPUserID]);
                break;

            case "lead":
                this.router.navigate(['lead-detail', dataItem.leadID]);
                break;
            case "company":
                this._router.navigateByUrl(`company-create?cid=${dataItem?.companyID}`);
                break;

            default: {
                break;
            }
        }
    }

    setExportRequest() {
        this.exportRequest = <ExportRequest>{};
        this.exportRequest.cLPUserID = this.user?.cLPUserID;
        this.exportRequest.cLPCompanyID = this.user?.cLPCompanyID;
    }


    async createExportRequest() {
        this.showSpinner = true;
        //this.setExportRequest();
        var exportRequest = <CreateExportRequestObj>{};
        exportRequest.searchQueryObj = <SearchQueryResponse>{};
        exportRequest.exportRequestObj = <ExportRequest>{};
        exportRequest.exportRequestObj.cLPUserID = this.user?.cLPUserID;
        exportRequest.exportRequestObj.whereClause = '';
        exportRequest.exportRequestObj.orderBy = '';
        exportRequest.exportRequestObj.objectType = eExportRequestObjectType.Lead;
        exportRequest.exportRequestObj.cLPCompanyID = this.user.cLPCompanyID;
        exportRequest.exportRequestObj.fileName = "lead_export";
        /* exportRequest.exportRequestObj.includeMetrics = this.includeMetrics;*/
        exportRequest.searchQueryObj = this.queryDataLoaded;
        await this._exportService.exportRequestCreate(this.encryptedUser, exportRequest, eExportRequestObjectType.Lead)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result?.messageBool)
                        this.notifyService.showSuccess('Your export request has been submitted successfully.', '', 3000);
                    else
                        this.notifyService.showError(result?.errorMsg, '', 3000);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }


    ngOnDestroy() {
        this.isContactShow = false;
    }

    isRunSearch(event) {
        this.showSaveSearchForm = false;
        this.showSaveSearch = true;
        let cSearch = JSON.parse(localStorage.getItem('custom_lead_search'));
        if (cSearch != undefined) {
            this.customSearch = cSearch;
            console.log(this.customSearch)
        }
    }

    getOperatorName(operator) {
        let opName = ''
        switch (operator) {
            case "=":
                opName = 'Equal'
                break;
            case "<>":
                opName = 'Not Equal'
                break;
            case "LIKE":
                opName = 'Contains'
                break;
            case "IN":
                opName = 'Contains Any'
                break;
            case "NOT IN":
                opName = 'Does Not Contain'
                break;
            case "CA":
                opName = 'Contains All'
                break;
            case "R":
                opName = 'Range'
                break;
            case "dt":
                opName = 'Date'
                break;
            case ">":
                opName = 'Greater Than'
                break;
            case "<":
                opName = 'Less Than'
                break;
            case ">=":
                opName = 'Greater Than Equal To'
                break;
            case "<=":
                opName = 'Less Than Equal To'
                break;
            case "NOT LIKE":
                opName = 'Does Not Contain'
                break;
            case "STARTS":
                opName = 'Starts With'
                break;
            case "ENDS":
                opName = 'Ends With'
                break;

        }
        return opName;
    }

    async getLeadSearch() {
        await this._searchContactService.getLeadSearchFields(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SearchItemListResponse) => {
                if (result) {
                    this.searchItemListResponse = UtilityService.clone(result);
                    this.searchItems = this.searchItemListResponse.searchItems.sort((a, b) => a.displayValue.localeCompare(b.displayValue))
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-common-search.getLeadSearch", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                    + 'cLPUserID: ' + this.user.cLPUserID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getSearchItemName(searchItem) {
        let itemName = '';

        let searchObj = this.searchItems?.filter((item) => item?.columnName == searchItem?.searchItem)[0];
        switch (searchItem?.controlType) {
            case 'md':
                let valuesArr = searchItem?.searchItemValue.split(",").map(Number);
                itemName = searchObj?.itemData
                    .filter(item => valuesArr.includes(item.value))
                    .map(item => item.display)
                    .join(", ");
                break;
            case 't':
                itemName = searchItem?.searchItemValue;
                break;
            case 'dt':
                itemName = searchItem?.strDtStart + " - " + searchItem?.strDtEnd;
                break;
            case 'd':
                itemName = searchObj?.itemData
                    .filter(item => item?.value == searchItem?.searchItemValue)[0]?.display
                break;
        }

        return itemName;
    }

    private prepareSaveSearchForm(): FormGroup {
        return this.fb.group({
            SearchQueryName: ['', [Validators.required]]
        });
    }

    SubmitSearch() {

        if ((this.saveSearchForm.valid)) {
            this.showSpinner = true;
            let searchQueryName = this.saveSearchForm.controls['SearchQueryName'].value
            console.log(searchQueryName)
            this._searchContactService.SavedSearchQueries_Update(this.encryptedUser, this.customSearch, this.user.cLPUserID, 0, searchQueryName, eCustomSearchCategory.Lead)
                .then(res => {
                    if (res) {
                        var response = UtilityService.clone(res);
                        console.log(response)
                        this.saveSearchForm.reset();
                        this.saveSearchForm.get('SearchQueryName').setValue('');
                        this.contactCommonSearchComponent.getLeadSearch();
                        this.notifyService.showSuccess("Search Query has been saved successfully!", "", 3000);
                        this.showSaveSearch = false;
                        this.showSpinner = false;
                    }
                    else {
                        this.notifyService.showError("Error during Submitting Search. Please contact administrator!", "", 3000);
                        this.showSpinner = false;
                    }
                }).catch((err: HttpErrorResponse) => {
                    this.showSpinner = false;
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            if (!this.saveSearchForm.valid)
                this.validateAllFormFields(this.saveSearchForm);
        }
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched();
                control.updateValueAndValidity();
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    get saveSearchFrm() {
        return this.saveSearchForm.controls;
    }
}
