<div class="dynamic-scheduler">
    <div class="body-content">
        <div class="RadAjaxPanel" id="RadAjaxPanel1Panel" style="width: 100%;">
            <div id="RadAjaxPanel1" style="">
                <!-- 2020.1.219.45 -->
                <div class="canvas">
                    <div class="title">
                        <h3><span id="lblTitle">Schedule Appointment</span></h3>
                        <h6><span id="lblPCalMessage">This calendar allows you to select from open time slots from the calendar below.</span></h6>
                        <div id="pnlMessage"><div class="alert alert-info mb-0">Please select a date and time that works for you (in EASTERN TIME).</div></div>
                    </div>
                    <div class="body">
                        <div class="">
                            <div class="dynamic-scheduler-panel">
                                <div id="pnlTime" class="time_selector">
                                    <div style="text-align: center"> {{scheduleAppointmentWeekDay}}<span *ngIf="scheduleAppointmentWeekDay">,</span> {{scheduleAppointmentMonth}} {{scheduleAppointmentDate}}</div>
                                    <div class="cards-colunm-right">
                                        <div class="row example-wrapper">
                                            <div class="col-xs-12 col-md-6 example-col">
                                                <kendo-calendar type="classic" [(value)]="currDate" (valueChange)="onScheduleAppointmentDateChange($event)"></kendo-calendar>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="time_slot_bar">
                                        <div id="pnlSlots">
                                            <div><input type="button" name="2023_7_6_9_0" value="09:00" (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                            <div><input type="button" name="2023_7_6_9_30" value="09:30" (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                            <div><input type="button" name="2023_7_6_10_0" value="10:00" (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                            <div><input type="button" name="2023_7_6_10_30" value="10:30" (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                            <div><input type="button" name="2023_7_6_11_0" value="11:00" (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                            <div><input type="button" name="2023_7_6_11_30" value="11:30" (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                            <div><input type="button" name="2023_7_6_12_0" value="12:00" (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                            <div><input type="button" name="2023_7_6_12_30" value="12:30" (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                            <div><input type="button" name="2023_7_6_13_0" value="13:00 " (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                            <div><input type="button" name="2023_7_6_13_30" value="13:30 " (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                            <div><input type="button" name="2023_7_6_14_0" value="14:00 " (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                            <div><input type="button" name="2023_7_6_14_30" value="14:30 " (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                            <div><input type="button" name="2023_7_6_15_0" value="15:00 " (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                            <div><input type="button" name="2023_7_6_15_30" value="15:30 " (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                            <div><input type="button" name="2023_7_6_16_0" value="16:00 " (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                            <div><input type="button" name="2023_7_6_16_30" value="16:30 " (click)="onScheduleAppointmentTimeSelect($event)"></div>
                                        </div>
                                    </div>
                                </div>
                                <div id="pnlContact" class="confirmation_box">
                                    <div class="scheduled_time" *ngIf="scheduleAppointmentTime">{{scheduleAppointmentTime}}, {{scheduleAppointmentWeekDay}}<span *ngIf="scheduleAppointmentWeekDay">,</span> {{scheduleAppointmentMonth}} {{scheduleAppointmentDate}}<span *ngIf="scheduleAppointmentYear">,</span> {{scheduleAppointmentYear}}</div>
                                    <form [formGroup]="scheduleAppointmentForm" (ngSubmit)="onSubmitScheduleForm()">
                                        <div class="row">
                                            <div class="form-group col-sm-12">
                                                <label class="control-label">Name<span style="color: red">&nbsp;*</span></label>
                                                <div>
                                                    <input formControlName="txtName" type="text" id="txtName" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-sm-12">
                                                <label class="control-label">Email<span style="color: red">&nbsp;*</span></label>
                                                <div>
                                                    <input type="text" formControlName="txtEmail" id="txtEmail" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-sm-12">
                                                <label class="control-label">Mobile<span style="color: red">&nbsp;*</span></label>
                                                <div>
                                                    <input type="text" formControlName="txtMobile" id="txtMobile" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-sm-12">
                                                <label class="control-label">Notes</label>
                                                <div>
                                                    <textarea rows="2" formControlName="txtNotes" cols="20" id="txtNotes" class="form-control" data-gramm="false" wt-ignore-input="true"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-sm-12">
                                                <div style="text-align: center;">
                                                    <input type="submit" name="btnSchedule" value="Schedule" id="btnSchedule" class="aspNetDisabled btn btn-success ml-0">
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
