import { HttpErrorResponse } from '@angular/common/http';
import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { isNull, isNullOrUndefined, isObject } from 'util';
import { filterAnimation, pageAnimations } from '../../../animations/page.animation';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ContactConnect, ContactConnectData, ContactConnect_GetListByOwner, ContactFields, ContactFieldsResponse, sectionDiplaySetting } from '../../../models/contact.model';
import { eButtonActions, eFeatures, eNoteOwnerType, eSection, eUserRole } from '../../../models/enum.model';
import { ContactService } from '../../../services/contact.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { Subject, Subscription } from 'rxjs';
import { ContactSearchService } from '../../../services/shared/contact-search.service';
import { NotificationService } from '../../../services/notification.service';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { ZipCode, ZipCodeResponse } from '../../../models/zip.model';
import { ZipService } from '../../../services/zip.service';
import { CountryService } from '../../../services/country.service';
import { Country, CountryListResponse, CurrentCountryResponse } from '../../../models/country.model';
import { and, isNullOrEmptyString } from '../../../../shared/utils.js';
import { CountryCode } from 'libphonenumber-js/types';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ClpCompany, Company, CompanyFields, CompanyFieldsResponse, CompanyObjectResponse, CompanyResponse } from '../../../models/company.model';
import { TagSettingService } from '../../../services/tag-setting.service';
import { TagData, TagsFields } from '../../../models/tag-settings.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { Search, SearchListResponse } from '../../../models/search.model';
import { SearchContactService } from '../../../services/Searchcontact.service';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { GlobalService } from '../../../services/global.service';
import { DateTimePickerComponent } from '@progress/kendo-angular-dateinputs';
import { MessageResponse } from '../../../models/message.model';
import { DashboardService } from '../../../services/dashboard.service';

declare var $: any;
@Component({
    selector: 'app-contact-detail',
    templateUrl: './contact-detail.component.html',
    styleUrls: ['./contact-detail.component.css'],
    animations: [pageAnimations, filterAnimation]
})
/** contact-detail component*/
export class ContactDetailComponent implements OnInit, OnDestroy, AfterContentChecked, AfterViewInit {
    //Animation
    @ViewChild('dateTimePicker', { static: false }) dateTimePicker: DateTimePickerComponent;
    eUserRole = eUserRole;
    @HostBinding('@pageAnimations') public animatePage = true;
    @Input() resetDetailSubject: Subject<boolean> = new Subject<boolean>();
    showAnimation = -1;
    // Animation
    panelOpenState = false;
    todaysDate: Date = new Date();
    private encryptedUser: string = '';
    @Input() isFromLiveConnect: boolean = false;
    @Input() contactId: number = 0;
    @Input() isSaveAs: boolean = false;
    @Input() isFromHandle: boolean = false;
    selectedcontactId: number = 0;
    email2: string;
    email3: string;
    email3blastaddress: boolean = false;
    email2blastaddress: boolean = false;
    isMoreFields: boolean = false;
    isEditMode: boolean = false;
    showSpinner: boolean = false;
    isContactUpdated: boolean = false;
    confirmText: string = '';
    msg_blastaddress: string;
    user: CLPUser;
    contactFieldsResponse: ContactFieldsResponse;
    contactFields: ContactFields;
    updateContactFields: ContactFields;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    companyId: number = 0;
    placeHolder: string = '';
    mobile_mask: string = '(000) 000-0000';
    show_countries: boolean = false;
    dialCode: number = 1;

    arrAllControls: any[] = [];

    arrGenCtrl: any[] = [];
    arrCommunicationCtrl: any[] = [];
    arrAddressCtrl: any[] = [];
    arrAddiInfoCtrl: any[] = [];
    arrClassiDropdownCtrl: any[] = [];
    arrClassiCheckboxCtrl: any[] = [];
    arrCommentsCtrl: any[] = [];
    arrImportantDatesCtrl: any[] = [];
    arrMoreFieldsCtrl: any[] = [];
    arrSystemCtrl: any[] = [];

    arrSortedBySection: any[] = [];

    more: boolean = true;
    referralId: number = 0;
    isReferred: boolean = false;
    moreLabel: string = 'more...';
    currentUrl: string = '';

    countryCode: string = '';
    countryListResponse: CountryListResponse;
    countryList: Country[];
    countryCodeSent: string;
    companyName: Company[] = [];

    tagListByCompany: TagData[] = [];
    tagListByOwner: TagData[] = [];
    selectedTagId: number;
    selectedId: number = -1;
    contactConnect: ContactConnect = <ContactConnect>{};


    contactSearchList: Search[] = [];
    isContactMandatory: boolean = false;
    isHideConnectionForm: boolean = true;
    connectionRelation: string = "";
    contactIdForConnection: number = 0;
    contactConnectionList: ContactConnectData[] = [];
    @ViewChild('autocomplete') autocomplete: AutoCompleteComponent;

    public format = "MM/dd/yyyy HH:mm:ss";
    dateFormat: string;

    contactForm = new FormGroup({});

    companyForm = new FormGroup({});
    companyFieldsResponse: CompanyFieldsResponse;
    companyFields: CompanyFields;
    updateCompanyFields: any;
    companyFieldsForNewContact: CompanyFields;
    buttonTypeOperation: eButtonActions = eButtonActions.None;
    isPinned: boolean = false;
    public companyForNewContactSubscription: Subscription;
    addressByCompany: CompanyObjectResponse;
    @Output() isContactUpdate = new EventEmitter<boolean>();
    @Output() openContact = new EventEmitter<boolean>(false);
    companyData: ClpCompany;
    messageID: number = 0;
    messageResponse: MessageResponse;
    messageDup: string = "";
    isShowMsgDup: boolean = false;
    /** contact-detail ctor */
    constructor(private fb: FormBuilder,
        public _contactService: ContactService,
        private _tagSettingService: TagSettingService,
        private srchContactSrvc: SearchContactService,
        public _accountSetupService: AccountSetupService,
        public _utilityService: UtilityService,
        public _localService: LocalService,
        public _contactSearchService: ContactSearchService,
        public notifyService: NotificationService,
        public _countryService: CountryService,
        private _zipService: ZipService,
        private _dashboardService: DashboardService,
        private _router: Router,
        private renderer: Renderer2,
        private elementRef: ElementRef,
        private _globalService: GlobalService,
        private route: ActivatedRoute,
        private cdRef: ChangeDetectorRef) {
        this._localService.isMenu = true;
        _router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const currentUrl = event.url;
                if (currentUrl != null) {
                    const splitUrl = currentUrl.split('/', 5);
                    const splitUrls = splitUrl.length > 0 ? splitUrl[1] : '';
                    this.currentUrl = splitUrls.split('?', 5)[0];
                }
            }
        });
        this._localService.showCommonComp = 'contact-new';
    }

    loadContactDetails() {
        this.removeControls();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(async () => {
                    if (this.user) {
                        if (this.isFromHandle) {
                            this._localService.isMenu = false;
                            this._localService.isFooter = false;
                        }
                        this._contactSearchService.showSpinner = true;
                        const contactId = this.contactId ? this.contactId : 0;
                        const userId = this.user ? this.user.cLPUserID : 0;
                        this.countryCode = this._localService.contactFields?.country?.fieldValue ? this._localService.contactFields?.country?.fieldValue : this.user.country ? this.user.country : 'US';
                        this.loadCountries();
                        const clpCompanyId = this.user ? this.user.cLPCompanyID : 0;
                        this.getClpCompany();
                        this.getContactFields(contactId, clpCompanyId, userId);
                        this.getTagListByOwnerId();
                        this.getContactConnectByOwner();
                        this.getCompanyConfig();
                        this.checkIsPinned();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    async getClpCompany() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (!isNullOrUndefined(result))
                    this.companyData = UtilityService.clone(result?.company);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("configuration.getClpCompany", err.message, null,
                    'companyId: ' + this.user?.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    ngAfterContentChecked(): void {
        this.cdRef.detectChanges();
    }

    ngOnInit() {
        this.buttonTypeOperation = eButtonActions.None;
        this._localService.getMoreOrLess().subscribe(res => {
            if (res) {
                this.more = true;
                this.moreLabel = 'more...';
            }
        });

        this.resetDetailSubject.subscribe(response => {
            if (response) {
                this.more = true;
                this.moreLabel = 'more...';
                this.clearCtrl();
                this._contactSearchService.showSpinner = true;
                this.loadContactDetails();
            }
        });

        this.companyForNewContactSubscription = this._localService.getCompanyForContact()
            .subscribe(response => {
                if (!isNullOrUndefined(response?.companyName?.fieldValue) && response?.companyName?.fieldValue.trim() != '') {
                    this.companyFieldsForNewContact = response;
                }
            });


        this.route.queryParams.subscribe(params => {
            if (!isNullOrUndefined(params)) {
                if (!isNullOrUndefined(params.rid)) {
                    this.referralId = Number(params.rid);
                    this.isReferred = true;
                }
                if (!isNullOrUndefined(params.cid)) {
                    this.companyId = Number(params.cid);
                    this.getAddressByCompanyId();
                }

            }
        });


        this.route.paramMap.subscribe(async params => {
            if (params.has('msgid')) {
                this.messageID = +params.get('msgid');
                this.updateMessage();
            }
        });

        this.loadContactDetails();
    }

    async updateMessage() {
        this._dashboardService.messageUpdate(this.encryptedUser, this.messageID)
            .then(async (result: MessageResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.messageResponse = UtilityService.clone(result);
                    if (this.messageResponse.messageBool) {
                        localStorage.removeItem("messageId");
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("acitvity-history.updateMessage", err.message, null, 'messageID ' + this.messageID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.NewContact)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    this.dateFormat = this.userResponse.user.dateFormat;
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.getCompanyList();
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("contact-detail.authenticateR", err.message, null,
                    'Feature: ' + eFeatures.NewContact
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    clearCtrl() {
        this.contactForm.reset();
        this.arrSystemCtrl = [];
        this.arrAllControls = [];
        this.arrSortedBySection = [];

        this.arrGenCtrl = [];
        this.arrCommunicationCtrl = [];
        this.arrAddressCtrl = [];
        this.arrAddiInfoCtrl = [];
        this.arrClassiDropdownCtrl = [];
        this.arrClassiCheckboxCtrl = [];
        this.arrCommentsCtrl = [];
        this.arrImportantDatesCtrl = [];
        this.arrMoreFieldsCtrl = [];
    }

    moreLess() {
        this.more = !this.more;
        this.moreLabel = this.more ? 'more...' : 'less...';
    }
    blastItem(e) {
        this.copyContactFormValuesToDataObject();
        if (e == 2) {
            if (this.updateContactFields.email2.fieldValue == "") {
                this.email2blastaddress = true;
                this.msg_blastaddress = "Please enter your Email ID in Email 2 field"
            } else {
                this.email2blastaddress = false;
                this.msg_blastaddress = "";
                return;
            }

        } else if (e == 3) {
            if (this.updateContactFields.email3.fieldValue == "") {
                this.email3blastaddress = true;
                this.msg_blastaddress = "Please enter your Email ID in Email 3 field"
            } else {
                this.email3blastaddress = false;
                this.msg_blastaddress = "";
                return;
            }
        } else {
            this.email2blastaddress = false;
            this.email3blastaddress = false;
            this.msg_blastaddress = "";
            return;
        }
    }

    getContactFields(contactId, clpCompanyID, userId) {
        if (contactId == 0) {
            this._contactService.contactFields_Get_Configuration(this.encryptedUser, clpCompanyID, userId)
                .then(async (result: ContactFieldsResponse) => {
                    if (result) {
                        this.contactFieldsResponse = UtilityService.clone(result);
                        this.contactFields = this.contactFieldsResponse.contactFields;
                        this.countryCode = this.contactFields?.country?.fieldValue ? this.contactFields?.country?.fieldValue : this.user?.country ? this.user?.country : 'US'
                        this._contactSearchService.showSpinner = false;
                        this.renderFields();
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("contact-detail.getContactFields", err.message, null,
                        'clpCompanyID: ' + clpCompanyID
                        + 'userId: ' + userId
                    );
                    this._contactSearchService.showSpinner = false;
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            this._localService.showCommonComp = 'contact-detail';
            if ((!isNullOrUndefined(this._localService.contactFields)) && (this._localService.contactFields.contactID.fieldValue == this.contactId))
                this._localService.getContactFields(this.encryptedUser, contactId, clpCompanyID, userId, true)
                    .subscribe((value) =>
                        this.loadInitData()
                    );
            else {
                this._localService.getContactFields(this.encryptedUser, contactId, clpCompanyID, userId, true)
                    .subscribe((value) =>
                        this.loadInitData()
                    );
            }
        }

    }

    loadInitData() {
        this.contactFieldsResponse = this._localService.contactFieldsResponse;
        this.contactFields = this._localService.contactFields;
        this._contactSearchService.showSpinner = false;
        this.renderFields();
    }

    renderFields() {
        if (!isNullOrUndefined(this.contactFields.contactMoreFields)) {
            let moreFields = this.contactFields.contactMoreFields;
            Object.keys(moreFields).forEach(key => {
                let value = moreFields[key];
                if (isObject(value)) {
                    value.fieldName = key;
                    this.contactFields[key] = value;
                }
            });
        }
        this.updateContactFields = UtilityService.clone(this.contactFields);
        if (!isNullOrUndefined(this.contactFields)) {
            this.setValidation();
            this._contactSearchService.showSpinner = false;
            this.contactForm.controls['country'].patchValue(this.setCountryDD(this.contactFields.country.fieldValue));
            if (this._localService.showCommonComp == 'contact-detail') {
                this.patchContactFormValue();
                if (!isNullOrUndefined(this.contactFields.companyID.fieldValue) && this.contactFields.companyID.fieldValue > 0) {
                    this.companyId = this.contactFields.companyID.fieldValue;
                }
            }
            if (this.contactId == 0 && this.contactFields.contactID.fieldValue == 0) {
                if (this.companyId > 0)
                    this.getAddressByCompanyId();
            }
        }

        if (!isNullOrUndefined(this.companyFieldsForNewContact)) {
            this.valueChangeCompanyName();
        }

        let contactsFields = this.contactFields;
        Object.keys(contactsFields).forEach(key => {
            let value = contactsFields[key];
            if (!isNullOrUndefined(value)) {
                value.fieldName = key;
                this.arrAllControls.push(value);
            }
        });

        this.showSpinner = false;
        this.arrGenCtrl = this.arrAllControls.filter(i => i.section == eSection.General && i.fieldName != "firstName" && i.fieldName != "lastName");
        this.arrCommunicationCtrl = this.arrAllControls.filter(i => i.section == eSection.Communication && i.fieldName != "email" && i.fieldName != "mobile");
        this.arrAddressCtrl = this.arrAllControls.filter(i => i.section == eSection.Address);

        this.arrAddiInfoCtrl = this.arrAllControls.filter(i => i.section == eSection.AddtionalInformation);

        this.arrClassiDropdownCtrl = this.arrAllControls.filter(i => i.section == eSection.ClassificationDropDown);
        this.arrClassiCheckboxCtrl = this.arrAllControls.filter(i => i.section == eSection.ClassificationCheckBox);
        this.arrCommentsCtrl = this.arrAllControls.filter(i => i.section == eSection.Comments);
        this.arrImportantDatesCtrl = this.arrAllControls.filter(i => i.section == eSection.ImportantDates);
        this.arrMoreFieldsCtrl = this.arrAllControls.filter(i => i.section == eSection.MoreFields);
        this.arrSystemCtrl = this.arrAllControls.filter(i => i.section == eSection.System);

        this.arrGenCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrCommunicationCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrAddressCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrAddiInfoCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrClassiDropdownCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrClassiCheckboxCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrCommentsCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrImportantDatesCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrMoreFieldsCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrSystemCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);

        let finalElement = this.arrAddiInfoCtrl?.length - this.companyData?.contactMaxTXT;
        this.arrAddiInfoCtrl.splice(-finalElement, finalElement);

        if (!isNullOrUndefined(this.contactFieldsResponse.contactFields) && !isNullOrUndefined(this.contactFieldsResponse.contactFields.displaySetting) && !isNullOrUndefined(this.contactFieldsResponse.contactFields.displaySetting.fieldDiplaySettings.length > 0)) {
            let sectionDiplaySettings: sectionDiplaySetting[] = this.contactFieldsResponse.contactFields.displaySetting.sectionDiplaySettings;
            sectionDiplaySettings.sort((a, b) => (a.sectionDisplayOrder > b.sectionDisplayOrder) ? 1 : -1);
            for (var i = 0; i < sectionDiplaySettings.length; i++) {
                switch (eSection[sectionDiplaySettings[i].sectionId]) {
                    case eSection[eSection.Communication]: this.arrSortedBySection.push({ sectionName: 'Communication', sectionId: sectionDiplaySettings[i].sectionId, items: this.arrCommunicationCtrl }); break;
                    case eSection[eSection.Address]: this.arrSortedBySection.push({ sectionName: 'Address', sectionId: sectionDiplaySettings[i].sectionId, items: this.arrAddressCtrl }); break;
                    case eSection[eSection.AddtionalInformation]: this.arrSortedBySection.push({ sectionName: 'Addtional Information', sectionId: sectionDiplaySettings[i].sectionId, items: this.arrAddiInfoCtrl }); break;
                    case eSection[eSection.ClassificationDropDown]: this.arrSortedBySection.push({ sectionName: 'Classification DropDown', sectionId: sectionDiplaySettings[i].sectionId, items: this.arrClassiDropdownCtrl }); break;
                    case eSection[eSection.ClassificationCheckBox]: this.arrSortedBySection.push({ sectionName: 'Classification CheckBox', sectionId: sectionDiplaySettings[i].sectionId, items: this.arrClassiCheckboxCtrl }); break;
                    case eSection[eSection.Comments]: this.arrSortedBySection.push({ sectionName: 'Comments', sectionId: sectionDiplaySettings[i].sectionId, items: this.arrCommentsCtrl }); break;
                    case eSection[eSection.ImportantDates]: this.arrSortedBySection.push({ sectionName: 'Important Dates', sectionId: sectionDiplaySettings[i].sectionId, items: this.arrImportantDatesCtrl }); break;
                    case eSection[eSection.MoreFields]: this.arrSortedBySection.push({ sectionName: 'More Fields', sectionId: sectionDiplaySettings[i].sectionId, items: this.arrMoreFieldsCtrl }); break;
                    case eSection[eSection.General]: this.arrSortedBySection.push({ sectionName: 'General', sectionId: sectionDiplaySettings[i].sectionId, items: this.arrGenCtrl }); break;
                }
            }
        }

        if (this.arrSystemCtrl.length > 0)
            this.arrSortedBySection.push({ sectionName: 'System', sectionId: 0, items: this.arrSystemCtrl });

    }

    setCountryDD(country) {
        if (this.countryList?.length > 0) {
            let code = this.countryList?.filter((item) => item?.code?.toLowerCase() == country?.toLowerCase() || item?.code2?.toLowerCase() == country?.toLowerCase() || item?.code2Lower?.toLowerCase() == country?.toLowerCase())[0]?.code;
            if (code) {
                return code;
            }
        }
        return "USA";
    }

    private async handleChangeCountry(code2: string, codeType: boolean) {
        this.countryCode = code2;

        if (code2) {
            let code2Lower: string = code2.toLowerCase();
            let found: boolean = false;
            if (this.countryList) {
                this.countryList.forEach((c) => {
                    if (codeType) {
                        if (c.code2Lower == code2Lower || c.code.toLowerCase() == code2Lower) {
                            found = true;
                            let code = c?.code2;
                            this.placeHolder = c.placeholder ? this._countryService.parseSimplePhone(c.placeholder, code as CountryCode) : '';
                            this.mobile_mask = this._countryService.replaceZero(this.placeHolder);
                            this.dialCode = c.dialCode;
                            //window.localStorage.setItem('sc_country', c?.code2.toLowerCase());
                        }
                    }
                    else {
                        if (c.code.toLowerCase() == code2Lower || c.code2Lower == code2Lower) {
                            found = true;
                            let code = c?.code2;
                            this.placeHolder = c.placeholder ? this._countryService.parseSimplePhone(c.placeholder, code as CountryCode) : '';
                            this.mobile_mask = this._countryService.replaceZero(this.placeHolder);
                            this.dialCode = c.dialCode;
                            //window.localStorage.setItem('sc_country', c?.code2.toLowerCase());
                        }
                    }
                });

                if (!found) {
                    this.placeHolder = '001234567890';
                    this.dialCode = 1;
                    this.mobile_mask = '';
                }
            }
        }

        this.resetNumberFields();
    }

    resetNumberFields() {
        if (this.contactForm) {
            this.contactForm.controls['mobile']?.patchValue('');
            this.contactForm.controls['phone']?.patchValue('');
            this.contactForm.controls['homePhone']?.patchValue('');
            this.contactForm.controls['altPhone']?.patchValue('');
        }
    }

    loadCountries() {
        this._countryService.getCountryList()
            .then((response: CountryListResponse) => {
                if (response) {
                    this.countryListResponse = UtilityService.clone(response);
                    this.countryList = UtilityService.clone(this.countryListResponse.countries);
                    this.countryList.map(val => {
                        val.code2Lower = val.code2.toLowerCase();
                    });

                    this.countryList.forEach((c) => {
                        if (c.code2.toLowerCase() == this.countryCode?.toLowerCase() || c.code.toLowerCase() == this.countryCode?.toLowerCase()) {
                            this.countryCodeSent = c.code;
                            this.handleChangeCountry(this.countryCode, true);
                        }
                    });
                }
            },
                (error) => {
                    this._utilityService.handleErrorResponse(error);
                }
            );
    }

    changeCountry($event) {
        //this.contactForm.controls['mobile'].reset();
        this.handleChangeCountry($event.target.options[$event.target.selectedIndex].getAttribute("value"), false);
    }

    setValidation() {
        //Validation on fields according to isShow(!= 2);
        let conatctsFields = this.contactFields;
        for (let key in conatctsFields) {
            let value = conatctsFields[key];
            //Set the validation and render the control
            if (!isNullOrUndefined(value))
                this.prepareContactForm(key, value);
        }
        if (this.contactForm) {
            this.contactForm.patchValue({
                cLPUserID: this.user ? this.user.cLPUserID : 0
            })
        }
    }

    private prepareContactForm(key, value) {
        this.contactForm.addControl(key, new FormControl(key == 'customDate1' || key == 'customDate2' || key == 'customDate3' || key == 'customDate4' || key == 'customDate5' ? null : key == 'eBlastAddress' ? this.contactFields?.eBlastAddress?.items[0]?.value : value.fieldType == 1 ? false : '', value.isShow == 1 ? { validators: [Validators.required], updateOn: 'blur' } : { updateOn: 'blur' }))
    }

    removeControls() {
        if (!isNullOrUndefined(this.contactFields)) {
            for (let key in this.contactFields)
                this.contactForm.removeControl(key);
        }
    }

    get contactFrm() {
        return this.contactForm.controls;
    }

    onCheckboxChange(e, field?) {
        this.contactForm.get(field)?.setValue(e?.target.checked);
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched();
                control.updateValueAndValidity();
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    patchContactFormValue() {
        var conatctsFields = this.contactFields;
        for (let key in conatctsFields) {
            let value = conatctsFields[key];
            //Set the validation and render the control
            if (!isNullOrUndefined(value))
                this.preparePatchContactFormValue(key, value);
        }

    }

    preparePatchContactFormValue(key, value) {
        if (!this.isSaveAs) {
            if (key == 'customDate1' || key == 'customDate2' || key == 'customDate3' || key == 'customDate4' || key == 'customDate5') {
                if (isNullOrEmptyString(value.fieldValue))
                    this.contactForm.get(key).setValue(null);
                else
                    this.contactForm.get(key).setValue(new Date(value.fieldValue));
            }
            else if (key == 'country') {
                this.contactForm.get(key).setValue(this.setCountryDD(value.fieldValue));
            }
            else
                this.contactForm.get(key).setValue(value.fieldValue);
        }
        else {
            if (key == 'customDate1' || key == 'customDate2' || key == 'customDate3' || key == 'customDate4' || key == 'customDate5') {
                if (isNullOrEmptyString(value.fieldValue))
                    this.contactForm.get(key).setValue(null);
                else
                    this.contactForm.get(key).setValue(new Date(value.fieldValue));
            }
            else if (key == 'contactID' || key == 'firstName' || key == 'lastName') {
                this.contactForm.get(key).setValue('');
            }
            else
                this.contactForm.get(key).setValue(value.fieldValue);
        }
    }

    contactFormSubmit() {
        console.log(this.contactForm)
        this.validateAllFormFields(this.contactForm);
        if (this.contactForm.valid) {
            this.contactForm.markAsPristine();
            this.contactFieldsUpdate();
        }
        else if (!this.contactForm.valid)
            this.notifyService.showError("Enter all mandatory fields", "Important fields Empty", 3000);
    }


    /*please verify if it is error */
    copyContactFormValuesToDataObject() {
        this.isMoreFields = false;
        if (!isNullOrEmptyString(this.contactForm.get('companyName').value) && this.companyId > 0) {
            let companyId: number = 0;
            if (this.companyName.length > 0) {
                companyId = this.companyName?.filter(item => item.companyID == this.companyId && item.companyName == this.contactForm.get('companyName').value)[0].companyID;
                if (companyId > 0) {
                    this.preparePatchDefaultCompanyAddressFormValue('companyID', companyId);
                }
                else {
                    this.preparePatchDefaultCompanyAddressFormValue('companyID', 0);
                }
            }
            else {
                this.preparePatchDefaultCompanyAddressFormValue('companyID', this.companyId);
            }
        }
        else {
            this.preparePatchDefaultCompanyAddressFormValue('companyID', 0);
        }
        var contactFormControl = this.contactForm.controls;
        for (let key in contactFormControl) {
            let value = contactFormControl[key].value;
            this.updateContactFields[key].fieldValue = value;
        }
        setTimeout(() => 10);
        if (this.isSaveAs)
            this.updateContactFields.contactID.fieldValue = this.contactForm.get('contactID').value === '' ? 0 : this.updateContactFields.contactID.fieldValue;

        if ((this._localService.showCommonComp == 'contact-new') || (this.currentUrl && this.currentUrl == 'contact-create')) {
            this.updateContactFields.cLPUserID.fieldValue = this.contactForm.get('cLPUserID').value === '' ? 0 : this.updateContactFields.cLPUserID.fieldValue;
            this.updateContactFields.salutation.fieldValue = this.contactForm.get('salutation').value === '' ? '' : this.updateContactFields.salutation.fieldValue;
            this.updateContactFields.eVID.fieldValue = this.contactForm.get('eVID').value === '' ? 0 : this.updateContactFields.eVID.fieldValue;
            //this.updateContactFields.cLPCompanyID.fieldValue = this.contactForm.get('cLPCompanyID').value === '' ? 0 : this.updateContactFields.cLPCompanyID.fieldValue;
            this.updateContactFields.cLPCompanyID.fieldValue = this.user && this.user.cLPCompanyID ? this.user.cLPCompanyID : this.contactForm.get('cLPCompanyID').value === '' ? 0 : this.updateContactFields.cLPCompanyID.fieldValue;
            this.updateContactFields.check1.fieldValue = this.contactForm.get('check1').value === '' ? false : this.updateContactFields.check1.fieldValue;
            this.updateContactFields.check2.fieldValue = this.contactForm.get('check2').value === '' ? false : this.updateContactFields.check2.fieldValue;
            this.updateContactFields.check3.fieldValue = this.contactForm.get('check3').value === '' ? false : this.updateContactFields.check3.fieldValue;
            this.updateContactFields.check4.fieldValue = this.contactForm.get('check4').value === '' ? false : this.updateContactFields.check4.fieldValue;
            this.updateContactFields.check5.fieldValue = this.contactForm.get('check5').value === '' ? false : this.updateContactFields.check5.fieldValue;
            this.updateContactFields.check6.fieldValue = this.contactForm.get('check6').value === '' ? false : this.updateContactFields.check6.fieldValue;
            this.updateContactFields.class1Code.fieldValue = this.contactForm.get('class1Code').value === '' ? 0 : this.updateContactFields.class1Code.fieldValue;
            this.updateContactFields.class2Code.fieldValue = this.contactForm.get('class2Code').value === '' ? 0 : this.updateContactFields.class2Code.fieldValue;
            this.updateContactFields.class3Code.fieldValue = this.contactForm.get('class3Code').value === '' ? 0 : this.updateContactFields.class3Code.fieldValue;
            this.updateContactFields.class4Code.fieldValue = this.contactForm.get('class4Code').value === '' ? 0 : this.updateContactFields.class4Code.fieldValue;
            this.updateContactFields.class5Code.fieldValue = this.contactForm.get('class5Code').value === '' ? 0 : this.updateContactFields.class5Code.fieldValue;
            this.updateContactFields.class6Code.fieldValue = this.contactForm.get('class6Code').value === '' ? 0 : this.updateContactFields.class6Code.fieldValue;
            this.updateContactFields.class7Code.fieldValue = this.contactForm.get('class7Code').value === '' ? 0 : this.updateContactFields.class7Code.fieldValue;
            this.updateContactFields.class8Code.fieldValue = this.contactForm.get('class8Code').value === '' ? 0 : this.updateContactFields.class8Code.fieldValue;
            this.updateContactFields.companyID.fieldValue = this.contactForm.get('companyID').value === '' ? 0 : this.contactForm.get('companyID').value;
            this.updateContactFields.contactID.fieldValue = this.contactForm.get('contactID').value === '' ? 0 : this.updateContactFields.contactID.fieldValue;
            this.updateContactFields.dtCreated.fieldValue = this.contactForm.get('dtCreated').value === '' ? new Date() : this.updateContactFields.dtCreated.fieldValue;
            this.updateContactFields.dtModified.fieldValue = this.contactForm.get('dtModified').value === '' ? new Date() : this.updateContactFields.dtModified.fieldValue;
            this.updateContactFields.eBlastAddress.fieldValue = this.contactForm.get('eBlastAddress').value === '' ? 0 : this.updateContactFields.eBlastAddress.fieldValue;
            this.updateContactFields.hasBeenEdited.fieldValue = this.contactForm.get('hasBeenEdited').value === '' ? false : this.updateContactFields.hasBeenEdited.fieldValue;
            this.updateContactFields.isOptOutEmail.fieldValue = this.contactForm.get('isOptOutEmail').value === '' ? false : this.updateContactFields.isOptOutEmail.fieldValue;
            this.updateContactFields.isOptOutTxtMsg.fieldValue = this.contactForm.get('isOptOutTxtMsg').value === '' ? 0 : this.updateContactFields.isOptOutTxtMsg.fieldValue;
            this.updateContactFields.outlookSync.fieldValue = this.contactForm.get('outlookSync').value === '' ? false : this.updateContactFields.outlookSync.fieldValue;
            this.updateContactFields.shareable.fieldValue = this.contactForm.get('shareable').value === '' ? false : this.updateContactFields.shareable.fieldValue;
            if (this.isReferred == true)
                this.updateContactFields.referralID.fieldValue = this.referralId > 0 ? this.referralId : 0;
            else
                this.updateContactFields.referralID.fieldValue = this.contactForm.get('referralID').value === '' ? 0 : this.updateContactFields.referralID.fieldValue;

        }
        if (!isNullOrUndefined(this.contactFields.contactMoreFields)) {
            this.isMoreFields = true;
            this.updateContactFields.contactMoreFields.customDate1.fieldValue = this.contactForm.get('customDate1').value === '' ? '' : this.contactForm.get('customDate1').value;
            this.updateContactFields.contactMoreFields.customDate2.fieldValue = this.contactForm.get('customDate2').value === '' ? '' : this.contactForm.get('customDate2').value;
            this.updateContactFields.contactMoreFields.customDate3.fieldValue = this.contactForm.get('customDate3').value === '' ? '' : this.contactForm.get('customDate3').value;
            this.updateContactFields.contactMoreFields.customDate4.fieldValue = this.contactForm.get('customDate4').value === '' ? '' : this.contactForm.get('customDate4').value;
            this.updateContactFields.contactMoreFields.customDate5.fieldValue = this.contactForm.get('customDate5').value === '' ? '' : this.contactForm.get('customDate5').value;
            this.updateContactFields.contactMoreFields.customText1.fieldValue = this.contactForm.get('customText1').value === '' ? '' : this.contactForm.get('customText1').value;
            this.updateContactFields.contactMoreFields.customText2.fieldValue = this.contactForm.get('customText2').value === '' ? '' : this.contactForm.get('customText2').value;
            this.updateContactFields.contactMoreFields.customText3.fieldValue = this.contactForm.get('customText3').value === '' ? '' : this.contactForm.get('customText3').value;
            this.updateContactFields.contactMoreFields.customText4.fieldValue = this.contactForm.get('customText4').value === '' ? '' : this.contactForm.get('customText4').value;
            this.updateContactFields.contactMoreFields.customText5.fieldValue = this.contactForm.get('customText5').value === '' ? '' : this.contactForm.get('customText5').value;
            this.updateContactFields.cLPCompanyID.fieldValue = this.user && this.user.cLPCompanyID ? this.user.cLPCompanyID : this.contactForm.get('cLPCompanyID').value === '' ? 0 : this.updateContactFields.cLPCompanyID.fieldValue;
        }
    }

    contactFieldsSubmit(transferConfirm) {
        this.buttonTypeOperation = eButtonActions.Save;
        this._contactService.updateContactFields(this.encryptedUser, this.updateContactFields, this.user.cLPCompanyID, this.contactFields.contactID.fieldValue, this.user.cLPUserID, transferConfirm, this.isMoreFields)
            .then(async (result: ContactFieldsResponse) => {
                this.buttonTypeOperation = eButtonActions.None;
                if (result) {
                    const response = UtilityService.clone(result);
                    if (response.messageInt == 1 && response.errorMsg.includes("To confirm the transfer")) {
                        this.confirmText = result?.errorMsg;
                        $('#contactConfirmModal').modal('show');
                        return;
                    }
                    if (response && !response?.messageBool) {
                        this.isContactUpdated = false;
                        this.notifyService.showError(response?.messageString ? response?.messageString : "Some error occurred", "", 3000);
                    }
                    else {
                        if (isNullOrUndefined(response?.errorMsg)) {
                            if (this.currentUrl && this.currentUrl == 'contact-create') {
                                this.notifyService.showSuccess("Contact created successfully", "", 3000);
                                setTimeout(() => { this.isContactUpdated = false; }, 1000);
                            }
                            else {
                                const msg = this.isSaveAs ? "created" : (this._localService.showCommonComp == 'contact-new' ? "created" : "updated");
                                this.notifyService.showSuccess("Contact " + msg + " successfully", "", 3000);
                                this.isContactUpdate.emit(true);
                                this._localService.hideCommonComponentEmit('contact-detail');
                                if (this._localService.showCommonComp == 'contact-new') {
                                    this.contactForm.reset();
                                    this._localService.showCommonComp = '';
                                }
                                this._contactSearchService.getContacts();
                                this.sendemaildatato_sendemail();
                                this.isContactUpdated = false;
                            }
                        }
                    }
                    if (this.isFromLiveConnect) {
                        this.openContact.emit(true);
                    }
                    else if (this.isFromHandle) {
                        this.hideForHandle()
                    }
                    else {
                        this._router.navigate(['/contact', this.user?.cLPUserID, result?.messageInt]);
                    }
                }
                else
                    this.isContactUpdated = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-detail.contactFieldsSubmit", err.message, this.updateContactFields,
                    'companyID: ' + this.user.cLPCompanyID
                    + 'contactID: ' + this.contactFields.contactID.fieldValue
                    + 'cLPUserID: ' + this.user.cLPUserID
                    + 'transferConfirm: ' + transferConfirm
                );
                this.isContactUpdated = false;
                this.buttonTypeOperation = eButtonActions.None;
                this._utilityService.handleErrorResponse(err);
            });
    }

    contactFieldsUpdate() {
        this.isContactUpdated = true;
        this.copyContactFormValuesToDataObject();
        this.contactFieldsSubmit(false);
    }

    confirmOperation(isConfirm: true) {
        this.contactFieldsSubmit(true);
    }

    onemail2Change(value) {
        this.email2 = value;
        this.msg_blastaddress = isNullOrEmptyString(this.email2) ? "Please enter your Email ID in Email 2 field" : "";
    }

    onemail3Change(value) {
        this.email3 = value;
        this.msg_blastaddress = isNullOrEmptyString(this.email3) ? "Please enter your Email ID in Email 3 field" : "";
    }

    sendemaildatato_sendemail() {
        if (this.contactForm.controls['eBlastAddress'].value == 0 || this.contactForm.controls['eBlastAddress'].value == 1)
            this._localService.sendEmlIdToEmail(this.contactForm.controls['email'].value);
        else if (this.contactForm.controls['eBlastAddress'].value == 2)
            this._localService.sendEmlIdToEmail(this.contactForm.controls['email2'].value);
        else
            this._localService.sendEmlIdToEmail(this.contactForm.controls['email3'].value);
    }

    isShowFields(is) {
        for (let i = 0; i < this.arrSortedBySection[is]?.items.length; i++) {
            if (!((this.arrSortedBySection[is]?.items[i]?.isShow == 2) && (this.arrSortedBySection[is]?.items[i]?.fieldType == 0 || this.arrSortedBySection[is]?.items[i]?.fieldType == 1 || this.arrSortedBySection[is]?.items[i]?.fieldType == 2)))
                return false;
        }
        return true;
    }

    async getCityState(e) {
        const zipCode = this.contactForm.controls['zip'].value;
        if (zipCode && zipCode.length >= 3) {
            await this._zipService.zip_Get(this.encryptedUser, zipCode)
                .then(async (result: ZipCodeResponse) => {
                    if (result) {
                        const response = UtilityService.clone(result);
                        const zipCode: ZipCode = response?.zipCode;
                        zipCode && zipCode?.city ? this.contactForm.patchValue({ city: zipCode?.city }) : null;
                        zipCode && zipCode?.state ? this.contactForm.patchValue({ state: zipCode?.state }) : null;
                        zipCode && zipCode?.zip ? this.contactForm.patchValue({ zip: zipCode?.zip }) : null;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("contact-detail.getCityState", err.message, null,
                        'zipCode: ' + zipCode
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async getCompanyList() {
        this.showSpinner = true;
        await this._accountSetupService.getCompanySearchData(this.encryptedUser, this.user?.cLPCompanyID, ' ')
            .then(async (result: Company[]) => {
                if (result)
                    this.companyName = UtilityService.clone(result);
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-detail.getCompanyList", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    //Tag Section
    async getTagListByOwnerId() {
        await this._tagSettingService.tagList_GetByOwner(this.encryptedUser, this.contactId, eNoteOwnerType.Contact)
            .then(async (result: TagData[]) => {
                if (!isNullOrUndefined(result))
                    this.tagListByOwner = UtilityService.clone(result);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-detail.getTagListByOwnerId", err.message, null,
                    'NoteOwnerType: ' + eNoteOwnerType.Contact
                    + 'contactId: ' + this.contactId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getTagListByCompany() {
        await this._tagSettingService.tag_GetListByCLPCompany(this.encryptedUser, this.user?.cLPCompanyID, eNoteOwnerType.Contact)
            .then(async (result: TagData[]) => {
                if (!isNullOrUndefined(result)) {
                    this.tagListByCompany = UtilityService.clone(result);
                    this.bindTagList();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-detail.getTagListByCompany", err.message, null,
                    'NoteOwnerType: ' + eNoteOwnerType.Contact
                    + 'cLPCompanyID: ' + this.user?.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    bindTagList() {
        this.tagListByCompany?.forEach(i => {
            const tagId = this.tagListByOwner?.filter(x => x.tagID == i.tagID)[0]?.tagID;
            i.checked = !isNullOrUndefined(tagId) ? true : false;
        });
    }

    async selectedTagChangeEvent(tag: string = "") {
        if (typeof (tag) === 'number' && parseInt(tag) > 0) {
            var tagData: TagData = <TagData>{};
            tagData = this.tagListByCompany?.filter(x => x.tagID == parseInt(tag))[0];
            if (tagData?.checked)
                this.tagItemDelete(tagData?.tagID);
            else
                this.tagItemCreate(tagData?.tagID);
        } else if (tag.length > 0 && tag.trim() != "")
            this.addNewTag(tag);
    }

    async tagItemCreate(tagId: number) {
        await this._tagSettingService.tagItem_Create(this.encryptedUser, tagId, this.contactId, eNoteOwnerType.Contact)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.notifyService.showSuccess(result.messageString, '', 3000);
                        this.selectedTagId = null;
                        await this.getTagListByOwnerId();
                        this.getToolTip();
                        this.bindTagList();
                    } else {
                        this.notifyService.showError(result.errorMsg, '', 3000);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-detail.tagItemCreate", err.message, null,
                    'NoteOwnerType: ' + eNoteOwnerType.Contact
                    + 'tagId: ' + tagId
                    + 'contactId: ' + this.contactId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async tagItemDelete(tagId: number) {
        await this._tagSettingService.tagItem_Delete(this.encryptedUser, tagId, this.contactId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result.messageBool) {
                        this.notifyService.showSuccess(result.messageString, '', 3000);
                        this.selectedTagId = null;
                        await this.getTagListByOwnerId();
                        this.getToolTip();
                        this.bindTagList();
                    } else {
                        this.notifyService.showError(result.errorMsg, '', 3000);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-detail.tagItemDelete", err.message, null,
                    'tagId: ' + tagId
                    + 'contactId: ' + this.contactId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async addNewTag(value: string) {
        var tagData: TagsFields = <TagsFields>{};
        tagData.cLPCompanyID = this.user?.cLPCompanyID;
        tagData.ownerType = eNoteOwnerType.Contact;
        tagData.tag = value;
        tagData.tagID = 0;
        await this._tagSettingService.tagUpdate(this.encryptedUser, tagData)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result?.messageBool)
                        this.tagItemCreate(result?.messageInt);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-detail.addNewTag", err.message, tagData,
                    ''
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getToolTip() {
        let strTooltip: string = "";
        if (this.tagListByOwner?.length > 0) {
            this.tagListByOwner?.forEach(x => {
                strTooltip += x.tagDisplay + ' ' + ', '
            })
        } else {
            strTooltip = "Tag";
        }
        return strTooltip;
    }

    //Connection Section

    getSearchData(txt, value: string = "") {
        let Search: Search = <Search>{};
        Search.searchText = txt;
        Search.searchValue = "";
        this.getContactSearch(Search);
    }

    getContactId(value, str: string = "") {
        if (this.contactSearchList?.length > 0) {
            if (str == "connection") {
                this.contactIdForConnection = value != "" ? (parseInt(this.contactSearchList.find(item => item.searchText === value)?.searchValue)) : 0;
            } else {
                this.selectedcontactId = parseInt(this.contactSearchList.find(item => item.searchText === value)?.searchValue);
                this.isContactMandatory = false;
            }
        }
    }

    getContactSearch(searchData: Search) {
        this.srchContactSrvc.getContactSearchData(this.encryptedUser, this.user?.cLPUserID, searchData)
            .then(async (result: SearchListResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    this.contactSearchList = response?.searchList.filter(i => i.searchValue.includes("ct"));
                    for (var i = 0; i < this.contactSearchList.length; i++) {
                        this.contactSearchList[i].searchValue = this.contactSearchList[i].searchValue.split("ct")[1]
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-detail.getContactSearch", err.message, searchData,
                    'cLPUserID ' + this.user?.cLPUserID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async createConnection() {
        this.copyConnectionValues();
        await this._contactService.contactConnectSave(this.encryptedUser, this.contactConnect)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.notifyService.showSuccess('Connection Added Successfully', '', 3000);
                    this.connectionRelation = "";
                    this.contactIdForConnection = 0;
                    this.autocomplete.reset();
                    this.getContactConnectByOwner();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-detail.createConnection", err.message, this.contactConnect,
                    ' '
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async updateConnection() {
        this.copyConnectionValues();
        await this._contactService.contactConnectupdate(this.encryptedUser, this.contactConnect)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.notifyService.showSuccess('Connection updated Successfully', '', 3000);
                    this.connectionRelation = "";
                    this.contactIdForConnection = 0;
                    this.selectedId = -1;
                    this.getContactConnectByOwner();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-detail.updateConnection", err.message, this.contactConnect,
                    ''
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    copyConnectionValues() {
        this.contactConnect.contactID = this.contactIdForConnection;
        this.contactConnect.contactConnectID = this.contactId;
        this.contactConnect.relationship = this.connectionRelation;
    }

    async getContactConnectByOwner() {
        await this._contactService.contactConnectListByOwner(this.encryptedUser, this.contactId)
            .then(async (result: ContactConnect_GetListByOwner) => {
                if (!isNullOrUndefined(result))
                    this.contactConnectionList = UtilityService.clone(result?.contactConnectList);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-detail.getContactConnectByOwner", err.message, null,
                    'contactId ' + this.contactId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async companyContactSearch(strData: string) {
        if (strData.length > 3) {
            await this._accountSetupService.getCompanySearchData(this.encryptedUser, this.user.cLPCompanyID, strData)
                .then(async (result: Company[]) => {
                    if (!isNullOrUndefined(result))
                        this.companyName = UtilityService.clone(result);
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("contact-detail.companyContactSearch", err.message, strData, 'cLPCompanyID ' + this.user.cLPCompanyID);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    companyContactCreate(strData: string) {
        if (this.companyName?.length <= 0 && strData.length > 3) {
            this.companyForm.controls['companyName'].setValue(strData);
            this.companyFormSubmit();
        }
    }

    async getCompanyConfig() {
        const companyFormControlsArray = Object.keys(this.companyForm.controls);
        if (!isNullOrUndefined(companyFormControlsArray) && companyFormControlsArray.length > 0) {
            companyFormControlsArray.forEach(companyControl => this.companyForm.removeControl(companyControl));
            this.companyForm.reset();
            this.companyForm.markAsPristine();
        }

        await this._accountSetupService.companyFields_GetConfiguration(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID)
            .then(async (result: CompanyFieldsResponse) => {
                if (result) {
                    this.companyFieldsResponse = UtilityService.clone(result);
                    this.companyFields = this.companyFieldsResponse?.companyFields;
                    this.renderContactCompanyFields();
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-detail.getCompanyConfig", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }
    renderContactCompanyFields() {
        if (!isNullOrUndefined(this.companyFields)) {
            const moreFields = this.companyFields;
            Object.keys(moreFields).forEach(key => {
                let value = moreFields[key];
                if (isObject(value)) {
                    value.fieldName = key;
                    this.companyFields[key] = value;
                }
            });
        }
        this.updateCompanyFields = JSON.parse(JSON.stringify(this.companyFields));
        if (!isNullOrUndefined(this.companyFields)) {
            this.setValidationCompanyForm();
            this.contactForm.patchValue({
                coClass1Code: 0,
                coClass2Code: 0,
                coClass3Code: 0,
                coClass4Code: 0,
                coClass5Code: 0,
                coClass6Code: 0,
                coClass7Code: 0,
                coClass8Code: 0,
                companyID: 0,
            });
        }
    }
    setValidationCompanyForm() {
        //Validation on fields according to isShow(!= 2);
        const companyFields = this.companyFields;
        for (let key in companyFields) {
            let value = companyFields[key];
            //Set the validation and render the control
            if (!isNullOrUndefined(value))
                this.prepareCompanyForm(key, value);
        }
        if (this.companyForm) {
            this.companyForm.patchValue({
                cLPUserID: this.user ? this.user.cLPUserID : 0,
                companyID: this.user ? this.user.cLPCompanyID : 0
            })
        }
    }
    private prepareCompanyForm(key, value) {
        this.companyForm.addControl(key, new FormControl(key == 'customDate1' || key == 'customDate2' || key == 'customDate3' || key == 'customDate4' || key == 'customDate5' || key == 'dtCreated' || key == 'dtModified' ? new Date() : value.fieldType == 1 ? false : '', value.isShow == 1 ? { updateOn: 'blur' } : { updateOn: 'blur' }))
    }

    patchCompanyFormValue() {
        const companyFields = this.companyFields;
        for (let key in companyFields) {
            let value = companyFields[key];
            //Set the validation and render the control
            if (!isNullOrUndefined(value))
                this.preparePatchCompanyFormValue(key, value);
        }
    }

    preparePatchCompanyFormValue(key, value) {
        if (key == 'customDate1' || key == 'customDate2' || key == 'customDate3' || key == 'customDate4' || key == 'customDate5' || key == 'dtCreated' || key == 'dtModified')
            this.companyForm.get(key).setValue(!isNullOrEmptyString(value.fieldValue) ? new Date(value.fieldValue) : null);
        else
            this.companyForm.get(key).setValue(value.fieldValue);
    }

    copyCompanyFormValuesToDataObject() {
        var companyFormControl = this.companyForm.controls;
        for (let key in companyFormControl) {
            let value = companyFormControl[key].value;
            this.updateCompanyFields[key].fieldValue = value;
        }
        this.updateCompanyFields.coClass1Code.fieldValue = this.updateCompanyFields?.coClass1Code?.fieldValue ? +this.updateCompanyFields.coClass1Code.fieldValue : 0;
        this.updateCompanyFields.coClass2Code.fieldValue = this.updateCompanyFields?.coClass2Code?.fieldValue ? +this.updateCompanyFields.coClass2Code.fieldValue : 0;
        this.updateCompanyFields.coClass3Code.fieldValue = this.updateCompanyFields?.coClass3Code?.fieldValue ? +this.updateCompanyFields.coClass3Code.fieldValue : 0;
        this.updateCompanyFields.coClass4Code.fieldValue = this.updateCompanyFields?.coClass4Code?.fieldValue ? +this.updateCompanyFields.coClass4Code.fieldValue : 0;
        this.updateCompanyFields.coClass5Code.fieldValue = this.updateCompanyFields?.coClass5Code?.fieldValue ? +this.updateCompanyFields.coClass5Code.fieldValue : 0;
        this.updateCompanyFields.coClass6Code.fieldValue = this.updateCompanyFields?.coClass6Code?.fieldValue ? +this.updateCompanyFields.coClass6Code.fieldValue : 0;
        this.updateCompanyFields.coClass7Code.fieldValue = this.updateCompanyFields?.coClass7Code?.fieldValue ? +this.updateCompanyFields.coClass7Code.fieldValue : 0;
        this.updateCompanyFields.coClass8Code.fieldValue = this.updateCompanyFields?.coClass8Code?.fieldValue ? +this.updateCompanyFields.coClass8Code.fieldValue : 0;
    }

    companyFormSubmit() {
        this.validateAllFormFields(this.companyForm);
        if (this.companyForm.valid) {
            this.companyForm.markAsPristine();
            this.companyFieldsUpdate();
        }
        else if (!this.companyForm.valid)
            this.notifyService.showError("Enter all mandatory fields", "Important fields Empty", 3000);
    }

    async companyFieldsUpdate() {
        this.showSpinner = true;

        this.copyCompanyFormValuesToDataObject();
        await this._accountSetupService.companyFields_Update(this.encryptedUser, this.updateCompanyFields, this.user.cLPCompanyID ? this.user.cLPCompanyID : 0, this.user.cLPUserID ? this.user.cLPUserID : 0, false,)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.updateCompanyFields.companyID.fieldValue = result.messageInt;
                    if (this.contactForm?.get('companyName'))
                        this.contactForm.patchValue({ companyName: this.updateCompanyFields.companyName.fieldValue });
                    if (this.contactForm?.get('companyID'))
                        this.contactForm.patchValue({ companyID: this.updateCompanyFields.companyID.fieldValue });
                    this.notifyService.showSuccess('Company Saved Successfully', 'Saved', 3000);
                    this.showSpinner = false;
                }
                else {
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-detail.companyFieldsUpdate", err.message, this.updateCompanyFields, 'cLPCompanyID ' + this.user.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async deleteContacts() {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Delete;
        this._contactService.deleteContact(this.encryptedUser, this.contactId, this.user.cLPUserID, this.user.slurpyUserId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this.notifyService.showSuccess('Contact deleted successfully', 'Deleted Contact' + this.contactFields?.firstName?.fieldValue + ' ' + this.contactFields?.lastName?.fieldValue, 3000);
                    this._router.navigate(['/contacts']);
                }
                else
                    this.notifyService.showError('Contact could not be deleted', 'Unable to deleted contact' + this.contactFields?.firstName?.fieldValue + ' ' + this.contactFields?.lastName?.fieldValue, 3000);

                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.notifyService.showError('Contact could not be deleted', 'Unable to deleted contact' + this.contactFields?.firstName?.fieldValue + ' ' + this.contactFields?.lastName?.fieldValue, 3000);
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                this._contactSearchService.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async deleteConnectContact(contactConnectID) {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Delete;
        this._contactService.contactConnectDelete(this.encryptedUser, contactConnectID, this.contactId)
            .then(async (result: SimpleResponse) => {
                if (result)
                    this.getContactConnectByOwner();
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-detail.deleteConnectContact", err.message, null, 'contactId ' + this.contactId + ' contactConnectID' + contactConnectID);
                this.showSpinner = false;
                this.buttonTypeOperation = eButtonActions.None;
                this._utilityService.handleErrorResponse(err);
            });
    }

    editConnection(id, connect) {
        this.selectedId = id;
        this.connectionRelation = connect.relationship;
        this.contactIdForConnection = connect?.contactID;
        this.isHideConnectionForm = true;
    }

    valueChangeCompanyName() {
        if (this.companyFieldsForNewContact?.companyID?.fieldValue > 0) {
            this.companyId = this.companyFieldsForNewContact?.companyID?.fieldValue;
            this.preparePatchDefaultCompanyAddressFormValue('companyName', this.companyFieldsForNewContact.companyName.fieldValue);
            this.preparePatchDefaultCompanyAddressFormValue('companyID', this.companyFieldsForNewContact?.companyID?.fieldValue);
            this.preparePatchDefaultCompanyAddressFormValue('add1', this.companyFieldsForNewContact.add1.fieldValue);
            this.preparePatchDefaultCompanyAddressFormValue('add2', this.companyFieldsForNewContact.add2.fieldValue);
            this.preparePatchDefaultCompanyAddressFormValue('add3', this.companyFieldsForNewContact.add3.fieldValue);
            this.preparePatchDefaultCompanyAddressFormValue('city', this.companyFieldsForNewContact.city.fieldValue);
            this.preparePatchDefaultCompanyAddressFormValue('state', this.companyFieldsForNewContact.state.fieldValue);
            this.preparePatchDefaultCompanyAddressFormValue('country', this.setCountryDD(this.companyFieldsForNewContact.country.fieldValue));
            this.preparePatchDefaultCompanyAddressFormValue('zip', this.companyFieldsForNewContact.zip.fieldValue);
        }
    }

    ngOnDestroy(): void {
        this.companyForNewContactSubscription?.unsubscribe();
        this._localService.addCompanyForContact(null);
    }

    async pinContact() {
        this.showSpinner = true;
        await this._contactService.pinnedPin(this.encryptedUser, this.user.cLPUserID, this.contactId, eNoteOwnerType.Contact)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    this.notifyService.showSuccess(response?.messageString ? response?.messageString : "", "Contact Pinned", 5000);
                    this.checkIsPinned();
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-detail.pinContact", err.message, null, 'contactId ' + this.contactId + ' cLPUserID' + this.user.cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async unpinContact() {
        this.showSpinner = true;
        await this._contactService.pinnedUnPin(this.encryptedUser, this.user.cLPUserID, this.contactId, eNoteOwnerType.Contact)
            .then(async (result: SimpleResponse) => {
                const response = UtilityService.clone(result);
                if (!isNullOrUndefined(response)) {
                    this.notifyService.showSuccess(response?.messageString ? response?.messageString : "", "Contact Unpinned", 5000);
                    this.checkIsPinned();
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-detail.unpinContact", err.message, null, 'contactId ' + this.contactId + ' cLPUserID' + this.user.cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async checkIsPinned() {
        await this._contactService.pinnedIsPin(this.encryptedUser, this.user?.cLPUserID, this.contactId, eNoteOwnerType.Contact)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result))
                    this.isPinned = result?.messageBool;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getAddressByCompanyId() {
        await this._accountSetupService.getAddressByCompanyId(this.encryptedUser, this.companyId)
            .then(async (result: CompanyObjectResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.addressByCompany = UtilityService.clone(result);
                    this.patchCompanyDefaultAddress(this.addressByCompany?.company);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }
    patchCompanyDefaultAddress(company: Company) {
        const companyKey = company;
        for (let key in companyKey) {
            let value = companyKey[key];
            //Set the validation and render the control
            if (!isNullOrUndefined(value))
                this.preparePatchDefaultCompanyAddressFormValue(key, value);
        }
    }

    selectedCompanyList(companyName) {
        const companyNewUpdate: Company = this.companyName?.filter(item => item.companyName == companyName)[0];
        if (!isNullOrUndefined(companyNewUpdate) && companyNewUpdate.companyID > 0) {
            this.companyId = companyNewUpdate.companyID;
            for (let key in companyNewUpdate) {
                let value = companyNewUpdate[key];
                this.preparePatchDefaultCompanyAddressFormValue(key, value);
            }
        }
        else {
            this.companyId = 0;
            this.contactForm.patchValue({ companyID: 0 });
        }
    }

    preparePatchDefaultCompanyAddressFormValue(key, value) {
        const keys: string[] = ["companyID", "add1", "add2", "add3", "city", "state", "zip", "country", "phone", "companyName"]
        if (keys.includes(key)) {
            this.contactForm?.get(key)?.setValue(value);
        }
    }

    goToContact() {
        this.openContact.emit(true);
    }

    hideForHandle() {
        this.openContact.emit(true);
    }

    ngAfterViewInit() {
        if (this.dateTimePicker) {
            this.dateTimePicker.toggle(true);

        }

        this.renderer.listen('document', 'click', (event: MouseEvent) => {
            const clickedInside = this.elementRef.nativeElement.contains(event.target);
            if (!clickedInside) {
                event.stopPropagation();
            }
        });
    }

    async checkDup(strBy: string) {
        let firstName = !isNullOrUndefined(this.contactForm.controls['firstName'].value) ? this.contactForm.controls['firstName'].value : "";
        let lastName = !isNullOrUndefined(this.contactForm.controls['lastName'].value) ? this.contactForm.controls['lastName'].value : "";
        let email = !isNullOrUndefined(this.contactForm.controls['email'].value) ? this.contactForm.controls['email'].value : "";
        let contactID = !isNullOrUndefined(this.contactForm.controls['contactID'].value) ? Number(this.contactForm.controls['contactID'].value) : 0;
        await this._contactService.contactDupCheck(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID, contactID, firstName, lastName, email, strBy)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result.messageBool) {
                        this.messageDup = result.messageString;
                        this.isShowMsgDup = true;
                    }
                    else {
                        this.isShowMsgDup = false;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }


}

