<form [formGroup]="vipSlideForm">
  <div class="global-card-section">
    <div class="custom-action-body">

      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../../assets/imagelisttitle.svg" class="mr-1" />{{headerTxt}}</div>
        <div class="header-button-panel">
          <div class="button-wrapper" *ngIf="!isViewMode">
            <button type="button" (click)="vipSlideFormSubmit()" [disabled]="isSubmitBtn?true:false" *ngIf="vipSlideForm?.controls.contentType.value != 0" matTooltip="Save" class="icon-btn">
              <i class="fa fa-save" aria-hidden="true"></i>
              <span class="btn-text">
                <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
                <ng-template [ngIf]="buttonTypeOperation===1">Saving  <span><i wrapper> </i></span></ng-template>
              </span>
            </button>
            <button type="button" (click)="onCancel()" matTooltip="Cancel" class="icon-btn">
              <i class="fa fa-times" aria-hidden="true"></i>
              <span class="btn-text">Cancel</span>
            </button>
          </div>
        </div>

      </div>
      <div class="global-body-section">
        <div class="">
          <div class="">
            <div class="cards-body-section" *ngIf="vipSlideForm?.controls.contentType.value == 0">
              <div class="cards-colunm-left">
                <span>Type</span>
              </div>
              <div class="cards-colunm-right">
                <select class="custom-select dropdown" id="contentType" formControlName="contentType" (change)="changeType($event.target.value)">
                  <option [value]=0>-Select One-</option>
                  <option [value]=1>HTML</option>
                  <option [value]=2>Image</option>
                  <option [value]=3>YouTube</option>
                  <option [value]=4 *ngIf="mailMergeTemplateList?.length > 0">Mail Merge Document</option>
                  <option [value]=5 *ngIf="emailTemplateList?.length > 0">Email Template</option>
                  <option [value]=6 *ngIf="isSlurpy">Web Page</option>
                </select>
              </div>
            </div>
            <div class="cards-body-section" *ngIf="vipSlideForm?.controls.contentType.value != 0">
              <div class="cards-colunm-left">
                <span>Slide Name<span class="text-danger" *ngIf="!isViewMode">*</span></span>
              </div>
              <div class="cards-colunm-right">
                <span *ngIf="isViewMode"> {{vipSlideForm.controls['slideTitle'].value}}</span>
                <input *ngIf="!isViewMode" type="text" [ngClass]="{'has-error': vipSlideFrm.slideTitle.errors && (vipSlideFrm.slideTitle.touched || vipSlideFrm.slideTitle.dirty)}" class="form-control" id="slideTitle" formControlName="slideTitle" />
                <div *ngIf="vipSlideFrm.slideTitle.errors && (vipSlideFrm.slideTitle.touched || vipSlideFrm.slideTitle.dirty)">
                  <div class="required-error" *ngIf="vipSlideFrm.slideTitle.errors.required">Please enter a slide name.</div>
                </div>
              </div>
            </div>
            <div class="cards-body-section" *ngIf="vipSlideForm?.controls.contentType.value != 0 && vipSlideCategoryList?.length > 0">
              <div class="cards-colunm-left">
                <span>Slide Category</span>
              </div>
              <div class="cards-colunm-right">
                <span *ngIf="isViewMode"> {{getVipSlideCategoryContent(vipSlideForm.controls['vipSlidecategoryCode'].value)}}</span>
                <select *ngIf="!isViewMode" class="custom-select dropdown" id="vipSlidecategoryCode" formControlName="vipSlidecategoryCode">
                  <option [value]="0">-None Selected-</option>
                  <option *ngFor="let item of vipSlideCategoryList" [value]="item.vipSlideCategoryCode">{{item.display}}</option>
                </select>
              </div>
            </div>
            <div class="cards-body-section" *ngIf="vipSlideForm?.controls.contentType.value == 2 || vipSlideForm?.controls.contentType.value == 3 || vipSlideForm?.controls.contentType.value == 6">
              <div class="cards-colunm-left">
                <span>URL<span class="text-danger" *ngIf="!isViewMode">*</span></span>
              </div>
              <div class="cards-colunm-right">
                <span *ngIf="isViewMode"> {{vipSlideForm.controls['urlContent'].value}}</span>
                <input *ngIf="!isViewMode" type="text" [ngClass]="{'has-error': vipSlideFrm.urlContent.errors && (vipSlideFrm.urlContent.touched || vipSlideFrm.urlContent.dirty)}" class="form-control" id="urlContent" formControlName="urlContent" />
                <div *ngIf="!isViewMode && vipSlideFrm.urlContent.errors && (vipSlideFrm.urlContent.touched || vipSlideFrm.urlContent.dirty)">
                  <div class="required-error" *ngIf="vipSlideFrm.urlContent.errors.required">Please enter a URL.</div>
                </div>
              </div>
            </div>
            <div class="cards-body-section" *ngIf="vipSlideForm?.controls.contentType.value == 2">
              <div class="cards-colunm-left">
                <span>Link</span>
              </div>
              <div class="cards-colunm-right">
                <span *ngIf="isViewMode"> {{vipSlideForm.controls['link'].value}}</span>
                <input *ngIf="!isViewMode" type="text" class="form-control" id="link" formControlName="link" />
              </div>
            </div>
            <div class="cards-body-section" *ngIf="vipSlideForm?.controls.contentType.value == 2 && !isViewMode">
              <div class="cards-colunm-left">
                <span>Upload Image</span>
              </div>
              <div class="cards-colunm-right">
                <div class="order-row-inner">
                  <!-- <angular-file-uploader vipImageUpload [config]="vipDocConfig" (ApiResponse)="apiResponse($event)" #vipImageUpload></angular-file-uploader>-->
                  <div class="uploader-panel">
                    <kendo-upload [saveUrl]="uploadSaveUrl"
                                  [restrictions]="uploadRestrictions"
                                  (success)="apiResponseSuccess($event)"
                                  (error)="apiResponseError($event)"
                                  [saveHeaders]="fileUploadHeaders"
                                  [multiple]="false"
                                  [autoUpload]="false">
                    </kendo-upload>
                    <span>&nbsp;&nbsp;(jpg,png,eps,jpeg,gif)</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="cards-body-section" *ngIf="vipSlideForm?.controls.contentType.value == 4">
              <div class="cards-colunm-left">
                <span>Mail Merge Document</span>
              </div>
              <div class="cards-colunm-right">
                <span *ngIf="isViewMode"> {{getMailMergeContent(vipSlideForm.controls['mailMergeContent'].value)}}</span>
                <select *ngIf="!isViewMode" class="custom-select dropdown" id="mailMergeContent" formControlName="mailMergeContent" (change)="loadMailMergeTemplate($event.target.value)">
                    <option [value]="0">-Select One-</option>
                  <option *ngFor="let item of mailMergeTemplateList" [value]="item.mailMergeTemplateID">{{item.templateName}}</option>
                </select>
              </div>
            </div>
            <div class="cards-body-section" *ngIf="vipSlideForm?.controls.contentType.value == 5">
              <div class="cards-colunm-left">
                <span>Email Template</span>
              </div>
              <div class="cards-colunm-right">
                <span *ngIf="isViewMode"> {{getEmailTempContent(vipSlideForm.controls['emailTemplateContent'].value)}}</span>
                <select *ngIf="!isViewMode" class="custom-select dropdown" id="emailTemplateContent" formControlName="emailTemplateContent">
                    <option [value]="0">Select One-</option>
                  <option *ngFor="let item of emailTemplateList" [value]="item.emailTemplateID">{{item.templateName}}</option>
                </select>
              </div>
            </div>
            <div class="cards-body-section" *ngIf="vipSlideForm?.controls.contentType.value == 1">
              <div class="cards-colunm-left">
                <span>Slide HTML</span>
              </div>
              <div class="cards-colunm-right">
                <kendo-editor *ngIf="!isViewMode" class="for-editor" id="htmlContent" formControlName="htmlContent">
                  <kendo-toolbar>
                    <kendo-toolbar-buttongroup>
                      <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                    <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                    <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                    <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                  </kendo-toolbar>
                </kendo-editor>
              </div>
            </div>
            <div class="cards-body-section" *ngIf="vipId > 0 && vipSlideId > 0 && !isViewMode">
              <div class="cards-colunm-left">
                <div><a (click)="isUploadAudio = false;">Record Audio / Video Bubble </a></div><div><a *ngIf="!isViewMode" (click)="isUploadAudio = true; getVipAudioUploadConfig();">Record Audio</a></div>
              </div>
              <div class="cards-colunm-right record-button" *ngIf="!isViewMode">
                <div [hidden]="isUploadAudio">
                  <app-audiovideo [vipId]="vipId" [slideId]="vipSlideId"></app-audiovideo>
                </div>
                <div [hidden]="!isUploadAudio">
                  <div class="order-row-inner">
                    <!--<angular-file-uploader vipAudioUpload [config]="vipAudioDocConfig" (ApiResponse)="apiAudioResponse($event)" #vipAudioUpload></angular-file-uploader>-->
                    <div class="uploader-panel">
                      <kendo-upload [saveUrl]="uploadAudioSaveUrl"
                                    [restrictions]="uploadAudioRestrictions"
                                    (success)="apiAudioResponseSuccess($event)"
                                    (error)="apiAudioResponseError($event)"
                                    [saveHeaders]="fileUploadHeaders"
                                    [multiple]="false"
                                    [autoUpload]="false">
                      </kendo-upload>
                      <span>&nbsp;&nbsp;(mp3)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cards-body-section" *ngIf="vipSlideForm?.controls.contentType.value != 0">
              <div class="cards-colunm-left">
                <span>Script</span>
              </div>
              <div class="cards-colunm-right">
                <kendo-editor *ngIf="!isViewMode" class="for-editor" id="script" formControlName="script">
                  <kendo-toolbar>
                    <kendo-toolbar-buttongroup>
                      <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                      <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                    </kendo-toolbar-buttongroup>
                    <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                    <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                    <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                    <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                  </kendo-toolbar>
                </kendo-editor>
              </div>
            </div>
            <div class="cards-body-section" *ngIf="vipSlideForm?.controls.contentType.value != 0 && !isViewMode">
              <div class="cards-colunm-left"></div>
              <div class="cards-colunm-right">
                <div class="bottom-button-bar">
                  <button class="btn btn-primary" type="button" (click)="vipSlideFormSubmit()" [disabled]="isSubmitBtn?true:false"> Save</button>
                  <button class="btn btn-cancel" type="button" (click)="onCancel()"> Cancel</button>
                  <button class="btn btn-danger" type="button" *ngIf="vipSlideId > 0" (click)="deleteSlides()">Delete</button>
                </div>
              </div>
            </div>
            <div class="cards-body-section" *ngIf="isViewMode">
              <div class="cards-colunm-left">
              </div>
              <div class="cards-colunm-right">
                <div class="row">
                  <div class="col-md-6"> <div [innerHTML]="vipSlideForm.controls['htmlContent'].value | safeHtml"></div></div>
                  <div class="col-md-6">  <div [innerHTML]="vipSlideForm.controls['script'].value | safeHtml"></div></div>
                </div>
              </div>
            </div>
            <div class="cards-body-section">
              <div class="cards-colunm-left">
              </div>
              <div class="cards-colunm-right">
                <div class="row">
                  <div class="col-md-6">  <div [innerHTML]="htmlDisplay | safeHtml"></div></div>
                </div>
              </div>
            </div>
            <div class="cards-body-section" *ngIf="vipSlideForm?.controls.contentType.value == 2">
              <div class="cards-colunm-left">
              </div>
              <div class="cards-colunm-right">
                <div class="row">
                  <img [src]="vipSlideForm.controls['urlContent'].value" class="img-thumbnail bee-thumbnail">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="modal fade" id="deleteSlidesModal" tabindex="-1" role="dialog" aria-labelledby="deleteSlidesModalLabel" aria-hidden="true">
  <div class="modal-dialog  modal-common-dialog" role="document">
    <div class="modal-content modal-common-content">
      <div class="modal-header modal-common-background">
        <h4 class="modal-title modal-common-title">Confirmation</h4>
      </div>
      <div class="modal-body modal-common-body">
        <h2>This will delete this slide. Please confirm this action.</h2>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="deleteSlideConfirm()" data-dismiss="modal" class="btn btn-primary">
          <ng-template [ngIf]="buttonTypeOperation!=0">ok</ng-template>
          <ng-template [ngIf]="buttonTypeOperation===0">Deleting  <span> &nbsp;<i wrapper> </i></span></ng-template>
        </button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
