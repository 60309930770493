import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { stat } from 'fs';
import { Observable, Subscription, timer } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { eFeatures, eLiveConnectCheckedInMethod, eLiveConnectCLPUserStatus, eLiveConnectItemActionStatus, eLiveConnectItemObjectType, eLiveConnectItemStatus, eNoteOwnerType, eUserRole } from '../../../models/enum.model';
import { IntDropDownItem, SimpleResponse } from '../../../models/genericResponse.model';
import { LiveConnectItem } from '../../../models/live-connect-item.model';
import { LiveConnectDashHeaderResponse, LiveConnectDashLoadRequest, LiveConnectDashLoadResponse, LiveConnectHeader, LiveConnectHeaderResponse } from '../../../models/live-connect.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { LiveConnectService } from '../../../services/live-connect.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { Title } from '@angular/platform-browser';
import { ContactService } from '../../../services/contact.service';
import { Contact } from '../../../models/contact.model';
import { GlobalService } from '../../../services/global.service';
import { AppconfigService } from '../../../services/shared/appconfig.service';
import { ConfigDetailsWithKey } from '../../../models/appConfig.model';
import { FALSE } from 'sass';

import { MailMergeTemplateLoad } from '../../../models/marketing.model';
import { MarketingService } from '../../../services/marketing.service';

@Component({
    selector: 'liveconnect-dashboard',
    templateUrl: './liveconnect-dashboard.component.html',
    styleUrls: ['./liveconnect-dashboard.component.css']
})
/** liveconnect-dashboard component*/
export class LiveconnectDashboardComponent {
    /** liveconnect-dashboard ctor */

    mailMergeTemplateResponse: MailMergeTemplateLoad;

    eLiveConnectItemObjectType = eLiveConnectItemObjectType;
    eLiveConnectItemStatus = eLiveConnectItemStatus;
    liveConnectDashboardLoadResponse: LiveConnectDashLoadResponse;
    liveConnectDashHeaderResponse: LiveConnectDashHeaderResponse;
    liveConnectHeader: LiveConnectHeader;
    liveConnectItemList: LiveConnectItem[] = [];
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    user: CLPUser;
    selectedLiveConnectClpUserId: number = 0;
    objectId: number = 0;
    objectType: eLiveConnectItemObjectType = eLiveConnectItemObjectType.Unknown;
    eLiveConnectItemActionStatus = eLiveConnectItemActionStatus;
    pinnedUserList: IntDropDownItem[] = [];
    contactData: Contact = <Contact>{};
    @Input() mailMergeTemplateId: number;
    isStart: boolean = true;
    isShowAdvanced: boolean = false;

    everyFiveSeconds: Observable<number> = timer(0, 2000);
    subscription: Subscription;
    htmlDisplay: any;
    filterLiveConnect: string[];

    dtStart: Date = new Date();
    dtEnd: Date = new Date();
    todaysDate = new Date();

    encryptedUser: string = '';
    encryptionKey: string = '';
    txtNote: string = '';
    pinnedUserId: string = '0';
    ddLiveConnectName: string = "";
    ddRating: string = "";
    ddType: string = "0";
    txtSearchStr: string = "";
    editRowIndex: number = -1;
    dateFormat: string = "MM/dd/yyyy";
    company: ClpCompany = <ClpCompany>{};
    /*variable for child component*/
    selectedLiveConnectObj: LiveConnectItem = <LiveConnectItem>{};
    contactLiveConnectObj: LiveConnectItem = <LiveConnectItem>{};
    isShowMergeMailScreen: boolean = false;
    isShowContactScreen: boolean = false;
    isShowReplyScreen: boolean = false;
    isShowApptScreen: boolean = false;
    isShowCallScreen: boolean = false;
    isShowNoteScreen: boolean = false;
    isShowEmailScreen: boolean = false;
    isShowMakeCallScreen: boolean = false;
    isShowHandleTmScreen: boolean = false;
    isShowEditContact: boolean = false;
    isShowContactHistory: boolean = false;
    isShowUpperTabs: boolean = false;
    isSRS: boolean = false;
    isSRSManager: boolean = false;
    isLCUser: boolean = false;
    isUseImage: boolean = false;
    logo: string = "";
    isShowActivityHistory: boolean = false;
    ownerName: string = "";
    isFromHandle: boolean = false;
    constructor(private datePipe: DatePipe,
        private titleService: Title,
        private _utilityService: UtilityService,
        private _accountSetupService: AccountSetupService,
        public _contactService: ContactService,
        private _router: Router,
        private _localService: LocalService,
        private _liveConnectSrvc: LiveConnectService,
        private _appConfigService: AppconfigService,
        public _globalService: GlobalService,
        private _marketingService: MarketingService     ) {
    }

    ngOnInit() {
        if (localStorage.getItem('title'))
            this.titleService.setTitle("Live Connect");
        this._router.routeReuseStrategy.shouldReuseRoute = () => false;
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.getEncryptionKey();
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.dateFormat = this.user.dateFormat;
                        this._localService.isMenu = false;
                        this.dtStart = new Date(this.dtStart.setDate(this.dtStart.getDate() - 14));
                        this.loadInit();
                    }
                    else {
                        this._router.navigate(['/login']);
                    }
                })
            }
            else {
                this._router.navigate(['/login']);
            }
        })
    }

    async loadInit() {
        await this.liveConnect_isLiveConnectSRSAccount();
        await this.liveConnectCLPUser_isLiveConnectCLPUser();
        await this.getCompanyData();
        if (this.user.cLPUserID > 0 && (this.isLCUser || this.user.slurpyUserId > 0 || this.isSRSManager)) {
            if (this.isUseImage)
                this.logo = this.company?.logoURL;
        }
        this.getLiveConnectDashboardHeaderResponse();
        this.getLiveConnectDashboardItemsList(false);
    }

    async getCompanyData() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.company = response?.company;
                    this.isUseImage = response.company.useImage;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("liveconnect-dashboard.getCompanyData", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }
    async liveConnect_isLiveConnectSRSAccount() {
        await this._liveConnectSrvc.liveConnect_isLiveConnectSRSAccount(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.isSRS = result?.messageBool;
                    this.isSRSManager = (this.isSRS && this.user?.userRole > eUserRole.Manager) ? true : false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("liveconnect-dashboard.liveConnect_isLiveConnectSRSAccount", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async liveConnectCLPUser_isLiveConnectCLPUser() {
        await this._liveConnectSrvc.liveConnectCLPUser_isLiveConnectCLPUser(this.encryptedUser, this.user?.cLPUserID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.isLCUser = result?.messageBool;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("liveconnect-dashboard.liveConnectCLPUser_isLiveConnectCLPUser", err.message, null, 'cLPUserID ' + this.user.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });
    }
    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse?.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("liveconnect-dashboard.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async getLiveConnectDashboardHeaderResponse() {
        this._liveConnectSrvc.liveConnectDashBoardHeader_Load(this.encryptedUser)
            .then(async (result: LiveConnectDashHeaderResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.liveConnectDashHeaderResponse = UtilityService.clone(result);
                    this.liveConnectHeader = this.liveConnectDashHeaderResponse?.liveConnectHeader;
                    /* ------------------------------------*/
                    if (this.liveConnectHeader?.ButtonTextCheckInOut != "Checked Out" && this.isStart)
                    {
                         this.cyclicEvent();
                        this.getLiveConnectDashboardItemsList(true);
                    }
                    else {
                        this.subscription?.unsubscribe();
                        this.getLiveConnectDashboardItemsList(true);
                    }
                    /*   -------------------------------------*/
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("liveconnect-dashboard.getLiveConnectDashboardHeaderResponse", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getLiveConnectDashboardItemsList(isRefresh: boolean = true) {
        var liveConnectDashboardObj: LiveConnectDashLoadRequest = <LiveConnectDashLoadRequest>{};
        liveConnectDashboardObj.clpCompanyID = this.user?.cLPCompanyID;
        liveConnectDashboardObj.isFilerVisible = this.isStart ? false : true;
        if (+this.selectedLiveConnectClpUserId > 0)
            liveConnectDashboardObj.liveConnectClpuserID = +this.selectedLiveConnectClpUserId;
        else
            liveConnectDashboardObj.liveConnectClpuserID = this.user?.cLPUserID;
        if (this.isShowAdvanced) {
            liveConnectDashboardObj.endDate = this.datePipe.transform(this.dtEnd, 'MMddyyyy');
            liveConnectDashboardObj.startDate = this.datePipe.transform(this.dtStart, 'MMddyyyy');
        }

        if (!isNullOrEmptyString(this.ddType) && this.ddType != "0")
            liveConnectDashboardObj.eType = eLiveConnectItemObjectType[this.ddType];

        if (!isNullOrEmptyString(this.ddLiveConnectName))
            liveConnectDashboardObj.selectedLiveConnectName = this.ddLiveConnectName;

        if (!isNullOrEmptyString(this.ddRating))
            liveConnectDashboardObj.selectedRating = this.ddRating;

        if (!isNullOrEmptyString(this.txtSearchStr))
            liveConnectDashboardObj.strSearchItem = this.txtSearchStr;

        await this.getDashboardList(liveConnectDashboardObj, isRefresh);

    }

    async getDashboardList(liveConnectDashboardObj: LiveConnectDashLoadRequest, isPageLoad: boolean = false) {
        await this._liveConnectSrvc.liveConnectDashboard_GetList(this.encryptedUser, liveConnectDashboardObj)
            .then(async (result: LiveConnectDashLoadResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageString == "UnAuthorized") {
                        this._router.navigate(['/unauthorized'], { state: { isMenu: false } });
                    } else {
                        this.liveConnectDashboardLoadResponse = UtilityService.clone(result);
                        this.liveConnectItemList = this.liveConnectDashboardLoadResponse?.liveConnectItemList;
                        if (!isPageLoad)
                            this.pinnedUserList = this.liveConnectDashboardLoadResponse?.filterUser;
                        this.filterLiveConnect = this.liveConnectDashboardLoadResponse?.filterLiveConnect;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("liveconnect-dashboard.getLiveConnectDashboardItemsList", err.message, liveConnectDashboardObj);
                this._utilityService.handleErrorResponse(err);
            });
    }

    private async getEncryptionKey() {
        await this._appConfigService.getAppConfigValues(this.encryptedUser, ["DBNotificationsEncryptionKey"])
            .then(async (result: ConfigDetailsWithKey[]) => {
                if (!isNullOrUndefined(result)) {
                    this.encryptionKey = result.filter(i => i.configKey == "DBNotificationsEncryptionKey")[0].configValue;
                    this.getDBNotifications();
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("liveconnect-dashboard.getEncryptionKey", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getDBNotifications() {
        if (!isNullOrEmptyString(this.encryptionKey)) {
            this._globalService.pollingDBNotifications(this.encryptedUser, this.encryptionKey, +this.selectedLiveConnectClpUserId, true)
                .then(async (result: string) => {
                    if (result == 'Y') {
                        this.getLiveConnectDashboardItemsList(true);
                        this.getLiveConnectDashboardHeaderResponse();
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("liveconnect-dashboard.getDBNotifications", err.message, null, 'encryptionKey ' + this.encryptionKey + 'selectedLiveConnectClpUserId ' + this.selectedLiveConnectClpUserId + 'isCLientRequest ' + true);
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else
            this.getEncryptionKey();
    }

    onRefresh() {
        this.todaysDate = new Date();
        this.getLiveConnectDashboardItemsList(true);
    }

    cyclicEvent() {
        this.subscription = this.everyFiveSeconds.subscribe(() => {
            this.todaysDate = new Date();
            //this.getLiveConnectDashboardItemsList();
            this.getDBNotifications();
        });
    }

    async handleLiveConnectItem(liveConnectItemObj: LiveConnectItem, status: eLiveConnectItemActionStatus) {
        this._liveConnectSrvc.handle_LiveConnectItem(this.encryptedUser, liveConnectItemObj, status, status == eLiveConnectItemActionStatus.Pinned ? (+ this.pinnedUserId) : 0, this.user?.cLPUserID)
            .then(async (result: any) => {
                if (!isNullOrUndefined(result)) {
                    if (status == eLiveConnectItemActionStatus.Pinned) {
                        this.editRowIndex = -1;
                        this.pinnedUserId = '0';
                        this.getLiveConnectDashboardHeaderResponse();
                        this.getLiveConnectDashboardItemsList(false);
                    }
                    if (status == eLiveConnectItemActionStatus.Handle) {
                        this.getLiveConnectDashboardItemsList(false);
                        this.redirectItem(liveConnectItemObj);
                        this.redirectItem(liveConnectItemObj);
                    }
                    if (status == eLiveConnectItemActionStatus.Pin) {
                        this.stopTimer();
                        if (!isNullOrUndefined(liveConnectItemObj.notes)) {
                            this.txtNote = liveConnectItemObj.notes;
                        }
                        else {
                            this.txtNote = "";
                        }
                    }
                    if (status != eLiveConnectItemActionStatus.Pin) {
                        this.getLiveConnectDashboardHeaderResponse();
                        this.getLiveConnectDashboardItemsList(false);
                    }
                    if (status == eLiveConnectItemActionStatus.MarkAsAlert) {
                        this.getLiveConnectDashboardHeaderResponse();
                        this.getLiveConnectDashboardItemsList(false);
                    }
                    if (status == eLiveConnectItemActionStatus.Close) {
                        this.getLiveConnectDashboardHeaderResponse();
                        this.getLiveConnectDashboardItemsList(false);
                    }
                     
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("liveconnect-dashboard.handleLiveConnectItem", err.message, liveConnectItemObj, 'status ' + status + 'toPinnedcLPUserID ' + (status == eLiveConnectItemActionStatus.Pinned ? (+ this.pinnedUserId) : 0) + 'cLPUserID ' + this.user?.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async onChangePinnedUserList(liveConnectItemObj: LiveConnectItem) {
        if (+this.pinnedUserId == -1 || +this.pinnedUserId > 0) {
            liveConnectItemObj.notes = this.txtNote ?? "";
            await this.handleLiveConnectItem(liveConnectItemObj, eLiveConnectItemActionStatus.Pinned)
            this.txtNote = "";
        } else {
            this.editRowIndex = -1;
            this.txtNote = "";
            this.pinnedUserId = '0';
        }
    }

    getDataByObjectType(liveConnectItemObj: LiveConnectItem, value: string) {
        var typeDisplay: string = "";
        var fontColor: string = "";
        switch (+liveConnectItemObj?.objectType) {
            case eLiveConnectItemObjectType.VoiceCall:
                typeDisplay = "Call";
                fontColor = "color: #FF8431";
                break;
            case eLiveConnectItemObjectType.VoiceMail:
                typeDisplay = "Voicemail";
                fontColor = "color: #FF8431";
                break;
            case eLiveConnectItemObjectType.TxtMsgIB:
                typeDisplay = "SMS";
                fontColor = "color: #5FBBB6";
                break;
            case eLiveConnectItemObjectType.Slidecast:
                typeDisplay = "Slidecast";
                fontColor = "color: #177EFB";
                break;
            case eLiveConnectItemObjectType.Agreement:
                typeDisplay = "Agreement";
                fontColor = "color: #ffc800";
                break;
            case eLiveConnectItemObjectType.DynamicScheduledApptDep: case eLiveConnectItemObjectType.Appt:
                typeDisplay = "Event";
                fontColor = "color: #2DC76D";
                break;
            case eLiveConnectItemObjectType.Contact:
                typeDisplay = "Contact";
                fontColor = "color: #25265e";
                break;
            case eLiveConnectItemObjectType.AutomationAlert:
                typeDisplay = this.extractAutomationTypeDisplay(liveConnectItemObj.itemDetail);
                fontColor = "color: #ff7052";
                break;
        }
        switch (value) {
            case "typeDisplay":
                return typeDisplay;
            case "fontColor":
                return fontColor;
        }
    }

    extractAutomationTypeDisplay(itemDetail: string) {
        var strTitle: string = "Alert";
        var isExist: boolean = itemDetail.includes("~Contact:");
        if (isExist) {
            strTitle = itemDetail.split("Contact:")[0].replace("~", "");
            return strTitle;
        }
    }

    extractAutomationItemDetails(liveConnectItemObj: LiveConnectItem) {
        var strOut: string = liveConnectItemObj?.itemDetail;
        var isExist: boolean = liveConnectItemObj?.itemDetail.includes("~Contact:");
        if (isExist) {
            strOut = liveConnectItemObj?.itemDetail.split("Contact:")[1];
        }
        if (!isNullOrEmptyString(liveConnectItemObj.rating)) {
            strOut += "<br /><span style='font-weight: bold; color:black; font-size:10pt;'><b>Rating:</b>" + liveConnectItemObj.rating.replace("SM", "<span style='color:red;'>SM</span>") + "</span>";
        }
        if (!isNullOrEmptyString(liveConnectItemObj.notes)) {
            strOut += "<br /><span style='font-weight: bold; color:green; font-size:10pt;'>" + liveConnectItemObj.notes + "</span>";
        }
        return strOut;
    }

    getHandledBy(liveConnectItemObj: LiveConnectItem) {
        var strOut: string = "";
        if (!isNullOrEmptyString(liveConnectItemObj?.dtHandled)) {
            strOut = "handled " + this.datePipe.transform(liveConnectItemObj?.dtHandled, "M/dd h:mm a");
            if (liveConnectItemObj?.isSRS && this.user?.slurpyUserId <= 0)
                strOut += " by SRS";
            else {
                if (!isNullOrEmptyString(liveConnectItemObj?.repName))
                    strOut += " by " + liveConnectItemObj.repName;
            }
        }
        return strOut;
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    stopTimer() {
        this.isStart = !this.isStart;
        if (this.isStart == true) {
            this.cyclicEvent();
        } else
            this.subscription?.unsubscribe();
    }

    onAdvance() {
        this.isShowAdvanced = !this.isShowAdvanced;
        if (this.isShowAdvanced == true) {
            this.getLiveConnectDashboardItemsList(false);
        } else {
            this.dtStart = new Date(this.dtStart.setDate(this.dtStart.getDate() - 14));
            this.dtEnd = new Date();
        }
    }

    redirectItem(liveConnectItemObj: LiveConnectItem, isFromOtherChild: boolean = false, objectType: eLiveConnectItemObjectType = eLiveConnectItemObjectType.Unknown) {
        this.editRowIndex = -1;
        this.isShowMergeMailScreen = false;
        this.isShowContactScreen = false;
        this.isShowApptScreen = false;
        this.isShowCallScreen = false;
        this.isShowReplyScreen = false;
        this.isShowNoteScreen = false;
        this.isShowEmailScreen = false;
        this.isShowMakeCallScreen = false;
        this.isShowHandleTmScreen = false;
        this.isShowEditContact = false;
        this.isShowContactHistory = false;
        this.isShowActivityHistory = false;
        this.isShowUpperTabs = false;
        this.selectedLiveConnectObj = UtilityService.clone(liveConnectItemObj);
        if (!isFromOtherChild) {
            this.getContact(liveConnectItemObj.contactID);
            this.contactLiveConnectObj = this.selectedLiveConnectObj;
        }
        this.selectedLiveConnectObj.objectID = isFromOtherChild ? liveConnectItemObj.contactID : liveConnectItemObj.objectID;
        this.selectedLiveConnectObj.objectType = isFromOtherChild ? objectType : liveConnectItemObj.objectType;
        switch (this.selectedLiveConnectObj.objectType) {
            case eLiveConnectItemObjectType.TxtMsgIB:
                this.isShowReplyScreen = false;
                setTimeout(() => this.isShowReplyScreen = true, 0);
                break;
            case eLiveConnectItemObjectType.VoiceCall:
                this.isShowCallScreen = false;
                setTimeout(() => this.isShowCallScreen = true, 0);
                break;
            case eLiveConnectItemObjectType.Appt:
                this.isShowApptScreen = false;
                setTimeout(() => this.isShowApptScreen = true, 0);
                break;
            default:
                this.isShowContactScreen = false;
                setTimeout(() => this.isShowContactScreen = true, 0);
                break;
        }
        this.isShowUpperTabs = false;
        setTimeout(() => this.isShowUpperTabs = true, 0);
    }


    openSubChilds(liveConnectItemObj: LiveConnectItem, show: string = 'note') {
        this.isShowMergeMailScreen = false;
        this.isShowContactScreen = false;
        this.isShowApptScreen = false;
        this.isShowCallScreen = false;
        this.isShowReplyScreen = false;
        this.isShowNoteScreen = false;
        this.isShowEmailScreen = false;
        this.isShowMakeCallScreen = false;
        this.isShowHandleTmScreen = false;
        this.isShowEditContact = false;
        this.isShowContactHistory = false;
        this.selectedLiveConnectObj = UtilityService.clone(liveConnectItemObj);
        this.selectedLiveConnectObj.objectID = liveConnectItemObj.contactID;
        this.selectedLiveConnectObj.objectType = eNoteOwnerType.Contact;
        switch (show) {
            case "note":
                this.isShowNoteScreen = false;
                setTimeout(() => this.isShowNoteScreen = true, 0);
                break;
            case "email":
                this.isShowEmailScreen = false;
                setTimeout(() => this.isShowEmailScreen = true, 0);
                break;
            case "makecall":
                this.isShowMakeCallScreen = false;
                setTimeout(() => this.isShowMakeCallScreen = true, 0);
                break;
            case "sendSMS":
                this.isShowHandleTmScreen = false;
                setTimeout(() => this.isShowHandleTmScreen = true, 0);
                break;
            case "editContact":
                this.isShowEditContact = false;
                setTimeout(() => this.isShowEditContact = true, 0);
                break;
            case "contactHistory":
                this.isShowContactHistory = false;
                setTimeout(() => this.isShowContactHistory = true, 0);
                break;
            case "activityHistory":
                this.isShowActivityHistory = false;
                setTimeout(() => this.isShowActivityHistory = true, 0);
                break;
        }
    }


    async checkInCheckOutRing(eStat: eLiveConnectCLPUserStatus, eMeth: eLiveConnectCheckedInMethod) {
        this._liveConnectSrvc.checkInOutRing(this.encryptedUser, this.user?.cLPUserID, eStat, eMeth)
            .then(async (result: LiveConnectHeaderResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.getLiveConnectDashboardHeaderResponse();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("liveconnect-dashboard.checkInCheckOutRing", err.message, null, 'liveconnectCLpUserId ' + this.user?.cLPUserID + 'eStat ' + eStat + 'eMeth ' + eMeth);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getArchive(contactID: number) {
        const url = this._router.serializeUrl(
            this._router.createUrlTree(['/archive-live-connect', 3]));
        localStorage.setItem("contactID", contactID.toString());
        window.open(url, "_blank");
    }

    async getContact(value: number) {
        await this._contactService.contactLoad(this.encryptedUser, value)
            .then(async (result: Contact) => {
                if (result) {
                    this.contactData = UtilityService.clone(result);
                    this.ownerName = this.contactData?.firstName + " " + this.contactData?.lastName;
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("liveconnect-dashboard.getContact", err.message, null, 'contactID ' + value);
                this._utilityService.handleErrorResponse(err);
            });
    }


    getBorderCssByEventType(liveConnectItemObj: LiveConnectItem) {
        switch (+liveConnectItemObj?.objectType) {
            case eLiveConnectItemObjectType.VoiceCall:
                return "voice-call-border";
            case eLiveConnectItemObjectType.VoiceMail:
                return "voice-mail-border";
            case eLiveConnectItemObjectType.TxtMsgIB:
                return "text-msg-border";
            case eLiveConnectItemObjectType.Slidecast:
                return "slidecast-border";
            case eLiveConnectItemObjectType.Agreement:
                return "agreement-border";
            case eLiveConnectItemObjectType.DynamicScheduledApptDep: case eLiveConnectItemObjectType.Appt:
                return "dynamic-border";
            case eLiveConnectItemObjectType.Contact:
                return "contact-border";
            case eLiveConnectItemObjectType.AutomationAlert:
                return "automation-alert-border";
        }
    }

    getBtnCssByEventType(liveConnectItemObj: LiveConnectItem) {
        switch (+liveConnectItemObj?.objectType) {
            case eLiveConnectItemObjectType.VoiceCall:
                return "voice-call-btn";
            case eLiveConnectItemObjectType.VoiceMail:
                return "voice-mail-btn";
            case eLiveConnectItemObjectType.TxtMsgIB:
                return "text-msg-btn";
            case eLiveConnectItemObjectType.Slidecast:
                return "slidecast-btn";
            case eLiveConnectItemObjectType.Agreement:
                return "agreement-btn";
            case eLiveConnectItemObjectType.DynamicScheduledApptDep: case eLiveConnectItemObjectType.Appt:
                return "dynamic-btn";
            case eLiveConnectItemObjectType.Contact:
                return "contact-btn";
            case eLiveConnectItemObjectType.AutomationAlert:
                return "automation-alert-btn";
        }
    }

    //async loadMailMergeTemplate(mailMergeTemplateId: number) {
    //    this.mailMergeTemplateId = mailMergeTemplateId;
    //    this.parentEmitter.emit('Hello from the parent component!');
    //}

    async loadMailMergeTemplate(mailMergeTemplateId: number) {
        this.mailMergeTemplateId = mailMergeTemplateId;
      
        await this._marketingService.loadMailMergeTemplate(this.encryptedUser, mailMergeTemplateId)
            .then(async (result: MailMergeTemplateLoad) => {
                if (result) {
                    this.mailMergeTemplateResponse = UtilityService.clone(result);
                    /*this.templateName = this.mailMergeTemplateResponse.templateName;*/
                    this.htmlDisplay = this.mailMergeTemplateResponse.hTMLText;

                    this.isShowMergeMailScreen = true;
                    this.isShowContactScreen = false;
                    this.isShowApptScreen = false;
                    this.isShowCallScreen = false;
                    this.isShowReplyScreen = false;
                    this.isShowNoteScreen = false;
                    this.isShowEmailScreen = false;
                    this.isShowMakeCallScreen = false;
                    this.isShowHandleTmScreen = false;
                    this.isShowEditContact = false;
                    this.isShowContactHistory = false;
                    this.isShowActivityHistory = false;
                    this.isShowUpperTabs = true;
 
                }
           
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);

            });
    }
}
