import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { isNullOrUndefined } from 'util';

import { ConfigDetails } from '../../../../models/appConfig.model';
import { CLPUser, CLPUserProfile, UserDD, UserResponse } from '../../../../models/clpuser.model';
import { ContactHistory, ContactHistoryListResponse, SCSessionLog } from '../../../../models/contactHistory.model';
import { Contact, ContactFields } from '../../../../models/contact.model';

import { AppconfigService } from '../../../../services/shared/appconfig.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { ContactSearchService } from '../../../../services/shared/contact-search.service';
import { NotificationService } from '../../../../services/notification.service';
import { eApptCategory, eFeatures, eUserPermissions, eUserRole } from '../../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { ContactService } from '../../../../services/contact.service';
import { EmailTemplateService } from '../../../../services/email-template.service';
import { EmailTemplateLoad } from '../../../../models/emailTemplate.model';
import { MarketingService } from '../../../../services/marketing.service';
import { MailMergeTemplateLoad } from '../../../../models/marketing.model';
import { Task } from '../../../../models/task.model';
import { Note, NoteFilterResponse } from '../../../../models/note.model';
import { ApppointmentResponse, ShareableApptUI, UpdateAppt } from '../../../../models/appt.model';
import { CoachCornerObjForNoteHistory, ContactInfo, SendMailInfo } from '../../../../models/report.model';
import { DatePipe } from '@angular/common';
import { OutBoundEmailService } from '../../../../services/outBoundEmail.service';
import { Document } from '../../../../models/document';
import { NotesService } from '../../../../services/notes.service';
import { NoteTypeCodeModel } from '../../../../models/noteTypeCode.model';
import { VoiceCallService } from '../../../../services/voice-call.service';
import { VoiceCallModel } from '../../../../models/voiceCall.model';
import { TextMsgTemplateService } from '../../../../services/text-msg-template.service';
import { AppointmentSettingService } from '../../../../services/appointmentSetting.service';
import { GlobalService } from '../../../../services/global.service';
import { SimpleResponse } from '../../../../models/AudioVedioRecorder/genericResponse.model';
import { UserService } from '../../../../services/user.service';
import { LeadSettingService } from '../../../../services/leadSetting.service';
import { Lead } from '../../../../models/lead.model';

declare var $: any;

@Component({
    selector: 'app-contact-activity-history',
    templateUrl: './contact-activity-history.component.html',
    styleUrls: ['./contact-activity-history.component.css']
})
/** contact-activity-history component*/
export class ContactActivityHistoryComponent implements OnInit {
    routeUserId: number;
    routeContactId: number;
    @Input() objFromCoachCorner: CoachCornerObjForNoteHistory;
    @Input() isFromCoachCorner: boolean = false;
    isShowModalVoiceCallLog: boolean = false;
    voiceCallId: number = 0;
    /** contact-history ctor */
    mySOUrl: any;
    soUrl: any;
    soImageSite: any;
    slidecastModalImageData: any = {};
    private encryptedUser: string = '';
    user: CLPUser;
    taskData: ContactHistory = <ContactHistory>{};
    isShowTaskModal: boolean = false;
    noteData: ContactHistory = <ContactHistory>{};
    @Output() selectTask = new EventEmitter<Task>();
    @Output() selectNote = new EventEmitter<Note>();
    @Input() contactHistory: ContactHistory[] = [];
    contactHistoryListResponse: ContactHistoryListResponse;
    @Input() contactId: number = 0;
    @Input() isCoachCorner: boolean = false;
    @Input() userddList: UserDD[];
    @Output() viewLoaded = new EventEmitter();
    showSpinner: boolean;
    contactFields: ContactFields;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    proposalID: number = 0;
    initContactHistory: ContactHistory[] = [];

    //for skypeCalls: type-5
    blnIncludeUser: boolean = true;
    isAllActivityAppend: boolean = true;

    /** Appt Modal */
    shareableAppt: ShareableApptUI;
    isShowApptModal: boolean = false;
    @ViewChild('closeInputButton') closeInputButton: ElementRef;
    selectedTab: string = 'AllActivity';
    noteTypeCodes: NoteTypeCodeModel[] = [];
    selectedNoteTypeCode: number = -1;
    sendTextInfo: any = { contactId: 0, isShow: false };
    selectedContactData: any;
    selectedCallType: string;
    isShowNewCallModal: boolean;
    textCallInfo: ContactInfo = { contactId: 0, isShow: false, contact: null };
    voiceCallID: number;
    textMsgInfo: SendMailInfo = { contactId: 0, isShow: false };
    textMsgStatus: any;
    txtMsgID: number;
    txtdtSend: Date;
    scPresentationSrc: any;
    recordingHtml: string;
    blnIsIH: boolean = false;
    leadData: Lead;
    dateFormat: string;


    constructor(private _appConfigService: AppconfigService,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _router: Router,
        private _route: ActivatedRoute,
        public _outboundEmailSrvc: OutBoundEmailService,
        public _contactSearchService: ContactSearchService,
        public _ngZone: NgZone,
        public _contactService: ContactService,
        private datepipe: DatePipe,
        private notifyService: NotificationService,
        public _emailTemplateService: EmailTemplateService,
        public _marketingService: MarketingService,
        public _notesService: NotesService,
        private textMessageService: TextMsgTemplateService,
        private voiceCallService: VoiceCallService,
        private _leadSettingService: LeadSettingService,
        private _globalService: GlobalService,
        private _appointmentSettingService: AppointmentSettingService,
        private _userService: UserService,
    ) {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this._appConfigService.getAppConfigValue(this.encryptedUser, "SO_Site")
                    .then(async (result: ConfigDetails) => {
                        this.soUrl = result?.configValue;
                    })
                this._appConfigService.getAppConfigValue(this.encryptedUser, "MySO_URL").
                    then(async (result: ConfigDetails) => {
                        this.mySOUrl = result?.configValue;
                    })
                this._appConfigService.getAppConfigValue(this.encryptedUser, "SO_Image_Site")
                    .then(async (result: ConfigDetails) => {
                        this.soImageSite = result?.configValue;
                    })
            }
        });
        this._contactSearchService.showSpinner = true;
        this.subscribeToEvents();
    }

    blnIncludeContact: boolean = false;
    blnIncludeCompany: boolean = false;

    ngOnInit() {
        this._contactSearchService.showSpinner = true;
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getNoteFilters();
                        if (this.isFromCoachCorner) {
                            this.getContactHistory(0);
                        }
                        else {
                            this.routeUserId = this._contactSearchService.routeUserId;
                            this.routeContactId = this._contactSearchService.routeContactId;

                            if ((!isNullOrUndefined(this._localService.contactFields)) && (this._localService.contactFields.contactID.fieldValue == this.contactId))
                                this.loadInitData();
                            else
                                this.getContactFields(this.contactId, this.user.cLPCompanyID, this.user.cLPUserID);
                        }
                    }
                    else {
                        this._router.navigate(['/unauthorized']);
                    }
                });
            }
            else {
                this._router.navigate(['/unauthorized']);
            }
        })
    }


    ngOnChanges() {
        $("#AllActivity").prop("checked", true);
        var activityDiv = document.getElementById('activityDiv');
        $("#allActivityHtml").append(activityDiv);
        this.initContactHistory = this.contactHistory;
        this.contactHistory?.sort((x, y) => +new Date(y.dtToSort) - +new Date(x.dtToSort));
    }

    ngAfterViewInit() {
        this.viewLoaded.next(true);
        this._contactSearchService.showSpinner = false;
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.dateFormat = this.userResponse?.user?.dateFormat;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-activity-history.authenticateR", err.message, null,
                    'Features: ' + eFeatures.None
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getContactFields(contactId, companyID, userId) {

        this._localService.getContactFields(this.encryptedUser, contactId, companyID, userId, true)
            .subscribe((value) =>
                this.loadInitData()
            );

    }

    private subscribeToEvents(): void {
        this._localService.reloadData.subscribe((value: boolean) => {
            this._ngZone.run(() => {
                this._contactSearchService.showSpinner = false;
            });
        });
    }

    loadInitData() {
        this.contactFields = this._localService.contactFields;
        this._contactSearchService.showSpinner = false;
    }


    public onTabSelect(e) {
        var activityType = -1;
        var activityType2 = -1;


        if (e) {
            var title = e.target.id;
            if (title) {
                var activityDiv = document.getElementById('activityDiv');
                switch (title) {
                    case "AllActivity": activityType = -1; activityType2 = -1; $("#allActivityHtml").append(activityDiv); this.selectedTab = title; break;
                    case "Notes": activityType = 1; activityType2 = -1; $("#notesHtml").append(activityDiv); this.selectedTab = title; break;
                    case "Mailings": activityType = 4; activityType2 = 6; $("#mailingsHtml").append(activityDiv); this.selectedTab = title; break;
                    case "Calls": activityType = 11; activityType2 = -1; $("#callsHtml").append(activityDiv); this.selectedTab = title; break;
                    case "Texts": activityType = 8; activityType2 = -1; $("#textsHtml").append(activityDiv); this.selectedTab = title; break;
                    case "Appointments": activityType = 2; activityType2 = -1; $("#appointmentsHtml").append(activityDiv); this.selectedTab = title; break;
                    case "Tasks": activityType = 3; activityType2 = -1; $("#tasksHtml").append(activityDiv); this.selectedTab = title; break;
                    case "InboundTexts": activityType = 9; activityType2 = -1; $("#inboundTextsHtml").append(activityDiv); this.selectedTab = title; break;
                    case "VoiceDrops": activityType = 10; activityType2 = -1; $("#voiceDropsHtml").append(activityDiv); this.selectedTab = title; break;
                    case "SkypeCalls": activityType = 5; activityType2 = -1; $("#skypeCallsHtml").append(activityDiv); this.selectedTab = title; break;
                    case "Slidecast": activityType = 12; activityType2 = -1; $("#slidecastHtml").append(activityDiv); this.selectedTab = title; break;
                }
                this.filterContactHistoryByType(activityType, activityType2);
            }
        }

    }

    ngAfterViewChecked() {
        if (this.isAllActivityAppend) {
            var activityDiv = document.getElementById('activityDiv');
            if (activityDiv != null) {
                if (this.isCoachCorner) {
                    $("#notesHtml").prop("checked", true);
                    $("#notesHtml").append(activityDiv);
                    setTimeout(() => { this.filterContactHistoryByType(1, -1) });
                }
                else {
                    /*  changes for Coach Corner Page start                                    -Tarun*/
                    $("#AllActivity").prop("checked", true);
                    /*  changes for Coach Corner Page   end                                  -Tarun*/
                    $("#allActivityHtml").append(activityDiv);
                }
                this.isAllActivityAppend = false;
                this._contactSearchService.showSpinner = false;
            }
        }
    }

    filterContactHistoryByType(type?, type2?) {
        this.contactHistory = [];
        if (type == -1 && type2 == -1) {
            this.contactHistory = this.initContactHistory;
            return this.contactHistory;
        }

        if (type2 != -1) {
            this.contactHistory = this.initContactHistory?.filter(function (v, i) {
                return ((v["type"] == type || v["type"] == type2));
            });
        }
        else {
            this.contactHistory = this.initContactHistory.filter(x => x.type == type);
        }
        return this.contactHistory;
    }

    changeContactActivitySort(event) {
        if (event && this.contactHistory.length > 0) {
            var selectedValue = event.target.value;
            var result = [];
            switch (selectedValue) {
                case "newest":
                    result = this.contactHistory.sort((x, y) => +new Date(y.dtToSort) - +new Date(x.dtToSort));
                    this.contactHistory = result;
                    this.notifyService.showSuccess("Activity filtered on " + selectedValue + "!", "", 3000);
                    break;
                case "oldest":
                    result = this.contactHistory.sort((x, y) => +new Date(x.dtToSort) - +new Date(y.dtToSort));
                    this.contactHistory = result;
                    this.notifyService.showSuccess("Activity filtered on " + selectedValue + "!", "", 3000);
                    break;
                default:
                    this.contactHistory = this.contactHistory;
                    break;
            }
        }
    }

    goToApptContact(type, id?) {
        if (type) {
            switch (type) {
                case "contact": {
                    this._router.navigate(['/contact', this.user.cLPUserID, this.contactId]);
                    break;
                }
                case "company": {
                    this._router.navigateByUrl(`company-create?cid=${this.user.cLPCompanyID}`);
                    break;
                }
                case "lead": {
                    this._router.navigateByUrl(`lead-detail/${id}`);
                    break;
                }
            }
        }
    }


    async voiceCallRecording(vCall_VoiceCallID) {
        if (vCall_VoiceCallID && vCall_VoiceCallID != '' && vCall_VoiceCallID != null) {
            await this.playAudio("vc", vCall_VoiceCallID)
            this.contactHistory.filter((item) => {
                if (item?.vCall_VoiceCallID == vCall_VoiceCallID) {
                    item.callRecording = this.recordingHtml
                }
            })
        }
    }

    VoiceCallLogWindowLink(vCall_VoiceCallID) {
        if (vCall_VoiceCallID && vCall_VoiceCallID != '' && vCall_VoiceCallID != null) {
            this.voiceCallId = vCall_VoiceCallID;
            this.isShowModalVoiceCallLog = true;
            $('#voiceCallLogModal').modal('show');
        }
    }

    mailingContactLink(type, mailingId?) {
        if (type) {
            switch (type) {
                case "contact": {
                    this._router.navigate(['/contact', this.user.cLPUserID, this.contactId]);
                    break;
                }
                case "company": {
                    this._router.navigateByUrl(`company-create?cid=${this.user.cLPCompanyID}`);
                    break;
                }
                case "emt": {
                    this._router.navigate([`/template-preview/${mailingId}/emt`])
                    break;
                }
                case "mmt": {
                    this._router.navigate([`/template-preview/${mailingId}`])
                    break;
                }
                case "image": {
                    this._router.navigateByUrl(`marketing/mailing?mid=${mailingId}`);
                    break;
                }
                case "day": {
                    this._router.navigateByUrl(`marketing/mailing?mid=${mailingId}`);
                    break;
                }
                case "status": {
                    this._router.navigateByUrl(`marketing/mailing?mid=${mailingId}`);
                    break;
                }
            }
        }
    }

    async notesLink(type, noteLinkId?, ownerType?) {
        if (type) {
            switch (type) {
                case "dayTime": {
                    var url = this.soUrl;
                    var mContactUrl = '';
                    if (ownerType == 2) {
                        this._router.navigateByUrl(`activity-history/${this.user.cLPCompanyID}?ch=true`);
                        break;
                    }
                    else if (ownerType == 3) {
                        await this.getLeadLoad(noteLinkId)
                        mContactUrl = `lead-contact-history/${this.leadData?.leadID}/${this.leadData?.contactID}`;
                        //mContactUrl = this.mySOUrl + this.encryptedUser + "&ReturnUrl=" + encodeURIComponent(url + "/lead/history.aspx?ntid=" + noteLinkId);
                        window.open(mContactUrl, '_blank');
                    }
                    else if (ownerType == 6) {
                        mContactUrl = `link-history/${noteLinkId}`;
                    }
                }
                case "contact": {
                    this._router.navigate(['/contact', this.user.cLPUserID, this.contactId]);
                    break;
                }
                case "emailTemplate": {
                    this.loadEmailTemplate(noteLinkId);
                    break;
                }
                case "mailMergeTemplate": {
                    this.loadMailMergeTemplate(noteLinkId);
                    break;
                }
            }
        }
    }

    getLeadLoad(ownerId) {
        this._leadSettingService.getLeadLoad(this.encryptedUser, ownerId)
            .then((result: Lead) => {
                if (!isNullOrUndefined(result)) {
                    this.leadData = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("manage-campaign.getLeadLoad", err.message, null, 'ownerId ' + ownerId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    loadEmailTemplate(noteLinkId) {
        this._emailTemplateService.getEmailTemplateById(this.encryptedUser, noteLinkId)
            .then((result: EmailTemplateLoad) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    localStorage.setItem('object', JSON.stringify(response.htmlText));
                    const url = this._router.serializeUrl(
                        this._router.createUrlTree([`/template-preview/${noteLinkId}/emt`])
                    );
                    window.open(url)
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-activity-history.loadEmailTemplate", err.message, null,
                    'noteLinkId: ' + noteLinkId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    loadMailMergeTemplate(mailMergeTemplateId) {
        this._marketingService.loadMailMergeTemplate(this.encryptedUser, mailMergeTemplateId)
            .then((result: MailMergeTemplateLoad) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    localStorage.setItem('object', JSON.stringify(response.hTMLText));
                    const url = this._router.serializeUrl(
                        this._router.createUrlTree([`/template-preview/${mailMergeTemplateId}`])
                    );
                    window.open(url)
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-activity-history.loadMailMergeTemplate", err.message, null,
                    'mailMergeTemplateId: ' + mailMergeTemplateId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    taskLink(type, noteLinkId?, clpUserId?) {
        if (type) {
            switch (type) {
                case "contact": {
                    var url = this.soUrl;
                    var mContactUrl = `contact/${clpUserId}/${noteLinkId}`
                    window.open(mContactUrl, '_blank');
                    break;
                }
                case "company": {
                    var url = this.soUrl;
                    var mContactUrl = `company-create/${noteLinkId}`;
                    window.open(mContactUrl, '_blank');
                    break;
                }
                case "leadCompany": {
                    var url = this.soUrl;
                    var mContactUrl = `contact/${clpUserId}/${noteLinkId}`
                    window.open(mContactUrl, '_blank');
                    break;
                }
                case "lead": {
                    var url = this.soUrl;
                    var mContactUrl = `lead-detail/${noteLinkId}`;
                    window.open(mContactUrl, '_blank');
                    break;
                }
                case "leadcompany2": {
                    var url = this.soUrl;
                    var mContactUrl = `company-create/${noteLinkId}`;
                    window.open(mContactUrl, '_blank');
                    break;
                }
            }
        }
    }

    skypeCallLink(type, skypeId?) {
        if (type) {
            switch (type) {
                case "contact": {
                    var url = this.soUrl;
                    //window.open(mContactUrl, '');
                    break;
                }
                case "company": {
                    var url = this.soUrl;
                    //window.open(mContactUrl, '');
                    break;
                }
            }
        }
    }

    slidecastSCLink(history: ContactHistory) {
        this.scPresentationSrc = history.scPresentationLink;
        this.showSlidecastImageModal();
    }

    slidecastImageLink(log: SCSessionLog) {
        this.scPresentationSrc = log.scSlideLink;
        this.showSlidecastImageModal();
    }

    showSlidecastImageModal() {
        $('#slidecastModal').modal('show');
    }

    hideSlidecastImageModal() {
        $('#slidecastModal').modal('hide');
    }

    trackByFn(index, item) {
        return (item.type);
    }

    public goToTask(id) {
        let task: Task = <Task>{};
        this.taskData = this.contactHistory.filter(x => x.type == 3 && x.t_TaskID == id)[0];
        this.isShowTaskModal = true;
        this.selectTask.emit(task);
        $('#viewTaskModal').modal('show');
    }

    async appointmentPopUp(apptData: ContactHistory) {
        await this._appointmentSettingService.apptLoad(this.encryptedUser, apptData?.app_ApptID, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: ApppointmentResponse) => {
                if (!isNullOrUndefined(result)) {
                    let appt = UtilityService.clone(result);
                    if (!isNullOrUndefined(appt)) {
                        if (appt.appt.cLPCompanyID == 1226) {
                            if (appt.appt.cLPUserID != 3893) {
                                this.blnIsIH = true;
                                if (appt.appt.proposalID > 0) {
                                    this.blnIsIH = false
                                }
                            }
                        }
                    }
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-activity-history.apptLoad", err.message, null,
                    'apptID: ' + apptData?.app_ApptID
                    + 'clpuserId: ' + this.user?.cLPUserID
                    + 'clpCompanyID: ' + this.user?.cLPCompanyID
                );
                this.contactHistory = this.initContactHistory;
                this._utilityService.handleErrorResponse(err);
            });

        this.shareableAppt = {
            apptId: apptData?.app_ApptID,
            rApptID: apptData?.app_RApptID,
            leadContactId: 0,
            cat: Number(apptData?.app_Category),
            ownerId: Number(apptData?.app_Category) == eApptCategory.Contact ? apptData?.app_ContactID : Number(apptData?.app_Category) == eApptCategory.Lead ? apptData?.app_LeadID : 0,
            currentUrlAppt: 'appointmentlist',
            isNewTab: true,
        };
        this.isShowApptModal = true;
    }

    hideQuickApptModal(updateAppt?: UpdateAppt) {
        if (!isNullOrUndefined(updateAppt))
            this.closeModalApptModal();
        this.isShowApptModal = false;
        this.shareableAppt = null;
        $('#quickApptModalContactActivity').modal('hide');
    }

    closeModalApptModal() {
        let inputElement: HTMLElement = this.closeInputButton.nativeElement as HTMLElement;
        inputElement.click();
    }

    async getContactHistory(contactId: number) {
        const startDate: string = this.datepipe.transform(this.objFromCoachCorner.startDate, 'MMddyyyy') ?? "";
        const endDate: string = this.datepipe.transform(this.objFromCoachCorner.endDate, 'MMddyyyy') ?? "";
        this._contactService.contactHistory_Get(this.encryptedUser, contactId, this.objFromCoachCorner.selUser, startDate, endDate)
            .then(async (result: ContactHistoryListResponse) => {
                if (result) {
                    this.contactHistoryListResponse = UtilityService.clone(result);
                    this.contactHistory = this.contactHistoryListResponse.contactHistory;
                    this.initContactHistory = this.contactHistoryListResponse.contactHistory;
                }
                else {
                    this._contactSearchService.showSpinner = false;
                    this.contactHistory = this.initContactHistory;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-activity-history.getContactHistory", err.message, null,
                    'contactId: ' + contactId
                    + 'selUser: ' + this.objFromCoachCorner.selUser
                    + 'startDate: ' + startDate
                    + 'endDate: ' + endDate
                );
                this.contactHistory = this.initContactHistory;
                this._utilityService.handleErrorResponse(err);
            });
    }

    hideQuickTaskModal(event) {
        //this.emitTask.emit(true);
        this.isShowTaskModal = false;
        $('#viewTaskModal').modal('hide');
    }

    goToNoteHistory(noteId: number, noteOwnerType: number, ownerId: number, contactName: string) {
        localStorage.setItem("ownerName", contactName);
        localStorage.setItem("ownerType", noteOwnerType.toString());
        localStorage.setItem("category", "2");
        this._router.navigate(['/activity-history', ownerId], { queryParams: { ntId: noteId } });
    }

    async downloadDocuments(selectedDocumentId) {
        await this._outboundEmailSrvc.downloadDocumentsByDocId(this.encryptedUser, selectedDocumentId)
            .then(async (result: Document) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    const byteCharacters = atob(response?.bytes);
                    const byteNumbers = new Array(byteCharacters?.length);
                    for (let i = 0; i < byteCharacters?.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const fileType = response?.documentName.split('.');
                    const file = new Blob([byteArray], { type: fileType[1] });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(file);
                    link.download = response.documentName;
                    link.click();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-activity-history.downloadDocuments", err.message, null,
                    'selectedDocumentId: ' + selectedDocumentId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }


    getNoteFilters() {
        this._notesService.getNoteFilters(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: NoteFilterResponse) => {
                if (result)
                    this.noteTypeCodes = UtilityService.clone(result?.noteTypeCodes);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-activity-history.getNoteFilters", err.message, null,
                    'cLPUserID: ' + this.user?.cLPUserID
                    + 'cLPCompanyID: ' + this.user?.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    filterNoteByTypeCode(value: number) {
        $('#Notes').prop('checked', true).trigger('change');

        this.selectedTab = 'Notes';
        var activityDiv = document.getElementById('activityDiv');
        $("#notesHtml").append(activityDiv);
        this.filterContactHistoryByType(1, -1);

        if (value == -1) {
            this.contactHistory = this.initContactHistory.filter(x => x.type == 1);
            this.toSortHistoryList(this.contactHistory)
        }
        else {
            this.contactHistory = this.initContactHistory.filter(x => x.type == 1 && x.note_NoteTypeCode == value);
            this.toSortHistoryList(this.contactHistory)
        }
    }

    toSortHistoryList(historyList) {
        let history = historyList;
        if (history?.length > 0) {
            history?.sort((x, y) => +new Date(y.dtToSort) - +new Date(x.dtToSort));
            return history;
        }
    }

    sendText(ev) {
        $('#sendTextModal').modal('show');
        this.sendTextInfo.isShow = true;
        this.sendTextInfo.contactId = ev?.contactID;
    }

    hideTextMsgModal(sms?: boolean) {
        this.textMsgInfo.isShow = false;
        this.textMsgInfo.contactId = 0;
        $('#viewTextModal').modal('hide');
    }

    async showCallModal(contactData) {
        this.textCallInfo.isShow = true;
        this.textCallInfo.contact = contactData;
        $('#viewCallModalReffered').modal('show');
    }

    hideVoiceCallLogModal(isCloseButtonClicked: boolean) {
        if (isCloseButtonClicked) {
            $('#voiceCallLogModal').modal('hide');
            this.isShowModalVoiceCallLog = false;
        }
    }

    getVoiceCallLoad(vCall_VoiceCallID: number) {
        this.showSpinner = true
        this.voiceCallID = vCall_VoiceCallID
        if (vCall_VoiceCallID > 0) {
            this.voiceCallService.voiceCallLoad(this.encryptedUser, vCall_VoiceCallID)
                .then(async (result: VoiceCallModel) => {
                    if (result && result.contactID) {
                        this.textCallInfo.contactId = result?.contactID
                        this.getContactData()
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("contact-activity-history.getVoiceCallLoad", err.message, null,
                        'vCall_VoiceCallID: ' + vCall_VoiceCallID
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async getContactData() {
        await this._contactService.contactLoad(this.encryptedUser, this.textCallInfo.contactId)
            .then(async (result: Contact) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    if (response.contactID > 0) {
                        this.textCallInfo.isShow = true;
                        this.textCallInfo.contact = response;
                        $('#viewCallModal').modal('show');
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-activity-history.getContactData", err.message, null,
                    'contactId: ' + this.textCallInfo.contactId
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    hideTextCallModal(sms?: boolean) {
        this.textCallInfo.isShow = false;
        this.textCallInfo.contactId = 0;
        this.textCallInfo.contact = null;
        $('#viewCallModal').modal('hide');
    }

    getTextActivity(txtmsgId: number, txtMsgStatus, txtdtSend) {
        this.showSpinner = true
        if (txtmsgId > 0) {
            this.textMessageService.TxtxMsgLoad(this.encryptedUser, txtmsgId)
                .then(async (result: any) => {
                    if (result && result.contactID) {
                        this.textMsgStatus = txtMsgStatus
                        this.txtMsgID = txtmsgId
                        this.textMsgInfo.contactId = result?.contactID
                        this.textMsgInfo.isShow = true;
                        this.txtdtSend = txtdtSend;
                        //this.showCommonComponent()
                        $('#viewTextModal').modal('show');
                        this.showSpinner = false

                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("contact-activity-history.getTextActivity", err.message, null,
                        'txtmsgId: ' + txtmsgId
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async playAudio(tpe: string, id: number) {
        if (id > 0) {
            await this.voiceCallService.playAudio(this.encryptedUser, tpe, id)
                .then(async (result) => {
                    if (result) {
                        this.recordingHtml = result?.messageString
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("contact-activity-history.getVoiceCallLoad", err.message, null,
                        'id: ' + id
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    getUserName(userId: string): string {
        var userName: string = "";
        if (!isNullOrUndefined(userId)) {
            userName = this.userddList.filter(x => x.value == Number(userId))[0]?.text;
            var fullName = userName.split(",");
            userName = ""
            userName = fullName[1] + " " + fullName[0]
        }
        return userName;
    }


    displayCallDuration(seconds: number): string {
        const minutes = Math.floor(seconds / 60); 
        const remainingSeconds = seconds % 60; 

        let formattedTime = '';

        if (minutes > 0) {
            formattedTime += `${minutes} Min `;
        }

        formattedTime += `${remainingSeconds} secs`;

        return formattedTime.trim();
    }

}
