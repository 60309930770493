<div class="custom-security-link" *ngIf="isShowHistory">
    <a [routerLink]="['/lead-create']" [queryParams]="{cid: selectedContactId}">New Lead</a>
    <a (click)="appointmentPopUp(null,true);" data-toggle="modal" data-target="#quickApptModalContact" data-backdrop="static" data-keyboard="false">New Appt</a>
    <a href="javascript:void(0)" (click)="createNewTask()">New Task</a>
    <a [routerLink]="['/contact-create']" [queryParams]="{rid: selectedContactId}">New Referral</a>
    <a [routerLink]="" (click)="isClickSaveAs = true; editContact(activeDashboardId)">Save as</a>
    <!--data-toggle="modal" data-target="#underConstruction"-->
</div>
<div class="custom-security-link" *ngIf="!isShowHistory">
    <a (click)="viewContact()">View Contact</a>
    <a [routerLink]="['/appointment']">New Appt</a>
    <a href="javascript:void(0)" (click)="createNewTask()">New Task</a>
    <a>Import History</a>
</div>
<div class="contact-container">
    <div class="main-module">
        <kendo-splitter orientation="horizontal">
            <kendo-splitter-pane min="27%" max="29%" [(size)]="leftPanelSize" (sizeChange)="panelSizeChange('left', $event)" *ngIf="isShowHistory">
                <div class="left-section">
                    <fieldset>
                        <legend>
                            Contact Module
                            <a *ngIf="user?.userRole > 3" [hidden]="(roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false) && user?.userRole <= eUserRole.Administrator" href="javascript:void(0)" class="create-contact-icon" (click)="_localService.showCommonComp ='contact-new'; showCommonComponent(); _localService.scrollTop(activeDashboardId);this.contactId=0;showContactDetail=false" title="Create new contact"><i class="fas fa-user-plus"></i></a>
                        </legend>
                        <div class="contact-module-panel">
                            <div class="profile-module">
                                <span class="profile-main"><i class="fa fa-user"></i><input type="file" /></span>
                                <div class="profile-edit" *ngIf="selectedContact">
                                    <div class="top-icon">
                                        <a class="text-primary" title="Refresh" (click)="contactUpdate(true);"><img src="../../../../assets/refreshbtn.svg"> <span></span></a>
                                        <a class="text-primary" title="Pin" (click)="pinUnpinContact();"><img src="../../../../assets/pinbtn.svg"> <span>{{isPinContact ? 'Pinned' : 'Pin'}}</span></a>
                                        <a mattooltip="Download" (click)="downloadVCFFile()" class="text-primary">
                                            <img src="../../../../assets/downbtn.svg">
                                            <span class="btn-text">Download</span>
                                        </a>
                                        <a title="Edit" (click)="isClickSaveAs = false;editContact(activeDashboardId);" [hidden]="roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator">
                                            <img src="../../../../assets/editbtn.svg" />
                                            <span>Edit</span>
                                        </a>
                                    </div>
                                    <div class="user-details">
                                        <p class="mb-2">{{selectedContact?.firstName}} {{selectedContact?.lastName}} </p>

                                        <div class="d-flex">
                                            <span class="green" [title]="emailTooltip" (click)="isShowModalEmailOptLog=true" data-toggle="modal" data-target="#emailOptLogModal" data-backdrop="static" data-keyboard="false" style="cursor:pointer">Email:</span>
                                            <a style="color:#30a5ff" class="ml-2" (click)="_localService.showCommonComp ='email'; _localService.scrollTop(activeDashboardId); showCommonComponent(); _localService.showPristneForm();" title="send email">{{selectedContact?.email}}</a>
                                            <a class="btn copy-btn grid-common-btn" (click)="copyEmail(selectedContact?.email);  Copied.hidden=false;" *ngIf="Copied.hidden==true && selectedContact?.email != ''" #Copy>
                                                <img src="../../../assets/copy-paste.svg" title="Copy" />
                                            </a>
                                            <a class="copiedText" id="copyBtn" (click)="copyEmail(selectedContact?.email);" #Copied hidden title="Copied"><img src="../../../assets/copy-paste.svg" /><!--Copied--></a>
                                        </div>
                                        <span>
                                            <span [title]="mobileTooltip" style="cursor:pointer" (click)="isShowModalMobileOptLog = true" data-toggle="modal" data-target="#mobileOptLogModal" data-backdrop="static" data-keyboard="false">Mobile: </span>
                                            <a class="ml-2" style="color: #30a5ff" title="Make Call" data-toggle="modal" data-target="#callModal" (click)="openModalNewCall(selectedContact,'mp')">
                                                <span>{{pixString(selectedContact?.mobile) | phoneFormat}}</span>
                                            </a>
                                        </span>
                                        <span>
                                            <span>Company: </span>
                                            <a class="ml-2" style="color: #30a5ff" (click)="goToLink('company')">
                                                <span>{{selectedContact?.companyName}}</span>
                                            </a>
                                        </span>
                                    </div>
                                    <div class="user-icon">
                                        <div class="btn-group">
                                            <a *ngIf="!user?.enableSkype" data-toggle="modal" data-target="#callModal" (click)="openModalNewCall(selectedContact,'mp')" title="Make Call">
                                                <img src="../../../../assets/callbtn.svg" />
                                                <span>Call</span>
                                            </a>
                                            <a title="Send Text Message" [hidden]="roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator" (click)="_localService.showCommonComp ='sms'; _localService.scrollTop(activeDashboardId); showCommonComponent(); _localService.showPristneForm();">
                                                <img src="../../../../assets/txtmsgstitle_grey.svg" />
                                                <span>Text</span>
                                            </a>
                                            <a title="send email" (click)="_localService.showCommonComp ='email'; _localService.scrollTop(activeDashboardId); showCommonComponent(); _localService.showPristneForm();" [hidden]="roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator">
                                                <img src="../../../assets/email_new.svg" />
                                                <span>Email</span>
                                            </a>
                                        </div>
                                        <hr />
                                        <div class="delete-icon">
                                            <a (click)="onBuzzScore()" title="Buzz Index">
                                                <img src="../../../../assets/lead-score.svg" />
                                                <span>Lead Score</span>
                                            </a>
                                            <a data-toggle="modal" title="Delete" data-target="#contactDeleteModal" [hidden]="roleFeaturePermissions?.delete == false && user?.userRole <= eUserRole.Administrator">
                                                <img src="../../../../assets/delete.svg" />
                                                <span>Delete</span>
                                            </a>
                                            <!--<a class="text-primary" [title]="mobileTooltip" (click)="isShowModalMobileOptLog = true" data-toggle="modal" data-target="#mobileOptLogModal" data-backdrop="static" data-keyboard="false">
                                              <img src="../../../../assets/cpe_icontxtmsg.svg">
                                            </a>
                                            <a class="text-primary" [title]="emailTooltip" (click)="isShowModalEmailOptLog = true" data-toggle="modal" data-target="#emailOptLogModal" data-backdrop="static" data-keyboard="false">
                                                <img src="../../../../assets/emailtitle_gray.svg">
                                            </a>
                                            <a class="text-primary" [title]="emailTooltip" (click)="isShowModalEmailOptLog = true" data-toggle="modal" data-target="#emailOptLogModal" data-backdrop="static" data-keyboard="false">
                                                <img src="../../../../assets/emailtitle_gray.svg">
                                            </a>
                                            <!--<a class="text-primary" title="Pin" *ngIf="!isPinContact" (click)="pinContact();"><img src="../../../../assets/pinbtn.svg"></a>
                                            <a href="javascript:void(0)" title="Edit" (click)=" isClickSaveAs = false; editContact(activeDashboardId); " [hidden]="roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator">
                                              <img src="../../../../assets/editbtn.svg" />
                                            </a>
                                            <a *ngIf="user?.enableSkype" [href]="sanitize(skypenumber)" title="Skype Call"><img src="../../../../assets/callbtn.svg" /></a>
                                            <a [href]="selectedContact?.mapURL" [target]="_blank" title="Get Map"><img src="../../../../assets/mapbtn.svg" /></a>
                                            <a [href]="selectedContact?.directionsURL" [target]="_blank" title="Get Direction"><img src="../../../../assets/drivebtn.svg" /></a>
                                            <a title="Download to Outlook" (click)="getContactVCard();" [hidden]="roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator">
                                              <img src="../../../../assets/btnvcard_sm.svg" />
                                            </a>
                                            <a title="Send Text Message" [hidden]="roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator" (click)="_localService.showCommonComp ='sms'; _localService.scrollTop(activeDashboardId); showCommonComponent(); _localService.showPristneForm();">
                                              <img src="../../../../assets/txtmsgstitle_grey.svg" />
                                            </a>
                                            -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="search-new-section">
                                <div class="search-new-panel">
                                    <ul class="nav nav-tabs" role="tablist">
                                        <li class="nav-item" [hidden]="isSearch">
                                            <a class="nav-link" [ngClass]="!isSearch ? 'active' : ''" data-toggle="tab" href="#searchDetails" role="tab">Details</a>
                                        </li>
                                        <li class="nav-item" [hidden]="!isSearch">
                                            <a class="nav-link" [ngClass]="isSearch ? 'active' : ''" data-toggle="tab" href="#searchResults" role="tab">Search Results</a>
                                        </li>
                                    </ul><!-- Tab panes -->
                                    <div class="tab-content">
                                        <div class="tab-pane active" id="searchDetails" role="tabpanel" [hidden]="isSearch">
                                            <div class="expand-btn">
                                                <a id="colapse" (click)="expandCollapse()" role="button">
                                                    <span>{{isCollapse ? 'Expand All' : 'Collapse All'}}</span> <i *ngIf="isCollapse" class="fa fa-plus-square"></i><i *ngIf="!isCollapse" class="fa fa-minus-square"></i>
                                                </a>
                                            </div>
                                            <div id="accordion">
                                                <div class="accodian-details">
                                                    <div class="accodian-top" *ngFor="let section of arrSortedBySection; let i = index;">
                                                        <div class="card-header" id="heading-{{i}}" *ngIf="section.sectionName != 'Connections' && section.sectionName != 'Classification' && section.sectionName != 'Tags' && section.sectionName != 'Additional Information'" (click)="toggleAccordians(i)">
                                                            <a class="collapsed" role="button">
                                                                <span>{{ section.sectionName }}</span>
                                                                <i id="icon-{{i}}" *ngIf="section.isActive" class="fa fa-chevron-down"></i>
                                                                <i id="icon-{{i}}" *ngIf="!section.isActive" class="fa fa-chevron-up"></i>
                                                            </a>
                                                        </div>
                                                        <div class="card-header" id="heading-{{i}}" *ngIf="section.sectionName == 'Additional Information'">
                                                            <div style="display:flex; justify-content:space-between">
                                                                <a class="collapsed" role="button" (click)="toggleAccordians(i)">
                                                                    <span>{{ section.sectionName }}</span>
                                                                </a>
                                                                <div>
                                                                    <button *ngIf="soMetricCount > 0" class="btn btn-primary" style="min-width: auto; min-height: auto; margin: 2px 5px 2px 2px; padding: 2px 5px" role="button" (click)="openSoMetric()">
                                                                        <img title="Metric" style="margin-left: 0px; width: 18px;" src="../../../../assets/metric.svg" /> {{soMetricCount}}
                                                                    </button>
                                                                    <i (click)="toggleAccordians(i)" style="color:#4A5568; font-size:12px" id="icon-{{i}}" *ngIf="section.isActive" class="fa fa-chevron-down"></i>
                                                                    <i (click)="toggleAccordians(i)" style="color:#4A5568; font-size:12px" id="icon-{{i}}" *ngIf="!section.isActive" class="fa fa-chevron-up"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-header" id="heading-{{i}}" *ngIf="section.sectionName == 'Connections'">
                                                            <div style="display:flex; justify-content:space-between">
                                                                <a class="collapsed" role="button" (click)="toggleAccordians(i)">
                                                                    <span>{{ section.sectionName }}</span>
                                                                </a>
                                                                <div>
                                                                    <button class="btn btn-primary" style="min-width: auto; min-height: auto; margin: 2px 5px 2px 2px; padding: 2px 5px" type="button" matTooltip="Add" (click)="isHideConnectionForm = false;">
                                                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                                                        <span class="btn-text">New</span>
                                                                    </button>
                                                                    <i (click)="toggleAccordians(i)" style="color:#4A5568; font-size:12px" id="icon-{{i}}" *ngIf="section.isActive" class="fa fa-chevron-down"></i>
                                                                    <i (click)="toggleAccordians(i)" style="color:#4A5568; font-size:12px" id="icon-{{i}}" *ngIf="!section.isActive" class="fa fa-chevron-up"></i>
                                                                </div>
                                                            </div>
                                                            <div class="connnecting-panel" *ngIf="!isHideConnectionForm">
                                                                <label>Contact</label>
                                                                <kendo-autocomplete #autocomplete [data]="contactSearchList" valueField="searchText" (valueChange)="getContactId($event,'connection')" placeholder="Search"
                                                                                    (keyup)="getSearchData($event.target.value,'connection')"></kendo-autocomplete>
                                                                <label>Relation</label>  <input type="text" class="form-control" placeholder="Relation" [(ngModel)]="connectionRelation" [ngModelOptions]="{standalone: true}" #relation="ngModel" required />
                                                                <button class="btn btn-primary" type="button" [disabled]="contactIdForConnection == 0 || (relation.invalid == true)" (click)="createConnection()">Save</button>
                                                                <button class="btn btn-cancel" (click)="isHideConnectionForm = !isHideConnectionForm" type="button">Hide</button>
                                                            </div>
                                                        </div>
                                                        <div class="card-header" id="heading-{{i}}" *ngIf="section.sectionName == 'Classification'">
                                                            <div style="display:flex; justify-content:space-between">
                                                                <a class="collapsed" role="button" (click)="toggleAccordians(i)">
                                                                    <span>{{ section.sectionName }}</span>
                                                                </a>
                                                                <div class="display-row">
                                                                    <div class="m-1 mr-2">
                                                                        <button class="btn-primary" style="min-width:auto;" title="Buzz Index" (click)="onBuzzScore();"><img title="Buzz Index" src="../../../../../assets/buzzbtn.svg">{{buzzScore}}</button>
                                                                    </div>
                                                                    <i (click)="toggleAccordians(i)" id="icon-{{i}}" style="color:#4A5568; font-size:12px" *ngIf="section.isActive" class="fa fa-chevron-down"></i>
                                                                    <i (click)="toggleAccordians(i)" id="icon-{{i}}" style="color:#4A5568; font-size:12px" *ngIf="!section.isActive" class="fa fa-chevron-up"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-header" id="heading-{{i}}" *ngIf="section.sectionName == 'Tags'">
                                                            <div style="display:flex; justify-content:space-between">
                                                                <a class="collapsed" role="button" (click)="toggleAccordians(i)">
                                                                    <span>{{ section.sectionName }}</span>
                                                                </a>
                                                                <div>
                                                                    <button class="btn-primary" *ngIf="!isShowTagList" (click)="getTagListByCompany();isShowTagList=true;" style="min-width:auto; margin-right:10px;">
                                                                        <img style="margin-left: 0px; width: 15px; filter: brightness(0) invert(1)" src="../../../../assets/tagbtn.svg" />
                                                                    </button>
                                                                    <i (click)="toggleAccordians(i)" id="icon-{{i}}" style="color:#4A5568; font-size:12px" *ngIf="section.isActive" class="fa fa-chevron-down"></i>
                                                                    <i (click)="toggleAccordians(i)" id="icon-{{i}}" style="color:#4A5568; font-size:12px" *ngIf="!section.isActive" class="fa fa-chevron-up"></i>
                                                                </div>
                                                            </div>
                                                            <div class="form-group" *ngIf="isShowTagList">
                                                                <kendo-combobox [data]="tagListByCompany"
                                                                                textField="tagDisplay"
                                                                                [(ngModel)]="selectedTagId"
                                                                                [ngModelOptions]="{standalone: true}"
                                                                                valueField="tagID"
                                                                                [allowCustom]="true"
                                                                                [valuePrimitive]="true"
                                                                                [suggest]="true"
                                                                                placeholder="Type a Tag"
                                                                                (valueChange)="selectedTagChangeEvent($event)">
                                                                    <ng-template kendoComboBoxItemTemplate let-dataItem>
                                                                        <strong [ngStyle]="{'background-color': (dataItem?.checked) ? '#2DC76D' : ''}">{{dataItem.tagDisplay}}</strong>
                                                                    </ng-template>
                                                                </kendo-combobox>
                                                            </div>
                                                        </div>

                                                        <div id="collapse-{{i}}" class="collapse" *ngIf="section.sectionName != 'Connections' && section.sectionName != 'Tags'" [ngClass]="{'show': section.isActive }">
                                                            <div class="accodian-text-panel" *ngFor="let item of section.items">
                                                                <div class="accodian-text-details" *ngIf="item.isShow != 2">
                                                                    <span [hidden]="section?.sectionName == 'System' && (item?.fieldName == 'outlookSync' || item?.fieldName == 'email' || item?.fieldName == 'eBlastAddress' || item?.fieldName == 'lastName' || item?.fieldName == 'firstName' || item?.fieldName == 'mobile')">{{item.fieldTitle}}:</span>
                                                                    <div *ngIf="numberFormatArray.includes(item.fieldName);then phFormat else other_content"></div>
                                                                    <ng-template #phFormat>
                                                                        <span [hidden]="item?.fieldName == 'outlookSync' || item?.fieldName == 'email' || item?.fieldName == 'eBlastAddress' || item?.fieldName == 'lastName' || item?.fieldName == 'firstName' || item?.fieldName == 'mobile'">{{item.fieldValue | phoneFormat}}</span>
                                                                    </ng-template>
                                                                    <ng-template #other_content>
                                                                        <span *ngIf="section?.sectionName != 'Email' && section?.sectionName != 'System'">{{item.fieldValue}}</span>
                                                                        <div *ngIf="section?.sectionName == 'System'">
                                                                            <span [hidden]="item?.fieldName == 'outlookSync' || item?.fieldName == 'email' || item?.fieldName == 'eBlastAddress' || item?.fieldName == 'lastName' || item?.fieldName == 'firstName' || item?.fieldName == 'mobile'">
                                                                                <span *ngIf="item?.fieldName == 'shareable'" [ngStyle]="{'color': item.fieldValue ? 'green' : 'red'}">{{item.fieldValue ? 'Contact is shared.' : 'Contact is not shared.'}}</span>
                                                                                <a *ngIf="item?.fieldTitle == 'User'" [routerLink]="['/edit-profile', user?.cLPUserID]">{{getUserName(item)}}</a>
                                                                                <span *ngIf="item?.fieldName == 'dtModified'">{{item.fieldValue | date : 'MM/dd/yyyy'}}</span>
                                                                                <span *ngIf="item?.fieldName == 'dtCreated'">{{item.fieldValue | date : 'MM/dd/yyyy'}}</span>
                                                                            </span>
                                                                        </div>
                                                                        <a *ngIf="section?.sectionName == 'Email'" href="mailto:{{item.fieldValue}}">{{item.fieldValue}}</a>
                                                                    </ng-template>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="section.sectionName == 'Address'" class="user-icon">
                                                                <a class="btn-group" href="{{mapURL}}" target="_blank" style="min-width:auto;" title="Map URL"> <img src="../../../../../assets/mapbtn.svg"></a>
                                                                <a class="btn-group" href="{{DirectionsLink}}" target="_blank" style="min-width:auto;" title="Direction Link"> <img src="../../../../../assets/drivebtn.svg"></a>
                                                            </div>
                                                            <div *ngIf="section.items?.length == 0" class="not-found">
                                                                No data found.
                                                            </div>
                                                        </div>

                                                        <div id="collapse-{{i}}" class="collapse" *ngIf="section.sectionName == 'Connections'" [ngClass]="{'show': section.isActive }">
                                                            <div class="global-padding10" *ngFor="let item of contactConnectionList;">
                                                                <span class="m-0">
                                                                    <a [routerLink]="['/contact', user?.cLPUserID, item?.contactID]">
                                                                        {{item?.lastName + ' ' + ',' + ' ' + item?.firstName }}
                                                                        <span>{{ '(' + item?.relationship + ')'}}</span>
                                                                    </a>
                                                                </span>
                                                            </div>
                                                            <div *ngIf="contactConnectionList?.length == 0" class="not-found">
                                                                No data found.
                                                            </div>
                                                        </div>

                                                        <div id="collapse-{{i}}" class="collapse" *ngIf="section.sectionName == 'Tags'" [ngClass]="{'show': section.isActive }">
                                                            <div *ngFor="let tag of tagListByOwner;">
                                                                <span style="color: #4A5568">{{tag?.tagDisplay}}</span>
                                                            </div>
                                                            <div *ngIf="tagListByOwner?.length == 0" class="not-found">
                                                                No data found.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane" [ngClass]="isSearch ? 'active' : ''" id="searchResults" role="tabpanel" [hidden]="!isSearch">
                                            <div class="sort-filter-module">
                                                <div class="sort-panel">
                                                    <label>Sort</label>
                                                    <select class="form-control" (change)="changeContactSort($event);">
                                                        <option value="">-Select-</option>
                                                        <option value="created">Created on</option>
                                                        <option value="updated">Updated on</option>
                                                    </select>
                                                </div>
                                                <div class="filter-panel">
                                                    <label>Filter</label>
                                                    <select id="drpSavedQueries" class="form-control" (change)="_contactSearchService.drpSavedQueries_onChange($event)">
                                                        <option [value]='0'>-Select-</option>
                                                        <option *ngFor="let item of _contactSearchService.savedQuery_Filter" [value]='item.key'>{{item?.value}}</option>
                                                        <option [value]='-2'>New Search</option>
                                                    </select>
                                                </div>
                                                <div class="filter-edit-btn">
                                                    <button (click)="_contactSearchService.editSavedQuery()" class="btn-icon" [disabled]="_contactSearchService.savedQueryValue <= '0' || _contactSearchService.isDrpSavedQueryChanged">
                                                        <i class="fas fa-pencil-alt btn-square-md"></i>
                                                        <span>Edit</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="list-module">
                                                <div class="grid-search">
                                                    <div class="align-items-start">
                                                        <input placeholder="Search(Name, Number ,Email , etc)" kendoTextBox (input)="onContactsFilter($event.target.value)" />
                                                    </div>
                                                </div>
                                                <kendo-grid #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                                                            [kendoGridBinding]="contactList"
                                                            [kendoGridSelectBy]="'contactID'"
                                                            [selectedKeys]="mySelection"
                                                            [pageSize]="_gridCnfgService.pageSize"
                                                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                                            [scrollable]="'false'"
                                                            [sortable]="{mode: 'multiple'}"
                                                            [sort]="_gridCnfgService.sort"
                                                            [columnMenu]="{ filter: true }"
                                                            [resizable]="true"
                                                            [selectable]="true"
                                                            [rowClass]="rowCallback.bind(this)"
                                                            (columnReorder)="_gridCnfgService.columnsOrderChanged('contact_module_grid', $event)"
                                                            (sortChange)="_gridCnfgService.sortChange('contact_module_grid', $event)"
                                                            (pageChange)="_gridCnfgService.pageChange('contact_module_grid', $event)"
                                                            (columnResize)="_gridCnfgService.columnResize(4,'contact_module_grid', $event)"
                                                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'contact_module_grid',grid)"
                                                            (selectionChange)="rowSelectionChange($event)">
                                                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                                       [field]="column.field"
                                                                       [title]="column.title | titlecase"
                                                                       [width]="column.width | stringToNumber"
                                                                       [filterable]="true"
                                                                       [headerStyle]="{'text-align': 'center','background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1">
                                                        <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                                                            <div (click)="onSwitchChange(true, dataItem?.contactID)">
                                                                <span *ngIf="column.field == 'name'">{{dataItem?.name}}</span>
                                                                <span *ngIf="column.title == 'handled'">
                                                                    <!--<kendo-switch [checked]="false" onLabel=" " offLabel=" " [readonly]="true" *ngIf="selectedContact?.contactID != dataItem?.contactID "></kendo-switch>-->
                                                                    <kendo-switch [checked]="dataItem?.isSelected" [disabled]="dataItem?.isSelected" id="ks_{{rowIndex}}" (valueChange)="onSwitchChange($event, dataItem?.contactID)" onLabel=" " offLabel=" "></kendo-switch>
                                                                </span>
                                                            </div>

                                                        </ng-template>
                                                    </kendo-grid-column>
                                                </kendo-grid>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="modal fade" id="contactDeleteModal" tabindex="-1" role="dialog" aria-labelledby="contactDeleteMdl" aria-hidden="true">
                    <div class="modal-dialog  modal-common-dialog" role="document">
                        <div class="modal-content modal-common-content">
                            <div class="modal-header modal-common-background">
                                <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
                            </div>
                            <div class="modal-body modal-common-body">
                                <!--<h5>Are you sure to delete {{selectedContact?.name}}?</h5>-->
                                <h6>This contact (including any related leads, appointments, tasks, etc) will be permanently deleted. Are you sure you want to delete this contact?</h6>
                            </div>
                            <div class="modal-footer">
                                <button type="button" (click)="deleteContacts()" data-dismiss="modal" class="btn btn-primary">Confirm</button>
                                <button type="button" class="btn btn-cancel" data-dismiss="modal"> Close</button>


                            </div>


                        </div>
                    </div>
                </div>
            </kendo-splitter-pane>
            <kendo-splitter-pane>
                <div class="middle-section" [class.fade-out]="showSpinner || _contactSearchService.showSpinner">
                    <fieldset #activeDashboardId>
                        <legend *ngIf="isShowHistory">
                            Active Dashboard
                        </legend>
                        <legend *ngIf="!isShowHistory">
                            Activity : {{selectedContact?.lastName}} {{selectedContact?.firstName}}
                        </legend>
                        <div class="middle-section-alignment">
                            <div class="common-section-module">
                                <form>
                                    <span *ngIf="loadOtherComponents && _localService.contactFields && selectedContactId">
                                        <lead-appt #leadApptChild *ngIf="showApptComponent == true" [loggedUser]="user" [apptTypeCodes]="apptTypeCodes" [contactId]="selectedContactId" [isContactAppt]="true"></lead-appt>
                                        <!--<app-contact-task *ngIf="showTaskComponent" [loggedUser]="user" [contactId]="selectedContactId" [task]="taskData" [isContactTask]=true></app-contact-task>-->
                                        <app-contact-note *ngIf="showNoteComponent" [ContactName]="getContactName(selectedContact?.firstName,selectedContact?.lastName)" [loggedUser]="user" [ownerId]="selectedContactId" [note]="noteData"></app-contact-note>
                                        <app-contact-email *ngIf="showEmailComponent" [loggedUser]="user" [contactId]="isShowReferredEmail ? mailingContactId : selectedContactId" (closeEmailModal)="hideEmailModel()"></app-contact-email>
                                        <app-contact-meeting *ngIf="_localService.showCommonComp == 'meeting'" [loggedUser]="user" [contactFields]="contactFields"></app-contact-meeting>
                                        <app-contact-sms *ngIf="showTextComponent" [loggedUser]="user" [contactId]="selectedContactId"></app-contact-sms>
                                        <quick-link *ngIf="showLinkComponent == true" [ownerType]="eNoteOwnerType.Contact" [loggedUser]="user" [ownerId]="selectedContactId"></quick-link>
                                        <!--<upload-document *ngIf="showDocComponent == true" [loggedUser]="user" [ownerId]="selectedContactId"></upload-document>-->
                                        <app-contact-detail *ngIf="showContactDetail" [contactId]="selectedContactId" [isSaveAs]="isClickSaveAs" [resetDetailSubject]="resetDetailSubject.asObservable()" (isContactUpdate)="contactUpdate($event);"></app-contact-detail>
                                        <contact-recent-lead *ngIf="showLeadComponent == true && recentLeadList.length>0" [loggedUser]="user" [ownerId]="selectedContactId"></contact-recent-lead>
                                        <!--<contact-recent-mailing *ngIf="showMailingComponent == true" (isShowEmail)="showEmail($event,mailing);" [loggedUser]="user" [ownerId]="selectedContactId" type="Mailing"></contact-recent-mailing>
                                        <contact-recent-mailing *ngIf="showReferralComponent == true" (isShowEmail)="showEmail($event);_localService.scrollTop(activeDashboardId);" (mailingContactId)="mailingContactId = $event" [loggedUser]="user" [ownerId]="selectedContactId" type="Referral"></contact-recent-mailing>-->
                                    </span>
                                    <app-contact-search [isEdit]="_contactSearchService.isSearchEditClick" *ngIf="_contactSearchService.savedQueryValue == '-2' || _contactSearchService.isSearchEditClick"></app-contact-search>
                                    <app-contact-detail *ngIf="_localService.showCommonComp == 'contact-new' && !showContactDetail" [contactId]="0"></app-contact-detail>
                                    <app-manage-campaigns *ngIf="user && selectedContactId>0" (refreshContactDetail)="refreshContactDetail($event)" [ownerId]="selectedContactId" [ownerType]="eCampaignTemplateOwnerType?.Contact" [from]="'contact'" [isShowForm]="false"></app-manage-campaigns>
                                    <app-contact-activity-history *ngIf="contactHistory" (selectNote)=selectNote($event) (selectTask)=selectTask($event) [userddList]="userList" [contactHistory]="contactHistory" [contactId]="selectedContactId" (viewLoaded)="loadOtherComponents = true"></app-contact-activity-history>
                                </form>
                            </div>
                        </div>
                        <div class="common"></div>
                    </fieldset>
                </div>
            </kendo-splitter-pane>
            <kendo-splitter-pane min="27%" max="29%" [(size)]="rightPanelSize" (sizeChange)="panelSizeChange('right', $event)" *ngIf="isShowHistory">
                <div class="right-section">
                    <fieldset>
                        <legend>Action Module</legend>
                        <div class="contact-info-section">
                            <!--<legend>Engagement</legend>-->
                            <div class="">
                                <div class="row-panel">
                                    <div class="col-right-panel">
                                        <div class="wraper-main-section">
                                            <div class="global-card-section global-card-side-section">
                                                <div class="global-header-section">
                                                    <div class="svg-icon-panel">Quick Launch</div>
                                                </div>
                                                <div class="time-link-report">
                                                    <span><a (click)="goToLink('lead')" title="view leads for this contact">View Lead List</a></span>
                                                    <!--<span><a [routerLink]="['contact/leads',routeContactId, apptCategory]" title="view leads for this contact">View Lead List</a></span>-->
                                                    <span><a (click)="goToTask()" title="view tasks">View Task List</a></span>
                                                    <span><a (click)="goToLink('appt')" title="view all appointments">View Appointment List</a></span>
                                                    <span><a [routerLink]="['/my-documents',routeContactId, apptCategory]" title="view all documents">View Document List</a></span>
                                                    <span><a class="custom-security-link" (click)="goToLink('history')" title="view all documents">View Contact History</a></span>
                                                    <span><a href="javascript:void(0)" (click)="viewAutomationProcess()" title="manage the automation process for this contact">View Automation Processes</a></span>
                                                    <span><a [routerLink]="" title="Open Custom Actions" data-toggle="modal" (click)="goToLink('action')" data-target="#customActionScreenModal">Custom Action</a></span>
                                                    <span><a title="send email" (click)="_localService.showCommonComp ='email'; showCommonComponent();  _localService.showPristneForm();">Send Email</a></span>
                                                    <span><a [routerLink]="" title="create a mail merge document" data-toggle="modal" data-target="#documentMailMerge" data-backdrop="static" data-keyboard="false">Create Mail Merge</a></span>
                                                    <span *ngIf="selectedContact != null ? isShowSendFax() : false"><a [routerLink]="" title="send fax using Fax2Mail">Send Fax</a></span>
                                                    <span><a title="Launch Slidecast" (click)="launchSlidecast()">Launch Slidecast</a></span>
                                                    <span><a title="Self Guided Slidecast" (click)="sendSelfGuidedSlideCast()">Self-Guided Slidecast</a><span *ngIf="selfGuided" style="font:bold" class="text-success"> Text sent.</span></span>

                                                    <span>
                                                        <a title="Fire Zap" *ngIf="fireZapLink" (click)="showZapDD()">Fire Zap</a>
                                                        <span *ngIf="zapTriggered" style='color:darkgreen'>{{selectedZapName}} Triggered</span>
                                                        <select class="form-control" *ngIf="zapDD" (change)="selectedZap($event)">
                                                            <option value="">-Select a Zap-</option>
                                                            <option *ngFor="let zapdd of zapDropDownItem" [value]="zapdd?.id">{{zapdd?.text}}</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="global-card-section global-card-side-section mt-3" *ngIf="companyData?.isClubModuleInstalled">
                                                <div class="global-header-section">
                                                    <div class="svg-icon-panel">{{companyData?.companyName}} Boat Club</div>
                                                </div>
                                                <div class="time-link-report">
                                                    <span><a title="Contract Manager" (click)="redirectToCM()">Contract Manager</a></span>
                                                </div>
                                                <div class="cm-card">
                                                    <div>
                                                        <span>Status</span>:<span>{{setStatusLabel(cmContractSummary?.status)}}</span>
                                                    </div>
                                                    <div>
                                                        <span>Product</span>:<span>{{cmContractSummary?.display}}</span>
                                                    </div>
                                                    <div>
                                                        <span>Contract Start</span>:<span>{{cmContractSummary?.dtContractStart | date : 'MM/dd/yyyy'}}</span>
                                                    </div>
                                                    <div>
                                                        <span>Payment Begins	</span>:<span>{{cmContractSummary?.dtPaymentBegins | date : 'MM/dd/yyyy'}}</span>
                                                    </div>
                                                    <div>
                                                        <span>Contract End</span>:<span>{{cmContractSummary?.dtContractEnd | date : 'MM/dd/yyyy'}}</span>
                                                    </div>
                                                    <div>
                                                        <span>Contract Expires</span>:<span>{{cmContractSummary?.dtExpires | date : 'MM/dd/yyyy'}}</span>
                                                    </div>
                                                    <div *ngFor="let item of cmContractFeeSummary">
                                                        <span>{{item?.cMFeeName}}</span>
                                                        <span>{{item?.feeBase | currency}}</span>
                                                    </div>
                                                    <div class="cm-user" *ngFor="let item of cmMembersSummary">
                                                        <div>
                                                            <a (click)="redirectToContact(item)">{{item?.firstName}} {{item?.lastName}}</a>
                                                        </div>
                                                        <div>
                                                            <span>Status</span><span [ngStyle]="{ 'color': getStatusColor(item?.status) }">{{setStatusLabel(item?.status)}}</span>
                                                        </div>
                                                        <div>
                                                            <span>Stripe</span><span>{{item?.vCustomerID}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="global-card-section global-card-side-section mt-3" *ngIf="companyData?.isClubModuleInstalled && user?.cLPCompanyID == 1686">
                                                <div class="global-header-section">
                                                    <div class="svg-icon-panel">Boat Club Payment Request</div>
                                                </div>
                                                <div class="time-link-report" *ngIf="!isShowPaymentIntent">
                                                    <span><a title="Contract Manager" (click)="createPaymentRequest()">Create Payment Request</a></span>
                                                </div>
                                                <div class="cm-card">
                                                    <div *ngIf="isShowPaymentIntent">
                                                        <div>
                                                            <b> {{c1686_PaymentIntent.description}} </b>
                                                            <br /> Entry Fee: {{c1686_PaymentIntent.entryFee | currency}}
                                                            <ng-container [ngSwitch]="c1686_PaymentIntent.status">
                                                                <ng-container *ngSwitchCase="1">
                                                                    Status<span style='color: red'>Pending</span>
                                                                </ng-container>
                                                                <ng-container *ngSwitchCase="2">
                                                                    Status<span style='color: darkgreen'>Collected {{c1686_PaymentIntent.dtModified | date:"M/d/yy 'at' h:mm tt" }}  </span>
                                                                </ng-container>
                                                                <ng-container *ngSwitchCase="3">
                                                                    Status<span style='color: orange'>Waiting For ACH Code</span>
                                                                </ng-container>
                                                                <ng-container *ngSwitchDefault>
                                                                    Status<span style='color: red'>{{c1686_PaymentIntent.status}}  {{c1686_PaymentIntent.diag}}</span>
                                                                </ng-container>
                                                            </ng-container>
                                                        </div>
                                                        <div>
                                                            <a class="contact-cursor-pointer" (click)="cancelPaymentRequest()"> Cancel Payment Request</a>
                                                        </div>
                                                        <div>
                                                            <a class="contact-cursor-pointer" (click)="f2f()">Face To Face</a>
                                                        </div>
                                                        <div>
                                                            Copy/Paste
                                                        </div>
                                                    </div>
                                                    <div *ngIf="trQCK">
                                                        <select *ngIf="isddPlan" [(ngModel)]="selectedPlanId" (change)="onSelectPlan(selectedPlanId)">
                                                            <option *ngFor="let item of ddPlan" [value]="item?.class4Code">{{item?.display}}</option>
                                                        </select>
                                                    </div>
                                                    <div *ngIf="trQCK">
                                                        <span>Accept ACH</span>:<input type="checkbox" class="checkbox" [(ngModel)]="isAcceptACH" />
                                                    </div>
                                                    <div *ngIf="trQCK">
                                                        <input type="text" [(ngModel)]="cfAmmount" />
                                                        <input type="button" (click)="createPaymentIntent()" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="home-dashboard-right mt-3" *ngIf="user?.cLPCompanyID == 1321">
                                            <div class="wraper-main-section">
                                                <div class="global-card-section global-card-side-section">
                                                    <div class="global-header-section goals-bg-contact">
                                                        <div class="svg-icon-panel">INPEX</div>
                                                    </div>
                                                    <div class="global-body-section">
                                                        <div class="time-link-report">
                                                            <span> <a href="javascript:void(0)">Schedule Phone Call</a></span>
                                                            <span> <a href="javascript:void(0)">Purchase Booth or Services</a></span>
                                                            <span>
                                                                <a *ngIf="!isShowMarkDead" (click)="getLoadClass4Code()">Mark as Dead</a>
                                                                <select *ngIf="isShowMarkDead" [(ngModel)]="markDeadPnl">
                                                                    <option *ngFor="let item of class4Codes" [value]="item?.id">{{item?.text}}</option>
                                                                </select>
                                                                &nbsp; <button *ngIf="isShowMarkDead" id="nomd" class="btn btn-cancel" (click)="confirmMarkAsDead(markDeadPnl)">Mark As Dead</button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="home-dashboard-right mt-3" *ngIf="ucIHContactQuickLinks1">
                                            <div class="wraper-main-section">
                                                <div class="global-card-section global-card-side-section">
                                                    <div class="global-header-section goals-bg-contact">
                                                        <div class="svg-icon-panel">InventHelp</div>
                                                    </div>
                                                    <div class="global-body-section ">
                                                        <div class="time-link-report">
                                                            <span> <a class="custom-security-link" (click)="goToLink('contractIH')">Contracts</a></span>
                                                            <span> <a [routerLink]="['/call-ih']">View Call List</a></span>
                                                            <span> <a [routerLink]="['/calender']">View Calendar</a></span>
                                                            <span>
                                                                <a id="ih" (click)="openSendBrochuresService()">Send Brochures</a>
                                                                <br />
                                                                <select *ngIf="openBrochure" [(ngModel)]="sendBrochure" name="sendBrochure" (change)="selectBrochure()">
                                                                    <option value="0">-Select Country-</option>
                                                                    <option value="27894">United States</option>
                                                                    <option value="27892">Canada</option>
                                                                    <option value="27893">United Kingdom</option>
                                                                    <option value="27891">Australia</option>
                                                                    <option value="41994">123Invent</option>
                                                                    <option value="41995">123Invent-Australia</option>
                                                                </select>
                                                                <span *ngIf="btnEmailSend" class="text-success">Selected: {{sendBrochure==27894?'United States':sendBrochure==27892?'Canada':sendBrochure==27893?'United Kingdom':sendBrochure==27891?'Australia':'Please select a country'}}</span>
                                                                <button *ngIf="btnEmailSend" class="btn btn-primary" id="sendBrochurebtn" (click)="sendEmailService(sendBrochure,'Brochure')">Send</button>
                                                            </span>
                                                            <span>
                                                                <a id="ih-sf" (click)="openSendServiceFees()">Services and Fees</a>
                                                                <br />
                                                                <select *ngIf="openServiceFees" [(ngModel)]="serviceFees" name="openService" (change)="selectServiceFees()">
                                                                    <option value="0">-Select Country-</option>
                                                                    <option value="27894">United States</option>
                                                                    <option value="27892">Canada</option>
                                                                    <option value="27893">United Kingdom</option>
                                                                    <option value="27891">Australia</option>
                                                                    <option value="41996">123Invent</option>
                                                                    <option value="41997">123Invent-Australia</option>
                                                                </select>
                                                                <span *ngIf="btnServiceEmailSend" class="text-success">Selected: {{serviceFees==27894?'United States':serviceFees==27892?'Canada':serviceFees==27893?'United Kingdom':serviceFees==27891?'Australia':'Please select a country'}}</span>
                                                                <button *ngIf="btnServiceEmailSend" class="btn btn-primary" id="sendServicebtn" (click)="sendEmailService(serviceFees,'Service')">Send</button>
                                                            </span>
                                                            <span> <a (click)="goToIHStep('ScheduleLeadFUCall')">Schedule Lead FU Call</a></span>
                                                            <span> <a (click)="goToIHStep('ScheduleBIPFUCall')">Schedule BIP FU Call</a></span>
                                                            <span> <a (click)="goToIHStep('ScheduleBIPMeeting')">Schedule BIP Meeting</a></span>
                                                            <span> <a (click)="goToIHStep('ScheduleBIPBe-BackMeeting')">Schedule BIP Be-Back Meeting</a></span>
                                                            <span> <a (click)="goToIHStep('ScheduleInterimMeeting')">Schedule Interim Meeting</a></span>
                                                            <span> <a (click)="goToIHStep('BIPBackfromHomeOffice')">BIP Back from Home Office</a></span>
                                                            <span> <a (click)="goToIHStep('ScheduleSUBMeeting')">Schedule SUB Meeting</a></span>
                                                            <span> <a (click)="goToIHStep('ScheduleSUBFUCall')">Schedule SUB FU Call</a></span>
                                                            <span> <a (click)="goToIHStep('ScheduleSUBBe-BackMeeting')">Schedule SUB Be-Back Meeting</a></span>
                                                            <span> <a (click)="goToIHStep('SUBSaleComplete')">SUB Sale Complete</a></span>
                                                            <span> <a (click)="goToIHStep('SendTimetableofServices')">Send Timetable of Services</a></span>
                                                            <span> <a (click)="goToIHStep('SalesPortal')">Sales Portal</a></span>
                                                            <span> <a (click)="getAllUserInfo(false)">Transfer Contact</a></span>
                                                            <span> <a (click)="downloadAddressLevel();">Print Address Label</a></span>
                                                            <span> <a (click)="goToLink('contact')">TeleSales - Paid</a></span>
                                                            <span> <a (click)="goToLink('contact')">TeleSales - ADS</a></span>
                                                            <span> <a (click)="goToLink('contact')">Advertising</a></span>
                                                            <span> <a (click)="goToLink('contact')">Internal</a></span>
                                                            <span>
                                                                <a *ngIf="!isShowMarkDead" (click)="getLoadClass4Code()">Mark as Dead</a>
                                                                <select *ngIf="isShowMarkDead" [(ngModel)]="markDeadPnl">
                                                                    <option *ngFor="let item of class4Codes" [value]="item?.id">{{item?.text}}</option>
                                                                </select>
                                                                &nbsp; <button *ngIf="isShowMarkDead" id="ihms" class="btn btn-cancel" (click)="confirmMarkAsDead(markDeadPnl)">Mark As Dead</button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="home-dashboard-right mt-3" *ngIf="ucIHPPContactQuickLinks1">
                                            <div class="wraper-main-section">
                                                <div class="global-card-section global-card-side-section">
                                                    <div class="global-header-section goals-bg-contact">
                                                        <div class="svg-icon-panel">Patent Plus</div>
                                                    </div>
                                                    <div class="global-body-section ">
                                                        <div class="time-link-report">
                                                            <span> <a class="custom-security-link" (click)="goToLink('ihpp_contsite')">Contracts-PP</a></span>
                                                            <span> <a [routerLink]="['/call-ih']">View Call List</a></span>
                                                            <span> <a [routerLink]="['/calender']">View Calendar</a></span>
                                                            <span>
                                                                <a id="pp" (click)="openSendBrochuresService()">Send Brochures</a>
                                                                <br />
                                                                <select *ngIf="openBrochure" [(ngModel)]="sendBrochure" name="sendBrochure" (change)="selectBrochure()">
                                                                    <option value="0">-Select Country-</option>
                                                                    <option value="27894">United States</option>
                                                                    <option value="27892">Canada</option>
                                                                    <option value="27893">United Kingdom</option>
                                                                    <option value="27891">Australia</option>
                                                                </select>
                                                                <span *ngIf="btnEmailSend" class="text-success">Selected: {{sendBrochure==27894?'United States':sendBrochure==27892?'Canada':sendBrochure==27893?'United Kingdom':sendBrochure==27891?'Australia':'Please select a country'}}</span>
                                                                <button *ngIf="btnEmailSend" class="btn btn-primary" id="sendBrochurebtn" (click)="sendEmailService(sendBrochure,'Brochure')">Send</button>
                                                            </span>
                                                            <span>
                                                                <a id="pp-sf" (click)="openSendServiceFees()">Services and Fees</a>
                                                                <br />
                                                                <select *ngIf="openServiceFees" [(ngModel)]="serviceFees" name="openService" (change)="selectServiceFees()">
                                                                    <option value="0">-Select Country-</option>
                                                                    <option value="27896">Australia</option>
                                                                    <option value="27895">United States</option>
                                                                    <option value="27898">United Kingdom</option>
                                                                    <option value="27897">Canada</option>
                                                                </select>
                                                                <span *ngIf="btnServiceEmailSend" class="text-success">Selected: {{serviceFees==27894?'United States':serviceFees==27892?'Canada':serviceFees==27893?'United Kingdom':serviceFees==27891?'Australia':'Please select a country'}}</span>
                                                                <button *ngIf="btnServiceEmailSend" class="btn btn-primary" id="sendServicebtn" (click)="sendEmailService(serviceFees,'Service')">Send</button>
                                                            </span>
                                                            <span> <a (click)="goToPPStep('ScheduleLeadFUCall')">Schedule Lead FU Call</a></span>
                                                            <span> <a (click)="goToPPStep('ScheduleBIPFUCall')">Schedule BIP FU Call</a></span>
                                                            <span> <a (click)="goToPPStep('SchedulePPSOMeeting')">Schedule PPSO Meeting</a></span>
                                                            <span> <a (click)="goToPPStep('SchedulePPSOBe-BackMeeting')">Schedule PPSO Be-Back Meeting</a></span>
                                                            <span> <a (click)="goToPPStep('ScheduleInterimMeeting')">Schedule Interim Meeting</a></span>
                                                            <span> <a (click)="goToPPStep('PPSOBackfromHomeOffice')">PPSO Back from Home Office</a></span>
                                                            <span> <a (click)="goToPPStep('SchedulePatPlusServicesMeeting')">Schedule PatPlus Services Meeting</a></span>
                                                            <span> <a (click)="goToPPStep('SchedulePatPlusServicesFUCall')">Schedule PatPlus Services FU Call</a></span>
                                                            <span> <a (click)="goToPPStep('SchedulePatPlusServicesBe-BackMeeting')">Schedule PatPlus Services Be-Back Meeting</a></span>
                                                            <span> <a (click)="goToPPStep('PatPlusServicesSaleComplete')">PatPlus Services Sale Complete</a></span>
                                                            <span> <a (click)="getAllUserInfo(true)">Transfer Contact</a></span>
                                                            <span> <a (click)="downloadAddressLevel();">Print Address Label</a></span>
                                                            <span> <a (click)="goToLink('contact')">TeleSales - Paid</a></span>
                                                            <span> <a (click)="goToLink('contact')">TeleSales - ADS</a></span>
                                                            <span> <a (click)="goToLink('contact')">Advertising</a></span>
                                                            <span> <a (click)="goToLink('contact')">Internal</a></span>
                                                            <span>
                                                                <a *ngIf="!isShowMarkDead" (click)="getLoadClass4Code()">Mark as Dead</a>
                                                                <select *ngIf="isShowMarkDead" [(ngModel)]="markDeadPnl">
                                                                    <option *ngFor="let item of class4Codes" [value]="item?.id">{{item?.text}}</option>
                                                                </select>
                                                                &nbsp; <button *ngIf="isShowMarkDead" id="ppsm" class="btn btn-cancel" (click)="confirmMarkAsDead(markDeadPnl)">Mark As Dead</button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="home-dashboard-right mt-3">
                                            <div class="wraper-main-section">
                                                <div class="global-card-section global-card-side-section">
                                                    <div class="global-header-section goal-bg-banner">
                                                        <div class="svg-icon-panel"><!--<img src="../../../../assets/activity/task/remindertasklead.svg" class="title-img" />-->{{apptHeader}}</div>
                                                        <div class="header-button-panel">
                                                            <div class="button-wrapper">
                                                                <button class="btn btn-primary" (click)="appointmentPopUp(null,true);" data-toggle="modal" data-target="#quickApptModalContact" data-backdrop="static" data-keyboard="false"><i class="fa fa-plus"></i> New</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="global-body-section">
                                                        <lead-appt #leadApptChild *ngIf="user" [apptTypeCodes]="apptTypeCodes" [loggedUser]="user" (isApptList)="getApptHeader($event)" [contactId]="selectedContactId" [isContactAppt]="true" [isShowHeader]="false"></lead-appt>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="home-dashboard-right mt-3">
                                            <div class="wraper-main-section">
                                                <div class="global-card-section global-card-side-section">
                                                    <div class="global-header-section recent-note">
                                                        <div class="svg-icon-panel"><!--<img src="../../../../assets/activity/task/remindertasklead.svg" class="title-img" /> -->{{noteHeader}}</div>
                                                        <div class="header-button-panel">
                                                            <div class="button-wrapper">
                                                                <button class="btn btn-primary" (click)="goToNoteHistory()"><i class="fa fa-plus"></i> New</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="global-body-section">
                                                        <app-contact-note *ngIf="user" [ContactName]="getContactName(selectedContact?.firstName,selectedContact?.lastName)" [loggedUser]="user" (isNoteList)="getNoteHeader($event)" [ownerId]="selectedContactId" [note]="noteData" [isShowHeader]="false"></app-contact-note>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="home-dashboard-right mt-3">
                                            <dashboard-card #apptTask *ngIf="user" cardType="task" [ownerId]="routeContactId" [taskCategory]="taskCategory" [user]="user" (dataItemEventRecentParent)="sendEmailParent($event)" [roleFeaturePermissions]="roleFeaturePermissions">
                                                <div class="global-header-section global-card-side-section">
                                                    <div class="svg-icon-panel">{{apptTask?.listData?.length>0 ? 'Upcoming Tasks' : 'Quick Task'}}</div>
                                                    <div class="header-button-panel">
                                                        <div class="button-wrapper">
                                                            <button class="btn btn-primary" (click)="createNewTask()"><i class="fa fa-plus"></i> New</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </dashboard-card>
                                        </div>
                                        <div class="home-dashboard-right mt-3">
                                            <div class="wraper-main-section">
                                                <div class="global-card-section global-card-side-section">
                                                    <div class="global-header-section goal-bg-leads">
                                                        <div class="svg-icon-panel"><!--<img src="../../../../assets/activity/task/remindertasklead.svg" class="title-img" />-->Recent Leads</div>
                                                        <div class="header-button-panel">
                                                            <div class="button-wrapper">
                                                                <button class="btn btn-primary" (click)="goToNoteHistory()"><i class="fa fa-plus"></i> New</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="global-body-section">
                                                        <div class="grid-wrapper" *ngIf="recentLeadList?.length!=0">
                                                            <div class="contact" *ngFor="let leadList of recentLeadList; let idx=index">
                                                                <div class="cal-icon">
                                                                    <a class="contact-cursor-pointer" title="{{ leadList?.leadDesc }}
                                                                        Contact : {{userName}} Status : {{leadList?.leadStatus}}" [routerLink]="['/lead-detail', leadList?.leadID]">
                                                                        <img src="../../../../../assets/leadstitle.svg" />
                                                                    </a>
                                                                </div>
                                                                <div class="contact-details">
                                                                    <a class="contact-cursor-pointer" [routerLink]="['/lead-detail',leadList?.leadID]">
                                                                        <div class="contact-name-call">{{ userName }} ({{ leadList?.leadDesc }}) </div>
                                                                    </a>
                                                                </div>
                                                                <div class="button-wrapper">
                                                                    <button class="icon-btn" (click)="sendEmail(leadList?.contactID)" title="Send Email to Link">
                                                                        <img src="../../../../../assets/email_new.svg">
                                                                        <span class="btn-text">Email</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="home-dashboard-right mt-3">
                                            <div class="wraper-main-section">
                                                <div class="global-card-section global-card-side-section">
                                                    <div class="global-header-section goals-bg-contact">
                                                        <div class="svg-icon-panel"><!--<img src="../../../../assets/linktitle.svg" class="title-img" />-->Link Groups</div>
                                                        <div class="header-button-panel">
                                                            <div class="button-wrapper">
                                                                <button class="btn btn-primary" [routerLink]="['/link-group/0']"><i class="fa fa-plus"></i> New</button>
                                                                <!--<a class="contact-cursor-pointer cal-icon" title="Send Email to Link" data-toggle="modal" data-target="#emailModal1"><img src="../../../../../assets/email_new.svg"/></a>-->
                                                                <button class="icon-btn" title="Send Email to Link" data-toggle="modal" data-target="#emailModal1">
                                                                    <img src="../../../../../assets/email_new.svg" />
                                                                    <span class="btn-text">Email</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="global-body-section">
                                                        <quick-link *ngIf="user" [loggedUser]="user" [ownerType]="eNoteOwnerType.Contact" [ownerId]="selectedContactId" [isShowHeader]="false" (filterLink)="getFilterlinks($event)"></quick-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="home-dashboard-right mt-3">
                                            <contact-referred *ngIf="user && referralId>=0" (isShowEmail)="showEmail($event);_localService.scrollTop(activeDashboardId);" (mailingContactId)="mailingContactId = $event" [loggedUser]="user" [referralId]="referralId" [contactId]="selectedContactId" [isNewShow]=true></contact-referred>
                                        </div>
                                        <div class="home-dashboard-right mt-3">
                                            <contact-recent-mailing *ngIf="user && selectedContactId" [loggedUser]="user" [contactId]="selectedContactId" (isShowEmail)="showEmail($event)" (mailingContactId)="sendEmail($event)" type="Referral"></contact-recent-mailing>
                                        </div>
                                        <div class="home-dashboard-right mt-3">
                                            <contact-recent-mailing *ngIf="user && selectedContactId" [loggedUser]="user" [contactId]="selectedContactId" type="Mailing"></contact-recent-mailing>
                                        </div>
                                        <div class="home-dashboard-right mt-3">
                                            <div class="wraper-main-section">
                                                <div class="global-card-section global-card-side-section">
                                                    <div class="global-header-section goals-bg-contact">
                                                        <div class="svg-icon-panel">{{documentHeader}}</div>
                                                    </div>
                                                    <div class="global-body-section">
                                                        <upload-document *ngIf="user && selectedContactId" (isDocumentList)="getDocumentHeader($event)" [loggedUser]="user" [ownerId]="selectedContactId" [documentCategory]="eDocumentCategory.Contact"></upload-document>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!--<div class="home-dashboard-right">
                                          <dashboard-card #apptLeads *ngIf="user && recentLeadList?.length>0" cardType="lead" [user]="user" (dataItemEventRecentParent)="sendEmailParent($event)" [roleFeaturePermissions]="roleFeaturePermissions">
                                            <div class="global-header-section goal-bg-leads">
                                              <div class="svg-icon-panel"> Recent Leads</div>
                                              <div class="header-button-panel">
                                                <div class="button-wrapper">
                                                  <button class="btn btn-primary" [routerLink]="['/lead-create']" title="New Lead"><i class="fa fa-plus"></i> New</button>
                                                </div>
                                              </div>
                                            </div>
                                          </dashboard-card>
                                        </div>-->
                                    </div>
                                </div>
                            </div>
                            <!---->
                            <!--13 feb issue 8-->
                            <!--<div class="lead-button-panel">
                            <button class="upload-doc-btn" [disabled]="!(roleFeaturePermissions?.create || roleFeaturePermissions?.edit || roleFeaturePermissions?.isAdmin || eUserRole > eUserRole.Administrator)" (click)="_localService.showCommonComp ='email'; _localService.scrollTop(activeDashboardId); showCommonComponent();  _localService.showPristneForm();">
                              <i class="fas fa-envelope"></i> Send Email
                            </button>
                            <button class="link-group-btn" [disabled]="!(roleFeaturePermissions?.create || roleFeaturePermissions?.edit || roleFeaturePermissions?.isAdmin || eUserRole > eUserRole.Administrator )" (click)="_localService.showCommonComp ='sms'; _localService.scrollTop(activeDashboardId); showCommonComponent(); _localService.showPristneForm(); ">
                              <i class="fas fa-comments"></i> Send Text
                            </button>-->
                            <!--<div class="home-dashboard-right mt-3">
                            <div class="wraper-main-section">
                              <div class="global-card-section">-->
                            <!--<div class="global-header-section goal-bg-banner">
                              <div class="svg-icon-panel"> <img src="../../../../assets/icon_contact.svg" class="title-img" /> Refrells</div>
                              <button class="btn btn-primary" [routerLink]="['/contact-create']" [queryParams]="{rid: selectedContactId}"><i class="fa fa-plus"></i> Add</button>
                            </div>-->
                            <!--<div class="global-body-section">
                              <contact-recent-mailing (isShowEmail)="showEmail($event);_localService.scrollTop(activeDashboardId);" (mailingContactId)="mailingContactId = $event" [loggedUser]="user" [ownerId]="selectedContactId" type="Referral"></contact-recent-mailing>
                            </div>-->
                            <!--</div>
                              </div>
                            </div>-->
                            <!--<button class="upload-doc-btn" title="View Referrals" (click)="_localService.showCommonComp ='referral'; showCommonComponent();_localService.scrollTop(activeDashboardId);">
                              <img src="../../../../assets/icon_contact.svg" class="title-img" /> Referrals
                            </button>-->
                            <!--</div>-->
                        </div>
                    </fieldset>
                </div>
            </kendo-splitter-pane>
        </kendo-splitter>
    </div>
</div>
<div class="modal fade" id="sendEmailModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-contact-email *ngIf="sendMailInfo?.isShow" [loggedUser]="user" (closeEmailModal)="hideSendMail()" [contactId]="sendMailInfo?.contactId"></app-contact-email>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="modal fade" id="customActionScreenModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title white-contact" id="exampleModalLabel"><i class="fas fa-envelope-open-text white-contact"></i> Custom Action Screen</h5>
                    <div class="button-wrapper">
                        <button type="button" class="icon-btn" aria-label="Close" (click)="hideCustomActionScreen();">
                            <i class="fa fa-close mr-0"></i>
                            <span class="btn-text">Cancel</span>
                        </button>
                    </div>
                </div>
                <div class="modal-body">
                    <app-custom-action-screen *ngIf="customActionScreen?.isShow" [loggedUser]="user" [contactId]="customActionScreen?.contactId" [ownerName]="customActionScreen?.userName" (isCloseButton)="emptyDestinationUrl($event)" (isEditContact)="showEditContact($event)"></app-custom-action-screen>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="transferContactScreenModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title white-contact" id="transferContactScreen"> Transfer Contact </h5>
                    <div class="button-wrapper">
                        <button type="button" class="icon-btn" aria-label="Close" data-dismiss="modal">
                            <i class="fa fa-close mr-0"></i>
                            <span class="btn-text">Cancel</span>
                        </button>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="transfer-contact">
                        <span class="text-success">{{transferMsg}}</span>
                        <span>Select User :</span> &nbsp;
                        <select [(ngModel)]="userR" (change)="changeUserInfoToTransfer(userR)">
                            <option *ngFor="let user of userInfo" [value]="user?.value">{{user?.text}}</option>
                        </select>
                        <br />
                        <br />
                        &nbsp;
                        <button *ngIf="!isConfirm" id="tcontact" class="btn btn-primary" (click)="transferContact(userR)">Transfer Contact</button>
                        <button *ngIf="isConfirm" id="tcontact" class="btn btn-primary" (click)="transferContactConfirm(userR)">Transfer Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="callModal" tabindex="-1" role="dialog" aria-labelledby="callModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title white-contact" id="exampleModalLabel">
                        <img src="../../../../../assets/btnVoiceCall.svg" class="" /> Voice Call
                    </h5>
                    <div class="button-wrapper">
                        <button type="button" class="icon-btn" data-dismiss="modal" aria-label="Close" (click)="isShowNewCallModal=false;">
                            <i class="fas fa-times"></i>
                            <span class="btn-text">Close</span>
                        </button>
                    </div>
                </div>
                <div class="modal-body">
                    <contact-new-call *ngIf="isShowNewCallModal && selectedContactData" [contactData]="selectedContactData" (updatedCall)="hideTextCallModal($event)" [callType]="selectedCallType" [user]="user"></contact-new-call>
                </div>
                <!--<div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="isShowNewCallModal=false;">Close</button>
                </div>-->
            </div>
        </div>

    </div>
</div>

<div class="modal fade" id="quickApptModalContact" tabindex="-1" role="dialog" aria-labelledby="quickApptModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-appointment-common *ngIf="user?.cLPCompanyID != 1226 && isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)" #closeInputButton></app-appointment-common>
                    <app-appointment-ih *ngIf="user?.cLPCompanyID == 1226 && isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)" #closeInputButton></app-appointment-ih>
                </div>
                <!-- <div hidden="hidden">
                   <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideQuickApptModal();">Close</button>
                 </div>-->
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="emailModal1" tabindex="-1" role="dialog" aria-labelledby="emailModalLabel" aria-hidden="true">
    <div class="custom-modal-panel">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <lead-email *ngIf="user" [filterLinks]="filterLinks" [loggedUser]="user" [ownerId]="selectedContactId" [linkId]="0"></lead-email>
                <!--<div class="modal-footer">
                  <button type="button" class="btn btn-cancel" data-dismiss="modal"> Close</button>
                </div>-->
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="buzzScoreCalculation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title white-contact" id="exampleModalLabel">Buzz Index Calculation</h5>
                    <div class="button-wrapper">
                        <button type="button" class="icon-btn" data-dismiss="modal" aria-label="Close" (click)="closeModal();">
                            <i class="fas fa-times"></i>
                            <span class="btn-text">Close</span>
                        </button>
                    </div>
                </div>
                <div class="modal-body pb-3">
                    <buzz-index *ngIf="user && isShowModal" [user]="user" [buzzIndexCalcModal]="buzzIndexCalcModal"></buzz-index>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="documentMailMerge" tabindex="-1" role="dialog" aria-labelledby="quickApptModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-document-mail-merge *ngIf="user" [user]="user" (isCloseButton)="closeDocumentMailMergeModal($event)" [ownerId]="routeContactId" [userName]="userName"></app-document-mail-merge>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="emailOptLogModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="emailOptLogModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-email-opt-log *ngIf="user && isShowModalEmailOptLog" [contactData]="selectedContact" [user]="user" (isCloseButton)="hideEmailOptLogModal($event)"></app-email-opt-log>
                </div>
            </div>
        </div>

    </div>
</div>

<div id="mobileOptLogModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mobileOptLogModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-mobile-opt-log *ngIf="user && isShowModalMobileOptLog" [contactData]="selectedContact" [user]="user" (isCloseButton)="hideMobileOptLogModal($event)"></app-mobile-opt-log>
                </div>
            </div>
        </div>

    </div>
</div>

<div id="soMetricModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mobileOptLogModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-so-metric *ngIf="user && showMetricModal" [contactId]="routeContactId" (isCloseButton)="hideSoMetricModal($event)"></app-so-metric>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _contactSearchService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
