<div class="create-email">
  <div class="global-padding10">
    <div class="wraper-main-section">
      <div class="global-card-section">
        <div class="global-header-section">
          <div class="svg-icon-panel"><img src="../../../assets/emailtitle.svg" class="mr-1" />InventHelp Quick Blast Setup</div>
        </div>
        <div class="global-body-section">
          <div class="alert-panel">
            <div [innerHTML]="quickBlastMessageHeading | byPassSecurity "></div>
            <span>{{quickBlastMessage}}</span>
            <span [innerHTML]="selectUserWithContact?.messageString"></span>
          </div>
          <div>
            <form [formGroup]="quickBlastForm" *ngIf="!isViewMode && user && !isConfirm" (ngSubmit)="quickBlastFormSubmit();">
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Filter</span>
                </div>
                <div class="cards-colunm-right">
                  <select class="form-control" formControlName="filter">
                    <option value="">-Select One-</option>
                    <option value="26543">BIP - No Buy</option>
                    <option value="26544">SUB - No Buy</option>
                  </select>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Selected Users</span>
                </div>
                <div class="cards-colunm-right">
                  <select class="form-control" formControlName="selectedUser" (change)="onChangeUser($event.target.value)">
                      <option [value]="0">-Select One-</option>
                    <option *ngFor="let item of userList; let i= index;" [value]="item?.value">{{item?.text}}</option>
                  </select>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Email Template</span>
                </div>
                <div class="cards-colunm-right">
                  <select class="form-control" formControlName="emailTemplate">
                      <option [value]="0">-Select One-</option>
                    <option *ngFor="let item of emailTemplateList" [value]="item.emailTemplateID">{{item.templateName}}</option>
                  </select>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Subject Line</span>
                </div>
                <div class="cards-colunm-right">
                  <input type="text" class="form-control" formControlName="subjectLine">
                  <div class="login-error" *ngIf="quickBlastForm.controls.subjectLine.errors && (quickBlastForm.controls.subjectLine.touched || quickBlastForm.controls.subjectLine.dirty)">
                    <div *ngIf="(quickBlastForm.controls.subjectLine.touched)">Please enter a title.</div>
                  </div>

                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span></span>
                </div>
                <div class="cards-colunm-right">
                  <button class="btn btn-primary" type="submit"><i class="fa fa-save'"></i> Configure</button>
                  <button class="btn btn-cancel" type="button" (click)="onCancelFormQB();"> Cancel</button>
                </div>
              </div>
            </form>
            <div *ngIf="isViewMode && !isConfirm">
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Filter</span>
                </div>
                <div class="cards-colunm-right">
                  {{filterNameQB}}
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Selected Users</span>
                </div>
                <div class="cards-colunm-right">
                  <div *ngFor="let item of selectedUser">{{item.text}}</div>
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Email Template</span>
                </div>
                <div class="cards-colunm-right">
                  {{emailTemplateView}}
                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span>Subject Line</span>
                </div>
                <div class="cards-colunm-right">
                  {{quickBlastForm?.value?.subjectLine}}

                </div>
              </div>
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span></span>
                </div>
                <div class="cards-colunm-right">
                  <button class="btn btn-primary" type="button" (click)="configureQuickBlast()"><i class="fa fa-save'"></i> Confirm</button>
                  <button class="btn btn-cancel" type="button" (click)="onCancelViewModeQB()"> Cancel</button>
                </div>
              </div>
            </div>
            <div *ngIf="isConfirm && !isViewMode">
              <div class="cards-body-section">
                <div class="cards-colunm-left">
                  <span></span>
                </div>
                <div class="cards-colunm-right">
                  <button class="btn btn-cancel" type="button" (click)="onStartOverQB()">Start Over</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
