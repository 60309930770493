<div class="custom-security-link" *ngIf="from ==''">
    <a *ngIf="this.ownerType == eCampaignTemplateOwnerType.Lead" [routerLink]="['/lead-detail',ownerId]">View Lead</a>
    <a *ngIf="this.ownerType == eCampaignTemplateOwnerType.Lead" [routerLink]="['/contact', user?.cLPUserID, leadData?.contactID]">View Contact</a>
    <a *ngIf="this.ownerType == eCampaignTemplateOwnerType.Contact" [routerLink]="['/contact', user?.cLPUserID, ownerId]">View Contact</a>
    <a [routerLink]="['/automation-process']">View Automation Process Campaigns</a>
</div>
<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="top-txtmsg">
                <div class="global-header-section">
                    <div class="svg-icon-panel">
                        <img src="../../../../assets/automationprocesstitle.svg" class="mr-1" />{{from =='contact' ? 'Automation':'Automation Processes for ' }}
                        <a *ngIf="ownerType == eCampaignTemplateOwnerType?.Contact && from ==''" [routerLink]="['/contact',user?.cLPUserID,ownerId]">&nbsp;{{ownerName}}</a>
                        <a *ngIf="ownerType == eCampaignTemplateOwnerType?.Lead" [routerLink]="['/lead-detail',ownerId]">&nbsp;{{ownerName}}</a>
                    </div>
                    <div class="header-button-panel">
                        <div class="button-wrapper">
                            <button type="button" class="icon-btn" (click)="changeFormState();">
                                <i class="fas fa-angle-down up-hide-icon" aria-hidden="true" *ngIf="!isShowForm"></i>
                                <i class="fas fa-angle-up down-icon-hide" aria-hidden="true" *ngIf="isShowForm"></i>
                                <span class="btn-text">Expand</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="email-total-records" *ngIf="isShowForm">
                    <span>Automation Trigger Settings</span>
                </div>
                <div class="global-body-section">
                    <div class="wraper-body-panel" *ngIf="isShowForm">
                        <div class="wraper-body-left">
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span class="control-label">Automation Process</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <kendo-combobox [data]="campaignTemplateList"
                                                    textField="CampaignTemplateName"
                                                    valueField="CampaignTemplateID"
                                                    [filterable]="true"
                                                    (filterChange)="handleFilter($event)"
                                                    (selectionChange)="templateChange($event)"
                                                    placeholder=""
                                                    *ngIf="campaignTemplateId == 0">
                                    </kendo-combobox>
                                    <span *ngIf="campaignTemplateId != 0"><a [routerLink]="['/campaign-template',campaignTemplateId]" class="contact-cursor-pointer">{{campaignTemplateName}}</a></span>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span class="control-label spantext">User</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <select class="form-control" [(ngModel)]="selectedUserId" *ngIf="campaignTemplateId == 0">
                                        <option value="0"> -Select- </option>
                                        <option *ngFor="let item of userList" [value]="item?.id">{{item?.text}}</option>
                                    </select>
                                    <span *ngIf="campaignTemplateId != 0">{{userName}}</span>
                                </div>
                            </div>
                            <div class="cards-body-section" *ngIf="campaignTemplateId != 0">
                                <div class="cards-colunm-left"></div>
                                <div class="cards-colunm-right">
                                    <button class="btn btn-primary" (click)="activateAutomationProcess()" [disabled]="showSpinner" [hidden]="(roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false) && user?.userRole <= eUserRole.Administrator" type="submit">
                                        <ng-template [ngIf]="buttonTypeOperation!=1">Confirm & Activate</ng-template>
                                        <ng-template [ngIf]="buttonTypeOperation===1">Saving  <span> &nbsp; <i wrapper></i></span>  </ng-template>
                                    </button>
                                    <button class="btn btn-cancel" type="button" (click)="cancel()">Cancel</button>
                                </div>
                            </div>
                            <div class="cards-body-section" *ngIf="campaignTemplateId != 0">
                                <div class="cards-colunm-left">Events</div>
                                <div class="cards-colunm-right">
                                    <campaign-event-list *ngIf="user && campaignTemplateId >0" [campaignTemplateId]="campaignTemplateId"></campaign-event-list>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="email-total-records">
                            <span *ngIf="campaignTemplateId != 0">Please review the campaign and click the Confirm and Activate buttom to add the contact to this campaign.</span>
                            <span *ngIf="campaignTemplateId == 0">Total Records found: {{campaignItems?.length}}</span>
                            <div class="button-wrapper" *ngIf="isShowForm && campaignItems?.length > 0">
                                <div class="flex-align-panel">
                                    <button class="icon-btn" type="button" matTooltip="Check All" (click)="onSelectAllChange('checked')">
                                        <img src="../../../../assets/btncapcheckbox.svg" />
                                        <span class="btn-text">Check All</span>
                                    </button>
                                    <button class="icon-btn" type="button" matTooltip="Activate or resume all checked." data-toggle="modal" data-target="#activeCampaignModal">
                                        <img src="../../../../assets/btnmarkactiviate.svg" />
                                        <span class="btn-text">Activate or resume all checked</span>
                                    </button>
                                    <button class="icon-btn" type="button" matTooltip="stop and reset all checked." data-toggle="modal" data-target="#stopCampaignModal">
                                        <img src="../../../../assets/btnmarkstop.svg" />
                                        <span class="btn-text">Stop and reset all checked</span>
                                    </button>
                                    <button class="icon-btn" type="button" matTooltip="Pause all checked." data-toggle="modal" data-target="#pauseCampaignModal">
                                        <img src="../../../../assets/btnmarkpause.svg" />
                                        <span class="btn-text">Pause all checked</span>
                                    </button>
                                    <button class="icon-btn" type="button" matTooltip="Skip current step for all checked." data-toggle="modal" data-target="#skipCampaignModal">
                                        <img src="../../../../assets/btnmarkskip.svg" />
                                        <span class="btn-text">Skip current step for all checked</span>
                                    </button>
                                    <button class="icon-btn" type="button" matTooltip="Remove all checked From campaign" data-toggle="modal" data-target="#removeCampaignModal">
                                        <img src="../../../../assets/btnmarkremove.svg" />
                                        <span class="btn-text">Remove all checked From campaign</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="campaignItems?.length > 0">
                            <kendo-grid *ngIf="_gridCnfgService.reloadGridGeneric" #grid id="grid"
                                        [kendoGridBinding]="campaignItems"
                                        [sortable]="{mode: 'multiple'}"
                                        [sort]="_gridCnfgService.sort"
                                        [pageSize]="_gridCnfgService.pageSize"
                                        kendoGridSelectBy="campaignID"
                                        [(selectedKeys)]="mySelection"
                                        [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                        [scrollable]="'false'"
                                        [reorderable]="true"
                                        [resizable]="true"
                                        [columnMenu]="{ filter: true }"
                                        (columnReorder)="_gridCnfgService.columnsOrderChanged('manage_campaign_grid', $event)"
                                        (sortChange)="_gridCnfgService.sortChange('manage_campaign_grid', $event)"
                                        (pageChange)="_gridCnfgService.pageChange('manage_campaign_grid', $event)"
                                        (columnResize)="_gridCnfgService.columnResize(4,'manage_campaign_grid', $event)"
                                        (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'manage_campaign_grid',grid)">
                                <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                   [field]="column.field"
                                                   [title]="column.title"
                                                   [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                   [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                   [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                   [width]="column.width | stringToNumber"
                                                   [filterable]="true"
                                                   [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                                   [includeInChooser]="column.field=='$' ? false : true">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="customer-name" *ngIf="column.field == '$' && column.title == ''">{{ rowIndex+1 }}</div>
                                        <div class="customer-name" *ngIf="column.field == 'campaignTemplateName'"><a [routerLink]="['/workflow-campaign']" [queryParams]="{cid:dataItem?.campaignID}">{{ dataItem[column.field] }} (User: {{dataItem?.userName}})</a></div>
                                        <div class="customer-name" *ngIf="column.field == 'userName'">{{ dataItem[column.field] }}</div>
                                        <div class="customer-name" *ngIf="column.field == 'campaignEventName'">{{ dataItem?.status == eCampaignStatus?.Draft ? '' : dataItem[column.field] }}</div>
                                        <div class="customer-name" *ngIf="column.field == 'status'">
                                            <span *ngIf="dataItem[column.field] == eCampaignStatus?.Active" class="smallgreen">Active</span>
                                            <span *ngIf="dataItem[column.field] == eCampaignStatus?.Paused" class="small">Paused</span>
                                            <span *ngIf="dataItem[column.field] == eCampaignStatus?.Draft" class="smallred">Stopped</span>
                                            <span *ngIf="dataItem[column.field] == eCampaignStatus?.Completed" class="smallred">Completed</span>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-checkbox-column [width]="40"
                                                            [headerClass]="{ 'text-center': true }"
                                                            [class]="{ 'text-center': true }"
                                                            [resizable]="false"
                                                            [columnMenu]="false"
                                                            [showSelectAll]="true"
                                                            *ngIf="isShowForm">
                                    <ng-template kendoGridHeaderTemplate>
                                        <input type="checkbox" #selectAll title="Check All"
                                               kendoCheckBox
                                               id="selectAllCheckboxId"
                                               kendoGridSelectAllCheckbox
                                               (selectAllChange)="onSelectAllChange($event)" />
                                        <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                                    </ng-template>
                                </kendo-grid-checkbox-column>
                            </kendo-grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="activeCampaignModal" tabindex="-1" role="dialog" aria-labelledby="activeCampaignModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>Activate all checked campaigns?</h2><br />
                <h2>Note: if current status is paused, campaign will be resumed at current event.</h2>
            </div>
            <div class="modal-footer bg-white">
                <button type="button" class="btn btn-primary" (click)="statusUpdate(eCampaignItemStatusAction?.Active);" data-dismiss="modal">Ok</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="stopCampaignModal" tabindex="-1" role="dialog" aria-labelledby="stopCampaignModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>Stop and reset all checked campaigns?</h2><br />
                <h2>Note:This will set current event back to the first event.</h2>
            </div>
            <div class="modal-footer bg-white">
                <button type="button" class="btn btn-primary" (click)="statusUpdate(eCampaignItemStatusAction?.eStop);" data-dismiss="modal">Ok</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="pauseCampaignModal" tabindex="-1" role="dialog" aria-labelledby="pauseCampaignModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>Pause all checked campaigns?</h2><br />
            </div>
            <div class="modal-footer bg-white">
                <button type="button" class="btn btn-primary" (click)="statusUpdate(eCampaignItemStatusAction?.Pause);" data-dismiss="modal">Ok</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="skipCampaignModal" tabindex="-1" role="dialog" aria-labelledby="skipCampaignModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>Skip the current event for all checked campaigns?</h2><br />
            </div>
            <div class="modal-footer bg-white">
                <button type="button" class="btn btn-primary" (click)="statusUpdate(eCampaignItemStatusAction?.SkipEvent);" data-dismiss="modal">Ok</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="removeCampaignModal" tabindex="-1" role="dialog" aria-labelledby="removeCampaignModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>Remove from all checked campaigns?</h2><br />
            </div>
            <div class="modal-footer bg-white">
                <button type="button" class="btn btn-primary" (click)="statusUpdate(eCampaignItemStatusAction?.RemoveFromCampaign);" data-dismiss="modal">Ok</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
