<form class="form-horizontal required-section" [formGroup]="noteForm" (ngSubmit)="noteFormSubmit()">
    <div class="wraper-main-section">
        <div class="global-body-section">
            <div class="wraper-body-panel">
                <div class="wraper-body-left">
                    <div class="cards-body-section">
                        <div class="cards-colunm-left">
                            <span class="control-label" for="dateTime">Date/Time</span>
                        </div>
                        <div class="cards-colunm-right">
                            <kendo-datetimepicker placeholder="" formControlName="dtSent" [ngClass]="{'has-error': noteForm.controls.dtSent.errors && (noteForm.controls.dtSent.touched || noteForm.controls.dtSent.dirty)}"></kendo-datetimepicker>
                            <div *ngIf="noteForm.controls.dtSent.errors && (noteForm.controls.dtSent.touched || noteForm.controls.dtSent.dirty)">
                                <div class="login-error" *ngIf="noteForm.controls.dtSent.errors.required">Please enter date </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isEmail">
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span class="control-label">To</span>
                            </div>
                            <div class="cards-colunm-right">
                                <div class="email-form-group align-div" style="margin:0px;">
                                    <label class="k-label ml-0" [for]="1">Contact</label>
                                    <input #1 type="radio" [value]="1" name="toChoice" formControlName="toChoice" (change)="changeToType('contact')" kendoRadioButton />
                                    <label class="k-label" [for]="2">&nbsp;User</label>
                                    <input #2 type="radio" [value]="2" name="toChoice" formControlName="toChoice" (change)="changeToType('user')" kendoRadioButton />
                                    <label class="k-label" [for]="3">&nbsp;Other</label>
                                    <input #3 type="radio" [value]="3" name="toChoice" formControlName="toChoice" (change)="changeToType('other')" kendoRadioButton />
                                </div>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span class="control-label">To</span>
                            </div>
                            <div class="cards-colunm-right">
                                <div class="to-title" *ngIf="noteForm.controls.toChoice.value == eNoteEmailToChoice.Contact">
                                    <span>Contact</span>
                                </div>
                                <div class="generic-width-per" *ngIf="noteForm.controls.toChoice.value == eNoteEmailToChoice.User">
                                    <select class="form-control" formControlName="toField">
                                        <option value="-1">-Select-</option>
                                        <option *ngFor="let user of emailDropDownsResponse?.userToList" [value]="user?.key">{{user?.value}}</option>
                                    </select>
                                </div>
                                <div *ngIf="noteForm.controls.toChoice.value == eNoteEmailToChoice.Other" class="generic-width-per">
                                    <div class="" [ngClass]="{'has-error': noteForm.controls.toField.errors && (noteForm.controls.toField.touched ||  noteForm.controls.toField.dirty)}">
                                        <input type="text" class="form-control" formControlName="toField" />
                                    </div>
                                    <div class="search-query-error generic-text-align" *ngIf="noteForm.controls.toField.errors && ( noteForm.controls.toField.touched ||  noteForm.controls.toField.dirty)">
                                        <div *ngIf="noteForm.controls.toField.errors.required">Please enter comma delimited list of email addresses to send this email to.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span class="control-label">From</span>
                            </div>
                            <div class="cards-colunm-right">
                                <span *ngIf="userData?.cLPUserID == note?.cLPUserID">{{userData?.firstName}}&nbsp;{{userData?.lastName}}&nbsp;({{userData?.userName}})</span>
                                <span *ngIf="userData?.cLPUserID != note?.cLPUserID && note?.status != eNoteStatus.Pending">{{userData?.firstName}}&nbsp;{{userData?.lastName}}&nbsp;({{userData?.email}})</span>
                                <div class="to-title" *ngIf="userData?.cLPUserID != note?.cLPUserID && note?.status == eNoteStatus.Pending">
                                    <select class="form-control" formControlName="cLPUserID" *ngIf="isShowDD(note?.cLPUserID)">
                                        <option value="-1">-Select-</option>
                                        <option *ngFor="let user of emailDropDownsResponse?.userToList" [value]="user?.key">{{user?.value}}</option>
                                    </select>
                                    <span *ngIf="!isShowDD(note?.cLPUserID)">{{userData?.firstName}}&nbsp;{{userData?.lastName}}&nbsp;({{userData?.userName}})</span><br />
                                    <span *ngIf="!isShowDD(note?.cLPUserID)" class="red-contact-span">Caution: If you make changes to this email,the <i>From</i> address will change to&nbsp;{{userData?.firstName}}&nbsp;{{userData?.lastName}}&nbsp;({{userData?.userName}})</span><br />
                                </div>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span class="control-label">CC</span>
                            </div>
                            <div class="cards-colunm-right">
                                <input type="text" formControlName="cCField" class="form-control" />
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span class="control-label">BCC</span>
                            </div>
                            <div class="cards-colunm-right">
                                <input type="text" formControlName="bCCField" class="form-control" />
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span class="control-label">Mail Merge Template</span>
                            </div>
                            <div class="cards-colunm-right">
                                <select class="form-control" formControlName="mailMergeTemplateID">
                                    <option value=0>-None Selected-</option>
                                    <option *ngFor="let item of emailDropDownsResponse?.mailMergeTemplates" [value]="item?.mailMergeTemplateID">{{ item?.templateName }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span class="control-label">Attached Files</span>
                            </div>
                            <div class="cards-colunm-right">
                                <div class="full-width-container">
                                    <div class="message-info">{{documentList?.length > 0 ? documentList?.length : "No"}} file(s) Attached</div>
                                    <div class="document-list" *ngFor="let item of documentList;">
                                        <div class="cal-icon">
                                            <a (click)="downloadDocuments(item?.documentId)" title="Download Document"> <img src="../../../../assets/iconimagequick.svg"></a>
                                        </div>
                                        <div class="contact-details">
                                            <div class="contact-name"><a class="text-primary" title="Download Document" (click)="downloadDocuments(item?.documentId)"> {{item?.documentName}} </a></div>
                                        </div>
                                        <div class="cal-icon">
                                            <button type="button" class="grid-delete-btn" data-toggle="modal" data-target="#deleteDocumentModal" (click)="deleteDocument(item?.documentId)">
                                                <i class="fa fa-trash-alt" title="Delete"></i>
                                                <span class="grid-common-text"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span class="control-label">HTML Template</span>
                            </div>
                            <div class="cards-colunm-right">
                                <select class="form-control" formControlName="emailTemplateID">
                                    <option value=0>-None Selected-</option>
                                    <option *ngFor="let item of emailDropDownsResponse?.emailTemplates" [value]="item?.emailTemplateID">{{ item?.templateName }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span class="control-label">Email Snippet</span>
                            </div>
                            <div class="cards-colunm-right">
                                <select class="form-control" formControlName="emailSnippetID" (change)="onChangeEmailSnippet($event.target.value)">
                                    <option value="0">-None Selected-</option>
                                    <option *ngFor="let item of emailDropDownsResponse?.emailSnippets" [value]="item?.emailSnippetID">{{ item?.snippetTitle }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span class="control-label" for="subject">Subject</span>
                            </div>
                            <div class="cards-colunm-right">
                                <div class="full-width-container" [ngClass]="{'has-error': noteForm.controls.noteSubject.errors && (noteForm.controls.noteSubject.touched ||  noteForm.controls.noteSubject.dirty)}">
                                    <input type="text" id="noteSubject" class="form-control" formControlName="noteSubject" placeholder="Note subject" />
                                </div>
                                <div class="search-query-error" *ngIf="noteForm.controls.noteSubject.errors && ( noteForm.controls.noteSubject.touched ||  noteForm.controls.noteSubject.dirty)">
                                    <div *ngIf="noteForm.controls.noteSubject.errors.required">Please enter a subject.</div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span class="control-label">Note Type</span>
                            </div>
                            <div class="cards-colunm-right">
                                <select class="form-control" formControlName="noteTypeCode">
                                    <option value="null">-None Selected-</option>
                                    <option *ngFor="let noteCode of noteTypeCodes" [value]="noteCode?.noteTypeCode">{{ noteCode?.display }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span class="control-label" for="note">Note</span>
                            </div>
                            <div class="cards-colunm-right">
                                <div *ngIf="companyData?.isHTMLEmailIncluded && note?.emailTemplateID > 0">
                                    <kendo-editor formControlName="noteDesc" style="height: 300px;">
                                        <kendo-toolbar>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                                            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                                            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                                            <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                                            <kendo-toolbar-colorpicker kendoEditorBackColor
                                                                       view="gradient"></kendo-toolbar-colorpicker>
                                            <kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                                            </kendo-toolbar-buttongroup>
                                            <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
                                            <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                                        </kendo-toolbar>
                                    </kendo-editor>
                                </div>

                                <div class="form-group full-width-container" *ngIf="note?.emailTemplateID == 0 || noteId == 0">
                                    <div [ngClass]="{'has-error': noteForm.controls.noteDesc.errors && (noteForm.controls.noteDesc.touched ||  noteForm.controls.noteDesc.dirty)}">
                                        <kendo-editor formControlName="noteDesc" style="height: 300px;" placeholder="Note">
                                            <kendo-toolbar>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                                                <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                                                <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                                                <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                                                <kendo-toolbar-colorpicker kendoEditorBackColor
                                                                           view="gradient"></kendo-toolbar-colorpicker>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorCreateLinkButton></kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-button kendoEditorInsertFileButton></kendo-toolbar-button>
                                                <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                                            </kendo-toolbar>
                                        </kendo-editor>
                                        <!--<textarea id="note" class="form-control contact-text-auto-height" row="4" cols="1" formControlName="noteDesc" placeholder="Note"></textarea>-->
                                    </div>
                                    <div class="search-query-error" *ngIf="noteForm.controls.noteDesc.errors && ( noteForm.controls.noteDesc.touched ||  noteForm.controls.noteDesc.dirty)">
                                        <div *ngIf="noteForm.controls.noteDesc.errors.required && this.note.toChoice == 0">Please describe the history item.</div>
                                        <div *ngIf="noteForm.controls.noteDesc.errors.required && (note.toChoice == 1 || note.toChoice == 2 || note.toChoice == 3) && !companyData.isHTMLEmailIncluded">Please enter the <i>Body</i> of the email.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left"></div>
                            <div class="cards-colunm-right">
                                <button class="btn btn-primary" [disabled]="showSpinner" type="submit">
                                    <div *ngIf="buttonTypeOperation!=1">
                                        <span>Save<app-loader></app-loader></span>

                                    </div>
                                </button>
                                <button class="btn btn-cancel" (click)="cancelNote()" type="button">Cancel </button>
                                <button class="btn btn-danger" *ngIf="noteId > 0" data-toggle="modal" data-target="#modalDeleteNote" type="button">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wraper-body-right"></div>
            </div>
        </div>
    </div>
</form>

<!--Delete note model-->
<div class="modal fade" id="modalDeleteNote" aria-labelledby="exampleModalLabel">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Note Delete</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>Caution: This history entry will be permanently deleted.</h2>
                <h2>Are you sure you wants to delete this entry?</h2>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="deleteNote();" data-dismiss="modal" class="btn btn-primary">Yes</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="hideDeleteNote()">No</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteDocumentModal" tabindex="-1" role="dialog" aria-labelledby="deleteDocumentModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
            </div>
            <div class="modal-body modal-common-body">
                <h5>Are you sure to delete this document? </h5>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="deleteDocumentConfirm()" data-dismiss="modal" class="btn btn-primary">Confirm</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
