import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { isNullOrUndefined } from 'util';

import { filterAnimation, pageAnimations } from '../../../../animations/page.animation';

import { CLPUser } from '../../../../models/clpuser.model';
import { ContactFields } from '../../../../models/contact.model';
import { TextMsgFilters, TextMsgFiltersResponse } from '../../../../models/textMsgFilters.model';

import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { OutBoundTextMsgService } from '../../../../services/outBoundTextMsg.service';
import { NotificationService } from '../../../../services/notification.service';
import { TxtMsg, TxtMsgResponse, TxtMsgSettingLoadResponse, TxtMsgSettingsResponse } from '../../../../models/txtMsg.model';
import { ContactService } from '../../../../services/contact.service';
import { ContactSearchService } from '../../../../services/shared/contact-search.service';
import { DatePipe } from '@angular/common';
import { TxtMsgService } from '../../../../services/textmsg.service';
import { TxtMsgSettings } from '../../../../models/txtMsg.model';
import { LiveConnectService } from '../../../../services/live-connect.service';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { CampaignService } from '../../../../services/campaign.service';
import { eCampaignStatus, eCampaignTemplateOwnerType } from '../../../../models/enum.model';
import { GlobalService } from '../../../../services/global.service';
import { TextMsgTemplateService } from '../../../../services/text-msg-template.service';

@Component({
    selector: 'app-contact-sms',
    templateUrl: './contact-sms.component.html',
    styleUrls: ['./contact-sms.component.css'],
    animations: [pageAnimations, filterAnimation]
})
/** contact-sms component*/
export class ContactSmsComponent implements OnInit {
    //Animation
    @HostBinding('@pageAnimations') public animatePage = true;
    showAnimation = -1;
    //Animation
    /** contact-sms ctor */
    @Input() loggedUser: CLPUser;
    @Input() contactId: number;
    @Input() textMsgStatus: number;
    @Input() txtMsgID: number;
    @Input() txtdtSend: Date
    public min: Date = new Date();

    contactFields: ContactFields;
    textMsgFiltersResponse: TextMsgFiltersResponse;
    txtMsgFilters: TextMsgFilters;
    txtMsg: TxtMsg;
    private encryptedUser: string = '';
    public hasError: boolean = false;
    toChoiceDropDown: any[] = [];
    templateFilter: any[] = [];
    imageFilter: any[] = [];
    videoFilter: any[] = [];
    smsForm: FormGroup;
    showDatePicker: boolean = false;
    public datePickerformat = "yyyy-MM-dd HH:mm a";
    textStatusMessage: string = ''
    isTextSend: boolean = false;
    @Output() updatedTextMsg = new EventEmitter<boolean>();
    txtMsgSettingsData: TxtMsgSettings;
    contactNumber: string;
    showSpinner: boolean;
    txtMsgSettings: TxtMsgSettings;
    defaultDateObject: { date?: Date }
    textConsent: boolean = true
    txtMsgLoad: TxtMsg;


    constructor(private fb: FormBuilder,
        private _router: Router,
        private _utilityService: UtilityService,
        private _outBoundTextMsgService: OutBoundTextMsgService,
        public _localService: LocalService,
        public _contactService: ContactService,
        public _contactSearchService: ContactSearchService,
        public notifyService: NotificationService,
        private datepipe: DatePipe,
        private _txtMsgSettingSrvc: TxtMsgService,
        private _globalService: GlobalService,
        private textMessageService: TextMsgTemplateService,
        private _liveConnectSrvc: LiveConnectService,
        private _campaignService: CampaignService,
    ) {
    }

    ngOnInit() {
        this.smsForm = this.prepareSmsForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                if (this.loggedUser) {
                    const dateObj = { date: new Date() };
                    this.defaultDateObject = this.parseObjectDates(dateObj);
                    this.smsForm.controls.datePickerValue.patchValue(this.defaultDateObject.date)
                    this.getTextStatus(this.textMsgStatus);
                    this.getTxtMsgSettings()
                    if (!isNullOrUndefined(this._localService.contactFields) && (this._localService.contactFields.contactID.fieldValue == this.contactId))
                        this.loadInitData();
                    else
                        this.getContactFields(this.contactId, this.loggedUser.cLPCompanyID, this.loggedUser.cLPUserID);
                    this.getTextActivity(this.txtMsgID, this.textMsgStatus, this.txtdtSend)
                }
            }
            else
                this._router.navigate(['/unauthorized']);
        });
    }

    public parseObjectDates(target: any): any {
        const result = Object.assign({}, target);
        Object.keys(result).forEach((key) => {
            const date = new Date(result[key]);
            if (!isNaN(date.getTime())) {
                result[key] = date;
            }
        });
        return result;
    }

    getTextStatus(textMsgStatus: number) {
        switch (textMsgStatus) {
            case - 1:
                this.textStatusMessage = "Unknown"
                break;
            case 0: this.textStatusMessage = "Completed"
                break;
            case 1: this.textStatusMessage = "Pending"
                break;
            case 2: this.textStatusMessage = "InProgress"
                break;
            case 3: this.textStatusMessage = "Sent"
                break;
            case 4: this.textStatusMessage = "CheckInProgress"
                break;
            case 5: this.textStatusMessage = "Failed"
                break;
            case 6: this.textStatusMessage = "Responded"
                break;
            case 7: this.textStatusMessage = "Paused"
                break;
            case 8: this.textStatusMessage = "Cancelled"
                break;
            case 9: this.textStatusMessage = "ToBeDeleted"
                break;
        }
    }

    private prepareSmsForm(): FormGroup {
        return this.fb.group({
            from: new FormControl(0),
            to: new FormControl(-1),
            template: new FormControl(-1),
            mediaUrl: new FormControl(''),
            image: new FormControl(''),
            message: new FormControl('', [Validators.required]),
            datePickerValue: new FormControl(''),
        });
    }

    get smsFrm() {
        return this.smsForm.controls;
    }

    getContactFields(contactId, companyID, userId) {
        this._localService.getContactFields(this.encryptedUser, contactId, companyID, userId, true)
            .subscribe((value) =>
                this.loadInitData()
            );
    }

    loadInitData() {
        this.contactFields = this._localService.contactFields;
        this.getTextMsgFilters();
    }
    onCloseSms() {
        this._localService.hideCommonComponentEmit('sms');
        this._localService.showCommonComp = '';
        this.smsForm.reset();
        this.updatedTextMsg?.emit(false);
    }

    getTextMsgFilters() {
        this.hasError = false;
        const contactId = this.contactFields?.contactID.fieldValue ? this.contactFields?.contactID.fieldValue : 0;
        this._outBoundTextMsgService.getTextMsgFilters(this.encryptedUser, this.loggedUser?.cLPCompanyID, this.loggedUser?.cLPUserID, contactId)
            .then(async (result: TextMsgFiltersResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.textMsgFiltersResponse = UtilityService.clone(result);
                    this.txtMsgFilters = this.textMsgFiltersResponse?.txtMsgFilters;
                    this.contactNumber = this.txtMsgFilters.messageInt.toString();
                    if (this.txtMsgFilters && !this.txtMsgFilters?.messageBool && this.txtMsgFilters?.messageString) {
                        this.hasError = true;
                        return;
                    }
                    this.toChoiceDropDown = this.txtMsgFilters && this.txtMsgFilters?.toChoiceDropDown ? this.txtMsgFilters?.toChoiceDropDown : [];
                    const choiceContact = this.toChoiceDropDown.filter(x => x.key == this.contactId)[0];
                    this.smsForm.patchValue({ to: choiceContact.key });
                    this.templateFilter = this.txtMsgFilters && this.txtMsgFilters?.templateFilter ? this.txtMsgFilters?.templateFilter : [];
                    this.imageFilter = this.txtMsgFilters && this.txtMsgFilters?.imageFilter ? this.txtMsgFilters?.imageFilter : [];
                    this.videoFilter = this.txtMsgFilters && this.txtMsgFilters?.videoFilter ? this.txtMsgFilters?.videoFilter : [];
                    //this.patchSmsFormValues();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-sms.getTextMsgFilters", err.message, null,
                    'cLPCompanyID: ' + this.loggedUser?.cLPCompanyID
                    + ' cLPUserID: ' + this.loggedUser?.cLPUserID
                    + ' contactId: ' + contactId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    patchSmsFormValues() {
        if (this.toChoiceDropDown.length > 0) {
            const choiceContact = this.toChoiceDropDown.find(x => x.key === this.txtMsgLoad?.contactID);
            this.smsForm.patchValue({ to: choiceContact.key });
        }
        this.smsForm.patchValue({
            from: 0,
            template: -1,
            mediaUrl: this.txtMsgLoad.mediaURL,
            message: this.txtMsgLoad.msg,

        });
    }

    smsFormSubmit() {
        this._localService.validateAllFormFields(this.smsForm);
        if (this.smsForm.valid) {
            this.smsForm.markAsPristine();
            this.sendSms();
        }
    }

    async sendSms() {
        //const dateResult = this.onDateChange();
        //if (dateResult) {
        //  this.notifyService.showError("Time should be greater than 5 min", "", 3000);
        //  return;
        //}
        this.copySmsFormToDataObject();
        this.isTextSend = true;
        this.txtMsg.cLPUserID = this.loggedUser?.cLPUserID;
        this.txtMsg.contactID = this.contactFields.contactID.fieldValue;

        this._outBoundTextMsgService.sendMessage(this.encryptedUser, this.txtMsg)
            .then(async (result: TxtMsgResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (!response?.messageBool && response?.messageString != '')
                        this.notifyService.showError(response?.messageString ? response?.messageString : 'Error: please try again.', "", 5000);
                    else {
                        this.notifyService.showSuccess(response?.messageString ? response?.messageString : this.txtMsg?.isScheduled ? "Text message scheduled successfully." : "Text message sent successfully.", "", 5000);
                        //this.smsForm.reset();
                        //this.patchSmsFormValues();
                        this.updatedTextMsg?.emit(false);
                        this._localService.handledEventEmit(true);
                    }
                }
                this.isTextSend = false;

            })
            .catch((err: HttpErrorResponse) => {
                this.isTextSend = false;
                this._globalService.error("contact-sms.sendSms", err.message, null,
                    'txtMsg: ' + this.txtMsg
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    copySmsFormToDataObject() {
        this.txtMsg = <TxtMsg>{};
        this.txtMsg.cLPCompanyID = this.loggedUser?.cLPCompanyID;
        this.txtMsg.cLPUserID = this.loggedUser?.cLPUserID;

        if ((this.smsForm.controls.to.value == 0) || (this.smsForm.controls.to.value == '') || ((this.smsForm.controls.to.value) == (this.contactFields.contactID.fieldValue))) {
            this.txtMsg.isToUser = false;
            this.txtMsg.mobileNumber = this.contactFields ? this.contactFields.mobile.fieldValue : '';
            this.txtMsg.toCLPUserID = 0;
            this.txtMsg.msg = this.smsForm.controls.message.value ? this.smsForm.controls.message.value : '';
        }
        else {
            this.txtMsg.isToUser = true;
            const selectedToChoiceUser = this.toChoiceDropDown.find(x => x.key == this.smsForm.controls.to.value);
            const splitValue = selectedToChoiceUser ? selectedToChoiceUser.value.split(':', 4) : null;
            this.txtMsg.mobileNumber = splitValue ? splitValue[1].trim() : '';
            this.txtMsg.toCLPUserID = this.smsForm.controls.to.value ? this.smsForm.controls.to.value : 0;
            this.txtMsg.msg = this.smsForm.controls.message.value ? this.smsForm.controls.message.value : '';
        }
        this.txtMsg.contactID = this.contactFields.contactID.fieldValue;
        this.txtMsg.countryCode = '1';
        this.txtMsg.isUseShortCode = false;
        this.txtMsg.mediaURL = this.smsForm.controls.mediaUrl.value ? this.smsForm.controls.mediaUrl.value : '';
        this.txtMsg.imgID = this.smsForm.controls.image.value ? this.smsForm.controls.image.value : 0;
        this.txtMsg.status = 1; //Pending
        this.txtMsg.requestID = 'default';
        this.txtMsg.requestComment = '';
        this.txtMsg.campaignID = 0;
        this.txtMsg.campaignEventID = 0;
        this.txtMsg.isThrottle = false;

        if (this.showDatePicker && this.smsForm.controls.datePickerValue.value && this.smsForm.controls.datePickerValue.value != '') {
            this.txtMsg.isScheduled = true;
            this.txtMsg.dtSend = this.datepipe.transform(this.smsForm.controls.datePickerValue.value ? this.smsForm.controls.datePickerValue.value : new Date(), 'MM/dd/yyyy HH:mm:ss');
        }
        else {
            this.txtMsg.isScheduled = false;
            this.txtMsg.dtSend = this.datepipe.transform(new Date(), 'MM/dd/yyyy HH:mm:ss');
        }

        this.txtMsg.fromNumber = this.txtMsgFilters.fromFilter.find(x => x.id == this.smsForm.controls.from.value)?.id.toString();
    }

    onImageDropChange() {
        const selectedDocId = this.smsForm.controls.image.value;
        const selectedImgObj = this.imageFilter.find(i => i.documentId == selectedDocId);
        if (!isNullOrUndefined(selectedImgObj))
            this.smsForm.patchValue({ mediaUrl: selectedImgObj?.imagePath });
    }

    onVideoDropChange() {
        const selectedDocId = this.smsForm.controls.image.value;
        const selectedImgObj = this.videoFilter.find(i => i.documentId == selectedDocId);
        if (!isNullOrUndefined(selectedImgObj))
            this.smsForm.patchValue({ mediaUrl: selectedImgObj?.imagePath });
    }

    onTemplateChange() {
        const selectedTemplateId = this.smsForm.controls.template.value;
        const selectedTemplateObj = this.templateFilter.find(i => i.key == selectedTemplateId);
        if (!isNullOrUndefined(selectedTemplateObj)) {
            this.smsForm.patchValue({ message: selectedTemplateObj?.messageString ? selectedTemplateObj?.messageString : '' });
            this.smsForm.patchValue({ mediaUrl: selectedTemplateObj?.messageString2 ? selectedTemplateObj?.messageString2 : '' });
        }
    }

    onDateChange() {
        const selectedDate: Date = new Date(this.smsForm.controls.datePickerValue.value);
        if ((selectedDate?.getFullYear() == new Date().getFullYear()) && (selectedDate?.getTime() < new Date().getTime() + 300000)) return true; //5 min add
        else return false;
    }

    addText(value: string) {
        this.smsForm.get('message').patchValue(this.smsForm.controls.message.value + ' ' + value);
    }

    async getTxtMsgSettings() {
        await this._txtMsgSettingSrvc.txtMsgSettingsGetById(this.encryptedUser, this.loggedUser.cLPCompanyID)
            .then(async (result: TxtMsgSettingsResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this.txtMsgSettingsData = response?.txtMsgSettings;
                }

            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-sms.getTxtMsgSettings", err.message, null,
                    'cLPCompanyID: ' + this.loggedUser.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
            });

    }

    onOptinVerbal() {
        this._liveConnectSrvc.contactOptOutOptIn(this.encryptedUser, this.loggedUser?.cLPUserID, 0, this.contactId, false)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.loadInitData()
                    } else {
                        this.notifyService.showError(result?.errorMsg, "", 3000);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-sms.onOptinVerbal", err.message, null,
                    'cLPUserID: ' + this.loggedUser?.cLPUserID
                    + ' contactId: ' + this.contactId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    txtMsgMarkAsCancel() {
        this._txtMsgSettingSrvc.txtMsgMarkAsCancel(this.encryptedUser, this.txtMsgID)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.notifyService.showError("Text Message has been cancelled an will not be sent! ", "", 3000);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-sms.txtMsgMarkAsCancel", err.message, null,
                    'txtMsgID: ' + this.txtMsgID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async onSendOptInRqst() {
        await this.txtMsgSettingLoadByCompany()
        if (this.contactId > 0 && this.txtMsgSettings?.optinAPID) {
            this.processBatchTrigger(eCampaignStatus.Active, this.txtMsgSettings.optinAPID, eCampaignTemplateOwnerType.Contact)
        }
    }

    async txtMsgSettingLoadByCompany() {
        this.showSpinner = true;
        return this._txtMsgSettingSrvc.txtMsgSettingLoadByCompany(this.encryptedUser, this.loggedUser?.cLPCompanyID)
            .then(async (result: TxtMsgSettingLoadResponse) => {
                if (result) {
                    this.txtMsgSettings = UtilityService.clone(result?.txtMsgSettings);
                    this.showSpinner = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-sms.txtMsgSettingLoadByCompany", err.message, null,
                    'cLPCompanyID: ' + this.loggedUser?.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });

    }

    async processBatchTrigger(eAction: eCampaignStatus, campaignTemplateID: number, eItemType: eCampaignTemplateOwnerType = eCampaignTemplateOwnerType.Contact) {
        this.showSpinner = true;
        return this._campaignService.processBatchTrigger(this.encryptedUser, this.loggedUser?.cLPCompanyID, eAction, campaignTemplateID, this.loggedUser?.cLPUserID, this.contactId, eItemType)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.notifyService.showSuccess("Contact has been added to Opt-In Request automation process.", 'Success', 5000)
                    this.onCloseSms()
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-sms.processBatchTrigger", err.message, null,
                    'cLPCompanyID: ' + this.loggedUser?.cLPCompanyID
                    + ' eAction: ' + eAction
                    + ' campaignTemplateID: ' + campaignTemplateID
                    + ' contactId: ' + this.contactId
                    + ' eItemType: ' + eItemType
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getTextActivity(txtmsgId: number, txtMsgStatus, txtdtSend) {
        this.showSpinner = true
        if (txtmsgId > 0) {
            this.textMessageService.TxtxMsgLoad(this.encryptedUser, txtmsgId)
                .then(async (result: TxtMsg) => {
                    if (result && result.contactID) {
                        this.txtMsgLoad = result
                        this.showSpinner = false
                        this.patchSmsFormValues()
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("contact-activity-history.getTextActivity", err.message, null,
                        'txtmsgId: ' + txtmsgId
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    createNewText() {
        this.smsForm = this.prepareSmsForm();
        this.isTextSend = false;
    }
}
