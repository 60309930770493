<div class="custom-security-link" *ngIf=" (isNewTaskUser || isShowParamTask) && isShowTaskGrid ">
  <a [routerLink]="['/calender']" routerLinkActive="active">My Calendar</a>
  <a [routerLink]="user?.cLPCompanyID === 1226  ? ['/call-ih'] : ['/call']" routerLinkActive="active">My Call List</a>
  <a (click)="showTaskFilter=!showTaskFilter">{{ !showTaskFilter ? 'Show Filters' : 'Hide Filters' }}</a>
</div>
<!-- For Show filter -->
<div class="margin-all-area" *ngIf="showTaskFilter">
  <div class="wraper-body-panel mt-3">
    <div class="wraper-body-left">
      <div class="wraper-main-section">
        <div class="global-card-section">
          <div class="common-inner-header">
            <div class="inner-header-bg">
              <div class="inner-cards-panel header-inner-hidden">
                <span>User</span>
                <span>Start Date</span>
                <span>End Date</span>
                <span>Action</span>
              </div>
              <div class="inner-detail-bg">
                <span>Details</span>
              </div>
            </div>
          </div>
          <div class="common-inner-cards">
            <div>
              <div class="inner-cards-grid">
                <div class="inner-card-mobile">
                  <label>User</label>
                  <div class="mobile-view-design">
                    <select class="form-control" [(ngModel)]="filterUser" *ngIf="user?.userRole >=2">
                      <option *ngFor="let item of userList; let i= index;" [value]="item?.value">{{ item?.text }}</option>
                    </select>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <label class="font-weight-bold">Start Date</label>
                  <div class="mobile-view-design">
                    <kendo-datepicker [(ngModel)]="startDate"></kendo-datepicker>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <label class="font-weight-bold">End Date</label>
                  <div class="mobile-view-design">
                    <kendo-datepicker [(ngModel)]="endDate"></kendo-datepicker>
                  </div>
                </div>
                <div class="padding5">
                  <label class="font-weight-bold">Action</label>
                  <button type="button" class="grid-common-btn" (click)="getTaskList()">
                    <i class="fa fa-refresh"></i>
                    <span class="grid-common-text">Refresh</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wraper-body-right"></div>
  </div>
</div>

<div class="margin-all-area" *ngIf="!isCalenderTask && taskIdFromDashboard == 0">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../../../assets/activity/task/task_title.svg" class="mr-1" /> {{taskGridHeaderName}}</div>
        <div class="header-button-panel" *ngIf="tasks.length!=0">
          <div class="button-wrapper" *ngIf="isAddButtonVisible && !isLeadTask && !isContactTask && (!isNewTaskUser || !isShowParamTask)">
            <button *ngIf="!isFromCoachCorner" (click)="toogleCheckAllAppt()" class="icon-btn" type="button" matTooltip="Check All" data-toggle="modal">
              <i class="fa fa-check-square " aria-hidden="true"></i>
              <span class="btn-text">Check All</span>
            </button>
            <button class="icon-btn" type="button" matTooltip="Edit multiple task" data-toggle="modal" *ngIf="!isEditTask && !isFromCoachCorner" (click)="cancelEditTask('showEdit');">
              <i class="fa-solid fa-pen-to-square" aria-hidden="true"></i>
              <span class="btn-text">Edit Task</span>
            </button>
            <span *ngIf="isEditTask" class="button-wrapper">
              <button class="icon-btn" type="button" matTooltip="Cancel multiple tasks edit" (click)="cancelEditTask('hideEdit');">
                <i class="fa fa-times" aria-hidden="true"></i>
                <span class="btn-text">Cancel</span>
              </button>
              <button class="icon-btn" type="button" matTooltip="Marked  all checked tasks as Pending" (click)="taskStatusBulkUpdate('Pending')" data-toggle="modal" data-target="#bulkTaskUpdate">
                <i class="fa fa-star" aria-hidden="true"></i>
                <span class="btn-text">Pending</span>
              </button>
              <button class="icon-btn" type="button" matTooltip="Marked  all checked tasks as On Hold" (click)="taskStatusBulkUpdate('On Hold')" data-toggle="modal" data-target="#bulkTaskUpdate">
                <i class="fa-solid fa-pause" aria-hidden="true"></i>
                <span class="btn-text">On Hold</span>
              </button>
              <button class="icon-btn" type="button" matTooltip="Marked  all checked tasks as Completed" (click)="taskStatusBulkUpdate('Completed')" data-toggle="modal" data-target="#bulkTaskUpdate">
                <img src='../../../../assets/btnmarkcompleted_md.svg' />
                <span class="btn-text">Completed</span>
              </button>
            </span>
          </div>
        </div>
        <div class="header-button-panel">
          <div class="button-wrapper" *ngIf="isFromCoachCorner">
            <select class="form-control" (change)="filterByStatus($event.target.value)">
              <option [value]="3">-All-</option>
              <option [value]="0" selected>Pending Only</option>
              <option [value]="1">Completed Only</option>
              <option [value]="2">On Hold Only</option>
            </select>
            <select class="form-control" (change)="filterByCategory($event.target.value)">
              <option [value]="0">-All-</option>
              <option [value]="2">Contact Only</option>
              <option [value]="3">Lead  Only</option>
              <option [value]="1">Personal Only</option>
            </select>
            <span [hidden]="isShowParamTask" *ngIf="isAddButtonVisible && !isFromCoachCorner">
              <button #addBtn class="btn btn-primary margin-left10" (click)="addNewHandler()" data-toggle="collapse" data-target="#taskForm" *ngIf="isShowTaskGrid"> Add New</button>
            </span>
          </div>
          <div class="button-wrapper" *ngIf="!isFromCoachCorner">
            <select class="form-control" (change)="onTaskStatusFilter($event.target.value, 'Status')" *ngIf="!isEditTask">
              <option [value]="3">-All-</option>
              <option [value]="0" selected>Pending Only</option>
              <option [value]="1">Completed Only</option>
              <option [value]="2">On Hold Only</option>
            </select>
            <select class="form-control" *ngIf="!isLeadTask && !isContactTask && !isEditTask" (change)="onTaskStatusFilter($event.target.value, 'Category')">
              <option [value]="0">-All-</option>
              <option [value]="2">Contact Only</option>
              <option [value]="3">Lead  Only</option>
              <option [value]="1">Personal Only</option>
            </select>
            <span [hidden]="isShowParamTask" *ngIf="isAddButtonVisible && !isFromCoachCorner">
              <button #addBtn class="btn btn-primary margin-left10" (click)="addNewHandler()" data-toggle="collapse" data-target="#taskForm" *ngIf="isShowTaskGrid"> Add New</button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="margin-all-area">
  <p class="message-info" *ngIf="taskFormStatus=='cancel'">No changes were made.</p>
  <p class="message-info" *ngIf="taskFormStatus=='saved'">Task created successfully.</p>
</div>
<div class="create-email" [@filterAnimation]="showAnimation">
  <fieldset>
    <!--for ui issue 30sep issue 10-->
    <!--<legend *ngIf="!isCalenderTask && isShowTaskGrid">Create Task</legend>-->
    <div class="email-module">
      <div id="taskForm" class="collapse cancel-collapse" [@filterAnimation]="showAnimation">
        <form class="form-horizontal required-section" [formGroup]="taskForm" (ngSubmit)="taskFormSubmit()" *ngIf="user || loggedUser">
          <div class="lead-wraper-section">
            <div class="wraper-main-section">
              <div class="global-card-section">
                <div class="create-task-header">
                  <div class="global-header-section">
                    <div class="svg-icon-panel"> {{ !isFormEdit ? 'Create' : 'Update' }} Task</div>
                    <div class="header-button-panel">
                      <div class="button-wrapper">
                        <button type="button" *ngIf="taskIdFromDashboard == 0" (click)="onCloseTask();" class="icon-btn">
                          <i class="fas fa-times"></i>
                          <span class="btn-text">Close</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="global-body-section">
                  <div class="global-padding10">
                    <div class="wraper-body-panel">
                      <div class="wraper-body-left">
                        <div class="templates-alignment">
                          <div class="full-width-container">
                            <div class="form-group">
                              <span class="control-label" for="priority">Priority</span>
                              <div>
                                <select id="priority" class="form-control" formControlName="priority">
                                  <!--<option value="1" [selected]="true">Low</option>-->
                                  <option value="1">Low</option>
                                  <option value="2">Medium</option>
                                  <option value="3">High</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="full-width-container margin-left10">
                            <div class="form-group">
                              <span class="control-label" for="dueDate">Due Date</span>
                              <div class="" [ngClass]="{'has-error': taskFrm.dtDue.errors && (taskFrm.dtDue.touched ||  taskFrm.dtDue.dirty)}">
                                <kendo-datepicker placeholder="" formControlName="dtDue"></kendo-datepicker>
                              </div>
                              <div class="search-query-error" *ngIf="taskFrm.dtDue.errors && ( taskFrm.dtDue.touched ||  taskFrm.dtDue.dirty)">
                                <div *ngIf="taskFrm.dtDue.errors.required">Please Enter valid date.</div>
                              </div>
                            </div>
                          </div>
                          <div class="full-width-container margin-left10">
                            <div class="form-group">
                              <span class="control-label" for="hours">Hours</span>
                              <div>
                                <input id="hours" type="text" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)" class="form-control" formControlName="hours" maxlength="5">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="templates-alignment">
                          <div class="full-width-container">
                            <div class="form-group">
                              <span class="control-label" for="task">Task</span>
                              <div class="" [ngClass]="{'has-error': taskFrm.taskDesc.errors && (taskFrm.taskDesc.touched ||  taskFrm.taskDesc.dirty)}">
                                <textarea id="task" class="form-control contact-text-auto-height" row="1" cols="1" formControlName="taskDesc"></textarea>
                              </div>
                              <div class="search-query-error text-xs-left" *ngIf="taskFrm.taskDesc.errors && ( taskFrm.taskDesc.touched ||  taskFrm.taskDesc.dirty)">
                                <div *ngIf="taskFrm.taskDesc.errors.required">Please describe the task.</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="wraper-body-right margin-left10">
                        <div class="templates-alignment">
                          <div class="full-width-container">
                            <div class="form-group">
                              <span class="control-label" for="cost">Cost</span>
                              <div>
                                <input id="cost" type="text" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)" class="form-control" formControlName="cost" maxlength="5">
                              </div>
                            </div>
                          </div>
                          <div class="full-width-container margin-left10">
                            <div class="form-group">
                              <span class="control-label" for="status">Status</span>
                              <div>
                                <select id="status" class="form-control" formControlName="status">
                                  <option value="0">Pending</option>
                                  <option value="1">Completed</option>
                                  <option value="2">OnHold</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="full-width-container margin-left10">
                            <div class="form-group">
                              <span class="control-label" for="user">User</span>
                              <div>
                                <select id="user" class="form-control" formControlName="cLPUserID" *ngIf="users && users.length > 0">
                                  <option *ngFor="let user of users" [value]="user.key">{{user.value}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="templates-alignment">
                          <div class="full-width-container">
                            <div class="form-group">
                              <span class="control-label" for="status">Reminder</span>
                              <div class="message-center-section">
                                <div class="message-center-panel">
                                  <label class="k-label ml-0" [for]="messageCenter">Message Center </label>
                                  <input #messageCenter type="checkbox" name="messageCenter" formControlName="reminderCLP" kendoCheckBox />
                                </div>
                                <div class="message-center-panel">
                                  <label class="k-label ml-0" [for]="chkEmail"> Email </label>
                                  <input #chkEmail type="checkbox" name="type" formControlName="reminderEmail" kendoCheckBox />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="templates-alignment">
                      <div class="full-width-container">
                        <div class="bottom-panel">
                          <button class="btn btn-primary" [disabled]="isTaskSubmit" type="submit">
                            <ng-template [ngIf]="buttonTypeOperation!=1">{{ !isFormEdit ? 'Save' : 'Update' }}</ng-template>
                            <ng-template [ngIf]="buttonTypeOperation===1">{{ !isFormEdit ? 'Saving' : 'Updating' }}</ng-template>
                            <app-loader></app-loader>
                          </button>
                          <button #cancelButton class="btn btn-cancel" type="button" data-toggle="collapse" data-target=".cancel-collapse" aria-expanded="false" aria-controls="taskForm" (click)="onCancelButton();">Cancel</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="grid-wrapper mt-3" [hidden]="isShowParamTask || taskIdFromDashboard != 0">
        <kendo-grid class="create-task-grid"
                    *ngIf="!isCalenderTask && taskIdFromDashboard == 0"
          [kendoGridBinding]="tasks"
          [sortable]="true"
          [selectable]="{ checkboxOnly: true ,mode: 'multiple'}"
          [(selectedKeys)]="overTaskSelection"
          kendoGridSelectBy="taskID"
          [pageSize]="10"
          [pageable]="{buttonCount:10, pageSizes:[10,50,100,200]}">
          <kendo-grid-messages [pagerItemsPerPage]="'Items per page'" [pagerItems]="'Items'"></kendo-grid-messages>
          <kendo-grid-column field="index" title="" [width]="40">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{rowIndex+1}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="priority" title="Priority" [width]="80">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span [ngClass]="dataItem.priority == 1 ? 'text-success' : dataItem.priority == 2 ? 'text-warning' : 'text-danger'">{{ dataItem.priority == 1 ? 'Low' : dataItem.priority == 2 ? 'Medium' : 'High' }}</span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="taskDesc" title="Task"></kendo-grid-column>

          <kendo-grid-column title="" [width]="40">
            <ng-template kendoGridCellTemplate let-dataItem>
              <a class="grid-icon webkit-any-link" (click)="getTaskDocuments(dataItem?.taskID, dataItem?.cLPUserID);" title="{{dataItem?.isShowAttached ?'view attached files' :'attached files'}}">
                <span *ngIf="!dataItem?.isShowAttached" class="icon_contact"> <img src="../../../../../assets/activity/task/icontaskdocempty.svg" /></span>
                <span *ngIf="dataItem?.isShowAttached" class="icon_contact"> <img src="../../../../../assets/activity/task/icontaskdocfull.svg" /></span>
              </a>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="dtDue" title="Due Date" [width]="100">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.dtDue | date: dateFormat }}
            </ng-template>
          </kendo-grid-column>
          <ng-container *ngIf="isNewTaskUser || isShowParamTask || isFromCoachCorner">
            <kendo-grid-column field="type" title="Type" [width]="200">
              <ng-template kendoGridCellTemplate let-dataItem>
                <ng-container [ngSwitch]="dataItem.category">
                  <ng-container *ngSwitchCase="2">
                    <div class="grid-colunm-vertical">
                      <a class="grid-icon" (click)="goToLink('contact',dataItem)">
                        <span class="icon_contact" (click)="goToLink('contact',dataItem)">
                          <img src='../../../../../assets/icon_contact.svg' title="View Contact" />
                        </span>
                      </a>
                      <a title='View Contact' (click)="goToLink('contact',dataItem)"></a>
                      <a class="webkit-any-link" (click)="goToLink('contact',dataItem)">{{dataItem.contactName}}</a>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="3">
                    <div class="grid-colunm-vertical">
                      <a class="grid-icon" (click)="goToLink('lead',dataItem)">
                        <span class="icon_contact" (click)="goToLink('lead',dataItem)">
                          <img src='../../../../../assets/leadstitle.svg' title="View Lead" />
                        </span>
                      </a>
                      <a title='View Lead ' (click)="goToLink('lead',dataItem)"></a>
                      <a class="webkit-any-link" (click)="goToLink('lead',dataItem)">{{dataItem.ownerName}} ({{dataItem.contactName}})</a>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <a class="grid-icon">
                      <span class="icon_contact">
                        <img src='../../../../../assets/icon_user.svg' title="View Personal" />
                      </span>
                    </a>
                    <a title='View Personal '></a>
                  </ng-container>
                </ng-container>
              </ng-template>
            </kendo-grid-column>
          </ng-container>

          <kendo-grid-column field="hours" title="Hours" [width]="80"> </kendo-grid-column>

          <kendo-grid-column field="cost" title="Cost" [width]="80">
            <ng-template kendoGridCellTemplate let-dataItem> ${{ dataItem.cost }}</ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="status" title="Status" [width]="80">
            <ng-template kendoGridCellTemplate let-dataItem>
              <img *ngIf="dataItem.status == 0" src="../../../../assets/activity/main/statuspending.svg" class="status-img" />
              <img *ngIf="dataItem.status == 1" src="../../../../assets/activity/main/statuscompleted.svg" class="status-img" />
              <img *ngIf="dataItem.status == 2" src="../../../../assets/activity/main/statusonhold.svg" class="status-img" />
              <img *ngIf="dataItem.status == 3 || dataItem.status == 9" src="../../../../assets/activity/main/statuscancelled.svg" class="status-img" />
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="cLPUserID" title="User" [width]="100">
            <!--<ng-template kendoGridCellTemplate let-dataItem> <a class="webkit-any-link" (click)="goToLink('user', dataItem.cLPUserID);">{{ getUser(dataItem.cLPUserID)?.value }}</a> </ng-template>-->
            <ng-template kendoGridCellTemplate let-dataItem> <a class="webkit-any-link" (click)="goToLink('user', dataItem.cLPUserID);">{{dataItem?.userLastFirst }}</a> </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Reminder" [width]="140">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span *ngIf="dataItem.reminderCLP ">via</span> {{ dataItem.reminderCLP ? 'Message Center' : '' }}
              <span *ngIf="dataItem.reminderEmail">via</span>&nbsp;{{dataItem.reminderEmail ? 'Email' : ''}}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="" [width]="60" *ngIf="!isEditTask">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
              <a title="Edit task" (click)="taskGridCRUD('edit', dataItem, rowIndex);"><i class="fas fa-pencil-alt"></i></a>
              <a title="Delete task" (click)="taskGridCRUD('delete', dataItem, rowIndex);" *ngIf="isShowTaskGrid"><i class="fa fa-trash ml-2"></i></a>
            </ng-template>
          </kendo-grid-column>
          <div *ngIf="isEditTask">
            <kendo-grid-checkbox-column [width]="45"
              [headerClass]="{ 'text-center': true }"
              [class]="{ 'text-center': true }"
              [resizable]="false"
              [columnMenu]="false"
              [showSelectAll]="true">
              <ng-template kendoGridHeaderTemplate>
                <input type="checkbox"
                kendoCheckBox
                id="selectAllTaskCheckboxId"
                kendoGridSelectAllCheckbox
                (selectAllChange)="onSelectAllTaskChange($event)" />
                <label class="k-checkbox-label" for="selectAllTaskCheckboxId"></label>
              </ng-template>
            </kendo-grid-checkbox-column>
          </div>
        </kendo-grid>
        <div *ngIf="showSpinner" class="k-i-loading"></div>
      </div>

      <div class="global-body-section">
        <div class="border-panel slidecast-panel" *ngIf="showAttachmentsDiv">
          <!--Attached file grid-->
          <div class="email-form-group" *ngIf="taskAttachedFiles && taskAttachedFiles.length <= 0">
            <div class="messageInfo">No file(s) found.</div>
          </div>
          <div class="attach-alignment" *ngIf="taskAttachedFiles && taskAttachedFiles.length > 0">
            <div>
              <kendo-grid class="create-task-grid" #gridAttach id="attachGridId" [kendoGridBinding]="taskAttachedFiles" (remove)="removeHandler($event)" [sort]="attachFileSort" [skip]="skip" [pageSize]="pageSize" [pageable]="{ buttonCount: 0 }">
                <kendo-grid-column [width]="2">
                  <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                    <a (click)="downloadDocument(dataItem?.documentId)" title="Download Document"> <img src="../../../../assets/iconimagequick.svg"></a>
                    <!--<img class="img-slidecast" src="{{ dataItem.documentTypeIcon ? soUrl + dataItem.documentTypeIcon : 'https://dev.salesoptima.com/images/framework/iconimagequick.png' }}" />-->
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="File" [width]="20">
                  <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                    <a (click)="downloadDocument(dataItem?.documentId)">{{dataItem.documentName}}</a>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="" [width]="4">
                  <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                    <!--   (click)="deleteTaskFile(dataItem, rowIndex)"-->
                    <button kendoGridRemoveCommand [primary]="true" data-toggle="modal" data-target="#deleteDocumentModal" title="Remove">
                      <kendo-icon name="delete"></kendo-icon>
                    </button>
                    <!--  <button kendoGridRemoveCommand type="button" class="btn btn-sm btn-success" data-toggle="modal" data-target="#deleteDocumentModal"><i class="fas fa-trash"></i> Dalete</button>-->
                  </ng-template>
                </kendo-grid-column>

              </kendo-grid>
            </div>
          </div>
          <!--Attached file grid-->
          <!--File uploader-->
          <div class="file-attach-uploader" [hidden]="hideAFU">
            <div class="attach-alignment">
              <div class="email-form-group">
                <div class="file-uploader">
                  <div class="border">
                    <!--<angular-file-uploader [config]="afuConfig" [resetUpload]=resetUpload (ApiResponse)="fileUpload($event)"></angular-file-uploader>-->
                    <div class="uploader-panel">
                      <kendo-upload [saveUrl]="uploadSaveUrl"
                                    [restrictions]="uploadRestrictions"
                                    (complete)="fileUpload()"
                                    [saveHeaders]="fileUploadHeaders"
                                    [autoUpload]="false">
                      </kendo-upload>
                      <span>&nbsp;&nbsp;(jpg,png,pdf,docx,txt,gif,jpeg,xlsx,pptx,bmp,tiff) Size Limit: 3MB</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--File Uploader-->
        </div>
      </div>
    </div>
  </fieldset>

  <!--Delete task model-->
  <div class="modal fade" id="modalDeleteTask" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
      <div class="modal-content modal-common-content">
        <div class="modal-header modal-common-background">
          <h4 class="modal-title modal-common-title">Task Delete</h4>
        </div>
        <div class="modal-body modal-common-body">
          <h2>Do you want to delete {{currentTaskToDelete}} task..</h2>
        </div>
        <div class="modal-footer">
          <button type="button" (click)="deleteTask();" data-dismiss="modal" class="btn btn-primary">Yes</button>
          <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="hideDeleteTask()">No</button>

        </div>
      </div>
    </div>
  </div>
  <!--Delete task model-->
  <!--Update task model-->
  <div class="modal fade" id="modalUpdateTask" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
      <div class="modal-content modal-common-content">
        <div class="modal-header modal-common-background">
          <h4 class="modal-title modal-common-title">Task Update</h4>
        </div>
        <div class="modal-body modal-common-body">
          <h2>You are about to transfer this task.  Click the Save button to confirm...</h2>
        </div>
        <div class="modal-footer">
          <button type="button"  [style.pointer-events]="showSpinner?'none':''" [disabled]="showSpinner"  (click)="createUpdateTask();" data-dismiss="modal" class="btn btn-primary">
            <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
            <ng-template [ngIf]="buttonTypeOperation===1">Saving<span> &nbsp;<i wrapper> </i></span></ng-template>
          </button>
          <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="hideUpdateTask()">Cancel</button>

        </div>
      </div>
    </div>
  </div>
  <!--Update task model-->

</div>
<div class="modal fade" id="deleteDocumentModal" tabindex="-1" role="dialog" aria-labelledby="deleteDocumentModal" aria-hidden="true">
  <div class="modal-dialog  modal-common-dialog" role="document">
    <div class="modal-content modal-common-content">
      <div class="modal-header modal-common-background">
        <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
      </div>
      <div class="modal-body modal-common-body">
        <h5>Are you sure to delete this document? </h5>
      </div>
      <div class="modal-footer">
        <button type="button" [style.pointer-events]="showSpinner?'none':''" (click)="deleteTaskFile()" data-dismiss="modal" class="btn btn-primary">          
          <ng-template [ngIf]="buttonTypeOperation!=0">Confirm</ng-template>
          <ng-template [ngIf]="buttonTypeOperation ===0">Deleting  <span> &nbsp;<i wrapper> </i></span></ng-template>
        </button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="bulkTaskUpdate" tabindex="-1" role="dialog" aria-labelledby="bulkTaskUpdateModalLabel" aria-hidden="true" *ngIf="overTaskSelection?.length">
  <div class="modal-dialog  modal-common-dialog" role="document">
    <div class="modal-content modal-common-content">
      <div class="modal-header modal-common-background">
        <h5 class="modal-title modal-common-title">Task Update Confirmation</h5>
      </div>
      <div class="modal-body modal-common-body">
        <h5>Mark all checked tasks as {{bulkUpdateTaskType}}</h5>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="taskStatusBulkUpdate(bulkUpdateTaskType,'yes')" data-dismiss="modal" class="btn btn-primary">Confirm</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
