import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { HttpErrorResponse } from '@angular/common/http';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { Lead, LeadStatusHistoryDdlLoadResponse, LeadStatusHistoryResponse, SaveLeadStatusHistory } from '../../../models/lead.model';
import { IntDropDownItem, SimpleResponse } from '../../../models/genericResponse.model';
import { FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NotificationService } from '../../../services/notification.service';
import { LeadStatus } from '../../../models/calender.model';
import { GlobalService } from '../../../services/global.service';
import { DashboardService } from '../../../services/dashboard.service';
import { MessageResponse } from '../../../models/message.model';


@Component({
    selector: 'app-lead-status-history',
    templateUrl: './lead-status-history.component.html',
    styleUrls: ['./lead-status-history.component.css'],
    providers: [GridConfigurationService]
})
/** lead-status-history component*/
export class LeadStatusHistoryComponent {
    /** lead-status-history ctor */
    roleFeaturePermissions: RoleFeaturePermissions;
    user: CLPUser;
    dateFormat: string;
    userResponse: UserResponse;
    saveLeadStatusHistory: SaveLeadStatusHistory = {} as SaveLeadStatusHistory;
    columns = [
        { field: '$', title: '', width: '10' },
        { field: 'dtChangedDisplay', title: 'Date Changed', width: '150' },
        { field: 'leadStatusCodeDisplay', title: 'Status', width: '150' },
        { field: 'userName', title: 'User', width: '70' }
    ];
    reorderColumnName = 'dtChangedDisplay,leadStatusCodeDisplay,userName';
    columnWidth = 'dtChangedDisplay:150,leadStatusCodeDisplay:150,userName:70';
    arrColumnWidth = ['dtChangedDisplay:150,leadStatusCodeDisplay:150,userName:70'];
    encryptedUser: string;
    showSpinner: boolean;
    leadId: number;
    leadStatusHistory: LeadStatusHistoryResponse[] = []
    mobileColumnNames: string[];
    leadStatus: LeadStatus[] = [];
    users: IntDropDownItem[] = [];
    selectedLeadStatusCode: number;
    leadData: Lead = <Lead>{};
    selectedLeadStatusHistoryId: number;
    selectedUser: number;
    editedRowIndex: number;
    formGroup: FormGroup;
    selectedDateChanged: Date;
    messageID: number = 0;
    messageResponse: MessageResponse;
    constructor(private _datePipe: DatePipe,
        public _gridCnfgService: GridConfigurationService,
        private _router: Router,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _leadSettingService: LeadSettingService,
        private _globalService: GlobalService,
        private _notifyService: NotificationService,
        private _route: ActivatedRoute,
        private _dashboardService: DashboardService
    ) {
        this._localService.isMenu = true;
    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(async () => {
                    if (this.user) {
                        this._route.paramMap.subscribe(async params => {
                            if (!isNullOrUndefined(localStorage.getItem("messageId"))) {
                                this.messageID = Number(localStorage.getItem("messageId"));
                                this.updateMessage();
                            }
                            else if (params.has('msgid')) {
                                this.messageID = +params.get('msgid');
                                this.updateMessage();
                            }
                            if (params.has('leadId')) {
                                this.leadId = +params.get('leadId');
                                if (this.leadId != undefined && this.leadId != null && this.leadId > 0) {
                                    this.getLeadLoad();
                                    this.getLeadStatusHistory()
                                    this.getLEadStatusHistoryGridConfiguration();
                                    this.getHistoryDropDowns()
                                }
                            }
                        });
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    this.dateFormat = this.userResponse.user.dateFormat;
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lead-status-history.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getLEadStatusHistoryGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.user = this.user;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'lead_history_status').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('lead_history_status').subscribe((value) => { }));

    }




    async getLeadStatusHistory() {
        this.showSpinner = true;
        await this._leadSettingService.getLeadStatusHistory(this.encryptedUser, this.leadId)
            .then(async (result: LeadStatusHistoryResponse[]) => {
                if (!isNullOrUndefined(result)) {
                    this.leadStatusHistory = UtilityService.clone(result);
                    if (!isNullOrUndefined(this._gridCnfgService)) {
                        this._gridCnfgService.iterateConfigGrid(this.leadStatusHistory, "lead_history_status");
                        this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('lead_history_status');
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getHistoryDropDowns() {
        await this._leadSettingService.getHistoryDropDowns(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: LeadStatusHistoryDdlLoadResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.leadStatus = result?.leadStatus
                    this.users = result?.userDropDownItem
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async deleteStatusHistory() {
        await this._leadSettingService.deleteStatusHistory(this.encryptedUser, this.selectedLeadStatusHistoryId)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this._notifyService.showSuccess("Lead status deleted successfully.", "", 3000);
                        await this.getLeadStatusHistory()
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    editHandler({ sender, rowIndex, dataItem }) {
        this.setDropDownvalues(dataItem);
        this.closeEditor(sender);
        this.editedRowIndex = rowIndex;
        sender.editRow(rowIndex, this.formGroup);
    }

    closeEditor(grid, rowIndex = this.editedRowIndex) {
        grid.closeRow(rowIndex);
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
    }


    cancelHandler({ sender, rowIndex }) {
        this.selectedLeadStatusCode = null
        this.selectedUser = null
        this.formGroup = null;
        this.closeEditor(sender, rowIndex);
    }

    saveHandler({ sender, rowIndex, dataItem }): void {
        this.showSpinner = true;
        this.saveLeadStatusHistory.leadStatusCode = this.selectedLeadStatusCode
        this.saveLeadStatusHistory.leadStatusHistoryID = dataItem?.leadStatusHistoryID
        this.saveLeadStatusHistory.cLPUserID = this.selectedUser;
        this.saveLeadStatusHistory.dtChanged = this.selectedDateChanged
        this.saveLeadStatusHistory.strDtChanged = this._datePipe.transform(this.selectedDateChanged, "MMddyyyy");
        sender.closeRow(rowIndex);
        this._leadSettingService.saveLeadStatusHistory(this.encryptedUser, this.saveLeadStatusHistory)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var res = UtilityService.clone(result);
                    if (res?.messageBool) {
                        this._notifyService.showSuccess("Lead status updated successfully.", "", 3000);
                        await this.getLeadStatusHistory()
                    }
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    setDropDownvalues(dataItem: LeadStatusHistoryResponse) {
        this.selectedDateChanged = new Date(dataItem?.dtChangedDisplay);
        this.selectedLeadStatusCode = dataItem?.leadStatusCode;
        this.selectedUser = dataItem?.cLPUserID;
    }

    removeHandler({ dataItem }): void {
        this.selectedLeadStatusHistoryId = dataItem?.leadStatusHistoryID;
    }

    getLeadLoad() {
        this._leadSettingService.getLeadLoad(this.encryptedUser, this.leadId)
            .then((result: Lead) => {
                if (!isNullOrUndefined(result)) {
                    this.leadData = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    goToLeadContactHistory() {
        localStorage.setItem("ownerName", this.leadData?.contactLastName + '(' + this.leadData?.leadDesc + ')');
        localStorage.setItem("contactName", this.leadData?.lastFirst);
        this._router.navigate(['/lead-contact-history', this.leadData?.leadID, this.leadData?.contactID]);
    }


    async updateMessage() {
        this._dashboardService.messageUpdate(this.encryptedUser, this.messageID)
            .then(async (result: MessageResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.messageResponse = UtilityService.clone(result);
                    if (this.messageResponse.messageBool) {
                        localStorage.removeItem("messageId");
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'lead_history_status').subscribe((value) => this.getLEadStatusHistoryGridConfiguration());
    }

}
