import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, NgZone, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataBindingDirective, RowClassArgs } from '@progress/kendo-angular-grid';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eApptCategory, eCampaignTemplateOwnerType, eDocumentCategory, eFeatures, eNoteOwnerType, eSectionLead, eTaskCategory, eUserRole } from '../../../models/enum.model';
import { AppointmenTypeCodeByCategory, Lead, LeadFields, LeadFieldsResponse, LeadListResponse, VerticalDisplaysetting } from '../../../models/lead.model';
import { Note } from '../../../models/note.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { keyValue, Search, SearchListResponse, SearchQueryResponse } from '../../../models/search.model';
import { ContactService } from '../../../services/contact.service';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { NotificationService } from '../../../services/notification.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { process } from '@progress/kendo-data-query';
import { ContactSearchService } from '../../../services/shared/contact-search.service';
import { OnInit } from '@angular/core';
import { DropDownItem, SimpleResponse, UserDD } from '../../../models/genericResponse.model';
import { ApppointmentResponse, ShareableApptUI, UpdateAppt } from '../../../models/appt.model';
import { LeadApptComponent } from '../common/lead-appt/lead-appt.component';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { ContactHistory, ContactHistoryListResponse } from '../../../models/contactHistory.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { LeadField } from '../../../models/leadField.model';
import { Contact } from '../../../models/contact.model';
import { AppointmentSettingService } from '../../../services/appointmentSetting.service';
import { GlobalService } from '../../../services/global.service';
import { LeadHistoryComponent } from '../lead-history/lead-history.component';
import { DashboardService } from '../../../services/dashboard.service';
import { MessageResponse } from '../../../models/message.model';
import { DatePipe } from '@angular/common';
import { UserService } from '../../../services/user.service';
import { TagSettingService } from '../../../services/tag-setting.service';
import { TagData, TagsFields } from '../../../models/tag-settings.model';
import { LeadConnect, LeadConnectData } from '../../../models/leadSetting.model';
import { SearchContactService } from '../../../services/Searchcontact.service';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
declare var $: any;

@Component({
    selector: 'lead',
    templateUrl: './lead.component.html',
    styleUrls: ['./lead.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [GridConfigurationService]
})
/** lead-list component*/
export class LeadComponent implements OnInit {
    showSpinner: boolean = false;
    isPinned: boolean = false;
    eDocumentCategory = eDocumentCategory;
    private encryptedUser: string = '';
    user: CLPUser;
    eNoteOwnerType = eNoteOwnerType;
    roleFeaturePermissions: RoleFeaturePermissions;
    userResponse: UserResponse;
    noteData: Note = <Note>{};
    isRefresh: boolean = true;
    leadList: Lead[];
    initLeadList: Lead[];
    selectedLead: Lead;
    /*private _leftPanelSize: string = '80%';
    private _rightPanelSize: string = '20%';
    panelsSize: string = 'left:20%,right:15%';*/
    private _leftPanelSize: string = '25%';
    private _rightPanelSize: string = '25%';
    panelsSize: string = 'left:29%, right:29%';
    showHistoryComponent: boolean = true;
    showQuickActionComponent: boolean = true;
    showLeadCreateComponent: boolean = false;
    link: boolean = false;
    leadId: number = 0;
    isLeadEdit: boolean = false;
    savedQuery_Filter: keyValue[] = [];
    @ViewChild('activeDashboardId') activeDashboardId: ElementRef;
    @ViewChild('editButton') editButton: ElementRef;
    @ViewChild('leadApptChild') leadApptChild: LeadApptComponent;
    @ViewChild('closeButton') closeButton: ElementRef;
    @ViewChild('leadHistoryChild') leadHistoryChild: LeadHistoryComponent;
    @ViewChild('autocomplete') autocomplete: AutoCompleteComponent;

    loadOtherComponents: boolean = false;
    contactHistory: ContactHistory[];
    eCampaignTemplateOwnerType = eCampaignTemplateOwnerType;
    leadFieldsResponse: LeadFieldsResponse;
    leadFields: LeadFields;

    columns = [{ field: 'leadDesc', title: 'Lead Description', width: '100' }];
    reorderColumnName: string = 'leadDesc';
    columnWidth: string = 'leadDesc:100';
    arrColumnWidth: any[] = ['leadDesc:100'];

    sendMailInfo: any = { isShow: false, contactId: 0 };
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    mode: string = '';
    isShowApptModal: boolean = false;
    shareableAppt: ShareableApptUI;
    @ViewChild('closeInputButton') closeInputButton: ElementRef;
    taskCategory: eTaskCategory = eTaskCategory.Lead;
    apptCategory: eApptCategory = eApptCategory.Lead;
    documentHeader: string = '';
    noteHeader: string = 'Quick Notes'
    apptHeader: string = 'Quick Appointments'
    companyData: ClpCompany;
    showLeadInvoiceComponent: boolean = false;
    leadInvoice: any = { isShow: false, leadId: 0 };
    isCloseButton: boolean = false;
    filterLinks: DropDownItem[] = [];
    selectedCallType: string;
    isShowNewCallModal: boolean;
    contactID: number;
    contactData: Contact;
    proposalID: number = 0;
    isGridVisible: boolean = false;

    arrAllControls: any[] = [];
    arrSortedBySection: any[] = [];
    eUserRole: eUserRole;
    arrGenCtrl: any[] = [];
    arrRevenueRelatedCtrl: any[];
    isCollapse: boolean = false;

    userTitle: string = '';
    arrScopeCtrl: any[] = []
    arrCommentsCtrl: any[] = []
    arrTagsCtrl: any[] = [];
    arrSystemCtrl: any[] = []
    arrAdditionalInfoCtrl: any[] = []
    arrClassificationCtrl: any[] = []
    arrConnectionsCtrl: any[] = []
    accordianStates: any = []
    userList: UserDD[];
    apptTypeCodes: AppointmenTypeCodeByCategory[];
    tagListByCompany: TagData[] = [];
    tagListByOwner: TagData[] = [];
    selectedTagId: any;
    contactSearchList: Search[] = [];
    leadConnectionList: LeadConnectData[];
    leadConnect: LeadConnect = <LeadConnect>{};
    contactIdForConnection: number = 0;
    connectionRelation: string = "";
    selectedcontactId: number = 0;
    isContactMandatory: boolean = false;
    isHideConnectionForm = true;

    constructor(public _gridCnfgService: GridConfigurationService,
        private _accountSetupService: AccountSetupService,
        public _contactService: ContactService,
        private _utilityService: UtilityService,
        private _route: ActivatedRoute,
        private contactService: ContactService,
        private _ngZone: NgZone,
        public _localService: LocalService,
        private datePipe: DatePipe,
        private leadSettingService: LeadSettingService,
        public _contactSearchService: ContactSearchService,
        private _router: Router,
        public notifyService: NotificationService,
        public _globalService: GlobalService,
        private cdRef: ChangeDetectorRef,
        private _apptService: AppointmentSettingService,
        private _dashboardService: DashboardService,
        private _userService: UserService,
        private _tagSettingService: TagSettingService,
        private srchContactSrvc: SearchContactService,
    ) {
        this._localService.isMenu = true;
        this._contactSearchService.isSearchEditClick = false;
        this._route.paramMap.subscribe(async params => {
            if (params.has('leadId')) {
                this.leadId = +params.get('leadId');
                this._contactSearchService.isFromLead = true;
                this._contactSearchService.authenticateUser();
            } if (params.has('mode'))
                this.mode = params.get('mode');
            if (params.has('msgid')) {
                this.updateMessage(Number(params.has('msgid')));
            }
        });
        this.subscribeToEvents();
    }

    ngOnInit() {

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(async () => {
                    if (this.user) {
                        await this.getLeadListByUser();
                        if (this.selectedLead?.cLPCompanyID == this.user?.cLPCompanyID || this.user?.slurpyUserId > 0 || this.user?.cLPCompanyID == 0) {
                            this._contactSearchService.routeUserId = this.user?.cLPUserID;
                            this.getGridConfiguration();
                            this.getApptFilters();
                            this.getLoadUsers();
                            this.getCompanyData();
                            this.getLeadConnectByOwner();
                            this.getTagListByOwnerId(this.leadId);
                            this.getLeadField();
                            this.getLeadConfigurationFields();
                        }
                        else {
                            this._router.navigate(['/login']);
                        }
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
        this.getPageUp();
    }

    ngOnDestroy() {
        window.localStorage.removeItem("custom_lead_search");
        if (this.leadList?.length > 0) {
            this.leadList.forEach((item) => {
                item.isSelected = false;
            });
        }
    }

    ngAfterContentChecked(): void {
        this.cdRef.detectChanges();
    }

    leadUpdate(isUpdate: boolean) {
        if (isUpdate)
            this.getLeadListByUser();
    }

    getLeadConfigurationFields() {
        this.leadSettingService.getLeadFields(this.encryptedUser, this.leadId, this.user?.cLPCompanyID, this.user?.cLPUserID)
            .then(async (result: LeadFieldsResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.leadFieldsResponse = UtilityService.clone(result);
                    this.leadFields = this.leadFieldsResponse?.leadFields;
                    this.leadFieldsResponse.leadFields.dtStart.fieldValue = this.formatDate(this.leadFieldsResponse.leadFields.dtStart.fieldValue)
                    this.leadFieldsResponse.leadFields.dtEnd.fieldValue = this.formatDate(this.leadFieldsResponse.leadFields.dtEnd.fieldValue)
                    this.leadFieldsResponse.leadFields.dtRevenue.fieldValue = this.formatDate(this.leadFieldsResponse.leadFields.dtRevenue.fieldValue)
                    /*  this.leadFieldsResponse.leadFields.dtModified.fieldValue = this.formatDate(this.leadFieldsResponse.leadFields.dtModified.fieldValue)*/
                    this.renderFields()
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    formatDate(date) {
        if (date) {
            let datee = new Date(date);
            let month = datee.getMonth() + 1;
            let dt = datee.getDate();
            let year = datee.getFullYear();
            let formatedDate = month + '/' + dt + '/' + year;
            return formatedDate;
        }
    }


    renderFields() {
        this.arrAllControls = [];
        var keys = Object.keys(this.leadFieldsResponse.leadFields).filter(i => i.indexOf("contactMoreFields") == -1 && i.indexOf("displaySetting") == -1);
        for (var i = 0; i < keys.length; i++) {
            if (!!this.leadFieldsResponse.leadFields[keys[i]])
                this.arrAllControls.push(this.leadFieldsResponse.leadFields[keys[i]]);
        }
        console.log(this.arrAllControls, 'this is arr control');
        this.arrGenCtrl = this.arrAllControls.filter(i => i.sectionLead == eSectionLead.General);
        this.arrScopeCtrl = this.arrAllControls.filter(i => i.fieldName == "Scope");
        this.arrCommentsCtrl = this.arrAllControls.filter(i => i.fieldName == "Comments");
        this.arrTagsCtrl = this.arrAllControls.filter(i => i.fieldName == "Tags");
        this.arrConnectionsCtrl = this.arrAllControls.filter(i => i.fieldName == "Connections");
        this.arrSystemCtrl = this.arrAllControls.filter(i => i.fieldName == "ClpuserID" || i.fieldName == "dtCreated" || i.fieldName == "dtModified" || i.fieldName == "LeadIDDisplay");
        this.arrAdditionalInfoCtrl = this.arrAllControls.filter(i => i.fieldName == "dtCustom3" || i.fieldName == "CustomText1" || i.fieldName == "CustomText2" || i.fieldName == "CustomText3" || i.fieldName == "CustomText4" || i.fieldName == "CustomText5" || i.fieldName == "CustomText6");
        this.arrRevenueRelatedCtrl = this.arrAllControls.filter(i => i.fieldName == "Volume" || i.fieldName == "Revenue" || i.fieldName == "dtRevenue" || i.fieldName == "WinProbability" || i.fieldName == "CustomMoney1" || i.fieldName == "CustomMoney2" || i.fieldName == "CustomMoney3" || i.fieldName == "CustomMoney4" || i.fieldName == "CustomMoney5");
        this.arrClassificationCtrl = this.arrAllControls.filter(i => i.fieldName == "LeadClass1Code" || i.fieldName == "LeadClass2Code" || i.fieldName == "LeadClass3Code" || i.fieldName == "LeadClass4Code" || i.fieldName == "LeadClass5Code" || i.fieldName == "LeadClass6Code" || i.fieldName == "LeadClass7Code" || i.fieldName == "LeadClass8Code" || i.fieldName == "LeadClass9Code");

        this.arrGenCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrScopeCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrCommentsCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrSystemCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrAdditionalInfoCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrRevenueRelatedCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrClassificationCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrTagsCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
        this.arrConnectionsCtrl.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);

        let accordianStates = JSON.parse(localStorage.getItem('accordianStatesByLeads')) ? JSON.parse(localStorage.getItem('accordianStatesByLeads')) : []
        let currentContactState = accordianStates?.filter((item) => item.leadId == this.leadId)

        if (currentContactState?.length > 0) {
            let arrSortedBySection = currentContactState[0]?.arrSortedBySection

            this.isCollapse = currentContactState[0]?.isCollapse

            if (!isNullOrUndefined(this.leadFieldsResponse.leadFields) && !isNullOrUndefined(this.leadFieldsResponse.leadFields.displaySetting) && !isNullOrUndefined(this.leadFieldsResponse.leadFields.displaySetting.fieldDiplaySettings.length > 0)) {
                this.arrSortedBySection = [];
                let sectionDiplaySettings: VerticalDisplaysetting[] = this.leadFieldsResponse.leadFields.verticalDisplaysetting;
                sectionDiplaySettings.sort((a, b) => (a.sectionDisplayOrder > b.sectionDisplayOrder) ? 1 : -1);
                for (var i = 0; i < sectionDiplaySettings.length; i++) {
                    let filteredObj = arrSortedBySection.filter(item => item?.sectionName == sectionDiplaySettings[i].sectionName)[0]
                    switch (sectionDiplaySettings[i].sectionName) {
                        case 'Generals': this.arrSortedBySection.push({ sectionName: 'Generals', items: this.arrGenCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                        case 'Additional Information': this.arrSortedBySection.push({ sectionName: 'Additional Information', items: this.arrAdditionalInfoCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                        case 'Revenue Estimates': this.arrSortedBySection.push({ sectionName: 'Revenue Estimates', items: this.arrRevenueRelatedCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                        case 'Classification': this.arrSortedBySection.push({ sectionName: 'Claasification', items: this.arrClassificationCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                        case 'Scope': this.arrSortedBySection.push({ sectionName: 'Scope', items: this.arrScopeCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                        case 'Comments': this.arrSortedBySection.push({ sectionName: 'Comments', items: this.arrCommentsCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                        case "Tags": this.arrSortedBySection.push({ sectionName: 'Tags', items: this.arrTagsCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                        case 'Connections': this.arrSortedBySection.push({ sectionName: 'Connections', items: this.arrConnectionsCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                        case 'System': this.arrSortedBySection.push({ sectionName: 'System', items: this.arrSystemCtrl, isActive: filteredObj?.isActive, sectionId: i }); break;
                    }
                }
            }
        }
        else {
            if (!isNullOrUndefined(this.leadFieldsResponse.leadFields) && !isNullOrUndefined(this.leadFieldsResponse.leadFields.displaySetting) && !isNullOrUndefined(this.leadFieldsResponse.leadFields.displaySetting.fieldDiplaySettings.length > 0)) {
                this.arrSortedBySection = [];
                let sectionDiplaySettings: VerticalDisplaysetting[] = this.leadFieldsResponse.leadFields.verticalDisplaysetting;
                sectionDiplaySettings.sort((a, b) => (a.sectionDisplayOrder > b.sectionDisplayOrder) ? 1 : -1);
                for (var i = 0; i < sectionDiplaySettings.length; i++) {
                    switch (sectionDiplaySettings[i].sectionName) {
                        case 'Generals': this.arrSortedBySection.push({ sectionName: 'Generals', items: this.arrGenCtrl, isActive: true, sectionId: i }); break;
                        case 'Additional Information': this.arrSortedBySection.push({ sectionName: 'Additional Information', items: this.arrAdditionalInfoCtrl, isActive: true, sectionId: i }); break;
                        case 'Revenue Estimates': this.arrSortedBySection.push({ sectionName: 'Revenue Estimates', items: this.arrRevenueRelatedCtrl, isActive: true, sectionId: i }); break;
                        case 'Classification': this.arrSortedBySection.push({ sectionName: 'Claasification', items: this.arrClassificationCtrl, isActive: true, sectionId: i }); break;
                        case 'Scope': this.arrSortedBySection.push({ sectionName: 'Scope', items: this.arrScopeCtrl, isActive: true, sectionId: i }); break;
                        case 'Comments': this.arrSortedBySection.push({ sectionName: 'Comments', items: this.arrCommentsCtrl, isActive: true, sectionId: i }); break;
                        case "Tags": this.arrSortedBySection.push({ sectionName: 'Tags', items: this.arrTagsCtrl, isActive: true, sectionId: i }); break;
                        case 'Connections': this.arrSortedBySection.push({ sectionName: 'Connections', items: this.arrConnectionsCtrl, isActive: true, sectionId: i }); break;
                        case 'System': this.arrSortedBySection.push({ sectionName: 'System', items: this.arrSystemCtrl, isActive: true, sectionId: i }); break;
                    }
                }
            }
        }
    }

    getStatus(statusCode: number): string {
        let status = "";
        if (this.leadFields?.leadStatusCode?.items.some(s => s.value = statusCode)) {
            status = this.leadFields?.leadStatusCode?.items.filter(l => l.value == statusCode)[0].display;
        }
        return status;
    }

    getWinProb(winValue: number): string {
        let winProb = "";
        if (this.leadFields?.winProbability?.items.some(s => s.value = winValue)) {
            winProb = this.leadFields?.winProbability?.items.filter(w => w.value == winValue)[0].display;
        }
        return winProb;
    }

    getleadClassCode(classValue: number): string {
        let leadClassCode = "";
        if (this.leadFields?.leadClass1Code?.items.some(s => s.value = classValue)) {
            leadClassCode = this.leadFields?.leadClass1Code?.items.filter(w => w.value == classValue)[0].display;
        }
        return leadClassCode;
    }

    getUserDetails(classValue: number): string {
        let userName = "";
        let arr = [];
        for (let key in this.leadFieldsResponse?.filterUsers) {
            arr.push({ key: key, value: this.leadFieldsResponse?.filterUsers[key] });
        }

        arr.forEach((item) => {
            if (item.value.key == classValue) {
                userName = item.value.value;
                this.userTitle = item.value.value
            }
        });
        return userName;
    }

    async getCompanyData() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result)
                    this.companyData = UtilityService.clone(result?.company);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }


    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'lead_detail_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('lead_detail_grid').subscribe((value) => { }));
    }
    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'lead_detail_grid').subscribe((value) => this.getGridConfiguration());
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lead.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    public get leftPanelSize(): string {
        return this._leftPanelSize;
    }

    public set leftPanelSize(newSize: string) {
        this._leftPanelSize = newSize;
    }

    public get rightPanelSize(): string {
        return this._rightPanelSize;
    }

    public set rightPanelSize(newSize: string) {
        this._rightPanelSize = newSize;
    }

    panelSizeChange(panelName, size) {
        if (panelName == 'left') {
            const leftPanelSize = this._leftPanelSize.slice(0, 5);
            this._leftPanelSize = leftPanelSize.includes('%') ? leftPanelSize : leftPanelSize + '%';
        }
        else if (panelName == 'right') {
            const rightPanelSize = this._rightPanelSize.slice(0, 5);
            this._rightPanelSize = rightPanelSize.includes('%') ? rightPanelSize : rightPanelSize + '%';
        }
        const gridType = 'contact_panel_size';
        this.panelsSize = 'left:' + this._leftPanelSize + ',' + 'right:' + this.rightPanelSize;
    }

    private subscribeToEvents(): void {
        this._contactSearchService.leadListChanged.subscribe((data) => {
            this._ngZone.run(() => {
                this.leadList = data;
                this.initLeadList = data;
            })
        })

        this._localService.hideCommonComponent.subscribe((value: string) => {
            this._ngZone.run(() => {
                switch (value) {
                    case 'lead-create':
                        this.showLeadCreateComponent = false;
                        break;
                    default:
                        break;
                }
            });
        });
    }

    showCommonComponent() {
        var commonComponent = this._localService.showCommonComp;
        switch (commonComponent) {
            case 'lead-create':
                this.resetChildForm();
                break;
            default:
                break;
        }
    }

    isEditLead(isOpen: boolean) {
        this._localService.showCommonComp = 'lead-create';
        this.showCommonComponent();
        this.isLeadEdit = isOpen;
    }

    resetChildForm() {
        this.showLeadCreateComponent = false;
        setTimeout(() => { this.showLeadCreateComponent = true }, 100);
    }

    resetHistoryComponent() {
        this.showHistoryComponent = false;
        setTimeout(() => { this.showHistoryComponent = true }, 100);
    }


    resetQuickActionComponent() {
        this.showQuickActionComponent = false;
        setTimeout(() => { this.showQuickActionComponent = true }, 100);
    }

    async getLeadListByUser() {
        if (!isNullOrUndefined(window.localStorage.getItem('custom_lead_search'))) {
            this.isGridVisible = true;
            this.leadList = [];
            let queryParam: SearchQueryResponse = JSON.parse(window.localStorage.getItem("custom_lead_search"));
            await this.leadSettingService.searchSaveLead(this.encryptedUser, queryParam, this.user.cLPUserID)
                .then(async (result: LeadListResponse) => {
                    if (result) {
                        this.leadList = UtilityService.clone(result?.leads);
                        this.initLeadList = UtilityService.clone(result?.leads);
                        this.selectedLead = this.leadList.find(i => i.leadID == this.leadId);
                        this.leadId = this.selectedLead?.leadID
                        localStorage.setItem('selectedLead', JSON.stringify(this.selectedLead));
                        this.checkIsPinned();
                        this.getLeadConfigurationFields();
                        this.getContactHistory(this.selectedLead?.contactID, this.selectedLead?.cLPUserID)
                        if (this.mode == 'edit' && !isNullOrUndefined(this.selectedLead))
                            this.editButton.nativeElement.click();
                        window.localStorage.removeItem('custom_lead_search');
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            this.isGridVisible = false;
            await this.leadSettingService.getLeadListByCompanyId(this.encryptedUser, this.user?.cLPCompanyID, this.leadId)
                .then(async (result: LeadListResponse) => {
                    if (!isNullOrUndefined(result)) {
                        this.leadList = UtilityService.clone(result?.leads);
                        this.initLeadList = UtilityService.clone(result?.leads);
                        this.selectedLead = this.leadList.find(i => i.leadID == this.leadId);
                        localStorage.setItem('selectedLead', JSON.stringify(this.selectedLead));
                        this.leadId = this.selectedLead?.leadID
                        this.getLeadConfigurationFields();
                        this.checkIsPinned();
                        this.getContactHistory(this.selectedLead?.contactID, this.selectedLead?.cLPUserID)
                        if (this.mode == 'edit' && !isNullOrUndefined(this.selectedLead))
                            this.editButton.nativeElement.click();
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    onLeadListFilter(inputValue: string): void {
        this.leadList = process(this.initLeadList, {
            filter: {
                logic: "or",
                filters: [
                    { field: 'leadDesc', operator: 'contains', value: inputValue }
                ],
            }
        }).data;
        this.dataBinding.skip = 0;
    }

    rowSelectionChange(e) {
        if (e?.selectedRows && e?.selectedRows?.length > 0) {
            this.selectedLead = e?.selectedRows[0]?.dataItem;
            this.leadId = this.selectedLead?.leadID;
            this.showLeadCreateComponent = false;
            this.resetHistoryComponent();
            this.resetQuickActionComponent();
            this.leadList.forEach((item) => {
                if (item.leadID == this.leadId)
                    item.isSelected = true;
            });
        }
    }

    public rowCallback(context: RowClassArgs): any {
        if (context?.dataItem?.leadID == this.leadId)
            return { passive: true };
    }

    changeLeadSort(value) {
        this.isRefresh = false;
        switch (value) {
            case "created":
                this.leadList = this.leadList.sort((x, y) => new Date(x.dtCreated).getTime() - new Date(y.dtCreated).getTime());
                this.notifyService.showSuccess("Lead sorted on " + value + "!", "", 3000);
                break;
            case "updated":
                this.leadList = this.leadList.sort((x, y) => new Date(x.dtModified).getTime() - new Date(y.dtModified).getTime());
                this.notifyService.showSuccess("Lead sorted on " + value + "!", "", 3000);
                break;
            default:
                this.leadList = this.initLeadList;
                break;
        }
        setTimeout(() => { this.isRefresh = true; }, 50);
    }

    async deleteSelectedLead(leadId: number) {
        this.showSpinner = true;
        await this.leadSettingService.delete_Lead(this.encryptedUser, leadId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result.messageBool) {
                        this.notifyService.showSuccess(result.messageString, '', 3000);
                        this._router.navigate(['/lead']);
                    } else
                        this.notifyService.showError(result.errorMsg, '', 3000);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async pinnedLead() {
        this.showSpinner = true;
        await this.contactService.pinnedPin(this.encryptedUser, this.selectedLead?.cLPUserID, this.selectedLead?.leadID, eNoteOwnerType.Lead)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result?.messageBool) {
                        this.notifyService.showSuccess("Pinned Successfully", '', 3000);
                        this.isPinned = true;
                    } else {
                        this.notifyService.showError("Something went wrong", '', 3000);
                        this.isPinned = false;
                    }
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this.isPinned = false;
                this._utilityService.handleErrorResponse(err);
            });

    }

    async unPinnedLead() {
        this.showSpinner = true;
        await this.contactService.pinnedUnPin(this.encryptedUser, this.selectedLead?.cLPUserID, this.selectedLead?.leadID, eNoteOwnerType.Lead)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result.messageBool) {
                        this.notifyService.showSuccess("Un-Pinned Successfully", '', 3000);
                        this.isPinned = false;
                    } else {
                        this.notifyService.showError("Something went wrong", '', 3000);
                        this.isPinned = true;
                    }
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this.isPinned = true;
                this._utilityService.handleErrorResponse(err);
            });

    }

    async checkIsPinned() {
        await this.contactService.pinnedIsPin(this.encryptedUser, this.selectedLead?.cLPUserID, this.selectedLead?.leadID, eNoteOwnerType.Lead)
            .then(async (result: SimpleResponse) => {
                if (result)
                    this.isPinned = result?.messageBool ? true : false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    sendEmail(contactId: number, leadID: number) {
        $('#sendEmailModal').modal('show');
        this.sendMailInfo.isShow = true;
        this.leadId = leadID
        this.sendMailInfo.contactId = contactId;
    }

    hideSendMail() {
        $('#sendEmailModal').modal('hide');
        this.sendMailInfo.isShow = false;
        this.sendMailInfo.contactId = 0;
    }

    async appointmentPopUp(apptData, isNewAppt: boolean = false) {
        if (isNewAppt) {
            this.shareableAppt = {
                apptId: 0,
                rApptID: apptData?.rApptID,
                cat: eApptCategory.Lead,
                leadContactId: this.selectedLead?.contactID,
                ownerId: this.leadId,
                currentUrlAppt: 'lead-appt',
                isNewTab: true,
            };
            this.isShowApptModal = true;
        }
        else {
            await this._apptService.apptLoad(this.encryptedUser, apptData?.apptID, this.user?.cLPUserID, this.user?.cLPCompanyID).then
                (async (result: ApppointmentResponse) => {
                    if (!isNullOrUndefined(result)) {
                        this.proposalID = result.appt.proposalID;
                    }
                })
            this.shareableAppt = {
                apptId: apptData?.apptID,
                rApptID: apptData?.rApptID,
                leadContactId: this.selectedLead?.contactID,
                cat: apptData?.category,
                ownerId: apptData?.category == eApptCategory.Contact ? apptData?.contactID : apptData?.category == eApptCategory.Lead ? apptData?.leadID : 0,
                currentUrlAppt: 'lead-appt',
                isNewTab: true,
            };
            this.isShowApptModal = true;
        }

    }

    hideQuickApptModal(updateAppt?: UpdateAppt) {
        if (!isNullOrUndefined(updateAppt)) {
            this.closeModalApptModal();
            if (updateAppt?.isSave) {
                this.leadApptChild.getApptList();
                this.leadHistoryChild?.ngOnInit();
            }

        }
        this.isShowApptModal = false;
        this.shareableAppt = null;
        $('#quickApptModalLead').modal('hide');
    }

    closeModalApptModal() {
        let inputElement: HTMLElement = this.closeInputButton.nativeElement as HTMLElement;
        inputElement.click();
    }

    sendEmailParent(ev) {
        $('#sendEmailModal').modal('show');
        this.sendMailInfo.isShow = true;
        this.sendMailInfo.contactId = ev?.contactID;
    }

    goToLinkLead(name, selectedLead: Lead) {
        if (!isNullOrEmptyString(name)) {
            switch (name) {
                case "name":
                    this._router.navigate(['/contact/', this.user?.cLPUserID, selectedLead?.contactID]);
                    break;
                case "company":
                    this._router.navigateByUrl(`company-create?cid=${selectedLead?.companyID}`);
                    break;
            }
        }
    }

    async getContactHistory(contactId: number, userId: number) {
        this.showSpinner = true;
        this._contactService.contactHistory_Get(this.encryptedUser, contactId, userId)
            .then(async (result: ContactHistoryListResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.contactHistory = UtilityService.clone(result?.contactHistory);
                    this._contactSearchService.showSpinner = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._contactSearchService.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getDocumentHeader(isDocument: boolean) {
        isDocument ? this.documentHeader = 'Recent Documents' : this.documentHeader = 'Upload Documents';
    }

    getNoteHeader(isNoteList: boolean) {
        isNoteList ? this.noteHeader = 'Recent Notes' : this.noteHeader = 'Quick Notes';
    }

    getApptHeader(isApptList: boolean) {
        isApptList ? this.apptHeader = 'Pending Appointments' : this.apptHeader = 'Quick Appointments';
    }

    getLeadName() {
        return this.selectedLead?.contactLastName + ' (' + this.selectedLead?.leadDesc + ')';
    }

    viewAutomationProcess() {
        localStorage.setItem("ownerName", this.selectedLead?.leadDesc);
        this._router.navigate(['/manage-campaign'], { queryParams: { oid: this.selectedLead?.leadID, otype: eCampaignTemplateOwnerType?.Lead } });
    }

    goToAppt() {
        localStorage.setItem("ownerName", this.selectedLead?.leadDesc);
        this._router.navigate(['/appointment-list', this.selectedLead?.leadID, eApptCategory.Lead]);
    }

    goToTask() {
        localStorage.setItem("ownerName", this.selectedLead?.contactLastName + '(' + this.selectedLead?.leadDesc + ')');
        this._router.navigate(['/task', this.selectedLead?.leadID, eTaskCategory.Lead]);
    }

    goToLeadContactHistory() {
        localStorage.setItem("ownerName", this.selectedLead?.contactLastName + '(' + this.selectedLead?.leadDesc + ')');
        localStorage.setItem("contactName", this.selectedLead?.lastFirst);
        this._router.navigate(['/lead-contact-history', this.selectedLead?.leadID, this.selectedLead?.contactID]);
    }

    createNewNote() {
        localStorage.setItem("ownerName", this.selectedLead?.contactLastName + '(' + this.selectedLead?.leadDesc + ')');
        localStorage.setItem("contactName", this.selectedLead?.lastFirst);
        localStorage.setItem("ownerType", (eNoteOwnerType.Lead).toString());
        this._router.navigate(['/lead-contact-history', this.selectedLead?.leadID, this.selectedLead?.contactID], { queryParams: { isNew: true } });
    }
    createNewTask() {
        localStorage.setItem("ownerName", this.selectedLead?.leadDesc);
        this._router.navigate(['/task', this.selectedLead?.leadID, eTaskCategory.Lead], { queryParams: { isNew: true } });
    }

    gotoDocumentList() {
        localStorage.setItem("ownerName", this.selectedLead?.contactLastName + '(' + this.selectedLead?.leadDesc + ')');
        this._router.navigate(['/my-documents', this.selectedLead?.leadID, eTaskCategory.Lead]);
    }

    getLeadField() {
        this.showSpinner = true;
        this.leadSettingService.leadFieldLoad(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: LeadField) => {
                if (!isNullOrUndefined(result))
                    this.showLeadInvoiceComponent = UtilityService.clone(result?.isInvoiceModule);
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._contactSearchService.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    showLeadInvoice() {
        $('#leadInvoiceModal').modal('show');
        this.leadInvoice.isShow = false;
        this.leadInvoice.leadId = this.leadId;
        this.leadInvoice.isShow = true;
    }

    hideLeadModal() {
        $('#leadInvoiceModal').modal('hide');
        this.isCloseButton = true;
        this.leadInvoice.isShow = false;
        this.leadInvoice.leadId = 0;
    }

    closeLeadInvoice(isCloseButton: boolean) {
        this.isCloseButton = isCloseButton;
        if (this.isCloseButton)
            this.closeButton.nativeElement.click();
    }

    closeDocumentMailMergeModal(isCloseButtonClicked: boolean) {
        if (isCloseButtonClicked)
            $('#documentMailMerge').modal('hide');
    }

    getFilterlinks(filterLink: DropDownItem[]) {
        this.filterLinks = filterLink;
    }

    refreshContactDetail(value: boolean) {
        if (value) {
            this.showLeadCreateComponent = false;
            this.resetHistoryComponent();
            this.getLeadListByUser();
        }
    }

    toCloseEmailModal(event) {
        $('#emailModal1').modal('hide');
        this.link = false
    }

    async openModalNewCall(contactData, callType: string) {
        this.contactID = contactData?.contactID
        await this.getContactData()
        this.selectedCallType = callType;
        this.isShowNewCallModal = true;
    }

    hideTextCallModal(data) {
        $('#newCallCreateModal').modal('hide');
        this.isShowNewCallModal = false;
    }

    getContactData(): Promise<any> {
        return this._contactService.contactLoad(this.encryptedUser, this.contactID)
            .then(async (result: Contact) => {
                if (result) {
                    this.contactData = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    toggleAccordians(id) {
        this.arrSortedBySection.forEach((ele, index) => {
            if (ele?.sectionId == id) {
                if (ele?.isActive) {
                    ele.isActive = false
                }
                else {
                    ele.isActive = true
                }
            }
        });

        this.setAccordianState()
    }

    expandCollapse() {
        if (this.isCollapse) {
            this.isCollapse = false
            this.arrSortedBySection.forEach((ele, index) => {
                ele.isActive = true
            })
        }
        else {
            this.isCollapse = true
            this.arrSortedBySection.forEach((ele, index) => {
                ele.isActive = false
            })
        }

        this.setAccordianState()
    }

    setAccordianState() {
        let obj = {
            leadId: this.leadId,
            arrSortedBySection: this.arrSortedBySection,
            isCollapse: this.isCollapse
        }

        this.accordianStates = JSON.parse(localStorage.getItem('accordianStatesByLeads')) ? JSON.parse(localStorage.getItem('accordianStatesByLeads')) : []

        if (this.accordianStates?.length == 0) {
            this.accordianStates.push(obj)
        }
        else {
            const index = this.accordianStates.findIndex(item => item.leadId == obj.leadId);
            if (index !== -1) {
                this.accordianStates[index] = obj;
            }
            else {
                this.accordianStates.push(obj)
            }
        }

        localStorage.setItem('accordianStatesByLeads', JSON.stringify(this.accordianStates))
    }

    getPageUp() {
        $("html, body").animate({
            scrollTop: $("body").offset().top = 0
        }, 0);
    }


    async updateMessage(messageID: number) {
        this._dashboardService.messageUpdate(this.encryptedUser, messageID)
            .then(async (result: MessageResponse) => {
                if (!isNullOrUndefined(result)) {
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lead.component", err.message, null, 'messageID: ' + messageID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getDDValue(item) {
        let displayValue = ''
        if (item && item?.items && item?.items?.length > 0) {
            displayValue = item?.items.filter((x) => x.value == item.fieldValue)[0]?.display
        }
        return displayValue
    }

    async getLoadUsers() {
        await this._userService.getCLPCompany_LoadUsers(this.encryptedUser, this.user?.cLPCompanyID, 1, false).
            then(async (result: UserDD[]) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.userList = response;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.getLoadUsers", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' permission: ' + 1
                    + ' includeNewUsers: ' + false
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }


    getUserName(value: number): string {
        let userName: string = "";
        if (this.userList?.length > 0) {
            userName = this.userList?.filter(x => x.value == value)[0]?.text;
        }
        return userName
    }


    pixString(val: string): string {
        let value = "";
        if (!isNullOrEmptyString(val))
            value = val.replace(/[^a-zA-Z0-9]/g, '');
        return value;
    }

    getApptFilters() {
        this.leadSettingService.apptFiltersGet(this.encryptedUser, this.user.cLPCompanyID, eApptCategory.Lead)
            .then(async (result: AppointmenTypeCodeByCategory[]) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.apptTypeCodes = response;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async getTagListByCompany() {
        await this._tagSettingService.tag_GetListByCLPCompany(this.encryptedUser, this.user?.cLPCompanyID, eNoteOwnerType.Lead)
            .then(async (result: TagData[]) => {
                if (!isNullOrUndefined(result)) {
                    this.tagListByCompany = UtilityService.clone(result);
                    this.bindTagList();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("Lead.getTagListByCompany", err.message, null,
                    'NoteOwnerType: ' + eNoteOwnerType.Lead
                    + 'cLPCompanyID: ' + this.user?.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    bindTagList() {
        this.tagListByCompany?.forEach(i => {
            const tagId = this.tagListByOwner?.filter(x => x.tagID == i?.tagID)[0]?.tagID;
            i.checked = !isNullOrUndefined(tagId) ? true : false;
        })
    }


    async getTagListByOwnerId(_leadId: number) {
        await this._tagSettingService.tagList_GetByOwner(this.encryptedUser, _leadId, eNoteOwnerType.Lead)
            .then(async (result: TagData[]) => {
                if (!isNullOrUndefined(result))
                    this.tagListByOwner = UtilityService.clone(result);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lead.getTagListByOwnerId", err.message, null,
                    'NoteOwnerType: ' + eNoteOwnerType.Lead
                    + 'leadID: ' + this.leadId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async addNewTag(value: string) {
        var tagData: TagsFields = <TagsFields>{};
        tagData.cLPCompanyID = this.user?.cLPCompanyID;
        tagData.ownerType = eNoteOwnerType.Lead;
        tagData.tag = value;
        tagData.tagID = 0;
        await this._tagSettingService.tagUpdate(this.encryptedUser, tagData)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result?.messageBool)
                        this.tagItemCreate(result?.messageInt);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lead.addNewTag", err.message, tagData,
                    ''
                );
                this._utilityService.handleErrorResponse(err);
            });
    }


    async tagItemCreate(tagId: number) {
        await this._tagSettingService.tagItem_Create(this.encryptedUser, tagId, this.leadId, eNoteOwnerType.Lead)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.notifyService.showSuccess(result.messageString, '', 3000);
                        this.selectedTagId = null;
                        await this.getTagListByOwnerId(this.leadId);
                        this.bindTagList();
                    } else {
                        this.notifyService.showError(result.errorMsg, '', 3000);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lead.tagItemCreate", err.message, null,
                    'NoteOwnerType: ' + eNoteOwnerType.Contact
                    + 'tagId: ' + tagId
                    + 'contactId: ' + this.leadId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async tagItemDelete(tagId: number) {
        this.showSpinner = true;
        await this._tagSettingService.tagItem_Delete(this.encryptedUser, tagId, this.leadId)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.notifyService.showSuccess(result?.messageString, '', 3000);
                        this.selectedTagId = null;
                        await this.getTagListByOwnerId(this.leadId);
                        this.bindTagList();
                    } else
                        this.notifyService.showError(result?.errorMsg, '', 3000);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false
                this._utilityService.handleErrorResponse(err);
            });
    }


    async getLeadConnectByOwner() {
        await this.leadSettingService.leadConnectGet(this.encryptedUser, this.leadId)
            .then(async (result: LeadConnectData[]) => {
                if (!isNullOrUndefined(result))
                    this.leadConnectionList = UtilityService.clone(result);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    copyConnectionValues() {
        this.leadConnect = <LeadConnect>{};
        this.leadConnect.contactID = this.contactIdForConnection;
        this.leadConnect.leadConnectID = this.leadId;
        this.leadConnect.relationship = this.connectionRelation;
    }

    getSearchData(txt, value: string = "") {
        let Search: Search = <Search>{};
        Search.searchText = txt;
        Search.searchValue = "";
        this.getContactSearch(Search);
    }

    getContactSearch(searchData: Search) {
        this.showSpinner = true;
        this.srchContactSrvc.getContactSearchData(this.encryptedUser, this.user?.cLPUserID, searchData)
            .then(async (result: SearchListResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    this.contactSearchList = response?.searchList?.filter(i => i.searchValue?.includes("ct"));
                    for (let i = 0; i < this.contactSearchList.length; i++) {
                        this.contactSearchList[i].searchValue = this.contactSearchList[i]?.searchValue.split("ct")[1]
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getContactId(value, str: string = "") {
        if (this.contactSearchList?.length > 0) {
            if (str == "connection") {
                this.contactIdForConnection = value != "" ? (parseInt(this.contactSearchList.find(item => item.searchText === value)?.searchValue)) : 0;
            } else {
                this.selectedcontactId = parseInt(this.contactSearchList.find(item => item.searchText === value)?.searchValue);
                this.isContactMandatory = false;
            }
        }
    }

    async createConnection() {
        this.copyConnectionValues();
        await this.leadSettingService.leadConnectSave(this.encryptedUser, this.leadConnect)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.notifyService.showSuccess('Connection Added Successfully', '', 3000);
                    this.connectionRelation = "";
                    this.contactIdForConnection = 0;
                    this.autocomplete.reset();
                    this.getLeadConnectByOwner();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lead.createConnection", err.message, this.leadConnect,
                    ' '
                );
                this._utilityService.handleErrorResponse(err);
            });
    }





}
