import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { c1686_PaymentIntentResponse, c1686_PlanResponse } from '../models/carefree.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class CarefreeService {
    private baseUrl: string;
    private api: string = "api/CareFree";

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async getPaymentIntentByContact(encryptedUser: string, contactID: number): Promise<c1686_PaymentIntentResponse | void> {
        const a = await this.httpClient
            .get<c1686_PaymentIntentResponse>(`${this.baseUrl}/GetPaymentIntentByContact/${contactID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "contactID - " + contactID, encryptedUser, "CareFreeService", "getPaymentIntentByContact"); }); //toPromise();
        return a;
    }

    async deletePaymentIntent(encryptedUser: string, contactID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/DeletePaymentIntent/${contactID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "contactID - " + contactID, encryptedUser, "CareFreeService", "deletePaymentIntent"); }); //toPromise();
        return a;
    }


    async getPlanList(encryptedUser: string, class4Code: number): Promise<c1686_PlanResponse | void> {
        const a = await this.httpClient
            .get<c1686_PlanResponse>(`${this.baseUrl}/GetPlanList/${class4Code}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "class4Code - " + class4Code, encryptedUser, "CareFreeService", "GetPlanList"); }); //toPromise();
        return a;
    }

    async createPaymentIntent(encryptedUser: string, contactID: number, selectedC4Code: number, amount: number, montlyFee: number, strDesc: string, blnAcceptACH: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CreatePaymentIntent/${contactID}/${selectedC4Code}/${amount}/${montlyFee}?strDesc=${strDesc}&blnAcceptACH=${blnAcceptACH}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "contactID - " + contactID, encryptedUser, "CareFreeService", "GetPlanList"); }); //toPromise();
        return a;
    }

}
