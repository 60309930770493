
<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../../assets/invoicestitle.svg" class="mr-1" />SalesOptima Billing Manager</div>
                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <div *ngIf="lblSummary != ''" [innerHTML]="lblSummary"></div>
                        <a class="contact-cursor-pointer" (click)="onBtnFreeTxt()">{{btnFreeTxt}}</a>
                        <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputClickSearch.value = ''">Reset Grid Setting</button>
                        <input class="" placeholder="Search in all columns..." kendoTextBox (input)="onBillingFilter($event.target.value)" #inputClickSearch />
                    </div>
                </div>
            </div>
            <div class="global-body-section">
                <div *ngIf="strMessage != ''" class="message-info" [innerHTML]="strMessage"></div>
                <div class="common-inner-cards">
                    <div class="inner-cards-grid">
                        <div class="flex-width2">
                            <div class="inner-card-mobile">
                                <div class="mobile-view-design inner-container">
                                    <div class="display-row">
                                        <span class="min-width125"><b>Next Billing Date:</b></span>&nbsp;
                                        <kendo-datepicker placeholder="" [(ngModel)]="startDate"></kendo-datepicker>
                                    </div>
                                    <div class="display-row">
                                        <span><b>End:</b></span>&nbsp;
                                        <kendo-datepicker placeholder="" [(ngModel)]="endDate"></kendo-datepicker>
                                    </div>
                                    <button type="button" class="btn btn-primary" (click)="onFilter()">Filter</button>
                                    <!--*ngIf="isShowBtnProcessAllListed"-->
                                    <button *ngIf="isShowBtnProcessAllListed" type="button" class="btn btn-primary all-listed" data-toggle="modal" data-target="#exampleModal">Process All Listed</button>
                                </div>
                            </div>
                        </div>
                        <div class="flex-width2">
                            <div class="inner-card-mobile">
                                <div class="mobile-view-design inner-container">
                                    <div class="display-row">
                                        <span><b>ID:	</b></span>&nbsp;
                                        <input type="text" class="form-control" [(ngModel)]="lookupId" />
                                    </div>
                                    <div class="display-row">
                                        <button type="button" class="btn btn-primary" (click)="onIdLookup()">Find</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="global-body-section">
                <div class="message-info">{{clpbillingSummary?.length}} accounts to check.</div>
                <kendo-grid class="slide-cast-grid" #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                            [kendoGridBinding]="clpbillingSummary"
                            [sortable]="{mode: 'multiple'}"
                            [sort]="_gridCnfgService.sort"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [scrollable]="'false'"
                            [reorderable]="true"
                            [resizable]="true"
                            [columnMenu]="{ filter: true }"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('admin-billing-manager-grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('admin-billing-manager-grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('admin-billing-manager-grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(6,'admin-billing-manager-grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'admin-billing-manager-grid',grid)">


                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title | titlecase"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [width]="column.width | stringToNumber"
                                       [filterable]="true"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                       [includeInChooser]="column.field=='$' ? false : true">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == '$'">{{ rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.field == 'cLPCompanyID'">{{ dataItem[column.field] }}</div>
                            <div class="customer-name" *ngIf="column.field == 'companyName'">{{ dataItem[column.field] }}</div>
                            <div class="customer-name" *ngIf="column.field == 'companySatus'">{{ getCompanyStatus(dataItem[column.field])}}</div>
                            <div class="customer-name" *ngIf="column.field == 'contract'">{{ getContractStatus(dataItem[column.field]) }}</div>
                            <div class="customer-name" *ngIf="column.field == 'dtContractExpire'">{{dataItem[column.field] | date: dateFormat}}</div>
                            <div class="customer-name" *ngIf="column.field == 'numUsers'">{{ dataItem[column.field] }}</div>
                            <div class="customer-name" *ngIf="column.field == 'dtNextBillDate'">{{dataItem[column.field] | date: dateFormat}}</div>
                            <div class="customer-name" *ngIf="column.field == 'strBalanceDue'">{{ dataItem[column.field] }}</div>
                            <div class="customer-name" *ngIf="column.field == 'strMonthlyFee'">{{ dataItem[column.field] }}</div>
                            <div class="customer-name" *ngIf="column.field == 'useCC'">{{ dataItem[column.field] }}</div>
                            <div class="customer-name" *ngIf="column.field == 'htmlFeeDisp'" [innerHTML]="dataItem.htmlFeeDisp"></div>
                            <div class="customer-name" *ngIf="column.field == 'due'" [innerHTML]="dataItem.due"></div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-command-column title="Action" [width]="40" min="40" [style]="{'text-align': 'center'}" [includeInChooser]="false" [reorderable]="false" [columnMenu]="false">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <button type="button" class="btn btn-primary" (click)="processCompanyId(dataItem.cLPCompanyID)">Process</button>
                        </ng-template>
                    </kendo-grid-command-column>
                </kendo-grid>

            </div>
        </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog  modal-common-dialog" role="document">
            <div class="modal-content modal-common-content">
                <div class="modal-header modal-common-background">
                    <h5 class="modal-title modal-common-title">Confirmation</h5>
                </div>
                <div class="modal-body modal-common-body">
                    <h5>
                        Richard, what are you doing!?!
                    </h5>
                </div>
                <div class="modal-footer">
                    <button type="button" (click)="processAll()" data-dismiss="modal" class="btn btn-primary">Confirm</button>
                    <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<account-billing-history *ngIf="user && selectedCompanyId > 0 && isRefreshHistory" [user]=user [onlyShowBillingHistory]="true" [companyIdBilling]="selectedCompanyId" [roleFeaturePermissions]="roleFeaturePermissions"></account-billing-history>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
