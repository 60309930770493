import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../../shared/utils.js';
import { isNullOrUndefined } from 'util';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { Contact } from '../../../../models/contact.model';
import { eCampaignStatus, eCampaignTemplateOwnerType, eEmailOptType, eFeatures, eMessageType, eMobileBlockType, eMobileOptType } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { mobileOptLog } from '../../../../models/note.model';
import { MobileBlockLog, MobileBlockLogListResponse, MobileOptLog, MobileOptLogListResponse, MobilePromoOptLog, MobilePromoOptLogListResponse, TxtMsgSettingLoadResponse, TxtMsgSettings } from '../../../../models/txtMsg.model';
import { CampaignService } from '../../../../services/campaign.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { TxtMsgService } from '../../../../services/textmsg.service';
import { GlobalService } from '../../../../services/global.service';

@Component({
    selector: 'app-mobile-opt-log',
    templateUrl: './mobile-opt-log.component.html',
    styleUrls: ['./mobile-opt-log.component.css']
})
export class MobileOptLogComponent implements OnInit {
    @Output() isCloseButton = new EventEmitter<boolean>();
    userResponse: UserResponse;
    @Input() contactData: Contact;
    showSpinner: boolean = false;
    private encryptedUser: string = '';
    @Input() user: CLPUser;
    optLogList: mobileOptLog[] = [];
    mobileOptLogList: MobileOptLog[] = [];
    mobilePromoOptLogList: MobilePromoOptLog[] = [];
    mobileBlockLogList: MobileBlockLog[] = [];
    txtMsgSettings: TxtMsgSettings = <TxtMsgSettings>{};
    strMessage: string = "";
    contactId: number = 0;
    mobileNumber: string = "";
    mobileBlockGetLastType: number = 0;
    mobileOptGetLastType: number = 0;
    isShowPnlOptInOptions: boolean = false;
    isShowBtnPnlOptIn: boolean = false;
    isShowBtnPnlOptOut: boolean = false;
    isShowBtnPnlBlock: boolean = false;
    btnPnlOptOutText: string = "Opt Out";
    cbisAllowVerbalOptinOptionText: string = "I confirm that I have received a verbal opt-in from this contact.";
    btnPnlBlockText: string = "Block";
    btnOptinVerbalText: string = "Contact Verbally Opted-In";
    lblTitle: string = "";
    trisAllowVerbalOptinOption: boolean = true;
    cbisAllowVerbalOptinOption: boolean = false;
    trOptinAP: boolean = false;
    dateFormat: string = 'MM/dd/yyyy hh:mm a';
    constructor(
        public _localService: LocalService,
        public _datePipe: DatePipe,
        private _txtMsgService: TxtMsgService,
        private _campaignService: CampaignService,
        private _globalService: GlobalService,
        private _utilityService: UtilityService,
        private _router: Router) {
    }


    ngOnInit(): void {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                if (this.user) {
                    this.dateFormat = this.user?.dateFormat;
                    this.dateFormat = this.dateFormat + ' ' + 'hh:mm a'
                    this.contactId = this.contactData?.contactID;
                    this.mobileNumber = this.contactData?.mobile;
                    this.setup();
                }
                else
                    this._router.navigate(['/login']);
            }
            else
                this._router.navigate(['/login']);
        });
    }

    setup() {
        this.isShowPnlOptInOptions = false;
        this.isShowBtnPnlOptIn = false;
        this.btnPnlOptOutText = "Opt Out";
        this.btnPnlBlockText = "Block";
        if (!isNullOrEmptyString(this.mobileNumber))
            this.bindData();
    }

    async bindData() {
        await this.mobileBlockLogGetLatestType();
        if (this.mobileBlockGetLastType == eMobileBlockType.Block) {
            this.btnPnlBlockText = "Un-Block";
            this.isShowBtnPnlBlock = true;
        }
        else
            this.btnPnlBlockText = "Block";

        await this.mobileOptLogGetLatestType();
        if (this.mobileOptGetLastType == eMobileOptType.OptOutByContact || this.mobileOptGetLastType == eMobileOptType.OptOutByUser)
            this.isShowBtnPnlOptOut = false;
        else
            this.isShowBtnPnlOptOut = true;

        if (this.mobileOptGetLastType == eMobileOptType.OptOutByUser || (this.user?.cLPCompanyID == 0 && this.mobileOptGetLastType == eMobileOptType.OptOutByContact)) {
            var blnShowbtnPnlOptIn: boolean = false;
            await this.txtMsgSettingLoadByCompany();
            this.trisAllowVerbalOptinOption = false;
            if (this.txtMsgSettings?.isAllowVerbalOptinOption) {
                this.trisAllowVerbalOptinOption = true;
                this.cbisAllowVerbalOptinOption = false;
                blnShowbtnPnlOptIn = true;
            }
            this.trOptinAP = false;
            if (this.contactId > 0 && this.txtMsgSettings?.optinAPID > 0) {
                this.trOptinAP = true;
                blnShowbtnPnlOptIn = true;
            }
            this.isShowBtnPnlOptIn = blnShowbtnPnlOptIn;
        } else
            this.isShowBtnPnlOptIn = false;

        await this.mobileOptLogGetList();
        await this.mobilePromoOptLogGetList();
        await this.mobileBlockLogGetList();
        this.bindOptLogList();
        this.lblTitle = this._localService.FixPhoneNumber(this.mobileNumber) + " Opt In/Out Events";
    }

    async txtMsgSettingLoadByCompany() {
        this.showSpinner = true;
        await this._txtMsgService.txtMsgSettingLoadByCompany(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: TxtMsgSettingLoadResponse) => {
                if (result) {
                    this.txtMsgSettings = UtilityService.clone(result?.txtMsgSettings);
                    this.showSpinner = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("mobile-opt-log.txtMsgSettingLoadByCompany", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });

    }

    async mobileBlockLogGetLatestType() {
        this.showSpinner = true;
        await this._txtMsgService.mobileBlockLogGetLatestType(this.encryptedUser, this.user?.cLPCompanyID, this.mobileNumber)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this.mobileBlockGetLastType = UtilityService.clone(result?.messageInt);
                    this.showSpinner = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("mobile-opt-log.mobileBlockLogGetLatestType", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    +' mobileNumber: ' + this.mobileNumber
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async mobileOptLogGetLatestType() {
        this.showSpinner = true;
        await this._txtMsgService.mobileOptLogGetLatestType(this.encryptedUser, this.user?.cLPCompanyID, this.mobileNumber)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    this.mobileOptGetLastType = UtilityService.clone(result?.messageInt);
                    this.showSpinner = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("mobile-opt-log.mobileOptLogGetLatestType", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' mobileNumber: ' + this.mobileNumber
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async mobileOptLogGetList() {
        this.showSpinner = true;
        await this._txtMsgService.mobileOptLogGetList(this.encryptedUser, this.user?.cLPCompanyID, this.mobileNumber)
            .then(async (result: MobileOptLogListResponse) => {
                if (result) {
                    this.mobileOptLogList = UtilityService.clone(result.mobileOptLogList);
                    this.showSpinner = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("mobile-opt-log.mobileOptLogGetList", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' mobileNumber: ' + this.mobileNumber
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async mobilePromoOptLogGetList() {
        this.showSpinner = true;
        await this._txtMsgService.mobilePromoOptLogGetList(this.encryptedUser, this.user?.cLPCompanyID, this.mobileNumber)
            .then(async (result: MobilePromoOptLogListResponse) => {
                if (result) {
                    this.mobilePromoOptLogList = UtilityService.clone(result.mobilePromoOptLogList);
                    this.showSpinner = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("mobile-opt-log.mobilePromoOptLogGetList", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' mobileNumber: ' + this.mobileNumber
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    bindOptLogList() {
        this.showSpinner = true;
        this.optLogList = [];
        this.mobileOptLogList?.forEach(x => {
            this.optLogList?.push({ catDisplay: 'Info', typeDisplay: x.typeDisplay, notes: x.notes, dtCreated: this._datePipe.transform(new Date(x.dtCreated), this.dateFormat) })
        });
        this.mobilePromoOptLogList?.forEach(x => {
            this.optLogList?.push({ catDisplay: 'Promo', typeDisplay: x.typeDisplay, notes: x.notes, dtCreated: this._datePipe.transform(new Date(x.dtCreated), this.dateFormat) })
        });
        this.mobileBlockLogList?.forEach(x => {
            this.optLogList?.push({ catDisplay: 'Block', typeDisplay: x.typeDisplay, notes: x.notes, dtCreated: this._datePipe.transform(new Date(x.dtCreated), this.dateFormat) })
        });

        this.showSpinner = false;
    }

    async mobileBlockLogGetList() {
        this.showSpinner = true;
        await this._txtMsgService.mobileBlockLogGetList(this.encryptedUser, this.user?.cLPCompanyID, this.mobileNumber)
            .then(async (result: MobileBlockLogListResponse) => {
                if (result) {
                    this.mobileBlockLogList = UtilityService.clone(result.mobileBlockLogList);
                    this.showSpinner = false;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("mobile-opt-log.mobileBlockLogGetList", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' mobileNumber: ' + this.mobileNumber
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async mobileOptLogCreate(type: eMobileOptType, notes: string) {
        await this._txtMsgService.mobileOptLogCreate(this.encryptedUser, this.user?.cLPCompanyID, this.mobileNumber, type, notes)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("mobile-opt-log.mobileOptLogCreate", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' mobileNumber: ' + this.mobileNumber
                    + ' type: ' + type
                    + ' notes: ' + notes
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async mobilePromoOptLogCreate(type: eMobileOptType, notes: string) {
        await this._txtMsgService.mobilePromoOptLogCreate(this.encryptedUser, this.user?.cLPCompanyID, this.mobileNumber, type, notes)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("mobile-opt-log.mobilePromoOptLogCreate", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' mobileNumber: ' + this.mobileNumber
                    + ' type: ' + type
                    + ' notes: ' + notes
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async mobileBlockLogCreate(type: eMobileBlockType, notes: string) {
        await this._txtMsgService.mobileBlockLogCreate(this.encryptedUser, this.user?.cLPCompanyID, this.mobileNumber, type, notes)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("mobile-opt-log.mobileBlockLogCreate", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' mobileNumber: ' + this.mobileNumber
                    + ' type: ' + type
                    + ' notes: ' + notes
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async processBatchTrigger(eAction: eCampaignStatus, campaignTemplateID: number, eItemType: eCampaignTemplateOwnerType = eCampaignTemplateOwnerType.Contact) {
        await this._campaignService.processBatchTrigger(this.encryptedUser, this.user?.cLPCompanyID, eAction, campaignTemplateID, this.user?.cLPUserID, this.contactId, eItemType)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("mobile-opt-log.processBatchTrigger", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' cLPUserID: ' + this.user?.cLPUserID
                    + ' campaignTemplateID: ' + campaignTemplateID
                    + ' contactId: ' + this.contactId
                    + ' eItemType: ' + eItemType
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    setMessage(value: string = "", eType: eMessageType = eMessageType.Info) {
        if (!isNullOrEmptyString(value)) {
            switch (eType) {
                case eMessageType.Info:
                    this.strMessage = "<div class='alert alert-primary mb-0'><strong>" + value + "</strong></div>";
                    break;
                case eMessageType.StopAction:
                    this.strMessage = "<div class='alert alert-danger mb-0'><strong>" + value + "</strong></div>";
                    break;
                case eMessageType.Warning:
                    this.strMessage = "<div class='alert alert-warning mb-0'><strong>" + value + "</strong></div>";
                    break;
                case eMessageType.Success:
                    this.strMessage = "<div class='alert alert-success mb-0'><strong>" + value + "</strong></div>";
                    break;
            }
        } else
            this.strMessage = "";
    }

    async btnOptOutClick() {
        switch (this.btnPnlOptOutText) {
            case "Opt Out":
                this.setMessage("Please confirm request to <i>Opt Out</i> text engagement for this mobile number?", eMessageType.Warning);
                this.btnPnlOptOutText = "Confirm Opt Out";
                break;
            default:
                await this.mobileOptLogCreate(eMobileOptType.OptOutByUser, "Opted out by " + this.user?.firstName + ' ' + this.user?.lastName + " from App");
                await this.mobilePromoOptLogCreate(eMobileOptType.OptOutByUser, "Promo Opted out by " + this.user?.firstName + ' ' + this.user?.lastName + " from App");
                this.setup();
                this.setMessage("Mobile number has been <i>Opted Out</i>.");
                break;
        }
    }

    btnBlockClick() {
        switch (this.btnPnlBlockText) {
            case "Block":
                if (!this._localService.StripPhone(this.mobileNumber)?.includes("8887817363")) {
                    this.setMessage("Please confirm request to <i>Block</i> voice/text engagement for this mobile number?", eMessageType.Warning);
                    this.btnPnlBlockText = "Confirm Block";
                } else
                    this.setMessage("Unfortunately, this number cannot be <i>Blocked</i>.", eMessageType.Warning);
                break;
            case "Un-Block":
                this.mobileBlockLogCreate(eMobileBlockType.UnBlock, "Un-Blocked by " + this.user?.firstName + ' ' + this.user?.lastName);
                this.setup();
                this.setMessage("Mobile number has been <i>Un-Blocked</i>.", eMessageType.Warning);
                break;
            case "Confirm Block":
                this.mobileBlockLogCreate(eMobileBlockType.Block, "Blocked by " + this.user?.firstName + ' ' + this.user?.lastName);
                this.setup();
                this.setMessage("Mobile number has been <i>Blocked</i>.", eMessageType.Warning);
                break;
        }
    }

    btnOptInClick() {
        this.isShowPnlOptInOptions = true;
        this.cbisAllowVerbalOptinOptionText = "By checking this box, I confirm that " + this._localService.FixPhoneNumber(this.mobileNumber) + " has provided express consent to receive text messages."
        this.btnOptinVerbalText = "Opt-In " + this._localService.FixPhoneNumber(this.mobileNumber);
    }

    btnOptinVerbalClick() {
        if (this.cbisAllowVerbalOptinOption) {
            this.mobileOptLogCreate(eMobileOptType.OptIn, "Express consent confirmed by " + this.user?.firstName + ' ' + this.user?.lastName);
            this.setup();
            this.setMessage("Mobile number has been <i>Opted In</i>.");
        }
        else
            this.setMessage("Please confirm that you have received express consent by checking the box.");
    }

    btnOptinAPClick() {
        if (this.contactId > 0 && this.txtMsgSettings?.optinAPID > 0) {
            this.processBatchTrigger(eCampaignStatus.Active, this.txtMsgSettings?.optinAPID, eCampaignTemplateOwnerType.Contact);
            this.isShowPnlOptInOptions = false;
            this.setMessage("Contact has been added to Opt-In Request automation process.");
        }
    }

    onClose() {
        this.isCloseButton.emit(true);
    }
}
