import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { SOMetricListResponse, SOMetricModel } from '../../../models/so-metric.model';
import { AuthenticateService } from '../../../services/authenticate.service';
import { GlobalService } from '../../../services/global.service';
import { NotificationService } from '../../../services/notification.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { isNullOrUndefined, UtilityService } from '../../../services/shared/utility.service';
import { SoMetricService } from '../../../services/so-metric.service';
import { UserService } from '../../../services/user.service';

declare var $: any

@Component({
    selector: 'app-so-metric',
    templateUrl: './so-metric.component.html',
    styleUrl: './so-metric.component.css',
    providers: [GridConfigurationService]
})
export class SoMetricComponent implements OnInit {
    encryptedUser: string = '';
    userResponse: UserResponse;
    user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    @Input() contactId: number = 0;
    editedRowIndex: number;
    sOMetricList: SOMetricModel[];
    sOMetricID: number = 0;
    contactID: number;
    metricName: string;
    metricValue: string;
    dtCreated: string;
    columns = [
        { field: '$', title: ' ', width: '40' },
        { field: 'metricName', title: 'Metric', width: '40' },
        { field: 'metricValue', title: 'Value', width: '120' },
        { field: 'dtCreated', title: 'Created', width: '120' },
    ];

    reorderColumnName: string = 'metricName,metricValue,dtCreated';
    columnWidth: string = 'metricName:109,metricValue:114,dtCreated:114';
    arrColumnWidth: any = ['metricName:109,metricValue:114,dtCreated:114'];
    @Output() isCloseButton = new EventEmitter<boolean>();
    constructor(
        private _utilityService: UtilityService,
        public _localService: LocalService,
        private _route: ActivatedRoute,
        private notifyService: NotificationService,
        private _router: Router,
        private _userService: UserService,
        private _authenticateService: AuthenticateService,
        private soMetricService: SoMetricService,
        public _gridCnfgService: GridConfigurationService,
        private _globalService: GlobalService,) {

    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(async () => {
                    if (this.user) {
                        this.sOMetric_LoadByContactID();
                    }
                    else {
                        this._router.navigate(['/login']);
                    }
                });
            }
            else {
                this._router.navigate(['/login']);
            }
        });
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.authenticateR", err.message, null,
                    'Feature: ' + eFeatures.ViewMyContacts
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async sOMetric_LoadByContactID() {
        await this.soMetricService.sOMetric_LoadByContactID(this.encryptedUser, this.user.cLPCompanyID, this.user?.cLPUserID, this.contactId)
            .then(async (result: SOMetricListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.sOMetricList = response?.sOMetricList
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-new-search.getContactSearch", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async sOMetric_Delete() {
        await this.soMetricService.sOMetric_Delete(this.encryptedUser, this.user.cLPCompanyID, this.user?.cLPUserID, this.sOMetricID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response?.messageBool) {
                        this.cancel();
                        this.notifyService.showSuccess("SOMetric Deleted", "", 3000);
                    }
                    else {
                        this.notifyService.showError("Some error occured. Try Again.", "", 3000);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-new-search.getContactSearch", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }
   

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'so_metric_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('so_metric_grid').subscribe((value) => { }));
    }

    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'so_metric_grid').subscribe((value) => this.getGridConfiguration());
    }

    public editHandlerNewUser({ sender, rowIndex, dataItem }) {
        this.closeEditorNewUser(sender);
        sender.editRow(rowIndex);
    }

    public cancelHandlerNewUser({ sender, rowIndex }) {
        this.closeEditorNewUser(sender, rowIndex);
    }

    private closeEditorNewUser(grid, rowIndex = this.editedRowIndex) {
        grid.closeRow(rowIndex);
        this.editedRowIndex = undefined;
    }

    public saveHandlerNewUser({ sender, rowIndex, dataItem }): void {
        sender.closeRow(rowIndex);
        this.soMetricService.sOMetric_UpdateValue(this.encryptedUser, this.user.cLPCompanyID, this.user?.cLPUserID, this.contactId, dataItem?.metricValue)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response?.messageBool) {
                        this.notifyService.showSuccess("SOMetric Value Updated", "", 3000);
                    }
                    else {
                        this.notifyService.showError("Some error occured. Try Again.", "", 3000);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("so-metric.saveHandlerNewUser", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    
    onClose() {
        this.isCloseButton.emit(true);
    }

    public removeHandler({ dataItem }): void {
        this.sOMetricID = dataItem?.sOMetricID;
    }

    cancel() {
        $('#confirmDeleteModal').modal('hide');
    }

}

