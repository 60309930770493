import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApptTwoDimensionResponse, CallActionScreenResponse, CompanyTwoDimensionResponse, ContactDisByClassifictaionResponse, ContactDisByManagerResponse, ContactTwoDimensionResponse, DistributionResponse, NoteTypeSummaryResponse, QualCallReportResponse, userListResponse, TxtMsgLogResponse, VoiceCallRptResponse, RevenueByMonthResponse, LeadTwoDimensionResponse, LeadInvoiceResponse, ScoreHistoryResponse, ReferrerReportResponse, ReferralReportResponse, BuzzCoreFilterResponse, BuzzScoreResponse, CLPUserFilterResponse, ClickCountResponse, IHApptSetter, SelectedUserSummaryData, TagModel, ApptTypeSummaryResponse, LoadSecuredUserResponse, SAMVoiceCallFilterResponse, SAMVoiceCallReportObj, LeadInvoiceFilterResponse, RevenueByManagerFilterResponse, ProjectRevenueListResponse, RevenueByClassificationFilterResponse, BIPClickByApptSetterResponse, DuplicateLeadsResponse, ScorecardResponse, ScorecardFilterResponse, ScoreCardByApptSetterResponse, ScorecardByCodeFilterResponse, ScoreCardByCodeResponse, ScorecardByKeywordFilterResponse, ScoreCardByKeywordResponse, ScoreCardByTVSourceResponse, SkypeCallAttemptByContactResponse, VoiceCallInboundFilterResponse, VoiceCallInboundResponse, LeadSearchResultGroupedresponse, ContactSearchResultObj, ContactSearchResultResponse, IHApptSetterActivityResponse, ResponseTimeFilterResponse, ResponseTimeDataResponse, EmailOpenRateReportResponse, NonApptSettersUsersResponse, ContactSearchSetupResponse, ScorecardCompareResponse, RevenueByMonthFilterResponse, ClpUserFilterResponse, SendResultToNewObj, LeadStatusListResponse } from '../models/report.model';
import { Contact, ContactResponse } from '../models/contact.model';
import { ExportRequest, ExportRequestSaveObj } from '../models/exportRequest.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';
import { keyValue } from '../models/search.model';
import { Contact2_eSplit, eCompanyType, eMeasure, eReportTimeType, eRptFilterBy, eSplit_LeadSnapshot, SearchContactBy } from '../models/enum.model';
import { LoadUserObj, LoadUserResponse } from '../models/clpuser.model';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    private baseUrl: string;
    private api: string = "api/Report";
    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async getApptTypeSummary(encryptedUser: string, clpUserID: number, clpCompanyId: number, appCategory: number, apptTypeSummary: number, fromDate: string, toDate: string): Promise<ApptTypeSummaryResponse | void> {
        const a = await this.httpClient
            .get<ApptTypeSummaryResponse>(`${this.baseUrl}/GetAppointmentTypeSummary/${clpCompanyId}/${clpUserID}?fromDate=${fromDate}&toDate=${toDate}&apptTypeSummary=${apptTypeSummary}&appCategory=${appCategory}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserId - " + clpUserID + "," + "clpCompanyId - " + clpCompanyId + "," + "appCategory - " + appCategory + "fromDate - " + fromDate + "toDate - " + toDate, encryptedUser, "ReportService", "getApptTypeSummary"); });
        return a;
    }

    async getApptTwoDimensionFilter(encryptedUser: string, clpCompanyId: number, clpUserID: number): Promise<ApptTwoDimensionResponse | void> {
        const a = await this.httpClient
            .get<ApptTwoDimensionResponse>(`${this.baseUrl}/GetApptTwoDimensionFilter/${clpCompanyId}/${clpUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID, encryptedUser, "ReportService", "getApptTwoDimensionFilter"); });
        return a;
    }

    async getApptTwoDimensionFields(encryptedUser: string, clpCompanyId: number, clpUserID: number, rows: number, column: number, startDate: string, endDate: string, status: number, strUsers: string[]): Promise<ApptTwoDimensionResponse | void> {
        const a = await this.httpClient
            .post<ApptTwoDimensionResponse>(`${this.baseUrl}/BindApptTwoDimensionData/${clpCompanyId}/${clpUserID}?startDate=${startDate}&endDate=${endDate}&status=${status}&rowsSelValue=${rows}&columnSelValue=${column}`, strUsers, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, strUsers, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID + "," + "rows - " + rows + "," + "column - " + column + "," + "startDate - " + startDate + "," + "endDate - " + endDate + "," + "status - " + status, encryptedUser, "ReportService", "getApptTwoDimensionFields"); });
        return a;
    }

    async getApptQualCallList(encryptedUser: string, clpCompanyId: number, clpUserID: number, startDate: string, endDate: string, weekView: boolean): Promise<QualCallReportResponse | void> {
        const a = await this.httpClient
            .get<QualCallReportResponse>(`${this.baseUrl}/GetApptQualCallResponse/${clpCompanyId}/${clpUserID}?startDate=${startDate}&endDate=${endDate}&weekView=${weekView}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID + "," + "startDate - " + startDate + "," + "endDate - " + endDate + "," + "weekView - " + weekView, encryptedUser, "ReportService", "getApptQualCallList"); });
        return a;
    }

    async loadSecuredUsers(encryptedUser: string, clpCompanyId: number, clpUserID: number): Promise<LoadSecuredUserResponse | void> {
        const a = await this.httpClient
            .get<LoadSecuredUserResponse>(`${this.baseUrl}/LoadSecuredUsers/${clpCompanyId}/${clpUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID, encryptedUser, "ReportService", "loadSecuredUsers"); });
        return a;
    }
    async getNoteTypeSummaryList(encryptedUser: string, clpCompanyId: number, clpUserID: number, strDate: string, reportTimeType: eReportTimeType = eReportTimeType.Day): Promise<NoteTypeSummaryResponse | void> {
        const a = await this.httpClient
            .get<NoteTypeSummaryResponse>(`${this.baseUrl}/GetNoteTypeSummaryResponse/${clpCompanyId}/${clpUserID}?strDate=${strDate}&reportTimeType=${reportTimeType}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID + "," + "strDate - " + strDate + "," + "reportTimeType - " + reportTimeType, encryptedUser, "ReportService", "getNoteTypeSummaryList"); });
        return a;
    }
    async getClickReportList(encryptedUser: string, clpCompanyId: number, clpUserID: number, strDate: string): Promise<CallActionScreenResponse | void> {
        const a = await this.httpClient
            .get<CallActionScreenResponse>(`${this.baseUrl}/GetClickReportResponse/${clpCompanyId}/${clpUserID}?strDate=${strDate}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID + "," + "strDate - " + strDate, encryptedUser, "ReportService", "getClickReportList"); });
        return a;
    }
    async getEmaiOpenReportList(encryptedUser: string, clpCompanyId: number, clpUserId: number, selUserId: number, startDate: string, endDate: string, category: string): Promise<EmailOpenRateReportResponse | void> {
        const a = await this.httpClient
            .get<EmailOpenRateReportResponse>(`${this.baseUrl}/GetListEmailOpenRateResponse/${clpCompanyId}/${clpUserId}/${selUserId}?startDate=${startDate}&endDate=${endDate}&category=${category}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserId + "," + "selUserId - " + selUserId + "startDate - " + startDate + "endDate - " + endDate + "category - " + category, encryptedUser, "ReportService", "getEmaiOpenReportList"); });
        return a;
    }

    async getCompany2DFilters(encryptedUser: string, clpCompanyId: number, clpUserID: number): Promise<CompanyTwoDimensionResponse | void> {
        const a = await this.httpClient
            .get<CompanyTwoDimensionResponse>(`${this.baseUrl}/GetCompanyTwoDimensionFilter/${clpCompanyId}/${clpUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID, encryptedUser, "ReportService", "getCompany2DFilters"); });
        return a;
    }
    async getUserList(encryptedUser: string, loadUserObj: LoadUserObj): Promise<LoadUserResponse | void> {
        const a = await this.httpClient
            .post<LoadUserResponse>(`${this.baseUrl}/LoadUsers`, loadUserObj, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "loadUserObj - " + loadUserObj, encryptedUser, "ReportService", "getUserList"); });
        return a;
    }
    async getCompany2dReportData(encryptedUser: string, clpCompanyId: number, rows: number, column: number, startDate: string, endDate: string, strUsers: string): Promise<CompanyTwoDimensionResponse | void> {
        const a = await this.httpClient
            .post<CompanyTwoDimensionResponse>(`${this.baseUrl}/BindCompanyTwoDimensionData/${clpCompanyId}/${rows}/${column}?startDate=${startDate}&endDate=${endDate}`, strUsers, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, strUsers, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "rows - " + rows + "," + "column - " + column + "," + "startDate - " + startDate + "," + "endDate - " + endDate, encryptedUser, "ReportService", "getCompany2dReportData"); });
        return a;
    }

    async getDistByManager(encryptedUser: string, clpCompanyId: number, clpUserID: number): Promise<ContactDisByManagerResponse | void> {
        const a = await this.httpClient
            .get<ContactDisByManagerResponse>(`${this.baseUrl}/GetContactDisByManagerFilter/${clpCompanyId}/${clpUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID, encryptedUser, "ReportService", "getDistByManager"); });
        return a;
    }
    // Common for  DistributionByClassification & DistributionByManager
    async getDistributionByClassificationBindReport(encryptedUser: string, clpCompanyId: number, includeZeroes: boolean, selectedUserId: number, startDt: string, endDt: string, ddClass: number): Promise<DistributionResponse | void> {
        const a = await this.httpClient
            .get<DistributionResponse>(`${this.baseUrl}/BindDistributionByClassificationData/${clpCompanyId}/${includeZeroes}/${selectedUserId}/${ddClass}?startDate=${startDt}&endDate=${endDt}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "includeZeroes - " + includeZeroes + "," + "selectedUserId - " + selectedUserId + "," + "startDt - " + startDt + "," + "endDt - " + endDt + "," + "ddClass - " + ddClass, encryptedUser, "ReportService", "getDistributionByClassificationBindReport"); });
        return a;
    }
    async getContactDistByClassificationFilter(encryptedUser: string, clpCompanyId: number, clpUserID: number): Promise<ContactDisByClassifictaionResponse | void> {
        const a = await this.httpClient
            .get<ContactDisByClassifictaionResponse>(`${this.baseUrl}/GetContactDisByClassificationFilter/${clpCompanyId}/${clpUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID, encryptedUser, "ReportService", "getContactDistByClassificationFilter"); });
        return a;
    }

    async getContactDisTwoDimension(encryptedUser: string, clpCompanyId: number, clpUserID: number): Promise<ContactTwoDimensionResponse | void> {
        const a = await this.httpClient
            .get<ContactTwoDimensionResponse>(`${this.baseUrl}/GetContactDisTwoDimensionFilter/${clpCompanyId}/${clpUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID, encryptedUser, "ReportService", "getContactDisTwoDimension"); });
        return a;
    }

    async getContactTwoDimensionBindReport(encryptedUser: string, clpCompanyId: number, clpUserID: number, rows: number, column: number, startDate: string, endDate: string, dateFilter: string, includeZeroes: boolean, strUsers: string[]): Promise<ContactTwoDimensionResponse | void> {
        const a = await this.httpClient
            .post<ContactTwoDimensionResponse>(`${this.baseUrl}/BindContactTwoDimensionData/${clpCompanyId}/${rows}/${column}?startDate=${startDate}&endDate=${endDate}&dateFilter=${dateFilter}&includeZeroes=${includeZeroes}`, strUsers, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, strUsers, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID + "," + "rows - " + rows + "," + "column - " + column + "," + "startDate - " + startDate + "," + "endDate - " + endDate + "," + "dateFilter - " + dateFilter + "," + "includeZeroes - " + includeZeroes, "ReportService", "getContactTwoDimensionBindReport"); });
        return a;
    }

    async getTextMsgLogReport(encryptedUser: string, clpCompanyId: number, clpUserID: number, startDate: string, endDate: string, rptType: string, msgStatus: number, class1Code: string, contactId: number = 0): Promise<TxtMsgLogResponse | void> {
        const a = await this.httpClient
            .get<TxtMsgLogResponse>(`${this.baseUrl}/GetTxtMsgLogResponse/${clpCompanyId}/${rptType}?clpUserId=${clpUserID}&startDate=${startDate}&endDate=${endDate}&msgStatus=${msgStatus}&contactId=${contactId}&class1Code=${class1Code}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID + "," + "startDate - " + startDate + "," + "endDate - " + endDate + "," + "rptType - " + rptType + "," + "msgStatus - " + msgStatus + "," + "contactId - " + contactId, encryptedUser, "ReportService", "getTextMsgLogReport"); });
        return a;
    }

    async getSAMVoiceCallFilter(encryptedUser: string, clpCompanyId: number, clpUserID: number): Promise<SAMVoiceCallFilterResponse | void> {
        const a = await this.httpClient
            .get<SAMVoiceCallFilterResponse>(`${this.baseUrl}/GetSAMVoiceCallFilter/${clpCompanyId}/${clpUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID, encryptedUser, "ReportService", "getSAMVoiceCallFilter"); });
        return a;
    }

    async getSkypeCallAttemptsByContact(encryptedUser: string, clpCompanyId: number, clpUserID: number, startDate: string, endDate: string): Promise<SkypeCallAttemptByContactResponse | void> {
        const a = await this.httpClient
            .get<SkypeCallAttemptByContactResponse>(`${this.baseUrl}/GetSkypeCallAttemptsByContact/${clpCompanyId}?startDate=${startDate}&endDate=${endDate}&clpUserId=${clpUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID + "," + "startDate - " + startDate + "," + "endDate - " + endDate, encryptedUser, "ReportService", "GetSkypeCallAttemptsByContact"); });
        return a;
    }

    async getSKyNoCallAttemptsByContact(encryptedUser: string, clpCompanyId: number, clpUserID: number, startDate: string, endDate: string): Promise<SkypeCallAttemptByContactResponse | void> {
        const a = await this.httpClient
            .get<SkypeCallAttemptByContactResponse>(`${this.baseUrl}/GetSKyNoCallAttemptsByContact/${clpCompanyId}?startDate=${startDate}&endDate=${endDate}&clpUserId=${clpUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID + "," + "startDate - " + startDate + "," + "endDate - " + endDate, encryptedUser, "ReportService", "GetSKyNoCallAttemptsByContact"); });
        return a;
    }

    async getSKyNoCallAttemptsCount(encryptedUser: string, clpCompanyId: number, clpUserID: number, startDate: string, endDate: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/GetSKyNoCallAttemptsCount/${clpCompanyId}?startDate=${startDate}&endDate=${endDate}&clpUserId=${clpUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID + "," + "startDate - " + startDate + "," + "endDate - " + endDate, encryptedUser, "ReportService", "getSKyNoCallAttemptsCount"); });
        return a;
    }

    async getVoiceCallInboundFilterResponse(encryptedUser: string, clpCompanyId: number, clpUserID: number): Promise<VoiceCallInboundFilterResponse | void> {
        const a = await this.httpClient
            .get<VoiceCallInboundFilterResponse>(`${this.baseUrl}/GetVoiceCallInboundFilterResponse/${clpCompanyId}/${clpUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID, encryptedUser, "ReportService", "getVoiceCallInboundFilterResponse"); });
        return a;
    }

    async getVoiceCallInboundResponse(encryptedUser: string, clpCompanyId: number, clpUserID: number, startDate: string, endDate: string, officeCode: number, teamCode: number, selWeekendFilter: number): Promise<VoiceCallInboundResponse | void> {
        const a = await this.httpClient
            .get<VoiceCallInboundResponse>(`${this.baseUrl}/GetVoiceCallInboundResponse/${clpCompanyId}?startDate=${startDate}&endDate=${endDate}&clpUserId=${clpUserID}&officeCode=${officeCode}&teamCode=${teamCode}&selWeekendFilter=${selWeekendFilter}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID + "," + "startDate - " + startDate + "," + "endDate - " + endDate + "," + "officeCode - " + officeCode + "," + "teamCode - " + teamCode + "," + "selWeekendFilter - " + selWeekendFilter, encryptedUser, "ReportService", "getVoiceCallInboundResponse"); });
        return a;
    }

    async getVoiceCallSummaryReport(encryptedUser: string, samVoiceCallReporttObj: SAMVoiceCallReportObj): Promise<VoiceCallRptResponse | void> {
        const a = await this.httpClient
            .post<VoiceCallRptResponse>(`${this.baseUrl}/BindVoiceCallSummary`, samVoiceCallReporttObj, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, samVoiceCallReporttObj, "r - " + encryptedUser, encryptedUser, "ReportService", "getVoiceCallSummaryReport"); });
        return a;
    }

    async getLeadInvoiceFilter(encryptedUser: string, clpCompanyId: number, clpUserID: number, leadId: number = 0): Promise<LeadInvoiceFilterResponse | void> {
        const a = await this.httpClient
            .get<LeadInvoiceFilterResponse>(`${this.baseUrl}/GetLeadInvoiceFilterResponse/${clpCompanyId}/${clpUserID}?leadId=${leadId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserID - " + clpUserID + "," + "leadId - " + leadId, encryptedUser, "ReportService", "getLeadInvoiceFilter"); });
        return a;
    }

    async getLeadInvoiceReport(encryptedUser: string, clpCompanyId: number, startDate: string, endDate: string, clpUserID: number, leadId: number, est: number, leadStatusCode: string): Promise<LeadInvoiceResponse | void> {
        const a = await this.httpClient
            .get<LeadInvoiceResponse>(`${this.baseUrl}/LeadInvoice_GetList/${clpCompanyId}?startDate=${startDate}&endDate=${endDate}&clpUserId=${clpUserID}&leadId=${leadId}&eStatus=${est}&leadStatusCode=${leadStatusCode}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserID - " + clpUserID + "," + "leadId - " + leadId + "," + "est - " + est + "," + "leadStatusCode - " + leadStatusCode + "," + "startDate - " + startDate + "," + "endDate - " + endDate, encryptedUser, "ReportService", "getLeadInvoiceReport"); });
        return a;
    }

    async getProjectRevenueManagerFilter(encryptedUser: string, clpCompanyId: number, clpuserId: number): Promise<RevenueByManagerFilterResponse | void> {
        const a = await this.httpClient
            .get<RevenueByManagerFilterResponse>(`${this.baseUrl}/GetProjectRevenueByManagerFilter/${clpCompanyId}/${clpuserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpuserId - " + clpuserId, encryptedUser, "ReportService", "getProjectRevenueManagerFilter"); });
        return a;
    }

    async getProjectRevenueClassificationFilter(encryptedUser: string, clpCompanyId: number, clpuserId: number): Promise<RevenueByClassificationFilterResponse | void> {
        const a = await this.httpClient
            .get<RevenueByClassificationFilterResponse>(`${this.baseUrl}/GetProjectRevenueByClassificationFilter/${clpCompanyId}/${clpuserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpuserId - " + clpuserId, encryptedUser, "ReportService", "getProjectRevenueClassificationFilter"); });
        return a;
    }

    async getProjectRevenueManager(encryptedUser: string, clpCompanyId: number, clpuserId: number, dtStart: string, dtEnd: string, useSpecificDates: boolean = false, numberOfMonths: number = 3, split: eSplit_LeadSnapshot = eSplit_LeadSnapshot.Company, filterBy: eRptFilterBy = eRptFilterBy.User, filterValue: number = 0): Promise<ProjectRevenueListResponse | void> {
        const a = await this.httpClient
            .get<ProjectRevenueListResponse>(`${this.baseUrl}/Bind_ProjectRevenueByManagerClassification/${clpCompanyId}/${clpuserId}?startDate=${dtStart}&endDate=${dtEnd}&useSpecificDates=${useSpecificDates}&numberOfMonths=${numberOfMonths}&split=${split}&filterBy=${filterBy}&filterValue=${filterValue}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "split - " + split + "," + "dtStart - " + dtStart + "," + "dtEnd - " + dtEnd + "," + "useSpecificDates - " + useSpecificDates + "," + "filterBy - " + filterBy + "," + "filterValue - " + filterValue, encryptedUser, "ReportService", "getprojectRevenueManager"); });
        return a;
    }

    async getUsersDD(encryptedUser: string, clpCompanyId: number, clpUserID: number, officeCode: number, teamCode: number): Promise<userListResponse | void> {
        const a = await this.httpClient
            .get<userListResponse>(`${this.baseUrl}/LoadUsers?clpCompanyId=${clpCompanyId}&clpUserId=${clpUserID}&officeCode=${officeCode}&teamCode=${teamCode}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID + "," + "officeCode - " + officeCode + "," + "teamCode - " + teamCode, encryptedUser, "ReportService", "getUsersDD"); });
        return a;
    }

    async getProjectRevenueByMonthFilter(encryptedUser: string, clpCompanyId: number, clpuserId: number): Promise<RevenueByMonthFilterResponse | void> {
        const a = await this.httpClient
            .get<RevenueByMonthFilterResponse>(`${this.baseUrl}/GetProjectRevenueByMonthFilter/${clpCompanyId}/${clpuserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser

                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpuserId - " + clpuserId, encryptedUser, "ReportService", "getProjectRevenueByMonthFilter"); });
        return a;
    }

    async getProjectRevenueByMonth(encryptedUser: string, clpCompanyId: number, clpuserId: number, dtStart: string, dtEnd: string, useSpecificDates: boolean, numberOfMonths: number, filterBy: eRptFilterBy, filterValue: number): Promise<RevenueByMonthResponse | void> {
        const a = await this.httpClient
            .get<RevenueByMonthResponse>(`${this.baseUrl}/Bind_LeadProjectRevenueByMonth/${clpCompanyId}/${clpuserId}?startDate=${dtStart}&endDate=${dtEnd}&useSpecificDates=${useSpecificDates}&numberOfMonths=${numberOfMonths}&filterBy=${filterBy}&filterValue=${filterValue}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "dtStart - " + dtStart + "," + "dtEnd - " + dtEnd + "," + "filterBy - " + filterBy + "," + "filterValue - " + filterValue, encryptedUser, "ReportService", "getProjectRevenueByMonth"); });
        return a;
    }

    async getLeadTwoDimensionFilter(encryptedUser: string, clpCompanyId: number, clpUserID: number): Promise<LeadTwoDimensionResponse | void> {
        const a = await this.httpClient.get<LeadTwoDimensionResponse>(`${this.baseUrl}/GetLeadTwoDimensionFilter/${clpCompanyId}/${clpUserID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserID - " + clpUserID, encryptedUser, "ReportService", "getLeadTwoDimensionResponse"); });
        return a;
    }

    async getLeadTwoDimensionResponse(encryptedUser: string, clpCompanyId: number, rowValue: number, columnValue: number, emr: eMeasure, dateFilter: string, startDate: string, endDate: string, strUsers: string[]): Promise<LeadTwoDimensionResponse | void> {
        const a = await this.httpClient.post<LeadTwoDimensionResponse>(`${this.baseUrl}/BindLead2DimensionData/${clpCompanyId}/${rowValue}/${columnValue}/${emr}?dateFilter=${dateFilter}&startDate=${startDate}&endDate=${endDate}`, strUsers, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "emr - " + emr + "," + "startDate - " + startDate + "," + "endDate - " + endDate, encryptedUser, "ReportService", "getLeadTwoDimensionResponse"); });
        return a;
    }

    async getLeadSearchResult(encryptedUser: string, clpCompanyId: number, ur: number, strdim1: string, strdim1val: string, strdim2: string, strdim2val: string, strStart: string, strEnd: string, strDateFilter: string, rpt: string, isMgrView: boolean): Promise<LeadTwoDimensionResponse | void> {
        const a = await this.httpClient.get<LeadTwoDimensionResponse>(`${this.baseUrl}/GetLeadSearchResult/${clpCompanyId}/${ur}?&strdim1=${strdim1}&strdim1val=${strdim1val}&strdim2=${strdim2}&strdim2val=${strdim2val}&strStart=${strStart}&strEnd=${strEnd}&strDateFilter=${strDateFilter}&rpt=${rpt}&isMgrView=${isMgrView}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "ur - " + ur + "," + "strdim1 - " + strdim1 + "," + "strdim1val - " + strdim1val + "," + "strdim2val - " + strdim2val + "," + "strStart - " + strStart + "," + "strEnd - " + strEnd + "," + "strDateFilter - " + strDateFilter + "," + "isMgrView - " + isMgrView + "," + "rpt - " + rpt, encryptedUser, "ReportService", "getLeadSearchResult"); });
        return a;
    }

    async getBuzzScoreResponse(encryptedUser: string, clpCompanyId: number, clpUserId: number): Promise<BuzzCoreFilterResponse | void> {
        const a = await this.httpClient.get<BuzzCoreFilterResponse>(`${this.baseUrl}/GetBuzzScoreFilter/${clpCompanyId}/${clpUserId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserId, encryptedUser, "ReportService", "getBuzzScoreResponse"); });
        return a;
    }

    async getBindBuzzScoreResponse(encryptedUser: string, clpCompanyId: number, startDate: string, endDate: string, class1Code: number, class4Code: number, clpUserId: number, scoreType: number): Promise<BuzzScoreResponse | void> {
        const a = await this.httpClient.get<BuzzScoreResponse>(`${this.baseUrl}/BuzzScore_Get/${clpCompanyId}?startDate=${startDate}&endDate=${endDate}&class1Code=${class1Code}&class4Code=${class4Code}&clpUserId=${clpUserId}&scoreType=${scoreType}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "startDate - " + startDate + "," + "endDate - " + endDate + "," + "class1Code - " + class1Code + "," + "class4Code - " + class4Code + "," + "clpUserId - " + clpUserId + "," + "scoreType - " + scoreType, encryptedUser, "ReportService", "getBindBuzzScoreResponse"); });
        return a;
    }

    async getScoreHistoryResponse(encryptedUser: string, contactId: number, clpUserId: number, startDate: string, endDate: string, scoreType: number): Promise<ScoreHistoryResponse | void> {
        const a = await this.httpClient.get<ScoreHistoryResponse>(`${this.baseUrl}/ScoreHistoryResponse/${contactId}/${clpUserId}?startDate=${startDate}&endDate=${endDate}&scoreType=${scoreType}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "contactId - " + contactId + "," + "startDate - " + startDate + "," + "endDate - " + endDate + "," + "scoreType - " + scoreType, encryptedUser, "ReportService", "getScoreHistoryResponse"); });
        return a;
    }

    async getResetScoreHistory(encryptedUser: string, contactId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/ResetScoreHistory/${contactId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "contactId - " + contactId, encryptedUser, "ReportService", "getResetScoreHistory"); });
        return a;
    }

    async getDeleteScoreHistory(encryptedUser: string, contactId: number, scoreType: number, dtCreated: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/DeleteScoreHistory/${contactId}/${scoreType}/${dtCreated}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "contactId - " + contactId + "," + "scoreType - " + scoreType + "," + "dtCreated - " + dtCreated, encryptedUser, "ReportService", "getDeleteScoreHistory"); });
        return a;
    }

    async getContactReferrerReport(encryptedUser: string, clpCompanyId: number, clpUserID: number, startDate: string, endDate: string, strUsers: string[]): Promise<ReferrerReportResponse | void> {
        const a = await this.httpClient
            .post<ReferrerReportResponse>(`${this.baseUrl}/Referrer_GetList/${clpCompanyId}/${clpUserID}?startDate=${startDate}&endDate=${endDate}`, strUsers, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, strUsers, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID + "," + "startDate - " + startDate + "," + "endDate - " + endDate, encryptedUser, "ReportService", "getContactReferrerReport"); });
        return a;
    }

    async getContactReferralReport(encryptedUser: string, clpCompanyId: number, clpUserId: number, referralId: number, strUsers: string[], startDate: string = "", endDate: string = ""): Promise<ReferralReportResponse | void> {
        const a = await this.httpClient
            .post<ReferralReportResponse>(`${this.baseUrl}/Referral_GetList/${clpCompanyId}/${clpUserId}/${referralId}?startDate=${startDate}&endDate=${endDate}`, strUsers, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, strUsers, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "referralId - " + referralId + "," + "clpUserId - " + clpUserId + "," + "startDate - " + startDate + "," + "endDate - " + endDate, encryptedUser, "ReportService", "getContactReferralReport"); });
        return a;
    }

    async getUserFilterResponse(encryptedUser: string, clpCompanyId: number, clpUserID: number): Promise<ClpUserFilterResponse | void> {
        const a = await this.httpClient
            .get<ClpUserFilterResponse>(`${this.baseUrl}/Get_CLPUserFilter/${clpCompanyId}/${clpUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID, encryptedUser, "ReportService", "getUserFilterResponse"); });
        return a;
    }

    async getExportRequestsList(encryptedUser: string, clpCompanyId: number, clpUserID: number, eStat: number, eType: number): Promise<ExportRequest[] | void> {
        const a = await this.httpClient
            .get<ExportRequest[]>(`${this.baseUrl}/ExportRequests_GetList/${clpCompanyId}/${clpUserID}?eStat=${eStat}&eType=${eType}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID + "," + "eStat - " + eStat + "," + "eType - " + eType, encryptedUser, "ReportService", "getExportRequestsList"); });
        return a;
    }

    async getContactSearchResult(encryptedUser: string, clpCompanyId: number, clickId: number = 0, clpUserID: number = 0, tagSearchType: number = 0, selectedUserId: number = 0, dtStart: string = "", dtEnd: string = "", searchBy: SearchContactBy = null, noteOwnerType: number = 0, selectedTagIds: TagModel[] = [], eDim1: Contact2_eSplit = 0, dim1Value: string = "", eDim2: Contact2_eSplit = 0, dim2Value: string = "", companyType: eCompanyType = 0, selectedCompanyId: number = 0): Promise<Contact[] | void> {
        const a = await this.httpClient
            .post<Contact[]>(`${this.baseUrl}/ContactSearchResult_Get/${clpCompanyId}?clpUserId=${clpUserID}&clickId=${clickId}&tagSearchType=${tagSearchType}&selectedUserId=${selectedUserId}&searchBy=${searchBy}&noteOwnerType=${noteOwnerType}&eDim1=${eDim1}&dim1Value=${dim1Value}&eDim2=${eDim2}&dim2Value=${dim2Value}&dtStart=${dtStart}&dtEnd=${dtEnd}&companyType=${companyType}&selectedCompanyId=${selectedCompanyId}`, selectedTagIds, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, selectedTagIds, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID + "," + "clickId - " + clickId + "," + "tagSearchType - " + tagSearchType + "," + "selectedUserId - " + selectedUserId + "," + "searchBy - " + searchBy + "," + "noteOwnerType - " + noteOwnerType + "," + "eDim1 - " + eDim1 + "," + "dim1Value - " + dim1Value + "," + "eDim2 - " + eDim2 + "," + "dim2Value - " + dim2Value + "," + "dtStart - " + dtStart + "," + "dtEnd - " + dtEnd + "," + "companyType - " + companyType + "," + "selectedCompanyId - " + selectedCompanyId, encryptedUser, "ReportService", "getContactSearchResult"); });
        return a;
    }

    async setupContactSearchResult(encryptedUser: string, clpCompanyId: number, clpUserId: number, isShowExcelDownload: boolean = true, isShowSendResultToNew: boolean = true, isShowNewBtn: boolean = true): Promise<ContactSearchSetupResponse | void> {
        const a = await this.httpClient
            .get<ContactSearchSetupResponse>(`${this.baseUrl}/ContactSearchResult_Setup/${clpCompanyId}/${clpUserId}?isShowExcelDownload=${isShowExcelDownload}&isShowSendResultToNew=${isShowSendResultToNew}&isShowNewBtn=${isShowNewBtn}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserId + "," + "isShowExcelDownload - " + isShowExcelDownload + "," + "isShowSendResultToNew - " + isShowSendResultToNew + "," + "isShowNewBtn - " + isShowNewBtn + ",", encryptedUser, "ReportService", "setupContactSearchResult"); });
        return a;
    }

    async getContactSearchResultList(encryptedUser: string, contactSearchResultObj: ContactSearchResultObj): Promise<ContactSearchResultResponse | void> {
        const a = await this.httpClient
            .post<ContactSearchResultResponse>(`${this.baseUrl}/ContactSearchResult_Get`, contactSearchResultObj, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, contactSearchResultObj, "r - " + encryptedUser, encryptedUser, "ReportService", "getContactSearchResultList"); });
        return a;
    }

    async createExportRequest(encryptedUser: string, exportRequest: ExportRequest, selectedUserId: number, dtStart: string, dtEnd: string, createExportFor: number, linkId: number = 0, selectedDD: string = ""): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/ExportRequest_Create?createExportFor=${createExportFor}&selectedUserId=${selectedUserId}&dtStart=${dtStart}&dtEnd=${dtEnd}&linkId=${linkId}&selectedDD=${selectedDD}`, exportRequest, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, exportRequest, "r - " + encryptedUser + "," + "createExportFor - " + createExportFor + "," + "selectedUserId - " + selectedUserId + "," + "dtStart - " + dtStart + "," + "dtEnd - " + dtEnd + "," + "linkId - " + linkId, encryptedUser, "ReportService", "createExportRequest"); });
        return a;
    }

    async getClickCountData(encryptedUser: string, clpCompanyId: number, dtStart: string, dtEnd: string,): Promise<ClickCountResponse | void> {
        const a = await this.httpClient
            .get<ClickCountResponse>(`${this.baseUrl}/ClickCount_Get/${clpCompanyId}?startDate=${dtStart}&endDate=${dtEnd}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + " startDate - " + dtStart + "," + " endDate - " + dtEnd, encryptedUser, "ReportService", "getClickCountData"); });
        return a;
    }

    async getApptSetter(encryptedUser: string, clpCompanyId: number, dtStart: string, dtEnd: string,): Promise<IHApptSetterActivityResponse | void> {
        const a = await this.httpClient
            .get<IHApptSetterActivityResponse>(`${this.baseUrl}/GetApptSetterActivityResponse/${clpCompanyId}?startDate=${dtStart}&endDate=${dtEnd}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "startDate - " + dtStart + "," + "endDate - " + dtEnd, encryptedUser, "ReportService", "getApptSetter"); });
        return a;
    }

    async getScoreCardByCodeFilters(encryptedUser: string, clpCompanyId: number, clpUserID: number): Promise<ScorecardByCodeFilterResponse | void> {
        const a = await this.httpClient
            .get<ScorecardByCodeFilterResponse>(`${this.baseUrl}/GetScoreCardByCodeFilterResponse/${clpCompanyId}/${clpUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID, encryptedUser, "ReportService", "getScoreCardByCodeFilters"); });
        return a;
    }

    async getScoreCardByCode(encryptedUser: string, clpCompanyId: number, clpUserID: number, officeCode: number, startDate: string, endDate: string,): Promise<ScoreCardByCodeResponse | void> {
        const a = await this.httpClient
            .get<ScoreCardByCodeResponse>(`${this.baseUrl}/GetScoreCardByCode?clpUserID=${clpUserID}&officeCode=${officeCode}&startDate=${startDate}&endDate=${endDate}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserID + "," + "officeCode - " + officeCode + "," + "startDate - " + startDate + "," + "endDate - " + endDate, encryptedUser, "ReportService", "getScoreCardByCode"); });
        return a;
    }

    async getScoreCardByKeywordFilter(encryptedUser: string, clpCompanyId: number, clpUserId: number): Promise<ScorecardByKeywordFilterResponse | void> {
        const a = await this.httpClient
            .get<ScorecardByKeywordFilterResponse>(`${this.baseUrl}/GetScoreCardByKeywordFilterResponse/${clpCompanyId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpUserId + "," + "clpUserId - " + clpCompanyId, encryptedUser, "ReportService", "getScoreCardByKeywordFilter"); });
        return a;
    }

    async getScoreCardByKeyword(encryptedUser: string, clpCompanyId: number, selectedUserId: number, officeCode: number, class7Code: number, class8Code: number, startDate: string, endDate: string,): Promise<ScoreCardByKeywordResponse | void> {
        const a = await this.httpClient
            .get<ScoreCardByKeywordResponse>(`${this.baseUrl}/GetScoreCardByKeyword?selectedUserId=${selectedUserId}&officeCode=${officeCode}&class7Code=${class7Code}&class8Code=${class8Code}&startDate=${startDate}&endDate=${endDate}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "startDate - " + startDate + "," + "endDate - " + endDate + "," + "selectedUserId - " + selectedUserId + "," + "officeCode - " + officeCode + "," + "class7Code - " + class7Code + "," + "class8Code - " + class8Code, encryptedUser, "ReportService", "getScoreCardByKeyword"); });
        return a;
    }

    async getBIPClickByApptSetter(encryptedUser: string, costPerline: number, dtStart: string, dtEnd: string): Promise<BIPClickByApptSetterResponse | void> {
        const a = await this.httpClient
            .get<BIPClickByApptSetterResponse>(`${this.baseUrl}/GetBIPClickByApptSetterResponse/${costPerline}?startDate=${dtStart}&endDate=${dtEnd}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "costPerline - " + costPerline + "dtStart - " + dtStart + "," + "dtEnd - " + dtEnd, encryptedUser, "ReportService", "getBIPClickByApptSetter"); });
        return a;
    }

    async getScoreCard(encryptedUser: string, clpUserID: number, class6Code: number, startDate: string, endDate: string, officeGroup: number,): Promise<ScorecardResponse | void> {
        const a = await this.httpClient
            .get<ScorecardResponse>(`${this.baseUrl}/GetScoreCard/${clpUserID}?class6Code=${class6Code}&startDate=${startDate}&endDate=${endDate}&officeGroup=${officeGroup}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "startDate - " + startDate + "," + "endDate - " + endDate + "," + "officeGroup - " + officeGroup + "," + "clpUserID - " + clpUserID + "," + "class6Code - " + class6Code, encryptedUser, "ReportService", "getScoreCard"); });
        return a;
    }

    async getScoreCardFilter(encryptedUser: string, clpCompanyId: number): Promise<ScorecardFilterResponse | void> {
        const a = await this.httpClient
            .get<ScorecardFilterResponse>(`${this.baseUrl}/GetScoreCardFilterResponse/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId, encryptedUser, "ReportService", "getScoreCardFilter"); });
        return a;
    }

    async getScoreCardByTVSource(encryptedUser: string, clpUserID: number, startDate: string, endDate: string,): Promise<ScoreCardByTVSourceResponse | void> {
        const a = await this.httpClient
            .get<ScoreCardByTVSourceResponse>(`${this.baseUrl}/GetScoreCardByTVSource/${clpUserID}?startDate=${startDate}&endDate=${endDate}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserID - " + clpUserID + "," + "startDate - " + startDate + "," + "endDate - " + endDate, encryptedUser, "ReportService", "getScoreCardByTVSource"); });
        return a;
    }

    async getScoreCardCompare(encryptedUser: string, clpUserID: number, dtStart1: string, dtEnd1: string, dtStart2: string, dtEnd2: string,): Promise<ScorecardCompareResponse | void> {
        const a = await this.httpClient
            .get<ScorecardCompareResponse>(`${this.baseUrl}/GetScoreCardCompare/${clpUserID}?startDate=${dtStart1}&endDate=${dtEnd1}&startDate2=${dtStart2}&endDate2=${dtEnd2}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpUserID - " + clpUserID + "," + "dtStart1 - " + dtStart1 + "," + "dtEnd1 - " + dtEnd1 + "," + "dtStart2 - " + dtStart2 + "," + "dtEnd2 - " + dtEnd2, encryptedUser, "ReportService", "getScoreCardCompare"); });
        return a;
    }

    async getScoreCardByApptSetter(encryptedUser: string, startDate: string, endDate: string): Promise<ScoreCardByApptSetterResponse | void> {
        const a = await this.httpClient
            .get<ScoreCardByApptSetterResponse>(`${this.baseUrl}/GetScoreCardByApptSetter?startDate=${startDate}&endDate=${endDate}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "startDate - " + startDate + "," + "endDate - " + endDate, encryptedUser, "ReportService", "getScoreCardByApptSetter"); });
        return a;
    }

    async getApptGetCountByCLPUserID(encryptedUser: string, clpCompanyId: number, startDate: string, endDate: string, clpUserId: number, ownerId: number, eapt: number, eaptSt: number): Promise<SimpleResponse[] | void> {
        const a = await this.httpClient
            .get<SimpleResponse[]>(`${this.baseUrl}/Appt_GetCountByCLPUserID/${clpCompanyId}/${startDate}/${endDate}/${clpUserId}?ownerId=${ownerId}&eapt=${eapt}&eapt=${eaptSt}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "startDate - " + startDate + "," + "endDate - " + endDate + "," + "clpUserId - " + clpUserId + "," + "ownerId - " + ownerId + "," + "eapt - " + eapt + "," + "eaptSt - " + eaptSt, encryptedUser, "ReportService", "getApptGetCountByCLPUserID"); });
        return a;
    }
    async getLeadCount(encryptedUser: string, clpCompanyId: number, clpUserId: number, StartDt: string, endDt: string, isAllActive: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/Get_LeadCount/${clpCompanyId}?clpUserId=${clpUserId}&StartDt=${StartDt}&endDt=${endDt}&isAllActive=${isAllActive}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserId + "," + "StartDt - " + StartDt + "," + "endDt - " + endDt + "," + "isAllActive - " + isAllActive, encryptedUser, "ReportService", "getLeadCount"); });
        return a;
    }

    async getLeadSearchDataGrouped(encryptedUser: string, clpCompanyId: number, clpUserId: number, startDate: string, endDate: string, leadGroupBy: number, isAllActive: boolean, isManagerView: boolean = false): Promise<LeadSearchResultGroupedresponse | void> {
        const a = await this.httpClient
            .get<LeadSearchResultGroupedresponse>(`${this.baseUrl}/LeadSearchDataGrouped/${clpCompanyId}/${clpUserId}?startDate=${startDate}&endDate=${endDate}&leadGroupBy=${leadGroupBy}&isAllActive=${isAllActive}&isManagerView=${isManagerView}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserId + "," + "startDate - " + startDate + "," + "endDate - " + endDate + "," + "leadGroupBy - " + leadGroupBy + "," + "isAllActive - " + isAllActive + "," + "isManagerView - " + isManagerView, encryptedUser, "ReportService", "getLeadSearchDataGrouped"); });
        return a;
    }

    async getResponseTimeFilter(encryptedUser: string, clpCompanyId: number, clpUserId: number): Promise<ResponseTimeFilterResponse | void> {
        const a = await this.httpClient
            .get<ResponseTimeFilterResponse>(`${this.baseUrl}/GetResponseTimeFilter/${clpCompanyId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => {
                this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "r -  " + encryptedUser + "clpCompanyId - " + clpCompanyId + "," + "clpUserId - " + clpUserId, encryptedUser, "ReportService", "getResponseTimeFilter");
            });
        return a;
    }

    async getResponseTimeData(encryptedUser: string, clpCompanyId: number, officeCode: number, teamCode: number, strStart: string, strEnd: string): Promise<ResponseTimeDataResponse | void> {
        const a = await this.httpClient
            .get<ResponseTimeDataResponse>(`${this.baseUrl}/BindResponseTimeData/${clpCompanyId}?officeCode=${officeCode}&teamCode=${teamCode}&startDate=${strStart}&endDate=${strEnd}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => {
                this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "r -  " + encryptedUser + "clpCompanyId - " + clpCompanyId + "," + "officeCode - " + officeCode + "," + "teamCode - " + teamCode + "," + "startDate - " + strStart + "," + "endDate" + strEnd, encryptedUser, "ReportService", "getResponseTimeData");
            });
        return a;
    }

    async getDuplicatesLead(encryptedUser: string, startDate: string, endDate: string): Promise<DuplicateLeadsResponse | void> {
        const a = await this.httpClient
            .get<DuplicateLeadsResponse>(`${this.baseUrl}/DuplicateLeads_GetList?startDate=${startDate}&endDate=${endDate}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => {
                this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "r -  " + encryptedUser + "," + "startDate" + startDate + "," + "endDate" + endDate, encryptedUser, "ReportService", "getDuplicatesLead");
            });
        return a;
    }

    async loadApptSetter(encryptedUser: string): Promise<IHApptSetter[] | void> {
        const a = await this.httpClient
            .get<IHApptSetter[]>(`${this.baseUrl}/LoadApptSetter`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => {
                this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "ReportService", "loadApptSetter");
            });
        return a;
    }


    async loadNonApptSettersUsers(encryptedUser: string, clpCompanyId: number): Promise<NonApptSettersUsersResponse | void> {
        const a = await this.httpClient
            .get<NonApptSettersUsersResponse>(`${this.baseUrl}/LoadNonApptSettersUsers/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => {
                this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId " + clpCompanyId, encryptedUser, "ReportService", "loadNonApptSettersUsers");
            });
        return a;
    }

    /*  async loadAssignedUsers(encryptedUser: string, apptSetterId: number): Promise<IHApptSetterCLPUser[] | void> {
        const a = await this.httpClient
          .get<IHApptSetterCLPUser[]>(`${this.baseUrl}/LoadAssignedUsers/${apptSetterId}`, {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': 'Basic ' + encryptedUser
            })
          }).pipe(delayedRetryHttp()).toPromise().catch(err => {
            this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "apptSetterId" + apptSetterId , encryptedUser, "ReportService", "loadApptSetter");
          });
        return a;
      }*/

    async loadNonAssignedUsers(encryptedUser: string, clpCompanyId: number, apptSetterId: number): Promise<keyValue[] | void> {
        const a = await this.httpClient
            .get<keyValue[]>(`${this.baseUrl}/LoadNonAssignedUsers/${clpCompanyId}/${apptSetterId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => {
                this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId" + clpCompanyId + "," + apptSetterId + "apptSetterId", encryptedUser, "ReportService", "loadNonAssignedUsers");
            });
        return a;
    }

    async getSelectedUserSummary(encryptedUser: string, emailType: number, userIds: SelectedUserSummaryData[]): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/SelectedUserSummary_Get/${emailType}`, userIds, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => {
                this._utilityService.handleErrors(err, userIds, "r - " + encryptedUser + "," + "emailType" + emailType, encryptedUser, "ReportService", "getSelectedUserSummary");
            });
        return a;
    }

    async configureBlasts(encryptedUser: string, emailType: number, subject: string, emailTemplateId: number, selectedUserIds: number[]): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/ConfigureBlasts/${emailType}/${subject}/${emailTemplateId}`, selectedUserIds, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => {
                this._utilityService.handleErrors(err, selectedUserIds, "r - " + encryptedUser + "," + "emailType" + emailType + "," + "subject" + subject + "," + "emailTemplateId" + emailTemplateId, encryptedUser, "ReportService", "configureBlasts");
            });
        return a;
    }

    getCbSendResultsToNewSelectedIndexChanged(encryptedUser: string, sendResultToNewObj: SendResultToNewObj): Promise<SimpleResponse | void> {
        const a = this.httpClient
            .post<SimpleResponse[]>(`${this.baseUrl}/SendResultsToNew_SelectedIndexChanged`, sendResultToNewObj, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, sendResultToNewObj, "r - " + encryptedUser, encryptedUser, "ReportService", "getCbSendResultsToNewSelectedIndexChanged"); });
        return a;
    }

    async getContactsByCustomSearchId(encryptedUser: string, clpCompanyId: number, customSearchId: number, clpUserId: number, redirectedTo: number): Promise<ContactResponse | void> {
        const a = await this.httpClient
            .get<ContactResponse>(`${this.baseUrl}/GetContactsByCustomSearchId/${clpCompanyId}/${customSearchId}/${clpUserId}/${redirectedTo}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => {
                this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + clpCompanyId + "clpCompanyId" + "," + customSearchId + "customSearchId" + "," + clpUserId + "clpUserId" + "," + redirectedTo + "redirectedTo", encryptedUser, "ReportService", "getContactsByCustomSearchId");
            });
        return a;
    }

    async saveExportRequest(encryptedUser: string, exportRequestObj: ExportRequestSaveObj): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/ExportRequest_Save`, exportRequestObj, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, exportRequestObj, "r - " + encryptedUser, encryptedUser, "ReportService", "saveExportRequest"); });
        return a;
    }

    async leadStatusCode_GetList(encryptedUser: string, clpCompanyId: number): Promise<LeadStatusListResponse | void> {
        const a = await this.httpClient
            .get<LeadStatusListResponse>(`${this.baseUrl}/LeadStatusCode_GetList/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => {
                this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + clpCompanyId + "clpCompanyId", encryptedUser, "ReportService", "leadStatusCode_GetList");
            });
        return a;
    }

    async rptstatushistorytimespan_Get(encryptedUser: string, clpCompanyId: number, fromStatusCode: number, toStatusCode: number, strdtfrom: string, strdtTo: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/rptstatushistorytimespan_Get/${clpCompanyId}/${fromStatusCode}/${toStatusCode}/${strdtfrom}/${strdtTo}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => {
                this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + clpCompanyId + "clpCompanyId", encryptedUser, "ReportService", "rptstatushistorytimespan_Get");
            });
        return a;
    }
}
