<div *ngIf="isShowEmailSettings" class="custom-security-link">
    <a [routerLink]="['/club-module-settings']">Club Module Settings</a>
</div>
<div class="margin-all-area">
    <div class="wraper-main-section mb-3">
        <div class="global-card-section">
            <form [formGroup]="clubModuleSettingsForm" >
                <div class="">
                    <div class="">
                        <div class="global-header-section">
                            <div class="svg-icon-panel"><img src="../../../../assets/txtmsgstitle.svg" class="mr-1" /> Club Module Settings</div>
                        </div>
                        <div class="message-info" *ngIf="ClubModuleSettingsDataResponse ">
                            <span [innerHTML]="messageInfo | safeHtml"></span>
                        </div>

                        <div class="global-body-section">
                            <div class="flex-row-inner">
                                <div class="wraper-body-left">
                                    <div class="custom-action-title">
                                        <span>General</span>
                                    </div>

                                    <div class="excel-main-panel">
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div class="align-center">
                                                    <span class="control-label">Info Site Url</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <span>
                                                    <span *ngIf="!editShow"> {{ClubModuleSettingsDataResponse?.InfoSiteURL}} </span>
                                                    <input type="text" *ngIf="editShow" class="txtStandard" id="InfoSiteURL" formControlName="InfoSiteURL" />
                                                </span>
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div class="align-center">
                                                    <span class="control-label">Learn Site Url</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <span>
                                                    <span *ngIf="!editShow"> {{ClubModuleSettingsDataResponse?.LearnSiteURL}} </span>
                                                    <input type="text" *ngIf="editShow" class="txtStandard" id="LearnSiteURL" formControlName="LearnSiteURL" />
                                                </span>
                                            </div>
                                        </div>

                                        <div class="" >
                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left calc-width"></div>
                                                <div class="cards-colunm-right">
                                                    <div class="bottom-button-bar">
                                                        <button class="btn btn-primary mr-2" type="submit" *ngIf="editShow" >
                                                            <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
                                                            <ng-template [ngIf]="buttonTypeOperation===1">Saving  <span><i wrapper> </i></span></ng-template>
                                                        </button>
                                                        <button type="button" *ngIf="!editShow" [hidden]=" roleFeaturePermissions?.edit == false" (click)="editData()" class="btn btn-primary mr-2">Edit</button>
                                                        <button type="button" *ngIf="editShow" (click)="backToView()" class="btn btn-cancel mr-2">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
