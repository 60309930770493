<div *ngIf="!isFromAcSetting" class="custom-security-link">
    <a [routerLink]="['/outlook-integration']" routerLinkActive="active">Outlook Integration</a>
    <a [routerLink]="['/gsettings']" routerLinkActive="active">Google Integration</a>
    <a [routerLink]="['/smtpsettings']" routerLinkActive="active">SMTP Email Settings</a>
    <a [routerLink]="['/user-prefs']" routerLinkActive="active">User Preferences</a>
    <a [routerLink]="['/user-defaults']" routerLinkActive="active">User Default</a>
    <a [routerLink]="['/cm-user-prefs']" *ngIf="companyData?.isClubModuleInstalled" routerLinkActive="active">Club User Preferences</a>
</div>

<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../assets/clpusertitle.svg" class="mr-1" />User Profile &nbsp;{{user?.firstName}}&nbsp;{{user?.lastName}}</div>

                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <button *ngIf="!isViewMode" type="button" matTooltip="Save" (click)="userProfileFormSubmit()" class="icon-btn">
                            <i class="fa fa-save" aria-hidden="true"></i>
                            <span class="btn-text">Save</span>
                        </button>
                        <button type="button" *ngIf="isViewMode" matTooltip="Edit" class="icon-btn" (click)="onEditProfile()">
                            <i class="fa fa-pencil-alt" aria-hidden="true"></i>
                            <span class="btn-text">Edit</span>
                        </button>
                        <button type="button" matTooltip="Cancel Webform" class="icon-btn" *ngIf="!isViewMode" (click)="isViewMode = true;onCancelEdit()">
                            <span class="btn-text">Cancel</span>
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="global-body-section">
                <form [formGroup]="userProfileForm" (ngSubmit)="userProfileFormSubmit()">
                    <div class="align-top-style">
                        <div class="wraper-body-panel">
                            <div class="wraper-body-left">
                                <div class="custom-action-title">
                                    <span>General</span>
                                </div>
                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <span class="control-label" for="firstName">First Name</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="w-100" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" id="firstName" formControlName="firstName" placeholder="First Name" [ngClass]="{'has-error':userProfileForm.controls.firstName?.errors && (userProfileForm.controls.firstName?.touched ||  userProfileForm.controls.firstName?.dirty)}" />
                                                <div class="search-query-error" *ngIf="!userProfileForm.controls.firstName?.valid && (userProfileForm.controls.firstName?.dirty
                                  ||userProfileForm.controls.firstName?.touched )">
                                                    <div *ngIf="userProfileForm.controls.firstName.errors.required">Please enter a first name.</div>
                                                </div>
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{userProfileForm.controls.firstName?.value}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <span class="control-label" for="lastName">Last Name</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="w-100" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" id="lastName" formControlName="lastName" placeholder="Last Name" [ngClass]="{'has-error': userProfileForm.controls.lastName?.errors && (userProfileForm.controls.lastName?.touched ||  userProfileForm.controls.lastName?.dirty)}" />
                                                <div class="search-query-error" *ngIf="!userProfileForm.controls['lastName'].valid && (userProfileForm.controls['lastName'].dirty || userProfileForm.controls['lastName'].touched )">
                                                    <div *ngIf="userProfileForm.controls['lastName'].errors.required">Please enter a last name.</div>
                                                </div>
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{userProfileForm.controls.lastName?.value}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <span class="control-label" for="title">Title</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" id="title" formControlName="title" placeholder="Title" />
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{userProfileForm.controls.title?.value}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <span class="control-label" for="companyName">Company</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <!--<div class="full-width-container" *ngIf="!isViewMode">
                                                    <input type="text" class="form-control" id="companyName" formControlName="companyName" placeholder="Company Name" />
                                                </div>
                                                <div *ngIf="isViewMode">-->
                                            <div>
                                                <span>{{userProfileForm.controls.companyName?.value}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <span class="control-label" for="selectedTheme">Change theme</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <select class="form-control" id="selectedTheme" formControlName="selectedTheme">
                                                    <option *ngFor="let name of ddTheme" [value]="name">{{eTheme[name]}}</option>
                                                </select>
                                            </div>

                                            <div *ngIf="isViewMode">
                                                <span>{{eTheme[userProfileForm.controls.selectedTheme?.value]}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="custom-action-title">
                                    <span>Address</span>
                                </div>
                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <span class="control-label" for="add1">Address 1</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" id="add1" formControlName="add1" placeholder="Address 1" />
                                            </div>

                                            <div *ngIf="isViewMode">
                                                <span>{{userProfileForm.controls.add1?.value}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <span class="control-label" for="add2">Address 2</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" id="add2" formControlName="add2" placeholder="Address 2" />
                                            </div>

                                            <div *ngIf="isViewMode">
                                                <span>{{userProfileForm.controls.add2?.value}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <span class="control-label" for="add3">Address 3</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" id="add3" formControlName="add3" placeholder="Address 3" />
                                            </div>

                                            <div *ngIf="isViewMode">
                                                <span>{{userProfileForm.controls.add3?.value}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <span class="control-label" for="zip">Zip</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" [ngClass]="{'has-error': userProfileForm.controls.zip.errors && (userProfileForm.controls.zip.touched || userProfileForm.controls.zip.dirty)}" id="zip" formControlName="zip" placeholder="Zip" minlength="3" maxlength="10" (blur)="getCityState($event);" />
                                                <div *ngIf="userProfileForm.controls.zip.errors && (userProfileForm.controls.zip.touched || userProfileForm.controls.zip.dirty)">
                                                    <div class="search-query-error" *ngIf="userProfileForm.controls.zip.errors.pattern">Please enter correct Zip </div>
                                                </div>
                                            </div>

                                            <div *ngIf="isViewMode">
                                                <span>{{userProfileForm.controls.zip?.value}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <span class="control-label" for="city">City</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" id="city" formControlName="city" placeholder="City" />
                                            </div>

                                            <div *ngIf="isViewMode">
                                                <span>{{userProfileForm.controls.city?.value}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <span class="control-label" for="state">State</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" id="state" formControlName="state" placeholder="State" />
                                            </div>

                                            <div *ngIf="isViewMode">
                                                <span>{{userProfileForm.controls.state?.value}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                            <span class="control-label" for="country">Country</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <select class="form-control custom-select" id="country" formControlName="country" placeholder="Country">
                                                    <option *ngFor="let country of countryList" [value]="country.code" [attr.data-code2]="country.code">{{country.name}}</option>
                                                </select>
                                                <div class="search-query-error" *ngIf="usrProfileForm.country.touched && usrProfileForm.country.errors">
                                                    <div *ngIf="usrProfileForm.country.errors.required">Country is required</div>
                                                </div>
                                            </div>

                                            <div *ngIf="isViewMode">
                                                <!--<span>{{userProfileForm.controls.country?.value}}</span>-->
                                                <span>{{getCountryName(userProfileForm.controls.country?.value)}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="custom-action-title">
                                        <span>Social Network</span>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="facebookURL">Facebook Page URL</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="input-group" *ngIf="!isViewMode">
                                                    <input type="text" class="form-control" id="facebookURL" formControlName="facebookURL" placeholder="Facebook Page URL" aria-label="facebookURL" aria-describedby="basic-addon1">
                                                    <div class="input-group-prepend">
                                                        <button type="button" id="basic-addon1" class="input-group-text" (click)="goToLink('facebookURL')">
                                                            <i class="fa fa-eye" title="Go to Link"></i>
                                                            <span class="grid-common-text"></span>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div *ngIf="isViewMode">
                                                    <span>{{userProfileForm.controls.facebookURL?.value}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="twitterURL">Twitter Page URL</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <!--<input type="text" class="form-control" id="twitterURL" formControlName="twitterURL" placeholder="Twitter Page URL" />-->
                                                <div class="input-group" *ngIf="!isViewMode">
                                                    <input type="text" class="form-control" id="twitterURL" formControlName="twitterURL" placeholder="Twitter Page URL" aria-label="twitterURL" aria-describedby="basic-addon1">
                                                    <div class="input-group-prepend">
                                                        <button type="button" id="basic-addon1" class="input-group-text" (click)="goToLink('twitterURL')">
                                                            <i class="fa fa-eye" title="Go to Link"></i>
                                                            <span class="grid-common-text"></span>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div *ngIf="isViewMode">
                                                    <span>{{userProfileForm.controls.twitterURL?.value}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="linkedInURL">LinkedIn Page URL</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <!--<input type="text" class="form-control" id="linkedInURL" formControlName="linkedInURL" placeholder="LinkedIn Page URL" />-->
                                                <div class="input-group" *ngIf="!isViewMode">
                                                    <input type="text" class="form-control" id="linkedInURL" formControlName="linkedInURL" placeholder="LinkedIn Page URL" aria-label="linkedInURL" aria-describedby="basic-addon1">
                                                    <div class="input-group-prepend">
                                                        <button type="button" id="basic-addon1" class="input-group-text" (click)="goToLink('linkedInURL')">
                                                            <i class="fa fa-eye" title="Go to Link"></i>
                                                            <span class="grid-common-text"></span>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div *ngIf="isViewMode">
                                                    <span>{{userProfileForm.controls.linkedInURL?.value}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="linkedInURL">YouTube Page URL</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <!--<input type="text" class="form-control" id="linkedInURL" formControlName="linkedInURL" placeholder="LinkedIn Page URL" />-->
                                                <div class="input-group" *ngIf="!isViewMode">
                                                    <input type="text" class="form-control" formControlName="youtubeURL" placeholder="Youtube Page URL" aria-describedby="basic-addon1">
                                                    <div class="input-group-prepend">
                                                        <button type="button" id="basic-addon1" class="input-group-text" (click)="goToLink('youtubeURL')">
                                                            <i class="fa fa-eye" title="Go to Link"></i>
                                                            <span class="grid-common-text"></span>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div *ngIf="isViewMode">
                                                    <span>{{userProfileForm.controls.youtubeURL?.value}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="linkedInURL">Instagram Page URL</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <!--<input type="text" class="form-control" id="linkedInURL" formControlName="linkedInURL" placeholder="LinkedIn Page URL" />-->
                                                <div class="input-group" *ngIf="!isViewMode">
                                                    <input type="text" class="form-control" formControlName="instagramURL" placeholder="Instagram Page URL">
                                                    <div class="input-group-prepend">
                                                        <button type="button" id="basic-addon1" class="input-group-text" (click)="goToLink('instagramURL')">
                                                            <i class="fa fa-eye" title="Go to Link"></i>
                                                            <span class="grid-common-text"></span>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div *ngIf="isViewMode">
                                                    <span>{{userProfileForm.controls.instagramURL?.value}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="custom-action-title">
                                        <span>System</span>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="emailFormat">Email Format</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="full-width-container" *ngIf="!isViewMode">
                                                    <select class="form-control" id="emailFormat" formControlName="emailFormat">
                                                        <option value="0">Text</option>
                                                        <option value="1">HTML</option>
                                                    </select>
                                                </div>


                                                <div *ngIf="isViewMode">
                                                    <span>{{userProfileForm.controls.emailFormat?.value==1 ? 'HTML' : 'Text'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="timeZoneWinId">Time Zone</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="w-100" *ngIf="!isViewMode">
                                                    <select class="form-control" formControlName="timeZoneWinId" (change)="updateDropDownFormValue($event.target.value,'timeZoneWinId')"
                                                            [ngClass]="{'is-invalid':usrProfileForm.timeZoneWinId.touched && usrProfileForm.timeZoneWinId.errors}">
                                                        <option value=""> -None Selected- </option>
                                                        <option *ngFor="let dtList of timezoneFilterList; let i = index" [value]="dtList?.id">{{dtList?.standardName }}</option>
                                                    </select>
                                                    <div class="search-query-error" *ngIf="usrProfileForm.timeZoneWinId.touched && usrProfileForm.timeZoneWinId.errors">
                                                        <div *ngIf="usrProfileForm.timeZoneWinId.errors.required">Time Zone is  required</div>
                                                    </div>
                                                </div>

                                                <div *ngIf="isViewMode">
                                                    <span>{{getTimeZoneByID(userProfileForm.controls.timeZoneWinId?.value)}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="dateFormatId">Date Format</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="w-100" *ngIf="!isViewMode">
                                                    <select class="form-control" formControlName="dateFormatId" (change)="updateDropDownFormValue($event.target.value,'dateFormatId')" [ngClass]="{'is-invalid':usrProfileForm.dateFormatId.touched && usrProfileForm.dateFormatId.errors}">
                                                        <option value=""> -None Selected- </option>
                                                        <option *ngFor="let dtList of dateFormatList; let i = index" [value]="dtList.id">{{dtList?.dateFormat }}</option>
                                                    </select>
                                                    <div class="search-query-error" *ngIf="usrProfileForm.dateFormatId.touched && usrProfileForm.dateFormatId.errors">
                                                        <div *ngIf="usrProfileForm.dateFormatId.errors.required">Date Format is required</div>
                                                    </div>
                                                </div>

                                                <div *ngIf="isViewMode">
                                                    <span>{{getDateFormate(userProfileForm.controls.dateFormatId?.value)}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="cLPUserID">User Code</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <label *ngIf="userProfile">VR9{{ userProfile?.cLPUserID }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!isFromAcSetting" class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="dShareContact">Share</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div *ngIf="!isViewMode">
                                                    <div class="custom-toggle">
                                                        <label class="switch">
                                                            <input type="checkbox" class="form-control" id="dShareContact" formControlName="dShareContact" placeholder="dShareContact" (change)="changeCheckbox('dShareContact', $event)" />
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </div>
                                                    <span class="error-red" *ngIf="!userProfile?.dShareContact">Not shared by default.</span>
                                                </div>
                                                <div *ngIf="isViewMode">
                                                    <span class="error-red" *ngIf="!userProfile?.dShareContact">Not shared by default.</span>
                                                    <span class="text-success" *ngIf="userProfile?.dShareContact">Shared by default.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!isFromAcSetting" class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="dOutlookSync">Google Sync</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div *ngIf="!isViewMode">
                                                    <div class="custom-toggle">
                                                        <label class="switch">
                                                            <input type="checkbox" class="form-control" id="dOutlookSync" formControlName="dOutlookSync" placeholder="dOutlookSync" (change)="changeCheckbox('dOutlookSync', $event)" />
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </div>
                                                    <span class="error-red" *ngIf="!userProfile?.dOutlookSync">New contacts will NOT be set to sync by default.</span>
                                                </div>
                                                <div *ngIf="isViewMode">
                                                    <span class="error-red" *ngIf="!userProfile?.dOutlookSync">New contacts will NOT be set to sync by default.</span>
                                                    <span class="text-success" *ngIf="userProfile?.dOutlookSync">New contacts will be set to sync by default.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="userRole">Role</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <label *ngIf="userProfile">{{ userProfile.userRole == 1 ? 'General' :  userProfile.userRole == 2 ? 'Manager' :  userProfile.userRole == 3 ? 'Administrator' :  userProfile.userRole == 4 ? 'SuperUser' :  userProfile.userRole == 5 ? 'SOAdminUser' : 'None' }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="status">Status</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <label *ngIf="userProfile">{{ userProfile.status == 0 ? 'Disabled' :  userProfile.status == 1 ? 'Active' : userProfile.status == 2 ? 'Downloaded' : 'Activated' }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="changePwd">Change Password</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div *ngIf="!isViewMode">
                                                    <div class="custom-toggle">
                                                        <label class="switch">
                                                            <input type="checkbox" class="form-control" id="changePW" formControlName="changePW" placeholder="Change Password" (change)="changeCheckbox('changePW', $event)" />
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </div>
                                                    <span class="user-force-password">Force a password change.</span>
                                                </div>

                                                <div *ngIf="isViewMode">
                                                    <span>{{userProfileForm.controls.changePW?.value==0 ? 'False':'True'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="wraper-body-right">
                                <div>
                                    <div class="custom-action-title">
                                        <span>Contact</span>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="mobile">Mobile</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="w-100" *ngIf="!isViewMode">
                                                    <input type="text" class="form-control" id="mobile" formControlName="mobile" minlength="10" maxlength="15" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)" [placeholder]="placeHolder" [mask]="mobile_mask" [ngClass]="{'has-error': userProfileForm.controls.mobile?.errors && (userProfileForm.controls.mobile?.touched ||  userProfileForm.controls.mobile?.dirty)}" />
                                                    <div class="search-query-error" *ngIf="!userProfileForm.controls.mobile?.valid && (userProfileForm.controls.mobile?.dirty
                                ||userProfileForm.controls.mobile?.touched )">
                                                        <div *ngIf="userProfileForm.controls.mobile.errors?.required">Please enter your mobile.</div>
                                                        <div *ngIf="userProfileForm.controls.mobile.errors?.pattern">Please enter correct mobile.</div>
                                                    </div>
                                                    <div>
                                                        <span class="control-label" style="color:black">
                                                            By submitting my mobile phone number, I consent to receive calls, user /system alerts via text messages and emails from SalesOptima, including information about my account.
                                                        </span>
                                                    </div>
                                                </div>

                                                <div *ngIf="isViewMode">
                                                    <span>{{pixString(userProfileForm.controls.mobile?.value) | phoneFormat}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="phone">Phone</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="full-width-container" *ngIf="!isViewMode">
                                                    <input type="text" class="form-control" (keypress)="_localService.onKeyDown($event)"
                                                           [ngClass]="{'has-error': userProfileForm.controls.phone?.errors && (userProfileForm.controls.phone?.touched ||  userProfileForm.controls.phone?.dirty)}"
                                                           (paste)="_localService.onPaste($event)" id="phone"
                                                           minlength="10" maxlength="15" formControlName="phone" [placeholder]="placeHolder" [mask]="mobile_mask" />
                                                    <div class="search-query-error" *ngIf="!userProfileForm.controls['phone'].valid && (userProfileForm.controls['phone'].dirty || userProfileForm.controls['phone'].touched )">
                                                        <div *ngIf="userProfileForm.controls['phone'].errors.pattern">Please enter a valid number.</div>
                                                    </div>
                                                </div>

                                                <div *ngIf="isViewMode">
                                                    <span>{{pixString(userProfileForm.controls.phone?.value) | phoneFormat}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="altPhone">Other Phone</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="full-width-container" *ngIf="!isViewMode">
                                                    <input type="text" class="form-control" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)"
                                                           [ngClass]="{'has-error': userProfileForm.controls.altPhone?.errors && (userProfileForm.controls.altPhone?.touched ||  userProfileForm.controls.altPhone?.dirty)}"
                                                           id="altPhone" minlength="10" maxlength="15" formControlName="altPhone" [placeholder]="placeHolder" [mask]="mobile_mask" />
                                                    <div class="search-query-error" *ngIf="!userProfileForm.controls['altPhone'].valid && (userProfileForm.controls['altPhone'].dirty || userProfileForm.controls['altPhone'].touched )">
                                                        <div *ngIf="userProfileForm.controls['altPhone'].errors.pattern">Please enter a valid number.</div>
                                                    </div>
                                                </div>

                                                <div *ngIf="isViewMode">
                                                    <span>{{pixString(userProfileForm.controls.altPhone?.value) | phoneFormat}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="fax">Fax</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="full-width-container" *ngIf="!isViewMode">
                                                    <input type="text" class="form-control" id="fax" formControlName="fax" [placeholder]="placeHolder" [mask]="mobile_mask" />
                                                </div>

                                                <div *ngIf="isViewMode">
                                                    <span>{{userProfileForm.controls.fax?.value  | phoneFormat}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="fax">Long Code</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div>
                                                    <span>{{userProfileForm.controls.txtMsgLongCode?.value  | phoneFormat}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="fax">Toll Free</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div>
                                                    <span>{{userProfileForm.controls.txtMsgTollFree?.value  | phoneFormat}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div class="">
                                                    <span class="control-label">Voice and Text</span>
                                                    <span class="control-label text-success">Features</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div>
                                                    <button type="button" (click)="directToVirtualVM()" *ngIf="btnisDirectToVirtualVM && !isFromAcSetting" class="btn btn-primary">
                                                        {{virtualButtonVM}}
                                                    </button>
                                                    <div *ngIf="btnisDirectToVirtualVM">
                                                        <div *ngFor="let item of txtDirectToVirtualVM">
                                                            <span class="control-label"> {{item}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--<div class="cards-body-section">
                                      <div class="flex-width-panel">
                                        <div class="cards-colunm-left">
                                          <div class="">
                                            <span class="control-label" for="enableSkype">
                                              Skype
                                            </span>
                                            <span class="control-label text-success">Integration</span>
                                          </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                          <div *ngIf="!isViewMode">
                                            <div class="custom-toggle">
                                              <label class="switch">
                                                <input type="checkbox" class="form-control" id="enableSkype" formControlName="enableSkype" placeholder="Skype" (change)="changeCheckbox('enableSkype', $event)" />
                                                <span class="slider round"></span>
                                              </label>
                                            </div>
                                            <span class="user-activate"> Activate.</span> &nbsp;
                                            <a class="contact-cursor-pointer" href="https://www.salesoptima.com/features/mobile-crm" target="_blank">Learn More</a>

                                          </div>

                                          <div *ngIf="isViewMode">
                                            <span class="control-label text-success" *ngIf="userProfileForm.controls.enableSkype?.value">Activated</span>
                                            <span style="color:red" *ngIf="!userProfileForm.controls.enableSkype?.value">Disabled</span>&nbsp;
                                            <a class="contact-cursor-pointer" href="https://www.salesoptima.com/features/mobile-crm" target="_blank">Learn More</a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>-->
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label">Fax By Email</span>
                                                    <span class="control-label text-success">Integration</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="full-width-container" *ngIf="!isViewMode">
                                                    <div class="flex-align-panel mb-2">
                                                        <div class="custom-toggle">
                                                            <label class="switch">
                                                                <input type="checkbox" class="form-control" id="enableFax2Mail" formControlName="enableFax2Mail" placeholder="enableFax2Mail" (change)="changeCheckbox('enableFax2Mail', $event)" />
                                                                <span class="slider round"></span>
                                                            </label>
                                                        </div>
                                                        <span class="user-activate"> Activate.</span> &nbsp;<a class="contact-cursor-pointer" href="https://www.salesoptima.com/features/mobile-crm" target="_blank">Learn More</a>&nbsp;
                                                    </div>
                                                    <div class="flex-align-panel">
                                                        <div>
                                                            <span class="control-label text-success">Account type</span>
                                                            <select class="form-control" formControlName="fax2EmailFrom" (change)="changeCheckbox('fax2EmailFrom', $event)">
                                                                <option value="">--Select--</option>
                                                                <option value="1">Fax2Mail</option>
                                                                <option value="2">eFax</option>
                                                            </select>
                                                        </div>
                                                        <div class="margin-left10 flex-width1">
                                                            <span class="control-label text-success">Account email address</span>
                                                            <input type="text" class="form-control" id="accEmailAdd" formControlName="accEmailAdd" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="full-width-container" *ngIf="isViewMode">
                                                    <div *ngIf="!userProfileForm.controls.enableFax2Mail?.value">
                                                        <span style="color:red" *ngIf="!userProfileForm.controls.enableFax2Mail?.value">Disabled</span>&nbsp;
                                                        <a class="contact-cursor-pointer" href="https://www.salesoptima.com/features/mobile-crm" target="_blank">Learn More</a>
                                                    </div>
                                                    <div *ngIf="userProfileForm.controls.enableFax2Mail?.value">
                                                        <span class="control-label text-success" *ngIf="userProfileForm.controls.enableFax2Mail?.value">Activated</span>
                                                        <div *ngIf="userProfileForm.controls.fax2EmailFrom?.value > 0">Account Type : <span>{{userProfileForm.controls.enableFax2Mail?.value == 1 ? 'Fax2Mail' :userProfileForm.controls.enableFax2Mail?.value == 2 ? 'eFax' : ''}}</span></div>
                                                        <div *ngIf="userProfileForm.controls.accEmailAdd?.value != ''">Account Email Address: <span>{{userProfileForm.controls.accEmailAdd?.value}}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label" for="email">Email</span>
                                                    <span class="control-label text-success">Username</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="input-group" *ngIf="!isViewMode">
                                                    <input type="text" class="form-control" id="email" readonly formControlName="email" placeholder="Email" pattern="^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$" aria-label="email" aria-describedby="basic-addon1">
                                                    <div class="input-group-prepend">
                                                        <button type="button" id="basic-addon1" class="input-group-text" (click)="goToLink('email')">
                                                            <i class="fa fa-envelope" title="Go to Link"></i>
                                                            <span class="grid-common-text"></span>
                                                        </button>
                                                    </div>
                                                    <div class="search-query-error" *ngIf="userProfileForm.controls['email'].errors &&(userProfileForm.controls['email'].touched || userProfileForm.controls['email'].dirty)">
                                                        <div [hidden]="!userProfileForm.controls['email'].errors?.pattern">
                                                            Invalid Email
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="isViewMode">
                                                    <span>
                                                        <a href="mailto:{{userProfileForm.controls.email?.value}}">{{userProfileForm.controls.email?.value}}</a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label" for="otherEmail">Other Email</span>
                                                    <span class="control-label text-success">Reminders formated for mobile devices</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div *ngIf="!isViewMode">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" id="otherEmail" formControlName="otherEmail" placeholder="Other Email" pattern="^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$" aria-label="otherEmail" aria-describedby="basic-addon1">
                                                        <div class="input-group-prepend">
                                                            <button type="button" id="basic-addon1" class="input-group-text" (click)="goToLink('otherEmail')">
                                                                <i class="fa fa-envelope" title="Go to Link"></i>
                                                                <span class="grid-common-text"></span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="search-query-error" *ngIf="userProfileForm.controls['otherEmail'].errors &&(userProfileForm.controls['otherEmail'].touched || userProfileForm.controls['otherEmail'].dirty)">
                                                        <div [hidden]="!userProfileForm.controls['otherEmail'].errors?.pattern">
                                                            Invalid Other Email
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="isViewMode">
                                                    <span>
                                                        <a href="mailto:{{userProfileForm.controls.otherEmail?.value}}">{{userProfileForm.controls.otherEmail?.value}}</a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="useBothEmail">Use Both Emails</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div *ngIf="!isViewMode">
                                                    <div class="custom-toggle">
                                                        <label class="switch">
                                                            <input type="checkbox" class="form-control" id="useBothEmail" formControlName="useBothEmail" placeholder="Use Both Emails" (change)="changeCheckbox('useBothEmail', $event)" />
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </div>
                                                    <span class="use-both-email"> Send messages to both emails.</span>

                                                </div>

                                                <div *ngIf="isViewMode">
                                                    <span style="color: red" *ngIf="!userProfileForm.controls.useBothEmail?.value">Appt. reminders will be sent to primary email only.</span>
                                                    <span class="control-label text-success" *ngIf="userProfileForm.controls.useBothEmail?.value">Appt. reminders will be sent to both emails.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label" for="defaultSignature">Signature</span>
                                                    <span class="control-label text-success">Default</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="full-width-container" *ngIf="!isViewMode">
                                                    <textarea type="text" class="form-control" id="defaultSignature" formControlName="defaultSignature" placeholder="Signature"></textarea>
                                                </div>
                                                <div *ngIf="isViewMode">
                                                    <span [innerHtml]="textWithLineBreaks | safeHtml"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="htmlSign">HTML Signature</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="full-width-container" *ngIf="!isViewMode">
                                                    <kendo-editor formControlName="hTMLSignature" class="form-control p-0">
                                                        <kendo-toolbar>
                                                            <kendo-toolbar-buttongroup>
                                                                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                                                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                                                <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                                                            </kendo-toolbar-buttongroup>
                                                            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                                                            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                                                            <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                                                            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                                                        </kendo-toolbar>
                                                    </kendo-editor>
                                                </div>

                                                <div *ngIf="isViewMode">
                                                    <span *ngIf="userProfile?.hTMLSignature">-Has been set-</span>
                                                    <span class="error-red" *ngIf="!userProfile?.hTMLSignature">-Has not been set-</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label" for="enableSkype">HTML Email Template</span>
                                                    <span class="control-label text-success" for="enableSkype">Default</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="full-width-container" *ngIf="!isViewMode">
                                                    <select class="form-control" formControlName="emailTemplateID">
                                                        <option [value]=0>--Select--</option>
                                                        <option *ngFor="let item of emailTemplates; let i = index" [value]='item.emailTemplateID'>
                                                            {{item?.templateName}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div *ngIf="isViewMode">
                                                    <span *ngIf="userProfile?.emailTemplateID" [innerHTML]="getEmailTemplateByID(userProfileForm.controls.emailTemplateID?.value) | safeHtml"></span>
                                                    <span class="error-red" *ngIf="!userProfile?.emailTemplateID">-Has not been set-</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="fromDisplayName">Email From: Display</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="full-width-container" *ngIf="!isViewMode">
                                                    <input type="text" class="form-control" id="fromDisplayName" formControlName="fromDisplayName" placeholder="Email From: Display" />
                                                </div>
                                                <div *ngIf="isViewMode">
                                                    <span>{{userProfileForm.controls.fromDisplayName?.value}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="custom-action-title">
                                        <span>Company</span>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="officeCode">Office</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <!--<div class="full-width-container" *ngIf="!isViewMode">
                                                  <select *ngIf="userProfile" class="form-control" formControlName="officeCode">
                                                    <option value="0">--Select--</option>
                                                    <option *ngFor="let item of officeCodeList; let i = index" [ngValue]='item.key'>
                                                      {{item?.value}}
                                                    </option>
                                                  </select>
                                                </div>-->


                                                <div>
                                                    <span>{{getOfficeName(userProfileForm.controls.officeCode?.value) }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="teamCode">Team</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <!--<div class="full-width-container" *ngIf="!isViewMode">
                                                  <select class="form-control" formControlName="teamCode">
                                                    <option value="0">--Select--</option>
                                                    <option *ngFor="let item of teamCodeList; let i = index" [ngValue]='item.key'>
                                                      {{item?.value}}
                                                    </option>
                                                  </select>
                                                </div>-->



                                                <div>
                                                    <span>{{getTeamName(userProfileForm.controls.teamCode?.value)}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="flex-width-panel">
                                            <div class="cards-colunm-left">
                                                <span class="control-label" for="employeeID">EmployeeID</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="full-width-container" *ngIf="!isViewMode">
                                                    <input type="text" class="form-control" id="employeeID" formControlName="employeeID" placeholder="employee ID" />
                                                </div>

                                                <div *ngIf="isViewMode">
                                                    <span>{{userProfileForm.controls.employeeID?.value}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cards-body-section" *ngIf="!isViewMode">
                        <div class="cards-colunm-right">
                            <div class="full-width-container text-center">
                                <button class="btn btn-primary" [disabled]="showSpinner" [hidden]="roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false" type="submit">
                                    <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
                                    <ng-template [ngIf]="buttonTypeOperation===1">Saving  <span><i wrapper> </i></span></ng-template>
                                </button>
                                <button class="btn btn-primary" (click)="onreset()" type="button">Reset</button>
                                <button class="btn btn-cancel" (click)="backToPreviousPage()" type="button"> Cancel</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
