import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ContactListResponse } from '../../../models/contact.model';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { Lead, LeadListResponse } from '../../../models/lead.model';
import { LeadQuickSearchRequest } from '../../../models/leadField.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { ContactSearchReq, SearchItem, SearchItemListResponse } from '../../../models/search.model';
import { GlobalService } from '../../../services/global.service';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { SearchContactService } from '../../../services/Searchcontact.service';
import { ContactCommonSearchService } from '../../../services/shared/contact-common-search.service';
import { ContactSearchService } from '../../../services/shared/contact-search.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
    selector: 'app-lead-new-search',
    templateUrl: './lead-new-search.component.html',
    styleUrl: './lead-new-search.component.css'
})
export class LeadNewSearchComponent implements OnInit {
    searchQueriesForm: FormGroup
    encryptedUser: string = '';
    userResponse: UserResponse;
    user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    searchItemListResponse: SearchItemListResponse;
    searchItems: SearchItem[];
    ddField: SearchItem[];
    usersObj: SearchItem;
    leadClass1Code: SearchItem;
    check1Obj: SearchItem;
    check2Obj: SearchItem;
    check3Obj: SearchItem;
    check4Obj: SearchItem;
    check5Obj: SearchItem;
    tagsObj: SearchItem;
    check6Obj: SearchItem;
    emailInfoStatus: SearchItem;
    emailPromoStatus: SearchItem;
    textOptStatus: SearchItem;
    isShowCompFields: boolean = false;
    officeObj: SearchItem;
    teamObj: SearchItem;
    contactListResponse: ContactListResponse;
    statusObj: SearchItem;
    leadClass2Code: SearchItem;
    leadClass3Code: SearchItem;
    leadClass6Code: SearchItem;
    leadClass8Code: SearchItem;
    leadClass4Code: SearchItem;
    leadClass7Code: SearchItem;
    leadClass5Code: SearchItem;
    leadClass9Code: SearchItem;
    customMoney1Obj: SearchItem;
    customMoney2Obj: SearchItem;
    customMoney3Obj: SearchItem;
    customMoney4Obj: SearchItem;
    netRevenueObj: SearchItem;
    locationOfficeCodeObj: SearchItem;
    importOfficeCodeObj: SearchItem;
    leadQuickSearchRequest: LeadQuickSearchRequest;
    leadListResponse: LeadListResponse;
    @Output() leadListChanged = new EventEmitter<Lead[]>(null);
    constructor(private fb: FormBuilder,
        private _globalService: GlobalService,
        private _router: Router,
        private _utilityService: UtilityService,
        private _localService: LocalService,
        private _searchContactService: SearchContactService,
        private leadSettingService: LeadSettingService,
        public _contactSearchService: ContactSearchService,
        public datepipe: DatePipe,
    ) {

    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.searchQueriesForm = this.prepareSearchQueriesForm();
                        this.getLeadSearch();
                    }
                    else {
                        this._router.navigate(['/unauthorized']);
                    }
                });
            }
            else {
                this._router.navigate(['/unauthorized']);
            }
        });
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-new-search.authenticateR", err.message, null,
                    'Feature: ' + eFeatures.None
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    prepareSearchQueriesForm() {
        return this.fb.group({
            ddField: ['1'],
            txtSearch: [''],

            ddRevenueOperator: ['>'],
            txtRevenue: [''],
            ddVolumeOperator: ['>'],
            txtVolume: [''],

            ddCustomMoney1Operator: ['>'],
            txtCustomMoney1: [''],
            ddCustomMoney2Operator: ['>'],
            txtCustomMoney2: [''],
            ddCustomMoney3Operator: ['>'],
            txtCustomMoney3: [''],
            ddCustomMoney4Operator: ['>'],
            txtCustomMoney4: [''],

            ddManager: [this.user?.cLPUserID.toString()],
            ddTeamCode: ['0'],
            ddOfficeCode: ['0'],
            ddLeadStatusCode: [''],
            ddLocationOfficeCode: [''],
            ddImportOfficeCode: [''],

            ddLeadClass1Code: [''],
            ddLeadClass2Code: [''],
            ddLeadClass3Code: [''],
            ddLeadClass4Code: [''],
            ddLeadClass5Code: [''],
            ddLeadClass6Code: [''],
            ddLeadClass7Code: [''],
            ddLeadClass8Code: [''],
            ddLeadClass9Code: [''],

            ddCheck1: ['-1'],
            ddCheck2: ['-1'],
            ddCheck3: ['-1'],
            ddCheck4: ['-1'],
            ddCheck5: ['-1'],
            ddCheck6: ['-1'],

            ddOpTags: ['any'],
            lbTags: [[]],
            ddDateFilter: ['dtCreated'],
            txtdtStart: [''],
            txtdtEnd: [''],
        })
    }

    async getLeadSearch() {
        await this._searchContactService.getLeadSearchFields(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID)
            .then(async (result: SearchItemListResponse) => {
                if (result) {
                    this.searchItemListResponse = UtilityService.clone(result);
                    this.searchItems = this.searchItemListResponse.searchItems.sort((a, b) => a.displayValue.localeCompare(b.displayValue))
                    console.log(this.searchItems);
                    this.ddField = this.searchItems.filter((item) => item?.controlType == 't');
                    this.ddField = this.ddField.sort((a, b) => a.value.localeCompare(b.value));
                    this.usersObj = this.searchItems.filter((item) => item?.columnName == "CLPUserID")[0];
                    this.officeObj = this.searchItems.filter((item) => item?.columnName == "OfficeCode")[0];
                    this.teamObj = this.searchItems.filter((item) => item?.columnName == "TeamCode")[0];
                    this.statusObj = this.searchItems.filter((item) => item?.columnName == "LeadStatusCode")[0];
                    this.leadClass1Code = this.searchItems.filter((item) => item?.columnName == "LeadClass1Code")[0];
                    this.leadClass2Code = this.searchItems.filter((item) => item?.columnName == "LeadClass2Code")[0];
                    this.leadClass3Code = this.searchItems.filter((item) => item?.columnName == "LeadClass3Code")[0];
                    this.leadClass4Code = this.searchItems.filter((item) => item?.columnName == "LeadClass4Code")[0];
                    this.leadClass5Code = this.searchItems.filter((item) => item?.columnName == "LeadClass5Code")[0];
                    this.leadClass6Code = this.searchItems.filter((item) => item?.columnName == "LeadClass6Code")[0];
                    this.leadClass7Code = this.searchItems.filter((item) => item?.columnName == "LeadClass7Code")[0];
                    this.leadClass8Code = this.searchItems.filter((item) => item?.columnName == "LeadClass8Code")[0];
                    this.leadClass9Code = this.searchItems.filter((item) => item?.columnName == "LeadClass9Code")[0];
                    this.check1Obj = this.searchItems.filter((item) => item?.columnName == "Check1")[0];
                    this.check2Obj = this.searchItems.filter((item) => item?.columnName == "Check2")[0];
                    this.check3Obj = this.searchItems.filter((item) => item?.columnName == "Check3")[0];
                    this.check4Obj = this.searchItems.filter((item) => item?.columnName == "Check4")[0];
                    this.check5Obj = this.searchItems.filter((item) => item?.columnName == "Check5")[0];
                    this.check6Obj = this.searchItems.filter((item) => item?.columnName == "Check6")[0];
                    this.customMoney1Obj = this.searchItems.filter((item) => item?.columnName == "CustomMoney1")[0];
                    this.customMoney2Obj = this.searchItems.filter((item) => item?.columnName == "CustomMoney2")[0];
                    this.customMoney3Obj = this.searchItems.filter((item) => item?.columnName == "CustomMoney3")[0];
                    this.customMoney4Obj = this.searchItems.filter((item) => item?.columnName == "CustomMoney4")[0];
                    this.netRevenueObj = this.searchItems.filter((item) => item?.columnName == "Revenue")[0];
                    this.locationOfficeCodeObj = this.searchItems.filter((item) => item?.columnName == "LocationOfficeCode")[0];
                    this.importOfficeCodeObj = this.searchItems.filter((item) => item?.columnName == "ImportOfficeCode")[0];
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lead-new-search.getLeadSearch", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                    + 'cLPUserID: ' + this.user.cLPUserID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async submitSearch() {
        await this.setContactSearchReq()
        this.leadSettingService.leadQuickSearch(this.encryptedUser, this.user?.cLPUserID, this.leadQuickSearchRequest)
            .then(async (result: LeadListResponse) => {
                if (result) {
                    this.leadListResponse = UtilityService.clone(result);
                    this.leadListChanged.emit(this.leadListResponse.leads);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-common-search.getContactSearch", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    setContactSearchReq() {
        this.leadQuickSearchRequest = <LeadQuickSearchRequest>{}
        this.leadQuickSearchRequest.CLPCompanyID = this.user?.cLPCompanyID
        this.leadQuickSearchRequest.ddManager = this.searchQueriesForm.controls['ddManager'].value
        this.leadQuickSearchRequest.ddCheck1 = this.searchQueriesForm.controls['ddCheck1'].value == -1 ? "" : this.searchQueriesForm.controls['ddCheck1'].value
        this.leadQuickSearchRequest.ddCheck2 = this.searchQueriesForm.controls['ddCheck2'].value == -1 ? "" : this.searchQueriesForm.controls['ddCheck2'].value
        this.leadQuickSearchRequest.ddCheck3 = this.searchQueriesForm.controls['ddCheck3'].value == -1 ? "" : this.searchQueriesForm.controls['ddCheck3'].value
        this.leadQuickSearchRequest.ddCheck4 = this.searchQueriesForm.controls['ddCheck4'].value == -1 ? "" : this.searchQueriesForm.controls['ddCheck4'].value
        this.leadQuickSearchRequest.ddCheck5 = this.searchQueriesForm.controls['ddCheck5'].value == -1 ? "" : this.searchQueriesForm.controls['ddCheck5'].value
        this.leadQuickSearchRequest.ddCheck6 = this.searchQueriesForm.controls['ddCheck6'].value == -1 ? "" : this.searchQueriesForm.controls['ddCheck6'].value
        this.leadQuickSearchRequest.ddLeadClass1Code = this.searchQueriesForm.controls['ddLeadClass1Code'].value
        this.leadQuickSearchRequest.ddLeadClass2Code = this.searchQueriesForm.controls['ddLeadClass2Code'].value
        this.leadQuickSearchRequest.ddLeadClass3Code = this.searchQueriesForm.controls['ddLeadClass3Code'].value
        this.leadQuickSearchRequest.ddLeadClass4Code = this.searchQueriesForm.controls['ddLeadClass4Code'].value
        this.leadQuickSearchRequest.ddLeadClass5Code = this.searchQueriesForm.controls['ddLeadClass5Code'].value
        this.leadQuickSearchRequest.ddLeadClass6Code = this.searchQueriesForm.controls['ddLeadClass6Code'].value
        this.leadQuickSearchRequest.ddLeadClass7Code = this.searchQueriesForm.controls['ddLeadClass7Code'].value
        this.leadQuickSearchRequest.ddLeadClass8Code = this.searchQueriesForm.controls['ddLeadClass8Code'].value
        this.leadQuickSearchRequest.ddLeadClass9Code = this.searchQueriesForm.controls['ddLeadClass9Code'].value
        this.leadQuickSearchRequest.ddField = this.searchQueriesForm.controls['ddField'].value
        this.leadQuickSearchRequest.ddOpTags = this.searchQueriesForm.controls['ddOpTags'].value
        this.leadQuickSearchRequest.lbTags = this.searchQueriesForm.controls['lbTags'].value
        this.leadQuickSearchRequest.txtSearch = this.searchQueriesForm.controls['txtSearch'].value
        this.leadQuickSearchRequest.ddCustomMoney1Operator = this.searchQueriesForm.controls['ddCustomMoney1Operator'].value
        this.leadQuickSearchRequest.ddCustomMoney2Operator = this.searchQueriesForm.controls['ddCustomMoney2Operator'].value
        this.leadQuickSearchRequest.ddCustomMoney3Operator = this.searchQueriesForm.controls['ddCustomMoney3Operator'].value
        this.leadQuickSearchRequest.ddCustomMoney4Operator = this.searchQueriesForm.controls['ddCustomMoney4Operator'].value
        this.leadQuickSearchRequest.txtCustomMoney1 = this.searchQueriesForm.controls['txtCustomMoney1'].value
        this.leadQuickSearchRequest.txtCustomMoney2 = this.searchQueriesForm.controls['txtCustomMoney2'].value
        this.leadQuickSearchRequest.txtCustomMoney3 = this.searchQueriesForm.controls['txtCustomMoney3'].value
        this.leadQuickSearchRequest.txtCustomMoney4 = this.searchQueriesForm.controls['txtCustomMoney4'].value
        this.leadQuickSearchRequest.ddDateFilter = this.searchQueriesForm.controls['ddDateFilter'].value
        this.leadQuickSearchRequest.ddLeadStatusCode = this.searchQueriesForm.controls['ddLeadStatusCode'].value
        this.leadQuickSearchRequest.ddLocationOfficeCode = this.searchQueriesForm.controls['ddLocationOfficeCode'].value
        this.leadQuickSearchRequest.ddOfficeCode = this.searchQueriesForm.controls['ddOfficeCode'].value
        this.leadQuickSearchRequest.ddTeamCode = this.searchQueriesForm.controls['ddTeamCode'].value
        this.leadQuickSearchRequest.ddRevenueOperator = this.searchQueriesForm.controls['ddRevenueOperator'].value
        this.leadQuickSearchRequest.ddVolumeOperator = this.searchQueriesForm.controls['ddVolumeOperator'].value
        let dtEnd = this.searchQueriesForm.controls['txtdtEnd'].value != '' ? this.datepipe.transform(this.searchQueriesForm.controls['txtdtEnd'].value, "MMddyyyy") : ""
        let dtStart = this.searchQueriesForm.controls['txtdtStart'].value != '' ? this.datepipe.transform(this.searchQueriesForm.controls['txtdtStart'].value, "MMddyyyy") : "";
        this.leadQuickSearchRequest.txtdtEnd = dtEnd
        this.leadQuickSearchRequest.txtdtStart = dtStart
        this.leadQuickSearchRequest.txtRevenue = this.searchQueriesForm.controls['txtRevenue'].value
        this.leadQuickSearchRequest.txtVolume = this.searchQueriesForm.controls['txtVolume'].value
    }
}
