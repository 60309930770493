import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';
import { Document } from '../models/document'
import { EmailDropDownsResponse, EmailSnippet, EmailSnippetListResponse, EmailSnippetResponse, EmailTemplate, EmailTemplateListResponse, MailMergeTemplate, MailMergeTemplateListResponse } from '../models/emailTemplate.model';
import { DocumentResponse } from '../models/document';
import { Note, NoteResponse } from '../models/note.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { LinkEmailFilterResponse } from '../models/link-group.model';
import { RddEmailTemplateDD } from '../models/campaignTemplate.model';
import { eDocumentCategory } from '../models/enum.model';

@Injectable({
  providedIn: 'root'
})
export class OutBoundEmailService {

  private baseUrl: string;
  private api: string = "api/OutBoundEmail";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async getEmailDropDowns(encryptedUser: string, cLPCompanyID: number, cLPUserID: number, teamCode: number): Promise<EmailDropDownsResponse | void> {
    const a = await this.httpClient
      .get<EmailDropDownsResponse>(`${this.baseUrl}/EmailDropDowns_Get/${cLPCompanyID}/${cLPUserID}/${teamCode}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "cLPCompanyID - " + cLPCompanyID + "," + "cLPUserID - " + cLPUserID + "," + "teamCode - " + teamCode, encryptedUser,"OutBoundEmailService", "getEmailDropDowns"); }); //toPromise();
    return a;
  }

  async getDocumentsListByCLPUser(encryptedUser: string, userId: number): Promise<DocumentResponse | void> {
    const a = await this.httpClient
      .get<DocumentResponse>(`${this.baseUrl}/Documents_GetDocumentByCLPUser/${userId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "userId - " + userId, encryptedUser, "OutBoundEmailService", "getDocumentsListByCLPUser"); });
    return a;
  }

  async getDocumentsListByOwner(encryptedUser: string, ownerId: number, documentCategory: eDocumentCategory = eDocumentCategory.Contact, isRecent: boolean = false): Promise<DocumentResponse | void> {
    const a = await this.httpClient
      .get<DocumentResponse>(`${this.baseUrl}/Documents_GetListByOwner/${ownerId}?documentCategory=${documentCategory}&isRecent=${isRecent}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "ownerId - " + ownerId + "," + "documentCategory - " + documentCategory + "," + "isRecent - " + isRecent , encryptedUser,"OutBoundEmailService", "getDocumentsListByOwner"); });
    return a;
  }

  async documentsSearch(encryptedUser: string, arrString, userId: number): Promise<DocumentResponse | void> {
    const a = await this.httpClient.post<DocumentResponse>(`${this.baseUrl}/Documents_Search/${userId}`, arrString, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, arrString, "r - " + encryptedUser + "," + "userId - " + userId, encryptedUser, "OutBoundEmailService", "documentsSearch"); });
    return a;
  }

  async loadEmailSnippet(encryptedUser: string, emailSnippetID: number): Promise<EmailSnippetResponse | void> {
    const a = await this.httpClient
      .get<EmailSnippetResponse>(`${this.baseUrl}/EmailSnippet_Load/${emailSnippetID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "emailSnippetID - " + emailSnippetID, encryptedUser, "OutBoundEmailService", "loadEmailSnippet"); }); //toPromise();
    return a;
  }

    async scheduleEmailOrSend(encryptedUser: string, note): Promise<NoteResponse | void> {
        const a = await this.httpClient.post<NoteResponse>(`${this.baseUrl}/ScheduleEmailOrSend`, note, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, note, "r - " + encryptedUser , encryptedUser, "OutBoundEmailService", "scheduleEmailOrSend"); });
    return a;
  }

    async sendOutboundMail(encryptedUser: string, note: Note, isPreview: boolean = false): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/SendOutboundMail?isPreview=${isPreview}`, note, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, note, "r - " + encryptedUser, encryptedUser, "OutBoundEmailService", "SendOutboundMail"); });
    return a;
  }

  async downloadDocumentsByDocId(encryptedUser: string, documentID: number): Promise<Document | void> {
    const a = await this.httpClient
      .get<Document>(`${this.baseUrl}/DocumentsDownLoadByDocId/${documentID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "documentID - " + documentID, encryptedUser,"OutBoundEmailService", "downloadDocumentsByDocId"); });
    return a;
  }

  async linkOutBoundEmailLoad(encryptedUser: string, linkId: number, clpCompanyID: number, clpuserID: number): Promise<LinkEmailFilterResponse | void> {
    const a = await this.httpClient
      .get<LinkEmailFilterResponse>(`${this.baseUrl}/LinkOutBoundEmail_Load/${linkId}/${clpCompanyID}/${clpuserID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "linkId - " + linkId + "," + "clpCompanyID - " + clpCompanyID + "," + "clpuserID - " + clpuserID, encryptedUser,"OutBoundEmailService", "linkOutBoundEmailLoad"); });
    return a;
  }

  async linkOutBoundEmailNext(encryptedUser: string, contactID: number, clpCompanyID: number, clpuserID: number, note: Note): Promise<NoteResponse | void> {
    const a = await this.httpClient
      .post<NoteResponse>(`${this.baseUrl}/LinkOutBoundEmail_Next/${contactID}/${clpCompanyID}/${clpuserID}`, note, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, note, "r - " + encryptedUser + "," + "contactID - " + contactID + "," + "clpCompanyID - " + clpCompanyID + "," + "clpuserID - " + clpuserID, encryptedUser,"OutBoundEmailService", "linkOutBoundEmailNext"); });
    return a;
  }

  async linkOutBoundEmailSendEmail(encryptedUser: string, nt: Note, linkID: number, clpcompanyID: number, clpuserID: number, selectedEmailTemplateID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/LinkOutBoundEmail_SendEmail/${linkID}/${clpcompanyID}/${clpuserID}/${selectedEmailTemplateID}`, nt, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, nt, "r - " + encryptedUser + "," + "linkID - " + linkID + "," + "clpcompanyID - " + clpcompanyID + "," + "clpuserID - " + clpuserID + "," + "selectedEmailTemplateID - " + selectedEmailTemplateID  , encryptedUser,"OutBoundEmailService", "linkOutBoundEmailSendEmail"); });
    return a;
  }

  async rddEmailTemplateList_GetByTeam(encryptedUser: string, clpcompanyId: number, teamCode: number): Promise<RddEmailTemplateDD[] | void> {
    const a = await this.httpClient
      .get<RddEmailTemplateDD[]>(`${this.baseUrl}/RddEmailTemplateList_GetByTeam/${clpcompanyId}/${teamCode}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpcompanyId - " + clpcompanyId + "," + "teamCode - " + teamCode , encryptedUser,"OutBoundEmailService", "rddEmailTemplateList_GetByTeam"); });
    return a;
  }

  async mailMergeTemplate_GetList(encryptedUser: string, clpcompanyId: number, userId: number, includeShared: boolean): Promise<MailMergeTemplateListResponse | void> {
    const a = await this.httpClient
      .get<MailMergeTemplateListResponse>(`${this.baseUrl}/mailMergeTemplate_GetList/${clpcompanyId}/${userId}/${includeShared}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpcompanyId - " + clpcompanyId + "," + "userId - " + userId + "," + "includeShared - " + includeShared, encryptedUser,"OutBoundEmailService", "mailMergeTemplate_GetList"); });
    return a;
  }

  async emailTemplate_GetList(encryptedUser: string, clpCompanyId: number, inclucludeShared: boolean, clpuserId: number): Promise<EmailTemplateListResponse | void> {
    const a = await this.httpClient
      .get<EmailTemplateListResponse>(`${this.baseUrl}/EmailTemplate_GetList/${clpCompanyId}/${inclucludeShared}/${clpuserId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "inclucludeShared - " + inclucludeShared + "clpuserId - " + clpuserId , encryptedUser,"OutBoundEmailService", "EmailTemplate_GetList"); });
    return a;
  }

  async delete_Document(encryptedUser: string, documentId: number): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/Document_Delete/${documentId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "documentId - " + documentId , encryptedUser,"OutBoundEmailService", "delete_Document") });

    return http$;
  }

  async getEmailSnippetList(encryptedUser: string, clpuserId: number): Promise<EmailSnippetListResponse | void> {
    const a = await this.httpClient
      .get<EmailSnippetListResponse>(`${this.baseUrl}/EmailSnippet_GetList/${clpuserId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpuserId - " + clpuserId ,encryptedUser,"OutBoundEmailService", "getEmailSnippetList"); });
    return a;
  }

  async saveEmailSnippet(encryptedUser: string, emailSnippetObj: EmailSnippet): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/EmailSnippet_Save/`, emailSnippetObj, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, emailSnippetObj, "r - " + encryptedUser, encryptedUser,"OutBoundEmailService", "saveEmailSnippet"); });
    return a;
  }

  async deleteEmailSnippet(encryptedUser: string, emailSnippetId : number): Promise<SimpleResponse | void> {
    const http$ = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/EmailSnippet_Delete/${emailSnippetId }`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "emailSnippetId  - " + emailSnippetId ,encryptedUser,"OutBoundEmailService", "deleteemailSnippet") });

    return http$;
  }


  async linkOutBoundEmailCancel(encryptedUser: string, noteID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/LinkOutBoundEmail_Cancel/${noteID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "noteID - " + noteID, encryptedUser, "OutBoundEmailService", "linkOutBoundEmailCancel"); });
    return a;
  }
}
