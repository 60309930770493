<div class="custom-security-link" *ngIf="isShowFilter && !isFromCoachCorner && eCat != 7">
    <a [routerLink]="['/lead-detail', ownerId]" *ngIf="eCat == 3">View Lead</a>
    <a [routerLink]="['/contact', user?.cLPUserID, ownerId]" *ngIf="ownerId > 0">View Contact</a>
    <a [routerLink]="['/calender']">My Calender</a>
    <a *ngIf="eCat != 3" (click)="myApptList()">My Appt List</a>
    <a [routerLink]="user?.cLPCompanyID === 1226  ? ['/call-ih'] : ['/call']">My Call List</a>
    <a (click)="appointmentPopUp(null,true);">New Appt</a>
    <a *ngIf="eCat != 3" [routerLink]="['/task']">My Tasks</a>
</div>
<div class="margin-all-area">
    <div class="wraper-main-section appointment-list">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel">
                    <span><img src="../../../assets/appttitle.svg" class="mr-1" />{{apptListTitle}}</span>
                    <span>{{apptListFilterName}}</span>
                    <span *ngIf="eCat==3">:&nbsp;{{apptListTitleCompanyName}}</span>
                    <span *ngIf="ownerId>0 && eCat!=3" class="pl-0">:&nbsp; <a class="webkit-any-link" (click)="gotoLink()" title="{{apptTooltip}}">{{apptListTitleCompanyName}}</a></span>
                </div>
                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputOverdueAppt.value = ''">Reset Grid Setting</button>
                        <input class="" placeholder="Search..." kendoTextBox (input)="onOveDueApptFilter($event.target.value)" #inputOverdueAppt />
                        <button *ngIf="overdueApptList?.length > 0" (click)="toogleCheckAllAppt()" class="icon-btn" type="button" [matTooltip]="isCheckAllAppt?'Uncheck All':'Check All' " data-toggle="modal">
                            <img src='../../../../assets/btncapcheckbox.svg' />
                            <span class="btn-text">Check All</span>
                        </button>
                        <button *ngIf="overdueApptList?.length > 0" class="icon-btn" type="button" matTooltip="Mark all checked appointment as Phone Call." data-toggle="modal" data-target="#bulkApptUpdatePhone">
                            <img src='../../../../assets/callbtn.svg' />
                            <span class="btn-text">Phone All</span>
                        </button>
                        <button *ngIf="overdueApptList?.length > 0" class="icon-btn" type="button" matTooltip="Bump all checked appointments 1 business day" data-toggle="modal" data-target="#bulkApptUpdateBumped">
                            <img src='../../../../assets/btnmarkasbumped_md.svg' />
                            <span class="btn-text">Appointments</span>
                        </button>
                        <button *ngIf="overdueApptList?.length > 0" class="icon-btn" type="button" matTooltip="Mark  all checked appointment as Pending" (click)="apptStatusBulkUpdate('Pending','no')" data-toggle="modal" data-target="#bulkApptUpdate">
                            <img src='../../../../assets/transiconstatuspending.svg' />
                            <span class="btn-text">Pending</span>
                        </button>
                        <button *ngIf="overdueApptList?.length > 0" class="icon-btn" type="button" matTooltip="Mark  all checked appointment as Cancelled" (click)="apptStatusBulkUpdate('Cancelled','no')" data-toggle="modal" data-target="#bulkApptUpdate">
                            <img src='../../../../assets/btnmarkcancelled_md.svg' />
                            <span class="btn-text">Cancelled</span>
                        </button>
                        <button *ngIf="overdueApptList?.length > 0" class="icon-btn" type="button" matTooltip="Mark  all checked appointment as Completed" (click)="apptStatusBulkUpdate('Completed','no')" data-toggle="modal" data-target="#bulkApptUpdate">
                            <img src='../../../../assets/btnmarkcompleted_md.svg' />
                            <span class="btn-text">Completed</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="global-body-section">
                <div class="flex-row-inner margin-left-none">
                    <div *ngIf="isShowFilter && !isFromCoachCorner">
                        <kendo-calendar [(value)]="currDate" (valueChange)="onDateChange($event)"></kendo-calendar>
                        <div class="mt-3">
                            <div class="global-card-section">
                                <div class="global-header-section">
                                    <div class="svg-icon-panel">Quick Filters</div>
                                </div>
                                <div class="global-body-section">
                                    <form [formGroup]="appointmentSearchForm">
                                        <div class="wraper-body-panel">
                                            <div class="wraper-body-left">
                                                <div class="margin-all-area">
                                                    <p><a class="contact-cursor-pointer" style="font-weight:600; font-size:13px;" (click)="viewAllAppointment()">view all</a></p>
                                                    <p><a class="contact-cursor-pointer" style="font-weight:600; font-size:13px;" (click)="viewOverDueOnlyAppointment()">view overdue only</a></p>
                                                </div>
                                                <div class="cards-body-section">
                                                    <div class="cards-colunm-left flex-width3">
                                                        <span>Status:</span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <select class="form-control" formControlName="status" (change)="getAppointmentList()">
                                                            <option [value]="3">-None Selected-</option>
                                                            <option [value]="0">Pending Only</option>
                                                            <option [value]="1">Cancelled Only</option>
                                                            <option [value]="2">Completed Only</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="cards-body-section">
                                                    <div class="cards-colunm-left flex-width3">
                                                        <span>Month:</span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <select class="form-control" formControlName="month" (change)="getAppointmentList()">
                                                            <option *ngIf="!isShowFilter" value="0">-All-</option>
                                                            <option *ngFor="let item of monthDD" [value]="item?.value">{{item?.text}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="cards-body-section">
                                                    <div class="cards-colunm-left flex-width3">
                                                        <span>User:</span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <select class="form-control" formControlName="ddUser" (change)="setApptListFilterTitle('user')">
                                                            <option value="0">-All-</option>
                                                            <option *ngFor="let item of userFilterDD" [value]="item?.id">{{item?.text}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="cards-body-section">
                                                    <div class="cards-colunm-left flex-width3">
                                                        <span>Team:</span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <select class="form-control" formControlName="ddTeam" (change)="setApptListFilterTitle('team')">
                                                            <option [value]="0">-All-</option>
                                                            <option *ngFor="let item of teamFilterDD" [value]="item?.teamCode">{{item?.display}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="cards-body-section">
                                                    <div class="cards-colunm-left flex-width3">
                                                        <span>Office:</span>
                                                    </div>
                                                    <div class="cards-colunm-right">
                                                        <select class="form-control" formControlName="ddOffice" (change)="setApptListFilterTitle('office')">
                                                            <option [value]="0">-All-</option>
                                                            <option *ngFor="let item of officeFilterDD" [value]="item?.officeCode">{{item?.display}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="global-body-section">
                            <!--<div class="message-info" *ngIf="!isFromCoachCorner">-->
                            <div class="message-info">
                                <span>Page {{currentPage}} (Appointments found: {{overdueApptList?.length}})</span>
                            </div>
                            <kendo-grid #grid id="gridId" class="my-document-grid" *ngIf="_gridCnfgService?.reloadGridGeneric"
                                        [kendoGridBinding]="overdueApptList"
                                        kendoGridSelectBy="apptID"
                                        [selectable]="{ checkboxOnly: true ,mode: 'multiple'}"
                                        [(selectedKeys)]="overDueApptSelection"
                                        [pageSize]="_gridCnfgService.pageSize"
                                        [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                        [sortable]="{mode: 'multiple'}"
                                        [scrollable]="'scrollable'"
                                        [sort]="_gridCnfgService.sort"
                                        [columnMenu]="{ filter: true }"
                                        [resizable]="true"
                                        [reorderable]="true"
                                        (columnReorder)="_gridCnfgService.columnsOrderChanged('overdue_appt_grid', $event)"
                                        (sortChange)="_gridCnfgService.sortChange('overdue_appt_grid', $event)"
                                        (pageChange)="_gridCnfgService.pageChange('overdue_appt_grid', $event); pageChange($event)"
                                        (columnResize)="_gridCnfgService.columnResize(9,'overdue_appt_grid', $event)"
                                        (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'overdue_appt_grid',grid)">

                                <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                   [field]="column.field"
                                                   [title]="column.title | titlecase"
                                                   [width]="(column.field == '$' ? '55' : column.width) | stringToNumber"
                                                   [filterable]="true"
                                                   [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                   [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                   [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                   [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1 || ( column.field == '$$$' && !isFromCoachCorner)"
                                                   [includeInChooser]="column.field=='$' ? false : (column.field == 'companyName' && isFromCoachCorner) ? false : (column.field == 'contactName' && isFromCoachCorner) ? false : (column.field == 'ownerName' && isFromCoachCorner) ? false : true"
                                                   [resizable]="column.field=='$' ? false : true">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="customer-name" *ngIf="column.field == '$' && column.title == 'S.N.'">{{ rowIndex+1 }}</div>
                                        <div class="customer-name" *ngIf="column.title == ' ' && column.field == '$$'">
                                            <ng-container [ngSwitch]="dataItem.category">
                                                <ng-container *ngSwitchCase="1">
                                                    <div class="grid-colunm-vertical">
                                                        <div class="customer-name">
                                                            <a class="grid-icon" (click)="appointmentPopUp(dataItem, false)">
                                                                <img src='../../../../../assets/companystitleheaderApp.svg' title="Open appointment in new window" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="2">
                                                    <div class="grid-colunm-vertical">
                                                        <div class="customer-name">
                                                            <a class="grid-icon" (click)="appointmentPopUp(dataItem, false)">
                                                                <img src='../../../../../assets/icon_contact.svg' title="Open appointment in new window" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="3">
                                                    <div class="grid-colunm-vertical">
                                                        <div class="customer-name">
                                                            <a class="grid-icon" (click)="appointmentPopUp(dataItem, false)">
                                                                <img src='../../../../../assets/leadstitle.svg' title="Open appointment in new window" />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    <div class="customer-name">
                                                        <a class="grid-icon" (click)="appointmentPopUp(dataItem, false)">
                                                            <img src='../../../../../assets/icon_user.svg' title="Open appointment in new window" />
                                                        </a>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                        <div class="customer-name" *ngIf="column.field == 'apptStartTime'">
                                            <a class="text-primary" title="{{bindDynamicColumnAnchor(dataItem)}}" (click)="appointmentPopUp(dataItem, false)">{{dataItem[column.field] | date: 'EEE' }}&nbsp;{{dataItem[column.field] | date: dateFormat }}&nbsp;&#64;{{dataItem[column.field] | date: 'shortTime' }}</a>
                                        </div>
                                        <div class="customer-name" *ngIf="column.field == 'subject'">{{dataItem[column.field]}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'location'  ">
                                            <img *ngIf="dataItem?.isPhoneCall" src='../../../../assets/callbtn.svg' />
                                            {{dataItem[column.field]}}
                                        </div>
                                        <div class="customer-name" *ngIf="column.field == 'companyName'"><a class="" title="{{dataItem[column.field]}}" [routerLink]="['/company-create']" [queryParams]="{cid: dataItem?.companyId}">{{dataItem[column.field]}}</a></div>
                                        <div class="customer-name" *ngIf="column.field == 'contactName'">
                                            <div class="display-row mt-2">
                                                <span>
                                                    <a class="contact-cursor-pointer" title="View Contact" (click)="viewHandlerQuickDashboardList(dataItem ,'contact')">{{dataItem[column.field]}}</a>
                                                </span>
                                                <!--cid-->
                                                <a *ngIf="dataItem.category==2 && isTxtMsgActive" title="Send Text Message " (click)="moveToTextMsg(dataItem,'msg')" class="tbsmsappt" data-toggle="modal" data-target="#textMsgModal" data-backdrop="static" data-keyboard="false"></a>
                                                <a *ngIf="eSkype==3 && dataItem.category==2 && isTxtMsgActive" title="Make Voice Call" (click)="moveToTextMsg(dataItem,'call')" class="tbcall" data-toggle="modal" data-target="#textCallModal" data-backdrop="static" data-keyboard="false"></a>
                                            </div>
                                        </div>
                                        <div class="customer-name" *ngIf="column.field == 'ownerName' && dataItem.category==3"><a title="View Lead" class="contact-cursor-pointer" (click)="viewHandlerQuickDashboardList(dataItem ,'lead')">{{dataItem[column.field]}}</a></div>
                                        <div class="customer-name" *ngIf="column.field == '$$$'">
                                            <div class="display-row">
                                                <div class="customer-name">
                                                    <a class="grid-icon" (click)="moveToTextMsg(dataItem,'msg')" data-toggle="modal" data-target="#textMsgModal">
                                                        <img src='../../../../../assets/txtmsgstitle_grey.svg' title="Send Text Message" />
                                                    </a>
                                                </div>
                                                <div class="customer-name">
                                                    <a class="grid-icon" (click)="moveToTextMsg(dataItem,'call')" data-toggle="modal" data-target="#textCallModal">
                                                        <img src='../../../../assets/callbtn.svg' title="Make Voice Call" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <!--<div class="customer-name" *ngIf="column.field == 'lead'"><a class="contact-cursor-pointer" title="View Lead" (click)="viewHandlerQuickDashboardList(dataItem?.lead ,'lead')">{{dataItem?.lead?.leadDesc != '' ? dataItem?.lead?.leadDesc : '--'
                                        }}</a>
</div>-->
                                        <div class="customer-name" *ngIf="column.field == 'codeDisplay'"> {{dataItem[column.field]}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'userLastFirst'"><a class="contact-cursor-pointer" (click)="viewHandlerQuickDashboardList(dataItem ,'user')">{{dataItem[column.field]}}</a></div>
                                        <div class="customer-name" *ngIf="column.field == 'status'" (click)="appointmentPopUp(dataItem, false)">
                                            <a class="grid-icon" title="{{dataItem[column.field]===0?'Pending':dataItem[column.field]==1?'Cancelled':dataItem[column.field]===2?'Completed':'Cancelled' }}">
                                                <img *ngIf="dataItem[column.field] == 0" src='../../../../assets/transiconstatuspending.svg' />
                                                <img *ngIf="dataItem[column.field] == 1" src='../../../../assets/btnmarkcancelled_md.svg' />
                                                <img *ngIf="dataItem[column.field] == 2" src='../../../../assets/btnmarkcompleted_md.svg' />
                                            </a>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-checkbox-column *ngIf="!isFromCoachCorner" [width]="45"
                                                            [headerClass]="{ 'text-center': true }"
                                                            [class]="{ 'text-center': true }"
                                                            [resizable]="false"
                                                            [columnMenu]="false"
                                                            [showSelectAll]="true">
                                    <ng-template kendoGridHeaderTemplate>
                                        <input type="checkbox"
                                               kendoCheckBox
                                               id="selectAllOverDueAppointmentCheckboxId"
                                               kendoGridSelectAllCheckbox
                                               (selectAllChange)="onSelectAllOverDueApptChange($event)" />
                                        <label class="k-checkbox-label" for="selectAllOverDueAppointmentCheckboxId"></label>
                                    </ng-template>
                                </kendo-grid-checkbox-column>
                            </kendo-grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="unShareDocumentModal" tabindex="-1" role="dialog" aria-labelledby="unShareDocumentModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>UNSHARE all checked documents?</h2>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-primary">ok</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteDocumentModal" tabindex="-1" role="dialog" aria-labelledby="deleteDocumentModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>Caution! Are you sure you want to DELETE all checked documents?</h2>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn btn-primary">ok</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="overdueApptModal" tabindex="-1" role="dialog" aria-labelledby="overdueApptModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <div *ngIf="blnIsIH == false">
                        <app-appointment-common *ngIf="isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-common>
                    </div>
                    <div *ngIf="blnIsIH ==  true">
                        <app-appointment-ih *ngIf="isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-ih>
                    </div>
                </div>
                <div class="modal-footer p-0 border-0">
                    <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideQuickApptModal();" [hidden]="true">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="textMsgInfo?.isShow" class="modal fade" id="textMsgModal" tabindex="-1" role="dialog" aria-labelledby="textMsgModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-contact-sms *ngIf="user && textMsgInfo?.isShow" (updatedTextMsg)="hideTextMsgModal($event)" [loggedUser]="user" [contactId]="textMsgInfo?.contactId"></app-contact-sms>
                </div>
                <!--<div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideTextMsgModal();">Close</button>
                </div>-->
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="textCallModal" tabindex="-1" role="dialog" aria-labelledby="textCallModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title " id="exampleModalLabel"><img src="../../../../../assets/callbtn_white_hover.svg" class="" />Voice Call</h5>
                    <button type="button" class="icon-btn" data-dismiss="modal" aria-label="Close" (click)="textCallInfo.isShow=false;">
                        <i class="fas fa-times"></i>
                        <span class="btn-text">Close</span>
                    </button>
                </div>
                <div class="modal-body">
                    <contact-new-call *ngIf="user && textCallInfo?.isShow" (updatedCall)="hideTextCallModal($event)" [contactData]="textCallInfo.contact" [callType]="'mp'" [user]="user"></contact-new-call>
                </div>
                <div class="modal-footer d-none">
                    <!--<button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideTextCallModal();">Close</button>-->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="bulkApptUpdate" tabindex="-1" role="dialog" aria-labelledby="bulkAppytUpdateModalLabel" aria-hidden="true" *ngIf="overDueApptSelection?.length">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h5 class="modal-title modal-common-title">Appointment Update Confirmation</h5>
            </div>
            <div class="modal-body modal-common-body">
                <h5>Mark all checked appointment as {{bulkUpdateApptType}}</h5>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="apptStatusBulkUpdate(bulkUpdateApptType,'yes')" data-dismiss="modal" class="btn btn-primary">Confirm</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="bulkApptUpdatePhone" tabindex="-1" role="dialog" aria-labelledby="bulkAppytUpdateModalLabel" aria-hidden="true" *ngIf="overDueApptSelection?.length">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h5 class="modal-title modal-common-title">Appointment Update Confirmation</h5>
            </div>
            <div class="modal-body modal-common-body">
                <h5>Mark all checked appointments as PHONE CALL?</h5>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="apptPhoneCallSubmit('yes')" data-dismiss="modal" class="btn btn-primary">Confirm</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="bulkApptUpdateBumped" tabindex="-1" role="dialog" aria-labelledby="bulkAppytUpdateModalLabel" aria-hidden="true" *ngIf="overDueApptSelection?.length">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h5 class="modal-title modal-common-title">Appointment Update Confirmation</h5>
            </div>
            <div class="modal-body modal-common-body">
                <h5>BUMP all checked appointments 1 business day?</h5>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="apptBumpedSubmit('yes')" data-dismiss="modal" class="btn btn-primary">Confirm</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
