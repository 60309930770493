<div class="outbound-top-panel">
    <div class="modal-content user-body-section border-radius-contact">
        <div class="modal-header user-body-header">
            <h5 class="modal-title white-contact" id="exampleModalLabel">
                <img src="../../../../assets/activity/task/task_title.svg" />
                Invoice
            </h5>
            <div class="header-button-panel">
                <div class="button-wrapper">
                    <button type="button" matTooltip="Download PDF" class="icon-btn" (click)="changeButton()" *ngIf="!isDownload">
                        <i class="fa fa-file-pdf"></i>
                        <span class="btn-text">Download PDF</span>
                    </button>
                    <button type="button" matTooltip="Download" class="icon-btn" (click)="downloadPDF(invoiceData)" *ngIf="isDownload">
                        <img src="../../../../assets/downbtn.svg" />
                        <span class="btn-text">Download</span>
                    </button>
                    <button type="button" matTooltip="View Item" class="icon-btn" (click)="loadEmailTemplate(this.invoiceId,contact?.contactID)" *ngIf="ViewMail">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        <span class="btn-text">View Item</span>
                    </button>
                    <button type="button" matTooltip="Send Mail" class="icon-btn" (click)="sendEmail(contact?.contactID)" *ngIf="isSendMail">
                        <i class="email_new"><img src="../../../../assets/email_new.svg" /></i>
                        <span class="btn-text">View Mail</span>
                    </button>
                    <button type="button" matTooltip="Undo" class="icon-btn" (click)="undoAction()" *ngIf="undoButton">
                        <i class="fa fa-undo" aria-hidden="true"></i>
                        <span class="btn-text">Undo</span>
                    </button>
                    <button type="button" class="icon-btn" data-dismiss="modal" aria-label="Close" (click)="cancelLeadInvoice()">
                        <i class="fa fa-close"></i>
                        <span class="btn-text">Cancel</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="header-button-panel">
        <div class="global-padding10">

        </div>
    </div>
    <div class="margin-all-area">
        <div class="wraper-main-section">
            <div class="global-card-section">
                <form [formGroup]="invoiceForm" *ngIf="invoiceForm">
                    <div class="wraper-body-left">
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span>Number</span>
                            </div>
                            <div class="cards-colunm-right" *ngIf="!isSaved">
                                <input type="text" id="number" value="strNewInvoice" size="10" class="form-control" formControlName="number" />
                            </div>
                            <div class="cards-colunm-right" *ngIf="isSaved">
                                {{invoiceData?.invoiceNumber}}
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span>Invoice Date</span>
                            </div>
                            <div class="cards-colunm-right" *ngIf="!isSaved">
                                <kendo-datetimepicker formControlName="invoicedate"></kendo-datetimepicker>
                            </div>
                            <div class="cards-colunm-right" *ngIf="isSaved">
                                {{dtInvoice}}
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span>Short Description</span>
                            </div>
                            <div class="cards-colunm-right" *ngIf="!isSaved">
                                <input type="text" id="shortdescription" class="form-control" formControlName="shortDescription" />
                            </div>
                            <div class="cards-colunm-right" *ngIf="isSaved">
                                {{invoiceData?.invoiceShortDesc}}
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span>Mail Merge Template</span>
                            </div>
                            <div class="cards-colunm-right" *ngIf="!isSaved">
                                <select class="form-control" formControlName="mailMergeTemplate">
                                    <option [value]="0">-Select One-</option>
                                    <option *ngFor="let mMerge of mailMergeDD" [value]="mMerge?.mailMergeTemplateID">{{mMerge?.templateName}}</option>
                                </select>
                            </div>
                            <div class="cards-colunm-right" *ngIf="isSaved">
                                {{mailmergelable}}
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span>Amount</span>
                            </div>
                            <div class="cards-colunm-right" *ngIf="!isSaved">
                                <div class="display-row">
                                    <span class="mr-1">$</span><input (keydown)="preventSpecialCharacters($event)"  type="text" id="amount" name="amount" class="form-control" formControlName="amount" />
                                </div>

                                <a class="contact-cursor-pointer ml-1" *ngIf="isNewAdd" (click)="newRefresh()">New Items</a>
                                <a class="contact-cursor-pointer ml-1" *ngIf="isAddItem" (click)="AddRefresh()">Add Items</a>
                                <a class="contact-cursor-pointer ml-1" *ngIf="isClear" (click)="clearItem()">Clear Items</a>
                            </div>
                            <div class="cards-colunm-right" *ngIf="isSaved">
                                ${{invoiceData?.amount}}  <a class="contact-cursor-pointer ml-1" *ngIf="isAddItem" (click)="AddRefresh()"> Add Items</a>
                            </div>
                        </div>
                        <div class="cards-body-section" *ngIf="invoiceItemDiv" formGroupName="invoiceItemForm">
                            <div class="cards-colunm-left">
                                <div>
                                    <span class="d-block">Items</span>
                                    <a class="contact-cursor-pointer" title="View Lead" (click)="add()">Add</a>
                                </div>
                            </div>
                            <div class="cards-colunm-right">
                                <div class="full-width-container">
                                    <div class="tag-cloud-listing" *ngIf="addInvoiceItem">
                                        <div>
                                            <span>Quantity</span>
                                            <input type="text" id="quantity" class="form-control" formControlName="quantity" />
                                        </div>
                                        <div class="margin-left10">
                                            <span>Item</span>
                                            <input type="text" id="item" class="form-control" formControlName="item" />
                                        </div>
                                        <div class="margin-left10">
                                            <span>Description</span>
                                            <textarea id="description" class="form-control" formControlName="description"></textarea>
                                        </div>
                                        <div class="margin-left10">
                                            <span>Unit Price</span>
                                            <input type="text" id="unitPrice" class="form-control" formControlName="unitPrice" />
                                        </div>
                                        <div class="margin-left10">
                                            <span>Unit Discount</span>
                                            <input type="text" id="unitDiscount" class="form-control" formControlName="unitDiscount" />
                                        </div>
                                    </div>
                                    <div class="cards-colunm-left" *ngIf="addInvoiceItem">
                                        <button class="btn btn-primary" [disabled]="disableBtn" (click)="saveInvoiceItem()" id="Save">
                                            Save
                                            <app-loader></app-loader>
                                        </button>
                                        <button class="btn btn-cancel" (click)="hideInvoiceItem()" id="Cancel">Cancel</button>
                                    </div>
                                    <div class="cards-colunm-left" *ngIf="gridInvoiceItem">
                                        <div class="button-wrapper">
                                            <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
                                        </div>
                                    </div>
                                    <div class="global-body-section" *ngIf="gridInvoiceItem">
                                        <kendo-grid [kendoGridBinding]="invoiceItemData" *ngIf="_gridCnfgService.reloadGridGeneric"
                                                    [pageSize]="_gridCnfgService.pageSize"
                                                    [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                                    [sortable]="{mode: 'multiple'}"
                                                    [scrollable]="'scrollable'"
                                                    [sort]="_gridCnfgService.sort"
                                                    [columnMenu]="{ filter: true }"
                                                    [resizable]="true"
                                                    [reorderable]="true"
                                                    (columnReorder)="_gridCnfgService.columnsOrderChanged('lead_invoice_grid', $event)"
                                                    (sortChange)="_gridCnfgService.sortChange('lead_invoice_grid', $event)"
                                                    (pageChange)="_gridCnfgService.pageChange('lead_invoice_grid', $event)"
                                                    (columnResize)="_gridCnfgService.columnResize(16,'lead_invoice_grid', $event)"
                                                    (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'lead_invoice_grid',grid)"
                                                    (edit)="editHandler($event)"
                                                    (cancel)="cancelHandler($event)"
                                                    (save)="saveHandler($event)"
                                                    (remove)="removeHandler($event)">

                                            <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                               [field]="column.field"
                                                               [title]="column.title | titlecase"
                                                               [width]="column?.width | stringToNumber"
                                                               [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                               [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                               [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                               [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                                               [includeInChooser]="column.field.includes('$') ? false : true">
                                                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                                    <div class="customer-name" *ngIf="column.field == '$' && column.title == ''">{{ rowIndex+1 }}</div>
                                                    <div class="customer-name" *ngIf="column.field == 'quantity'">
                                                        {{ dataItem[column.field] }}
                                                    </div>
                                                    <div class="customer-name" *ngIf="column.field == 'partNumber'">
                                                        {{ dataItem[column.field] }}
                                                    </div>
                                                    <div class="customer-name" *ngIf="column.field == 'itemDesc'">
                                                        {{ dataItem[column.field] }}
                                                    </div>
                                                    <div class="customer-name" *ngIf="column.field == 'unitPrice'">
                                                        {{ dataItem[column.field] }}
                                                    </div>
                                                    <div class="customer-name" *ngIf="column.field == 'unitDiscount'">
                                                        {{ dataItem[column.field] }}
                                                    </div>
                                                    <div class="customer-name" *ngIf="column.field == 'totalPrice'">
                                                        {{ dataItem[column.field] }}
                                                    </div>
                                                </ng-template>
                                            </kendo-grid-column>
                                            <kendo-grid-command-column title="Action" width="100">
                                                <ng-template kendoGridCellTemplate let-isNew="isNew">
                                                    <button kendoGridEditCommand [primary]="true" title="Edit">
                                                        <kendo-icon name="edit"></kendo-icon>
                                                    </button>
                                                    <button kendoGridRemoveCommand [primary]="true" data-toggle="modal" data-target="#confirmModal" title="Remove">
                                                        <kendo-icon name="delete"></kendo-icon>
                                                    </button>
                                                    <button kendoGridSaveCommand [primary]="true" *ngIf="isNew" title="Add">
                                                        <kendo-icon name="check"></kendo-icon>
                                                    </button>
                                                    <button kendoGridSaveCommand [primary]="true" *ngIf="!isNew" title="Update">
                                                        <kendo-icon name="check"></kendo-icon>
                                                    </button>
                                                    <button kendoGridCancelCommand [primary]="true">
                                                        <kendo-icon name="close"></kendo-icon>
                                                    </button>
                                                </ng-template>
                                            </kendo-grid-command-column>
                                        </kendo-grid>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span>Lead</span>
                            </div>
                            <div class="cards-colunm-right">
                                <a class="contact-cursor-pointer" title="View Lead" (click)="gotoLink('lead',null)">{{lead?.leadDesc}}</a>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span>Contact</span>
                            </div>
                            <div class="cards-colunm-right">
                                <div>
                                    <a class="contact-cursor-pointer" title="View Contact" (click)="gotoLink('contact',contact)">{{contact?.lastName}}&nbsp;{{contact?.firstName}}<br /></a>
                                    <span *ngIf="contact?.companyName !=''">{{contact?.companyName}}  <br /></span>
                                    <span *ngIf="contact?.add1 !=''">{{contact?.add1}}  <br /></span>
                                    <span *ngIf="contact?.add2 !=''">{{contact?.add2}}  <br /></span>
                                    <span *ngIf="contact?.phone !=''">B: {{contact?.phone | phoneFormat}} <br /></span>
                                    <span *ngIf="contact?.email !=''">E: {{contact?.email}} <br /></span>
                                    <span *ngIf="contact?.email !=''">E: {{contact?.email}} <br /></span>
                                </div>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span>User</span>
                            </div>
                            <div class="cards-colunm-right" *ngIf="!isSaved">
                                <select class="form-control" formControlName="user">
                                    <option *ngFor="let user of userList" [value]="user?.value">{{user?.text}}</option>
                                </select>
                            </div>
                            <div class="cards-colunm-right" *ngIf="isSaved">
                                {{userNameDD}}
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span>Status</span>
                            </div>
                            <div class="cards-colunm-right" *ngIf="!isSaved">
                                <select class="form-control" formControlName="status">
                                    <option [value]="1">Pending</option>
                                    <option [value]="2">Paid</option>
                                    <option [value]="3">Void</option>
                                </select>
                            </div>
                            <div class="cards-colunm-right" *ngIf="isSaved">
                                {{getStatus(statusDD)}}
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <div class="align-right">
                                    <span>Details</span>
                                    <span class="text-success">(limit 2000 characters)</span>
                                </div>
                            </div>
                            <div class="cards-colunm-right" *ngIf="!isSaved">
                                <input type="text" class="form-control" formControlName="longDescription" />
                            </div>
                            <div class="cards-colunm-right" *ngIf="isSaved">
                                {{invoiceData?.invoiceLongDesc}}
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left"></div>
                            <div class="cards-colunm-right">
                                <div class="bottom-button-bar">
                                    <button class="btn btn-primary" *ngIf="isSavedButton" [disabled]="disableBtn" (click)="invoiceSave()" id="create">
                                        Save
                                        <app-loader></app-loader>
                                    </button>
                                    <button class="btn btn-primary" *ngIf="isUpdateButton" [disabled]="disableBtn" (click)="saveUpdate()" id="Save">Update <app-loader></app-loader></button>
                                    <button class="btn btn-primary" *ngIf="isEditButton" (click)="invoiceEdit()" id="Edit">Edit</button>
                                    <button class="btn btn-cancel" (click)="cancelLeadInvoice()" id="Cancel">Cancel</button>
                                    <button class="btn btn-danger" type="button" *ngIf="isDeleteButton" data-toggle="modal" data-target="#leadInvoiceDeleteModal"> Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="sendEmailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="true">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg mt-5 mb-5" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <!--<div class="modal-header user-body-header">
                  <h5 class="modal-title white-contact" id="exampleModalLabel"><i class="fas fa-envelope-open-text white-contact"></i> Outbound Email</h5>
                  <button type="button" class="close" aria-label="Close" (click)="hideSendMail();" #closeButton>
                    <span class="white-contact" aria-hidden="true">&times;</span>
                  </button>
                </div>-->
                <div class="modal-body p-0">
                    <app-contact-email *ngIf="sendMailInfo?.isShow" [loggedUser]="user" [invoiceId]="invoiceData?.invoiceID" [invoice]="invoiceData" [isLeadInvoice]="sendMailInfo?.isLeadInvoice" [isFromLead]="true" (isCloseButton)="emailCloseButton($event)" [contactId]="sendMailInfo?.contactId"></app-contact-email>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="confirmModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
            </div>
            <div class="modal-body modal-common-body">
                <h5>Are you sure you want to delete? </h5>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="deleteInvoiceItem()">Confirm</button>
                <button type="button" class="btn btn-cancel" (click)="cancel()">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="leadInvoiceDeleteModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
            </div>
            <div class="modal-body modal-common-body">
                <h5>Caution: This invoice will be permanently deleted.</h5>
                <h5>Are you sure you want to delete this invoice?</h5>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="deleteLeadInvoice()" data-dismiss="modal" class="btn btn-primary">
                    <ng-template [ngIf]="buttonTypeOperation!=0">Confirm</ng-template>
                    <ng-template [ngIf]="buttonTypeOperation===0">Deleting &nbsp; <span> &nbsp;<i wrapper> </i></span></ng-template>
                </button>
                <button type="button" class="btn btn-cancel" (click)="cancelDelete()"> Close</button>
            </div>
        </div>
    </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>

