<div class="custom-security-link">
    <a [routerLink]="['/automation-process']">Automation Processes</a>
</div>
<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel">
                    <div class="flex-align-panel">
                        <img src="../../../../assets/automationprocesstitle.svg" class="mr-1" />
                        Add {{campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Contact ? 'Contacts' : 'Leads'}} to Process Wizard
                        <span class="text-success">Automation Process: {{campaignTemplateData?.campaignTemplateName}} Campaign Owner: {{campaignTemplateData?.userLastFirst}}</span>
                    </div>
                </div>
                <div class="header-button-panel" *ngIf="step ==2">
                    <div class="button-wrapper">
                        <div class="custom-search">
                            <div class="display-row">
                                <contact-common-search *ngIf="campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Contact"></contact-common-search>
                                <contact-common-search *ngIf="campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Lead" [execMapDuplicate]="false" [isLead]="true"></contact-common-search>
                            </div>
                        </div>
                        <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
                    </div>
                </div>
            </div>
            <div class="global-body-section">
                <div class="step">
                    <div *ngIf="step ==1">
                        <div class="active vertical-center">
                            <div class="admin-row-flex flex-width3">
                                <img src="../../../../assets/trans1x1.gif" class="steps1_3" border="0">
                                <div class="admin-row-colunm">
                                    <h4 class="font-weight-bold">Select User </h4>
                                    <h6 class="font-weight-bold">Select the User that will be the Campaign Owner for this Automation Process.</h6>
                                </div>
                            </div>
                            <div class="margin-all-area">
                                <button class="btn btn-primary" type="button" (click)="goToNext()">Go To Next Step</button>
                            </div>
                        </div>
                        <div class="email-total-records">
                            <span>Please select a user.</span>
                        </div>
                    </div>
                    <div *ngIf="step ==2">
                        <div class="active vertical-center">
                            <div class="admin-row-flex flex-width3">
                                <img src="../../../../assets/trans1x1.gif" class="steps1_3" border="0">
                                <div class="admin-row-colunm">
                                    <h4 class="font-weight-bold">Select {{campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Contact ? 'Contacts' : 'Leads'}} </h4>
                                    <h6 class="font-weight-bold" *ngIf="campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Lead">Use Quick Search to select leads to add to the automation process by searching, selecting, and moving leads to the box on the right.  Then click the Go To Next Step button on the right.</h6>
                                    <h6 class="font-weight-bold" *ngIf="campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Contact">Use Quick Search to select contacts to add to the automation process by searching, selecting, and moving contacts to the box on the right.  Then click the Go To Next Step button on the right.</h6>
                                </div>
                            </div>
                            <div class="margin-all-area">
                                <button class="btn btn-primary" type="button" (click)="step = 3">Go To Next Step</button>
                            </div>
                        </div>
                        <div *ngIf="checkLength()" class="email-total-records">
                            <span>Total Records found: {{campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Contact ? contactList?.length : leadList?.length}}</span>
                            <div class="top-common-btn_txtmsg">
                                <button class="btn btn-primary" type="button" (click)="selectAllContacts();">Select All</button>
                                <button class="btn btn-primary" type="button" (click)="moveRight();">Move Right</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="step > 2">
                        <div class="custom-action-title d-block" *ngIf="step == 4">
                            <span class="text-success">{{selectedOwnerIds}} {{campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Contact ? 'contact(s)' : 'lead(s)'}} have been added to the campaign and activated successfully.</span>
                            <a [routerLink]="['/workflow-campaign']" [queryParams]="{cid: campaignId}" class="webkit-any-link">Click here to view the campaign</a>
                        </div>
                        <div class="active vertical-center">
                            <div class="admin-row-flex flex-width3">
                                <img src="../../../../assets/trans1x1.gif" class="stepsdone_3" border="0">
                                <div class="admin-row-colunm">
                                    <h4 class="font-weight-bold">Confirm Add To Campaign</h4>
                                    <h6 class="font-weight-bold">Please verify your campaign settings and then click the Confirm and Finish button to add these {{campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Contact ? 'contacts' : 'leads'}} to the campaign.</h6>
                                </div>
                            </div>
                            <div class="margin-all-area" *ngIf="step==3">
                                <button class="btn btn-primary" type="button" (click)="confirmProcess()">Confirm and Finish</button>
                            </div>
                        </div>
                        <div *ngIf="checkLength()" class="email-total-records">
                            <span *ngIf="step ==3">Total {{campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Contact ? 'contact(s)' : 'lead(s)'}} to add to campaign: {{campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Contact ? movedContactList?.length : movedLeadList?.length}}</span>
                            <span *ngIf="step ==4">Total {{campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Contact ? 'contact(s)' : 'lead(s)'}} to add to campaign: {{selectedOwnerIds}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="global-body-section" *ngIf="step ==1">
                <div class="wraper-body-panel">
                    <div class="wraper-body-left">
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span class="control-label">Automation Process</span>
                            </div>
                            <div class="cards-colunm-right">
                                <span>{{campaignTemplateData?.campaignTemplateName}}</span>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span class="control-label spantext">Campaign Owner</span>
                            </div>
                            <div class="cards-colunm-right">
                                <select class="form-control" [(ngModel)]="selectedUserId">
                                    <option value="0"> -Select- </option>
                                    <option *ngFor="let item of userList" [value]="item?.id">{{item?.text}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span class="control-label spantext">Events</span>
                            </div>
                            <div class="cards-colunm-right">
                                <campaign-event-list *ngIf="user && campaignTemplateData?.campaignTemplateID >0" [campaignTemplateId]="campaignTemplateData?.campaignTemplateID"></campaign-event-list>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="global-body-section" *ngIf="step ==2">
                <div class="flex-box-view" *ngIf="campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Contact">
                    <div class="flex-box-left">
                        <div class="global-body-section" *ngIf="refreshGrid">
                            <kendo-grid #grid id="gridId" class="mailing-common-grid" *ngIf="_gridCnfgService?.reloadGridGeneric"
                                        [kendoGridBinding]="contactList"
                                        [sortable]="{mode: 'multiple'}"
                                        [selectable]="{ checkboxOnly: true ,mode: 'multiple'}"
                                        kendoGridSelectBy="contactID"
                                        [(selectedKeys)]="mySelection"
                                        [pageSize]="_gridCnfgService.pageSize"
                                        [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                        [scrollable]="'scrollable'"
                                        [sort]="_gridCnfgService.sort"
                                        [columnMenu]="{ filter: true }"
                                        [resizable]="true"
                                        [reorderable]="true"
                                        (columnReorder)="_gridCnfgService.columnsOrderChanged('contact_process_grid', $event)"
                                        (sortChange)="_gridCnfgService.sortChange('contact_process_grid', $event)"
                                        (pageChange)="_gridCnfgService.pageChange('contact_process_grid', $event)"
                                        (columnResize)="_gridCnfgService.columnResize(9,'contact_process_grid', $event)"
                                        (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'contact_process_grid',grid)">
                                <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                   [field]="column.field"
                                                   [title]="column.title | titlecase"
                                                   [width]="(column.field == '$' ? '40' : column.width) | stringToNumber"
                                                   [filterable]="true"
                                                   [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                   [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                   [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                   [includeInChooser]="column.field=='$' ? false : true">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="customer-name" *ngIf="column.title == ' '">{{ rowIndex+1 }}</div>
                                        <div class="customer-name" *ngIf="column.title == '  '"><a class="contact-cursor-pointer" (click)="gotoLink('address-card', dataItem);" title="View Contact"><i class="icon_contact"><img src="../../../assets/icon_contact.svg" /></i></a></div>
                                        <div class="customer-name" *ngIf="column.field == 'email'"><a class="contact-cursor-pointer" (click)="gotoLink('email', dataItem);" title="send email to {{dataItem.email}}" *ngIf="dataItem.email"><i class="email_new"><img src="../../../assets/email_new.svg" /></i></a></div>
                                        <div class="customer-name" *ngIf="column.field == 'phone'"><span [innerHTML]="dataItem[column.field] ? dataItem[column.field] : '--'"></span></div>
                                        <div class="customer-name" [ngClass]="[column.field=='name' ? 'text-left' : '',  column.field=='companyName' ? 'text-left' : '']">
                                            <a class="webkit-any-link" (click)="gotoLink(column.field, dataItem);" [style.color]="column.field == 'userName'? '#1c0dbf':''" *ngIf="column.field == 'name' || column.field == 'userName'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</a>
                                            <span *ngIf="column.field=='companyName'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</span>
                                            <span *ngIf="column.field == 'dtModifiedDisplay' || column.field == 'dtCreatedDisplay'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] | date: dateFormat }}</span>
                                        </div>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-checkbox-column [width]="40"
                                                            [headerClass]="{ 'text-center': true }"
                                                            [class]="{ 'text-center': true }"
                                                            [resizable]="false"
                                                            [columnMenu]="false"
                                                            [showSelectAll]="true">
                                    <ng-template kendoGridHeaderTemplate>
                                        <input type="checkbox" #selectAll title="Check All"
                                               kendoCheckBox
                                               id="selectAllCheckboxId"
                                               kendoGridSelectAllCheckbox />
                                        <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                                    </ng-template>
                                </kendo-grid-checkbox-column>
                            </kendo-grid>
                        </div>
                    </div>
                    <div class="flex-box-right">
                        <div class="wraper-main-section">
                            <div class="global-card-section">
                                <div class="global-header-section">
                                    <div class="svg-icon-panel"><span class="text-white">Selected: {{movedContactList.length}}</span></div>
                                    <div class="header-button-panel">
                                        <div class="button-wrapper" *ngIf="movedContactList.length > 0">
                                            <button type="button" class="icon-btn" (click)="clearMovedList();">
                                                <i class="fa fa-arrow-left" aria-hidden="true" title="Clear List"></i>
                                                <span class="btn-text">Back</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="global-body-section">
                                    <div *ngIf="movedContactList.length > 0">
                                        <div class="common-grid-section " *ngFor="let item of movedContactList; let idx=index;">
                                            <div class="common-grid-row">
                                                <div class="common-grid-colunm">
                                                    <div class="">{{item?.name}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-box-view" *ngIf="campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Lead">
                    <div class="flex-box-left">
                        <div class="global-body-section" *ngIf="refreshGrid">
                            <kendo-grid class="lead-search-grid" #grid id="gridId" *ngIf="_gridCnfgService?.reloadGridGeneric"
                                        [kendoGridBinding]="leadList"
                                        [sortable]="{mode: 'multiple'}"
                                        [scrollable]="'scrollable'"
                                        [sort]="_gridCnfgService.sort"
                                        [columnMenu]="{ filter: true }"
                                        [selectable]="{ checkboxOnly: true ,mode: 'multiple'}"
                                        kendoGridSelectBy="leadID"
                                        [(selectedKeys)]="mySelection"
                                        [resizable]="true"
                                        [pageSize]="_gridCnfgService.pageSize"
                                        [reorderable]="true"
                                        [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                        (columnReorder)="_gridCnfgService.columnsOrderChanged('lead_process_grid', $event)"
                                        (sortChange)="_gridCnfgService.sortChange('lead_process_grid', $event)"
                                        (pageChange)="_gridCnfgService.pageChange('lead_process_grid', $event)"
                                        (columnResize)="_gridCnfgService.columnResize(12,'lead_process_grid', $event)"
                                        (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'lead_process_grid',grid)">
                                <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                   [field]="column.field"
                                                   [title]="column.title | titlecase"
                                                   [width]="column.width | stringToNumber"
                                                   [filterable]="true"
                                                   [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                   [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                   [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                   [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                                   [includeInChooser]="column.field=='$' ? false : true"
                                                   [editable]="column.field == '$'?false: true">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                                        <div class="customer-name" *ngIf="column.field == '$$'">  <a class="contact-cursor-pointer" title="View Lead" (click)="gotoLink('lead',dataItem);"><img src="../../../../assets/leadstitle.svg" class="mr-1" /> </a></div>
                                        <div class="customer-name" *ngIf="column.field == 'leadDesc'">  <a class="webkit-any-link" (click)="gotoLink('lead',dataItem);"> {{ dataItem[column.field] ? dataItem[column.field] : '--' }}</a></div>
                                        <div class="customer-name" *ngIf="column.field == 'lastFirst'"><a class="webkit-any-link" (click)="gotoLink('address-card', dataItem);">{{ dataItem[column.field] ? dataItem[column.field] : '--' }}</a></div>
                                        <div class="customer-name" *ngIf="column.field == 'companyName'">{{ dataItem[column.field] ? dataItem[column.field] : ' ' }}</div>
                                        <div class="customer-name" *ngIf="column.field == 'ufirstName'"><a class="webkit-any-link" (click)="gotoLink('userName', dataItem);"> {{ dataItem[column.field] ? dataItem[column.field] : '--' }}</a></div>
                                        <div class="customer-name" *ngIf="column.field == 'dtStart'" [ngClass]="dataItem[column.field]? '' : 'text-success'">{{dataItem[column.field]  ? (dataItem[column.field] | date: dateFormat) : 'NA'}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'dtEnd'" [ngClass]="dataItem[column.field]? '' : 'text-success'">{{dataItem[column.field] ? (dataItem[column.field] | date: dateFormat) : 'NA'}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'revenue'">{{dataItem[column.field] ? '$'+dataItem[column.field] : '$0'}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'winProbability'">{{dataItem[column.field]}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'leadStatusCode'">{{dataItem[column.field] != 0 ? dataItem.leadStatus : 'Not Set'}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'dtModified'">{{dataItem[column.field] != '0001-01-01T00:00:00' ? (dataItem[column.field] | date: dateFormat) : '--'}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{dataItem[column.field] != '0001-01-01T00:00:00' ? (dataItem[column.field] | date: dateFormat) : '--'}}</div>
                                    </ng-template>

                                </kendo-grid-column>
                                <kendo-grid-checkbox-column [width]="40"
                                                            [headerClass]="{ 'text-center': true }"
                                                            [class]="{ 'text-center': true }"
                                                            [resizable]="false"
                                                            [columnMenu]="false"
                                                            [showSelectAll]="true">
                                    <ng-template kendoGridHeaderTemplate>
                                        <input type="checkbox" #selectAll title="Check All"
                                               kendoCheckBox
                                               id="selectAllCheckboxId"
                                               kendoGridSelectAllCheckbox
                                               (selectAllChange)="onSelectAllChange($event)" />
                                        <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                                    </ng-template>
                                </kendo-grid-checkbox-column>
                            </kendo-grid>
                        </div>
                    </div>
                    <div class="flex-box-right">
                        <div class="wraper-main-section">
                            <div class="global-card-section">
                                <div class="global-header-section">
                                    <div class="svg-icon-panel"><span class="text-white">Selected: {{movedLeadList.length}}</span></div>
                                    <div class="header-button-panel">
                                        <div class="button-wrapper" *ngIf="movedLeadList.length > 0">
                                            <button type="button" class="icon-btn" (click)="clearMovedList();">
                                                <i class="fa fa-arrow-left" aria-hidden="true" title="Clear List"></i>
                                                <span class="btn-text">Back</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="global-body-section">
                                    <div *ngIf="movedLeadList.length > 0">
                                        <div class="common-grid-section " *ngFor="let item of movedLeadList; let idx=index;">
                                            <div class="common-grid-row">
                                                <div class="common-grid-colunm">
                                                    <div class="">{{movedLeadList[idx]?.leadDesc}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="global-body-section" *ngIf="step > 2">
                <div class="wraper-body-panel">
                    <div class="wraper-body-left">
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span class="control-label">Automation Process</span>
                            </div>
                            <div class="cards-colunm-right">
                                <span>{{campaignTemplateData?.campaignTemplateName}}</span>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span class="control-label spantext">User</span>
                            </div>
                            <div class="cards-colunm-right">
                                <span>{{userName}}</span>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span class="control-label spantext">Number of New {{campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Contact ? 'contacts' : 'leads'}}</span>
                            </div>
                            <div class="cards-colunm-right">
                                <span *ngIf="campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Contact">{{movedContactList?.length}}</span>
                                <span *ngIf="campaignTemplateData?.ownerType == eCampaignTemplateOwnerType?.Lead">{{movedLeadList?.length}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
        <div class="lds-ripple"><div></div><div></div></div>
    </div>
</div>
