<div class="custom-security-link" *ngIf="linkId > 0">
    <a class="custom-security-link" (click)="newLinkGroup()">New Link</a>
    <a [routerLink]="['/link-history', linkId]">View History</a>
    <a [routerLink]="['/link']">View My Links</a>
</div>

<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../../assets/linktitle.svg" class="mr-1" />Link Information</div>
                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <button type="button" *ngIf="linkId!=0" matTooltip="Send Email to Link Group" class="icon-btn" (click)="isShowLinkEmailModal = true" data-toggle="modal" data-target="#quickLinkEmailModal">
                            <img src="../../../../../assets/emailbtn.svg" />
                            <span class="btn-text">Email</span>
                        </button>
                        <button [hidden]="roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator" type="button" matTooltip="Edit" class="icon-btn" (click)="editLink();" *ngIf="showViewMode">
                            <i class="fa fa-pencil-alt" aria-hidden="true"></i>
                            <span class="btn-text">Edit</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="global-body-section">
                <div class="message-info" *ngIf=" linkId!=0 && !showViewMode">Please edit Link information and click Save.</div>
                <div class="message-info" *ngIf=" linkId==0 && !showViewMode">Please enter the new Link information and click Save.</div>
                <div class="wraper-body-panel">
                    <div class="wraper-body-left">
                        <form [formGroup]="linkGroupForm" (ngSubmit)="linkGroupFormSubmit()">
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span class="control-label spantext">Link Name</span>
                                </div>
                                <div class="cards-colunm-right" *ngIf="showViewMode == false">
                                    <input type="text" class="form-control" id="linkName" formControlName="linkName" />
                                    <div class="login-error" *ngIf="linkGroupFrm.linkName.errors && ( linkGroupFrm.linkName.touched ||  linkGroupFrm.linkName.dirty)">
                                        <div *ngIf="linkGroupFrm.linkName.errors.required">Please enter Link Name.</div>
                                    </div>
                                </div>
                                <div class="cards-colunm-right" *ngIf="showViewMode == true">
                                    <span>{{linkData?.linkName}}</span>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span class="control-label spantext">Shareable</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <span *ngIf="showViewMode == true" [ngClass]="linkData?.isShareable ? '' : 'text-danger'">{{linkData?.isShareable == true ? 'Link is shared' : 'Link is not shared'}}</span>
                                    <input *ngIf="showViewMode == false" type="checkbox" class="checkbox" id="isShareable" formControlName="isShareable" />&nbsp;<span *ngIf="showViewMode == false">Share this link</span>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span class="control-label spantext">Created By</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <span *ngIf="linkId != 0"><a class="webkit-any-link" (click)="gotoLink('userName', user);" title="{{userTooltip}}">{{user?.firstName}}&nbsp;{{user?.lastName}}</a></span>
                                    <span *ngIf="linkId == 0"> {{user?.firstName}}&nbsp;{{user?.lastName}}</span>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <span class="control-label spantext">Date Created</span>
                                </div>
                                <div class="cards-colunm-right">
                                    {{linkId > 0 ? (linkData?.dtCreated |  date: dateFormat) : (dtCreated |  date: dateFormat)}}
                                </div>
                            </div>
                            <div class="cards-body-section" *ngIf="linkId > 0 && showViewMode == true">
                                <div class="cards-colunm-left">
                                    <span class="control-label spantext">Quick Add</span>
                                </div>
                                <div class="cards-colunm-right">
                                    <kendo-autocomplete [data]="contactSearchList" valueField="searchText" (valueChange)="getContactId($event)" placeholder="Search" (keyup)="getSearchData($event.target.value)" #quickAdd></kendo-autocomplete>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left"></div>
                                <div class="cards-colunm-right">
                                    <div class="bottom-button-bar" *ngIf="showViewMode == false">
                                        <button class="btn btn-primary" [hidden]="linkId > 0 ? (roleFeaturePermissions?.create == false && user?.userRole <= eUserRole.Administrator) : (roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator)" [disabled]="isLinkGroupSubmit" type="submit">{{linkId > 0 ? 'Update' : 'Save' }}</button>
                                        <button type="button" (click)="onCancel()" class="btn btn-cancel"><i class="fa fa-times mr-2"></i>Cancel</button>
                                        <button [hidden]="linkId == 0 || (roleFeaturePermissions?.delete == false && user?.userRole <= eUserRole.Administrator)" type="button" class="btn btn-danger" data-toggle="modal" data-target="#linkGroupDeleteModal"><i class="fa fa-trash mr-2"></i>Delete</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="margin-all-area" *ngIf="linkId > 0">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../../assets/linktitle.svg" class="mr-1" />Linked Contacts</div>
                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <div class="custom-search">
                            <div class="display-row">
                                <contact-common-search *ngIf="isShowSearch" [execMapDuplicate]="false"></contact-common-search>
                                <button type="button" [matTooltip]="isShowSearch ? 'Cancel Add Contacts' : 'Add Contacts'" class="icon-btn" (click)="isShowSearch = !isShowSearch ; isShowSearch ? (linkContactList = []) : getLinkContactList(linkId); ">
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                    <span class="btn-text">{{isShowSearch ? 'Cancel Add Contacts' : 'Add Contacts'}}</span>
                                </button>
                                <button type="button" *ngIf="!isShowSearch" matTooltip="Delete selected linked contacts" class="icon-btn" (click)="deleteCheckedMsgSubmit()">
                                    <img src="../../../../assets/trash-btn.svg" />
                                    <span class="btn-text">Delete selected linked contacts</span>
                                </button>
                                <button type="button" matTooltip="Download" *ngIf="!isShowSearch && linkContactList?.length > 0" class="icon-btn" (click)="createExportRequest();">
                                    <img src="../../../../assets/downbtn.svg" />
                                    <span class="btn-text">Download</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="global-body-section">
                <div class="global-padding10" *ngIf="isShowSearch && linkContactList?.length > 0">
                    <div class="text-right full-width-container">
                        <button class="btn btn-primary" type="button" (click)="addCheckedContacts()">Add Checked</button>
                    </div>
                </div>
                <!--<contact-common-search *ngIf="isShowSearch" [execMapDuplicate]="false"></contact-common-search>-->
                <contact-exports *ngIf="showExports && !isShowSearch" [user]="user" [selectedUserId]="user?.cLPUserID" [isContactReport]="true" [eStat]="eStat" [eType]="eType"></contact-exports>
                <div>
                    <div class="message-info">
                        <span>Total records found: {{linkContactList?.length}}</span>
                    </div>
                    <kendo-grid class="link-group-grid" #grid id="gridId"
                                [kendoGridBinding]="linkContactList"
                                kendoGridSelectBy="contactID"
                                [selectable]="{ checkboxOnly: true ,mode: 'multiple' }"
                                [(selectedKeys)]="selectedContactIds"
                                (edit)="editHandler($event)"
                                (cancel)="cancelHandler($event)"
                                (save)="saveHandler($event)"
                                [pageSize]="10"
                                [pageable]="{buttonCount:10, pageSizes:[10,50,100,200]}">
                        <kendo-grid-column *ngFor="let column of columns"
                                           [field]="column.field"
                                           [title]="column.title | titlecase"
                                           [width]="column.width | stringToNumber"
                                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="customer-name" *ngIf="column.field == '$'">{{ rowIndex+1 }}</div>
                                <div class="customer-name" *ngIf="column.field == '$$'"><a class="contact-cursor-pointer" (click)="gotoLink('name', dataItem);" title="View Contact"><i class="icon_contact"><img src="../../../../../assets/icon_contact.svg" /></i></a></div>
                                <div class="customer-name" *ngIf="column.field == 'name'"><a class="webkit-any-link" (click)="gotoLink('name',dataItem);"> {{ dataItem[column.field] ? dataItem[column.field] : '--' }}</a></div>
                                <div class="customer-name" *ngIf="column.field == 'relationship'"> {{ dataItem[column.field] }}</div>
                                <div class="customer-name" *ngIf="column.field == 'email'"><a class="contact-cursor-pointer" (click)="gotoLink('email', dataItem);" title="send email to {{dataItem.email}}" *ngIf="dataItem.email"><i class="email_new"><img src="../../../assets/email_new.svg" /></i></a></div>
                                <div class="customer-name" *ngIf="column.field == 'title'">{{ dataItem[column.field] }}</div>
                                <div class="customer-name" *ngIf="column.field == 'companyName'"><a class="webkit-any-link" (click)="gotoLink('companyName',dataItem);"> {{ dataItem[column.field] ? dataItem[column.field] : '--' }}</a></div>
                                <div class="customer-name" *ngIf="column.field == 'phone' && isShowSearch" [innerHTML]="dataItem[column.field]"></div>
                                <div class="customer-name" *ngIf="column.field == 'phone' && !isShowSearch">{{dataItem[column.field] | phoneFormat}}</div>
                                <div class="customer-name" *ngIf="column.field == 'userName'">  <a class="webkit-any-link" (click)="gotoLink('userName',dataItem);"> {{ dataItem[column.field] ? dataItem[column.field] : '--' }}</a></div>
                                <div class="customer-name" *ngIf="column.field == 'dtModified'"> {{dataItem[column.field] |  date: dateFormat}}</div>
                                <div class="customer-name" *ngIf="column.field == 'dtCreated'"> {{dataItem[column.field] |  date: dateFormat}}</div>
                            </ng-template>
                            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-column="column">
                                <div class="customer-name" *ngIf="column.field == 'relationship'">
                                    <input placeholder="Relationship" [(ngModel)]="dataItem[column.field]" name="column.title" />
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-command-column [width]="100" title="Action" [includeInChooser]="false" [reorderable]="false" [columnMenu]="false">
                            <ng-template kendoGridCellTemplate title="Action" width="60" min="60">
                                <div class="flex-align-panel">
                                    <button kendoGridEditCommand [primary]="true" title="Edit">
                                        <kendo-icon name="edit"></kendo-icon>
                                    </button>
                                    <button kendoGridSaveCommand [primary]="true" title="Update">
                                        <kendo-icon name="check"></kendo-icon>
                                    </button>
                                    <button kendoGridCancelCommand [primary]="true" title="Cancel">
                                        <kendo-icon name="close"></kendo-icon>
                                    </button>
                                </div>
                            </ng-template>
                        </kendo-grid-command-column>
                        <!----remove selected contacts from link ----->
                        <kendo-grid-checkbox-column *ngIf="linkContactList?.length > 0" [width]="45"
                                                    [headerClass]="{ 'text-center': true }"
                                                    [class]="{ 'text-center': true }"
                                                    [resizable]="false"
                                                    [columnMenu]="false"
                                                    showSelectAll="true">
                            <ng-template kendoGridHeaderTemplate>
                                <input type="checkbox"
                                       kendoCheckBox
                                       id="selectAllMsgCenterCheckboxId"
                                       kendoGridSelectAllCheckbox
                                       [state]="selectAllMsgCenterApptState"
                                       (selectAllChange)="onSelectAllMsgCenterChange($event)" />
                                <label class="k-checkbox-label" for="selectAllMsgCenterCheckboxId"></label>
                            </ng-template>
                        </kendo-grid-checkbox-column>
                        <!----remove selected contacts from link ----->
                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isShowLinkEmailModal" class="modal fade" id="quickLinkEmailModal" tabindex="-1" role="dialog" aria-labelledby="quickLinkEmailModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <lead-email (closeEmailComponent)="isShowLinkEmailModal = $event" [loggedUser]="user" [ownerId]="user?.cLPUserID" [ownerType]="ownerType" [linkId]="linkId" [linkName]="linkData?.linkName"></lead-email>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="isShowLinkEmailModal = false" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="modal fade" id="deleteCheckedSlidesModal" tabindex="-1" role="dialog" aria-labelledby="deleteCheckedSlidesModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>Caution! Are you sure you want to REMOVE  the selected Contacts from this Link?</h2>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="deleteCheckedContacts()" data-dismiss="modal" class="btn btn-primary">ok</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="sendEmailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <!--<div class="modal-header user-body-header">
                  <h5 class="modal-title white-contact" id="exampleModalLabel"><i class="fas fa-envelope-open-text white-contact"></i> Outbound Email</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideSendMail();">
                    <span class="white-contact" aria-hidden="true">&times;</span>
                  </button>
                </div>-->
                <div class="modal-body">
                    <app-contact-email *ngIf="sendMailInfo?.isShow" [loggedUser]="user" [contactId]="sendMailInfo?.contactId"></app-contact-email>
                </div>
                <!--<div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideSendMail();">Close</button>
                </div>-->
            </div>
        </div>

    </div>
</div>

<div class="modal fade" id="linkGroupDeleteModal" tabindex="-1" role="dialog" aria-labelledby="linkGroupDeleteModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
            </div>
            <div class="modal-body modal-common-body">
                <h5>Are you sure to delete?</h5>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="deleteLink()" data-dismiss="modal" class="btn btn-primary">Confirm</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal"> Close</button>
            </div>
        </div>
    </div>
</div>
