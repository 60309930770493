import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataBindingDirective, PageChangeEvent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { isNullOrUndefined } from 'util';
import { ApppointmentResponse, Appt, ApptExtended, ApptListResponse, ApptResponse, ShareableApptUI, UpdateAppt } from '../../../models/appt.model';
import { eApptCategory, eApptStatus, eCompanyType, eFeatures, eUserRole } from '../../../models/enum.model';
import { CLPUser, ClpUsersDdResponse, OfficeCodeResponseIEnumerable, OfficeCodes, TeamCodeResponseIEnumerable, TeamCodes, UserResponse } from '../../../models/clpuser.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { DashboardService } from '../../../services/dashboard.service';
import { NotificationService } from '../../../services/notification.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../../services/user.service';
import { CLPUserPref } from '../../../models/clpUserPref.model';
import { TxtMsgService } from '../../../services/textmsg.service';
import { TxtMsgSettings, TxtMsgSettingsResponse } from '../../../models/txtMsg.model';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AppointmentSettingService } from '../../../services/appointmentSetting.service';
import { MonthDDResponse } from '../../../models/calender.model';
import { DropDownItem, IntDropDownItem } from '../../../models/genericResponse.model';
import { OfficeSetupService } from '../../../services/officeCode.service';
import { TeamOfficeSetupService } from '../../../services/teamoffice.service';
import { process } from '@progress/kendo-data-query';
import { ContactInfo, SendMailInfo } from '../../../models/report.model';
import { ContactService } from '../../../services/contact.service';
import { Contact } from '../../../models/contact.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { CompanyObjectResponse } from '../../../models/company.model';
import { Subscription } from 'rxjs';
import { FiltersApptMain, FiltersApptMainResponse } from '../../../models/filterApptMain.model';
import { Lead } from '../../../models/lead.model';
import { GlobalService } from '../../../services/global.service';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { parsePhoneNumber } from 'libphonenumber-js';

declare var $: any;
export const imgQuickIconPersonal: string = "icon_user";
export const imgQuickIconCompany: string = "icon_company_grid";
export const imgQuickIconContact: string = "icon_contact";
export const imgQuickIconLead: string = "icon_lead";
export const imgCalStatusTransIconPending: string = "transiconstatuspending";
export const imgCalStatusTransIconCancelled: string = "transiconstatuscancelled";
export const imgCalStatusTransIconCompleted: string = "transiconstatuscompleted";
@Component({
    selector: 'overdue-appointment',
    templateUrl: './overdue-appointment.component.html',
    styleUrls: ['./overdue-appointment.component.css'],
    providers: [GridConfigurationService]
})
export class OverdueAppointmentComponent implements OnInit {

    roleFeaturePermissions: RoleFeaturePermissions;
    user: CLPUser;
    ownerId: number = 0;
    private encryptedUser: string = '';
    dateFormat: string = "MM/dd/yyyy";
    userResponse: UserResponse;
    public inChooserColoumns = [];
    showSpinner: boolean = false;
    overdueApptListInit: ApptExtended[] = [];
    public overDueApptSelection: number[] = [];
    overdueApptList: ApptExtended[] = [];
    selectedDate: Date = new Date();
    gridHeight;
    selectedId: number = -1;
    selectedCountry: any = 'US';
    columns = [
        { field: '$', title: 'Sr. No.', width: '50' },
        { field: '$$', title: ' ', width: '40' },
        { field: 'apptStartTime', title: 'Schedule Time', width: '115' },
        { field: 'subject', title: 'Subject', width: '76' },
        { field: 'location', title: 'Location', width: '86' },
        { field: 'companyName', title: 'Company', width: '86' },
        { field: 'contactName', title: 'Contact', width: '146' },
        { field: '$$$', title: ' ', width: '60' },
        { field: 'lead', title: 'Lead', width: '96' },
        { field: 'codeDisplay', title: 'Type', width: '86' },
        { field: 'userLastFirst', title: 'User', width: '86' },
        { field: 'status', title: 'Status', width: '66' }];
    reorderColumnName = 'apptStartTime,subject,location,companyName,contactName,lead,codeDisplay,userLastFirst,status';
    columnWidth = 'apptStartTime:115,subject:76,location:86,companyName:86,contactName:146,lead:96,codeDisplay:86,userLastFirst:86,status:66';
    arrColumnWidth = ['apptStartTime:115,subject:76,location:86,companyName:86,contactName:146,lead:96,codeDisplay:86,userLastFirst:86,status:66'];
    mobileColumnNames = [];

    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    overDueApptReponse: ApptResponse;
    public selectAllOverDueApptState: SelectAllCheckboxState = "unchecked";
    isCheckAllAppt: boolean = false;

    @Input() isFromCoachCorner: boolean = false;
    @Input() coachCornerAppointmentList: ApptExtended[];
    coachCornerAppointmentListInit: ApptExtended[];
    @Input() isHideGridTitle: boolean = true;
    @Input() isShowFilter: boolean = true;
    headerTitle: string = 'Overdue Appointments'
    userPrefData: CLPUserPref;
    txtMsgSettingsDataResponse: TxtMsgSettingsResponse;
    txtMsgSettingsData: TxtMsgSettings;
    isTxtMsgActive: boolean = false;
    eSkype: number = 0;

    appointmentSearchForm: FormGroup;
    currDate: Date = new Date();
    isOverdue: boolean = false;
    monthDD: DropDownItem[] = [];
    teamFilterDD: TeamCodes[];
    officeFilterDD: OfficeCodes[];
    userFilterDD: IntDropDownItem[];
    eCat: eApptCategory = eApptCategory.None;
    counter;
    isShowApptModal: boolean = false;
    shareableAppt: ShareableApptUI;
    @ViewChild('closeInputButton') closeInputButton: ElementRef;
    textMsgInfo: SendMailInfo = { contactId: 0, isShow: false };
    textCallInfo: ContactInfo = { contactId: 0, isShow: false, contact: null };

    bulkUpdateApptType: string = '';
    apptListTitle: string = 'Overdue Appointments';
    apptListTitleCompanyName: string = '';
    apptTooltip: string = '';
    apptFilters: FiltersApptMain;
    apptCategoryType: number = eApptCategory.Personal;
    routeParamsSubscription: Subscription;
    selectedLead: Lead;
    userName: string;
    apptListFilterName: string;
    proposalID: number = 0;
    currentPage: number = 1
    blnIsIH: boolean =  false;
    constructor(
        private _notifyService: NotificationService, private _route: ActivatedRoute,
        private _utilityService: UtilityService,
        private _router: Router,
        private fb: FormBuilder,
        public _localService: LocalService,
        public _gridCnfgService: GridConfigurationService,
        private datePipe: DatePipe,
        public _dashboardService: DashboardService,
        public _appointmentSettingService: AppointmentSettingService,
        private _officeCodeservice: OfficeSetupService,
        private _teamOfficeService: TeamOfficeSetupService,
        private _userService: UserService,
        private _txtMsgSettingSrvc: TxtMsgService,
        private leadSettingService: LeadSettingService,
        private _globalService: GlobalService,
        private _contactService: ContactService,
        private _sanitizer: DomSanitizer,
        private _accountSetupService: AccountSetupService) {
        if (this.isShowFilter)
            this._localService.isMenu = true;
        this.gridHeight = this._localService.getGridHeight('499px');

        if (localStorage.getItem('selectedLead') != null && localStorage.getItem('selectedLead') != undefined && localStorage.getItem('selectedLead') != "undefined") {
            this.selectedLead = JSON.parse(localStorage.getItem('selectedLead'));
        }
    }

    ngOnInit() {
        this.appointmentSearchForm = this.prepareAppointmentSearchForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.dateFormat = this.user.dateFormat;
                        this.routeParamsSubscription = this._route.paramMap.subscribe(async params => {
                            if (params.has('ownerId')) {
                                this.ownerId = +params.get('ownerId');
                                this.eCat = +params.get('cat');
                                //this.apptListTitleCompanyName = localStorage.getItem("ownerName");
                                if (this.eCat == eApptCategory.CompanyObject)
                                    this.getAddressByCompanyId();
                                if (this.eCat == eApptCategory.Contact)
                                    this.getContactData();
                                if (this.eCat == eApptCategory.Lead)
                                    this.loadLeadData();
                            }
                        })

                        this.apptListTitle = (this.isFromCoachCorner || this.ownerId > 0 || this.isShowFilter) ? 'Appointment List' : 'Overdue Appointments';
                        if (this.ownerId > 0 && this.eCat == eApptCategory.CompanyObject)
                            this.getAddressByCompanyId();
                        this.getOverDueApptGridConfiguration();
                        this.getApptFilters();
                        if (!this.isFromCoachCorner) {
                            this.getMonthDD();
                            this.onTeamDD();
                            this.onOfficeDD();
                            this.getTxtMsgSettingFormData();
                            this.getLoadUsers();
                            this.get_ClpUserPref();
                            if (this.isShowFilter || this.ownerId > 0)
                                this.getAppointmentList();
                            else
                                this.getOverDueAppointment();
                        } else {
                            this.overdueApptList = this.coachCornerAppointmentList;
                            this.overdueApptListInit = this.coachCornerAppointmentList;
                            this._gridCnfgService.iterateConfigGrid(this.overdueApptList, "overdue_appt_grid");
                            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('overdue_appt_grid');
                        }
                        this.appointmentSearchForm.controls.ddUser.patchValue(this.user?.cLPUserID)
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }



    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("overdue-appointment.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async myApptList() {
        window.location.reload();
    }


    async get_ClpUserPref() {
        await this._userService.CLPUserPref_Load(this.encryptedUser, this.user.cLPUserID)
            .then(async (result: CLPUserPref) => {
                this.userPrefData = UtilityService.clone(result);
                if (!isNullOrUndefined(this.userPrefData)) {
                    if (this.userPrefData?.isClickToCallLine)
                        this.eSkype = 3;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getTxtMsgSettingFormData() {
        await this._txtMsgSettingSrvc.txtMsgSettings_Load(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, this.user?.slurpyUserId)
            .then(async (result: TxtMsgSettingsResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.txtMsgSettingsDataResponse = UtilityService.clone(result);
                    this.txtMsgSettingsData = this.txtMsgSettingsDataResponse?.txtMsgSettings;
                    this.isTxtMsgActive = this.txtMsgSettingsData?.isShowContactIcon;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getOverDueApptGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.user = this.user;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'overdue_appt_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('overdue_appt_grid').subscribe((value) => { }));
    }

    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'overdue_appt_grid').subscribe((value) => this.getOverDueApptGridConfiguration());
    }

    viewAllAppointment() {
        this.appointmentSearchForm.controls.status.setValue(0);
        this.isOverdue = false
        this.getAppointmentList();
    }

    viewOverDueOnlyAppointment() {
        this.isOverdue = true;
        this.overdueApptList = this.overdueApptListInit.filter((item) => item?.isOverDue == true)
        //this.getAppointmentList();
    }

    prepareAppointmentSearchForm() {
        const currMonth = this.datePipe.transform(new Date(), 'yyyyMM');
        return this.fb.group({
            status: new FormControl(0),
            month: new FormControl(currMonth),
            ddUser: new FormControl(0),
            ddTeam: new FormControl(0),
            ddOffice: new FormControl(0)
        });
    }

    async getOverDueAppointment() {
        this.showSpinner = true;
        await this._dashboardService.apptGetOverDueOnlyByCLPUserID(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID)
            .then(async (result: ApptResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.overDueApptReponse = UtilityService.clone(result);
                    this.overdueApptList = this.overDueApptReponse?.apptsExtended;
                    this.overdueApptListInit = UtilityService.clone(this.overdueApptList);
                    this._gridCnfgService.iterateConfigGrid(this.overdueApptList, "overdue_appt_grid");
                    this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('overdue_appt_grid');
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async getAppointmentList() {
        this.showSpinner = true;
        await this._appointmentSettingService.getApptList(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, this.appointmentSearchForm.controls.month.value,
            this.appointmentSearchForm.controls.ddUser.value, this.appointmentSearchForm.controls.ddTeam.value, this.appointmentSearchForm.controls.ddOffice.value, this.isOverdue, this.eCat, this.appointmentSearchForm.controls.status.value, this.ownerId)
            .then(async (result: ApptListResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.overdueApptList = UtilityService.clone(result?.apptList);
                    this.overdueApptListInit = UtilityService.clone(this.overdueApptList);
                    let originalOverdueApptList = this.overdueApptList
                    let filteredList = []
                    if (this.eCat == 2 && this.isOverdue) {
                        filteredList = originalOverdueApptList.filter((appt) => appt?.contactID == this.ownerId && appt?.isOverDue)
                        this.overdueApptList = filteredList
                    }
                    else if (this.eCat == 3 && this.isOverdue) {
                        filteredList = originalOverdueApptList.filter((appt) => appt?.leadID == this.ownerId && appt?.isOverDue)
                        this.overdueApptList = filteredList
                    }
                    this._gridCnfgService.iterateConfigGrid(this.overdueApptList, "overdue_appt_grid");
                    this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('overdue_appt_grid');
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    setApptListFilterTitle(type) {
        switch (type) {
            case 'user':
                this.apptListFilterName = 'User: ' + this.userFilterDD.filter(item => item?.id == this.appointmentSearchForm.controls.ddUser.value)[0]?.text
                this.appointmentSearchForm.controls.ddTeam.patchValue(0)
                this.appointmentSearchForm.controls.ddOffice.patchValue(0)
                this.getAppointmentList()
                break;
            case 'team':
                this.apptListFilterName = 'Team: ' + this.teamFilterDD.filter(item => item?.teamCode == this.appointmentSearchForm.controls.ddTeam.value)[0]?.display
                this.appointmentSearchForm.controls.ddUser.patchValue(this.user?.cLPUserID)
                this.appointmentSearchForm.controls.ddOffice.patchValue(0)
                this.getAppointmentList()
                break;
            case 'office':
                this.apptListFilterName = 'Office: ' + this.officeFilterDD.filter(item => item?.officeCode == this.appointmentSearchForm.controls.ddOffice.value)[0]?.display
                this.appointmentSearchForm.controls.ddUser.patchValue(this.user?.cLPUserID)
                this.appointmentSearchForm.controls.ddTeam.patchValue(0)
                this.getAppointmentList()
                break;
        }
    }
    async getMonthDD() {
        await this._appointmentSettingService.getMonthDD(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: MonthDDResponse) => {
                if (!isNullOrUndefined(result))
                    this.monthDD = UtilityService.clone(result?.monthList);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async onTeamDD() {
        await this._teamOfficeService.teamCode_GetList(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: TeamCodeResponseIEnumerable) => {
                if (!isNullOrUndefined(result))
                    this.teamFilterDD = UtilityService.clone(result?.teamCodes);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }
    async onOfficeDD() {
        await this._officeCodeservice.OfficeCode_GetList(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: OfficeCodeResponseIEnumerable) => {
                if (!isNullOrUndefined(result))
                    this.officeFilterDD = UtilityService.clone(result?.officeCodes);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    bindDynamicColumnAnchor(dr): string {
        if (!isNullOrUndefined(dr)) {
            const ecat = dr.category;
            const estat = dr.status;
            const dtAppt = new Date(dr.apptStartTime);
            const dtApptEnd = new Date(dr.apptEndTime);
            const dtTZNow = new Date();
            var sbToolTip: string = '';
            const filterTypeCode = this.apptFilters;
            if ((dtAppt < dtTZNow) && estat == eApptStatus.Pending) {
                dr.isOverDue = true;
                sbToolTip += '* Overdue * \n ';
            } else
                dr.isOverDue = false;
            sbToolTip += dr.subject + '\n ';

            const customDtAppt = this.datePipe.transform(dtAppt, 'h:mm a');
            const customDtApptEnd = this.datePipe.transform(dtApptEnd, 'h:mm a');

            sbToolTip += customDtAppt + ' - ' + customDtApptEnd + '\n ';

            if (dr.location && dr.location != '')
                sbToolTip += '(' + dr.location + ')' + '\n ';


            var strQuickImage: string = '';
            var sbContactDisplay: string = '';
            var blnIsShareable: boolean;
            switch (ecat) {
                case eApptCategory.Company:
                    strQuickImage = "<IMG src='../images/trans1x1.gif' class='" + imgQuickIconCompany + "' border=0>";
                    break;
                case eApptCategory.Contact:
                    strQuickImage = "<IMG src='../images/trans1x1.gif' class='" + imgQuickIconContact + "' border=0>";
                    blnIsShareable = dr?.ContactisShareable;
                    sbContactDisplay += `<a [routerLink]="['/contact', e, ${this.user?.cLPUserID}, ${dr?.contactID}]" title='View Contact'>${dr?.contactID.toString()}</a>`

                    sbToolTip += "Contact: " + dr?.contactName + '\n';
                    if (!isNullOrUndefined(dr?.codeDisplay)) {
                        sbToolTip += "Type: " + dr?.codeDisplay;
                    }
                    else {
                        sbToolTip += "Type: " + " ";
                    }

                    //if (dr?.generalTypeCode > 0 && !isNullOrUndefined(filterTypeCode?.filterTypeCode))
                    //  sbToolTip += "Type: " + filterTypeCode?.filterTypeCode.find(x => x.apptTypeCode == dr?.generalTypeCode)?.display + '\n';
                    //else
                    //  sbToolTip += "Type: ";
                    break;
                case eApptCategory.Lead:
                    sbToolTip += "Lead: " + "ownerName" + '\n' + "Contact: " + dr.contactID + '\n';
                    if (!isNullOrUndefined(dr?.codeDisplay)) {
                        sbToolTip += "Type: " + dr?.codeDisplay;
                    }
                    else {
                        sbToolTip += "Type: " + " ";
                    }

                //if (dr?.generalTypeCode > 0 && !isNullOrUndefined(filterTypeCode?.filterTypeCode))
                //  sbToolTip += "Type: " + filterTypeCode?.filterTypeCode.find(x => x.apptTypeCode == dr?.generalTypeCode)?.display + '\n';
                //else
                //  sbToolTip += "Type: ";
                //break;
                default:
                    if (!isNullOrUndefined(dr?.codeDisplay)) {
                        sbToolTip += "Type: " + dr?.codeDisplay;
                    }
                    else {
                        sbToolTip += "Type: " + " ";
                    }
                //if (dr?.generalTypeCode > 0 && !isNullOrUndefined(filterTypeCode?.filterTypeCode))
                //  sbToolTip += "Type: " + filterTypeCode?.filterTypeCode.find(x => x.apptTypeCode == dr?.generalTypeCode)?.display + '\n';
                //else
                //  sbToolTip += "Type: ";
                //break;

            }
            if (dr?.notes && dr?.notes != '')
                sbToolTip += '\n' + dr.notes;
            return sbToolTip;
        }
    }

    async apptIsPhoneCallUpdate() {
        this.showSpinner = true;
        await this._dashboardService.apptIsPhoneCallUpdate(this.encryptedUser, false, this.overDueApptSelection)
            .then(async (result: ApptResponse) => {
                if (!isNullOrUndefined(result))
                    this.getOverDueAppointment();
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    toogleCheckAllAppt() {
        !this.isCheckAllAppt ? this.onSelectAllOverDueApptChange('checked') : this.onSelectAllOverDueApptChange('unchecked')
        this.isCheckAllAppt = !this.isCheckAllAppt;
    }

    public onSelectAllOverDueApptChange(checkedState: SelectAllCheckboxState): void {
        if (checkedState === "checked") {
            this.overDueApptSelection = this.overdueApptList.map((item) => item.apptID);
            this.selectAllOverDueApptState = "checked";
        } else {
            this.overDueApptSelection = [];
            this.selectAllOverDueApptState = "unchecked";
        }
    }

    apptPhoneCallSubmit(isConfirm: string) {
        if (this._utilityService.validateCheckbox(this.overDueApptSelection, 'appointment') && isConfirm == 'yes')
            this.apptPhoneCallUpdate();
    }

    async apptPhoneCallUpdate() {
        this.showSpinner = true;
        await this._dashboardService.apptIsPhoneCallUpdate(this.encryptedUser, true, this.overDueApptSelection)
            .then(async (result: ApptResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    if (response?.messageBool) {
                        this.overDueApptSelection = [];
                        if (this.isShowFilter || this.ownerId > 0) {
                            await this.getAppointmentList();
                            //this.onDateChange(this.selectedDate);
                        }
                        else
                            this.getOverDueAppointment();
                        this._notifyService.showSuccess('Checked appointments marked as Phone calls', 'Phone Calls Marked', 3000);
                    }
                    else
                        this._notifyService.showError('Could not mark checked appointments as Phone calls', 'Phone Calls Marked', 3000);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    apptBumpedSubmit(isConfirm: string) {
        if (this._utilityService.validateCheckbox(this.overDueApptSelection, 'appointment') && isConfirm == 'yes')
            this.apptBumped();
    }

    async apptBumped() {
        this.showSpinner = true;
        await this._dashboardService.apptBumped(this.encryptedUser, this.overDueApptSelection)
            .then(async (result: ApptResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    if (response?.messageBool) {
                        this.overDueApptSelection = [];
                        if (this.isShowFilter || this.ownerId > 0) {
                            this.getAppointmentList();
                        }
                        else {
                            this.getOverDueAppointment();
                        }
                        this._notifyService.showSuccess('Checked appointments marked as Bumped', 'Bumped Marked', 3000);

                    }
                    else
                        this._notifyService.showError('Could not mark checked appointments as Bumped', 'Bumped Marked', 3000);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }


    apptStatusBulkUpdate(type: string, isConfirm: string) {
        this.bulkUpdateApptType = type;
        var eApptSt: eApptStatus;
        switch (type) {
            case 'Pending':
                eApptSt = eApptStatus.Pending;
                break;
            case 'Cancelled':
                eApptSt = eApptStatus.Cancelled;
                break;
            case 'Completed':
                eApptSt = eApptStatus.Completed;
                break;
            case 'None':
                eApptSt = eApptStatus.None;
                break;
            case 'Bumped':
                eApptSt = eApptStatus.Bumped;
                break;
            case 'CompleteAndCampaignStop':
                eApptSt = eApptStatus.CompleteAndCampaignStop;
                break;
            case 'ToBeDeleted':
                eApptSt = eApptStatus.ToBeDeleted;
                break;
            default:
                break;
        }

        if (this._utilityService.validateCheckbox(this.overDueApptSelection, 'appointment') && isConfirm == 'yes')
            this.apptStatusBulkUpdateAPI(type, eApptSt);
    }

    async apptStatusBulkUpdateAPI(type, eApptSt) {
        this.showSpinner = true;
        await this._dashboardService.apptStatusBulkUpdate(this.encryptedUser, eApptSt, this.overDueApptSelection)
            .then(async (result: ApptResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    if (response?.messageBool) {
                        this.overDueApptSelection = [];
                        if (!this.isFromCoachCorner) {
                            if (this.isShowFilter) {
                                await this.getAppointmentList();
                                this.onDateChange(this.selectedDate);
                            }
                            else
                                this.getOverDueAppointment();
                        }
                        else {
                            this.getOverDueApptListData();
                        }
                        this._notifyService.showSuccess('Checked appointments marked as ' + type, ' Marked' + type, 3000);
                    }
                    else
                        this._notifyService.showError('Could not mark checked appointments as ' + type, ' Marked' + type, 3000);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getOverDueApptListData() {
        const now = new Date();
        const startDate = this.datePipe.transform(new Date(now.getFullYear(), now.getMonth()), 'MMddyyyy');
        const endDate = this.datePipe.transform(new Date(now.getFullYear(), now.getMonth() + 1, 0), 'MMddyyyy');
        await this._appointmentSettingService.ApptListGetByClpUserId(this.encryptedUser, this.user?.cLPCompanyID, startDate, endDate, this.user.cLPUserID, 0, eApptCategory.None, 500, eApptStatus.None)
            .then(async (result: ApptListResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        this.overdueApptList = response?.apptList;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    vfunCompany(dr): string {
        if (!isNullOrUndefined(dr)) {
            var strCompanyName = "";
            var companyshow = '';
            strCompanyName = dr?.companyName;
            var eCoType = eCompanyType.Personal;
            if (!isNullOrUndefined(dr?.cLPCompanyID) && dr?.cLPCompanyID > 0) {
                eCoType = eCompanyType.Personal;
                if (!isNullOrUndefined(dr?.companyType)) {
                    eCoType = dr?.companyType;
                    if (eCoType = eCompanyType.Business)
                        companyshow = strCompanyName;
                    /*previous code:           companyshow = "<a href='../company/coid=" + dr.cLPCompanyID + "&mde=e' title=" + "" + strCompanyName + "" + ">" + strCompanyName + "</a>";*/
                    else
                        companyshow = strCompanyName;
                }
            }
            return this._sanitizer.sanitize(SecurityContext.HTML, companyshow);
        }
    }

    public viewHandlerQuickDashboardList(dataItem, type) {
        if (type == 'lead') {
            localStorage.setItem('userName', JSON.stringify(dataItem?.userLastFirst));
            localStorage.setItem('companyName', JSON.stringify(dataItem?.companyName));
            localStorage.setItem('contactId', JSON.stringify(dataItem?.contactID));
            this.leadSettingService.leadHomeSubject.next(dataItem);
            this._router.navigate(['lead-detail', dataItem?.leadID]);
        }
        else if (type == 'contact')
            this._router.navigate(['contact', dataItem?.cLPUserID, dataItem?.contactID]);
        else if (type == 'user')
            this._router.navigate(['edit-profile', dataItem?.cLPUserID]);
    }

    async appointmentPopUp(apptData: ApptExtended, isNewAppt: boolean = false) {
        if (isNewAppt) {
            if (this.user.cLPCompanyID == 1226) {
                if (this.user.cLPUserID != 3893) {
                    this.blnIsIH = true;
                }
            }
            this.shareableAppt = {
                apptId: 0,
                rApptID: apptData?.rApptID,
                cat: this.eCat,
                leadContactId: this.selectedLead?.contactID,
                ownerId: this.ownerId,
                currentUrlAppt: '',
                isNewTab: true,
            };
            this.isShowApptModal = true;
            $('#overdueApptModal').modal('show');
        }
        else {
            await this._appointmentSettingService.apptLoad(this.encryptedUser, apptData?.apptID, this.user?.cLPUserID, this.user?.cLPCompanyID).then
                (async (result: ApppointmentResponse) => {
                    if (!isNullOrUndefined(result)) {
                        let appt = UtilityService.clone(result);
                        if (!isNullOrUndefined(appt)) {
                            if (appt.appt.cLPCompanyID == 1226) {
                                if (appt.appt.cLPUserID != 3893) {
                                    this.blnIsIH = true;
                                    if (appt.appt.proposalID > 0) {
                                        this.blnIsIH = false
                                    }
                                }
                            }
                        }
                        this.shareableAppt = {
                            apptId: apptData?.apptID,
                            rApptID: apptData?.rApptID,
                            leadContactId: 0,
                            cat: apptData?.category,
                            ownerId: apptData?.category == eApptCategory.Contact ? apptData?.contactID : apptData?.category == eApptCategory.Lead ? apptData?.leadID : 0,
                            currentUrlAppt: this.isShowFilter ? 'appointmentlist' : 'overdueappt',
                            isNewTab: true,
                        };
                        this.isShowApptModal = true;
                        this.showSpinner = false
                        $('#overdueApptModal').modal('show');
                    }
                }).catch((err: HttpErrorResponse) => {
                    this.showSpinner = false;
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }

    }

    hideQuickApptModal(updateAppt?: UpdateAppt) {
        if (!isNullOrUndefined(updateAppt)) {
            this.closeModalApptModal();
            if (updateAppt.isSave || updateAppt.isDelete)
                this.isShowFilter ? this.getAppointmentList() : this.getOverDueAppointment();
        }
        this.isShowApptModal = false;
        this.shareableAppt = null;
    }

    closeModalApptModal() {
        let inputElement: HTMLElement = this.closeInputButton.nativeElement as HTMLElement;
        inputElement.click();
    }

    onOveDueApptFilter(inputValue: string): void {
        if (this.isHideGridTitle) {
            this.overdueApptList = process(this.overdueApptListInit, {
                filter: {
                    logic: "or",
                    filters: [
                        { field: 'apptStartTime', operator: 'contains', value: inputValue },
                        { field: 'subject', operator: 'contains', value: inputValue },
                        { field: 'location', operator: 'contains', value: inputValue },
                        { field: 'companyName', operator: 'contains', value: inputValue },
                        { field: 'contactName', operator: 'contains', value: inputValue },
                        { field: 'lead', operator: 'contains', value: inputValue },
                        { field: 'codeDisplay', operator: 'contains', value: inputValue },
                        { field: 'userLastFirst', operator: 'contains', value: inputValue },
                    ],
                }
            }).data;
        }
        else {
            this.coachCornerAppointmentList = process(this.coachCornerAppointmentListInit, {
                filter: {
                    logic: "or",
                    filters: [
                        { field: 'subject', operator: 'contains', value: inputValue },
                        { field: 'companyName', operator: 'contains', value: inputValue }
                    ],
                }
            }).data;
        }
        this.dataBinding.skip = 0;
    }

    onDateChange(e) {
        e = e?.setHours(0, 0, 0, 0);
        this.selectedDate = new Date(e);
        this.overdueApptList = this.overdueApptListInit?.filter(item => new Date(new Date(item.apptStartTime).setHours(0, 0, 0, 0)).toString() == new Date(e).toString());
    }


    moveToTextMsg(data, type: string) {
        if (!isNullOrUndefined(data) && !isNullOrUndefined(data?.contactID)) {
            if (type == 'msg') {
                this.hideTextCallModal();
                this.textMsgInfo.isShow = true;
                this.textMsgInfo.contactId = data?.contactID;
            }
            else {
                this.hideTextMsgModal();
                this.textCallInfo.contactId = data?.contactID;
                if (this.textCallInfo?.contactId > 0)
                    this.getContactData();
            }
        }
        else {
            if (type = 'msg') {
                this.hideTextMsgModal();
            }
            else {
                this.hideTextCallModal();
            }
        }
    }

    hideTextMsgModal(sms?: boolean) {
        this.textMsgInfo.isShow = false;
        this.textMsgInfo.contactId = 0;
        $('#textMsgModal').modal('hide');
    }

    async loadLeadData() {
        this.showSpinner = true;
        await this.leadSettingService.getLeadLoad(this.encryptedUser, this.ownerId)
            .then(async (result: Lead) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    this.apptListTitleCompanyName = response?.leadDesc;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContactData() {
        this.showSpinner = true;
        await this._contactService.contactLoad(this.encryptedUser, this.eCat == eApptCategory.Contact ? this.ownerId : this.textCallInfo.contactId)
            .then(async (result: Contact) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    let faxformatted: string = '';
                    let phformatted: string = '';
                    this.apptListTitleCompanyName = response?.firstName + " " + response?.lastName;
                    this.selectedCountry = isNullOrEmptyString(response?.country) ? this.selectedCountry : response?.phone;
                    if (!isNullOrEmptyString(response.phone)) {
                        const phoneNumber = parsePhoneNumber(response?.phone, { defaultCountry: this.selectedCountry });
                        phformatted = phoneNumber.formatNational();
                    }
                    if (!isNullOrEmptyString(response.fax)) {
                        const faxNumber = parsePhoneNumber(response?.fax, { defaultCountry: this.selectedCountry });
                        faxformatted = faxNumber.formatNational();
                    }
                        this.apptTooltip = response?.firstName + response?.lastName + '\n' + response?.add1 + '\n' + response?.add2 + '\n' + response?.city + '  ' + response?.state + '  ' + response?.zip + '\n' + response?.country + '\n' + 'P:' + phformatted + '\n' + 'F:' + faxformatted;
                    if (response.contactID > 0) {
                        this.textCallInfo.isShow = true;
                        this.textCallInfo.contact = response;
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    hideTextCallModal(sms?: boolean) {
        this.textCallInfo.isShow = false;
        this.textCallInfo.contactId = 0;
        this.textCallInfo.contact = null;
        $('#textCallModal').modal('hide');
    }

    async getLoadUsers() {
        await this._accountSetupService.getClpUserList(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: ClpUsersDdResponse) => {
                if (!isNullOrUndefined(result))
                    this.userFilterDD = UtilityService.clone(result?.clpUsers);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    gotoLink() {
        switch (this.eCat) {
            case eApptCategory.CompanyObject:
                this._router.navigateByUrl(`company-create?cid=${this.ownerId}`);
                break;
            case eApptCategory.Contact:
                this._router.navigate(['/contact', this.user?.cLPUserID, this.ownerId]);
                break;
        }
    }

    async getAddressByCompanyId() {
        await this._accountSetupService.getAddressByCompanyId(this.encryptedUser, this.ownerId)
            .then(async (result: CompanyObjectResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result)
                    this.apptListTitleCompanyName = response?.company?.companyName;
                    this.apptTooltip = response?.company?.companyName + '\n' + response?.company?.add1 + '\n' + response?.company?.add2 + '\n' + response?.company?.add3 + '\n' + response?.company?.city + '  ' + response?.company?.state + '  ' + response?.company?.zip + '\n' + response?.company?.country + '\n' + 'P:' + response?.company?.phone + '\n' + 'F:' + response?.company?.fax;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrors(err);
            })
    }

    ngOnDestroy(): void {
        this.routeParamsSubscription?.unsubscribe();
    }


    async getApptFilters() {
        await this._appointmentSettingService.getAppointmentFilter(this.encryptedUser, this.user.cLPUserID, this.user.cLPCompanyID, this.apptCategoryType)
            .then(async (result: FiltersApptMainResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.apptFilters = response?.filters;
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    pageChange(event: PageChangeEvent): void {
        let skip = event.skip;
        this.currentPage = (skip / this._gridCnfgService.pageSize) + 1;
    }

}
