
<div class="mb-4">
    <div class="margin-all-area">
        <div class="wraper-main-section">
            <div class="global-card-section">
                <div class="global-header-section">
                    <div class="svg-icon-panel">
                        <img src="../../../../assets/activity/config/userlisttitle.svg" class="mr-1" />Metrics
                    </div>
                    <div class="header-button-panel">
                        <div class="button-wrapper">
                            <button type="button" matTooltip="Close" class="icon-btn" (click)="onClose()">
                                <i class="fas fa-times"></i>
                                <span class="btn-text">Close</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="global-body-section">
                    <div class="alert-panel">
                        <div *ngIf="sOMetricList?.length > 0">Metric found: {{sOMetricList?.length}}</div>
                    </div>
                    <kendo-grid #grid id="gridIdNew" [kendoGridBinding]="sOMetricList" class="new-user-list-grid"
                                (edit)="editHandlerNewUser($event)"
                                (cancel)="cancelHandlerNewUser($event)"
                                (save)="saveHandlerNewUser($event)"
                                (remove)="removeHandler($event)">

                        <kendo-grid-column *ngFor="let column of columns"
                                           [field]="column.field"
                                           [title]="column.title | titlecase"
                                           [width]="column?.width | stringToNumber"
                                           [filterable]="true"
                                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                           [editable]="column.field == '$' || column.field == 'status'?false: true"
                                           [includeInChooser]="column.field=='$' ? false : true">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="customer-name" *ngIf="column.title == ' '">{{ rowIndex+1 }}</div>
                                <div *ngIf="column.field == 'metricName'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] }} </div>
                                <div *ngIf="column.field == 'metricValue'">{{ !dataItem['metricValue'] ? '--' : dataItem['metricValue'] }} </div>
                                <div *ngIf="column.field == 'dtCreated'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] }} </div>

                            </ng-template>
                            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column">
                                <div class="customer-name" *ngIf="column.title == ' '">{{ rowIndex+1 }}</div>
                                <div *ngIf="column.field == 'metricName'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] }} </div>
                                <div class="customer-name" *ngIf="column.field=='metricValue'">
                                    <input type="text" [(ngModel)]="dataItem.metricValue" />
                                </div>
                                <div *ngIf="column.field == 'dtCreated'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] }} </div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-command-column [width]="100" title="Action" [includeInChooser]="false" [reorderable]="false" [columnMenu]="false">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="flex-align-panel">
                                    <button kendoGridEditCommand title="Edit">
                                        <kendo-icon name="edit"></kendo-icon>
                                    </button>
                                    <button kendoGridRemoveCommand [primary]="true" data-toggle="modal" data-target="#confirmDeleteModal" title="Remove">
                                        <kendo-icon name="delete"></kendo-icon>
                                    </button>
                                    <button kendoGridSaveCommand [primary]="true" title="Update">
                                        <kendo-icon name="check"></kendo-icon>
                                    </button>
                                    <button kendoGridCancelCommand [primary]="true" title="Cancel">
                                        <kendo-icon name="close"></kendo-icon>
                                    </button>
                                </div>
                            </ng-template>
                        </kendo-grid-command-column>
                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="confirmDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
            </div>
            <div class="modal-body modal-common-body">
                <h5>Caution: This metric will be premanently deleted.</h5>
                <h5>Are you sure you want to delete this metric?</h5>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="sOMetric_Delete()">Confirm</button>
                <button type="button" class="btn btn-cancel" (click)="cancel()">Close</button>
            </div>
        </div>
    </div>
</div>
