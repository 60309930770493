import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { ClubModuleSettingsResponse } from "../models/ClubModuleSettings.model";
import { delayedRetryHttp } from "./shared/delayedRetry";
import { UtilityService } from "./shared/utility.service";


@Injectable({
    providedIn: 'root'
})
export class ClubModuleSettingsService {
    private baseUrl: string;
    private api: string = "api/ClubModuleSettings";

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async CMSettings_Load(encryptedUser: string, clpcompnayId): Promise<ClubModuleSettingsResponse | void> {
        const a = await this.httpClient
            .get<ClubModuleSettingsResponse>(`${this.baseUrl}/CMSettings_Load/${clpcompnayId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpcompnayId, encryptedUser, "ClubModuleSettingsService", "CMSettings_Load"); });
        return a;
    }


}
