import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BWCampaignListResponse } from '../models/bw-campaign.model';
import { Contact, ContactResponse } from '../models/contact.model';
import { MailTypeResponse } from '../models/emailTemplate.model';
import { eMailingContactResult, eTxtMsgStatus, eVoiceDropStatus } from '../models/enum.model';
import { DropDownItem, SimpleResponse } from '../models/genericResponse.model';
import { EmailingSaveRequest, Mailing, MailingListResponse, MailingSaveRequest } from '../models/mailing.model';
import { MailingContact, TempMMDocument } from '../models/mailingContact.model';
import { ClickCount } from '../models/report.model';
import { MailingSearchByCategoryObj, MailListItem, MailListItemResponse } from '../models/tempList.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class EmailBlastService {
  private baseUrl: string;
  private api: string = "api/EmailWizard";
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async completeMailing(encryptedUser: string, mailingID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/CompleteMailing/${mailingID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "mailingID- " + mailingID, encryptedUser, "EmailBlastService", "CompleteMailing"); });
    return a;
  }

  async moveRightList(encryptedUser: string, clpCompanyId: number, cLPUserId: number, tempListID: number, contactId: number[]): Promise<boolean | void> {
    const a = await this.httpClient
      .get<boolean>(`${this.baseUrl}/MoveRight/${tempListID}/${clpCompanyId}/${cLPUserId}/${contactId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, contactId, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "cLPUserId - " + cLPUserId + " , " + "tempListID - " + tempListID, encryptedUser, "EmailBlastService", "moveRightList"); });
    return a;
  }

  async saveEmailWizard(encryptedUser: string, objMail: Mailing): Promise<number | void> {
    const a = await this.httpClient
      .post<number>(`${this.baseUrl}/SaveEmailWizard`, objMail, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, objMail, "r - " + encryptedUser, encryptedUser, "EmailBlastService", "saveEmailWizard"); });
    return a;
  }

  async mailingFinishAndConfirm(encryptedUser: string, mailingSaveRequest: MailingSaveRequest): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/MailingFinishAndConfirm`, mailingSaveRequest, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, mailingSaveRequest, "r - " + encryptedUser, encryptedUser, "EmailBlastService", "mailingFinishAndConfirm"); });
    return a;
  }

  async emailingFinishAndConfirm(encryptedUser: string, emailingSaveRequest: EmailingSaveRequest): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/EmailingFinishAndConfirm`, emailingSaveRequest, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, emailingSaveRequest, "r - " + encryptedUser, encryptedUser, "EmailBlastService", "emailingFinishAndConfirm"); });
    return a;
  }

  async getEmailTypeList(encryptedUser: string, clpCompanyId: number): Promise<MailTypeResponse | void> {
    const a = await this.httpClient
      .get<MailTypeResponse>(`${this.baseUrl}/GetEmailType/${clpCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "EmailBlastService", "getEmailTypeList"); });
    return a;
  }

  async getMailingSearchByCategory(encryptedUser: string, mailingSearchByCategoryObj: MailingSearchByCategoryObj): Promise<MailListItemResponse | void> {
    const a = await this.httpClient
      .post<MailListItemResponse>(`${this.baseUrl}/MailingSearch_ByCategory`, mailingSearchByCategoryObj, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, mailingSearchByCategoryObj, "r - " + encryptedUser, encryptedUser, "EmailBlastService", "getMailingSearchByCategory"); });
    return a;
  }

  async saveMailingContact(encryptedUser: string, mailingContactObj: MailingContact[]): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/MailingContact_Save`, mailingContactObj, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, mailingContactObj, "r - " + encryptedUser, encryptedUser, "EmailBlastService", "saveMailingContact"); });
    return a;
  }

  async mailingLoadByMailingId(encryptedUser: string, mailingId: number): Promise<Mailing | void> {
    const a = await this.httpClient
      .get<Mailing>(`${this.baseUrl}/Mailing_LoadByMailingId/${mailingId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "mailingId - " + mailingId, encryptedUser, "EmailBlastService", "mailingLoadByMailingId"); });
    return a;
  }

  async mailingDelete(encryptedUser: string, mailingId: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/Mailing_Delete/${mailingId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "mailingId - " + mailingId, encryptedUser, "EmailBlastService", "mailingDelete"); });
    return a;
  }

  async mailingContactList(encryptedUser: string, mailingId: number, eResult: eMailingContactResult = eMailingContactResult.Unknown, rowCount: number = -1): Promise<ContactResponse | void> {
    const a = await this.httpClient
      .get<ContactResponse>(`${this.baseUrl}/Mailing_MailingContactList/${mailingId}/${eResult}/${rowCount}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "mailingId - " + mailingId + " , " + "eResult - " + eResult + " , " + "rowCount - " + rowCount, encryptedUser, "EmailBlastService", "mailingContactList"); });
    return a;
  }

  async mailingVoiceDropList(encryptedUser: string, mailingId: number, status: eVoiceDropStatus = eVoiceDropStatus.Unknown, rowCount: number = -1): Promise<ContactResponse | void> {
    const a = await this.httpClient
      .get<ContactResponse>(`${this.baseUrl}/Mailing_MailingVoiceDropList/${mailingId}/${status}/${rowCount}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "mailingId - " + mailingId + " , " + "status - " + status + " , " + "rowCount - " + rowCount, encryptedUser, "EmailBlastService", "mailingVoiceDropList"); });
    return a;
  }

  async mailingTxtMsgList(encryptedUser: string, mailingId: number, status: eTxtMsgStatus = eTxtMsgStatus.Unknown, rowCount: number = -1): Promise<ContactResponse | void> {
    const a = await this.httpClient
      .get<ContactResponse>(`${this.baseUrl}/Mailing_MailingTxtMsgList/${mailingId}/${status}/${rowCount}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "mailingId - " + mailingId + " , " + "status - " + status + " , " + "rowCount - " + rowCount, encryptedUser, "EmailBlastService", "mailingTxtMsgList"); });
    return a;
  }
  async mailingClickCountByMailingID(encryptedUser: string, mailingId: number, companyId: number): Promise<ClickCount[] | void> {
    const a = await this.httpClient
      .get<ClickCount[]>(`${this.baseUrl}/MailingClick_ClickCountByMailingID/${companyId}/${mailingId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "mailingId - " + mailingId + " , " + "companyId - " + companyId, encryptedUser, "EmailBlastService", "mailingClickCountByMailingID"); });
    return a;
  }

  async mailingDocumnetSave(encryptedUser: string, tempMMDocument: TempMMDocument): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .post<SimpleResponse>(`${this.baseUrl}/TempMMDocument_Save`, tempMMDocument, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, tempMMDocument, "r - " + encryptedUser, encryptedUser, "EmailBlastService", "mailingDocumnetSave"); });
    return a;
  }

  async mailingDocumentList(encryptedUser: string, OwnerId: number, type: number, clpCompanyId: number, subOwnerId: number): Promise<TempMMDocument[] | void> {
    const a = await this.httpClient
      .get<TempMMDocument[]>(`${this.baseUrl}/TempMMDocuments_GetListByOwner/${OwnerId}/${type}/${clpCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "OwnerId - " + OwnerId + " , " + "type - " + type + " , " + "clpCompanyId - " + clpCompanyId + " , " + "subOwnerId - " + subOwnerId, encryptedUser, "EmailBlastService", "mailingDocumentList"); });
    return a;
  }

  async mailingContactCount(encryptedUser: string, mailingId: number, eResult: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/Mailing_MailingContactCount/${mailingId}?eResult=${eResult}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "mailingId - " + mailingId + " , " + "eResult - " + eResult, encryptedUser, "EmailBlastService", "mailingContactCount"); });
    return a;
  }

  async mailingTextMsgCount(encryptedUser: string, mailingId: number, estat: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/Mailing_MailingTxtMsgCount/${mailingId}?estat=${estat}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "mailingId - " + mailingId + " , " + "estat - " + estat, encryptedUser, "EmailBlastService", "mailingTextMsgCount"); });
    return a;
  }
    
    async mailingVoiceDropCount(encryptedUser: string, mailingId: number, estat: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
        .get<SimpleResponse>(`${this.baseUrl}/Mailing_MailingVoiceDropCount/${mailingId}?estat=${estat}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "mailingId - " + mailingId + " , " + "estat - " + estat, encryptedUser, "EmailBlastService", "mailingVoiceDropCount"); });
    return a;
  }

  async getMailingListByContact(encryptedUser: string, contactID: number, isRecent: boolean = true): Promise<MailingListResponse | void> {
    const a = await this.httpClient
      .get<MailingListResponse>(`${this.baseUrl}/Mailing_GetListByContact/${contactID}?isRecent=${isRecent}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "contactID - " + contactID + " , " + "isRecent - " + isRecent, encryptedUser, "EmailBlastService", "getMailingListByContact"); });
    return a;
  }

  async getMailingListByContactCompany(encryptedUser: string, companyId: number, isRecent: boolean = true): Promise<MailingListResponse | void> {
    const a = await this.httpClient
      .get<MailingListResponse>(`${this.baseUrl}/Mailing_GetListByContactCompany/${companyId}?isRecent=${isRecent}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "companyId - " + companyId + " , " + "isRecent - " + isRecent, encryptedUser, "EmailBlastService", "getMailingListByContactCompany"); });
    return a;
  }

  async getMailingListByCLPUserID(encryptedUser: string, clpUserId: number, isRecent: boolean = true, clpCompanyId: number = -1): Promise<MailingListResponse | void> {
    const a = await this.httpClient
      .get<MailingListResponse>(`${this.baseUrl}/Mailing_GetListByCLPUserID/${clpUserId}?isRecent=${isRecent}&clpCompanyId=${clpCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserId - " + clpUserId + " , " + "isRecent - " + isRecent + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "EmailBlastService", "getMailingListByCLPUserID"); });
    return a;
  }

  async createPDFRequest(encryptedUser: string, clpUserId: number, mailingId: number, clpCompanyId: number = -1): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/createPDFRequest/${clpUserId}/${mailingId}/${clpCompanyId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserId - " + clpUserId + " , " + "mailingId - " + mailingId + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "EmailBlastService", "createPDFRequest"); });
    return a;
  }

  async dMDDAction(encryptedUser: string, clpUserId: number, mailingId: number, clpCompanyId: number = -1, onSelectedAction: string = "", ddResult: string = "", ddTxtMsgStatus: string = "", ddVoiceDropStatus: string = ""): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/DMDDAction/${clpUserId}/${mailingId}/${clpCompanyId}/${onSelectedAction}?ddResult=${ddResult}&ddTxtMsgStatus=${ddTxtMsgStatus}&ddVoiceDropStatus=${ddVoiceDropStatus}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserId - " + clpUserId + " , " + "mailingId - " + mailingId + " , " + "clpCompanyId - " + clpCompanyId + "OnSelectedAction -" + onSelectedAction + "ddResult -" + ddResult + "ddTxtMsgStatus -" + ddTxtMsgStatus + "ddVoiceDropStatus -" + ddVoiceDropStatus, encryptedUser, "EmailBlastService", "dMDDAction"); });
    return a;
  }

  async bindMailingOwnerDD(encryptedUser: string, cLPCompanyID: number, officeCode: number = 0, teamCode: number = 0, permissions: number = 9): Promise<DropDownItem | void> {
    const a = await this.httpClient
      .get<DropDownItem>(`${this.baseUrl}/BindMailingOwnerDD?cLPCompanyID=${cLPCompanyID}&officeCode=${officeCode}&teamCode=${teamCode}&permissions=${permissions}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPCompanyID - " + cLPCompanyID + " , " + "OfficeCode - " + officeCode + " , " + "teamCode - " + teamCode + "permissions -" + permissions, encryptedUser, "EmailBlastService", "BindMailingOwnerDD"); });
    return a;
    }

    async updateBWCampaignInfo(encryptedUser: string, mailingId: number, bWCampaign: string, bWBrand: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/Mailing_UpdateBWCampaignInfo/${mailingId}/${bWCampaign}/${bWBrand}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "mailingId - " + mailingId + "bWCampaign" + bWCampaign + "bWBrand" + bWBrand  , encryptedUser, "EmailBlastService", "updateBWCampaignInfo"); });
        return a;
    }

    async setTNCampaign(encryptedUser: string, strTN: string, strCampaign: string): Promise<SimpleResponse | void>  {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/SetTNCampaign/${strTN}/${strCampaign}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "strTN - " + strTN + "strCampaign" + strCampaign, encryptedUser, "EmailBlastService", "setTNCampaign"); });
        return a;
    }

    async getBrand(encryptedUser: string, strCampaign: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/GetBrand/${strCampaign}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "strCampaign" + strCampaign, encryptedUser, "EmailBlastService", "setTNCampaign"); });
        return a;
    }

   async getTNCampaignBrand(encryptedUser: string, strTN: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/GetTNCampaignBrand/${strTN}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "strTN" + strTN, encryptedUser, "EmailBlastService", "getTNCampaignBrand"); });
        return a;
    }

   async getBWCampaignDD(encryptedUser: string): Promise<BWCampaignListResponse | void> {
        const a = await this.httpClient
            .get<BWCampaignListResponse>(`${this.baseUrl}/BWCampaign_GetFullList/`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "EmailBlastService", "getBWCampaignDD"); });
        return a;
    }
}
