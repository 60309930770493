<div class="custom-security-link">
    <!--<a class="custom-security-link" (click)="appointmentPopUp(null,true);" data-toggle="modal" data-target="#quickApptModalCall" data-backdrop="static" data-keyboard="false">New Appt</a>-->
    <a class="custom-security-link">New Appt</a>
    <a [routerLink]="['/calender']" routerLinkActive="active">My Calendar</a>
    <a [routerLink]="['/task']" routerLinkActive="active">My Tasks</a>
</div>

<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel">
                    <img src="../../../../../assets/activity/calendar/calllisttitle.svg" class="mr-1" />
                    <a class="contact-cursor-pointer" (click)="updateDateCall(false)">
                        <img src="../../../../../assets/activity/config/arrow_left_yellow.svg" class="mr-1" />
                    </a>
                    Call List for {{currSelectedDate | date : 'EEEE'}}&nbsp;{{currSelectedDate | date :dateFormat}}
                    <a class="contact-cursor-pointer" (click)="updateDateCall(true)">
                        <img src="../../../../../assets/activity/config/arrow_right_yellow.svg" class="mr-1" />
                    </a>
                </div>
                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <div *ngIf="filterType?.length>0">
                            <select class="form-control" [(ngModel)]="ddFilterType" (change)="callFilterData($event.target.value)">
                                <option value="-1"> -All Types- </option>
                                <option *ngFor="let call of filterType; let i = index" [value]="call?.value">{{call?.text }}</option>
                            </select>
                        </div>
                        <div>
                            <select class="form-control" [(ngModel)]="ddSortField" (change)="filterSortByFilter($event.target.value)">
                                <option *ngFor="let call of ddSortFilterType; let i = index" [value]="call?.value">{{call?.text }}</option>
                            </select>
                        </div>
                        <div>
                            <select class="form-control" [(ngModel)]="ddSortDir" (change)="filterSortByDir($event.target.value)">
                                <option *ngFor="let call of ddSortDirFilterType; let i = index" [value]="call?.value">{{call?.text }}</option>
                            </select>
                        </div>
                        <div>
                            <button title="Reload Call List" class="icon-btn" (click)="reloadCall()">
                                <i class="fa fa-refresh"></i>
                                <span class="btn-text">Reload</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="global-body-section" [class.fade-out]="showSpinner">
                <div *ngIf="initCopyApptsExtended?.length>0" class="message-info">
                    There are {{apptsExtended?.length}} pending calls scheduled for this day.
                    <ng-template [ngIf]="initCopyApptsExtended?.length>10">
                        Only the top 10 are displayed.<span class="color-orange">  After you handle these calls, click the Reload button to load more calls into the Call List.</span>
                    </ng-template>
                </div>
                <div *ngIf="initCopyApptsExtended?.length<=0" class="message-info">There are no pending calls scheduled for this day.</div>
                <div>
                    <div *ngIf="copyApptsExtended?.length>0">
                        <div>
                            <div *ngFor="let item of copyApptsExtended; let idx=index;">
                                <ng-container *ngIf="!item?.isSaved;  then apptTemplate; else warningApptTemplate">
                                </ng-container>
                                <ng-template #apptTemplate>
                                    <div class="call-section">
                                        <div class="call-row">
                                            <div class="call-colunm7">
                                                <div class="customer-name">
                                                    <a class="text-primary contact-cursor-pointer" title="Open appointment in new window" (click)="appointmentPopUp(item)" data-toggle="modal" data-target="#quickApptModalCall" data-backdrop="static" data-keyboard="false">
                                                        <span style="font-weight:600">{{item?.apptStartTime | date : "h:mm a"}}</span> - <span style="font-weight:600">{{item?.apptEndTime | date : "h:mm a"}}</span>
                                                    </a>
                                                    &nbsp; <b>{{item?.subject}}</b>
                                                </div>
                                            </div>
                                            <div class="call-colunm">
                                                <div class="grid-colunm-vertical">
                                                    <span>{{item?.codeDisplay}}</span>
                                                    <div class="" *ngIf="!item?.isSelected">
                                                        <button type="button" [hidden]="roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator" class="grid-common-btn" [disabled]="showSpinner" (click)="item.isSelected = true;"> <i class="fa fa-pencil" aria-hidden="true" title="Edit"></i></button>
                                                    </div>
                                                    <div class="flex-align-panel" *ngIf="item?.isSelected">
                                                        <button type="button" class="grid-common-btn" [disabled]="showSpinner" (click)="saveCallEdit(item);"> <i class="fa fa-save" aria-hidden="true" title="Save"></i></button>
                                                        <button type="button" class="grid-common-btn" [disabled]="showSpinner" (click)="cancelCallEdit(item);"> <i class="fa fa-undo" aria-hidden="true" title="Cancel"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="call-section" id="pnlQualCall" *ngIf="item?.campaignEvent?.campaignEventID  && item.campaignEvent?.CLPCompanyID == user.CLPCompanyID">
                                        <div class="call-row" *ngIf="item?.campaignEvent?.toChoice > 0">
                                            <div class="call-colunm7" id="lblQualAttempts">
                                                <div class="customer-name" *ngIf="item?.class1>0">
                                                    Previous Call Attempts: {{item?.class1}}
                                                </div>
                                            </div>
                                            <div class="call-colunm">
                                                <button id="=btnQualDNC" (click)="onQualClick(1)">Left Message</button>
                                                <button id="btnQualLM" (click)="onQualClick(0)">Did Not Contact</button>
                                                <label id="lblQualCallForm"><a class="qualbar">Open Call Form</a></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="call-section">
                                        <div class="call-row">
                                            <div *ngIf="item?.isSelected" class="call-colunm">
                                                <div>
                                                    <label><b>Start Time</b></label>
                                                    <div>
                                                        <kendo-datetimepicker [(ngModel)]="item.apptStartTime"></kendo-datetimepicker>
                                                    </div>
                                                </div>
                                                <div class="mt-2">
                                                    <label><b>Status</b></label>
                                                    <div>
                                                        <select class="form-control" [(ngModel)]="item.status">
                                                            <ng-container [ngSwitch]="item.campaignEventID">
                                                                <ng-container *ngSwitchCase="0">
                                                                    <option *ngFor="let call of apptStatusListNonCampaign; let i = index" [value]="call?.value">{{call?.text }} </option>
                                                                </ng-container>
                                                                <ng-container *ngSwitchDefault>
                                                                    <option *ngFor="let call of apptStatusList; let i = index" [value]="call?.value">{{call?.text }} </option>
                                                                </ng-container>
                                                            </ng-container>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="call-colunm7" *ngIf="!item?.isSelected">
                                                <label><b>Notes</b></label>
                                                <div>
                                                    <span>{{item?.notes}}</span>
                                                </div>
                                            </div>

                                            <div class="call-colunm7" *ngIf="item?.isSelected">
                                                <textarea type="text" class="form" [(ngModel)]="item.notes"></textarea>
                                            </div>

                                            <div class="global-padding10" *ngIf="user?.cLPCompanyID == 160">
                                                <div>Engagement Summary</div>
                                                <div>
                                                    <ul>
                                                        <li>Last USPS mailing : {{item?.callListSummary?.lastUSPSMailing ? item?.callListSummary?.lastUSPSMailing?.mailingStartTime + item?.callListSummary?.lastUSPSMailing?.subject : 'None found'}} </li>
                                                        <li>Active Leads :{{item?.callListSummary?.isActiveLeadExists ? 'Yes' : 'No'}}</li>
                                                        <li>Birthday : {{item?.callListSummary?.customDate1 ? customDate1 : 'Unknown'}}</li>
                                                        <li>
                                                            Recent Emails : <span *ngFor="let item of item?.callListSummary?.recentEmailList">{{item?.dtSend}}{{item?.subject}}{{item?.result}}</span>
                                                            <span>None found</span>
                                                        </li>
                                                        <li>Home purchase date : {{item?.callListSummary?.customDate2 ? customDate2 : 'Unknown'}}</li>
                                                        <li>
                                                            Recent referrals : <span *ngFor="let item of item?.callListSummary?.recentReferralList">{{item?.referral}}{{item?.dtCreated}}</span>
                                                            <span>None found</span>
                                                        </li>
                                                        <li>Last Call : {{item?.callListSummary?.lastCompletedAppt ?  item?.callListSummary?.apptStartTime + item?.callListSummary?.subject + item?.callListSummary?.notes : 'None found'}} </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div class="global-padding10">
                                                <ng-container *ngIf="isTxtMsgActive && (item?.contact || item?.leadContact)">
                                                    <div *ngIf="item?.contact?.email != '' || item?.leadContact?.email != ''">
                                                        <button type="button" class="grid-common-btn" title="Send Email" (click)="sendEmailParent((item?.contact!=null)?(item?.contact):(item?.leadContact));"><img src="../../../../../assets/emailbtn.svg"></button>
                                                    </div>
                                                    <div>
                                                        <button type="button" class="grid-common-btn" (click)="appointmentPopUp(null,true);" data-toggle="modal" data-target="#quickApptModalCall" data-backdrop="static" data-keyboard="false"> <img src="../../../../../assets/appttitle.svg"></button>
                                                    </div>
                                                    <div *ngIf="item?.contact?.mobile != '' || item?.leadContact?.mobile != ''">
                                                        <button type="button" class="grid-common-btn" title="Send Text" (click)="sendText((item?.contact!=null)?(item?.contact):(item?.leadContact));"><img src="../../../../assets/txtmsgstitle_grey.svg"></button>
                                                    </div>
                                                </ng-container>

                                                <div>
                                                    <!--include score =companyData?.isSFAIncluded -->
                                                    <div *ngIf="companyData?.isSFAIncluded && (item?.leadContact?.contactID>0 || item?.contact?.contactID>0)">
                                                        <div id="lblScoreDisplay">
                                                            <!-- use item?.leadContact.contactID and open Buzz score-->
                                                            <button class="btn-primary" title="Buzz Index" (click)="onBuzzScore(item);"> <img src="../../../../../assets/buzzbtn.svg"> {{item?.score}}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div *ngIf="item?.leadID>0 || item?.contactID>0">
                                                        <!--<label id="tdTag">OwnerID: {{item?.leadContact.contactID}} OwnerType:{{eNoteOwnerTypeEnum.Contact}}</label>-->
                                                        <div class="mt-3">
                                                            <span [hidden]="item?.isTagSelected" id="ucTag1" (click)="item.isTagSelected=!item.isTagSelected">
                                                                <button class="grid-common-btn" title="{{getDisplayTagsToolTip(item?.tags)}}"><img src="../../../../../assets/tagbtn.svg" /></button>
                                                            </span>

                                                            <kendo-combobox [hidden]="!item?.isTagSelected" [data]="item?.fullTagList" textField="tag" valueField="tagID" [allowCustom]="true" [valuePrimitive]="true" [suggest]="true" placeholder="Type a Tag" (valueChange)="selectedTagChangeEvent($event, item)">
                                                                <ng-template kendoComboBoxItemTemplate let-dataItem>
                                                                    <strong [ngStyle]="{'background-color': (dataItem?.checked) ? '#2DC76D' : ''}">{{dataItem.tag}}</strong>
                                                                </ng-template>
                                                            </kendo-combobox>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="call-colunm">
                                                <ng-container *ngIf="item?.leadID>0;  then leadTemplate; else contactTemplate">
                                                </ng-container>
                                                <ng-template #leadTemplate>
                                                    <div id="lblContactInfo" class="mobile-view-design">
                                                        <div>
                                                            <a class="" title="View Lead" [routerLink]="['/lead-detail',item?.lead?.leadID]">
                                                                Lead: {{item?.lead?.leadDesc}}<br>
                                                            </a>
                                                            <ng-container *ngIf="item?.leadContact" class="mulitple-call-text">
                                                                <p *ngIf="item?.leadContact?.companyName"> {{item?.leadContact.companyName}}</p>
                                                                <p *ngIf="item?.leadContact?.add1"> {{item?.leadContact.add1}}</p>
                                                                <p *ngIf="item?.leadContact?.add2"> {{item?.leadContact.add2}}</p>
                                                                <p *ngIf="item?.leadContact?.add3"> {{item?.leadContact.add3}}</p>
                                                                <p *ngIf="item?.leadContact?.city"> {{item?.leadContact.city}}</p>
                                                                <p *ngIf="item?.leadContact?.state"> {{item?.leadContact.state}}</p>
                                                                <p *ngIf="item?.leadContact?.zip"> {{item?.leadContact.zip}}</p>
                                                                <p *ngIf="item?.leadContact?.country"> {{item?.leadContact.country}}</p>
                                                                <div>
                                                                    <b>B:</b>
                                                                    <a data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(item?.leadContact,'ph')">
                                                                        <span *ngIf="item?.leadContact?.phone">{{item?.leadContact.phone | phoneFormat}}<br></span>
                                                                    </a>
                                                                </div>
                                                                <div>
                                                                    <b>M: </b>
                                                                    <a data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(item?.leadContact,'mp')">
                                                                        <span *ngIf="item?.leadContact?.mobile">{{item?.leadContact.mobile | phoneFormat}}<br></span>
                                                                    </a>
                                                                </div>
                                                                <div>
                                                                    <b>H: </b>
                                                                    <a data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(item?.leadContact,'hp')">
                                                                        <span *ngIf="item?.leadContact?.homePhone">{{item?.leadContact.homePhone | phoneFormat}}<br></span>
                                                                    </a>
                                                                </div>
                                                                <div>
                                                                    <b>O: </b>
                                                                    <a data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(item?.leadContact,'ap')">
                                                                        <span *ngIf="item?.leadContact?.altPhone">{{item?.leadContact.altPhone | phoneFormat}}<br></span></a>
                                                                    </div>
                                                                <div>
                                                                    <b>F: </b>
                                                                    <a data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(item?.leadContact,'fx')">
                                                                        <span *ngIf="item?.leadContact?.fax">{{item?.leadContact.fax}}<br></span>
                                                                    </a>
                                                                </div>
                                                                <div>
                                                                    <b>E: </b>
                                                                    <span *ngIf="item?.leadContact?.email">{{item?.leadContact.email}}<br></span>
                                                                </div>
                                                            </ng-container>
                                                            <span class='smallblack' *ngIf="item?.leadContact.cLPUserID !=item?.cLPUserID">Owner: {{item?.leadContactUserFullName}}<br> </span>
                                                        </div>
                                                    </div>
                                                </ng-template>
                                                <ng-template #contactTemplate>
                                                    <div id="lblContactInfo" class="mobile-view-design">
                                                        <ng-template [ngIf]="item?.contact" class="mulitple-call-text">
                                                            <a [routerLink]="['/contact',item?.contact.cLPUserID,item?.contact.contactID]">
                                                                <span> {{item?.contact.firstName}}&nbsp;{{item?.contact.lastName}}<br></span>
                                                            </a>
                                                            <span>{{item?.contact.companyName? item?.contact.companyName : ''}}</span>
                                                            <span>{{item?.contact.add1 ? item?.contact.add1 : '' }}</span>
                                                            <span>{{item?.contact.add2 ? item?.contact.add2 : ''}}</span>
                                                            <span>{{item?.contact.add3 ? item?.contact.add3 : ''}}</span>
                                                            <span>{{item?.contact.city ? item?.contact.city : ''}}</span>
                                                            <span>{{item?.contact.state ? item?.contact.state : ''}}</span>
                                                            <span>{{item?.contact.zip ? item?.contact.zip : ''}}</span>
                                                            <p> {{item?.contact.country? item?.contact.country : ''}}</p>
                                                            <div>
                                                                <b>B: </b>
                                                                <a *ngIf="!user?.enableSkype" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(item?.contact,'ph')">
                                                                    <span *ngIf="item?.contact?.phone">{{item?.contact.phone | phoneFormat}}<br></span>
                                                                </a>
                                                            </div>
                                                            <div>
                                                                <b>M: </b>
                                                                <a *ngIf="!user?.enableSkype" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(item?.contact,'mp')">
                                                                    <span *ngIf="item?.contact?.mobile">{{item?.contact.mobile | phoneFormat}}<br></span>
                                                                </a>
                                                            </div>
                                                            <div>
                                                                <b>H: </b>
                                                                <a *ngIf="!user?.enableSkype" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(item?.contact,'hp')">
                                                                    <span *ngIf="item?.contact?.homePhone">{{item?.contact.homePhone | phoneFormat}}<br></span>
                                                                </a>
                                                            </div>
                                                            <div>
                                                                <b>O: </b>
                                                                <a *ngIf="!user?.enableSkype" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(item?.contact,'ap')">
                                                                    <span *ngIf="item?.contact?.altPhone">{{item?.contact.altPhone | phoneFormat}}<br></span>
                                                                </a>
                                                            </div>
                                                            <div>
                                                                <b>F: </b>
                                                                <a *ngIf="!user?.enableSkype" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(item?.contact,'fx')">
                                                                    <span *ngIf="item?.contact?.fax">{{item?.contact.fax}}<br></span>
                                                                </a>
                                                            </div>
                                                            <div>
                                                                <b>E: </b>
                                                                <span>{{item?.contact.email? item?.contact.email : ''}}</span>
                                                            </div>
                                                            <!--<a *ngIf="user?.enableSkype" [href]="sanitize(skypenumber)" title="Skype Call">  <span *ngIf="item?.contact?.phone">B: {{item?.contact.phone}}<br></span></a>
                                                            <a *ngIf="user?.enableSkype" [href]="sanitize(skypenumber)" title="Skype Call">  <span *ngIf="item?.contact?.mobile">M: {{item?.contact.mobile}}<br></span></a>
                                                            <a *ngIf="user?.enableSkype" [href]="sanitize(skypenumber)" title="Skype Call"><span *ngIf="item?.contact?.homePhone">H: {{item?.contact.homePhone}}<br></span></a>
                                                            <a *ngIf="user?.enableSkype" [href]="sanitize(skypenumber)" title="Skype Call"><span *ngIf="item?.contact?.altPhone">O: {{item?.contact.altPhone}}<br></span></a>
                                                            <a *ngIf="user?.enableSkype" [href]="sanitize(skypenumber)" title="Skype Call"><span *ngIf="item?.contact?.fax">F:  {{item?.contact.fax}}<br></span></a>-->
                                                        </ng-template>
                                                        <span class='smallblack' *ngIf="item?.contact?.cLPUserID !=item?.cLPUserID">Owner: {{item?.contactUserFullName}} <br></span>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template #warningApptTemplate>
                                    <div class="flex-align-panel global-padding10 warning-alert">
                                        <font color=red>Handled:</font>
                                        <div class="customer-name">
                                            <a class="text-primary contact-cursor-pointer" data-toggle="modal" data-target="#quickApptModalCall" data-backdrop="static" data-keyboard="false" title="Open appointment in new window" (click)="appointmentPopUp(item)">
                                                <span style="font-weight:600">{{item?.apptStartTime | date : "h:mm a"}}</span> - <span style="font-weight:600">{{item?.apptEndTime | date : "h:mm a"}}</span>
                                                on
                                                <span *ngIf="item?.status!=0">{{item?.apptStartTime | date : 'EEEE'}}&nbsp;{{item?.apptStartTime | date :dateFormat}}</span>
                                                <!--<b>{{item?.subject}}</b>-->
                                            </a>
                                        </div>
                                    </div>
                                    <div *ngIf="item?.cLPUserID != user.cLPUserID">Us st<span style="color:red">(Appointment Owner: {{item?.apptUserFullName}} )</span></div>
                                    e>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>

<div *ngIf="isShowNewCallModal && selectedContactData" class="modal fade" id="newCallCreateModal" tabindex="-1" role="dialog" aria-labelledby="newCallCreateModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title white-contact" id="exampleModalLabel"><img src="../../../../../assets/tagtitle.svg" class="mr-1" />Voice Call</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="isShowNewCallModal=false;">
                        <span class="white-contact" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <contact-new-call [contactData]="selectedContactData" [callType]="selectedCallType" [user]="user"></contact-new-call>
                </div>
                <!--<div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="isShowNewCallModal=false;">Close</button>
                </div>-->
            </div>
        </div>

    </div>
</div>

<div class="modal fade" id="sendEmailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <!--<div class="modal-header user-body-header">
                  <h5 class="modal-title white-contact" id="exampleModalLabel"><i class="fas fa-envelope-open-text white-contact"></i> Outbound Email</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideSendMail();">
                    <span class="white-contact" aria-hidden="true">&times;</span>
                  </button>
                </div>-->
                <div class="modal-body">
                    <app-contact-email *ngIf="sendMailInfo?.isShow && user " [loggedUser]="user" [contactId]="sendMailInfo?.contactId"></app-contact-email>
                </div>
                <!--<div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideSendMail();">Close</button>
                </div>-->
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="buzzScoreCalculation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title white-contact" id="exampleModalLabel"> Buzz Index Calculation</h5>
                    <div class="button-wrapper">
                        <button class="icon-btn mt-0" type="button" (click)="closeModalBuzz();">
                            <i class="fa fa-close" aria-hidden="true"></i>
                            <span class="btn-text">Cancel</span>
                        </button>
                    </div>
                    <!-- <button class="close" type="button" data-dismiss="modal" (click)="closeModalBuzz();">
                       <span class="white-contact" aria-hidden="true">&times;</span>
                     </button>-->
                </div>
                <div class="modal-body">
                    <div class="global-padding10">
                        <div class="flex-align-panel">
                            <h6 class="flex-width2">Contact: <b>{{buzzContactName}}</b></h6>
                            <h6>Buzz Index: <b>{{buzzCalcSelectedAppt?.score}}</b></h6>
                        </div>
                    </div>
                    <div class="global-body-section" *ngIf="scoreHistoryResponse">
                        <kendo-grid #grid id="gridId" class="pro-rev-month-grid" *ngIf="_gridCnfgServiceBSC.reloadGridGeneric"
                                    [kendoGridBinding]="scoreHistoryResponse"
                                    [sortable]="{mode: 'multiple'}"
                                    [sort]="_gridCnfgServiceBSC.sort"
                                    [pageSize]="_gridCnfgServiceBSC.pageSize"
                                    [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                    [scrollable]="false"
                                    [reorderable]="true"
                                    [resizable]="true"
                                    [columnMenu]="{ filter: true }"
                                    (columnReorder)="_gridCnfgServiceBSC.columnsOrderChanged('buzz_score_calculation_grid', $event)"
                                    (sortChange)="_gridCnfgServiceBSC.sortChange('buzz_score_calculation_grid', $event)"
                                    (pageChange)="_gridCnfgServiceBSC.pageChange('buzz_score_calculation_grid', $event)"
                                    (columnResize)="_gridCnfgServiceBSC.columnResize(4,'buzz_score_grid', $event)"
                                    (columnVisibilityChange)="_gridCnfgServiceBSC.onVisibilityChange($event,'buzz_score_calculation_grid',grid)">

                            <kendo-grid-column *ngFor="let column of _gridCnfgServiceBSC.columns"
                                               [field]="column.field"
                                               [title]="column.title"
                                               [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                               [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                               [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                               [width]="column.width | stringToNumber"
                                               [filterable]="true">
                                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                    <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                                    <div class="customer-name" *ngIf="column.field == 'score'">{{ dataItem[column.field]}}</div>
                                    <div class="customer-name" *ngIf="column.field == 'type'">{{ dataItem[column.field]}}</div>
                                    <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{ dataItem[column.field] | date: dateFormat}}&nbsp;{{dataItem[column.field] | date : 'mediumTime'}}</div>
                                    <div class="customer-name" *ngIf="column.field == 'delete'"><a class="" (click)="resetSingleScore(dataItem)"><i class="fa-solid fa-trash-can"></i></a></div>
                                </ng-template>
                            </kendo-grid-column>
                        </kendo-grid>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="resetScoreHistory();">Reset Index</button>
                    <!--<button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="closeModalBuzz();">Close this window</button>-->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="underConstruction" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body modal-common-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <p class="under-construction under-construction-common">
                    <img src="../../../assets/under-construction.gif" />
                </p>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isShowApptModal" class="modal fade" id="quickApptModalCall" tabindex="-1" role="dialog" aria-labelledby="quickApptModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-appointment-common [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-common>
                </div>
                <div>
                    <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideQuickApptModal();" [hidden]="true">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade bd-example-modal-lg" id="sendTextModal" tabindex="-1" role="dialog" aria-labelledby="sendTextLabel" aria-hidden="true">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg">
            <div class="modal-content" style="background:none">
                <div class="modal-body">
                    <app-contact-sms *ngIf="sendTextInfo?.isShow" (updatedTextMsg)="hideTextMsgModal($event)" [loggedUser]="user" [contactId]="sendTextInfo?.contactId"></app-contact-sms>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="loader-body" *ngIf="_gridCnfgServiceBSC.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
