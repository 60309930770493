<div class="custom-security-link">
  <a [routerLink]="['/calender']" routerLinkActive="active">My Calendar</a>
  <a [routerLink]="user?.cLPCompanyID === 1226  ? ['/call-ih'] : ['/call']" routerLinkActive="active">My Call List</a>
  <a [routerLink]="['/task']" routerLinkActive="active">My Tasks</a>
  <a [routerLink]="['/contacts']" routerLinkActive="active">My Contacts</a>
  <a [routerLink]="['/lead']" routerLinkActive="active">My Leads</a>
</div>

<div class="margin-all-area">
  <div class="wraper-body-panel">
    <div class="wraper-body-left">
      <div class="wraper-main-section">
        <div class="global-card-section">
          <div class="common-inner-header">
            <div class="inner-header-bg">
              <div class="inner-cards-panel header-inner-hidden">
                <span>Search</span>
                <span>Options</span>
                <span>Just Mine</span>
                <span></span>
              </div>
              <div class="inner-detail-bg">
                <span>Details</span>
              </div>
            </div>
          </div>
          <div class="common-inner-cards">
            <form [formGroup]="homeSearchForm">
              <div class="inner-cards-grid">
                <div class="inner-card-mobile">
                  <label>Search</label>
                  <div class="mobile-view-design">
                    <div class="display-row">
                      <input type="text" formControlName="search">
                    </div>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <label>Option</label>
                  <div class="mobile-view-design">
                    <div class="display-row">
                      <select class="form-control" formControlName="option">
                        <option value="StartsWith">Starts With</option>
                        <option value="Contains">Contains</option>
                        <option value="EndsWith">Ends With</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <label>Just Mine</label>
                  <div class="mobile-view-design">
                    <div class="display-row">
                      <p><input type="checkbox" class="checkbox" formControlName="justMine" /></p>
                    </div>
                  </div>
                </div>
                <div class="inner-card-mobile">
                  <label>Action</label>
                  <div class="">
                    <button type="submit" class="grid-common-btn" (click)="homeSearchSubmit()" #searchButton>
                      <i class="fa fa-refresh"></i>
                      <span class="grid-common-text">Refresh</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="wraper-body-right"></div>
  </div>
</div>
<div class="wraper-main-section" >
  <company *ngIf="user && isCompanyList" [user]=user [roleFeaturePermissions]="roleFeaturePermissions" [companyList]="companyList" [isHomeSearch]="isHomeSearch"></company>
  <div class="global-card-section">
  </div>
  <div class="margin-all-area" *ngIf="isContactList">
    <app-search-result-common *ngIf="user" [user]="user" [SendResultToNewObj]="SendResultToNewObj" [selectedUserId]="user?.cLPUserID" [contactsMapData]="contactList" [isGetContactList]="false" [startDate]="" [endDate]="" [isHomeSearch]="isHomeSearch"></app-search-result-common>
  </div>
  <div *ngIf="isLeadList">
    <lead-list *ngIf="user" [user]=user [leadList]="leadList" [isHomeSearch]="isHomeSearch"></lead-list>
  </div>
  <div *ngIf="isDocumentList">
    <app-my-documents *ngIf="user" [documentList]="documentList" [isHomeSearch]="isHomeSearch"></app-my-documents>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
