<div class="create-email" #scrollIdTarget [@filterAnimation]="showAnimation">
    <fieldset class="p-0">
        <!--<legend>Create Email</legend> _localService.hideCommonComponentEmit('email'); _localService.showCommonComp = ''"-->
        <div class="outbound-module m-0">
            <form class="form-horizontal required-section" [formGroup]="emailForm">
                <div class="modal-header user-body-header" *ngIf="!isForActivities">
                    <h5 class="modal-title white-contact text-left d-flex" id="exampleModalLabel">
                        <i class="fas fa-envelope-open-text white-contact mr-2"></i>
                        {{isFromLead ?  'Outbound Lead Email' : 'Outbound Email'}}
                    </h5>
                    <div class="display-row">
                        <!--<button type="button" class="btn btn-primary" (click)="goToLink('help', '69')">Help</button>-->
                        <div class="button-wrapper">
                            <button class="icon-btn mt-0" (click)="showAttachedDiv = !showAttachedDiv" [disabled]="isCancelEmail" type="button">
                                <i class="fas fa-paperclip mr-0"></i>
                                <span class="btn-text">Attachment</span>
                            </button>
                        </div>
                        <div class="button-wrapper">
                            <button class="icon-btn mt-0" *ngIf="isViewMode && sendEmailFrm.emailTemplateID.value > 0" (click)="previewEmail();" type="button">
                                <i class="fa fa-search mr-0"></i>
                                <span class="btn-text">Search</span>
                            </button>
                        </div>
                        <div class="button-wrapper">
                            <button class="icon-btn mt-0" *ngIf="isShowCLoseBtn" (click)="hideSendMail();" type="button">
                                <i aria-hidden="true" class="fa fa-close mr-0"></i>
                                <span class="btn-text">Cancel</span>
                            </button>
                        </div>
                    </div>
                </div>
                <!--<hr />-->
                <!--Mail merge error here-->
                <div class="warning-alert" *ngIf="mailMergeWarning != ''">
                    <span [innerHTML]="mailMergeWarning | safeHtml"></span>
                </div>
                <div class="warning-alert" *ngIf="isMailMergeError && noteResult && noteResult.messageBool">
                    <span [innerHTML]="noteResult.messageString"></span>
                </div>
                <!-------------------------->
                <div class="">
                    <div class="">
                        <table cellspacing=0 class="email-table">
                            <tr *ngIf="!isViewMode">
                                <th class="sendemail-th">
                                    <div class="sendemail-div">
                                        To
                                    </div>
                                </th>
                                <td class="sendemail-td">
                                    <div class="email-form-group align-div">
                                        <label class="k-label ml-0" [for]="contact">Contact</label>
                                        <input #contact type="radio" value="contact" name="type" formControlName="type" (change)="changeToType($event)" kendoRadioButton />
                                        <!--<label *ngIf="!isFromLead" class="k-label ml-0" [for]="contactAllEmails">&nbsp;Contact (All Emails)</label>
                                        <input *ngIf="!isFromLead" #contact type="radio" value="contactAllEmails" name="type" formControlName="type" (change)="changeToType($event)" kendoRadioButton />-->
                                        <label class="k-label" [for]="user">&nbsp;User</label>
                                        <input #user type="radio" value="user" name="type" formControlName="type" (change)="changeToType($event)" kendoRadioButton />
                                        <label class="k-label" [for]="other">&nbsp;Other</label>
                                        <input #other type="radio" value="other" name="type" formControlName="type" (change)="changeToType($event)" kendoRadioButton />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="sendemail-th">
                                    <div class="sendemail-div">
                                        To
                                    </div>
                                </th>
                                <td class="sendemail-td" *ngIf="!isViewMode">
                                    <div class="email-form-group">
                                        <div class="to-title" *ngIf="selectedType == 'contact'">
                                            <span *ngIf="contactFields">{{contactFields?.email?.fieldValue}} {{contactFields?.firstName?.fieldValue == "" && contactFields?.lastName?.fieldValue == "" ? "" :"(" }}{{contactFields?.firstName?.fieldValue}} {{contactFields?.lastName?.fieldValue}}{{contactFields?.firstName?.fieldValue == "" && contactFields?.lastName?.fieldValue == "" ? "" :")" }}</span>
                                            <br />
                                            <div *ngFor="let number of strEmailMsg" [innerHtml]="number | safeHtml"></div>
                                        </div>
                                        <div class="to-title" *ngIf="selectedType == 'contactAllEmails'">
                                            <span *ngIf="contactFields">{{contactFields?.email?.fieldValue}} ({{contactFields?.firstName?.fieldValue}} {{contactFields?.lastName?.fieldValue}})</span>
                                        </div>
                                        <div class="generic-width-per" *ngIf="selectedType == 'user'">
                                            <select class="form-control" (change)="selectUserType($event.target.value)" formControlName="to">
                                                <option value="-1" [selected]="toUsersArr?.length <= 0">-Select-</option>
                                                <option *ngFor="let user of toUsersArr" [value]="user.key">{{user.value}}</option>
                                            </select>
                                        </div>
                                        <div *ngIf="selectedType == 'other'" class="generic-width-per">
                                            <div class="" [ngClass]="{'has-error': sendEmailFrm.to.errors && (sendEmailFrm.to.touched ||  sendEmailFrm.to.dirty)}">
                                                <input type="text" class="form-control" formControlName="to" />
                                            </div>
                                            <div class="search-query-error generic-text-align" *ngIf="sendEmailFrm.to.errors && ( sendEmailFrm.to.touched ||  sendEmailFrm.to.dirty)">
                                                <div *ngIf="sendEmailFrm.to.errors.required || sendEmailFrm.to.errors.invalidEmailsOrPhones">Please enter comma delimited list of email addresses to send this email to.</div>
                                                <!-- <div *ngIf="sendEmailFrm.controls['to'].hasError('invalidEmailsOrPhones')">Please enter comma delimited list of email addresses to send this email to.</div>-->
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td *ngIf="isViewMode && selectedType == 'user'">{{toUser}} </td>
                                <td *ngIf="isViewMode && selectedType != 'user'">
                                    <div>{{emailForm?.value?.to}}</div>
                                <div *ngFor="let number of strEmailMsg" [innerHtml]="number | safeHtml"></div>
                                </td>
                            </tr>
                            <tr>
                                <th class="sendemail-th">
                                    <div class="sendemail-div">
                                        From
                                    </div>
                                </th>
                                <td class="sendemail-td" *ngIf="!isViewMode">
                                    <div class="email-form-group">
                                        <div class="full-width-container" *ngIf="loggedUser?.userRole>2">
                                            <div class="generic-width-per" *ngIf="emailDropDownsResponse && !emailDropDownsResponse.isMultipleFromAddress">
                                                <!--<input type="text" class="form-control" formControlName="from" readonly />-->
                                                <span>{{emailForm.controls.from.value}}</span>
                                                <!--<input type="text" class="form-control" value="{{getUserEmail(emailForm.controls.from.value)}}" readonly />-->
                                            </div>
                                            <div class="generic-width-per" *ngIf="emailDropDownsResponse && emailDropDownsResponse.isMultipleFromAddress">
                                                <select class="form-control" formControlName="from">
                                                    <option disabled value="-1" [selected]="users?.length <= 0">-Select-</option>
                                                    <option *ngFor="let user of users" [value]="user.key">{{user.value}}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="to-title" *ngIf="loggedUser?.userRole<=2">
                                            <span>{{emailDropDownsResponse?.isMultipleFromAddress ? getUserEmail(emailForm.controls.from.value) : emailForm.controls.from.value}}</span>
                                        </div>
                                    </div>

                                </td>
                                <td *ngIf="isViewMode">{{emailDropDownsResponse.isMultipleFromAddress ? getUserEmail(emailForm.controls.from.value) : emailForm.controls.from.value}}</td>
                            </tr>
                            <tr>
                                <th class="sendemail-th">
                                    <div class="sendemail-div">
                                        CC
                                    </div>
                                </th>
                                <td class="sendemail-td" *ngIf="!isViewMode">
                                    <div class="email-form-group" [ngClass]="{'has-error': sendEmailFrm.cc.errors}">
                                        <input type="text" class="form-control" formControlName="cc" />
                                    </div>
                                    <div class="search-query-error" *ngIf="sendEmailFrm.cc.errors">
                                        <div *ngIf="sendEmailFrm.cc.errors">Invalid Email Syntax.</div>
                                    </div>
                                </td>
                                <td *ngIf="isViewMode">{{emailForm?.value?.cc}}</td>
                            </tr>
                            <tr>
                                <th class="sendemail-th">
                                    <div class="sendemail-div">
                                        Bcc
                                    </div>
                                </th>
                                <td class="sendemail-td" *ngIf="!isViewMode">
                                    <div class="email-form-group" [ngClass]="{'has-error': sendEmailFrm.bcc.errors}">
                                        <input type="text" class="form-control" formControlName="bcc" />
                                    </div>
                                    <div class="search-query-error" *ngIf="sendEmailFrm.bcc.errors">
                                        <div *ngIf="sendEmailFrm.bcc.errors">Invalid Email Syntax.</div>
                                    </div>
                                </td>
                                <td *ngIf="isViewMode">{{emailForm?.value?.bcc}} </td>
                            </tr>
                            <tr>
                                <th class="sendemail-th">
                                    <div class="sendemail-div">
                                        Subject
                                    </div>
                                </th>
                                <td class="sendemail-td" *ngIf="!isViewMode">
                                    <div *ngIf="showEmoji">
                                        <a class="contact-cursor-pointer" (click)="addText('👍')">👍</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('✌️')">✌️</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('👋')">👋</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('🙂')">🙂</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('😂')">😂</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('🤔')">🤔</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('😳')">😳</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('💪')">💪</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('🤞')">🤞</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('🤝')">🤝</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('👀')">👀</a><br>
                                    </div>
                                    <div class="email-form-group">
                                        <input type="text" class="form-control" formControlName="subject" />
                                    </div>
                                </td>
                                <td *ngIf="isViewMode">{{emailForm?.value?.subject}} </td>
                            </tr>
                            <tr>
                                <th class="sendemail-th" *ngIf="!isLeadInvoice" style="vertical-align:top">
                                    <div class="sendemail-div">
                                        Attach Mail Merge Document
                                    </div>
                                </th>
                                <th class="sendemail-th" *ngIf="isLeadInvoice">
                                    <div class="sendemail-div">
                                        Attached Invoice
                                    </div>
                                </th>
                                <td *ngIf="isLeadInvoice">
                                    <div>
                                        <a (click)="goToLink('invoice',0)">{{invoice?.invoiceNumber}} :{{ invoice?.invoiceShortDesc}}</a>
                                    </div>
                                </td>
                                <td *ngIf="isLeadInvoiceList">
                                    <a class="contact-cursor-pointer" data-toggle="modal" data-target="#leadInvoice" data-backdrop="static" data-keyboard="false" (click)="goToLink('invoice',0)">{{invoice?.invoiceNumber}} :{{ invoice?.invoiceShortDesc}} </a>
                                </td>
                                <td class="sendemail-td">
                                    <div class="email-form-group" *ngIf="!isViewMode">
                                        <select class="form-control" formControlName="mailMergeTemplateID" (change)="changeMailMerge($event);">
                                            <option value="0">- Select one -</option>
                                            <option *ngFor="let mmTemplate of mailMergeTemplates" [value]="mmTemplate.mailMergeTemplateID">{{mmTemplate.templateName}}</option>
                                        </select>
                                    </div>
                                    <span *ngIf="isViewMode"> {{getDropDownValue(emailForm?.value?.mailMergeTemplateID,'templateID')}}</span>
                                    <div *ngIf="showAttachedDiv">
                                        <div class="flex-panel">
                                            <div class="email-form-group">
                                                <!--<span>Attached Files</span>-->
                                                <div *ngIf="attachedFiles" class="messageInfo">{{ attachedFiles.length > 0 ? attachedFiles.length + ' file(s) attached' : 'No files attached.' }}</div><br />
                                                <a *ngFor="let file of attachedFiles">
                                                    <span (click)="downloadDocuments(file)" class="contact-cursor-pointer">{{file?.documentName}}</span><br />
                                                </a>
                                            </div>
                                            <!--File uploader-->
                                            <div class="file-attach-uploader">
                                                <div class="attach-alignment mb-2">
                                                    <div class="email-form-group">
                                                        <div class="file-uploader mb-2" *ngIf="showFileUploader">
                                                            <div class="border">
                                                                <!--<angular-file-uploader [config]="afuConfig" [resetUpload]=resetUpload (ApiResponse)="fileUpload($event)"></angular-file-uploader>-->
                                                                <div class="uploader-panel">
                                                                    <kendo-upload [saveUrl]="uploadSaveUrl"
                                                                                  [restrictions]="uploadRestrictions"
                                                                                  (success)="fileUploadSuccess($event)"
                                                                                  (error)="fileUploadError($event)"
                                                                                  [saveHeaders]="fileUploadHeaders"
                                                                                  [autoUpload]="false">
                                                                    </kendo-upload>
                                                                    <span>&nbsp;&nbsp;(jpg,png,pdf,docx,txt,gif,jpeg,xlsx,pptx,bmp,tiff) Size Limit: 3MB</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--File Uploader-->
                                            <div class="attach-alignment">
                                                <div class="email-form-group mb-3">
                                                    <!--<span></span>-->
                                                    <div class="flex-left">
                                                        <input type="text" placeholder="file1,file2" id="idSearchAttached" class="form-control" [(ngModel)]="searchValue" [ngModelOptions]="{standalone: true}" />
                                                    </div>
                                                    <div class="flex-right">
                                                        <button type="button" class="btn btn-primary btn-sm" (click)="searchDocumentsList();"><!--<i class="fas fa-search"></i>-->&nbsp;Search</button>
                                                        <button type="button" class="btn btn-primary btn-sm" (click)="getContactDocumentsListByOwner();"><!--<i class="fas fa-search"></i>-->&nbsp;{{isFromLead ? 'View Lead Files' : 'View Contact Files'}}</button>
                                                        <button type="button" class="btn btn-primary btn-sm" (click)="showFileUploader = !showFileUploader"><!--<i class="fas fa-sync"></i>-->&nbsp;Upload New</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--Attached file grid-->
                                            <div class="email-form-group" *ngIf="documents && documents.length <= 0">
                                                <!--<span></span>-->
                                                <div class="messageInfo">No Contact file(s) found.</div>
                                            </div>
                                            <div class="attach-alignment" *ngIf="documents && documents.length > 0">
                                                <!--<span></span>-->
                                                <div class="attachFile-grid">
                                                    <kendo-grid #gridAttach id="attachGridId" [kendoGridBinding]="documents" [sort]="attachFileSort" [skip]="skip" [pageSize]="pageSize" [pageable]="{ buttonCount: 0, info: true }">
                                                        <kendo-grid-column [width]="2">
                                                            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                                                                <ng-container [ngSwitch]="dataItem.documentType">
                                                                    <ng-container *ngSwitchCase="1">
                                                                        <img src="../../../assets/iconppt.svg" />
                                                                    </ng-container>
                                                                    <ng-container *ngSwitchCase="3">
                                                                        <img src="../../../assets/iconhtml.svg" />
                                                                    </ng-container>
                                                                    <ng-container *ngSwitchCase="4">
                                                                        <img src="../../../assets/icontext.svg" />
                                                                    </ng-container>
                                                                    <ng-container *ngSwitchCase="6">
                                                                        <img src="../../../assets/iconpdf.svg" />
                                                                    </ng-container>
                                                                    <ng-container *ngSwitchCase="7">
                                                                        <img src="../../../assets/iconppt.svg" />
                                                                    </ng-container>
                                                                    <ng-container *ngSwitchCase="[8, 9, 10, 11].includes(dataItem.documentType) ? dataItem.documentType : !dataItem.documentType">
                                                                        <img src="../../../assets/iconimage.svg" />
                                                                    </ng-container>
                                                                    <ng-container *ngSwitchCase="13">
                                                                        <img src="../../../assets/iconexcel.svg" />
                                                                    </ng-container>
                                                                    <ng-container *ngSwitchDefault>
                                                                        <img src="../../../assets/icontext.svg" />
                                                                    </ng-container>
                                                                </ng-container>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column title="{{ documentGridTitle }}" [width]="20" [class.selected-row]="isSelected">
                                                            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                                                                {{dataItem.documentName}}
                                                                &nbsp;<i class="fa fa-check" *ngIf="dataItem.isSelected"></i>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                        <kendo-grid-column title="" [width]="4">
                                                            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                                                                <button type="button" class="btn btn-sm btn-success" *ngIf="!dataItem.isSelected" (click)="attachDetachFile('add', dataItem, rowIndex)"><!--<i class="fas fa-paperclip"></i>--> Attach</button>
                                                                <button type="button" class="btn btn-sm btn-danger" *ngIf="dataItem.isSelected" (click)="attachDetachFile('remove', dataItem, rowIndex)"><!--<i class="fas fa-minus-circle"></i>--> Detach</button>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                    </kendo-grid>
                                                </div>
                                            </div>
                                            <!--Attached file grid-->
                                        </div>
                                    </div>
                                </td>
                                <!--<td *ngIf="isViewMode && showAttachedDiv">
                                  <div class="messageInfo">{{attachedFiles?.length}} file(s) attached.</div>
                                  <a *ngFor="let file of attachedFiles" (click)="downloadDocuments(file)" class="contact-cursor-pointer"> {{file?.documentName}}</a>
                                </td>-->
                            </tr>
                            <tr>
                                <th class="sendemail-th">
                                    <div class="sendemail-div">
                                        Email Template
                                    </div>
                                </th>
                                <td class="sendemail-td" *ngIf="!isViewMode">
                                    <div class="email-form-group">
                                        <select class="form-control" formControlName="emailTemplateID" (change)="loadEmailTemplate()">
                                            <option value="0"> -Use plain text- </option>
                                            <option *ngFor="let eTemplate of emailTemplates" [value]="eTemplate.emailTemplateID">{{eTemplate.templateName}}</option>
                                        </select>
                                    </div>
                                </td>
                                <td *ngIf="isViewMode">{{getDropDownValue(emailForm?.value?.emailTemplateID,'emailTemplate')}}</td>
                            </tr>
                            <tr *ngIf="emailSnippets && emailSnippets?.length > 0">
                                <th class="sendemail-th">
                                    <div class="sendemail-div">
                                        Email Snippet
                                    </div>
                                </th>
                                <td class="sendemail-td" *ngIf="!isViewMode">
                                    <div class="email-form-group">
                                        <select class="form-control" formControlName="emailSnippetID" (change)="changeEmailSnippet($event);">
                                            <option value="0">- Select one -</option>
                                            <option *ngFor="let eSnippet of emailSnippets" [value]="eSnippet.emailSnippetID">{{eSnippet?.snippetTitle}}</option>
                                        </select>
                                    </div>
                                </td>
                                <td *ngIf="isViewMode">{{getDropDownValue(emailForm?.value?.emailSnippetID,'emailSnippet')}}</td>
                            </tr>
                            <tr>
                                <th class="sendemail-th p-0">
                                    <div class="sendemail-div warning-alert">
                                        Important Note
                                    </div>
                                </th>
                                <td class="sendemail-td">
                                    <div class="email-form-group">
                                        <span class="imp-sub-notes">No HTML tags permitted | <a href="https://www.salesoptima.com/support/email-placeholder-list" target="_blank" (click)="goToLink('mailPlaceholderList')">Email Merge Placeholder list</a></span>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="selectedType == 'user' || selectedType == 'other'">
                                <th class="sendemail-th">
                                    <div class="sendemail-div">
                                        Regarding Link
                                    </div>
                                </th>
                                <td class="sendemail-td" style="background:none">
                                    <div class="email-form-group">
                                        <input #regardingLink type="checkbox" name="regardingLink" formControlName="regardingLink" kendoCheckBox />
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="global-padding10 pb-0" *ngIf="!isForActivities">
                    <div class="" *ngIf="!isViewMode">
                        <div class="email-form-group mb-0" [hidden]="sendEmailFrm.emailSnippetID.value && sendEmailFrm.emailSnippetID.value != '' && !isFromLead">
                            <kendo-editor formControlName="body" class="full-width-container">
                                <kendo-toolbar>
                                    <kendo-toolbar-buttongroup>
                                        <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                        <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                        <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                                    </kendo-toolbar-buttongroup>
                                    <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                                    <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                                    <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                                </kendo-toolbar>
                            </kendo-editor>
                        </div>
                    </div>
                    <span *ngIf="isViewMode && sendEmailFrm.emailSnippetID.value == ''" [innerHTML]="emailForm?.value?.body | safeHtml"></span>
                </div>
                <!----------------------------->
                <div [innerHTML]="selectedEmailSnippet" *ngIf="isViewMode"></div>
                <div class="email-snippet-editor" *ngIf="(sendEmailFrm.emailSnippetID.value && sendEmailFrm.emailSnippetID.value != '') && !isViewMode && !isFromLead">
                    <kendo-editor [value]="selectedEmailSnippet" (valueChange)="changeValueKendoEditor($event)" class="email-editor-height">
                        <kendo-toolbar>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorItalicButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorUnderlineButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorAlignLeftButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAlignCenterButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAlignRightButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorAlignJustifyButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-dropdownlist kendoEditorFormat></kendo-toolbar-dropdownlist>
                            <kendo-toolbar-dropdownlist kendoEditorFontSize></kendo-toolbar-dropdownlist>
                            <kendo-toolbar-dropdownlist kendoEditorFontFamily></kendo-toolbar-dropdownlist>
                            <kendo-toolbar-colorpicker kendoEditorForeColor></kendo-toolbar-colorpicker>
                            <kendo-toolbar-colorpicker kendoEditorBackColor view="gradient"></kendo-toolbar-colorpicker>
                            <kendo-toolbar-buttongroup>
                                <kendo-toolbar-button kendoEditorInsertUnorderedListButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorInsertOrderedListButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorIndentButton></kendo-toolbar-button>
                                <kendo-toolbar-button kendoEditorOutdentButton></kendo-toolbar-button>
                            </kendo-toolbar-buttongroup>
                            <kendo-toolbar-button kendoEditorInsertImageButton></kendo-toolbar-button>
                            <kendo-toolbar-button kendoEditorViewSourceButton></kendo-toolbar-button>
                        </kendo-toolbar>
                    </kendo-editor>
                </div>
                <div class="margin-left10 mt-2" *ngIf="!isForActivities && !isViewMode">
                    <small class="text-success"><b>Note:</b> The email will be saved in this contact's history.</small>
                </div>
                <div class="global-padding10 mt-3" *ngIf="!isForActivities">
                    <span class="flex-align-panel margin-left10" *ngIf="!showDatePicker && !isViewMode">
                        <span class="text-success mr-2">{{ getCurrentDate | date:dateFormat }} &nbsp;{{ getCurrentDate | date:'shortTime'}} </span>
                        <a class="btn btn-primary btn-schedule" (click)="showDatePicker = !showDatePicker">Schedule for Later</a>
                    </span>
                    <span class="flex-align-panel margin-left10 mb-2" *ngIf="showDatePicker && !isViewMode">
                        <kendo-datetimepicker [formatPlaceholder]="{ year: 'Year', month: 'Month', day: 'Day', hour: 'Hour', minute: 'Minute'}" placeholder="Choose date..." [(ngModel)]="datePickerValue" [ngModelOptions]="{standalone: true}" class="select-option"></kendo-datetimepicker>
                        <a class="btn btn-cancel" href="javascript:void(0)" (click)="showDatePicker = !showDatePicker">Cancel</a>
                    </span>
                </div>
                <!--Schedule later form if mail has mail merge attached.-->
                <div class="global-padding10 mt-3" *ngIf="isViewMode && isMailMergeTemplateAdded">
                    <span class="flex-align-panel margin-left10">

                        <!--<span class="text-success mr-2">{{ getCurrentDate | date:dateFormat }} &nbsp;{{ getCurrentDate | date:'shortTime': timeZoneResult}} </span>-->
                        <!--<a class="btn btn-primary btn-schedule" (click)="showDatePicker = !showDatePicker">Schedule for Later</a>-->
                    </span>
                    <span class="flex-align-panel margin-left10 mb-2">
                        <kendo-datetimepicker [formatPlaceholder]="{ year: 'Year', month: 'Month', day: 'Day', hour: 'Hour', minute: 'Minute'}" placeholder="Choose date..." [(ngModel)]="datePickerValue" [ngModelOptions]="{standalone: true}" class="select-option"></kendo-datetimepicker>
                        <a class="btn btn-cancel" href="javascript:void(0)" (click)="showDatePicker = !showDatePicker">Cancel</a>
                    </span>
                </div>
                <div class="text-center global-padding10 mb-2">
                    <button type="submit" class="btn btn-primary mr-3" [disabled]="disableBtnOnSubmit" (click)="emailFormSubmit()" *ngIf="(isViewMode && !isCloseWindow) || isForActivities">
                        {{ submitBtnTxt }}
                    </button>
                    <a type="button" href="{{emailToLink}}" class="btn btn-primary" *ngIf="isViewMode && isFromLead && !isForActivities && !isCloseWindow && !isMailMergeTemplateAdded">Send via my email program</a>
                    <button type="button" class="btn btn-primary" (click)="cancelEmail()" *ngIf="(isViewMode && isCancelEmail) && !isForActivities">Cancel</button>
                    <button type="button" class="btn btn-primary" (click)="cancelEmail()" *ngIf="isForActivities">Cancel</button>
                    <button type="button" class="btn btn-primary" *ngIf="isForActivities" (click)="deleteNote()">Delete <app-loader></app-loader></button>
                    <button type="button" class="btn btn-danger float-none" (click)="hideSendMail();" *ngIf="isCloseWindow && isViewMode">Close Window</button>
                    <button type="submit" class="btn btn-primary mb-2" (click)="nextButton();" *ngIf="!isViewMode && !isForActivities">Next <app-loader></app-loader></button>
                </div>
            </form>
        </div>
    </fieldset>
</div>



