import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CLPUser } from '../../../models/clpuser.model';
import { CMClubSiteConfig, CMClubSiteConfigListResponse, CMSite, CMSiteListResponse } from '../../../models/cm-club-service.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { LiveConnectContactLoadResponse } from '../../../models/live-connect.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { CMClubService } from '../../../services/cm-club.service';
import { GlobalService } from '../../../services/global.service';
import { LiveConnectService } from '../../../services/live-connect.service';
import { isNullOrUndefined, UtilityService } from '../../../services/shared/utility.service';
import { CtComponent } from '../ct/ct.component';

@Component({
  selector: 'lc-engagedash',
  templateUrl: './lc-engagedash.component.html',
  styleUrl: './lc-engagedash.component.css'
})
export class LcEngagedashComponent {
    showSpinner: boolean = false;
    encryptedUser: string = "";
    @Input() user: CLPUser;
    /* @Input() clpCompanyData: ClpCompany;*/
    @ViewChild(CtComponent) ct : CtComponent
    @Output() loadMailMergeTemplate = new EventEmitter();
    sites: CMSite[];
    curSite: CMSite = <CMSite>{};
    MailMergeTemplateID: number =0;
    curSiteSub: Subscription;
    customID1: any = 0;
    customID2: any = 0;
    customID3: any = 0;
    customID4: any = 0;
    customID5: any = 0;
    liveConnectContactViewResponse: LiveConnectContactLoadResponse;
    @Input() contactId: number = 0;
     clpCompanyData: ClpCompany;
    clubSiteConfig: CMClubSiteConfig = <CMClubSiteConfig>{}
    private _utilityService: UtilityService;
    constructor(private _accountSetupService: AccountSetupService, private _globalService: GlobalService, private _router: Router,
        private clubService: CMClubService, private _route: ActivatedRoute  ,  private _liveConnectSrvc: LiveConnectService
    ) { }
    ngOnInit() {
       
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                if (this.user) {
                  
                    this.loadInit();

                    
                   
                }
                else {
                    this._router.navigate(['/login']);
                }
            }
            else {
                this._router.navigate(['/login']);
            }
        })
    }

    async loadInit()
    {
       await this.getCompanyData()
        if (this.clpCompanyData) {
            await this.clubSiteConfigSearch();
            await this.getContactAPISetup();
            await this.getEngage();
        }
    }
    
    async getContactAPISetup() {
        await this._liveConnectSrvc.ContactAPISetup(this.encryptedUser, this.contactId, 0, 0, 0)
            .then(async (result: any) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.liveConnectContactViewResponse = UtilityService.clone(result);
                       
                       
                    }
          
                }
                this.showSpinner = false;

            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("handle-reply.onOptinVerbal", err.message, null, 'cLPUserID ' + this.user?.cLPUserID + 'contactID ' + this.contactId );
                this._utilityService.handleErrorResponse(err);
            });

    }

    async getCompanyData() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.clpCompanyData = response?.company;
                    /*this.isUseImage = response.company.useImage;*/
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("archive-liveconnect.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async clubSiteConfigSearch() {
       

        await this.clubService.clubSiteConfigSearch(this.encryptedUser, 0, 0, 0, this.clpCompanyData?.cLPCompanyID, true).
                then(async (result: CMClubSiteConfigListResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.clubSiteConfig = response?.clubSiteConfigs[0];
             
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("site-fields.clubConfigGet", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
    }

    async getEngage() {
     
        this.showSpinner = true
        await this.clubService.siteSearch(this.encryptedUser, 0, 0, this.liveConnectContactViewResponse.contact.class1Code, this.liveConnectContactViewResponse.contact.cLPCompanyID, true).
                then(async (result: CMSiteListResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.sites = response?.sites;
                        //this.cloneOpts = []
                        //this.cloneOpts = [{ display: "-All Sites-", value: -1 }, { display: "-Cancel Clone-", value: 0 }]
                        this.sites.forEach((item) => {
                            if (item?.siteID != this.curSite?.siteID) {
                                let obj = {
                                    display: item?.siteName,
                                    value: item?.siteID
                                }
                                //this.cloneOpts.push(obj)
                            }
                        })
                        this.customID1 = this.sites[0].engDashCustomMMT1D1;
                        this.customID2 = this.sites[0].engDashCustomMMT1D2;
                        this.customID3 = this.sites[0].engDashCustomMMT1D3;
                        this.customID4 = this.sites[0].engDashCustomMMT1D4;
                        this.customID5 = this.sites[0].engDashCustomMMT1D5;
                        //this.customID6 = +this.sites[0].engDashCustomMMT1D6;
                        //this.customID7 = +this.sites[0].engDashCustomMMT1D7;
                        //this.customID8 = +this.sites[0].engDashCustomMMT1D8;
                        //this.customID9 = +this.sites[0].engDashCustomMMT1D9;
                        //this.customID10 = +this.sites[0].engDashCustomMMT1D10;
                    }
                    this.showSpinner = false
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false
                    this._globalService.error("lm-engage-dash.getEngage", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
    }

    LoadMM( ButtonNumber :number)
    {
        switch (ButtonNumber)
        {
            case 1:
                this.MailMergeTemplateID = this.customID1;
                break;
            case 2:
                this.MailMergeTemplateID = this.customID2;
                break;
            case 3:
                this.MailMergeTemplateID = this.customID3;
                break;
            case 4:
                this.MailMergeTemplateID = this.customID4;
                break;
            case 5:
                this.MailMergeTemplateID = this.customID5;
                break;
        }
        this.loadMailMergeTemplate.emit(this.MailMergeTemplateID);
    }
    }
    



