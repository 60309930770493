<div class="wraper-main-section mb-3">    
    <div class="global-card-section">
        <div class="global-header-section">
            <div class="svg-icon-panel">Quick Search</div>
        </div>
        <form *ngIf="searchQueriesForm" [formGroup]="searchQueriesForm" (ngSubmit)="submitSearch()">
            <div class="global-body-section">
                <div class="global-padding10">
                    <div class="quick-list">
                        <div class="quick-label"><span>Search field</span></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddField">
                                <option *ngFor="let item of ddField" [value]="item?.value">{{item?.displayValue}}</option>
                                <option value="9">Unique Identifier</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list">
                        <div class="quick-label"><span>Search value</span></div>
                        <div class="quick-input">
                            <input class="form-control" formControlName="txtSearch" />
                        </div>
                    </div>
                    <div class="quick-list">
                        <div class="quick-label"></div>
                        <div class="quick-input">
                            <button type="submit" class="btn btn-primary">Search</button>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="netRevenueObj?.searchOperators?.length > 0">
                        <div class="quick-label">
                            <span>{{netRevenueObj?.displayValue}}</span>
                        </div>
                        <div class="quick-input display-row">
                            <select class="form-control" formControlName="ddRevenueOperator">
                                <option *ngFor="let item of netRevenueObj?.searchOperators" [value]="item?.value">{{item?.value}}</option>
                            </select>
                            <input class="form-control margin-left10" formControlName="txtRevenue" />
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="teamObj?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>Gross Revenue</span>
                        </div>
                        <div class="quick-input display-row">
                            <select class="form-control" formControlName="ddVolumeOperator">
                                <option value="&gt;">&gt;</option>
                                <option value="&lt;">&lt;</option>
                                <option value="&gt;=">&gt;=</option>
                                <option value="&lt;=">&lt;=</option>
                            </select>
                            <input class="form-control margin-left10" style="min-height:35px;" formControlName="txtVolume" />
                        </div>
                    </div>

                    <div class="quick-list" *ngIf="customMoney1Obj?.searchOperators?.length > 0">
                        <div class="quick-label">
                            <span>{{customMoney1Obj?.displayValue}}</span>
                        </div>
                        <div class="quick-input  display-row">
                            <select class="form-control" formControlName="ddCustomMoney1Operator">
                                <option *ngFor="let item of customMoney1Obj?.searchOperators" [value]="item?.value">{{item?.value}}</option>
                            </select>
                            <input class="form-control margin-left10" formControlName="txtCustomMoney1" />
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="customMoney2Obj?.searchOperators?.length > 0">
                        <div class="quick-label">
                            <span>{{customMoney2Obj?.displayValue}}</span>
                        </div>
                        <div class="quick-input  display-row">
                            <select class="form-control" formControlName="ddCustomMoney2Operator">
                                <option *ngFor="let item of customMoney2Obj?.searchOperators" [value]="item?.value">{{item?.value}}</option>
                            </select>
                            <input class="form-control margin-left10" formControlName="txtCustomMoney2" />
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="customMoney3Obj?.searchOperators?.length > 0">
                        <div class="quick-label">
                            <span>{{customMoney3Obj?.displayValue}}</span>
                        </div>
                        <div class="quick-input  display-row">
                            <select class="form-control" formControlName="ddCustomMoney3Operator">
                                <option *ngFor="let item of customMoney3Obj?.searchOperators" [value]="item?.value">{{item?.value}}</option>
                            </select>
                            <input class="form-control margin-left10" formControlName="txtCustomMoney3" />
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="customMoney4Obj?.searchOperators?.length > 0">
                        <div class="quick-label">
                            <span>{{customMoney4Obj?.displayValue}}</span>
                        </div>
                        <div class="quick-input  display-row">
                            <select class="form-control" formControlName="ddCustomMoney4Operator">
                                <option *ngFor="let item of customMoney4Obj?.searchOperators" [value]="item?.value">{{item?.value}}</option>
                            </select>
                            <input class="form-control margin-left10" formControlName="txtCustomMoney4" />
                        </div>
                    </div>

                    <!--<div class="quick-list" *ngIf="teamObj?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>Sub Total By</span>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddTeam">
                                <option value="0">-All-</option>
                                <option *ngFor="let item of teamObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>-->

                    <div class="quick-list" *ngIf="usersObj?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{usersObj?.displayValue}}</span>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddManager">
                                <option value="0">-All-</option>
                                <option *ngFor="let item of usersObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="quick-list" *ngIf="teamObj?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>Team</span>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddTeamCode">
                                <option value="0">-All-</option>
                                <option *ngFor="let item of teamObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="officeObj?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>Office</span>

                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddOfficeCode">
                                <option value="0">-All-</option>
                                <option *ngFor="let item of officeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="statusObj?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{statusObj?.displayValue}}</span>

                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddLeadStatusCode">
                                <option value="">-All-</option>
                                <option value="-1">-All Active-</option>
                                <option *ngFor="let item of statusObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                                <option value="0">None Selected</option>
                            </select>
                        </div>
                    </div>

                    <div class="quick-list" *ngIf="leadClass1Code?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{leadClass1Code?.displayValue}}</span>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddLeadClass1Code">
                                <option value="">-All-</option>
                                <option *ngFor="let item of leadClass1Code?.itemData" [value]="item?.value">{{item?.display}}</option>
                                <option value="0">None Selected</option>

                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="leadClass2Code?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{leadClass2Code?.displayValue}}</span>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddLeadClass2Code">
                                <option value="">-All-</option>
                                <option *ngFor="let item of leadClass2Code?.itemData" [value]="item?.value">{{item?.display}}</option>
                                <option value="0">None Selected</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="leadClass3Code?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{leadClass3Code?.displayValue}}</span>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddLeadClass3Code">
                                <option value="">-All-</option>
                                <option *ngFor="let item of leadClass3Code?.itemData" [value]="item?.value">{{item?.display}}</option>
                                <option value="0">None Selected</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="leadClass4Code?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{leadClass4Code?.displayValue}}</span>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddLeadClass4Code">
                                <option value=''>-All-</option>
                                <option *ngFor="let item of leadClass4Code?.itemData" [value]="item?.value">{{item?.display}}</option>
                                <option value="0">None Selected</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="leadClass5Code?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{leadClass5Code?.displayValue}}</span>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddLeadClass5Code">
                                <option value=''>-All-</option>
                                <option *ngFor="let item of leadClass5Code?.itemData" [value]="item?.value">{{item?.display}}</option>
                                <option value="0">None Selected</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="leadClass6Code?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{leadClass6Code?.displayValue}}</span>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddLeadClass6Code">
                                <option value=''>-All-</option>
                                <option *ngFor="let item of leadClass6Code?.itemData" [value]="item?.value">{{item?.display}}</option>
                                <option value="0">None Selected</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="leadClass7Code?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{leadClass7Code?.displayValue}}</span>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddLeadClass7Code">
                                <option value=''>-All-</option>
                                <option *ngFor="let item of leadClass7Code?.itemData" [value]="item?.value">{{item?.display}}</option>
                                <option value="0">None Selected</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="leadClass8Code?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{leadClass8Code?.displayValue}}</span>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddLeadClass8Code">
                                <option value=''>-All-</option>
                                <option *ngFor="let item of leadClass8Code?.itemData" [value]="item?.value">{{item?.display}}</option>
                                <option value="0">None Selected</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="leadClass9Code?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{leadClass9Code?.displayValue}}</span>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddLeadClass9Code">
                                <option value=''>-All-</option>
                                <option *ngFor="let item of leadClass9Code?.itemData" [value]="item?.value">{{item?.display}}</option>
                                <option value="0">None Selected</option>
                            </select>
                        </div>
                    </div>


                    <div class="quick-list" *ngIf="locationOfficeCodeObj?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{locationOfficeCodeObj?.displayValue}}</span>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddLocationOfficeCode">
                                <option value=''>-All-</option>
                                <option *ngFor="let item of locationOfficeCodeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                                <option value="0">None Selected</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="importOfficeCodeObj?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{importOfficeCodeObj?.displayValue}}</span>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddImportOfficeCode">
                                <option value=''>-All-</option>
                                <option *ngFor="let item of importOfficeCodeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                                <option value="0">None Selected</option>
                            </select>
                        </div>
                    </div>


                    <div class="quick-list" *ngIf="check1Obj?.itemData?.length > 0">
                        <div class="quick-label"><span>{{check1Obj?.displayValue}}</span></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddCheck1">
                                <option *ngFor="let item of check1Obj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="check2Obj?.itemData?.length > 0">
                        <div class="quick-label"><span>{{check2Obj?.displayValue}}</span></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddCheck2">
                                <option *ngFor="let item of check2Obj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="check3Obj?.itemData?.length > 0">
                        <div class="quick-label"><span>{{check3Obj?.displayValue}}</span></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddCheck3">
                                <option *ngFor="let item of check3Obj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="check4Obj?.itemData?.length > 0">
                        <div class="quick-label"><span>{{check4Obj?.displayValue}}</span></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddCheck4">
                                <option *ngFor="let item of check4Obj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="check5Obj?.itemData?.length > 0">
                        <div class="quick-label"><span>{{check5Obj?.displayValue}}</span></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddCheck5">
                                <option *ngFor="let item of check5Obj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="check6Obj?.itemData?.length > 0">
                        <div class="quick-label"><span>{{check6Obj?.displayValue}}</span></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddCheck6">
                                <option *ngFor="let item of check6Obj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="quick-list">
                        <div class="quick-label"></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddOpTags">
                                <option value="any">contains any</option>
                                <option value="all">contains all</option>
                                <option value="not">does not contain</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="tagsObj?.itemData?.length > 0">
                        <div class="quick-label">{{tagsObj?.displayValue}}</div>
                        <div class="quick-input">
                            <select id="vender" multiple="multiple" formControlName="lbTags">
                                <option *ngFor="let item of tagsObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="quick-list">
                        <div class="quick-label">
                            <select class="form-control" formControlName="ddDateFilter">
                                <option value="dtCreated">Date Created</option>
                                <option value="dtModified">Date Modified</option>
                                <option value="dtStart">Start Date</option>
                                <option value="dtEnd">Close Date</option>
                                <option value="dtRevenue">Receive Rev Date</option>
                                <option value="dtCustom1">Custom Date</option>
                                <option value="dtCustom2">Custom Date</option>
                                <option value="dtCustom3">Custom Date</option>
                                <option value="LeadStatusHistory.dtChanged">Lead Status Changed</option>
                            </select>
                        </div>
                        <div class="quick-input">
                            <div>
                                <label>From</label>
                                <kendo-datepicker formControlName="txtdtStart"></kendo-datepicker>
                            </div>
                            <div class="mt-3">
                                <label>To</label>
                                <kendo-datepicker formControlName="txtdtEnd"></kendo-datepicker>
                            </div>
                        </div>
                    </div>
                    <div class="quick-list">
                        <div class="quick-label"></div>
                        <div class="quick-input">
                            <button type="submit" class="btn btn-primary">Search</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
