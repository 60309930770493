import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ContactListResponse } from '../../../models/contact.model';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { ContactSearchReq, SearchItem, SearchItemListResponse } from '../../../models/search.model';
import { GlobalService } from '../../../services/global.service';
import { SearchContactService } from '../../../services/Searchcontact.service';
import { ContactCommonSearchService } from '../../../services/shared/contact-common-search.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
    selector: 'app-contact-new-search',
    templateUrl: './contact-new-search.component.html',
    styleUrl: './contact-new-search.component.css'
})
export class ContactNewSearchComponent implements OnInit {
    searchQueriesForm: FormGroup
    encryptedUser: string = '';
    userResponse: UserResponse;
    user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    searchItemListResponse: SearchItemListResponse;
    searchItems: SearchItem[];
    ddField: SearchItem[];
    usersObj: SearchItem;
    class1CodeObj: SearchItem;
    class2CodeObj: SearchItem;
    class3CodeObj: SearchItem;
    check1Obj: SearchItem;
    check2Obj: SearchItem;
    check3Obj: SearchItem;
    check4Obj: SearchItem;
    check5Obj: SearchItem;
    outlookSyncObj: SearchItem;
    tagsObj: SearchItem;
    class4CodeObj: SearchItem;
    class5CodeObj: SearchItem;
    class6CodeObj: SearchItem;
    class7CodeObj: SearchItem;
    class8CodeObj: SearchItem;
    check6Obj: SearchItem;
    emailInfoStatus: SearchItem;
    emailPromoStatus: SearchItem;
    textOptStatus: SearchItem;
    isShowCompFields: boolean = false;
    coClass1CodeObj: SearchItem;
    coClass2CodeObj: SearchItem;
    coClass3CodeObj: SearchItem;
    coClass4CodeObj: SearchItem;
    coClass5CodeObj: SearchItem;
    coClass6CodeObj: SearchItem;
    coClass7CodeObj: SearchItem;
    coClass8CodeObj: SearchItem;
    coCheck1Obj: SearchItem;
    coCheck2Obj: SearchItem;
    coCheck3Obj: SearchItem;
    coCheck4Obj: SearchItem;
    companyTagsObj: SearchItem;
    isAdvancedSearch: boolean = false;
    isQuickSearch: boolean = true;
    isPowerSearch: boolean = false;
    metricObj: SearchItem;
    officeObj: SearchItem;
    teamObj: SearchItem;
    contactListResponse: ContactListResponse;
    contactSearchReq: ContactSearchReq;
    constructor(private fb: FormBuilder,
        private _globalService: GlobalService,
        private _router: Router,
        private _utilityService: UtilityService,
        private _localService: LocalService,
        private _searchContactService: SearchContactService,
        public _contactSearchService: ContactCommonSearchService,
    ) {

    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.searchQueriesForm = this.prepareSearchQueriesForm();
                        this.getContactSearch();
                    }
                    else {
                        this._router.navigate(['/unauthorized']);
                    }
                });
            }
            else {
                this._router.navigate(['/unauthorized']);
            }
        });
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-new-search.authenticateR", err.message, null,
                    'Feature: ' + eFeatures.None
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    prepareSearchQueriesForm() {
        return this.fb.group({
            ddSavedSearch: [''],
            ddStartsWith: [''],
            cbShowAddress: [false],
            ddField: ['0'],
            txtSearch: [''],
            ddClass1Code: ['0'],
            ddClass2Code: ['0'],
            ddClass3Code: ['0'],
            ddClass4Code: ['0'],
            ddClass5Code: ['0'],
            ddClass6Code: ['0'],
            ddClass7Code: ['0'],
            ddClass8Code: ['0'],
            ddCheck1: [''],
            ddCheck2: [''],
            ddCheck3: [''],
            ddCheck4: [''],
            ddCheck5: [''],
            ddCheck6: [''],
            ddOutlookSync: [''],
            ddCoClass1Code: ['0'],
            ddCoClass2Code: ['0'],
            ddCoClass3Code: ['0'],
            ddCoClass4Code: ['0'],
            ddCoClass5Code: ['0'],
            ddCoClass6Code: ['0'],
            ddCoClass7Code: ['0'],
            ddCoClass8Code: ['0'],
            ddCoCheck1: [''],
            ddCoCheck2: [''],
            ddCoCheck3: [''],
            ddCoCheck4: [''],
            ddOpCoTags: ['any'],
            ddOpTags: ['any'],
            ddMetricNames: [''],
            txtBuzzStart: [''],
            txtBuzzEnd: [''],
            ddTxtMsgStatus: [''],
            ddlEmailStatus: [''],
            ddlStatusEmailInfo: [''],
            ddlStatusEmailPromo: [''],
            txtDisplayName: [''],
            cbMyContacts: [false],
            cbMyPersonalContacts: [false],
            ddOpSearchText: [''],
            ddOpTeam: [''],
            ddOpUser: [''],
            ddOpClass1: [''],
            ddOpClass2: [''],
            ddOpClass3: [''],
            ddOpClass4: [''],
            ddOpClass5: [''],
            ddOpClass6: [''],
            ddOpClass7: [''],
            ddOpClass8: [''],
            ddTeam: ['0'],
            ddOffice: ['0'],
            ddUser: [this.user?.cLPUserID.toString()],
            lbTags: [[]],
            lbCoTags: [[]],
            cbLimit: [false],
        })
    }

    async getContactSearch() {
        await this._searchContactService.getSearchFields(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: SearchItemListResponse) => {
                if (result) {
                    this.searchItemListResponse = UtilityService.clone(result);
                    console.log(this.searchItemListResponse)
                    this.searchItems = this.searchItemListResponse.searchItems.sort((a, b) => a.displayValue.localeCompare(b.displayValue));
                    this.ddField = this.searchItems.filter((item) => item?.controlType == 't' || item?.controlType == 'dt');
                    this.usersObj = this.searchItems.filter((item) => item?.columnName == "ClpuserId")[0];
                    this.class1CodeObj = this.searchItems.filter((item) => item?.columnName == "Class1Code")[0];
                    this.class2CodeObj = this.searchItems.filter((item) => item?.columnName == "Class2Code")[0];
                    this.class3CodeObj = this.searchItems.filter((item) => item?.columnName == "Class3Code")[0];
                    this.class4CodeObj = this.searchItems.filter((item) => item?.columnName == "Class4Code")[0];
                    this.class5CodeObj = this.searchItems.filter((item) => item?.columnName == "Class5Code")[0];
                    this.class6CodeObj = this.searchItems.filter((item) => item?.columnName == "Class6Code")[0];
                    this.class7CodeObj = this.searchItems.filter((item) => item?.columnName == "Class7Code")[0];
                    this.class8CodeObj = this.searchItems.filter((item) => item?.columnName == "Class8Code")[0];
                    this.check1Obj = this.searchItems.filter((item) => item?.columnName == "Check1")[0];
                    this.check2Obj = this.searchItems.filter((item) => item?.columnName == "Check2")[0];
                    this.check3Obj = this.searchItems.filter((item) => item?.columnName == "Check3")[0];
                    this.check4Obj = this.searchItems.filter((item) => item?.columnName == "Check4")[0];
                    this.check5Obj = this.searchItems.filter((item) => item?.columnName == "Check5")[0];
                    this.check6Obj = this.searchItems.filter((item) => item?.columnName == "Check6")[0];
                    this.outlookSyncObj = this.searchItems.filter((item) => item?.columnName == "OutlookSync")[0];
                    this.tagsObj = this.searchItems.filter((item) => item?.columnName == "TagID")[0];
                    this.emailInfoStatus = this.searchItems.filter((item) => item?.columnName == "InfoSubStatus")[0];
                    this.emailPromoStatus = this.searchItems.filter((item) => item?.columnName == "PromoSubStatus")[0];
                    this.textOptStatus = this.searchItems.filter((item) => item?.columnName == "isOptOutTxtMsg")[0];
                    this.coClass1CodeObj = this.searchItems.filter((item) => item?.columnName == "CoClass1Code")[0];
                    this.coClass2CodeObj = this.searchItems.filter((item) => item?.columnName == "CoClass2Code")[0];
                    this.coClass3CodeObj = this.searchItems.filter((item) => item?.columnName == "CoClass3Code")[0];
                    this.coClass4CodeObj = this.searchItems.filter((item) => item?.columnName == "CoClass4Code")[0];
                    this.coClass5CodeObj = this.searchItems.filter((item) => item?.columnName == "CoClass5Code")[0];
                    this.coClass6CodeObj = this.searchItems.filter((item) => item?.columnName == "CoClass6Code")[0];
                    this.coClass7CodeObj = this.searchItems.filter((item) => item?.columnName == "CoClass7Code")[0];
                    this.coClass8CodeObj = this.searchItems.filter((item) => item?.columnName == "CoClass8Code")[0];
                    this.coCheck1Obj = this.searchItems.filter((item) => item?.columnName == "CoCheck1")[0];
                    this.coCheck2Obj = this.searchItems.filter((item) => item?.columnName == "CoCheck2")[0];
                    this.coCheck3Obj = this.searchItems.filter((item) => item?.columnName == "CoCheck3")[0];
                    this.coCheck4Obj = this.searchItems.filter((item) => item?.columnName == "CoCheck4")[0];
                    this.companyTagsObj = this.searchItems.filter((item) => item?.columnName == "COTagID")[0];
                    this.metricObj = this.searchItems.filter((item) => item?.columnName == "MetricName")[0];
                    this.officeObj = this.searchItems.filter((item) => item?.columnName == "OfficeCode")[0];
                    this.teamObj = this.searchItems.filter((item) => item?.columnName == "TeamCode")[0];
                    //this.savedQuery_Filter = this.searchItemListResponse.savedQueries;
                    //this.itemData_user_all = this.searchItems.filter(i => i.displayValue == "User").length > 0 ? this.searchItems.filter(i => i.displayValue == "User")[0].itemData : <Item[]>{};;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-new-search.getContactSearch", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    onShowQuickSearch() {
        this.isAdvancedSearch = false;
        this.isQuickSearch = true;
        this.isPowerSearch = false;
    }

    onShowAdvanceSearch() {
        this._contactSearchService.advanceSearchToggle(true);
    }

    onShowPowerSearch() {
        this.isAdvancedSearch = false;
        this.isQuickSearch = false;
        this.isPowerSearch = true;
    }

    async submitSearch() {
        await this.setContactSearchReq()
        this._searchContactService.contactSearchNew(this.encryptedUser, this.user?.cLPUserID, this.user.cLPCompanyID, this.contactSearchReq)
            .then(async (result: ContactListResponse) => {
                if (result) {
                    this.contactListResponse = UtilityService.clone(result);
                    this._contactSearchService.contactListChanged.emit(this.contactListResponse.contactList);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-common-search.getContactSearch", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    setContactSearchReq() {
        this.contactSearchReq = <ContactSearchReq>{}
        this.contactSearchReq.buzzEnd = this.searchQueriesForm.controls['txtBuzzEnd'].value
        this.contactSearchReq.buzzStart = this.searchQueriesForm.controls['txtBuzzStart'].value
        this.contactSearchReq.clpuserId = +this.searchQueriesForm.controls['ddUser'].value
        this.contactSearchReq.ddCheck1 = this.searchQueriesForm.controls['ddCheck1'].value
        this.contactSearchReq.ddCheck2 = this.searchQueriesForm.controls['ddCheck2'].value
        this.contactSearchReq.ddCheck3 = this.searchQueriesForm.controls['ddCheck3'].value
        this.contactSearchReq.ddCheck4 = this.searchQueriesForm.controls['ddCheck4'].value
        this.contactSearchReq.ddCheck5 = this.searchQueriesForm.controls['ddCheck5'].value
        this.contactSearchReq.ddCheck6 = this.searchQueriesForm.controls['ddCheck6'].value
        this.contactSearchReq.ddClass1Code = +this.searchQueriesForm.controls['ddClass1Code'].value
        this.contactSearchReq.ddClass2Code = +this.searchQueriesForm.controls['ddClass2Code'].value
        this.contactSearchReq.ddClass3Code = +this.searchQueriesForm.controls['ddClass3Code'].value
        this.contactSearchReq.ddClass4Code = +this.searchQueriesForm.controls['ddClass4Code'].value
        this.contactSearchReq.ddClass5Code = +this.searchQueriesForm.controls['ddClass5Code'].value
        this.contactSearchReq.ddClass6Code = +this.searchQueriesForm.controls['ddClass6Code'].value
        this.contactSearchReq.ddClass7Code = +this.searchQueriesForm.controls['ddClass7Code'].value
        this.contactSearchReq.ddClass8Code = +this.searchQueriesForm.controls['ddClass8Code'].value
        this.contactSearchReq.ddCoCheck1 = this.searchQueriesForm.controls['ddCoCheck1'].value
        this.contactSearchReq.ddCoCheck2 = this.searchQueriesForm.controls['ddCoCheck2'].value
        this.contactSearchReq.ddCoCheck3 = this.searchQueriesForm.controls['ddCoCheck3'].value
        this.contactSearchReq.ddCoCheck4 = this.searchQueriesForm.controls['ddCoCheck4'].value
        this.contactSearchReq.ddCoClass1Code = +this.searchQueriesForm.controls['ddCoClass1Code'].value
        this.contactSearchReq.ddCoClass2Code = +this.searchQueriesForm.controls['ddCoClass2Code'].value
        this.contactSearchReq.ddCoClass3Code = +this.searchQueriesForm.controls['ddCoClass3Code'].value
        this.contactSearchReq.ddCoClass4Code = +this.searchQueriesForm.controls['ddCoClass4Code'].value
        this.contactSearchReq.ddCoClass5Code = +this.searchQueriesForm.controls['ddCoClass5Code'].value
        this.contactSearchReq.ddCoClass6Code = +this.searchQueriesForm.controls['ddCoClass6Code'].value
        this.contactSearchReq.ddCoClass7Code = +this.searchQueriesForm.controls['ddCoClass7Code'].value
        this.contactSearchReq.ddCoClass8Code = +this.searchQueriesForm.controls['ddCoClass8Code'].value
        this.contactSearchReq.ddFieldSelectedValue = this.searchQueriesForm.controls['ddField'].value
        this.contactSearchReq.ddlStatusEmailInfo = +this.searchQueriesForm.controls['ddlStatusEmailInfo'].value
        this.contactSearchReq.ddlStatusEmailPromo = +this.searchQueriesForm.controls['ddlStatusEmailPromo'].value
        this.contactSearchReq.ddMetricNames = this.searchQueriesForm.controls['ddMetricNames'].value
        this.contactSearchReq.ddMetricValues = "all"
        this.contactSearchReq.ddOpTags = this.searchQueriesForm.controls['ddOpTags'].value
        this.contactSearchReq.ddOutlookSync = this.searchQueriesForm.controls['ddOutlookSync'].value
        this.contactSearchReq.ddTxtMsgStatus = this.searchQueriesForm.controls['ddTxtMsgStatus'].value
        this.contactSearchReq.emailType = 0
        this.contactSearchReq.isEmailRequired = false
        this.contactSearchReq.isMyPersonalContacts = this.searchQueriesForm.controls['cbMyPersonalContacts'].value
        this.contactSearchReq.isShowScore = true
        this.contactSearchReq.lbCoTags = this.searchQueriesForm.controls['lbCoTags'].value
        this.contactSearchReq.lbCoTagSelected = this.searchQueriesForm.controls['ddOpCoTags'].value
        this.contactSearchReq.lblLetter = this.searchQueriesForm.controls['ddStartsWith'].value
        this.contactSearchReq.lblTagSelected = this.searchQueriesForm.controls['lbTags'].value
        this.contactSearchReq.lbMetricsSelected = []
        this.contactSearchReq.secClause = "0"
        this.contactSearchReq.txtMetricTextSearch = ""
        this.contactSearchReq.txtSearch = this.searchQueriesForm.controls['txtSearch'].value
    }
}
