import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CLPUser, DDFields, UserResponse } from '../../../models/clpuser.model';
import { eFeatures } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { GlobalService } from '../../../services/global.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { DatePipe, DOCUMENT, Location } from '@angular/common'
import { CmUserPrefService } from '../../../services/cm-user-pref.service';
import { ClubUserPreferencesResponse, CMUserPref } from '../../../models/cm-user-pref.model';
import { IntDropDownItem } from '../../../models/genericResponse.model';
import { TxtMsgService } from '../../../services/textmsg.service';
import { TxtMsgTemplate, TxtMsgTemplateResponse } from '../../../models/textMsgTemplate.model';
import { NotificationService } from '../../../services/notification.service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

@Component({
    selector: 'app-cm-user-prefs',
    templateUrl: './cm-user-prefs.component.html',
    styleUrl: './cm-user-prefs.component.css'
})
export class CmUserPrefsComponent {
    showSpinner: boolean;
    encryptedUser: string;
    userResponse: UserResponse;
    user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    isViewMode: boolean = true
    cmUserPrefForm: FormGroup
    ddCMSites: IntDropDownItem[];
    ddEmailTemplates: IntDropDownItem[];
    ddCLPUsers: IntDropDownItem[];
    ddTxtMsgTemplates: IntDropDownItem[];
    cmUserPrefReq: CMUserPref = <CMUserPref>{};
    cmUserPref: CMUserPref;
    txtMsgTemplateRes: TxtMsgTemplate = <TxtMsgTemplate>{};
    isSlurpy: boolean = false;
    selectedClass2Codes = []
    ddMemClass2Codes: DDFields[];
    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };
    class2CodeTitle: string;
    placeHolder: string;
    constructor(private fb: FormBuilder,
        public _localService: LocalService,
        private _router: Router,
        public _utilityService: UtilityService,
        public _globalService: GlobalService,
        public cmUserPrefService: CmUserPrefService,
        private notifyService: NotificationService,
        private txtMsgService: TxtMsgService
    ) {
        this._localService.isMenu = true;
    }

    ngOnInit(): void {
        this.cmUserPrefForm = this.prepareCmUserPrefForm();

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.clubUserPreferences_Load()
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.slurpyUserId > 0) {
                                this.isSlurpy = true;
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("user-profile.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    prepareCmUserPrefForm() {
        return this.fb.group({
            defaultSiteID: [0],
            initialETID: [0],
            vCRAlertCLPUserID: [0],
            vCRAlertBCC: [''],
            pH1SMSTemplateID: [0],
            pH1SMSMessageText: [''],
            pH1SMSMediaURL: [''],
            pH2SMSTemplateID: [0],
            pH2SMSMessageText: [''],
            pH2SMSMediaURL: [''],
            pH3SMSTemplateID: [0],
            pH3SMSMessageText: [''],
            pH3SMSMediaURL: [''],
            pH1KWTemplateID: [0],
            pH1KWMessageText: [''],
            pH1KWMediaURL: [''],
            pH2KWTemplateID: [0],
            pH2KWMessageText: [''],
            pH2KWMediaURL: [''],
            pH3KWTemplateID: [0],
            pH3KWMessageText: [''],
            pH3KWMediaURL: [''],
            pH4KWTemplateID: [0],
            pH4KWMessageText: [''],
            pH4KWMediaURL: [''],
            pH5KWTemplateID: [0],
            pH5KWMessageText: [''],
            pH5KWMediaURL: [''],
            pHLCVCInTemplateID: [0],
            pHLCVCInMessageText: [''],
            pHLCVCInMediaURL: [''],
            pHLCSMSInTemplateID: [0],
            pHLCSMSInMessageText: [''],
            pHLCSMSInMediaURL: [''],
            pH24hrTourTemplateID: [0],
            pH24hrTourMessageText: [''],
            pH24hrTourMediaURL: [''],
            pH24hrContractTemplateID: [0],
            pH24hrContractMessageText: [''],
            pH24hrContractMediaURL: [''],
            pHSendContractTemplateID: [0],
            pHSendContractMessageText: [''],
            pHSendContractMediaURL: [''],
            isDCRActive: [false],
            dCRMemberCalls: [''],
            dCRReciprocals: [''],
            dCRMemberSMSRouting: [''],
            isSOPManaged: [false],
            sOPPerDealType: [0],
            sOPPerDealFee: [0],
            lCPerDealType: [0],
            lCPerDealUnit: [0],
            isMemberClass2Codes: [''],
        })
    }

    patchCmUserPrefForm() {
        this.cmUserPrefForm.controls['defaultSiteID'].patchValue(this.cmUserPref?.defaultSiteID)
        this.cmUserPrefForm.controls['initialETID'].patchValue(this.cmUserPref?.initialETID)
        this.cmUserPrefForm.controls['vCRAlertCLPUserID'].patchValue(this.cmUserPref?.vCRAlertCLPUserID)
        this.cmUserPrefForm.controls['vCRAlertBCC'].patchValue(this.cmUserPref?.vCRAlertBCC)
        this.cmUserPrefForm.controls['pH1SMSTemplateID'].patchValue(this.cmUserPref?.pH1SMSTemplateID)
        this.cmUserPrefForm.controls['pH1SMSMessageText'].patchValue(this.cmUserPref?.pH1SMSMessageText)
        this.cmUserPrefForm.controls['pH1SMSMediaURL'].patchValue(this.cmUserPref?.pH1SMSMediaURL)
        this.cmUserPrefForm.controls['pH2SMSTemplateID'].patchValue(this.cmUserPref?.pH2SMSTemplateID)
        this.cmUserPrefForm.controls['pH2SMSMessageText'].patchValue(this.cmUserPref?.pH2SMSMessageText)
        this.cmUserPrefForm.controls['pH2SMSMediaURL'].patchValue(this.cmUserPref?.pH2SMSMediaURL)
        this.cmUserPrefForm.controls['pH3SMSTemplateID'].patchValue(this.cmUserPref?.pH3SMSTemplateID)
        this.cmUserPrefForm.controls['pH3SMSMessageText'].patchValue(this.cmUserPref?.pH3SMSMessageText)
        this.cmUserPrefForm.controls['pH3SMSMediaURL'].patchValue(this.cmUserPref?.pH3SMSMediaURL)
        this.cmUserPrefForm.controls['pH1KWTemplateID'].patchValue(this.cmUserPref?.pH1KWTemplateID)
        this.cmUserPrefForm.controls['pH1KWMessageText'].patchValue(this.cmUserPref?.pH1KWMessageText)
        this.cmUserPrefForm.controls['pH1KWMediaURL'].patchValue(this.cmUserPref?.pH1KWMediaURL)
        this.cmUserPrefForm.controls['pH2KWTemplateID'].patchValue(this.cmUserPref?.pH2KWTemplateID)
        this.cmUserPrefForm.controls['pH2KWMessageText'].patchValue(this.cmUserPref?.pH2KWMessageText)
        this.cmUserPrefForm.controls['pH2KWMediaURL'].patchValue(this.cmUserPref?.pH2KWMediaURL)
        this.cmUserPrefForm.controls['pH3KWTemplateID'].patchValue(this.cmUserPref?.pH3KWTemplateID)
        this.cmUserPrefForm.controls['pH3KWMessageText'].patchValue(this.cmUserPref?.pH3KWMessageText)
        this.cmUserPrefForm.controls['pH3KWMediaURL'].patchValue(this.cmUserPref?.pH3KWMediaURL)
        this.cmUserPrefForm.controls['pH4KWTemplateID'].patchValue(this.cmUserPref?.pH4KWTemplateID)
        this.cmUserPrefForm.controls['pH4KWMessageText'].patchValue(this.cmUserPref?.pH4KWMessageText)
        this.cmUserPrefForm.controls['pH4KWMediaURL'].patchValue(this.cmUserPref?.pH4KWMediaURL)
        this.cmUserPrefForm.controls['pH5KWTemplateID'].patchValue(this.cmUserPref?.pH5KWTemplateID)
        this.cmUserPrefForm.controls['pH5KWMessageText'].patchValue(this.cmUserPref?.pH5KWMessageText)
        this.cmUserPrefForm.controls['pH5KWMediaURL'].patchValue(this.cmUserPref?.pH5KWMediaURL)
        this.cmUserPrefForm.controls['pHLCVCInTemplateID'].patchValue(this.cmUserPref?.pHLCVCInTemplateID)
        this.cmUserPrefForm.controls['pHLCVCInMessageText'].patchValue(this.cmUserPref?.pHLCVCInMessageText)
        this.cmUserPrefForm.controls['pHLCVCInMediaURL'].patchValue(this.cmUserPref?.pHLCVCInMediaURL)
        this.cmUserPrefForm.controls['pHLCSMSInTemplateID'].patchValue(this.cmUserPref?.pHLCSMSInTemplateID)
        this.cmUserPrefForm.controls['pHLCSMSInMessageText'].patchValue(this.cmUserPref?.pHLCSMSInMessageText)
        this.cmUserPrefForm.controls['pHLCSMSInMediaURL'].patchValue(this.cmUserPref?.pHLCSMSInMediaURL)
        this.cmUserPrefForm.controls['pH24hrTourTemplateID'].patchValue(this.cmUserPref?.pH24hrTourTemplateID)
        this.cmUserPrefForm.controls['pH24hrTourMessageText'].patchValue(this.cmUserPref?.pH24hrTourMessageText)
        this.cmUserPrefForm.controls['pH24hrTourMediaURL'].patchValue(this.cmUserPref?.pH24hrTourMediaURL)
        this.cmUserPrefForm.controls['pH24hrContractTemplateID'].patchValue(this.cmUserPref?.pH24hrContractTemplateID)
        this.cmUserPrefForm.controls['pH24hrContractMessageText'].patchValue(this.cmUserPref?.pH24hrContractMessageText)
        this.cmUserPrefForm.controls['pH24hrContractMediaURL'].patchValue(this.cmUserPref?.pH24hrContractMediaURL)
        this.cmUserPrefForm.controls['pHSendContractTemplateID'].patchValue(this.cmUserPref?.pHSendContractTemplateID)
        this.cmUserPrefForm.controls['pHSendContractMessageText'].patchValue(this.cmUserPref?.pHSendContractMessageText)
        this.cmUserPrefForm.controls['pHSendContractMediaURL'].patchValue(this.cmUserPref?.pHSendContractMediaURL)
        this.cmUserPrefForm.controls['isDCRActive'].patchValue(this.cmUserPref?.isDCRActive)
        this.cmUserPrefForm.controls['dCRMemberCalls'].patchValue(this.cmUserPref?.dCRMemberCalls)
        this.cmUserPrefForm.controls['dCRReciprocals'].patchValue(this.cmUserPref?.dCRReciprocals)
        this.cmUserPrefForm.controls['dCRMemberSMSRouting'].patchValue(this.cmUserPref?.dCRMemberSMSRouting)
        this.cmUserPrefForm.controls['isSOPManaged'].patchValue(this.cmUserPref?.isSOPManaged)
        this.cmUserPrefForm.controls['sOPPerDealType'].patchValue(this.cmUserPref?.sOPPerDealType)
        this.cmUserPrefForm.controls['sOPPerDealFee'].patchValue(this.cmUserPref?.sOPPerDealFee)
        this.cmUserPrefForm.controls['lCPerDealType'].patchValue(this.cmUserPref?.lCPerDealType)
        this.cmUserPrefForm.controls['lCPerDealUnit'].patchValue(this.cmUserPref?.lCPerDealUnit)
        this.cmUserPrefForm.controls['isMemberClass2Codes'].patchValue(this.cmUserPref?.isMemberClass2Codes)
    }

    async clubUserPreferences_Load() {
        this.showSpinner = true;
        await this.cmUserPrefService.clubUserPreferences_Load(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID)
            .then(async (result: ClubUserPreferencesResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.cmUserPref = response?.cMUserPref;
                    this.ddCMSites = response?.ddCMSites;
                    this.ddEmailTemplates = response?.ddEmailTemplates;
                    this.ddCLPUsers = response?.ddCLPUsers;
                    this.ddTxtMsgTemplates = response?.ddTxtMsgTemplates;
                    this.ddMemClass2Codes = response?.ddisMemberClass2Codes;
                    this.class2CodeTitle = response?.class2CodeTitle;
                    this.placeHolder = 'Select one or more ' + this.class2CodeTitle;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }
    getSiteName(id: number) {
        if (this.ddCMSites?.length > 0) {
            let name = this.ddCMSites?.filter((item) => item?.id == id)[0]?.text;
            return name;
        }
        return '--'
    }

    getEmailTempName(id: number) {
        if (this.ddEmailTemplates?.length > 0) {
            let name = this.ddEmailTemplates?.filter((item) => item?.id == id)[0]?.text;
            return name;
        }
        return '--'
    }

    getCLPUserName(id: number) {
        if (this.ddCLPUsers?.length > 0) {
            let name = this.ddCLPUsers?.filter((item) => item?.id == id)[0]?.text;
            return name;
        }
        return '--'
    }

    getTxtMsgTemplateName(id: number) {
        if (this.ddTxtMsgTemplates?.length > 0) {
            let name = this.ddTxtMsgTemplates?.filter((item) => item?.id == id)[0]?.text;
            return name;
        }
        return '--'
    }
    onEdit() {
        this.isViewMode = false
        this.patchCmUserPrefForm()
    }

    onCancelEdit() {
        this.clubUserPreferences_Load();
    }

    copyCmUserPref() {
        this.cmUserPrefReq.cLPUserID = this.user?.cLPUserID
        this.cmUserPrefReq.defaultSiteID = this.cmUserPrefForm.controls['defaultSiteID'].value;
        this.cmUserPrefReq.initialETID = this.cmUserPrefForm.controls['initialETID'].value;
        this.cmUserPrefReq.vCRAlertCLPUserID = this.cmUserPrefForm.controls['vCRAlertCLPUserID'].value;
        this.cmUserPrefReq.vCRAlertBCC = this.cmUserPrefForm.controls['vCRAlertBCC'].value;
        this.cmUserPrefReq.pH1SMSTemplateID = this.cmUserPrefForm.controls['pH1SMSTemplateID'].value;
        this.cmUserPrefReq.pH1SMSMessageText = this.cmUserPrefForm.controls['pH1SMSMessageText'].value;
        this.cmUserPrefReq.pH1SMSMediaURL = this.cmUserPrefForm.controls['pH1SMSMediaURL'].value;
        this.cmUserPrefReq.pH2SMSTemplateID = this.cmUserPrefForm.controls['pH2SMSTemplateID'].value;
        this.cmUserPrefReq.pH2SMSMessageText = this.cmUserPrefForm.controls['pH2SMSMessageText'].value;
        this.cmUserPrefReq.pH2SMSMediaURL = this.cmUserPrefForm.controls['pH2SMSMediaURL'].value;
        this.cmUserPrefReq.pH3SMSTemplateID = this.cmUserPrefForm.controls['pH3SMSTemplateID'].value;
        this.cmUserPrefReq.pH3SMSMessageText = this.cmUserPrefForm.controls['pH3SMSMessageText'].value;
        this.cmUserPrefReq.pH3SMSMediaURL = this.cmUserPrefForm.controls['pH3SMSMediaURL'].value;
        this.cmUserPrefReq.pH1KWTemplateID = this.cmUserPrefForm.controls['pH1KWTemplateID'].value;
        this.cmUserPrefReq.pH1KWMessageText = this.cmUserPrefForm.controls['pH1KWMessageText'].value;
        this.cmUserPrefReq.pH1KWMediaURL = this.cmUserPrefForm.controls['pH1KWMediaURL'].value;
        this.cmUserPrefReq.pH2KWTemplateID = this.cmUserPrefForm.controls['pH2KWTemplateID'].value;
        this.cmUserPrefReq.pH2KWMessageText = this.cmUserPrefForm.controls['pH2KWMessageText'].value;
        this.cmUserPrefReq.pH2KWMediaURL = this.cmUserPrefForm.controls['pH2KWMediaURL'].value;
        this.cmUserPrefReq.pH3KWTemplateID = this.cmUserPrefForm.controls['pH3KWTemplateID'].value;
        this.cmUserPrefReq.pH3KWMessageText = this.cmUserPrefForm.controls['pH3KWMessageText'].value;
        this.cmUserPrefReq.pH3KWMediaURL = this.cmUserPrefForm.controls['pH3KWMediaURL'].value;
        this.cmUserPrefReq.pH4KWTemplateID = this.cmUserPrefForm.controls['pH4KWTemplateID'].value;
        this.cmUserPrefReq.pH4KWMessageText = this.cmUserPrefForm.controls['pH4KWMessageText'].value;
        this.cmUserPrefReq.pH4KWMediaURL = this.cmUserPrefForm.controls['pH4KWMediaURL'].value;
        this.cmUserPrefReq.pH5KWTemplateID = this.cmUserPrefForm.controls['pH5KWTemplateID'].value;
        this.cmUserPrefReq.pH5KWMessageText = this.cmUserPrefForm.controls['pH5KWMessageText'].value;
        this.cmUserPrefReq.pH5KWMediaURL = this.cmUserPrefForm.controls['pH5KWMediaURL'].value;
        this.cmUserPrefReq.pHLCVCInTemplateID = this.cmUserPrefForm.controls['pHLCVCInTemplateID'].value;
        this.cmUserPrefReq.pHLCVCInMessageText = this.cmUserPrefForm.controls['pHLCVCInMessageText'].value;
        this.cmUserPrefReq.pHLCVCInMediaURL = this.cmUserPrefForm.controls['pHLCVCInMediaURL'].value;
        this.cmUserPrefReq.pHLCSMSInTemplateID = this.cmUserPrefForm.controls['pHLCSMSInTemplateID'].value;
        this.cmUserPrefReq.pHLCSMSInMessageText = this.cmUserPrefForm.controls['pHLCSMSInMessageText'].value;
        this.cmUserPrefReq.pHLCSMSInMediaURL = this.cmUserPrefForm.controls['pHLCSMSInMediaURL'].value;
        this.cmUserPrefReq.pH24hrTourTemplateID = this.cmUserPrefForm.controls['pH24hrTourTemplateID'].value;
        this.cmUserPrefReq.pH24hrTourMessageText = this.cmUserPrefForm.controls['pH24hrTourMessageText'].value;
        this.cmUserPrefReq.pH24hrTourMediaURL = this.cmUserPrefForm.controls['pH24hrTourMediaURL'].value;
        this.cmUserPrefReq.pH24hrContractTemplateID = this.cmUserPrefForm.controls['pH24hrContractTemplateID'].value;
        this.cmUserPrefReq.pH24hrContractMessageText = this.cmUserPrefForm.controls['pH24hrContractMessageText'].value;
        this.cmUserPrefReq.pH24hrContractMediaURL = this.cmUserPrefForm.controls['pH24hrContractMediaURL'].value;
        this.cmUserPrefReq.pHSendContractTemplateID = this.cmUserPrefForm.controls['pHSendContractTemplateID'].value;
        this.cmUserPrefReq.pHSendContractMessageText = this.cmUserPrefForm.controls['pHSendContractMessageText'].value;
        this.cmUserPrefReq.pHSendContractMediaURL = this.cmUserPrefForm.controls['pHSendContractMediaURL'].value;
        this.cmUserPrefReq.isDCRActive = this.cmUserPrefForm.controls['isDCRActive'].value;
        this.cmUserPrefReq.dCRMemberCalls = this.cmUserPrefForm.controls['dCRMemberCalls'].value;
        this.cmUserPrefReq.dCRReciprocals = this.cmUserPrefForm.controls['dCRReciprocals'].value;
        this.cmUserPrefReq.dCRMemberSMSRouting = this.cmUserPrefForm.controls['dCRMemberSMSRouting'].value;
        this.cmUserPrefReq.isSOPManaged = this.cmUserPrefForm.controls['isSOPManaged'].value;
        this.cmUserPrefReq.sOPPerDealType = this.cmUserPrefForm.controls['sOPPerDealType'].value;
        this.cmUserPrefReq.sOPPerDealFee = this.cmUserPrefForm.controls['sOPPerDealFee'].value;
        this.cmUserPrefReq.lCPerDealType = this.cmUserPrefForm.controls['lCPerDealType'].value;
        this.cmUserPrefReq.lCPerDealUnit = this.cmUserPrefForm.controls['lCPerDealUnit'].value;
        this.cmUserPrefReq.isMemberClass2Codes = this.selectedClass2Codes.join(', ');
    }

    async onChangeTxtTemplate(event, type) {
        let templateId = event?.target?.value
        switch (type) {
            case 1:
                await this.txtMsgTemplate_Load(templateId)
                this.cmUserPrefForm.controls['pH1SMSMessageText'].patchValue(this.txtMsgTemplateRes?.messageText);
                this.cmUserPrefForm.controls['pH1SMSMediaURL'].patchValue(this.txtMsgTemplateRes?.mediaURL);
                break;
            case 2:
                await this.txtMsgTemplate_Load(templateId)
                this.cmUserPrefForm.controls['pH2SMSMessageText'].patchValue(this.txtMsgTemplateRes?.messageText);
                this.cmUserPrefForm.controls['pH2SMSMediaURL'].patchValue(this.txtMsgTemplateRes?.mediaURL);
                break;
            case 3:
                await this.txtMsgTemplate_Load(templateId)
                this.cmUserPrefForm.controls['pH3SMSMessageText'].patchValue(this.txtMsgTemplateRes?.messageText);
                this.cmUserPrefForm.controls['pH3SMSMediaURL'].patchValue(this.txtMsgTemplateRes?.mediaURL);
                break;
            case 4:
                await this.txtMsgTemplate_Load(templateId)
                this.cmUserPrefForm.controls['pHLCVCInMessageText'].patchValue(this.txtMsgTemplateRes?.messageText);
                this.cmUserPrefForm.controls['pHLCVCInMediaURL'].patchValue(this.txtMsgTemplateRes?.mediaURL);
                break;
            case 5:
                await this.txtMsgTemplate_Load(templateId)
                this.cmUserPrefForm.controls['pHLCSMSInMessageText'].patchValue(this.txtMsgTemplateRes?.messageText);
                this.cmUserPrefForm.controls['pHLCSMSInMediaURL'].patchValue(this.txtMsgTemplateRes?.mediaURL);
                break;
            case 6:
                await this.txtMsgTemplate_Load(templateId)
                this.cmUserPrefForm.controls['pH24hrTourMessageText'].patchValue(this.txtMsgTemplateRes?.messageText);
                this.cmUserPrefForm.controls['pH24hrTourMediaURL'].patchValue(this.txtMsgTemplateRes?.mediaURL);
                break;
            case 7:
                await this.txtMsgTemplate_Load(templateId)
                this.cmUserPrefForm.controls['pH24hrContractMessageText'].patchValue(this.txtMsgTemplateRes?.messageText);
                this.cmUserPrefForm.controls['pH24hrContractMediaURL'].patchValue(this.txtMsgTemplateRes?.mediaURL);
                break;
            case 8:
                await this.txtMsgTemplate_Load(templateId)
                this.cmUserPrefForm.controls['pHSendContractMessageText'].patchValue(this.txtMsgTemplateRes?.messageText);
                this.cmUserPrefForm.controls['pHSendContractMediaURL'].patchValue(this.txtMsgTemplateRes?.mediaURL);
                break;
            case 9:
                await this.txtMsgTemplate_Load(templateId)
                this.cmUserPrefForm.controls['pH1KWMessageText'].patchValue(this.txtMsgTemplateRes?.messageText);
                this.cmUserPrefForm.controls['pH1KWMediaURL'].patchValue(this.txtMsgTemplateRes?.mediaURL);
                break;
            case 10:
                await this.txtMsgTemplate_Load(templateId)
                this.cmUserPrefForm.controls['pH2KWMessageText'].patchValue(this.txtMsgTemplateRes?.messageText);
                this.cmUserPrefForm.controls['pH2KWMediaURL'].patchValue(this.txtMsgTemplateRes?.mediaURL);
                break;
            case 11:
                await this.txtMsgTemplate_Load(templateId)
                this.cmUserPrefForm.controls['pH3KWMessageText'].patchValue(this.txtMsgTemplateRes?.messageText);
                this.cmUserPrefForm.controls['pH3KWMediaURL'].patchValue(this.txtMsgTemplateRes?.mediaURL);
                break;
            case 12:
                await this.txtMsgTemplate_Load(templateId)
                this.cmUserPrefForm.controls['pH4KWMessageText'].patchValue(this.txtMsgTemplateRes?.messageText);
                this.cmUserPrefForm.controls['pH4KWMediaURL'].patchValue(this.txtMsgTemplateRes?.mediaURL);
                break;
            case 13:
                await this.txtMsgTemplate_Load(templateId)
                this.cmUserPrefForm.controls['pH5KWMessageText'].patchValue(this.txtMsgTemplateRes?.messageText);
                this.cmUserPrefForm.controls['pH5KWMediaURL'].patchValue(this.txtMsgTemplateRes?.mediaURL);
                break;
        }


    }

    async cMUserPref_Save() {
        this.showSpinner = true;
        await this.copyCmUserPref()
        await this.cmUserPrefService.cMUserPref_Save(this.encryptedUser, this.cmUserPrefReq)
            .then(async (result: ClubUserPreferencesResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response?.messageBool) {
                        this.isViewMode = true;
                        this.clubUserPreferences_Load();
                        this.notifyService.showSuccess("Saved successfully.", "Success.", 3000);
                    }
                    else {
                        this.notifyService.showError("Some error occured while saving.", "Try again!", 3000);
                    }
                  
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async txtMsgTemplate_Load(txtMsgTemplateID) {
        this.showSpinner = true;
        await this.txtMsgService.txtMsgTemplate_Load(this.encryptedUser, txtMsgTemplateID)
            .then(async (result: TxtMsgTemplateResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response?.txtMsgTemplateResponse && response?.txtMsgTemplateResponse?.cLPCompanyID == this.user?.cLPCompanyID) {
                        this.txtMsgTemplateRes = response?.txtMsgTemplateResponse;
                    }
                    else {
                        this.txtMsgTemplateRes = <TxtMsgTemplate>{}
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

}
