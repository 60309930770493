<div class="mb-2">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><span><i class="fas fa-users"></i></span> Transfer or Purge User Data</div>
            </div>
            <div class="global-body-section">
                <div class="wraper-body-left">
                    <div [ngClass]="hideUserData==false ? '' : ''" *ngIf="showBtn<=1">
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <div class="">
                                    <span class="control-label">Select FROM User:</span>
                                </div>
                            </div>
                            <div class="cards-colunm-right">
                                <select class="form-control" (change)="getUserTransferData($event.target.value)" [(ngModel)]="fromUser">
                                    <option value=0> -Select- </option>
                                    <option *ngFor="let item of userList; let i = index" [value]="item?.id">{{item?.text }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <div class="align-center">
                                    <span class="control-label">Select To User:</span>
                                </div>
                            </div>
                            <div class="cards-colunm-right">
                                <select class="form-control" [(ngModel)]="toUser" (change)="getToUser($event.target.value)">
                                    <option value=0> -None (Purge Data)- </option>
                                    <option *ngFor="let item of userList; let i = index" [value]="item?.id">{{item?.text }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <div class="align-center">
                                    <span class="control-label">Mark Data</span>
                                </div>
                            </div>
                            <div class="cards-colunm-right">
                                <div class="full-width-container">
                                    <input type="text" [(ngModel)]="markData" />
                                    <span style="font-size:11px;">This will be appended to items</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wraper-body-right">
                        <div *ngIf="showBtn==5">
                            <div class="message-Warning">
                                <span>{{strConfirmMessage}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="wraper-body-right">
                        <div [ngClass]="showBtn>1 ? '' : ''" *ngIf="hideUserData && !pnlNexBtn">
                            <div class="user-data-section">
                                <span>{{userName}} data item are.. </span><br />
                                <span [innerText]="userDataDisplay"></span>
                            </div>
                        </div>
                    </div>
                    <div class="wraper-body-right">
                        <div *ngIf="hideUserData && pnlNexBtn">
                            <div class="message-Warning">
                                <span *ngIf="fromUser!=toUser">
                                    <span>You have chosen to <b>{{showBtn === 2 ? 'transfer' : 'purge'}}</b> data.</span><br />
                                    <span>All data items belonging to <b>{{userName}}</b> will be  {{showBtn === 2 ? 'transferred to' : 'purged permanently and user will be reset.'}}</span><span *ngIf="showBtn === 2">&nbsp;&nbsp;<b>{{userToName}}</b>.</span><br />
                                    <span *ngIf="showBtn === 2">All non-pending items will be marked with <b>{{markData}}</b> on the subject line or description area.</span><br />
                                </span>
                                <span *ngIf="fromUser==toUser">
                                    <span>Something is wrong with the 'to' user. Please click Cancel and check.</span><br />
                                </span>
                                <br />
                                <span>Are you sure you want to do this? It cannot be undone.</span>
                                <br />
                                <br />
                                <span>Data item are.. </span><br />
                                <span [innerText]="userDataDisplay"></span>
                            </div>
                        </div>
                    </div>
                    <div class="cards-body-section">
                        <div class="cards-colunm-left"></div>
                        <div class="cards-colunm-right">
                            <button class="btn btn-primary" type="button" (click)="saveAndResetUserData(fromUser,toUser)" *ngIf="pnlNexBtn && (fromUser!=toUser)"> Confirm and Finish </button>
                            <button class="btn btn-cancel" type="button" *ngIf="pnlNexBtn" (click)="cancel('cancel')"> Cancel</button>
                            <button class="btn btn-cancel" type="button" *ngIf="startOverBtn" (click)="cancel('startOver')"> Start Over</button>
                            <button class="btn btn-primary" *ngIf="!pnlNexBtn && !startOverBtn" type="button" (click)="goToNext()"> Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
