<div class="custom-security-link" *ngIf="!isNewTab">
    <a [routerLink]="['/calender']">My Calender</a>
    <a [routerLink]="['/appointment-list']">My Appt List</a>
    <a [routerLink]="['/task']">My Tasks</a>
    <a [routerLink]="user?.cLPCompanyID === 1226 ? ['/call-ih'] : ['/call']">My Call List</a>
</div>

<div class="" [ngClass]="{'': isNewTab}">
    <div class="margin-all-area">
        <div class="wraper-main-section">
            <div class="global-card-section">
                <div class="global-header-section">
                    <div class="svg-icon-panel"><img src="../../../../../assets/appttitle.svg" class="mr-1" />Appointment</div>
                    <div class="header-button-panel">
                        <div *ngIf="this.apptData.class1 > 0">Previous Call Attempts: {{this.apptData.class1}} </div>
                        <div class="button-wrapper">
                            <div *ngIf="showCallList">
                                <button class="btn btn-primary" (click)="onQualClick()">Did Not Contact</button>
                                <button class="btn btn-primary" (click)="apptQualLeftMessage()">Left Message</button>
                                <button class="btn btn-primary" (click)="apptOCF()">Open Call Form</button>
                            </div>
                            <button type="button" matTooltip="Send Text Message" class="icon-btn" (click)="showTextMail()" *ngIf="showTextMsgIcon && (apptData?.contact  || apptData?.lead)">
                                <img src="../../../../../assets/txtmsgstitle_grey.svg" />
                                <span class="btn-text">Send Text Message</span>
                            </button>
                            <button type="button" *ngIf="apptData?.directionsLink != null" matTooltip="Get Directions" class="icon-btn" (click)="getDirection()">
                                <img src="../../../../assets/drivebtn.svg" />
                                <span class="btn-text">Get Directions</span>
                            </button>
                            <button type="button" matTooltip="Download iCalender file" class="icon-btn" (click)="addToCalendar()" *ngIf="apptId >0">
                                <img src="../../../../assets/downbtn.svg">
                                <span class="btn-text">Download iCalender file</span>
                            </button>
                            <button *ngIf="isNewTab" type="button" matTooltip="Close" class="icon-btn" (click)="cancelAppointment()">
                                <i class="fa fa-times"></i>
                                <span class="btn-text">Cancel</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="global-body-section">
                    <div class="wraper-body-panel">
                        <div class="wraper-body-left">
                            <div class="global-padding10">
                                <div class="company-2d-alert mb-2" *ngIf="isError">
                                    <span [innerHTML]="strError"></span>
                                </div>
                            </div>
                            <kendo-tabstrip [keepTabContent]="true" class="m-0">
                                <kendo-tabstrip-tab [title]="'General'" [selected]="!ocf">
                                    <ng-template kendoTabContent>
                                        <ng-template [ngIf]="generalApptForm">
                                            <form [formGroup]="generalApptForm">
                                                <div class="">
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <span>Subject</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <input type="text" class="form-control" formControlName="subject" [ngClass]="{'has-error': generalApptForm.controls.subject.errors && (generalApptForm.controls.subject.touched || generalApptForm.controls.subject.dirty)}" />
                                                            <div *ngIf="generalApptForm.controls.subject.errors && (generalApptForm.controls.subject.touched || generalApptForm.controls.subject.dirty)">
                                                                <div class="login-error" *ngIf="generalApptForm.controls.subject.errors.required">Please describe the purpose of the appointment.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <span>Start</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <kendo-datetimepicker formControlName="apptStartTime" [steps]="steps" (valueChange)="loadApptData(true)"></kendo-datetimepicker>
                                                        </div>
                                                    </div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <span>End</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <select class="form-control" formControlName="apptLength" (valueChange)="loadApptData(true)">
                                                                <option *ngFor="let apptLen of apptLengthDD" [value]="apptLen?.value">{{apptLen?.text }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="cards-body-section" *ngIf="apptData?.lead">
                                                        <div class="cards-colunm-left">
                                                            <span>Lead</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <a class="contact-cursor-pointer" title="View Lead" (click)="gotoLink('lead',apptData?.lead);">{{apptData?.lead?.leadDesc}}</a>
                                                        </div>
                                                    </div>
                                                    <div class="cards-body-section flex-mobile-view" *ngIf="apptData?.contact || apptData?.leadContact || ownerId > 0">
                                                        <div class="cards-colunm-left">
                                                            <div>
                                                                <div>
                                                                    <span>Contact</span>
                                                                </div>
                                                                <div class="align-right">
                                                                    <button type="button" class="grid-common-btn" (click)="gotoLink('email',apptData?.contact);" *ngIf="(apptData?.contact?.email | isNull)">
                                                                        <img src="../../../../../assets/emailbtn.svg" title="Send an email to {{apptData?.contact?.email}}" />
                                                                        <span class="grid-common-text">Edit</span>
                                                                    </button>
                                                                </div>
                                                                <div class="align-right">
                                                                    <a href="{{apptData?.directionsLink}}" title="Get Directions" *ngIf="(apptData?.directionsLink | isNull)" target="_blank" class="grid-common-btn">
                                                                        <img src=".../../../../assets/drivebtn.svg" />
                                                                        <span class="grid-common-text">E-mail</span>
                                                                    </a>
                                                                </div>
                                                                <div class="align-right" *ngIf="tagItem && !isShowTag">
                                                                    <a (click)="tagdd()" [title]="getDisplayTagsToolTip()" *ngIf="(apptData?.directionsLink | isNull)" class="grid-common-btn">
                                                                        <img src="../../../../../assets/tagbtn.svg" />
                                                                        <span class="grid-common-text">Tag</span>
                                                                    </a>
                                                                </div>
                                                                <div class="align-right" *ngIf="isShowTag">
                                                                    <kendo-combobox [data]="tagListByCompany"
                                                                                    textField="tagDisplay"
                                                                                    [(ngModel)]="selectedTagId"
                                                                                    [ngModelOptions]="{standalone: true}"
                                                                                    valueField="tagID"
                                                                                    [allowCustom]="true"
                                                                                    [valuePrimitive]="true"
                                                                                    [suggest]="true"
                                                                                    placeholder="Type a Tag"
                                                                                    (valueChange)="selectedTagChangeEvent($event)">
                                                                        <ng-template kendoComboBoxItemTemplate let-dataItem>
                                                                            <strong [ngStyle]="{'background-color': (dataItem?.checked) ? '#2DC76D' : ''}">{{dataItem.tagDisplay}}</strong>
                                                                        </ng-template>
                                                                    </kendo-combobox>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <div *ngIf="apptData?.contact && this.apptCategoryType == 2">
                                                                <a class="contact-cursor-pointer" (click)="gotoLink('name',apptData?.contact);">{{apptData?.contact?.firstName}} {{apptData?.contact?.lastName}}<br /></a>
                                                                <span *ngIf="(apptData?.contact?.companyName | isNull)">{{apptData?.contact?.companyName}}<br /></span>
                                                                <span *ngIf="(apptData?.contact?.add1 | isNull)">{{apptData?.contact?.add1}}<br /></span>
                                                                <span *ngIf="(apptData?.contact?.add2 | isNull)">{{apptData?.contact?.add2}}<br /></span>
                                                                <span *ngIf="(apptData?.contact?.add3 | isNull)">{{apptData?.contact?.add3}}<br /></span>
                                                                <span *ngIf="(apptData?.contact?.city | isNull)">{{apptData?.contact?.city}},</span>
                                                                <span *ngIf="(apptData?.contact?.state | isNull)">{{apptData?.contact?.state}}  </span>
                                                                <span *ngIf="(apptData?.contact?.zip | isNull)">{{apptData?.contact?.zip}}  </span>
                                                                <span *ngIf="(apptData?.contact?.country | isNull)">{{apptData?.contact?.country}}<br /></span>
                                                                <a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(apptData?.contact,'mp')"><span *ngIf="(apptData?.contact?.phone | isNull)">B: {{pixString(apptData?.contact?.phone) | phoneFormat}}<br /></span></a>
                                                                <a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(apptData?.contact,'mp')"><span *ngIf="(apptData?.contact?.mobile | isNull)">M: {{pixString(apptData?.contact?.mobile) | phoneFormat}}<br /></span></a>
                                                                <a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(apptData?.contact,'mp')"><span *ngIf="(apptData?.contact?.homePhone | isNull)">H: {{pixString(apptData?.contact?.homePhone) | phoneFormat}}<br /></span></a>
                                                                <a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(apptData?.contact,'mp')"><span *ngIf="(apptData?.contact?.altPhone | isNull)">O: {{pixString(apptData?.contact?.altPhone) | phoneFormat}}<br /></span></a>
                                                                <span *ngIf="(apptData?.contact?.fax | isNull)">F: {{apptData?.contact?.fax}}<br /></span>
                                                                <span *ngIf="(apptData?.contact?.email | isNull)">E: {{apptData?.contact?.email}}</span>
                                                            </div>
                                                            <div *ngIf="apptData?.leadContact && this.apptCategoryType == 3">
                                                                <a class="contact-cursor-pointer" (click)="gotoLink('name',apptData?.leadContact);">{{apptData?.leadContact?.firstName}} {{apptData?.leadContact?.lastName}}<br /></a>
                                                                <span *ngIf="(apptData?.leadContact?.companyName | isNull)">{{apptData?.leadContact?.companyName}}<br /></span>
                                                                <span *ngIf="(apptData?.leadContact?.add1 | isNull)">{{apptData?.leadContact?.add1}}<br /></span>
                                                                <span *ngIf="(apptData?.leadContact?.add2 | isNull)">{{apptData?.leadContact?.add2}}<br /></span>
                                                                <span *ngIf="(apptData?.leadContact?.add3 | isNull)">{{apptData?.leadContact?.add3}}<br /></span>
                                                                <span *ngIf="(apptData?.leadContact?.city | isNull)">{{apptData?.leadContact?.city}},</span>
                                                                <span *ngIf="(apptData?.leadContact?.state | isNull)">{{apptData?.leadContact?.state}}  </span>
                                                                <span *ngIf="(apptData?.leadContact?.zip | isNull)">{{apptData?.leadContact?.zip}}  </span>
                                                                <span *ngIf="(apptData?.leadContact?.country | isNull)">{{apptData?.leadContact?.country}}<br /></span>
                                                                <a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(apptData?.leadContact,'mp')"><span *ngIf="(apptData?.leadContact?.phone | isNull)">B: {{pixString(apptData?.leadContact?.phone) | phoneFormat}}<br /></span></a>
                                                                <a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(apptData?.leadContact,'mp')"><span *ngIf="(apptData?.leadContact?.mobile | isNull)">M: {{pixString(apptData?.leadContact?.mobile) | phoneFormat}}<br /></span></a>
                                                                <a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(apptData?.leadContact,'mp')"><span *ngIf="(apptData?.leadContact?.homePhone | isNull)">H: {{pixString(apptData?.leadContact?.homePhone) | phoneFormat}}<br /></span></a>
                                                                <a class="contact-cursor-pointer" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(apptData?.leadContact,'mp')"><span *ngIf="(apptData?.leadContact?.altPhone | isNull)">O: {{pixString(apptData?.leadContact?.altPhone) | phoneFormat}}<br /></span></a>
                                                                <span *ngIf="(apptData?.leadContact?.fax | isNull)">F: {{apptData?.leadContact?.fax}}<br /></span>
                                                                <span *ngIf="(apptData?.leadContact?.email | isNull)">E: {{apptData?.leadContact?.email}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <span>Category</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <div class="inner-container margin-left-none">
                                                                <select class="form-control inner-container-left mr-2" formControlName="category" *ngIf="apptCategoryType !=2 && apptCategoryType !=3">
                                                                    <option [value]="0">Personal</option>
                                                                    <option [value]="1">Company</option>
                                                                </select>
                                                                <span *ngIf="apptCategoryType ==2 || apptCategoryType ==3">{{apptCategoryType ==2 ? 'Contact' :'Lead'}}</span>
                                                                <input type="checkbox" class="checkbox" formControlName="isPhoneCall" />
                                                                <span>Phone Call (will appear only as part of Call List)</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <span>Type</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <select id="noteType" class="form-control" formControlName="apptType">
                                                                <option [value]="0">-None Selected-</option>
                                                                <option *ngFor="let item of apptFilters?.filterTypeCode" [value]="item.apptTypeCode">{{ item?.display }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <span>Location</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <input type="text" class="form-control" formControlName="location">
                                                        </div>
                                                    </div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <span>User</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <select class="form-control" formControlName="cLPUserID">
                                                                <option [value]="0">-None Selected</option>
                                                                <option *ngFor="let item of apptFilters?.filterUser" [value]="item.value">{{ item?.text }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="cards-body-section" *ngIf="apptId>0">
                                                        <div class="cards-colunm-left">
                                                            <span>Appointment Status</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <select class="form-control" formControlName="status">
                                                                <option [value]="0">Pending</option>
                                                                <option [value]="1">Cancelled</option>
                                                                <option [value]="2">Completed</option>
                                                                <option *ngIf="!apptData?.cLPUserID != user.cLPUserID" [value]="4">Bump</option>
                                                                <option [value]="5">Completed/Stop Campaign</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <span>Notes</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <div class="full-width-container">
                                                                <textarea class="form-control" id="exampleFormControlTextarea1" formControlName="notes" rows="3"></textarea>
                                                                <p class="text-success">(limit 2000 characters)</p>
                                                                <input type="checkbox" class="checkbox" formControlName="showNoteInUserReminder" />
                                                                <span>Include these notes in user reminder.</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </ng-template>
                                    </ng-template>
                                </kendo-tabstrip-tab>
                                <kendo-tabstrip-tab [title]="'Reminder'">
                                    <ng-template kendoTabContent>
                                        <ng-template [ngIf]="reminderApptForm">
                                            <form [formGroup]="reminderApptForm">
                                                <div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <span>Schedule Reminder</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <select class="form-control" formControlName="reminderLength">
                                                                <option [value]="-1">Do not schedule a reminder</option>
                                                                <option *ngFor="let item of reminderLengthDD" [value]="item.value">{{item?.text}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <span>Via Message Center</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <input type="checkbox" class="checkbox" formControlName="reminderCLP" />
                                                        </div>
                                                    </div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <span>Via Email</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <div class="inner-container margin-left-none">
                                                                <input type="checkbox" class="checkbox" formControlName="reminderEmail" />&nbsp;
                                                                <div>
                                                                    <span class="text-dark">Additional Email Addresses (seperated by comma)</span>
                                                                    <input type="text" class="form-control" formControlName="reminderEmails" />
                                                                    <span class="text-dark" *ngIf="apptData?.contact?.email">Note: Contact's email is {{apptData?.contact?.email}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <span>Via Text Message</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <div class="inner-container margin-left-none">
                                                                <div *ngIf="apptData.isTxtMsgActive">
                                                                    <input type="checkbox" class="checkbox" formControlName="isTxtMsgReminder" />
                                                                    <span>To {{apptData?.contact?.firstName}} {{apptData?.contact?.lastName}} ({{pixString(apptData?.contact?.mobile) | phoneFormat}})</span>
                                                                </div>
                                                                <div class="mt-2" *ngIf="user?.mobile !=''">
                                                                    <input type="checkbox" class="checkbox" formControlName="isTxtMsgUserReminder" />
                                                                    <span>To {{user?.firstName}} {{user?.lastName}} ({{pixString(user?.mobile) | phoneFormat}})</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <span>Reminder Text Template</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <select class="form-control" formControlName="reminderTxtMsgTemplateID">
                                                                <option [value]="0">-None Selected-</option>
                                                                <option *ngFor="let item of apptFilters?.filterTxtMsgTemplate" [value]="item.value">{{ item?.text }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <span>Reminder Email Template</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <select class="form-control" formControlName="reminderEmailTemplateID">
                                                                <option [value]="0">-Select an Email Template-</option>
                                                                <option *ngFor="let item of apptFilters?.filterInApptTemplate" [value]="item.value">{{ item?.text }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <span>Reminder Notes</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <div class="full-width-container">
                                                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" formControlName="reminderNote"></textarea>
                                                                <p class="text-success">(limit 2000 characters)</p>
                                                                <span>Note: This reminder note will be included in all reminder emails (including to any Additional Email Addresses specified above).</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <span>Send Confirmation upon save</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <input type="checkbox" class="checkbox" (change)="onClickIsSendConfirmation($event)" formControlName="isSendConfirmationUponSave" />
                                                        </div>
                                                    </div>
                                                    <div *ngIf="isCTxtMsgTemplate" class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <span>Confirmation Text Template</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <select class="form-control" formControlName="confirmationTxtMsgTemplateID">
                                                                <option [value]="0">-None Selected-</option>
                                                                <option *ngFor="let item of apptFilters?.filterTxtMsgTemplate" [value]="item.value">{{ item?.text }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="isCEmailTemplateID" class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <span>Confirmation Email Template</span>
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <select class="form-control" formControlName="confirmationEmailTemplateID">
                                                                <option [value]="0">-Select an Email Template-</option>
                                                                <option *ngFor="let item of apptFilters?.filterInApptTemplate" [value]="item.value">{{ item?.text }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </ng-template>
                                    </ng-template>
                                </kendo-tabstrip-tab>
                                <kendo-tabstrip-tab [title]="'Attachments'">
                                    <ng-template kendoTabContent>
                                        <div class="">
                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left">
                                                    <span>Linked Files</span>
                                                </div>
                                                <div class="cards-colunm-right">
                                                    <div *ngIf="apptId > 0 && linkedDocuments?.length>0">
                                                        <div *ngFor="let item of linkedDocuments; let idx=index;">
                                                            <div class="common-grid-section">
                                                                <div class="common-grid-row">
                                                                    <div class="common-grid-colunm">
                                                                        <img [src]="getDocIconPath(item?.documentType)">
                                                                    </div>
                                                                    <div class="common-grid-colunm">
                                                                        <a class="text-primary contact-cursor-pointer" title="Download Document" (click)="downloadDocuments(item?.documentId)"> {{item?.documentName}} </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <span *ngIf="!linkedDocuments?.length>0">None exist.</span>
                                                </div>
                                            </div>
                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left">
                                                    <span>Attached Files</span>
                                                </div>
                                                <div class="cards-colunm-right">
                                                    <div class="file-uploader" *ngIf="apptId >0">
                                                        <div class="border display-row">
                                                            <upload-document *ngIf="user" [loggedUser]="user" [ownerId]="apptId" [documentCategory]="eDocumentCategory.Appointment"></upload-document>

                                                            <!--<angular-file-uploader [config]="afuConfig" [resetUpload]=resetUpload (ApiResponse)="fileUpload($event)"></angular-file-uploader>-->
                                                        </div>
                                                    </div>
                                                    <span *ngIf="apptId ==0">Please attach files once this appointment has been created.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </kendo-tabstrip-tab>
                                <kendo-tabstrip-tab [title]="'Attendees'">
                                    <ng-template kendoTabContent>
                                        <ng-template [ngIf]="attendeesApptForm">
                                            <form [formGroup]="attendeesApptForm">
                                                <div class="">
                                                    <div class="cards-body-section">
                                                        <div class="cards-colunm-left">
                                                            <img src="../../../../../assets/leadstitle.svg" />
                                                        </div>
                                                        <div class="cards-colunm-right">
                                                            <div class="full-width-container">
                                                                <div class="inner-container">
                                                                    <select class="form-control inner-container-left" formControlName="teamCode" (change)="getNonAttendeeList()">
                                                                        <option [value]="0" selected>-Filter By Team-</option>
                                                                        <option *ngFor="let item of apptFilters?.filterAttendeeTeam" [value]="item.value">{{ item?.text }}</option>
                                                                    </select>
                                                                    <div class=" margin-left10">
                                                                        <select class="form-control inner-container-left" formControlName="officeCode" (change)="getNonAttendeeList()">
                                                                            <option [value]="0" selected>-Filter By Office-</option>
                                                                            <option *ngFor="let item of apptFilters?.filterAttendeeOffice" [value]="item.value">{{ item?.text }}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="attendees-section">
                                                                    <div class="switch-section">
                                                                        <div class="attendees-flex">
                                                                            <div class="attendees-header"><span>User</span></div>
                                                                            <div class="switch-left">
                                                                                <div class="example-container">
                                                                                    <div cdkDropList
                                                                                         #frmList="cdkDropList"
                                                                                         [cdkDropListData]="fromUserlist"
                                                                                         [cdkDropListConnectedTo]="[tolist]"
                                                                                         class="example-list"
                                                                                         (cdkDropListDropped)="drop($event)"
                                                                                         (click)="fromList_Onclick($event)">
                                                                                        <div class="example-box" *ngFor="let i of fromUserlist" cdkDrag (click)="fromList_Onclick($event)">
                                                                                            {{i?.text}}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="attendees-flex">
                                                                            <div class="switch-middle-btn">
                                                                                <button class="btn btn-primary" (click)="selectAll()"><i class="fas fa-angle-double-down"></i> <i class="fas fa-angle-double-right"></i></button>
                                                                                <button class="btn btn-primary" (click)="unSelectAll()"><i class="fas fa-angle-double-up"></i> <i class="fas fa-angle-double-left"></i></button>
                                                                            </div>
                                                                        </div>
                                                                        <div class="attendees-flex">
                                                                            <div class="attendees-header"><span>Attendees</span></div>
                                                                            <div class="switch-right">
                                                                                <div class="example-container">
                                                                                    <div cdkDropList
                                                                                         #tolist="cdkDropList"
                                                                                         [cdkDropListData]="toUserlist"
                                                                                         [cdkDropListConnectedTo]="[frmList]"
                                                                                         class="example-list"
                                                                                         (cdkDropListDropped)="drop($event)">
                                                                                        <div class="example-box" *ngFor="let i of toUserlist" cdkDrag>{{i?.text}}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="attendees-flex">
                                                                            <div class="attendees-header"><span>Messages</span></div>
                                                                            <div class="switch-right">
                                                                                <div class="msg-center" *ngFor="let item of attendeeOverLaps">
                                                                                    <span [ngClass]="{'text-danger': item?.isBusy}">{{item?.cLPUserID == user.cLPUserID ?'You':getUserName(item?.cLPUserID)}} is {{item?.isBusy ?'busy':'free'}}</span>
                                                                                </div>
                                                                                <div class="msg-center" *ngFor="let item of strProposedTimes">
                                                                                    <span>{{item}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <input type="checkbox" class="checkbox" formControlName="isPerformBusy" /> &nbsp;
                                                                    <span>Perform Free/Busy Check</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </ng-template>
                                    </ng-template>
                                </kendo-tabstrip-tab>
                                <kendo-tabstrip-tab [title]="'History'" *ngIf="(apptCategoryType ==2 || apptCategoryType ==3)">
                                    <ng-template kendoTabContent>
                                        <app-contact-activity-history *ngIf="apptCategoryType ==2" [contactHistory]="apptResponse?.contactHistory" [userddList]="userList" [contactId]="ownerId"></app-contact-activity-history>
                                        <lead-history *ngIf="apptCategoryType ==3" [leadIdReceive]=ownerId [leadHistory]="apptResponse?.leadHistory" [isAppointmentHistory]="true"></lead-history>
                                    </ng-template>
                                </kendo-tabstrip-tab>
                                <kendo-tabstrip-tab [title]="'Recurrence'" *ngIf="recurringApptlist?.length >0">
                                    <ng-template kendoTabContent>
                                        <div class="wraper-main-section">
                                            <div class="global-card-section">
                                                <div class="global-header-section">
                                                    <div class="svg-icon-panel"><img src="../../../../../assets/refreshbtn.svg" class="mr-1" />Other Occurrences</div>
                                                    <div class="header-button-panel">
                                                        <div class="button-wrapper">
                                                            <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="global-body-section">
                                                    <kendo-grid class="click-tracking-grid" #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                                                                [kendoGridBinding]="recurringApptlist"
                                                                [sortable]="{mode: 'multiple'}"
                                                                [sort]="_gridCnfgService.sort"
                                                                [pageSize]="_gridCnfgService.pageSize"
                                                                [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                                                [scrollable]="'false'"
                                                                [reorderable]="true"
                                                                [resizable]="true"
                                                                [columnMenu]="{ filter: true }"
                                                                (columnReorder)="_gridCnfgService.columnsOrderChanged('recurrence_appointment_grid', $event)"
                                                                (sortChange)="_gridCnfgService.sortChange('recurrence_appointment_grid', $event)"
                                                                (pageChange)="_gridCnfgService.pageChange('recurrence_appointment_grid', $event)"
                                                                (columnResize)="_gridCnfgService.columnResize(3,'recurrence_appointment_grid', $event)"
                                                                (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'recurrence_appointment_grid',grid)">

                                                        <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                                           [field]="column.field"
                                                                           [title]="column.title"
                                                                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                                           [width]="column.width | stringToNumber"
                                                                           [filterable]="true"
                                                                           [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                                                           [includeInChooser]="column.field=='$' ? false : true">
                                                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                                                <div class="customer-name" *ngIf="column.field == '$' && column.title == ''">{{ rowIndex+1 }}</div>
                                                                <div class="customer-name" *ngIf="column.field == 'category'">
                                                                    <a class="contact-cursor-pointer"><i class="icon_contact"><img src="../../../assets/icon_contact.svg" /></i></a>
                                                                </div>
                                                                <div class="customer-name" *ngIf="column.field == 'apptStartTime'">
                                                                    <a title="{{bindDynamicColumnAnchor(dataItem)}}">
                                                                        {{ dataItem[column.field] | date : dateFormat }}({{ dataItem[column.field] | date : dateFormat }})  &#64; {{ dataItem[column.field] | date : dateFormat }}
                                                                    </a>
                                                                </div>
                                                                <div class="customer-name" *ngIf="column.field == 'status'">{{ getApptStatus(dataItem[column.field])}}</div>
                                                            </ng-template>
                                                        </kendo-grid-column>
                                                    </kendo-grid>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </kendo-tabstrip-tab>
                                <kendo-tabstrip-tab [title]="'Custom Action'" [selected]="ocf">
                                    <ng-template kendoTabContent>
                                        <div class="">
                                            <div>
                                                <div class="global-padding10">
                                                    <select class="form-control" *ngIf="!isShowTempCus" [(ngModel)]="inApptTemplateID" (change)="loadEmailTemplate($event.target.value)">
                                                        <option *ngIf="apptCategoryType == 2 || apptCategoryType == 3" [value]="-1">-Select Custom Action Screen-</option>
                                                        <option [value]="0">-Select an Email Template-</option>
                                                        <option *ngFor="let itemTemp of apptFilters?.filterInApptTemplate" [value]="itemTemp.value">{{ itemTemp?.text }}</option>
                                                    </select>
                                                    <select class="form-control" *ngIf="isShowTempCus" [(ngModel)]="customActionScreenId" (change)="loadCustomAction($event.target.value)">
                                                        <option *ngIf="apptCategoryType == 2 || apptCategoryType == 3" [value]="0">-Select Custom Action Screen-</option>
                                                        <option [value]="-1">-Select an Email Template-</option>
                                                        <option *ngFor="let itemCA of apptFilters?.filterCustomAction" [value]="itemCA.value">{{ itemCA?.text }}</option>
                                                    </select>
                                                </div>
                                                <div *ngIf="!isShowTempCus" class="template-layout">
                                                    <div [innerHTML]="htmlText" *ngIf="htmlText"></div>
                                                </div>
                                                <div *ngIf="isShowTempCus">
                                                    <div *ngIf="pnlIHAddToCallList">
                                                        <div class="global-padding10">
                                                            <span>{{lblIHCISummary}}</span>
                                                        </div>
                                                        <div class="cards-body-section">
                                                            <div class="cards-colunm-left"><span>Contact Details</span></div>
                                                            <div class="cards-colunm-right"><div [innerHtml]="lblIHCILeft"></div></div>
                                                        </div>
                                                        <div class="cards-body-section">
                                                            <div class="cards-colunm-left"><span>Infomation</span></div>
                                                            <div class="cards-colunm-right"><div [innerHtml]="lblIHCIRight"></div></div>
                                                        </div>
                                                        <div class="cards-body-section">
                                                            <div class="cards-colunm-left"><span></span></div>
                                                            <div class="cards-colunm-right">
                                                                <div class="display-row">
                                                                    <input type="text" style="margin-right:10px" [(ngModel)]="txtIHCallListNote">
                                                                    <button class="btn btn-primary" (click)="saveIHCallListNote()">{{btnSaveIHCallListNote}}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="full-width-container mt-3 mb-3" *ngIf="showhideHistory">
                                                        <div class="custom-action-title mb-3">
                                                            <div>
                                                                <span>Add to Contact History</span>
                                                                <span class="text-success">(limit 2000 characters)</span>
                                                            </div>
                                                        </div>
                                                        <textarea class="form-control" [(ngModel)]="contactHistory" rows="3"></textarea>
                                                        <button class="btn btn-primary mt-3" id="saveHistory" (click)="saveToHistory()">Save</button>
                                                    </div>
                                                    <div class="full-width-container mt-3 mb-3" *ngIf="showhideComments">
                                                        <div class="custom-action-title mb-3">
                                                            <div>
                                                                <span>Add To Contact Comments</span>
                                                                <span class="text-success">(limit 2000 characters)</span>
                                                            </div>
                                                        </div>
                                                        <textarea class="form-control" [(ngModel)]="contactComments" rows="3"></textarea>
                                                        <button class="btn btn-primary mt-3" id="saveComment" (click)="saveToComments()">Save</button>
                                                    </div>
                                                    <div class="middle-center-panel" *ngFor="let customButton of customButtons">
                                                        <div contextmenu="center">
                                                            <div [innerHTML]="customButton?.instructions"></div>
                                                            <div *ngIf="customButton.buttonText != 'Custom Dropdown'">
                                                                <button id="customactionbutton" [ngStyle]="{'width':customButton.width+'px','backgroundColor':customButton.backColor,'font-weight':customButton.isFontBold ? 'bold' : '400','height':customButton.height +'px','color': customButton.foreColor,'font-size':customButton.fontSize +'px'}" (click)="clickCustomButton()">
                                                                    <span *ngIf="customButton.buttonText !=''">{{customButton.buttonText}}</span>
                                                                    <span *ngIf="customButton.buttonText ==''">Button Text</span>
                                                                </button>
                                                            </div>
                                                            <div *ngIf="customButton.buttonText == 'Custom Dropdown'">
                                                                <select id="customactiondropdown" class="form-control" [(ngModel)]="customActionDdItemId" (change)="getDDProcessing()">
                                                                    <option [value]="0">-Select One-</option>
                                                                    <option *ngFor="let customDropdown of filterCustomActionDD(customButton?.customActionButtonId)" [value]="customDropdown?.customActionDdItemId">{{customDropdown?.itemText}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </kendo-tabstrip-tab>
                            </kendo-tabstrip>
                            <div class="global-padding10" *ngIf="sbConfirmationAlert">
                                <div class="company-2d-alert mb-2">
                                    <span [innerHTML]="sbConfirmationAlert"></span>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left"></div>
                                <div class="cards-colunm-right">
                                    <div class="bottom-button-bar">
                                        <button class="btn btn-primary" [style.pointer-events]="disableButtonOnRequest?'none':''" [disabled]="disableButtonOnRequest" [hidden]="(roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false) && user?.userRole <= eUserRole.Administrator" type="submit" (click)="saveApptData()">
                                            <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
                                            <ng-template [ngIf]="buttonTypeOperation===1 ">Saving</ng-template>
                                            <app-loader></app-loader>
                                        </button>
                                        <button class="btn btn-cancel" type="button" (click)="cancelAppointment()">Cancel</button>
                                        <button class="btn btn-danger" type="button" data-toggle="modal" data-target="#deleteApptModal" (click)="showDeleteModal = true" *ngIf="apptId>0">Delete</button>
                                    </div>
                                    <div class="bottom-button-bar" *ngIf="apptId>0">
                                        <button class="btn btn-primary" type="button" (click)="saveNew()">Save and New</button>
                                        <button class="btn btn-primary" type="button" *ngIf="rApptID==0 && currentUrlAppt != 'overdueappt'" (click)="makeReccurring()">Make Recurring</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="apptSendEmailModal" *ngIf="sendMailInfo?.isShow" tabindex="-1" role="dialog" aria-labelledby="exampleTextModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg mt-5 mb-5" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body p-0">
                    <app-contact-email *ngIf="sendMailInfo?.isShow && user" [loggedUser]="user" (closeEmailModal)="hideSendMail()" [contactId]="sendMailInfo?.contactId"></app-contact-email>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="sendTextModal" tabindex="-1" role="dialog" aria-labelledby="exampleTextModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title white-contact" id="exampleTextModalLabel"><i class="fas fa-envelope-open-text white-contact"></i> Text Message</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="hideTextMail();">
                        <span class="white-contact" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <app-contact-sms *ngIf="user" [loggedUser]="user" [contactId]="contactId"></app-contact-sms>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="deleteApptModal" *ngIf="showDeleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteApptModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>Caution: This appointment will be permanently delete.</h2>
                <h2>Are you sure you want to delete this appointment? </h2>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="apptDelete();" [style.pointer-events]="showSpinner?'none':''" [disabled]="showSpinner" data-dismiss="modal" class="btn btn-primary">
                    <ng-template [ngIf]="buttonTypeOperation!=0">Ok</ng-template>
                    <ng-template [ngIf]="buttonTypeOperation===0">Deleting  <span> &nbsp;<i wrapper> </i></span></ng-template>
                </button>
                <button type="button" class="btn btn-cancel" (click)="showDeleteModal = false;">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="newCallCreateModal" *ngIf="isShowNewCallModal" tabindex="-1" role="dialog" aria-labelledby="newCallCreateModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title white-contact" id="exampleModalLabel"><img src="../../../../../assets/btnVoiceCall.svg" class="" />Voice Call</h5>
                    <div class="display-row">
                        <div class="button-wrapper">
                            <button type="button" class="icon-btn" aria-label="Close" (click)="isShowNewCallModal=false;">
                                <i class="fa fa-close mr-0"></i>
                                <span class="btn-text">Cancel</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <contact-new-call *ngIf="isShowNewCallModal && selectedContactData" [contactData]="selectedContactData" (updatedCall)="hideTextCallModal($event)" [callType]="selectedCallType" [user]="user"></contact-new-call>
                </div>
                <!--<div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="isShowNewCallModal=false;">Close</button>
                </div>-->
            </div>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
