import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';
import { SimpleResponse } from '../models/genericResponse.model';
import { MobileBlockLogListResponse, MobileOptLogListResponse, MobilePromoOptLogListResponse, TxtMsgMailingFilterResponse, TxtMsgSettingLoadResponse, TxtMsgSettings, TxtMsgSettingsResponse } from '../models/txtMsg.model';
import { eMobileBlockType, eMobileOptType } from '../models/enum.model';
import { TxtMsgTemplateResponse } from '../models/textMsgTemplate.model';

@Injectable({
  providedIn: 'root'
})
export class TxtMsgService {

  private baseUrl: string;
  private api: string = "api/TxtMsgSettings";

  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }
  async txtMsgSettings_Load(encryptedUser: string, clpcompnayId: number, clpuserId: number, slurrpyUserId: number): Promise<TxtMsgSettingsResponse | void> {
    const a = await this.httpClient
      .get<TxtMsgSettingsResponse>(`${this.baseUrl}/TxtMsgSettings_Load/${clpcompnayId}/${clpuserId}/${slurrpyUserId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpcompnayId + "," + "clpuserId - " + clpuserId + "," + "slurrpyUserId - " + slurrpyUserId, encryptedUser, "TxtMsgService", "txtMsgSettings_Load"); });
    return a;
  }
  
  async txtMsgSettingLoadByCompany(encryptedUser: string, clpcompnayId: number): Promise<TxtMsgSettingLoadResponse | void> {
    const a = await this.httpClient
      .get<TxtMsgSettingLoadResponse>(`${this.baseUrl}/TxtMsgSetting_LoadByCompany/${clpcompnayId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpcompnayId, encryptedUser, "TxtMsgService", "txtMsgSettingLoadByCompany"); });
    return a;
  }
  
  async txtMsgSettings_Save(encryptedUser: string, TxtMsgSettingsData: TxtMsgSettings): Promise<SimpleResponse | void> {
    const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/TxtMsgSettings_Save`, TxtMsgSettingsData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, TxtMsgSettingsData, "r - " + encryptedUser, encryptedUser, "TxtMsgService", "TxtMsgSettings_Save") });
    return a;

  }


  async txtMsgSettingsGetById(encryptedUser: string, clpCompanyId: number): Promise<TxtMsgSettingLoadResponse | void> {
    const a = await this.httpClient.get<TxtMsgSettingLoadResponse>(`${this.baseUrl}/TxtMsgSettingsGetById/${clpCompanyId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId, encryptedUser, "TxtMsgService", "txtMsgSettingsGetById") });
    return a;

  }
  async textMsgSettingDelete(encryptedUser: string, clpcompnayId: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/TxtMsgSetting_Delete/${clpcompnayId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpcompnayId - " + clpcompnayId, encryptedUser,"TxtMsgService", "textMsgSettingDelete") });
    return a;

  }

  async getTxtMsgMailingBlastFilter(encryptedUser: string, clpcompnayId: number, clpuserId: number, slurrpyUserId: number): Promise<TxtMsgMailingFilterResponse | void> {
    const a = await this.httpClient.get<TxtMsgMailingFilterResponse>(`${this.baseUrl}/Get_TxtMsgBlastFilterResponse/${clpcompnayId}/${clpuserId}/${slurrpyUserId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpcompnayId - " + clpcompnayId + "," + "clpuserId - " + clpuserId + "," + "slurrpyUserId - " + slurrpyUserId, encryptedUser, "TxtMsgService", "getTxtMsgMailingBlastFilter") });
    return a;
  }

  async configureBegin_RequestActivation(encryptedUser: string, clpCompanyId: number, clpuserId: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/ConfigureBegin_RequestActivation/${clpCompanyId}/${clpuserId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpuserId - " + clpuserId, encryptedUser, "TxtMsgService", "configureBegin_RequestActivation") });
    return a;
  }

  async configureNew_TxtMsgSettings(encryptedUser: string, clpCompanyId: number, clpuserId: number, slurrpyUserId: number): Promise<TxtMsgSettingsResponse | void> {
    const a = await this.httpClient.get<TxtMsgSettingsResponse>(`${this.baseUrl}/ConfigureNew_TxtMsgSettings/${clpCompanyId}/${clpuserId}/${slurrpyUserId}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpuserId - " + clpuserId + "," + "slurrpyUserId - " + slurrpyUserId, encryptedUser, "TxtMsgService", "configureNew_TxtMsgSettings") });
    return a;
  }

  async mobileBlockLogGetLatestType(encryptedUser: string, clpCompanyId: number, strMobile: string): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/MobileBlockLog_GetLatestType/${clpCompanyId}?strMobile=${strMobile}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "strMobile - " + strMobile, encryptedUser, "TxtMsgService", "mobileBlockLogGetLatestType") });
    return a;
  }

  async mobileOptLogGetLatestType(encryptedUser: string, clpCompanyId: number, strMobile: string): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/MobileOptLog_GetLatestType/${clpCompanyId}?strMobile=${strMobile}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "strMobile - " + strMobile, encryptedUser, "TxtMsgService", "mobileOptLogGetLatestType") });
    return a;
  }

  async mobileOptLogGetList(encryptedUser: string, clpCompanyId: number, strMobile: string): Promise<MobileOptLogListResponse | void> {
    const a = await this.httpClient.get<MobileOptLogListResponse>(`${this.baseUrl}/MobileOptLog_GetList/${clpCompanyId}?strMobile=${strMobile}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "strMobile - " + strMobile, encryptedUser, "TxtMsgService", "mobileOptLogGetList") });
    return a;
  }

  async mobilePromoOptLogGetList(encryptedUser: string, clpCompanyId: number, strMobile: string): Promise<MobilePromoOptLogListResponse | void> {
    const a = await this.httpClient.get<MobilePromoOptLogListResponse>(`${this.baseUrl}/MobilePromoOptLog_GetList/${clpCompanyId}?strMobile=${strMobile}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "strMobile - " + strMobile, encryptedUser, "TxtMsgService", "mobilePromoOptLogGetList") });
    return a;
  }

  async mobileBlockLogGetList(encryptedUser: string, clpCompanyId: number, strMobile: string): Promise<MobileBlockLogListResponse | void> {
    const a = await this.httpClient.get<MobileBlockLogListResponse>(`${this.baseUrl}/MobileBlockLog_GetList/${clpCompanyId}?strMobile=${strMobile}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "strMobile - " + strMobile, encryptedUser, "TxtMsgService", "mobileBlockLogGetList") });
    return a;
  }

  async mobileOptLogCreate(encryptedUser: string, clpCompanyId: number, strMobile: string, type: eMobileOptType, strNote: string): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/MobileOptLog_Create/${clpCompanyId}?strMobile=${strMobile}&type=${type}&strNote=${strNote}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "strMobile - " + strMobile + "," + "type - " + type + "," + "strNote - " + strNote, encryptedUser, "TxtMsgService", "mobileOptLogCreate") });
    return a;
  }

  async mobilePromoOptLogCreate(encryptedUser: string, clpCompanyId: number, strMobile: string, type: eMobileOptType, strNote: string): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/MobilePromoOptLog_Create/${clpCompanyId}?strMobile=${strMobile}&type=${type}&strNote=${strNote}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "strMobile - " + strMobile + "," + "type - " + type + "," + "strNote - " + strNote, encryptedUser, "TxtMsgService", "mobilePromoOptLogCreate") });
    return a;
  }

  async mobileBlockLogCreate(encryptedUser: string, clpCompanyId: number, strMobile: string, type: eMobileBlockType, strNote: string): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/MobileBlockLog_Create/${clpCompanyId}?strMobile=${strMobile}&type=${type}&strNote=${strNote}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "strMobile - " + strMobile + "," + "type - " + type + "," + "strNote - " + strNote, encryptedUser, "TxtMsgService", "mobileBlockLogCreate") });
    return a;
  }

  async txtMsgMarkAsCancel(encryptedUser: string, txtMsgID: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/TxtMsg_MarkAsCancel/${txtMsgID}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + encryptedUser
      })
    }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "txtMsgID - " + txtMsgID, encryptedUser, "TxtMsgService", "txtMsgMarkAsCancel") });
    return a;
    }

    async txtMsgTemplate_Load(encryptedUser: string, txtMsgTemplateID: number): Promise<TxtMsgTemplateResponse | void> {
        const a = await this.httpClient.get<TxtMsgTemplateResponse>(`${this.baseUrl}/TxtMsgTemplate_Load/${txtMsgTemplateID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "txtMsgTemplateID - " + txtMsgTemplateID, encryptedUser, "TxtMsgService", "txtMsgTemplate_Load") });
        return a;
    }
}
