import { Component, OnInit, ViewChild, NgZone, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { DataBindingDirective, GridItem } from '@progress/kendo-angular-grid';
import { process, DataResult } from '@progress/kendo-data-query';
import { ContactService } from '../../../services/contact.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { LocalService } from '../../../services/shared/local.service';
import { NotificationService } from '../../../services/notification.service';
import { Contact, ContactList, ContactListResponse } from '../../../models/contact.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { SimpleResponse, UserDD } from '../../../models/genericResponse.model';
import { CreateExportFor, eCustomSearchCategory, eExportRequestObjectType, eExportRequestStatus, eFeatures, eNoteOwnerType, eUserRole, SearchContactBy } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { ContactSearchService } from '../../../services/shared/contact-search.service';
import { ReportService } from '../../../services/report.service';
import { TagsFields } from '../../../models/tag-settings.model';
import { CreateExportRequestObj, ExportRequest } from '../../../models/exportRequest.model';
import { ContactSearchResultObj, ContactSearchResultResponse } from '../../../models/report.model';
import { ContactCommonSearchService } from '../../../services/shared/contact-common-search.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { ExportRequestService } from '../../../services/export-request.service';
import { SearchItem, SearchItemListResponse, SearchQuery, SearchQueryResponse } from '../../../models/search.model';
import { SendResultToNewObj } from '../../../models/report.model';
import { GlobalService } from '../../../services/global.service';
import { AppconfigService } from '../../../services/shared/appconfig.service';
import { ConfigDetails } from '../../../models/appConfig.model';
import { SearchContactService } from '../../../services/Searchcontact.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactCommonSearchComponent } from '../common/contact-common-search/contact-common-search.component';
declare var $: any;

@Component({
    selector: 'app-contact-list',
    templateUrl: './contactList.component.html',
    styleUrls: ['./contactList.component.css'],
    providers: [{ provide: 'GridConfigurationService', useClass: GridConfigurationService },
    { provide: 'GridConfigurationService1', useClass: GridConfigurationService }]
})

export class ContactListComponent implements OnInit {
    @ViewChild(ContactCommonSearchComponent) contactCommonSearchComponent!: ContactCommonSearchComponent;
    private encryptedUser: string = '';
    user: CLPUser;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    showSpinner: boolean = false;
    gridHeight;
    sendMailInfo: any = { isShow: false, contactId: 0 };
    eUserRole = eUserRole;
    exportColumnName = [
        { field: 'name', title: 'Name', width: '200' },
        { field: 'emailAddress', title: 'Email Address', width: '400' },
        { field: 'companyName', title: 'Company', width: '200' },
        { field: 'address', title: 'Address', width: '200' },
        { field: 'city', title: 'City', width: '100' },
        { field: 'state', title: 'State', width: '100' },
        { field: 'country', title: 'Country', width: '100' },
        { field: 'zip', title: 'Zip', width: '80' },
        { field: 'phone', title: 'Phone', width: '100' },
        { field: 'userName', title: 'User', width: '150' },
        { field: 'dtModifiedDisplay', title: 'Modified', width: '100' },
        { field: 'dtCreatedDisplay', title: 'Created', width: '100' },
    ]
    public contactsData: any[];
    public initContactsData: any[];
    public gridDataCount: DataResult;
    public mySelection: string[] = [];
    public recentSelectionKey: string[] = [];
    public recentContactsData: any[];
    public initRecentContactsData: any[];
    selectedId: number = 0;

    currentUrl: string = '';
    contactListResponse: ContactListResponse;
    recentContactListResponse: ContactListResponse;
    contactList: ContactList[] = [];
    recentContactList: ContactList[] = [];

    reorderColumnName: string = 'name,email,text,companyName,address:h,city:h,state:h,country:h,zip:h,emailAddress:h,phone,userName,dtModifiedDisplay,dtCreatedDisplay';
    reorderColumnNameRecent: string = 'name,email,text,companyName,address:h,city:h,state:h,country:h,zip:h,emailAddress:h,phone,userName,dtModifiedDisplay,dtCreatedDisplay';
    columnWidth: string = 'name:184,email:89,text:170,companyName:159,address:142,city:102,state:102,country:102,zip:82,emailAddress:162,phone:142,userName:142,dtModifiedDisplay:104,dtCreatedDisplay:112';
    recentColumnWidth: string = 'name:184,email:89,text:170,companyName:159,address:142,city:102,state:102,country:102,zip:82,emailAddress:162,phone:142,userName:142,dtModifiedDisplay:104,dtCreatedDisplay:112';
    arrColumnWidth: any[] = ['name:184,email:89,text:170,companyName:159,address:142,city:102,state:102,country:102,zip:82,emailAddress:162,phone:142,userName:142,dtModifiedDisplay:104,dtCreatedDisplay:112'];
    arrColumnWidthRecent: any[] = ['name:184,email:89,text:170,companyName:159,address:142,city:102,state:102,country:102,zip:82,emailAddress:162,phone:142,userName:142,dtModifiedDisplay:104,dtCreatedDisplay:112'];
    sortingColumn: string = 'dtCreatedDisplay:desc';
    recentSortingColumn: string = 'dtCreatedDisplay:desc';
    arrSortingColumn: any[] = [];
    arrSortingColumnRecent: any[] = [];

    reloadGridContact: boolean = true;

    //For dynamic columns
    columns = [
        { field: '$', title: 'Sr No.', width: '60' },
        { field: '$$', title: '  ', width: '40' },
        { field: 'name', title: 'Name', width: '184' },
        { field: 'email', title: 'Email', width: '89' },
        { field: 'text', title: 'Text', width: '170' },
        { field: 'companyName', title: 'Company', width: '159' },
        { field: 'address', title: 'Address', width: '142' },
        { field: 'city', title: 'City', width: '102' },
        { field: 'state', title: 'State', width: '102' },
        { field: 'country', title: 'Country', width: '102' },
        { field: 'zip', title: 'Zip', width: '82' },
        { field: 'emailAddress', title: 'Email Address', width: '162' },
        { field: 'phone', title: 'Phone', width: '142' },
        { field: 'userName', title: 'User', width: '142' },
        { field: 'dtModifiedDisplay', title: 'Modified', width: '104' },
        { field: 'dtCreatedDisplay', title: 'Created', width: '112' },
    ];

    recentColumns: any[] = [];
    contactColumnMenuRemovedArr: any[] = [];
    recentColumnMenuRemovedArr: any[] = [];
    valueSelected: string;
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

    savedQueryValue: string = '0';
    private getContactsSearchSubscription: Subscription;
    mobileColumnNames: string[] = [];

    //Tag Cloud & For Home search
    headerTitle: string = 'My Tag Cloud';
    contactListTagCloud: Contact[];
    isShowContactList: boolean = false;
    searchBy: SearchContactBy = SearchContactBy.TagCloud;
    tagSearchType: number = 2;
    selectedTags: TagsFields[] = [];
    showGridChild: boolean = false;
    //isShowTagCloud: boolean = true;

    // for download button & DD
    eStat: number = eExportRequestStatus.None;
    eType: number = eExportRequestObjectType.Contact;
    exportRequest: ExportRequest;
    createExportFor: number = CreateExportFor.leadCreate;
    includeMetrics: boolean = false;
    isExportDownload: boolean = false;
    customSearchID: number = 0;
    selectedDropdownId: number = 2;
    contactValuesDD: UserDD[];
    isMyContacts: boolean = true;
    clearSearch: boolean = true;
    //Calender
    public isShowFirstMonth: boolean = false;
    public isshowSecondMonth: boolean = false;
    public nextMonthDate: Date;
    dateFormat: string;
    contactSearchResultObj: ContactSearchResultObj = <ContactSearchResultObj>{};
    selectedContactData: any;
    selectedCallType: string;
    isShowNewCallModal: boolean;

    queryDataLoaded: SearchQueryResponse = <SearchQueryResponse>{};
    subscriptionQueryList: any;
    clpUserID: any;
    dropdownData: any[] = [
        { text: 'Option 1', value: 1 },
        { text: 'Option 2', value: 2 },
        { text: 'Option 3', value: 3 }
    ];
    selectedItem: any;
    searchInput: string;
    isCloudVisible: boolean;
    isShowNewSearch: boolean = false;
    customSearch: SearchQueryResponse;
    searchItemListResponse: SearchItemListResponse;
    searchItems: SearchItem[];
    showSaveSearch: boolean = false;
    showSaveSearchForm: boolean = false;
    searchName: string = '';
    saveSearchForm: FormGroup;
    constructor(public _contactService: ContactService,
        private _utilityService: UtilityService,
        public _localService: LocalService,
        private _router: Router,
        @Inject('GridConfigurationService') public _gridCnfgService: GridConfigurationService,
        @Inject('GridConfigurationService1') public _gridCnfgServiceRecent: GridConfigurationService,
        private datePipe: DatePipe,
        private notifyService: NotificationService,
        public _contactSearchService: ContactSearchService,
        public _globalService: GlobalService,
        private _contactCommonSearchService: ContactCommonSearchService,
        private _searchContactService: SearchContactService,
        private _ngZone: NgZone,
        private _route: ActivatedRoute,
        private _reportService: ReportService,
        private _appConfigService: AppconfigService,
        private fb: FormBuilder,
        private _exportService: ExportRequestService) {
        this.isCloudVisible = localStorage.getItem("isCloudVisible") ? JSON.parse(localStorage.getItem("isCloudVisible")) : true
        this.gridHeight = this._localService.getGridHeight('499px');
        this._localService.isMenu = true;
        this.subscribeToEvents();
        _router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const url = event?.url;
                const splitUrl = url?.split('/', 4);
                this.currentUrl = splitUrl.length > 0 ? splitUrl[1] : '';
            }
        });


    }

    ngOnInit() {
        this.saveSearchForm = this.prepareSaveSearchForm();
        this.contactValuesDD = this._localService.contactValuesDD;
        this.showSpinner = true;
        this.recentColumns = this.columns;
        window.localStorage.removeItem("custom_search");
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this._route.paramMap.subscribe(async params => {
                            if (params.has('isContactExit')) {
                                var item = +params.has('isContactExit');
                                if (item > 0) {
                                    this.notifyService.showError("Contact Does Not Exits", "Contact", 5000)
                                }
                            }
                        });
                        this.getContactSearch();
                        this.getRecentContacts();
                        this.getGridConfiguration();
                        this.getContacts();
                        this.defaultSearch();
                        this.getRecentGridConfiguration();
                        this.getQueryDataLoad();

                        this._appConfigService.getAppConfigValue(this.encryptedUser, "isShowNewSearch")
                            .then(async (result: ConfigDetails) => {
                                if (result) {
                                    this.isShowNewSearch = result?.configValue == "1" ? true : false;
                                }
                            })
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.ViewMyContacts)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userResponse = UtilityService.clone(result);
                    this.dateFormat = this.userResponse?.user?.dateFormat;
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contactList.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.user = this.user;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'contact_map_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('contact_map_grid').subscribe((value) => { }));
    }

    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'contact_map_grid').subscribe((value) => this.getGridConfiguration());
    }

    getRecentGridConfiguration() {
        this._gridCnfgServiceRecent.columns = this.columns;
        this._gridCnfgServiceRecent.reorderColumnName = this.reorderColumnName;
        this._gridCnfgServiceRecent.columnWidth = this.columnWidth;
        this._gridCnfgServiceRecent.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgServiceRecent.user = this.user;
        this._gridCnfgServiceRecent.getGridColumnsConfiguration(this.user.cLPUserID, 'contact_map_grid').subscribe((value) => this._gridCnfgServiceRecent.createGetGridColumnsConfiguration('contact_map_grid').subscribe((value) => { }));
    }

    resetRecentGridSetting() {
        this._gridCnfgServiceRecent.deleteColumnsConfiguration(this.user.cLPUserID, 'contact_map_grid').subscribe((value) => this.getRecentGridConfiguration());
    }

    private subscribeToEvents(): void {
        this._contactCommonSearchService.contactListChanged.subscribe((data) => {
            this._ngZone.run(() => this.contactsData = data)
        });
    }


    async getContacts() {
        this.showSpinner = true;
        await this._contactService.getContacts(this.encryptedUser, this.user?.cLPUserID)
            .then(async (result: ContactListResponse) => {
                if (result) {
                    this.contactListResponse = UtilityService.clone(result);
                    this.contactList = this.contactListResponse?.contactList;
                    this.contactsData = this.transformDate(this.contactList);
                    this.initContactsData = UtilityService.clone(this.contactList);
                    this._gridCnfgServiceRecent.iterateConfigGrid(this.contactList, "contact_map_grid");
                    this.contactColumnMenuRemovedArr = this.columns;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    transformDate(data) {
        data.forEach((item) => {
            item.dtCreatedDisplay = this.datePipe.transform(item.dtCreatedDisplay, 'yyyy-MM-dd');
        });
        return data;
    }

    async getRecentContacts() {
        await this._contactService.getRecentContacts(this.encryptedUser, this.user?.cLPUserID)
            .then(async (result: ContactListResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.recentContactListResponse = UtilityService.clone(result);
                    this.recentContactsData = this.recentContactListResponse.contactList;
                    this.initRecentContactsData = UtilityService.clone(this.recentContactsData);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    public onTabSelect(e) {
        this.showGridChild = false;
        //this.isShowTagCloud = e.title != "My Calendar";
        if (e.title == "My Contacts") {
            this.isMyContacts = true;
            if (this.savedQueryValue == '0' && (isNullOrUndefined(this.getContactsSearchSubscription)))
                this.getContacts();
        }
        else if (e.title == "My Calendar") {
            this._router.navigateByUrl("/calender");
        }
        else {
            this.isMyContacts = false;
            if (this.savedQueryValue == '0' && (isNullOrUndefined(this.getContactsSearchSubscription)))
                this.getRecentContacts();
        }
    }

    public onContactsFilter(inputValue: string, isContactFilter: boolean = true): void {
        const columnName: string[] = ['name', 'email', 'companyName', 'address', 'city', 'state', 'country', 'zip', 'emailAddress', 'phone', 'userName', 'dtModifiedDisplay', 'dtCreatedDisplay'];
        if (isContactFilter) {
            this.contactsData = process(this.initContactsData, {
                filter: {
                    logic: "or",
                    filters: this._localService.createFilterJson(columnName, inputValue),
                }
            }).data;
            this.dataBinding.skip = 0;
        } else {
            this.recentContactsData = process(this.initRecentContactsData, {
                filter: {
                    logic: "or",
                    filters: this._localService.createFilterJson(columnName, inputValue),
                }
            }).data;
            this.dataBinding.skip = 0;
        }
    }

    showColumn(columnDef): boolean {
        return columnDef ? (columnDef == 'email') || (columnDef == 'phone') ? false : true : true;
    }

    gotoLink(columnName, dataItem) {
        if (columnName == "email") {
            $('#sendEmail').modal('show');
            this.sendMailInfo.isShow = true;
            this.sendMailInfo.contactId = dataItem?.contactID;
        }
        else if (columnName == "userName") {
            this._router.navigate(['/edit-profile', dataItem?.clpUserId]);
        }
        else {
            this.clpUserID = dataItem.clpUserId
            if (this.user.timeZoneWinId != 0)
                this._router.navigate(['/contact', dataItem?.clpUserId, dataItem.contactID]);
            else {
                $('#timezoneModal').modal('show');
            }
            //this._localService.gotoLink(columnName, dataItem, this.user.timeZoneWinId);
        }
    }

    hideTextMail() {
        this.selectedId = 0;
        $('#sendTextModal').modal('hide');
    }

    showTextMail(contactId) {
        this.selectedId = contactId;
        $('#sendTextModal').modal('show');
    }

    addNewContact() {
        this._router.navigate(['/contact-create']);
    }

    public saveExcel(component): void {
        this._localService.saveExcel(component, 'Contact List');
    }

    giveAccessContact() {
        return !(this.roleFeaturePermissions?.create || this.roleFeaturePermissions?.edit);
    }

    //For Tag Cloud Api
    async getContactSearchList() {
        this.showSpinner = true;
        this.copyContactSearchValue();
        await this._reportService.getContactSearchResultList(this.encryptedUser, this.contactSearchResultObj)
            .then(async (result: ContactSearchResultResponse) => {
                if (!isNullOrUndefined(result))
                    //this.contactsData = UtilityService.clone(result?.contactList);
                    this.contactListTagCloud = UtilityService.clone(result?.contactList);
                this.contactsData = this.contactListTagCloud
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    copyContactSearchValue() {
        let tagIds: number[] = [];
        this.selectedTags.forEach(item => { tagIds.push(item?.tagID) });
        this.contactSearchResultObj.clickId = 0;
        this.contactSearchResultObj.clpUserId = this.user?.cLPUserID;
        this.contactSearchResultObj.clpCompanyId = this.user?.cLPCompanyID;
        this.contactSearchResultObj.startDate = "";
        this.contactSearchResultObj.endDate = "";
        this.contactSearchResultObj.ownerType = eNoteOwnerType.Contact;
        this.contactSearchResultObj.searchContactBy = this.searchBy;
        this.contactSearchResultObj.selectedTagIds = tagIds;
        this.contactSearchResultObj.tagSearchType = this.tagSearchType;
    }

    tagCloudData(data) {
        this.selectedTags = data.selectedTags;
        this.tagSearchType = data.tagSearchType;
        this.showGridChild = true;
        this.getContactSearchList();
    }
    public trackBy(index: number, item: GridItem): any {
        return index;
    }

    downloadExport() {
        if (this.user?.userRole >= 3 || this.user?.slurpyUserId > 0) {
            if (this.isExportDownload) {
                this.createExportRequest();
                this.isExportDownload = false;
                this.includeMetrics = false;
            }
            else {
                this.isExportDownload = true;
                this.includeMetrics = false;
            }
        }
        else
            this.createExportRequest();
    }

    setExportRequest() {
        this.exportRequest = <ExportRequest>{};
        this.exportRequest.cLPUserID = this.user?.cLPUserID;
        this.exportRequest.whereClause = '';
        this.exportRequest.orderBy = '';
        this.exportRequest.objectType = this.eType;
        this.exportRequest.cLPCompanyID = this.user.cLPCompanyID;
        this.exportRequest.fileName = eExportRequestObjectType[this.eType];
        this.exportRequest.includeMetrics = this.includeMetrics;
    }

    async createExportRequest() {
        this.showSpinner = true;
        //this.setExportRequest();

        var exportRequest = <CreateExportRequestObj>{};
        exportRequest.searchQueryObj = <SearchQueryResponse>{};
        exportRequest.exportRequestObj = <ExportRequest>{};
        exportRequest.exportRequestObj.cLPUserID = this.user?.cLPUserID;
        exportRequest.exportRequestObj.whereClause = '';
        exportRequest.exportRequestObj.orderBy = '';
        exportRequest.exportRequestObj.objectType = eExportRequestObjectType.Contact;
        exportRequest.exportRequestObj.cLPCompanyID = this.user.cLPCompanyID;
        exportRequest.exportRequestObj.fileName = "contact_export";
        exportRequest.exportRequestObj.includeMetrics = this.includeMetrics;
        exportRequest.searchQueryObj = this.queryDataLoaded;
        await this._exportService.exportRequestCreate(this.encryptedUser, exportRequest, eExportRequestObjectType.Contact)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result?.messageBool)
                        this.notifyService.showSuccess('Your export request has been submitted successfully.', '', 3000);
                    else
                        this.notifyService.showError(result?.errorMsg, '', 3000);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    goToRoute(value) {
        this.selectedDropdownId = value;
        this._localService.goToCustomSearchRoute(value, this.customSearchID);
    }

    getCustomSearchId() {
        this.showSpinner = true;
        var SendResultToNewObj: SendResultToNewObj = <SendResultToNewObj>{};
        SendResultToNewObj.clpCompanyId = this.user?.cLPCompanyID;
        SendResultToNewObj.clpUserId = this.user?.cLPUserID;
        SendResultToNewObj.searchContactBy = SearchContactBy.Contact;
        SendResultToNewObj.selectedDropdownId = this.selectedDropdownId;
        SendResultToNewObj.searchQueryObj = this.queryDataLoaded;
        this._reportService.getCbSendResultsToNewSelectedIndexChanged(this.encryptedUser, SendResultToNewObj)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result))
                    this.customSearchID = UtilityService.clone(result?.messageInt);
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    resetGridData() {
        this.searchInput = ""
        if (!isNullOrUndefined(this.contactList)) {
            this.contactsData = this.transformDate(this.contactList);
        }
        this.clearSearch = false;
        setTimeout(() => this.clearSearch = true, 100);
    }

    onCompanySelect(companyId) {
        this._router.navigateByUrl(`company-create?cid=${companyId}`);
    }

    hideTextMsgModal(sms?: boolean) {
        this.selectedId = 0;
        $('#sendTextModal').modal('hide');
    }

    async openModalNewCall(contact, callType: string) {
        this.selectedContactData = contact
        this.selectedCallType = callType;
        this.isShowNewCallModal = true;
    }

    hideSendMail() {
        $('#sendEmail').modal('hide');
        this.sendMailInfo.isShow = false;
        this.sendMailInfo.contactId = 0;
    }

    async hideTextCallModal() {
        $('#newCallCreateModal').modal('hide');
        this.isShowNewCallModal = false;
    }


    getQueryDataLoad() {
        this.subscriptionQueryList = this._contactCommonSearchService.getqueryListChangedChangeEmitter().subscribe((data) => {
            this._ngZone.run(() => {
                this.queryDataLoaded = data;
                if (isNullOrUndefined(this.queryDataLoaded))
                    this.defaultSearch();
                else
                    this.getCustomSearchId();
            })
        });
    }

    defaultSearch() {
        var searchQuery: SearchQuery = <SearchQuery>{};
        searchQuery.cLPUserID = this.user.cLPUserID;
        searchQuery.controlType = "md";
        searchQuery.operator = "IN";
        searchQuery.searchItem = "CLPUserID";
        searchQuery.searchItemValue = this.user.cLPUserID.toString();
        searchQuery.tableName = "contact";
        this.queryDataLoaded = <SearchQueryResponse>{};
        this.queryDataLoaded.searchQueryList = [];
        this.queryDataLoaded.searchQueryList.push(searchQuery);
        this.getCustomSearchId();
    }

    ngOnDestroy(): void {
        this.getContactsSearchSubscription?.unsubscribe();
    }

    toggleCloudVisibility() {
        this.isCloudVisible = !this.isCloudVisible
        localStorage.setItem("isCloudVisible", JSON.stringify(this.isCloudVisible))
    }

    isRunSearch(event) {
        this.showSaveSearch = true;
        this.showSaveSearchForm = false;
        let cSearch = JSON.parse(localStorage.getItem('custom_search'));
        if (cSearch != undefined) {
            this.customSearch = cSearch;
            console.log(this.customSearch)
        }
    }

    getOperatorName(operator) {
        let opName = ''
        switch (operator) {
            case "=":
                opName = 'Equal'
                break;
            case "<>":
                opName = 'Not Equal'
                break;
            case "LIKE":
                opName = 'Contains'
                break;
            case "IN":
                opName = 'Contains Any'
                break;
            case "NOT IN":
                opName = 'Does Not Contain'
                break;
            case "CA":
                opName = 'Contains All'
                break;
            case "R":
                opName = 'Range'
                break;
            case "dt":
                opName = 'Date'
                break;
            case ">":
                opName = 'Greater Than'
                break;
            case "<":
                opName = 'Less Than'
                break;
            case ">=":
                opName = 'Greater Than Equal To'
                break;
            case "<=":
                opName = 'Less Than Equal To'
                break;
            case "NOT LIKE":
                opName = 'Does Not Contain'
                break;
            case "STARTS":
                opName = 'Starts With'
                break;
            case "ENDS":
                opName = 'Ends With'
                break;

        }
        return opName;
    }

    async getContactSearch() {
        await this._searchContactService.getSearchFields(this.encryptedUser, this.user.cLPCompanyID)
            .then(async (result: SearchItemListResponse) => {
                if (result) {
                    this.searchItemListResponse = UtilityService.clone(result);
                    console.log(this.searchItemListResponse)
                    this.searchItems = this.searchItemListResponse.searchItems.sort((a, b) => a.displayValue.localeCompare(b.displayValue));

                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-new-search.getContactSearch", err.message, null,
                    'cLPCompanyID: ' + this.user.cLPCompanyID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getSearchItemName(searchItem) {
        let itemName = '';

        let searchObj = this.searchItems?.filter((item) => item?.columnName == searchItem?.searchItem)[0];
        switch (searchItem?.controlType) {
            case 'md':
                let valuesArr = searchItem?.searchItemValue.split(",").map(Number);
                itemName = searchObj?.itemData
                    .filter(item => valuesArr.includes(item.value))
                    .map(item => item.display)
                    .join(", ");
                break;
            case 't':
                itemName = searchItem?.searchItemValue;
                break;
            case 'dt':
                itemName = searchItem?.strDtStart + " - " + searchItem?.strDtEnd;
                break;
            case 'd':
                itemName = searchObj?.itemData
                    .filter(item => item?.value == searchItem?.searchItemValue)[0]?.display
                break;
        }

        return itemName;
    }

    private prepareSaveSearchForm(): FormGroup {
        return this.fb.group({
            SearchQueryName: ['', [Validators.required]]
        });
    }

    SubmitSearch() {

        if ((this.saveSearchForm.valid)) {
            this.showSpinner = true;
            let searchQueryName = this.saveSearchForm.controls['SearchQueryName'].value
            console.log(searchQueryName)
            this._searchContactService.SavedSearchQueries_Update(this.encryptedUser, this.customSearch, this.user.cLPUserID, 0, searchQueryName, eCustomSearchCategory.Contact)
                .then(res => {
                    if (res) {
                        var response = UtilityService.clone(res);
                        console.log(response)
                        this.saveSearchForm.reset();
                        this.saveSearchForm.get('SearchQueryName').setValue('');
                        this.contactCommonSearchComponent.getContactSearch();
                        this.notifyService.showSuccess("Search Query has been saved successfully!", "", 3000);
                        this.showSaveSearch = false;
                        this.showSpinner = false;
                    }
                    else {
                        this.notifyService.showError("Error during Submitting Search. Please contact administrator!", "", 3000);
                        this.showSpinner = false;
                    }
                }).catch((err: HttpErrorResponse) => {
                    this.showSpinner = false;
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            if (!this.saveSearchForm.valid)
                this.validateAllFormFields(this.saveSearchForm);
        }
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched();
                control.updateValueAndValidity();
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    get saveSearchFrm() {
        return this.saveSearchForm.controls;
    }
}

