import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, DDFields, DDFieldsResponse, UserResponse } from '../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { ContactList } from '../../../models/contact.model';
import { DocumentListResponse, Document } from '../../../models/document';
import { EmailTemplateLoad, MailMergeTemplate, MailMergeTemplateListResponse } from '../../../models/emailTemplate.model';
import { eDDField, eFeatures, eMailingCategory, eMailingStatus, eTempMMDocumentStatus, eTempMMDocumentType, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { Mailing, MailingSaveRequest } from '../../../models/mailing.model';
import { MailingContact, TempMMDocument } from '../../../models/mailingContact.model';
import { MailMergeTemplateLoad } from '../../../models/marketing.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { SearchQueryResponse } from '../../../models/search.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ContactService } from '../../../services/contact.service';
import { EmailBlastService } from '../../../services/email-blast.service';
import { EmailTemplateService } from '../../../services/email-template.service';
import { GlobalService } from '../../../services/global.service';
import { MarketingService } from '../../../services/marketing.service';
import { MyDocumentService } from '../../../services/my-document.service';
import { OutBoundEmailService } from '../../../services/outBoundEmail.service';
import { ContactCommonSearchService } from '../../../services/shared/contact-common-search.service';
import { ContactSearchService } from '../../../services/shared/contact-search.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-direct-mail',
  templateUrl: './direct-mail.component.html',
  styleUrls: ['./direct-mail.component.css'],
  providers: [GridConfigurationService, ContactCommonSearchService]
})
export class DirectMailComponent {

  isProcess: boolean = false;
  queryDataLoaded: SearchQueryResponse = <SearchQueryResponse>{};
  eUserRole = eUserRole;
  movedDirectMailData: ContactList[] = [];
  public mailMergeTemplateList: MailMergeTemplate[];
  mailingSaveRequest = <MailingSaveRequest>{};
  mailingData = <Mailing>{};
  public step: number = 1;
  mailMergeOption: number = 1;
  mailingId: number = 0;
  //csId: number = 0;
  //isCustomSearch: boolean = false;
  showSpinner: boolean = false;
  roleFeaturePermissions: RoleFeaturePermissions;
  user: CLPUser;
  private encryptedUser: string = '';
  userResponse: UserResponse;
  gridHeight;
  companyData: ClpCompany = <ClpCompany>{};
  directMailForm: FormGroup;
  mobileColumnNames: string[];
  public format = {
    displayFormat: "",
    inputFormat: "dd/MM/yy",
  };
  dateFormat: string = "MM/dd/yyyy";
  isInternational: boolean = false;
  documentList: any[] = [];
  ddMailingTypeCode: DDFields[] = [];
  documents: Document[] = [];
  isSlurrpy: boolean = false;
  isShowLinkedDocuments: boolean = false;
    disableBtn: boolean;
  constructor(@Inject('BASE_URL') _baseUrl: string,
    public _contactService: ContactService,
    private _accountSetupService: AccountSetupService,
    private _emailTemplateService: EmailTemplateService,
      private _marketingService: MarketingService,
      private userService: UserService,
    private _emailBlastService: EmailBlastService,
    private _documentService: MyDocumentService,
    private _utilityService: UtilityService,
    public _localService: LocalService,
    private fb: FormBuilder,
      private _router: Router,
      private _globalService: GlobalService,
    private _route: ActivatedRoute,
    private datepipe: DatePipe,
    public _gridCnfgService: GridConfigurationService,
    public _contactSearchService: ContactSearchService,
    private _outBoundEmailService: OutBoundEmailService) {
    this._localService.isMenu = true;
  }

    ngOnInit() {
        this.directMailForm = this.prepareDirectMailForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.dateFormat = this.user.dateFormat;
                        // For Nagvigating URL to - from view direct mail to direct mail. 
                        //this._route.paramMap.subscribe(async params => {   
                        //  if (params.has('id')) {
                        //    this.csId = +params.get('id');
                        //    this.isCustomSearch = true;
                        //  }
                        //}); 
                        this.getCompanyData(() => {
                            if (!this.companyData?.secMarketingTool) {
                                if (this.user?.userRole == eUserRole.General)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                            /* this.setUp();*/
                        })
                    }
                    else
                        this._router.navigate(['/login']);
                })
            }
            else
                this._router.navigate(['/login']);
        })
    }

  private async authenticateR() {
    this.showSpinner = true;
    await this._localService.authenticateUser(this.encryptedUser, eFeatures.CreateNewMailing)
      .then(async (result: UserResponse) => {
        if (result) {
          this.userResponse = UtilityService.clone(result);
          if (!isNullOrUndefined(this.userResponse)) {
            if (!isNullOrUndefined(this.userResponse?.user)) {
              this.user = this.userResponse.user;
              this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
              if (this.user?.userRole <= eUserRole.Administrator) {
                if (this.roleFeaturePermissions?.view == false)
                  this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
              }
            }
          }
        }
        this.showSpinner = false;
      })
        .catch((err: HttpErrorResponse) => {
            this._globalService.error("direct-mail-campaign.authenticateR", err.message, null, 'Features ' + eFeatures.CreateNewMailing);
        this.showSpinner = false;
        this._utilityService.handleErrorResponse(err);
      });
  }

  setUpCLPMailMerge() {
    this.getDDFieldList();
    if (this.companyData?.isHTMLEmailIncluded) {
      if (this.user?.cLPCompanyID == 0 || this.user?.slurpyUserId > 0)
        this.isSlurrpy = true;
        this.mailMergeTemplate_GetList();
      this.directMailForm.get("mailMergeOption").setValue(1);
    } else {
      this.directMailForm.get("mailMergeOption").setValue(2);
    }
  }


  async goToNext(id) {
    switch (id) {
      case 1:
        this.directMailForm = this.prepareDirectMailForm();
        await this.setUpCLPMailMerge();
        this.step = 2;
        break;
      case 2:
        this.directMailForm.markAllAsTouched();
        if (this.directMailForm.controls.mailMergeOption.value == 1) {
          if (this.directMailForm.valid && this.directMailForm.controls.mailMergeTemplateID.value != 0) {
            await this.copyDirectMailFormValueToData()
            this.step = 3;
          }
        } else {
          if (this.documentList.length > 0)
            await this.getDocumentListByDocIds();
          await this.copyDirectMailFormValueToData()
          this.step = 3;
        }

        break;
      case 3:
        await this.mailingFinishAndConfirm();
        this.step = 4;
        break;
    }
  }

  async getDDFieldList() {
    await this.userService.getDDFieldList(this.encryptedUser, eDDField.MailingTypeCode, this.user?.cLPCompanyID)
      .then(async (result: DDFieldsResponse) => {
        if (!isNullOrUndefined(result))
          this.ddMailingTypeCode = UtilityService.clone(result?.lstDDFields);
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("direct-mail.getDDFieldList", err.message, null, 'eDDField ' + eDDField.MailingTypeCode + 'cLPCompanyID ' + this.user?.cLPCompanyID);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getDocumentListByDocIds() {
    await this._documentService.getDocumentListByDocIds(this.encryptedUser, this.documentList)
      .then(async (result: DocumentListResponse) => {
        if (!isNullOrUndefined(result))
          this.documents = UtilityService.clone(result?.documents);
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("direct-mail.getDocumentListByDocIds", err.message, this.documentList);
        this._utilityService.handleErrorResponse(err);
      });
    }

    async mailMergeTemplate_GetList() {
    await this._outBoundEmailService.mailMergeTemplate_GetList(this.encryptedUser, this.user.cLPCompanyID, this.user?.cLPUserID, true)
      .then(async (result: MailMergeTemplateListResponse) => {
        if (!isNullOrUndefined(result)) {
          var response = UtilityService.clone(result);
          this.mailMergeTemplateList = response?.mailMergeTemplateList;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("direct-mail.mailMergeTemplate_GetList", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'includeShared ' + true);
        this._utilityService.handleErrorResponse(err);
      });
  }

  prepareDirectMailForm() {
    return this.fb.group({
      subject: new FormControl('', [Validators.required]),
      mailingTypeCode: new FormControl(0, [Validators.required]),
      fromType: new FormControl(1),
      toType: new FormControl(1),
      mailMergeTemplateID: new FormControl(0),
        score: new FormControl(0, [Validators.max(100), Validators.min(-100), Validators.required]),
      body: new FormControl(''),
      mailingStartTime: new FormControl(new Date()),
      mailMergeOption: new FormControl(1),
      documentList: new FormControl(''),
    })
  }

  async copyDirectMailFormValueToData() {
    this.mailingData.cLPUserID = this.user.cLPUserID;
    this.mailingData.cLPCompanyID = this.user.cLPCompanyID;
    this.mailingData.category = eMailingCategory.Mail;
    this.mailingData.subject = this.directMailForm.controls.subject.value;
    this.mailingData.body = this.directMailForm.controls.body.value;

    this.mailingData.documentList = "";
    this.documentList.forEach(item => {
      this.mailingData.documentList += item + ',';
    })
    this.mailingData.documentList = this.mailingData.documentList.substring(0, this.mailingData.documentList.length - 1);


    this.mailingData.mailingStartTime = this.directMailForm.controls.mailingStartTime.value;
    this.mailingData.strMailingStartTime = this.datepipe.transform(this.directMailForm.controls.mailingStartTime.value.toString(), 'MMddyyyy HHmmssa');
    this.mailingData.mailingTypeCode = this.directMailForm.controls.mailingTypeCode.value;
    this.mailingData.fromType = 0;
    this.mailingData.toType = 0;
    this.mailingData.mailMergeTemplateID = this.directMailForm.controls.mailMergeTemplateID.value;
    this.mailingData.customSearchID = 0;
    this.mailingData.score = this.directMailForm.controls.score.value;
    if (this.directMailForm.controls.mailMergeOption.value == 2)
      this.mailingData.status = eMailingStatus.Completed;

    /* ----------- mailingContacts----------------*/
    var mailingContactList: MailingContact[] = [];
    await this.movedDirectMailData.forEach((item) => {
      var mailingContact = <MailingContact>{};
      mailingContact.contactID = item?.contactID;
      mailingContact.fromAddress = item?.address;
      mailingContact.mailingID = this.mailingId;
      mailingContactList.push(mailingContact);
    });

    /* --------------mailingDocument----------------------------*/
    var mailingDocument = <TempMMDocument>{}
    mailingDocument.clpCompanyID = this.user.cLPCompanyID;
    mailingDocument.clpUserID = this.user.cLPUserID;
    mailingDocument.phSubject = this.directMailForm.controls.subject.value;
    mailingDocument.phBody = this.directMailForm.controls.body.value;
    mailingDocument.mailMergeTemplateID = this.directMailForm.controls.mailMergeTemplateID.value;
    mailingDocument.ownerID = this.mailingId.toString();
    mailingDocument.ownerType = eTempMMDocumentType.Mailing;
    mailingDocument.status = eTempMMDocumentStatus.Pending;

    this.mailingSaveRequest.mailing = this.mailingData;
    this.mailingSaveRequest.mailingContacts = mailingContactList;
    this.mailingSaveRequest.tempMMDocument = mailingDocument;
    this.mailingSaveRequest.isInternational = this.isInternational;
    this.mailingSaveRequest.processAll = this.isProcess;
    this.mailingSaveRequest.customSearchID = 0;
    this.mailingSaveRequest.rdMMOption = this.directMailForm.controls.mailMergeOption.value;
    this.mailingSaveRequest.searchQueryResponse = this.queryDataLoaded;
  }
  
  async mailingFinishAndConfirm() {
    this.disableBtn = true
    await this._emailBlastService.mailingFinishAndConfirm(this.encryptedUser, this.mailingSaveRequest)
      .then(async (result: SimpleResponse) => {
        if (result) {
          this.disableBtn = false
          this.mailingId = UtilityService.clone(result?.messageInt);
          this._router.navigate(['/marketing/mailing'], { queryParams: { mid: this.mailingId, isnew: 'y' } })
        }
        else
          this.disableBtn = false
      })
      .catch((err: HttpErrorResponse) => {
        this.disableBtn = false
          this._globalService.error("direct-mail.mailingFinishAndConfirm", err.message, this.mailingSaveRequest);
        this._utilityService.handleErrorResponse(err);
      });
  }

  getDisplayData(type: string, value: number) {
    switch (type) {
      case 'mailMergeTemplate':
        var mailMergeTemplate = this.mailMergeTemplateList.filter(item => item.mailMergeTemplateID == value);
        return mailMergeTemplate ? mailMergeTemplate[0]?.templateName : '-Select One-';
      case 'mailingType':
        var mailType = this.ddMailingTypeCode.filter(item => item.classCode == value);
        return mailType ? mailType[0]?.display : '-None Selected-';
    }
  }

  onMailMergeChange() {
    this.directMailForm.controls.subject.setValue('');
    this.directMailForm.controls.subject.markAsPristine();
  }

  async getCompanyData(callback) {
    await this._accountSetupService.getClpCompany(this.encryptedUser, this.user.cLPCompanyID)
      .then(async (result: CompanyResponse) => {
        if (result) {
          var response = UtilityService.clone(result);
          this.companyData = response.company;
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("direct-mail.getCompanyData", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
        this._utilityService.handleErrorResponse(err);
      });
    callback();
  }

  getAttachedList(value: any) {
    this.documentList = value;
  }

  loadMailMergeTemplate(mailMergeTemplateId) {
    this._marketingService.loadMailMergeTemplate(this.encryptedUser, mailMergeTemplateId)
      .then((result: MailMergeTemplateLoad) => {
        if (result) {
          var response = UtilityService.clone(result);
          localStorage.setItem('object', JSON.stringify(response.hTMLText));
          const url = this._router.serializeUrl(
            this._router.createUrlTree([`/template-preview/${mailMergeTemplateId}`])
          );
          window.open(url)
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("direct-mail.loadMailMergeTemplate", err.message, null, 'mailMergeTemplateId ' + mailMergeTemplateId);
        this._utilityService.handleErrorResponse(err);
      });
  }

}
