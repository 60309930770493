<div class="global-padding10">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-body-section">
                <div class="text-center" *ngIf="liveConnectTextMsgIBViewResponse" style="display:block">
                    <div class="margin-all-area">
                        <h6 style="font-weight:600;">{{liveConnectTextMsgIBViewResponse?.companyName}}&nbsp;| LiveConnect Rep: {{liveConnectTextMsgIBViewResponse?.repName}}</h6>
                    </div>
                </div>
                <div *ngIf="confirmMsg ! ''" class="message-info margin-all-area" [innerHTML]="confirmMsg">
                </div>
                <div class="global-padding10" *ngIf="liveConnectTextMsgIBViewResponse">
                    <div class="flex-row-inner">
                        <div class="full-width-container">
                            <div class="alert-info">Enter message reply.</div>
                        </div>
                    </div>
                    <div class="inherit-panel">
                        <div class="flex-row-inner">
                            <div class="flex-width1">
                                <div [innerHTML]="progressBarstring | safeHtml"></div>
                                <br />
                            </div>
                        </div>
                    </div>
                    <div class="flex-row-inner">
                        <div class="full-width-container">
                            <div [innerHTML]="liveConnectTextMsgIBViewResponse?.strcontactInfo"></div>
                            <div [innerHTML]="liveConnectTextMsgIBViewResponse?.duplicateDisplaySummary"></div>
                            <div><button type="button" class="btn btn-secondary" (click)="goToContact()">View Contact</button>
                                 <button *ngIf="this.clpCompanyData.isClubModuleInstalled" type="button" class="btn btn-secondary" (click)="OpenContract()">View Contract</button></div>
                            <!--<div *ngIf="this.clpCompanyData.isClubModuleInstalled"><button type="button" class="btn btn-warning btn-block" (click)="OpenContract()">View Contract</button></div>-->
                        </div>
                        <div class="full-width-container">
                            <label><b>Inbound Message</b></label><br />
                            <div [innerHTML]="liveConnectTextMsgIBViewResponse?.ibMessage"></div><br />
                        </div>
                    </div>
                </div>
                <!--------------------------------------send sms form ------------------------------------------------------------>
                <form class="form-horizontal required-section" *ngIf="liveConnectTextMsgIBViewResponse" [formGroup]="sendSMSForm">
                    <div class="wraper-main-section">
                        <div class="global-body-section">
                            <div class="global-padding10">
                                <div class="full-width-container">
                                    <div class="form-group">
                                        <span class="control-label" for="fromNumber">From Number</span>
                                        <select class="form-control" formControlName="fromNumber">
                                            <option *ngFor="let item of liveConnectTextMsgIBViewResponse?.ddFromNumber" [value]="item.id">{{item.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="full-width-container">
                                    <div class="form-group">
                                        <span class="control-label" for="to">To</span>
                                        <select class="form-control" formControlName="toChoice">
                                            <option *ngFor="let item of liveConnectTextMsgIBViewResponse?.ddToChoice" [value]="item.id">{{item.text}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="full-width-container">
                                    <div class="form-group">
                                        <span class="control-label" for="template">Template</span>
                                        <!--(filterChange)="handleFilter($event)"
                                        (selectionChange)="templateChange($event)"-->
                                        <kendo-combobox [data]="liveConnectTextMsgIBViewResponse?.rddTemplate"
                                                        textField="text"
                                                        valueField="id"
                                                        [filterable]="true"
                                                        [valuePrimitive]="true"
                                                        formControlName="selectedRddTemplate"
                                                        (selectionChange)="templateChange($event)">
                                        </kendo-combobox>
                                    </div>
                                </div>
                                <div class="full-width-container">
                                    <div class="form-group">
                                        <div class="row" style="margin-top:10px;">
                                            <div id="pnlIFrmAVideoBtn" class="col-sm-3" style="margin-top:10px;">
                                                <button type="button" (click)="showAudioVideo()" id="btnShowAV" class="btn btn-danger btn-block">Record Audio/Video</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="full-width-container">
                                    <div class="form-group">
                                        <span class="control-label" for="mediaImages">Media Images</span>
                                        <kendo-combobox [data]="liveConnectTextMsgIBViewResponse?.ddMediaImges"
                                                        textField="documentTitle"
                                                        valueField="documentId"
                                                        [filterable]="true"
                                                        [suggest]="true"
                                                        placeholder="-Select Image-"
                                                        [allowCustom]="true"
                                                        [(ngModel)]="selectedItem"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (valueChange)="onChangeMedia($event)">
                                            <ng-template kendoComboBoxItemTemplate let-dataItem>
                                                <img width="40" height="40" [src]="dataItem.imagePath" /> &nbsp; {{dataItem.documentTitle}}
                                            </ng-template>
                                        </kendo-combobox>
                                    </div>
                                </div>
                                <div class="full-width-container" *ngIf="user?.cLPCompanyID == 1644">
                                    <div class="form-group">
                                        <span class="control-label" for="gbcMedia">GBC Media</span>
                                        <select class="form-control">
                                            <option *ngFor="let item of liveConnectTextMsgIBViewResponse?.gbcMediaDD" [value]="item?.documentId">{{item?.documentTitle}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="full-width-container">
                                    <div class="form-group">
                                        <!--<angular-file-uploader *ngIf="isShowFileUploader" [config]="afuConfig" (ApiResponse)="fileUpload($event)"></angular-file-uploader>-->
                                        <div class="uploader-panel" *ngIf="isShowFileUploader">
                                            <kendo-upload [saveUrl]="uploadSaveUrl"
                                                          [restrictions]="uploadRestrictions"
                                                          (success)="fileUploadSuccess($event)"
                                                          (error)="fileUploadError($event)"
                                                          [saveHeaders]="fileUploadHeaders"
                                                          [autoUpload]="false">
                                            </kendo-upload>
                                            <span>&nbsp;&nbsp;(doc,docx,xls,xlsx,txt,pdf,html,htm,ppt,pptx,png,gif,jpg,tif) Size Limit: 3MB</span>
                                        </div>
                                        <button type="button" class="btn btn-primary" (click)="onNewUpload()">
                                            <span class="btn-text">{{isShowFileUploader ? 'Cancel' : 'Upload Image'}}</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="full-width-container">
                                    <div class="form-group">
                                        <span class="control-label" for="mediaUrl">Media URL</span>
                                        <input [hidden]="!isShowMediaUrlBox" type="text" class="form-control" formControlName="mediaUrl" />
                                        <span [hidden]="isShowMediaUrlBox">{{sendSMSForm.controls.mediaUrl.value}}</span>
                                    </div>
                                    <div class="form-group" *ngIf="sendSMSForm.controls.mediaUrl.value != ''">
                                        <img [src]="sendSMSForm.controls.mediaUrl.value" class="txtmsg-image" />
                                    </div>
                                </div>
                                <div *ngIf="isShowAudioVideo && user">
                                    <div style="height:auto; width:auto">
                                        <app-lc-audio-video-recording *ngIf="isShowAudioVideo && user" [slideId]="liveConnectItem?.objectID" [pageType]="2" [vipId]="0" [videoLimit]="10"  [user]="user"></app-lc-audio-video-recording>
                                    </div>
                                </div>
                                <div class="full-width-container">
                                    <div class="form-group">
                                        <span class="control-label" for="textMsg">Text Message</span> (max 500 chars)&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('👍')">👍</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('✌️')">✌️</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('👋')">👋</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('🙂')">🙂</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('😂')">😂</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('🤔')">🤔</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('😳')">😳</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('💪')">💪</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('🤞')">🤞</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('🤝')">🤝</a>&nbsp;
                                        <a class="contact-cursor-pointer" (click)="addText('👀')">👀</a>
                                        <textarea [hidden]="!isShowTxtBox" class="form-control" formControlName="msg"
                                                  [ngClass]="{'has-error': sendSMSForm.controls.msg.errors && (sendSMSForm.controls.msg.touched || sendSMSForm.controls.msg.dirty)}"></textarea>
                                        <div class="login-error" *ngIf="sendSMSForm.controls.msg.errors && (sendSMSForm.controls.msg.touched || sendSMSForm.controls.msg.dirty)">
                                            <span *ngIf="sendSMSForm.controls.msg?.value?.length > 500">Max Reached. </span>
                                        </div>                                       
                                        <span [hidden]="isShowTxtBox">{{sendSMSForm.controls.msg.value}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="global-padding10">
                                <button type="button" class="btn btn-primary btntxtmsg btn-block" (click)="sendTxtMsg()">Send</button>
                            </div>
                        </div>
                    </div>
                </form>
                <!-------------------------------------------send sms form end-------------------------------------------------------------------->
                <div class="global-padding10" *ngIf="liveConnectTextMsgIBViewResponse">
                    <div class="flex-row-inner">
                        <div class="full-width-container">
                            <div><button type="button" class="btn btn-success btn-block mt-3 ml-0" (click)="makeCall()">Make Call</button></div>
                            <div><button type="button" class="btn btn-dark btn-block mt-3" (click)="sendEmail()">Send Email</button></div>
                            <div><button type="button" *ngIf="liveConnectTextMsgIBViewResponse?.isVIP" (click)="launchSlidecast()" class="btn btn-primary active btn-block mt-3 ml-0" aria-pressed="true">Launch Slidecast</button></div>
                            <!--OptIn opt out section-->
                            <div *ngIf="optInSection">
                                <button type="button" (click)="onOptinByUser()" *ngIf="optInByUserBtn" class="btn btn-danger btn-block mt-3 mb-3 ml-0">Opt In</button>
                                <div *ngIf="optInOptionSection">
                                    <div *ngIf="isAllowVerbalOptinOption">
                                        <input type="checkbox" class="checkbox" (change)="isOptInConfirmed = !isOptInConfirmed"  /> &nbsp;By checking this box, I confirm that {{liveConnectContactViewResponse?.contact?.mobile  | phoneFormat }} has provided express consent to receive text messages.<br />
                                        <button type="button" (click)="onOptinVerbal()" [disabled]="!isOptInConfirmed" class="btn btn-warning btn-block mt-3">Opt In &nbsp; {{liveConnectContactViewResponse?.contact?.mobile | phoneFormat }} </button>
                                    </div>
                                    <div *ngIf="isOptInAp">
                                        <button type="button" (click)="onOptinAP()" class="btn btn-warning btn-block mt-3">Send Opt-In Instructions</button>
                                    </div>
                                </div>
                            </div>
                            <div class="danger-btn" *ngIf="isShowOptOutBtn"><button type="button" class="btn btn-danger btn-block mt-3 mb-3 ml-0" (click)="OptOutOptIn()">{{isConfirmOptOut ? 'Confirm Opt Out' : 'Opt Out'}}</button></div>
                            <div><button type="button" *ngIf="liveConnectTextMsgIBViewResponse?.isCustomAction && !isShowCustomAction" (click)="isShowCustomAction = !isShowCustomAction" class="btn btn-secondary btn-block btmsgcontact btn-block">Custom Actions</button></div>

                        </div>
                        <div class="full-width-container margin-left10">
                            <div><button type="button" class="btn btn-outline-dark btn-block mt-3" (click)="addNote()">Add Note</button></div>
                            <div class="self-guided-btn"><button type="button" *ngIf="liveConnectTextMsgIBViewResponse?.isSendSGVIP" (click)="onSelfGuided()" class="btn btn-secondary btn-block mt-3">Self Guided</button></div>
                            <div><button type="button" *ngIf="liveConnectTextMsgIBViewResponse?.eBType != eMobileBlockType.Block" (click)="onBlock()" class="btn btn-outline-danger btn-block mt-3">{{isConfirm ? 'Confirm Block' : 'Block'}}</button></div>
                        </div>
                    </div>
                </div>
                <lc-cas-display *ngIf="isShowCustomAction && user" [customButtons]="liveConnectTextMsgIBViewResponse?.customButtons" [contactID]="liveConnectTextMsgIBViewResponse?.contact?.contactID" [customActionDD]="liveConnectTextMsgIBViewResponse?.customActionDD" [user]="user"></lc-cas-display>
            </div>
        </div>
    </div>
</div>


<div class="loader-body" *ngIf="showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
