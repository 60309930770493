import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CMClubSiteConfigListResponse, CMSiteListResponse } from '../models/cm-club-service.model';
import { delayedRetryHttp } from './shared/delayedRetry';

import { UtilityService } from './shared/utility.service';

@Injectable({
    providedIn: 'root'
})
export class CMClubService {
    private baseUrl: string;
    private api: string = 'api/CMClub';
    clubId = new BehaviorSubject<number>(0)
    curClubId = this.clubId.asObservable()
    site = new BehaviorSubject<{}>(null)
    curSite = this.site.asObservable()
    baseUrlAI: string;
    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }
    async clubSiteConfigSearch(encryptedUser: string, id: number, clubID: number, teamCode: number, clpcompanyID: number, isAI: boolean): Promise<CMClubSiteConfigListResponse | void> {
        const a = await this.httpClient
            .get<CMClubSiteConfigListResponse>(`${this.baseUrl}/CMClubSiteConfig_Search/${clpcompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "clubSiteConfigSearch"); });
        return a;
    }

    async siteSearch(encryptedUser: string, siteID: number, clubID: number, class1Code: number, clpcompanyID: number, isAI: boolean): Promise<CMSiteListResponse | void> {
        const a = await this.httpClient
            .get<CMSiteListResponse>(`${this.baseUrl}/CMSite_Search/${siteID}/${clpcompanyID}/${class1Code}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + encryptedUser, "CMClubService", "siteSearch"); });
        return a;
    }
}
