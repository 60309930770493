import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser } from '../../../../models/clpuser.model';
import { eUserRole } from '../../../../models/enum.model';
import { DropDownItem } from '../../../../models/genericResponse.model';
import { HTMLEmailLogUsageByMonth, HTMLEmailLogUsageByMonthListResponse, HTMLEmailPricing, TxtMsgLogGetMonthListResponse } from '../../../../models/htmlEmailPricing.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { BilligService } from '../../../../services/billing.service';
import { GlobalService } from '../../../../services/global.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';

@Component({
    selector: 'text-msg-usage',
    templateUrl: './text-msg-usage.component.html',
    styleUrls: ['./text-msg-usage.component.css']
})
/** text-msg-usage component*/
export class TextMsgUsageComponent {
  @Input() encryptedUser: string;
  @Input() user: CLPUser;
  @Input() roleFeaturePermissions: RoleFeaturePermissions;
  selMonthDisplay: string = "";
  selMonthValue: string = "";
  showSpinner: boolean = false;
  public htmlEmailLogGetMonths: DropDownItem[];
  public htmlEmailLogUsageByMonth: HTMLEmailLogUsageByMonth[];
  selYear: string;
  eUserRole = eUserRole;

  constructor(public _localService: LocalService,
      private _billingService: BilligService,
      private _globalService: GlobalService,
      private _utilityService: UtilityService,
    private _router: Router) {

  }

  ngOnInit(): void {

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              if (this.user) {
                  if (this.user?.userRole <= eUserRole.Administrator) {
                      if (this.roleFeaturePermissions?.view == false)
                          this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                      else
                          this.loadInit();
                  }
                  else
                      this.loadInit();
              }
              else
                  this._router.navigate(['/login']);
          }
          else
              this._router.navigate(['/login']);
      });
  }

  loadInit() {
    this.htmlEmailLogGetMonthList();
  }

  async soHtmlEmailLogUsageByMonth(dtmth, dtyr) {
    this.showSpinner = true;
    await this._billingService.txtMsgLogUsageByMonthByUser(this.encryptedUser,this.user.cLPUserID, this.user.cLPCompanyID, dtmth, dtyr)
      .then(async (result: HTMLEmailLogUsageByMonthListResponse) => {
        if (result)
          this.htmlEmailLogUsageByMonth = UtilityService.clone(result?.hTMLEmailLogUsageByMonths);
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("txt-msg-usage.soHtmlEmailLogUsageByMonth", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + "dtmth " + dtmth + "," + "dtyr " + dtyr);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async htmlEmailLogGetMonthList() {
    await this._billingService.txtMsgLogGetMonthList(this.encryptedUser, this.user.cLPCompanyID, this.user?.cLPUserID)
      .then(async (result: TxtMsgLogGetMonthListResponse) => {
        if (result) {
          this.htmlEmailLogGetMonths = UtilityService.clone(result?.txtMsgLogMonths);
          if (this.htmlEmailLogGetMonths?.length > 0) {
            this.selMonthValue = this.htmlEmailLogGetMonths[0].value;
            this.selMonthDisplay = this.htmlEmailLogGetMonths[0].text;
            this.setMonth(this.selMonthValue);
          }
          else {
            this.selMonthValue = "0";
            this.selMonthDisplay = "";
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("txt-msg-usage.htmlEmailLogGetMonthList", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  setMonth(e:string) {
    if (e != "0") {
      this.selMonthValue = e;
      this.selMonthDisplay = this.htmlEmailLogGetMonths?.find(x => x.value == e)?.text;
      this.soHtmlEmailLogUsageByMonth(e.substring(4, 6), e.substring(0, 4));
    }
  }
}
