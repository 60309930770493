<div class="custom-security-link">
    <a [routerLink]="['/outlook-integration']" routerLinkActive="active">Outlook Integration</a>
    <a [routerLink]="['/gsettings']" routerLinkActive="active">Google Integration</a>
    <a [routerLink]="['/smtpsettings']" routerLinkActive="active">SMTP Email Settings</a>
    <a [routerLink]="['/user-prefs']" routerLinkActive="active">User Preferences</a>
    <a [routerLink]="['/user-defaults']" routerLinkActive="active">User Default</a>
    <a [routerLink]="['/cm-user-prefs']" routerLinkActive="active">Club User Preferences</a>
</div>

<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../assets/clpusertitle.svg" class="mr-1" />Club User Preferences: &nbsp;{{user?.firstName}}&nbsp;{{user?.lastName}}</div>
                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <button *ngIf="!isViewMode" type="button" matTooltip="Save" (click)="cMUserPref_Save()" class="icon-btn">
                            <i class="fa fa-save" aria-hidden="true"></i>
                            <span class="btn-text">Save</span>
                        </button>
                        <button type="button" *ngIf="isViewMode" matTooltip="Edit" class="icon-btn" (click)="onEdit()">
                            <i class="fa fa-pencil-alt" aria-hidden="true"></i>
                            <span class="btn-text">Edit</span>
                        </button>
                        <button type="button" matTooltip="Cancel Webform" class="icon-btn" *ngIf="!isViewMode" (click)="isViewMode = true;onCancelEdit()">
                            <span class="btn-text">Cancel</span>
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="global-body-section">
                <form [formGroup]="cmUserPrefForm" (ngSubmit)="cMUserPref_Save()">
                    <div class="align-top-style">
                        <div class="wraper-body-panel">
                            <div class="wraper-body-left">
                                <div class="custom-action-title">
                                    <span>General Defaults</span>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div class="">
                                            <span class="control-label" for="firstName">Default Site</span>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <div class="w-100" *ngIf="!isViewMode">
                                            <select class="form-control" formControlName="defaultSiteID">
                                                <option *ngFor="let item of ddCMSites" [value]="item?.id"> {{ item?.text}} </option>
                                            </select>
                                        </div>
                                        <div *ngIf="isViewMode">
                                            <span>{{getSiteName(cmUserPref?.defaultSiteID)}}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div class="">
                                            <span class="control-label" for="lastName">Initial Email Template</span>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <div class="w-100" *ngIf="!isViewMode">
                                            <select class="form-control" formControlName="initialETID">
                                                <option *ngFor="let item of ddEmailTemplates" [value]="item?.id"> {{ item?.text}} </option>
                                            </select>
                                        </div>
                                        <div *ngIf="isViewMode">
                                            <span>{{ getEmailTempName(cmUserPref?.initialETID)}}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <div class="">
                                            <span class="control-label" for="title">Call Recording Alert</span>
                                        </div>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <div class="full-width-container" *ngIf="!isViewMode">
                                            <select class="form-control" formControlName="vCRAlertCLPUserID">
                                                <option [value]="-1">Select</option>
                                                <!--<option *ngFor="let name of ddTheme" [value]="name"></option>-->
                                            </select>
                                        </div>
                                        <div *ngIf="isViewMode">
                                            <span>-Not Selected-</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="cards-body-section" *ngIf="!isViewMode">
                                    <div class="cards-colunm-left">
                                        <span class="control-label" for="companyName">Call Recording Alert (BCC)</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <div class="full-width-container">
                                            <div class="full-width-container">
                                                <input type="text" class="form-control" formControlName="vCRAlertBCC" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="custom-action-title">
                                        <span>SAM Automation - First Three Messages</span>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label" for="add1">First Text</span>
                                                <span class="control-label text-success">PH_CMUP_1SMSMessageText</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <select class="form-control" formControlName="pH1SMSTemplateID" (change)="onChangeTxtTemplate($event, 1)">
                                                    <option *ngFor="let item of ddTxtMsgTemplates" [value]="item?.id"> {{ item?.text}} </option>
                                                </select>
                                                <textarea class="form-control mt-2" formControlName="pH1SMSMessageText"></textarea>
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.pH1SMSMessageText}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label" for="add2">First Text MMS</span>
                                                <span class="control-label text-success">PH_CMUP_1SMSMediaURL</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" formControlName="pH1SMSMediaURL" />
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.pH1SMSMediaURL}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label" for="add3">Second Text</span>
                                                <span class="control-label text-success">PH_CMUP_2SMSMessageText</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <select class="form-control" formControlName="pH2SMSTemplateID" (change)="onChangeTxtTemplate($event, 2)">
                                                    <option *ngFor="let item of ddTxtMsgTemplates" [value]="item?.id"> {{ item?.text}} </option>
                                                </select>
                                                <textarea class="form-control mt-2" formControlName="pH2SMSMessageText"></textarea>
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.pH2SMSMessageText}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label" for="zip">Second Text MMS</span>
                                                <span class="control-label text-success">PH_CMUP_2SMSMediaURL</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" formControlName="pH2SMSMediaURL" />
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.pH2SMSMediaURL}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label" for="city">Third Text</span>
                                                <span class="control-label text-success">PH_CMUP_3SMSMessageText</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <select class="form-control" formControlName="pH3SMSTemplateID" (change)="onChangeTxtTemplate($event, 3)">
                                                    <option *ngFor="let item of ddTxtMsgTemplates" [value]="item?.id"> {{ item?.text}} </option>
                                                </select>
                                                <textarea class="form-control mt-2" formControlName="pH3SMSMessageText"></textarea>
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.pH3SMSMessageText}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label" for="state">Third Text MMS</span>
                                                <span class="control-label text-success">PH_CMUP_3SMSMediaURL</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" formControlName="pH3SMSMediaURL" />
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.pH3SMSMediaURL}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="custom-action-title">
                                            <span>SAM Automation - New Contact Auto Reply Defaults</span>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label" for="facebookURL">Long Code Call-in Text</span>
                                                    <span class="control-label text-success">PH_CMUP_LCVCInMessageText</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="full-width-container" *ngIf="!isViewMode">
                                                    <div>
                                                        <select class="form-control" formControlName="pHLCVCInTemplateID" (change)="onChangeTxtTemplate($event, 4)">
                                                            <option *ngFor="let item of ddTxtMsgTemplates" [value]="item?.id"> {{ item?.text}} </option>
                                                        </select>
                                                        <textarea class="form-control mt-2" formControlName="pHLCVCInMessageText"></textarea>
                                                    </div>
                                                </div>
                                                <div *ngIf="isViewMode">
                                                    <span>{{cmUserPref?.pHLCVCInMessageText}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label" for="twitterURL">Long Code Call-in MMS</span>
                                                    <span class="control-label text-success">PH_CMUP_LCVCInMediaURL</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="full-width-container" *ngIf="!isViewMode">
                                                    <input type="text" class="form-control" formControlName="pHLCVCInMediaURL">
                                                </div>
                                                <div *ngIf="isViewMode">
                                                    <span>{{cmUserPref?.pHLCVCInMediaURL}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label" for="linkedInURL">Long Code Text-in Text</span>
                                                    <span class="control-label text-success">PH_CMUP_LCSMSInMessageText</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="full-width-container" *ngIf="!isViewMode">
                                                    <div>
                                                        <select class="form-control" formControlName="pHLCSMSInTemplateID" (change)="onChangeTxtTemplate($event, 5)">
                                                            <option *ngFor="let item of ddTxtMsgTemplates" [value]="item?.id"> {{ item?.text}} </option>
                                                        </select>
                                                        <textarea class="form-control mt-2" formControlName="pHLCSMSInMessageText"></textarea>
                                                    </div>
                                                </div>
                                                <div *ngIf="isViewMode">
                                                    <span>{{cmUserPref?.pHLCSMSInMessageText}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label" for="linkedInURL">Long Code Text-in MMS</span>
                                                    <span class="control-label text-success">PH_CMUP_LCSMSInMediaURL</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <!--<input type="text" class="form-control" id="linkedInURL" formControlName="linkedInURL" placeholder="LinkedIn Page URL" />-->
                                                <div class="full-width-container" *ngIf="!isViewMode">
                                                    <input type="text" class="form-control" formControlName="pHLCSMSInMediaURL">
                                                </div>
                                                <div *ngIf="isViewMode">
                                                    <span>{{cmUserPref?.pHLCSMSInMediaURL}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="custom-action-title">
                                            <span>SAM Automation - 24 hour Reminders</span>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label" for="emailFormat">After Personal Tour Text</span>
                                                    <span class="control-label text-success">PH_CMUP_24hrTourMessageText</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="full-width-container" *ngIf="!isViewMode">
                                                    <select class="form-control" id="emailFormat" formControlName="pH24hrTourTemplateID" (change)="onChangeTxtTemplate($event, 6)">
                                                        <option *ngFor="let item of ddTxtMsgTemplates" [value]="item?.id"> {{ item?.text}} </option>
                                                    </select>
                                                    <textarea class="form-control mt-2" formControlName="pH24hrTourMessageText"></textarea>
                                                </div>
                                                <div *ngIf="isViewMode">
                                                    <span>{{cmUserPref?.pH24hrTourMessageText}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label" for="timeZoneWinId">After Personal Tour MMS</span>
                                                    <span class="control-label text-success">PH_CMUP_24hrTourMediaURL</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="w-100" *ngIf="!isViewMode">
                                                    <textarea class="form-control mt-2" formControlName="pH24hrTourMediaURL"></textarea>
                                                </div>
                                                <div *ngIf="isViewMode">
                                                    <span>{{cmUserPref?.pH24hrTourMediaURL}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label" for="dateFormatId">After Agreement Sent Text</span>
                                                    <span class="control-label text-success">PH_CMUP_24hrContractMessageText</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="w-100" *ngIf="!isViewMode">
                                                    <select class="form-control" formControlName="pH24hrContractTemplateID" (change)="onChangeTxtTemplate($event, 7)">
                                                        <option *ngFor="let item of ddTxtMsgTemplates" [value]="item?.id"> {{ item?.text}} </option>
                                                    </select>
                                                    <textarea class="form-control mt-2" formControlName="pH24hrContractMessageText"></textarea>
                                                </div>
                                                <div *ngIf="isViewMode">
                                                    <span>{{cmUserPref?.pH24hrContractMessageText}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label" for="dateFormatId">After Agreement Sent MMS</span>
                                                    <span class="control-label text-success">PH_CMUP_24hrContractMediaURL</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="w-100" *ngIf="!isViewMode">
                                                    <textarea class="form-control mt-2" formControlName="pH24hrContractMediaURL"></textarea>
                                                </div>
                                                <div *ngIf="isViewMode">
                                                    <span>{{cmUserPref?.pH24hrContractMediaURL}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="custom-action-title">
                                            <span>SAM Automation - Other Settings</span>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label" for="emailFormat">Send Agreement SMS</span>
                                                    <span class="control-label text-success">PH_CMUP_SendContractMessageText</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="full-width-container" *ngIf="!isViewMode">
                                                    <select class="form-control" formControlName="pHSendContractTemplateID" (change)="onChangeTxtTemplate($event, 8)">
                                                        <option *ngFor="let item of ddTxtMsgTemplates" [value]="item?.id"> {{ item?.text}} </option>
                                                    </select>
                                                    <textarea class="form-control mt-2" formControlName="pHSendContractMessageText"></textarea>
                                                </div>
                                                <div *ngIf="isViewMode">
                                                    <span>{{cmUserPref?.pHSendContractMessageText}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div>
                                                    <span class="control-label" for="timeZoneWinId">Send Agreement MMS</span>
                                                    <span class="control-label text-success">PH_CMUP_SendContractMediaURL</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <div class="w-100" *ngIf="!isViewMode">
                                                    <input type="text" class="form-control" formControlName="pHSendContractMediaURL" />
                                                </div>
                                                <div *ngIf="isViewMode">
                                                    <span>{{cmUserPref?.pHSendContractMediaURL}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wraper-body-right">
                                <div>
                                    <div class="custom-action-title">
                                        <span>Text Message Keyword</span>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label" for="mobile">First Text</span>
                                                <span class="control-label text-success">PH_CMUP_1KWMessageText</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="w-100" *ngIf="!isViewMode">
                                                <select class="form-control" formControlName="pH1KWTemplateID" (change)="onChangeTxtTemplate($event, 9)">
                                                    <option *ngFor="let item of ddTxtMsgTemplates" [value]="item?.id"> {{ item?.text}} </option>
                                                </select>
                                                <textarea class="form-control mt-2" formControlName="pH1KWMessageText"></textarea>
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.pH1KWMessageText}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label" for="phone">First Text MMS</span>
                                                <span class="control-label text-success">PH_CMUP_1KWMediaURL</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" formControlName="pH1KWMediaURL" />
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.pH1KWMediaURL}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label" for="altPhone">Second Text</span>
                                                <span class="control-label text-success">PH_CMUP_2KWMessageText</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <select class="form-control" formControlName="pH2KWTemplateID" (change)="onChangeTxtTemplate($event, 10)">
                                                    <option *ngFor="let item of ddTxtMsgTemplates" [value]="item?.id"> {{ item?.text}} </option>
                                                </select>
                                                <textarea class="form-control mt-2" formControlName="pH2KWMessageText"></textarea>
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.pH2KWMessageText}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label" for="fax">Second Text MMS</span>
                                                <span class="control-label text-success">PH_CMUP_2KWMediaURL</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" formControlName="pH2KWMediaURL" />
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.pH2KWMediaURL}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label" for="fax">Third Text</span>
                                                <span class="control-label text-success">PH_CMUP_3KWMessageText</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <select class="form-control" formControlName="pH3KWTemplateID" (change)="onChangeTxtTemplate($event, 11)">
                                                    <option *ngFor="let item of ddTxtMsgTemplates" [value]="item?.id"> {{ item?.text}} </option>
                                                </select>
                                                <textarea class="form-control mt-2" formControlName="pH3KWMessageText"></textarea>
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.pH3KWMessageText}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label">Third Text MMS</span>
                                                <span class="control-label text-success">PH_CMUP_3KWMediaURL</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" formControlName="pH3KWMediaURL" />
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.pH3KWMediaURL}}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label">Fourth Text</span>
                                                <span class="control-label text-success">PH_CMUP_4KWMessageText</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <select class="form-control" formControlName="pH4KWTemplateID" (change)="onChangeTxtTemplate($event, 12)">
                                                    <option *ngFor="let item of ddTxtMsgTemplates" [value]="item?.id"> {{ item?.text}} </option>
                                                </select>
                                                <textarea class="form-control mt-2" formControlName="pH4KWMessageText"></textarea>
                                            </div>
                                            <div class="full-width-container" *ngIf="isViewMode">
                                                <span>{{cmUserPref?.pH4KWMessageText}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label" for="email">Fourth Text MMS</span>
                                                <span class="control-label text-success">PH_CMUP_4KWMediaURL</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" formControlName="pH4KWMediaURL" />
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.pH4KWMediaURL}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div class="full-width-container">
                                                <span class="control-label" for="otherEmail">Fifth Text</span>
                                                <span class="control-label text-success">PH_CMUP_5KWMessageText</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <select class="form-control" formControlName="pH5KWTemplateID" (change)="onChangeTxtTemplate($event, 13)">
                                                    <option *ngFor="let item of ddTxtMsgTemplates" [value]="item?.id"> {{ item?.text}} </option>
                                                </select>
                                                <textarea class="form-control mt-2" formControlName="pH5KWMessageText"></textarea>
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.pH5KWMessageText}}</span>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label" for="useBothEmail">Fifth Text MMS</span>
                                                <span class="control-label text-success">PH_CMUP_5KWMediaURL</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" formControlName="pH5KWMediaURL" />
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.pH5KWMediaURL}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="custom-action-title">
                                        <span>Dynamic Call Routing</span>
                                        <div style="text-align:right">
                                            <input *ngIf="!isViewMode" class="checkbox" type="checkbox" formControlName="isDCRActive">
                                            <span *ngIf="cmUserPref?.isDCRActive" style="color: darkgreen; ">&nbsp; Active</span>
                                            <span *ngIf="!cmUserPref?.isDCRActive" style="color: red; ">&nbsp; In-Active</span>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label" for="officeCode">{{class2CodeTitle}}</span>
                                                <span class="control-label text-success">That define a member</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <kendo-multiselect 
                                                                   [data]="ddMemClass2Codes"
                                                                   [autoClose]="false"
                                                                   textField="display"
                                                                   [kendoDropDownFilter]="filterSettings"
                                                                   [valuePrimitive]="true"
                                                                   [(ngModel)]="selectedClass2Codes"
                                                                   [ngModelOptions]="{standalone: true}"
                                                                   (valueChange)="onValueChange(sitesRef)"
                                                                   valueField="classCode"
                                                                   [placeholder]="placeHolder">
                                                </kendo-multiselect>
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.dCRMemberCalls}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label" for="officeCode">Member Calls</span>
                                                <span class="control-label text-success">Enter a phone number</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" formControlName="dCRMemberCalls" />
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.dCRMemberCalls}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label" for="teamCode">Reciprocals</span>
                                                <span class="control-label text-success">Enter a phone number</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" formControlName="dCRReciprocals" />
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.dCRReciprocals}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label" for="employeeID">Member SMS Routing</span>
                                                <span class="control-label text-success">Enter a cell number</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <input type="text" class="form-control" formControlName="dCRMemberSMSRouting" />
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.dCRMemberSMSRouting}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="isSlurpy">
                                    <div class="custom-action-title">
                                        <span>SalesOptima Professional Services</span>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <span class="control-label" for="officeCode">Managed By SOP</span>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <input type="checkbox" class="checkbox" formControlName="isSOPManaged" />
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.isSOPManaged ? 'Yes' : 'No'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label" for="teamCode">Commission Per Deal</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <select class="form-control" formControlName="sOPPerDealType">
                                                    <option value='1'>$</option>
                                                    <option value='2'>%</option>

                                                </select>
                                                <input type="text" class="form-control mt-2" formControlName="sOPPerDealFee" />
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.sOPPerDealFee  | currency}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div class="custom-action-title">
                                        <span>LiveConnect Deal Commission</span>
                                    </div>
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left">
                                            <div>
                                                <span class="control-label" for="officeCode">Commission Per Deal</span>
                                            </div>
                                        </div>
                                        <div class="cards-colunm-right">
                                            <div class="full-width-container" *ngIf="!isViewMode">
                                                <select class="form-control" formControlName="lCPerDealType">
                                                    <option value='1'>$</option>
                                                    <option value='2'>%</option>

                                                </select>
                                                <input type="text" class="form-control mt-2" formControlName="lCPerDealUnit" />
                                            </div>
                                            <div *ngIf="isViewMode">
                                                <span>{{cmUserPref?.lCPerDealUnit | currency}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-body-section" *ngIf="!isViewMode">
                            <div class="cards-colunm-right">
                                <div class="full-width-container text-center">
                                    <button class="btn btn-primary" [disabled]="showSpinner" [hidden]="roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false" type="submit">
                                        <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
                                        <ng-template [ngIf]="buttonTypeOperation===1">Saving  <span><i wrapper> </i></span></ng-template>
                                    </button>
                                    <button class="btn btn-cancel" (click)="onCancelEdit()" type="button"> Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
