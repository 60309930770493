<div class="custom-security-link">
    <a [routerLink]="['/contact', selectedLead?.cLPUserID, selectedLead?.contactID]">View Contact</a>
    <a (click)="appointmentPopUp(null,true);" data-toggle="modal" data-target="#quickApptModalLead" data-backdrop="static" data-keyboard="false">New Appt</a>
    <a (click)="createNewTask();">New Task</a>
    <a (click)="showLeadInvoice()" data-toggle="modal" data-target="#leadInvoice" data-backdrop="static" data-keyboard="false">New Invoice</a>
</div>
<div class="contact-container">
    <div class="main-module">
        <kendo-splitter orientation="horizontal">
            <kendo-splitter-pane min="25%" max="29%" [size]="leftPanelSize" (sizeChange)="panelSizeChange('left', $event)">
                <div class="left-section" [class.fade-out]="showSpinner || _contactSearchService.showSpinner">
                    <fieldset>
                        <legend>
                            Lead Module
                            <a class="create-contact-icon" (click)="_localService.showCommonComp ='lead-create'; showCommonComponent(); isLeadEdit = false" title="Create new Lead"><i class="fas fa-user-plus"></i></a>
                        </legend>
                        <div class="contact-module-panel">
                            <div class="profile-module">
                                <span class="profile-main"><img src="../../../../assets/leadtitle_black.svg" class="mr-1" /></span>
                                <div class="profile-edit">
                                    <div class="top-icon">
                                        <a [title]="isPinned ? 'Un-Pin' : 'Pin'" *ngIf="user?.cLPCompanyID == 1285" (click)="isPinned ? unPinnedLead() : pinnedLead()">
                                            <img [src]="isPinned ? '../../../assets/unpinbtn.svg' : '../../../assets/pinbtn.svg'">
                                            <span>Pin</span>
                                        </a>
                                        <a title="Edit Lead" (click)="_localService.showCommonComp ='lead-create'; showCommonComponent(); isLeadEdit = true" #editButton>
                                            <img src="../../../../assets/editbtn.svg" />
                                            <span>Edit</span>
                                        </a>
                                    </div>
                                    <div class="user-details" *ngIf="selectedLead">
                                        <p>{{selectedLead?.contactLastName}} ({{selectedLead?.leadDesc}})</p>
                                        <div class="d-flex">
                                            <b>Contact Name:</b>
                                            <a class="custom-security-link ml-2" (click)="goToLinkLead('name',selectedLead)">{{selectedLead?.lastFirst}} </a>
                                        </div>
                                        <b>Company Name:</b>
                                        <a class="custom-security-link ml-2" (click)="goToLinkLead('company',selectedLead)"> {{selectedLead?.companyName}}</a>
                                        <span>
                                            <b>Mobile:</b>
                                            <a class="custom-security-link ml-2" data-toggle="modal" data-target="#newCallCreateModal" (click)="openModalNewCall(selectedLead,'mp')">{{pixString(selectedLead?.phone) | phoneFormat}}</a>
                                        </span>
                                    </div>
                                    <div class="user-icon">
                                        <div class="btn-group">
                                            <a style="border-radius:50px;" title="Send Email" (click)="sendEmail(selectedLead?.contactID, selectedLead?.leadID);">
                                                <img src="../../../../assets/email_new.svg" />
                                                <span>Email</span>
                                            </a>
                                        </div>
                                        <hr />
                                        <div class="delete-icon" style="justify-content:flex-end">
                                            <a title="Delete Lead" data-toggle="modal" data-target="#deleteLeadModal">
                                                <img src="../../../../assets/delete.svg" />
                                                <span>Delete</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="search-new-section">
                                <div class="search-new-panel">
                                    <ul class="nav nav-tabs" role="tablist">
                                        <li class="nav-item" [hidden]="isGridVisible">
                                            <a class="nav-link" [ngClass]="!isGridVisible ? 'active' : ''" data-toggle="tab" href="#searchDetails" role="tab">Details</a>
                                        </li>
                                        <li class="nav-item" *ngIf="isGridVisible">
                                            <a class="nav-link" [ngClass]="isGridVisible ? 'active' : ''" data-toggle="tab" href="#searchResults" role="tab">Search Results</a>
                                        </li>
                                    </ul><!-- Tab panes -->
                                    <div class="tab-content">
                                        <div class="tab-pane active" id="searchDetails" role="tabpanel" [hidden]="isGridVisible">
                                            <div class="expand-btn">
                                                <a id="colapse" (click)="expandCollapse()" role="button">
                                                    <span>{{isCollapse ? 'Expand All' : 'Collapse All'}}</span> <i *ngIf="isCollapse" class="fa fa-plus-square"></i><i *ngIf="!isCollapse" class="fa fa-minus-square"></i>
                                                </a>
                                            </div>
                                            <div id="accordion">
                                                <div class="accodian-details">
                                                    <div class="accodian-top" *ngFor="let section of arrSortedBySection; let i = index;">
                                                        <div class="card-header" id="heading-{{i}}" (click)="toggleAccordians(i)">
                                                            <a class="collapsed" role="button">
                                                                <span>{{ section.sectionName }}</span>
                                                                <i id="icon-{{i}}" *ngIf="section.isActive" class="fa fa-chevron-down"></i>
                                                                <i id="icon-{{i}}" *ngIf="!section.isActive" class="fa fa-chevron-up"></i>
                                                            </a>
                                                        </div>
                                                        <div id="collapse-{{i}}" class="collapse" [ngClass]="{'show': section.isActive }">
                                                            <div class="accodian-text-panel" *ngFor="let item of section.items">
                                                                <div class="accodian-text-details" *ngIf="item.isShow != 2">
                                                                    <span *ngIf="item?.fieldType == 3">{{item.fieldTitle=='dtCreated'?'Created':item.fieldTitle=='dtModified'?'Last Modified': 'Unique Identifier' }}:</span>
                                                                    <span *ngIf="item?.fieldType != 3">{{ item.fieldTitle == 'ClpuserID' ? 'User' : item.fieldTitle}}:</span>
                                                                    <span *ngIf="item?.fieldType != 2 && (item?.fieldTitle != 'dtCreated' && item?.fieldTitle != 'dtModified' && item?.fieldTitle != 'ClpuserID' ) ">{{item.fieldValue}}</span>
                                                                    <span *ngIf="item?.fieldType != 2 && (item?.fieldTitle == 'dtCreated' || item?.fieldTitle == 'dtModified') ">{{item.fieldValue | date:'M/d/yyyy'}}</span>
                                                                    <span *ngIf="item?.fieldType == 2 &&  item?.fieldTitle == 'ClpuserID'">{{getUserName(item.fieldValue)}}</span>
                                                                    <span *ngIf="item?.fieldType == 2 && item?.fieldTitle != 'ClpuserID'">{{getDDValue(item)}}</span>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="section.items?.length == 0" class="not-found">
                                                                No data found.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane active" id="searchResults" role="tabpanel" *ngIf="isGridVisible">
                                            <div class="sort-filter-module">
                                                <div class="sort-panel">
                                                    <label>Sort</label>
                                                    <select class="form-control" (change)="changeLeadSort($event.target.value);">
                                                        <option value="">-Select-</option>
                                                        <option value="created">Created on</option>
                                                        <option value="updated">Updated on</option>
                                                    </select>
                                                </div>
                                                <div class="filter-panel">
                                                    <label>Filter</label>
                                                    <select id="drpSavedQueries" class="form-control" (change)="_contactSearchService.drpSavedQueries_onChange($event)">
                                                        <option [value]='0'>-Select-</option>
                                                        <option *ngFor="let item of _contactSearchService.savedQuery_Filter" [value]='item.key'>{{item?.value}}</option>
                                                        <option [value]='-2'>New Search</option>
                                                    </select>
                                                </div>
                                                <div class="filter-edit-btn">
                                                    <button (click)="_contactSearchService.editSavedQuery()" class="btn-icon" [disabled]="_contactSearchService.savedQueryValue <= '0' || _contactSearchService.isDrpSavedQueryChanged">
                                                        <i class="fas fa-pencil-alt btn-square-md"></i>
                                                        <span>Edit</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="list-module" *ngIf="isGridVisible">
                                                <div class="grid-search">
                                                    <div class="align-items-start">
                                                        <input placeholder="Search(Lead Description)" kendoTextBox (input)="onLeadListFilter($event.target.value)" />
                                                    </div>
                                                </div>
                                                <kendo-grid class="mail-merge-grid" #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                                                            [kendoGridBinding]="leadList"
                                                            [kendoGridSelectBy]="'leadID'"
                                                            kendoGridSelectBy="vipSlideId"
                                                            [sortable]="{mode: 'multiple'}"
                                                            [sort]="_gridCnfgService.sort"
                                                            [pageSize]="_gridCnfgService.pageSize"
                                                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                                            [scrollable]="'false'"
                                                            [reorderable]="true"
                                                            [resizable]="true"
                                                            [columnMenu]="{ filter: true }"
                                                            (selectionChange)="rowSelectionChange($event)"
                                                            [rowClass]="rowCallback.bind(this)"
                                                            (columnReorder)="_gridCnfgService.columnsOrderChanged('lead_detail_grid', $event)"
                                                            (sortChange)="_gridCnfgService.sortChange('lead_detail_grid', $event)"
                                                            (pageChange)="_gridCnfgService.pageChange('lead_detail_grid', $event)"
                                                            (columnResize)="_gridCnfgService.columnResize(6,'lead_detail_grid', $event)"
                                                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'lead_detail_grid',grid)">

                                                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                                       [field]="column.field"
                                                                       [title]="column.title | titlecase"
                                                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                                       [width]="column.width | stringToNumber"
                                                                       [filterable]="true"
                                                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                                                       [includeInChooser]="column.field=='$' ? false : true">
                                                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                                            <div class="customer-name" *ngIf="column.field == 'leadDesc'">{{dataItem[column.field]}}</div>
                                                        </ng-template>
                                                    </kendo-grid-column>
                                                    <kendo-grid-command-column title="Handled" [width]="40" min="40" [style]="{'text-align': 'center'}" [includeInChooser]="false" [reorderable]="false" [columnMenu]="false">
                                                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                                            <kendo-switch [checked]="dataItem?.isSelected" [disabled]="dataItem?.isSelected" onLabel=" " offLabel=" "></kendo-switch>
                                                        </ng-template>
                                                    </kendo-grid-command-column>
                                                </kendo-grid>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </kendo-splitter-pane>
            <kendo-splitter-pane>
                <div class="middle-section">
                    <fieldset #activeDashboardId>
                        <legend>Active Dashboard</legend>
                        <div class="middle-section-alignment">
                            <div class="common-section-module">
                                <lead-create *ngIf="showLeadCreateComponent == true" [leadId]="isLeadEdit == true ? leadId : 0" [isLeadCommon]=true (isLeadUpdate)="leadUpdate($event);"></lead-create>
                                <app-contact-search [isFromLead]="true" [leadId]="leadId" [isEdit]="_contactSearchService.isSearchEditClick" *ngIf="_contactSearchService.savedQueryValue == '-2' || _contactSearchService.isSearchEditClick"></app-contact-search>
                                <app-manage-campaigns *ngIf="user && selectedLead" (refreshContactDetail)="refreshContactDetail($event)" [ownerId]="selectedLead?.leadID" [ownerType]="eCampaignTemplateOwnerType?.Lead" [from]="'lead'" [user]="user" [roleFeaturePermissions]="roleFeaturePermissions" [isShowForm]="false" [ownerName]="selectedLead?.leadDesc"></app-manage-campaigns>
                                <lead-history #leadHistoryChild *ngIf="showHistoryComponent == true" [leadName]="getLeadName()" leadIdReceive={{leadId}} (isEditLead)=isEditLead($event)></lead-history>
                                <app-contact-activity-history *ngIf="contactHistory" [userddList]="userList" [contactHistory]="contactHistory" [contactId]="selectedLead?.contactID" (viewLoaded)="loadOtherComponents = true"></app-contact-activity-history>
                            </div>
                        </div>
                        <div class="common"></div>
                    </fieldset>
                </div>
            </kendo-splitter-pane>
            <kendo-splitter-pane min="25%" max="29%" [(size)]="rightPanelSize" (sizeChange)="panelSizeChange('right', $event)">
                <div class="right-section">
                    <fieldset>
                        <legend>Action Module</legend>
                        <div class="contact-info-section">
                            <div class="row-panel">
                                <div class="col-right-panel" *ngIf="showQuickActionComponent">
                                    <div class="wraper-main-section">
                                        <div class="global-card-section">
                                            <div class="global-header-section">
                                                <div class="svg-icon-panel">Quick Launch</div>
                                            </div>
                                            <div class="quick-panel">
                                                <span><a href="javascript:void(0)" (click)="goToAppt()" title="view all appointments">View Appointment List</a></span>
                                                <span><a href="javascript:void(0)" (click)="goToTask()" title="">View Task List</a></span>
                                                <span><a href="javascript:void(0)" title="view all documents" (click)="gotoDocumentList()">View Document List</a></span>
                                                <span><a [routerLink]="['/lead-status-history', leadId]" title="view lead status change history">View Status History</a></span>
                                                <span><a href="javascript:void(0)" (click)="goToLeadContactHistory()" title="view lead contact history">View Lead Contact History</a></span>
                                                <span *ngIf="companyData?.isSFAIncluded"><a href="javascript:void(0)" (click)="viewAutomationProcess()" title="manage automation processes for this lead">View Automation Processes</a></span>
                                                <span><a href="javascript:void(0)" title="view invoices" [routerLink]="['/lead/invoice', leadId]">View Invoices</a></span>
                                                <span><a href="javascript:void(0)" [routerLink]="" title="" (click)="sendEmail(selectedLead?.contactID)">Send Email</a></span>
                                                <span *ngIf="companyData?.isHTMLEmailIncluded"><a href="javascript:void(0)" [routerLink]="" title="create a mail merge document" data-toggle="modal" data-target="#documentMailMerge" data-backdrop="static" data-keyboard="false">Create Mail Merge</a></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="home-dashboard-right mt-3">
                                        <div class="wraper-main-section">
                                            <div class="global-card-section">
                                                <div class="global-header-section goal-bg-banner">
                                                    <div class="svg-icon-panel"><img src="../../../../assets/activity/task/remindertasklead.svg" class="title-img" />{{apptHeader}}</div>
                                                    <div class="header-button-panel">
                                                        <div class="button-wrapper">
                                                            <button class="btn btn-primary" (click)="appointmentPopUp(null,true);" data-toggle="modal" data-target="#quickApptModalLead" data-backdrop="static" data-keyboard="false"><i class="fa fa-plus"></i> New</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="global-body-section">
                                                    <lead-appt #leadApptChild *ngIf="user" [loggedUser]="user" [apptTypeCodes]="apptTypeCodes" (isApptList)="getApptHeader($event)" [ownerId]="leadId" [contactId]="contactId" [isLeadTask]="true" [isShowHeader]="false"></lead-appt>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="home-dashboard-right mt-3">
                                        <dashboard-card #apptTask *ngIf="user" cardType="task" [ownerId]="leadId" [taskCategory]="taskCategory" [user]="user" (dataItemEventRecentParent)="sendEmailParent($event)" [roleFeaturePermissions]="roleFeaturePermissions">
                                            <div class="global-header-section goal-bg-task">
                                                <div class="svg-icon-panel"> <img src="../../../../assets/activity/task/tasktitle.svg" class="title-img" /> {{apptTask?.listData?.length>0 ? 'Upcoming Tasks' : 'Quick Task'}}</div>
                                                <div class="header-button-panel">
                                                    <div class="button-wrapper">
                                                        <button class="btn btn-primary" title="Add Task" (click)="createNewTask()"><i class="fa fa-plus"></i> New</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </dashboard-card>
                                    </div>
                                    <div class="home-dashboard-right mt-3">
                                        <div class="wraper-main-section">
                                            <div class="global-card-section">
                                                <div class="global-header-section recent-note">
                                                    <div class="svg-icon-panel"><img src="../../../../assets/activity/note/notetitle.svg" class="title-img" /> {{noteHeader}}</div>
                                                    <div class="header-button-panel">
                                                        <div class="button-wrapper">
                                                            <a class="btn btn-primary" title="Add Notes" (click)="createNewNote()"><i class="fa fa-plus m-0"></i> New</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="global-body-section">
                                                    <app-contact-note *ngIf="user" [loggedUser]="user" [leadName]="getLeadName()" (isNoteList)="getNoteHeader($event)" [ownerId]="leadId" [isLeadTask]="true" [note]="noteData" [isShowHeader]="false"></app-contact-note>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="home-dashboard-right mt-3">
                                        <div class="wraper-main-section">
                                            <div class="global-card-section">
                                                <div class="global-header-section goals-bg-contact">
                                                    <div class="svg-icon-panel">Link Groups</div>
                                                    <div class="header-button-panel">
                                                        <div class="button-wrapper">
                                                            <button class="btn btn-primary" title="Add Link Groups" [routerLink]="['/link-group/0']"><i class="fa fa-plus"></i> New</button>
                                                            <button class="icon-btn" title="Send Email to Link" (click)="link = true" data-toggle="modal" data-target="#emailModal1">
                                                                <img src="../../../../../assets/email_new.svg" />
                                                                <span class="btn-text">Email</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="global-body-section">
                                                    <quick-link *ngIf="user" [loggedUser]="user" [ownerId]="leadId" [ownerType]="eNoteOwnerType.Lead" [isShowHeader]="false" (filterLink)="getFilterlinks($event)"></quick-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="home-dashboard-right mt-3 mb-3">
                                        <div class="wraper-main-section">
                                            <div class="global-card-section">
                                                <div class="global-header-section goals-bg-contact">
                                                    <div class="svg-icon-panel">{{documentHeader}}</div>
                                                </div>
                                                <div class="global-body-section">
                                                    <upload-document *ngIf="user && leadId" (isDocumentList)="getDocumentHeader($event)" [loggedUser]="user" [ownerId]="leadId" [documentCategory]="eDocumentCategory.Lead"></upload-document>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </kendo-splitter-pane>
        </kendo-splitter>
    </div>
</div>
<div class="modal fade" id="underConstruction" tabindex="-1" role="dialog" aria-labelledby="underConstruction" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body modal-common-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <p class="under-construction under-construction-common">
                    <img src="../../../assets/under-construction.gif" />
                </p>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="deleteLeadModal" tabindex="-1" role="dialog" aria-labelledby="deleteLeadModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
            </div>
            <div class="modal-body modal-common-body">
                <h5>Are you sure to delete this Lead ? </h5>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="deleteSelectedLead(selectedLead?.leadID)" data-dismiss="modal" class="btn btn-primary">Confirm</button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="sendEmailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-contact-email *ngIf="sendMailInfo?.isShow" [isFromLead]="true" [leadId]="leadId" [loggedUser]="user" (closeEmailModal)="hideSendMail()" [contactId]="sendMailInfo?.contactId"></app-contact-email>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="quickApptModalLead" tabindex="-1" role="dialog" aria-labelledby="quickApptModalLeadLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-appointment-common *ngIf="user?.cLPCompanyID != 1226 && isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-common>
                    <app-appointment-ih *ngIf="user?.cLPCompanyID == 1226 && user?.cLPUserID != 3893 && proposalID === 0 && isShowApptModal" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-ih>
                </div>
                <div hidden="hidden">
                    <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideQuickApptModal();">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="emailModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <lead-email *ngIf="link" [filterLinks]="filterLinks" (closeEmailComponent)="toCloseEmailModal($event)" [loggedUser]="user" [ownerId]="leadId" [linkId]="" [linkName]=""></lead-email>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="leadInvoice" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-lead-invoice *ngIf="leadInvoice?.isShow" [invoiceId]="0" [isNewInvoice]="true" [loggedUser]="user" [leadId]="leadInvoice?.leadId" (isCloseButton)="closeLeadInvoice($event)"></app-lead-invoice>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="documentMailMerge" tabindex="-1" role="dialog" aria-labelledby="quickApptModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-document-mail-merge *ngIf="user && selectedLead?.contactID" [user]="user" (isCloseButton)="closeDocumentMailMergeModal($event)" [ownerId]="selectedLead?.contactID" [userName]="selectedLead?.lastFirst"></app-document-mail-merge>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="newCallCreateModal" tabindex="-1" role="dialog" aria-labelledby="newCallCreateModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-header user-body-header">
                    <h5 class="modal-title white-contact" id="exampleModalLabel"><img src="../../../../../assets/btnVoiceCall.svg" class="" />Voice Call</h5>
                    <div class="button-wrapper">
                        <button type="button" class="icon-btn" data-dismiss="modal" aria-label="Close" (click)="isShowNewCallModal=false;">
                            <i class="fa fa-close mr-0"></i>
                            <span class="btn-text">Cancel</span>
                        </button>
                    </div>
                </div>
                <div class="modal-body">
                    <contact-new-call *ngIf="isShowNewCallModal && contactData" [contactData]="contactData" (updatedCall)="hideTextCallModal($event)" [callType]="selectedCallType" [user]="user"></contact-new-call>
                </div>
                <!--<div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="isShowNewCallModal=false;">Close</button>
                </div>-->
            </div>
        </div>

    </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
