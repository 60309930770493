<div *ngIf="showRecent && documentCategory == eDocumentCategory.Appointment">
  <div class="document-list" *ngFor="let item of documentList;">
    <div class="cal-icon">
      <a class="contact-cursor-pointer" (click)="downloadDocuments(item?.documentId)" title="Download Document"> <img [src]="getDocIconPath(item?.documentType)"></a>
    </div>
    <div class="contact-details">
      <div class="contact-name"><a class="text-primary contact-cursor-pointer" title="Download Document" (click)="downloadDocuments(item?.documentId)"> {{getDocumentName(item?.documentName)}} </a></div>
    </div>
    <div class="cal-icon">
      <button type="button" class="grid-delete-btn" data-toggle="modal" data-target="#deleteDocumentModal" (click)="deleteDocument(item?.documentId); showDeleteDocModel= true">
        <i class="fa fa-trash-alt" title="Delete"></i>
        <span class="grid-common-text"></span>
      </button>
    </div>
  </div>
</div>
<div class="wraper-main-section">
  <!--<angular-file-uploader [config]="recentDocConfig" (ApiResponse)="changeFileApiResponse($event)" #imageUpload></angular-file-uploader>-->
  <div class="uploader-panel">
    <kendo-upload [saveUrl]="uploadSaveUrl"
                  [restrictions]="uploadRestrictions"
                  (complete)="changeFileApiResponse()"
                  (error)="changeFileApiResponse($event)"
                  [saveHeaders]="fileUploadHeaders"
                  [autoUpload]="false">
    </kendo-upload>
    <span>&nbsp;&nbsp;(jpg,png,jpeg,gif,pdf,txt,doc,docx,xlsx,csv)</span>
  </div>
  <div class="search-query-error acc-info-srch-error">
    {{errorMsg}}
  </div>
  <div class="margin-all-area">
    <span class="text-success d-block">max file size: &nbsp;{{maxFileSize}}&nbsp;MB</span>
  </div>
</div>
<div *ngIf="showRecent && documentCategory != eDocumentCategory.Appointment">
  <div class="document-list" *ngFor="let item of documentList;">
    <div class="cal-icon">
      <a class="contact-cursor-pointer" (click)="downloadDocuments(item?.documentId)" title="Download Document"> <img [src]="getDocIconPath(item?.documentType)"></a>
    </div>
    <div class="contact-details">
      <div class="contact-name"><a class="text-primary contact-cursor-pointer" title="Download Document" (click)="downloadDocuments(item?.documentId)"> {{getDocumentName(item?.documentName)}} </a></div>
    </div>
    <div class="cal-icon">
      <button type="button" class="grid-delete-btn" data-toggle="modal" data-target="#deleteDocumentModal" (click)="deleteDocument(item?.documentId); showDeleteDocModel = true">
        <i class="fa fa-trash-alt" title="Delete"></i>
        <span class="grid-common-text"></span>
      </button>
    </div>
  </div>
</div>
<div class="modal fade" id="deleteDocumentModal" *ngIf="showDeleteDocModel" tabindex="-1" role="dialog" aria-labelledby="deleteDocumentModal" aria-hidden="true">
  <div class="modal-dialog  modal-common-dialog" role="document">
    <div class="modal-content modal-common-content">
      <div class="modal-header modal-common-background">
        <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
      </div>
      <div class="modal-body modal-common-body">
        <h5>Caution: This document will be permanently deleted.</h5>
        <h5>Are you sure you want to delete this document? </h5>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="deleteDocumentConfirm()" class="btn btn-primary">OK</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
