<div class="mb-2">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../../assets/companystitleheader.svg" /> Company Module Toggle</div>
            </div>
            <div class="global-padding10">
                <div>Current Status : <span class="text-danger">{{ companyModuleResponse?.messageString2 }}</span><span class="text-right float-right">{{ companyModuleResponse?.messageString }}</span></div>
                <br />
                <div>New Status : <button type="button" data-toggle="modal" data-target="#confirmModal" class="btn btn-primary">{{ companyModuleResponse?.messageString3 }}</button></div>
            </div>
        </div>

        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../../assets/companystitleheader.svg" /> Club Manager Module Toggle</div>
            </div>
            <div class="global-padding10">
                <div>Current Status : <span class="text-danger">{{ clpCompanyData?.isClubModuleInstalled ? "Club Manager Module is currently installed." : "Club Manager Module is currently NOT installed." }}</span></div>
                <br />
                <div>New Status : <button type="button" data-toggle="modal" data-target="#confirmCMModal" class="btn btn-primary">{{ clpCompanyData?.isClubModuleInstalled ? "Un-install Club Manager Module" : "Install Club Manager Module"}}</button></div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="confirmModal" aria-hidden="true">
        <div class="modal-dialog  modal-common-dialog" role="document">
            <div class="modal-content modal-common-content">
                <div class="modal-header modal-common-background">
                    <h4 class="modal-title modal-common-title">Confirmation</h4>
                </div>
                <div class="modal-body modal-common-body">
                    <h2>Are you sure you want to <b>{{ companyModuleResponse?.messageString3 }}</b>?</h2>
                </div>
                <div class="modal-footer">
                    <button type="button" (click)="onConfirm()" data-dismiss="modal" class="btn btn-primary">Confirm</button>
                    <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>


    <div class="modal fade" id="confirmCMModal" tabindex="-1" role="dialog" aria-labelledby="confirmCMModal" aria-hidden="true">
        <div class="modal-dialog  modal-common-dialog" role="document">
            <div class="modal-content modal-common-content">
                <div class="modal-header modal-common-background">
                    <h4 class="modal-title modal-common-title">Confirmation</h4>
                </div>
                <div class="modal-body modal-common-body">
                    <h2>Are you sure you want to <b>{{ clpCompanyData?.isClubModuleInstalled ? "un-install the Club Manager Module" : "install the Club Manager Module" }}</b>?</h2>
                </div>
                <div class="modal-footer">
                    <button type="button" (click)="onConfirmCM()" data-dismiss="modal" class="btn btn-primary">Confirm</button>
                    <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>
