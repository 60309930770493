<div class="container-fluid">
  <div class="password-policy-section">
    <div class="header-table-section">
      <div class="password-header-section">
        <div class="password-header-text">
            <h4> Password Policy Configuration</h4>
        </div>
        <div class="password-search">
          <mat-form-field>
            <mat-label class="pass-company">Company...</mat-label>
            <input matInput (keyup)="applyFilterTemplateConfiguration($event.target.value)" placeholder="Search by Company..." type="search">
            <mat-icon matSuffix class="pass-srch">search</mat-icon>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 table-custom">
        <div class="table-grid-panel">
          <div class="mat-container mat-elevation-z2">
            <table mat-table [dataSource]="dataSource" #dataSort="matSort" matSort multiTemplateDataRows>
              <ng-container matColumnDef="clpcompanyId" style="display:none">
                <mat-header-cell mat-header-cell *matHeaderCellDef style="display:none"> ID </mat-header-cell>
                <mat-header-cell mat-cell *matCellDef="let element" style="display:none"> {{element?.clpCompanyId}} </mat-header-cell>
              </ng-container>

              <ng-container matColumnDef="companyName">
                <mat-header-cell *matHeaderCellDef class="table-th-header" style="text-align:start!important; border-left:none; background:#c5c5c5; justify-content: flex-start;" mat-sort-header> Company </mat-header-cell>
                <mat-cell *matCellDef="let element" class="table-th-header">
                  <span class="mobile-label">Company</span>
                  <div class="company-label">
                    {{element?.companyName}}
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="minLength">
                <mat-header-cell *matHeaderCellDef class="password" mat-sort-header>
                  <span title="The minimum length of the password">Length</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element" (click)="editRow(element?.clpCompanyId)">
                  <span class="mobile-label">Length</span>
                  <label *ngIf="element?.clpCompanyId!==editRowID">{{element?.minLength}}</label>
                  <input *ngIf="element?.clpCompanyId==editRowID" type="text" class="form-control" id="minLength_{{element?.clpCompanyId}}"
                         [value]="element?.minLength" maxlength="2" (keypress)="allowNumeric($event)" />
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="specialCharCount">
                <mat-header-cell *matHeaderCellDef class="password" mat-sort-header>
                  <span title="The minimum number of special characters in the password">Special Characters</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span class="mobile-label">Special Characters</span>
                  <label *ngIf="element?.clpCompanyId!==editRowID">{{element?.specialCharCount}}</label>
                  <input *ngIf="element?.clpCompanyId==editRowID" type="text" class="form-control" id="specialCharCount_{{element?.clpCompanyId}}"
                         [value]="element?.specialCharCount" maxlength="1" (keypress)="allowNumeric($event)" />
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="digitCount">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="password" mat-sort-header>
                  <span title="The minimum number of digits in the password">Digits</span>
                </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element">
                  <span class="mobile-label">Digits</span>
                  <label *ngIf="element?.clpCompanyId!==editRowID">{{element?.digitCount}}</label>
                  <input *ngIf="element?.clpCompanyId==editRowID" type="text" class="form-control" id="digitCount_{{element?.clpCompanyId}}"
                         [value]="element?.digitCount" maxlength="1" (keypress)="allowNumeric($event)" />
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="capitalCount">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="password" mat-sort-header><span title="The minimum number of uppercase letters in the password">Uppercase</span></mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element">
                  <span class="mobile-label">Uppercase</span>
                  <label *ngIf="element?.clpCompanyId!==editRowID">{{element?.capitalCount}}</label>
                  <input *ngIf="element?.clpCompanyId==editRowID" type="text" class="form-control" id="capitalCount_{{element?.clpCompanyId}}"
                         [value]="element?.capitalCount" maxlength="1" (keypress)="allowNumeric($event)" />
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="smallAlphabetCount">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="password" mat-sort-header><span title="The minimum number of lowercase letters in the password">Lowercase</span></mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element">
                  <span class="mobile-label">Lowercase</span>
                  <label *ngIf="element?.clpCompanyId!==editRowID">{{element?.smallAlphabetCount}}</label>
                  <input *ngIf="element?.clpCompanyId==editRowID" type="text" class="form-control" id="smallAlphabetCount_{{element?.clpCompanyId}}"
                         [value]="element?.smallAlphabetCount" maxlength="1" (keypress)="allowNumeric($event)" />
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="expiration">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="password" mat-sort-header>
                  <span title="The number of days before the password expires">Expiration (days)</span>
                </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element">
                  <span class="mobile-label">Expiration (days)</span>
                  <label *ngIf="element?.clpCompanyId!==editRowID">{{element?.expiration}}</label>
                  <input *ngIf="element?.clpCompanyId==editRowID" type="text" class="form-control" id="expiration_{{element?.clpCompanyId}}"
                         [value]="element?.expiration" maxlength="3" (keypress)="allowNumeric($event)" />
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="pwdReuseCount">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="password" mat-sort-header>
                  <span title="The number of previous passwords the user cannot reuse">Prevent Previous</span>
                </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element">
                  <span class="mobile-label">Prevent Previous</span>
                  <label *ngIf="element?.clpCompanyId!==editRowID">{{element?.pwdReuseCount}}</label>
                  <input *ngIf="element?.clpCompanyId==editRowID" type="text" class="form-control" id="pwdReuseCount_{{element?.clpCompanyId}}"
                         [value]="element?.pwdReuseCount" maxlength="2" (keypress)="allowNumeric($event)" />
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="maxFailedAttempts">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="password" mat-sort-header>
                  <span title="The maximum number of failed attempts before the user gets locked out">Attempts before Lockout</span>
                </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element">
                  <span class="mobile-label">Attempts before Lockout</span>
                  <label *ngIf="element?.clpCompanyId!==editRowID">{{element?.maxFailedAttempts}}</label>
                  <input *ngIf="element?.clpCompanyId==editRowID" type="text" class="form-control" id="maxFailedAttempts_{{element?.clpCompanyId}}"
                         [value]="element?.maxFailedAttempts" maxlength="1" (keypress)="allowNumeric($event);" (keyup)="changeMaxFailedAttempt(element?.clpCompanyId);" />
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="maxPwdChangeInDay">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="password" mat-sort-header>
                  <span title="The maximum number of times a user can change a password in one day">Daily Changes</span>
                </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element">
                  <span class="mobile-label">Daily Changes</span>
                  <label *ngIf="element?.clpCompanyId!==editRowID">{{element?.maxPwdChangeInDay}}</label>
                  <input *ngIf="element?.clpCompanyId==editRowID" type="text" class="form-control" id="maxPwdChangeInDay_{{element?.clpCompanyId}}"
                         [value]="element?.maxPwdChangeInDay" maxlength="1" (keypress)="allowNumeric($event)" />
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="lockOutDuration">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="password" mat-sort-header>
                  <span title="The number of minutes the user remains locked out before being allowed to log back in">Lockout Duration (minutes)</span>
                </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element">
                  <span class="mobile-label">Lockout Duration (minutes)</span>
                  <label *ngIf="element?.clpCompanyId!==editRowID">{{element?.lockOutDuration}}</label>
                  <input *ngIf="element?.clpCompanyId==editRowID" type="text" class="form-control" id="lockOutDuration_{{element?.clpCompanyId}}"
                         [value]="element?.lockOutDuration" maxlength="3" (keypress)="allowNumeric($event)" [disabled]="maxFailedAttemptsValue==0" />
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="isMFAEnabled">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="mfa" mat-sort-header><span title="Enable or disable Multi-Factor Authentication">MFA Enabled</span></mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span class="mobile-label">MFA Enabled</span>
                  <label *ngIf="element?.clpCompanyId!==editRowID">{{element?.isMFAEnabled==true?'yes':'no'}}</label>
                  <input *ngIf="element?.clpCompanyId==editRowID" type="checkbox" id="mfaEnable_{{element?.clpCompanyId}}"
                         [checked]="element?.isMFAEnabled" (change)="changeMFAEnable(element?.clpCompanyId)" />
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="mfaMethod">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="mfa" mat-sort-header><span title="The method of communicating the Multi-Factor Authentication">Method</span></mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element">
                  <span class="mobile-label">Method</span>
                  <label *ngIf="element?.clpCompanyId!==editRowID">{{element?.isMFAEnabled==false?'N/A':(element?.mfaMethod==0?'SMS':(element?.mfaMethod==1?'Email':'Both'))}}</label>
                  <select *ngIf="element?.clpCompanyId==editRowID" class="custom-select" id="mfaMethod_{{element?.clpCompanyId}}" name="mfaMethod_{{element?.clpCompanyId}}" [disabled]="isMFAEnabled==false">
                    <option [value]="-1" [selected]="!element?.isMFAEnabled || element?.mfaMethod==-1">-Select-</option>
                    <option [value]="0" [selected]="element?.isMFAEnabled && element?.mfaMethod==0">Text</option>
                    <option [value]="1" [selected]="element?.isMFAEnabled && element?.mfaMethod==1">Email</option>
                    <option [value]="2" [selected]="element?.isMFAEnabled && element?.mfaMethod==2">both</option>
                  </select>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="mfaExpiration">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="mfa" mat-sort-header>
                  <span title="The number of days before the saved device token expires and the user has to re-authenticate">Expiration (days)</span>
                </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element">
                  <span class="mobile-label">Expiration (days)</span>
                  <label *ngIf="element?.clpCompanyId!==editRowID">{{element?.isMFAEnabled==false?'N/A':element?.mfaExpiration}}</label>
                  <input *ngIf="element?.clpCompanyId==editRowID" type="text" class="form-control" id="mfaExpiration_{{element?.clpCompanyId}}"
                         [value]="element?.mfaExpiration" (keypress)="allowNumeric($event)" [disabled]="isMFAEnabled==false" maxlength="2" />
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="maxMFADevicesAllowed">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="mfa" mat-sort-header><span title="The maximum number of devices the user can use SalesOptima with">Devices</span></mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element">
                  <span class="mobile-label">Devices</span>
                  <label *ngIf="element?.clpCompanyId!==editRowID">{{element?.isMFAEnabled==false?'N/A':element?.maxMFADevicesAllowed}}</label>
                  <input *ngIf="element?.clpCompanyId==editRowID" type="text" class="form-control" id="maxMFADevicesAllowed_{{element?.clpCompanyId}}"
                         [value]="element?.maxMFADevicesAllowed" maxlength="1" (keypress)="allowNumeric($event)" [disabled]="isMFAEnabled==false" />
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="roleBased">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="mfa" style="width:2%; text-align:center;">
                  <span>Role Based</span>
                </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element; let i = dataIndex;" style="text-align:center;">
                  <span class="mobile-label">Role Based</span>
                  <label *ngIf="element?.clpCompanyId !== editRowID">{{element?.isRoleBased==true?'yes':'no'}}</label>
                  <div class="grid-action-icon" *ngIf="element?.clpCompanyId == editRowID">
                    <input type="checkbox" class="form-check" [checked]="element?.isRoleBased" id="isRoleBased_{{element?.clpCompanyId}}" [disabled]="element?.id == 0" (change)="onRoleBasedChecked(element?.clpCompanyId);" />
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="action">
                <mat-header-cell mat-header-cell *matHeaderCellDef style="width:2%; text-align:center;"><span>Action</span></mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element; let i = dataIndex;" style="text-align:center;">
                  <span class="mobile-label">Action</span>
                  <div class="grid-action-icon">
                    <i *ngIf="element?.clpCompanyId==editRowID" class="fa fa-save" (click)="updatePasswordPolicy(element?.clpCompanyId)" title="save"></i>
                    <i *ngIf="element?.clpCompanyId==editRowID" class="fas fa-times" (click)="resetGrid()" title="cancel"></i>
                    <i *ngIf="element?.clpCompanyId!==editRowID" class="fa fa-pen" (click)="highlight(element)" title="Edit"></i>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="addNew">
                <mat-header-cell mat-header-cell *matHeaderCellDef style="width:2%; text-align:center;"><span>Add New</span></mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element; let i = dataIndex;" style="text-align:center;">
                  <span class="mobile-label">Add New</span>
                  <div class="grid-action-icon">
                    <button>
                      <i class="fa fa-plus" (click)="addRoleBasedPolicies(element?.clpCompanyId)" title="Add New"></i>
                    </button>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="expand">
                <mat-header-cell mat-header-cell *matHeaderCellDef style="width:2%; text-align:center;"><span>Expand</span></mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element; let i = dataIndex;" style="text-align:center;">
                  <span class="mobile-label">Expand</span>
                  <div class="grid-action-icon">
                    <button (click)="toggleRow(element)" class="policy-btn" [disabled]="element?.roleBasedPasswordPolicies?.length == 0 || !element?.isRoleBased || element?.roleBasedPasswordPolicies == null">
                      <i class="fas fa-caret-up" title="Expand" *ngIf="isExpanded(element) == 'expanded'"></i>
                      <i class="fas fa-caret-down" title="Collapse" *ngIf="isExpanded(element) != 'expanded'"></i>
                    </button>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="expandedDetail" *ngIf="isRefresh">
                <td mat-cell *matCellDef="let element; let j = dataIndex;" [attr.colspan]="innerDisplayedColumns?.length">
                  <div class="example-element-detail nested-table" *ngIf="isExpanded(element) == 'expanded' && element?.roleBasedPasswordPolicies?.length > 0 && element?.isRoleBased" [@detailExpand]="isExpanded(element)">
                    <div class="inner-table mat-elevation-z8">
                      <table #innerTables mat-table [dataSource]="element?.roleBasedPasswordPolicies" matSort>
                        <ng-container matColumnDef="role">
                          <th mat-header-cell *matHeaderCellDef class="nested-password" mat-sort-header>
                            <span title="Select the role">Role</span>
                          </th>
                          <td mat-cell *matCellDef="let element; let i = index;">
                            <span class="mobile-label">Role</span>
                            <label *ngIf="i!==editNestedRowID || element?.clpCompanyId != editNestedCompanyID">{{setRoleValue(element?.roleID)}}</label>
                            <select *ngIf="i==editNestedRowID && element?.clpCompanyId == editNestedCompanyID" [(ngModel)]="element.roleID" class="custom-select" id="role_{{element?.clpCompanyId}}_{{i}}" name="role_{{element?.clpCompanyId}}">
                              <option *ngFor="let item of rolesList" [value]="item?.value">{{item?.text}}</option>
                            </select>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="minLength">
                          <th mat-header-cell *matHeaderCellDef class="nested-password" mat-sort-header>
                            <span title="The minimum length of the password">Length</span>
                          </th>
                          <td mat-cell *matCellDef="let element; let i = index;" (click)="editRow(element?.clpCompanyId)">
                            <span class="mobile-label">Length</span>
                            <label *ngIf="i!==editNestedRowID || element?.clpCompanyId != editNestedCompanyID">{{element?.minLength}}</label>
                            <input *ngIf="i==editNestedRowID && element?.clpCompanyId == editNestedCompanyID" type="text" class="form-control" id="minLength_{{element?.clpCompanyId}}_{{i}}"
                                   [value]="element?.minLength" maxlength="2" (keypress)="allowNumeric($event)" />
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="specialCharCount">
                          <th mat-header-cell *matHeaderCellDef class="nested-password" mat-sort-header>
                            <span title="The minimum number of special characters in the password">Special Characters</span>
                          </th>
                          <td mat-cell *matCellDef="let element; let i = index;">
                            <span class="mobile-label">Special Characters</span>
                            <label *ngIf="i!==editNestedRowID || element?.clpCompanyId != editNestedCompanyID">{{element?.specialCharCount}}</label>
                            <input *ngIf="i==editNestedRowID && element?.clpCompanyId == editNestedCompanyID" type="text" class="form-control" id="specialCharCount_{{element?.clpCompanyId}}_{{i}}"
                                   [value]="element?.specialCharCount" maxlength="1" (keypress)="allowNumeric($event)" />
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="digitCount">
                          <th mat-header-cell *matHeaderCellDef class="nested-password" mat-sort-header>
                            <span title="The minimum number of digits in the password">Digits</span>
                          </th>
                          <td mat-cell *matCellDef="let element; let i = index;">
                            <span class="mobile-label">Digits</span>
                            <label *ngIf="i!==editNestedRowID || element?.clpCompanyId != editNestedCompanyID">{{element?.digitCount}}</label>
                            <input *ngIf="i==editNestedRowID && element?.clpCompanyId == editNestedCompanyID" type="text" class="form-control" id="digitCount_{{element?.clpCompanyId}}_{{i}}"
                                   [value]="element?.digitCount" maxlength="1" (keypress)="allowNumeric($event)" />
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="capitalCount">
                          <th mat-header-cell *matHeaderCellDef class="nested-password" mat-sort-header><span title="The minimum number of uppercase letters in the password">Uppercase</span></th>
                          <td mat-cell *matCellDef="let element; let i = index;">
                            <span class="mobile-label">Uppercase</span>
                            <label *ngIf="i!==editNestedRowID || element?.clpCompanyId != editNestedCompanyID">{{element?.capitalCount}}</label>
                            <input *ngIf="i==editNestedRowID && element?.clpCompanyId == editNestedCompanyID" type="text" class="form-control" id="capitalCount_{{element?.clpCompanyId}}_{{i}}"
                                   [value]="element?.capitalCount" maxlength="1" (keypress)="allowNumeric($event)" />
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="smallAlphabetCount">
                          <th mat-header-cell *matHeaderCellDef class="nested-password" mat-sort-header><span title="The minimum number of lowercase letters in the password">Lowercase</span></th>
                          <td mat-cell *matCellDef="let element; let i = index;">
                            <span class="mobile-label">Lowercase</span>
                            <label *ngIf="i!==editNestedRowID || element?.clpCompanyId != editNestedCompanyID">{{element?.smallAlphabetCount}}</label>
                            <input *ngIf="i==editNestedRowID && element?.clpCompanyId == editNestedCompanyID" type="text" class="form-control" id="smallAlphabetCount_{{element?.clpCompanyId}}_{{i}}"
                                   [value]="element?.smallAlphabetCount" maxlength="1" (keypress)="allowNumeric($event)" />
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="expiration">
                          <th mat-header-cell *matHeaderCellDef class="nested-password" mat-sort-header>
                            <span title="The number of days before the password expires">Expiration (days)</span>
                          </th>
                          <td mat-cell *matCellDef="let element; let i = index;">
                            <span class="mobile-label">Expiration (days)</span>
                            <label *ngIf="i!==editNestedRowID || element?.clpCompanyId != editNestedCompanyID">{{element?.expiration}}</label>
                            <input *ngIf="i==editNestedRowID && element?.clpCompanyId == editNestedCompanyID" type="text" class="form-control" id="expiration_{{element?.clpCompanyId}}_{{i}}"
                                   [value]="element?.expiration" maxlength="3" (keypress)="allowNumeric($event)" />
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="pwdReuseCount">
                          <th mat-header-cell *matHeaderCellDef class="nested-password" mat-sort-header>
                            <span title="The number of previous passwords the user cannot reuse">Prevent Previous</span>
                          </th>
                          <td mat-cell *matCellDef="let element; let i = index;">
                            <span class="mobile-label">Prevent Previous</span>
                            <label *ngIf="i!==editNestedRowID || element?.clpCompanyId != editNestedCompanyID">{{element?.pwdReuseCount}}</label>
                            <input *ngIf="i==editNestedRowID && element?.clpCompanyId == editNestedCompanyID" type="text" class="form-control" id="pwdReuseCount_{{element?.clpCompanyId}}_{{i}}"
                                   [value]="element?.pwdReuseCount" maxlength="2" (keypress)="allowNumeric($event)" />
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="maxFailedAttempts">
                          <th mat-header-cell *matHeaderCellDef class="nested-password" mat-sort-header>
                            <span title="The maximum number of failed attempts before the user gets locked out">Attempts before Lockout</span>
                          </th>
                          <td mat-cell *matCellDef="let element; let i = index;">
                            <span class="mobile-label">Attempts before Lockout</span>
                            <label *ngIf="i!==editNestedRowID || element?.clpCompanyId != editNestedCompanyID">{{element?.maxFailedAttempts}}</label>
                            <input *ngIf="i==editNestedRowID && element?.clpCompanyId == editNestedCompanyID" type="text" class="form-control" id="maxFailedAttempts_{{element?.clpCompanyId}}_{{i}}"
                                   [value]="element?.maxFailedAttempts" maxlength="1" (keypress)="allowNumeric($event);" (keyup)="changeMaxFailedAttempt(element?.clpCompanyId);" />
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="maxPwdChangeInDay">
                          <th mat-header-cell *matHeaderCellDef class="nested-password" mat-sort-header>
                            <span title="The maximum number of times a user can change a password in one day">Daily Changes</span>
                          </th>
                          <td mat-cell *matCellDef="let element; let i = index;">
                            <span class="mobile-label">Daily Changes</span>
                            <label *ngIf="i!==editNestedRowID || element?.clpCompanyId != editNestedCompanyID">{{element?.maxPwdChangeInDay}}</label>
                            <input *ngIf="i==editNestedRowID && element?.clpCompanyId == editNestedCompanyID" type="text" class="form-control" id="maxPwdChangeInDay_{{element?.clpCompanyId}}_{{i}}"
                                   [value]="element?.maxPwdChangeInDay" maxlength="1" (keypress)="allowNumeric($event)" />
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="lockOutDuration">
                          <th mat-header-cell *matHeaderCellDef class="nested-password" mat-sort-header>
                            <span title="The number of minutes the user remains locked out before being allowed to log back in">Lockout Duration (minutes)</span>
                          </th>
                          <td mat-cell *matCellDef="let element; let i = index;">
                            <span class="mobile-label">Lockout Duration (minutes)</span>
                            <label *ngIf="i!==editNestedRowID || element?.clpCompanyId != editNestedCompanyID">{{element?.lockOutDuration}}</label>
                            <input *ngIf="i==editNestedRowID && element?.clpCompanyId == editNestedCompanyID" type="text" class="form-control" id="lockOutDuration_{{element?.clpCompanyId}}_{{i}}"
                                   [value]="element?.lockOutDuration" maxlength="3" (keypress)="allowNumeric($event)" [disabled]="maxFailedAttemptsValue==0" />
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="isMFAEnabled">
                          <th mat-header-cell *matHeaderCellDef class="mfa" mat-sort-header><span title="Enable or disable Multi-Factor Authentication">MFA Enabled</span></th>
                          <td mat-cell *matCellDef="let element; let i = index;">
                            <span class="mobile-label">MFA Enabled</span>
                            <label *ngIf="i!==editNestedRowID || element?.clpCompanyId != editNestedCompanyID">{{element?.isMFAEnabled==true?'yes':'no'}}</label>
                            <input *ngIf="i==editNestedRowID && element?.clpCompanyId == editNestedCompanyID" type="checkbox" id="mfaEnable_{{element?.clpCompanyId}}_{{i}}"
                                   [checked]="element?.isMFAEnabled" (change)="changeNestedMFAEnable(element?.clpCompanyId,i)" />
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="mfaMethod">
                          <th mat-header-cell *matHeaderCellDef class="mfa" mat-sort-header><span title="The method of communicating the Multi-Factor Authentication">Method</span></th>
                          <td mat-cell *matCellDef="let element; let i = index;">
                            <span class="mobile-label">Method</span>
                            <label *ngIf="i!==editNestedRowID || element?.clpCompanyId != editNestedCompanyID">{{element?.isMFAEnabled==false?'N/A':(element?.mfaMethod==0?'SMS':(element?.mfaMethod==1?'Email':'Both'))}}</label>
                            <select *ngIf="i==editNestedRowID && element?.clpCompanyId == editNestedCompanyID" class="custom-select" id="mfaMethod_{{element?.clpCompanyId}}_{{i}}" name="mfaMethod_{{element?.clpCompanyId}}_{{i}}" [disabled]="isNestedMFAEnabled==false">
                              <option [value]="-1" [selected]="!element?.isMFAEnabled || element?.mfaMethod==-1">-Select-</option>
                              <option [value]="0" [selected]="element?.isMFAEnabled && element?.mfaMethod==0">Text</option>
                              <option [value]="1" [selected]="element?.isMFAEnabled && element?.mfaMethod==1">Email</option>
                              <option [value]="2" [selected]="element?.isMFAEnabled && element?.mfaMethod==2">both</option>
                            </select>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="mfaExpiration">
                          <th mat-header-cell *matHeaderCellDef class="mfa" mat-sort-header>
                            <span title="The number of days before the saved device token expires and the user has to re-authenticate">Expiration (days)</span>
                          </th>
                          <td mat-cell *matCellDef="let element; let i = index;">
                            <span class="mobile-label">Expiration (days)</span>
                            <label *ngIf="i!==editNestedRowID || element?.clpCompanyId != editNestedCompanyID">{{element?.isMFAEnabled==false?'N/A':element?.mfaExpiration}}</label>
                            <input *ngIf="i==editNestedRowID && element?.clpCompanyId == editNestedCompanyID" type="text" class="form-control" id="mfaExpiration_{{element?.clpCompanyId}}_{{i}}"
                                   [value]="element?.mfaExpiration" (keypress)="allowNumeric($event)" [disabled]="isNestedMFAEnabled==false" maxlength="2" />
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="maxMFADevicesAllowed">
                          <th mat-header-cell *matHeaderCellDef class="mfa" mat-sort-header><span title="The maximum number of devices the user can use SalesOptima with">Devices</span></th>
                          <td mat-cell *matCellDef="let element; let i = index;">
                            <span class="mobile-label">Devices</span>
                            <label *ngIf="i!==editNestedRowID || element?.clpCompanyId != editNestedCompanyID">{{element?.isMFAEnabled==false?'N/A':element?.maxMFADevicesAllowed}}</label>
                            <input *ngIf="i==editNestedRowID && element?.clpCompanyId == editNestedCompanyID" type="text" class="form-control" id="maxMFADevicesAllowed_{{element?.clpCompanyId}}_{{i}}"
                                   [value]="element?.maxMFADevicesAllowed" maxlength="1" (keypress)="allowNumeric($event)" [disabled]="isNestedMFAEnabled==false" />
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                          <th mat-header-cell *matHeaderCellDef style="width:2%; text-align:center;"><span>Action</span></th>
                          <td mat-cell *matCellDef="let element; let i = index;" style="text-align:center;">
                            <span class="mobile-label">Action</span>
                            <div class="grid-action-icon">
                              <i *ngIf="i==editNestedRowID && element?.clpCompanyId == editNestedCompanyID" class="fa fa-save" (click)="updatePasswordPolicy(element?.clpCompanyId, true, i, element)" title="save"></i>
                              <i *ngIf="i==editNestedRowID && element?.clpCompanyId == editNestedCompanyID" class="fas fa-times" (click)="resetNestedGrid(element?.id, j)" title="cancel"></i>
                              <i *ngIf="i!==editNestedRowID || element?.clpCompanyId != editNestedCompanyID" class="fa fa-pen" (click)="editNestedRow(i,element?.clpCompanyId, j,element?.isMFAEnabled)" title="Edit" style="font-size:17px;"></i>
                              <i *ngIf="i!==editNestedRowID || element?.clpCompanyId != editNestedCompanyID" class="fa fa-minus" (click)="deleteId = element?.id" title="delete" data-toggle="modal" data-target="#deleteAllMessages" style="color:red"></i>
                            </div>
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns;"></tr>
                      </table>
                    </div>
                  </div>
                </td>
              </ng-container>

              <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row mat-row *matRowDef="let row; columns: displayedColumns;" [style.background]="highlightedRows.indexOf(row) != -1 ? 'rgb(169 218 255)' : ''" [class.example-element-row]="row?.roleBasedPasswordPolicies?.lenght == 0"></mat-row>
              <mat-row *matRowDef="let row; columns: ['expandedDetail']" [ngClass]="{'example-detail-row':isExpanded(element) != 'expanded'}"></mat-row>
            </table>

          </div>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 500]" [pageSize]="pageSize" (page)="pageEvent = handlePage($event)" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
    <!--Password Administrator-->
    <div class="password-dministrator-table">
        <div class="password-policy-section">
            <div class="header-table-section">
                <div class="row">
                    <div class="col-lg-8 col-md-8 col-sm-12">
                        <div class="header-table-panel">
                            <h2> Password Administrator</h2>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <mat-form-field>
                            <mat-label class="pass-administrator-company">Company or Username...</mat-label>
                            <input #matInputSearchPA matInput (keyup)="applyFilterPA($event.target.value)" placeholder="Search by Company or Username..." type="search">
                            <mat-icon matSuffix class="pass-administrator-srch">search</mat-icon>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <div class="table-grid-panel">
                        <div class="mat-container mat-elevation-z2">
                            <table mat-table [dataSource]="dataSourceClpUser" #userSort="matSort" matSort>

                                <ng-container matColumnDef="clpUserId" class="for-pass-administrator-id" >
                                    <mat-header-cell mat-header-cell *matHeaderCellDef class="for-pass-administrator-id"> ID </mat-header-cell>
                                    <mat-cell mat-cell *matCellDef="let element" class="for-pass-administrator-id"> {{element?.clpUserId}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="companyName">
                                    <mat-header-cell mat-header-cell *matHeaderCellDef class="table-th-header text-left" mat-sort-header> Company </mat-header-cell>
                                    <mat-cell mat-cell *matCellDef="let element" class="table-th-header">
                                        <span class="mobile-label">Company</span>
                                        {{element?.companyName}}
                                    </mat-cell>

                                </ng-container>

                                <ng-container matColumnDef="userName">
                                    <mat-header-cell mat-header-cell *matHeaderCellDef class="table-th-header text-left" mat-sort-header> Username </mat-header-cell>
                                    <mat-cell mat-cell *matCellDef="let element" class="table-th-header">
                                        <span class="mobile-label">Username</span>
                                        <span *ngIf="element?.firstName">{{element?.firstName}}&nbsp;</span>
                                        <span *ngIf="element?.lastName">{{element?.lastName}}&nbsp;</span>
                                        <span *ngIf="element?.userName">({{element?.userName}})</span>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="isPasswordAdmin">
                                    <mat-header-cell mat-header-cell *matHeaderCellDef class="justify-content-center" mat-sort-header>Administrator </mat-header-cell>
                                    <mat-cell mat-cell *matCellDef="let element" class="text-center">
                                        <input type="checkbox" id="forIsPA" [checked]="element?.isPasswordAdmin" (change)="updateClpUser_PA(element?.cLPUserID)" [disabled]="adminDisable == true" />
                                    </mat-cell>
                                </ng-container>

                                <tr mat-row *matRowDef="let row; columns: displayedColumnsClpUser;" [style.background]="row.isPasswordAdmin == true ? 'rgb(169 218 255)' : ''"></tr>
                                <tr mat-header-row *matHeaderRowDef="displayedColumnsClpUser; sticky: true"></tr>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="margin-bottom"></div>
</div>


<div class="modal fade" id="deleteAllMessages" tabindex="-1" role="dialog" aria-labelledby="deleteAllMessages" aria-hidden="true">
  <div class="modal-dialog  modal-common-dialog" role="document">
    <div class="modal-content modal-common-content">
      <div class="modal-header modal-common-background">
        <h4 class="modal-title modal-common-title">Confirmation</h4>
      </div>
      <div class="modal-body modal-common-body">
        <h5>Caution! Are you sure you want to delete the password policy of this role?</h5>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="deletePasswordPolicyRole(deleteId)" data-dismiss="modal" class="btn btn-primary">ok</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal" style="border:solid 1px #ccc;">Cancel</button>
      </div>
    </div>
  </div>
</div>
