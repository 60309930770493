import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostBinding, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FileRestrictions, ErrorEvent, SuccessEvent } from '@progress/kendo-angular-upload';
import { isNullOrUndefined } from 'util';
import { CLPUser } from '../../../../models/clpuser.model';
import { ContactFields } from '../../../../models/contact.model';
import { EmailDropDownsResponse, EmailSnippet, EmailSnippetResponse, EmailTemplate, EmailTemplateLoad, MailMergeTemplate } from '../../../../models/emailTemplate.model';
import { DocumentResponse, Document } from '../../../../models/document';
import { ConfigDetails } from '../../../../models/appConfig.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { OutBoundEmailService } from '../../../../services/outBoundEmail.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { AppconfigService } from '../../../../services/shared/appconfig.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { pageAnimations, filterAnimation } from '../../../../animations/page.animation';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Note, NoteResponse, StatusEmail, StatusEmailResponse } from '../../../../models/note.model';
import { ContactSearchService } from '../../../../services/shared/contact-search.service';
import { ContactService } from '../../../../services/contact.service';
import { DatePipe } from '@angular/common';
import { isNullOrEmptyString } from '../../../../../shared/utils.js';
import { keyValue } from '../../../../models/search.model';
import { EmailTemplateService } from '../../../../services/email-template.service';
import { MyDocumentService } from '../../../../services/my-document.service';
import { Invoice } from '../../../../models/lead-invoice.model';
import { NotesService } from '../../../../services/notes.service';
import { eEmailOptType } from '../../../../models/enum.model';
import { GlobalService } from '../../../../services/global.service';
import { UserService } from '../../../../services/user.service';
declare var $: any;

@Component({
    selector: 'app-contact-email',
    templateUrl: './contact-email.component.html',
    styleUrls: ['./contact-email.component.css'],
    animations: [pageAnimations, filterAnimation]
})
/** contact-email component*/
export class ContactEmailComponent implements OnInit, OnDestroy {
    //Animation
    @HostBinding('@pageAnimations') public animatePage = true;
    showAnimation = -1;
    //Animation
    /** contact-email ctor */
    @ViewChild('scrollIdTarget') scrollIdTarget: ElementRef;
    private encryptedUser: string = '';
    selectedType: string = 'contact';
    dateFormat: string = "MM/dd/yyyy";
    emailForm: FormGroup;

    showAttachedDiv: boolean = false;
    showFileUploader: boolean = false;
    showDatePicker: boolean = false;
    isMailMergeError: boolean = false;
    forMessageBoolValue: boolean;
    strEmailMsg: string[] = []
    users: keyValue[];
    usersFrom: keyValue[];
    toUsersArr: any;
    @Input() isFromLead: boolean = false;
    @Input() loggedUser: CLPUser;
    @Input() contactId: number = 0;
    @Input() isShowCLoseBtn: boolean = true;
    statusEmail: StatusEmail;
    @Output() closeEmailModal = new EventEmitter<boolean>();
    //Lead Invoice
    @Output() isCloseButton = new EventEmitter<boolean>();

    timeZoneResult: string = ""
    @Input() isLeadInvoice: boolean = false;
    @Input() isForActivities: boolean = false;
    @Input() invoice: Invoice;
    @Input() isLeadInvoiceList: boolean = false;
    leadInvoice: any = { isShow: false, leadId: 0 };
    @Input() invoiceId: number = 0;
    @Input() isNewInvoice: boolean = false;
    @Input() leadId: number = 0;
    @Input() noteId: number = 0;
    emailDropDownsResponse: EmailDropDownsResponse;
    emailSnippets: EmailSnippet[] = [];
    emailTemplates: EmailTemplate[] = [];
    mailMergeTemplates: MailMergeTemplate[] = [];
    documentRecentResponse: DocumentResponse;
    documentContactResponse: DocumentResponse;
    documentSearchResponse: DocumentResponse;
    documents: Document[] = [];
    contactFields: ContactFields;

    pageSize: number = 5;
    public skip = 0;

    attachedFiles: any[] = [];
    uploadedFileResponse: SimpleResponse;
    helpSite: string = "";
    soUrl: any;
    resetUpload: boolean;

    documentGridTitle: string = 'Recent';
    searchValue: any;

    selectedEmailSnippet = '';
    submitBtnTxt: string = 'Send Email';

    public attachFileSort: SortDescriptor[] = [{ field: "dtCreated", dir: "desc" }];

    //for datetime picker
    public datePickerValue: Date;

    emailSnippetResponse: EmailSnippetResponse;
    note: any;
    noteResponse: NoteResponse;
    noteResult: Note;
    emailBind;
    baseUrl: string;
    htmlDisplay: string = '';
    isViewMode: boolean = false;
    isCloseWindow: boolean = false;
    isCancelEmail: boolean = false;
    disableBtnOnSubmit: boolean;
    mailMergeWarning: string = "";
    @Input() showEmoji: boolean = true
    isMailMergeTemplateAdded: boolean;
    emailToLink: string;
    uploadSaveUrl: string;
    uploadRestrictions: FileRestrictions = {
        allowedExtensions: [".jpg", ".png", ".pdf", ".docx", ".txt", ".gif", ".jpeg", ".xlsx", ".pptx", ".bmp", ".tiff"],
        maxFileSize: 3145728
    };
    fileUploadHeaders: HttpHeaders;
    toUser: string = "";
    previewBody: string = '';

    constructor(private fb: FormBuilder, private _router: Router, private datepipe: DatePipe,
        private _outBoundEmailService: OutBoundEmailService,
        private _noteService: NotesService,
        private _utilityService: UtilityService,
        private _appConfigService: AppconfigService,
        private notifyService: NotificationService,
        private _globalService: GlobalService,
        public _localService: LocalService,
        public _contactService: ContactService,
        public _contactSearchService: ContactSearchService,
        public _emailTemplateService: EmailTemplateService,
        public _notesService: NotesService,
        public _myDocumentService: MyDocumentService,
        private _userService: UserService,
        @Inject('BASE_URL') _baseUrl: string
    ) {
        this.baseUrl = _baseUrl;
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;

                this._appConfigService.getAppConfigValue(this.encryptedUser, "SO_Site")
                    .then(async (result: ConfigDetails) => {
                        if (result) {
                            this.soUrl = result?.configValue;
                        }
                    });

                this._appConfigService.getAppConfigValue(this.encryptedUser, "MySo_Help")
                    .then(async (result: ConfigDetails) => {
                        if (result) {
                            this.helpSite = result?.configValue;
                        }
                    })
            }
        });
    }
    ngOnInit() {
        if (this.isForActivities) {
            this.submitBtnTxt = 'Save';
        }
        this._localService.getEmail().subscribe(msg => {
            this.emailBind = msg;
        });
        this.emailForm = this.prepareEmailForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                if (this.loggedUser) {
                    this.fileUploadHeaders = new HttpHeaders({
                        'Authorization': 'Basic ' + this.encryptedUser
                    });
                    this.getUserTimeZone()
                    this.dateFormat = this.loggedUser.dateFormat;
                    if (!isNullOrUndefined(this._localService.contactFields) && (this._localService.contactFields.contactID.fieldValue == this.contactId)) {
                        this.loadInitData();
                    }
                    else {
                        this.getContactFields(this.contactId, this.loggedUser.cLPCompanyID, this.loggedUser.cLPUserID);
                    }
                    this.getEmailDropDowns();
                    this.getRecentDocumentsListByClpUser();
                        //this._localService.getContactsId().subscribe(msg => {
                    //    this.contactId = msg;
                    //    this.getContactFields(this.contactId, this.loggedUser.cLPCompanyID, this.loggedUser.cLPUserID);
                    //    this.getEmailDropDowns();
                    //    this.getRecentDocumentsListByClpUser();
                    //});
                    this._contactSearchService.showSpinner = false;
                }
            }
            else
                this._router.navigate(['/unauthorized']);
        });
    }


    async getUserTimeZone() {
        await this._userService.getTimeZoneById(this.encryptedUser, this.loggedUser.timeZoneWinId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    var time = response.messageString.split('.');
                    this.timeZoneResult = time[0];
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-email.getUserTimeZone", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getContactFields(contactId, companyID, userId) {
        this._localService.getContactFields(this.encryptedUser, contactId, companyID, userId, true)
            .subscribe((value) => {
                this.loadInitData()
            });
    }

    loadInitData() {
        this.contactFields = this._localService.contactFields;
        this.loadEmailForm();
        this.loadAfuConfig();
        this.statusEmailLoad();
    }

    loadEmailForm() {
        this.emailForm.patchValue({
            to: this.contactFields.email.fieldValue,
            //from: this.loggedUser.userName,
            type: 'contact',
            body: this.loggedUser?.defaultSignature
        });
    }

    loadAfuConfig() {
        this.uploadSaveUrl = this.baseUrl + 'api/OutBoundEmail/Document_Post/' + this.loggedUser.cLPCompanyID + '/' + this.contactFields.contactID.fieldValue + '/' + this.loggedUser.cLPUserID;
    }

    private prepareEmailForm(): FormGroup {
        return this.fb.group({
            type: new FormControl(''),
            to: new FormControl('', [Validators.required, this.validateEmailsAndPhones]),
            from: new FormControl(''),
            cc: new FormControl('', Validators.pattern(/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})(,\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})*$/)),
            bcc: new FormControl('', Validators.pattern(/^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})(,\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4})*$/)),
            subject: new FormControl(''),
            mailMergeTemplateID: new FormControl(0),
            emailTemplateID: new FormControl(0),
            emailSnippetID: new FormControl(0),
            regardingLink: new FormControl(false),
            body: new FormControl(''),
            fromClpUserId: new FormControl(''),
        });
    }

    validateEmailsAndPhones(control: FormControl) {
        if (!isNullOrUndefined(control.value) && typeof control.value != "number") {
            const emailsPhonesArray: string[] = control.value.split(',');
            const isValid = emailsPhonesArray.every((entry: string) => {
                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                const phoneRegex = /^-?(0|[1-9]\d*)?$/;

                return emailRegex.test(entry.trim()) || phoneRegex.test(entry.trim());
            });
            return isValid ? null : { invalidEmailsOrPhones: true };
        }
    }

    get sendEmailFrm() {
        return this.emailForm.controls;
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched();
                control.updateValueAndValidity();
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }
    onCloseEmail() {
        this.resetEmailForm();
        this._localService.hideCommonComponentEmit('email');
        this._localService.showCommonComp = '';
    }

    getEmailDropDowns() {
        this._outBoundEmailService.getEmailDropDowns(this.encryptedUser, this.loggedUser.cLPCompanyID, this.loggedUser.cLPUserID, this.loggedUser.teamCode)
            .then(async (result: EmailDropDownsResponse) => {
                if (result) {
                    this.emailDropDownsResponse = UtilityService.clone(result);
                    this.users = this.emailDropDownsResponse?.userToList;
                    this.usersFrom = this.emailDropDownsResponse?.userFromList;
                    if (this.users && this.users.length > 0) {
                        this.users.map((e) => {
                            const splitU = e?.value?.split(":", 3);
                            e.value = splitU.length == 3 ? splitU[1] + ', ' + splitU[0] + ' (' + splitU[2] + ')' : splitU.length == 2 ? splitU[1] + ', ' + splitU[0] : splitU.length == 1 ? splitU[0] : e?.value;
                        })
                    }
                    this.emailFormPatchValueByField('from');
                    this.emailSnippets = this.emailDropDownsResponse?.emailSnippets;
                    this.emailTemplates = this.emailDropDownsResponse?.emailTemplates;
                    this.mailMergeTemplates = this.emailDropDownsResponse?.mailMergeTemplates;
                    let filterArr = [];
                    if (this.loggedUser.userRole == 1 && this.emailTemplates)
                        filterArr = this.emailTemplates?.filter(s => s.templateName == '2018 - PROSPECT (HTB) Info with Pitch and Price - LIVE');
                    if (filterArr.length > 0)
                        this.emailForm.patchValue({ emailTemplateID: filterArr[0].emailTemplateID });
                    else
                        this.emailForm.patchValue({ emailTemplateID: 0 });
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-email.getEmailDropDowns", err.message, null,
                    'cLPCompanyID: ' + this.loggedUser.cLPCompanyID
                    + ' cLPUserID: ' + this.loggedUser.cLPUserID
                    + ' teamCode: ' + this.loggedUser.teamCode
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getRecentDocumentsListByClpUser() {
        this._outBoundEmailService.getDocumentsListByOwner(this.encryptedUser, this.contactId)
            .then(async (result: DocumentResponse) => {
                if (result) {
                    this.documentRecentResponse = UtilityService.clone(result);
                    this.documents = this.documentRecentResponse.documents;
                    this.documentImageUrl();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-email.getRecentDocumentsListByClpUser", err.message, null,
                    ' cLPUserID: ' + this.loggedUser.cLPUserID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getContactDocumentsListByOwner() {
        this._outBoundEmailService.getDocumentsListByOwner(this.encryptedUser, this.contactFields.contactID.fieldValue)
            .then(async (result: DocumentResponse) => {
                if (result) {
                    this.documentGridTitle = 'Contact Files';
                    this.documentContactResponse = UtilityService.clone(result);
                    this.documents = this.documentContactResponse?.documents;
                    this.documentImageUrl();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-email.getContactDocumentsListByOwner", err.message, null,
                    ' contactID: ' + this.contactFields.contactID.fieldValue
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    searchDocumentsList() {
        if (!isNullOrUndefined(this.searchValue)) {
            const splitArr = this.searchValue.split(/[, ]/);
            if (splitArr.length > 0) {
                this._outBoundEmailService.documentsSearch(this.encryptedUser, splitArr, this.loggedUser?.cLPUserID)
                    .then(async (result: DocumentResponse) => {
                        if (result) {
                            this.documentGridTitle = 'Results';
                            this.documentSearchResponse = UtilityService.clone(result);
                            this.documents = this.documentSearchResponse?.documents;
                            this.documentImageUrl();
                        }
                    })
                    .catch((err: HttpErrorResponse) => {
                        this._globalService.error("contact-email.searchDocumentsList", err.message, splitArr,
                            ' cLPUserID: ' + this.loggedUser?.cLPUserID
                        );
                        this._utilityService.handleErrorResponse(err);
                    });
            }
        }
        else
            this.notifyService.showError("Please enter document name.", "", 2000);
    }

    documentImageUrl() {
        this.documents.forEach((item) => {
            if (item?.documentTypeIcon) {
                const splitValue = item?.documentTypeIcon.split(/[..]/);
                item.documentTypeIcon = splitValue.length > 0 ? splitValue[2] + '.png' : item.documentTypeIcon;
            }
        })
    }

    changeToType(e) {
        this.mailMergeWarning = "";
        if (e) {
            this.emailForm.patchValue({ regardingLink: false });
            this.selectedType = e.target?.value;
            if (this.selectedType == 'other') {
                this.emailForm.get('to').setValue('');
                this.emailForm.patchValue({ regardingLink: true });
            }
            if (this.selectedType == 'contact')
                this.emailForm.get('to').setValue(this.contactFields.email.fieldValue);
            if (this.selectedType == 'user' && this.users?.length > 0) {
                var usersArray = this.users.map(x => Object.assign({}, x));
                var manipulatedArr = [];
                usersArray.map(function (e) {
                    var splittedArr = e?.value.split(/[:(]/, 2);
                    e.value = splittedArr.length == 2 ? splittedArr[1] + ', ' + splittedArr[0] : splittedArr.length == 1 ? splittedArr[0] : e?.value;
                    manipulatedArr.push(e);
                })
                this.toUsersArr = manipulatedArr;
                this.emailForm.patchValue({ regardingLink: true });
                this.emailFormPatchValueByField('to');
            }

        }
    }

    emailFormPatchValueByField(field) {
        if (this.users && this.users.length > 0) {
            const user = this.users.find(x => x.key === this.loggedUser?.cLPUserID);
            if (field) {
                switch (field) {
                    case "from":
                        if (this.emailDropDownsResponse?.isMultipleFromAddress) {
                            this.emailForm.patchValue({ from: user?.key });
                            this.emailForm.patchValue({ fromClpUserId: user?.key });
                        }
                        else {
                            this.emailForm.patchValue({ from: user?.value });
                            this.emailForm.patchValue({ fromClpUserId: user?.key });
                        }
                        break;
                    case "to":
                        this.emailForm.patchValue({ to: user?.key });
                        break;
                }
            }
        }
    }

    fileUploadSuccess(e: SuccessEvent) {
        if (!isNullOrUndefined(e)) {
            var uploadList = e.response.body.list;
            uploadList.forEach((item) => {
                this.attachedFiles.push(item);
            })
        }
    }

    fileUploadError(e) {
        if (!isNullOrUndefined(e)) {
            this.notifyService.showError(e.response.message, "", 2000);
        }
    }

    attachDetachFile(type, data, index?) {
        if (type) {
            switch (type) {
                case "add":
                    data.isSelected = true;
                    this.attachedFiles.push(data);
                    break;
                case "remove":
                    data.isSelected = false;
                    this.attachedFiles = this.attachedFiles.filter(({ documentId }) => documentId !== data.documentId);
                    break;
            }
        }
    }

    changeEmailSnippet(e) {
        if (this.emailForm.controls.emailSnippetID?.value) {
            this._outBoundEmailService.loadEmailSnippet(this.encryptedUser, this.emailForm.controls.emailSnippetID?.value)
                .then(async (result: EmailSnippetResponse) => {
                    if (result) {
                        this.emailSnippetResponse = UtilityService.clone(result);
                        this.selectedEmailSnippet = this.emailSnippetResponse.emailSnippet ? this.emailSnippetResponse.emailSnippet.snippetText : '';
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("contact-email.changeEmailSnippet", err.message, null,
                        ' emailSnippetID: ' + this.emailForm.controls.emailSnippetID?.value
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    changeValueKendoEditor(value: any): void {
        this.selectedEmailSnippet = value;
    }

    nextButton() {
        this.showDatePicker = false;
        this.isMailMergeError = false;
        this.isCancelEmail = true;
        if (isNullOrEmptyString(this.emailForm.controls.to.value)) {
            this.notifyService.showError("Please enter comma delimited list of email addresses to send this email to.", "", 5000);
            return;
        }

        if ((this.emailForm.controls.emailSnippetID?.value > 0) && ((!this.emailForm.controls.emailTemplateID?.value) || (this.emailForm.controls.emailTemplateID?.value <= 0))) {
            this.notifyService.showSuccess("Please select an Email Template since you are using an Email Snippet.", "", 5000);
            return;
        }
        if ((this.emailForm.controls.mailMergeTemplateID?.value > 0)) {
            this.mailMergeWarning = "Attaching a mail merge document requires that you schedule this email for later. \n Please select below an approximate date and time you would like this email sent."
            this.isMailMergeTemplateAdded = true
            this.validateAllFormFields(this.emailForm);
            if (this.emailForm.valid)
                this.isViewMode = true;
        }
        else {
            this.emailToLink = this.generateOutlookLink()
            this.validateAllFormFields(this.emailForm);
            if (this.emailForm.valid) {
                this.isViewMode = true;
                this.scheduleEmail()
            }
        }

    }

    async emailFormSubmit() {
        this.showDatePicker = false;
        this.isMailMergeError = false;
        if (!(this.statusEmail?.infoSubStatus == 1) && !(this.statusEmail?.promoSubStatus == 1)) {
            this.mailMergeWarning = "This email has <span style='color:#8b0000; margin-right:0px;'>Un-Subscribed</span> to receive both Informational or Promotional emails.";
            this.disableBtnOnSubmit = true;
            return;
        }
         else if (this.submitBtnTxt == 'Send Email' || this.submitBtnTxt == 'Send Via SalesOptima') {
            this.sendEmail(false)
        }
        else {

            if ((this.emailForm.controls.emailSnippetID?.value > 0) && ((!this.emailForm.controls.emailTemplateID?.value) || (this.emailForm.controls.emailTemplateID?.value <= 0))) {
                this.notifyService.showSuccess("Please select an Email Template since you are using an Email Snippet.", "", 5000);
                return;
            }
            this.validateAllFormFields(this.emailForm);
            if (this.emailForm.valid) {
                this.emailForm.markAsPristine();
                this.scheduleEmail();
            }
        }

    }

    async scheduleEmail() {
        if (this.forMessageBoolValue == true && this.submitBtnTxt == 'Schedule Email') {
            this.notifyService.showSuccess('Email has been scheduled successfully.', "", 5000);
            this.isCloseWindow = true;
            this.isCancelEmail = false;
            this.isMailMergeTemplateAdded = false
            this.disableBtnOnSubmit = false;
            this._localService.scrollTop(this.scrollIdTarget.nativeElement);
        }
        //if (this.forMessageBoolValue == false && this.submitBtnTxt == 'Next') {
        //    this.showDatePicker = true;
        //    this.isMailMergeError = true;
        //    this.submitBtnTxt = 'Schedule Email';
        //    this._localService.scrollTop(this.scrollIdTarget.nativeElement);
        //}
        else {
            this.disableBtnOnSubmit = true;
            this.isMailMergeError = false;
            this.copyDataObjectToNoteAPIObject();
            this._outBoundEmailService.scheduleEmailOrSend(this.encryptedUser, this.note)
                .then(async (result: NoteResponse) => {
                    if (result) {
                        this.noteResponse = UtilityService.clone(result);
                        this.noteResult = this.noteResponse.note;
                        this.noteId = this.noteResult.noteID;
                        this.noteResult.messageBool = this.forMessageBoolValue;
                        this.previewBody = this.noteResult?.noteDesc;
                        //this.sendEmail(true);
                        if (this.isForActivities) {
                            this.closeEmailModal.emit(true);
                            return
                        }
                        if (this.noteResult.status == 1) {
                            this.showDatePicker = true;
                            this.isMailMergeError = true;
                            this.datePickerValue = new Date(this.noteResult.dtSent);
                            this.submitBtnTxt = 'Schedule Email';
                            this.forMessageBoolValue = true;
                            this._localService.scrollTop(this.scrollIdTarget.nativeElement);
                            this.disableBtnOnSubmit = false;
                            this.isCancelEmail = true;
                            return;
                        }
                        if (this.noteResult.mailMergeTemplateID > 0) {
                            this._localService.handledEventEmit(true);

                            this.emailForm.controls.mailMergeTemplateID.patchValue(0);
                            this.notifyService.showSuccess('Email has been scheduled successfully.', "", 5000);
                            this.isCloseWindow = true;
                            this.isCancelEmail = false;
                            this.isMailMergeTemplateAdded = false

                            this.disableBtnOnSubmit = false;
                        }
                        else {
                            this.submitBtnTxt = this.isFromLead ? 'Send Via SalesOptima' : 'Send Email';
                            this.disableBtnOnSubmit = false
                        }

                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("contact-email.scheduleEmail", err.message, null,
                        ' note: ' + this.note
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    ngOnDestroy() {
        this.resetEmailForm();
    }

    sendEmail(isPreview) {
        this._outBoundEmailService.sendOutboundMail(this.encryptedUser, this.noteResult, isPreview)
            .then(async (result: NoteResponse) => {
                if (result) {
                    if (!isPreview) {
                        this._localService.handledEventEmit(true);
                        this._contactSearchService.showSpinner = false;
                        const response = UtilityService.clone(result);
                        this.notifyService.showSuccess(response?.messageString ? response?.messageString : 'Email has been sent successfully.', "", 5000);
                        this.isCloseWindow = true;
                        this.isCancelEmail = false;
                        /* this.resetEmailForm();*/
                    }
                   
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-email.sendEmail", err.message, null,
                    ' note: ' + this.noteResult
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    resetEmailForm() {
        this.emailForm.reset();
        this.emailForm = this.prepareEmailForm();
    }

    copyDataObjectToNoteAPIObject() {
        const documentList = [];
        if (this.attachedFiles.length > 0) {
            for (let i = 0; i < this.attachedFiles.length; i++) {
                documentList.push(this.attachedFiles[i].documentId ? this.attachedFiles[i].documentId.toString() : this.attachedFiles[i].key);
            }
        }
        const documents = documentList.toString();
        const toChoice = this.emailForm.controls.type?.value ? this.emailForm.controls.type?.value == 'contact' ? 1 : this.emailForm.controls.type?.value == 'user' ? 2 : this.emailForm.controls.type?.value == 'other' ? 3 : this.emailForm.controls.type?.value == 'contactAllEmails' ? 6 : 4 : 0;
        this.note = {
            noteID: 0,
            cLPCompanyID: this.loggedUser.cLPCompanyID,
            cLPUserID: this.loggedUser.cLPUserID,
            ownerID: this.isFromLead ? this.leadId : this.contactFields.contactID.fieldValue,
            ownerType: this.isFromLead ? 3 : 2,
            noteTypeCode: 0,
            toChoice: toChoice,
            toField: this.emailForm.controls.to?.value,
            cCField: this.emailForm.controls.cc?.value ? this.emailForm.controls.cc?.value : '',
            bCCField: this.emailForm.controls.bcc?.value ? this.emailForm.controls.bcc?.value : '',
            noteSubject: this.emailForm.controls.subject?.value ? this.emailForm.controls.subject?.value : '',
            mailMergeTemplateID: this.emailForm.controls.mailMergeTemplateID?.value ? this.emailForm.controls.mailMergeTemplateID?.value : 0,
            emailTemplateID: this.emailForm.controls.emailTemplateID?.value ? this.emailForm.controls.emailTemplateID?.value : 0,
            emailSnippetID: this.emailForm.controls.emailSnippetID?.value ? this.emailForm.controls.emailSnippetID?.value : 0,
            documentList: documents, //
            dtSent: !isNullOrUndefined(this.datePickerValue) ? this.datepipe.transform(this.datePickerValue, 'MM-dd-yyyy hh:mm:ss a') : null,
            noteDesc: this.emailForm.controls.body?.value ? this.emailForm.controls.body?.value : '',
            fromCLPUserID: this.emailDropDownsResponse?.isMultipleFromAddress ? this.emailForm.controls?.fromCLPUserID?.value : this.loggedUser.cLPUserID,
            scheduledTime: '',
            emailpreviewLink: '',
            campaignID: 0,
            campaignEventID: 0,
            runID: 0,
            status: 0,
            emailStatus: 0,
            emailResult: '',
            cLPServiceRunID: 0,
            messageBool: this.noteResult && this.noteResult.messageBool ? this.noteResult.messageBool : false,
        }
    }

    changeMailMerge(e) {
        this.forMessageBoolValue = false;
        this.submitBtnTxt = this.emailForm.controls.mailMergeTemplateID?.value > 0 ? 'Next' : 'Send Email';
    }

    goToLink(type, id?) {
        if (type) {
            switch (type) {
                case "help": {
                    const helpUrl = this.helpSite + 'help/page.aspx?hpid=69';
                    window.open(helpUrl, '_blank');
                    break;
                }
                case "mailPlaceholderList": {
                    const helpUrl = 'https://www.salesoptima.com/support/email-placeholder-list';
                    window.open(helpUrl, '_blank');
                    break;
                }
                case "invoice": {
                    this.isCloseButton.emit(true);
                    break;
                }
            }
        }
    }

    get getCurrentDate(): Date {
        return new Date();

    }

    hideSendMail() {
        this.onCloseEmail();
        this.closeEmailModal.emit(true);
        $('#sendEmailModal').modal('hide');
        this.isCancelEmail = false;
        this.isCloseWindow = false;
        this.isViewMode = false;
    }

    getUserEmail(fromUser) {
        const user = this.users?.find(x => x.key == fromUser);
        return user?.value;
    }

    getDropDownValue(value: number, rowName: string) {
        if (!isNullOrUndefined(value) && !isNullOrEmptyString(rowName)) {
            switch (rowName) {
                case 'fromUser':
                    const userName = this.users?.filter(item => item?.key === value)[0]?.value;
                    return userName;
                case 'templateID':
                    const templateNameMM = this.mailMergeTemplates?.filter(item => item?.mailMergeTemplateID == value)[0]?.templateName;
                    return templateNameMM;
                case 'emailTemplate':
                    const templateNameEmail = this.emailTemplates?.filter(item => item?.emailTemplateID == value)[0]?.templateName;
                    return templateNameEmail;
                case 'emailSnippet':
                    const emailSnippet = this.emailSnippets?.filter(item => item?.emailSnippetID == value)[0]?.snippetTitle;
                    return emailSnippet;
            }
        }
    }

    previewEmail() {
        if (this.sendEmailFrm.emailTemplateID?.value && this.sendEmailFrm.emailTemplateID?.value != '') {
            localStorage.setItem('object', JSON.stringify(this.htmlDisplay));
            const url = this._router.serializeUrl(
                this._router.createUrlTree([`/template-preview/${this.emailForm?.value?.emailTemplateID}/emt`])
            );
            window.open(url)
        }
        else {
            localStorage.setItem('txtBody', JSON.stringify(this.emailForm?.value?.body));
            const url = this._router.serializeUrl(
                this._router.createUrlTree([`/template-preview/${0}`])
            );
            window.open(url)
        }
    }

    loadEmailTemplate() {
        this._emailTemplateService.getEmailTemplateById(this.encryptedUser, this.emailForm?.value?.emailTemplateID)
            .then(async (result: EmailTemplateLoad) => {
                if (!isNullOrUndefined(result))
                    this.htmlDisplay = UtilityService.clone(result?.htmlText);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-email.loadEmailTemplate", err.message, null,
                    ' emailTemplateID: ' + this.emailForm?.value?.emailTemplateID
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async downloadDocuments(dataItem) {
        await this._myDocumentService.downloadDocuments(this.encryptedUser, dataItem?.documentId)
            .then(async (result: Document[]) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    this._localService.downloadFile(response);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-email.downloadDocuments", err.message, null,
                    ' documentId: ' + dataItem?.documentId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    hideLeadModal() {
        $('#leadInvoiceModal').modal('hide');
        this.leadInvoice.leadId = 0;
        this.invoiceId = 0;
        this.isNewInvoice = false;
        /*this.closeLeadButton = true;*/
        this.leadInvoice.isShow = false;

    }

    cancelEmail() {
        if (this.isForActivities) {
            this.closeEmailModal.emit(true);
            this.deleteNote();
        }
        else {
            this.deleteNote();
        }

    }

    async deleteNote() {
        await this._notesService.noteDelete(this.encryptedUser, this.noteId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this.isCancelEmail = false;
                    this.isCloseWindow = true;
                    this.notifyService.showError("Email has been cancelled.", "", 3000);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact-email.deleteNote", err.message, null,
                    ' noteId: ' + this.noteId
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    addText(value: string) {
        this.emailForm.get('subject').patchValue(this.emailForm.controls.subject?.value + ' ' + value);
    }

    async statusEmailLoad() {
        if (!isNullOrEmptyString(this.contactFields?.email?.fieldValue) && this.contactFields?.email?.fieldValue.includes("@")) {
            this.mailMergeWarning = "";
            await this._noteService.statusEmailLoad(this.encryptedUser, this.contactFields?.cLPCompanyID?.fieldValue, this.contactFields?.email?.fieldValue)
                .then(async (result: StatusEmailResponse) => {
                    if (!isNullOrUndefined(result)) {
                        this.statusEmail = UtilityService.clone(result?.statusEmail);

                        if (this.statusEmail?.infoSubStatus == eEmailOptType.Subscribed) {
                        }
                        else if (this.statusEmail?.infoSubStatus == 1 && !(this.statusEmail?.promoSubStatus == 1)) {
                            this.strEmailMsg.push("This email has <span style='color:#8b0000; margin-right:0px;'>Un-Subscribed</span> to receive Promotional emails.");
                        }
                        else if (!(this.statusEmail?.infoSubStatus == 1) && (this.statusEmail?.promoSubStatus == 1)) {
                            this.strEmailMsg.push("This email has <span style='color:#8b0000; margin-right:0px;'>Un-Subscribed</span> to receive Informational emails.");
                        }
                        else if (!(this.statusEmail?.infoSubStatus == 1) && !(this.statusEmail?.promoSubStatus == 1)) {
                            this.strEmailMsg.push("This email has <span style='color:#8b0000; margin-right:0px;'>Un-Subscribed</span> to receive both Informational or Promotional emails.");
                        }
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("contact-email.statusEmailLoad", err.message, null,
                        ' cLPCompanyID: ' + this.contactFields?.cLPCompanyID?.fieldValue
                        + ' email: ' + this.contactFields?.email?.fieldValue
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            this.mailMergeWarning = "No email address exists for this contact."
        }
    }

    generateOutlookLink() {

        let txtCC = this.emailForm.controls.cc?.value
        let txtBCC = this.emailForm.controls.bcc?.value
        let txtSubject = this.emailForm.controls.subject?.value
        let otxtBody = this.emailForm.controls.body?.value
        let lblTo = this.emailForm.controls.to?.value
        let strCC = ""
        let strBody = ""
        let strBCC = ""
        if (txtCC.trim() != '') { strCC = "cc=" + txtCC.trim() + "&" }
        if (txtBCC.trim() != "") { strBCC = "bcc=" + txtBCC.trim() + "&" }
        let strSubject = ""
        if (txtSubject.trim() != "") {
            strSubject = "subject=" + txtSubject.replace(" ", "%20").replace(",", "%2C").replace("!", "%21").replace("$", "%24") + "&"
            if (otxtBody.trim != "") {
                if (otxtBody.length > 250) {
                    let msg;
                    msg = "Warning: Body may be truncated when sending via Outlook."
                }
                strBody = "body=" + otxtBody + "&"
                strBody = strBody.replace("+", "%20")
            }
        }
        return "mailto:" + lblTo.trim() + "?" + strCC + strBCC + strSubject + strBody
    }

    selectUserType(to) {
        this.toUser = this.toUsersArr.filter(x => x.key == to)[0]?.value;
    }

    back() {
        this.isViewMode = false;
    }
}

