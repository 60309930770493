import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { ClubModuleSettings, ClubModuleSettingsResponse } from '../../../models/ClubModuleSettings.model';
import { eButtonActions, eFeatures, eUserRole } from '../../../models/enum.model';
import { ClubModuleSettingsService } from '../../../services/clubmodulesettings.service';
import { GlobalService } from '../../../services/global.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';


import { Router } from '@angular/router';

import { isNullOrUndefined } from 'util';

import { NotificationService } from '../../../services/notification.service';

import { UserService } from '../../../services/user.service';
import { SignupService } from '../../../services/signup.service';
import { BilligService } from '../../../services/billing.service';
import { TxtMsgService } from '../../../services/textmsg.service';


import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { CLPAddOnBilling } from '../../../models/clpBilling.model';
import { TxtMsgSettings, TxtMsgSettingsResponse } from '../../../models/txtMsg.model';
import { EmailTemplate } from '../../../models/emailTemplate.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { keyValue } from '../../../models/search.model';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { CampaignTemplate } from '../../../models/appt.model';



@Component({
    selector: 'club-module-settings',
    templateUrl: './club-module-settings.component.html',
    styleUrl: './club-module-settings.component.css'
})
export class ClubModuleSettingsComponent {
    userResponse: UserResponse;
    @Input() roleFeaturePermissions: RoleFeaturePermissions;
    @Input() isShowEmailSettings: boolean = true;
    @Input() encryptedUser: string;
    @Input() user: CLPUser;
    showSpinner: boolean;
    isShowRequestActivation: boolean = true;
    public ClubModuleSettingsDataResponse: ClubModuleSettingsResponse;
    messageInfo: string = "";
    editShow: boolean = false;
    @Input() selectedCompanyId: number = 0;
    buttonTypeOperation: eButtonActions = eButtonActions.None;
    clubModuleSettingsForm: FormGroup
    editData() {
        this.editShow = !this.editShow;
    }

    backToView() {
        this.editShow = false;
    }
    clubModuleSettingsFormSubmit() {

    }

    ngOnInit() {
       
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (!isNullOrUndefined(this.user)) {
                        if (this.user?.userRole <= eUserRole.Administrator) {
                            if (this.roleFeaturePermissions?.view == false)
                                this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            else
                                this.loadMessageSettings();
                        }
                        else
                            this.loadMessageSettings();
                    } else {
                        this.authenticateR().then(() => {
                            if (!isNullOrUndefined(this.user))
                                this.loadMessageSettings();
                            else
                                this._router.navigate(['/login']);
                        })
                    }
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    constructor(private fb: FormBuilder,
        public _localService: LocalService,
        private _globalService: GlobalService,
        private _utilityService: UtilityService,
        private _router: Router,
        private _ClubModuleSettingsSrvc: ClubModuleSettingsService) {
        this.clubModuleSettingsForm = this.prepareclubModuleSettingsForm();
         
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.TextMessageSettings)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("textMsgSetting.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }
    prepareclubModuleSettingsForm() {
        return this.fb.group({
            cLPCompanyID: new FormControl(0),
            InfoSiteURL: new FormControl(''),
            LearnSiteURL: new FormControl('')

        });
    }

    loadMessageSettings() {

        this.clubModuleSettingsForm = this.prepareclubModuleSettingsForm();
        this.getFormData();
    }

    async getFormData() {
        this.showSpinner = true;
        let companyID = this.selectedCompanyId == 0 ? this.user.cLPCompanyID : this.selectedCompanyId
        
        await this._ClubModuleSettingsSrvc.CMSettings_Load(this.encryptedUser, companyID)
            .then(async (result: ClubModuleSettingsResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this.ClubModuleSettingsDataResponse = response;

                }

                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("txt-msg-setting.getTxtMsgSettingFormData", err.message, null, 'cLPCompanyID ' + companyID + "," + "cLPUserID " + this.user.cLPUserID + "," + "slurpyUserId " + this.user.slurpyUserId);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });

    }


}
