import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser } from '../../../models/clpuser.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { BuzzScore, ScoreHistory, ScoreHistoryResponse } from '../../../models/report.model';
import { GlobalService } from '../../../services/global.service';
import { NotificationService } from '../../../services/notification.service';
import { ReportService } from '../../../services/report.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
    selector: 'buzz-index',
    templateUrl: './buzz-index.component.html',
  styleUrls: ['./buzz-index.component.css'],
  providers: [GridConfigurationService]
})
export class BuzzIndexComponent implements OnInit {
  private encryptedUser: string = '';
  showSpinner: boolean = false;

  scoreHistoryResponse: ScoreHistory;
  @Input() user: CLPUser;
  @Input() buzzIndexCalcModal: BuzzScore;
  @Input() scoreType: number = 0;
  @Input() startDt: string;
  @Input() endDt: string;
  dateFormat: string = "MM/dd/yyyy";
  columns = [
    { field: '$', title: '', width: '40' },
    { field: 'score', title: 'Index', width: '100' },
    { field: 'type', title: 'Type', width: '100' },
    { field: 'dtCreated', title: 'Created', width: '100' },
    { field: 'delete', title: '', width: '30' },
  ];
  reorderColumnName: string = 'score,type,dtCreated,delete';
  arrColumnWidth: any[] = ['score:100,type:100,dtCreated:100,delete:30'];
  columnWidth: string = 'score:100,type:100,dtCreated:100,delete:30';
    contactName: any;
    buzzScore: any;
  constructor(private _utilityService: UtilityService,
    public _localService: LocalService,
    private _router: Router,
    public _gridCnfgService: GridConfigurationService,
      private _reportService: ReportService,
      private _globalService: GlobalService,
      private notifyService: NotificationService,
    private datePipe: DatePipe) {

  }

ngOnInit(): void {
    if (!isNullOrUndefined(localStorage.getItem("token"))) {
        this.encryptedUser = localStorage.getItem("token");           
        if (!isNullOrUndefined(this.user)) {
            this.dateFormat = this.user.dateFormat;
            this._gridCnfgService.user = this.user;
            this.getGridConfiguration();
            this.getBuzzScoreCalculation();
    }
    } 
  }

  getGridConfiguration() {
    this._gridCnfgService.columns = this.columns;
    this._gridCnfgService.reorderColumnName = this.reorderColumnName;
    this._gridCnfgService.columnWidth = this.columnWidth;
    this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
    this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'buzz_score_calculation_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('buzz_score_calculation_grid').subscribe((value) => { }));
  }

  async resetScoreHistory() {
    this.showSpinner = true;
    await this._reportService.getResetScoreHistory(this.encryptedUser, this.buzzIndexCalcModal?.contactID ?? 0)
        .then(async (result: SimpleResponse) => {
            if (result && result?.messageBool) {
                this.getBuzzScoreCalculation();
                this.notifyService.showSuccess("Score has been reset.", "", 3000);
            }
            else {
                this.notifyService.showError("Some error occured", "", 3000);
            }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("buzz-index.getLeadHistory", err.message, null, 'contactID ' + this.buzzIndexCalcModal?.contactID);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async resetSingleScore(scoreHistoryData: ScoreHistory) {
    this.showSpinner = true;
    const dtCreated = this.datePipe.transform(scoreHistoryData.dtCreated, 'MMddyyyy') ?? '';
    await this._reportService.getDeleteScoreHistory(this.encryptedUser, scoreHistoryData.contactID, this.scoreType, dtCreated)
      .then(async (result: SimpleResponse) => {
        if (result) 
          this.getBuzzScoreCalculation();
        this.showSpinner = false
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("buzz-index.getLeadHistory", err.message, null, 'contactID ' + scoreHistoryData.contactID + "," + "scoreType " + this.scoreType + "," + "dtCreated " + dtCreated);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async getBuzzScoreCalculation() {
    this.showSpinner = true;
    const startDate = this.datePipe.transform(this.startDt, 'MMddyyyy') ?? '';
    const endDate = this.datePipe.transform(this.endDt, 'MMddyyyy') ?? '';
    await this._reportService.getScoreHistoryResponse(this.encryptedUser, this.buzzIndexCalcModal?.contactID ?? 0, this.user?.cLPUserID, startDate, endDate, this.scoreType)
      .then(async (result: any) => {
        if (result) { 
          this.scoreHistoryResponse = UtilityService.clone(result?.scoreHistory);
          this.buzzScore = this.scoreHistoryResponse[0]?.score
          this.contactName = result?.contactName
          this.showSpinner = false;
         }
          this.showSpinner = false;
          
      })
      .catch((err: HttpErrorResponse) => {
          this._globalService.error("buzz-index.getLeadHistory", err.message, null, 'buzzIndexCalcModal?.contactID ' + this.buzzIndexCalcModal?.contactID + "," + "cLPUserID " + this.user?.cLPUserID + "," + "startDate " + startDate + "," + "endDate " + endDate + "," + "scoreType " + this.scoreType);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

}
