<div class="custom-security-link">
    <a class="custom-security-link" (click)="myProfile()">My Profile</a>
    <a [routerLink]="['/outlook-integration']">Outlook Integration</a>
</div>
<div class="create-email">
    <div class="global-padding10">
        <div class="wraper-main-section">
            <div class="global-card-section">
                <div class="global-header-section">
                    <div class="svg-icon-panel"><img src="../../../assets/gsynctitle.svg" class="mr-1" />Google Integration Settings (Beta)</div>
                </div>
                <div class="global-body-section">
                    <div class="global-padding10">
                        <p class="company-2d-alert mb-0" *ngIf="errorMsg">{{errorMsg}}</p>
                        <p class="message-info mb-0" *ngIf="alertMessage">{{alertMessage}}</p>
                    </div>
                    <form [formGroup]="googleIntegrationForm" *ngIf="googleIntegrationForm">
                        <div class="custom-action-title">
                            <span>Google Authentication</span>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                                <span class="control-label">Google Username</span>
                            </div>
                            <div class="cards-colunm-right">
                                <div class="w-100">
                                    <input type="text" class="form-control" formControlName="userName" />
                                    <div class="login-error" *ngIf="googleIntegrationForm.controls.userName.errors &&(googleIntegrationForm.controls.userName.touched || googleIntegrationForm.controls.userName.dirty)">
                                        <div *ngIf="googleIntegrationForm.controls.userName.touched">Email is required.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="cards-body-section">
                            <div class="cards-colunm-left">
                            </div>
                            <div class="cards-colunm-right">
                                <button class="btn btn-primary" type="button" (click)="getGoogleIntegrationSettingByUserName()">Connect SalesOptima and Google</button>
                            </div>
                        </div>
                        <div *ngIf="pnlFirstSync">
                            <div class="custom-action-title">
                                <span>Synchronization Settings</span>
                            </div>
                            <div class="global-padding10">
                                <p class="company-2d-alert mb-0" *ngIf="configureMessage">{{configureMessage}}</p>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                    <div class="">
                                        <span class="control-label"></span>
                                    </div>
                                </div>
                                <div class="cards-colunm-right">
                                    <button class="btn btn-primary" type="button">{{configureButtonTxt}}</button>
                                    <button class="btn btn-primary" type="button" (click)="yesButtonClick()">{{yesButtonText}}</button>
                                    <button class="btn btn-primary" type="button" (click)="noButtonClick()">{{noButtonText}}</button>
                                </div>
                            </div>
                            <div class="cards-body-section" *ngIf="isGApptSync">
                                <div class="cards-colunm-left">
                                    <div class="">
                                        <span class="control-label">Sync Appointments</span>
                                    </div>
                                </div>
                                <div class="cards-colunm-right">
                                    <input type="checkbox" class="checkbox" formControlName="syncAppt"><span>Automatically keep my calendar synchronized.</span>
                                </div>
                            </div>
                            <div class="cards-body-section" *ngIf="isGContactSync">
                                <div class="cards-colunm-left">
                                    <div class="">
                                        <span class="control-label">Sync Contacts</span>
                                    </div>
                                </div>
                                <div class="cards-colunm-right">
                                    <input type="checkbox" class="checkbox" formControlName="syncContact"><span>Automatically keep my contacts synchronized.</span>
                                </div>
                            </div>
                            <div class="cards-body-section">
                                <div class="cards-colunm-left">
                                </div>
                                <div class="cards-colunm-right">
                                    <button class="btn btn-primary" type="button">Edit</button>
                                    <button class="btn btn-primary" type="button">Cancel</button>
                                    <button class="btn btn-primary" type="button" data-toggle="modal" data-target="#GoogleIntegrationDeleteModal">Clear Credentials</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="cards-body-section">
                        <div class="cards-colunm-left">
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="create-email">
    <div class="global-padding10">
        <div class="wraper-main-section">
            <div class="global-card-section">
                <div class="global-header-section">
                    <div class="svg-icon-panel">Important Notes	</div>
                </div>
                <div class="cards-body-section">
                    <div class="google-info">
                        <div class="google-info-text">
                            <div>
                                <img src="../../../../assets//bullet.svg" />
                            </div>
                            <div>
                                <p><b>Overview of Google Sync</b></p>
                                <p>
                                    The Google Sync feature enables you to perform a two-way synchronization between your SalesOptima account (Contact and/or Calendar) and your Google Contacts and/or Calendar.
                                    Once you have activated Google Sync for either Contacts or Appointments, they will automatically be synchronized when you create or update them in SalesOptima or Google.
                                </p>
                            </div>
                        </div>
                        <div class="google-info-text">
                            <div><img src="../../../../assets//bullet.svg" /></div>
                            <div>
                                <p><b>Can I choose which contacts to sync?</b></p>
                                <p>
                                    Yes. Only Contacts that have the Google Sync checkbox checked will be eligible for synchronization. This means that if you do not want a Contact to be synchronized
                                    with your Google Contacts, simply uncheck the Google Sync checkbox.
                                </p>
                            </div>
                        </div>
                        <div class="google-info-text">
                            <div>
                                <img src="../../../../assets//bullet.svg" />
                            </div>
                            <div>
                                <p><b>Will all of my appointments sync?</b></p>
                                <p>No. Only appointments that meet the following criteria will sync:</p>
                                <ul>
                                    <li>Appointment must be related to a Contact that has the Google Sync checkbox checked.</li>
                                    <li>Appointment must be pending.</li>
                                    <li>Appointment must not be a part of a recurring series.</li>
                                    <li>Appointment must not be marked as a phone call (Phone Call checkbox is not checked)</li>
                                </ul>
                            </div>
                        </div>
                        <div class="google-info-text">
                            <div><img src="../../../../assets//bullet.svg" /></div>
                            <div>
                                <p><b>"I clicked Save and checked my Google account and it didn't sync!"</b></p>
                                <p>"Give it a second, it's going to space." Lewis C.K. If after a minute or two, you still don't see it, check to make sure your contacts and appointments meet the criteria above.</p>
                            </div>
                        </div>
                        <div class="google-info-text">
                            <div><img src="../../../../assets//bullet.svg" /></div>
                            <div><p>The Google Sync feature is currently in Beta. Please be patient with us as we work out the kinks.</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="GoogleIntegrationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="GoogleIntegrationDeleteModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
            </div>
            <div class="modal-body modal-common-body">
                <h5>
                    Caution: This will clear your Google App credentials.<br />
                    Are you sure you want to do this?
                </h5>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="deleteGoogleIntegration()" data-dismiss="modal" class="btn btn-primary">
                    <span>{{buttonTypeOperation!=0?'Delete':'Deleting'}}</span>
                </button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal"> Close</button>
            </div>
        </div>
    </div>
</div>

