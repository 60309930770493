<div class="wraper-main-section mb-3">
    <div class="global-card-section global-card-side-section">
        <div class="global-header-section">
            <div class="svg-icon-panel" *ngIf="isQuickSearch">Quick Search</div>
            <div class="svg-icon-panel" *ngIf="isAdvancedSearch">Advanced Search</div>
            <div class="svg-icon-panel" *ngIf="isPowerSearch">Power Search</div>
        </div>
        <form *ngIf="searchQueriesForm" [formGroup]="searchQueriesForm" (ngSubmit)="submitSearch()">
            <div class="global-body-section">
                <div class="global-padding10">
                    <div class="text-right">
                        <button type="button" class="btn btn-primary" *ngIf="!isQuickSearch" (click)="onShowQuickSearch()">Quick Search</button>
                        <button type="button" class="btn btn-primary" (click)="onShowAdvanceSearch()">Advanced Search</button>
                        <!--<button  type="button" class="btn btn-primary" *ngIf="!isPowerSearch" (click)="onShowPowerSearch()">Power Search</button>-->
                    </div>
                    <!--<div class="quick-list">
                        <div class="quick-label"><span>Saved</span></div>
                        <div class="quick-input">
                            <select class="form-control">
                                <option>--Select--</option>
                            </select>
                        </div>
                    </div>-->
                    <div class="quick-list" *ngIf="isPowerSearch">
                        <div class="quick-label"></div>
                        <div class="quick-input">
                            <button type="button" class="btn btn-primary">Get Results</button>
                            <button type="button" class="btn btn-primary">Delete</button>
                        </div>
                    </div>
                    <hr style="border-color:#eee" />
                    <div class="quick-list">
                        <div class="quick-label"><span>Starts with</span></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddStartsWith">
                                <option value="">-Select-</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                                <option value="D">D</option>
                                <option value="E">E</option>
                                <option value="F">F</option>
                                <option value="G">G</option>
                                <option value="H">H</option>
                                <option value="I">I</option>
                                <option value="J">J</option>
                                <option value="K">K</option>
                                <option value="L">L</option>
                                <option value="M">M</option>
                                <option value="N">N</option>
                                <option value="O">O</option>
                                <option value="P">P</option>
                                <option value="Q">Q</option>
                                <option value="R">R</option>
                                <option value="S">S</option>
                                <option value="T">T</option>
                                <option value="U">U</option>
                                <option value="V">V</option>
                                <option value="W">W</option>
                                <option value="X">X</option>
                                <option value="Y">Y</option>
                                <option value="Z">Z</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="isAdvancedSearch">
                        <div class="quick-label"></div>
                        <div class="quick-input">
                            <input class="checkbox" type="checkbox" formControlName="cbMyContacts" /> My contacts
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="isQuickSearch || isAdvancedSearch">
                        <div class="quick-label"></div>
                        <div class="quick-input">
                            <input class="checkbox" type="checkbox" formControlName="cbMyPersonalContacts" /> My personal contacts
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="isPowerSearch">
                        <div class="quick-label"></div>
                        <div class="quick-input">
                            <input class="checkbox" type="checkbox" formControlName="cbLimit" /> Limit 1000 records
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="isQuickSearch || isAdvancedSearch">
                        <div class="quick-label"></div>
                        <div class="quick-input">
                            <input class="checkbox" type="checkbox" formControlName="cbShowAddress" /> Display Address
                        </div>
                    </div>
                    <div class="quick-list">
                        <div class="quick-label"><span>Search field</span></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddField">
                                <option value="0">-None Selected-</option>
                                <option *ngFor="let item of ddField" [value]="item?.value">{{item?.displayValue}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="isAdvancedSearch">
                        <div class="quick-label"><span></span></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddOpSearchText">
                                <option value="=">equals</option>
                                <option value="<>">not equals</option>
                                <option value="contains">contains</option>
                                <option value="does not contain">does not contain</option>
                                <option value="starts">starts with</option>
                                <option value="ends">ends with</option>
                                <option value=">=">equal or greater than</option>
                                <option value="<=">equal or less than</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list">
                        <div class="quick-label"><span>Search value</span></div>
                        <div class="quick-input">
                            <input class="form-control" formControlName="txtSearch" />
                        </div>
                    </div>
                    <div class="quick-list">
                        <div class="quick-label"></div>
                        <div class="quick-input">
                            <button type="submit" class="btn btn-primary">Search</button>
                        </div>
                    </div>

                    <!--<div class="quick-list">
                        <div class="quick-label"></div>
                        <div class="quick-input">
                            <input class="checkbox" /> My Team Only
                        </div>
                    </div>
                    <div class="quick-list">
                        <div class="quick-label"></div>
                        <div class="quick-input">
                            <input class="checkbox" /> My Office Only
                        </div>
                    </div>-->
                    <div class="quick-list" *ngIf="teamObj?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>Team</span>
                            <select class="form-control" *ngIf="isAdvancedSearch" formControlName="ddOpTeam">
                                <option value="=">=</option>
                                <option value="<>">&lt;&gt;</option>
                            </select>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddTeam">
                                <option value="0">-All-</option>
                                <option *ngFor="let item of teamObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="officeObj?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>Office</span>
                            <select class="form-control" *ngIf="isAdvancedSearch">
                                <option  value="=">=</option>
                                <option value="<>">&lt;&gt;</option>
                            </select>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddOffice">
                                <option value="0">-All-</option>
                                <option *ngFor="let item of officeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="usersObj?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{usersObj?.displayValue}}</span>
                            <select class="form-control" *ngIf="isAdvancedSearch" formControlName="ddOpUser">
                                <option value="=">=</option>
                                <option value="<>">&lt;&gt;</option>
                            </select>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddUser">
                                <option value="0">-All-</option>
                                <option *ngFor="let item of usersObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="class1CodeObj?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{class1CodeObj?.displayValue}}</span>
                            <select class="form-control" *ngIf="isAdvancedSearch" formControlName="ddOpClass1">
                                <option value="=">=</option>
                                <option value="<>">&lt;&gt;</option>
                            </select>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddClass1Code">
                                <option value="0">-All-</option>
                                <option *ngFor="let item of class1CodeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="class2CodeObj?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{class2CodeObj?.displayValue}}</span>
                            <select class="form-control" *ngIf="isAdvancedSearch" formControlName="ddOpClass2">
                                <option value="=">=</option>
                                <option value="<>">&lt;&gt;</option>
                            </select>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddClass2Code">
                                <option value="0">-All-</option>
                                <option *ngFor="let item of class2CodeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="class3CodeObj?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{class3CodeObj?.displayValue}}</span>
                            <select class="form-control" *ngIf="isAdvancedSearch" formControlName="ddOpClass3">
                                <option value="=">=</option>
                                <option value="<>">&lt;&gt;</option>
                            </select>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddClass3Code">
                                <option value="0">-All-</option>
                                <option *ngFor="let item of class3CodeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="class4CodeObj?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{class4CodeObj?.displayValue}}</span>
                            <select class="form-control" *ngIf="isAdvancedSearch" formControlName="ddOpClass4">
                                <option value="=">=</option>
                                <option value="<>">&lt;&gt;</option>
                            </select>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddClass4Code">
                                <option value='0'>-All-</option>
                                <option *ngFor="let item of class4CodeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="class5CodeObj?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{class5CodeObj?.displayValue}}</span>
                            <select class="form-control" *ngIf="isAdvancedSearch" formControlName="ddOpClass5">
                                <option value="=">=</option>
                                <option value="<>">&lt;&gt;</option>
                            </select>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddClass5Code">
                                <option value='0'>-All-</option>
                                <option *ngFor="let item of class5CodeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="class6CodeObj?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{class6CodeObj?.displayValue}}</span>
                            <select class="form-control" *ngIf="isAdvancedSearch" formControlName="ddOpClass6">
                                <option value="=">=</option>
                                <option value="<>">&lt;&gt;</option>
                            </select>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddClass6Code">
                                <option value='0'>-All-</option>
                                <option *ngFor="let item of class6CodeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="class7CodeObj?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{class7CodeObj?.displayValue}}</span>
                            <select class="form-control" *ngIf="isAdvancedSearch" formControlName="ddOpClass7">
                                <option value="=">=</option>
                                <option value="<>">&lt;&gt;</option>
                            </select>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddClass7Code">
                                <option value='0'>-All-</option>
                                <option *ngFor="let item of class7CodeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="class8CodeObj?.itemData?.length > 0">
                        <div class="quick-label">
                            <span>{{class8CodeObj?.displayValue}}</span>
                            <select class="form-control" *ngIf="isAdvancedSearch" formControlName="ddOpClass8">
                                <option value="=">=</option>
                                <option value="<>">&lt;&gt;</option>
                            </select>
                        </div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddClass8Code">
                                <option value='0'>-All-</option>
                                <option *ngFor="let item of class8CodeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="check1Obj?.itemData?.length > 0">
                        <div class="quick-label"><span>{{check1Obj?.displayValue}}</span></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddCheck1">
                                <option *ngFor="let item of check1Obj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="check2Obj?.itemData?.length > 0">
                        <div class="quick-label"><span>{{check2Obj?.displayValue}}</span></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddCheck2">
                                <option *ngFor="let item of check2Obj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="check3Obj?.itemData?.length > 0">
                        <div class="quick-label"><span>{{check3Obj?.displayValue}}</span></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddCheck3">
                                <option *ngFor="let item of check3Obj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="check4Obj?.itemData?.length > 0">
                        <div class="quick-label"><span>{{check4Obj?.displayValue}}</span></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddCheck4">
                                <option *ngFor="let item of check4Obj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="check5Obj?.itemData?.length > 0">
                        <div class="quick-label"><span>{{check5Obj?.displayValue}}</span></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddCheck5">
                                <option *ngFor="let item of check5Obj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="check6Obj?.itemData?.length > 0">
                        <div class="quick-label"><span>{{check6Obj?.displayValue}}</span></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddCheck6">
                                <option *ngFor="let item of check6Obj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="outlookSyncObj?.itemData?.length > 0">
                        <div class="quick-label"><span>{{outlookSyncObj?.displayValue}}</span></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddOutlookSync">
                                <option *ngFor="let item of outlookSyncObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="!isAdvancedSearch">
                        <div class="quick-label"><span>Company Fields</span></div>
                        <div class="quick-input button-wrapper">
                            <button type="button" class="icon-btn" data-toggle="collapse" data-target="#companyFields" (click)="isShowCompFields = !isShowCompFields">
                                <i class="fas fa-angle-down up-hide-icon" *ngIf="isShowCompFields" aria-hidden="true"></i>
                                <i class="fas fa-angle-up icon-hide" *ngIf="!isShowCompFields" aria-hidden="true"></i>
                                <span class="btn-text">Expand</span>
                            </button>
                            <!--<button class="btn-primary" (click)="isShowCompFields = !isShowCompFields">{{isShowCompFields ? 'Close' : 'Open'}}</button>-->
                        </div>
                    </div>
                    <div *ngIf="isShowCompFields" id="companyFields">
                        <div class="quick-list" *ngIf="coClass1CodeObj?.itemData?.length > 0">
                            <div class="quick-label"><span>{{coClass1CodeObj?.displayValue}}</span></div>
                            <div class="quick-input">
                                <select class="form-control" formControlName="ddCoClass1Code">
                                    <option value='0'>-All-</option>
                                    <option *ngFor="let item of coClass1CodeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="quick-list" *ngIf="coClass2CodeObj?.itemData?.length > 0">
                            <div class="quick-label"><span>{{coClass2CodeObj?.displayValue}}</span></div>
                            <div class="quick-input">
                                <select class="form-control" formControlName="ddCoClass2Code">
                                    <option value='0'>-All-</option>
                                    <option *ngFor="let item of coClass2CodeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="quick-list" *ngIf="coClass3CodeObj?.itemData?.length > 0">
                            <div class="quick-label"><span>{{coClass3CodeObj?.displayValue}}</span></div>
                            <div class="quick-input">
                                <select class="form-control" formControlName="ddCoClass3Code">
                                    <option value='0'>-All-</option>
                                    <option *ngFor="let item of coClass3CodeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="quick-list" *ngIf="coClass4CodeObj?.itemData?.length > 0">
                            <div class="quick-label"><span>{{coClass4CodeObj?.displayValue}}</span></div>
                            <div class="quick-input">
                                <select class="form-control" formControlName="ddCoClass4Code">
                                    <option value='0'>-All-</option>
                                    <option *ngFor="let item of coClass4CodeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="quick-list" *ngIf="coClass5CodeObj?.itemData?.length > 0">
                            <div class="quick-label"><span>{{coClass5CodeObj?.displayValue}}</span></div>
                            <div class="quick-input">
                                <select class="form-control" formControlName="ddCoClass5Code">
                                    <option value='0'>-All-</option>
                                    <option *ngFor="let item of coClass5CodeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="quick-list" *ngIf="coClass6CodeObj?.itemData?.length > 0">
                            <div class="quick-label"><span>{{coClass6CodeObj?.displayValue}}</span></div>
                            <div class="quick-input">
                                <select class="form-control" formControlName="ddCoClass6Code">
                                    <option value='0'>-All-</option>
                                    <option *ngFor="let item of coClass6CodeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="quick-list" *ngIf="coClass7CodeObj?.itemData?.length > 0">
                            <div class="quick-label"><span>{{coClass7CodeObj?.displayValue}}</span></div>
                            <div class="quick-input">
                                <select class="form-control" formControlName="ddCoClass7Code">
                                    <option value='0'>-All-</option>
                                    <option *ngFor="let item of coClass7CodeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="quick-list" *ngIf="coClass8CodeObj?.itemData?.length > 0">
                            <div class="quick-label"><span>{{coClass8CodeObj?.displayValue}}</span></div>
                            <div class="quick-input">
                                <select class="form-control" formControlName="ddCoClass8Code">
                                    <option value='0'>-All-</option>
                                    <option *ngFor="let item of coClass8CodeObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="quick-list" *ngIf="coCheck1Obj?.itemData?.length > 0">
                            <div class="quick-label"><span>{{coCheck1Obj?.displayValue}}</span></div>
                            <div class="quick-input">
                                <select class="form-control" formControlName="ddCoCheck1">
                                    <option *ngFor="let item of coCheck1Obj?.itemData" [value]="item?.value">{{item?.display}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="quick-list" *ngIf="coCheck2Obj?.itemData?.length > 0">
                            <div class="quick-label"><span>{{coCheck2Obj?.displayValue}}</span></div>
                            <div class="quick-input">
                                <select class="form-control" formControlName="ddCoCheck2">
                                    <option *ngFor="let item of coCheck2Obj?.itemData" [value]="item?.value">{{item?.display}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="quick-list" *ngIf="coCheck3Obj?.itemData?.length > 0">
                            <div class="quick-label"><span>{{coCheck3Obj?.displayValue}}</span></div>
                            <div class="quick-input">
                                <select class="form-control" formControlName="ddCoCheck3">
                                    <option *ngFor="let item of coCheck3Obj?.itemData" [value]="item?.value">{{item?.display}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="quick-list" *ngIf="coCheck4Obj?.itemData?.length > 0">
                            <div class="quick-label"><span>{{coCheck4Obj?.displayValue}}</span></div>
                            <div class="quick-input">
                                <select class="form-control" formControlName="ddCoCheck4">
                                    <option *ngFor="let item of coCheck4Obj?.itemData" [value]="item?.value">{{item?.display}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="quick-list">
                            <div class="quick-label"></div>
                            <div class="quick-input">
                                <select class="form-control" formControlName="ddOpCoTags">
                                    <option value="any">contains any</option>
                                    <option value="all">contains all</option>
                                    <option value="not">does not contain</option>
                                </select>
                            </div>
                        </div>
                        <div class="quick-list" *ngIf="companyTagsObj?.itemData?.length > 0">
                            <div class="quick-label">{{companyTagsObj?.displayValue}}</div>
                            <div class="quick-input">
                                <select id="vender" multiple="multiple" formControlName="lbCoTags">
                                    <option *ngFor="let item of companyTagsObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="quick-list">
                        <div class="quick-label"></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddOpTags">
                                <option value="any">contains any</option>
                                <option value="all">contains all</option>
                                <option value="not">does not contain</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="tagsObj?.itemData?.length > 0">
                        <div class="quick-label">{{tagsObj?.displayValue}}</div>
                        <div class="quick-input">
                            <select id="vender" multiple="multiple" formControlName="lbTags">
                                <option *ngFor="let item of tagsObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list">
                        <div class="quick-label"><span>{{metricObj?.displayValue}}</span></div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddMetricNames">
                                <option *ngFor="let item of metricObj?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list">
                        <div class="quick-label"><span>Buzz score</span></div>
                        <div class="quick-input">
                            <div class="display-row">
                                <div>
                                    <span>From</span>
                                    <input formControlName="txtBuzzStart" />
                                </div>
                                <div class="margin-left10">
                                    <span>To</span>
                                    <input formControlName="txtBuzzEnd" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="textOptStatus?.itemData?.length > 0">
                        <div class="quick-label">{{textOptStatus?.displayValue}}</div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddTxtMsgStatus">
                                <option *ngFor="let item of textOptStatus?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="emailInfoStatus?.itemData?.length > 0">
                        <div class="quick-label">{{emailInfoStatus?.displayValue}}</div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddlEmailStatus">
                                <option *ngFor="let item of emailInfoStatus?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list" *ngIf="emailPromoStatus?.itemData?.length > 0">
                        <div class="quick-label">{{emailPromoStatus?.displayValue}}</div>
                        <div class="quick-input">
                            <select class="form-control" formControlName="ddlStatusEmailInfo">
                                <option *ngFor="let item of emailPromoStatus?.itemData" [value]="item?.value">{{item?.display}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="quick-list">
                        <div class="quick-label"></div>
                        <div class="quick-input">
                            <button type="submit" class="btn btn-primary">Search</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
