<div class="custom-security-link">
    <a [routerLink]="['/calender']" routerLinkActive="active">My Calendar</a>
    <a [routerLink]="user?.cLPCompanyID === 1226  ? ['/call-ih'] : ['/call']" routerLinkActive="active">My Call List</a>
    <a [routerLink]="['/task']" routerLinkActive="active">My Tasks</a>
    <a [routerLink]="['/contacts']" routerLinkActive="active">My Contacts</a>
    <a [routerLink]="['/lead']" routerLinkActive="active">My Leads</a>
    <!--<a [routerLink]="" data-toggle="modal" data-target="#underConstruction">Live Connect Dashboard</a>-->
    <a *ngIf="isShowLiveConnectLink" (click)="openLiveConnect()">Live Connect Dashboard</a>
</div>
<div class="margin-all-area" *ngIf="announcements?.length > 0">
    <div class="important-msg-section">
        <div class="important-msg-panel" style="padding:0px;">
            <div class="display-row" style="position:relative; border-top:solid 1px #3FB6DC; padding:5px;" *ngFor="let item of announcements">
                <div style="width:95%; margin-bottom:0px;">
                    <h6 style="color:#2a3644; font-weight:600;">{{item?.announceTitle}}</h6>
                    <p style="margin-bottom:0px;" [innerHtml]="item?.announceDesc | safeHtml"></p>
                </div>
                <a class="close-btn" *ngIf="item?.showDismiss" (click)="cLPAnnounceDismissCreate(item?.cLPAnnounceID)">
                    <i class="fa fa-close"></i>
                </a>
            </div>
        </div>
    </div>
</div>
<div class="margin-all-area">
    <div class="home-dashboard-section">
        <div class="row-panel">
            <div class="col-left-panel">
                <div class="wraper-main-section mb-3" *ngIf="userPrefData?.showGSCheckList && gsCheckList?.length > 0">
                    <div class="global-card-section">
                        <div class="global-header-section">
                            <div class="svg-icon-panel">
                                <img src="../../../../assets/gschecklisttitle.svg" class="mr-1"> Getting Started Checklist
                            </div>
                            <div class="header-button-panel">
                                <div class="button-wrapper">
                                    <button type="button" class="mat-mdc-tooltip-trigger icon-btn" (click)="saveUserPref()" aria-describedby="cdk-describedby-message">
                                        <i class="fa fa-chevron-up"></i>
                                        <span class="btn-text">Expand</span>
                                    </button><!--container-->
                                </div>
                            </div>
                        </div>
                        <div class="global-body-section">
                            <div class="checklist-row" *ngFor="let item of gsCheckList;let i = index">
                                <div class="checklist-column">
                                    <span class="counting">{{i + 1}}</span>
                                    <div>
                                        <h6>{{item?.title}}</h6>
                                        <span [innerHtml]='item?.description'></span>
                                    </div>
                                </div>
                                <div class="checklist-column">
                                    <a href="{{item?.videoLink}}"><i class="fa fa-video"></i></a>
                                    <a href="{{item?.helpTopicLink}}" target="_blank"><img src="../../../../assets/helpicon.svg" /></a>
                                    <input type="checkbox" [(ngModel)]="item.isDone" class="k-checkbox" (change)="onGSListCheckboxChange($event, item)" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <message-center *ngIf="user" [user]="user"></message-center>
                </div>
                <div class="mt-4">
                    <overdue-appointment [isShowFilter]="false"></overdue-appointment>
                </div>
            </div>
            <div class="col-right-panel">
                <div class="home-dashboard-right">
                    <div [hidden]="pinnedContact?.listData?.length<=0" class="mb-3">
                        <dashboard-card #pinnedContact *ngIf="user" [apptTypeCodes]="apptTypeCodes" (dataItemEventRecentParent)="sendEmailParent($event)" cardType="pinnedContact" [user]="user" [roleFeaturePermissions]="roleFeaturePermissions">
                            <div class="global-header-section quick-goals-bg">
                                <div class="svg-icon-panel"> Pinned Contacts</div>
                                <div class="header-button-panel">
                                    <div class="button-wrapper">
                                        <button class="btn btn-primary " [routerLink]="['/contact-create']" title="New Contact"><i class="fa fa-plus"></i> New</button>
                                    </div>
                                </div>
                            </div>
                        </dashboard-card>
                    </div>
                    <div class="mb-3">
                        <quick-goals *ngIf="user" [loggedUser]="user" [selectedMonth]="selectedMonth" [selectedYear]="selectedYear" [isHome]="true"></quick-goals>
                    </div>
                    <div class="mb-3">
                        <dashboard-card #apptDash *ngIf="user" [apptTypeCodes]="apptTypeCodes" (dataItemEventRecentParent)="sendEmailParent($event)" cardType="appointment" [user]="user" [roleFeaturePermissions]="roleFeaturePermissions">
                            <div class="global-header-section goal-bg-banner">
                                <div class="svg-icon-panel">{{apptDash?.listData?.length>0 ? 'Upcoming Appointments' : 'Quick Appointment'}}</div>
                                <div class="header-button-panel">
                                    <div class="button-wrapper">
                                        <button class="btn btn-primary " title="New Appointment" (click)="appointmentPopUp();" data-toggle="modal" data-target="#homeApptModal" data-backdrop="static" data-keyboard="false"><i class="fa fa-plus"></i> New</button>
                                    </div>
                                </div>
                            </div>
                        </dashboard-card>
                    </div>
                    <div class="mb-3">
                        <dashboard-card #apptTask *ngIf="user" cardType="task" [apptTypeCodes]="apptTypeCodes" [user]="user" (dataItemEventRecentParent)="sendEmailParent($event)" [isFormHome]="true" [roleFeaturePermissions]="roleFeaturePermissions">
                            <div class="global-header-section goal-bg-task">
                                <div class="svg-icon-panel">{{apptTask?.listData?.length>0 ? 'Upcoming Tasks' : 'Quick Task'}}</div>
                                <div class="header-button-panel">
                                    <div class="button-wrapper">
                                        <button class="btn btn-primary " [routerLink]="['/task']" [queryParams]="{isNew : true}" title="New Task"><i class="fa fa-plus"></i> New</button>
                                    </div>
                                </div>
                            </div>
                        </dashboard-card>
                    </div>
                    <div class="mb-3">
                        <dashboard-card #apptLeads *ngIf="user" cardType="lead" [apptTypeCodes]="apptTypeCodes" [user]="user" (dataItemEventRecentParent)="sendEmailParent($event)" [roleFeaturePermissions]="roleFeaturePermissions">
                            <div class="global-header-section goal-bg-leads">
                                <div class="svg-icon-panel"> Recent Leads</div>
                                <div class="header-button-panel">
                                    <div class="button-wrapper">
                                        <button class="btn btn-primary" [routerLink]="['/lead-create']" title="New Lead"><i class="fa fa-plus"></i> New</button>
                                    </div>
                                </div>
                            </div>
                        </dashboard-card>
                    </div>
                    <div class="mb-3">
                        <dashboard-card #apptContact *ngIf="user" [apptTypeCodes]="apptTypeCodes" cardType="contact" [user]="user" (dataItemEventRecentParent)="sendEmailParent($event)" [roleFeaturePermissions]="roleFeaturePermissions">
                            <div class="global-header-section goals-bg-contact">
                                <div class="svg-icon-panel">Recent Contacts</div>
                                <div class="header-button-panel">
                                    <div class="button-wrapper">
                                        <button class="btn btn-primary " [routerLink]="['/contact-create']" title="New Contact"><i class="fa fa-plus"></i> New</button>
                                    </div>
                                </div>
                            </div>
                        </dashboard-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="sendEmailModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-contact-email *ngIf="sendMailInfo?.isShow && user" [loggedUser]="user" [contactId]="sendMailInfo?.contactId" (closeEmailModal)="hideSendMail()" [isFromLead]="isFromLead" [showEmoji]="true"></app-contact-email>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="underConstruction" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body modal-common-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <p class="under-construction under-construction-common">
                    <img src="../../../assets/under-construction.gif" />
                </p>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isShowApptModal" class="modal fade" id="homeApptModal" tabindex="-1" role="dialog" aria-labelledby="homeApptModalLabel" aria-hidden="true" data-focus="false">
    <div class="custom-modal-panel" role="document">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content user-body-section border-radius-contact">
                <div class="modal-body">
                    <app-appointment-common *ngIf="user?.cLPCompanyID != 1226" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-common>
                    <app-appointment-ih *ngIf="user?.cLPCompanyID == 1226" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-ih>
                </div>
                <div class="modal-footer p-0 border-0">
                    <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideQuickApptModal();" [hidden]="true">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!--<div *ngIf="isShowApptModal" class="modal fade" id="quickApptModal" tabindex="-1" role="dialog" aria-labelledby="quickApptModalLabel" aria-hidden="true" data-focus="false">
  <div class="custom-modal-panel" role="document">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content user-body-section border-radius-contact">
        <div class="modal-body">
          <app-appointment-common *ngIf="user?.cLPCompanyID != 1226" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-common>
          <app-appointment-ih *ngIf="user?.cLPCompanyID == 1226" [inputDataAppt]="shareableAppt" (toggleHideApptModal)="hideQuickApptModal($event)"></app-appointment-ih>
        </div>
        <div [hidden]="true" class="modal-footer p-0 border-0">
          <button #closeInputButton type="button" class="btn btn-secondary" data-dismiss="modal" (click)="hideQuickApptModal();">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>-->
