<!--<lc-engagedash *ngIf="user && liveConnectContactViewResponse && liveConnectItem?.contactID >0" [user]="user" [contactId]="liveConnectItem?.contactID" (loadMailMergeTemplate)="loadMailMergeTemplate($event)"></lc-engagedash>-->

<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-body-section">
                <div class="margin-all-area" *ngIf="liveConnectContactViewResponse">
                    {{liveConnectContactViewResponse?.companyName}}&nbsp;|&nbsp;<span [innerHTML]="liveConnectContactViewResponse?.repName | safeHtml"></span>
                </div>
                <div class="message-info margin-all-area" [innerHTML]="confirmMsg | safeHtml"></div>
                <div class="margin-all-area" *ngIf="liveConnectContactViewResponse">
                    <div class="inherit-panel">
                        <div class="btn-group-panel">
                            <div>
                                <div [innerHTML]="liveConnectContactViewResponse?.strContactInfo | safeHtml"></div>
                                <div *ngIf="liveConnectContactViewResponse?.duplicateDisplaySummary" [innerHTML]="liveConnectContactViewResponse?.duplicateDisplaySummary | safeHtml"></div>
                                <br />
                                <!--general section start-->
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.salutation != eSAMFieldStatus.Hide"><label><b>Salutation : </b></label> <span>{{liveConnectContactViewResponse?.contact?.salutation}}</span></div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.title != eSAMFieldStatus.Hide"><label><b>Title : </b></label> <span>{{liveConnectContactViewResponse?.contact?.title}}</span></div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.companyName != eSAMFieldStatus.Hide"><label><b>Company : </b></label> <span>{{liveConnectContactViewResponse?.contact?.companyName}}</span></div>
                                <!--general section end-->
                                <br />
                                <!--address section start-->
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.add1 != eSAMFieldStatus.Hide"><span>{{liveConnectContactViewResponse?.contact?.add1}}</span></div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.add2 != eSAMFieldStatus.Hide"><span>{{liveConnectContactViewResponse?.contact?.add2}}</span></div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.add3 != eSAMFieldStatus.Hide"><span>{{liveConnectContactViewResponse?.contact?.add3}}</span></div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.city != eSAMFieldStatus.Hide"><span>{{liveConnectContactViewResponse?.contact?.city}}</span></div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.state != eSAMFieldStatus.Hide"><span>{{liveConnectContactViewResponse?.contact?.state}}</span></div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.zip != eSAMFieldStatus.Hide"><span>{{liveConnectContactViewResponse?.contact?.zip}}</span></div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.country != eSAMFieldStatus.Hide"><span>{{getCountryName(liveConnectContactViewResponse?.contact?.country)}}</span></div>
                                <!--address section end-->
                                <br />
                                <!--other field section start-->
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom1 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 1">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom1Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom1}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom2 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 2">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom2Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom2}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom3 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 3">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom3Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom3}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom4 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 4">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom4Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom4}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom5 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 5">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom5Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom5}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom6 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 6">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom6Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom6}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom7 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 7">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom7Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom7}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom8 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 8">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom8Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom8}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom9 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 9">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom9Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom9}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom10 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 10">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom10Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom10}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom11 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 11">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom11Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom11}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom12 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 12">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom12Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom12}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom13 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 13">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom13Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom13}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom14 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 14">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom14Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom14}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom15 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 15">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom15Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom15}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom16 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 16">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom16Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom16}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom17 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 17">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom17Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom17}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom18 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 18">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom18Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom18}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom19 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 19">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom19Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom19}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.custom20 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxTXT >= 20">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.custom20Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.custom20}}</span>
                                </div>
                                <!--other field section end-->
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.comments != eSAMFieldStatus.Hide"><label><b>Comments : </b></label> <span style="color:#dc3545;">{{liveConnectContactViewResponse?.contact?.comments}}</span></div>

                                <br />
                                <!--other email section start-->
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.email2 != eSAMFieldStatus.Hide || liveConnectContactViewResponse?.samContactField?.email3 != eSAMFieldStatus.Hide">
                                    <label><b>Other Email </b> &nbsp;</label>
                                    <span> <a href="mailto:{{liveConnectContactViewResponse?.contact?.email2}}" target="_top">{{liveConnectContactViewResponse?.contact?.email2}} </a></span><br />
                                    <span><a href="mailto:{{liveConnectContactViewResponse?.contact?.email3}}" target="_top">{{liveConnectContactViewResponse?.contact?.email3}} </a></span>
                                </div>
                                <!--other email section end-->
                                <br />
                                <!--communication section start-->
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.phone != eSAMFieldStatus.Hide"><label><b>Business Phone : </b></label> <span [innerHTML]="liveConnectContactViewResponse?.contact?.phone | safeHtml"></span></div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.homePhone != eSAMFieldStatus.Hide"><label><b>Home Phone : </b></label> <span>{{liveConnectContactViewResponse?.contact?.homePhone}}</span></div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.altPhone != eSAMFieldStatus.Hide"><label><b>Other Phone : </b></label> <span>{{liveConnectContactViewResponse?.contact?.altPhone}}</span></div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.fax != eSAMFieldStatus.Hide"><label><b>Fax : </b></label> <span>{{liveConnectContactViewResponse?.contact?.fax}}</span></div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.otherFax != eSAMFieldStatus.Hide"><label><b>Other Fax : </b></label> <span>{{liveConnectContactViewResponse?.contact?.otherFax}}</span></div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.webSite != eSAMFieldStatus.Hide"><span>{{liveConnectContactViewResponse?.contact?.webSite}}</span></div>
                                <!--communication section end-->
                                <br />
                                <!--classification section start-->
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.class1Code != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxDD >= 1">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.Class1CodeTitle}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.class1CodeDisplay}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.class2Code != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxDD >= 2">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.Class2CodeTitle}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.class2CodeDisplay}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.class3Code != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxDD >= 3">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.Class3CodeTitle}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.class3CodeDisplay}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.class4Code != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxDD >= 4">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.Class4CodeTitle}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.class4CodeDisplay}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.class5Code != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxDD >= 5">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.Class5CodeTitle}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.class5CodeDisplay}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.class6Code != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxDD >= 6">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.Class6CodeTitle}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.class6CodeDisplay}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.class7Code != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxDD >= 7">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.Class7CodeTitle}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.class7CodeDisplay}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.class8Code != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxDD >= 8">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.Class8CodeTitle}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.class8CodeDisplay}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.check1 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxCB >= 1">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.check1Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.check1 ? 'yes' : 'no'  }}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.check2 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxCB >= 2">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.check2Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.check2 ? 'yes' : 'no'}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.check3 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxCB >= 3">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.check3Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.check3 ? 'yes' : 'no'}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.check4 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxCB >= 4">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.check4Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.check4 ? 'yes' : 'no'}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.check5 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxCB >= 5">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.check5Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.check5 ? 'yes' : 'no'}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.check6 != eSAMFieldStatus.Hide && liveConnectContactViewResponse?.clpCompany?.contactMaxCB >= 6">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.check6Title}} : </b></label> <span>{{liveConnectContactViewResponse?.contact?.check6 ? 'yes' : 'no'}}</span>
                                </div>
                                <!--classification section end-->
                                <br />
                                <!--more field section start-->
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.customDate1 != eSAMFieldStatus.Hide">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.cmCustomDate1Title}} : </b></label> <span>{{liveConnectContactViewResponse?.customDate1}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.customDate2 != eSAMFieldStatus.Hide">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.cmCustomDate2Title}} : </b></label> <span>{{liveConnectContactViewResponse?.customDate2}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.customDate3 != eSAMFieldStatus.Hide">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.cmCustomDate3Title}} : </b></label> <span>{{liveConnectContactViewResponse?.customDate3}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.customText1 != eSAMFieldStatus.Hide">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.cmCustomText1Title}} : </b></label> <span>{{liveConnectContactViewResponse?.customText1}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.customText2 != eSAMFieldStatus.Hide">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.cmCustomText2Title}} : </b></label> <span>{{liveConnectContactViewResponse?.customText2}}</span>
                                </div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.customText3 != eSAMFieldStatus.Hide">
                                    <label><b>{{liveConnectContactViewResponse?.samContactField?.cmCustomText3Title}} : </b></label> <span>{{liveConnectContactViewResponse?.customText3}}</span>
                                </div>
                                <!--more field section end-->
                                <!--system section start-->
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.share != eSAMFieldStatus.Hide"><label><b>Share : </b></label> <span>{{liveConnectContactViewResponse?.contact?.shareable ? 'Contact is shared' : 'Contact not shared'}}</span></div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.sAMUser != eSAMFieldStatus.Hide"><label><b>User : </b></label> <span>{{liveConnectContactViewResponse?.contact?.userName}}</span></div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.lastModified != eSAMFieldStatus.Hide"><label><b>Last Modified : </b></label> <span>{{liveConnectContactViewResponse?.contact?.dtModified | date:dateFormat}}</span></div>
                                <div *ngIf="liveConnectContactViewResponse?.samContactField?.created != eSAMFieldStatus.Hide"><label><b>Created : </b></label> <span>{{liveConnectContactViewResponse?.contact?.dtCreated | date:dateFormat}}</span></div>
                                <!--system section end-->
                                <lc-cas-display *ngIf="isShowCustomAction && user" [customButtons]="liveConnectContactViewResponse?.customButtons" [contactID]="liveConnectContactViewResponse?.contact?.contactID" [customActionDD]="liveConnectContactViewResponse?.customActionDDList" [user]="user"></lc-cas-display>
                            </div>
                            <div class="">
                                <div class="inherit-panel">
                                    <div class="flex-row-inner">
                                        <div class="flex-width1">
                                            <div [innerHTML]="progressBarstring | safeHtml"></div>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div class="inherit-panel">
                                    <div class="flex-row-inner">

                                        <div class="flex-width1">
                                            <div><button type="button" class="btn btn-secondary btmsgcontact btn-block" (click)="editContact()">Edit Contact</button></div>
                                            <div><button type="button" class="btn btn-primary btntxtmsg btn-block" (click)="sendSms()">Send SMS</button></div>
                                            <div><button type="button" class="btn btn-dark btn-block" (click)="sendEmail()">Send Email</button></div>
                                            <div *ngIf="liveConnectContactViewResponse?.isVip">
                                                <button type="button" (click)="launchSlidecast()" class="btn btn-primary active btn-block" aria-pressed="true">Launch Slidecast</button>
                                            </div>
                                            <!--OptIn opt out section-->
                                            <div *ngIf="optInSection">
                                                <button type="button" (click)="onOptinByUser()" *ngIf="optInByUserBtn" class="btn btn-warning btn-block">Opt In</button>
                                                <div *ngIf="optInOptionSection">
                                                    <div *ngIf="isAllowVerbalOptinOption">
                                                        <input type="checkbox" class="checkbox" /> &nbsp;By checking this box, I confirm that {{liveConnectContactViewResponse?.contact?.mobile  | phoneFormat}} has provided express consent to receive text messages.<br />
                                                        <button type="button" (click)="onOptinVerbal()" class="btn btn-warning btn-block mt-2">Opt In  {{liveConnectContactViewResponse?.contact?.mobile | phoneFormat }} </button>
                                                    </div>
                                                    <div *ngIf="isOptInAp">
                                                        <button type="button" (click)="onOptinAP()" class="btn btn-warning btn-block">Send Opt-In Instructions</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="danger-btn" *ngIf="isShowOptOutBtn"><button type="button" class="btn btn-danger btn-block" (click)="onOptOut()">{{isConfirmOptOut ? 'Confirm Opt Out' : 'Opt Out'}}</button></div>
                                            <!--OptIn opt out section-->
                                            <div *ngIf="!isShowCustomAction && liveConnectContactViewResponse?.isCustomAction">
                                                <button type="button" class="btn btn-secondary btn-block btmsgcontact" (click)="isShowCustomAction = !isShowCustomAction">Custom Actions</button>
                                            </div>
                                            <div>
                                                <div class="mb-2" *ngIf="isConfirmSubscribe">
                                                    <input type="checkbox" class="checkbox mx-2" (change)="onChangeConfirmEmail($event)" />
                                                    By checking this box, I confirm that <a href="mailto:{{liveConnectContactViewResponse.contact.email}}"> {{liveConnectContactViewResponse.contact.email}}</a> has provided express consent to revice email message.
                                                </div>
                                                <button *ngIf="!isSubscribe" type="button" class="btn btn-success btn-block" (click)="UnSubscribe(true)">{{isConfirmSubscribe ? 'Confirm Subscribe' : 'Subscribe'}}</button>
                                            </div>
                                            <div>
                                                <button *ngIf="isSubscribe" type="button" class="btn btn-danger btn-block" (click)="UnSubscribe(false)">{{isConfirmUnSubscribe ? 'Confirm Unsubscribe' : ' Unsubscribe'}}</button>
                                            </div>
                                        </div>
                                        <div class="flex-width1 margin-left10">
                                            <div *ngIf="this.liveConnectContactViewResponse?.clpCompany.isClubModuleInstalled"><button type="button" class="btn btn-warning btn-block" (click)="OpenContract()">View Contract</button></div>
                                            <div><button type="button" class="btn btn-success btn-block" (click)="makeCall()">Make Call</button></div>
                                            <div><button type="button" class="btn btn-outline-dark btn-block" (click)="addNote()">Add Note</button></div>
                                            <div class="self-guided-btn" *ngIf="liveConnectContactViewResponse?.isSendSGVIP"><button type="button" class="btn btn-secondary btn-block" (click)="onSelfGuided()">Self Guided</button></div>
                                            <div *ngIf="liveConnectContactViewResponse?.isShowBlock"><button type="button" class="btn btn-outline-danger btn-block" (click)="onBlock()">{{isConfirm ? 'Confirm Block' : 'Block'}}</button></div>
                                            <div><button type="button" class="btn btn-secondary btmsgcontact btn-block" (click)="viewHistory()">View History</button></div>
                                            <div><button type="button" class="btn btn-secondary btmsgcontact btn-block" (click)="pinUnpin(liveConnectContactViewResponse?.isPinned)">{{liveConnectContactViewResponse?.isPinned ? 'Unpin' : 'Pin'}} Contact</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<app-contact-detail *ngIf="user && isShowEditContact" (openContact)="redirectItem()" [isFromHandle]="isFromHandle" [contactId]="contactId"></app-contact-detail>
<lc-contact-history *ngIf="user && isShowContactHistory" (openViewContact)="redirectItem()" [liveConnectItem]="selectedLiveConnectObj" [ownerId]="contactId"
                    [ownerType]="2" [isFromLive]="true" [user]="user"></lc-contact-history>
<div class="loader-body" *ngIf="showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
