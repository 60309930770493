import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, OnInit, Output } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { Component, Input } from '@angular/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { CLPUser } from '../../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../../models/company.model';
import { ContactFieldResponse, ContactFields } from '../../../../models/contact.model';
import { ContactField } from '../../../../models/contactField.model';
import { eDDField, eFieldStatus, eGoalType } from '../../../../models/enum.model';
import { DataList, GoalData, GoalSetup, GoalSetupRespnose, GoalsGeneric } from '../../../../models/goalSetup.model';
import { LeadField } from '../../../../models/leadField.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AccountSetupService } from '../../../../services/accountSetup.service';
import { ContactService } from '../../../../services/contact.service';
import { GoalsService } from '../../../../services/goals.service';
import { LeadSettingService } from '../../../../services/leadSetting.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
import { UserService } from '../../../../services/user.service';

@Component({
    selector: 'goal-setup-list',
    templateUrl: './goal-setup-list.component.html',
    styleUrls: ['./goal-setup-list.component.css']
})

export class GoalSetupListComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() sectionHeading: string;
    @Input() loggedUser: CLPUser;
    encryptedUser: string = "";
    @Input() roleFeaturePermissions?: RoleFeaturePermissions;
    showSpinner: boolean = false;

    /*General*/
    generalGoal: GoalSetup[];
    eGoalGeneral: eGoalType = eGoalType.None;

    /*General Appointment*/
    eGoalApptGeneral: eGoalType = eGoalType.ApptGeneralType;
    eDDFieldsAppGeneral: eDDField = eDDField.ApptGeneralTypeCode;
    generalAppointmentGoal: DataList[];

    /*Contact Appointment*/
    eContactApptType: eGoalType = eGoalType.ApptContactType;
    eDDFieldContactAppt: eDDField = eDDField.ApptContactTypeCode;
    contactAppointmentGoal: DataList[];

    /*Contact: Type*/
    eContactType: eGoalType = eGoalType.Class1Code;
    eDDFieldContactType: eDDField = eDDField.Class1Code;
    contactType: DataList[];

    /*Contact Status*/
    eContactApptSta: eGoalType = eGoalType.Class2Code;
    eContactSta: eDDField = eDDField.Class2Code;
    contactStatusGoal: DataList[];

    /*Contact Prospect  Status*/
    eContactApptProsSta: eGoalType = eGoalType.Class3Code;
    eDDFieldContactProsSta: eDDField = eDDField.Class3Code;
    contactProsStatusGoal: DataList[];

    /*Contact: Agreement Type*/
    eContactAggr: eGoalType = eGoalType.Class4Code;
    eDDFieldContactAggr: eDDField = eDDField.Class4Code;
    contactAggrGoal: DataList[];

    /*Contact: Referral Source Type*/
    eContactRef: eGoalType = eGoalType.Class5Code;
    eDDFieldContactRef: eDDField = eDDField.Class5Code;
    contactRefGoal: DataList[];

    /*Contact: Location of Interest*/
    eContactLOI: eGoalType = eGoalType.Class6Code;
    eDDFieldContactLOI: eDDField = eDDField.Class6Code;
    contactLOIGoal: DataList[];

    /*Contact: Objections*/
    eContactObject: eGoalType = eGoalType.Class7Code;
    eDDFieldContactObject: eDDField = eDDField.Class7Code;
    contactObjectGoal: DataList[];

    /*Contact: Ad/Marketing Channel*/
    eContactMarket: eGoalType = eGoalType.Class8Code;
    eDDFieldContactMarket: eDDField = eDDField.Class8Code;
    contactMarketGoal: DataList[];

    eLeadApptType: eGoalType = eGoalType.ApptLeadType;
    eDDFieldLeadAppt: eDDField = eDDField.ApptLeadTypeCode;
    leadAppointmentGoal: DataList[];

    /*LeadStatusCode*/
    eLeadStatus: eGoalType = eGoalType.LeadStatus;
    eDDFieldLeadStatusCode: eDDField = eDDField.LeadStatusCode;
    leadStatusGoal: DataList[];

    /*LeadClass1Code*/
    eLeadClass1Code: eGoalType = eGoalType.LeadClass1Code;
    eDDFieldLeadClass1Code: eDDField = eDDField.LeadClass1Code;
    leadClass1CodeGoal: DataList[];

    /*LeadClass2Code*/
    eLeadRollOut: eGoalType = eGoalType.LeadClass2Code;
    eDDFieldLeadRollOut: eDDField = eDDField.LeadClass2Code;
    leadRollOutGoal: DataList[];

    /*Lead: Class 3 */
    eLeadClass3: eGoalType = eGoalType.LeadClass3Code;
    eDDFieldLeadClass3: eDDField = eDDField.LeadClass3Code;
    leadClass3: DataList[];

    /*Lead: Class 4 */
    eLeadClass4: eGoalType = eGoalType.LeadClass4Code;
    eDDFieldLeadClass4: eDDField = eDDField.LeadClass4Code;
    leadClass4: DataList[];
    /*Lead: Class 5 */
    eLeadClass5: eGoalType = eGoalType.LeadClass5Code;
    eDDFieldLeadClass5: eDDField = eDDField.LeadClass5Code;
    leadClass5: DataList[];
    /*Lead: Class 6 */
    eLeadClass6: eGoalType = eGoalType.LeadClass6Code;
    eDDFieldLeadClass6: eDDField = eDDField.LeadClass6Code;
    leadClass6: DataList[];
    /*Lead: Class 7 */
    eLeadClass7: eGoalType = eGoalType.LeadClass7Code;
    eDDFieldLeadClass7: eDDField = eDDField.LeadClass7Code;
    leadClass7: DataList[];
    /*Lead: Class 8 */
    eLeadClass8: eGoalType = eGoalType.LeadClass8Code;
    eDDFieldLeadClass8: eDDField = eDDField.LeadClass8Code;
    leadClass8: DataList[];

    /*Lead: Class 9 */
    eLeadClass9: eGoalType = eGoalType.LeadClass9Code;
    eDDFieldLeadClass9: eDDField = eDDField.LeadClass9Code;
    leadClass9: DataList[];

    finalGoals: GoalsGeneric[] = new Array<GoalsGeneric>(10);
    private saveClickedSubscription: Subscription;

    @Input() buttonSave: Observable<void>;
    disableSaveButton: boolean = false;
    goalDataList: GoalData[] = [];

    @Output() onSaveGoals = new EventEmitter<boolean>();
    isValidGoals: boolean = false;

    co: ClpCompany;
    cf: ContactField;
    lf: LeadField;


    ngAfterViewInit(): void {
        this.saveClickedSubscription = this.buttonSave?.subscribe(() => this.submitGoalForm());
    }

    ngOnInit() {
        this.encryptedUser = localStorage.getItem("token");
        this.getClpCompany().then(() => this.getContactField()).then(() => this.getLeadFields()).then(() => {
            if (!isNullOrUndefined(this.cf) && !isNullOrUndefined(this.co) && !isNullOrUndefined(this.lf))
                this.getSectionDetails();
        });
    }

    ngOnDestroy() {
        this.saveClickedSubscription?.unsubscribe();
    }

    constructor(private _goalsService: GoalsService,
        private _notifyService: NotificationService,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _accountSetupService: AccountSetupService,
        private _contactService: ContactService,
        private _leadSettingService: LeadSettingService,
    ) {

    }


    async getClpCompany() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.loggedUser.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    let companyResponse: CompanyResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(companyResponse) && !isNullOrUndefined(companyResponse.company)) {
                        this.co = companyResponse.company;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContactField() {
        await this._contactService.getContactField(this.encryptedUser, this.loggedUser.cLPCompanyID)
            .then(async (result: ContactFieldResponse) => {
                if (result) {
                    let response: ContactFieldResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(response.contactField)) {
                        this.cf = response.contactField;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getLeadFields() {
        await this._leadSettingService.leadFieldLoad(this.encryptedUser, this.loggedUser.cLPCompanyID)
            .then(async (result: LeadField) => {
                if (result) {
                    let response: LeadField = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        this.lf = response;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

   async getSectionDetails() {
        switch (this.sectionHeading) {
            case 'General Related Goals': {
                await this.getGeneralData();
                await this.getGeneralApptData();
                break;
            }
            case 'Contact Related Goals': {
                this.finalGoals = [];

                await this.getContactApptTypeData();

                if (this.co?.contactMaxDD > 1 && this.cf?.class1Code != eFieldStatus.Hide)
                    await  this.getContacSetupeTypeData();

                if (this.co?.contactMaxDD > 2 && this.cf?.class2Code != eFieldStatus.Hide)
                    await this.getContactSetupStatusData();

                if (this.co?.contactMaxDD > 3 && this.cf?.class3Code != eFieldStatus.Hide)
                    await this.getContactSetupProsStatusData();

                if (this.co?.contactMaxDD > 4 && this.cf?.class4Code != eFieldStatus.Hide)
                    await this.getContactAggrData();

                if (this.co?.contactMaxDD > 5 && this.cf?.class5Code != eFieldStatus.Hide)
                    await this.getContactRefSetupData();

                if (this.co?.contactMaxDD > 6 && this.cf?.class6Code != eFieldStatus.Hide)
                    await this.getContactSetupLOIApiData();

                if (this.co?.contactMaxDD > 7 && this.cf?.class7Code != eFieldStatus.Hide)
                    await this.getContactSObjectApiData();

                if (this.co?.contactMaxDD > 8 && this.cf?.class8Code != eFieldStatus.Hide)
                    await this.getContactMarketApiData();

                break;
            }
            case 'Lead Related Goals': {
                this.finalGoals = [];
               /*its lead Type Code*/
                await this.getLeadApptType();
                //Lead Status
                await this.getLeadStatusData();

                if (this.co?.leadMaxDD > 1 && this.lf?.leadClass1Code != eFieldStatus.Hide)
                    await this.getLeadClass1Data();

                if (this.co?.leadMaxDD > 2 && this.lf?.leadClass2Code != eFieldStatus.Hide)
                    await this.getLeadClass2Data();

                if (this.co?.leadMaxDD > 3 && this.lf?.leadClass3Code != eFieldStatus.Hide)
                    await this.getLeadClass3Data();

                if (this.co?.leadMaxDD > 4 && this.lf?.leadClass4Code != eFieldStatus.Hide)
                    await this.getLeadClass4Data();

                if (this.co?.leadMaxDD > 5 && this.lf?.leadClass5Code != eFieldStatus.Hide)
                    await this.getLeadClass5Data();

                if (this.co?.leadMaxDD > 6 && this.lf?.leadClass6Code != eFieldStatus.Hide)
                    await this.getLeadClass6Data();

                //if (this.co?.leadMaxDD > 7 && this.lf?.leadClass7Code != eFieldStatus.Hide)
                //    await this.getLeadClass7Data();

                //if (this.co?.leadMaxDD > 8 && this.lf?.leadClass8Code != eFieldStatus.Hide)
                //    await this.getLeadClass8Data();

                //if (this.co?.leadMaxDD > 9 && this.lf?.leadClass9Code != eFieldStatus.Hide)
                //    await this.getLeadClass9Data();



                break;
            }
            default: {
                this.showSpinner = false;
                break;
            }

        }

    }


    async getGeneralData() {
        this.showSpinner = true;
        await this._goalsService.getGeneralData(this.encryptedUser, this.loggedUser.cLPUserID, this.eGoalGeneral)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.generalGoal = UtilityService.clone(result?.goalResponse);
                    this.finalGoals.push({ sectionName: 'General', goals: this.generalGoal });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'General Goals Setup List Error', 2000);
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._notifyService.showError('Could not show all goals', 'General Goals List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getGeneralApptData() {
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldsAppGeneral, this.eGoalApptGeneral)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.generalAppointmentGoal = UtilityService.clone(result?.goalAPTResponse);
                    this.generalAppointmentGoal.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'General Appointment Type', goals: this.generalAppointmentGoal });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'General Appointment Goals Setup List Error', 2000);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals', 'General Appointment Goals List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContactApptTypeData() {
        this.showSpinner = true;
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldContactAppt, this.eContactApptType)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.contactAppointmentGoal = UtilityService.clone(result?.goalAPTResponse);
                    this.contactAppointmentGoal.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Contact Appointment Type', goals: this.contactAppointmentGoal });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Contact Goal Setup List Error', 2000);
                this.showSpinner = false;

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals setup', 'Contact Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContacSetupeTypeData() {
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldContactType, this.eContactType)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.contactType = UtilityService.clone(result?.goalAPTResponse);
                    this.contactType.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Contact:Type', goals: this.contactType });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Contact Goal Setup List Error', 2000);

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals setup', 'Contact Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContactSetupStatusData() {
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eContactSta, this.eContactApptSta)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.contactStatusGoal = UtilityService.clone(result?.goalAPTResponse);
                    this.contactStatusGoal.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Contact: Status', goals: this.contactStatusGoal });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Contact Goal Setup List Error', 2000);

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals setup', 'Contact Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContactSetupProsStatusData() {
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldContactProsSta, this.eContactApptProsSta)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.contactProsStatusGoal = UtilityService.clone(result?.goalAPTResponse);
                    this.contactProsStatusGoal.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Contact:Prospect Status', goals: this.contactProsStatusGoal });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Contact Goal Setup List Error', 2000);

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals setup', 'Contact Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContactAggrData() {
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldContactAggr, this.eContactAggr)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.contactAggrGoal = UtilityService.clone(result?.goalAPTResponse);
                    this.contactAggrGoal.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Contact:Agreement Type', goals: this.contactAggrGoal });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Contact Goal Setup List Error', 2000);

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals setup', 'Contact Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContactRefSetupData() {
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldContactRef, this.eContactRef)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.contactRefGoal = UtilityService.clone(result?.goalAPTResponse);
                    this.contactRefGoal.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Contact:Referral Source Type', goals: this.contactRefGoal });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Contact Goal Setup List Error', 2000);

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals setup', 'Contact Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContactSetupLOIApiData() {

        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldContactLOI, this.eContactLOI)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.contactLOIGoal = UtilityService.clone(result?.goalAPTResponse);
                    this.contactLOIGoal.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Contact:Location of Interest', goals: this.contactLOIGoal });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Contact Goal Setup List Error', 2000);

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals setup', 'Contact Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContactSObjectApiData() {
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldContactObject, this.eContactObject)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.contactObjectGoal = UtilityService.clone(result?.goalAPTResponse);
                    this.contactObjectGoal.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Contact:Objections', goals: this.contactObjectGoal });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Contact Goal Setup List Error', 2000);

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals setup', 'Contact Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContactMarketApiData() {
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldContactMarket, this.eContactMarket)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.contactMarketGoal = UtilityService.clone(result?.goalAPTResponse);
                    this.contactMarketGoal.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Contact:Ad/Marketing Channel', goals: this.contactMarketGoal });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Contact Goal Setup List Error', 2000);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals setup', 'Contact Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }



    async getLeadApptData() {
        this.showSpinner = true;
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldLeadAppt, this.eLeadApptType)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.leadAppointmentGoal = UtilityService.clone(result?.goalAPTResponse);
                    this.leadAppointmentGoal.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Lead Appointment Type', goals: this.leadAppointmentGoal });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Lead Goals Setup List Error', 2000);
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._notifyService.showError('Could not show all goals setup ', 'Lead Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getLeadStatusData() {
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldLeadStatusCode, this.eLeadStatus)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.leadStatusGoal = UtilityService.clone(result?.goalAPTResponse);
                    this.leadStatusGoal.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.leadStatusGoal = this.leadStatusGoal.filter(x => x.display != 'Lost' && x.display != 'Abandoned');
                    this.finalGoals.push({ sectionName: 'Lead Status', goals: this.leadStatusGoal });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Lead Goals Setup List Error', 2000);

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals setup ', 'Lead Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async getLeadApptType() {
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldLeadAppt, this.eLeadApptType)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.leadStatusGoal = UtilityService.clone(result?.goalAPTResponse);
                    this.leadStatusGoal.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Lead Appointment Type', goals: this.leadStatusGoal });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Lead Goals Setup List Error', 2000);

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals setup ', 'Lead Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }
    
    async getLeadClass1Data() {
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldLeadClass1Code, this.eLeadClass1Code)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.leadRollOutGoal = UtilityService.clone(result?.goalAPTResponse);
                    this.leadRollOutGoal.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Lead: ' + this.lf.leadClass1CodeTitle, goals: this.leadRollOutGoal });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Lead Goals Setup List Error', 2000);

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals setup ', 'Lead Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getLeadClass2Data() {
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldLeadRollOut, this.eLeadRollOut)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.leadRollOutGoal = UtilityService.clone(result?.goalAPTResponse);
                    this.leadRollOutGoal.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Lead: ' + this.lf.leadClass2CodeTitle, goals: this.leadRollOutGoal });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Lead Goals Setup List Error', 2000);

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals setup ', 'Lead Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async getLeadClass3Data() {
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldLeadClass3, this.eLeadClass3)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.leadClass3 = UtilityService.clone(result?.goalAPTResponse);
                    this.leadClass3.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Lead: ' + this.lf.leadClass3CodeTitle, goals: this.leadClass3 });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Lead Goals Setup List Error', 2000);

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals setup ', 'Lead Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getLeadClass4Data() {
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldLeadClass4, this.eLeadClass4)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.leadClass4 = UtilityService.clone(result?.goalAPTResponse);
                    this.leadClass4.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Lead: '+ this.lf.leadClass4CodeTitle, goals: this.leadClass4 });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Lead Goals Setup List Error', 2000);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals setup ', 'Lead Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getLeadClass5Data() {
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldLeadClass5, this.eLeadClass5)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.leadClass5 = UtilityService.clone(result?.goalAPTResponse);
                    this.leadClass5.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Lead: ' + this.lf.leadClass5CodeTitle , goals: this.leadClass5 });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Lead Goals Setup List Error', 2000);

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals setup ', 'Lead Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getLeadClass6Data() {
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldLeadClass6, this.eLeadClass6)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.leadClass6 = UtilityService.clone(result?.goalAPTResponse);
                    this.leadClass6.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Lead: ' + this.lf.leadClass6CodeTitle, goals: this.leadClass6 });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Lead Goals Setup List Error', 2000);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals setup ', 'Lead Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getLeadClass7Data() {
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldLeadClass7, this.eLeadClass7)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.leadClass7 = UtilityService.clone(result?.goalAPTResponse);
                    this.leadClass7.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Lead: Class 7', goals: this.leadClass7 });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Lead Goals Setup List Error', 2000);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals setup ', 'Lead Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getLeadClass8Data() {
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldLeadClass8, this.eLeadClass8)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.leadClass8 = UtilityService.clone(result?.goalAPTResponse);
                    this.leadClass8.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Lead: Class 8', goals: this.leadClass8 });
                }
                else
                    this._notifyService.showError('Not found goals setup ', 'Lead Goals Setup List Error', 2000);

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);

                this._notifyService.showError('Could not show all goals setup ', 'Lead Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getLeadClass9Data() {
        await this._goalsService.getApptGeneral(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, this.eDDFieldLeadClass9, this.eLeadClass9)
            .then(async (result: GoalSetupRespnose) => {
                if (!isNullOrUndefined(result)) {
                    this.leadClass9 = UtilityService.clone(result?.goalAPTResponse);
                    this.leadClass9.sort((a, b) => parseFloat(a.sOrder) - parseFloat(b.sOrder));
                    this.finalGoals.push({ sectionName: 'Lead: Class 9', goals: this.leadClass9 });
                }
                else {
                    this._notifyService.showError('Not found goals setup ', 'Lead Goals Setup List Error', 2000);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._notifyService.showError('Could not show all goals setup ', 'Lead Goals Setup List Error', 2000);
                this._utilityService.handleErrorResponse(err);
            });
    }

    activateRowGeneral(goalRes, generalGoal, i: number) {
        if (goalRes.active) {
            generalGoal[i].goal = -1;
            generalGoal[i].showInSummary = false;
        }
        else
            generalGoal[i].goal = 0;
        generalGoal[i].active = !goalRes.active;
    }


    submitGoalForm(isDeleted?: boolean) {
        this.goalDataList = [];
        this.finalGoals?.forEach((goal: GoalsGeneric) => {
            if (goal.sectionName === "General") {
                goal?.goals?.forEach((goalValue, id) => {
                    let goalData: GoalData = <GoalData>{};
                    goalData = this.copyGoalDataGeneral(goalValue, goalData, id);
                    this.goalDataList.push(goalData);
                });
            }
            else {
                goal?.goals?.forEach((goalValue) => {
                    let goalData: GoalData = <GoalData>{};
                    goalData = this.copyGoalData(goalValue, goalData, goal.sectionName);
                    this.goalDataList.push(goalData);
                })
            }
        });
        this.goalDataList = this.goalDataList.filter(val => val.goal != 0);
        if (this.isValidGoals) {
            this._notifyService.showError('Could not save goals', 'Goal values Invalid', 2000);
            return;
        }
        else {
            if (isDeleted)
                this.saveGoals(true);
            else
                this.saveGoals();
        }

    }
    copyGoalData(goalData: DataList, objGoalData: GoalData, sectonName: string): GoalData {
        objGoalData.clpCompanyId = goalData?.clpCompanyId ? goalData?.clpCompanyId : this.loggedUser.cLPCompanyID;
        objGoalData.clpUserId = this.loggedUser.cLPUserID;
        objGoalData.goal = goalData?.goal;
        objGoalData.goalId = goalData?.goalId;
        objGoalData.showInSummary = goalData?.showInSummary;
        objGoalData.sOrder = goalData?.sOrder;
        objGoalData.ownerId = goalData?.typeCode;
        switch (sectonName) {
            case 'General Appointment Type': {
                objGoalData.goalType = this.eGoalApptGeneral;
                break;
            }
            case 'Contact Appointment Type': {
                objGoalData.goalType = this.eContactApptType;
                break;
            }
            case 'Contact:Type': {
                objGoalData.goalType = this.eContactType;
                break;
            }
            case 'Contact: Status': {
                objGoalData.goalType = this.eContactApptSta;
                break;
            }
            case 'Contact:Prospect Status': {
                objGoalData.goalType = this.eContactApptProsSta;
                break;
            }
            case 'Contact:Agreement Type': {
                objGoalData.goalType = this.eContactAggr;
                break;
            }
            case 'Contact:Referral Source Type': {
                objGoalData.goalType = this.eContactRef;
                break;
            }
            case 'Contact:Location of Interest': {
                objGoalData.goalType = this.eContactLOI;
                break;
            }
            case 'Contact:Objections': {
                objGoalData.goalType = this.eContactObject;
                break;
            }
            case 'Contact:Ad/Marketing Channel': {
                objGoalData.goalType = this.eContactMarket;
                break;
            }
            case 'Lead Appointment Type': {
                objGoalData.goalType = this.eLeadApptType;
                break;
            }
            case 'Lead Status': {
                objGoalData.goalType = this.eLeadStatus;
                break;
            }
            case 'Lead: Rollout Phases 1': {
                objGoalData.goalType = this.eLeadRollOut;
                break;
            }
            case 'Lead: Class 3': {
                objGoalData.goalType = this.eLeadClass3;
                break;
            }
            case 'Lead: Class 4': {
                objGoalData.goalType = this.eLeadClass4;
                break;
            }
            case 'Lead: Class 5': {
                objGoalData.goalType = this.eLeadClass5;
                break;
            }
            case 'Lead: Class 6': {
                objGoalData.goalType = this.eLeadClass6;
                break;
            }
            case 'Lead: Class 7': {
                objGoalData.goalType = this.eLeadClass7;
                break;
            }
            case 'Lead: Class 8': {
                objGoalData.goalType = this.eLeadClass8;
                break;
            }
            case 'Lead: Class 9': {
                objGoalData.goalType = this.eLeadClass9;
                break;
            }
            default: {
                objGoalData.goalType = this.eGoalApptGeneral;
            }
        }


        return objGoalData;
    }

    copyGoalDataGeneral(goalData: GoalSetup, objGoalData: GoalData, id: number) {
        objGoalData.clpCompanyId = this.loggedUser.cLPCompanyID;
        objGoalData.clpUserId = this.loggedUser.cLPUserID;
        objGoalData.goal = goalData?.goal;
        objGoalData.goalId = goalData?.goalId;
        objGoalData.showInSummary = goalData?.showInSummary;
        objGoalData.goalType = goalData?.goalType;
        objGoalData.ownerId = 0;
        objGoalData.sOrder = '' + ++id;
        return objGoalData;
    }

    setButtonDisable(model) {
        this.disableSaveButton = model.invalid;
    }

    async saveGoals(isDeleted?: boolean) {
        this.showSpinner = true;
        await this._goalsService.saveGoal(this.encryptedUser, this.goalDataList)
            .then(async (result: boolean) => {
                if (result) {
                    this.onSaveGoals.emit(true);
                    this.isValidGoals = false;
                    if (isDeleted)
                        this._notifyService.showError(this.sectionHeading + ' have been cleared.', 'Goals deleted successfully', 3000);
                    else
                        this._notifyService.showSuccess(this.sectionHeading + ' have been saved.', 'Goals Saved Successfully', 3000);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                console.log('error in saving monthly goal' + err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    deleteGoals() {
        this.finalGoals?.forEach((goal: GoalsGeneric) => {
            goal?.goals?.forEach((goalValue) => {
                if (goalValue.active)
                    goalValue.goal = -1;
            });
        });
        this.submitGoalForm(true);
    }
}


