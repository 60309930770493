
<div class="container-fluid">
  <div class="wraper-main-section mt-2">
    <div class="row">
      <div class="col-md-12">
        <ul class="step">
          <li class="active">Search Leads </li>
          <li [ngClass]="{'active': leadBulkStep >= 2}">Select Leads</li>
          <li [ngClass]="{'active': leadBulkStep >= 3}">Select Action</li>
        </ul>
      </div>
    </div>
  </div>
  <!--<div class="row">
      <div class="right-div">
          <contact-common-search *ngIf="isShowSearch" [execMapDuplicate]="false" [isLead]="true"></contact-common-search>
      </div>
  </div>-->
</div>

<div class="margin-all-area">
  <div class="tag-management-panel">
    <div class="wraper-main-section">
      <div class="global-card-section">
        <div class="global-header-section">
          <div class="svg-icon-panel"><img src="../../../../assets/leadtitle.svg" class="mr-1" />&nbsp; Bulk Lead Actions</div>
          <div class="header-button-panel">
            <div class="button-wrapper">
                <div class="custom-search">
                    <div class="display-row">
                        <contact-common-search *ngIf="isShowSearch" [execMapDuplicate]="false" [isLead]="true"></contact-common-search>
                        <label class="info-text" *ngIf="bulkLeadSelection?.length > 0 && showSelectedLeadCount">[Leads selected:{{bulkLeadSelection?.length}}]&nbsp;</label>
                        <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputTagSearch.value = ''">Reset Grid Setting</button>
                        <input class="" placeholder="Search in all columns..." kendoTextBox (input)="onLeadSearchFilter($event.target.value)" #inputTagSearch />
                        <button type="button" class="icon-btn" matTooltip="Start Over" (click)="startOver()" *ngIf="leadList?.length>0">
                            <i class="fa fa-refresh" aria-hidden="true"></i><span class="btn-text">Start Over</span>
                        </button>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div class="global-body-section">
          <ng-container [ngSwitch]="leadBulkStep">
            <ng-container *ngSwitchCase="1">
              <div class="success-alert">
                Please use quick search button on top right to search for Leads.
              </div>
              <div class="success-alert" *ngIf="isSaveBulkLeadEdit">
                {{leadBulkActionMsg}}
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="2">
              <div *ngIf="leadList?.length<=0">
                <div class="success-alert">
                  Please use the Quick Search panel on the right to search for leads.
                </div>
                <div class="row alert-panel margin-all-area" [hidden]="showBulkGrid">
                  No leads found.
                </div>
              </div>
              <div *ngIf="leadList?.length>0">
                <div class="success-alert">
                  Please select the leads that you would like to edit in bulk.
                </div>
                <div class="row mt-2 margin-all-area">
                  <button class="btn btn-primary" type="button" (click)="selectBulkLeads('all');">Select All</button>
                  <button class="btn btn-primary" *ngIf="showBulkGrid" [disabled]="bulkLeadSelection?.length<=0" type="button" (click)="selectBulkLeads('checked');">Select Checked</button>
                  <button class="btn btn-primary" *ngIf="!showBulkGrid" (click)="showBulkGrid=true;">Select Some</button>
                </div>
                <div class="row alert-panel margin-all-area" [hidden]="showBulkGrid">
                  Total lead found : <a class="webkit-any-link" (click)="showBulkGrid=true;">&nbsp;{{leadList?.length}} View Result</a>
                </div>
                <kendo-grid class="lead-search-grid" #grid *ngIf="_gridCnfgService.reloadGridGeneric"
                            [hidden]="!showBulkGrid"
                            id="gridId"
                            kendoGridSelectBy="leadID"
                            [selectable]="{ checkboxOnly: true ,mode: 'multiple'}"
                            [(selectedKeys)]="bulkLeadSelection"
                            [kendoGridBinding]="leadList"
                            [sortable]="{mode: 'multiple'}"
                            [scrollable]="'scrollable'"
                            [sort]="_gridCnfgService.sort"
                            [columnMenu]="{ filter: true }"
                            [resizable]="true"
                            [pageSize]="_gridCnfgService.pageSize"
                            [reorderable]="true"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('lead_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('lead_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('lead_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(12,'lead_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'lead_grid',grid)">
                  
                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title | titlecase"
                                       [width]="column.width | stringToNumber"
                                       [filterable]="true"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                       [includeInChooser]="column.field=='$' ? false : true"
                                       [editable]="column.field == '$'?false: true">
                      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                        <div class="customer-name" *ngIf="column.field == '$$'">  <a class="contact-cursor-pointer" title="View Lead" (click)="gotoLink('lead',dataItem);"><img src="../../../../assets/leadstitle.svg" class="mr-1" /> </a></div>
                        <div class="customer-name" *ngIf="column.field == 'leadDesc'">  <a class="webkit-any-link" (click)="gotoLink('lead',dataItem);"> {{ dataItem[column.field] ? dataItem[column.field] : '--' }}</a></div>
                        <div class="customer-name" *ngIf="column.field == 'lastFirst'"><a class="webkit-any-link" (click)="gotoLink('address-card', dataItem);">{{ dataItem[column.field] ? dataItem[column.field] : '--' }}</a></div>
                        <div class="customer-name" *ngIf="column.field == 'companyName'">{{ dataItem[column.field] ? dataItem[column.field] : ' ' }}</div>
                        <div class="customer-name" *ngIf="column.field == 'ufirstName'"><a class="webkit-any-link" (click)="gotoLink('userName', dataItem);"> {{ dataItem[column.field] ? dataItem[column.field] : '--' }}</a></div>
                        <div class="customer-name" *ngIf="column.field == 'dtStart'" [ngClass]="dataItem[column.field]? '' : 'text-success'">{{dataItem[column.field]  ? (dataItem[column.field] | date:dateFormat) : 'NA'}}</div>
                        <div class="customer-name" *ngIf="column.field == 'dtEnd'" [ngClass]="dataItem[column.field]? '' : 'text-success'">{{dataItem[column.field] ? (dataItem[column.field] | date:dateFormat) : 'NA'}}</div>
                        <div class="customer-name" *ngIf="column.field == 'volume'">{{dataItem[column.field] ? '$'+dataItem[column.field] : '$0'}}</div>
                        <div class="customer-name" *ngIf="column.field == 'revenue'">{{dataItem[column.field] ? '$'+dataItem[column.field] : '$0'}}</div>
                        <div class="customer-name" *ngIf="column.field == 'winProbability'">{{dataItem[column.field]}}</div>
                        <div class="customer-name" *ngIf="column.field == 'leadStatusCode'">{{dataItem[column.field] != 0 ? dataItem.leadStatus : 'Not Set'}}</div>
                        <div class="customer-name" *ngIf="column.field == 'dtModified'">{{dataItem[column.field] != '0001-01-01T00:00:00' ? (dataItem[column.field] | date:dateFormat) : '--'}}</div>
                        <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{dataItem[column.field] != '0001-01-01T00:00:00' ? (dataItem[column.field] | date:dateFormat) : '--'}}</div>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-checkbox-column [width]="45"
                                                [headerClass]="{ 'text-center': true }"
                                                [class]="{ 'text-center': true }"
                                                [resizable]="false"
                                                [columnMenu]="false"
                                                [showSelectAll]="true">
                      <ng-template kendoGridHeaderTemplate>
                        <input type="checkbox"
                               kendoCheckBox
                               id="selectBulkLeadCheckboxId"
                               kendoGridSelectAllCheckbox
                               (selectAllChange)="onSelectLeadChange($event)" />
                        <label class="k-checkbox-label" for="selectBulkLeadCheckboxId"></label>
                      </ng-template>
                    </kendo-grid-checkbox-column>
                </kendo-grid>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="3">
              <ng-container [ngSwitch]="isEditBulkLeadAction">
                <ng-container *ngSwitchCase="'default'">
                  <div>
                    <div class="success-alert">
                      Please select the type of bulk action that you would like to perform on the selected leads.
                    </div>
                    <div class="wraper-body-left">
                      <div class="bottom-button-bar">
                        <div class="button-bar-align">
                          <button class="btn btn-primary" [hidden]="roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator" (click)="editLeadBulkField();">Edit A Field</button>
                          <button class="btn btn-primary" [hidden]="roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator" (click)="transferBulkLeadOwnerField();">Transfer Ownership</button>
                          <button class="btn btn-outline-danger" [hidden]="roleFeaturePermissions?.delete == false && user?.userRole <= eUserRole.Administrator" data-toggle="modal" data-target="#deleteCheckedBulkLeads" >Permanently Delete a</button>
                        </div>
                      </div>
                      <div class="row alert-panel margin-all-area">
                        Total records selected: {{bulkLeadSelection?.length}}
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'edit'">
                  <div class="success-alert">{{leadBulkActionMsg}}</div>
                  <form [formGroup]="bulkEditLeadForm" (ngSubmit)="saveBulkLeadsEdits()">
                    <div class="cards-body-section">
                      <div class="cards-colunm-left">
                        <span>Select Field</span>
                      </div>
                      <div class="cards-colunm-right">
                        <select class="form-control" formControlName="ddField" *ngIf="bulkEditLeadForm.controls.ddField.value == ''" (change)="changeBulkLeadDDFields();">
                            <option value="">-Select One-</option>
                          <option *ngFor="let item of leadBulkFieldDropdown;" [value]="item?.value">{{item?.text}}</option>
                        </select>
                        <span *ngIf="bulkEditLeadForm.controls.ddField.value != ''">{{getSelectedFieldText(bulkEditLeadForm.controls.ddField.value)}}</span>
                      </div>
                    </div>
                    <div class="cards-body-section" *ngIf="bulkEditLeadForm.controls.ddField.value != '' ">
                      <div class="cards-colunm-left">
                        <span>Edit Type</span>
                      </div>
                      <div class="cards-colunm-right">
                        <select class="form-control" formControlName="editType" *ngIf="bulkEditLeadForm.controls.editType.value == -1" (change)="changeBulkLeadEditField()">
                            <option disabled value="-1">-Select One-</option>
                          <option [value]="key" *ngFor="let key of editTypeKeys" [label]="enumForEditType[key]"></option>
                        </select>
                        <span *ngIf="bulkEditLeadForm.controls.editType.value == 0">Replace</span>
                        <span *ngIf="bulkEditLeadForm.controls.editType.value == 1">Add to</span>
                        <span *ngIf="bulkEditLeadForm.controls.editType.value == 2">Clear</span>
                        <span *ngIf="bulkEditLeadForm.controls.editType.value == 3">Remove</span>
                      </div>
                    </div>
                    <div class="cards-body-section" *ngIf="bulkEditLeadForm.controls.editType.value == 0 || bulkEditLeadForm.controls.editType.value == 1">
                      <div class="cards-colunm-left">
                        <span>Value</span>
                      </div>
                      <div class="cards-colunm-right">
                        <ng-container [ngSwitch]="true">
                          <ng-container *ngSwitchCase="checkColumns('dateField')">
                            <div>
                              <kendo-datetimepicker class="form-control" id="textValue" formControlName="textValue" name="textValue"></kendo-datetimepicker>
                              <div *ngIf="bulkEditLeadForm.controls.textValue.errors && (bulkEditLeadForm.controls.textValue.touched || bulkEditLeadForm.controls.textValue.dirty)">
                                <div class="login-error" *ngIf="bulkEditLeadForm.controls.textValue.errors.required">Value is required </div>
                              </div>
                            </div>
                          </ng-container>
                          <ng-container *ngSwitchCase="checkColumns('textField')">
                            <div>
                              <input type="number" formControlName="textValue" [ngClass]="{'has-error': bulkEditLeadForm.controls.textValue.errors && (bulkEditLeadForm.controls.textValue.touched || bulkEditLeadForm.controls.textValue.dirty)}" />
                              <div *ngIf="bulkEditLeadForm.controls.textValue.errors && (bulkEditLeadForm.controls.textValue.touched || bulkEditLeadForm.controls.textValue.dirty)">
                                <div class="login-error" *ngIf="bulkEditLeadForm.controls.textValue.errors.required">Value must be a valid dollar amount.</div>
                              </div>
                            </div>
                          </ng-container>
                          <ng-container *ngSwitchCase="checkColumns('checkBox')">
                            <div>
                              <input class="form-control" type="checkbox" formControlName="textValue" [ngClass]="{'has-error': bulkEditLeadForm.controls.textValue.errors && (bulkEditLeadForm.controls.textValue.touched || bulkEditLeadForm.controls.textValue.dirty)}" />
                              <div *ngIf="bulkEditLeadForm.controls.textValue.errors && (bulkEditLeadForm.controls.textValue.touched || bulkEditLeadForm.controls.textValue.dirty)">
                                <div class="login-error" *ngIf="bulkEditLeadForm.controls.textValue.errors.required">Value is required </div>
                              </div>
                            </div>
                          </ng-container>
                          <ng-container *ngSwitchCase="bulkEditLeadForm.controls.ddField.value == 'Comments' || bulkEditLeadForm.controls.ddField.value == 'Scope'">
                            <div>
                              <textarea id="note" class="form-control contact-text-auto-height" row="1" cols="1" formControlName="textValue" placeholder="note"></textarea>
                              <div *ngIf="bulkEditLeadForm.controls.textValue.errors && (bulkEditLeadForm.controls.textValue.touched || bulkEditLeadForm.controls.textValue.dirty)">
                                <div class="login-error" *ngIf="bulkEditLeadForm.controls.textValue.errors.required">Note is required </div>
                              </div>
                            </div>
                          </ng-container>
                          <ng-container *ngSwitchCase="checkColumns('dropDown')">
                            <div>
                              <select class="form-control" formControlName="textValue">
                                  <option disabled value="0"> -Select One- </option>
                                <option *ngFor="let key of classValueLeadDropDown; let i = index" [value]="key.value">{{key?.text }}</option>
                              </select>
                              <div *ngIf="bulkEditLeadForm.controls.textValue?.errors && (bulkEditLeadForm.controls.textValue.touched || bulkEditLeadForm.controls.textValue.dirty)">
                                <div class="login-error" *ngIf="bulkEditLeadForm.controls.textValue.errors.required">Value is required </div>
                              </div>
                            </div>
                          </ng-container>

                          <ng-container *ngSwitchCase=" bulkEditLeadForm.controls.ddField.value == 'Tag'">
                            <div>
                              <div class="example-wrapper">
                                <kendo-autocomplete #autocomplete
                                                    [data]="classValueLeadDropDown"
                                                    textField="text"
                                                    valueField="value"
                                                    (valueChange)="handleTagFilter($event)"
                                                    placeholder="Search Tag value"
                                                    formControlName="textValue"></kendo-autocomplete>

                              </div>
                            </div>
                          </ng-container>

                          <ng-container *ngSwitchDefault>
                            <div>
                              <input type="text" formControlName="textValue" [ngClass]="{'has-error': bulkEditLeadForm.controls.textValue.errors && (bulkEditLeadForm.controls.textValue.touched || bulkEditLeadForm.controls.textValue.dirty)}" />
                              <div *ngIf="bulkEditLeadForm.controls.textValue.errors && (bulkEditLeadForm.controls.textValue.touched || bulkEditLeadForm.controls.textValue.dirty)">
                                <div class="login-error" *ngIf="bulkEditLeadForm.controls.textValue.errors.required">Value is required </div>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                    <div class="cards-body-section" *ngIf="bulkEditLeadForm.controls.editType.value != -1">
                      <div class="cards-colunm-left"></div>
                      <div class="cards-colunm-right">
                        <div class="bottom-button-bar">
                          <button class="btn btn-primary" *ngIf="!isShowSaveBulkLeadSubmit" type="button" (click)="bulkLeadConfirmEditSubmit();">                          
                            <ng-template [ngIf]="!showSpinner">Save</ng-template>
                            <ng-template [ngIf]="showSpinner">Saving  <span><i wrapper> </i></span></ng-template>
                          </button>
                          <button class="btn btn-primary" [disabled]="" *ngIf="isShowSaveBulkLeadSubmit" [hidden]="(roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false) && user?.userRole <= eUserRole.Administrator" type="submit">Confirm <app-loader></app-loader></button>
                          <button class="btn btn-cancel" type="button" (click)="cancelBulkEditLeadAction();"><i class="fa fa-times"></i>Cancel</button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div class="row alert-panel margin-all-area">
                    Total records selected: {{bulkLeadSelection?.length}}
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'transfer'">
                  <div class="wraper-body-panel">
                    <div class="wraper-body-left">
                      <div *ngIf="!confirmTransfer" class="alert-panel">Please select the new owner of all checked leads.</div>
                      <div *ngIf="confirmTransfer && isSelectAllBulkLeads" class="success-alert">You are about to transfer ownership of all {{bulkLeadSelection?.length}} selected leads to {{newOwnerToTransfer}}.  Please confirm</div>
                      <div *ngIf="confirmTransfer && !isSelectAllBulkLeads" class="success-alert">You are about to transfer ownership of all checked leads to {{newOwnerToTransfer}}. Please confirm.</div>
                      <form [formGroup]="bulkTransferBulkLeadForm" (ngSubmit)="transferBulkLead()">
                        <div class="cards-body-section">
                          <div class="cards-colunm-left">
                            <span>New Owner</span>
                          </div>
                          <div class="cards-colunm-right">
                            <select class="form-control" formControlName="ddValue" (change)="onChangeNewOwner($event)">
                              <option value="0" hidden>Does not Exist</option>
                              <option *ngFor="let item of usersBulkLeadListDropDown" [value]="item.value">{{item?.text}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="cards-body-section">
                          <div class="cards-colunm-left"></div>
                          <div class="cards-colunm-right">
                            <div class="button-bar-align">
                              <button class="btn btn-primary ml-0" *ngIf="!confirmTransfer" [hidden]="(roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false) && user?.userRole <= eUserRole.Administrator" type="button" (click)="confirmTransfer=true">
                                <ng-template [ngIf]="!showSpinner">Save</ng-template>
                              </button>
                               <button class="btn btn-primary ml-0" *ngIf="confirmTransfer" [hidden]="(roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false) && user?.userRole <= eUserRole.Administrator" type="submit">
                                <ng-template [ngIf]="!showSpinner">Confirm</ng-template>
                                <ng-template [ngIf]="showSpinner">Saving  <span><i wrapper> </i></span></ng-template>
                              </button>
                              <button class="btn btn-cancel" type="button" (click)="cancelBulkEditLeadAction();"><i class="fa fa-times"></i>Cancel</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'delete'">
                  <div class="wraper-body-panel">
                    <div class="wraper-body-left">
                      <div class="alert-panel">
                        <span *ngIf="!isSelectAllBulkLeads">Caution: You are about to permanently delete all {{bulkLeadSelection?.length}} selected leads along with all related items such as appointments, tasks, etc... Please confirm.</span>
                        <span *ngIf="isSelectAllBulkLeads">Caution: You are about to permanently delete all checked leads along with all related items such as appointments, tasks, etc... Please confirm.</span>
                      </div>
                      <div class="bottom-button-bar">
                        <div class="button-bar-align">
                          <button class="btn btn-outline-danger" (click)="confirmDeleteBulkLead()" [hidden]="roleFeaturePermissions?.delete == false && user?.userRole <= eUserRole.Administrator" >Permanently Delete b</button>
                          <button class="btn btn-cancel" type="button" (click)="cancelBulkEditLeadAction();"><i class="fa fa-times"></i>Cancel</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
             
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteCheckedBulkLeads" tabindex="-1" role="dialog" aria-labelledby="deleteCheckedBulkLeads" aria-hidden="true">
  <div class="modal-dialog  modal-common-dialog" role="document">
    <div class="modal-content modal-common-content">
      <div class="modal-header modal-common-background">
        <h4 class="modal-title modal-common-title">Confirmation</h4>
      </div>
      <div class="modal-body modal-common-body">
        <span *ngIf="!isSelectAllBulkLeads">Caution: You are about to permanently delete all {{bulkLeadSelection?.length}} selected leads along with all related items such as appointments, tasks, etc... Please confirm.</span>
        <span *ngIf="isSelectAllBulkLeads">Caution: You are about to permanently delete all checked leads along with all related items such as appointments, tasks, etc... Please confirm.</span>
      </div>
      <div class="modal-footer">
        <button type="button"  (click)="isEditBulkLeadAction='delete';" data-dismiss="modal" class="btn btn-primary">          
          <ng-template [ngIf]="buttonTypeOperation!=0">ok</ng-template>
          <ng-template [ngIf]="buttonTypeOperation===0">Deleting &nbsp; <span> &nbsp;<i wrapper> </i></span></ng-template>
        </button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>

