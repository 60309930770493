import { Component } from '@angular/core';
import { CLPUser, ContactSectionOrderSaveRequest, ProcessActiveHoursSaveRequest, UserDefaultLoadResponse, UserResponse } from '../../../models/clpuser.model';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { UserService } from '../../../services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilityService } from '../../../services/shared/utility.service';
import { eFeatures } from '../../../models/enum.model';
import { LocalService } from '../../../services/shared/local.service';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { Router } from '@angular/router';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { GlobalService } from '../../../services/global.service';
import { ContactService } from '../../../services/contact.service';
import { DisplaySetting, DisplaySettingsResponse } from '../../../models/contact.model';
import { DatePipe } from '@angular/common';
import { NotificationService } from '../../../services/notification.service';

@Component({
    selector: 'app-user-default',
    templateUrl: './user-default.component.html',
    styleUrls: ['./user-default.component.css'],
    providers: [GridConfigurationService]
})

export class UserDefaultComponent {
    warningStr: string = ""
    user: CLPUser;
    displaySetting: DisplaySetting;
    isViewModeSection1: boolean = true
    userResponse: UserResponse;
    displaySettingResponse: DisplaySettingsResponse;
    contactSectionOrderSaveRequest = <ContactSectionOrderSaveRequest>{}
    processActiveHoursSaveRequest = <ProcessActiveHoursSaveRequest>{}
    isViewModeSection2: boolean = true
    roleFeaturePermissions: RoleFeaturePermissions;
    sectionOrderForm: FormGroup;
    voiceTextActiveHoursForm: FormGroup;
    userDefaultLoad: UserDefaultLoadResponse;
    verticalDisplaysetting: any = [];
    defaultContactSaveOrder: { sectionName: string; sectionDisplayOrder: number; }[];
    settingSetupForm(): FormGroup {
        return new FormGroup({
            settingConfigs: this.fb.array([this.fb.group({
                section: ['']
            })]),
        });
    }
    encryptedUser: string = "";
    isResetOrder: boolean = false;
    constructor(private fb: FormBuilder,
        private userService: UserService,
        public _localService: LocalService,
        public _globalService: GlobalService,
        public _utilityService: UtilityService,
        public _contactService: ContactService,
        private _notifyService: NotificationService,
        private datepipe: DatePipe,
        private _router: Router) {

    }

    ngOnInit() {
        this.sectionOrderForm = this.settingSetupForm();
        this.voiceTextActiveHoursForm = this.prepareVoiceTextActiveHoursForm();
        this.defaultContactSaveOrder = [
            { sectionName: "General", sectionDisplayOrder: 1 },
            { sectionName: "Classification", sectionDisplayOrder: 2 },
            { sectionName: "Address", sectionDisplayOrder: 3 },
            { sectionName: "Additional Information", sectionDisplayOrder: 4 },
            { sectionName: "Comments", sectionDisplayOrder: 5 },
            { sectionName: "Tags", sectionDisplayOrder: 6 },
            { sectionName: "Communication", sectionDisplayOrder: 7 },
            { sectionName: "Other Email", sectionDisplayOrder: 8 },
            { sectionName: "Connections", sectionDisplayOrder: 9 },
            { sectionName: "More Fields", sectionDisplayOrder: 10 },
            { sectionName: "System", sectionDisplayOrder: 11 },
        ];

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(async () => {
                    if (this.user) {
                        this.getUserDefaults();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });

    }

    prepareVoiceTextActiveHoursForm() {
        return this.fb.group({
            sMSStartTimeWeekDay: new FormControl(new Date()),
            sMSEndTimeWeekDay: new FormControl(new Date()),
            sMSStartTimeWeekend: new FormControl(new Date()),
            sMSEndTimeWeekend: new FormControl(new Date()),
            voiceStartTimeWeekDay: new FormControl(new Date()),
            voiceEndTimeWeekDay: new FormControl(new Date()),
            voiceStartTimeWeekend: new FormControl(new Date()),
            voiceEndTimeWeekend: new FormControl(new Date()),
        })
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("user-default.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    get sectionOrderFormCtls() {
        return this.sectionOrderForm.get('settingConfigs') as FormArray;
    }

    EditSection1() {
        this.isViewModeSection1 = false;
        this.warningStr = "Drag and drop the sections of the contact home page to your desired order."
    }


    EditSection2() {
        this.isViewModeSection2 = false;
        this.patchVoiceTextHoursValues()
    }


    dropSetting(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container)
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }

    async getUserDefaults() {
        return this.userService.getUserDefaults(this.encryptedUser, this.user.cLPUserID)
            .then(async (result: UserDefaultLoadResponse) => {
                if (result) {
                    this.userDefaultLoad = UtilityService.clone(result);
                    let clpuserDefault = this.userDefaultLoad?.clpUserDefaults
                    await this.copyDefaultData(clpuserDefault);
                    this.verticalDisplaysetting = JSON.parse(this.userDefaultLoad?.clpUserDefaults?.verticalDisplaysetting)
                    this.sectionOrderFormCtls.controls = [];
                    this.sectionOrderFormCtls.removeAt(0);
                    this.verticalDisplaysetting.forEach((element: any) => {
                        this.sectionOrderFormCtls.push(
                            this.fb.group({
                                sectionDisplayOrder: element.sectionDisplayOrder,
                                sectionName: element.sectionName,
                            })
                        )
                    });
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log('error in saving setting code' + err);
            });
    }

    async contactSectionOrderSave() {
        this.verticalDisplaysetting = []
        this.sectionOrderFormCtls.controls.forEach((row, index) => {
            var orders = {
                sectionName: row.value.sectionName,
                sectionDisplayOrder: index + 1,
            }
            this.verticalDisplaysetting.push(orders);
        });
        this.isResetOrder = false;
        this.onContactSave(this.verticalDisplaysetting);

    }

    async onContactSave(request) {
        await this._contactService.contactFieldVerticalDisplaysettingUpdate(this.encryptedUser, request, this.user?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        await this.getUserDefaults();
                        if (this.isResetOrder)
                            this._notifyService.showSuccess('Contact home page order as been reset.', '', 3000)
                        else
                            this._notifyService.showSuccess('Contact home page order as been saved.', '', 3000)
                        this.isResetOrder = false;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log('error in saving setting code' + err);
            });
    }

    async saveActiveHours() {
        let processActiveHoursSaveRequest = <ProcessActiveHoursSaveRequest>{};
        processActiveHoursSaveRequest.cLPUserID = this.user?.cLPUserID;
        processActiveHoursSaveRequest.cLPCompanyID = this.user?.cLPCompanyID;
        processActiveHoursSaveRequest.strSMSStartTimeWeekDay = this.formatDate(this.voiceTextActiveHoursForm.controls['sMSStartTimeWeekDay'].value)
        processActiveHoursSaveRequest.strSMSEndTimeWeekDay = this.formatDate(this.voiceTextActiveHoursForm.controls['sMSEndTimeWeekDay'].value)
        processActiveHoursSaveRequest.strSMSStartTimeWeekend = this.formatDate(this.voiceTextActiveHoursForm.controls['sMSStartTimeWeekend'].value)
        processActiveHoursSaveRequest.strSMSEndTimeWeekend = this.formatDate(this.voiceTextActiveHoursForm.controls['sMSEndTimeWeekend'].value)
        processActiveHoursSaveRequest.strVoiceStartTimeWeekDay = this.formatDate(this.voiceTextActiveHoursForm.controls['voiceStartTimeWeekDay'].value)
        processActiveHoursSaveRequest.strVoiceEndTimeWeekDay = this.formatDate(this.voiceTextActiveHoursForm.controls['voiceEndTimeWeekDay'].value)
        processActiveHoursSaveRequest.strVoiceStartTimeWeekend = this.formatDate(this.voiceTextActiveHoursForm.controls['voiceStartTimeWeekend'].value)
        processActiveHoursSaveRequest.strVoiceEndTimeWeekend = this.formatDate(this.voiceTextActiveHoursForm.controls['voiceEndTimeWeekend'].value)

        await this.userService.processActiveHoursSave(this.encryptedUser, processActiveHoursSaveRequest)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.isViewModeSection2 = true;
                        this.getUserDefaults();
                    }
                    else {
                        this._notifyService.showError(response.messageString, "Error", 3000);
                        this.isViewModeSection2 = false;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log('error in saving setting code' + err);
            });
    }

    formatDate(inputDate: string): string {
        const date = new Date(inputDate);

        let curDt = new Date();
        const month = curDt.getMonth() + 1;
        const day = curDt.getDate();
        const year = curDt.getFullYear();

        const formattedMonth = month < 10 ? '0' + month : month.toString();
        const formattedDay = day < 10 ? '0' + day : day.toString();

        let hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12;

        const formattedHours = hours < 10 ? '0' + hours : hours.toString();
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();
        const formattedSeconds = seconds < 10 ? '0' + seconds : seconds.toString();

        return `${formattedMonth}${formattedDay}${year} ${formattedHours}${formattedMinutes}${formattedSeconds}${ampm}`;
    }


    patchVoiceTextHoursValues() {
        this.voiceTextActiveHoursForm.controls['sMSStartTimeWeekDay'].setValue(new Date(this.processActiveHoursSaveRequest.strSMSStartTimeWeekDay))
        this.voiceTextActiveHoursForm.controls['sMSEndTimeWeekDay'].setValue(new Date(this.processActiveHoursSaveRequest.strSMSEndTimeWeekDay))
        this.voiceTextActiveHoursForm.controls['sMSStartTimeWeekend'].setValue(new Date(this.processActiveHoursSaveRequest.strSMSStartTimeWeekend))
        this.voiceTextActiveHoursForm.controls['sMSEndTimeWeekend'].setValue(new Date(this.processActiveHoursSaveRequest.strSMSEndTimeWeekend))
        this.voiceTextActiveHoursForm.controls['voiceStartTimeWeekDay'].setValue(new Date(this.processActiveHoursSaveRequest.strVoiceStartTimeWeekDay))
        this.voiceTextActiveHoursForm.controls['voiceEndTimeWeekDay'].setValue(new Date(this.processActiveHoursSaveRequest.strVoiceEndTimeWeekDay))
        this.voiceTextActiveHoursForm.controls['voiceStartTimeWeekend'].setValue(new Date(this.processActiveHoursSaveRequest.strVoiceStartTimeWeekend))
        this.voiceTextActiveHoursForm.controls['voiceEndTimeWeekend'].setValue(new Date(this.processActiveHoursSaveRequest.strVoiceEndTimeWeekend))
    }

    cancelSectionOrderChange() {
        this.isViewModeSection1 = true;
        this.warningStr = "No changes have been made."
    }

    onResetContactOrder() {
        this.verticalDisplaysetting = [];
        this.defaultContactSaveOrder.forEach((element: any) => {
            var orders = {
                sectionName: element.sectionName,
                sectionDisplayOrder: element.sectionDisplayOrder,
            }
            this.verticalDisplaysetting.push(orders);
        });
        this.isResetOrder = true;
        this.onContactSave(this.verticalDisplaysetting);

    }

    copyDefaultData(clpuserDefault) {
        this.processActiveHoursSaveRequest = <ProcessActiveHoursSaveRequest>{}
        this.processActiveHoursSaveRequest.cLPUserID = clpuserDefault?.cLPUserID
        this.processActiveHoursSaveRequest.cLPCompanyID = clpuserDefault?.cLPCompanyID
        this.processActiveHoursSaveRequest.strSMSStartTimeWeekDay = clpuserDefault?.sMSStartTimeWeekDay
        this.processActiveHoursSaveRequest.strSMSEndTimeWeekDay = clpuserDefault?.sMSEndTimeWeekDay
        this.processActiveHoursSaveRequest.strSMSStartTimeWeekend = clpuserDefault?.sMSStartTimeWeekend
        this.processActiveHoursSaveRequest.strSMSEndTimeWeekend = clpuserDefault?.sMSEndTimeWeekend
        this.processActiveHoursSaveRequest.strVoiceStartTimeWeekDay = clpuserDefault?.voiceStartTimeWeekDay
        this.processActiveHoursSaveRequest.strVoiceEndTimeWeekDay = clpuserDefault?.voiceEndTimeWeekDay
        this.processActiveHoursSaveRequest.strVoiceStartTimeWeekend = clpuserDefault?.voiceStartTimeWeekend
        this.processActiveHoursSaveRequest.strVoiceEndTimeWeekend = clpuserDefault?.voiceEndTimeWeekend


    }
}


