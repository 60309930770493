import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UtilityService } from './shared/utility.service';
import { delayedRetryHttp } from './shared/delayedRetry';
import { SimpleResponse } from '../models/genericResponse.model';
import { SOMetricListResponse } from '../models/so-metric.model';

@Injectable({
  providedIn: 'root'
})
export class SoMetricService {

    private baseUrl: string;
    private api: string = "api/SOMetric";

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }


    async sOMetric_CountByContactID(encryptedUser: string,  clpCompanyId : number,  clpuserId : number,  contactID : number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/SOMetric_CountByContactID/${clpCompanyId}/${clpuserId}/${contactID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpuserId - " + clpuserId + "," + "contactID - " + contactID, encryptedUser, "SoMetricService", "countByContact"); });
        return a;
    }

    async sOMetric_LoadByContactID(encryptedUser: string, clpCompanyId: number, clpuserId: number, contactID: number): Promise<SOMetricListResponse | void> {
        const a = await this.httpClient
            .get<SOMetricListResponse>(`${this.baseUrl}/SOMetric_LoadByContactID/${clpCompanyId}/${clpuserId}/${contactID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpuserId - " + clpuserId + "," + "contactID - " + contactID, encryptedUser, "SoMetricService", "sOMetric_LoadByContactID"); });
        return a;
    }

    async sOMetric_UpdateValue(encryptedUser: string, clpCompanyId: number, clpuserId: number, soMetricID : number, strValue : string = ''): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/SOMetric_UpdateValue/${clpCompanyId}/${clpuserId}/${soMetricID}/${strValue}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpuserId - " + clpuserId + "," + "soMetricID - " + soMetricID + "," + "strValue - " + strValue, encryptedUser, "SoMetricService", "sOMetric_UpdateValue"); });
        return a;
    }

    async sOMetric_Delete(encryptedUser: string, clpCompanyId: number, clpuserId: number, soMetricID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/SOMetric_Delete/${clpCompanyId}/${clpuserId}/${soMetricID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clpuserId - " + clpuserId + "," + "soMetricID - " + soMetricID, encryptedUser, "SoMetricService", "sOMetric_Delete"); });
        return a;
    }
}
