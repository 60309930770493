import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ContractResponse } from '../models/cmContracts.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';


@Injectable({
    providedIn: 'root'
})
export class CMContractService {

    private baseUrl: string;
    private api: string = 'api/CMContract';


    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async cmContractGet(encryptedUser: string, clpCompanyId: number, clubId: number, siteId: number, contractId: number, isAI: boolean): Promise<ContractResponse | void> {
        const a = await this.httpClient
            .get<ContractResponse>(`${this.baseUrl}/CMContract_Get/${clpCompanyId}?clubID=${clubId}&siteID=${siteId}&contractId=${contractId}&isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpCompanyId - " + clpCompanyId + "," + "clubId - " + clubId + "," + "siteId - " + siteId, encryptedUser, "CMContractService", "cmContractGet"); });
        return a;
    }

    async cmContractGetById(encryptedUser: string, clpuserID: number, contactID: number, isAI: boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMMember_GetContractID/${clpuserID}/${contactID}?isAI=${isAI}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpuserID - " + clpuserID + "," + "contactID - " + contactID, encryptedUser, "CMContractService", "cmContractGet"); });
        return a;




    }

    async CMMember_GetAgreementProgressBarByContactID(encryptedUser: string, clpuserID: number, contactID: number, companyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/CMMember_GetAgreementProgressBarByContactID/${clpuserID}/${contactID}/${companyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "clpuserID - " + clpuserID + "," + "contactID - " + contactID, encryptedUser, "CMContractService", "cmContractGet"); });
        return a;
    }
}
