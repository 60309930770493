<div class="role-setup-section">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <div class="">
        <div class="global-header-section">
          <div class="svg-icon-panel"><img src="../../../../assets/activity/config/userlisttitle.svg" class="mr-1" />Roles</div>
          <div class="header-button-panel">
            <div class="button-wrapper">
              <button class="icon-btn" (click)="onAddRole()" type="button">
                <i class="fa fa-plus" aria-hidden="true"></i>
                <span class="btn-text">Add</span>
              </button>
              <button class="icon-btn" [disabled]="showSpinner" [hidden]="(roleFeaturePermissions?.edit == false || roleFeaturePermissions?.create == false) && user?.userRole <= eUserRole.Administrator" (click)="saveRoleSettings()">
                <i class="fa fa-save" aria-hidden="true"></i>
                <span class="btn-text">Save</span>
              </button>
              <button class="icon-btn" (click)="onreset()" type="button">
                <i class="fa fa-refresh" aria-hidden="true"></i>
                <span class="btn-text">Refresh</span>
              </button>
              <button class="grid-delete-btn" (click)="deleteRole()" type="button">
                <kendo-icon name="delete"></kendo-icon>
                <span class="btn-text">Delete</span>
              </button>
              <button class="icon-btn" [routerLink]="['/home']">
                <i class="fa fa-times" aria-hidden="true"></i>
                <span class="btn-text">Delete</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="global-body-section">
        <div class="roles-body-section">
          <div class="role-listing-panel" *ngIf="_roleList && _roleList.length>0">
            <h3><a href="javascript:void();">Role</a></h3>
            <ul>
              <mat-selection-list #selectedRole [multiple]="false">
                <mat-list-option [ngStyle]="{'background-color':this.selectedRoleId === i+1? '#EBF7FB' : 'white'}" *ngFor="let r of _roleList, let i = index" [value]="r.role.roleId" (click)="roleSelectionChange()" [selected]="r && i == 0">
                  {{r.role.roleName}}
                </mat-list-option>
              </mat-selection-list>
            </ul>
          </div>
          <div class="role-description-panel">
            <form [formGroup]="tableForm">
              <div class="display-row">
                <div class="full-width-container">
                  <label>Role </label>
                  <input type="text" formControlName="roleName" />
                </div>
                <div class="full-width-container">
                  <label>Description </label>
                  <input type="text" formControlName="roleDescription" />
                </div>
              </div>
              <div class="role-tabs-section">
                <div class="role-tabs-panel">
                  <kendo-tabstrip (tabSelect)="onTabSelect($event)">
                    <kendo-tabstrip-tab [title]="'Feature'" [selected]="true">
                      <ng-template kendoTabContent>
                        <!------------------------changes------------------------>
                        <div class="feature-table" formArrayName="arrayForm">
                          <fieldset>
                            <legend>Features</legend>
                            <table class="table" *ngFor="let category of tableFormControls.controls; let i = index;">
                              <thead>
                                <tr>
                                  <th>{{category.controls.featureName.value}}</th>
                                  <th>View</th>
                                  <th>Create</th>
                                  <th>Edit</th>
                                  <th>Delete</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ list: category?.controls.items?.controls }"></ng-container>
                                <ng-template #recursiveListTmpl let-list="list">
                                  <ng-container *ngFor="let item of list" [formGroupName]="i">
                                    <tr *ngIf="item?.controls.featureName.value != 'Recent' && item?.controls.featureName.value != 'Custom'">
                                      <ng-container>
                                        <td [attr.colspan]="item?.controls?.items?.value?.length > 0 ? '5' : ''">
                                          <div class="inner-cards-panel">
                                            <div>{{ item.controls.featureName.value }} </div>
                                            <div>
                                              <button (click)="createPackage(item);" *ngIf="item?.controls?.items?.value?.length > 0" type="button" class="grid-common-btn">
                                                <i class="fa " [ngClass]="item?.controls.isCollapse.value ?'fa fa-chevron-up':'fa fa-chevron-down'"></i>
                                              </button>
                                            </div>
                                          </div>
                                        </td>
                                        
                                        <td [hidden]="item?.controls?.items?.value?.length > 0"><div class="custom-toggle"><label class="switch"><input type="checkbox" [checked]="item?.controls['view']?.value" (change)="onchangeView($event,item)" ><span class="slider round"></span></label></div></td>
                                        <td [hidden]="item?.controls?.items?.value?.length > 0"><div class="custom-toggle"><label class="switch"><input type="checkbox" [checked]="item?.controls['create']?.value" (change)="onchangeCreate($event,item)" ><span class="slider round"></span></label></div></td>
                                        <td [hidden]="item?.controls?.items?.value?.length > 0"><div class="custom-toggle"><label class="switch"><input type="checkbox" [checked]="item?.controls['edit']?.value" (change)="onchangeEdit($event,item)" ><span class="slider round"></span></label></div></td>
                                        <td [hidden]="item?.controls?.items?.value?.length > 0"><div class="custom-toggle"><label class="switch"><input type="checkbox" [checked]="item?.controls['delete']?.value" (change)="onchangeDelete($event,item)" ><span class="slider round"></span></label></div></td>
                                      </ng-container>
                                    </tr>
                                    <ng-container *ngIf="item?.controls.featureName.value != 'Recent' && item?.controls.featureName.value != 'Custom' && item?.controls?.items?.value?.length > 0 && item?.controls.isCollapse.value">
                                      <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ list: item?.controls.items?.controls }"></ng-container>
                                    </ng-container>
                                  </ng-container>
                                </ng-template>
                              </tbody>
                            </table>
                          </fieldset>
                        </div>
                        <!-----------------changes--------------------->
                      </ng-template>
                    </kendo-tabstrip-tab>
                    <kendo-tabstrip-tab [title]="'Users in Roles'">
                      <ng-template kendoTabContent>
                        <div class="user-tabs-panel">
                          <div class="display-row">
                            <div class="user-form">
                              <label>Offices</label>
                              <select class="form-control" (change)="filterOffice_onchange($event.key)">
                                <option [value]=0>--Select--</option>
                                <option *ngFor="let filterOffice_item of filterOffice; let i = index" [value]='filterOffice_item.key'>
                                  {{filterOffice_item.value}}
                                </option>
                              </select>
                            </div>
                            <div class="user-form">
                              <label>Teams</label>
                              <select class="form-control" (change)="filterTeam_onchange($event.key)">
                                <option [value]=0>--Select--</option>
                                <option *ngFor="let filterTeam_item of filterTeam; let i = index" [value]='filterTeam_item.key'>
                                  {{filterTeam_item.value}}
                                </option>
                              </select>
                            </div>
                            <div class="user-form">
                              <label>Search</label>
                              <input class="role-serach" type="text" [(ngModel)]="searchText" (keyup)="keyPress($event)" placeholder="Search" [ngModelOptions]="{standalone: true}" />
                            </div>
                            <div class="header-button-side">
                              <button class="btn btn-cancel" (click)="clearDataSearch()"><i class="fas fa-times"></i> Clear</button>
                            </div>
                          </div>
                          <div class="switch-section">
                            <div class="switch-left">
                              <div class="example-container">
                                <div cdkDropList
                                     #frmList="cdkDropList"
                                     [cdkDropListData]="fromUserlist"
                                     [cdkDropListConnectedTo]="[tolist]"
                                     class="example-list"
                                     (cdkDropListDropped)="drop($event)"
                                     (click)="fromList_Onclick()">
                                  <div class="example-box" *ngFor="let i of  fromUserlist" cdkDrag (click)="fromList_Onclick($event)">
                                    {{i.value}}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="switch-middle-btn">
                              <button class="btn btn-primary" (click)="selectAll()"><i class="fas fa-angle-double-right"></i></button>
                              <button class="btn btn-primary mt-2" (click)="unSelectAll()"><i class="fas fa-angle-double-left"></i></button>
                            </div>
                            <div class="switch-right">
                              <div class="example-container">
                                <div cdkDropList
                                     #tolist="cdkDropList"
                                     [cdkDropListData]="toUserlist"
                                     [cdkDropListConnectedTo]="[frmList]"
                                     class="example-list"
                                     (cdkDropListDropped)="drop($event)">
                                  <div class="example-box" *ngFor="let i of toUserlist" cdkDrag>{{i.value}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </kendo-tabstrip-tab>
                  </kendo-tabstrip>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loader-body" *ngIf="showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
  </div>
</div>

<div class="modal fade" id="deleteRoleSetup" tabindex="-1" role="dialog" aria-labelledby="deleteRoleSetup" aria-hidden="true">
  <div class="modal-dialog  modal-common-dialog" role="document">
    <div class="modal-content modal-role-content">
      <div class="modal-body modal-common-body">
        <h2>This role will be permanently deleted. Are you sure you want to delete this role?</h2>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="deleteRoleConfirm()">Confirm</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="confirmationModal" tabindex="-1" role="dialog" aria-labelledby="confirmationModal" aria-hidden="true">
  <div class="modal-dialog  modal-common-dialog" role="document">
    <div class="modal-content modal-role-content">
      <div class="modal-body modal-common-body">
        <h2>Admin Role can not be removed . Please add current logged in user in List!!!</h2>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

