import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppointmentSetting, AppointmentSettingListResponse } from '../models/appointmentSetting.model';
import { ApppointmentResponse, ApptExtendedSaveRequest, ApptListResponse, ApptSelectedContact, BulkApptGetRequest, BulkApptProcessStep1Response, BulkApptSaveRequest, ProposedTimesResponse, TempList, TempListResponse } from '../models/appt.model';
import { MonthDDResponse } from '../models/calender.model';
import { eAppointmentSettings, eApptCategory, eApptStatus, eTempListStatus } from '../models/enum.model';
import { ApptDataToConfig, ApptFormBody, ApptIHMainResponse, ApptMainResponse, ConfiguredApptResponse, ddIHReasonResponse, ddIXReasonResponse, FiltersApptIHMainResponse, FiltersApptMainResponse, IHIMSaveBody, IHReason, IHSchSaveBody, RecurrApptMonthListResponse, RecurringApptFilters, RecurringApptListResponse, TSAUserListResponse } from '../models/filterApptMain.model';
import { DropDownItem, IntDropDownItem, SimpleResponse } from '../models/genericResponse.model';
import { Document } from "../models/document";
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';
import { isNullOrEmptyString } from '../../shared/utils.js';
import { SchedularListResponse, SchedularRequest, ScheduleAppointmentRequest } from '../models/scheduler.model';
import { DDFieldResponse } from '../models/clpuser.model';

@Injectable({
    providedIn: 'root'
})
export class AppointmentSettingService {

    private baseUrl: string;
    private api: string = "api/AppointmentSetting";

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    getApptLengthDD() {
        var lengthList: DropDownItem[] = [];
        var val: number = -15;
        var txt: string = '';
        var idx: number = 1;
        for (let i = 0; i < 17; i++) {
            if (i < 3) {
                val = val + 15;
                txt = `${val} ${i == 0 ? 'minute' : 'minutes'} before`;
            } else if (i == 3) {
                continue;
            } else if (i >= 4 && i < 8) {
                val = val + 60;
                txt = `${idx} ${i == 4 ? 'hour' : 'hours'} before`;
                idx++;
            } else if (i >= 8 && i < 15) {
                idx = i == 8 ? 1 : idx;
                val = i == 8 ? val + 1200 : val + 1440;
                txt = `${idx} ${i == 8 ? 'day' : 'days'} before`;
                idx++;
            } else if (i >= 15) {
                val = val * 2;
                txt = `${i == 15 ? '14' : '30'} days before`;
            }
            lengthList.push({ value: val.toString(), text: txt });
        }
        return lengthList;
    }

    overlapAppointment(apptResponse): { isError: boolean, strError: string } {
        if (apptResponse?.isApptOverLap) {
            var isError = true;
            var overlapAppt = apptResponse.overLapAppt;
            var strError = 'This appointment overlaps with another: <br>.';
            switch (overlapAppt.category) {
                case eApptCategory.Contact:
                    if (overlapAppt?.contact?.firstName)
                        strError = strError + `Contact: ${overlapAppt?.contact?.firstName} ${overlapAppt?.contact?.lastName}` + '<br>';
                    if (overlapAppt?.contactTypeCode != 0)
                        strError = strError + 'Type: ' + overlapAppt?.codeDisplay + '<br>';
                    break;
                case eApptCategory.Lead:
                    if (overlapAppt?.lead?.leadDesc && overlapAppt?.leadContact?.firstName)
                        strError = strError + `Lead: ${overlapAppt?.lead?.leadDesc} <br> Contact: ${overlapAppt?.leadContact?.firstName}  ${overlapAppt?.leadContact?.lastName}` + '<br>';
                    if (overlapAppt?.leadTypeCode != 0)
                        strError = strError + 'Type: ' + overlapAppt?.codeDisplay + '<br>';
                    break;
                default:
                    if (overlapAppt?.generalTypeCode != 0)
                        strError = strError + 'Type:' + overlapAppt?.codeDisplay + '<br>';
                    break;
            }
            strError = strError + overlapAppt?.subject + '<br>';
            strError = strError + overlapAppt?.strTimeSpanDisplay;
            if (!isNullOrEmptyString(overlapAppt.location))
                strError = strError + `(${overlapAppt.location})`;
            if (!isNullOrEmptyString(overlapAppt.notes))
                strError = strError + '<br>' + `${overlapAppt.notes}`;
        } else {
            isError = false;
            strError = '';
        }
        return { isError, strError };
    }

    async getAppointmentSettings(encryptedUser: string, clpUserId: number, clpCompanyId: number): Promise<AppointmentSettingListResponse | void> {
        const http$ = await this.httpClient
            .get<AppointmentSettingListResponse>(`${this.baseUrl}/AppointmentSetting_GetList/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "cLPCompanyID - " + clpCompanyId, encryptedUser, "AppointmentSettingService", "getAppointmentSettings"); });

        return http$;
    }

    async updateAppointment(encryptedUser: string, appointmentSettings: AppointmentSetting[], clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.post<SimpleResponse>(`${this.baseUrl}/Appointment_Update/${clpUserId}/${clpCompanyId}`, appointmentSettings, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, appointmentSettings, "r - " + encryptedUser, encryptedUser, "AppointmentSettingService", "updateAppointment"); });
        return a;

    }

    async deleteAppointment(encryptedUser: string, code: number, eAppointmentSettings: eAppointmentSettings, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/Appointment_Delete/${code}/${eAppointmentSettings}/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, eAppointmentSettings, "r - " + encryptedUser + " , " + "code - " + code, encryptedUser, "AppointmentSettingService", "deleteAppointment"); });
        return a;
    }

    async getAppointmentFilter(encryptedUser: string, clpuserID: number, clpCompanyId: number, eApptCategory: eApptCategory): Promise<FiltersApptMainResponse | void> {
        const a = await this.httpClient
            .get<FiltersApptMainResponse>(`${this.baseUrl}/Appt_Main_Filters_Get/${clpuserID}/${clpCompanyId}/${eApptCategory}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyId - " + clpCompanyId + " , " + "eApptCategory - " + eApptCategory, encryptedUser, "AppointmentSettingService", "getAppointmentFilter"); });
        return a;
    }

    async getNonAttendeeList(encryptedUser: string, clpuserId: number, clpCompanyId: number, apptID: number, teamCode: number, officeCode: number): Promise<DropDownItem[] | void> {
        const a = await this.httpClient
            .get<DropDownItem[]>(`${this.baseUrl}/Filter_NonAttendeeList/${clpCompanyId}/${clpuserId}/${apptID}/${teamCode}/${officeCode}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "apptID - " + apptID + " , " + "teamCode - " + teamCode + " , " + "officeCode - " + officeCode, encryptedUser, "AppointmentSettingService", "getNonAttendeeList"); });
        return a;
    }

    async loadCustomTemplate(encryptedUser: string, apptID: number, apptTemplateID: number, clpuserID: number, clpcompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/LoadCustomTemplate/${apptID}/${apptTemplateID}/${clpuserID}/${clpcompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "apptTemplateID - " + apptTemplateID + " , " + "clpuserID - " + clpuserID, encryptedUser, "AppointmentSettingService", "loadCustomTemplate"); });
        return a;
    }

    async loadApptData(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number, casID: number, firstLoad: boolean = false, apptLengthInMins: number = 30, strDtStartSelectedValue: string = '8 : 00 AM', apptCategory: number = 4, ownerID: number = 0, openToCallAction: boolean = false): Promise<ApptMainResponse | void> {
        const a = await this.httpClient
            .get<ApptMainResponse>(`${this.baseUrl}/ApptMain_Load/${apptID}/${clpuserID}/${clpCompanyID}/${casID}/${firstLoad}/${apptLengthInMins}/${strDtStartSelectedValue}/${apptCategory}/${ownerID}/${openToCallAction}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID + " , " + "casID - " + casID + " , " + "firstLoad - " + firstLoad + " , " + "apptLengthInMins - " + apptLengthInMins + " , " + "strDtStartSelectedValue - " + strDtStartSelectedValue + " , " + "apptCategory - " + apptCategory + " , " + "ownerID - " + ownerID + " , " + "openToCallAction - " + openToCallAction, encryptedUser, "AppointmentSettingService", "loadApptData"); });
        return a;
    }

    async performFreeCheck(encryptedUser: string, clpuserID: number, clpcompanyId: number, apptRequest: ApptExtendedSaveRequest): Promise<ProposedTimesResponse | void> {
        const a = await this.httpClient
            .post<ProposedTimesResponse>(`${this.baseUrl}/PerformOverLapCheck/${clpuserID}/${clpcompanyId}`, apptRequest, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, apptRequest, "r - " + encryptedUser + " , " + "clpuserID - " + clpuserID, encryptedUser, "AppointmentSettingService", "performFreeCheck"); });
        return a;
    }

    async saveApptData(encryptedUser: string, apptRequest: ApptExtendedSaveRequest, apptID: number, clpuserID: number, clpcompanyId: number, selectedUserId: number, isManagerCheck: Boolean, isCLPReminder: boolean = false, isAddNew: boolean = false, isPeformOverLap: boolean = false): Promise<ApptMainResponse | void> {
        const a = await this.httpClient
            .post<ApptMainResponse>(`${this.baseUrl}/ApptMain_Save/${apptID}/${clpuserID}/${clpcompanyId}/${selectedUserId}/${isManagerCheck}/${isCLPReminder}/${isAddNew}/${isPeformOverLap}`, apptRequest, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, apptRequest, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "selectedUserId - " + selectedUserId + " , " + "isManagerCheck - " + isManagerCheck + " , " + "isCLPReminder - " + isCLPReminder + " , " + "isAddNew - " + isAddNew + " , " + "isPeformOverLap - " + isPeformOverLap, encryptedUser, "AppointmentSettingService", "saveApptData"); });
        return a;
    }

    async bindRecurrringApptData(encryptedUser: string, clpCompanyId: number, clpUserId: number, apptId: number): Promise<RecurringApptFilters | void> {
        const a = await this.httpClient
            .get<RecurringApptFilters>(`${this.baseUrl}/Bind_RecurrringApptData/${clpCompanyId}/${clpUserId}/${apptId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId + " , " + "apptId - " + apptId, encryptedUser, "AppointmentSettingService", "bindRecurrringApptData"); });
        return a;
    }

    async bindSearchFiles(encryptedUser: string, clpUserId: number, clpCompanyId: number, strFileSearch: string): Promise<Document[] | void> {
        const a = await this.httpClient
            .get<Document[]>(`${this.baseUrl}/BindSearchFiles/${clpUserId}/${clpCompanyId}/${strFileSearch}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpUserId - " + clpUserId + " , " + "strFileSearch - " + strFileSearch, encryptedUser, "AppointmentSettingService", "bindSearchFiles"); });
        return a;
    }

    async getConfiguredRecurrTimeAppt(encryptedUser: string, apptDataToConfig: ApptDataToConfig, clpUserId: number, clpCompanyId: number): Promise<ConfiguredApptResponse | void> {
        const a = await this.httpClient
            .post<ConfiguredApptResponse>(`${this.baseUrl}/GetConfiguredAppt/${clpUserId}/${clpCompanyId}`, apptDataToConfig, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, apptDataToConfig, "r - " + encryptedUser, encryptedUser, "AppointmentSettingService", "getConfiguredRecurrTimeAppt"); });
        return a;
    }

    async getApptList(encryptedUser: string, clpCompanyId: number, clpuserID: number, selMonth: number, selUserId: number, selTeamCode: number, selOfficeCode: number = 0, isOverDueOnly: boolean = false, ecat: eApptCategory, eStatus: eApptStatus, ownerId: number = 0): Promise<ApptListResponse | void> {
        const a = await this.httpClient
            .get<ApptListResponse>(`${this.baseUrl}/ApptList_Get/${clpCompanyId}/${clpuserID}/${selMonth}/${selUserId}/${selTeamCode}/${selOfficeCode}/${isOverDueOnly}/${ecat}/${eStatus}/${ownerId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpuserID - " + clpuserID + " , " + "selMonth - " + selMonth + " , " + "selUserId - " + selUserId + " , " + "selTeamCode - " + selTeamCode + " , " + "selOfficeCode - " + selOfficeCode + " , " + "isOverDueOnly - " + isOverDueOnly + " , " + "ecat - " + ecat + " , " + "eStatus - " + eStatus, encryptedUser, "AppointmentSettingService", "getApptList"); });
        return a;
    }

    async getMonthDD(encryptedUser: string, clpuserID: number, clpCompanyId: number, stat: eApptStatus = eApptStatus.None): Promise<MonthDDResponse | void> {
        const a = await this.httpClient
            .get<MonthDDResponse>(`${this.baseUrl}/Get_MonthDD/${clpuserID}/${clpCompanyId}?eStatus=${stat}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "stat - " + stat, encryptedUser, "AppointmentSettingService", "getMonthDD"); });
        return a;
    }

    async bulkApptMoveRight(encryptedUser: string, clpCompanyId: number, clpuserID: number, eTempListStatus: eTempListStatus, contactIds: number[]): Promise<TempListResponse | void> {
        const a = await this.httpClient
            .post<TempListResponse>(`${this.baseUrl}/BulkAppt_MoveRight/${clpCompanyId}/${clpuserID}/${eTempListStatus}`, contactIds, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, contactIds, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpuserID - " + clpuserID + " , " + "eTempListStatus - " + eTempListStatus, encryptedUser, "AppointmentSettingService", "bulkApptMoveRight"); });
        return a;
    }

    async bulkApptProcess1(encryptedUser: string, clpuserID: number, clpCompanyId: number, bulkApptRequest: BulkApptGetRequest): Promise<BulkApptProcessStep1Response | void> {
        const a = await this.httpClient
            .post<BulkApptProcessStep1Response>(`${this.baseUrl}/BulkApptProcessStep1/${clpuserID}/${clpCompanyId}`, bulkApptRequest, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, bulkApptRequest, "r - " + encryptedUser + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "AppointmentSettingService", "bulkApptProcess1"); });
        return a;
    }

    async saveBulkAppt(encryptedUser: string, clpuserID: number, clpCompanyId: number, bulkApptRequest: BulkApptSaveRequest): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/BulkAppt_Finish/${clpuserID}/${clpCompanyId}`, bulkApptRequest, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, bulkApptRequest, "r - " + encryptedUser + " , " + "clpuserID - " + clpuserID, encryptedUser, "AppointmentSettingService", "saveBulkAppt"); });
        return a;
    }

    async bulkApptConfig(encryptedUser: string, clpuserID: number, clpCompanyID: number, movedRightList: ApptSelectedContact[], numPerDay: number, isWeekend: boolean, strdtStart: string): Promise<BulkApptProcessStep1Response | void> {
        const a = await this.httpClient
            .post<BulkApptProcessStep1Response>(`${this.baseUrl}/bulkApptConfig/${clpuserID}/${numPerDay}/${clpCompanyID}/${isWeekend}/${strdtStart}`, movedRightList, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, movedRightList, "r - " + encryptedUser + " , " + "clpuserID - " + clpuserID + " , " + "numPerDay - " + numPerDay + " , " + "isWeekend - " + isWeekend + " , " + "strdtStart -" + strdtStart, encryptedUser, "AppointmentSettingService", "saveBulkAppt"); });
        return a;
    }

    async getMonthDDInGrid(encryptedUser: string): Promise<RecurrApptMonthListResponse | void> {
        const a = await this.httpClient
            .get<RecurrApptMonthListResponse>(`${this.baseUrl}/GetMonthDD`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "AppointmentSettingService", "getMonthDDInGrid"); });
        return a;
    }

    async getDaysDDByMonthYear(encryptedUser: string, year: number, month: number): Promise<IntDropDownItem[] | void> {
        const a = await this.httpClient
            .get<IntDropDownItem[]>(`${this.baseUrl}/GetDaysDDBy_MonthYear/${year}/${month}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "year - " + year + " , " + "month - " + month, encryptedUser, "AppointmentSettingService", "getDaysDDByMonthYear"); });
        return a;
    }

    async recurrApptConfirmAndFinishClick(encryptedUser: string, clpUserId: number, clpCompanyId: number, rApptId: number, apptFormBody: ApptFormBody): Promise<RecurringApptListResponse | void> {
        const a = await this.httpClient
            .post<RecurringApptListResponse>(`${this.baseUrl}/RApptConfirmAndFinish?clpUserId=${clpUserId}&clpCompanyId=${clpCompanyId}&rApptId=${rApptId}`, apptFormBody, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, apptFormBody, "r - " + encryptedUser + " , " + "clpUserId - " + clpUserId + " , " + "clpCompanyId - " + clpCompanyId + " , " + "rApptId - " + rApptId, encryptedUser, "AppointmentSettingService", "recurrApptConfirmAndFinishClick"); });
        return a;
    }

    async apptDelete(encryptedUser: string, apptId: number, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient.get<SimpleResponse>(`${this.baseUrl}/Appt_Delete/${apptId}/${clpUserId}/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptId - " + apptId + " , " + "clpCompanyId - " + clpCompanyId, encryptedUser, "AppointmentSettingService", "apptDelete"); });
        return a;
    }

    async apptLoad(encryptedUser: string, apptId: number, clpUserId: number, clpCompanyId: number): Promise<ApppointmentResponse | void> {
        const a = await this.httpClient.get<ApppointmentResponse>(`${this.baseUrl}/Appt_Load/${apptId}/${clpUserId}/${clpCompanyId}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptId - " + apptId + encryptedUser, "AppointmentSettingService", "apptLoad"); });
        return a;
    }
    //Appointment -Ih


    async getAppointmentIhFilter(encryptedUser: string, clpuserID: number, clpCompanyId: number, eApptCategory: eApptCategory): Promise<FiltersApptIHMainResponse | void> {
        const a = await this.httpClient
            .get<FiltersApptIHMainResponse>(`${this.baseUrl}/ApptIH_Main_Filters_Get/${clpuserID}/${clpCompanyId}/${eApptCategory}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyId - " + clpCompanyId + " , " + "eApptCategory - " + eApptCategory, encryptedUser, "AppointmentSettingService", "getAppointmentIhFilter"); });
        return a;
    }

    async loadApptIhData(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number, apptLengthInMins: number = 30, strDtStartSelectedValue: string = '8 : 00 AM', apptCategory: number = 4, ownerID: number = 0): Promise<ApptIHMainResponse | void> {
        const a = await this.httpClient
            .get<ApptIHMainResponse>(`${this.baseUrl}/ApptIHMain_Load/${apptID}/${clpuserID}/${clpCompanyID}/${apptLengthInMins}/${strDtStartSelectedValue}/${apptCategory}/${ownerID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID + " , " + "apptLengthInMins - " + apptLengthInMins + " , " + "strDtStartSelectedValue - " + strDtStartSelectedValue + " , " + "apptCategory - " + apptCategory + " , " + "ownerID - " + ownerID, encryptedUser, "AppointmentSettingService", "loadApptIhData"); });
        return a;
    }

    async saveIhApptData(encryptedUser: string, apptRequest: ApptExtendedSaveRequest, apptID: number, clpuserID: number, clpCompanyID: number, selectedUserId: number, isManagerCheck: Boolean): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/ApptIHMain_Save/${apptID}/${clpuserID}/${clpCompanyID}/${selectedUserId}/${isManagerCheck}`, apptRequest, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, apptRequest, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "selectedUserId - " + selectedUserId + " , " + "isManagerCheck - " + isManagerCheck, encryptedUser, "AppointmentSettingService", "saveIhApptData"); });
        return a;
    }

    async saveApptNotes(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number, strNote: string, strCallListNote: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHNotesSave/${apptID}/${strCallListNote}/${strNote}/${clpCompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID + " , " + "strNote - " + strNote + " , " + "strCallListNote - " + strCallListNote, encryptedUser, "AppointmentSettingService", "saveApptNotes"); });
        return a;
    }

    async IHNewTaskSave(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number, txtIHTaskDesc: string, txtdtIHTaskDate: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHNewTaskSave/${apptID}/${clpCompanyID}/${clpuserID}?txtIHTaskDesc=${txtIHTaskDesc}&txtdtIHTaskDate=${txtdtIHTaskDate}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID + " , " + "txtIHTaskDesc - " + txtIHTaskDesc + " , " + "txtdtIHTaskDate - " + txtdtIHTaskDate, encryptedUser, "AppointmentSettingService", "saveNewTask"); });
        return a;
    }

    async didNotAnswer(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number, selectedUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHDidNotAnswer/${apptID}/${clpCompanyID}/${clpuserID}/${selectedUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID + " , " + "selectedUserId - " + selectedUserId, encryptedUser, "AppointmentSettingService", "didNotAnswer"); });
        return a;
    }

    async getTsaUserList(encryptedUser: string, clpuserID: number, clpCompanyId: number): Promise<TSAUserListResponse | void> {
        const a = await this.httpClient
            .get<TSAUserListResponse>(`${this.baseUrl}/Get_TSAUserList/${clpuserID}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpuserID - " + clpuserID, encryptedUser, "AppointmentSettingService", "getTsaUserList"); });
        return a;
    }

    async saveIHAppt(encryptedUser: string, apptID: number, dtIHDate: string, ddIHTime: string, isIHOverRight: boolean, cbIHNewLeadSchTeleSales: boolean, txtOtherEmails: string, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHSaveAppt/${apptID}/${dtIHDate}/${ddIHTime}/${isIHOverRight}/${cbIHNewLeadSchTeleSales}/${clpUserId}/${clpCompanyId}?txtOtherEmails=${txtOtherEmails}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "dtIHDate - " + dtIHDate + " , " + "ddIHTime - " + ddIHTime + " , " + "isIHOverRight - " + isIHOverRight + " , " + "cbIHNewLeadSchTeleSales - " + cbIHNewLeadSchTeleSales + " , " + "txtOtherEmails - " + txtOtherEmails, encryptedUser, "AppointmentSettingService", "saveIHAppt"); });
        return a;
    }

    async iHTSASelectRep(encryptedUser: string, apptID: number, clpCompanyID: number, clpuserID: number, selectedRep: number, IHTSANowLaterTxt: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSASelectRep/${apptID}/${clpCompanyID}/${clpuserID}/${selectedRep}?IHTSANowLaterTxt=${IHTSANowLaterTxt}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID + " , " + "selectedRep - " + selectedRep + " , " + "IHTSANowLaterTxt - " + IHTSANowLaterTxt, encryptedUser, "AppointmentSettingService", "iHTSASelectRep"); });
        return a;
    }

    async iHLeftVoiceMail(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number, selectedUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHLeftVoicemail/${apptID}/${clpCompanyID}/${clpuserID}/${selectedUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID + " , " + "selectedUserId - " + selectedUserId, encryptedUser, "AppointmentSettingService", "iHLeftVoiceMail"); });
        return a;
    }

    async iHAccessBIP(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number, IHETID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHAccessBIP/${apptID}/${clpCompanyID}/${clpuserID}/${IHETID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID + " , " + "IHETID - " + IHETID, encryptedUser, "AppointmentSettingService", "iHAccessBIP"); });
        return a;
    }

    async iHTsAccessBip(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSAccessBIP/${apptID}/${clpCompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AppointmentSettingService", "iHTsAccessBip"); });
        return a;
    }

    async iHDNADead(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHDNADEAD/${apptID}/${clpCompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AppointmentSettingService", "iHDNADead"); });
        return a;
    }

    async iHBIPContract(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHBIPContract/${apptID}/${clpCompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AppointmentSettingService", "iHBIPContract"); });
        return a;
    }

    async iHBIPNoContract(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/btnIHBIPNoContract/${apptID}/${clpCompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AppointmentSettingService", "iHBIPNoContract"); });
        return a;
    }

    async iHBIPNoShow(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHBIPNoShow/${apptID}/${clpCompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AppointmentSettingService", "iHBIPNoShow"); });
        return a;
    }

    async iHBIPMarkDeadNoShow(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHBIPMarkDeadNoShow/${apptID}/${clpCompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AppointmentSettingService", "iHBIPMarkDeadNoShow"); });
        return a;
    }

    async iHBipReschSpecFU(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHBIPReschSpecFU/${apptID}/${clpCompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AppointmentSettingService", "iHBipReschSpecFU"); });
        return a;
    }

    async ihBipMarkAsDead(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHBIPMarkAsDead/${apptID}/${clpCompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AppointmentSettingService", "ihBipMarkAsDead"); });
        return a;
    }

    async iHBIPBeBack(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number, btnIHBIPBeBackTxt: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHBIPBeBack/${apptID}/${clpCompanyID}/${clpuserID}/${btnIHBIPBeBackTxt}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID + " , " + "btnIHBIPBeBackTxt - " + btnIHBIPBeBackTxt, encryptedUser, "AppointmentSettingService", "iHBIPBeBack"); });
        return a;
    }

    async iHBipSpecificFollowUp(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number, btnIHBIPSpecificFollowUpTxt: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHBIPSpecificFollowUp/${apptID}/${clpCompanyID}/${clpuserID}/${btnIHBIPSpecificFollowUpTxt}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID + " , " + "btnIHBIPSpecificFollowUpTxt - " + btnIHBIPSpecificFollowUpTxt, encryptedUser, "AppointmentSettingService", "iHBipSpecificFollowUp"); });
        return a;
    }

    async iHBipSpecificFollowUpContract(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number, btnIHBIPSpecificFollowUpContractTxt: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHBIPSpecificFollowUpContract/${apptID}/${clpCompanyID}/${clpuserID}/${btnIHBIPSpecificFollowUpContractTxt}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID + " , " + "btnIHBIPSpecificFollowUpContractTxt - " + btnIHBIPSpecificFollowUpContractTxt, encryptedUser, "AppointmentSettingService", "iHBipSpecificFollowUpContract"); });
        return a;
    }

    async iHBipEmailOnly(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number, btnIHBIPEmailOnlyTxt: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHBIPEmailOnly/${apptID}/${clpCompanyID}/${clpuserID}/${btnIHBIPEmailOnlyTxt}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AppointmentSettingService", "iHBipEmailOnly"); });
        return a;
    }

    async iHBIPShowView(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHBIPShowView/${apptID}/${clpCompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AppointmentSettingService", "iHBIPShowView"); });
        return a;
    }

    async iHBIPNoShowView(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHBIPNoShowView/${apptID}/${clpCompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AppointmentSettingService", "iHBIPNoShowView"); });
        return a;
    }

    async iHBIPMarkDeadShow(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHBIPMarkDeadShow/${apptID}/${clpCompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AppointmentSettingService", "iHBIPMarkDeadShow"); });
        return a;
    }

    async iHTsLeadCallDead(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSLeadCallDead/${apptID}/${clpCompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AppointmentSettingService", "iHTsLeadCallDead"); });
        return a;
    }

    async iHTsBipShow(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSBIPShow/${apptID}/${clpCompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AppointmentSettingService", "iHTsBipShow"); });
        return a;
    }

    async bIPTelesalesCStartInterim(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/bIPTelesalesCStartInterim/${apptID}/${clpuserID}/${clpCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "bIPTelesalesCStartInterim"); });
        return a;
    }

    async bIPTelesalesCBeBackMeeting(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/bIPTelesalesCBeBackMeeting/${apptID}/${clpuserID}/${clpCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "bIPTelesalesCBeBackMeeting"); });
        return a;
    }

    async bIPTelesalesCNotSold(encryptedUser: string, apptID: number, ihBipSpecificFollowUpTxt: string, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/bIPTelesalesCNotSold/${apptID}/${ihBipSpecificFollowUpTxt}/${clpuserID}/${clpCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + "bIPTelesalesCNotSold - " + ihBipSpecificFollowUpTxt, encryptedUser, "AppointmentSettingService", "bIPTelesalesCNotSold"); });
        return a;
    }

    async bIPTelesalesCDeadLead(encryptedUser: string, apptID: number, cLPCompanyID: number, cLPUserID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/bIPTelesalesCDeadLead/${apptID}/${cLPCompanyID}/${cLPUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + "," + "cLPCompanyID - " + cLPCompanyID + "cLPUserID - " + cLPUserID, encryptedUser, "AppointmentSettingService", "bIPTelesalesCDeadLead"); });
        return a;
    }

    async bIPFace2FaceBStartInterim(encryptedUser: string, apptID: number, cLPUserID: number, cLPCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/bIPFace2FaceBStartInterim/${apptID}/${cLPUserID}/${cLPCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + "," + "cLPUserID - " + cLPUserID, encryptedUser, "AppointmentSettingService", "bIPFace2FaceBStartInterim"); });
        return a;
    }

    async bIPFace2FaceBBeBackMeeting(encryptedUser: string, apptID: number, cLPUserID: number, cLPCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/bIPFace2FaceBBeBackMeeting/${apptID}/${cLPUserID}/${cLPCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "bIPFace2FaceBBeBackMeeting"); });
        return a;
    }

    async bIPFace2FaceBNotSold(encryptedUser: string, apptID: number, ihBipSpecificFollowUpTxt: string, cLPUserID: number, cLPCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/bIPFace2FaceBNotSold/${apptID}/${ihBipSpecificFollowUpTxt}/${cLPUserID}/${cLPCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "ihBipSpecificFollowUpTxt - " + ihBipSpecificFollowUpTxt, encryptedUser, "AppointmentSettingService", "bIPFace2FaceBNotSold"); });
        return a;
    }

    async bIPFace2FaceBDeadLead(encryptedUser: string, apptID: number, cLPCompanyID: number, cLPUserID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/bIPFace2FaceBDeadLead/${apptID}/${cLPCompanyID}/${cLPUserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "cLPCompanyID - " + cLPCompanyID + " , " + "cLPUserID - " + cLPUserID, encryptedUser, "AppointmentSettingService", "bIPFace2FaceBDeadLead"); });
        return a;
    }

    async iHTsBipNoShow(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSBIPNoShow/${apptID}/${clpCompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AppointmentSettingService", "iHTsBipNoShow"); });
        return a;
    }

    async iHTsBipCancelled(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSBIPCancelled/${apptID}/${clpCompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AppointmentSettingService", "iHTsBipCancelled"); });
        return a;
    }

    async iHTsBipShowDead(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSBIPShowDead/${apptID}/${clpCompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AppointmentSettingService", "iHTsBipShowDead"); });
        return a;
    }

    async iHContractSignedPaymentReceived(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHContractSignedPaymentReceived/${apptID}/${clpuserID}/${clpCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID, encryptedUser, "AppointmentSettingService", "iHContractSignedPaymentReceived"); });
        return a;
    }

    async iHContractSignedPaymentNotReceived(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHContractSignedPaymentNotReceived/${apptID}/${clpuserID}/${clpCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "iHContractSignedPaymentNotReceived"); });
        return a;
    }


    async ihTSGotoBIP(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/ihTSGotoBIP/${apptID}/${clpuserID}/${clpCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "ihTSGotoBIP"); });
        return a;
    }


    async iHTsScheduleBip(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSScheduleBIP/${apptID}/${clpuserID}/${clpCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "iHTsScheduleBip"); });
        return a;
    }

    async iHSchedule(encryptedUser: string, apptID: number, cLPUserID: number, cLPCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/iHSchedule/${apptID}/${cLPUserID}/${cLPCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + "cLPUserID - " + cLPUserID + "cLPCompanyID - " + cLPCompanyID, encryptedUser, "AppointmentSettingService", "iHTsScheduleBip"); });
        return a;
    }

    async iHTsBipBuy(encryptedUser: string, apptID: number, cLPUserID: number, cLPCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSBIPBuy/${apptID}/${cLPUserID}/${cLPCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "iHTsBipBuy"); });
        return a;
    }

    async iHTsBipNoBuy(encryptedUser: string, apptID: number, cLPUserID: number, cLPCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSBIPNoBuy/${apptID}/${cLPUserID}/${cLPCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "iHTsBipNoBuy"); });
        return a;
    }

    async iHTsBipBuyCanada(encryptedUser: string, apptID: number, cLPUserID: number, cLPCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSBIPBuyCanada/${apptID}/${cLPUserID}/${cLPCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "iHTsBipBuyCanada"); });
        return a;
    }

    async iHTsBipNoBuyCanada(encryptedUser: string, apptID: number, cLPUserID: number, cLPCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSBIPNoBuyCanada/${apptID}/${cLPUserID}/${cLPCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "iHTsBipNoBuyCanada"); });
        return a;
    }

    async iHTsBipNoBuyInternational(encryptedUser: string, apptID: number, cLPUserID: number, cLPCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSBIPNoBuyInternational/${apptID}/${cLPUserID}/${cLPCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "iHTsBipNoBuyInternational"); });
        return a;
    }

    async iHTsBipDead(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSBIPDead/${apptID}/${clpCompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AppointmentSettingService", "iHTsBipDead"); });
        return a;
    }

    async iHTsContractContinue(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/iHTsContractContinue/${apptID}/${clpuserID}/${clpCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "iHTsContractContinue"); });
        return a;
    }

    async iHTsIm(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSIM/${apptID}/${clpuserID}/${clpCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "iHTsIm"); });
        return a;
    }

    async iHTsImNoAnswerView(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSIMNoAnswerView/${apptID}/${clpuserID}/${clpCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "iHTsImNoAnswerView"); });
        return a;
    }

    async iHTsImFileComplete(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSIMFileComplete/${apptID}/${clpuserID}/${clpCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "iHTsImFileComplete"); });
        return a;
    }

    async iHTsImFileNotComplete(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSIMFileNotComplete/${apptID}/${clpuserID}/${clpCompanyID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "iHTsImFileNotComplete"); });
        return a;
    }

    async iHTsImDead(encryptedUser: string, apptID: number, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSIMDead/${apptID}/${clpCompanyID}/${clpuserID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID, encryptedUser, "AppointmentSettingService", "iHTsImDead"); });
        return a;
    }

    async iHTsContractSignedPaymentNotReceived(encryptedUser: string, apptID: number, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTSContractSignedPaymentNotReceived/${apptID}/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "iHTsContractSignedPaymentNotReceived"); });
        return a;
    }

    async iHTmMarkAsDead(encryptedUser: string, clpUserId: number, clpCompanyId: number): Promise<ddIHReasonResponse | void> {
        const a = await this.httpClient
            .get<ddIHReasonResponse>(`${this.baseUrl}/IHTMMarkAsDead/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "AppointmentSettingService", "iHTmMarkAsDead"); });
        return a;
    }

    async iHQYes(encryptedUser: string, apptID: number, iHQInstructions: string, leadId: number, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHQYes/${apptID}/${iHQInstructions}/${leadId}/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "iHQInstructions - " + iHQInstructions + " , " + "leadId - " + leadId, encryptedUser, "AppointmentSettingService", "iHQYes"); });
        return a;
    }

    async iHQNo(encryptedUser: string, iHQInstructions: string, clpUserId: number, clpCompanyId: number): Promise<ddIHReasonResponse | void> {
        const a = await this.httpClient
            .get<ddIHReasonResponse>(`${this.baseUrl}/IHQNo/${iHQInstructions}/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "iHQInstructions - " + iHQInstructions, encryptedUser, "AppointmentSettingService", "iHQNo"); });
        return a;
    }

    async iHReason(encryptedUser: string, apptID: number, clpUserId: number, clpCompanyId: number, ihReasonObj: IHReason): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/IHReason/${apptID}/${clpUserId}/${clpCompanyId}`, ihReasonObj, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, ihReasonObj, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpUserId - " + clpUserId, encryptedUser, "AppointmentSettingService", "iHReason"); });
        return a;
    }

    async iHQContinueToSchInterim(encryptedUser: string, apptID: number, iHQContinueToSchInterim: string, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHQContinueToSchInterim/${apptID}/${iHQContinueToSchInterim}/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "iHQContinueToSchInterim - " + iHQContinueToSchInterim, encryptedUser, "AppointmentSettingService", "iHQContinueToSchInterim"); });
        return a;
    }

    async iHFuDidNotAnswer(encryptedUser: string, apptID: number, clpUserId: number, selectedUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHFUDidNotAnswer/${apptID}/${clpUserId}/${clpCompanyId}/${selectedUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpUserId - " + clpUserId + " , " + "selectedUserId - " + selectedUserId, encryptedUser, "AppointmentSettingService", "iHFuDidNotAnswer"); });
        return a;
    }

    async iHFuBipBeBack(encryptedUser: string, apptID: number, clpCompanyId: number, clpUserId: number, iHFUBipBeBackTxt: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHFUBIPBeBack/${apptID}/${clpCompanyId}/${clpUserId}/${iHFUBipBeBackTxt}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId + " , " + "iHFUBipBeBackTxt - " + iHFUBipBeBackTxt, encryptedUser, "AppointmentSettingService", "iHFuBipBeBack"); });
        return a;
    }

    async iHFuSpec(encryptedUser: string, apptID: number, clpCompanyId: number, clpUserId: number, iHFUSpecTxt: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHFUSpec/${apptID}/${clpCompanyId}/${clpUserId}/${iHFUSpecTxt}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId + " , " + "iHFUSpecTxt - " + iHFUSpecTxt, encryptedUser, "AppointmentSettingService", "iHFuSpec"); });
        return a;
    }

    async iHFUDead(encryptedUser: string, apptID: number, clpCompanyId: number, clpUserId: number, iHFUDeadTxt: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHFUDead/${apptID}/${clpCompanyId}/${clpUserId}/${iHFUDeadTxt}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId + " , " + "iHFUDeadTxt - " + iHFUDeadTxt, encryptedUser, "AppointmentSettingService", "iHFUDead"); });
        return a;
    }

    async iHSchContract(encryptedUser: string, apptID: number, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHSchContract/${apptID}/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "iHSchContract"); });
        return a;
    }

    async iHIMNoShow(encryptedUser: string, apptID: number, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHIMNoShow/${apptID}/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "iHIMNoShow"); });
        return a;
    }

    async iHIMCompleted(encryptedUser: string, apptID: number, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHIMCompleted/${apptID}/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "iHIMCompleted"); });
        return a;
    }

    async iHIMContract(encryptedUser: string, apptID: number, clpCompanyId: number, clpUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHIMContract/${apptID}/${clpCompanyId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId, encryptedUser, "AppointmentSettingService", "iHIMContract"); });
        return a;
    }

    async iHSubLeadDidNotAnswer(encryptedUser: string, apptID: number, clpUserId: number, clpCompanyId: number, selectedUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHSUBLeadDidNotAnswer/${apptID}/${clpUserId}/${clpCompanyId}/${selectedUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpUserId - " + clpUserId + " , " + "selectedUserId - " + selectedUserId, encryptedUser, "AppointmentSettingService", "iHSubLeadDidNotAnswer"); });
        return a;
    }

    async iHTmDidNotAnswer(encryptedUser: string, apptID: number, clpCompanyId: number, clpUserId: number, selectedUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTMDidNotAnswer/${apptID}/${clpCompanyId}/${clpUserId}/${selectedUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId + " , " + "selectedUserId - " + selectedUserId, encryptedUser, "AppointmentSettingService", "iHTmDidNotAnswer"); });
        return a;
    }

    async iHTmLeftVoiceMail(encryptedUser: string, apptID: number, clpCompanyId: number, clpUserId: number, selectedUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTMLeftVoiceMail/${apptID}/${clpCompanyId}/${clpUserId}/${selectedUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId + " , " + "selectedUserId - " + selectedUserId, encryptedUser, "AppointmentSettingService", "iHTmLeftVoiceMail"); });
        return a;
    }

    async iHTmScheduleBIP(encryptedUser: string, apptID: number, clpCompanyId: number, clpUserId: number, IHETID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTMScheduleBIP/${apptID}/${clpCompanyId}/${clpUserId}/${IHETID}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId + " , " + "IHETID - " + IHETID, encryptedUser, "AppointmentSettingService", "iHTmScheduleBIP"); });
        return a;
    }

    async iHTmBipNoContract(encryptedUser: string, apptID: number, clpCompanyId: number, clpUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTMBIPNoContract/${apptID}/${clpCompanyId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId, encryptedUser, "AppointmentSettingService", "iHTmBipNoContract"); });
        return a;
    }

    async iHTmBipNoShow(encryptedUser: string, apptID: number, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTMBIPNoShow/${apptID}/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "iHTmBipNoShow"); });
        return a;
    }

    async iHTmImNoShow(encryptedUser: string, apptID: number, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTMIMNoShow/${apptID}/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "iHTmImNoShow"); });
        return a;
    }

    async iHTmImCompleted(encryptedUser: string, apptID: number, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHTMIMCompleted/${apptID}/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID, encryptedUser, "AppointmentSettingService", "iHTmImCompleted"); });
        return a;
    }

    async iHSubLeadSchedule(encryptedUser: string, apptID: number, iHSubLeadSchedule: string, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHSUBLeadSchedule/${apptID}/${iHSubLeadSchedule}/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "iHSubLeadSchedule - " + iHSubLeadSchedule, encryptedUser, "AppointmentSettingService", "iHSubLeadSchedule"); });
        return a;
    }

    async iHSchSave(encryptedUser: string, apptID: number, clpUserId: number, clpCompanyId: number, ihSchSaveObj: IHSchSaveBody): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/IHSchSave/${apptID}/${clpUserId}/${clpCompanyId}`, ihSchSaveObj, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, ihSchSaveObj, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpUserId - " + clpUserId, encryptedUser, "AppointmentSettingService", "iHSchSave"); });
        return a;
    }

    async iHImSave(encryptedUser: string, apptID: number, clpUserId: number, clpCompanyId: number, ihIMSaveObj: IHIMSaveBody): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/IHIMSave/${apptID}/${clpUserId}/${clpCompanyId}`, ihIMSaveObj, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, ihIMSaveObj, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpUserId - " + clpUserId, encryptedUser, "AppointmentSettingService", "iHImSave"); });
        return a;
    }

    async iXDidNotAnswer(encryptedUser: string, apptID: number, clpCompanyId: number, clpUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IXDidNotAnswer/${apptID}/${clpCompanyId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId, encryptedUser, "AppointmentSettingService", "iXDidNotAnswer"); });
        return a;
    }

    async iXLeftVoicemail(encryptedUser: string, apptID: number, clpCompanyId: number, clpUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IXLeftVoicemail/${apptID}/${clpCompanyId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId, encryptedUser, "AppointmentSettingService", "iXLeftVoicemail"); });
        return a;
    }

    async iXPurchase(encryptedUser: string, apptID: number, clpCompanyId: number, clpUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IXPurchase/${apptID}/${clpCompanyId}/${clpUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId, encryptedUser, "AppointmentSettingService", "iXPurchase"); });
        return a;
    }

    async iXDnaDead(encryptedUser: string, clpUserId: number, clpCompanyId: number): Promise<ddIXReasonResponse | void> {
        const a = await this.httpClient
            .get<ddIXReasonResponse>(`${this.baseUrl}/IXDNADEAD/${clpUserId}/${clpCompanyId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser, encryptedUser, "AppointmentSettingService", "iXDnaDead"); });
        return a;
    }

    async iXDnaDeadConfirm(encryptedUser: string, apptID: number, ddIXReasonValue: string, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/iXDnaDeadConfirm/${apptID}/${clpCompanyId}/${clpUserId}/${ddIXReasonValue}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "ddIXReasonValue - " + ddIXReasonValue, encryptedUser, "AppointmentSettingService", "iXDnaDeadConfirm"); });
        return a;
    }

    async saveIxAppt(encryptedUser: string, apptID: number, clpCompanyId: number, clpUserId: number, dtIXDate: string, ddIXTime: string, dtIHBIPStart: string, ddIHBIPTime: string, isIHOverRight: boolean, cbIXSchTeleSales: boolean, txtOtherEmails: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IXSaveAppt/${apptID}/${clpCompanyId}/${clpUserId}?dtIXDate=${dtIXDate}&ddIXTime=${ddIXTime}&dtIHBIPStart=${dtIHBIPStart}&ddIHBIPTime=${ddIHBIPTime}&isIHOverRight=${isIHOverRight}&cbIXSchTeleSales=${cbIXSchTeleSales}&txtOtherEmails=${txtOtherEmails}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpCompanyId - " + clpCompanyId + " , " + "clpUserId - " + clpUserId + " , " + "dtIXDate - " + dtIXDate + " , " + "ddIXTime - " + ddIXTime + " , " + "dtIHBIPStart - " + dtIHBIPStart + " , " + "ddIHBIPTime - " + ddIHBIPTime + " , " + "isIHOverRight - " + isIHOverRight + " , " + "cbIXSchTeleSales - " + cbIXSchTeleSales + " , " + "txtOtherEmails - " + txtOtherEmails, encryptedUser, "AppointmentSettingService", "saveIxAppt"); });
        return a;
    }

    async saveBipIH(encryptedUser: string, apptID: number, strIHBIPStartDate: string, strIHBIPTime: string, IHBIPSchDuration: number, clpUserId: number, clpCompanyId: number, isIHOverRight: boolean, chIHBIPReminder24Prior: boolean, cbIHBIPTextReminder24Prior: boolean, isUserTxtMsg: boolean, chIHBIPReminderImmediate: boolean, isTxtMsgActive: boolean, txtOtherEmails: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHBIPSave/${apptID}/${strIHBIPStartDate}/${strIHBIPTime}/${IHBIPSchDuration}/${clpUserId}/${clpCompanyId}/${isIHOverRight}/${chIHBIPReminder24Prior}/${cbIHBIPTextReminder24Prior}/${isUserTxtMsg}/${chIHBIPReminderImmediate}/${isTxtMsgActive}?txtOtherEmails=${txtOtherEmails}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "strIHBIPStartDate - " + strIHBIPStartDate + " , " + "strIHBIPTime - " + strIHBIPTime + " , " + "IHBIPSchDuration - " + IHBIPSchDuration + " , " + "clpUserId - " + clpUserId + " , " + "isIHOverRight - " + isIHOverRight + " , " + "chIHBIPReminder24Prior - " + chIHBIPReminder24Prior + " , " + "cbIHBIPTextReminder24Prior - " + cbIHBIPTextReminder24Prior + " , " + "isUserTxtMsg - " + isUserTxtMsg + " , " + "chIHBIPReminderImmediate - " + chIHBIPReminderImmediate + " , " + "isTxtMsgActive - " + isTxtMsgActive + " , " + "txtOtherEmails - " + txtOtherEmails, encryptedUser, "AppointmentSettingService", "saveBipIH"); });
        return a;
    }



    async IHBIPSchedule(encryptedUser: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/IHBIPSchedule`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + encryptedUser, "AppointmentSettingService", "IHBIPSchedule"); });
        return a;
    }

    async loadSchedular(encryptedUser: string, clpuserID: number, clpCompanyID: number, selectedDate: string): Promise<SchedularListResponse> {
        const a = await this.httpClient
            .get<SchedularListResponse>(`${this.baseUrl}/LoadSchedular/${clpuserID}/${clpCompanyID}/${selectedDate}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpuserID - " + clpuserID + " , " + "clpCompanyID - " + clpCompanyID + " , " + "selectedDate - " + selectedDate, encryptedUser, "AppointmentSettingService", "loadSchedular"); });
        return a;
    }

    async schedularUpdate(encryptedUser: string, schedular: SchedularRequest, clpuserID: number, clpCompanyID: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/Schedular_Update/${clpuserID}/${clpCompanyID}`, schedular, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, schedular, "r - " + encryptedUser, encryptedUser, "AppointmentSettingService", "schedularUpdate"); });
        return a;
    }

    async ApptCountGetByClpUserId(encryptedUser: string, clpCompanyId: number, startDate: string, endDate: string, clpUserId: number = 0, ownerId: number = 0, appCategory: eApptCategory = eApptCategory.None, appStatus: eApptStatus = eApptStatus.Pending): Promise<SimpleResponse> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/ApptByClpUserId_GetCount/${clpCompanyId}?startDate=${startDate}&endDate=${endDate}&clpUserId=${clpUserId}&ownerId=${ownerId}&appCategory=${appCategory}&appStatus=${appStatus}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "startDate - " + startDate + " , " + "endDate - " + endDate + " , " + "clpUserId - " + clpUserId + " , " + "ownerId - " + ownerId + " , " + "appCategory - " + appCategory + " , " + "appStatus - " + appStatus, encryptedUser, "AppointmentSettingService", "ApptCountGetByClpUserId"); });
        return a;
    }


    async ApptListGetByClpUserId(encryptedUser: string, clpCompanyId: number, startDate: string, endDate: string, clpUserId: number = 0, ownerId: number = 0, appCategory: eApptCategory = eApptCategory.None, rowCount: number = 5, appStatus: eApptStatus = eApptStatus.Pending): Promise<ApptListResponse> {
        const a = await this.httpClient
            .get<ApptListResponse>(`${this.baseUrl}/ApptByClpUserId_GetList/${clpCompanyId}?startDate=${startDate}&endDate=${endDate}&clpUserId=${clpUserId}&ownerId=${ownerId}&appCategory=${appCategory}&rowCount=${rowCount}&appStatus=${appStatus}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + " , " + "clpCompanyId - " + clpCompanyId + " , " + "startDate - " + startDate + " , " + "endDate - " + endDate + " , " + "clpUserId - " + clpUserId + " , " + "ownerId - " + ownerId + " , " + "appCategory - " + appCategory + " , " + "rowCount - " + rowCount + " , " + "appStatus - " + appStatus, encryptedUser, "AppointmentSettingService", "ApptListGetByClpUserId"); });
        return a;
    }

    async downloadICalender(encryptedUser: string, apptID: number, clpUserID: number, clpCompanyId: number): Promise<any | void> {
        const http$ = await this.httpClient
            .get<any>(`${this.baseUrl}/downloadICalender/${apptID}/${clpUserID}/${clpCompanyId}`, {
                responseType: 'blob' as 'json',
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "r - " + encryptedUser + " , " + "apptID - " + apptID + " , " + "clpUserID - " + clpUserID + "downloadICalender") });
        return http$;
    }

    async scheduleCreate(encryptedUser: string, schedular: ScheduleAppointmentRequest, clpUserId: number, clpCompanyId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/Schedular_Update/${clpUserId}/${clpCompanyId}`, schedular, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, schedular, "r - " + encryptedUser, encryptedUser, "MyCalenderService", "ScheduleCreate"); });
        return a;
    }

    async apptQualClickDNC(encryptedUser: string, apptId: number, userId: number, selectedUserId : number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/ApptQualDNC/${apptId}/${userId}/${selectedUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "r - " + encryptedUser, encryptedUser, "MyCalenderService", "QualDNC"); });
        return a;
    }
    
    async apptQualLeftMessage(encryptedUser: string, apptId: number, userId: number, selectedUserId: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .post<SimpleResponse>(`${this.baseUrl}/ApptQualLeftMessage/${apptId}/${userId}/${selectedUserId}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "r - " + encryptedUser, encryptedUser, "MyCalenderService", "QualDNC"); });
        return a;
    }

    async apptGetDDFielde(encryptedUser: string, efield: number, code: number): Promise<DDFieldResponse | void> {
        const a = await this.httpClient
            .get<DDFieldResponse>(`${this.baseUrl}/ApptGetDDFielde/${efield}/${code}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "r - " + encryptedUser, encryptedUser, "MyCalenderService", "QualDNC"); });
        return a;
    }

    async saveIHCallListNote(encryptedUser: string, contactID: number, callListNote: number): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<DDFieldResponse>(`${this.baseUrl}/SaveIHCallListNote/${contactID}/${callListNote}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "r - " + encryptedUser, encryptedUser, "MyCalenderService", "QualDNC"); });
        return a;
    }
}
