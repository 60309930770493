<div class="custom-security-link" *ngIf="companyId!=0">
    <a class="custom-security-link" (click)="addNewContactForCompany()">New Contact</a>
    <a (click)="goToApptList()">Appt List</a>
    <a (click)="urlChange()">View History </a>
</div>
<div class="row-panel company-create-panel">
    <div class="col-left-panel">
        <div class="margin-all-area">
            <div class="wraper-main-section">
                <div class="global-card-section">
                    <div class="global-header-section">
                        <div class="svg-icon-panel">
                            <img src="../../../../assets/companytitle_grid.svg" />
                            <span class="contact-create-span">Company<span *ngIf="companyId==0">Information</span></span>
                            <span class="contact-create-span pl-0" *ngIf="companyForm?.get('companyName')?.value">{{ companyFields?.companyName?.fieldValue ? ('- '+ companyFields?.companyName?.fieldValue  | titlecase) : ''}}  </span>
                        </div>
                        <div class="header-button-panel">
                            <div class="button-wrapper">
                                <button [hidden]="roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator" *ngIf="isEditMode && companyId>0" type="button" matTooltip="Edit" class="icon-btn" (click)="editCompanyForm()">
                                    <i class="fa fa-pencil-alt"></i>
                                    <span class="btn-text">Edit</span>
                                </button>
                                <button [style.pointer-events]="showSpinner?'none':''" [disabled]="showSpinner" *ngIf="!isEditMode " type="button" [hidden]="isNew ? (roleFeaturePermissions?.create == false && user?.userRole <= eUserRole.Administrator) : (roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator)" matTooltip="Save Company" class="icon-btn" (click)="saveCompanyById()">
                                    <i class="fa fa-save" aria-hidden="true"></i>
                                    <span class="btn-text">Save</span>
                                </button>
                                <button class="grid-delete-btn" *ngIf="companyId>0" [hidden]="roleFeaturePermissions?.delete == false && user?.userRole <= eUserRole.Administrator" type="button" matTooltip="Delete Company" data-toggle="modal" data-target="#companyDeleteModal">
                                    <img src="../../../../assets/trash-btn.svg" />
                                    <span class="btn-text">Delete</span>
                                </button>
                                <button *ngIf="isEditMode " type="button" matTooltip="Back To Company List" class="icon-btn" (click)="showCompanyList()">
                                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                    <span class="btn-text">Back</span>
                                </button>
                                <button *ngIf="!isEditMode" type="button" matTooltip="Cancel Company" class="icon-btn" (click)="companyId<=0?gotoCompany():cancelCompanyCreate()">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                    <span class="btn-text">Cancel</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="global-body-section">
                        <p class="message-info" *ngIf="companyId==0 && companyFormStatus==''">Please enter Company information and click Save.</p>
                        <p class="message-info" *ngIf="isTransferConfirm" [innerHTML]="transferWaringMsg"></p>
                        <p class="message-info" *ngIf=" companyId!=0 && companyFormStatus=='' && !isEditMode">Please edit Company information and click Save.</p>
                        <p class="message-info" *ngIf="companyFormStatus=='cancel'">No changes were made.</p>
                        <p class="message-info" *ngIf="companyFormStatus=='saved'">New company has been created.</p>
                        <form [formGroup]="companyForm" (ngSubmit)="companyFormSubmit()">
                            <div class="cards-height-section">
                                <div *ngIf="companyFields">
                                    <div class="">
                                        <div *ngIf="companyList?.length > 0">
                                            <div class="company-2d-alert">
                                                Similar companies were found. Please check to make sure you are not creating a duplicate company entry.
                                            </div>
                                        </div>
                                        <div class="" [hidden]="companyFields.companyName?.isShow == 2">
                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left">
                                                    <div>
                                                        <span>{{companyFields.companyName.fieldTitle}}</span><span class="red-contact-span">*</span>
                                                    </div>
                                                </div>
                                                <div class="cards-colunm-right">
                                                    <div class="full-width-container">
                                                        <span *ngIf="isEditMode">{{companyFields.companyName.fieldValue}}</span>
                                                        <input type="text" tabindex="1" (input)="OnChangeCompanyName($event.target.value)" class="form-control" *ngIf="!isEditMode" formControlName="companyName" [ngClass]="{'has-error': (companyForm.get('companyName')?.errors && (companyForm.get('companyName')?.touched || companyForm.get('companyName')?.dirty))}" required />
                                                        <div *ngIf="companyForm.controls?.companyName?.errors &&(companyForm.controls?.companyName?.touched || companyForm.controls?.companyName?.dirty)">
                                                            <div class="search-query-error acc-info-srch-error" *ngIf="companyForm.controls?.companyName?.errors?.required">
                                                                Please enter Company Name
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="global-body-section show" *ngIf="showCompanyGrid && companyFormStatus !='saved'" id="companyList">
                                    <div class="margin-all-area">
                                        <div class="tag-management-panel">
                                            <div class="wraper-main-section">
                                                <div class="global-card-section">
                                                    <div class="global-header-section">
                                                        <div class="svg-icon-panel"><img src="../../../../assets/companystitleheader.svg" class="mr-1" />Companies Search Results</div>
                                                        <div class="header-button-panel">
                                                            <div class="button-wrapper">
                                                                <button type="button" matTooltip="Add" class="icon-btn" (click)="showCompanyGrid = false;companyList=[];companyForm.reset()">
                                                                    <i class="fa fa-plus" aria-hidden="true"> </i>
                                                                    <span class="btn-text">Add</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="global-body-section">
                                                        <app-company-grid *ngIf="user" [user]="user" [companyList]="companyList" [isCompanyCreate]="true"></app-company-grid>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cards-height-panel">
                                    <div id="list">
                                        <div class="common-detail-section">
                                            <div class="multiple-cards-section">
                                                <div class="multiple-cards-panel" *ngFor="let section of arrSortedBySection; let is = index;" [hidden]="section.items.length == 0 || isShowFields(is)">
                                                    <div class="wraper-main-section">
                                                        <div class="global-card-section">
                                                            <div class="global-header-section">
                                                                <div class="svg-icon-panel">
                                                                    <span>{{ section.sectionName }}</span>
                                                                </div>
                                                                <div class="header-button-panel" *ngIf="isEditMode && section?.sectionId == 1">
                                                                    <div class="button-wrapper">
                                                                        <a title="Get Map" class="icon-btn" [href]="companyFieldsResponse?.mapURL" [target]="_blank">
                                                                            <img src="../../../../assets/mapbtn.svg" />
                                                                            <span class="btn-text">Map</span>
                                                                        </a>
                                                                        <a class="icon-btn" [href]="companyFieldsResponse?.directionsURL" [target]="_blank" title="Get Direction">
                                                                            <img src="../../../../assets/drivebtn.svg" />
                                                                            <span class="btn-text">Direction</span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="global-body-section">
                                                                <div class="">
                                                                    <div class="" *ngFor="let item of section.items; let i = index">
                                                                        <div class="form-group" *ngIf="item.fieldType == 0" [hidden]="item.isShow == 2" [ngClass]="{'has-asterisk': item.isShow == 1 && !isEditMode}">
                                                                            <label>{{item.fieldTitle}} :</label>
                                                                            <span *ngIf="isEditMode && item.fieldTitle != 'WebSite'"> {{item.fieldValue | phoneFormat}}</span>
                                                                            <span *ngIf="isEditMode && item.fieldTitle != 'WebSite' && item.fieldTitle != 'Email2' && item.fieldTitle != 'Email3' && item.fieldTitle != 'Phone' && item.fieldTitle != 'HomePhone' && item.fieldTitle != 'AltPhone' && item.fieldTitle != 'Zip' && item.fieldTitle != 'Fax' && item.fieldTitle != 'OtherFax'"> {{item.fieldValue}}</span>
                                                                            <a (click)="goToLink(item.fieldValue)" *ngIf="isEditMode && item.fieldTitle == 'WebSite'"><span>{{item.fieldValue}}</span></a>
                                                                            <input type="text" class="form-control" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)" maxlength="14" *ngIf="!isEditMode && item.fieldTitle == 'Phone'" formControlName="{{ item.fieldName }}"
                                                                                   [placeholder]="placeHolder" [mask]="mobile_mask" [ngClass]="{'has-error': (companyForm.get(item.fieldName)?.errors && (companyForm.get(item.fieldName)?.touched || companyForm.get(item.fieldName)?.dirty))}" />
                                                                            <input type="text" class="form-control" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)" maxlength="14" *ngIf="!isEditMode && item.fieldTitle == 'HomePhone'" formControlName="{{ item.fieldName }}"
                                                                                   [placeholder]="placeHolder" [mask]="mobile_mask" [ngClass]="{'has-error': (companyForm.get(item.fieldName)?.errors && (companyForm.get(item.fieldName)?.touched || companyForm.get(item.fieldName)?.dirty))}" />
                                                                            <input type="text" class="form-control" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)" maxlength="14" *ngIf="!isEditMode && item.fieldTitle == 'AltPhone'" formControlName="{{ item.fieldName }}"
                                                                                   [placeholder]="placeHolder" [mask]="mobile_mask" [ngClass]="{'has-error': (companyForm.get(item.fieldName)?.errors && (companyForm.get(item.fieldName)?.touched || companyForm.get(item.fieldName)?.dirty))}" />

                                                                            <input type="text" class="form-control" *ngIf="!isEditMode && item.fieldTitle == 'Zip'" placeholder="Zip" minlength="3" maxlength="9" (blur)="getCityState($event);" formControlName="{{ item.fieldName }}" (keypress)="_localService.onKeyDown($event)"
                                                                                   [ngClass]="{'has-error': (companyForm.get(item.fieldName)?.errors && (companyForm.get(item.fieldName)?.touched || companyForm.get(item.fieldName)?.dirty))}" />

                                                                            <input type="text" class="form-control" *ngIf="!isEditMode && item.fieldTitle != 'Email2' && item.fieldTitle != 'Email3' && item.fieldTitle != 'Phone' && item.fieldTitle != 'HomePhone' && item.fieldTitle != 'AltPhone' && item.fieldTitle != 'Zip' && item.fieldTitle != 'Fax' && item.fieldTitle != 'OtherFax'"
                                                                                   formControlName="{{ item.fieldName }}" [ngClass]="{'has-error': (companyForm.get(item.fieldName)?.errors && (companyForm.get(item.fieldName)?.touched || companyForm.get(item.fieldName)?.dirty))}" />

                                                                            <input type="text" class="form-control" [mask]="mobile_mask" (keypress)="_localService.onKeyDown($event)" *ngIf="!isEditMode && item.fieldTitle == 'Fax'" 
                                                                                   formControlName="{{ item.fieldName }}" [ngClass]="{'has-error': (companyForm.get(item.fieldName)?.errors && (companyForm.get(item.fieldName)?.touched || companyForm.get(item.fieldName)?.dirty))}" />

                                                                            <input type="text" class="form-control" (keypress)="_localService.onKeyDown($event)" *ngIf="!isEditMode && item.fieldTitle == 'OtherFax'" 
                                                                                   formControlName="{{ item.fieldName }}" [ngClass]="{'has-error': (companyForm.get(item.fieldName)?.errors && (companyForm.get(item.fieldName)?.touched || companyForm.get(item.fieldName)?.dirty))}" />
                                                                        </div>
                                                                        <!--<div class="form-group" *ngIf="item.fieldType == 2 && item.fieldTitle != 'CompanyName'" [hidden]="item.isShow == 2" [ngClass]="{'has-asterisk': item.isShow == 1 && !isEditMode}">
                                                                          <label>{{item.fieldTitle}} :</label>
                                                                          <span *ngIf="isEditMode">&nbsp;{{getClassificationDDValue(item.fieldValue,item.fieldName)}}</span>
                                                                          <select class="form-control" *ngIf="!isEditMode && item.fieldTitle != 'Country'" formControlName="{{ item.fieldName }}" [ngClass]="{'has-error': ((item.isShow==1 || companyForm.get(item.fieldName)?.errors) && (companyForm.get(item.fieldName)?.touched || companyForm.get(item.fieldName)?.dirty))}">
                                                                            <option value=0>-None Selected-</option>
                                                                            <option *ngFor="let blastItem of item.items; let i = index" [value]='blastItem.value'>
                                                                              {{blastItem.display}}2
                                                                            </option>
                                                                          </select>
                                                                          <select class="form-control" *ngIf="!isEditMode && item.fieldTitle == 'Country'" formControlName="{{ item.fieldName }}" [ngClass]="{'has-error': ((item.isShow==1 || companyForm.get(item.fieldName)?.errors) && (companyForm.get(item.fieldName)?.touched || companyForm.get(item.fieldName)?.dirty))}">
                                                                            <option value=0>-None Selected-</option>
                                                                            <option *ngFor="let blastItem of item.items; let i = index" [value]='blastItem.strValue'>
                                                                              {{blastItem.display}}1
                                                                            </option>
                                                                          </select>
                                                                        </div>-->
                                                                        <div class="form-group" *ngIf="item.fieldType == 2 && item.fieldTitle != 'CompanyName'" [hidden]="item.isShow == 2" [ngClass]="{'has-asterisk': item.isShow == 1 && !isEditMode}">
                                                                            <label>{{item.fieldTitle}} :</label>
                                                                            <span *ngIf="isEditMode">&nbsp;{{getClassificationDDValue(item.fieldValue, item.fieldName)}}</span>
                                                                            <select class="form-control" *ngIf="!isEditMode && item.fieldTitle != 'Country'" formControlName="{{ item.fieldName }}"
                                                                                    [ngClass]="{'has-error': companyForm.get(item.fieldName)?.invalid && (companyForm.get(item.fieldName)?.touched || companyForm.get(item.fieldName)?.dirty)}">
                                                                                <option value="0">-None Selected-</option>
                                                                                <option *ngFor="let blastItem of item.items; let i = index" [value]="blastItem.value">
                                                                                    {{blastItem.display}}
                                                                                </option>
                                                                            </select>
                                                                            <select class="form-control" *ngIf="!isEditMode && item.fieldTitle == 'Country'" formControlName="{{ item.fieldName }}"
                                                                                    [ngClass]="{'has-error': companyForm.get(item.fieldName)?.invalid && (companyForm.get(item.fieldName)?.touched || companyForm.get(item.fieldName)?.dirty)}">
                                                                                <option value="0">-None Selected-</option>
                                                                                <option *ngFor="let blastItem of item.items; let i = index" [value]="blastItem.strValue">
                                                                                    {{blastItem.display}}
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                        <div class="form-group" *ngIf="item.fieldType == 4 && item.fieldTitle == 'CompanyName'" [ngClass]="{'has-asterisk': item.isShow == 1 && !isEditMode}">
                                                                            <label>{{item.fieldTitle}} :</label>
                                                                            <span *ngIf="isEditMode">&nbsp;{{item.fieldValue}}</span>
                                                                            <input type="text" class="form-control" formControlName="{{ item.fieldName }}" [ngClass]="{'has-error': (companyForm.get(item.fieldName)?.errors && (companyForm.get(item.fieldName)?.touched || companyForm.get(item.fieldName)?.dirty))}" />
                                                                        </div>
                                                                        <div class="form-group" *ngIf="item.fieldType == 5" [hidden]="item.isShow == 2" [ngClass]="{'has-asterisk': item.isShow == 1 && !isEditMode}">
                                                                            <label>{{item.fieldTitle}} :</label>
                                                                            <span *ngIf="isEditMode">&nbsp;{{item.fieldValue}}</span>
                                                                            <kendo-datetimepicker *ngIf="!isEditMode" formControlName="{{ item.fieldName }}" [format]="format" class="generic-width-per" [ngClass]="{'has-error': (companyForm.get(item.fieldName)?.errors && (companyForm.get(item.fieldName)?.touched || companyForm.get(item.fieldName)?.dirty))}"></kendo-datetimepicker>
                                                                        </div>
                                                                        <div class="custom-toggle">
                                                                            <div class="form-group" *ngIf="item.fieldType == 1" [hidden]="item.isShow == 2" [ngClass]="{'has-asterisk': item.isShow == 1 && !isEditMode}">
                                                                                <label>{{item.fieldTitle}}</label>
                                                                                <span *ngIf="isEditMode" [ngClass]="item.fieldValue ? '' : 'text-danger'">{{item.fieldValue==true? 'Yes' : 'No'}}</span>
                                                                                <label class="switch" *ngIf="!isEditMode">
                                                                                    <input type="checkbox" [id]="item.fieldName" [name]="item.fieldName" formControlName="{{ item.fieldName }}" (change)="onCheckboxChange($event, item.fieldName)" [ngClass]="{'has-error': (companyForm.get(item.fieldName)?.errors && (companyForm.get(item.fieldName)?.touched || companyForm.get(item.fieldName)?.dirty))}" />
                                                                                    <span class="slider round"></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-group" *ngIf="item.fieldType == 3" [hidden]="item.isShow == 2 && item.fieldTitle != 'dtModified' && item.fieldTitle != 'dtCreated'" [ngClass]="{'has-asterisk': item.isShow == 1 && !isEditMode}">
                                                                            <label>{{item.fieldTitle == 'dtModified' ? 'Last Modified' : item.fieldTitle == 'dtCreated' ? 'Created' : item.fieldTitle}}</label>
                                                                            <span *ngIf="(item.fieldTitle == 'dtModified' || item.fieldTitle == 'dtCreated') && item.fieldValue != '0001-01-01T00:00:00'">&nbsp;{{item.fieldValue | date : dateFormat}}</span>
                                                                            <span *ngIf="(item.fieldTitle == 'dtModified' || item.fieldTitle == 'dtCreated') && item.fieldValue == '0001-01-01T00:00:00'">&nbsp;</span>
                                                                            <span *ngIf="item.fieldTitle != 'dtModified' && item.fieldTitle != 'dtCreated'">&nbsp;{{item.fieldValue}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="multiple-cards-panel">
                                                    <div class="wraper-main-section">
                                                        <div class="global-card-section">
                                                            <div class="global-header-section">
                                                                <span>System</span>
                                                            </div>
                                                            <div class="global-body-section">
                                                                <div class="">
                                                                    <div class="">
                                                                        <div *ngIf="companyFields">
                                                                            <div class="form-group" [hidden]="companyFields.companyName?.isShow == 2" *ngIf="!isEditMode">
                                                                                <label>User</label>
                                                                                <select class="form-control" formControlName="cLPUserID">
                                                                                    <!--<option value=0 [selected]="companyUserList?.length <= 0">-Select-</option>-->
                                                                                    <option *ngFor="let companyUser of companyUserList" [ngValue]="companyUser.value">{{companyUser.display}}</option>
                                                                                </select>
                                                                                <span style="font-size:7pt; color:green; display:block;">Changing the user will transfer this company.</span>
                                                                            </div>
                                                                            <div class="form-group" [hidden]="companyFields.companyName?.isShow == 2" *ngIf="isEditMode">
                                                                                <label>User :&nbsp;</label>
                                                                                <span><a class="webkit-any-link" title="{{userTooltip}}" (click)="getUserNameCompany(companyForm?.value?.cLPUserID, 'yes');">{{ getUserNameCompany(companyForm?.value?.cLPUserID,'no')}}</a>  </span>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Last Modified:&nbsp;</label>
                                                                                <label *ngIf="!isNew">{{convertToDateTime(companyForm?.controls?.dtCreated?.value)}}</label>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Created:&nbsp;</label>
                                                                                <label *ngIf="!isNew">{{convertToDateTime(companyForm?.controls?.dtModified?.value)}}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="multiple-cards-panel">
                                                    <div class="wraper-main-section">
                                                        <div class="global-card-section">
                                                            <div class="global-header-section">
                                                                <span>Comments</span>
                                                            </div>
                                                            <div class="global-body-section">
                                                                <div class="">
                                                                    <div class="">
                                                                        <div *ngIf="companyFields">
                                                                            <div class="form-group" [hidden]="companyFields.notes?.isShow == 2">
                                                                                <!--issue 28 dec remove NOTE text from comments section-->
                                                                                <!--<label>{{companyFields.notes.fieldTitle}}</label>-->
                                                                                <span *ngIf="isEditMode">{{companyFields.notes.fieldValue}}</span>
                                                                                <textarea type="text" tabindex="1" class="form-control" *ngIf="!isEditMode" formControlName="notes" maxlength="500"></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="multiple-cards-panel" *ngIf="companyId>0">
                                                    <div class="wraper-main-section">
                                                        <div class="global-card-section">
                                                            <div class="global-header-section">
                                                                <span> Tags</span>
                                                                <div class="header-button-panel">
                                                                    <div class="button-wrapper">
                                                                        <div class="svg-icon-panel">
                                                                            <button type="button" class="icon-btn" [title]="getToolTip()" (click)="getTagListByCompany();isShowTagList=true;">
                                                                                <img src="../../../../assets/tagbtn.svg" class="tag-icon" style="margin-left:0px" />
                                                                                <span class="btn-text">Tag</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="global-body-section lead-body-section">
                                                                <div class="">
                                                                    <div class="">
                                                                        <div class="form-group" *ngIf="isShowTagList">
                                                                            <label>Tag</label>
                                                                            <kendo-combobox [data]="tagListByCompany"
                                                                                            textField="tagDisplay"
                                                                                            [(ngModel)]="selectedTagId"
                                                                                            [ngModelOptions]="{standalone: true}"
                                                                                            valueField="tagID"
                                                                                            [allowCustom]="true"
                                                                                            [valuePrimitive]="true"
                                                                                            [suggest]="true"
                                                                                            placeholder="Type a Tag"
                                                                                            (valueChange)="selectedTagChangeEvent($event)">
                                                                                <ng-template kendoComboBoxItemTemplate let-dataItem>
                                                                                    <strong [ngStyle]="{'background-color': (dataItem?.checked) ? '#2DC76D' : ''}">{{dataItem?.tagDisplay}}</strong>
                                                                                </ng-template>
                                                                            </kendo-combobox>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngFor="let item of tagListByOwner">
                                                                        <span>{{item?.tagDisplay}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="bottom-fix-footer">
                                    <div class="top-common-btn">
                                        <button *ngIf="!isEditMode" [style.pointer-events]="showSpinner?'none':''" class="btn btn-primary" [hidden]="isNew ? (roleFeaturePermissions?.create == false && user?.userRole <= eUserRole.Administrator) : (roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator)"
                                                [disabled]="showSpinner" id="primarySaveCompany" type="submit">
                                            <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
                                            <ng-template [ngIf]="buttonTypeOperation===1">Saving </ng-template>
                                            <app-loader></app-loader>
                                        </button>
                                        <button *ngIf="!isEditMode" type="button" class="btn btn-cancel" (click)="companyId<=0?gotoCompany():cancelCompanyCreate()"> Cancel</button>
                                        <button *ngIf="!isEditMode && !isNew" type="button" class="btn btn-danger" style="float:none" data-toggle="modal" data-target="#companyDeleteModal">Delete</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-right-panel" *ngIf="companyId>0">
        <div class="margin-all-area">
            <div class="wraper-main-section">
                <div class="global-card-section">
                    <div class="global-header-section quick-filter-bg">
                        <div class="svg-icon-panel"><span>{{documentList?.length==0?'Upload Documents':' Recent Documents'}}</span></div>
                    </div>
                    <div class="global-body-section">
                        <div class="mt-1">
                            <div class="uploader-panel">
                                <kendo-upload [saveUrl]="uploadSaveUrl"
                                              [restrictions]="uploadRestrictions"
                                              (complete)="changeFileApiResponse()"
                                              (error)="changeFileApiResponse($event)"
                                              [saveHeaders]="fileUploadHeaders"
                                              [autoUpload]="false">
                                </kendo-upload>
                                <span>&nbsp;&nbsp;(jpg,png,eps,jpeg,gif,pdf,txt,wpd,doc,docx,xlsx,csv) Size Limit: 3MB</span>
                            </div>
                            <div class="search-query-error acc-info-srch-error">
                                {{errorMsg}}
                            </div>
                        </div>
                    </div>
                    <div class="document-list" *ngFor="let item of documentList;">
                        <div class="cal-icon">
                            <a (click)="downloadDocuments(item?.documentId)" title="Download Document"> <img src="../../../../assets/iconimagequick.svg"></a>
                        </div>
                        <div class="contact-details">
                            <div class="contact-name"><a class="text-primary" title="Download Document" (click)="downloadDocuments(item?.documentId)"> {{item?.documentName}} </a></div>
                        </div>
                        <div class="cal-icon">
                            <button type="button" class="grid-delete-btn" (click)="deleteDocument(item?.documentId)" data-toggle="modal" data-target="#deleteDocumentModal">
                                <i class="fa fa-trash-alt" title="Delete"></i>
                                <span class="grid-common-text"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="companyId>0" class="margin-all-area">
    <app-search-result-common *ngIf="user && contactListResponse" [user]="user" [SendResultToNewObj]="SendResultToNewObj" [selectedUserId]="user?.cLPUserID" [contactsMapData]="contactListResponse" [parentCompanyFields]="companyFields" [eType]="eType" [createExportFor]="createExportFor" [isCompanyCreate]="true" [isGetContactList]="false"></app-search-result-common>
</div>

<div class="modal fade" id="companyDeleteModal" tabindex="-1" role="dialog" aria-labelledby="companyDeleteModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Delete Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h5>Are you sure to delete Company ?</h5>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="deleteCompany()" data-dismiss="modal" [style.pointer-events]="showSpinner?'none':''" [disabled]="showSpinner" class="btn btn-primary">
                    <ng-template [ngIf]="buttonTypeOperation!=0">Ok</ng-template>
                    <ng-template [ngIf]="buttonTypeOperation===0">Deleting  <span> &nbsp;<i wrapper> </i></span></ng-template>
                </button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="deleteDocumentModal" tabindex="-1" role="dialog" aria-labelledby="deleteDocumentModal" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
            </div>
            <div class="modal-body modal-common-body">
                <h5>Are you sure to delete this Document ? </h5>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="deleteDocumentConfirm()" data-dismiss="modal" [style.pointer-events]="showSpinner?'none':''" [disabled]="showSpinner" class="btn btn-primary">
                    <ng-template [ngIf]="buttonTypeOperation!=0">Confirm</ng-template>
                    <ng-template [ngIf]="buttonTypeOperation===0">Deleting  <span><i class="fa fa-spinner fa-spin "> </i></span></ng-template>
                </button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="underConstruction" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body modal-common-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <p class="under-construction under-construction-common">
                    <img src="../../../assets/under-construction.gif" />
                </p>
            </div>
        </div>
    </div>
</div>
