<div class="custom-security-link">
    <a [routerLink]="['/text-msg-settings']">Voice and Message Settings</a>
</div>
<div class="margin-all-area">
    <div class="wraper-main-section mb-3">
        <div class="global-card-section">
            <form [formGroup]="emailSettingForm" (ngSubmit)="emailSettingFormSubmit()">
                <div class="">
                    <div class="">
                        <div class="global-header-section">
                            <div class="svg-icon-panel"><img src="../../../../assets/emailtitle.svg" class="mr-1" /> Account Level Email Configuration and Compliance Settings</div>
                        </div>
                        <div class="message-info" *ngIf="messageInfo">
                            <span [innerHTML]="messageInfo | safeHtml"></span>
                        </div>

                        <div class="global-body-section">
                            <div class="flex-row-inner">
                                <div class="wraper-body-left">
                                    <div class="custom-action-title">
                                        <span>Engagement: The number of months with no email engagement (opens) before a contact gets an Email Engagement Status of Unengaged.</span>
                                    </div>
                                    <div class="excel-main-panel">
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div class="align-center">
                                                    <span class="control-label">UnEngaged Months</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <span *ngIf="!editShow && emailSettingData?.unengageMonths > 0">
                                                    After {{emailSettingData?.unengageMonths}} month(s) with no engagement (opens, clicks), <b>Email Engagement Status</b> will be set to <span style='color:red'>UnEngaged</span>.
                                                </span>
                                                <span *ngIf="!editShow && emailSettingData?.unengageMonths == 0" style='color:red'>-Not Set- </span>
                                                <select class="custom-select txtStandard" *ngIf="editShow" formControlName="unengageMonths">
                                                    <option *ngFor="let item of ddUnengageMonths" [value]="item.value">{{item.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-row-inner">
                                <div class="wraper-body-left">
                                    <div class="custom-action-title">
                                        <span>New Contact Default Subscribe/Unsubscribe Status</span>
                                    </div>
                                    <div class="excel-main-panel">
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div class="align-center">
                                                    <span class="control-label">Informational Email</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <span *ngIf="!editShow">
                                                    The default <b>informational</b> email subscription status for new emails is <b>{{emailSettingForm.controls.defaultInfoSubStatus.value ==1 ?'Subscribed':'Unsubscribed' }}</b>
                                                </span>
                                                <select class="custom-select txtStandard" *ngIf="editShow" formControlName="defaultInfoSubStatus">
                                                    <option value=1>Subscribed</option>
                                                    <option value=3>Unsubscribed</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="excel-main-panel">
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div class="align-center">
                                                    <span class="control-label">Promotional Email</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <span *ngIf="!editShow">
                                                    The default <b>promotional</b> email subscription status for new emails is <b>{{emailSettingForm.controls.defaultPromoSubStatus.value ==1 ?'Subscribed':'Unsubscribed' }}</b>
                                                </span>
                                                <select class="custom-select txtStandard" *ngIf="editShow" formControlName="defaultPromoSubStatus">
                                                    <option value=1>Subscribed</option>
                                                    <option value=3>Unsubscribed</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="excel-main-panel">
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div class="align-center">
                                                    <span class="control-label">Allow User Subscribe Option</span>
                                                    <br /><span style="font-size:10px; color: darkgreen;">Informational Messages</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <span *ngIf="!editShow && emailSettingData?.isAllowVerbalInfoSubscribeOption">
                                                    User Subscribe Option for <i>Informational</i> messages is <span style='color:darkgreen'><b>ENABLED</b></span>.
                                                </span>
                                                <span *ngIf="!editShow && !emailSettingData?.isAllowVerbalInfoSubscribeOption">
                                                    User Subscribe Option for <i>Informational</i> messages is <span style='color:red'><b>DISABLED</b></span>.
                                                </span>
                                                <input type="checkbox" class="checkbox" id="isAllowVerbalInfoSubscribeOption" *ngIf="editShow" formControlName="isAllowVerbalInfoSubscribeOption" />

                                            </div>
                                        </div>
                                    </div>

                                    <div class="excel-main-panel">
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div class="align-center">
                                                    <span class="control-label">Allow User Subscribe Option</span>
                                                    <br /><span style="font-size:10px; color: darkgreen;">Promotional Messages </span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <span *ngIf="!editShow && emailSettingData.isAllowVerbalPromoSubscribeOption">
                                                    User Subscribe Option for <i>Promotional</i> messages is <span style='color:darkgreen'><b>ENABLED</b></span>.
                                                </span>
                                                <span *ngIf="!editShow && !emailSettingData.isAllowVerbalPromoSubscribeOption">
                                                    User Subscribe Option for <i>Promotional</i> messages is <span style='color:red'><b>DISABLED</b></span>.
                                                </span>
                                                <input type="checkbox" class="checkbox" id="isAllowVerbalPromoSubscribeOption" *ngIf="editShow" formControlName="isAllowVerbalPromoSubscribeOption" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-row-inner">
                                <div class="wraper-body-left">
                                    <div class="custom-action-title">
                                        <span>Email Communication Preferences Page Configuration</span>
                                    </div>
                                    <div class="excel-main-panel">
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div class="align-center">
                                                    <span class="control-label">Logo URL</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <span *ngIf="!editShow"> {{emailSettingData?.prefsLogoURL}}</span>
                                                <input type="text" *ngIf="editShow" class="txtStandard" id="prefsLogoURL" formControlName="prefsLogoURL" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="excel-main-panel">
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div class="align-center">
                                                    <span class="control-label">Main Description</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <span *ngIf="!editShow" [innerHTML]="emailSettingData?.prefsMainDesc"></span>
                                                <textarea type="text" *ngIf="editShow" class="txtStandard" id="prefsMainDesc" formControlName="prefsMainDesc"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="excel-main-panel">
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div class="align-center">
                                                    <span class="control-label">Unsubscribe To Informational Description</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <span *ngIf="!editShow" [innerHTML]="emailSettingData?.prefsInfoDesc"> </span>
                                                <textarea type="text" *ngIf="editShow" class="txtStandard" id="prefsInfoDesc" formControlName="prefsInfoDesc"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="excel-main-panel">
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div class="align-center">
                                                    <span class="control-label">Unsubscribe To Promotional Description</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <span *ngIf="!editShow" [innerHTML]="emailSettingData?.prefsPromoDesc"> </span>
                                                <textarea type="text" *ngIf="editShow" class="txtStandard" id="prefsPromoDesc" formControlName="prefsPromoDesc"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="excel-main-panel">
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div class="align-center">
                                                    <span class="control-label">Display Comments Box</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <span *ngIf="!editShow && emailSettingData.prefsShowComments">Comments box will be displayed.</span>
                                                <span *ngIf="!editShow && !emailSettingData.prefsShowComments">Comments box will <span style='color:red'><b>NOT</b></span> be displayed.</span>
                                                <input type="checkbox" class="checkbox" id="prefsShowComments" *ngIf="editShow" formControlName="prefsShowComments" />

                                            </div>
                                        </div>
                                    </div>
                                    <div class="excel-main-panel">
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left">
                                                <div class="align-center">
                                                    <span class="control-label">Comments Box Description</span>
                                                </div>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <span *ngIf="!editShow"> {{emailSettingData?.prefsCommentsTitle}}</span>
                                                <input type="text" *ngIf="editShow" class="txtStandard" id="prefsCommentsTitle" formControlName="prefsCommentsTitle" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex-row-inner" *ngIf="emailSettingData">
                            <div class="wraper-body-left">
                                <div class="excel-main-panel">
                                    <div class="cards-body-section">
                                        <div class="cards-colunm-left calc-width"></div>
                                        <div class="cards-colunm-right">
                                            <div class="bottom-button-bar">
                                                <button class="btn btn-primary mr-2" type="submit" *ngIf="editShow" [hidden]="roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false" [disabled]=!submitEmailSetting>
                                                    <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
                                                    <ng-template [ngIf]="buttonTypeOperation===1">Saving  <span><i wrapper> </i></span></ng-template>
                                                </button>
                                                <button type="button" *ngIf="!editShow" [hidden]=" roleFeaturePermissions?.edit == false" (click)="editData()" class="btn btn-primary mr-2">Edit</button>
                                                <button type="button" *ngIf="editShow" (click)="backToView()" class="btn btn-cancel mr-2">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
