import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CampaignEvent } from '../../../models/appt.model';
import { CampaignEventDataResponse, CampaignEventSaveRequest, EventsFiltersResponse } from '../../../models/campaign.model';
import { CLPUser, DDFields, DDFieldsResponse, UserResponse, VoiceRecordingFilterResponse, VoiceRecordingType } from '../../../models/clpuser.model';
import { VoiceSettingService } from '../../../services/voice-setting.service';
import { eCampaignEventAddedLink, eCampaignEventNoteEmailToChoice, eCampaignEventType, eCampaignStatus, eCampaignTemplateOwnerType, eDDField, eFeatures, eFieldType, eFTEditType, eMailingCategory, eNoteOwnerType, eUserRole } from '../../../models/enum.model';
import { DropDownItem, IntDropDownItem, SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { CampaignService } from '../../../services/campaign.service';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { ContactFields, ContactFilters } from '../../../models/contact.model';
import { LeadFields } from '../../../models/lead.model';
import { TagData, TagsFields } from '../../../models/tag-settings.model';
import { TagSettingService } from '../../../services/tag-setting.service';
import { LinkGroupService } from '../../../services/link-group.service';
import { LinkContactExtWithCount } from '../../../models/link-contact.model';
import { MarketingService } from '../../../services/marketing.service';
import { Marketing_MailMergeResponse } from '../../../models/marketing.model';
import { EmailTemplate, EmailTemplateResponse, MailMergeTemplate } from '../../../models/emailTemplate.model';
import { EmailTemplateService } from '../../../services/email-template.service';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { NoteTypeCodeModel } from '../../../models/noteTypeCode.model';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { ZapierService } from '../../../services/zapier.service';
import { isNumber } from 'util';
import { GlobalService } from '../../../services/global.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'campaign-event',
    templateUrl: './campaign-event.component.html',
    styleUrls: ['./campaign-event.component.css']
})
/** campaign-event component*/
export class CampaignEventComponent {
    showSpinner: boolean = false;
    userResponse: UserResponse;
    roleFeaturePermissions: RoleFeaturePermissions;
    user: CLPUser;
    isApptQualCall: boolean = false;
    @Output() refreshEventList = new EventEmitter<boolean>(false);
    @Output() cancelEvent = new EventEmitter<boolean>(false);
    @Input() campaignTemplateId: number = 0;
    @Input() campaignEventId: number = 0;
    @Input() ownerType: eCampaignTemplateOwnerType = eCampaignTemplateOwnerType.Contact; //dynamic
    private encryptedUser: string = '';
    filterUsers: IntDropDownItem[] = [];
    filterFTLeadField: DropDownItem[] = [];
    filterFTFields: DropDownItem[] = [];
    filterCTCampaignTemplate: DropDownItem[] = [];
    filterCTCLPUser: DropDownItem[] = [];
    ddEventType: IntDropDownItem[] = [];
    filterApptTypeCode: DropDownItem[] = [];
    filterEmailTemplates: DropDownItem[] = [];
    filterTxtMsgTemplate: DropDownItem[] = [];
    filterCustomActionScreenList: DropDownItem[] = [];
    ddTimeSinceUnit: IntDropDownItem[] = [];
    ddReminderLength: IntDropDownItem[] = [];
    ddApptStartTime: DropDownItem[] = [];
    ddApptLength: IntDropDownItem[] = [];
    voiceRecordingType: VoiceRecordingType[] = [];
    contactFieldConfiguration: ContactFields = <ContactFields>{};
    leadFieldConfiguration: LeadFields = <LeadFields>{};
    camapignEventData: CampaignEvent = <CampaignEvent>{};
    ftDDItems: ContactFilters[] = [];
    linkContactExtWithCount: LinkContactExtWithCount[];
    mailMergeTemplateList: MailMergeTemplate[];
    noteTypeCodeList: NoteTypeCodeModel[] = [];
    emailTemplateList: EmailTemplate[];
    zapDDFields: DDFields[];
    selectedEventType: number = 0;
    campaignEventForm: FormGroup;

    customTagDisplay: string = '';
    tagListByCompany: TagData[] = [];
    /** campaign-event ctor */
    /* boolean for field trigger*/
    isShowFtInputField: boolean = false;
    isShowFtInputNumberField: boolean = false;
    isShowFtCheckbox: boolean = false;
    isShowHistoryNoteField: boolean = false;
    isShowFtTextareaField: boolean = false;
    isShowMetricField: boolean = false;
    isShowDDField: boolean = false;
    isShowDDUserField: boolean = false;
    isShowTagField: boolean = false;
    isShowOutputTxtMsgField: boolean = false;
    isShowDateField: boolean = false;
    isShowDateTxtField: boolean = false;
    isShowEditTypeDD: boolean = true;
    emailValue: number = 0;
    documentList: any[] = [];
    campaignEventFormSubmitted: boolean;
    isSendImmReminder: boolean = false;
    isCTCaution: boolean = false;
    /* tagListByOwner: TagData[] = [];*/
    /** automation-process ctor */
    constructor(private fb: FormBuilder, private _tagSettingService: TagSettingService,
        private _notifyService: NotificationService,
        private datepipe: DatePipe,
        private _campaignService: CampaignService,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _zapierService: ZapierService,
        private _voiceSettingService: VoiceSettingService,
        private _linkService: LinkGroupService,
        private _marketingService: MarketingService,
        private _emailTemplateService: EmailTemplateService,
        private userService: UserService,
        private _router: Router,
        public _globalService: GlobalService) {
        this._localService.isMenu = true;
    }


    ngOnInit(): void {
        this.campaignEventForm = this.prepareCampaignEventForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.loadInit();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    async loadInit() {
        this.showSpinner = true;
        await this.loadEventFilters();
        this.ddEventType = this._localService.gettWorkflowCampaignEventTypeList();
        await this.getZapierEventCodeList();
        this.getMailMergeTemplateList();
        this.getEmailTemplateList();
        this.getDDFieldList();
        if (this.campaignEventId > 0) {
            this.loadCampaignEventData();
        }

        this.showSpinner = false;
    }
    initialiseBooleanValues() {
        this.isShowFtCheckbox = false;
        this.isShowHistoryNoteField = false;
        this.isShowFtTextareaField = false;
        this.isShowMetricField = false;
        this.isShowFtInputField = false;
        this.isShowFtInputNumberField = false;
        this.isShowDDField = false;
        this.isShowDDUserField = false;
        this.isShowTagField = false;
        this.isShowOutputTxtMsgField = false;
        this.isShowDateField = false;
        this.isShowDateTxtField = false;
        this.isShowEditTypeDD = true;
    }

    prepareCampaignEventForm() {
        return this.fb.group({
            campaignEventType: new FormControl(''),
            campaignEventName: new FormControl('', [Validators.required]),
            timeSinceValue: new FormControl(1, [Validators.required, Validators.max(100), Validators.min(0)]),
            timeSinceUnit: new FormControl(8),
            ownerCLPUserID: new FormControl(0),
            alertStatus: new FormControl(false),
            score: new FormControl(0, [Validators.required, Validators.min(-100), Validators.max(100)]),
            typeCode: new FormControl(0),
            apptStartTime: new FormControl(''),
            apptLength: new FormControl(30),
            reminderLength: new FormControl(30),
            reminderCLP: new FormControl(true),
            reminderEmail: new FormControl(true),
            reminderEmails: new FormControl(''),
            subject: new FormControl(''),
            notes: new FormControl(''),
            documentList: new FormControl(''),
            emailTemplateID: new FormControl('0'),
            mailMergeTemplateID: new FormControl(0),
            mailingCategory: new FormControl(0),
            toChoice: new FormControl(1),
            toField: new FormControl(''),
            connRelationship: new FormControl(''),
            cCField: new FormControl(''),
            bCCField: new FormControl(''),
            emailAddedLink: new FormControl(''),
            cTAction: new FormControl(eCampaignStatus.Active),
            cTCampaignTemplateID: new FormControl(''),
            cTCLPUserID: new FormControl(""),
            fTField: new FormControl(''),
            fTEditType: new FormControl(1),
            fTValue: new FormControl(''),
            txtMsgTemplateID: new FormControl(''),
            isPhoneCall: new FormControl(false),
            isShowNoteInUserReminder: new FormControl(true),
            isTxtMsgReminder: new FormControl(false),
            isTxtMsgUserReminder: new FormControl(false),
            isSendImmediateReminder: new FormControl(false),
            confirmationTxtMsgTemplateID: new FormControl(0),
            confirmationEmailTemplateID: new FormControl(0),
            apptQualStartTime: new FormControl(new Date(new Date().setHours(8, 0, 0, 0))),
            apptQualEndTime: new FormControl(new Date(new Date().setHours(17, 0, 0, 0))),
            apptQualDelayNum: new FormControl('3'),
            apptQualDelayType: new FormControl('1'),
            salutation: new FormControl(0),
            linkId: new FormControl(0),
            ftSelectedDateValue: new FormControl(new Date()),
            isPromotional: new FormControl(false)
        });
    }

    setValidationBasedOnEventType() {
        var subjectControl = this.campaignEventForm.get('subject');
        var notesControl = this.campaignEventForm.get('notes');
        var emailTemplateIDControl = this.campaignEventForm.get('emailTemplateID');
        var txtMsgTemplateIDControl = this.campaignEventForm.get('txtMsgTemplateID');
        var cTCampaignTemplateIDControl = this.campaignEventForm.get('cTCampaignTemplateID');
        var cTCLPUserIDControl = this.campaignEventForm.get('cTCLPUserID');
        var fTFieldControl = this.campaignEventForm.get('fTField');
        switch (+this.selectedEventType) {
            case eCampaignEventType.Appointment:
                subjectControl.setValidators([Validators.required]);
                emailTemplateIDControl.setValidators([Validators.required]);
                txtMsgTemplateIDControl.setValidators([Validators.required]);
                notesControl.setValidators(null);
                cTCampaignTemplateIDControl.setValidators(null);
                cTCLPUserIDControl.setValidators(null);
                fTFieldControl.setValidators(null);
                break;
            case eCampaignEventType.Task:
                notesControl.setValidators([Validators.required]);

                subjectControl.setValidators(null);
                emailTemplateIDControl.setValidators(null);
                cTCampaignTemplateIDControl.setValidators(null);
                cTCLPUserIDControl.setValidators(null);
                fTFieldControl.setValidators(null);
                break;
            case eCampaignEventType.Email: case eCampaignEventType.Zapier_Event:
                emailTemplateIDControl.setValidators([Validators.required, Validators.min(1)]);

                notesControl.setValidators(null);
                subjectControl.setValidators(null);
                cTCampaignTemplateIDControl.setValidators(null);
                cTCLPUserIDControl.setValidators(null);
                fTFieldControl.setValidators(null);
                break;
            case eCampaignEventType.Voice_Drop:
                emailTemplateIDControl.setValidators([Validators.required]);

                subjectControl.setValidators(null);
                notesControl.setValidators(null);
                cTCampaignTemplateIDControl.setValidators(null);
                cTCLPUserIDControl.setValidators(null);
                fTFieldControl.setValidators(null);
                break;
            case eCampaignEventType.Campaign_Trigger:
                cTCampaignTemplateIDControl.setValidators([Validators.required]);
                cTCLPUserIDControl.setValidators([Validators.required]);

                emailTemplateIDControl.setValidators(null);
                notesControl.setValidators(null);
                subjectControl.setValidators(null);
                fTFieldControl.setValidators(null);
                break;
            case eCampaignEventType.Field_Trigger:
                fTFieldControl.setValidators([Validators.required]);

                notesControl.setValidators(null);
                subjectControl.setValidators(null);
                emailTemplateIDControl.setValidators(null);
                cTCampaignTemplateIDControl.setValidators(null);
                cTCLPUserIDControl.setValidators(null);
                break;
        }

        subjectControl.updateValueAndValidity();
        notesControl.updateValueAndValidity();
        emailTemplateIDControl.updateValueAndValidity();
        cTCampaignTemplateIDControl.updateValueAndValidity();
        cTCLPUserIDControl.updateValueAndValidity();
        fTFieldControl.updateValueAndValidity();
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-event.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }


    async loadEventFilters() {
        await this._campaignService.loadEventFilters(this.encryptedUser, this.campaignTemplateId, this.selectedEventType, this.ownerType, this.user?.cLPCompanyID, this.user?.cLPUserID)
            .then(async (result: EventsFiltersResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        this.filterUsers = response?.filterUsers;
                        this.filterApptTypeCode = response?.apptEventFilters?.filterApptTypeCode;
                        this.filterEmailTemplates = response?.apptEventFilters?.filterEmailTemplates;
                        this.filterTxtMsgTemplate = response?.apptEventFilters?.filterTxtMsgTemplate;
                        this.filterCustomActionScreenList = response?.apptEventFilters?.filterCustomActionScreenList;
                        this.filterFTFields = response?.fieldTriggerFilters?.filterFTFields;
                        this.filterFTLeadField = response?.fieldTriggerFilters?.filterFTLeadField;
                        this.filterCTCampaignTemplate = response?.campaignTriggerFilters?.filterCTCampaignTemplate;
                        this.filterCTCLPUser = response?.campaignTriggerFilters?.filterCTCLPUser;
                        this.contactFieldConfiguration = response?.fieldTriggerFilters?.contactFields;
                        this.leadFieldConfiguration = response?.fieldTriggerFilters?.leadFields;
                        this.noteTypeCodeList = response?.fieldTriggerFilters?.noteTypeCodes;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-event.loadEventFilters", err.message, null, 'campaignTemplateId ' + this.campaignTemplateId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getVoicesRecordingType() {
        await this._voiceSettingService.voiceRecording_GetList(this.encryptedUser, this.user?.cLPCompanyID)
            .then((result: VoiceRecordingFilterResponse) => {
                if (result)
                    this.voiceRecordingType = UtilityService.clone(result?.voiceRecordings);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-event.getVoicesRecordingType", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
            });
    }

    async getZapierEventCodeList() {
        await this._zapierService.getZapierDD_Code(this.encryptedUser, this.user?.cLPCompanyID)
            .then((result: IntDropDownItem[]) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response) && response?.length == 0) {
                        this.ddEventType = this.ddEventType.filter(x => x.id != 5);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-event.getZapierEventCodeList", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
            });
    }
    async loadGetListByClpCompanyID() {
        await this._linkService.linkGetListByClpCompanyID(this.encryptedUser, this.user?.cLPCompanyID)
            .then((result: LinkContactExtWithCount[]) => {
                if (!isNullOrUndefined(result)) {
                    this.linkContactExtWithCount = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-event.loadGetListByClpCompanyID", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID);
            });
    }

    async getMailMergeTemplateList() {
        await this._marketingService.getMailMergeTemplateList(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, this.user?.cLPUserID, null)
            .then(async (result: Marketing_MailMergeResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        this.mailMergeTemplateList = response?.mailMergeList;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-event.getMailMergeTemplateList", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user?.cLPUserID + 'selectedUserId' + this.user?.cLPUserID + 'strisAdvanced ' + null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getDDFieldList() {
        await this.userService.getDDFieldList(this.encryptedUser, eDDField.ZapierEventCode, this.user?.cLPCompanyID)
            .then(async (result: DDFieldsResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.zapDDFields = response.lstDDFields;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-event.getDDFieldList", err.message, null, 'efield ' + eDDField.ZapierEventCode + 'cLPCompanyID ' + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getEmailTemplateList() {
        await this._emailTemplateService.getEmailTemplateListSetup(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, this.user.cLPUserID, true, null)
            .then(async (result: EmailTemplateResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.emailTemplateList = response.emailTemplates;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-event.getEmailTemplateList", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID ' + this.user.cLPUserID + 'selectedUserId' + this.user?.cLPUserID + 'includeShared' + this.user?.cLPUserID + 'strisAdvanced' + null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async loadCampaignEventData() {
        await this._campaignService.loadCampaignEventById(this.encryptedUser, this.campaignEventId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then((result: CampaignEventDataResponse) => {
                if (result) {
                    this.camapignEventData = UtilityService.clone(result?.campaignEvent);
                    this.selectEventType(this.camapignEventData?.campaignEventType);
                    this.patchCampaignTemplateFormValue();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-event.loadCampaignEventData", err.message, null, 'campaignEventId ' + this.campaignEventId);
            });
    }

    patchCampaignTemplateFormValue() {
        const campaignEvent = this.camapignEventData;
        for (let key in campaignEvent) {
            let value = campaignEvent[key];

            if (this.campaignEventForm.get(key)) {
                switch (+this.selectedEventType) {
                    case eCampaignEventType.Appointment:
                        switch (key) {
                            case "documentList":
                                if (value != "" && value?.length > 0) {
                                    this.documentList = value?.split(',');
                                }
                                break;
                            case "cTAction":
                                if (value == eCampaignStatus.Active)
                                    this.campaignEventForm.get("isPhoneCall").setValue(true);
                                else
                                    this.campaignEventForm.get("isPhoneCall").setValue(false);
                                break;
                            case "cTCampaignTemplateID":
                                if (value == 1)
                                    this.campaignEventForm.get("isShowNoteInUserReminder").setValue(true);
                                else
                                    this.campaignEventForm.get("isShowNoteInUserReminder").setValue(false);
                                break;
                            case "mailingCategory":
                                if (value == eMailingCategory.Mail) {
                                    this.campaignEventForm.get("isSendImmediateReminder").setValue(true);
                                    this.campaignEventForm.get("confirmationTxtMsgTemplateID").setValue(this.camapignEventData.confirmationTxtMsgTemplateID);
                                    this.campaignEventForm.get("confirmationEmailTemplateID").setValue(this.camapignEventData.confirmationEmailTemplateID);
                                    this.isSendImmReminder = true;
                                }
                                else
                                    this.campaignEventForm.get("isSendImmediateReminder").setValue(false);
                                break;
                            case "fTField":
                                if (value == "True")
                                    this.campaignEventForm.get("isTxtMsgReminder").setValue(true);
                                else
                                    this.campaignEventForm.get("isTxtMsgReminder").setValue(false);
                                break;
                            case "toField":
                                let toFielddt = moment(value, "h:mm tt");
                                this.campaignEventForm.get("apptQualStartTime").setValue(toFielddt.toDate());
                                break;
                            case "cCField":
                                let cCFielddt = moment(value, "h:mm tt");
                                this.campaignEventForm.get("apptQualEndTime").setValue(cCFielddt.toDate());
                                break;
                            case "bCCField":
                                if (!isNullOrUndefined(value) && value != "") {
                                    let strOut: string[] = [];
                                    strOut = value.split('::');
                                    this.campaignEventForm.get("apptQualDelayNum").setValue(strOut[0]);
                                    this.campaignEventForm.get("apptQualDelayType").setValue(strOut[1]);
                                }
                                break;
                            case "fTEditType":
                                if (value == eFTEditType.Append)
                                    this.campaignEventForm.get("isTxtMsgUserReminder").setValue(true);
                                else
                                    this.campaignEventForm.get("isTxtMsgUserReminder").setValue(false);
                                break;
                            case "isTxtMsgUserReminder": case "isTxtMsgReminder":
                                break;
                            default:
                                this.campaignEventForm.get(key).setValue(value);
                                break;
                        }
                        break;
                    case eCampaignEventType.Task:
                        switch (key) {
                            case "documentList":
                                if (value != "" && value?.length > 0) {
                                    this.documentList = value?.split(',');
                                }
                                break;
                            default:
                                this.campaignEventForm.get(key).setValue(value);
                                break;
                        }
                        break;
                    case eCampaignEventType.Field_Trigger:
                        switch (key) {
                            case "fTField":
                                this.onChangeContactFT(value, false);
                                this.campaignEventForm.get(key).setValue(value);
                                break;
                            default:
                                this.campaignEventForm.get(key).setValue(value);
                                break;
                        }
                        break;
                    case eCampaignEventType.Email:
                        switch (key) {
                            case "documentList":
                                if (value != "" && value?.length > 0) {
                                    this.documentList = value?.split(',');
                                }
                                break;
                            case "toChoice":
                                this.emailCheckList.filter(x => {
                                    if (x.id == value) {
                                        this.emailValue = x.id;
                                        x.isSelected = true;
                                    }
                                })
                                this.campaignEventForm.get(key).setValue(value);
                                break;
                            case "isPromotional":
                                if (value)
                                    this.campaignEventForm.get("isPromotional").setValue(true);
                                else
                                    this.campaignEventForm.get("isPromotional").setValue(false);
                                break;
                            default:
                                this.campaignEventForm.get(key).setValue(value);
                                break;
                        }
                        break;
                    default:
                        this.campaignEventForm.get(key).setValue(value);
                        break;
                }
            }
        }
        if (+this.selectedEventType == eCampaignEventType.Field_Trigger) {
            if (this.campaignEventForm.controls.fTField.value == "Tag") {
                this.campaignEventForm.get("fTValue").setValue(+(this.campaignEventForm.controls.fTValue.value));
            }
            else if (this.campaignEventForm.controls.fTField.value == "CMCustomDate1" || this.campaignEventForm.controls.fTField.value == "CMCustomDate2" || this.campaignEventForm.controls.fTField.value == "CMCustomDate3"
                || this.campaignEventForm.controls.fTField.value == "dtStart" || this.campaignEventForm.controls.fTField.value == "dtEnd" || this.campaignEventForm.controls.fTField.value == "dtRevenue"
                || this.campaignEventForm.controls.fTField.value == "dtCustom1" || this.campaignEventForm.controls.fTField.value == "dtCustom2" || this.campaignEventForm.controls.fTField.value == "dtCustom3") {
                if (this.campaignEventForm.controls.fTValue.value == "PH_CDate")
                    this.isShowDateTxtField = true;
                else {
                    this.isShowDateTxtField = false;
                    this.campaignEventForm.get("fTValue").setValue(new Date(this.campaignEventForm.controls.fTValue.value));
                }
            }
        }
    }

    selectEventType(value: eCampaignEventType) {
        this.selectedEventType = value;
        this.campaignEventForm.reset();
        this.campaignEventForm = this.prepareCampaignEventForm();
        this.setValidationBasedOnEventType();
        switch (+value) {
            case eCampaignEventType.Task:
                this.ddTimeSinceUnit = this._localService.GetCampaignEventTimeSinceList(eCampaignEventType.Task);
                this.campaignEventForm.get("timeSinceUnit").setValue(9);
                break;
            default:
                this.ddTimeSinceUnit = this._localService.GetCampaignEventTimeSinceList(eCampaignEventType.Appointment);
                this.campaignEventForm.get("timeSinceUnit").setValue(8);
                this.loadGetListByClpCompanyID();
                break;
        }

        switch (+value) {
            case eCampaignEventType.Field_Trigger:
                this.campaignEventForm.get("fTField").setValue("");
                this.campaignEventForm.get("emailTemplateID").setValue(0);
                this.campaignEventForm.get("fTValue").setValue("");
                this.campaignEventForm.get("bCCField").setValue("");
                this.campaignEventForm.get("ownerCLPUserID").setValue("0");
                break;
            case eCampaignEventType.Campaign_Trigger:
                this.campaignEventForm.get("ownerCLPUserID").setValue("0");
                break;
            case eCampaignEventType.Voice_Drop:
                this.getVoicesRecordingType();
                this.campaignEventForm.get("emailTemplateID").setValue(0);
                break;
            case eCampaignEventType.TxtMsg:
                this.campaignEventForm.get("toChoice").setValue(1);
                this.campaignEventForm.get("reminderCLP").setValue(false);
                this.campaignEventForm.get("fTEditType").setValue('0');
                this.campaignEventForm.get("emailTemplateID").setValue(0);
                break;
            case eCampaignEventType.Voice_Call:
                this.getVoicesRecordingType();
                this.campaignEventForm.get("fTField").setValue("Prefs");
                this.campaignEventForm.get("emailTemplateID").setValue(0);
                break;
            case eCampaignEventType.LiveConnect_Card:
                this.campaignEventForm.get("subject").setValue("Alert");
                break;
            case eCampaignEventType.Appointment:
                this.ddApptLength = this._localService.GetDDApptLength();
                this.ddReminderLength = this._localService.getDDReminderLength();
                this.ddApptStartTime = this._localService.getApptTimeDD();
                break;
        }
    }

    async saveSFAEvents(requestBody: CampaignEventSaveRequest, eventType: eCampaignEventType) {
        await this._campaignService.saveSFAEvent(this.encryptedUser, requestBody, eventType, this.user?.cLPCompanyID, this.campaignTemplateId, this.user?.cLPUserID)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    if (response?.messageBool) {
                        this.campaignEventForm.reset();
                        this.campaignEventFormSubmitted = false
                        this.initialiseBooleanValues();
                        this.campaignEventForm = this.prepareCampaignEventForm();
                        this._notifyService.showSuccess("Event Saved Successfully.", "", 3000);
                        this.selectedEventType = 0;
                        this.campaignEventId = 0;
                        this.documentList = [];
                        this.refreshEventList.emit(true);
                    }
                    else
                        this._notifyService.showError(response.messageString ? response.messageString : "Something went wrong.", "", 3000);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-event.saveSFAEvents", err.message, requestBody, 'eventType ' + eventType + 'cLPCompanyID ' + this.user?.cLPCompanyID + 'campaignTemplateId ' + this.campaignTemplateId + 'cLPUserID ' + this.user?.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    emailCheckList = [
        { id: 1, text: "Contact", isSelected: true },
        { id: 2, text: "User", isSelected: false },
        { id: 3, text: "Other", isSelected: false },
        { id: 4, text: "Link Group", isSelected: false },
        { id: 5, text: "Connections", isSelected: false }
    ];

    isEmailSelected(item) {
        this.emailCheckList.forEach(emailVal => {

            if (emailVal.id == item.id) {
                this.emailValue = emailVal.id;
                emailVal.isSelected = !emailVal.isSelected;
            }
            else {
                emailVal.isSelected = false;
            }
        });
        switch (+item.id) {
            case eCampaignEventNoteEmailToChoice.Contact:
                this.campaignEventForm.get("toField").setValue("");
                break;
            case eCampaignEventNoteEmailToChoice.User:
                this.campaignEventForm.get("ownerCLPUserID").setValue("0");
                break;
            case eCampaignEventNoteEmailToChoice.Other: case eCampaignEventNoteEmailToChoice.Connection:
                this.campaignEventForm.get("toField").setValue("");
                break;
            case eCampaignEventNoteEmailToChoice.Link:
                this.campaignEventForm.get("linkId").setValue("0");
                break;
        }
    }

    onCancel() {
        this.cancelEvent.emit(true);
    }
    async copyApptFormDataToObj() {
        var campaignEventSaveRequestObj: CampaignEventSaveRequest = <CampaignEventSaveRequest>{};
        campaignEventSaveRequestObj.eventDetails = <CampaignEvent>{};
        campaignEventSaveRequestObj.eventDetails.campaignEventID = this.campaignEventId;
        campaignEventSaveRequestObj.eventDetails.cLPCompanyID = this.user?.cLPCompanyID;
        campaignEventSaveRequestObj.eventDetails.cLPUserID = this.user?.cLPUserID;
        campaignEventSaveRequestObj.eventDetails.campaignTemplateID = this.campaignTemplateId;
        campaignEventSaveRequestObj.eventDetails.campaignEventType = +this.selectedEventType;
        campaignEventSaveRequestObj.eventDetails.campaignEventName = this.campaignEventForm.controls.campaignEventName.value;
        campaignEventSaveRequestObj.eventDetails.timeSinceValue = this.campaignEventForm.controls.timeSinceValue.value;
        campaignEventSaveRequestObj.eventDetails.timeSinceUnit = this.campaignEventForm.controls.timeSinceUnit.value;
        campaignEventSaveRequestObj.eventDetails.ownerCLPUserID = this.campaignEventForm.controls.ownerCLPUserID.value;
        campaignEventSaveRequestObj.eventDetails.alertStatus = this.campaignEventForm.controls.alertStatus.value;
        campaignEventSaveRequestObj.eventDetails.score = this.campaignEventForm.controls.score.value ? this.campaignEventForm.controls.score.value : 0;

        switch (+this.selectedEventType) {
            case eCampaignEventType.Email:
                campaignEventSaveRequestObj.eventDetails.typeCode = 0;
                campaignEventSaveRequestObj.eventDetails.emailAddedLink = isNullOrEmptyString(this.campaignEventForm.controls.emailAddedLink.value) ? 0 : this.campaignEventForm.controls.emailAddedLink.value;
                campaignEventSaveRequestObj.eventDetails.subject = this.campaignEventForm.controls.subject.value;
                campaignEventSaveRequestObj.eventDetails.notes = this.campaignEventForm.controls.notes.value;
                campaignEventSaveRequestObj.eventDetails.documentList = "";
                this.documentList.forEach(item => {
                    campaignEventSaveRequestObj.eventDetails.documentList += item + ',';
                })
                campaignEventSaveRequestObj.eventDetails.documentList = campaignEventSaveRequestObj.eventDetails.documentList.substring(0, campaignEventSaveRequestObj.eventDetails.documentList.length - 1);

                campaignEventSaveRequestObj.eventDetails.toChoice = isNullOrUndefined(this.emailCheckList.find(x => x.isSelected == true)?.id) ? 0 : this.emailCheckList.find(x => x.isSelected == true)?.id;
                switch (+campaignEventSaveRequestObj.eventDetails.toChoice) {
                    case eCampaignEventNoteEmailToChoice.Contact:
                        campaignEventSaveRequestObj.eventDetails.toField = "Contact";
                        break;
                    case eCampaignEventNoteEmailToChoice.User:
                        campaignEventSaveRequestObj.eventDetails.toField = this.campaignEventForm.controls.ownerCLPUserID.value;
                        break;
                    case eCampaignEventNoteEmailToChoice.Other: case eCampaignEventNoteEmailToChoice.Connection:
                        campaignEventSaveRequestObj.eventDetails.toField = this.campaignEventForm.controls.toField.value;
                        break;
                    case eCampaignEventNoteEmailToChoice.Link:
                        campaignEventSaveRequestObj.eventDetails.toField = this.campaignEventForm.controls.linkId.value;
                        break;
                }
                campaignEventSaveRequestObj.eventDetails.cCField = this.campaignEventForm.controls.cCField.value;
                campaignEventSaveRequestObj.eventDetails.bCCField = this.campaignEventForm.controls.bCCField.value;
                campaignEventSaveRequestObj.eventDetails.emailTemplateID = this.campaignEventForm.controls.emailTemplateID.value;
                campaignEventSaveRequestObj.eventDetails.mailMergeTemplateID = this.campaignEventForm.controls.mailMergeTemplateID.value;
                campaignEventSaveRequestObj.eventDetails.isPromotional = this.campaignEventForm.controls.isPromotional.value == 1 ? true : false;
                break;
            case eCampaignEventType.TxtMsg:
                if (!this.validMobile()) {
                    this._notifyService.showWarning("Please enter a valid mobile number.", '');
                    return;
                }
                campaignEventSaveRequestObj.eventDetails.notes = this.campaignEventForm.controls.notes.value;
                campaignEventSaveRequestObj.eventDetails.toChoice = this.campaignEventForm.controls.toChoice.value;
                campaignEventSaveRequestObj.eventDetails.fTField = this.campaignEventForm.controls.fTField.value;
                campaignEventSaveRequestObj.eventDetails.emailTemplateID = this.campaignEventForm.controls.emailTemplateID.value ? this.campaignEventForm.controls.emailTemplateID.value : 0;
                campaignEventSaveRequestObj.eventDetails.fTEditType = this.campaignEventForm.controls.fTEditType.value;
                campaignEventSaveRequestObj.eventDetails.reminderCLP = this.campaignEventForm.controls.reminderCLP.value;
                switch (+this.campaignEventForm.controls.toChoice.value) {
                    case eCampaignEventNoteEmailToChoice.Contact:
                        campaignEventSaveRequestObj.eventDetails.toField = "Contact";
                        break;
                    case eCampaignEventNoteEmailToChoice.User:
                        campaignEventSaveRequestObj.eventDetails.toField = this.campaignEventForm.controls.ownerCLPUserID.value;
                        break;
                    case eCampaignEventNoteEmailToChoice.Other:
                        campaignEventSaveRequestObj.eventDetails.toField = this.campaignEventForm.controls.reminderEmails.value;
                        break;
                }
                break;
            case eCampaignEventType.Zapier_Event:
                campaignEventSaveRequestObj.eventDetails.emailTemplateID = this.campaignEventForm.controls.emailTemplateID.value;
                break;
            case eCampaignEventType.Campaign_Trigger:
                if (!(this.campaignEventForm.controls.cTAction.value == 1 && this.campaignEventForm.controls.cTCampaignTemplateID.value == -1)) {
                    let blnGo: boolean = true;
                    if (this.campaignEventForm.controls.cTCampaignTemplateID.value == -1 || this.campaignEventForm.controls.cTCLPUserID.value == -1) {
                        if (!this.isCTCaution) {
                            blnGo = false;
                            this.isCTCaution = true;
                        }
                        else this.isCTCaution = false
                    }
                    if (blnGo) {
                        campaignEventSaveRequestObj.eventDetails.cTAction = this.campaignEventForm.controls.cTAction.value;
                        campaignEventSaveRequestObj.eventDetails.cTCampaignTemplateID = this.campaignEventForm.controls.cTCampaignTemplateID.value;
                        campaignEventSaveRequestObj.eventDetails.cTCLPUserID = this.campaignEventForm.controls.cTCLPUserID.value;
                    } else return;
                }
                else {
                    this._notifyService.showWarning('For security purposes, you cannot Start all automation processes.','');
                    return;
                }
                break;
            case eCampaignEventType.Field_Trigger:
                campaignEventSaveRequestObj.eventDetails.fTField = this.campaignEventForm.controls.fTField.value;
                if (this.ownerType == eCampaignTemplateOwnerType.Lead) {
                    switch (this.campaignEventForm.controls.fTField.value) {
                        case "LeadDesc": case "Scope": case "Comments": case "CustomText1": case "CustomText2": case "CustomText3": case "CustomText4": case "CustomText5": case "CustomText6": case "Tag":
                            campaignEventSaveRequestObj.eventDetails.fTEditType = this.campaignEventForm.controls.fTEditType.value;
                            break;
                        default:
                            campaignEventSaveRequestObj.eventDetails.fTEditType = eFTEditType.Replace;
                            break;
                    }
                } else {
                    switch (this.campaignEventForm.controls.fTField.value) {
                        case "Shareable": case "Class1Code": case "Class2Code": case "Class3Code": case "Class4Code": case "Class5Code": case "Class6Code": case "Class7Code": case "Class8Code": case "User":
                        case "Check1": case "Check2": case "Check3": case "Check4": case "Check5": case "Check6": case "Buzz": case "Metric": case "HistoryNote":
                            campaignEventSaveRequestObj.eventDetails.fTEditType = eFTEditType.Replace;
                            break;
                        default:
                            campaignEventSaveRequestObj.eventDetails.fTEditType = this.campaignEventForm.controls.fTEditType.value;
                            break;
                    }
                }
                if (this.isShowTagField) {
                    if (this.customTagDisplay != "" && this.customTagDisplay.length > 0) {
                        await this.addNewTag(this.customTagDisplay);
                        campaignEventSaveRequestObj.eventDetails.fTValue = this.campaignEventForm.controls.fTValue.value;
                    } else {
                        if (+this.campaignEventForm.controls.fTValue.value == -2) {
                            if (+this.campaignEventForm.controls.fTEditType.value != 3) {
                                return "All Tags can only be removed not added.";
                            }
                            else
                                campaignEventSaveRequestObj.eventDetails.fTValue = this.campaignEventForm.controls.fTValue.value;
                        }
                        else
                            campaignEventSaveRequestObj.eventDetails.fTValue = this.campaignEventForm.controls.fTValue.value;
                    }
                }
                else if (this.isShowDateField && !this.isShowDateTxtField)
                    campaignEventSaveRequestObj.eventDetails.fTValue = this.datepipe.transform(this.campaignEventForm.controls.ftSelectedDateValue.value, 'MM/dd/yyyy');
                else {
                    campaignEventSaveRequestObj.eventDetails.fTValue = this.campaignEventForm.controls.fTValue.value;
                }
                campaignEventSaveRequestObj.eventDetails.bCCField = this.campaignEventForm.controls.bCCField.value;
                campaignEventSaveRequestObj.eventDetails.emailTemplateID = isNullOrEmptyString(this.campaignEventForm.controls.emailTemplateID.value) ? 0 : this.campaignEventForm.controls.emailTemplateID.value;
                campaignEventSaveRequestObj.fieldvalue = this.campaignEventForm.controls.fTField.value;

                break;
            case eCampaignEventType.LiveConnect_Card:
                campaignEventSaveRequestObj.eventDetails.subject = this.campaignEventForm.controls.subject.value;
                campaignEventSaveRequestObj.eventDetails.notes = this.campaignEventForm.controls.notes.value;
                break;
            case eCampaignEventType.Voice_Drop:
                campaignEventSaveRequestObj.eventDetails.emailAddedLink = eCampaignEventAddedLink.None;
                campaignEventSaveRequestObj.eventDetails.emailTemplateID = this.campaignEventForm.controls.emailTemplateID.value;
                break;
            case eCampaignEventType.Voice_Call:
                campaignEventSaveRequestObj.eventDetails.fTField = this.campaignEventForm.controls.fTField.value;
                campaignEventSaveRequestObj.eventDetails.subject = this.campaignEventForm.controls.subject.value;
                campaignEventSaveRequestObj.eventDetails.emailTemplateID = this.campaignEventForm.controls.emailTemplateID.value == '0' ? 0 : this.campaignEventForm.controls.emailTemplateID.value;
                break;
            case eCampaignEventType.Task:
                campaignEventSaveRequestObj.eventDetails.notes = this.campaignEventForm.controls.notes.value;
                campaignEventSaveRequestObj.eventDetails.reminderCLP = this.campaignEventForm.controls.reminderCLP.value;
                campaignEventSaveRequestObj.eventDetails.reminderEmail = this.campaignEventForm.controls.reminderEmail.value;
                campaignEventSaveRequestObj.eventDetails.documentList = "";
                this.documentList.forEach(item => {
                    campaignEventSaveRequestObj.eventDetails.documentList += item + ',';
                })
                campaignEventSaveRequestObj.eventDetails.documentList = campaignEventSaveRequestObj.eventDetails.documentList.substring(0, campaignEventSaveRequestObj.eventDetails.documentList.length - 1);
                break;
            case eCampaignEventType.Appointment:
                campaignEventSaveRequestObj.eventDetails.reminderCLP = this.campaignEventForm.controls.reminderCLP.value;
                campaignEventSaveRequestObj.eventDetails.reminderEmail = this.campaignEventForm.controls.reminderEmail.value;
                campaignEventSaveRequestObj.eventDetails.typeCode = this.campaignEventForm.controls.typeCode.value;
                campaignEventSaveRequestObj.eventDetails.apptStartTime = this.campaignEventForm.controls.apptStartTime.value;
                campaignEventSaveRequestObj.eventDetails.apptLength = this.campaignEventForm.controls.apptLength.value;
                campaignEventSaveRequestObj.eventDetails.reminderLength = this.campaignEventForm.controls.reminderLength.value;
                campaignEventSaveRequestObj.eventDetails.reminderEmails = this.campaignEventForm.controls.reminderEmails.value;
                campaignEventSaveRequestObj.eventDetails.subject = this.campaignEventForm.controls.subject.value;
                campaignEventSaveRequestObj.eventDetails.notes = this.campaignEventForm.controls.notes.value;
                campaignEventSaveRequestObj.eventDetails.documentList = "";
                this.documentList.forEach(item => {
                    campaignEventSaveRequestObj.eventDetails.documentList += item + ',';
                })
                campaignEventSaveRequestObj.eventDetails.documentList = campaignEventSaveRequestObj.eventDetails.documentList.substring(0, campaignEventSaveRequestObj.eventDetails.documentList.length - 1);
                campaignEventSaveRequestObj.eventDetails.fTField = "False";
                campaignEventSaveRequestObj.eventDetails.fTEditType = eFieldType.None;
                campaignEventSaveRequestObj.eventDetails.txtMsgTemplateID = this.campaignEventForm.controls.txtMsgTemplateID.value;
                campaignEventSaveRequestObj.eventDetails.fTValue = this.campaignEventForm.controls.fTValue.value;
                campaignEventSaveRequestObj.eventDetails.emailTemplateID = this.campaignEventForm.controls.emailTemplateID.value;
                campaignEventSaveRequestObj.eventDetails.mailMergeTemplateID = this.campaignEventForm.controls.mailMergeTemplateID.value;
                campaignEventSaveRequestObj.eventDetails.cTCLPUserID = this.campaignEventForm.controls.cTCLPUserID.value ?? 0;
                campaignEventSaveRequestObj.eventDetails.isTxtMsgReminder = this.campaignEventForm.controls.isTxtMsgReminder.value;
                campaignEventSaveRequestObj.eventDetails.isTxtMsgUserReminder = this.campaignEventForm.controls.isTxtMsgUserReminder.value;

                campaignEventSaveRequestObj.strApptQualStartTime = this.datepipe.transform(this.campaignEventForm.controls.apptQualStartTime.value.toString(), 'MMddyyyy hhmmssa');
                campaignEventSaveRequestObj.strApptQualEndTime = this.datepipe.transform(this.campaignEventForm.controls.apptQualEndTime.value.toString(), 'MMddyyyy hhmmssa');
                campaignEventSaveRequestObj.apptQualDelayNum = this.campaignEventForm.controls.apptQualDelayNum.value;
                campaignEventSaveRequestObj.apptQualDelayType = this.campaignEventForm.controls.apptQualDelayType.value;
                campaignEventSaveRequestObj.eventDetails.salutation = this.campaignEventForm.controls.salutation.value;
                campaignEventSaveRequestObj.eventDetails.confirmationEmailTemplateID = this.campaignEventForm.controls.confirmationEmailTemplateID.value;
                campaignEventSaveRequestObj.eventDetails.confirmationTxtMsgTemplateID = this.campaignEventForm.controls.confirmationTxtMsgTemplateID.value;
                /* --------------------*/
                if (this.campaignEventForm.controls.isPhoneCall.value)
                    campaignEventSaveRequestObj.eventDetails.cTAction = eCampaignStatus.Active;
                else
                    campaignEventSaveRequestObj.eventDetails.cTAction = eCampaignStatus.None;
                /*------------------------*/
                if (this.campaignEventForm.controls.isShowNoteInUserReminder.value)
                    campaignEventSaveRequestObj.eventDetails.cTCampaignTemplateID = 1;
                else
                    campaignEventSaveRequestObj.eventDetails.cTCampaignTemplateID = 0;
                /* --------------------*/
                if (this.campaignEventForm.controls.isSendImmediateReminder.value)
                    campaignEventSaveRequestObj.eventDetails.mailingCategory = eMailingCategory.Mail;
                else
                    campaignEventSaveRequestObj.eventDetails.mailingCategory = eMailingCategory.None;
                /* --------------------*/
                if (this.isApptQualCall) {
                    campaignEventSaveRequestObj.eventDetails.toChoice = eCampaignEventNoteEmailToChoice.Contact
                    campaignEventSaveRequestObj.eventDetails.cTAction = eCampaignStatus.Active;
                }
                else
                    campaignEventSaveRequestObj.eventDetails.toChoice = eCampaignEventNoteEmailToChoice.None;
                break;
        }
        this.campaignEventFormSubmitted = true
        if (this.campaignEventForm.valid)

            this.saveSFAEvents(campaignEventSaveRequestObj, +this.selectedEventType);
        else {
            this._notifyService.showError("Please Fill Mandatory fields!!!", '', 3000);
            this.campaignEventForm.markAllAsTouched();
        }
    }

    onClickIsSendConfirmation(event) {
        this.isSendImmReminder = event.target.checked;
        if (this.isSendImmReminder) {
            if (this.campaignEventForm.controls.emailTemplateID.value > 0)
                this.campaignEventForm.get("confirmationEmailTemplateID").setValue(this.campaignEventForm.controls.emailTemplateID.value);
            else
                this.campaignEventForm.get("confirmationEmailTemplateID").setValue(0);

            if (this.campaignEventForm.controls.txtMsgTemplateID.value > 0)
                this.campaignEventForm.get("confirmationTxtMsgTemplateID").setValue(this.campaignEventForm.controls.txtMsgTemplateID.value);
            else
                this.campaignEventForm.get("confirmationTxtMsgTemplateID").setValue(0);
        }
    }

    onChangeCustomActionList(value: number) {
        if (value == -1)
            this.campaignEventForm.get("mailMergeTemplateID").setValue(0);
    }

    onChangeApptEmailTemp(value: number) {
        if (value == -1)
            this.campaignEventForm.get("cTCLPUserID").setValue(0);

    }

    getTypeDisplay(type: eCampaignEventType) {
        switch (+type) {
            case eCampaignEventType.Appointment:
                return "Appointment";
            case eCampaignEventType.Task:
                return "Task";
            case eCampaignEventType.Email:
                return "Email";
            case eCampaignEventType.Zapier_Event:
                return "Zapier Event";
            case eCampaignEventType.Campaign_Trigger:
                return "Campaign Trigger";
            case eCampaignEventType.Field_Trigger:
                return "Field Trigger";
            case eCampaignEventType.TxtMsg:
                return "Text Message";
            case eCampaignEventType.Voice_Drop:
                return "Voice Drop";
            case eCampaignEventType.Voice_Call:
                return "Voice Call";
            case eCampaignEventType.LiveConnect_Card:
                return "LiveConnect";
        }
    }

    validMobile() {
        var _out = true;
        var _str = this.campaignEventForm.get("reminderEmails")?.value;
        if (this.campaignEventForm.controls['toChoice'].value == eCampaignEventNoteEmailToChoice.Other && _str !== '' && !_str?.startsWith("PH_")) {
            if (!isNumber(this._localService.StripPhone(_str))) {
                _out = false;
            }
            else if (this._localService.StripPhone(_str).length !== 10) {
                _out = false;
            }
            else {
                this.campaignEventForm.get("reminderEmails").setValue(this._localService.StripPhone(_str));
            }
        }
        else if (this.campaignEventForm.controls['toChoice'].value == eCampaignEventNoteEmailToChoice.Other && _str == '') {
            _out = false;
        }
        return _out;
    }

    onChangeContactFT(value: string, isNew: boolean = true) {
        if (isNew) {
            this.campaignEventForm.get("emailTemplateID").setValue(0);
            this.campaignEventForm.get("fTValue").setValue("");
            this.campaignEventForm.get("bCCField").setValue("");
        }
        this.initialiseBooleanValues();
        switch (value) {
            case "Shareable": case "Check1": case "Check2": case "Check3": case "Check4": case "Check5": case "Check6":
                this.isShowFtCheckbox = true;
                this.isShowEditTypeDD = false;
                break;
            case "HistoryNote":
                if (isNew) {
                    this.campaignEventForm.get("emailTemplateID").setValue(0);
                    this.campaignEventForm.get("fTValue").setValue("");
                }
                this.isShowEditTypeDD = false;
                this.isShowHistoryNoteField = true;
                break;
            case "Comments": case "Scope":
                this.isShowFtTextareaField = true;
                break;
            case "User":
                this.isShowEditTypeDD = false;
                if (isNew)
                    this.campaignEventForm.get("fTValue").setValue("0");
                this.isShowDDUserField = true;
                break;
            case "Buzz":
                this.isShowEditTypeDD = false;
                break;
            case "Tag":
                this.getTagListByCompany();
                this.isShowTagField = true;
                break;
            case "isOptOutTxtMsg":
                if (isNew)
                    this.campaignEventForm.get("fTValue").setValue("0");
                this.isShowOutputTxtMsgField = true;
                break;
            case "CMCustomDate1": case "CMCustomDate2": case "CMCustomDate3": case "dtStart": case "dtEnd": case "dtRevenue": case "dtCustom1": case "dtCustom2": case "dtCustom3":
                if (isNew)
                    this.campaignEventForm.get("fTValue").setValue("PH_CDate");

                this.isShowDateField = true;
                this.isShowDateTxtField = true;
                break;
            case "Class1Code": case "Class2Code": case "Class3Code": case "Class4Code": case "Class5Code": case "Class6Code": case "Class7Code": case "Class8Code":
            case "WinProbability": case "LeadStatusCode": case "LeadClass1Code": case "LeadClass2Code": case "LeadClass3Code": case "LeadClass4Code": case "LeadClass5Code":
            case "LeadClass6Code": case "LeadClass7Code": case "LeadClass8Code": case "LeadClass9Code":
                this.isShowEditTypeDD = false;
                if (isNew)
                    this.campaignEventForm.get("fTValue").setValue("0");
                var result = value.slice(0, 0) + value[0].toLowerCase() + value.slice(1);
                this.ftDDItems = (this.ownerType == Number(eNoteOwnerType.Lead)) ? this.leadFieldConfiguration[result]?.items : this.contactFieldConfiguration[result]?.items;
                this.isShowDDField = true;
                break;
            case "Metric":
                this.isShowEditTypeDD = false;
                this.isShowMetricField = true;
                break;
            case "Revenue": case "Volume": case "CustomMoney1": case "CustomMoney2": case "CustomMoney3": case "CustomMoney4":
                this.isShowFtInputNumberField = true;
                break;
            default:
                this.isShowFtInputField = true;
                break;
        }
    }

    //Tag Section

    async getTagListByCompany() {
        await this._tagSettingService.tag_GetListByCLPCompany(this.encryptedUser, this.user?.cLPCompanyID, this.ownerType == 3 ? eNoteOwnerType.Lead : eNoteOwnerType.Contact)
            .then(async (result: TagData[]) => {
                if (!isNullOrUndefined(result)) {
                    this.tagListByCompany = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-event.getTagListByCompany", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'ownerType ' + this.ownerType);
                this._utilityService.handleErrorResponse(err);
            });
    }


    selectedTagChangeEvent(tag: string = "") {
        if (typeof (tag) === 'number' && parseInt(tag) > 0) {
            var tagData: TagData = <TagData>{};
            tagData = this.tagListByCompany?.filter(x => x.tagID == parseInt(tag))[0];
            this.customTagDisplay = "";
        } else if (tag.length > 0 && tag.trim() != "") {
            this.customTagDisplay = tag;
        }
    }


    async addNewTag(value: string) {
        var tagData: TagsFields = <TagsFields>{};
        tagData.cLPCompanyID = this.user?.cLPCompanyID;
        tagData.ownerType = this.ownerType;
        tagData.tag = value;
        tagData.tagID = 0;
        await this._tagSettingService.tagUpdate(this.encryptedUser, tagData)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result?.messageBool)
                        this.campaignEventForm.get("fTValue").setValue(result?.messageInt);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-event.addNewTag", err.message, tagData);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getAttachedList(value: any) {
        this.documentList = value;
    }

    async deleteCampaignEvent() {
        await this._campaignService.deleteCampaignEvent(this.encryptedUser, this.campaignEventId, this.campaignTemplateId)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result.messageBool) {
                        this._notifyService.showSuccess(result.messageString, '', 3000);
                        this.cancelEvent.emit(true);
                        this.refreshEventList.emit(true);
                    } else {
                        this._notifyService.showError(result.errorMsg, '', 3000);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("campaign-event.deleteCampaignEvent", err.message, null, 'campaignEventId ' + this.campaignEventId + 'campaignEventId ' + this.campaignTemplateId);
                this._utilityService.handleErrorResponse(err);
            });

    }

    onDateField() {
        this.isShowDateTxtField = !this.isShowDateTxtField
        if (this.isShowDateTxtField)
            this.campaignEventForm.get("fTValue").setValue("PH_CDate");
    }

}
