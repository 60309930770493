import { Data } from "@angular/router";


export enum eCLPVARStatus {
    None = 0,
    Active = 1,
    InActive = 2,
    AppliedFor = 3
}

export enum eLongCodeLineVendor {
    Unknown = 0,
    Bandwidth = 0,
    Twilio = 1,
}

export enum eVoiceCallLogSource {
    Unknown = 0,
    MainSite = 1,
    SAMSite = 2,
    AP = 3,
    Service = 4,
    Webhook = 5
}

export enum eUpgradeStep {
    None = 0,
    One = 1,
    Two = 2,
    Three = 3,
    Four = 4,
    Done = 5
}

export enum eLiveConnectItemObjectType {
    Unknown = 0,
    TxtMsgIB = 3,
    DynamicScheduledApptDep = 4,
    VoiceMail = 5,
    VoiceCall = 6,
    Contact = 7,
    Slidecast = 8,
    AutomationAlert = 9,
    Appt = 10,
    Agreement = 15
}

export enum eLiveConnectCLPUserStatus {
    None = 0,
    Checked_In = 1,
    Checked_Out = 2,
    Disabled = 3,
    Checked_In_No_Ring = 4
}

export enum eCLPBillingProfileAcctType {
    None = 0,
    Credit_Card = 1,
    ACH = 2,
}

export enum eTxtMsgLogObjectType {
    Unknown = 0,
    TxtBlast = 1,
    ShortCodeTxtMsg = 2,
    TxtMsgIB = 3,
    LongCodeTxtMsg = 4,
    VoiceDrop = 5,
    VoiceCall = 6,
    LongCodeMMSTxtMsg = 7,
    UserAlertTxtMsg = 8,
    TollFreeTxtMsg = 9,
    TollFreeMMSTxtMsg = 10,
    VoiceCallRecording = 11,
    VoiceRecording = 12,
    Slidecast = 13
}

export enum eTEGBizLine {
    None = 0,
    SalesOptima = 1,
    Discover_Boat_Clubs = 2,
    Grow_Boat_Clubs = 3,
    Slidecast = 4,
    SalesOptima_Digital = 5,
    SalesOptima_Professional_Services = 6
}

export enum eCreditCardFields {
    SOCRMPrimary = 1,
    SOCRMBackup = 2,
    digitalPrimary = 3,
    digitalBackup = 4,
    proServPrimary = 5,
    proServBackup = 6
}

export enum eLiveConnectCheckedInMethod {
    None = 0,
    Manual = 1,
    Auto_Dash_Timeout = 2,
    Auto_Dash_Window_Close = 3,
    Auto_Service_Timeout = 4,
    Admin_Manual = 5
}

export enum eLiveConnectItemStatus {
    None = 0,
    Is_New = 1,
    Handled = 2,
    Closed = 3,
    Pinned = 4,
    Alerts = 5,
    ToBeDeleted = 9,
    Open = 10
}

export enum eLiveConnectItemActionStatus {
    Handle,
    MarkAsAlert,
    UnHandle,
    Close,
    Pin,
    Pinned,
    Open,
    MarkAsNew
}

export enum eSAMFieldStatus {
    ViewOnly = 0,
    ViewandEdit = 1,
    ViewandEditMandatory = 2,
    Hide = 3
}

export enum eMobileBlockType {
    Unknown = 0,
    UnBlock = 1,
    Block = 2,
}

export enum eMobileOptType {
    Unknown = 0,
    OptIn = 1,
    OptOutByContact = 2,
    OptOutByUser = 3
}

export enum eContactTxtMsgStatus {
    xHardOptOut = 0,
    HardOptIn = 1,
    SoftOptIn = 2,
    UserOptOut = 3
}

export enum eCLPVARPaymentSchedule {
    None = 0,
    Monthly = 1,
    Quarterly = 2,
    SemiAnnually = 3,
    Annually = 4
}

export enum eCLPVARContractType {
    None = 0,
    Value_Added_Reseller = 1,
    Referral = 2
}

export enum eOSAddressType {
    None = 0,
    Business = 1,
    Home = 2,
    Other = 3
}

export enum eTicketCategory {
    Unknown = 0,
    Question = 1,
    Need_Help = 2,
    Report_a_problem = 3,
    Idea_for_new_feature = 4,
    Urgent = 5,
    Request_Training = 6
}

export enum eHistoryStatus {
    Unknown = 0,
    Completed = 1,
    Pending = 2
}

export enum eCustomSearchCategory {
    Company = 1,
    Contact = 2,
    Lead = 3
}

export enum Contact2_eSplit {
    c_CLPUserID = 0,
    cpu_TeamCode = 1,
    cpu_OfficeCode = 2,
    c_CLPCompanyID = 3,
    c_Class1Code = 11,
    c_Class2Code = 12,
    c_Class3Code = 13,
    c_Class4Code = 14,
    c_Class5Code = 15,
    c_Class6Code = 16,
    c_Class7Code = 17,
    c_Class8Code = 18
}

export enum eDocumentCategory {
    Unknown = 0,
    Appointment = 1,
    Contact = 2,
    Lead = 3,
    Mailing = 4,
    Personal = 5,
    Company = 6,
    Task = 9
}

export enum eNoteOwnerType {
    Unknown = 0,
    Personal = 1,
    Contact = 2,
    Lead = 3,
    Company = 4,
    Project = 5,
    LinkGroup = 6,
    Note = 7,
    Invoice = 8,
    Mailing = 11
}

export enum eNoteStatus {
    Unknown = -1,
    Completed = 0,
    Pending = 1,
    InProcessing = 2,
    Failed = 3,
    ToBeDeleted = 9
}

export enum eNoteEmailToChoice {
    None = 0,
    Contact = 1,
    User = 2,
    Other = 3,
    Link = 4,
    Fax2Mail = 5,
    ContactAllEmails = 6
}

export enum eTicketStatus {
    AllOpenOrUnread = 9,
    Unknown = 0,
    Active = 1,
    Being_Handled = 2,
    Resolved = 3,
    Under_Consideration = 4,
    User_Feedback_Required = 5
}

export enum eCLPCompanyStatus {
    DoesNotExist = 0,
    IsValid = 1,
    OnIntro = 2,
    Expired = 3,
    Disabled = 4,
    FreeAccount = 5,
}


export enum eCLPPricingContract {
    Monthly = 0,
    AnnualPaidMonthly = 1,
    AnnualPrePaid = 2,
    Unknown = 3
}

export enum eServiceType {
    Unknown = 0,
    DailyServ = 1,
    EmailServ = 2,
    ParserServ = 3,
    DropBoxServ = 4,
    ReminderServ = 5,
    ExportServ = 6,
    GSyncServ = 7,
    TxtMsgServ = 8,
    VoiceServ = 9,
    EvolveLeadServ = 10,
    TxtMsgCampaignServ = 11,
    InventHelpLeadServ = 12
}

export enum eSectionLead {
    General = 0,
    CustomDateFields = 1,
    CustomTextFields = 2,
    AdditionalMultilineTextFields = 3,
    RevenueRelatedFields = 4,
    CustomMoneyFields = 5,
    CustomClassificationDropDownFields = 6,
    CustomClassificationCheckboxFields = 7,
    None = -1,
}

export enum eSection {
    System = 0,
    Communication = 1,
    Address = 2,
    AddtionalInformation = 3,
    ClassificationDropDown = 4,
    ClassificationCheckBox = 5,
    Comments = 6,
    ImportantDates = 7,
    MoreFields = 8,
    General = 9
}

export enum eSectionCompany {
    General = 1,
    AddtionalInformation = 2,
    Communication = 3,
    ClassificationDropDown = 4,
    ClassificationCheckBox = 5,
    Comments = 6
}

export enum eCLPRole {
    StratusExpress = 0,
    Stratus = 1,
    Cirrus = 2,
    Nimbus = 3,
    Unknown = 9
}
export enum eCLPTxnStatus {
    None = 0,
    Pending = 1,
    Success = 2,
    Failure = 3,
}

export enum eCLPInvoiceStatus {
    None = 0,
    Pending = 1,
    Paid = 2,
    Void = 3
}

export enum eCLPTxnType {
    None = 0,
    Payment = 1,
    Credit = 2,
    Invoice = 3,
}

export enum eContractStatus {
    None = 0,
    Not_Begun = 1,
    Initial_Setup_Complete = 2,
    Initial_Payment_Pending = 3,
    Initial_Payment_Received = 4,
    Finalized = 5,
    Finalized_And_Secured = 6,
    Adding_Member = 7,
    Executed = 8,
    Abandonned = 9
}

export enum eMemberStatus {
    None = 0,
    Setup_Incomplete = 1,
    Ready_To_Email = 2,
    Email_Sent = 3,
    Logged_In = 4,
    Completed_Contact_Info = 5,
    Completed_Agreement_Review = 6,
    Completed_Billing_Info = 7,
    ESigned_Contract = 8,
    Ready_To_Bill = 9,
    Initial_Payment_Received = 10
}

export enum eUserRole {
    None = 0,
    General = 1,
    Manager = 2,
    Administrator = 3,
    SuperUser = 4,
    SOAdminUser = 5
}

export enum eMessageType {
    Info = 0,
    Warning = 1,
    StopAction = 2,
    Success = 3
}

export enum eMemberType {
    AMember = 0,
    BMember = 1,
    CMember = 2,
    DMember = 3
}

export enum eCampaignTemplateType {
    Unknown = 0,
    Mailing = 1,
    Workflow = 2
}

export enum eCampaignEventType {
    None = 0,
    Appointment = 1,
    Task = 2,
    Email = 3,
    Zapier_Event = 5,
    Campaign_Trigger = 6,
    Field_Trigger = 7,
    TxtMsg = 8,
    Voice_Drop = 9,
    Voice_Call = 10,
    LiveConnect_Card = 11,
}

export enum eDocumentType {
    unknown = 0,
    doc = 1,
    htm = 2,
    html = 3,
    txt = 4,
    xls = 5,
    pdf = 6,
    ppt = 7,
    png = 8,
    jpg = 9,
    gif = 10,
    tif = 11,
    docx = 12,
    xlsx = 13,
    pptx = 14,
    docm = 15,
    dotm = 16,
    dotx = 17,
    ppsm = 18,
    ppsx = 19,
    pptm = 20,
    xlsb = 21,
    xlsm = 22,
    xps = 23,
    kml = 24
}

export enum eCampaignEventTimeSinceUnit {
    None = 0,
    Minute = 8,
    Hour = 7,
    Day = 3,
    Week = 5,
    Month = 2,
    BusinessDay = 9
}

export enum eReadWrite {
    None = 0,
    ViewOnly = 1,
    ViewAndEdit = 2
}
export enum eFieldStatus {
    Show = 0,
    ShowMandatory = 1,
    Hide = 2
}

export enum eOSStatus {
    Disabled = 0,
    Allowed = 1,
    Downloaded = 2,
    Activated = 3
}

export enum eUserPermissions {
    Disabled = 0,
    Active = 1,
    Unknown = 9
}

export enum eCLPBillingMethodType {
    None = 0,
    CreditCard = 1,
    ACH = 2
}

export enum eButtonActions {
    None = -1,
    Load = 4,
    Save = 1,
    Delete = 0,
    Update = 2,
    Transfer = 3
}

export enum eVIPStatus {
    Unknown = 0,
    Published = 1,
    Draft = 2,
    Archived = 9
}

export enum eCampaignTemplateOwnerType {
    Unknown = 0,
    Personal = 1,
    Contact = 2,
    Lead = 3,
    Proposal = 4,
    Project = 5
}
export enum eFTEditType {
    None = 0,
    Replace = 1,
    Append = 2,
    Remove = 3
}


export enum eCampaignStatus {
    None = 0,
    Active = 1,
    Draft = 2,
    Paused = 3,
    Cancelled = 4,
    Completed = 5
}

export enum eCampaignItemStatusAction {
    None = 0,
    Active = 1,
    eStop = 2,
    Pause = 3,
    RemoveFromCampaign = 4,
    Completed = 5,
    SkipEvent = 6
}


export enum eCampaignEventNoteEmailToChoice {
    None = 0,
    Contact = 1,
    User = 2,
    Other = 3,
    Link = 4,
    Connection = 5
}


export enum eCampaignTemplateImmunityFilterOption {
    NoFilter,
    ImmuneOnly,
    NonImmuneOnly
}

export enum eFieldType {
    Textbox = 0,
    CheckBox = 1,
    Dropdown = 2,
    None = 3,
    Combobox = 4,
    Date = 5,
    MultiLineTextbox = 6,
}

export enum eSOSCStatus {
    New = 0,
    Draft = 1,
    Pending = 2,
    Signed = 3,
    Expired = 4,
}
export enum eFeatures {
    None = 0,
    RelevantTopics = 1,
    OnlineTutorials = 2,
    GettingStartedChecklist = 3,
    SubmitSupportTicket = 4,
    ReferralRewards = 5,
    NewCompany = 6,
    MyCompanies = 7,
    BulkCompanyActions = 8,
    NewContact = 9,
    ViewMyContacts = 10,
    ExcelUploadWizard = 11,
    BulkContactActions = 12,
    CreateContactMap = 13,
    RestoreContacts = 14,
    ManageDuplicates = 15,
    NewLead = 16,
    MyActiveLeads = 17,
    MyLeads = 18,
    BulkLeadActions = 19,
    ViewMailings = 20,
    CreateNewMailing = 21,
    ViewEmailBlastMaiings = 22,
    CreateNewEmailBlastMailing = 23,
    ViewTextBlastMailings = 24,
    CreateNewTextBlastMailing = 25,
    KeywordList = 26,
    AutomationProcesses = 27,
    CustomActionScreens = 29,
    WebForms = 30,
    EmailTemplates = 31,
    MailMergeTemplates = 33,
    TextMessageTemplates = 34,
    ImageBank = 35,
    ClickTrackings = 36,
    VoiceRecordings = 37,
    SlidecastPresentations = 38,
    AppointmentTypeSummary = 39,
    AppointmentbyTwoDimensions = 40,
    CoachCorner = 41,
    QualificationCallReport = 42,
    NoteTypeSummary = 43,
    CallActionButtonClickReport = 44,
    TextMessageLog = 45,
    EmailOpenRateReport = 46,
    SAMVoiceCallReport = 47,
    SlidecastAnalytics = 48,
    SkypeCallLog = 49,
    DistributionbyTwoDimesions = 50,
    DistribtionByManager = 51,
    DistributionbyClassification = 52,
    DistributionbyTwoDimension = 54,
    ReferralReport = 55,
    TagCloud = 56,
    ClickTracking = 57,
    BuzzScore = 58,
    ProjectedRevenuebyManager = 60,
    ProjectedRevenuebyClassification = 61,
    ProjectedRevenuebyMonth = 62,
    DistributionbyTwoDimensions = 63,
    FilterReport = 64,
    InvoiceReport = 65,
    AccountSetup = 66,
    UserSetup = 67,
    SecuritySettings = 68,
    CompanyModuleSettings = 69,
    ContactModuleSettings = 70,
    LeadModuleSettings = 71,
    AppointmentNoteMailingsettings = 72,
    TagSettings = 73,
    TextMessageSettings = 74,
    VoiceSettings = 75,
    SOSecurity = 76,
    SOBillingManager = 77,
    SOServicesContract = 78,
    SOAnnouncements = 79,
    SOFreeTrials = 80,
    SOServiceStatus = 81,
    SOAccountSetup = 82,
    RoleSetup = 83,
    Configuration = 84,
    TeamOfficeSetup = 85,
    CompanySetup = 86,
    WebFormManager = 87,
    SoRepSettings = 88,
    MyLinks = 89,
    EmailTemplate = 90,
    VideoBank = 93,
    PatentPlusAdmins = 94,
    PatentPlusReps = 95,
    ManageRoles = 96,
    OfficeSetup = 97,
    PauseLeads = 98,
    ApprovePauseRequest = 99,
    Reporting = 100,
    ConfigurationAdv = 101,
    ContractsAdministrator = 102,
    Contracts = 103,
    ContractsConfiguratio = 104,
    ViewContracts = 105,
    ReceivedContracts = 106,
    ResearchReview = 107,
    ResearchSubmission = 108,
    ResearchPrinters = 109,
    ResearchWriting = 110,
    ContractApproval = 111,
    ResearchTyping = 112,
    ClientServicesReview = 113,
    Portal = 114,
    AllContracts = 115,
    ContractList = 116,
    ContractsConfig = 117,
    Leads = 118
}

export enum eVIPSlideContentType {
    Unknown = 0,
    HTML = 1,
    Image = 2,
    YouTube = 3,
    Mail_Merge = 4,
    Email_Template = 5,
    Web_Page = 6
}

export enum eDocRepository {
    SalesOtima = 0,
    Azure = 1
}

export enum eClassCodes {
    Class1Code = 0,
    Class2Code = 1,
    Class3Code = 2,
    Class4Code = 3,
    Class5Code = 4,
    Class6Code = 5,
    Class7Code = 6,
    Class8Code = 7
}

export enum eAppointmentSettings {
    ApptContactTypeCode = 0,
    ApptGeneralTypeCode = 1,
    ApptLeadTypeCode = 2,
    MailingtypeCode = 3,
    NoteTypeCode = 4
}

export enum eLeadSettings {
    LeadClass1Code = 0,
    LeadClass2Code = 1,
    LeadClass3Code = 2,
    LeadClass4Code = 3,
    LeadClass5Code = 4,
    LeadClass6Code = 5,
    LeadClass7Code = 6,
    LeadClass8Code = 7,
    LeadClass9Code = 8,
    LeadStatusCode = 9
}
export enum webFormAction {
    Start = 1,
    Stop = 2,
    Pause = 3,
    Remove = 2,
}

export enum eCompanySettings {
    CoClass1Code = 0,
    CoClass2Code = 1,
    CoClass3Code = 2,
    CoClass4Code = 3,
    CoClass5Code = 4,
    CoClass6Code = 5,
    CoClass7Code = 6,
    CoClass8Code = 7
}

export enum eColor {
    Red = 0,
    Green = 1,
    Darkgreen = 2,
    Unknown = 3,
}
export enum eBillingViewMode {
    MainView = 0,
    UpgradeStart = 1,
    UpgradeConfirm = 2,
    UpgradeProcess = 3,
    UsersStart = 4,
    UsersConfirm = 5,
    UsersSetup = 6
}

export enum eExportRequestObjectType {
    Unknown = 0,
    Company = 1,
    Contact = 2,
    Lead = 3,
    LeadInvoice = 31,
    Mailing = 4,
    Campaign_Item = 5,
    KML_File = 9,
    Deverus_Activity_Report = 101,
    AFH_Activity_Report = 200,
    SkyRise_Activity_Report = 250,
    Harvest_Business_Lead_Report = 300,
    InventHelp_Scorecard_Report = 400,
    InventHelp_ScorecardByCode_Report = 401,
    InventHelp_ScorecardByKeyword_Report = 402,
    InventHelp_ScorecardCompare_Report = 403,
    InventHelp_ScorecardByTVCode_Report = 404,
    InventHelp_ScorecardByApptSetter_Report = 405,
}

export enum eExportRequestStatus {
    None = 0,
    Pending = 1,
    InProgress = 2,
    Completed = 3,
    ToBeDeleted = 4,
    Archived = 5
}

export enum CreateExportFor {
    Unknown = 0,
    ScoreCardByCode = 1,
    ScoreCardByKeyword = 2,
    ScoreCardByTeam = 3,
    ScoreCardCompare = 4,
    ScoreCardbyTVSource = 5,
    leadCreate = 6,
    linkContactScreen = 7,
    MyCompany = 8,
    LeadInvoice = 31
}

/*Excel Upload Section*/
export enum eEditType {
    Replace = 0,
    AddTo = 1,
    Clear = 2,
    Remove = 3,
    OwnerTransfer = 4,
    PermanentDelete = 5
}

export enum eUploadContactActionToTake {
    DoNotUpload = 0,
    Overwrite = 1,
    Upload = 2,
    None = 3
}

export enum eTaskCategory {
    Unknown = 0,
    Personal = 1,
    Contact = 2,
    Lead = 3,
    Proposal = 4,
    Project = 5
}

export enum eTaskPriority {
    None = 0,
    Low = 1,
    Medium = 2,
    High = 3
}

export enum eApptCategory {
    Personal = 0,
    Company = 1,
    Contact = 2,
    Lead = 3,
    None = 4,
    Proposal = 5,
    Project = 6,
    CompanyObject = 7
}

export enum eCalCalendar {
    User = 0,
    Team = 1,
    Company = 2,
    Office = 3,
}

export enum eDDField {
    Unknown = 0,
    CoClass1Code = 1,
    CoClass2Code = 2,
    CoClass3Code = 3,
    CoClass4Code = 4,
    CoClass5Code = 5,
    CoClass6Code = 6,
    CoClass7Code = 7,
    CoClass8Code = 8,
    Class1Code = 11,
    Class2Code = 12,
    Class3Code = 13,
    Class4Code = 14,
    Class5Code = 15,
    Class6Code = 16,
    Class7Code = 17,
    Class8Code = 18,
    Multi1Code = 19,
    Multi2Code = 110,
    Multi3Code = 111,
    Multi4Code = 112,
    LeadClass1Code = 20,
    LeadClass2Code = 21,
    LeadClass3Code = 22,
    LeadClass4Code = 23,
    LeadClass5Code = 24,
    LeadClass6Code = 25,
    LeadClass7Code = 26,
    LeadClass8Code = 27,
    LeadClass9Code = 28,
    LeadMulti1Code = 210,
    LeadMulti2Code = 211,
    LeadMulti3Code = 212,
    LeadMulti4Code = 213,
    LeadStatusCode = 214,
    ZapierEventCode = 300,
    TeamCode = 500,
    OfficeCode = 600,
    ApptGeneralTypeCode = 700,
    ApptContactTypeCode = 701,
    ApptLeadTypeCode = 702,
    ApptProposalTypeCode = 703,
    ApptProjectTypeCode = 704,
    NoteTypeCode = 800,
    MailingTypeCode = 900
}

export enum eGoalType {
    None = 0,
    ApptGeneralType = 1,
    ApptContactType = 2,
    ApptLeadType = 3,
    ApptProposalType = 4,
    ApptProjectType = 5,
    NewLeads = 6,
    LeadRevenueGross = 7,
    LeadRevenueNet = 8,
    NewContacts = 9,
    LeadStatus = 10,
    Class1Code = 11,
    Class2Code = 12,
    Class3Code = 13,
    Class4Code = 14,
    Class5Code = 15,
    Class6Code = 16,
    Class7Code = 17,
    Class8Code = 18,
    LeadClass1Code = 20,
    LeadClass2Code = 21,
    LeadClass3Code = 22,
    LeadClass4Code = 23,
    LeadClass5Code = 24,
    LeadClass6Code = 25,
    LeadClass7Code = 26,
    LeadClass8Code = 27,
    LeadClass9Code = 28,
}
export enum eGoalTypeCategory {
    None = 0,
    ApptGeneralType = 1,
    ApptContactType = 2,
    ApptLeadType = 3,
    ApptProposalType = 4,
    ApptProjectType = 5,
    General = 6,
    LeadStatus = 10,
    Class1Code = 11,
    Class2Code = 12,
    Class3Code = 13,
    Class4Code = 14,
    Class5Code = 15,
    Class6Code = 16,
    Class7Code = 17,
    Class8Code = 18,
    LeadClass1Code = 20,
    LeadClass2Code = 21,
    LeadClass3Code = 22,
    LeadClass4Code = 23,
    LeadClass5Code = 24,
    LeadClass6Code = 25,
    LeadClass7Code = 26,
    LeadClass8Code = 27,
    LeadClass9Code = 29,
}

export enum eCalenderView {
    Month,
    Day,
    Week
}

export enum eTaskStatus {
    Pending = 0,
    Completed = 1,
    OnHold = 2,
    None = 3,
    ToBeDeleted = 9
}
export enum eMailingStatus {
    Pending = 0,
    Cancelled = 1,
    Completed = 2,
    None = 3,
    In_Process = 4,
    Awaiting_MMDoc = 5,
    Awaiting_Configuration = 6,
    Being_Configured = 7,
    Paused = 8
}

export enum eApptStatus {
    Pending = 0,
    Cancelled = 1,
    Completed = 2,
    None = 3,
    Bumped = 4,
    CompleteAndCampaignStop = 5,
    ToBeDeleted = 9
}

export enum eReportFilterBy {
    All = 0,
    User = 1,
    Team = 2,
    Office = 3
}


export enum eReportTimePeriod {
    n0to3 = 1,
    n3to6 = 2,
    n6to12 = 3,
    nYTD = 4,
    nThisYear = 5,
    nLastYear = 6,
    nSpecificDates = 7
}

export enum eMeasure {
    NumLeads = 0,
    Volume = 1,
    Revenue = 2,
    CustomMoney1 = 3,
    CustomMoney2 = 4,
    CustomMoney3 = 5,
    CustomMoney4 = 6
}

export enum SearchContactBy {
    ClickTracking = 1,
    Referral = 2,
    Referrer = 3,
    TagCloud = 4,
    BIPNoBuy = 5,
    SUBNoBuy = 6,
    ContactDistByManager = 7,
    ContactDistByClassification = 8,
    ContactDistByTwoDimen = 9,
    TagCloundByUserForContact = 10,
    companyModule = 11,
    LeadInvoice = 12,
    ScoreCardByCode = 13,
    ScoreCardByKeyword = 14,
    ScoreCard = 15,
    ScoreCardCompare = 16,
    ScoreCardByApptSetter = 17,
    ScoreCardByTVSourceCode = 18,
    SkypeCallAttempt = 19,
    LeadSearchResult = 20,
    Campaign = 21,
    Contact = 22,
    HomeSearch = 23
}

export enum eMsgType {
    None = 0,
    Company = 1,
    Reminder = 2,
    User = 3,
    System = 4,
    Meeting = 5,
    TaskReminder = 6
}

export enum eCompanyType {
    None = 0,
    Business = 1,
    Personal = 2
}

export enum eLeadGroupBy {
    None = 0,
    CLPUserID = 1,
    LeadStatusCode = 2,
    NoneBasic = 3
}

export enum eSkypeSettings {
    Disabled = 0,
    Enabled = 1,
    EnbaledWithLogging = 2,
    VoiceCall = 3
}

export enum eTxtMsgStatus {
    Unknown = -1,
    Completed = 0,
    Pending = 1,
    InProgress = 2,
    Sent = 3,
    CheckInProgress = 4,
    Failed = 5,
    Responded = 6,
    Paused = 7,
    ToBeDeleted = 9,
    Cancelled = 8
}

export enum eVoiceDropStatus {
    Unknown = -1,
    Sent = 0,
    Pending = 1,
    InProgress = 2,
    Failed = 3,
    Delivered = 4,
    NotDelivered = 5,
    Paused = 6,
    ToBeDeleted = 9
}

export enum eRptFilterBy {
    None = 0,
    User = 1,
    Team = 2,
    Office = 3
}

// Fou UI only
export enum eRptdistMng {
    User = 0,
    Team = 1,
    Office = 2,
    Company = 3,
}

export enum eVoiceRecordingStatus {
    Unknown = -1,
    Not_Setup = 0,
    Ready_For_Use = 1,
    Initiating_Call = 2,
    Initiated_Call = 3,
    Recording_In_Progress = 4,
    Recording_Available = 5,
    Awaiting_CDN_Upload = 6,
    Processing_CDN_Upload = 7,
    Failed = 8,
    Responded = 9,
    To_Be_Deleted = 10,
    Recording_Ended = 11

}
export enum eVoiceRecordingServiceStatus {
    Completed = 0,
    Pending = 1,
    In_Process = 2
}

export enum eMailingCategory {
    None = 0,
    Mail = 1,
    Email = 2,
    ContactUpdate = 3,
    ReferralRequest = 4,
    CLPReferral = 5,
    TxtMsg = 6,
    VoiceDrop = 7
}

export enum eMailingFromType {
    MailingOwner = 0,
    ContactOwner = 1,
    SpecificUser = 2
}

export enum eCampaignEventAddedLink {
    None = 0,
    CampaignUpdate = 1,
    ReferralRequest = 2
}
export enum eMailingSalutation {
    None = 0,
    FirstOnly = 1,
    FirstAndLast = 2,
}

export enum eMailingToType {
    Contact = 0,
    ContactAllEmails = 1
}

export enum eTempMMDocumentType {
    Unknown = 0,
    Personal = 1,
    Contact = 2,
    Lead = 3,
    Proposal = 4,
    Project = 5,
    LinkGroup = 6,
    Note = 7,
    Invoice = 8,
    Mailing = 11,
    EBlast = 12
}

export enum eTempMMDocumentStatus {
    Unknown = 0,
    Pending = 1,
    InProgress = 2,
    Completed = 3,
    SingleMailMerge = 4,
    ToBeDeleted = 5,
}


export enum eMailingContactResult {
    Pending = 0,
    Cancelled = 1,
    Success = 2,
    Failed = 3,
    Unknown = 4,
    Opened = 5,
    InProcess = 6,
    Paused = 7,
    Bounced = 8
}

export enum eRedirectedTo {
    newMailing = 1,
    newBlast = 2,
    bulkAppts = 3,
    bulkContacts = 4,
    addToCampaign = 5
}

export enum eRecurPattern {
    Daily = 1,
    Weekly = 2,
    Monthly = 3
}

export enum eCLPUserPrefPreferredLine {
    NotSet = 0,
    Mobile = 1,
    Phone = 2,
    OtherPhone = 3,
    Fax = 4
}

export enum eTempListStatus {
    Pending = 0,
    Cancelled = 1,
    Completed = 2,
    ToBeDeleted = 5,
}

export enum eVoiceCallStatus {
    Unknown = -1,
    Completed = 0,
    Pending = 1,
    InProgress = 2,
    AwaitingAnswer = 3,
    CheckInProgress = 4,
    Failed = 5,
    Initiated = 6,
    Ended = 7,
    Missed = 8,
    ToBeDeleted = 9
}

export enum eTheme {
    main = 1,
    silk = 2,
    dark = 3,
    dark_theme = 4,
    new_theme = 5
}

export enum eTxtMsgSettingsStatus {
    None = 0,
    Active = 1,
    Waiting_To_Be_Configured = 2
}

export enum eApptTypeSummary {
    Both = 0,
    Monthly = 1,
    Yearly = 2
}

export enum eReportTimeType {
    Unknown = 0,
    Day = 1,
    Week = 2,
    Month = 3,
    Year = 4,
}


export enum eSplit_LeadSnapshot {
    Manager = 0,
    Team = 1,
    Office = 2,
    Company = 3,
    LeadClass1Code = 20,
    LeadClass2Code = 21,
    LeadClass3Code = 22,
    LeadClass4Code = 23,
    LeadClass5Code = 24,
    LeadClass6Code = 25,
    LeadClass7Code = 26,
    LeadClass8Code = 27,
    LeadClass9Code = 28,
    LeadMulti1Code = 210,
    LeadMulti2Code = 211,
    LeadMulti3Code = 212,
    LeadMulti4Code = 213,
    LeadStatusCode = 10,
    ImportOfficeCode = 13,
    ContactCompany = 14
}

export enum eSplit_Appt2Dim {
    a_CLPUserID = 0,
    cpu_TeamCode = 1,
    cpu_OfficeCode = 2,
    a_CLPCompanyID = 3,
    a_ApptGeneralTypeCode = 4,
    a_ApptContactTypeCode = 5,
    a_ApptLeadTypeCode = 6,
    a_Status = 7
}

export enum eStatusFilter {
    ViewAll = 0,
    CompletedOnly = 1,
    PendingOnly = 2,
    CancelledOnly = 3
}

export enum apptStatusFilter {
    '' = 0,
    Completed = 1,
    Pending = 2,
    Cancelled = 3
}

export enum leadDistribute {
    Manager = 0,
    Team = 2,
    Office = 3,
    Company = 4
}

export enum eGTriggerObjectType {
    Unknown = 0,
    Appt = 1,
    Contact = 2,
    Document = 3,
    Email = 4,
    Calendar = 5,
    MyContacts = 6
}

export enum eGSettingsStatus {
    None = 0,
    Active = 1,
    Failed = 2,
    NotConfigured = 3,
    TokenFailed = 4,
    PreLimConfiguration = 5
}

export enum eViewMode {
    View = 1,
    Edit = 2,
    AddNew = 3,
    Delete = 4,
    Invisible = 5,
    Hide = 6,
    Summary = 7,
    SaveAs = 8
}

export enum eVoiceCall {
    ConnectImmediately = 1,
    MakeCallEdit = 2,
    AddNew = 3,
    Delete = 4,
    Invisible = 5,
    Hide = 6,
    ScheduleCall = 7,
    ViewExisting = 8,
    EditExisting = 9,
}


export enum eInvoiceStatus {
    None = 0,
    Pending = 1,
    Paid = 2,
    Void = 3
}

export enum eEmailOptType {
    Unknown = 0,
    Subscribed = 1,
    UnsubscribedByContact = 2,
    UnsubscribedByUser = 3
}

export enum eSelectionState {
    Normal = 0,
    ProcessAll = 1,
    OnlySelected = 2
}

export enum eViewStep {
    None,
    One,
    Two,
    Three
}

