import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, OnInit, Output, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { Observable, Subscription, timer } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { ConfigDetailsWithKey } from '../../../models/appConfig.model';
import { CampaignEventResponse, CampaignTemplateDDL } from '../../../models/campaign.model';
import { ActivateSFAResponse, AutomationActions, CampaignItem, CampaignItemExtended, CampaignItemExtendedListResponse, CampaignItemListResponse } from '../../../models/campaignItem.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eButtonActions, eCampaignItemStatusAction, eCampaignStatus, eCampaignTemplateOwnerType, eFeatures, eUserRole } from '../../../models/enum.model';
import { IntDropDownItem } from '../../../models/genericResponse.model';
import { Lead } from '../../../models/lead.model';
import { MessageResponse } from '../../../models/message.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { CampaignService } from '../../../services/campaign.service';
import { DashboardService } from '../../../services/dashboard.service';
import { GlobalService } from '../../../services/global.service';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { NotificationService } from '../../../services/notification.service';
import { AppconfigService } from '../../../services/shared/appconfig.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
    selector: 'app-manage-campaigns',
    templateUrl: './manage-campaigns.component.html',
    styleUrls: ['./manage-campaigns.component.css'],
    providers: [GridConfigurationService]
})
export class ManageCampaignsComponent implements OnInit {
    gridHeight;
    eUserRole = eUserRole;
    private encryptedUser: string = '';
    userResponse: UserResponse;
    showSpinner: boolean = false;
    @Input() user: CLPUser;
    @Input() roleFeaturePermissions: RoleFeaturePermissions;
    buttonTypeOperation: eButtonActions;
    @Input() ownerId: number = 0;
    @Input() ownerType: eCampaignTemplateOwnerType = eCampaignTemplateOwnerType.Contact;
    @Input() isShowForm: boolean = true;
    @Input() from: string = '';
    @Input() ownerName: string = '';
    @Output() refreshContactDetail = new EventEmitter<boolean>(false);
    campaignItems: CampaignItemExtended[] = [];
    campaignTemplateList: CampaignTemplateDDL[] = [];
    initCampaignTemplateList: CampaignTemplateDDL[] = [];
    userList: IntDropDownItem[] = [];
    automationData: AutomationActions = <AutomationActions>{};
    mySelection: number[] = [];
    warningStr: string = '';
    campaignTemplateId: number = 0;
    selectedUserId: number = 0;
    campaignTemplateName: string = '';
    userName: string = '';
    eCampaignStatus = eCampaignStatus;
    eCampaignTemplateOwnerType = eCampaignTemplateOwnerType;
    eCampaignItemStatusAction = eCampaignItemStatusAction;
    leadData: Lead = <Lead>{};
    messageID: number = 0;
    messageResponse: MessageResponse;
    encryptionKey: string = '';
    todaysDate = new Date();
    subscription: Subscription;
    everyFiveSeconds: Observable<number> = timer(0, 10000);
    campaignItemsList: CampaignItemExtended[] = [];
    columns = [{ field: '$', title: '', width: '20' },
    { field: 'campaignTemplateName', title: 'Automation Process', width: '200' },
    { field: 'userName', title: 'User', width: '50' },
    { field: 'campaignEventName', title: 'Current Event', width: '80' },
    { field: 'status', title: 'Status', width: '40' }];
    reorderColumnName: string = 'campaignTemplateName,userName,campaignEventName,status';
    columnWidth: string = 'campaignTemplateName:200,userName:50,campaignEventName:80,status:40';
    arrColumnWidth: any[] = ['campaignTemplateName:200,userName:50,campaignEventName:80,status:40'];
    constructor(public _gridCnfgService: GridConfigurationService,
        public _campaignService: CampaignService,
        public _notifyService: NotificationService,
        private _leadSettingService: LeadSettingService,
        private route: ActivatedRoute,
        public _localService: LocalService,
        public _dashboardService: DashboardService,
        private _utilityService: UtilityService,
        private _router: Router,
        private _appConfigService: AppconfigService,
        public _globalService: GlobalService
    ) {
        this._localService.isMenu = true;

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!isNullOrUndefined(changes?.ownerId)) {
            if (!changes?.ownerId?.firstChange)
                this.ngOnInit();
        }
    }

    ngOnInit(): void {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.getEncryptionKey();
                this.authenticateR().then(() => {
                    if (this.user) {
                        this._gridCnfgService.user = this.user;
                        this.selectedUserId = this.user?.cLPUserID;
                        if (this.user?.userRole <= eUserRole.Administrator) {
                            if (this.roleFeaturePermissions?.view == false)
                                this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            else
                                this.loadInit();
                        }
                        else
                            this.loadInit();
                    } else {
                        this.authenticateR().then(() => {
                            if (this.user)
                                this.loadInit();
                            else
                                this._router.navigate(['/login']);
                        })
                    }
                })
            }
            else
                this._router.navigate(['/login']);
        });
    }

    loadInit() {
        if (!isNullOrUndefined(localStorage.getItem("messageId"))) {
            this.messageID = Number(localStorage.getItem("messageId"));
            this.updateMessage();
        }
        this.route.queryParams?.subscribe(params => {
            this.ownerId = params?.oid ? params?.oid : this.ownerId;
            this.ownerType = params?.otype ? params?.otype : this.ownerType;
            if (params?.msgid > 0) {
                this.messageID = params?.msgid;
                this.updateMessage();
            }
            if (isNullOrEmptyString(this.ownerName) || isNullOrUndefined(this.ownerName))
                this.ownerName = localStorage.getItem("ownerName");
        });
        if (this.ownerType == eCampaignTemplateOwnerType.Lead)
            this.getLeadLoad();
        this.getGridConfiguration();
        this.getCampaignFilter();
        this.getCampaignList();
    }

    async updateMessage() {
        this._dashboardService.messageUpdate(this.encryptedUser, this.messageID)
            .then(async (result: MessageResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.messageResponse = UtilityService.clone(result);
                    if (this.messageResponse.messageBool) {
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("manage-campaign.updateMessage", err.message, null, 'messageID ' + this.messageID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getLeadLoad() {
        this._leadSettingService.getLeadLoad(this.encryptedUser, this.ownerId)
            .then((result: Lead) => {
                if (!isNullOrUndefined(result)) {
                    this.leadData = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("manage-campaign.getLeadLoad", err.message, null, 'ownerId ' + this.ownerId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            this.selectedUserId = this.user?.cLPUserID;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("manage-campaign.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user?.cLPUserID, 'manage_campaign_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('manage_campaign_grid').subscribe((value) => { }));
    }
    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user?.cLPUserID, 'manage_campaign_grid').subscribe((value) => this.getGridConfiguration());
    }

    async getCampaignList() {
        this.showSpinner = true;
        await this._campaignService.getCampaignItemListbyOwner(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID, this.ownerId, this.ownerType)
            .then(async (result: CampaignItemExtendedListResponse) => {
                if (result)
                    this.campaignItems = UtilityService.clone(result?.campaignItemExtendedList);
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("manage-campaign.getCampaignList", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'ownerId' + this.ownerId + 'ownerType' + this.ownerType);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getCampaignFilter() {
        this.showSpinner = true;
        const leadId = this.ownerType == eCampaignTemplateOwnerType.Lead ? this.ownerId : 0;
        await this._campaignService.loadCampaignTemplate(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, leadId, this.ownerType)
            .then(async (result: CampaignEventResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this.userList = response?.userList;
                    this.campaignTemplateList = response?.campaignTemplateDDLList;
                    this.initCampaignTemplateList = UtilityService.clone(response?.campaignTemplateDDLList);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("manage-campaign.getCampaignFilter", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'cLPUserID' + this.user?.cLPUserID + 'leadId' + leadId + 'ownerType' + this.ownerType);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async updateSFA() {
        this.showSpinner = true;
        this.copyAutomationValues();
        await this._campaignService.getSFAActionUpdate(this.encryptedUser, this.automationData, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: CampaignItemExtendedListResponse) => {
                if (result) {
                    this.campaignItems = UtilityService.clone(result?.campaignItemExtendedList);
                    this.mySelection = [];
                    this.refreshContactDetail.emit(true);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("manage-campaign.updateSFA", err.message, this.automationData);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async activateAutomationProcess() {
        this.buttonTypeOperation = eButtonActions.Save;
        const contactId = this.ownerType == eCampaignTemplateOwnerType.Contact ? this.ownerId : 0;
        const leadId = this.ownerType == eCampaignTemplateOwnerType.Lead ? this.ownerId : 0;
        await this._campaignService.activateAutomationProcess(this.encryptedUser, this.user?.cLPCompanyID, this.campaignTemplateId, this.selectedUserId, contactId, leadId)
            .then(async (result: ActivateSFAResponse) => {
                if (result) {
                    this.campaignItems = UtilityService.clone(result?.campaignItems);
                    this.cancel();
                    this.refreshContactDetail.emit(true);
                }
                this.buttonTypeOperation = eButtonActions.None;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("manage-campaign.activateAutomationProcess", err.message, null, 'cLPCompanyID ' + this.user?.cLPCompanyID + 'campaignTemplateId ' + this.campaignTemplateId + 'selectedUserId ' + this.selectedUserId + 'contactId ' + contactId + 'leadId ' + leadId);
                this._utilityService.handleErrorResponse(err);
                this.buttonTypeOperation = eButtonActions.None;
            });
    }

    copyAutomationValues() {
        this.automationData.campaignIds = this.mySelection;
        this.automationData.clpCompanyID = this.user.cLPCompanyID;
        this.automationData.clpuserId = this.user.cLPUserID
        this.automationData.contactID = this.ownerType == eCampaignTemplateOwnerType.Contact ? this.ownerId : 0;
        this.automationData.leadID = this.ownerType == eCampaignTemplateOwnerType.Lead ? this.ownerId : 0;
    }

    public selectAllState: SelectAllCheckboxState = "unchecked";
    public onSelectAllChange(checkedState: SelectAllCheckboxState): void {
        if (checkedState === "checked") {
            this.mySelection = this.campaignItems.map((item) => item.campaignID);
            this.selectAllState = "checked";
        } else {
            this.mySelection = [];
            this.selectAllState = "unchecked";
        }
    }

    statusUpdate(status) {
        this.automationData.statusAction = status;
        if (this.mySelection.length > 0) {
            if (this.user.userRole > eUserRole.General) {
                this.updateSFA();
                switch (status) {
                    case eCampaignItemStatusAction.Active:
                        this._notifyService.showSuccess("Selected campaigns have been ACTIVATED.", "", 3000);
                        break;
                    case eCampaignItemStatusAction.eStop:
                        this._notifyService.showSuccess("Selected campaigns have been STOPPED and RESET.", "", 3000);
                        break;
                    case eCampaignItemStatusAction.Pause:
                        this._notifyService.showSuccess("Selected campaigns have been PAUSED.", "", 3000);
                        break;
                    case eCampaignItemStatusAction.SkipEvent:
                        this._notifyService.showSuccess("SKIPPED current event for all selected campaigns.", "", 3000);
                        break;
                    case eCampaignItemStatusAction.RemoveFromCampaign:
                        this._notifyService.showSuccess("REMOVED from selected campaigns successfully.", "", 3000);
                        break;
                }
            }
        } else
            this.warningStr = 'There were no campaigns checked.';
    }

    cancel() {
        this.campaignTemplateId = 0;
        this.selectedUserId = this.user?.cLPUserID;
    }
    changeFormState() {
        this.isShowForm = !this.isShowForm;
        this.cancel();
    }

    templateChange(value) {
        this.campaignTemplateName = value?.CampaignTemplateName;
        this.campaignTemplateId = value?.CampaignTemplateID;
        this.getUserName();
    }

    getUserName() {
        const user = this.userList.filter(item => item?.id == this.selectedUserId)[0];
        this.userName = user ? user?.text : '';
    }

    handleFilter(value) {
        this.campaignTemplateList = this.initCampaignTemplateList.filter(
            (s) => s.CampaignTemplateName.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
    }

    cyclicEvent() {
        this.subscription = this.everyFiveSeconds.subscribe(() => {
            this.todaysDate = new Date();
            this.getEncryptionKey();
        });
    }

    private async getEncryptionKey() {
        await this._appConfigService.getAppConfigValues(this.encryptedUser, ["DBNotificationsEncryptionKey"])
            .then(async (result: ConfigDetailsWithKey[]) => {
                if (!isNullOrUndefined(result)) {
                    this.encryptionKey = result.filter(i => i.configKey == "DBNotificationsEncryptionKey")[0].configValue;
                    this.getDBNotifications();
                }
            }).catch((err: HttpErrorResponse) => {
                this._globalService.error("liveconnect-dashboard.getEncryptionKey", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getDBNotifications() {
        if (!isNullOrEmptyString(this.encryptionKey)) {
            this._globalService.pollingDBNotifications(this.encryptedUser, this.encryptionKey, +this.selectedUserId, true)
                .then(async (result: string) => {
                    if (result == 'Y') {
                        this.Campaignitem_GetByClpuseID();
                        this.refreshContactDetail.emit(true);
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("manage-campaign.getDBNotifications", err.message, null, 'selectedUserId ' + this.selectedUserId + 'isClientRequest ' + true);
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else
            this.getEncryptionKey();
    }

    async Campaignitem_GetByClpuseID() {
        this.showSpinner = true;
        this._campaignService.Campaignitem_GetByClpuseID(this.encryptedUser, +this.selectedUserId, this.ownerId)
            .then(async (result: CampaignItemExtendedListResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.campaignItemsList = UtilityService.clone(result?.campaignItemExtendedList);
                    this.campaignItems?.forEach(x => {
                        this.campaignItemsList?.forEach(y => {
                            if (y.campaignEventID == x.campaignEventID && y.objectID == x.objectID && x.campaignID == y.campaignID) {
                                x.status = y.status;
                                x.campaignEventName = y.campaignEventName;
                            }
                        })
                    })
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("manage-campaign.Campaignitem_GetByClpuseID", err.message, null, 'selectedUserId ' + +this.selectedUserId + 'ownerId' + this.ownerId);
                this._utilityService.handleErrorResponse(err);
            });
    }
}
