import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
//contact-Module
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';

import { MatTableExporterModule } from 'mat-table-exporter';
import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from "@progress/kendo-angular-label";
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { IconsModule } from '@progress/kendo-angular-icons';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { SVGIcon } from '@progress/kendo-svg-icons';
import { ListViewModule } from "@progress/kendo-angular-listview";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { EditorModule } from "@progress/kendo-angular-editor";
import { IntlModule } from "@progress/kendo-angular-intl";
import { SortableModule } from "@progress/kendo-angular-sortable";
//contact-module
import { SchedulerModule, ToolbarService } from '@progress/kendo-angular-scheduler';

import { SortablejsModule } from 'ngx-sortablejs13';
import { MenusModule } from '@progress/kendo-angular-menu';

//material and custom controls
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSortModule } from '@angular/material/sort';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';

import { AppComponent } from './app.component';

import { MissingCredentialsComponent } from './UI/login-module/shared/missing-credentials/missing-credentials.component';
import { LoginComponent } from './UI/login-module//login/login.component';
import { PasswordPolicyComponent } from './UI/login-module//password-policy/password-policy.component';
import { AdminUserAcctComponent } from './UI/login-module//admin-user-acct/admin-user-acct.component';
import { SignUpComponent } from './UI/login-module/sign-up/sign-up.component';
import { AuthenticateComponent } from './shared/authenticate.component';

//contact-module components
import { ContactListComponent } from './UI/contact-module/contactList/contactList.component';
import { ContactComponent } from './UI/contact-module/contact/contact.component';
import { SearchComponent } from './UI/contact-module/shared/search/search.component';
import { ContactCallComponent } from './UI/contact-module/common/contact-call/contact-call.component';
import { ContactEmailComponent } from './UI/contact-module/common/contact-email/contact-email.component';
import { PaginationComponent } from './UI/contact-module/common/pagination/pagination.component';
import { ContactSearchComponent } from './UI/contact-module/common/contact-search/contact-search.component';
import { ContactSmsComponent } from './UI/contact-module/common/contact-sms/contact-sms.component';
import { ContactActivityHistoryComponent } from './UI/contact-module/common/contact-activity-history/contact-activity-history.component';
import { ContactTaskComponent } from './UI/contact-module/common/contact-task/contact-task.component';
import { ContactNoteComponent } from './UI/contact-module/common/contact-note/contact-note.component';
import { ContactSettingsComponent } from './UI/contact-module/common/contact-settings/contact-settings.component';
import { EmailOptLogComponent } from './UI/contact-module/common/email-opt-log/email-opt-log.component';
import { MobileOptLogComponent } from './UI/contact-module/common/mobile-opt-log/mobile-opt-log.component';
import { ContactMeetingComponent } from './UI/contact-module/common/contact-meeting/contact-meeting.component';
import { ContactDetailComponent } from './UI/contact-module/contact-detail/contact-detail.component';
import { VoiceCallLogComponent } from './UI/contact-module/common/voice-call-log/voice-call-log.component';
import { TextMsgSettingComponent } from './UI/settings/text-msg-setting/text-msg-setting.component';
import { ClubModuleSettingsComponent } from './UI/settings/club-module-settings/club-module-settings.component';
import { UserSetupComponent } from './UI/settings/user-setup/user-setup.component';
import { AccountSetupComponent } from './UI/settings/account-setup/account-setup.component';
import { AdminFreeTrialComponent } from './UI/settings/so-admin/admin-free-trial/admin-free-trial.component';
import { SoBillingComponent } from './UI/settings/so-admin/so-billing/so-billing.component';
import { AdminBillingAuditComponent } from './UI/settings/so-admin/admin-billing-audit/admin-billing-audit.component';
import { AdminBillingManagerComponent } from './UI/settings/so-admin/admin-billing-manager/admin-billing-manager.component';
import { ClptxnComponent } from './UI/settings/so-admin/clptxn/clptxn.component';
import { RoleSetupComponent } from './UI/settings/role-setup/role-setup.component';
import { CompanySetupComponent } from './UI/settings/company-setup/company-setup.component';
import { ApptSettingsComponent } from './UI/settings/appt-settings/appt-settings.component';
import { LcEngagedashComponent } from './UI/live-connect-module/lc-engagedash/lc-engagedash.component';

import { ContactService } from './services/contact.service';
import { LocalService } from './services/shared/local.service';
import { SearchContactService } from './services/Searchcontact.service';
import { ContactSearchService } from './services/shared/contact-search.service';
import { OutBoundEmailService } from './services/outBoundEmail.service';
import { TaskService } from './services/task.service';
import { NotesService } from './services/notes.service';
import { OutBoundTextMsgService } from './services/outBoundTextMsg.service';
import { SignupService } from './services/signup.service';
import { AccountSetupService } from './services/accountSetup.service';
import { BilligService } from './services/billing.service';
import { TxtMsgService } from './services/textmsg.service';
import { htmlEmailPricingService } from './services/htmlEmailPricing.service';
import { ZipService } from './services/zip.service';
import { ClassCodeService } from './services/classCode.service';
import { AppointmentSettingService } from './services/appointmentSetting.service';
import { CompanySettingService } from './services/companySetting.service';
import { ServicesStatusService } from './services/services-status.service';
import { MenuService } from './services/menu.service';

import { MatNativeDateModule } from '@angular/material/core';

//pipes
import { PhoneFormatePipe } from './pipes/phone-format';
import { safehtmlpipe } from './pipes/safeHtml.pipe';
import { HtmlToPlaintextPipe } from './pipes/htmlToPlainText.pipe';

import { safehtmlpipe1 } from './UI/shared/htmlpipe/safehtmlpipe';

//End Contact-module

import { PasswordPolicyService } from './services/password-policy.service';
import { EncryptionService } from './services/shared/encryption.service';
import { GlobalService } from './services/global.service';
import { UtilityService } from './services/shared/utility.service';
import { AuthenticateService } from './services/authenticate.service';

//Shared component
import { NavMenuComponent } from './UI/shared/nav-menu/nav-menu.component';
import { LiveConnectComponent } from './UI/live-connect/live-connect.component';
/*import { AutomationProcessComponent } from './UI/automation-process/automation-process.component';*/
import { ConfigurationComponent } from './UI/configuration/configuration.component';
//Shared component

import { OnlyNumberDirective } from './directive/only-number.directive';
import { NgxtForDirective } from './directive/ngxtFor.directive';
import { UserProfileComponent } from './UI/login-module/user-profile/user-profile.component';
import { AuthorizeComponent } from './UI/authorize/authorize.component';
import { TeamofficeSetupComponent } from './UI/settings/teamoffice-setup/teamoffice-setup.component';
import { TeamOfficeSetupService } from './services/teamoffice.service';
import { OfficeSetupService } from './services/officeCode.service';
import { VoiceSettingComponent } from './UI/settings/voice-setting/voice-setting.component';
import { TagSettingComponent } from './UI/settings/tag-setting/tag-setting.component';
import { RepSettingsComponent } from './UI/settings/rep-settings/rep-settings.component';
import { ContractComponent } from './UI/settings/contract/contract.component';
import { WebformManagerComponent } from './UI/settings/webform-manager/webform-manager.component';
import { WorkflowComponent } from './UI/settings/workflow/workflow.component';
import { WebformComponent } from './UI/settings/webform/webform.component';
import { RoundRobinComponent } from './UI/settings/round-robin/round-robin.component';
import { PagerModule } from '@progress/kendo-angular-pager';
import { CompanyConfigurationComponent } from './UI/settings/common/company-configuration/company-configuration.component';
import { AnnouncementsComponent } from './UI/settings/so-admin/announcements/announcements.component';
import { AccountSettingComponent } from './UI/settings/so-admin/account-setting/account-setting.component';
import { LeadConfigurationComponent } from './UI/settings/common/lead-configuration/lead-configuration.component';
import { AdminPasswordFormComponent } from './UI/settings/common/admin-password-form/admin-password-form.component';
import { AdminCompanyListComponent } from './UI/settings/common/admin-company-list/admin-company-list.component';
import { ApplyCreditComponent } from './UI/settings/common/apply-credit/apply-credit.component';
import { TicketsComponent } from './UI/settings/common/tickets/tickets.component';
import { ServicesStatusComponent } from './UI/settings/so-admin/services-status/services-status.component';
import { AccountCreditCardComponent } from './UI/settings/common/account-credit-card/account-credit-card.component';
import { AccountBillingHistoryComponent } from './UI/settings/common/account-billing-history/account-billing-history.component';
import { AccountHtmlEmailComponent } from './UI/settings/common/account-html-email/account-html-email.component';
import { SupportCreditComponent } from './UI/settings/common/support-credit/support-credit.component';
import { TextMsgUsageComponent } from './UI/settings/common/text-msg-usage/text-msg-usage.component';
import { DocumentStorageComponent } from './UI/settings/common/document-storage/document-storage.component';
import { CompanyModuleComponent } from './UI/settings/common/company-module/company-module.component';
import { AccountStorageSummaryComponent } from './UI/settings/common/account-storage-summary/account-storage-summary.component';
import { AccountInfoComponent } from './UI/settings/common/account-info/account-info.component';
import { OutlookAddinComponent } from './UI/settings/common/outlook-addin/outlook-addin.component';
import { UserPreferenceComponent } from './UI/settings/common/user-preference/user-preference.component';
import { ApiSettingComponent } from './UI/settings/common/api-setting/api-setting.component';
import { ActivityLogComponent } from './UI/settings/common/activity-log/activity-log.component';
import { IphoneSettingComponent } from './UI/settings/common/iphone-setting/iphone-setting.component';
import { EmailDropboxSettingComponent } from './UI/settings/common/email-dropbox-setting/email-dropbox-setting.component';
import { TransferUserComponent } from './UI/settings/common/transfer-user/transfer-user.component';
import { SmtpsettingComponent } from './UI/settings/common/smtpsetting/smtpsetting.component';
import { ContactArchiveComponent } from './UI/contact-module/common/contact-archive/contact-archive.component';
import { ContactCommonSearchComponent } from './UI/contact-module/common/contact-common-search/contact-common-search.component';
import { SecuritySettingComponent } from './UI/settings/security-setting/security-setting.component';
import { OutlookSecurityComponent } from './UI/settings/outlook-security/outlook-security.component';
import { ContactMapComponent } from './UI/contact-module/common/contact-map/contact-map.component';
import { ContactMapCommonComponent } from './UI/contact-module/common/contact-map-common/contact-map-common.component';
import { ImportSfaComponent } from './UI/settings/common/import-sfa/import-sfa.component'
import { ClickTrackingComponent } from './UI/click-tracking/click-tracking.component';
import { MyCalenderComponent } from './UI/contact-module/common/my-calender/my-calender.component';
import { CustomActionComponent } from './UI/custom-action/custom-action.component';
import { ContactUploadExcelComponent } from './UI/contact-module/contact-upload-excel/contact-upload-excel.component';
import { BulkContactActionsComponent } from './UI/contact-module/bulk-contact-actions/bulk-contact-actions.component';
import { MyCalenderService } from './services/my-calender.service';
import { BulkContactCommonComponent } from './UI/contact-module/common/bulk-contact-common/bulk-contact-common.component';
import { MailMergeTemplateComponent } from './UI/mail-merge-template/mail-merge-template.component';
import { EmailTemplateComponent } from './UI/email-template/email-template.component';
import { TemplatePreviewComponent } from './UI/template-preview/template-preview.component';
import { TextMsgTemplateComponent } from './UI/text-msg-template/text-msg-template.component';
import { ImageBankComponent } from './UI/image-bank/image-bank.component';
import { VideoBankComponent } from './UI/video-bank/video-bank.component';
import { ManageDuplicateComponent } from './UI/contact-module/manage-duplicate/manage-duplicate.component';
import { ContactDuplicateComponent } from './UI/contact-module/common/contact-duplicate/contact-duplicate.component';
import { MyDocumentsComponent } from './UI/my-documents/my-documents.component';
import { AppointmentCommonComponent } from './UI/contact-module/common/appointment-common/appointment-common.component';
import { EmailBlastComponent } from './UI/email-blast/email-blast.component';
import { ApptTypeSummaryComponent } from './UI/reports/activity/appt-type-summary/appt-type-summary.component';
import { ApptTwoDimensionsComponent } from './UI/reports/activity/appt-two-dimensions/appt-two-dimensions.component';
import { CompanyComponent } from './UI/company-module/company/company.component';
import { CompanyListComponent } from './UI/company-module/company-list/company-list.component';
import { QualificationCallReportComponent } from './UI/reports/activity/qualification-call-report/qualification-call-report.component';
import { NoteTypeSummaryComponent } from './UI/reports/activity/note-type-summary/note-type-summary.component';
import { CallClickReportComponent } from './UI/reports/activity/call-click-report/call-click-report.component';
import { EmailOpenRateReportComponent } from './UI/reports/activity/email-open-rate-report/email-open-rate-report.component';
import { RptFilterComponent } from './UI/reports/lead/rpt-filter/rpt-filter.component';
import { TextMsgLogComponent } from './UI/reports/activity/text-msg-log/text-msg-log.component';
import { CoachCornerComponent } from './UI/reports/activity/coach-corner/coach-corner.component';
import { DistTwoDimensionsComponent } from './UI/reports/activity/dist-two-dimensions/dist-two-dimensions.component';
import { DistributionByManagerComponent } from './UI/reports/activity/distribution-by-manager/distribution-by-manager.component';
import { DistributionByClassificationComponent } from './UI/reports/contact/distribution-by-classification/distribution-by-classification.component';
import { DistributionTwoDimensionsComponent } from './UI/reports/contact/distribution-two-dimensions/distribution-two-dimensions.component';
import { VoiceCallReportComponent } from './UI/reports/activity/voice-call-report/voice-call-report.component';
import { ReferralReportComponent } from './UI/reports/contact/referral-report/referral-report.component';
import { CompanyCreateComponent } from './UI/company-module/company-create/company-create.component';
import { LeadCreateComponent } from './UI/lead-module/lead-create/lead-create.component';
import { ContactExportsComponent } from './UI/contact-module/common/contact-exports/contact-exports.component';
import { LeadListComponent } from './UI/lead-module/lead-list/lead-list.component';
import { LeadLeadStatusListComponent } from './UI/lead-module/lead-lead-status-list/lead-lead-status-list.component';
import { ProjectRevenueManagerComponent } from './UI/reports/lead/project-revenue-manager/project-revenue-manager.component';
import { ProjectRevenueClassificationComponent } from './UI/reports/lead/project-revenue-classification/project-revenue-classification.component';
import { LeadComponent } from './UI/lead-module/lead/lead.component';
import { LeadHistoryComponent } from './UI/lead-module/lead-history/lead-history.component';
import { LeadApptComponent } from './UI/lead-module/common/lead-appt/lead-appt.component';
import { LinkGroupComponent } from './UI/lead-module/common/link-group/link-group.component';
import { QuickLinkComponent } from './UI/lead-module/common/quick-link/quick-link.component';
import { LinkGroupService } from './services/link-group.service';
import { LeadSettingService } from './services/leadSetting.service';
import { InvoiceReportComponent } from './UI/reports/lead/invoice-report/invoice-report.component';
import { ProjectRevenueByMonthComponent } from './UI/reports/lead/project-revenue-by-month/project-revenue-by-month.component';
import { UploadDocumentComponent } from './UI/lead-module/common/upload-document/upload-document.component';
import { DistributionByTwoDimensionsComponent } from './UI/reports/lead/distribution-by-two-dimensions/distribution-by-two-dimensions.component';
import { LeadEmailComponent } from './UI/lead-module/common/lead-email/lead-email.component';
import { BuzzScoreComponent } from './UI/reports/contact/buzz-score/buzz-score.component';
import { ReferralReportCommonComponent } from './UI/reports/contact/referral-report-common/referral-report-common.component';
import { ClickTrackingReportComponent } from './UI/reports/contact/click-tracking-report/click-tracking-report.component';
import { TagCloudComponent } from './UI/reports/contact/tag-cloud/tag-cloud.component';
import { GoalsComponent } from './UI/goals-module/goals/goals.component';
import { GoalsListComponent } from './UI/goals-module/goals-list/goals-list.component';
import { GoalsSetupComponent } from './UI/goals-module/goals-setup/goals-setup.component';
import { GoalComponent } from './UI/goals-module/common/goal/goal.component';
import { SearchResultCommonComponent } from './UI/reports/search-result-common/search-result-common.component';
import { GoalSetupListComponent } from './UI/goals-module/common/goal-setup-list/goal-setup-list.component';
import { ScoreCardCommonComponent } from './UI/reports/custom/score-card-common/score-card-common.component';
import { ApptSettersActivityComponent } from './UI/reports/custom/appt-setters-activity/appt-setters-activity.component';
import { AgreementsDashboardComponent } from './UI/reports/custom/agreements-dashboard/agreements-dashboard.component';
import { ScorecardByCodeComponent } from './UI/reports/custom/scorecard-by-code/scorecard-by-code.component';
import { ScorecardByKeywordComponent } from './UI/reports/custom/scorecard-by-keyword/scorecard-by-keyword.component';
import { QuickGoalsComponent } from './UI/goals-module/common/quick-goals/quick-goals.component';
import { ScorecardByTeamComponent } from './UI/reports/custom/scorecard-by-team/scorecard-by-team.component';
import { BipClickByApptSetterComponent } from './UI/reports/custom/bip-click-by-appt-setter/bip-click-by-appt-setter.component';
import { ScorecardComponent } from './UI/reports/custom/scorecard/scorecard.component';
import { BipNoBuyContestComponent } from './UI/reports/custom/bip-no-buy-contest/bip-no-buy-contest.component';
import { ScorecardByTvComponent } from './UI/reports/custom/scorecard-by-tv/scorecard-by-tv.component';
import { HomeComponent } from './UI/home-module/home/home.component';
import { OverdueAppointmentComponent } from './UI/home-module/overdue-appointment/overdue-appointment.component';
import { MessageCenterComponent } from './UI/home-module/message-center/message-center.component';
import { ScorecardByApptSetterComponent } from './UI/reports/custom/scorecard-by-appt-setter/scorecard-by-appt-setter.component';
import { ScorecardCompareComponent } from './UI/reports/custom/scorecard-compare/scorecard-compare.component';
import { QuickRecentListComponent } from './UI/home-module/common/quick-recent-list/quick-recent-list.component';
import { DashboardCardComponent } from './UI/home-module/common/dashboard-card/dashboard-card.component';
import { SchedulerComponent } from './UI/scheduler/scheduler.component';
import { MyTaskComponent } from './UI/home-module/my-task/my-task/my-task.component';
import { LeadInvoiceService } from './services/lead-invoice.service';
//So Report
import { RptCommonComponent } from './UI/bi-report/rpt-common/rpt-common.component';
import { VIPAnalyticsComponent } from './UI/bi-report/analytics/analytics.component';
import { FooterComponent } from './UI/shared/footer/footer.component';
import { GridTableComponent } from './UI/shared/grid-table/grid-table.component';
import { VipListComponent } from './UI/slidecast-presentation/vip-list/vip-list.component';
import { VipComponent } from './UI/slidecast-presentation/vip/vip.component';
import { PresentationsComponent } from './UI/slidecast-presentation/presentations/presentations.component';
import { AnalyticsComponent } from './UI/slidecast-presentation/analytics/analytics.component';
import { VoiceRecordingsComponent } from './UI/marketing/manage-templates/voice-recordings/voice-recordings.component';
import { LongcodelineListComponent } from './UI/marketing/longcodeline-list/longcodeline-list.component';
import { LongcodelineComponent } from './UI/marketing/longcodeline/longcodeline.component';
import { CreateVoiceRecordingComponent } from './UI/marketing/manage-templates/voice-recordings/common/create-voice-recording/create-voice-recording.component';
import { VipSlideComponent } from './UI/slidecast-presentation/vip-slide/vip-slide.component';
import { ViewMailingsComponent } from './UI/marketing/view-mailings/view-mailings.component';
import { MailingCommonComponent } from './UI/marketing/mailing-common/mailing-common.component';
import { DirectMailComponent } from './UI/marketing/direct-mail/direct-mail.component';
import { ViewDirectMailComponent } from './UI/marketing/view-direct-mail/view-direct-mail.component';
import { AttachDocumentComponent } from './UI/marketing/common/attach-document/attach-document.component';
import { ContactCallIHComponent } from './UI/contact-module/common/contact-call/contact-call-IH/contact-call-ih.component';
import { VipPreviewComponent } from './UI/slidecast-presentation/vip-preview/vip-preview.component';
import { ByPassSecurityPipe } from './pipes/byPassSecurity.pipe';
import { CalMailingToolTip } from './pipes/calMailingToolTip.pipe';
import { CalApptToolTip } from './pipes/callApptHtml.pipe';
import { SlideCategorySetupComponent } from './UI/slidecast-presentation/slide-category-setup/slide-category-setup.component';
import { CoPortalComponent } from './UI/custom/co-portal/co-portal.component';
import { ResponseTimeReportComponent } from './UI/reports/activity/response-time-report/response-time-report.component';
import { ApptSettersManagementComponent } from './UI/reports/custom/appt-setters-management/appt-setters-management.component';
import { DuplicatesLeadComponent } from './UI/reports/custom/duplicates-lead/duplicates-lead.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextCampaignComponent } from './UI/marketing/text-campaign/text-campaign.component';
import { QuickBlastComponent } from './UI/custom/quick-blast/quick-blast.component';
import { KeywordListComponent } from './UI/marketing/keyword-list/keyword-list.component';
import { LeadBulkActionsComponent } from './UI/lead-module/lead-bulk-actions/lead-bulk-actions.component';
import { LinkComponent } from './UI/contact-module/link/link.component';
import { ContactRecentLeadComponent } from './UI/contact-module/common/contact-recent-lead/contact-recent-lead.component';
import { ContactRecentMailingComponent } from './UI/contact-module/common/contact-recent-mailing/contact-recent-mailing.component';
import { ContactReferredComponent } from './UI/contact-module/common/contact-referred/contact-referred.component';
import { ZapierSettingComponent } from './UI/settings/zapier-setting/zapier-setting.component';
import { EmailSnippetComponent } from './UI/marketing/email-snippet/email-snippet.component';
import { UserPrefsComponent } from './UI/login-module/user-prefs/user-prefs.component';
import { HomeSearchComponent } from './UI/contact-module/shared/home-search/home-search.component';
import { ChangeSolutionSettingsComponent } from './UI/settings/common/change-solution-settings/change-solution-settings.component';
import { NullCheck } from './pipes/nullCheck.pipe';
import { RecurringAppointmentComponent } from './UI/recurring-appointment/recurring-appointment.component';
import { ContactNewCallComponent } from './UI/contact-module/common/contact-new-call/contact-new-call.component';
import { BulkAppointmentComponent } from './UI/appointments-module/bulk-appointment/bulk-appointment.component';
import { DynamicSchedulerListComponent } from './UI/dynamic-scheduler-list/dynamic-scheduler-list.component';
import { LinkHistoryComponent } from './UI/lead-module/common/link-history/link-history.component';
import { DynamicSchedularConfigComponent } from './UI/dynamic-schedular-config/dynamic-schedular-config.component';
import { NgxEventHandlerModule } from 'ngx-event-handler';
import { AppointmentIhComponent } from './UI/appointments-module/appointment-ih/appointment-ih.component';
import { CalendarSchedulerComponent } from './UI/calendar-scheduler/calendar-scheduler.component';
import { AudiovideoComponent } from './UI/slidecast-presentation/audiovideo/audiovideo.component';
import { ConfirmationDialogComponent } from './UI/shared/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from './UI/shared/alert-dialog/alert-dialog.component';
import { safeurlpipe } from './UI/shared/htmlpipe/safehtmlpipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { splitTextPipe } from './pipes/split.pipe';
import { UserFilterComponent } from './UI/reports/shared/user-filter/user-filter.component';
import { ModalWrapperDirective } from './directive/modal-wrapper.directive';
import { BuzzIndexComponent } from './UI/shared/buzz-index/buzz-index.component';
import { LeadSearchResultComponent } from './UI/reports/lead/lead-search-result/lead-search-result.component';
import { SkypeCallLogComponent } from './UI/reports/activity/skype-call-log/skype-call-log.component';
import { InboundCallReportComponent } from './UI/reports/activity/inbound-call-report/inbound-call-report.component';
import { SmtpEmailSettingComponent } from './UI/smtp-email-setting/smtp-email-setting.component';
import { ActivityHistoryComponent } from './UI/shared/activity-history/activity-history.component';
import { CompanyGridComponent } from './UI/company-module/company-grid/company-grid.component';
import { ContactHistoryNoteComponent } from './UI/contact-module/common/contact-history-note/contact-history-note.component';
import { ImportHistoryComponent } from './UI/contact-module/import-history/import-history.component';
import { DocumentMailMergeComponent } from './UI/document-mail-merge/document-mail-merge.component';
import { TaskComponent } from './UI/shared/task/task.component';
import { LeadContactHistoryComponent } from './UI/lead-module/common/lead-contact-history/lead-contact-history.component';
import { CustomActionScreenComponent } from './UI/marketing/custom-action-screen/custom-action-screen.component';
import { AutomationProcessListComponent } from './UI/automation-module/automation-process-list/automation-process-list.component';
import { CampaignListComponent } from './UI/automation-module/campaign-list/campaign-list.component';
import { AutomationProcessComponent } from './UI/automation-module/automation-process/automation-process.component';
import { CampaignEventComponent } from './UI/automation-module/campaign-event/campaign-event.component';
import { CampaignEventListComponent } from './UI/automation-module/campaign-event-list/campaign-event-list.component';
import { ManageCampaignsComponent } from './UI/automation-module/manage-campaigns/manage-campaigns.component';
import { GoogleIntegrationComponent } from './UI/google-integration/google-integration.component';
import { LinkedDocumentComponent } from './UI/shared/linked-document/linked-document.component';
import { CampaignWorkflowComponent } from './UI/automation-module/campaign-workflow/campaign-workflow.component';
import { CampaignProcessComponent } from './UI/automation-module/campaign-process/campaign-process.component';
import { LeadInvoiceComponent } from './UI/lead-module/lead-invoice/lead-invoice.component';
import { OutlookIntegrationComponent } from './UI/login-module/outlook-integration/outlook-integration.component';
import { LeadNextActionComponent } from './UI/lead-module/lead-next-action/lead-next-action.component';
import { LeadInvoiceListComponent } from './UI/lead-module/lead-invoice-list/lead-invoice-list.component';
import { LiveconnectDashboardComponent } from './UI/live-connect-module/liveconnect-dashboard/liveconnect-dashboard.component';
import { ArchiveLiveconnectDashComponent } from './UI/live-connect-module/archive-liveconnect-dash/archive-liveconnect-dash.component';
import { CtComponent } from './UI/live-connect-module/ct/ct.component';
import { LcApptComponent } from './UI/live-connect-module/lc-appt/lc-appt.component';
import { VcComponent } from './UI/live-connect-module/vc/vc.component';
import { HandleReplyComponent } from './UI/live-connect-module/handle-reply/handle-reply.component';
import { LcNoteComponent } from './UI/live-connect-module/lc-note/lc-note.component';
import { LcEmailComponent } from './UI/live-connect-module/lc-email/lc-email.component';
import { MakeVcComponent } from './UI/live-connect-module/make-vc/make-vc.component';
import { HandleTmComponent } from './UI/live-connect-module/handle-tm/handle-tm.component';
import { LcCasDisplayComponent } from './UI/live-connect-module/lc-cas-display/lc-cas-display.component';
import { LcAudioVideoRecordingComponent } from './UI/live-connect-module/lc-audio-video-recording/lc-audio-video-recording.component';
import { LcContactHistoryComponent } from './UI/live-connect-module/lc-contact-history/lc-contact-history.component';
import { LcAnalyticsComponent } from './UI/live-connect-module/lc-analytics/lc-analytics.component';
import { LcSettingsComponent } from './UI/live-connect-module/lc-settings/lc-settings.component';
/*import { LcDealsComponent } from './UI/live-connect-module/lc-deals/lc-deals.component';*/
import { LiveConnectHeaderComponent } from './UI/live-connect-module/live-connect-header/live-connect-header.component';
import { LcSettingFormComponent } from './UI/live-connect-module/lc-setting-form/lc-setting-form.component';
import { LiveConnectUserAssignmentComponent } from './UI/live-connect-module/live-connect-user-assignment/live-connect-user-assignment.component';
/*import { LcEndOfDayComponent } from './UI/live-connect-module/lc-end-of-day/lc-end-of-day.component';*/
import { LiveConnectService } from './services/live-connect.service';
import { HandleClickTrackComponent } from './UI/handle-click-track/handle-click-track.component';
import { AccountSummaryComponent } from './UI/settings/common/account-summary/account-summary.component';
import { UserDefaultComponent } from './UI/login-module/user-default/user-default.component';
import { LeadStatusHistoryComponent } from './UI/lead-module/lead-status-history/lead-status-history.component';

import { LoaderInterceptor } from '../app/interceptors/loader.interceptor';
import { LoaderComponent } from './UI/loader/loader.component';
import { AdcodeManagerComponent } from './UI/SOForms/ih/adcode-manager/adcode-manager.component';
import { IhService } from './services/ih.service';
import { IhInfoCenterComponent } from './UI/SOForms/ih/ih-info-center/ih-info-center.component';
import { DisclosuresComponent } from './UI/SOForms/ih/disclosures/disclosures.component';
import { ThankYouComponent } from './UI/SOForms/ih/thank-you/thank-you.component';
import { FreeTrialSignupComponent } from './UI/SOForms/so/free-trial-signup/free-trial-signup.component';
import { SoService } from './services/shared/so.service';
/*import { DbcCompareComponent } from './UI/SOForms/signup/dbc-compare/dbc-compare.component';*/
import { SoFormsSignupService } from './services/shared/so-forms-signup.service';
import { SoservicesService } from './services/soservices.service';
import { ParserRulesComponent } from './UI/SOForms/harvest/parser-rules/parser-rules.component';
import { HarvestService } from './services/harvest.service';
import { UploadFileComponent } from './UI/SOForms/sodigital/upload-file/upload-file.component';
import { SoDigitalService } from './services/so-digital.service';
import { SoInvoiceService } from './services/so-invoice.service';
import { CLPEnvironmentService } from './services/clp-environment.service';


import { VoiceCampaignComponent } from './UI/marketing/voice-campaign/voice-campaign.component';
import { IhUnsubscribeComponent } from './UI/SOPost/ih-unsubscribe/ih-unsubscribe.component';
import { SoPostService } from './services/so-post.service';
import { ReferralComponent } from './UI/SOPost/referral/referral.component';
import { UpdateInfoComponent } from './UI/SOPost/update-info/update-info.component';
import { EmlPrefConfirmComponent } from './UI/SOPost/eml-pref-confirm/eml-pref-confirm.component';
import { SoUnsubscribeComponent } from './UI/SOPost/so-unsubscribe/so-unsubscribe.component';
import { SoUnsubnComponent } from './UI/SOPost/so-unsubn/so-unsubn.component';
import { UpdateComponent } from './UI/SOPost/update/update.component';
import { TestEvalComponent } from './UI/SOPost/Admin/test-eval/test-eval.component';
import { SoUnsubComponent } from './UI/SOPost/so-unsub/so-unsub.component';
import { SopostSignupComponent } from './UI/SOPost/sopost-signup/sopost-signup.component';
import { WcmPostComponent } from './UI/SOPost/wcm-post/wcm-post.component';
import { WcmPostService } from './services/wcm-post.service';
import { SopostWebformComponent } from './UI/SOPost/sopost-webform/sopost-webform.component';
import { RefundATransactionComponent } from './UI/settings/so-admin/refund-a-transaction/refund-a-transaction.component';
import { EditTransactionComponent } from './UI/settings/so-admin/edit-transaction/edit-transaction.component';
import { CLPTxnListComponent } from './UI/settings/so-admin/clptxn-list/clptxn-list.component';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { WfpreviewComponent } from './UI/wfpreview/wfpreview.component';
import { StringToNumberPipe } from './pipes/string-to-number.pipe';
import { DeviceInfoLogsComponent } from './UI/settings/device-info-logs/device-info-logs.component';
import { BcmRedirectComponent } from './UI/bcm-redirect/bcm-redirect.component';
import { HandleVcComponent } from './UI/live-connect-module/handle-vc/handle-vc.component';
import { EmailSettingsComponent } from './UI/settings/email-settings/email-settings.component';
import { RptStatushistorytimespanComponent } from './UI/custom/rpt-statushistorytimespan/rpt-statushistorytimespan.component';
import { RptTworoadspipelineComponent } from './UI/custom/rpt-tworoadspipeline/rpt-tworoadspipeline.component';
import { RptTworoadspipelinebytagComponent } from './UI/custom/rpt-tworoadspipelinebytag/rpt-tworoadspipelinebytag.component';
import { DynamicSchedulerComponent } from './UI/dynamic-scheduler/dynamic-scheduler.component';
import { PlayaudioComponent } from './UI/voice/playaudio/playaudio.component';
import { CmUserPrefsComponent } from './UI/login-module/cm-user-prefs/cm-user-prefs.component';
import { CmUserPrefService } from './services/cm-user-pref.service';
import { ContractManagerService } from './services/contract-manager.service';
import { ContactNewSearchComponent } from './UI/shared/contact-new-search/contact-new-search.component';
import { SoMetricService } from './services/so-metric.service';
import { SoMetricComponent } from './UI/sometric/so-metric/so-metric.component';
import { LeadNewSearchComponent } from './UI/shared/lead-new-search/lead-new-search.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;


@NgModule({
    declarations: [
        AccountSummaryComponent,
        HandleClickTrackComponent,
        AppComponent,
        GridTableComponent,
        LoginComponent,
        UserProfileComponent,
        AuthorizeComponent,
        PasswordPolicyComponent,
        MissingCredentialsComponent,
        AdminUserAcctComponent,
        SignUpComponent,
        AuthenticateComponent,
        //pipe
        PhoneFormatePipe,
        StringToNumberPipe,
        safehtmlpipe,
        HtmlToPlaintextPipe,
        ByPassSecurityPipe,
        CalMailingToolTip,
        CalApptToolTip,
        NullCheck,
        splitTextPipe,
        //contact-module component
        ContactListComponent,
        ContactComponent,
        MissingCredentialsComponent,
        SearchComponent,
        ContactCallComponent,
        ContactNewCallComponent,
        ContactCallIHComponent,
        ContactEmailComponent,
        PaginationComponent,
        ContactSearchComponent,
        ContactSmsComponent,
        ContactActivityHistoryComponent,
        ContactTaskComponent,
        ContactNoteComponent,
        ContactSettingsComponent,
        EmailOptLogComponent,
        MobileOptLogComponent,
        ContactMeetingComponent,
        ContactDetailComponent,
        VoiceCallLogComponent,
        //End Contact-module
        //Shared
        NavMenuComponent,
        FooterComponent,
        LiveConnectComponent,
        AutomationProcessComponent,
        CampaignEventComponent,
        CampaignEventListComponent,
        ConfigurationComponent,
        //Shared

        //directive
        OnlyNumberDirective,
        ModalWrapperDirective,
        NgxtForDirective,
        //End directive

        UserSetupComponent,
        TextMsgSettingComponent,
        ClubModuleSettingsComponent,
        EmailSettingsComponent,
        RoleSetupComponent,
        AccountSetupComponent,
        AdminBillingManagerComponent,
        ClptxnComponent,
        SoBillingComponent,
        AdminBillingAuditComponent,
        AdminFreeTrialComponent,
        CompanySetupComponent,
        ApptSettingsComponent,
        LcEngagedashComponent,
        TagSettingComponent,
        TeamofficeSetupComponent,
        VoiceSettingComponent,
        RepSettingsComponent,
        ContractComponent,
        WebformManagerComponent,
        WebformComponent,
        RoundRobinComponent,
        WorkflowComponent,
        CompanyConfigurationComponent,
        LeadConfigurationComponent,
        AdminPasswordFormComponent,
        AdminCompanyListComponent,
        ApplyCreditComponent,
        TicketsComponent,
        AnnouncementsComponent,
        AccountSettingComponent,
        ServicesStatusComponent,
        AccountInfoComponent,
        OutlookAddinComponent,
        UserPreferenceComponent,
        ApiSettingComponent,
        ActivityLogComponent,
        AccountCreditCardComponent,
        AccountBillingHistoryComponent,
        AccountHtmlEmailComponent,
        SupportCreditComponent,
        TextMsgUsageComponent,
        DocumentStorageComponent,
        CompanyModuleComponent,
        AccountStorageSummaryComponent,
        IphoneSettingComponent,
        EmailDropboxSettingComponent,
        TransferUserComponent,
        SmtpsettingComponent,
        ContactArchiveComponent,
        ContactCommonSearchComponent,
        SecuritySettingComponent,
        OutlookSecurityComponent,
        ContactMapComponent,
        ContactMapCommonComponent,
        ImportSfaComponent,
        ClickTrackingComponent,
        MyCalenderComponent,
        CustomActionComponent,
        ContactUploadExcelComponent,
        BulkContactActionsComponent,
        BulkContactCommonComponent,
        MailMergeTemplateComponent,
        EmailTemplateComponent,
        TemplatePreviewComponent,
        TextMsgTemplateComponent,
        ImageBankComponent,
        VideoBankComponent,
        WfpreviewComponent,
        SlideCategorySetupComponent,
        CoPortalComponent,
        ManageDuplicateComponent,
        ContactDuplicateComponent,
        MyDocumentsComponent,
        AppointmentCommonComponent,
        EmailBlastComponent,
        ApptTypeSummaryComponent,
        ApptTwoDimensionsComponent,
        CompanyListComponent,
        CompanyComponent,
        QualificationCallReportComponent,
        NoteTypeSummaryComponent,
        CallClickReportComponent,
        EmailOpenRateReportComponent,
        RptFilterComponent,
        TextMsgLogComponent,
        CoachCornerComponent,
        DistTwoDimensionsComponent,
        CompanyCreateComponent,
        LeadCreateComponent,
        LeadListComponent,
        LeadLeadStatusListComponent,
        LeadStatusHistoryComponent,
        LeadComponent,
        DistributionByManagerComponent,
        DistributionByClassificationComponent,
        DistributionTwoDimensionsComponent,
        ContactExportsComponent,
        ProjectRevenueManagerComponent,
        ProjectRevenueClassificationComponent,
        VoiceCallReportComponent,
        LeadHistoryComponent,
        LeadApptComponent,
        LinkGroupComponent,
        InvoiceReportComponent,
        ProjectRevenueByMonthComponent,
        QuickLinkComponent,
        UploadDocumentComponent,
        ReferralReportComponent,
        ReferralReportCommonComponent,
        DistributionByTwoDimensionsComponent,
        LeadEmailComponent,
        BuzzScoreComponent,
        ClickTrackingReportComponent,
        TagCloudComponent,
        GoalComponent,
        GoalsComponent,
        GoalsListComponent,
        GoalsSetupComponent,
        SearchResultCommonComponent,
        GoalSetupListComponent,
        ApptSettersActivityComponent,
        AgreementsDashboardComponent,
        ScorecardByCodeComponent,
        ScoreCardCommonComponent,
        ScorecardByKeywordComponent,
        QuickGoalsComponent,
        ScorecardByTeamComponent,
        BipClickByApptSetterComponent,
        ScorecardComponent,
        BipNoBuyContestComponent,
        ScorecardByTvComponent,
        HomeComponent,
        MyTaskComponent,
        DeviceInfoLogsComponent,
        LeadContactHistoryComponent,
        AutomationProcessListComponent,
        CampaignListComponent,
        /*AutomationProcessComponent,*/
        OverdueAppointmentComponent,
        MessageCenterComponent,
        ScorecardByApptSetterComponent,
        ScorecardCompareComponent,
        DashboardCardComponent,
        QuickRecentListComponent,
        SchedulerComponent,
        RptCommonComponent,
        VIPAnalyticsComponent,
        VipListComponent,
        VipComponent,
        VipSlideComponent,
        PresentationsComponent,
        AnalyticsComponent,
        VoiceRecordingsComponent,
        LongcodelineListComponent,
        LongcodelineComponent,
        CreateVoiceRecordingComponent,
        ViewMailingsComponent,
        DirectMailComponent,
        AttachDocumentComponent,
        MailingCommonComponent,
        ViewDirectMailComponent,
        VipPreviewComponent,
        ResponseTimeReportComponent,
        ApptSettersManagementComponent,
        TextCampaignComponent,
        VoiceCampaignComponent,
        DuplicatesLeadComponent,
        KeywordListComponent,
        QuickBlastComponent,
        LeadBulkActionsComponent,
        ContactRecentLeadComponent,
        ContactRecentMailingComponent,
        ContactReferredComponent,
        ZapierSettingComponent,
        SkypeCallLogComponent,
        InboundCallReportComponent,
        LinkComponent,
        UserPrefsComponent,
        CmUserPrefsComponent,
        EmailSnippetComponent,
        ActivityHistoryComponent,
        HomeSearchComponent,
        ChangeSolutionSettingsComponent,
        BulkAppointmentComponent,
        RecurringAppointmentComponent,
        DynamicSchedulerListComponent,
        DynamicSchedulerComponent,
        LinkHistoryComponent,
        AppointmentIhComponent,
        DynamicSchedularConfigComponent,
        CalendarSchedulerComponent,
        UserFilterComponent,
        BuzzIndexComponent,
        LeadSearchResultComponent,
        AudiovideoComponent,
        ContactHistoryNoteComponent,
        safehtmlpipe1,
        safeurlpipe,
        ConfirmationDialogComponent,
        AlertDialogComponent,
        SmtpEmailSettingComponent,
        ImportHistoryComponent,
        CompanyGridComponent,
        DocumentMailMergeComponent,
        CustomActionScreenComponent,
        ManageCampaignsComponent,
        TaskComponent,
        UserDefaultComponent,
        GoogleIntegrationComponent,
        LeadInvoiceComponent,
        CampaignWorkflowComponent,
        CampaignProcessComponent,
        LiveconnectDashboardComponent,
        ArchiveLiveconnectDashComponent,
        CtComponent,
        LcApptComponent,
        VcComponent,
        HandleReplyComponent,
        LcNoteComponent,
        LcEmailComponent,
        MakeVcComponent,
        HandleTmComponent,
        LcCasDisplayComponent,
        LcAudioVideoRecordingComponent,
        LcContactHistoryComponent,
        LcAnalyticsComponent,
        LcSettingsComponent,
        /*LcDealsComponent,*/
        LiveConnectHeaderComponent,
        LcSettingFormComponent,
        LiveConnectUserAssignmentComponent,
        /*LcEndOfDayComponent,*/
        OutlookIntegrationComponent,
        LinkedDocumentComponent,
        LeadNextActionComponent,
        LeadInvoiceListComponent,
        LoaderComponent,
        //iH
        AdcodeManagerComponent,
        IhInfoCenterComponent,
        DisclosuresComponent,
        ThankYouComponent,

        //SO
        FreeTrialSignupComponent,

        //harvest
        ParserRulesComponent,

        //SoDigital
        UploadFileComponent,

        //SOPost
        IhUnsubscribeComponent,
        ReferralComponent,
        UpdateInfoComponent,
        EmlPrefConfirmComponent,
        SoUnsubscribeComponent,
        SoUnsubnComponent,
        UpdateComponent,
        TestEvalComponent,
        SoUnsubComponent,
        SopostSignupComponent,
        WcmPostComponent,
        SopostWebformComponent,
        RefundATransactionComponent,
        EditTransactionComponent,
        CLPTxnListComponent,
        BcmRedirectComponent,
        HandleVcComponent,
        PlayaudioComponent,

        RptStatushistorytimespanComponent,
        RptTworoadspipelineComponent,
        RptTworoadspipelinebytagComponent,

        //Search
        ContactNewSearchComponent,
        LeadNewSearchComponent,

        SoMetricComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        BrowserAnimationsModule,
        ReactiveFormsModule,
        NgbModule,
        SchedulerModule,
        HttpClientModule,
        FormsModule,
        MenusModule,
        NgSelectModule,

        MatTabsModule,
        MatTableModule,
        MatDialogModule,
        MatButtonModule,
        MatInputModule,
        MatDatepickerModule,
        MatPaginatorModule,
        MatSelectModule,
        MatFormFieldModule,
        MatSortModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatIconModule,
        MatAutocompleteModule,
        MatGridListModule,
        MatListModule,
        MatSidenavModule,
        MatSliderModule,
        MatToolbarModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatProgressBarModule,
        MatRippleModule,
        MatDividerModule,
        PagerModule,
        ScrollingModule,
        ToastrModule.forRoot(),
        NgxMaskModule.forRoot(options),

        SortablejsModule.forRoot({
            animation: 150
        }),

        //contact-module

        GridModule,
        ChartsModule,
        InputsModule,
        LabelModule,
        PDFModule,
        ExcelModule,
        PDFExportModule,
        ExcelExportModule,
        ListViewModule,
        ButtonsModule,
        EditorModule,
        SortableModule,
        IntlModule,
        DropDownsModule,
        DateInputsModule,
        LayoutModule,
        IconsModule,
        MatTableExporterModule,
        DragDropModule,
        MatNativeDateModule,
        //AngularFileUploaderModule,
        CommonModule,
        //contact-module
        NgxEventHandlerModule,
        UploadsModule,

        RouterModule.forRoot([
            { path: '', component: LoginComponent },
            { path: 'login', component: LoginComponent },
            { path: 'AdminPassPolicy', component: PasswordPolicyComponent },
            { path: 'unauthorized', component: MissingCredentialsComponent },
            { path: 'AdminUserAcct', component: AdminUserAcctComponent },
            { path: 'signup', component: SignUpComponent },
            { path: 'authenticate', component: AuthenticateComponent },
            { path: 'authorize', component: AuthorizeComponent },
            //contact-module

            { path: 'search', component: SearchComponent },
            { path: 'archive-contacts', component: ContactArchiveComponent },
            { path: 'upload-contacts', component: ContactUploadExcelComponent, title: 'Upload Contacts' },
            { path: 'manage-duplicates', component: ManageDuplicateComponent },
            { path: 'bulk-contacts', component: BulkContactActionsComponent },
            { path: 'bulk-contacts/:id', component: BulkContactActionsComponent },
            { path: 'map-contacts', component: ContactMapComponent },
            { path: 'contacts', component: ContactListComponent },
            { path: 'recent-contacts', component: ContactListComponent },
            { path: 'contact', component: ContactComponent },
            { path: 'contact/:clpUserId/:contactID', component: ContactComponent },
            { path: 'contact/:mode/:clpUserId/:contactID', component: ContactComponent },
            { path: 'contact-create', component: ContactDetailComponent, title : 'Contact' },
            { path: 'note/emailoptlog', component: EmailOptLogComponent },
            { path: 'contact-create/:rid', component: ContactDetailComponent },
            { path: 'link', component: LinkComponent },
            { path: 'lead/history', component: ContactNoteComponent },
            { path: 'lead/invoice/:leadId', component: LeadInvoiceListComponent },
            { path: 'contact/history', component: ContactNoteComponent },
            { path: 'importhistory', component: ImportHistoryComponent },
            { path: 'importhistory/:cid', component: ImportHistoryComponent },
            { path: 'task/:ownerId/:cat', component: TaskComponent, title : 'My Tasks' },
            { path: 'task/:taskid', component: TaskComponent, title: 'My Tasks' },
            { path: 'task', component: TaskComponent, title: 'My Tasks' },
            { path: 'lead-contact-history/:leadId/:contactId', component: LeadContactHistoryComponent },
            { path: 'automation-process', component: AutomationProcessListComponent },
            { path: 'campaignlist', component: CampaignListComponent },
            { path: 'campaign-template/:campaignTemplateId', component: AutomationProcessComponent },
            { path: 'campaign-template', component: AutomationProcessComponent },
            { path: 'manage-campaign', component: ManageCampaignsComponent },
            { path: 'manage-campaign/:oid/:otype', component: ManageCampaignsComponent },
            { path: 'workflow-campaign', component: CampaignWorkflowComponent },
            { path: 'workflow-campaign/:cid', component: CampaignWorkflowComponent },
            { path: 'campaign-process', component: CampaignProcessComponent },
            { path: 'campaign-process/:cid', component: CampaignProcessComponent },
            { path: 'live-connect', component: LiveconnectDashboardComponent },
            { path: 'archive-live-connect/:status', component: ArchiveLiveconnectDashComponent },
            { path: 'lc-analytics', component: LcAnalyticsComponent },
            { path: 'lc-settings', component: LcSettingsComponent },
            { path: 'playaudio', component: PlayaudioComponent },

            /*{ path: 'lc-deals', component: LcDealsComponent },*/
            //contact-module
            { path: 'live-connect', component: LiveConnectComponent },
            /* { path: 'automation-process', component: AutomationProcessComponent },*/
            { path: 'configuration', component: ConfigurationComponent },
            //settings
            { path: 'account-setup', component: AccountSetupComponent },
            { path: 'so-billing', component: SoBillingComponent },
            { path: 'sofreetriallist', component: AdminFreeTrialComponent },
            { path: 'role-setup', component: RoleSetupComponent },
            { path: 'admin-user-acct', component: AdminUserAcctComponent },
            { path: 'user-setup', component: UserSetupComponent },
            { path: 'text-msg-settings', component: TextMsgSettingComponent },
            { path: 'club-module-settings', component: ClubModuleSettingsComponent },
            { path: 'email-settings', component: EmailSettingsComponent },
            { path: 'voice-settings', component: VoiceSettingComponent },
            { path: 'rep-settings', component: RepSettingsComponent, title: 'SO Services Contract' },
            { path: 'company-setup', component: CompanySetupComponent },
            { path: 'edit-profile/:userId', component: UserProfileComponent, title : 'My Profile' },
            { path: 'user-defaults', component: UserDefaultComponent },
            { path: 'teamoffice-setup', component: TeamofficeSetupComponent },
            { path: 'appt-settings', component: ApptSettingsComponent },
            { path: 'company-settings', component: ApptSettingsComponent },
            { path: 'contact-settings', component: ApptSettingsComponent },
            { path: 'lead-settings', component: ApptSettingsComponent },
            { path: 'tag-settings', component: TagSettingComponent },
            { path: 'webform', component: WebformManagerComponent },
            { path: 'workflow', component: WorkflowComponent },
            { path: 'announcements', component: AnnouncementsComponent },
            { path: 'account-setting', component: AccountSettingComponent },
            { path: 'services-status', component: ServicesStatusComponent },
            { path: 'security-setting', component: SecuritySettingComponent },
            { path: 'outlook-security-setting', component: OutlookSecurityComponent },
            { path: 'contract/:sOSCID', component: ContractComponent },
            { path: 'click-tracking', component: ClickTrackingComponent },
            { path: 'mail-merge-template', component: MailMergeTemplateComponent },
            { path: 'email-template', component: EmailTemplateComponent },
            { path: 'custom-action', component: CustomActionComponent },
            { path: 'template-preview/:mid', component: TemplatePreviewComponent },
            { path: 'template-preview/:mid/:cat', component: TemplatePreviewComponent },
            { path: 'wfpreview/:wid', component: WfpreviewComponent },
            { path: 'template-preview/:mid/:cat/:cid', component: TemplatePreviewComponent },
            { path: 'text-template', component: TextMsgTemplateComponent },
            { path: 'image-bank', component: ImageBankComponent },
            { path: 'video-bank', component: VideoBankComponent },
            { path: 'my-documents', component: MyDocumentsComponent },
            { path: 'calender/scheduler', component: CalendarSchedulerComponent },
            { path: 'my-documents/:leadId', component: MyDocumentsComponent },
            { path: 'my-documents/:leadId/:cat', component: MyDocumentsComponent },
            { path: 'calender', component: SchedulerComponent, title : 'Calendar'},
            { path: 'calendar/pcallist', component: DynamicSchedulerListComponent },
            { path: 'calendar/pcal', component: DynamicSchedularConfigComponent },
            { path: 'calendar/pcal/:id', component: DynamicSchedularConfigComponent },
            { path: 'appt-calendar', component: DynamicSchedulerComponent },
            { path: 'appointment', component: AppointmentCommonComponent },
            { path: 'appointment/:apptId/:cat/:ownerId', component: AppointmentCommonComponent },
            { path: 'appointment/:apptId/:cat/:ownerId/:currentPage', component: AppointmentCommonComponent },
            { path: 'appointment-list', component: OverdueAppointmentComponent, title : 'Appointment List' },
            { path: 'appointment-list/:ownerId/:cat', component: OverdueAppointmentComponent },
            { path: 'leads/:cid/:cat', component: LeadSearchResultComponent },
            { path: 'bulk-appointment', component: BulkAppointmentComponent, title: 'Create Bulk Appointments'},
            { path: 'bulk-appointment/:csid', component: BulkAppointmentComponent, title: 'Create Bulk Appointments' },
            { path: 'appointment-ih', component: AppointmentIhComponent },
            { path: 'email-blast', component: EmailBlastComponent, title: 'New Email Blast' },
            { path: 'email-blast/:id', component: EmailBlastComponent, title: 'Email Blast' },
            { path: 'calendar/recurringappt', component: RecurringAppointmentComponent },
            { path: 'calendar/recurringappt/:apptId', component: RecurringAppointmentComponent },
            { path: 'smtpsettings', component: SmtpEmailSettingComponent },
            { path: 'outlook-integration', component: OutlookIntegrationComponent },

            //Reports Section
            { path: 'report/appttype', component: ApptTypeSummaryComponent },
            { path: 'report/rptresponsetime', component: ResponseTimeReportComponent },
            { path: 'report/appt2dim', component: ApptTwoDimensionsComponent },
            { path: 'calendar/rptqualcall', component: QualificationCallReportComponent },
            { path: 'report/notetype', component: NoteTypeSummaryComponent },
            { path: 'workflow/rptcasclick', component: CallClickReportComponent },
            { path: 'report/emailopenreport', component: EmailOpenRateReportComponent },
            { path: 'report/rptFilter', component: RptFilterComponent },
            { path: 'txtmsg/txtmsglist', component: TextMsgLogComponent },
            { path: 'report/rptuseractivity', component: CoachCornerComponent },
            { path: 'voice/rptvoicecall', component: VoiceCallReportComponent },
            { path: 'voice/rptvoicecallibtime', component: InboundCallReportComponent },
            { path: 'report/rptskypeattempts', component: SkypeCallLogComponent },
            { path: 'company/rpt2dim', component: DistTwoDimensionsComponent },
            { path: 'contact/rptdist_mng', component: DistributionByManagerComponent },
            { path: 'contact/rptdist_mng/:df/:dtCreated/:incZero/:dm1/:dm1val', component: DistributionByManagerComponent },
            { path: 'contact/rptdist_class', component: DistributionByClassificationComponent },
            { path: 'contact/rptdist_class/:df/:dtCreated/:incZero/:dm1/:dm1val', component: DistributionByManagerComponent },
            { path: 'contact/rpt2dim', component: DistributionTwoDimensionsComponent },
            { path: 'lead/rptinvoice', component: InvoiceReportComponent },
            { path: 'report/rptreferral', component: ReferralReportComponent },
            { path: 'company', component: CompanyListComponent },
            { path: 'bulk-company', component: CompanyListComponent },
            { path: 'lead-create', component: LeadCreateComponent, title : 'Lead Create' },
            { path: 'lead', component: LeadListComponent },
            { path: 'active-leads', component: LeadListComponent, title : 'My Lead List' },
            { path: 'project-revenue-manager', component: ProjectRevenueManagerComponent },
            { path: 'project-revenue-classification', component: ProjectRevenueClassificationComponent },
            { path: 'project-revenue-month', component: ProjectRevenueByMonthComponent },
            { path: 'company-create', component: CompanyCreateComponent, title : 'Company' },
            { path: 'company-create/:cid', component: CompanyCreateComponent, title: 'Company' },
            { path: 'lead-detail/:leadId', component: LeadComponent, title : 'Lead' },
            { path: 'lead-detail/:mode/:leadId', component: LeadComponent, title: 'Lead'  },
            { path: 'lead-status-history/:leadId', component: LeadStatusHistoryComponent },
            { path: 'bulk-lead', component: LeadBulkActionsComponent },
            { path: 'link-group/:linkId', component: LinkGroupComponent },
            { path: 'link-history/:linkId', component: LinkHistoryComponent },
            { path: 'rpt2dim', component: DistributionByTwoDimensionsComponent },
            { path: 'rpt2dim/:dm1val/:dm2val/:ms/:st/:ed/:ur/:dm1/:dm2', component: DistributionByTwoDimensionsComponent },
            { path: 'buzz-score', component: BuzzScoreComponent },
            { path: 'click-tracking-rpt', component: ClickTrackingReportComponent },
            { path: 'tag-cloud', component: TagCloudComponent },
            { path: 'viplist', component: VipListComponent },
            { path: 'vip/:vipId', component: VipComponent },
            { path: 'vip-preview/:vipId', component: VipPreviewComponent },
            { path: 'presentations', component: PresentationsComponent },
            { path: 'analytics', component: AnalyticsComponent },
            { path: 'vipSlideCategory', component: SlideCategorySetupComponent },
            { path: 'custom/co_portal', component: CoPortalComponent },
            {
                path: 'goal', component: GoalsComponent, title: 'My Monthly Goals', children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                    { path: 'list', component: GoalsListComponent },
                    { path: 'setup', component: GoalsSetupComponent }
                ]
            },
            { path: 'custom/rpt_ihapptsetters', component: ApptSettersActivityComponent },
            { path: 'custom/agreement-dashboard', component: AgreementsDashboardComponent },
            { path: 'custom/rpt_ihscorecardbycode', component: ScorecardByCodeComponent },
            { path: 'custom/rpt_ihscorecardbykeyword', component: ScorecardByKeywordComponent },
            { path: 'custom/rpt_ihbipclicksap', component: BipClickByApptSetterComponent },
            { path: 'custom/rpt_ihscorecard', component: ScorecardComponent },
            { path: 'custom/rpt_ihnobuycontest', component: BipNoBuyContestComponent },
            { path: 'custom/rpt_ihscorecardbytvcode', component: ScorecardByTvComponent },
            { path: 'custom/ihapptsetters', component: ApptSettersManagementComponent },
            { path: 'custom/rpt_ihduplicatelead', component: DuplicatesLeadComponent },
            { path: 'custom/iheblast', component: QuickBlastComponent },
            { path: 'home', component: HomeComponent, title: 'My SalesOptima' },
            { path: 'my-task', component: MyTaskComponent },
            { path: 'deviceinfo', component: DeviceInfoLogsComponent },
            { path: 'home/search', component: HomeSearchComponent },
            { path: 'home/search/:txt', component: HomeSearchComponent },
            { path: 'custom/rpt_ihscorecardbyapptsetter', component: ScorecardByApptSetterComponent },
            { path: 'custom/rpt_ihscorecardcompare', component: ScorecardCompareComponent },
            { path: 'custom/rpt-statushistorytimespan', component: RptStatushistorytimespanComponent },
            { path: 'custom/rpt-tworoadspipeline', component: RptTworoadspipelineComponent },
            { path: 'custom/rpt-tworoadspipelinebytag', component: RptTworoadspipelinebytagComponent },
            //{ path: 'task', component: ContactTaskComponent },
            //{ path: 'task/:taskid', component: ContactTaskComponent },
            //{ path: 'task/:ownerId/:cat', component: ContactTaskComponent },
            { path: 'zapier-setting', component: ZapierSettingComponent },
            { path: 'user-prefs', component: UserPrefsComponent },
            { path: 'cm-user-prefs', component: CmUserPrefsComponent, title : 'Club Manager User Preferences' },
            { path: 'gsettings', component: GoogleIntegrationComponent },



            //BI - Reports
            { path: 'rpt-common/1', component: RptCommonComponent },
            { path: 'unauthorized', component: MissingCredentialsComponent },
            { path: 'vipanalytics', component: VIPAnalyticsComponent },
            { path: 'call', component: ContactCallComponent, title: 'Call List' },
            { path: 'call/:cid', component: ContactCallComponent, title: 'Call List' },
            { path: 'call-ih', component: ContactCallIHComponent, title: 'Call List' },
            { path: 'call-ih/:cid', component: ContactCallIHComponent, title: 'Call List' },

            //Marketing     
            { path: 'longcodeline', component: LongcodelineListComponent },
            { path: 'voice', component: VoiceRecordingsComponent },
            { path: 'marketing/view', component: ViewMailingsComponent, title : 'My Mailings' },
            { path: 'marketing/view/:type', component: ViewMailingsComponent, title: 'View Mailings' },
            { path: 'direct-mail', component: DirectMailComponent, title: 'New Mailing' },
            //{ path: 'mailing-common/', component: MailingCommonComponent },
            //{ path: 'mailing-common/:id', component: MailingCommonComponent },
            { path: 'direct-mail/:id', component: DirectMailComponent, title: 'Mailing' },
            { path: 'text-campaign', component: TextCampaignComponent, title : 'Text Campaign' },
            { path: 'text-campaign/:id', component: TextCampaignComponent, title: 'Text Campaign' },
            { path: 'voice-campaign', component: VoiceCampaignComponent },
            { path: 'keywordlist', component: KeywordListComponent },
            { path: 'marketing/mailing', component: ViewDirectMailComponent },
            { path: 'marketing/mailing/:mid', component: ViewDirectMailComponent },
            { path: 'marketing/emailsnippetlist', component: EmailSnippetComponent },
            { path: 'activity-history/:ownerId', component: ActivityHistoryComponent, title : 'History' },
            { path: 'marketing/custom-action-screen', component: CustomActionScreenComponent },
            { path: 'handleClickTrack', component: HandleClickTrackComponent },
            { path: 'accountSummary', component: AccountSummaryComponent },
            { path: 'ticket', component: TicketsComponent },


            { path: 'lead/lead-invoice', component: LeadInvoiceComponent },
            { path: 'export-list', component: ContactExportsComponent },

            { path: 'SOForms/ih/adcode-manager', component: AdcodeManagerComponent },
            { path: 'SOForms/ih/ih-info-center', component: IhInfoCenterComponent },
            { path: 'SOForms/ih/disclosures', component: DisclosuresComponent },
            { path: 'SOForms/ih/thank-you', component: ThankYouComponent },

            { path: 'SOForms/so/free-trial', component: FreeTrialSignupComponent },

            { path: 'sosc/default', component: RepSettingsComponent },

            { path: 'SOForms/harvest/parser-rules', component: ParserRulesComponent },
            { path: 'contactNewCall', component: ContactNewCallComponent },

            { path: 'SOForms/so-digital/upload-files', component: UploadFileComponent },

            //SOPost
            { path: 'ih-unsubscribe', component: IhUnsubscribeComponent },
            { path: 'referral', component: ReferralComponent },
            { path: 'update-info', component: UpdateInfoComponent },
            { path: 'eml-pref-confirm', component: EmlPrefConfirmComponent },
            { path: 'so-unsubscribe', component: SoUnsubscribeComponent },
            { path: 'so-unsubn', component: SoUnsubnComponent },
            { path: 'update', component: UpdateComponent },
            { path: 'test-eval', component: TestEvalComponent },
            { path: 'so-unsub', component: SoUnsubComponent },
            { path: 'so-sign-up', component: SopostSignupComponent },
            { path: 'wcmPost', component: WcmPostComponent },
            { path: 'sopost-webform', component: SopostWebformComponent },

            //SAM
            { path: 'handle-reply/:contactId', component: HandleReplyComponent },
            { path: 'handle-reply/:contactId/:txtMsgIBID', component: HandleReplyComponent },
            { path: 'handle-tm/:contactId', component: HandleTmComponent },
            { path: 'handle-tm/:contactId/:userId', component: HandleTmComponent },
            { path: 'vc/:voiceCallId', component: VcComponent },
            { path: 'vc/:voiceCallId/:userId', component: VcComponent },
            { path: 'make-vc/:contactId', component: MakeVcComponent },
            { path: 'ct/:contactId', component: CtComponent },
            { path: 'lc-note/:contactId', component: LcNoteComponent },
            { path: 'lc-email/:contactId', component: LcEmailComponent },


            //BCM
            { path: 'bcm-redirect', component: BcmRedirectComponent },


            { path: 'handle-vc/:id', component: HandleVcComponent },
            //Default Route. (declare routes before default Route)
            { path: '**', redirectTo: '/home' },

        ]),

    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true,
        },
        PasswordPolicyService, EncryptionService, GlobalService, UtilityService, AuthenticateService,
        ContactService, LocalService, SearchContactService, ContactSearchService, OutBoundEmailService, LiveConnectService,
        TaskService, NotesService, OutBoundTextMsgService, SignupService, AccountSetupService,
        TeamOfficeSetupService, BilligService, ZipService, ClassCodeService, AppointmentSettingService,
        CompanySettingService, OfficeSetupService, DatePipe, TxtMsgService, ServicesStatusService, MenuService, StringToNumberPipe,
        htmlEmailPricingService, MyCalenderService, ToolbarService, Title, DecimalPipe, LinkGroupService, LeadSettingService, LeadInvoiceService,
        IhService, SoService, SoFormsSignupService,
        SoservicesService, HarvestService, SoDigitalService, SoInvoiceService,
        CLPEnvironmentService, SoPostService, WcmPostService, CmUserPrefService, ContractManagerService, SoMetricService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
