import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ClubUserPreferencesResponse, CMUserPref } from '../models/cm-user-pref.model';
import { CMContractContactSummary } from '../models/contract-manager.model';
import { SimpleResponse } from '../models/genericResponse.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class ContractManagerService {

    private baseUrl: string;
    private api: string = "api/ContractManager";


    constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
        this.baseUrl = _baseUrl + this.api;
    }

    async CMContract_ContactSummary(encryptedUser: string, contactID: number): Promise<CMContractContactSummary | void> {
        const a = await this.httpClient.get<CMContractContactSummary>(`${this.baseUrl}/CMContract_ContactSummary/${contactID}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + encryptedUser
            })
        }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, "r - " + encryptedUser, encryptedUser, "CmUserPrefService", "clubUserPreferences_Load") });
        return a;
    }
}
