<kendo-grid #companyGrid class="company-grid" *ngIf="_gridCnfgService.reloadGridGeneric"
    id="gridId"
    [kendoGridBinding]="companyList"
    [pageSize]="_gridCnfgService.pageSize"
    [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
    [sortable]="{mode: 'multiple'}"
    [scrollable]="'scrollable'"
    [sort]="_gridCnfgService.sort"
    [columnMenu]="{ filter: true }"
    [resizable]="true"
    [reorderable]="true"
    (columnReorder)="_gridCnfgService.columnsOrderChanged('company_grid', $event)"
    (sortChange)="_gridCnfgService.sortChange('company_grid', $event)"
    (pageChange)="_gridCnfgService.pageChange('company_grid', $event)"
    (columnResize)="_gridCnfgService.columnResize(14,'company_grid', $event)"
    (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'company_grid',companyGrid)">
    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
        [field]="column.field"
        [title]="column.title | titlecase"
        [width]="column.width | stringToNumber"
        [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
        [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
        [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
        [filterable]="true"
        [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1 || (isCompanyCreate && column.field == 'numContacts')"
        [includeInChooser]="column.field=='$' ? false : true">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="customer-name" *ngIf="column.field == '$' ">{{ rowIndex+1 }}</div>
            <div class="customer-name" *ngIf="column.field == '$$' "><a class="grid-icon"><img src="../../../../assets/companytitle_grid.svg" (click)="rowSelectionCompanyChange(dataItem['companyID'])" /></a></div>
            <div class="customer-name" *ngIf="column.field == 'companyName'" (click)="rowSelectionCompanyChange(dataItem['companyID'])"><a class="text-primary">{{ !dataItem[column.field] ? '--' : dataItem[column.field] }}</a></div>
            <div class="customer-name" *ngIf="column.field == 'webSite'"> <a class="contact-cursor-pointer" (click)="goToLinkCompanyList(dataItem,'website');">{{ !dataItem[column.field] ? ' ' : dataItem[column.field] }}</a></div>
            <div class="customer-name" *ngIf="column.field == 'phone'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] | phoneFormat}}</div>
            <div class="customer-name" *ngIf="column.field == 'numContacts'">{{ dataItem[column.field]  }}</div>
            <div class="customer-name" *ngIf="column.field == 'city'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] }}</div>
            <div class="customer-name" *ngIf="column.field == 'state'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] }}</div>
            <div class="customer-name" *ngIf="column.field == 'firstName'"> <a class="contact-cursor-pointer" (click)="goToLinkCompanyList(dataItem,'userName');">{{ dataItem.lastName  }},{{ dataItem.firstName  }}</a></div>
            <div class="customer-name" *ngIf="column.field == 'dtModified'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] | date: dateFormat }}</div>
            <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] | date: dateFormat}}</div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-messages [pagerItemsPerPage]="'Per page'" [pagerItems]="'Companies'"> </kendo-grid-messages>
</kendo-grid>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
