import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from 'util';

import { ApppointmentResponse, ShareableApptUI, UpdateAppt } from '../../../models/appt.model';
import { CLPUser, UserDD, UserResponse } from '../../../models/clpuser.model';
import { Contact, ContactLimitedFields } from '../../../models/contact.model';
import { ContactHistory, ContactHistoryListResponse, HistoryListResponse, SCSessionLog } from '../../../models/contactHistory.model';
import { Document } from '../../../models/document';
import { EmailTemplateLoad } from '../../../models/emailTemplate.model';
import { eApptCategory, eFeatures, eTaskCategory, eUserRole } from '../../../models/enum.model';
import { MailMergeTemplateLoad } from '../../../models/marketing.model';
import { NoteFilterResponse, NoteResponse } from '../../../models/note.model';
import { NoteTypeCodeModel } from '../../../models/noteTypeCode.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ContactService } from '../../../services/contact.service';
import { EmailTemplateService } from '../../../services/email-template.service';
import { LeadSettingService } from '../../../services/leadSetting.service';
import { LinkGroupService } from '../../../services/link-group.service';
import { MarketingService } from '../../../services/marketing.service';
import { NotesService } from '../../../services/notes.service';
import { OutBoundEmailService } from '../../../services/outBoundEmail.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { UserService } from '../../../services/user.service';
import { LeadApptComponent } from '../../lead-module/common/lead-appt/lead-appt.component';
import { Note } from '../../../models/note.model';
import { Task } from '../../../models/task.model';
import { ContactInfo, SendMailInfo } from '../../../models/report.model';
import { TextMsgTemplateService } from '../../../services/text-msg-template.service';
import { VoiceCallService } from '../../../services/voice-call.service';
import { VoiceCallModel } from '../../../models/voiceCall.model';
import { DashboardService } from '../../../services/dashboard.service';
import { MessageResponse } from '../../../models/message.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { GlobalService } from '../../../services/global.service';
import { AppointmentSettingService } from '../../../services/appointmentSetting.service';
declare var $: any;

@Component({
    selector: 'activity-history',
    templateUrl: './activity-history.component.html',
    styleUrls: ['./activity-history.component.css']
})
/** activity-history component*/
export class ActivityHistoryComponent implements OnInit {
    user: CLPUser;
    eUserRole = eUserRole;
    roleFeaturePermissions: RoleFeaturePermissions;
    private encryptedUser: string = '';
    showSpinner: boolean = false;
    contactHistory: ContactHistory[];
    @Input() isShowToggleBtn: boolean = true;
    @Input() isShowToggle: boolean = true;
    @Input() ownerId: number = 0;
    @Input() noteId: number = 0;
    @Input() ownerName: string = "";
    @Input() ownerType: number = 0;
    @Input() isFromLeadContactHistory: boolean = true;
    @Input() startDate: Date;
    @Input() endDate: Date;
    @Input() isShowUserHistory: boolean = false;
    @Input() isShowContactHistory: boolean = false;
    @Input() isShowLeadHistory: boolean = false;
    @Input() isShowNoteHistory: boolean = false;
    @Input() infoHistory: string = '';
    clickedAddNew: boolean = false;
    @Input() isShowAddNoteButton: boolean = false;
    @Input() isShowLinks: boolean = true;
    @Input() isFromLiveConnect: boolean = false;
    txtdtSend: Date;
    selectedNoteTypeCode: number = -1;
    noteTypeCodes: NoteTypeCodeModel[] = [];
    selectedHistoryType: number = 1;
    initHistoryList: ContactHistory[] = [];
    historyList: ContactHistory[] = [];
    routeParamsSubscription: Subscription;
    isShowCompanyHistory: boolean = false;
    dateFormat: string = "MM/dd/yyyy";
    headingTxt: string = "";
    contactHistoryListResponse: ContactHistoryListResponse;
    selectedContact: ContactLimitedFields;
    //for new appt
    shareableAppt: ShareableApptUI;
    isShowApptModal: boolean = false;
    @ViewChild('closeInputButton') closeInputButton: ElementRef;
    @ViewChild('leadApptChild') leadApptChild: LeadApptComponent;
    category: eApptCategory = eApptCategory.None
    sendMailInfo: any = { isShow: false, contactId: 0 };
    showContactDetail: boolean = false;
    showEmailComponent: boolean = false;
    showTextComponent: boolean = false;
    showNoteComponent: boolean = false;
    showTaskComponent: boolean = false;
    showApptComponent: boolean = false;
    showDocComponent: boolean = false;
    showLinkComponent: boolean = false;
    showLeadComponent: boolean = false;
    showMailingComponent: boolean = false;
    showReferralComponent: boolean = false;
    loadOtherComponents: boolean = false;
    isShowReferredEmail: boolean = false;
    isShowNoteEditPopup: boolean = false;
    mailingContactId: number = 0;
    selectedContactId: number;
    routeContactId: number;
    noteData: Note = <Note>{};
    taskData: Task = <Task>{};
    textMsgInfo: SendMailInfo = { contactId: 0, isShow: false };
    textCallInfo: ContactInfo = { contactId: 0, isShow: false, contact: null };
    eCat: eApptCategory = eApptCategory.None;
    messageID: number = 0;
    messageResponse: MessageResponse;
    companyData: ClpCompany;
    @Input() isFromCustomAction: boolean = false
    textMsgStatus: number
    voiceCallID: number;
    txtMsgID: number;
    newNote: boolean;
    isShowTaskModal: boolean;
    task: ContactHistory = <ContactHistory>{};
    voiceCallId: any;
    isShowModalVoiceCallLog: boolean;
    scPresentationSrc: any;
    userList: UserDD[];
    blnIsIH: boolean = false;

    constructor(private _accountSetupService: AccountSetupService,
        private _dashboardService: DashboardService, private route: ActivatedRoute,
        private voiceCallService: VoiceCallService,
        private textMessageService: TextMsgTemplateService,
        private datepipe: DatePipe,
        public _leadService: LeadSettingService,
        public _contactService: ContactService,
        public _marketingService: MarketingService,
        public _accountService: AccountSetupService,
        public _userService: UserService,
        public _noteService: NotesService,
        public _emailTemplateService: EmailTemplateService,
        public _outboundEmailSrvc: OutBoundEmailService,
        public _linkGroupSrvc: LinkGroupService,
        private _utilityService: UtilityService,
        private _router: Router,
        private _route: ActivatedRoute,
        public _localService: LocalService,
        public _globalService: GlobalService,
       public _appointmentSettingService: AppointmentSettingService,
    ) {
        if (!isNullOrUndefined(this._localService.isMenu))
            this._localService.isMenu = true;
        this._route.queryParams.subscribe(params => {
            if (!isNullOrUndefined(params)) {
                if (params?.ntId > 0) {
                    this.noteId = params?.ntId;
                }
                if (params?.ch) {
                    this.isShowContactHistory = params?.ch;
                    this.ownerName = localStorage.getItem("ownerName");
                    this.ownerType = +localStorage.getItem("ownerType");
                }
                if (params?.isNew) {
                    this.isShowNoteHistory = params?.isNew;
                }
            }
        })
    }

    ngOnInit() {
        if (this.isFromLiveConnect)
            this._localService.isMenu = false;
        this.routeParamsSubscription = this._route.paramMap.subscribe(async params => {
            if (params.has('ownerId') && this.noteId > 0) {
                this.ownerId = +params.get('ownerId');
                this.ownerName = localStorage.getItem("ownerName");
                this.ownerType = +localStorage.getItem("ownerType");
                if (this.ownerType == 2)
                    this.isShowContactHistory = true;
                if (this.ownerType == 3) {
                    this.isShowLeadHistory = true;
                    this.isShowAddNoteButton = true;
                }
                this.isShowNoteHistory = true;
            }
            if (params.has('ownerId') && this.noteId == 0 && !this.isShowContactHistory) {
                this.ownerId = +params.get('ownerId');
                this.ownerName = localStorage.getItem("ownerName");
                this.isShowCompanyHistory = true;
            }
            if (params.has('ownerId'))
                this.ownerId = +params.get('ownerId');
            if (params.has('msgid')) {
                this.messageID = +params.get('msgid');
                this.updateMessage();
            }
        })
        this.category = Number(localStorage.getItem("category"));

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.dateFormat = this.user.dateFormat;
                        this.getCompanyByCompanyId();
                        this.getNoteFilters();
                        this.getLoadUsers();
                        if (!isNullOrUndefined(this.ownerName)) {
                            this.headingTxt = "Activity items For " + this.ownerName;
                        }
                        else {
                            this.headingTxt = "Activity items For ";
                        }
                        if (this.isShowUserHistory) {
                            this.getUserHistory();
                        }
                        if (this.isShowCompanyHistory) {
                            this.selectedHistoryType = 0;
                            this.selectedNoteTypeCode = -1;
                            this.getCompanyHistory();
                        }
                        if (this.isShowContactHistory) {
                            if (!isNullOrUndefined(this.ownerName)) {
                                this.headingTxt = "Contact Activity: " + this.ownerName;
                            }
                            else {
                                this.headingTxt = "Contact Activity ";
                            }
                            this.selectedHistoryType = 0;
                            this.selectedNoteTypeCode = -1;
                            this.getContactHistory();
                        }
                        if (this.isShowLeadHistory) {
                            this.selectedHistoryType = 0;
                            this.selectedNoteTypeCode = -1;
                            this.getLeadHistory();
                        }
                        if (this.infoHistory == "notes") {
                            this.selectedHistoryType = 1;
                            this.filterNoteByTypeCode(1);
                        }
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });

    }

    async getCompanyByCompanyId() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result)
                    this.companyData = UtilityService.clone(result?.company);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("activity-history.getCompanyByCompanyId", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    if (!isNullOrUndefined(response)) {
                        if (!isNullOrUndefined(response?.user)) {
                            this.user = UtilityService.clone(response.user);
                            this.roleFeaturePermissions = response.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("activityHistory.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async updateMessage() {
        this._dashboardService.messageUpdate(this.encryptedUser, this.messageID)
            .then(async (result: MessageResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.messageResponse = UtilityService.clone(result);
                    if (this.messageResponse.messageBool) {
                        localStorage.removeItem("messageId");
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("acitvity-history.updateMessage", err.message, null, 'messageID ' + this.messageID);
                this._utilityService.handleErrorResponse(err);
            });
    }


    getNoteFilters() {
        this._noteService.getNoteFilters(this.encryptedUser, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: NoteFilterResponse) => {
                if (result)
                    this.noteTypeCodes = UtilityService.clone(result?.noteTypeCodes);
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("acitvity-history.getNoteFilters", err.message, null, 'cLPUserID ' + this.user.cLPUserID + "," + "cLPCompanyID " + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getUserHistory() {
        const startDate: string = this.datepipe.transform(this.startDate, 'MMddyyyy') ?? "";
        const endDate: string = this.datepipe.transform(this.endDate, 'MMddyyyy') ?? "";
        await this._userService.userHistory_Get(this.encryptedUser, this.user?.cLPUserID, this.ownerId, startDate, endDate, this.selectedNoteTypeCode)
            .then(async (result: HistoryListResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    this.initHistoryList = response?.historyList;
                    this.historyList = response?.historyList;
                    this.toSortHistoryList(this.historyList)
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("acitvity-history.getUserHistory", err.message, null, 'cLPUserID ' + this.user.cLPUserID + "," + 'ownerId ' + this.ownerId + "," + 'startDate ' + startDate + "," + 'endDate ' + endDate + "," + 'selectedNoteTypeCode ' + this.selectedNoteTypeCode);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getCompanyHistory() {
        await this._accountService.getCompanyHistory(this.encryptedUser, this.user?.cLPCompanyID, this.ownerId, this.user?.cLPUserID, this.selectedNoteTypeCode)
            .then(async (result: HistoryListResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    this.initHistoryList = response?.historyList
                    this.historyList = response?.historyList;
                    this.toSortHistoryList(this.historyList)
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("acitvity-history.getUserHistory", err.message, null, 'cLPCompanyID ' + this.user.cLPCompanyID + "," + 'ownerId ' + this.ownerId + "," + 'cLPUserID ' + this.user.cLPUserID + "," + 'selectedNoteTypeCode ' + this.selectedNoteTypeCode);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContactHistory() {
        this._contactService.contactHistory_Get(this.encryptedUser, this.ownerId, this.user?.cLPUserID, "", "")
            .then(async (result: ContactHistoryListResponse) => {
                if (result) {
                    this.contactHistoryListResponse = UtilityService.clone(result);
                    this.contactHistory = this.contactHistoryListResponse.contactHistory;
                    this.initHistoryList = this.contactHistoryListResponse.contactHistory;
                    this.historyList = this.contactHistoryListResponse.contactHistory;
                    this.toSortHistoryList(this.historyList)
                }
                else {
                    //this._contactSearchService.showSpinner = false;
                    this.contactHistory = this.initHistoryList;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("acitvity-history.getContactHistory", err.message, null, 'ownerId ' + this.ownerId + "," + "cLPUserID " + this.user?.cLPUserID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    toCloseEmailModal(event) {
        if (event) {
            $('#viewEmailModal').modal('hide');
        }
    }

    async getLeadHistory() {
        this._leadService.getLeadActivityHistory(this.encryptedUser, this.ownerId, this.user?.cLPUserID, this.user?.cLPCompanyID)
            .then(async (result: HistoryListResponse) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    this.initHistoryList = response?.historyList
                    this.historyList = response?.historyList;
                    this.toSortHistoryList(this.historyList)
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("acitvity-history.getLeadHistory", err.message, null, 'ownerId ' + this.ownerId + "," + "cLPUserID " + this.user?.cLPUserID + "," + "cLPCompanyID " + this.user?.cLPCompanyID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getNote(noteId: number) {
        if (noteId != null && noteId != undefined) {
            this._noteService.noteLoad(this.encryptedUser, noteId)
                .then(async (result: NoteResponse) => {
                    if (result) {
                        this.ownerId = result?.note?.ownerID
                        if (this.ownerId != null && this.ownerId != undefined) {
                            this.getContactHistory()
                        }

                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("acitvity-history.loadUserById", err.message, null, 'noteId ' + noteId);
                    this._utilityService.handleErrorResponse(err);
                });
        }

    }

    async downloadDocuments(selectedDocumentId) {
        await this._outboundEmailSrvc.downloadDocumentsByDocId(this.encryptedUser, selectedDocumentId)
            .then(async (result: Document) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    const byteCharacters = atob(response?.bytes);
                    const byteNumbers = new Array(byteCharacters?.length);
                    for (let i = 0; i < byteCharacters?.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const fileType = response?.documentName.split('.');
                    const file = new Blob([byteArray], { type: fileType[1] });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(file);
                    link.download = response.documentName;
                    link.click();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("acitvity-history.loadUserById", err.message, null, 'selectedDocumentId ' + selectedDocumentId);
                this._utilityService.handleErrorResponse(err);
            });
    }



    loadEmailTemplate(emailTempId: number) {
        this._emailTemplateService.getEmailTemplateById(this.encryptedUser, emailTempId)
            .then((result: EmailTemplateLoad) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    localStorage.setItem('object', JSON.stringify(response?.htmlText));
                    const url = this._router.serializeUrl(
                        this._router.createUrlTree([`/template-preview/${emailTempId}/emt`])
                    );
                    window.open(url)
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("acitvity-history.loadUserById", err.message, null, 'emailTempId ' + emailTempId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    loadMailMergeTemplate(mailMergeTemplateId) {
        this._marketingService.loadMailMergeTemplate(this.encryptedUser, mailMergeTemplateId)
            .then((result: MailMergeTemplateLoad) => {
                if (result) {
                    const response = UtilityService.clone(result);
                    localStorage.setItem('object', JSON.stringify(response.hTMLText));
                    const url = this._router.serializeUrl(
                        this._router.createUrlTree([`/template-preview/${mailMergeTemplateId}`])
                    );
                    window.open(url)
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("acitvity-history.loadUserById", err.message, null, 'mailMergeTemplateId ' + mailMergeTemplateId);
                this._utilityService.handleErrorResponse(err);
            });
    }

    filterNoteByTypeCode(value: number) {
        if (value == -1) {
            this.selectedHistoryType = 1;
            this.historyList = this.initHistoryList.filter(x => x.type == 1);
            this.toSortHistoryList(this.historyList)
        }
        else {
            this.selectedHistoryType = 1;
            this.historyList = this.initHistoryList.filter(x => x.type == 1 && x.note_NoteTypeCode == value);
            this.toSortHistoryList(this.historyList)
        }
    }

    changeHistoryType(value: string) {
        switch (value) {
            case "0":
                this.selectedNoteTypeCode = -1;
                this.historyList = this.initHistoryList;
                this.toSortHistoryList(this.historyList)
                break;
            case "1":
                this.filterNoteByTypeCode(this.selectedNoteTypeCode);
                break;
            case "2": case "3": case "4": case "5": case "8": case "9": case "10": case "11": case "12":
                this.selectedNoteTypeCode = -1;
                this.historyList = this.initHistoryList.filter(x => x.type == Number(value));
                this.toSortHistoryList(this.historyList)
                break;
        }
    }

    ngOnDestroy(): void {
        this.routeParamsSubscription?.unsubscribe();
    }

    getEmittedValueFromNote(value) {
        this.isShowNoteHistory = value;
        if (!this.isShowNoteHistory) {
            $('#viewNoteModal').modal('hide');
        }
        if (this.isShowContactHistory) {
            this.getContactHistory();
        }
        else {
            this.getLeadHistory();

        }
    }


    addNewNote() {
        this.newNote = true
        if (this.isShowNoteHistory == false) {
            setTimeout(() => {
                this.isShowNoteHistory = true
            }, 100);
        } else if (this.isShowNoteHistory == true && this.noteId > 0) {
            /*this.noteId = 0;*/
            this.clickedAddNew = true;
            this.isShowNoteHistory = false;
            setTimeout(() => {
                this.isShowNoteHistory = true;
            }, 100);
        } else {
            setTimeout(() => {
                this.isShowNoteHistory = true
            }, 100);
        }
    }

    goToLink(name: string) {
        if (!isNullOrEmptyString(name)) {
            switch (name) {
                case "company":
                    this._router.navigateByUrl(`company-create?cid=${this.ownerId}`);
                    break;
                case "task":
                    localStorage.setItem("ownerName", this.selectedContact?.firstName + ' ' + this.selectedContact?.lastName);
                    this._router.navigate(['/task', this.ownerId, eTaskCategory.Contact], { queryParams: { isNew: true } });
                    break;
                case "contact":
                    this._router.navigate(['/contact', this.user?.cLPUserID, this.ownerId]);
                    break;
                case "lead":
                    this._router.navigate(['/lead-detail', this.ownerId]);
                    break;
                case "calendar":
                    this._router.navigate(['/calender']);
                    break;
                case "call":
                    this._router.navigate(['/call']);
                    break;
            }

        }
    }

    async appointmentPopUp(apptData) {
        await this._appointmentSettingService.apptLoad(this.encryptedUser, apptData?.app_ApptID, this.user?.cLPUserID, this.user?.cLPCompanyID).then
            (async (result: ApppointmentResponse) => {
                if (!isNullOrUndefined(result)) {
                    let appt = UtilityService.clone(result);
                    if (!isNullOrUndefined(appt)) {
                        if (appt.appt.cLPCompanyID == 1226) {
                            if (appt.appt.cLPUserID != 3893) {
                                this.blnIsIH = true;
                                if (appt.appt.proposalID > 0) {
                                    this.blnIsIH = false
                                }
                            }
                        }
                    }
                }
            })
        this.shareableAppt = {
            apptId: apptData?.app_ApptID,
            rApptID: apptData?.app_RApptID,
            leadContactId: 0,
            cat: Number(apptData?.app_Category),
            ownerId: Number(apptData?.app_Category) == eApptCategory.Contact ? apptData?.app_ContactID : Number(apptData?.app_Category) == eApptCategory.Lead ? apptData?.app_LeadID : 0,
            currentUrlAppt: 'appointmentlist',
            isNewTab: true,
        };
        this.isShowApptModal = true;
    }

    hideQuickApptModal(updateAppt?: UpdateAppt) {
        if (!isNullOrUndefined(updateAppt)) {
            if (updateAppt.isSave)
                this.leadApptChild.getApptList();
        }
        this.isShowApptModal = false;
        this.shareableAppt = null;
        this.closeModalApptModal();
    }

    closeModalApptModal() {
        $('#quickApptModalActivityHistory').modal('hide');
    }

    refresh() {
        this.noteId = 0;
        this.newNote = false
        this.ngOnInit();
    }

    toSortHistoryList(historyList) {
        let history = historyList;
        if (history?.length > 0) {
            history?.sort((x, y) => +new Date(y.dtToSort) - +new Date(x.dtToSort));
            return history;
        }
    }

    showCommonComponent() {
        this.showEmailComponent = false; this.showTextComponent = false; this.showNoteComponent = false; this.showTaskComponent = false; this.showApptComponent = false; this.showDocComponent = false; this.showLinkComponent = false; this.showLeadComponent = false; this.showMailingComponent = false; this.showReferralComponent = false;
        switch (this._localService.showCommonComp) {
            case 'email':
                this.showContactDetail = false;
                this.showEmailComponent = true;
                break;
            case 'sms':
                this.showContactDetail = false;
                this.showTextComponent = true;
                break;
            case 'note':
                this.showContactDetail = false;
                this.resetNoteComponent();
                break;
            case 'task':
                this.showContactDetail = false;
                this.resetTaskComponent();
                break;
            case 'appt':
                this.showContactDetail = false;
                this.resetApptComponent();
                break;
            case 'upload':
                this.showContactDetail = false;
                this.resetDocComponent();
                break;
            case 'link':
                this.showContactDetail = false;
                this.resetLinkComponent();
                break;
            case 'lead':
                this.showContactDetail = false;
                this.resetLeadComponent();
                break;
            case 'mailing':
                this.showContactDetail = false;
                this.resetMailingComponent();
                break;
            case 'referral':
                this.showContactDetail = false;
                this.resetReferralComponent();
                break;
            default:
                break;
        }
    }

    resetTaskComponent() {
        this.showTaskComponent = false;
        setTimeout(() => this.showTaskComponent = true, 100);
    }

    resetNoteComponent() {
        this.showNoteComponent = false;
        setTimeout(() => this.showNoteComponent = true, 100);
    }

    resetApptComponent() {
        this.showApptComponent = false;
        setTimeout(() => this.showApptComponent = true, 100);
    }

    resetDocComponent() {
        this.showDocComponent = false;
        setTimeout(() => this.showDocComponent = true, 100);
    }

    resetLinkComponent() {
        this.showLinkComponent = false;
        setTimeout(() => this.showLinkComponent = true, 100);
    }

    resetLeadComponent() {
        this.showLeadComponent = false;
        setTimeout(() => this.showLeadComponent = true, 100);
    }

    resetMailingComponent() {
        this.showMailingComponent = false;
        setTimeout(() => this.showMailingComponent = true, 100);
    }

    resetReferralComponent() {
        this.showReferralComponent = false;
        setTimeout(() => this.showReferralComponent = true, 100);
    }

    selectNote(noteData: Note) {
        this.noteData = noteData;
        this._localService.showCommonComp = 'note';
        this.showCommonComponent();
        this._localService.showPristneForm();
    }

    selectTask(taskData: Task) {
        this.taskData = taskData;
        this._localService.showCommonComp = 'task';
        this.showCommonComponent();
        this._localService.showPristneForm();
    }

    getTextActivity(txtmsgId: number, txtMsgStatus, txtdtSend) {
        this.showSpinner = true
        if (txtmsgId > 0) {
            this.textMessageService.TxtxMsgLoad(this.encryptedUser, txtmsgId)
                .then(async (result: any) => {
                    if (result && result.contactID) {
                        this.textMsgStatus = txtMsgStatus
                        this.txtMsgID = txtmsgId
                        this.textMsgInfo.contactId = result?.contactID
                        this.textMsgInfo.isShow = true;
                        this.txtdtSend = txtdtSend;
                        this.showCommonComponent()
                        $('#viewTextModal').modal('show');
                        this.showSpinner = false

                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("acitvity-history.getTextActivity", err.message, null, 'txtMsgStatus ' + txtMsgStatus);
                    this._utilityService.handleErrorResponse(err);
                });
        }

    }

    getVoiceCallLoad(vCall_VoiceCallID: number) {
        this.showSpinner = true
        this.voiceCallID = vCall_VoiceCallID
        if (vCall_VoiceCallID > 0) {
            this.voiceCallService.voiceCallLoad(this.encryptedUser, vCall_VoiceCallID)
                .then(async (result: VoiceCallModel) => {
                    if (result && result.contactID) {
                        this.textCallInfo.contactId = result?.contactID
                        this.getContactData()
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("acitvity-history.getVoiceCallLoad", err.message, null, 'vCall_VoiceCallID ' + vCall_VoiceCallID);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    hideTextMsgModal(sms?: boolean) {
        this.textMsgInfo.isShow = false;
        this.textMsgInfo.contactId = 0;
        $('#viewTextModal').modal('hide');
    }

    hideTextCallModal(sms?: boolean) {
        if (sms) {
            this.getContactHistory()
            this.textCallInfo.isShow = false;
            this.textCallInfo.contactId = 0;
            this.textCallInfo.contact = null;
            $('#viewCallModal').modal('hide');
        } else {
            this.textCallInfo.isShow = false;
            this.textCallInfo.contactId = 0;
            this.textCallInfo.contact = null;
            $('#viewCallModal').modal('hide');
        }

    }



    async getContactData() {
        await this._contactService.contactLoad(this.encryptedUser, this.eCat == eApptCategory.Contact ? this.ownerId : this.textCallInfo.contactId)
            .then(async (result: Contact) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    if (response.contactID > 0) {
                        this.textCallInfo.isShow = true;
                        this.textCallInfo.contact = response;
                        $('#viewCallModal').modal('show');
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("acitvity-history.getContactData", err.message, null, 'eCat ' + this.eCat);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    isShowDownload() {
        if ((this.companyData?.secExcel && this.user?.userRole == eUserRole.General) || this.user?.isAllowDownload)
            return true;
        else
            return false;
    }

    goToNoteHistory(noteId: number, noteOwnerType: number, ownerId: number) {
        localStorage.setItem("ownerName", this.ownerName);
        localStorage.setItem("ownerType", noteOwnerType.toString());
        this._router.navigate(['/activity-history', ownerId], { queryParams: { ntId: noteId } });
        this.isShowNoteHistory = false;
        setTimeout(() => {
            this.isShowNoteHistory = true;
        }, 100);
    }


    goToTask(id) {
        let task: Task = <Task>{};
        this.task = this.contactHistory.filter(x => x.type == 3 && x.t_TaskID == id)[0];
        this.isShowTaskModal = true;
        //this.selectTask.emit(task);
        $('#viewTaskModal').modal('show');
    }

    hideQuickTaskModal(event) {
        //this.emitTask.emit(true);
        this.isShowTaskModal = false;
        $('#viewTaskModal').modal('hide');
    }

    VoiceCallLogWindowLink(vCall_VoiceCallID) {
        if (vCall_VoiceCallID && vCall_VoiceCallID != '' && vCall_VoiceCallID != null) {
            this.voiceCallId = vCall_VoiceCallID;
            this.isShowModalVoiceCallLog = true;
            $('#voiceCallLogModal').modal('show');
        }
    }

    hideVoiceCallLogModal(isCloseButtonClicked: boolean) {
        if (isCloseButtonClicked) {
            $('#voiceCallLogModal').modal('hide');
            this.isShowModalVoiceCallLog = false;
        }
    }

    slidecastSCLink(history: ContactHistory) {
        this.scPresentationSrc = history.scPresentationLink;
        this.showSlidecastImageModal();
    }

    slidecastImageLink(log: SCSessionLog) {
        this.scPresentationSrc = log.scSlideLink;
        this.showSlidecastImageModal();
    }

    showSlidecastImageModal() {
        $('#slidecastModal').modal('show');
    }

    hideSlidecastImageModal() {
        $('#slidecastModal').modal('hide');
    }

    async getLoadUsers() {
        await this._userService.getCLPCompany_LoadUsers(this.encryptedUser, this.user?.cLPCompanyID, 1, false).
            then(async (result: UserDD[]) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.userList = response;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.getLoadUsers", err.message, null,
                    'cLPCompanyID: ' + this.user?.cLPCompanyID
                    + ' permission: ' + 1
                    + ' includeNewUsers: ' + false
                );
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    getUserName(userId: string): string {
        var userName: string = "";
        if (!isNullOrUndefined(userId)) {
            userName = this.userList.filter(x => x.value == Number(userId))[0]?.text;
            var fullName = userName.split(",");
            userName = ""
            userName = fullName[1] + " " + fullName[0]
        }
        return userName;
    }

    mailingContactLink(type, mailingId?) {
        if (type) {
            switch (type) {
                case "contact": {
                    this._router.navigate(['/contact', this.user.cLPUserID, this.routeContactId]);
                    break;
                }
                case "company": {
                    this._router.navigateByUrl(`company-create?cid=${this.user.cLPCompanyID}`);
                    break;
                }
                case "emt": {
                    this._router.navigate([`/template-preview/${mailingId}/emt`])
                    break;
                }
                case "mmt": {
                    this._router.navigate([`/template-preview/${mailingId}`])
                    break;
                }
                case "image": {
                    this._router.navigateByUrl(`marketing/mailing?mid=${mailingId}`);
                    break;
                }
                case "day": {
                    this._router.navigateByUrl(`marketing/mailing?mid=${mailingId}`);
                    break;
                }
                case "status": {
                    this._router.navigateByUrl(`marketing/mailing?mid=${mailingId}`);
                    break;
                }
            }
        }
    }
}
