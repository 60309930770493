import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LeadStatus } from '../../../models/calender.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { LeadStatusListResponse } from '../../../models/report.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { GlobalService } from '../../../services/global.service';
import { ReportService } from '../../../services/report.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
    selector: 'app-rpt-statushistorytimespan',
    templateUrl: './rpt-statushistorytimespan.component.html',
    styleUrl: './rpt-statushistorytimespan.component.css',
    providers: [GridConfigurationService]
})
export class RptStatushistorytimespanComponent {
    encryptedUser: string = '';
    userResponse: UserResponse;
    user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    columns = [
        { field: '$', title: ' ', width: '40' },
        { field: 'LeadDesc', title: 'Lead', width: '109' },
        { field: 'FirstName', title: 'Contact', width: '114' },
        { field: 'dtFrom', title: '	Pre-underwriting review', width: '114' },
        { field: 'dtTo', title: 'Closing Scheduled', width: '124' },
        { field: 'Span', title: 'Time Span', width: '124' },
    ];

    userDataOriginal = []
    reorderColumnName: string = 'LeadDesc,FirstName,dtFrom,dtTo,Span';
    columnWidth: string = 'LeadDesc:109,FirstName:114,dtFrom:114,dtTo:120,Span:120';
    arrColumnWidth: string[] = ['LeadDesc:109,FirstName:114,dtFrom:114,dtTo:120,Span:120'];
    fromStatus: number = -1
    toStatus: number = -1
    dtStart: Date = new Date()
    dtEnd: Date = new Date()
    leadStatusCodeDD: LeadStatus[];
    statusHistoryTimespan: any;
    infoMsg: string = '';

    constructor(
        private _utilityService: UtilityService,
        public _localService: LocalService,
        public _gridCnfgService: GridConfigurationService,
        private _router: Router,
        private _globalService: GlobalService,
        private reportService: ReportService,
        private datePipe: DatePipe,
    ) {

    }

    ngOnInit() {
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (this.user) {
                        this.getGridConfiguration();
                        this.leadStatusCode_GetList()
                    }
                    else {
                        this._router.navigate(['/login']);
                    }
                });
            }
            else {
                this._router.navigate(['/login']);
            }
        });
    }



    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("contact.authenticateR", err.message, null,
                    'Feature: ' + eFeatures.ViewMyContacts
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'rpt_statushistorytimespan_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('rpt_statushistorytimespan_grid').subscribe((value) => { }));
    }

    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'rpt_statushistorytimespan_grid').subscribe((value) => this.getGridConfiguration());
    }

    async leadStatusCode_GetList() {
        await this.reportService.leadStatusCode_GetList(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: LeadStatusListResponse) => {
                if (result) {
                    this.leadStatusCodeDD = UtilityService.clone(result?.leadStatusCodeList);
                }

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async rptstatushistorytimespan_Get() {
        let dtStart = this.datePipe.transform(this.dtStart, 'MMddyyyy')
        let dtEnd = this.datePipe.transform(this.dtEnd, 'MMddyyyy')
        //let dtStart = 'test'
        //let dtEnd = 'test'
        await this.reportService.rptstatushistorytimespan_Get(this.encryptedUser, this.user?.cLPCompanyID, this.fromStatus, this.toStatus, dtStart, dtEnd)
            .then(async (result: any) => {
                if (result) {
                    this.statusHistoryTimespan = UtilityService.clone(result?.dynamicList);
                    this.infoMsg = result?.messageString
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    redirectTo(type, item) {
        let url = ''
        switch (type) {
            case 'lead':
                url = 'lead-detail/' + item?.LeadID
                window.open(url, '_blank')
                break;
            case 'contact':
                url = 'contact/' + this.user?.cLPUserID + '/' + item?.ContactID
                window.open(url, '_blank')
                break;
        }
    }
}
