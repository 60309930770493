<div class="">
  <div class="wraper-main-section mb-3">
    <div class="global-card-section_1">
      <form [formGroup]="liveSettingForm">
        <div class="">
          <div class="">
            <div class="global-body-section">
              <div class="flex-row-inner">
                <div class="wraper-body-left">
                  <div class="custom-action-title">
                    <span>LiveConnect Dashboard</span>
                    <div class="button-wrapper flex-row-inner" *ngIf="isSlurrpyForLiveConnect">
                      <button type="button" [hidden]="isShowViewMode" matTooltip="Save" class="icon-btn" (click)="liveConnectSave()">
                        <i class="fa fa-save" aria-hidden="true"></i>
                        <span class="btn-text">Save</span>
                      </button>
                      <button type="button" [hidden]="isShowViewMode" (click)="isShowViewMode = !isShowViewMode" matTooltip="Cancel" class="icon-btn">
                        <i class="fa fa-angle-double-left" aria-hidden="true"></i>
                        <span class="btn-text">Cancel</span>
                      </button>

                      <!--&& (isFromLiveConnect ? (isSlurrpyForLiveConnect && isSRS) : true)-->
                      <!--{{isShowViewMode}} - {{user?.cLPCompanyID}} - {{isFromLiveConnect}}-->

                      <!--{{isShowViewMode && user?.cLPCompanyID == 0 && (isFromLiveConnect ? (isSlurrpyForLiveConnect && isSRS) : true)}}-->
                      <button type="button" *ngIf="isShowViewMode && isShowButtons" matTooltip="Edit" class="icon-btn" (click)="isShowViewMode = !isShowViewMode">
                        <i class="fa fa-pencil-alt" aria-hidden="true"></i>
                        <span class="btn-text">Edit</span>
                      </button>
                    </div>
                  </div>
                  <div class="flex-width-panel">
                    <div class="cards-colunm-left">
                      <div class="align-center">
                        <span class="control-label spantext">Status</span>
                      </div>
                    </div>
                    <div class="cards-colunm-right">
                        <!--<span [hidden]="!isShowViewMode">{{liveConnectt?.isActive ? 'Activated for Long Code: ' + user?.txtMsgLongCode  | phoneFormat + user?.TxtMsgTollFree | phoneFormat : 'Not-Activated'}}</span>-->
                        <span [hidden]="!isShowViewMode">{{checkActiveStatus(liveConnectt?.isActive)}}</span>
                        <input [hidden]="isShowViewMode" type="checkbox" class="checkbox" formControlName="isActive" (change)="liveConnectSettingcbStatus()" />
                    </div>
                  </div>
                  <div *ngIf="isShowSettings">
                    <div class="flex-width-panel">
                      <div class="cards-colunm-left">
                        <div class="align-center">
                          <span class="control-label spantext">Display Name</span>
                        </div>
                      </div>
                      <div class="cards-colunm-right">
                        <span [hidden]="!isShowViewMode">{{liveConnectt?.liveConnectName}}</span>
                        <input [hidden]="isShowViewMode" type="text" class="txtStandard" formControlName="liveConnectName" />
                      </div>
                    </div>
                    <div class="flex-width-panel">
                      <div class="cards-colunm-left">
                        <div class="align-center">
                          <span class="control-label spantext">Greeting Name</span>
                        </div>
                      </div>
                      <div class="cards-colunm-right">
                        <span [hidden]="!isShowViewMode">{{liveConnectt?.speakName}}</span>
                        <input [hidden]="isShowViewMode" type="text" class="txtStandard" formControlName="speakName" />
                      </div>
                    </div>
                    <div class="flex-width-panel">
                      <div class="cards-colunm-left">
                        <div class="align-center">
                          <div class="text-right">
                            <span class="control-label spantext">Greeting Script</span>
                          </div>
                        </div>
                      </div>
                      <div class="cards-colunm-right">
                        <span [hidden]="!isShowViewMode">{{liveConnectt?.announceScript}}</span>
                        <textarea [hidden]="isShowViewMode" class="form-control" formControlName="announceScript"></textarea>
                      </div>
                    </div>
                    <div class="flex-width-panel">
                      <div class="cards-colunm-left">
                        <div class="align-center">
                          <div class="text-right">
                            <span class="control-label spantext">Greeting Recording</span>
                          </div>
                        </div>
                      </div>
                      <div class="cards-colunm-right">
                        <span [hidden]="!isShowViewMode">{{getGreetingRecordingName(liveConnectt?.announceVRID)}}</span>
                        <select [hidden]="isShowViewMode" class="form-control" formControlName="announceVRID">
                          <option value="">-None Selected-</option>
                          <option *ngFor="let item of voiceRecordDD" [value]="item?.voiceRecordingID">{{item?.shortDesc}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="flex-width-panel">
                      <div class="cards-colunm-left">
                        <div class="align-center">
                          <div class="text-right">
                            <span class="control-label spantext">Dashboard Notes</span>
                          </div>
                        </div>
                      </div>
                      <div class="cards-colunm-right">
                        <span [hidden]="!isShowViewMode" [innerHTML]="liveConnectt?.topNotes"></span>
                        <kendo-editor [hidden]="isShowViewMode" formControlName="topNotes"></kendo-editor>
                      </div>
                    </div>
                    <div class="flex-width-panel">
                      <div class="cards-colunm-left">
                        <div class="align-center">
                          <div class="text-right">
                            <span class="control-label spantext">Alerts Board</span>
                          </div>
                        </div>
                      </div>
                      <div class="cards-colunm-right">
                        <span [hidden]="!isShowViewMode">{{liveConnectt?.isAlertsTab ? 'Activated' : 'Not Activated'}}</span>
                        <input [hidden]="isShowViewMode" type="checkbox" class="checkbox" formControlName="isAlertsTab" />
                      </div>
                    </div>
                    <div class="flex-width-panel">
                      <div class="cards-colunm-left">
                        <div class="align-center">
                          <div class="text-right">
                            <span class="control-label spantext">Alerts Board Card Types</span>
                          </div>
                        </div>
                      </div>
                      <div class="cards-colunm-right" [hidden]="!isShowViewMode">
                        <span *ngIf="liveSettingForm.controls.isVirtualVoicemail.value">Virtual Voicemail,</span>
                        <span *ngIf="liveSettingForm.controls.isSlidecastAlert.value">&nbsp;Slidecast Alert,</span>
                        <span *ngIf="liveSettingForm.controls.isAutomationAlert.value">&nbsp;Automation Alert,</span>
                        <span *ngIf="liveSettingForm.controls.isAppointment.value">&nbsp;Appointment,</span>
                      </div>
                      <div class="cards-colunm-right" [hidden]="isShowViewMode">
                        <input type="checkbox" class="checkbox" formControlName="isVirtualVoicemail" /> Virtual Voicemail &nbsp;
                        <input type="checkbox" class="checkbox" formControlName="isSlidecastAlert" /> Slidecast Alert &nbsp;
                        <input type="checkbox" class="checkbox" formControlName="isAutomationAlert" />  Automation Alert &nbsp;
                        <input type="checkbox" class="checkbox" formControlName="isAppointment" /> Appointment
                      </div>
                    </div>
                    <div class="flex-width-panel">
                      <div class="cards-colunm-left">
                        <div class="align-center">
                          <div class="text-right">
                            <span class="control-label spantext">Inbound SMS</span>
                            <span class="text-success">Text User Alert</span>
                          </div>
                        </div>
                      </div>
                      <div class="cards-colunm-right">
                        <span [hidden]="!isShowViewMode">{{liveConnectt?.isIBSMSSendUserAlertText ? 'Activated' : 'Not Activated'}}</span>
                        <input [hidden]="isShowViewMode" type="checkbox" class="checkbox" formControlName="isIBSMSSendUserAlertText" />
                      </div>
                    </div>
                    <live-connect-user-assignment *ngIf="user && isLoadWithoutSRS" [user]="user" [isUserPref]="true" [isShowButtons]="isShowButtons" [isParentViewMode]="isShowViewMode" [liveConnectId]="liveConnectId" [clpCompanyId]="user?.cLPCompanyID" [isSRS]="false"></live-connect-user-assignment>
                    <div class="custom-action-title">
                      <span>SalesOptima Remote Services</span>
                    </div>
                    <div class="flex-width-panel">
                      <div class="cards-colunm-left">
                        <div class="align-center">
                          <div class="text-right">
                            <span class="control-label spantext">Status</span>
                          </div>
                        </div>
                      </div>
                      <div class="cards-colunm-right">
                        <span [hidden]="!isShowViewMode">{{liveConnectt?.isSRSActive ? 'Activated' : 'Not-Activated'}}</span>
                        <input [hidden]="isShowViewMode" type="checkbox" class="checkbox" formControlName="isSRSActive" (change)="liveConnectSettingisSRSActive()" />
                      </div>
                    </div>
                    <div class="flex-width-panel" *ngIf="liveConnectt?.isSRSActive">
                      <div class="cards-colunm-left">
                        <div class="align-center">
                          <div class="text-right">
                            <span class="control-label spantext">Account</span>
                          </div>
                        </div>
                      </div>
                      <div class="cards-colunm-right">
                        <div class="flex-align-panel">
                          <div class="" [hidden]="isShowViewMode">
                            <input type="text" class="form-control" formControlName="srsCLPCompanyID" />
                          </div>
                          <div class="margin-left10">
                            <span class="" [hidden]="isShowViewMode">
                              <button class="btn btn-outline-secondary border-start-0 border" (click)="onLoadSrsCompanyId()" type="button">
                                <i class="fa fa-search"></i>
                              </button>
                            </span>
                            <span class="">
                              <span>{{this.liveSettingForm.controls.searchedCompanyName.value}}</span>&nbsp;
                            </span>
                            <span class="" *ngIf="isShowSetupAccoutBtn && !isShowViewMode">
                              <button type="button" class="btn btn-primary" (click)="liveConnectSettingSRSSave()">Setup Account</button>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <live-connect-user-assignment *ngIf="user && isLoadWithSRS && liveConnectt?.isSRSActive" [isFromSales]="liveConnectt?.isSRSActive" [isShowButtons]="isShowButtons" [user]="user" [isUserPref]="false" [isParentViewMode]="isShowViewMode" [liveConnectId]="liveConnectId" [clpCompanyId]="liveConnectt?.srsCLPCompanyID" [isSRS]="true"></live-connect-user-assignment>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

