<div class="custom-security-link">
    <a [routerLink]="['/email-blast']">Create New Email Blast</a>
    <a [routerLink]="['/direct-mail']">Create New Direct Mail Mailing</a>
</div>
<div class="margin-all-area">
    <div class="home-dashboard-section">
        <div class="row-panel">
            <div class="col-left-panel">
                <div class="wraper-main-section">
                    <div class="global-card-section">
                        <div class="global-header-section">
                            <div class="svg-icon-panel">
                                <div *ngIf="param == 2"><img src="../../../../assets/emailtitle.svg" class="mr-1" />Email Blasts</div>
                                <div *ngIf="param == 1"><img src="../../../../assets/mailingtitle.svg" class="mr-1" />Marketing Mailing List</div>
                                <div *ngIf="param == 6"><img src="../../../../assets/txtmsgstitle.svg" class="mr-1" />Text Messages</div>
                                <div *ngIf="param == 7"><img src="../../../../assets/activity/voice/voicerecordtitle.svg" class="mr-1" />Voice drop</div>
                            </div>
                            <div class="header-button-panel">
                                <div class="button-wrapper">
                                    <button class="btn btn-primary" type="button" (click)="resetGridSetting();">Reset Grid Setting</button>
                                    <button *ngIf="param == 2" type="button" matTooltip="New Email Blast" class="icon-btn" [routerLink]="['/email-blast']">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="btn-text">Add</span>
                                    </button>
                                    <button *ngIf="param == 1" type="button" matTooltip="New Mailing" class="icon-btn" [routerLink]="['/direct-mail']">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="btn-text">Add</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="global-body-section">
                            <div class="message-info">Total mailings found: {{mailingList?.length}}</div>
                            <kendo-grid #grid id="gridId" class="email-blast-grid" *ngIf="_gridCnfgService.reloadGridGeneric"
                                        [kendoGridBinding]="mailingList"
                                        [sortable]="{mode: 'multiple'}"
                                        [sort]="_gridCnfgService.sort"
                                        [pageSize]="_gridCnfgService.pageSize"
                                        [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                        [scrollable]="'false'"
                                        [reorderable]="true"
                                        [resizable]="true"
                                        [columnMenu]="{ filter: true }"
                                        (columnReorder)="_gridCnfgService.columnsOrderChanged('view_mailing_grid', $event)"
                                        (sortChange)="_gridCnfgService.sortChange('view_mailing_grid', $event)"
                                        (pageChange)="_gridCnfgService.pageChange('view_mailing_grid', $event)"
                                        (columnResize)="_gridCnfgService.columnResize(4,'view_mailing_grid', $event)"
                                        (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'view_mailing_grid',grid)"
                                        (edit)="editHandler($event)"
                                        (save)="saveHandler($event)">
                                <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                   [field]="column.field"
                                                   [title]="column.title"
                                                   [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                   [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                   [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                   [width]="column.width | stringToNumber"
                                                   [filterable]="true"
                                                   [includeInChooser]="column.field=='$' ? false : true">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="customer-name" *ngIf="column.field == '$' && column.title == ''">{{ rowIndex+1 }}</div>
                                        <div class="customer-name" *ngIf="column.field == 'category'">{{getMethod(dataItem[column.field])}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'subject'"><a [routerLink]="['/marketing/mailing']" [queryParams]="{mid: dataItem?.mailingID}">{{ dataItem[column.field]}}</a></div>
                                        <div *ngIf="column.field == 'mailingType'">{{ dataItem[column.field]}}</div>
                                        <div *ngIf="column.field == 'campaign' && dataItem.category == 6 && user?.cLPCompanyID == 0">
                                            {{dataItem?.bWCampaign}} {{dataItem?.bWBrand == "Campaign not set" ? "" : dataItem?.bWBrand}}
                                        </div>
                                        <div *ngIf="column.field == 'mailingStartTime'" [ngClass]="checkDate(dataItem[column.field],dataItem?.status)? 'customer-name' : 'text-danger'">{{ dataItem[column.field] | date:dateFormat}}&nbsp;{{ dataItem[column.field] | date:'shortTime'}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{ dataItem[column.field] | date: dateFormat}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'uFirstName'">
                                            <a class="webkit-any-link" [routerLink]="['/edit-profile',dataItem.clpUserID]" [style.color]="'#1c0dbf'">{{ dataItem.uFirstName}} {{dataItem.uLastName}}</a>
                                        </div>
                                        <div class="customer-name" *ngIf="column.field == 'numContacts'">{{ dataItem[column.field]}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'numOpened'">{{getOpenRate(dataItem)}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'status'" [innerHTML]="getStatus(dataItem.status) | safeHtml"></div>
                                    </ng-template>
                                    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-rowIndex="rowIndex" let-column="column">
                                        <div class="customer-name" *ngIf="column.field == '$' && column.title == ''">{{ rowIndex+1 }}</div>
                                        <div class="customer-name" *ngIf="column.field == 'category'">{{getMethod(dataItem[column.field])}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'subject'"><a [routerLink]="['/marketing/mailing']" [queryParams]="{mid: dataItem?.mailingID}">{{ dataItem[column.field]}}</a></div>
                                        <div *ngIf="column.field == 'mailingType'">{{ dataItem[column.field]}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'campaign' && dataItem.category == 6">
                                            <kendo-multicolumncombobox [data]="campaignListDD"
                                                                       textField="brandId"
                                                                       valueField="campaignId"
                                                                       placeholder="Select a product..."
                                                                       [valuePrimitive]="true"
                                                                       [(value)]="campaignStr">
                                                <kendo-combobox-column field="campaignId" title="Campaign Code" [width]="200">
                                                </kendo-combobox-column>
                                                <kendo-combobox-column field="brandId" title="Brand Name" [width]="200">
                                                </kendo-combobox-column>
                                            </kendo-multicolumncombobox>

                                            <!--<select id="campaigndropdown" [(ngModel)]="campaignId">
                                                <option *ngFor="let campaign of campaignListDD" [value]="campaign?.campaignId">{{campaign?.campaignId}} {{campaign?.campaignId}}</option>
                                            </select>-->
                                        </div>
                                        <div *ngIf="column.field == 'mailingStartTime'" [ngClass]="checkDate(dataItem[column.field],dataItem?.status)? 'customer-name' : 'text-danger'">{{ dataItem[column.field] | date:dateFormat}}&nbsp;{{ dataItem[column.field] | date:'shortTime'}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{ dataItem[column.field] | date: dateFormat}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'uFirstName'">
                                            <a class="webkit-any-link" [routerLink]="['/edit-profile',dataItem.clpUserID]" [style.color]="'#1c0dbf'">{{ dataItem.uFirstName}} {{dataItem.uLastName}}</a>
                                        </div>
                                        <div class="customer-name" *ngIf="column.field == 'numContacts'">{{ dataItem[column.field]}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'numOpened'">{{getOpenRate(dataItem)}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'status'" [innerHTML]="getStatus(dataItem.status) | safeHtml"></div>
                                    </ng-template>

                                </kendo-grid-column>
                                <kendo-grid-command-column title="Action" [width]="110" [hidden]="!isShowCam">
                                    <ng-template kendoGridCellTemplate let-isNew="isNew">
                                        <button kendoGridEditCommand [primary]="true" title="Edit">
                                            <kendo-icon name="edit"></kendo-icon>
                                        </button>
                                        <button kendoGridSaveCommand [primary]="true" *ngIf="!isNew" title="Update">
                                            <kendo-icon name="check"></kendo-icon>
                                        </button>
                                    </ng-template>
                                </kendo-grid-command-column>
                            </kendo-grid>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-right-panel">
                <div class="wraper-main-section">
                    <div class="global-card-section">
                        <div class="global-header-section quick-filter-bg">
                            <div class="svg-icon-panel">Quick Filter</div>
                        </div>
                        <div class="global-body-section">
                            <form [formGroup]="mailingSearchForm" (ngSubmit)="getMailingList()">
                                <div class="wraper-body-panel">
                                    <div class="wraper-body-left">
                                        <div class="cards-body-section" *ngIf="userFilterResponse?.isMyTeam">
                                            <div class="cards-colunm-left flex-width3">
                                                <span>My Team</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <input type="checkbox" formControlName="cbMyTeam" class="checkbox" (change)="getUserList()" />
                                                <span *ngIf="userFilterResponse?.isTeamText">{{userFilterResponse?.teamText}}</span>
                                            </div>
                                        </div>
                                        <div class="cards-body-section" *ngIf="userFilterResponse?.isTeamDd">
                                            <div class="cards-colunm-left flex-width3">
                                                <span>Team</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <select class="form-control" formControlName="teamCode" (change)="getUserList()">
                                                    <option value="0">-All-</option>
                                                    <option *ngFor="let item of  userFilterResponse?.teamDd" [value]="item?.teamCode">{{item?.display}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="cards-body-section" *ngIf="userFilterResponse?.isMyOffice">
                                            <div class="cards-colunm-left flex-width3">
                                                <span>My Office</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <input type="checkbox" formControlName="cbMyOffice" class="checkbox" (change)="getUserList()" />
                                                <span *ngIf="userFilterResponse?.isOfficeText">{{userFilterResponse?.officeText}}</span>
                                            </div>
                                        </div>
                                        <div class="admin-option-section" *ngIf="userFilterResponse?.isOfficeDd">
                                            <div class="cards-body-section">
                                                <div class="cards-colunm-left flex-width3">
                                                    <span>Office</span>
                                                </div>
                                                <div class="cards-colunm-right">
                                                    <select class="form-control" formControlName="officeCode" (change)="getUserList()">
                                                        <option value="0">-All-</option>
                                                        <option *ngFor="let item of userFilterResponse?.officeDd" [value]="item?.officeCode">{{item?.display}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cards-body-section" *ngIf="userFilterResponse?.isUserDd">
                                            <div class="cards-colunm-left flex-width3">
                                                <span>User</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <select class="form-control" formControlName="ddUser" (change)="getUserList(true)">
                                                    <option value="0">-All-</option>
                                                    <option *ngFor="let item of userFilterResponse?.userDD" [value]="item?.id">{{item?.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left flex-width3">
                                                <span>Type</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <select class="form-control" formControlName="type">
                                                    <option [value]="2">Email Blast</option>
                                                    <option [value]="1">Direct Mail</option>
                                                    <option [value]="6">Text Campaign</option>
                                                    <option [value]="7">Voice Drop Campaign</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left flex-width3">
                                                <span>Filter By</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <select class="form-control" formControlName="filterBy">
                                                    <option value="MailingStartTime">Start Time</option>
                                                    <option value="dtCreated">Date Created</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left flex-width3">
                                                <span>From</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <kendo-datepicker formControlName="fromDt" placeholder=""></kendo-datepicker>
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left flex-width3">
                                                <span>To</span>
                                            </div>
                                            <div class="cards-colunm-right">
                                                <kendo-datepicker formControlName="toDt" placeholder=""></kendo-datepicker>
                                            </div>
                                        </div>
                                        <div class="cards-body-section">
                                            <div class="cards-colunm-left flex-width3"></div>
                                            <div class="cards-colunm-right">
                                                <div class="bottom-button-bar">
                                                    <button class="btn btn-primary" type="submit">Search</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
