<div *ngIf="isHomeSearch">
    <div class="custom-security-link">
        <a [routerLink]="['/lead']">My Lead</a>
        <a (click)="isShowSearch = !isShowSearch">{{isShowSearch ? 'Hide Quick Filter' : 'Show Quick Filter'}}</a>
    </div>
</div>
<div class="margin-all-area">
    <div class="tag-management-panel">
        <div class="home-dashboard-section">
            <div class="col-left-panel">
                <div class="wraper-main-section">
                    <div class="global-card-section">
                        <div class="global-header-section">
                            <div class="svg-icon-panel"><img src="../../../../assets/leadtitle.svg" class="mr-1" />&nbsp;Lead Search Results</div>
                            <div class="header-button-panel">
                                <div class="button-wrapper">
                                    <div class="custom-search">
                                        <div class="display-row">
                                            <contact-common-search *ngIf="isShowSearch" [execMapDuplicate]="false" [isLead]="true" (isRunSearch)="isRunSearch($event)"></contact-common-search>
                                            <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputTagSearch.value = ''">Reset Grid Setting</button>
                                            <input class="" placeholder="Search in all columns..." kendoTextBox (input)="onLeadSearchFilter($event.target.value)" #inputTagSearch />
                                            <button type="button" class="btn btn-primary min-width100 text-center" title="Clear Search" (click)="resetGridData(); inputTagSearch.value = ''">Clear Search</button>
                                            <button type="button" matTooltip="Download" class="icon-btn" (click)="createExportRequest();" *ngIf="isHomeSearch">
                                                <img src="../../../../assets/downbtn.svg" />
                                                <span class="btn-text">Download</span>
                                            </button>
                                            <button type="button" matTooltip="Add" class="icon-btn" [routerLink]="['/lead-create']">
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                                <span class="btn-text">Add</span>
                                            </button>
                                            <button type="button" class="icon-btn" data-toggle="collapse" data-target="#leadList" *ngIf="!isHomeSearch">
                                                <i class="fas fa-angle-down up-hide-icon" aria-hidden="true"></i>
                                                <i class="fas fa-angle-up icon-hide" aria-hidden="true"></i>
                                                <span class="btn-text">Expand</span>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="global-body-section" *ngIf="showSaveSearch">
                            <div class="margin-all-area display-row" style="justify-content:flex-end">
                                <div class="display-row" *ngIf="!showSaveSearchForm">
                                    <div>
                                        <h6>Do you wants to save the {{customSearch?.searchQueryList?.length == 1 ? 'below' : 'multiple'}} search?</h6>
                                        <div *ngIf="customSearch?.searchQueryList?.length == 1">
                                            <p class="mb-0" *ngFor="let item of customSearch?.searchQueryList">
                                                <span>{{item?.searchItem}}</span>&nbsp;
                                                <span>{{getOperatorName(item?.operator)}}</span>&nbsp;
                                                <span>{{getSearchItemName(item)}}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <button class="btn btn-primary" (click)="showSaveSearchForm = true;">Yes</button>
                                    <button class="btn btn-primary" (click)="showSaveSearch = false;showSaveSearchForm = false;">No</button>
                                </div>
                                <div *ngIf="showSaveSearchForm">
                                    <form class="form-horizontal required-section" [formGroup]="saveSearchForm">
                                        <div class="search-query-panel" style="width:auto; margin:0px;">
                                            <div class="" [ngClass]="{'has-error': saveSearchFrm.SearchQueryName.errors && (saveSearchFrm.SearchQueryName.touched ||  saveSearchFrm.SearchQueryName.dirty)}">
                                                <div class="search-query-alignment">
                                                    <div>
                                                        <label class="control-label" for="SearchQueryName" style="color:black;">Search Name <span class="red-contact-span">*</span></label>
                                                    </div>
                                                    <div>
                                                        <input type="text" id="SearchQueryName" class="form-control" style="width:100%" formControlName="SearchQueryName" />
                                                        <div class="search-query-error" *ngIf="saveSearchFrm.SearchQueryName.errors && ( saveSearchFrm.SearchQueryName.touched ||  saveSearchFrm.SearchQueryName.dirty)">
                                                            <div class="text-left" *ngIf="saveSearchFrm.SearchQueryName.errors.required">Search Name is a required</div>
                                                        </div>
                                                        <!--<div class="search-query-error text-left" *ngIf="savedQuery_response && savedQuery_response.messageBool">
                                                {{ savedQuery_response.errorMsg }}
                                            </div>-->
                                                    </div>
                                                    <div>
                                                        <button class="btn btn-primary" (click)="SubmitSearch()" title="Save Search"> Save Search</button>
                                                        <button class="btn btn-primary" (click)="showSaveSearch = false;showSaveSearchForm = false;" title="Cancel Search"> Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div class="global-body-section" *ngIf="isContactShow">
                            <contact-exports [user]="user" [selectedUserId]="user?.cLPUserID" [isContactReport]="true" [eStat]="eStat" [eType]="eType"></contact-exports>
                        </div>
                        <div class="global-body-section show" id="leadList">
                            <div class="message-info">
                                <span>Total records found: {{leadList?.length}}</span>
                            </div>
                            <kendo-grid class="lead-search-grid" #grid id="gridId" *ngIf="_gridCnfgService.reloadGridGeneric"
                                        [kendoGridBinding]="leadList"
                                        [sortable]="{mode: 'multiple'}"
                                        [scrollable]="'scrollable'"
                                        [sort]="_gridCnfgService.sort"
                                        [columnMenu]="{ filter: true }"
                                        [resizable]="true"
                                        [pageSize]="_gridCnfgService.pageSize"
                                        [reorderable]="true"
                                        [ngStyle]="gridHeight"
                                        [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                                        (columnReorder)="_gridCnfgService.columnsOrderChanged((currentUrl == '/active-leads') ? 'active_lead_grid' : 'lead_grid', $event)"
                                        (sortChange)="_gridCnfgService.sortChange((currentUrl == '/active-leads') ? 'active_lead_grid' : 'lead_grid', $event)"
                                        (pageChange)="_gridCnfgService.pageChange((currentUrl == '/active-leads') ? 'active_lead_grid' : 'lead_grid', $event)"
                                        (columnResize)="_gridCnfgService.columnResize(12,(currentUrl == '/active-leads') ? 'active_lead_grid' : 'lead_grid', $event)"
                                        (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,(currentUrl == '/active-leads') ? 'active_lead_grid' : 'lead_grid',grid)">

                                <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                                   [field]="column.field"
                                                   [title]="column.title | titlecase"
                                                   [width]="column.width | stringToNumber"
                                                   [filterable]="true"
                                                   [ngStyle]="gridHeight"
                                                   [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                                   [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                                   [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                                   [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                                   [includeInChooser]="column.field=='$' ? false : true"
                                                   [editable]="column.field == '$'?false: true">
                                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                        <div class="customer-name" *ngIf="column.field == '$' && column.title == '' ">{{ rowIndex+1 }}</div>
                                        <div class="customer-name" *ngIf="column.field == '$$'">  <a class="contact-cursor-pointer" title="View Lead" (click)="gotoLink('lead',dataItem);"><img src="../../../../assets/leadstitle.svg" class="mr-1" /> </a></div>
                                        <div class="customer-name" *ngIf="column.field == 'leadDesc'">  <a class="webkit-any-link" (click)="gotoLink('lead',dataItem);"> {{ dataItem[column.field] ? dataItem[column.field] : '--' }}</a></div>
                                        <div class="customer-name" *ngIf="column.field == 'lastFirst'"><a class="webkit-any-link" (click)="gotoLink('address-card', dataItem);">{{ dataItem[column.field] ? dataItem[column.field] : '--' }}</a></div>
                                        <div class="customer-name" *ngIf="column.field == 'companyName'"> <a class="contact-cursor-pointer" title="View Company" (click)="gotoLink('company',dataItem);">{{dataItem[column.field]}}</a></div>
                                        <div class="customer-name" *ngIf="column.field == 'ufirstName'"><a class="webkit-any-link" (click)="gotoLink('userName', dataItem);"> {{dataItem?.ufirstName + ' ' + dataItem?.ulastName}}</a></div>
                                        <div class="customer-name" *ngIf="column.field == 'dtStart'" [ngClass]="dataItem[column.field]? '' : 'text-success'">{{dataItem[column.field]  ? (dataItem[column.field] | date: dateFormat) : 'NA'}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'dtEnd'" [ngClass]="dataItem[column.field]? '' : 'text-success'">{{dataItem[column.field] ? (dataItem[column.field] | date: dateFormat) : 'NA'}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'revenue'">{{dataItem[column.field] ? '$'+dataItem[column.field] : '$0'}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'winProbability'">{{dataItem[column.field]}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'leadStatusCode'">{{dataItem[column.field] != 0 ? dataItem.leadStatus : 'Not Set'}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'dtModified'">{{dataItem[column.field] != '0001-01-01T00:00:00' ? (dataItem[column.field] | date: dateFormat) : '--'}}</div>
                                        <div class="customer-name" *ngIf="column.field == 'dtCreated'">{{dataItem[column.field] != '0001-01-01T00:00:00' ? (dataItem[column.field] | date: dateFormat) : '--'}}</div>
                                    </ng-template>

                                </kendo-grid-column>
                            </kendo-grid>
                        </div>
                    </div>
                </div>
                <div *ngIf="isHomeSearch" class="mt-3">
                    <lead-lead-status-list></lead-lead-status-list>
                </div>
            </div>
            <div class="col-right-panel">
                <div>
                    <app-lead-new-search *ngIf="user && isShowNewLeadSearch && isShowSearch" (leadListChanged)="setLeadList($event)"></app-lead-new-search>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner ">
    <div class="lds-ripple"><div></div><div></div></div>
</div>

