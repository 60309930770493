import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { ConfigDetails } from '../../../models/appConfig.model';
import { ActionType } from '../../../models/auditlog.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { CLPUserPref, CLPUserPrefDDsList } from '../../../models/clpUserPref.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { eButtonActions, eTheme, eUserRole } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { TxtMsgTemplate, TxtMsgTemplateListResponse } from '../../../models/textMsgTemplate.model';
import { TxtMsgSettingsResponse } from '../../../models/txtMsg.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { GlobalService } from '../../../services/global.service';
import { NotificationService } from '../../../services/notification.service';
import { AppconfigService } from '../../../services/shared/appconfig.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { TextMsgTemplateService } from '../../../services/text-msg-template.service';
import { TxtMsgService } from '../../../services/textmsg.service';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-user-prefs',
    templateUrl: './user-prefs.component.html',
    styleUrls: ['./user-prefs.component.css']
})
export class UserPrefsComponent {
    private encryptedUser: string = '';
    userResponse: UserResponse;
    user: CLPUser;
    roleFeaturePermissions: RoleFeaturePermissions;
    showSpinner: boolean = false;

    userPreferenceForm: FormGroup;
    userPrefData: CLPUserPref;
    companyData: ClpCompany;
    textMsgTemplateList: TxtMsgTemplate[];
    userPrefDD: CLPUserPrefDDsList;
    slidecastUrl: string = '';
    ddTheme = [];
    eTheme = eTheme;
    buttonTypeOperation: eButtonActions = eButtonActions.None;
    textMsgSettingData: TxtMsgSettingsResponse;
    constructor(
        private _notifyService: NotificationService,
        private fb: FormBuilder,
        private _accountSetupService: AccountSetupService,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _appConfigService: AppconfigService,
        private _userService: UserService,
        private _textMsgTemplateService: TextMsgTemplateService,
        private _txtMsgSettingSrvc: TxtMsgService,
        private _globalService: GlobalService,
        private _router: Router) {
        this._localService.isMenu = true;
        this.ddTheme = Object.keys(this.eTheme).filter(f => !isNaN(Number(f)));

    }

    ngOnInit(): void {
        this.userPreferenceForm = this.prepareUserPreferenceForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(async () => {
                    if (this.user) {
                        this.getSlidecastUrl();
                        this.getCompanyByCompanyId();
                        this.getUserPrefDDList();
                        this.getUserPrefLoad();
                        this.getTextMsgList();
                        this.getTxtMsgSettingFormData();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (!isNullOrUndefined(this.userResponse)) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse?.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("user-prefs.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getSlidecastUrl() {
        await this._appConfigService.getAppConfigValue(this.encryptedUser, "slidecastsite").
            then(async (result: ConfigDetails) => {
                if (!isNullOrUndefined(result) && !isNullOrUndefined(result.configValue))
                    this.slidecastUrl = result?.configValue;
                else
                    this.slidecastUrl = '';
            }).catch((err: HttpErrorResponse) => {
                this._userService.auditLog(ActionType.LoginError, isNullOrUndefined(this.user) ? 0 : this.user?.cLPUserID, "Error During Geting Broadcast URL - " + err.message, "", "")
                console.log(err);
                this.slidecastUrl = '';
            });
    }

    prepareUserPreferenceForm() {
        return this.fb.group({
            showGSCheckList: new FormControl(''),
            defaultTheme: new FormControl(''),
            defaultCASID: new FormControl(''),
            defaultContact: new FormControl(''),
            defaultPCalID: new FormControl(''),
            vIPQRCodeURL: new FormControl(''),
            defaultVIPID: new FormControl(''),
            defaultSGVIPID: new FormControl(''),
            alternateSGVIPID: new FormControl(''),
            defaultSGVIPSMS: new FormControl(''),
            sendSelfGuidedSlidecast: new FormControl(''),
            defaultSCEmailTemplateID: new FormControl(''),
            callForwardingPreferredLine: new FormControl(1),
            isVCRIn: new FormControl(''),
            isVirtualVM: new FormControl(''),
            virtualVMVRID: new FormControl(''),
            callForwardAPID: new FormControl(''),
            txtMsgIBAPID: new FormControl(''),
            isTxtMsgIBSendEmailAlert: new FormControl(''),
            isIBCallSendUserAlertText: new FormControl(''),
            isIBCallConnectedSendUserAlertText: new FormControl(''),
            clickToCallPreferredLine: new FormControl(1),
            isClickToCallEnablePreCallText: new FormControl(''),
            isClickToCallEnableInCallText: new FormControl(''),
            isVCROut: new FormControl(false),
            isTranscribeVCR: new FormControl(''),
            sAMPin: new FormControl('', [Validators.required, Validators.min(1000)]),
            isImportantDateSendEmail: new FormControl(''),
            txtMsgQCDNA: new FormControl(''),
            txtMsgQCVM: new FormControl(''),
            customPH1: new FormControl(''),
            customPH2: new FormControl(''),
            customPH3: new FormControl(''),
            customPH4: new FormControl(''),
            customPH5: new FormControl('')
        });
    }

    async getUserPrefLoad() {
        this.showSpinner = true;
        await this._userService.CLPUserPref_Load(this.encryptedUser, this.user.cLPUserID)
            .then(async (result: CLPUserPref) => {
                if (!isNullOrUndefined(result)) {
                    this.userPrefData = UtilityService.clone(result);
                    this.patchFormControlValue();
                    this.userPreferenceForm.controls.defaultSCEmailTemplateID.patchValue(this.userPrefData.defaultSCEmailTemplateID);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async getCompanyByCompanyId() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (!isNullOrUndefined(result))
                    this.companyData = UtilityService.clone(result?.company);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getTextMsgList() {
        await this._textMsgTemplateService.getTextMsgTemplateList(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID)
            .then(async (result: TxtMsgTemplateListResponse) => {
                if (!isNullOrUndefined(result))
                    this.textMsgTemplateList = UtilityService.clone(result?.txtMsgTemplateList);
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getTxtMsgSettingFormData() {
        return await this._txtMsgSettingSrvc.txtMsgSettings_Load(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID, this.user?.slurpyUserId)
            .then(async (result: TxtMsgSettingsResponse) => {
                var response = UtilityService.clone(result);
                if (!isNullOrUndefined(response)) {
                    this.textMsgSettingData = response
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getUserPrefDDList() {
        this.showSpinner = true;
        await this._userService.getCLPUserPrefDD(this.encryptedUser, this.user.cLPCompanyID, this.user.cLPUserID)
            .then(async (result: CLPUserPrefDDsList) => {
                if (!isNullOrUndefined(result)) {
                    this.userPrefDD = UtilityService.clone(result);
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    async saveUserPref() {
        this.showSpinner = true;
        this.buttonTypeOperation = eButtonActions.Save;
        this.copyUSerPrefsFormValueToData();
        await this._userService.saveCLPUserPref(this.encryptedUser, this.userPrefData)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response: SimpleResponse = UtilityService.clone(result);
                    this._notifyService.showSuccess(response?.messageString ? response?.messageString : "User Preferrence Saved Successfully.", "", 3000);
                    this.getUserPrefLoad();
                }
                this.buttonTypeOperation = eButtonActions.None;
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.buttonTypeOperation = eButtonActions.None;
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    copyUSerPrefsFormValueToData() {
        this.userPrefData.cLPUserID = this.user.cLPUserID;
        this.userPrefData.showGSCheckList = this.userPreferenceForm.controls.showGSCheckList.value
        this.userPrefData.defaultCASID = this.userPreferenceForm.controls.defaultCASID.value;
        this.userPrefData.defaultTheme = this.userPreferenceForm.controls.defaultTheme.value;
        this.userPrefData.defaultContact = this.userPreferenceForm.controls.defaultContact.value;
        this.userPrefData.defaultVIPID = this.userPreferenceForm.controls.defaultVIPID.value;
        this.userPrefData.defaultSGVIPID = this.userPreferenceForm.controls.defaultSGVIPID.value;
        this.userPrefData.alternateSGVIPID = this.userPreferenceForm.controls.alternateSGVIPID.value;
        this.userPrefData.defaultSGVIPSMS = this.userPreferenceForm.controls.defaultSGVIPSMS.value;
        this.userPrefData.defaultSCEmailTemplateID = this.userPreferenceForm.controls.defaultSCEmailTemplateID.value;
        this.userPrefData.callForwardAPID = this.userPreferenceForm.controls.callForwardAPID.value;
        this.userPrefData.txtMsgIBAPID = this.userPreferenceForm.controls.txtMsgIBAPID.value;
        this.userPrefData.callForwardingPreferredLine = this.userPreferenceForm.controls.callForwardingPreferredLine.value;
        this.userPrefData.clickToCallPreferredLine = this.userPreferenceForm.controls.clickToCallPreferredLine.value;
        this.userPrefData.isClickToCallEnablePreCallText = this.userPreferenceForm.controls.isClickToCallEnablePreCallText.value;
        this.userPrefData.isClickToCallEnableInCallText = this.userPreferenceForm.controls.isClickToCallEnableInCallText.value;
        this.userPrefData.defaultPCalID = this.userPreferenceForm.controls.defaultPCalID.value;
        this.userPrefData.sAMPin = this.userPreferenceForm.controls.sAMPin.value;
        this.userPrefData.isVCRIn = this.userPreferenceForm.controls.isVCRIn.value;
        this.userPrefData.isTranscribeVCR = this.userPreferenceForm.controls.isTranscribeVCR.value;
        this.userPrefData.isVirtualVM = this.userPreferenceForm.controls.isVirtualVM.value
        if (!this.userPrefData.isVirtualVM) {
            this.userPrefData.isDirectToVirtualVM = false
        }
        this.userPrefData.isVCROut = this.userPreferenceForm.controls.isVCROut.value;
        this.userPrefData.virtualVMVRID = this.userPreferenceForm.controls.virtualVMVRID.value;
        this.userPrefData.txtMsgQCDNA = this.userPreferenceForm.controls.txtMsgQCDNA.value;
        this.userPrefData.txtMsgQCVM = this.userPreferenceForm.controls.txtMsgQCVM.value;
        this.userPrefData.isTxtMsgIBSendEmailAlert = this.userPreferenceForm.controls.isTxtMsgIBSendEmailAlert.value;
        this.userPrefData.isIBCallSendUserAlertText = this.userPreferenceForm.controls.isIBCallSendUserAlertText.value;
        this.userPrefData.isIBCallConnectedSendUserAlertText = this.userPreferenceForm.controls.isIBCallConnectedSendUserAlertText.value;
        this.userPrefData.customPH1 = this.userPreferenceForm.controls.customPH1.value;
        this.userPrefData.customPH2 = this.userPreferenceForm.controls.customPH2.value;
        this.userPrefData.customPH3 = this.userPreferenceForm.controls.customPH3.value;
        this.userPrefData.customPH4 = this.userPreferenceForm.controls.customPH4.value;
        this.userPrefData.customPH5 = this.userPreferenceForm.controls.customPH5.value;
        this.userPrefData.isImportantDateSendEmail = this.userPreferenceForm.controls.isImportantDateSendEmail.value;
        this.userPrefData.vIPQRCodeURL = this.userPreferenceForm.controls.vIPQRCodeURL.value;
    }

    patchFormControlValue() {
        const userPrefData: CLPUserPref = this.userPrefData;
        for (let key in userPrefData) {
            let value = userPrefData[key];
            if (this.userPreferenceForm.get(key))
                this.userPreferenceForm.get(key).setValue(value);
        }

        this.userPreferenceForm.get('defaultPCalID')?.setValue(this.userPrefData?.defaultPCalID);
        if (this.userPrefData?.defaultSGVIPSMS == "" || this.userPrefData?.defaultSGVIPSMS == null) {
            let companyName = this.textMsgSettingData?.txtMsgSettings?.orgName
            let defaultSGVIPSMSValue = `PH_FirstName, view my${companyName == null ? ' ' : companyName == '' ? ' ' : companyName + ' '}presentation here: PH_DefaultSGSlidecastURL`
            this.userPreferenceForm.controls.defaultSGVIPSMS.patchValue(defaultSGVIPSMSValue)
        }
    }

    getMessageText(type, val) {
        const template: TxtMsgTemplate = this.textMsgTemplateList.filter(item => item.txtMsgTemplateID == val)[0];
        if (type == 'TxtMsgQCVM')
            this.userPreferenceForm.controls.txtMsgQCVM.setValue(template ? template?.messageText : '');
        else
            this.userPreferenceForm.controls.txtMsgQCDNA.setValue(template ? template?.messageText : '');
    }
}
