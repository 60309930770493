import { KeyValue } from '@angular/common';
import { EventEmitter, OnInit, Output, SecurityContext, SimpleChanges } from '@angular/core';
import { OnChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PageChangeEvent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { isNullOrUndefined } from 'util';
import { ShareableApptUI } from '../../../models/appt.model';
import { CLPUser, UserResponse } from '../../../models/clpuser.model';
import { Contact } from '../../../models/contact.model';
import { eApptCategory, eMailingCategory, eMailingContactResult, eTxtMsgStatus, eVoiceDropStatus } from '../../../models/enum.model';
import { ContactInfo } from '../../../models/report.model';
import { keyValue } from '../../../models/search.model';
import { NotificationService } from '../../../services/notification.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { LocalService } from '../../../services/shared/local.service';

declare var $: any

@Component({
    selector: 'app-grid-table',
    templateUrl: './grid-table.component.html',
    styleUrls: ['./grid-table.component.css'],
    providers: [GridConfigurationService]
})

export class GridTableComponent implements OnInit, OnChanges {
    @Input() user: CLPUser;
    @Input() columns;
    @Input() reorderColumnName;
    @Input() columnWidth;
    @Input() arrColumnWidth;
    @Input() mobileColumnNames;
    @Input() tableDataList = [];
    @Input() mailingCategory: eMailingCategory;
    @Input() gridName: string;
    @Input() isCbSelecteable: boolean = false;
    gridCbSelection: any[] = [];
    isCheckAllGrid: boolean;
    public selectAllOverDueApptState: SelectAllCheckboxState;
    dateFormat: string = "MM/dd/yyyy";
    gridHeight;
    @Input() showSpinner: boolean = false;
    shareableAppt: ShareableApptUI;
    isShowApptModal: boolean = false;
    @Input() skypeSettingCallList: boolean = false;
    @Output() sendEmailParent = new EventEmitter<string>();
    @Output() openVoiceRecord = new EventEmitter<number>();
    @Output() openApptById = new EventEmitter<number>();
    @Output() currentPage = new EventEmitter<number>();
    @Output() someEvent = new EventEmitter<any[]>();
    textCallInfo: ContactInfo = { contactId: 0, isShow: false, contact: null };
    selectedContactData: any;
    selectedCallType: string;
    isShowNewCallModal: boolean;
    sendMailInfo: any = { isShow: false, contactId: 0 };

    constructor(
        public _gridCnfgService: GridConfigurationService,
        public _localService: LocalService,
        private _router: Router,
        private _sanitizer: DomSanitizer,
        private _notifyService: NotificationService) {
        this.gridHeight = this._localService.getGridHeight('493px');
        this.isCheckAllGrid = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
    }


    ngOnInit(): void {
        if (!isNullOrUndefined(this.user)) {
            this._gridCnfgService.user = this.user;
            this.dateFormat = this.user.dateFormat;
            this.getGridConfiguration();
        }
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, this.gridName).subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration(this.gridName).subscribe((value) => {
            this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums(this.gridName);
            this._gridCnfgService.iterateConfigGrid(this.tableDataList, this.gridName);
        }
        ));
    }



    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, this.gridName).subscribe((value) => this.getGridConfiguration());
    }

    public selectAllCommonGridCheckbox(checkedState: SelectAllCheckboxState): void {
        if (checkedState === "checked") {
            this.gridCbSelection = this.tableDataList.map((item) => item.apptID);
            this.selectAllOverDueApptState = "checked";
        } else {
            this.gridCbSelection = [];
            this.selectAllOverDueApptState = "unchecked";
        }
    }

    toogleCheckAllGrid() {
        !this.isCheckAllGrid ? this.selectAllCommonGridCheckbox('checked') : this.selectAllCommonGridCheckbox('unchecked')
        this.isCheckAllGrid = !this.isCheckAllGrid;
    }

    sendEmailCallList(dataItem: any) {
        this.sendEmailParent.emit(dataItem);
    }

    completeSelectedApptsCall() {
        if (this.gridCbSelection?.length > 0)
            this.someEvent.emit(this.gridCbSelection);
        else
            this._notifyService.showError('Select atleast one call', 'No Call selected', 3000);
    }

    diffMinutes(dataItem) {
        if (!isNullOrUndefined(dataItem)) {
            var retText = "";
            var currDt: any = new Date();
            var eventDt: any = new Date(dataItem.apptStartTime);
            var diffMs = (eventDt - currDt);
            var diffMinsEvnt = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
            if (diffMinsEvnt > 0 && diffMinsEvnt <= 30)
                retText = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style='color:red; font-weight:bold;'>COMING UP</span>";
            else if (diffMinsEvnt < 0)
                retText = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style='color:red; font-weight:bold;'>PAST DUE</span>";
            return this._sanitizer.sanitize(SecurityContext.HTML, retText);
        }
        else
            return "";
    }

    gotoLink(columnName, dataItem) {
        if (columnName) {
            switch (columnName) {
                case "address-card":
                case "name": {
                    if (this.user.timeZoneWinId != 0)
                        this._router.navigate(['/contact', dataItem.cLPUserID, dataItem.contactID]);
                    else {
                        if (confirm("First , Please select your timezone!!!"))
                            this._router.navigate(['/edit-profile', dataItem.cLPUserID]);
                        else
                            return;
                    }
                    break;
                }
                default:
                    break;
            }
        }
    }


    saveExcel(component): void {
        let indexs: number[] = [];
        const options = component.workbookOptions();
        options.sheets[0].name = `Contact List`;
        let rows = options.sheets[0].rows;
        this._gridCnfgService.hiddenColumns.forEach(item => { indexs.push(rows[0].cells.findIndex(i => i.value == item)) });
        rows.forEach((row) => {
            if (row) {
                row.cells.forEach((cell, index) => {
                    indexs.forEach(i => {
                        if (i == index)
                            row.cells.splice(index, 1);
                    })
                    if (cell && cell.value && cell.value.toString().includes("<br>")) {
                        cell.value = cell.value.replace(/<br\s*\/?>/gi, ' ');
                    }
                });
            }
        });
        Array.prototype.unshift.apply(rows);
        component.save(options);
    }

    getResultForVoiceDrop(data: Contact): string {
        switch (data.status) {
            case eVoiceDropStatus.Failed:
                return "<span style='color: red;'>" + eVoiceDropStatus[data.status].toString() + ":" + data.requestComment + "</span>";
            case eVoiceDropStatus.Pending: case eVoiceDropStatus.Paused:
                return "<span style='color: darkorange;'>" + eVoiceDropStatus[data.status].toString() + "</span>";
            case eVoiceDropStatus.Delivered:
                return "<span style='color: darkgrey;'>Delivered</span>";
            case eVoiceDropStatus.NotDelivered:
                return "<span style='color: black;'>NotDelivered</span>";
            case eVoiceDropStatus.InProgress:
                return "<span style='color: darkorange;'> In Progress </span>";
            default:
                return "<span style='color: black;'>" + eVoiceDropStatus[data.status].toString() + "</span>";
        }
    }

    getResultForTxtMsg(data: Contact): string {
        switch (data.status) {
            case eTxtMsgStatus.Failed:
                return "<span style='color: red;'>" + eTxtMsgStatus[data.status].toString() + ":" + data.requestComment + "</span>";
            case eTxtMsgStatus.Pending: case eTxtMsgStatus.Paused:
                return "<span style='color: darkorange;'>" + eTxtMsgStatus[data.status].toString() + "</span>";
            case eTxtMsgStatus.Completed:
                return "<span style='color: darkgrey;'>Sent</span>";
            case eTxtMsgStatus.Responded:
                return "<span style='color: darkgrey;'>Responded</span>";
            case eTxtMsgStatus.Sent:
                return "<span style='color: darkgrey;'>Sent</span>";
            case eTxtMsgStatus.CheckInProgress: case eTxtMsgStatus.InProgress:
                return "<span style='color: darkorange;'>In Progress</span>";
            default:
                return "<span style='color: black;'>" + eTxtMsgStatus[data.status].toString() + "</span>";
        }
    }

    getResultForMailing(data: Contact): string {
        switch (data?.result) {
            case eMailingContactResult.Failed: case eMailingContactResult.Bounced:
                return "<span style='color: red;'>" + eMailingContactResult[data.result].toString() + "</span>";
            case eMailingContactResult.Pending: case eMailingContactResult.Paused:
                return "<span style='color: darkorange;'>" + eMailingContactResult[data.result].toString() + "</span>";
            case eMailingContactResult.Success:
                if (this.mailingCategory != eMailingCategory.Mail)
                    return "<span style='color: darkgrey;'>Sent</span>";
                else
                    return "<span style='color: darkgrey;'>Success</span>";
            case eMailingContactResult.Opened:
                return "<span style='color: black;'>" + eMailingContactResult[data.result].toString() + "</span>";
            default:
                return "<span style='color: black;'>" + eMailingContactResult[data.result].toString() + "</span>";
        }
    }

    getResult(data: Contact) {
        var status: string = "";
        switch (this.mailingCategory) {
            case eMailingCategory.TxtMsg:
                status = this.getResultForTxtMsg(data);
                break;
            case eMailingCategory.VoiceDrop:
                status = this.getResultForVoiceDrop(data);
                break;
            default:
                status = this.getResultForMailing(data);
                break;
        }
        return this._sanitizer.bypassSecurityTrustHtml(status);
    }

    navToVoiceRecord(voiceRecordingID) {
        this.openVoiceRecord.emit(voiceRecordingID)
    }

    appointmentPopUp(apptID) {
        this.openApptById.emit(apptID)
    }

    openModalNewCall(contactData, callType: string) {
        this.selectedContactData = contactData;
        this.selectedCallType = callType;
        this.isShowNewCallModal = true;
    }

    pageChange(event: PageChangeEvent): void {
        let skip = event.skip;
        let currentPg = (skip / this._gridCnfgService.pageSize) + 1;
        this.currentPage.emit(currentPg)
    }

    openEmailModal(dataItem) {
        $('#sendEmail').modal('show');
        this.sendMailInfo.isShow = true;
        this.sendMailInfo.contactId = dataItem?.contactID;
    }


    hideSendMail() {
        $('#sendEmail').modal('hide');
        this.sendMailInfo.isShow = false;
        this.sendMailInfo.contactId = 0;
    }
}
