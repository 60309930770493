import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { CLPUser, ResetPassword, UserListResponse, UserResponse, UserSetupExcel, UserSetupResponse } from '../../../models/clpuser.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { NotificationService } from '../../../services/notification.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';
import { UserService } from '../../../services/user.service';
import { process } from "@progress/kendo-data-query";
import { DataBindingDirective } from "@progress/kendo-angular-grid";
import { eFeatures, eSectionLead, eUserPermissions, eUserRole } from '../../../models/enum.model';
import { RoleFeaturePermissions } from '../../../models/roleContainer.model';
import { SignupDuplicateCheck } from '../../../models/signupMsg.model';
import { SignupService } from '../../../services/signup.service';
import { GridConfigurationService } from '../../../services/shared/gridConfiguration.service';
import { isNullOrEmptyString } from '../../../../shared/utils.js';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { GlobalService } from '../../../services/global.service';
import { ActionType } from '../../../models/auditlog.model';
import { Title } from '@angular/platform-browser';
declare var $: any;
@Component({
    selector: 'user-setup',
    templateUrl: './user-setup.component.html',
    styleUrls: ['./user-setup.component.css'],
    providers: [GridConfigurationService]
})

export class UserSetupComponent implements OnInit {
    eUserPermissions = eUserPermissions;
    eUserRole = eUserRole;
    @Input() isNewUserList?: boolean = false;
    @Input() isFromAcSetting?: boolean = false;
    @Input() companyIdBilling: number;
    isUserSelected: boolean = false;
    selectedUserID: number = 0;
    billingClpCompany: ClpCompany;
    private encryptedUser: string = '';
    private encryptedUserOld: string = '';
    @Input() user: CLPUser;
    showSpinner: boolean = false;
    isTimeZoneTouch: boolean = false;
    public userData: CLPUser[] = [];
    public userDataOriginal: CLPUser[];
    public userDataExcel: UserSetupExcel[] = [];
    userObj: CLPUser;
    userResponse: UserResponse;
    downloadFileName: string = 'user-setup'
    createUser: boolean = false;
    @Input() roleFeaturePermissions: RoleFeaturePermissions;
    dataItem;
    oldUserName: string = "";
    public teamCodeFilterList = [];
    public officeCodeFilterList = [];
    userNameExist: boolean = false;
    @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
    timezoneFilterList: Array<any> = [];
    userTypeFilterList: Array<any> = [];
    userStatusFilterList: Array<any> = [
        { key: 0, value: 'LockedOut' },
        { key: 1, value: 'Active' }

    ];
    columns = [
        { field: '$', title: ' ', width: '40' },
        { field: '$$', title: ' ', width: '40' },
        { field: 'cLPUserID', title: 'User Code', width: '109' },
        { field: 'lastName', title: 'Last Name', width: '114' },
        { field: 'firstName', title: 'First Name', width: '114' },
        { field: 'officeCode', title: 'Office', width: '124' },
        { field: 'teamCode', title: 'Team', width: '124' },
        { field: 'userName', title: 'User Name', width: '184' },
        { field: 'password', title: 'Password', with: '104' },
        { field: 'timeZone', title: 'Time Zone', width: '124' },
        { field: 'email', title: 'Email', width: '124' },
        { field: 'mobile', title: 'Mobile', width: '124' },
        { field: 'userRole', title: 'Role', width: '104' },
        { field: 'permissions', title: 'Status', width: '104' },
        { field: 'changePW', title: 'Change PW ', width: '113' },
        { field: 'isShowCP', title: 'Show CP ', width: '101' },
        { field: 'isAllowDownload', title: 'Allow Download  ', width: '144' },
        { field: 'welcome', title: 'Welcome', width: '34' }
    ];

    reorderColumnName: string = 'cLPUserID,lastName,firstName,officeCode,teamCode,userName,password,timeZone,email,mobile,userRole,permissions,changePW,isShowCP,isAllowDownload,welcome';
    columnWidth: string = 'cLPUserID:109,lastName:114,firstName:114,officeCode:120,teamCode:120,userName:184,password:104,timeZone:124,email:124,mobile:124,userRole:104,permissions:104,changePW:113,isShowCP:101,isAllowDownload:144,welcome:34';
    arrColumnWidth: any = ['cLPUserID:109,lastName:114,firstName:114,officeCode:120,teamCode:120,userName:184,password:104,timeZone:124,email:124,mobile:124,userRole:104,permissions:104,changePW:113,isShowCP:101,isAllowDownload:144,welcome:34'];
    columnsExcel = [       
        { field: 'userCode', title: 'User Code', width: '109' },
        { field: 'lastName', title: 'Last Name', width: '114' },
        { field: 'firstName', title: 'First Name', width: '114' },
        { field: 'office', title: 'Office', width: '124' },
        { field: 'team', title: 'Team', width: '124' },
        { field: 'userName', title: 'User Name', width: '184' },       
        { field: 'timeZone', title: 'Time Zone', width: '124' },        
        { field: 'role', title: 'Role', width: '104' }        
    ];

    public formGroup: FormGroup;
    public newUserFormGroup: FormGroup;
    private editedRowIndex: number;
    private editedRowIndexNewUser: number;
    public isNew: boolean;
    public type = "input";
    public info = true;
    public pageSizes = true;
    public previousNext = true;
    currentUserToDelete;
    initUserData: CLPUser[] = [];
    public isMobileValidate;
    public defaultItem: { key: number; value: string } = { key: -1, value: "None Selected" };
    public defaultItemTeamCodeTop: number = -1;
    public defaultItemOfficeCodeTop: number = -1
    public defaultRole: number = -1
    public currentUserUserName: any;
    dateFormatList: any;
    isPasswordAdmin: boolean = false;
    //New User 
    userSetupListResponse: UserSetupResponse;
    newUsersList: CLPUser[] = [];
    newUserColumns = [
        { field: '$', title: ' ', width: '40' },
        { field: 'userIcon', title: '', width: '40' },
        { field: 'email', title: 'Email', width: '120' },
        { field: 'emailConfirm', title: 'Email Confirm', width: '120' },
        { field: 'userName', title: 'User Name', width: '120' },
        { field: 'firstName', title: 'First Name', width: '125' },
        { field: 'lastName', title: 'Last Name', width: '125' },
        { field: 'status', title: 'Status', width: '100' },
    ];
    gridHeight;
    mobileColumnNames: string[];
    @ViewChild('saveExcelUSBtn') saveExcelUSBtn: ElementRef;
    isChangedUserName: boolean = false;
    constructor(private _accountSetupService: AccountSetupService,
        public _gridCnfgService: GridConfigurationService,
        private ngZone: NgZone, private userSvc: UserService,
        public _localService: LocalService,
        private _utilityService: UtilityService,
        private _router: Router,
        private _notifyService: NotificationService,
        private titleService: Title,
        public _signupService: SignupService, private _globalService: GlobalService) {
        this.gridHeight = this._localService.getGridHeight('482px');
        this._localService.isMenu = true;
    }

    loadUserSetup() {

        this._globalService.getToken((token) => {
            if (token) {
                this.getToken()
                this.encryptedUser = token;
                this.authenticateR().then(() => {
                    if (!isNullOrUndefined(this.user)) {
                        if (this.user?.userRole <= eUserRole.Administrator) {
                            if (this.roleFeaturePermissions?.view == false)
                                this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            else
                                this.loadInit();
                        }
                        else
                            this.loadInit();
                    } else {
                        this.authenticateR().then(() => {
                            if (!isNullOrUndefined(this.user))
                                this.loadInit();
                            else
                                this._router.navigate(['/login']);
                        })
                    }
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    addNewUser() {
        $("#btnForNewUser").click();
    }

    loadInit() {
        this._gridCnfgService.user = this.user;
        this.companyIdBilling = (!isNullOrUndefined(this.companyIdBilling) && this.companyIdBilling > 0) ? this.companyIdBilling : this.user.cLPCompanyID;
        this.getNewUserList();
        this.getGridConfiguration();
        this.getUserSetupData();
        this.getBillingClpCompany();
    }

    getGridConfiguration() {
        this._gridCnfgService.columns = this.columns;
        this._gridCnfgService.reorderColumnName = this.reorderColumnName;
        this._gridCnfgService.columnWidth = this.columnWidth;
        this._gridCnfgService.arrColumnWidth = this.arrColumnWidth;
        this._gridCnfgService.getGridColumnsConfiguration(this.user.cLPUserID, 'user_setup_grid').subscribe((value) => this._gridCnfgService.createGetGridColumnsConfiguration('user_setup_grid').subscribe((value) => { }));
    }

    resetGridSetting() {
        this._gridCnfgService.deleteColumnsConfiguration(this.user.cLPUserID, 'user_setup_grid').subscribe((value) => this.getGridConfiguration());
    }

    async getBillingClpCompany() {
        await this._accountSetupService.getClpCompany(this.encryptedUser, this.companyIdBilling)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    var result = UtilityService.clone(result);
                    this.billingClpCompany = result.company;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("user-setup.getBillingClpCompany", err.message, null, 'companyIdBilling ' + this.companyIdBilling);
                this._utilityService.handleErrorResponse(err);
            });
    }

    public ngOnInit(): void {
        this.loadUserSetup();
    }

    getNewUserList() {
        this.showSpinner = true;
        this.userSvc.getNewUserList(this.encryptedUser, this.companyIdBilling)
            .then(async (result: UserListResponse) => {
                if (!isNullOrUndefined(result)) {
                    var result = UtilityService.clone(result);
                    this.newUsersList = result.clpUsers;
                    if (this.user.userRole >= eUserRole.Administrator && this.user.isPasswordAdmin) {
                        this.isPasswordAdmin = true;
                    }


                    if (this.newUsersList?.length > 0 && this.user?.userRole > eUserRole.Manager) {
                        this.isNewUserList = true;
                    }
                    else {
                        this.isNewUserList = false;
                    }
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("user-setup.getNewUserList", err.message, null, 'companyIdBilling ' + this.companyIdBilling);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    patchFormValue() {
        this.formGroup.patchValue({
            cLPUserID: this.formGroup.value['cLPUserID'],
            changePW: this.formGroup.value['changePW'],
            firstName: this.formGroup.value['firstName'],
            isAllowDownload: this.formGroup.value['isAllowDownload'],
            isShowCP: this.formGroup.value['isShowCP'],
            lastName: this.formGroup.value['lastName'],
            password: this.formGroup.value['password'],
            permissions: this.formGroup.value['permissions'],
            timeZone: this.formGroup.value['timeZone'],
            teamCode: this.formGroup.value['teamCode'],
            userName: this.formGroup.value['userName'],
            userRole: this.formGroup.value['userRole'],
            email: this.formGroup.value['email'],
            mobile: this.formGroup.value['mobile'],
            dateFormat: this.formGroup.value['dateFormat'],
            officeCode: this.formGroup.value['officeCode'],

        });
    }



    public getSelectedOfficeCode(officeCode) {
        let userOfficeCodeSelected;
        if (!isNullOrUndefined(this.officeCodeFilterList))
            userOfficeCodeSelected = this.officeCodeFilterList.filter((data) => data.key === officeCode)[0];
        return userOfficeCodeSelected?.key;
    }

    public convertOfficeCode(officeCode) {
        let officeCodeselected;
        if (!isNullOrUndefined(this.officeCodeFilterList))
            officeCodeselected = this.officeCodeFilterList.filter((data) => data.key === officeCode)[0];
        return officeCodeselected ? officeCodeselected.value : null;
    }

    public getSelectedTeamCode(teamCode) {
        let userTeamCodeSelected;
        if (!isNullOrUndefined(this.teamCodeFilterList))
            userTeamCodeSelected = this.teamCodeFilterList.filter((data) => data.key === teamCode)[0];
        return userTeamCodeSelected?.key;
    }

    public convertTeamCode(teamCode) {
        let teamCodeselected;
        if (!isNullOrUndefined(this.teamCodeFilterList))
            teamCodeselected = this.teamCodeFilterList.filter((data) => data.key === teamCode)[0];
        return teamCodeselected ? teamCodeselected.value : null;
    }

    public getSelectedTimeZone(timeZone) {
        let usertimeZoneSelected;
        if (!isNullOrUndefined(this.timezoneFilterList))
            usertimeZoneSelected = this.timezoneFilterList.filter((data) => data.id === timeZone)[0];
        return usertimeZoneSelected?.id;
    }

    public convertTimeZone(timeZone) {
        let timeZoneSelected;
        if (!isNullOrUndefined(this.timezoneFilterList))
            timeZoneSelected = this.timezoneFilterList.filter((data) => data.id === timeZone)[0];
        return timeZoneSelected ? timeZoneSelected.standardName : null;
    }

    public getUserType(userType) {
        let usertypeResult;
        if (!isNullOrUndefined(this.userTypeFilterList))
            usertypeResult = this.userTypeFilterList.filter((data) => data.key === userType)[0];
        return usertypeResult?.key;
    }

    public convertUserType(userType) {
        let userTypeSelected;
        if (!isNullOrUndefined(this.userTypeFilterList))
            userTypeSelected = this.userTypeFilterList.filter((data) => data.key === userType)[0];
        return userTypeSelected ? userTypeSelected.value : null;
    }


    public getUserDateFormat(userDate) {
        let userDateArraySelected;
        if (!isNullOrUndefined(this.dateFormatList))
            userDateArraySelected = this.dateFormatList.filter((data) => data.id === userDate)[0];
        return userDateArraySelected?.id;
    }

    public convertUserDateFormat(userDate) {
        let userDateSelected;
        if (!isNullOrUndefined(this.dateFormatList))
            userDateSelected = this.dateFormatList.filter((data) => data.id === userDate)[0];
        return userDateSelected ? userDateSelected.dateFormat : null;
    }

    public getUserStatus(status) {
        let userStatusResult;
        if (!isNullOrUndefined(this.userStatusFilterList))
            userStatusResult = this.userStatusFilterList.filter((data) => data.key === status)[0];
        return userStatusResult?.key;
    }

    public convertUserStatus(status) {
        let userStatusSelected;
        if (!isNullOrUndefined(this.userStatusFilterList))
            userStatusSelected = this.userStatusFilterList.filter((data) => data.key === status)[0];
        return userStatusSelected ? userStatusSelected.value : null;
    }



    async getUserSetupData() {
        this.showSpinner = true;
        await this.userSvc.getUsersSetup(this.encryptedUser, this.companyIdBilling, this.user.cLPUserID)
            .then(async (result: UserSetupResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.userSetupListResponse = UtilityService.clone(result);
                    this.userDataOriginal = this.userSetupListResponse.users;
                    if (!isNullOrUndefined(this._gridCnfgService)) {
                        this._gridCnfgService.iterateConfigGrid(this.userDataOriginal, "user_setup_grid");
                        this.mobileColumnNames = this._gridCnfgService.getResponsiveGridColums('user_setup_grid');
                    }
                    this.initUserData = UtilityService.clone(this.userDataOriginal);
                    this.dateFormatList = this.userSetupListResponse?.dateFormat;
                    let filterTeam: any = this.userSetupListResponse?.filterTeam;
                    let filterOffice: any = this.userSetupListResponse?.filterOffice;
                    if (!isNullOrUndefined(filterTeam))
                        this.teamCodeFilterList = this._localService.convertDictionaryToAnyType(filterTeam);
                    if (!isNullOrUndefined(filterOffice))
                        this.officeCodeFilterList = this._localService.convertDictionaryToAnyType(filterOffice);
                    if (!isNullOrUndefined(this.userSetupListResponse?.filterRoles))
                        this.userTypeFilterList = this._localService.convertDictionaryToAnyType(this.userSetupListResponse.filterRoles);
                    this.timezoneFilterList = this.userSetupListResponse?.timeZoneWin;
                    this.getUserExcelData();
                    this.showSpinner = false;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("user-setup.getUserSetupData", err.message, null, 'companyIdBilling ' + this.companyIdBilling + "," + "cLPUserID " + this.user.cLPUserID);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }

    private async authenticateR() {
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.UserSetup)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (this.userResponse?.user) {
                            this.user = this.userResponse.user;
                            this._gridCnfgService.user = this.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.user?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("userSetup.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this._utilityService.handleErrorResponse(err);
            });
    }
    public addHandler({ sender }) {
        this.isTimeZoneTouch = false;
        this.closeEditor(sender);
        this.formGroup = new FormGroup({
            cLPUserID: new FormControl(""),
            lastName: new FormControl("", Validators.required),
            firstName: new FormControl("", Validators.required),
            officeCode: new FormControl(0),
            teamCode: new FormControl(0),
            userName: new FormControl(""),
            password: new FormControl(""),
            timeZone: new FormControl("", Validators.required),
            dateFormat: new FormControl(""),
            userRole: new FormControl(-1),
            permissions: new FormControl(0),
            email: new FormControl(""),
            mobile: new FormControl(""),
            changePW: new FormControl(false),
            isShowCP: new FormControl(false),
            isAllowDownload: new FormControl(false)
        });
        this.isNew = true;
        sender.addRow(this.formGroup);

    }

    public editHandler({ sender, rowIndex, dataItem }) {
        this.isTimeZoneTouch = false;
        this.isNew = false;
        this.closeEditor(sender);
        this.updateUserFormValues(dataItem);
        this.editedRowIndex = rowIndex;
        sender.editRow(rowIndex, this.formGroup);
        this.isMobileValidate = 0;
    }

    public updateUserFormValues(dataItem) {
        this.isTimeZoneTouch = false;
        this.formGroup = new FormGroup({
            cLPUserID: new FormControl(dataItem.cLPUserID),
            lastName: new FormControl(dataItem.lastName, Validators.required),
            firstName: new FormControl(dataItem.firstName, Validators.required),
            officeCode: new FormControl(dataItem.officeCode),
            teamCode: new FormControl(dataItem.teamCode),
            userName: new FormControl(dataItem.userName),
            email: new FormControl(dataItem.email),
            mobile: new FormControl(dataItem.mobile),
            password: new FormControl(dataItem.password),
            timeZone: new FormControl(dataItem.timeZoneWinId, Validators.required),
            dateFormat: new FormControl(dataItem.dateFormatId),
            userRole: new FormControl(dataItem.userRole),
            permissions: new FormControl(dataItem.permissions),
            changePW: new FormControl(dataItem.changePW),
            isShowCP: new FormControl(dataItem.isShowCP),
            isAllowDownload: new FormControl(dataItem.isAllowDownload),
        });
        this.oldUserName = dataItem.userName;
    }

    public cancelHandler({ sender, rowIndex }) {
        this.isTimeZoneTouch = false;
        this.formGroup = null;
        this.closeEditor(sender, rowIndex);
        this.isMobileValidate = 0;
    }

    public saveHandler({ sender, rowIndex, formGroup, isNew, dataItem }): void {
        if (this.userNameExist) {
            this._notifyService.showError("Unable to Save please try with different Username ", "Invalid User Name!", 3000);
            return;
        }
        //this.showSpinner = true;
        dataItem.teamCode = this.formGroup.value['teamCode'];
        dataItem.officeCode = this.formGroup.value['officeCode'];
        dataItem.timeZoneWinId = this.formGroup.value['timeZone'];
        dataItem.userRole = this.formGroup.value['userRole'];
        dataItem.permissions = this.formGroup.value['permissions'];
        dataItem.isShowCP = this.formGroup.value['isShowCP'];
        dataItem.changePW = this.formGroup.value['changePW'];
        dataItem.isAllowDownload = this.formGroup.value['isAllowDownload'];
        dataItem.dateFormatId = this.formGroup.value['dateFormat'];
        dataItem.userName = this.formGroup.value['userName'];
        dataItem.email = this.formGroup.value['email'];
        dataItem.mobile = this.formGroup.value['mobile'];
        dataItem.firstName = this.formGroup.value['firstName'];
        dataItem.lastName = this.formGroup.value['lastName'];
        /*    dataItem. = 1;*/
        dataItem.password = this.formGroup.value['password'];




        if (isNew) {
            let newClpUser: CLPUser = <CLPUser>{};
            newClpUser.teamCode = dataItem.teamCode == null ? -1 : dataItem.teamCode;
            newClpUser.officeCode = dataItem.officeCode == null ? -1 : dataItem.officeCode;
            newClpUser.timeZoneWinId = dataItem.timeZoneWinId;
            newClpUser.userRole = dataItem.userRole;
            newClpUser.status = dataItem.status;
            newClpUser.isShowCP = dataItem.isShowCP;
            newClpUser.changePW = dataItem.changePW ? 1 : 0;
            newClpUser.isAllowDownload = dataItem.isAllowDownload;
            newClpUser.userName = dataItem.userName;
            newClpUser.email = dataItem.email;
            newClpUser.firstName = dataItem.firstName;
            newClpUser.lastName = dataItem.lastName;
            newClpUser.permissions = 1;
            newClpUser.password = dataItem.password;
            newClpUser.dateFormatId = dataItem.dateFormatId;

            delete newClpUser.cLPUserID;
            this.userSvc.createUser(this.encryptedUser, newClpUser, this.companyIdBilling)
                .then(async (result: SimpleResponse) => {
                    if (result) {
                        var response = UtilityService.clone(result);
                        sender.closeRow(rowIndex);
                        /*  this.sort = [{ field: "dtCreated", dir: "desc" }];*/
                        this.getUserSetupData();
                        this.showSpinner = false;
                        this.isMobileValidate = 0;
                        this._notifyService.showSuccess('User added successfuly', "", 3000);
                        this.resetUserFilters();
                    }
                    else {
                        this.showSpinner = false;
                        this.isMobileValidate = 0;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("user-setup.saveHandler", err.message, newClpUser, 'companyIdBilling ' + this.companyIdBilling);
                    this.showSpinner = false;
                    this.isMobileValidate = 0;
                    this._utilityService.handleErrorResponse(err);
                });

        }
        else {
            let user = this.initUserData.filter((item) => item?.cLPUserID == dataItem?.cLPUserID)[0]
            let currentUserLogged: boolean = false
            if (this.formGroup.value['timeZone'] == 0) {
                this._notifyService.showError('Select time zone', "", 3000);
                return
            }


            if (this.formGroup.valid) {
                sender.closeRow(rowIndex);
                let clpuser: CLPUser = <CLPUser>{};
                if (this.user.cLPUserID == user.cLPUserID) {
                    currentUserLogged = true;
                }
                clpuser = dataItem;
                clpuser.resetPassword = <ResetPassword>{}
                clpuser.changePW = clpuser.changePW ? 1 : 0;
                clpuser.password = "";
                clpuser.resetPassword.isForget = 1;
                this.userSvc.updateUser(this.encryptedUser, clpuser, this.companyIdBilling, this.user.cLPUserID)
                    .then(async (result: UserSetupResponse) => {
                        if (result && result?.statusCode == 200) {
                            if (user?.userName != dataItem.userName) {
                                this.forgetPassword(clpuser)
                            }
                            var response = UtilityService.clone(result);
                            if (this.isChangedUserName) {
                                if (currentUserLogged) {
                                    this.logout();
                                    this.isChangedUserName = false;
                                }
                                else {
                                    this.deleteUserToken(this.oldUserName, user.cLPUserID);
                                    this.isChangedUserName = false;
                                }
                            }
                            this.getUserSetupData();
                            this.showSpinner = false;
                            this.isMobileValidate = 0;
                            this._notifyService.showSuccess('User updated successfuly', "", 3000);
                            this.resetUserFilters();

                        }
                        else if (result?.statusCode == 202) {
                            this._notifyService.showError('Duplicate user name. Try new.', "Duplicate", 3000);
                        }
                        else {
                            this.showSpinner = false;
                            this.isMobileValidate = 0;
                        }
                    })
                    .catch((err: HttpErrorResponse) => {
                        this._globalService.error("user-setup.saveHandler", err.message, clpuser, 'companyIdBilling ' + this.companyIdBilling);
                        this.showSpinner = false;
                        this.isMobileValidate = 0;
                        this._utilityService.handleErrorResponse(err);
                    });
            }

        }
    }

    public async forgetPassword(user) {

        await this.userSvc.forgotPassword(user)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    if (result?.messageBool)
                        this._notifyService.showSuccess(result?.messageString, "", 3000);
                    else
                        this._notifyService.showError(result?.messageString, "", 3000);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.userSvc.auditLog(ActionType.ForgetError, isNullOrUndefined(this.user) ? 0 : this.user?.cLPUserID, err.message, "", "")
                this._globalService.error("Login.forgetPassword", err.message, this.user);
            });
    }

    resetUserFilters() {
        this.defaultItemTeamCodeTop = -1;
        this.defaultItemOfficeCodeTop = -1;
        this.defaultRole = -1;
    }

    sendWelcomeEmail(userId) {
        this.userSvc.sendWelcomeEmail(this.encryptedUser, userId)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this._notifyService.showSuccess('Welcome email has been sent successfully.', "", 3000);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("user-setup.sendWelcomeEmail", err.message, null, 'userId ' + userId);
                this._utilityService.handleErrorResponse(err);
            });
    }


    //setDropDownvalues(dataItem) {
    //    this.selectedValueTeamCode = this.getSelectedTeamCode(dataItem?.teamCode);
    //    if (isNullOrUndefined(this.selectedValueTeamCode)) {
    //        this.selectedValueTeamCode = 0;
    //    }
    //    this.selectedValueTimeZone = this.getSelectedTimeZone(dataItem?.timeZoneWinId);
    //    if (isNullOrUndefined(this.selectedValueTimeZone)) {
    //        this.selectedValueTimeZone = 0;
    //    }
    //    this.selectedValueOfficeCode = this.getSelectedOfficeCode(dataItem?.officeCode);
    //    if (isNullOrUndefined(this.selectedValueOfficeCode)) {
    //        this.selectedValueOfficeCode = 0;
    //    }

    //    this.selectedValueUserType = this.getUserType(dataItem?.userRole);
    //    if (isNullOrUndefined(this.selectedValueUserType)) {
    //        this.selectedValueUserType = -1;
    //    }


    //    this.selectedValueStatus = this.getUserStatus(dataItem?.status);
    //    if (isNullOrUndefined(this.selectedValueStatus)) {
    //        this.selectedValueStatus = 0;
    //    }

    //    this.selectedDateFormat = this.getUserDateFormat(dataItem?.dateFormatId);
    //    if (isNullOrUndefined(this.selectedDateFormat)) {
    //        this.selectedDateFormat = 0;
    //    }

    //}


    public removeHandler({ dataItem }): void {
        if (dataItem != null)
            this.currentUserToDelete = dataItem?.cLPUserID;
        this.currentUserUserName = dataItem.firstName + ' ' + dataItem.lastName;
    }

    deleteUser() {
        this.showSpinner = true;
        this.userSvc.deleteUser(this.encryptedUser, this.currentUserToDelete)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    this.getUserSetupData();
                    this.showSpinner = false;
                    this._notifyService.showSuccess('User deleted successfuly', "", 3000);
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("user-setup.getMailMergeTemplateList", err.message, null, 'currentUserToDelete ' + this.currentUserToDelete);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    private closeEditor(grid, rowIndex = this.editedRowIndex) {
        grid.closeRow(rowIndex);
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
    }


    /*savi*/
    public onFilter(inputValue: string): void {
        this.userDataOriginal = process(this.initUserData, {
            filter: {
                logic: "or",
                filters: [

                    {
                        field: "lastName",
                        operator: "contains",
                        value: inputValue,
                    },
                    {
                        field: "firstName",
                        operator: "contains",
                        value: inputValue,
                    },
                    {
                        field: "cLPUserID",
                        operator: "contains",
                        value: inputValue,
                    },
                    {
                        field: "userRole",
                        operator: "contains",
                        value: inputValue,
                    },
                    {
                        field: "permissions",
                        operator: "contains",
                        value: inputValue,
                    }
                ],
            },
        }).data;

        this.dataBinding.skip = 0;
    }
    /*savi*/



    topDDChange() {
        if (typeof this.defaultItemTeamCodeTop === "string")
            this.defaultItemTeamCodeTop = +this.defaultItemTeamCodeTop;

        if (typeof this.defaultItemOfficeCodeTop === "string")
            this.defaultItemOfficeCodeTop = +this.defaultItemOfficeCodeTop;

        if (typeof this.defaultRole === "string")
            this.defaultRole = +this.defaultRole;

        this.userDataOriginal = this.initUserData.filter(item => (this.defaultItemTeamCodeTop > 0 ? (item.teamCode == this.defaultItemTeamCodeTop) : true) && (this.defaultItemOfficeCodeTop > 0 ? (item.officeCode == this.defaultItemOfficeCodeTop) : true) && (this.defaultRole > 0 ? (item.userRole === this.defaultRole) : true));
    }


    showColumns(column) {
        if (column.field != 'firstName' && column.field != 'dateFormat' && column.field != 'lastName' && column.field != 'teamCode' && column.field != 'timeZone' && column.field != 'userRole' && column.field != 'permissions' && column.field != 'officeCode' && column.field != 'cLPUserID' && column.field != 'welcome' && column.field != 'password' && column.field != 'changePW')
            return true;
        else
            return false;
    }

    async checkDuplicate(oldEmail: string) {
        let email = this.newUserFormGroup.value['userName']
        if (email != oldEmail) {
            if (email?.length > 0) {
                this.createUser = false;
                this.isMobileValidate = 1;
                await this._signupService.cLPUser_DuplicateCheck(email)
                    .then((result: SimpleResponse) => {
                        if (!isNullOrUndefined(result)) {
                            var response = UtilityService.clone(result);
                            if ((response && response.statusCode == 201 && response.messageInt > 0)) {
                                this.isMobileValidate = 1; //is exist
                                this.createUser = true;
                                this._notifyService.showError('Username already registered', "Username unavailable", 3000)
                            }
                            else if (((/^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$/).test(email) == false)) {
                                this.isMobileValidate = 1;//Wrong Formate
                                this.createUser = true;
                                this._notifyService.showError('Username should be in email format.', "Username Invalid", 3000)
                            } else {
                                this.isMobileValidate = 2; //not exist
                                this._notifyService.showSuccess('Username is available', "", 3000)
                            }
                        }
                    })
                    .catch((err: HttpErrorResponse) => {
                        this._globalService.error("user-setup.checkDuplicate", err.message, email);
                        this.isMobileValidate = 0;
                        this._utilityService.handleErrorResponse(err);
                    });
            }
        }
    }

    public checkColumn(column) {
        if (column.field != 'teamCode' && column.field != 'timeZone' && column.field != 'userRole' &&
            column.field != 'permissions' && column.field != 'officeCode' && column.field != 'cLPUserID' && column.field != '$'
            && column.field != 'lastName' && column.field != 'firstName' && column.field != 'userName' && column.field != 'changePW' && column.field != 'email'
            && column.field != 'isShowCP' && column.field != 'isAllowDownload' && column.field != 'dateFormat' && column.field != 'welcome' && column.field != 'password' && column.field != '$$' && column.field != 'email' && column.field != 'mobile')
            return true;
        else
            return false;
    }

    get f() { return this.formGroup.controls; }

    get nf() { return this.newUserFormGroup.controls; }

    get mob() {
        return this.isMobileValidate === 1 ? true : false;
    }
    get mobin() {
        return this.isMobileValidate === 2 ? true : false;
    }

    public saveExcel(component): void {
        const options = component.workbookOptions();
        options.sheets[0].name = `User List`;
        let rows = options.sheets[0].rows;
        rows.forEach((row) => {
            if (row && row.type == "data") {
                row.cells.forEach((cell) => {
                    if (!isNullOrUndefined(cell) && cell?.value && (typeof cell.value === 'string') && cell?.value?.includes("<br>")) {
                        cell.value = cell.value.replace(/<br\s*\/?>/gi, ' ');
                    }
                });
            }
        });
        Array.prototype.unshift.apply(rows);
        component.save(options);
    }


    saveExcelUser() {
        let inputElement: HTMLElement = this.saveExcelUSBtn.nativeElement as HTMLElement;
        inputElement.click();
    }

    getUserExcelData() {
        this.userDataExcel = [];
        this.userDataOriginal.forEach(s => {
            this.userDataExcel.push(
                {
                    firstName: s.firstName,
                    lastName: s.lastName,
                    office: this.convertOfficeCode(s.officeCode),
                    role: this.convertUserType(s.userRole),
                    team: this.convertTeamCode(s.teamCode),
                    timeZone: this.convertTimeZone(s.timeZoneWinId),
                    userName: s.userName,
                    userCode: 'VR9' + s.cLPUserID
                });               
        });
    }

    async getToken() {
        this.showSpinner = true;
        var simpleResponse: SimpleResponse = <SimpleResponse>{};
        await this._localService.Authenticate_ihRedirect(this.encryptedUser, simpleResponse)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.encryptedUserOld = result?.messageString2;
                }
                else
                    this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("user-setup.getToken", err.message, simpleResponse);
                this._utilityService.handleErrorResponse(err);
                this.showSpinner = false;
            });
    }


    public editHandlerNewUser({ sender, rowIndex, dataItem }) {
        this.closeEditorNewUser(sender);
        this.updateNewUserFormValues(dataItem);
        this.editedRowIndexNewUser = rowIndex;
        sender.editRow(rowIndex, this.newUserFormGroup);
    }

    public cancelHandlerNewUser({ sender, rowIndex }) {
        this.newUserFormGroup = null;
        this.closeEditorNewUser(sender, rowIndex);
    }

    public saveHandlerNewUser({ sender, rowIndex, newUserFormGroup, isNew, dataItem }): void {
        this._localService.validateAllFormFields(this.newUserFormGroup);
        if (this.newUserFormGroup.valid == false) {
            this._notifyService.showError("Please Check the Entries.", "", 3000);
            return;
        }
        if (this.createUser) {
            this._notifyService.showError("Username Already Exist.", "", 3000);
            return
        }
        if (this.newUserFormGroup.value['emailConfirm'] == this.newUserFormGroup.value['email']) {
            if (this.newUserFormGroup.value['lastName'] == '' || this.newUserFormGroup.value['lastName'] == null || this.newUserFormGroup.value['lastName'] == undefined) {
                this._notifyService.showError("Last name is required.", "", 3000);
                return
            }
            if (this.newUserFormGroup.value['firstName'] == '' || this.newUserFormGroup.value['firstName'] == null || this.newUserFormGroup.value['firstName'] == undefined) {
                this._notifyService.showError("First name is required.", "", 3000);
                return
            }
            if (this.newUserFormGroup.value['userName'] == '' || this.newUserFormGroup.value['userName'] == null || this.newUserFormGroup.value['userName'] == undefined) {
                this._notifyService.showError("User name is required.", "", 3000);
                return
            }
            this.showSpinner = true;
            let clpuser: CLPUser = <CLPUser>{};
            clpuser = dataItem;
            clpuser.timeZoneWinId = this.user?.timeZoneWinId;
            clpuser.userName = this.newUserFormGroup.value['userName'];
            clpuser.email = this.newUserFormGroup.value['email'];
            clpuser.lastName = this.newUserFormGroup.value['lastName'];
            clpuser.firstName = this.newUserFormGroup.value['firstName'];
            clpuser.permissions = eUserPermissions.Active;
            sender.closeRow(rowIndex);
            this.userSvc.updateUser(this.encryptedUser, clpuser, this.companyIdBilling, this.user.cLPUserID)
                .then(async (result: UserSetupResponse) => {
                    if (!isNullOrUndefined(result)) {
                        var response = UtilityService.clone(result);
                        this.getUserSetupData();
                        dataItem.status = 1;
                        this._notifyService.showSuccess(this.newUserFormGroup.value['firstName'] + ' has been setup successfully.', "", 3000);
                        this.showSpinner = false;
                    } else {
                        this.showSpinner = false;
                        this.isMobileValidate = 0;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("user-setup.saveHandlerNewUser", err.message, clpuser, 'companyIdBilling ' + this.companyIdBilling + "," + "cLPUserID " + this.user.cLPUserID);
                    this.showSpinner = false;
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            this._notifyService.showError("Email does not match.", "", 3000);
        }
    }


    private closeEditorNewUser(grid, rowIndex = this.editedRowIndex) {
        grid.closeRow(rowIndex);
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
    }

    public updateNewUserFormValues(dataItem) {
        this.newUserFormGroup = new FormGroup({
            lastName: new FormControl(dataItem.lastName, Validators.required),
            firstName: new FormControl(dataItem.firstName, Validators.required),
            email: new FormControl(dataItem.email, Validators.required),
            emailConfirm: new FormControl(dataItem.email, Validators.required),
            userName: new FormControl(dataItem.emailConfirm, Validators.required),
        });
    }
    onUserSelect(cLPUserID) {
        this.selectedUserID = cLPUserID;
        this.isUserSelected = true;
    }

    isEditable() {
        if (this.user?.slurpyUserId > 0) {
            return false;
        }
        else {
            return true;
        }
    }


    async checkuserNameDuplicate() {
        let userName = this.formGroup.value['userName'];
        let clpUserID = this.formGroup.value['cLPUserID'];
        if (this.oldUserName != userName) {
            await this.userSvc.checkDuplicateUserName(this.encryptedUser, userName, this.user.cLPCompanyID)
                .then(async (result: SimpleResponse) => {
                    if (!isNullOrUndefined(result)) {
                        var response = UtilityService.clone(result);
                        if (response.messageInt == 1) {
                            this.userNameExist = true;
                            this._notifyService.showError("User Name Exist in the Company", "User Name Error ", 3000);

                        }
                        else if (response.messageInt == 2) {
                            this.userNameExist = true;
                            this._notifyService.showError("User Name Exist in Database", " User Name Error ", 3000);

                        }
                        else {
                            this.isChangedUserName = true
                            this.userNameExist = false;
                        }
                        this.showSpinner = false;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("user-setup.checkuserNameDuplicate", err.message, userName, 'cLPCompanyID ' + this.user.cLPCompanyID);
                    this.showSpinner = false;
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async deleteUserToken(oldUserName: string, clpUserID: number) {
        await this.userSvc.deleteUserTokenByUserId(this.encryptedUser, oldUserName, clpUserID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    var response = UtilityService.clone(result);
                    
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("user-setup.checkuserNameDuplicate", err.message, oldUserName, 'clpUserID ' + clpUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }


    logout() {
        this._localService.userToken_Signout(this.encryptedUser)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result) && result.messageBool) {
                    localStorage.removeItem("token");
                    localStorage.clear();
                    this.titleService.setTitle('SalesOptima');
                    this._router.navigate(['/']);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("buzz-index.getLeadHistory", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }
}

