<div class="custom-security-link">
    <a [routerLink]="['/contact',user?.cLPUserID, contactId]">View Contact</a>
    <a [routerLink]="['/activity-history', contactId]">Contact History</a>
</div>
<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel">
                    <img src="../../../../assets/contacthistorytitle.svg" class="mr-1" />Import Contact History Into:&nbsp;
                    <a class="contact-cursor-pointer" (click)="gotoLink('name',contactData);">{{contactData?.firstName}} {{contactData?.lastName}}</a>
                </div>
            </div>
            <div [innerHTML]="warningMessage" class="message-info"></div>
            <div class="global-padding10" *ngIf="isShowHistory">
                <button type="button" [hidden]="roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator" class="btn btn-primary" (click)="confirm()">Confirm</button>
                <button type="button" class="btn btn-cancel" (click)="cancel()">Cancel</button>
            </div>
            <div class="global-body-section global-padding10">
                <div class="wraper-main-section">
                    <div class="global-card-section">
                        <div class="common-inner-header">
                            <div class="inner-header-bg">
                                <div class="inner-cards-panel header-inner-hidden">
                                    <span>From</span>
                                    <span>To</span>
                                </div>
                                <div class="inner-detail-bg">
                                    <span>Details</span>
                                </div>
                            </div>
                        </div>
                        <div class="common-inner-cards">
                            <div class="inner-cards-grid" style="align-items:flex-start">
                                <div class="inner-card-mobile">
                                    <label>From</label>
                                    <div class="mobile-view-design">
                                        <kendo-autocomplete #autocomplete [data]="contactSearchList" valueField="searchText" placeholder="Search" (valueChange)="onSelectContact($event)"
                                                            (keyup)="getSearchData($event.target.value)" *ngIf="!isShowHistory"></kendo-autocomplete>
                                        <div *ngIf="isShowHistory">
                                            <a class="contact-cursor-pointer" (click)="gotoLink('name',fromContactData);">{{fromContactData?.firstName}} {{fromContactData?.lastName}}<br /></a>
                                            <span *ngIf="(fromContactData?.companyName | isNull)">{{fromContactData?.companyName}}<br /></span>
                                            <span *ngIf="(fromContactData?.add1 | isNull)">{{fromContactData?.add1}}<br /></span>
                                            <span *ngIf="(fromContactData?.add2 | isNull)">{{fromContactData?.add2}}<br /></span>
                                            <span *ngIf="(fromContactData?.add3 | isNull)">{{fromContactData?.add3}}<br /></span>
                                            <span *ngIf="(fromContactData?.city | isNull)">{{fromContactData?.city}},</span>
                                            <span *ngIf="(fromContactData?.state | isNull)">{{fromContactData?.state}}  </span>
                                            <span *ngIf="(fromContactData?.zip | isNull)">{{fromContactData?.zip}}  </span>
                                            <span *ngIf="(fromContactData?.country | isNull)">{{fromContactData?.country}}<br /></span>
                                            <span *ngIf="(fromContactData?.phone | isNull)">B: {{pixString(fromContactData?.phone) | phoneFormat}}<br /></span>
                                            <span *ngIf="(fromContactData?.mobile | isNull)">M: {{pixString(fromContactData?.mobile) | phoneFormat}}<br /></span>
                                            <span *ngIf="(fromContactData?.homePhone | isNull)">H: {{pixString(fromContactData?.homePhone) | phoneFormat}}<br /></span>
                                            <span *ngIf="(fromContactData?.altPhone | isNull)">O: {{pixString(fromContactData?.altPhone) | phoneFormat}}<br /></span>
                                            <span *ngIf="(fromContactData?.fax | isNull)">F: {{fromContactData?.fax}}<br /></span>
                                            <span *ngIf="(fromContactData?.email | isNull)">E: {{fromContactData?.email}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="inner-card-mobile">
                                    <label>To</label>
                                    <div class="mobile-view-design">
                                        <div>
                                            <a class="contact-cursor-pointer" (click)="gotoLink('name',contactData);">{{contactData?.firstName}} {{contactData?.lastName}}<br /></a>
                                            <span *ngIf="(contactData?.companyName | isNull)">{{contactData?.companyName}}<br /></span>
                                            <span *ngIf="(contactData?.add1 | isNull)">{{contactData?.add1}}<br /></span>
                                            <span *ngIf="(contactData?.add2 | isNull)">{{contactData?.add2}}<br /></span>
                                            <span *ngIf="(contactData?.add3 | isNull)">{{contactData?.add3}}<br /></span>
                                            <span *ngIf="(contactData?.city | isNull)">{{contactData?.city}},</span>
                                            <span *ngIf="(contactData?.state | isNull)">{{contactData?.state}}  </span>
                                            <span *ngIf="(contactData?.zip | isNull)">{{contactData?.zip}}  </span>
                                            <span *ngIf="(contactData?.country | isNull)">{{contactData?.country}}<br /></span>
                                            <span *ngIf="(contactData?.phone | isNull)">B: {{pixString(contactData?.phone) | phoneFormat}}<br /></span>
                                            <span *ngIf="(contactData?.mobile | isNull)">M: {{pixString(contactData?.mobile) | phoneFormat}}<br /></span>
                                            <span *ngIf="(contactData?.homePhone | isNull)">H: {{pixString(contactData?.homePhone) | phoneFormat}}<br /></span>
                                            <span *ngIf="(contactData?.altPhone | isNull)">O: {{pixString(contactData?.altPhone) | phoneFormat}}<br /></span>
                                            <span *ngIf="(contactData?.fax | isNull)">F: {{contactData?.fax}}<br /></span>
                                            <span *ngIf="(contactData?.email | isNull)">E: {{contactData?.email}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cards-body-section">
                <div class="cards-colunm-left bg-warning text-white">
                    <span class="">Important Note</span>
                </div>
                <div class="cards-colunm-right" style="background:#fde8af">
                    <ul>
                        <li>Only contact related history items will be imported.</li>
                        <li>History items cannot be imported from a contact currently associated automated processes.</li>
                    </ul>
                </div>
            </div>
            <div *ngIf="isShowHistory">
                <activity-history [ownerId]="fromContactId" [isShowLinks]="false" ownerName="{{fromContactData?.firstName}} {{fromContactData?.lastName}}" [isShowContactHistory]="true"></activity-history>
            </div>

        </div>
    </div>
</div>

