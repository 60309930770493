import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CalenderSchedulerResponse, CreateScheduleRequest } from '../../models/calender.model';
import { ScheduleAppointmentRequest, ScheduleAppointmentResponse } from '../../models/scheduler.model';
import { AppointmentSettingService } from '../../services/appointmentSetting.service';
import { MyCalenderService } from '../../services/my-calender.service';
import { NotificationService } from '../../services/notification.service';
import { AppconfigService } from '../../services/shared/appconfig.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';

@Component({
    selector: 'app-dynamic-scheduler',
    templateUrl: './dynamic-scheduler.component.html',
    styleUrl: './dynamic-scheduler.component.css'
})
export class DynamicSchedulerComponent implements OnInit {
    scheduleAppointmentForm: FormGroup
    scheduleAppointmentWeekDay: string;
    scheduleAppointmentMonth: string;
    scheduleAppointmentDate: string;
    scheduleAppointmentYear: string;
    scheduleAppointmentTime: any;
    scheduleApptObj: CreateScheduleRequest = <CreateScheduleRequest>{};
    selectedDate: string
    completeDateTime: any;
    dateFormat: string;
    selectedUserId: number;
    currDate: Date = new Date();
    pKey: string;
    constructor(public _localService: LocalService,
        private _utilityService: UtilityService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _appConfigService: AppconfigService,
        private myCalenderService: MyCalenderService,
        private fb: FormBuilder,
        private appointmentSettingService: AppointmentSettingService,
        private notifyService: NotificationService,
    ) {
        this.scheduleAppointmentForm = this.prepareScheduleAppointmentForm()
    }


    ngOnInit(): void {
        this._route.queryParams.subscribe(params => {
            console.log(params)
            if (params?.pkey) {
                this.pKey = params?.pkey;
                this.getScheduleAppointmentData();
            }
        })
    }


    prepareScheduleAppointmentForm() {
        return this.fb.group({
            txtName: ['', [Validators.required]],
            txtEmail: ['', [Validators.required]],
            txtMobile: ['', [Validators.required]],
            txtNotes: [''],
        });
    }

    async getScheduleAppointmentData() {

        if (this.pKey != "") {
            await this.myCalenderService.calenderDynamicLoad(this.pKey)
                .then(async (result: CalenderSchedulerResponse) => {
                    if (result) {
                        this.scheduleApptObj.PCalID = result?.pcl.pCalID
                        this.scheduleApptObj.AppliesToCLPUserID = result?.pcl[0]?.appliesToCLPUserID
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            this.notifyService.showError("Some error occured in loading calendar.", "Failed", 3000)
        }
    }

    onScheduleAppointmentDateChange(e) {
        this.scheduleAppointmentTime = ""
        this.scheduleAppointmentWeekDay = new Date(e).toLocaleDateString('en-us', { weekday: "long" });
        this.scheduleAppointmentMonth = new Date(e).toLocaleDateString('en-us', { month: "long" });
        this.scheduleAppointmentDate = new Date(e).toLocaleDateString('en-us', { day: "numeric" });
        this.scheduleAppointmentYear = new Date(e).toLocaleDateString('en-us', { year: "numeric" });
        this.selectedDate = formatDate(e, "MM-dd-yyyy", 'en-US')
    }


    onScheduleAppointmentTimeSelect(event) {
        if (event.target.value) {
            this.scheduleAppointmentTime = event.target.value
            this.completeDateTime = this.selectedDate + " " + event.target.value
            this.scheduleApptObj.DateSelected = this.completeDateTime
        }
    }

    onSubmitScheduleForm() {
        if (this.scheduleAppointmentForm.valid) {
            this.scheduleApptObj.Name = this.scheduleAppointmentForm.controls['txtName'].value
            this.scheduleApptObj.Email = this.scheduleAppointmentForm.controls['txtEmail'].value
            this.scheduleApptObj.Mobile = this.scheduleAppointmentForm.controls['txtMobile'].value
            this.scheduleApptObj.Notes = this.scheduleAppointmentForm.controls['txtNotes'].value
            this.scheduleAppointment()
        }
        else {
            this.notifyService.showError("Fill required fields", "Failed", 3000)
        }
    }

    async scheduleAppointment() {
        await this.myCalenderService.Schedule_Create(this.scheduleApptObj)
            .then(async (result) => {
                if (result) {
                    this.notifyService.showSuccess("Appointment Scheduled Successfully.", "Success", 3000)
                    this.scheduleAppointmentForm.reset()
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }
}
