<div class="margin-all-area" #scrollIdTarget [@filterAnimation]="showAnimation">
    <div class="home-dashboard-section">
        <div [ngClass]="isReferred ? 'row-panel' : 'row-panel-full'">
            <div [ngClass]="isReferred ? 'col-left-panel' : ''">
                <div class="global-padding10">
                    <div class="wraper-main-section">
                        <div class="global-card-section">
                            <div class="global-header-section">
                                <div class="svg-icon-panel">
                                    <ng-template [ngIf]="isSaveAs">
                                        <span class="svg-icon-panel p-0">
                                            <img src="../../../../../assets/contactstitle.svg" /><span>Save As Contact Information</span>
                                        </span>
                                    </ng-template>
                                    <ng-template [ngIf]="!isSaveAs">
                                        <span class="svg-icon-panel p-0">
                                            <img src="../../../../../assets/contactstitle.svg" />
                                            <span>
                                                Contact Information
                                                <span class="pl-0" *ngIf="currentUrl == 'contact-create' && contactForm?.get('firstName')?.value">
                                                    - {{ contactForm?.get('firstName').value  | titlecase}} {{contactForm?.get('lastName').value | titlecase}}
                                                </span>
                                            </span>
                                        </span>
                                    </ng-template>

                                </div>
                                <div class="header-button-panel" *ngIf="!isFromLiveConnect">
                                    <div class="button-wrapper" [hidden]="_contactSearchService.showSpinner">
                                        <button class="icon-btn" [hidden]="contactId > 0 ? (roleFeaturePermissions?.create == false && user?.userRole <= eUserRole.Administrator) : (roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator)" [disabled]="showSpinner || isContactUpdated " type="button" (click)="contactFormSubmit()" title="Save">
                                            <i class="fa-solid fa-floppy-disk"></i>
                                            <span class="btn-text">Save</span>
                                        </button>
                                        <button class="grid-delete-btn" [hidden]="roleFeaturePermissions?.delete == false && user?.userRole <= eUserRole.Administrator" [disabled]="showSpinner || isContactUpdated " data-toggle="modal" data-target="#contactDeleteModal" *ngIf="contactId >0" title="Delete">
                                            <img src="../../../../assets/trash-btn.svg" />
                                            <span class="btn-text">Delete</span>
                                        </button>
                                        <button class="icon-btn" (click)="isPinned ? unpinContact() : pinContact()" *ngIf="contactId > 0" title="{{isPinned ? 'Unpin': 'Pin'}}">
                                            <img [src]=" !isPinned ? '../../../../assets/pinbtn.svg':'../../../../assets/unpinbtn.svg'">
                                            <span class="btn-text">Pin</span>
                                        </button>
                                        <button type="button" class="icon-btn" (click)="_localService.hideCommonComponentEmit('contact-detail'); _localService.showCommonComp = ''" *ngIf="currentUrl != 'contact-create'" title="Cancel">
                                            <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                            <span class="btn-text">Cancel</span>
                                        </button>
                                        <button class="icon-btn" [routerLink]="['/contacts']" *ngIf="currentUrl == 'contact-create'" title="Cancel">
                                            <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                            <span class="btn-text">Cancel</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="global-body-section">
                                <p class="message-info" *ngIf="isShowMsgDup">Duplicate Detection: </p> <div class="message-info" *ngIf="isShowMsgDup" [innerHtml]="messageDup"></div>
                                <p class="message-info">Please enter contact information and click Save.</p>
                                <form [formGroup]="contactForm" (ngSubmit)="contactFormSubmit()" *ngIf="!showSpinner">
                                    <div class="cards-height-section">
                                        <div class="cards-height-panel">
                                            <div *ngIf="contactFields">
                                                <div class="cards-height-flex">
                                                    <div>
                                                        <div class="form-group" [hidden]="contactFields.firstName.isShow == 2" [ngClass]="{'has-asterisk': contactFields.firstName.isShow == 1}">
                                                            <label>{{contactFields.firstName.fieldTitle}}</label>
                                                            <span *ngIf="isEditMode">:&nbsp;{{contactFields.firstName.fieldValue}}</span>
                                                            <input type="text" tabindex="1" class="form-control" *ngIf="!isEditMode" pattern="^(\s+\S+\s*)*(?!\s).*$" (blur)="checkDup('Name')" formControlName="firstName"
                                                                   [ngClass]="{'has-error':  (contactFrm?.firstName?.errors && (contactFrm?.firstName?.touched || contactFrm?.firstName?.dirty)) || contactFrm?.lastName?.errors?.pattern }" />
                                                            <!--<div *ngIf="contactFrm?.firstName?.errors &&(contactFrm?.firstName.touched || contactFrm?.firstName?.dirty)">
                                                              <div class="search-query-error acc-info-srch-error" *ngIf="contactFrm?.firstName?.errors?.required">
                                                                Please enter First Name
                                                              </div>
                                                            </div>-->
                                                        </div>
                                                        <div class="form-group" [hidden]="contactFields.lastName.isShow == 2" [ngClass]="{'has-asterisk': contactFields.lastName.isShow == 1}">
                                                            <label>{{contactFields.lastName.fieldTitle}}</label>
                                                            <span *ngIf="isEditMode">:&nbsp;{{contactFields.lastName.fieldValue}}</span>
                                                            <input type="text" tabindex="2" class="form-control" *ngIf="!isEditMode" (blur)="checkDup('Name')"
                                                                   pattern="^(\s+\S+\s*)*(?!\s).*$" formControlName="lastName"
                                                                   [ngClass]="{'has-error': (contactFrm?.lastName?.errors && (contactFrm?.lastName?.touched || contactFrm?.lastName?.dirty)) || contactFrm?.lastName?.errors?.pattern }" />
                                                            <!--<div *ngIf="contactFrm?.lastName?.errors &&(contactFrm?.lastName.touched || contactFrm?.lastName?.dirty)">
                                                              <div class="search-query-error acc-info-srch-error" *ngIf="contactFrm?.lastName?.errors?.required">
                                                                Please enter LastName
                                                              </div>
                                                            </div>-->
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="form-group" [hidden]="contactFields.mobile.isShow == 2" [ngClass]="{'has-asterisk':contactFields.mobile.isShow == 1}">
                                                            <label>{{contactFields.mobile.fieldTitle}}</label>
                                                            <span *ngIf="isEditMode">:&nbsp;{{contactFields.mobile.fieldValue | phoneFormat}}</span>
                                                            <input type="text" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)" maxlength="15" tabindex="3" class="form-control" *ngIf="!isEditMode" formControlName="mobile" [placeholder]="placeHolder" [mask]="mobile_mask"
                                                                   [ngClass]="{'has-error': (contactFrm?.mobile?.errors && (contactFrm?.mobile.touched || contactFrm?.mobile?.dirty))}" />
                                                            <!--<div *ngIf="contactFrm?.mobile?.errors &&(contactFrm?.mobile?.touched || contactFrm?.mobile?.dirty)">
                                                              <div class="search-query-error acc-info-srch-error" *ngIf="contactFrm?.mobile?.errors?.required">
                                                                Please enter Mobile
                                                              </div>
                                                            </div>-->
                                                        </div>
                                                        <div class="form-group" [hidden]="contactFields.email.isShow == 2" [ngClass]="{'has-asterisk':contactFields.email.isShow == 1}">
                                                            <label>{{contactFields.email.fieldTitle}}</label>
                                                            <span *ngIf="isEditMode">:&nbsp;{{contactFields.email.fieldValue}}</span>
                                                            <input type="text" tabindex="4" class="form-control" *ngIf="!isEditMode" formControlName="email" (blur)="checkDup('Email')"
                                                                   [ngClass]="{'has-error': (contactFrm?.email?.errors && (contactFrm?.email?.touched || contactFrm?.email?.dirty))}"
                                                                   pattern="^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$" />


                                                            <div *ngIf="contactFrm?.email?.errors &&(contactFrm?.email.touched || contactFrm?.email?.dirty)">
                                                                <div class="search-query-error acc-info-srch-error" *ngIf="contactFrm?.email?.errors?.pattern">
                                                                    Invalid Email
                                                                </div>
                                                                <!--<div class="search-query-error acc-info-srch-error" *ngIf="contactFrm?.email?.errors?.required">
                                                                  Please enter Email
                                                                </div>-->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="cards-height-flex">
                                                    <div></div>
                                                    <div>
                                                        <label class="clickable text-info" (click)="moreLess()"><i>{{moreLabel}}</i></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="list" [hidden]="more">
                                                <div class="multiple-cards-section">
                                                    <div class="multiple-cards-panel" *ngFor="let section of arrSortedBySection; let is = index;" [hidden]="section.items.length == 0 || isShowFields(is)">
                                                        <div class="wraper-main-section" *ngIf="isFromLiveConnect ? section.sectionName != 'System' : true">
                                                            <div class="global-card-section">
                                                                <div class="global-header-section">
                                                                    <span>{{ section.sectionName }}</span>
                                                                </div>
                                                                <div class="global-body-section">
                                                                    <div class="">
                                                                        <div class="" *ngFor="let item of section.items; let i = index">
                                                                            <div class="form-group" *ngIf="item.fieldType == 0" [hidden]="item.isShow == 2" [ngClass]="{'has-asterisk': item.isShow == 1}">
                                                                                <label *ngIf="section.sectionName != 'System' && item.fieldTitle != 'Email' && item.fieldTitle != 'First Name' && item.fieldTitle != 'Last Name' && item.fieldTitle != 'Mobile' ">{{item.fieldTitle}}</label>
                                                                                <span *ngIf="isEditMode">:&nbsp;{{item.fieldValue}}</span>
                                                                                <input type="text" class="form-control" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)" *ngIf="!isEditMode && item.fieldTitle == 'Phone'" formControlName="{{ item.fieldName }}"
                                                                                       [placeholder]="placeHolder" [mask]="mobile_mask" [ngClass]="{'has-error': (contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty))}" />
                                                                                <input type="text" class="form-control" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)" *ngIf="!isEditMode && item.fieldTitle == 'Home Phone'" formControlName="{{ item.fieldName }}"
                                                                                       [placeholder]="placeHolder" [mask]="mobile_mask" [ngClass]="{'has-error': (contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty))}" />

                                                                                <input type="text" class="form-control" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)" *ngIf="!isEditMode && item.fieldTitle == 'Alternate Phone'" formControlName="{{ item.fieldName }}"
                                                                                       [placeholder]="placeHolder" [mask]="mobile_mask" [ngClass]="{'has-error': (contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty))}" />

                                                                                <input type="text" class="form-control" (keypress)="_localService.onKeyDown($event)" (paste)="_localService.onPaste($event)" *ngIf="!isEditMode && item.fieldTitle == 'Emergency Contact Phone'" formControlName="{{ item.fieldName }}"
                                                                                       [placeholder]="placeHolder" [mask]="mobile_mask" [ngClass]="{'has-error': (contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty))}" />
                                                                                <!--Also Check this is not Patching Correctly for Company-->
                                                                                <input type="text" class="form-control" *ngIf="!isEditMode && item.fieldTitle == 'Zip'" placeholder="Zip" minlength="3" maxlength="9" (blur)="getCityState($event);" formControlName="{{ item.fieldName }}" (keypress)="_localService.onKeyDown($event)"
                                                                                       [ngClass]="{'has-error': (contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty))}" />

                                                                                <input type="text" class="form-control" *ngIf="!isEditMode && item.fieldTitle != 'Email2' && section.sectionName != 'System' && item.fieldTitle != 'Email3' && item.fieldTitle != 'Phone' && item.fieldTitle != 'Home Phone' && item.fieldTitle != 'Alternate Phone' && item.fieldTitle != 'Zip' && item.fieldTitle != 'Fax' && item.fieldTitle != 'Other Fax' && item.fieldTitle != 'Comments' && item.fieldTitle != 'Emergency Contact Phone'"
                                                                                       formControlName="{{ item.fieldName }}" [ngClass]="{'has-error': (contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty))}" />

                                                                                <input type="text" class="form-control" (keypress)="_localService.onKeyDown($event)" *ngIf="!isEditMode && item.fieldTitle == 'Fax'"
                                                                                       formControlName="{{ item.fieldName }}" [placeholder]="placeHolder" [mask]="mobile_mask" [ngClass]="{'has-error': (contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty))}" />

                                                                                <input type="text" class="form-control" (keypress)="_localService.onKeyDown($event)" *ngIf="!isEditMode && item.fieldTitle == 'Other Fax'"
                                                                                       formControlName="{{ item.fieldName }}" [placeholder]="placeHolder" [mask]="mobile_mask" [ngClass]="{'has-error': (contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty))}" />

                                                                                <input type="text" class="form-control" *ngIf="!isEditMode && item.fieldTitle == 'Email2'" formControlName="{{ item.fieldName }}"
                                                                                       (ngModelChange)="onemail2Change($event)" [ngClass]="{'has-error': (contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty))}"
                                                                                       pattern="^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$" [required]="email2blastaddress" />

                                                                                <textarea class="form-control" *ngIf="!isEditMode && item.fieldTitle == 'Comments'" formControlName="{{ item.fieldName }}" [ngClass]="{'has-error': (item?.isShow == 1 && contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty))}" [required]="item?.isShow == 1"></textarea>

                                                                                <div *ngIf="contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty) && item.fieldTitle == 'Email2'">
                                                                                    <div *ngIf="contactForm?.get(item.fieldName)?.errors?.pattern" class=" text-danger">
                                                                                        Invalid Email Id
                                                                                    </div>
                                                                                </div>

                                                                                <input type="text" class="form-control" *ngIf="!isEditMode && item.fieldTitle == 'Email3'" formControlName="{{ item.fieldName }}"
                                                                                       (ngModelChange)="onemail3Change($event)" [ngClass]="{'has-error': (contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty))}"
                                                                                       pattern="^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$" [required]="email3blastaddress" />

                                                                                <div *ngIf="contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty)&& item.fieldTitle == 'Email3'">
                                                                                    <div *ngIf="contactForm?.get(item.fieldName)?.errors?.pattern" class=" text-danger">
                                                                                        Invalid Email Id
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="form-group" *ngIf="item.fieldType == 2" [hidden]="item.isShow == 2" [ngClass]="{'has-asterisk': item.isShow == 1}">
                                                                                <label>{{item.fieldTitle}}</label>
                                                                                <span *ngIf="isEditMode">:&nbsp;{{item.fieldValue}}</span>

                                                                                <select class="form-control" *ngIf="!isEditMode && item.fieldTitle != 'EBlastAddress' && item.fieldTitle != 'Country'" formControlName="{{ item.fieldName }}" [ngClass]="{'has-error': (contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty))}">
                                                                                    <option *ngFor="let blastItem of item.items; let i = index" [value]='blastItem.value'>
                                                                                        {{blastItem.display}}
                                                                                    </option>
                                                                                </select>
                                                                                <p class="smallgreen" *ngIf="item.fieldTitle == 'User'">Changing the user will transfer this contact.</p>
                                                                                <select class="form-control" *ngIf="!isEditMode && item.fieldTitle == 'Country'" formControlName="{{ item.fieldName }}" (change)="changeCountry($event)" [ngClass]="{'has-error': (contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty))}">
                                                                                    <option *ngFor="let item of item.items; let i = index" [value]='item.strValue'>
                                                                                        {{item.display}}
                                                                                    </option>
                                                                                </select>
                                                                                <select class="form-control" *ngIf="!isEditMode && item.fieldTitle == 'EBlastAddress'" formControlName="{{ item.fieldName }}" (change)="blastItem($event.target.value)" [ngClass]="{'has-error': (contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty))}">
                                                                                    <option *ngFor="let blastItem of item.items; let i = index" [value]='blastItem.value'>
                                                                                        {{blastItem.display}}
                                                                                    </option>
                                                                                </select>
                                                                                <span class="validation-error" *ngIf="!isEditMode && item.fieldTitle == 'EBlastAddress'">{{msg_blastaddress}}</span>
                                                                            </div>
                                                                            <!--Check This If Company Creating duplicate while its already Exist-->
                                                                            <div class="form-group" *ngIf="item.fieldType == 4 && item.fieldTitle != 'CompanyName'" [hidden]="item.isShow == 2" [ngClass]="{'has-asterisk': item.isShow == 1}">
                                                                                <label>{{item.fieldTitle}}</label>
                                                                                <span *ngIf="isEditMode">:&nbsp;{{item.fieldValue}}</span>
                                                                                <kendo-autocomplete #autocompleteCon *ngIf="!isEditMode" [data]="companyName" formControlName="{{ item.fieldName }}" valueField="companyName" (valueChange)="selectedCompanyList($event)" (keydown.enter)="companyContactCreate($event?.target?.value)" (keyup)="companyContactSearch($event?.target?.value)" [ngClass]="{'has-error': (contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty))}">
                                                                                    <ng-template kendoAutoCompleteItemTemplate let-dataItem>{{dataItem.companyName}}</ng-template>
                                                                                </kendo-autocomplete>
                                                                            </div>
                                                                            <div class="form-group" *ngIf="item.fieldType == 4 && item.fieldTitle == 'CompanyName'" [ngClass]="{'has-asterisk': item.isShow == 1}">
                                                                                <label>{{item.fieldTitle}}</label>
                                                                                <span *ngIf="isEditMode">:&nbsp;{{item.fieldValue}}</span>
                                                                                <input type="text" class="form-control" formControlName="{{ item.fieldName }}" [ngClass]="{'has-error': (contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty))}" />
                                                                            </div>
                                                                            <div class="form-group" *ngIf="item.fieldType == 5" [hidden]="item.isShow == 2" [ngClass]="{'has-asterisk': item.isShow == 1}">
                                                                                <label>{{item.fieldTitle}}</label>
                                                                                <span *ngIf="isEditMode">:&nbsp;{{item.fieldValue}}</span>
                                                                                <kendo-datetimepicker #dateTimePicker
                                                                                                       placeholder="" *ngIf="!isEditMode" formControlName="{{ item.fieldName }}" [format]="format" class="generic-width-per" [ngClass]="{'has-error': (contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty))}"></kendo-datetimepicker>
                                                                            </div>
                                                                            <div class="custom-toggle">
                                                                                <div class="form-group" *ngIf="item.fieldType == 1" [hidden]="item.isShow == 2" [ngClass]="{'has-asterisk': item.isShow == 1}">
                                                                                    <label>{{item.fieldTitle}}</label>
                                                                                    <span *ngIf="isEditMode">:&nbsp;{{item.fieldValue}}</span>
                                                                                    <label class="switch">
                                                                                        <input type="checkbox" *ngIf="!isEditMode" [id]="item.fieldName" [name]="item.fieldName" formControlName="{{ item.fieldName }}" (change)="onCheckboxChange($event, item.fieldName)" [ngClass]="{'has-error': (contactForm?.get(item.fieldName)?.errors && (contactForm?.get(item.fieldName).touched || contactForm?.get(item.fieldName)?.dirty))}" />
                                                                                        <span class="slider round"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="form-group" *ngIf="item.fieldType == 3" [hidden]="item.isShow == 2 && item.fieldName != 'shareable' && item.fieldName != 'dtModified' && item.fieldName != 'dtCreated'" [ngClass]="{'has-asterisk': item.isShow == 1}">
                                                                                <label>{{item?.fieldTitle}}</label><br />
                                                                                <label *ngIf="item.fieldValue != '0001-01-01T00:00:00' && item.fieldValue != null">{{item.fieldValue | date : dateFormat}}</label>
                                                                                <label *ngIf="item.fieldValue == '0001-01-01T00:00:00' && item.fieldValue != null">{{todaysDate | date : dateFormat}}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="multiple-cards-panel" *ngIf="contactId>0 && !isFromLiveConnect">
                                                        <div class="wraper-main-section">
                                                            <div class="global-card-section">
                                                                <div class="global-header-section">
                                                                    <span> Tags</span>
                                                                    <div class="header-button-panel">
                                                                        <div class="button-wrapper">
                                                                            <div class="svg-icon-panel">
                                                                                <button class="icon-btn" type="button" [title]="getToolTip()" (click)="getTagListByCompany();isShowTagList=true;">
                                                                                    <img style="margin-left:0px" src="../../../../assets/tagbtn.svg" />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="global-body-section lead-body-section">
                                                                    <div class="">
                                                                        <div class="">
                                                                            <div class="form-group" *ngIf="isShowTagList">
                                                                                <label>Tag</label>
                                                                                <kendo-combobox [data]="tagListByCompany"
                                                                                                textField="tagDisplay"
                                                                                                [(ngModel)]="selectedTagId"
                                                                                                [ngModelOptions]="{standalone: true}"
                                                                                                valueField="tagID"
                                                                                                [allowCustom]="true"
                                                                                                [valuePrimitive]="true"
                                                                                                [suggest]="true"
                                                                                                placeholder="Type a Tag"
                                                                                                (valueChange)="selectedTagChangeEvent($event)">
                                                                                    <ng-template kendoComboBoxItemTemplate let-dataItem>
                                                                                        <strong [ngStyle]="{'background-color': (dataItem?.checked) ? '#2DC76D' : ''}">{{dataItem.tagDisplay}}</strong>
                                                                                    </ng-template>
                                                                                </kendo-combobox>
                                                                            </div>
                                                                        </div>
                                                                        <div *ngFor="let item of tagListByOwner">
                                                                            <span>{{item?.tagDisplay}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="multiple-cards-panel" *ngIf="contactId>0 && !isFromLiveConnect">
                                                        <div class="wraper-main-section">
                                                            <div class="global-card-section">
                                                                <div class="global-header-section">
                                                                    <span> Connections</span>
                                                                    <div class="header-button-panel">
                                                                        <div class="button-wrapper">
                                                                            <button type="button" matTooltip="Add" class="icon-btn" (click)="isHideConnectionForm = false;selectedId = -1">
                                                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                                                                <span class="btn-text">Add</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="global-body-section lead-body-section">
                                                                    <div class="">
                                                                        <div class="connnecting-panel" *ngIf="!isHideConnectionForm">
                                                                            <div class="form-group" [ngClass]="{'has-asterisk': true}">
                                                                                <label>Contact</label>
                                                                                <kendo-autocomplete #autocomplete [data]="contactSearchList" valueField="searchText" (valueChange)="getContactId($event,'connection')" placeholder="Search"
                                                                                                    (keyup)="getSearchData($event.target.value,'connection')"></kendo-autocomplete>
                                                                            </div>
                                                                            <div class="form-group" [ngClass]="{'has-asterisk': true}">
                                                                                <label>Relation</label>
                                                                                <input type="text" class="form-control" placeholder="Relation" [(ngModel)]="connectionRelation" [ngModelOptions]="{standalone: true}" #relation="ngModel" required />
                                                                            </div>
                                                                            <div class="top-common-btn">
                                                                                <button class="btn btn-primary" type="button" [disabled]="contactIdForConnection == 0 || (relation.invalid == true)" (click)="createConnection()">Save</button>
                                                                                <button class="btn btn-cancel" (click)="isHideConnectionForm = !isHideConnectionForm" type="button">Hide</button>
                                                                            </div>
                                                                        </div>
                                                                        <div class="global-padding10" *ngFor="let item of contactConnectionList;let id =index">
                                                                            <span class="m-0">
                                                                                <a [routerLink]="['/contact', user?.cLPUserID, item?.contactID]">
                                                                                    {{item?.lastName + ' ' + ',' + ' ' + item?.firstName }}
                                                                                    <span *ngIf="id != selectedId">{{ '(' + item?.relationship + ')'}}</span>
                                                                                </a>
                                                                            </span>
                                                                            <div class="mt-3">
                                                                                <input type="text" class="form-control mb-3" [(ngModel)]="connectionRelation" [ngModelOptions]="{standalone: true}" #relation="ngModel" *ngIf="id ==selectedId" />
                                                                                <button type="button" [hidden]="roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator" class="grid-common-btn ml-0" (click)="editConnection(id,item)" *ngIf="id !=selectedId">
                                                                                    <i class="fa fa-pencil"></i>
                                                                                </button>
                                                                                <button type="button" class="grid-delete-btn margin-left10" (click)="deleteConnectContact(item?.contactID);" *ngIf="id !=selectedId">
                                                                                    <i class="fa fa-trash-alt"></i>
                                                                                </button>
                                                                                <button type="button" class="grid-common-btn" (click)="updateConnection();" *ngIf="id ==selectedId"><i class="fa fa-save"></i></button>
                                                                            </div>
                                                                        </div>
                                                                        <div *ngIf="contactConnectionList?.length == 0">
                                                                            <span>No Connections Found</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bottom-fix-footer" [hidden]="_contactSearchService.showSpinner">
                                            <div class="top-common-btn">
                                                <button class="btn btn-primary" [style.pointer-events]="(showSpinner || isContactUpdated)?'none':''" [hidden]="contactId > 0 ? (roleFeaturePermissions?.create == false && user?.userRole <= eUserRole.Administrator) : (roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator)" [disabled]="showSpinner || isContactUpdated " type="submit">
                                                    <ng-template [ngIf]="buttonTypeOperation!=1">Save</ng-template>
                                                    <ng-template [ngIf]="buttonTypeOperation===1 ">Saving</ng-template>
                                                    &nbsp; <app-loader></app-loader>
                                                </button>
                                                <button type="button" class="btn btn-cancel" (click)="_localService.hideCommonComponentEmit('contact-detail'); _localService.showCommonComp = ''" *ngIf="currentUrl != 'contact-create' && !isFromLiveConnect && isFromHandle != 'true'">Cancel</button>
                                                <button type="button" class="btn btn-cancel" (click)="goToContact()" *ngIf="isFromLiveConnect"> Cancel</button>
                                                <button type="button" class="btn btn-cancel" (click)="hideForHandle()" *ngIf="isFromHandle == 'true'"> Cancel</button>
                                                <button class="btn btn-cancel" [routerLink]="['/contacts']" *ngIf="currentUrl == 'contact-create'">Cancel</button>
                                                <button type="button" class="btn btn-danger float-none" [hidden]="(roleFeaturePermissions?.delete == false && user?.userRole <= eUserRole.Administrator) || isFromLiveConnect" [disabled]="showSpinner || isContactUpdated " data-toggle="modal" data-target="#contactDeleteModal" *ngIf="contactId >0"><i class="fa " [ngClass]="isContactUpdated ? 'fa-spinner fa-pulse' : 'fa-save'"></i> Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-right-panel" *ngIf="isReferred">
                <div class="global-padding10" *ngIf="isReferred">
                    <contact-referred *ngIf="user" [loggedUser]="user" [referralId]="referralId" [isNewShow]="false"></contact-referred>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="contactConfirmModal" tabindex="-1" role="dialog" aria-labelledby="ConfirmModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h4 class="modal-title modal-common-title">Confirmation</h4>
            </div>
            <div class="modal-body modal-common-body">
                <h2>{{confirmText}}</h2>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" class="btn-lg btn-danger" (click)="confirmOperation(true)">Save</button>
                <button type="button" class="btn-lg btn-default" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="contactDeleteModal" tabindex="-1" role="dialog" aria-labelledby="contactDeleteMdl" aria-hidden="true">
    <div class="modal-dialog  modal-common-dialog" role="document">
        <div class="modal-content modal-common-content">
            <div class="modal-header modal-common-background">
                <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
            </div>
            <div class="modal-body modal-common-body">
                <h6>Are you sure you want to delete {{this.contactFields?.firstName }} {{ this.contactFields?.lastName}}?</h6>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="deleteContacts()" data-dismiss="modal" class="btn btn-primary">
                    <ng-template [ngIf]="buttonTypeOperation!=0">Confirm</ng-template>
                    <ng-template [ngIf]="buttonTypeOperation===0">Deleting &nbsp; <span> &nbsp;<i wrapper> </i></span></ng-template>

                </button>
                <button type="button" class="btn btn-cancel" data-dismiss="modal"> Close</button>
            </div>
        </div>
    </div>
</div>
