import { DatePipe, formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { filterAnimation, pageAnimations } from '../../../../animations/page.animation';
import { ApppointmentResponse, Appt, ApptResponse, ShareableApptUI, UpdateAppt } from '../../../../models/appt.model';
import { CLPUser, UserResponse } from '../../../../models/clpuser.model';
import { eApptCategory, eButtonActions, eFeatures, eUserRole } from '../../../../models/enum.model';
import { SimpleResponse } from '../../../../models/genericResponse.model';
import { AppointmenTypeCodeByCategory, LeadApptFilters } from '../../../../models/lead.model';
import { RoleFeaturePermissions } from '../../../../models/roleContainer.model';
import { AppointmentSettingService } from '../../../../services/appointmentSetting.service';
import { DashboardService } from '../../../../services/dashboard.service';
import { GlobalService } from '../../../../services/global.service';
import { LeadSettingService } from '../../../../services/leadSetting.service';
import { NotificationService } from '../../../../services/notification.service';
import { LocalService } from '../../../../services/shared/local.service';
import { UtilityService } from '../../../../services/shared/utility.service';
declare var $: any;
@Component({
    selector: 'lead-appt',
    templateUrl: './lead-appt.component.html',
    styleUrls: ['./lead-appt.component.css'],
    animations: [pageAnimations, filterAnimation]
})
/** lead-appt component*/
export class LeadApptComponent {
    buttonTypeOperation: eButtonActions = eButtonActions.None;
    @Input() loggedUser: CLPUser;
    @Input() ownerId: number = 0;
    @Input() isLeadTask: boolean = false;
    @Input() isContactAppt: boolean = false;
    @Input() contactId: number = 0;
    @Input() apptId: number = 0;
    currentApptToDelete: any;
    apptForm: FormGroup;
    private encryptedUser: string = '';
    public datePickerformat = "MM/dd/yyyy HH:mm a";
    dateFormat: string = "MM/dd/yyyy";
    apptFilterResponse: LeadApptFilters;
    roleFeaturePermissions: RoleFeaturePermissions;
    @Input() apptTypeCodes: AppointmenTypeCodeByCategory[];
    userResponse: UserResponse;
    appt: Appt = <Appt>{};
    apptList: Appt[];
    isApptSubmit: boolean = false;
    showSpinner: boolean = false;
    //Animation
    @HostBinding('@pageAnimations') public animatePage = true;
    showAnimation = -1;
    showApptForm: boolean = false;
    isShowApptModal: boolean = false;
    shareableAppt: ShareableApptUI;
    @ViewChild('closeInputButton') closeInputButton: ElementRef;

    @Input() isShowHeader: boolean = true;
    @Output() isApptList = new EventEmitter<boolean>();
    invalidStartDate: boolean;
    contactApptList: Appt[];
    proposalID
    blnIsIH: boolean = false;
    //Animation
    constructor(private fb: FormBuilder,
        private _router: Router,
        private datepipe: DatePipe,
        private _utilityService: UtilityService,
        public _localService: LocalService,
        public _leadSettingSrvc: LeadSettingService,
        public _apptSettingSrvc: AppointmentSettingService,
        private _dashboardSvc: DashboardService,
        private _globalService: GlobalService,
        public notifyService: NotificationService
    ) {
    }

    ngOnInit() {
        this.apptForm = this.prepareApptForm();
        this.apptForm.reset();
        this._localService.getPristineForm().subscribe(res => {
            this._localService.genericFormValidationState(this.apptForm);
        });

        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                this.authenticateR().then(async () => {
                    if (!isNullOrUndefined(this.loggedUser)) {
                        this.dateFormat = this.loggedUser.dateFormat;
                        this.apptForm.get('apptDateTime').setValue(new Date());
                        if (this.isContactAppt) {
                            await this.getContactApptList(this.contactId);
                            this.getApptList();
                        }
                        else
                            this.getApptList();
                    }
                    else
                        this._router.navigate(['/login']);
                });
            }
            else
                this._router.navigate(['/login']);
        });
    }

    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.None)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.loggedUser = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            if (this.loggedUser?.userRole <= eUserRole.Administrator) {
                                if (this.roleFeaturePermissions?.view == false)
                                    this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                            }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lead-appt.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    private prepareApptForm(): FormGroup {
        return this.fb.group({
            apptDateTime: new FormControl(new Date(), [Validators.required]),
            isPhoneCall: new FormControl(false),
            isCompleted: new FormControl(false),
            subject: new FormControl('', [Validators.required]),
            apptType: new FormControl(0)
        });
    }

    get apptFrm() {
        return this.apptForm.controls;
    }

  

    apptFormSubmit() {
        this._localService.validateAllFormFields(this.apptForm);
        if (this.apptForm.valid) {
            if (this.apptForm.get('apptDateTime').value) {
                let now = new Date()
                let date1 = formatDate(now, 'yyyy-MM-dd HH:mm:ss', 'en_US');
                let date2 = formatDate(this.apptForm.get('apptDateTime').value, 'yyyy-MM-dd HH:mm:ss', 'en_US');

                if (date1 > date2) {
                    this.invalidStartDate = true
                    return
                }
                else {
                    this.invalidStartDate = false
                }
            }
            this.apptForm.markAsPristine();
            this.createAppt();
        }
    }

    createAppt() {
        this.copyDataObjectToApptObject();
        this.isApptSubmit = true;
        var ownerId = this.isContactAppt ? 0 : this.ownerId;
        this.buttonTypeOperation = eButtonActions.Save;
        this._leadSettingSrvc.leadQuickApptSave(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, ownerId, this.appt, this.contactId)
            .then(async (result: ApptResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result.messageBool == true) {
                        let msg = this.apptId > 0 ? "updated" : "created";
                        this.notifyService.showSuccess("Appointment " + msg + " successfully", "", 5000);
                        if (this.isContactAppt)
                            this.getContactApptList(this.contactId);
                        else
                            this.getApptList();
                        this.apptId = 0;
                        this.apptForm.reset();
                        this.prepareApptForm();
                        this.apptForm.get('apptDateTime').setValue(new Date());
                        this.showApptForm = false;
                        this._localService.handledEventEmit(true);
                    } else {
                        this.notifyService.showError(result?.messageString, "", 5000);
                    }

                }
                this.buttonTypeOperation = eButtonActions.None;
                this.isApptSubmit = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.isApptSubmit = false;
                this.buttonTypeOperation = eButtonActions.None;
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    onCloseAppt() {
        this.apptForm.reset();
        this._localService.hideCommonComponentEmit('appt');
        this._localService.showCommonComp = '';
    }

    copyDataObjectToApptObject() {
        this.appt.apptID = this.apptId ? this.apptId : 0
        if (this.isContactAppt)
            this.appt.contactTypeCode = this.apptForm.controls.apptType.value ? this.apptForm.controls.apptType.value : 0;
        else
            this.appt.leadTypeCode = this.apptForm.controls.apptType.value ? this.apptForm.controls.apptType.value : 0;
        this.appt.strApptStartTime = this.datepipe.transform(this.apptForm.controls.apptDateTime.value, 'MM/dd/yyyy HH:mm:ss');
        this.appt.apptEndTime = this.apptForm.controls.apptDateTime.value;
        this.appt.reminderTime = this.apptForm.controls.apptDateTime.value;
        this.appt.isPhoneCall = this.apptForm.controls.isPhoneCall.value == null ? false : this.apptForm.controls.isPhoneCall.value;
        this.appt.subject = this.apptForm.controls.subject.value;
        this.appt.status = this.apptForm.controls.isCompleted.value == true ? 2 : 0;
        if (this.apptId == 0) {
            this.appt.cLPUserID = this.loggedUser ? this.loggedUser.cLPUserID : 0;
            this.appt.cLPCompanyID = this.loggedUser ? this.loggedUser.cLPCompanyID : 0;
            this.appt.leadID = this.isContactAppt ? 0 : this.ownerId;
            this.appt.contactID = this.contactId ? this.contactId : 0;
            this.appt.reminderCLP = true;
            this.appt.reminderEmail = true;
            this.appt.category = this.isContactAppt ? 2 : 3;
        }
    }

    getApptList() {
        this.showSpinner = true;
        const ownerId = this.isLeadTask ? this.ownerId : 0;
        this._leadSettingSrvc.leadApptListGet(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, ownerId)
            .then(async (result: ApptResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    if (this.contactApptList?.length > 0) {
                        this.apptList = [...response?.appts, ...this.contactApptList]
                    }
                    else {
                        this.apptList = response?.appts;
                    }
                    if (this.apptList.length == 0) {
                        this.showApptForm = true;
                        this.isApptList.emit(false);
                    }
                    else {
                        this.showApptForm = false;
                        this.isApptList.emit(true);
                    }
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContactApptList(contactId) {
        this.showSpinner = true;
        const eApp: eApptCategory = eApptCategory.Contact;
        const ownerId = this.isContactAppt ? contactId : 0;
        return this._dashboardSvc.quickAppt_Get(this.encryptedUser, this.loggedUser.cLPUserID, this.loggedUser.cLPCompanyID, eApp, ownerId)
            .then(async (result: ApptResponse) => {
                if (!isNullOrUndefined(result)) {
                    this.contactApptList = UtilityService.clone(result?.appts);
                    this.apptList = this.contactApptList
                    if (this.apptList?.length == 0) {
                        this.showApptForm = true;
                        this.isApptList.emit(false);
                    }
                    else {
                        this.showApptForm = false;
                        this.isApptList.emit(true);
                    }
                    this.showSpinner = false;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    getType(value) {
        const apptType = this.apptTypeCodes?.filter(x => x.apptTypeCode == value)[0];
        return value == 0 ? '-None Selected-' : apptType?.display ? apptType?.display : '';
    }

    apptGridCRUD(type, row, index) {
        this.currentApptToDelete = row.subject;
        if (type) {
            this.apptId = row?.apptID;
            switch (type) {
                case "edit":
                    this.showApptForm = true;
                    this.apptForm.patchValue({
                        apptDateTime: new Date(row.apptStartTime),
                        isPhoneCall: row.isPhoneCall,
                        isCompleted: row.status,
                        subject: row.subject,
                        apptType: this.isContactAppt ? row.contactTypeCode : row.leadTypeCode,
                    });
                    break;
                case "delete":
                    $('#modalDeleteAppt').modal('show');
                    break;
            }
        }
    }

    addNewHandler() {
        this.apptId = 0;
        this.apptForm.reset();
        this.prepareApptForm();
        this.apptForm.get('apptDateTime').setValue(new Date());
        this.showApptForm = true;
    }

    checkDate(dt) {
        return (new Date(dt) < new Date()) ? true : false;
    }

    onCancel() {
        this.apptForm.reset();
    }

    async appointmentPopUp(apptData, isNewAppt: boolean = false) {
        if (isNewAppt) {
            if (this.loggedUser.cLPCompanyID == 1226) {
                if (this.loggedUser.cLPUserID != 3893) {
                    this.blnIsIH = true;
                }
            }
            this.shareableAppt = {
                apptId: 0,
                rApptID: apptData?.rApptID,
                leadContactId: 0,
                cat: this.isContactAppt ? eApptCategory.Contact : eApptCategory.Lead,
                ownerId: this.isContactAppt ? this.contactId : this.ownerId,
                currentUrlAppt: 'lead-appt',
                isNewTab: true,
            };
            this.isShowApptModal = true;
            $("#quickApptModalLeadAppt").modal('show');
        }
        else {
            await this._apptSettingSrvc.apptLoad(this.encryptedUser, apptData?.apptID, this.loggedUser?.cLPUserID, this.loggedUser?.cLPCompanyID).then
                (async (result: ApppointmentResponse) => {
                    if (!isNullOrUndefined(result)) {
                        let appt = UtilityService.clone(result);
                        if (!isNullOrUndefined(appt)) {
                            if (appt.appt.cLPCompanyID == 1226) {
                                if (appt.appt.cLPUserID != 3893) {
                                    this.blnIsIH = true;
                                    if (appt.appt.proposalID > 0) {
                                        this.blnIsIH = false
                                    }
                                }
                            }
                        }
                    }
                }).catch((err: HttpErrorResponse) => {
                    this.showSpinner = false;
                    console.log(err);
                    this._utilityService.handleErrorResponse(err);
                });
            this.shareableAppt = {
                apptId: apptData?.apptID,
                rApptID: apptData?.rApptID,
                leadContactId: 0,
                cat: apptData?.category,
                ownerId: apptData?.category == eApptCategory.Contact ? apptData?.contactID : apptData?.category == eApptCategory.Lead ? apptData?.leadID : 0,
                currentUrlAppt: 'lead-appt',
                isNewTab: true,
            };
            this.isShowApptModal = true;
            $("#quickApptModalLeadAppt").modal('show');
        }
    }

    hideQuickApptModal(updateAppt?: UpdateAppt) {
        if (!isNullOrUndefined(updateAppt)) {
            this.closeModalApptModal();
            if (updateAppt.isSave)
                this.isContactAppt ? this.getContactApptList(this.contactId) : this.getApptList();
        }
        this.isShowApptModal = false;
        this.shareableAppt = null;
        $("#quickApptModalLeadAppt").modal('hide');
    }

    closeModalApptModal() {
        const inputElement: HTMLElement = this.closeInputButton.nativeElement as HTMLElement;
        inputElement.click();
    }

    deleteAppt() {
        const apptId = this.apptId;
        this._apptSettingSrvc.apptDelete(this.encryptedUser, apptId, this.loggedUser?.cLPUserID, this.loggedUser?.cLPCompanyID)
            .then(async (result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    const response = UtilityService.clone(result);
                    this.notifyService.showSuccess("Appt deleted successfully", "", 5000);
                    this.getApptList();
                    this.hideDeleteAppt();
                    this._localService.handledEventEmit(true);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.apptId = 0;
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    hideDeleteAppt() {
        this.apptId = 0;
        $('#modalDeleteAppt').modal('hide');
    }
}
