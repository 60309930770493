import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { SimpleResponse, UserDD } from '../models/genericResponse.model';
import { IHApptSetterCLPUserResponse } from '../models/ihChange.model';
import { delayedRetryHttp } from './shared/delayedRetry';
import { UtilityService } from './shared/utility.service';

@Injectable({
  providedIn: 'root'
})
export class IhChangeRequestService {
  private baseUrl: string;
  private api: string = "api/IHChangeRequest";
  constructor(private httpClient: HttpClient, @Inject('BASE_URL') _baseUrl: string, private _utilityService: UtilityService) {
    this.baseUrl = _baseUrl + this.api;
  }

  async apptSetterCreate(encryptedUser: string, apptSetterId: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse[]>(`${this.baseUrl}/IHApptSetter_Create/${apptSetterId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => {
        this._utilityService.handleErrors(err, null, "r -  " + encryptedUser + " , " + apptSetterId + "apptSetterId ", encryptedUser, "IhChangeRequestService", "apptSetterCreate");
      });
    return a;
  }

  async apptSetterUpdate(encryptedUser: string, apptSetterId: number, isUsedInReport: boolean): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse[]>(`${this.baseUrl}/IHApptSetter_UpdateisUsedInReport/${apptSetterId}/${isUsedInReport}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => {
        this._utilityService.handleErrors(err, null, "r -  " + encryptedUser + " , " + apptSetterId + "apptSetterId " + " , " + isUsedInReport + "isUsedInReport", encryptedUser, "IhChangeRequestService", "apptSetterUpdate");
      });
    return a;
  }

  async apptSetterDelete(encryptedUser: string, apptSetterId: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse[]>(`${this.baseUrl}/IHApptSetter_Delete/${apptSetterId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => {
        this._utilityService.handleErrors(err, null, "r -  " + encryptedUser + " , " + apptSetterId + "apptSetterId ", encryptedUser, "IhChangeRequestService", "apptSetterDelete");
      });
    return a;
  }

  async apptSetterCLPCreate(encryptedUser: string, apptSetterId: number, selectedUserId: number): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse[]>(`${this.baseUrl}/IHApptSetterCLPUser_Create/${apptSetterId}/${selectedUserId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => {
        this._utilityService.handleErrors(err, null, "r -  " + encryptedUser + " , " + apptSetterId + "apptSetterId " + " , " + selectedUserId + "selectedUserId ", encryptedUser, "IhChangeRequestService", "apptSetterCLPCreate");
      });
    return a;
  }

  async apptSetterCLPDelete(encryptedUser: string, apptSetterId: number, selectedUserId: boolean): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse[]>(`${this.baseUrl}/IHApptSetterCLPUser_Delete/${apptSetterId}/${selectedUserId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => {
        this._utilityService.handleErrors(err, null, "r -  " + encryptedUser + " , " + apptSetterId + "apptSetterId " + " , " + selectedUserId + "selectedUserId ", encryptedUser, "IhChangeRequestService", "apptSetterCLPDelete");
      });
    return a;
  }

  async getApptSetterCLPUserList(encryptedUser: string, apptSetterId: number): Promise<IHApptSetterCLPUserResponse | void> {
    const a = await this.httpClient
      .get<IHApptSetterCLPUserResponse>(`${this.baseUrl}/IHApptSetter_GetCLPUserList/${apptSetterId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => {
          this._utilityService.handleErrors(err, null, "r -  " + encryptedUser + " , " + apptSetterId + "apptSetterId ", encryptedUser, "IhChangeRequestService", "getApptSetterCLPUserList");
      });
    return a;
  }

  async loadDDs(encryptedUser, clpUserID, IsPatentPlus): Promise<any> {
    const a = await this.httpClient
      .get<any>(`${this.baseUrl}/LoadDDs/${clpUserID}/${IsPatentPlus}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => {
        this._utilityService.handleErrors(err, null, "r -  " + encryptedUser + " , " + clpUserID + "clpUserID ", encryptedUser, "IhChangeRequestService", "loadDDs");
      });
    return a;
  }

  async iHTransferContactContracts(encryptedUser, contactID, selectedUserID): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/IHTransferContactContracts/${contactID}/${selectedUserID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => {
        this._utilityService.handleErrors(err, null, "r -  " + encryptedUser + " , " + contactID + "contactID ", encryptedUser, "IhChangeRequestService", "iHTransferContactContracts");
      });
    return a;
  }

  async transferCompaign(encryptedUser, contactID, selectedUserID): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/TransferCampaigns/${contactID}/${selectedUserID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => {
        this._utilityService.handleErrors(err, null, "r -  " + encryptedUser + " , " + contactID + "contactID ", encryptedUser, "IhChangeRequestService", "transferCompaign");
      });
    return a;
  }

  async iHAddLable(encryptedUser, contactID): Promise<Blob> {
    const a = await this.httpClient
      .get<Blob>(`${this.baseUrl}/IHAddLable/${contactID}`, {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => {
        this._utilityService.handleErrors(err, null, "r -  " + encryptedUser + " , " + contactID + "contactID ", encryptedUser, "IhChangeRequestService", "iHAddLable");
      });
    return a;
  }

  async confirmMarkAsDead(encryptedUser, cLPUserID, routeContactId, cLPCompanyID, markDeadPnl): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/ConfirmMarkAsDead/${cLPUserID}/${routeContactId}/${cLPCompanyID}/${markDeadPnl}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "cLPCompanyID - " + cLPCompanyID, encryptedUser, "IhChangeRequestService", "confirmMarkAsDead"); });
    return a;
  }

  async iHBIPFormPost(encryptedUser, strLinkCode: string, contactID): Promise<SimpleResponse | void> {
    const a = await this.httpClient
      .get<SimpleResponse>(`${this.baseUrl}/IHBIPFormPost/${strLinkCode}/${contactID}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + encryptedUser
        })
      }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "contactID - " + contactID + "," + "strLinkCode - " + strLinkCode, encryptedUser,"IhChangeRequestService", "iHBIPFormPost"); });
    return a;
    }


    async addToIHEventLog(encryptedUser, routeContactId: number, cLPUserID: number, cLPCompanyID: number, strLog: string): Promise<SimpleResponse | void> {
        const a = await this.httpClient
            .get<SimpleResponse>(`${this.baseUrl}/AddToIHEventLog/${routeContactId}/${cLPUserID}/${cLPCompanyID}/${strLog}`, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + encryptedUser
                })
            }).pipe(delayedRetryHttp()).toPromise().catch(err => { this._utilityService.handleErrors(err, null, "r - " + encryptedUser + "," + "routeContactId - " + routeContactId + "," + "cLPUserID - " + cLPUserID + "cLPCompanyID - " + cLPCompanyID , encryptedUser, "IhChangeRequestService", "iHBIPFormPost"); });
        return a;
    }
}
