import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { ConfigDetails } from '../../../models/appConfig.model';
import { Appt } from '../../../models/appt.model';
import { CLPUser } from '../../../models/clpuser.model';
import { ClpCompany, CompanyResponse } from '../../../models/company.model';
import { eApptStatus, eContactTxtMsgStatus, eLiveConnectItemActionStatus, eLiveConnectItemObjectType, eMobileBlockType, eSAMFieldStatus } from '../../../models/enum.model';
import { SimpleResponse } from '../../../models/genericResponse.model';
import { LiveConnectItem } from '../../../models/live-connect-item.model';
import { LiveConncetApptLoadResponse, LiveConnectContactLoadResponse } from '../../../models/live-connect.model';
import { AccountSetupService } from '../../../services/accountSetup.service';
import { CMContractService } from '../../../services/cm-contractservice';
import { ContactService } from '../../../services/contact.service';
import { GlobalService } from '../../../services/global.service';
import { LiveConnectService } from '../../../services/live-connect.service';
import { NotificationService } from '../../../services/notification.service';
import { AppconfigService } from '../../../services/shared/appconfig.service';
import { LocalService } from '../../../services/shared/local.service';
import { UtilityService } from '../../../services/shared/utility.service';

@Component({
    selector: 'lc-appt',
    templateUrl: './lc-appt.component.html',
    styleUrls: ['./lc-appt.component.css']
})
/** lc-appt component*/
export class LcApptComponent {
    showSpinner: boolean = false;
    encryptedUser: string = "";
    progressBarstring: string = "";
    eApptStatus = eApptStatus;
    eMobileBlockType = eMobileBlockType;
    @Output() openContact = new EventEmitter<boolean>(false);
    eContactTxtMsgStatus = eContactTxtMsgStatus;
    eSAMFieldStatus = eSAMFieldStatus;
    @Input() liveConnectItem: LiveConnectItem = <LiveConnectItem>{};
    liveConnectContactViewResponse: LiveConnectContactLoadResponse;
    @Input() user: CLPUser;
    liveConnectApptViewResponse: LiveConncetApptLoadResponse;
    isConfirm: boolean = false;
    confirmMsg: string = "";
    isShowCustomAction: boolean = false;
    isConfirmOptOut: boolean = false;
    apptForm: FormGroup;
    isEditMode: boolean = false;
    isShowOptOut: boolean = false;
    isShowOptIn: boolean = false;
    apptData: Appt = <Appt>{};
    bcmSite: string;
    @Output() openTxtMsg = new EventEmitter<boolean>(false);
    @Output() openNote = new EventEmitter<boolean>(false);
    @Output() openEmail = new EventEmitter<boolean>(false);
    @Output() openMakeCall = new EventEmitter<boolean>(false);
    clpCompanyData: ClpCompany;
    constructor(private datePipe: DatePipe,
        private _utilityService: UtilityService,
        private _router: Router,
        private _globalService: GlobalService,
        private _contractService: CMContractService,
        private fb: FormBuilder, private _localService: LocalService, private _notifyService: NotificationService, private _liveConnectSrvc: LiveConnectService, private _contactService: ContactService, private _appConfigService: AppconfigService, private _accountSetupService: AccountSetupService) {
    }

    ngOnInit() {
        this.apptForm = this.prepareApptForm();
        this._globalService.getToken((token) => {
            if (token) {
                this.encryptedUser = token;
                if (this.user) {
                    this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                    this.loadCompany();
                   
                }
                else {
                    this._router.navigate(['/login']);
                }
            }
            else {
                this._router.navigate(['/login']);
            }
        })
    }

    //   async getContactAPISetup() {
    //    this._localService.isMenu = false;
    //    this._localService.isFooter = false;
    //    this.liveConnectItem = <LiveConnectItem>{};
    //       this.liveConnectItem.objectID = this.liveConnectApptViewResponse.objContactInfo.contactID;
    //    this.liveConnectItem.liveConnectCLPUserID = 0;
    //    this.liveConnectItem.liveConnectID = 0;
    //       this.liveConnectItem.contactID = this.liveConnectApptViewResponse.objContactInfo.contactID;
    //    this.liveConnectItem.objectType = 0;
    //       await this._liveConnectSrvc.ContactAPISetup(this.encryptedUser, this.liveConnectApptViewResponse.objContactInfo.contactID, 0, 0, 0)
    //        .then(async (result: any) => {
    //            if (!isNullOrUndefined(result)) {
    //                if (result?.messageBool) {
    //                    this.liveConnectContactViewResponse = UtilityService.clone(result);

    //                }
    //                else
    //                    this.confirmMsg = result?.messageString;
    //            }
    //            this.showSpinner = false;

    //        })
    //        .catch((err: HttpErrorResponse) => {
    //            this._globalService.error("handle-reply.onOptinVerbal", err.message, null, 'cLPUserID ' + this.user?.cLPUserID + 'contactID ' + this.liveConnectItem?.contactID );
    //            this._utilityService.handleErrorResponse(err);
    //        });
       
    //}

    async loadCompany() {
        this.showSpinner = true;
        await this._accountSetupService.loadCompany(this.encryptedUser, this.user?.cLPCompanyID)
            .then(async (result: CompanyResponse) => {
                if (result) {
                    this.clpCompanyData = UtilityService.clone(result.company);
                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    private prepareApptForm(): FormGroup {
        return this.fb.group({
            dtstartDay: new FormControl(new Date(),[Validators.required]),
            notes: new FormControl("")
        });
        
    }

    OpenContract() {
        this._appConfigService.getAppConfigValue(this.encryptedUser, "bcmsite")
            .then(async (result: ConfigDetails) => {
                if (result) {
                    this.bcmSite = result?.configValue;
                }
            });
        window.open(this.bcmSite + 'cm-contract/' + this.liveConnectItem?.contactID + '?r=' + this.encryptedUser + '&isAI=true');
    }
    async handleLiveConnectItem(liveConnectItemObj: LiveConnectItem, status: eLiveConnectItemActionStatus) {
        this.showSpinner = true;
        this._liveConnectSrvc.handle_LiveConnectItem(this.encryptedUser, liveConnectItemObj, status, 0, this.user?.cLPUserID)
            .then(async (result: any) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this.liveConnectApptViewResponse = UtilityService.clone(result);
                        this.GetAgreementProgressBarByContactID();
                        this.apptForm.get("dtstartDay").setValue(new Date(this.liveConnectApptViewResponse?.appt?.apptStartTime));
                        this.apptForm.get("notes").setValue(this.liveConnectApptViewResponse?.appt?.notes);
                        this.setupForOptOut();
                        if (this.liveConnectApptViewResponse?.eBType == eMobileBlockType.Block)
                            this.confirmMsg = "Voice/Text communication for this mobile is <i>Blocked</i>.";
                    }
                    else
                        this.confirmMsg = result?.messageString;
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-appt.handleLiveConnectItem", err.message, liveConnectItemObj, 'status ' + status + 'toPinnedClpUserID ' + 0 + 'cLPUserID ' + this.user?.cLPUserID);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

    goToContact() {
        this.openContact.emit(true);
        
    }


    onBlock() {
        if (!this.isConfirm) {
            this.confirmMsg = "Please confirm Voice/Text <i>Block</i> for this mobile number.";
            this.isConfirm = true;
        } else {
            this._liveConnectSrvc.blockMobile(this.encryptedUser, this.user?.cLPUserID, this.liveConnectItem?.liveConnectCLPUserID, this.liveConnectItem?.contactID, true)
                .then((result: SimpleResponse) => {
                    if (!isNullOrUndefined(result)) {
                        if (result?.messageBool)
                            this.confirmMsg = result?.messageString;
                        else
                            this._notifyService.showError(result?.errorMsg, "", 3000);
                        this.isConfirm = false;
                        this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("lc-appt.handleLiveConnectItem", err.message, null, 'cLPUserID ' + this.user?.cLPUserID + 'this.liveConnectItem?.liveConnectCLPUserID ' + this.liveConnectItem?.liveConnectCLPUserID + 'contactID ' + this.liveConnectItem?.contactID + 'isBlock '+ true);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }


    setupForOptOut() {
        switch (this.liveConnectApptViewResponse?.eContactTxtStat) {
            case eContactTxtMsgStatus.xHardOptOut:
                this.confirmMsg = "This contact must OPT-IN to receive text messages.";
                this.isShowOptOut = false;
                break;
            case eContactTxtMsgStatus.UserOptOut:
                this.confirmMsg = "This contact or user must OPT-IN to receive text messages.";
                this.isShowOptOut = false;
                this.isShowOptIn = true;
                break;
            default:
                this.isShowOptOut = true;
                break;
        }
    }


    OptOutOptIn() {
        if (this.isShowOptOut) {
            if (!this.isConfirmOptOut) {
                this.confirmMsg = "Please confirm <i>Opt Out</i> for this mobile number.";
                this.isConfirmOptOut = true;
            } else {
                this._liveConnectSrvc.optOutOptIn(this.encryptedUser, this.user?.cLPUserID, this.liveConnectItem?.liveConnectCLPUserID, this.liveConnectApptViewResponse?.appt.contactID, true)
                    .then((result: SimpleResponse) => {
                        if (!isNullOrUndefined(result)) {
                            if (result?.messageBool) {
                                this.confirmMsg = result?.messageString;
                                this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                            }
                            else
                                this._notifyService.showError(result?.errorMsg, "", 3000);
                            this.isConfirmOptOut = false;
                        }
                    })
                    .catch((err: HttpErrorResponse) => {
                        this._globalService.error("lc-appt.optOutOptIn", err.message, null, 'cLPUserID ' + this.user?.cLPUserID + 'liveConnectCLPUserID ' + this.liveConnectItem?.liveConnectCLPUserID + 'contactID ' + this.liveConnectApptViewResponse?.appt.contactID+ 'isoptout '+ true);
                        this._utilityService.handleErrorResponse(err);
                    });
            }
        } else {
            this._liveConnectSrvc.optOutOptIn(this.encryptedUser, this.user?.cLPUserID, this.liveConnectItem?.liveConnectCLPUserID, this.liveConnectApptViewResponse?.appt.contactID, false)
                .then((result: SimpleResponse) => {
                    if (!isNullOrUndefined(result)) {
                        if (result?.messageBool) {
                            this.confirmMsg = result?.messageString;
                            this.isShowOptIn = false;
                            this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                        }
                        else
                            this._notifyService.showError(result?.errorMsg, "", 3000);
                        this.isConfirmOptOut = false;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("lc-appt.optOutOptIn", err.message, null, 'cLPUserID ' + this.user?.cLPUserID + 'liveConnectCLPUserID ' + this.liveConnectItem?.liveConnectCLPUserID + 'contactID ' + this.liveConnectApptViewResponse?.appt.contactID + 'isoptout ' + false);
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    onSelfGuided() {
        this._liveConnectSrvc.sendSGVIP(this.encryptedUser, this.liveConnectApptViewResponse?.defaultSGVIPID, this.liveConnectItem?.contactID, this.liveConnectItem?.liveConnectCLPUserID, this.liveConnectItem?.liveConnectID)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool)
                        this._notifyService.showSuccess("Self-Guided Slidecast text sent to contact.", "", 3000);
                    else
                        this._notifyService.showError(result?.messageString, "", 3000);
                    this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-appt.onSelfGuided", err.message, null, 'defaultSGVIPID ' + this.liveConnectApptViewResponse?.defaultSGVIPID + 'contactID ' + this.liveConnectItem?.contactID + 'liveConnectCLPUserID ' + this.liveConnectItem?.liveConnectCLPUserID + 'liveConnectID ' + this.liveConnectItem?.liveConnectID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    updateAppt() {
        if (this.apptForm.invalid) return;
        this.apptData = this.liveConnectApptViewResponse?.appt;
        this.apptData.strApptStartTime = this.datePipe.transform(this.apptForm.controls.dtstartDay.value, 'MMddyyyy hhmmssa');
        this.apptData.notes = this.apptForm.controls.notes.value;
        this._liveConnectSrvc.lcApptSave(this.encryptedUser, this.apptData, this.user?.cLPUserID, 30, this.liveConnectItem?.liveConnectID, this.liveConnectItem?.liveConnectCLPUserID)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool) {
                        this._notifyService.showSuccess(result?.messageString, "", 3000);
                        this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                        this.isEditMode = false;
                    }
                    else
                        this._notifyService.showError(!isNullOrUndefined(result?.errorMsg) ? result?.errorMsg:'Something went wrong.', "", 3000);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-appt.updateAppt", err.message, this.apptData, 'cLPUserID ' + this.user?.cLPUserID + 'apptLenght ' + 30 + 'liveConnectID ' + this.liveConnectItem?.liveConnectID + 'liveConnectCLPUserID ' + this.liveConnectItem?.liveConnectCLPUserID );
                this._utilityService.handleErrorResponse(err);
            });
    }
    async GetAgreementProgressBarByContactID() {
        await this._contractService.CMMember_GetAgreementProgressBarByContactID(this.encryptedUser, this.user?.cLPUserID, this.liveConnectApptViewResponse.objContactInfo.contactID, this.user?.cLPCompanyID).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response.messageString != "") {
                        this.progressBarstring = response.messageString;

                    }
                    
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("CMMember_GetAgreementProgressBarByContactID", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    updateAppStatus(status: eApptStatus) {
        this._liveConnectSrvc.apptUpdateStatus(this.encryptedUser, this.liveConnectItem.objectID, status, this.liveConnectItem?.liveConnectID, this.liveConnectItem?.liveConnectCLPUserID)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool)
                        this._notifyService.showSuccess(result?.messageString, "", 3000);
                    else
                        this._notifyService.showError(result?.errorMsg, "", 3000);
                    this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-appt.updateAppStatus", err.message, null, 'objectID ' + this.liveConnectItem.objectID + 'status ' + status + 'liveConnectID ' + this.liveConnectItem?.liveConnectID + 'liveConnectCLPUserID ' + this.liveConnectItem?.liveConnectCLPUserID );
                this._utilityService.handleErrorResponse(err);
            });
    }

    apptPauseProcess() {
        this._liveConnectSrvc.apptPauseProcess(this.encryptedUser, this.liveConnectItem.objectID)
            .then((result: SimpleResponse) => {
                if (!isNullOrUndefined(result)) {
                    if (result?.messageBool)
                        this._notifyService.showSuccess(result?.messageString, "", 3000);
                    else
                        this._notifyService.showError(result?.errorMsg, "", 3000);
                    this.handleLiveConnectItem(this.liveConnectItem, eLiveConnectItemActionStatus.Open);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("lc-appt.apptPauseProcess", err.message, null, 'objectID ' + this.liveConnectItem.objectID);
                this._utilityService.handleErrorResponse(err);
            });
    }

    sendSms() {
        this.openTxtMsg.emit(true);
    }

    addNote() {
        this.openNote.emit(true);
    }

    sendEmail() {
        this.openEmail.emit(true);
    }

    makeCall() {
        this.openMakeCall.emit(true);
    }

    launchSlidecast() {
        var link = 'https://devvip.salesoptima.com/?c=' + this.liveConnectItem?.contactID + '&r=' + this.encryptedUser;
        this._router.navigate([]).then(result => { window.open(link, '_blank'); });
    }
}
