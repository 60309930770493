import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, Inject, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FileRestrictions } from "@progress/kendo-angular-upload";
import { isNullOrUndefined } from 'util';
import { CLPUser, ClpUsersDdResponse, UserResponse } from '../../models/clpuser.model';
import { eFeatures, eUserRole } from '../../models/enum.model';
import { Folder, FolderListResponse } from '../../models/folder.model';
import { IntDropDownItem, SimpleResponse } from '../../models/genericResponse.model';
import { ImageDocument, SOImageDocument } from '../../models/imageDocument.model';
import { RoleFeaturePermissions } from '../../models/roleContainer.model';
import { AccountSetupService } from '../../services/accountSetup.service';
import { GlobalService } from '../../services/global.service';
import { ImageBankService } from '../../services/image-bank.service';
import { NotificationService } from '../../services/notification.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';

declare var $: any;

@Component({
  selector: 'app-image-bank',
  templateUrl: './image-bank.component.html',
  styleUrls: ['./image-bank.component.css']
})
export class ImageBankComponent {
  uploadSaveUrl: string;
  baseUrl: string;
  showSpinner: boolean = false;
  private encryptedUser: string = '';
  user: CLPUser;
  roleFeaturePermissions: RoleFeaturePermissions;
  @Input() isWebFormManager: boolean = true;

  userResponse: UserResponse;
  folderListResponse: FolderListResponse;
  folderList: Folder[];
  userList: IntDropDownItem[];
  documentList: ImageDocument[];
  imageDocumentList: ImageDocument[];
  public selectedDocument: ImageDocument;
  folderUpdate: Folder;
  soImageDocument = <SOImageDocument>{};
  selectedFolderId: number;
  selectedFolder: Folder;
  selectedImagePreview: string;
  uploadRestrictions: FileRestrictions = {
    allowedExtensions: [".jpg", ".png", ".eps", ".jpeg", ".gif"],
    maxFileSize: 5242880
  };
  imageBankForm: FormGroup;
  documentForm: FormGroup;
  public isEnableEdit: boolean = false;
  public isShowDocument: boolean = false;
  public isReplaceDocument: boolean = false;
  public isShowUpdate: boolean = false;
  public isEditOrder: boolean = false;
  public isdocTitleEdit: boolean = false;
  public isShowDocList: boolean = false;
  public editFolderId: number = -1;
  public editDocumentId: number = -1;
  positionDD: any = []
    fileUploadHeaders: HttpHeaders;

    pageSizeOptions = [
        { value: 5, text: 'Page Size 5' },
        { value: 10, text: 'Page Size 10' },
        { value: 20, text: 'Page Size 20' },
        { value: 'All', text: 'All' }
    ];
    pageSize: any = 5;
    currentPage = 1;
    totalPageSize: number = 1;
    blnIncludeShared: boolean = false;

  constructor(@Inject('BASE_URL') _baseUrl: string,
    private _notifyService: NotificationService,
    private fb: FormBuilder,
    private _imageBankService: ImageBankService,
      private _accountSetupService: AccountSetupService,
      private _globalService: GlobalService,
    public _localService: LocalService,
    private _utilityService: UtilityService,
    private _router: Router,) {
    this._localService.isMenu = true;
    this.baseUrl = _baseUrl;
  }

  apiResponse() {
    this._notifyService.showSuccess("Image uploaded successfully.", "", 3000);
    this.getDocumentList(this.selectedFolderId);
  }

  ngOnInit(): void {
    this.documentForm = this.prepareDocumentForm();

      this._globalService.getToken((token) => {
          if (token) {
              this.encryptedUser = token;
              this.authenticateR().then(() => {
                  if (this.user) {
                      this.getClpUserList();
                      this.fileUploadHeaders = new HttpHeaders({
                          'Authorization': 'Basic ' + this.encryptedUser
                      });
                  }
                  else
                      this._router.navigate(['/login']);
              });
          }
          else
              this._router.navigate(['/login']);
      });
  }


    private async authenticateR() {
        this.showSpinner = true;
        await this._localService.authenticateUser(this.encryptedUser, eFeatures.ImageBank)
            .then(async (result: UserResponse) => {
                if (result) {
                    this.userResponse = UtilityService.clone(result);
                    if (this.userResponse) {
                        if (!isNullOrUndefined(this.userResponse?.user)) {
                            this.user = this.userResponse.user;
                            this.roleFeaturePermissions = this.userResponse.roleFeaturePermissions;
                            this.getTextMsgConfig();
                            if ($(window).width() < 768)
                                if (this.user?.userRole <= eUserRole.Administrator) {
                                    if (this.roleFeaturePermissions?.view == false)
                                        this._router.navigate(['/unauthorized'], { state: { isMenu: true } });
                                }
                        }
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("iamge-bank.authenticateR", err.message, null, 'Features ' + eFeatures.None);
                this.showSpinner = false;
                this._utilityService.handleErrorResponse(err);
            });
    }

  prepareImageBankForm() {
    return this.fb.group({
      folderName: new FormControl('', [Validators.required]),
      isShared: new FormControl(false),
    });
  }

  prepareDocumentForm() {
    return this.fb.group({
      imageWidth: new FormControl(500),
      isForceResize: new FormControl(false),
      insertPosition: new FormControl(1)
    });
  }

  patchFormControlValue(dataItem) {
    const textMsgTemplateData = dataItem;
    for (let key in textMsgTemplateData) {
      let value = textMsgTemplateData[key];
      if (this.imageBankForm.get(key))
        this.imageBankForm.get(key).setValue(value);
    }
  }

  async getClpUserList() {
    await this._accountSetupService.getClpUserList(this.encryptedUser, this.user?.cLPCompanyID)
      .then(async (result: ClpUsersDdResponse) => {
        if (!isNullOrUndefined(result)) {
          this.userList = UtilityService.clone(result?.clpUsers);
          this.getImageBankFolderList();
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
      });
  }

  async getImageBankFolderList() {
    this.showSpinner = true;
      await this._imageBankService.getImageBankFolderList(this.encryptedUser, this.user?.cLPCompanyID, this.user?.cLPUserID, this.blnIncludeShared)
      .then(async (result: FolderListResponse) => {
        if (result) {
          this.folderListResponse = UtilityService.clone(result);
          this.folderList = this.folderListResponse.folder;
          this.folderList.forEach(x => {
            if (x?.cLPUserID != this.user.cLPUserID)
              x.userName = this.userList?.filter(item => item.id == x.cLPUserID)[0]?.text;
          })
          if (this.folderList.length <= 0)
            this.addDefaultFolder();
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async imageBankFolderDelete(dataItem) {
    this.showSpinner = true;
    await this._imageBankService.deleteImageBankFolder(this.encryptedUser, dataItem.folderID)
      .then(async (result: SimpleResponse) => {
        if (result) {
          var response = UtilityService.clone(result);
          this.isEnableEdit = false;
          this.getImageBankFolderList();
          this._notifyService.showSuccess("Folder deleted successfully", "", 3000);
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async updateImageBankFolder(dataItem) {
    this.showSpinner = true;
    await this._imageBankService.updateImageBankFolder(this.encryptedUser, dataItem)
      .then(async (result: SimpleResponse) => {
        if (result) {
          var response = UtilityService.clone(result);
          await this.getImageBankFolderList();
          this.isEnableEdit = false;
          if (dataItem?.folderID == 0 && dataItem?.folderName != 'Default')
            this.getDocumentList(this.folderList[Object.keys(this.folderList).length - 1]?.folderID);
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  addFolder() {
    this.folderUpdate = <Folder>{};
    this.folderUpdate.cLPCompanyID = this.user?.cLPCompanyID;
    this.folderUpdate.cLPUserID = this.user?.cLPUserID;
    this.folderUpdate.folderID = 0;
    this.folderUpdate.isShared = false;
    this.selectedFolder = this.folderUpdate;
    this.folderUpdate.folderName = 'New Folder';
    this.updateImageBankFolder(this.folderUpdate);
    this._notifyService.showSuccess("New folder created.", "", 3000);
  }

  setPositionDD() {
    this.positionDD = [{ value: 1, display: 'Top' }]
    let positionObj
    for (let i = 1; i < this.documentList.length; i++) {
      positionObj = {
        value: 1 + i,
        display: 'After' + ' ' + i
      }
      this.positionDD.push(positionObj)
    }
    positionObj = {
      value: this.documentList.length + 1,
      display: 'Bottom'
    }
    this.positionDD.push(positionObj)
  }

  addDefaultFolder() {
    this.folderUpdate = <Folder>{};
    this.folderUpdate.cLPCompanyID = this.user?.cLPCompanyID;
    this.folderUpdate.cLPUserID = this.user?.cLPUserID;
    this.folderUpdate.folderID = 0;
    this.folderUpdate.folderName = 'Default';
    this.folderUpdate.isShared = false;
    this.selectedFolder = this.folderUpdate;
    this.updateImageBankFolder(this.folderUpdate);
    this._notifyService.showSuccess("New folder created.", "", 3000);
  }

  async getDocumentList(folderID) {
    this.showSpinner = true;
    this.selectedFolderId = folderID;
    await this._imageBankService.getDocumentList(this.encryptedUser, this.selectedFolderId)
      .then(async (result: ImageDocument[]) => {
        if (result) {
          this.documentList = UtilityService.clone(result);
          this.setPositionDD()
          this.isShowDocument = true;
          this.isReplaceDocument = false;
          this.isShowUpdate = false;
          this.isShowDocList = true;
          if (isNullOrUndefined(this.documentList) || this.documentList?.length <= 0) {
            this.setDocument();
            this.isShowDocList = false;
          }
          if (this.documentList?.length > 0) {
            for (let i = 1; i <= this.documentList?.length; i++) {
              this.documentList[i - 1].sOrder = i;
            }
          }
            this.goToPage(1)
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async updateDocument(item) {
    this.showSpinner = true;
    await this._imageBankService.updateDocument(this.encryptedUser, item)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this.isdocTitleEdit = false;
          this.getDocumentList(item?.folderID);
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  async documentDelete(dataItem) {
    this.showSpinner = true;
    await this._imageBankService.deleteSOImageDocument(this.encryptedUser, dataItem?.documentId)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this.isdocTitleEdit = false;
          this.getDocumentList(dataItem?.folderID);
          response?.messageBool ? this._notifyService.showSuccess("Document deleted successfully", "", 3000) : this._notifyService.showError("Some error occured", "", 3000)
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

    async documentOrderUpdate(item) {
        const maxInt = 2147483647;
        if (item.filter(x => x.sOrder > maxInt).length > 0) {
            const orderNum = item.filter(x => x.sOrder > maxInt)[0].sOrder
            this._notifyService.showError('Invalid sOrder Number ' + orderNum,'Error!');
            return
        }
      this.showSpinner = true;
    await this._imageBankService.updateDocumentOrder(this.encryptedUser, item)
      .then(async (result: SimpleResponse) => {
        if (result) {
          const response = UtilityService.clone(result);
          this.isdocTitleEdit = false;
          this.isEditOrder = false;
          this.getDocumentList(this.selectedFolderId);
        }
        this.showSpinner = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.log(err);
        this._utilityService.handleErrorResponse(err);
        this.showSpinner = false;
      });
  }

  docTitleEdit(id) {
    this.isdocTitleEdit = true;
    this.editDocumentId = id;
    this.isEditOrder = false;
  }

    onCancelDocEdit(documentId: number) {
        this.getDocumentList(this.selectedFolderId)
        this.goToPage(this.currentPage);
    }

  setDocument() {
    this.isShowUpdate = true;
    this.uploadSaveUrl = this.baseUrl + 'api/ImageBank/File_Uploaded' + '?documentId=' + 0 + '&clpcompanyId=' + this.user?.cLPCompanyID + '&clpuserId=' + this.user?.cLPUserID + '&folderid=' + this.selectedFolderId + '&cbForceResize=' + this.documentForm.controls.isForceResize.value + '&imageWidth=' + this.documentForm.controls.imageWidth.value + '&SOrder=' + this.documentForm.controls.insertPosition.value;
    this.documentForm = this.prepareDocumentForm();
  }

  setOrder() {
    this.isEditOrder = true;
    this.isShowUpdate = false;
    this.isdocTitleEdit = false;
  }

  documentReplace(item) {
    this.isShowDocument = false;
    this.isReplaceDocument = true;
    this.selectedImagePreview = item?.imagePreview;
    this.uploadSaveUrl = this.baseUrl + 'api/ImageBank/File_Uploaded' + '?documentId=' + item?.documentId + '&clpcompanyId=' + this.user?.cLPCompanyID + '&clpuserId=' + this.user?.cLPUserID + '&folderId=' + this.selectedFolderId + '&cbForceResize=' + this.documentForm.controls.isForceResize.value + '&imageWidth=' + this.documentForm.controls.imageWidth.value + '&SOrder=' + this.documentForm.controls.insertPosition.value;
  }

  submitImageBankFolder(dataItem) {
    if (this.imageBankForm.valid) {
      this.folderUpdate = dataItem;
      this.folderUpdate.folderName = this.imageBankForm.controls.folderName.value;
      this.folderUpdate.isShared = this.imageBankForm.controls.isShared.value;
      this._notifyService.showSuccess("Folder saved.", "", 3000);
      this.updateImageBankFolder(this.folderUpdate);
    }
  }

  copyInputMessage(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  folderEdit(item, i) {
    this.editFolderId = i;
    this.isEnableEdit = true;
    this.isShowDocument = false;
    this.isReplaceDocument = false;
    this.isEditOrder = false;
    this.isShowUpdate = false;
    this.imageBankForm = this.prepareImageBankForm();
    this.patchFormControlValue(item);
  }

  getTextMsgConfig() {
    this.uploadSaveUrl = this.baseUrl + 'api/ImageBank/File_Uploaded' + '?NumUploaded=' + 1 + '&clpcompanyId=' + this.user.cLPCompanyID + '&clpuserId=' + this.user?.cLPUserID + '&folderid=' + this.selectedFolderId + '&cbForceResize=' + this.documentForm.controls.isForceResize.value + '&imageWidth=' + this.documentForm.controls.imageWidth.value + '&SOrder=' + this.documentForm.controls.insertPosition.value;
    }

    //Pagination

    get totalPages(): number {
        if (this.pageSize === 'All') {
            return 1;
        }
        return Math.ceil(this.documentList.length / this.pageSize);
    }

    changePageSize(value: any): void {
        this.pageSize = value;
        if (value === 'All') {
            this.totalPageSize = 1;
            this.imageDocumentList = this.documentList;
        } else {
            this.totalPageSize = Math.ceil(this.documentList.length / this.pageSize);
            this.currentPage = 1;
            this.updatePageContent();
        }
    }

    goToPage(page: number): void {
        this.currentPage = page;
        this.updatePageContent();
    }

    private updatePageContent(): void {
        if (this.pageSize === 'All') {
            this.imageDocumentList = this.documentList;
        } else {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = start + this.pageSize;
            this.imageDocumentList = this.documentList?.slice(start, end);
        }
    }

    get pageNumbers(): number[] {
        return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    }

    async myFolder() {
        this.blnIncludeShared = !this.blnIncludeShared;
        await this.getImageBankFolderList();
    }
}
