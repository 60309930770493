<div class="instruction-box-panel">
  <div class="wraper-main-section">
    <div class="global-card-section">
      <!--<div class="global-header-section">
        <div class="svg-icon-panel"><img src="../../../../assets/automationprocesstitle.svg" class="mr-1" /> Automation Process List</div>
        <div class="header-button-panel white-font">
          <div class="inner-container">
            <div class="button-wrapper">
            </div>
          </div>
        </div>
      </div>-->
      <div class="global-body-section campaign-event-grid" *ngIf="gridData?.data?.length>0">
          <kendo-grid #griddupl id="griddupl"
                    (selectionChange)="selectionChange(griddupl, $event)"
                    [data]="gridData"
                    [pageable]="true"
                    [skip]="state.skip"
                    [pageSize]="state.take"
                    [rowClass]="rowCallback"
                    [selectable]="{ enabled: true, mode: 'single' }"
                    (dataStateChange)="dataStateChange($event)">
          <kendo-grid-column *ngFor="let column of columns"
                             [field]="column.field"
                             [title]="column.title | titlecase"
                             [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                             [width]="column.width | stringToNumber">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <div class="customer-name" *ngIf="column.field == '$' && column.title == ''">{{ rowIndex+1 }}</div>
              <div class="customer-name" *ngIf="column.field == 'icon'"><img [src]="getSrcPath(dataItem?.campaignEventType)" /> </div>
              <div class="customer-name" *ngIf="column.field == 'campaignEventType'">{{ getTypeDisplay( dataItem[column.field]) }} </div>
              <div class="customer-name" *ngIf="column.field == 'campaignEventName'">{{ dataItem[column.field] }} </div>
              <div class="customer-name" *ngIf="column.field == 'timeSinceUnit'">{{ getStartTimeDisplay(dataItem[column.field],dataItem.timeSinceValue)  }} </div>
              <div class="customer-name" *ngIf="column.field == 'ownerCLPUserID'">{{ dataItem[column.field] == 0 ? 'Campaign Owner' : getUserDisplay(dataItem[column.field]) }} </div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
  </div>
</div>
<div class="alert-panel" *ngIf="!gridData?.data || gridData?.data?.length==0">
    <span>No events exist.</span>
</div>

