<div *ngIf="!isFromAcSetting" class="custom-security-link">
    <a [routerLink]="['/account-setup']">Account Setup</a>
    <a [routerLink]="['/teamoffice-setup']">Teams and Office Setup</a>
    <a [routerLink]="['/role-setup']" *ngIf="isNewUserList && user?.isPasswordAdmin">Role Setup</a>
    <a [routerLink]="['/admin-user-acct']" *ngIf="isPasswordAdmin">Password Administration</a>
</div>

<div class="mb-4" *ngIf="isNewUserList">
    <div class="margin-all-area">
        <div class="wraper-main-section">
            <div class="global-card-section">
                <div class="global-header-section">
                    <div class="svg-icon-panel">
                        <img src="../../../../assets/activity/config/userlisttitle.svg" class="mr-1" />New User Setup
                    </div>
                </div>
                <div class="global-body-section">
                    <kendo-grid #grid id="gridIdNew" [kendoGridBinding]="newUsersList" class="new-user-list-grid"
                                (edit)="editHandlerNewUser($event)"
                                (cancel)="cancelHandlerNewUser($event)"
                                (save)="saveHandlerNewUser($event)">

                        <kendo-grid-column *ngFor="let column of newUserColumns"
                                           [field]="column.field"
                                           [title]="column.title | titlecase"
                                           [width]="column?.width | stringToNumber"
                                           [filterable]="true"
                                           [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                           [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                           [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                           [editable]="column.field == '$' || column.field == 'status'?false: true"
                                           [includeInChooser]="column.field=='$' ? false : true">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="customer-name" *ngIf="column.title == ' '">{{ rowIndex+1 }}</div>
                                <div class="customer-name" *ngIf="column.field == 'userIcon'">
                                    <img src="../../../../assets/icon_user.svg" />
                                </div>
                                <div *ngIf="column.field == 'email'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] }} </div>
                                <div *ngIf="column.field == 'emailConfirm'">{{ !dataItem['email'] ? '--' : dataItem['email'] }} </div>
                                <div *ngIf="column.field == 'userName'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] }} </div>
                                <div *ngIf="column.field == 'firstName'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] }} </div>
                                <div *ngIf="column.field == 'lastName'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] }} </div>
                                <div *ngIf="column.field == 'status'" [style.color]="dataItem.status == 1 ? '#008000':'#FF0000'">{{(dataItem[column.field] == eUserPermissions.Active ? 'Setup Successful' : 'Not Setup') }} </div>

                            </ng-template>
                            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="newUserFormGroup" let-column="column">
                                <form [formGroup]="newUserFormGroup">
                                    <div class="customer-name" *ngIf="column.field=='email'">
                                        <input placeholder="" type="email" formControlName="email" pattern="^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$" email="true" name="column.title" [ngClass]="{'red-border-class': nf.email.errors}" required />
                                    </div>
                                    <div class="customer-name" *ngIf="column.field=='emailConfirm'">
                                        <input placeholder="" type="email" formControlName="emailConfirm" pattern="^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$" email="true" name="column.title" [ngClass]="{'red-border-class': nf.emailConfirm.errors}" required />
                                    </div>
                                    <div class="customer-name" *ngIf="column.field=='userName'">
                                        <input placeholder="" type="email" formControlName="userName" (blur)="checkDuplicate('');" email="true" pattern="^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$" name="column.title" [ngClass]="{'red-border-class': nf.userName.errors}" required />
                                    </div>
                                    <div class="customer-name" *ngIf="column.field=='lastName'">
                                        <input placeholder="" formControlName="lastName" name="column.title" [ngClass]="{'red-border-class': nf.lastName.errors}" required />
                                    </div>
                                    <div class="customer-name" *ngIf="column.field=='firstName'">
                                        <input placeholder="" formControlName="firstName" name="column.title" [ngClass]="{'red-border-class': nf.firstName.errors}" required />
                                    </div>
                                </form>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-command-column [width]="100" title="Action" [includeInChooser]="false" [reorderable]="false" [columnMenu]="false">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div class="flex-align-panel">
                                    <button kendoGridEditCommand title="Edit">
                                        <kendo-icon name="edit"></kendo-icon>
                                    </button>
                                    <button kendoGridSaveCommand [primary]="true" title="Update">
                                        <kendo-icon name="check"></kendo-icon>
                                    </button>
                                    <button kendoGridCancelCommand [primary]="true" title="Cancel">
                                        <kendo-icon name="close"></kendo-icon>
                                    </button>
                                    <div *ngIf="dataItem.status == 1" class="margin-left10">
                                        <a class="contact-cursor-pointer" (click)="sendWelcomeEmail(dataItem?.cLPUserID);" title="Email" *ngIf="dataItem?.email">
                                            <i class="email_new"><img src="../../../assets/email_new.svg" /></i>
                                        </a>
                                    </div>
                                </div>
                            </ng-template>
                        </kendo-grid-command-column>
                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../assets/activity/config/userlisttitle.svg" class="mr-1" /> User List</div>
                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <button class="btn btn-primary" type="button" (click)="resetGridSetting(); inputUserSearch.value = ''">Reset Grid Setting</button>
                        <input class="" placeholder="Search..." kendoTextBox (input)="onFilter($event.target.value) " #inputUserSearch />
                        <select *ngIf="user && this.user.userRole >= 3 && this.billingClpCompany?.showOfficeDD" [(ngModel)]="defaultItemOfficeCodeTop" (change)="topDDChange($event)" class="form-control select-option">
                            <option value="-1"> -All Offices- </option>
                            <option *ngFor="let dtList of officeCodeFilterList; let i = index" [ngValue]="dtList.key">{{dtList.value }}</option>
                        </select>
                        <select *ngIf="user && this.billingClpCompany?.showTeamDD" [(ngModel)]="defaultItemTeamCodeTop" (change)="topDDChange($event)" class="form-control userSetupSelect select-option">
                            <option value="-1"> -All Teams- </option>
                            <option *ngFor="let dtList of teamCodeFilterList; let i = index" [ngValue]="dtList.key">{{dtList.value }}</option>
                        </select>
                        <select *ngIf="user " [(ngModel)]="defaultRole" (change)="topDDChange($event)" class="form-control userSetupSelect select-option">
                            <option value="-1"> -All Roles- </option>
                            <option *ngFor="let type of userTypeFilterList; let i = index" [ngValue]="type.key">{{type.value }}</option>
                        </select>
                        <!--<button type="button" [hidden]="roleFeaturePermissions?.create == false && user?.userRole <= eUserRole.Administrator" matTooltip="Add" class="icon-btn" (click)="addNewUser()">
                          <i class="fa fa-plus" aria-hidden="true"> </i>
                          <span class="btn-text">Add</span>
                        </button>-->
                        <button type="button" matTooltip="Download" class="icon-btn" (click)="saveExcelUser()">
                            <img src="../../../../assets/downbtn.svg" />
                            <span class="btn-text">Download</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="global-body-section">
                <kendo-grid class="create-user-grid" #grid id="gridId2"
                            [kendoGridBinding]="userDataOriginal"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [sortable]="{mode: 'multiple'}"
                            [scrollable]="'scrollable'"
                            [sort]="_gridCnfgService.sort"
                            [columnMenu]="{ filter: true }"
                            [resizable]="true"
                            [reorderable]="true"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('user_setup_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('user_setup_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('user_setup_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(16,'user_setup_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'user_setup_grid',grid)"
                            (edit)="editHandler($event)"
                            (cancel)="cancelHandler($event)"
                            (save)="saveHandler($event)"
                            (remove)="removeHandler($event)"
                            (add)="addHandler($event)">
                    <ng-template kendoGridToolbarTemplate kendoGridFilterCellTemplate let-filter>
                        <button kendoGridAddCommand id="btnForNewUser" [hidden]="true"><i class="fa fa-plus"></i>Add new</button>
                        <button #saveExcelUSBtn [hidden]="true" type="button" class="k-button" title="Export contacts in excel" (click)="saveExcel(excelRecentExport)"><i class="fas fa-file-excel"></i> Export to Excel </button>
                    </ng-template>
                    <!--savi-->
                    <!--savi-->

                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title | titlecase"
                                       [width]="column?.width | stringToNumber"
                                       [editable]="column.field == 'cLPUserID'?false: true"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [hidden]="(_gridCnfgService.hiddenColumns.indexOf(column.field) > -1) || (column.field == '$$' && user?.userRole != 3 && user?.userRole!= 4) || (column.field == 'password' && user?.slurpyUserId == 0)"
                                       [includeInChooser]="column.field.includes('$') ? false : true">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.field == '$' && column.title == ' ' ">
                                {{ rowIndex+1 }}
                            </div>
                            <div class="customer-name" *ngIf="column.field == '$$' && column.title == ' ' && user?.userRole!= 3 && user?.userRole!= 4 && user?.slurpyUserId == 0">
                                <a (click)="onUserSelect(dataItem?.cLPUserID);"><i class="fa fa-search"></i></a>
                            </div>
                            <div class="customer-name" *ngIf="checkColumn(column)">
                                <img src="../../../../assets/icon_user.svg" />
                            </div>
                            <!--savi-->

                            <div class="customer-name" *ngIf="showColumns(column) "> {{dataItem[column.field] }}</div>
                            <div class="customer-name" *ngIf="column.field == 'firstName'">
                                <span class="label-show-grid">First Name</span>
                                {{ dataItem[column.field] }}
                            </div>
                            <div class="customer-name" *ngIf="column.field == 'lastName'">
                                <span class="label-show-grid">Last Name</span>
                                {{ dataItem[column.field] }}
                            </div>
                            <div class="customer-name" *ngIf="column.field == 'teamCode'"><span class="label-show-grid">Team</span>{{convertTeamCode(dataItem[column.field]) ? convertTeamCode(dataItem[column.field]) : '--'}}</div>
                            <div class="customer-name" *ngIf="column.field == 'officeCode'"><span class="label-show-grid">Office</span>{{convertOfficeCode(dataItem[column.field]) ? convertOfficeCode(dataItem[column.field]) : '--'}}</div>
                            <div class="customer-name" *ngIf="column.field == 'changePW'"><span class="label-show-grid">Change Pw</span>{{dataItem[column.field] =='1' ? 'true' : 'false'}}</div>
                            <div class="customer-name" *ngIf="column.field == 'cLPUserID' && dataItem?.cLPUserID != ''">{{"VR9" + dataItem[column.field] }}</div>
                            <div class="customer-name" *ngIf="column.field == 'password' && dataItem?.password != ''"><span class="label-show-grid">Password</span> {{dataItem[column.field] }}</div>
                            <div class="customer-name" *ngIf="column.field == 'timeZone'"><span class="label-show-grid">Time Zone</span> {{ convertTimeZone(dataItem['timeZoneWinId']) ? convertTimeZone(dataItem['timeZoneWinId']) : '--' }}</div>
                            <div class="customer-name" *ngIf="column.field == 'userRole'"><span class="label-show-grid">Type</span> {{ convertUserType(dataItem[column.field]) ? convertUserType(dataItem[column.field]) : '--' }}</div>
                            <div class="customer-name" *ngIf="column.field == 'permissions'"> {{ convertUserStatus(dataItem[column.field]) }}</div>
                            <div class="customer-name" *ngIf="column.field == 'welcome'"><a class="contact-cursor-pointer" (click)="sendWelcomeEmail(dataItem?.cLPUserID);" title="Email" *ngIf="dataItem?.email"><i class="email_new"><img src="../../../assets/email_new.svg" /></i></a></div>
                        </ng-template>

                        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup" let-column="column">
                            <form [formGroup]="formGroup">
                                <div class="customer-name" *ngIf="column.field=='timeZone'">
                                    <select formControlName="timeZone" class="form-control"
                                            [ngClass]="{'red-border-class': f.timeZone.errors}">
                                        <option value=""> -None Selected- </option>
                                        <option *ngFor="let dtList of timezoneFilterList; let i = index" [ngValue]="dtList.id">{{dtList.standardName }}</option>
                                    </select>
                                </div>
                                <div class="customer-name" *ngIf="column.field=='officeCode'">
                                    <select formControlName="officeCode" class="form-control">
                                        <option value="0"> -None Selected- </option>
                                        <option *ngFor="let list of officeCodeFilterList; let i = index" [ngValue]="list.key">{{list.value }}</option>
                                    </select>
                                </div>
                                <div class="customer-name" *ngIf="column.field=='teamCode'">
                                    <select formControlName="teamCode" class="form-control">
                                        <option value="0"> -None Selected- </option>
                                        <option *ngFor="let list of teamCodeFilterList; let i = index" [ngValue]="list.key">{{list.value }}</option>
                                    </select>
                                </div>
                                <div class="customer-name" *ngIf="column.field=='userRole'">

                                    <select formControlName="userRole" class="form-control">
                                        <!--<option value="-1"> -None Selected- </option>-->
                                        <option *ngFor="let uList of userTypeFilterList; let i = index" [ngValue]="uList.key">{{uList.value }}</option>
                                    </select>
                                </div>
                                <div class="customer-name" *ngIf="column.field=='permissions'">

                                    <select formControlName="permissions" class="form-control">
                                        <!--<option value="0"> -None Selected- </option>-->
                                        <option *ngFor="let uList of userStatusFilterList; let i = index" [ngValue]="uList.key">{{uList.value }}</option>
                                    </select>
                                </div>
                                <div class="custom-toggle" *ngIf="column.field=='changePW'">
                                    <label class="switch">
                                        <input type="checkbox" formControlName="changePW">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <div class="custom-toggle" *ngIf="column.field=='isShowCP'">
                                    <label class="switch">
                                        <input type="checkbox" formControlName="isShowCP">
                                        <span class="slider round"></span>
                                    </label>
                                </div>

                                <div class="custom-toggle" *ngIf="column.field=='isAllowDownload'">
                                    <label class="switch">
                                        <input type="checkbox" formControlName="isAllowDownload">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <div class="customer-name" *ngIf="column.field=='lastName'">
                                    <input formControlName="lastName" placeholder="Last Name"
                                           name="column.title" [ngClass]="{'red-border-class': f.lastName.errors}" />
                                </div>
                                <div class="customer-name" *ngIf="column.field=='firstName'">
                                    <input formControlName="firstName" placeholder="First Name"
                                           name="column.title" [ngClass]="{'red-border-class': f.firstName.errors}" />
                                </div>
                                <div class="customer-name" *ngIf="column.field=='userName'">
                                    <input placeholder="User Name" (blur)="checkuserNameDuplicate()" formControlName="userName"
                                           name="column.title" />
                                </div>
                                <div class="customer-name" *ngIf="column.field=='email'">
                                    <input formControlName="email" placeholder="Email"
                                           name="column.title" />
                                </div>
                                <div class="customer-name" *ngIf="column.field=='mobile'">
                                    <input placeholder="Mobile" formControlName="mobile"
                                           name="column.title" />
                                </div>


                            </form>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-excelexport #excelRecentExport [data]="userDataExcel" [fileName]=downloadFileName>
                        <kendo-excelexport-column *ngFor="let col of columnsExcel" [field]="col.field" [title]="col.title" [width]="col.width"></kendo-excelexport-column>
                    </kendo-excelexport>
                    <kendo-grid-command-column title="Action" [width]="110" [hidden]="roleFeaturePermissions?.edit == false && roleFeaturePermissions?.delete == false && user?.userRole <= eUserRole.Administrator">
                        <ng-template kendoGridCellTemplate let-isNew="isNew">
                            <button [hidden]="roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator" kendoGridEditCommand [primary]="true" title="Edit">
                                <kendo-icon name="edit"></kendo-icon>
                            </button>
                            <button kendoGridRemoveCommand [primary]="true" [hidden]="roleFeaturePermissions?.delete == false && user?.userRole <= eUserRole.Administrator" data-toggle="modal" data-target="#exampleModal" title="Remove">
                                <kendo-icon name="delete"></kendo-icon>
                            </button>

                            <button kendoGridSaveCommand [primary]="true" *ngIf="isNew" title="Add">
                                <!--{{ isNew ? "Add" : "Update" }}-->
                                <kendo-icon name="check"></kendo-icon>
                            </button>

                            <button kendoGridSaveCommand [primary]="true" *ngIf="!isNew" title="Update">
                                <!--{{ isNew ? "Add" : "Update" }}-->
                                <kendo-icon name="check"></kendo-icon>
                            </button>

                            <button kendoGridCancelCommand [primary]="true">
                                <kendo-icon name="close"></kendo-icon>
                                {{ isNew ? "" : "" }}
                            </button>
                        </ng-template>
                    </kendo-grid-command-column>
                </kendo-grid>
            </div>
            <div>
                <app-user-profile *ngIf="isUserSelected && selectedUserID > 0" [userIDAcSeting]="selectedUserID" [isFromAcSetting]="true"></app-user-profile>
            </div>
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog  modal-common-dialog" role="document">
                    <div class="modal-content modal-common-content">
                        <div class="modal-header modal-common-background">
                            <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
                        </div>
                        <div class="modal-body modal-common-body">
                            <h5>Are you sure to delete - <span style="font-weight:600;">{{currentUserUserName}}</span>? </h5>
                        </div>
                        <div class="modal-footer">
                            <button type="button" (click)="deleteUser()" data-dismiss="modal" class="btn btn-primary">Confirm</button>
                            <button type="button" class="btn btn-cancel" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
