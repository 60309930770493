<div class="margin-all-area">
    <div class="wraper-main-section">
        <div class="global-card-section">
            <div class="global-header-section">
                <div class="svg-icon-panel"><img src="../../../../../assets/bulkcontactstitle.svg" class="mr-1" /> Bulk Contact Actions</div>
                <div class="header-button-panel">
                    <div class="button-wrapper">
                        <div class="custom-search">
                            <div class="display-row">
                                <label class="info-text" *ngIf="!selectAllContacts">Contacts selected: {{mySelection.length}}&nbsp;</label>
                                <label class="info-text" *ngIf="selectAllContacts">Contacts selected: {{contactsArchiveData.length}} &nbsp;</label>
                                <button class="btn btn-primary" *ngIf="!isSelectAction" [disabled]="mySelection.length<=0 ||contactsArchiveData.length<=0" type="button" (click)="selectAction();">Select Checked</button>
                                <button class="btn btn-primary" *ngIf="!isSelectAction" [disabled]="contactsArchiveData.length<=0" type="button" (click)="selectAction();  selectAllContacts=true;">Select All</button>
                                <button class="btn btn-primary" *ngIf="!isSelectAction" type="button" (click)="resetGridSetting()">Reset Grid Setting</button>
                                <input class="" *ngIf="!isSelectAction" placeholder="Search in all columns..." kendoTextBox (input)="onContactArchiveListFilter($event.target.value)" #inputContactSearch />
                                <contact-common-search [sendMailInfo]="sendMailInfo"></contact-common-search>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="header-button-panel" *ngIf="contactsArchiveData.length!=0">
                    <div class="button-wrapper">
                        <button class="icon-btn" type="button" matTooltip="Start Over" (click)="cancelBulkAction()"><i class="fa fa-refresh"></i><span class="btn-text">Start Over</span></button>
                    </div>
                </div>
            </div>
            <div class="global-body-section" *ngIf="!isSelectAction">
                <div class="vertical-center">
                    <div class="admin-row-flex" *ngIf="contactsArchiveData.length==0">
                        <img src="../../../../assets/trans1x1.gif" class="steps1_4" border="0">
                        <div class="admin-row-colunm">
                            <h4 class="font-weight-bold">Search Contacts</h4>
                        </div>
                    </div>
                    <div class="admin-row-flex" *ngIf="contactsArchiveData.length!=0">
                        <img src="../../../../assets/trans1x1.gif" class="steps2_4" border="0">
                        <div class="admin-row-colunm">
                            <h4 class="font-weight-bold">Search Contacts</h4>
                        </div>
                    </div>
                </div>
                <div class="alert-panel" *ngIf="contactsArchiveData.length==0">Please use the Quick Search panel on the right to search for contacts.</div>
                <div class="alert-panel" *ngIf="contactsArchiveData.length!=0">Please select the contacts that you would like to edit in bulk.</div>
                <kendo-grid #grid id="gridId" class="bulk-contact-grid" *ngIf="_gridCnfgService?.reloadGridGeneric"
                            [kendoGridBinding]="contactsArchiveData"
                            kendoGridSelectBy="contactID"
                            [selectable]="{ checkboxOnly: true ,mode: 'multiple' }"
                            [(selectedKeys)]="mySelection"
                            [pageSize]="_gridCnfgService.pageSize"
                            [pageable]="{buttonCount:10,pageSizes:[10,50,100,200]}"
                            [sortable]="{mode: 'multiple'}"
                            [scrollable]="'scrollable'"
                            [sort]="_gridCnfgService.sort"
                            [columnMenu]="{ filter: true }"
                            [resizable]="true"
                            [reorderable]="true"
                            (columnReorder)="_gridCnfgService.columnsOrderChanged('contact_bulk_action_grid', $event)"
                            (sortChange)="_gridCnfgService.sortChange('contact_bulk_action_grid', $event)"
                            (pageChange)="_gridCnfgService.pageChange('contact_bulk_action_grid', $event)"
                            (columnResize)="_gridCnfgService.columnResize(13,'contact_bulk_action_grid', $event)"
                            (columnVisibilityChange)="_gridCnfgService.onVisibilityChange($event,'contact_bulk_action_grid',grid)">

                    <kendo-grid-column *ngFor="let column of _gridCnfgService.columns"
                                       [field]="column.field"
                                       [title]="column.title | titlecase"
                                       [width]="(column.field == '$' ? '30' : column.width) | stringToNumber"
                                       [filterable]="true"
                                       [headerStyle]="{'background-color': '#333','color': '#fff','line-height': '1.5em'}"
                                       [headerClass]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-header' : ''"
                                       [class]="mobileColumnNames?.includes(column.field) ? 'mobile-grid-colunm' : ''"
                                       [hidden]="_gridCnfgService.hiddenColumns.indexOf(column.field) > -1"
                                       [includeInChooser]="column.field=='$' ? false : true"
                                       [resizable]="column.field=='$' ? false : true">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <div class="customer-name" *ngIf="column.title == ' '">{{ rowIndex+1 }}</div>
                            <div class="customer-name" *ngIf="column.title == '  '"><a class="contact-cursor-pointer" (click)="gotoLink('address-card', dataItem);" title="View Contact"><i class="icon_contact"><img src="../../../assets/icon_contact.svg" /></i></a></div>
                            <div class="customer-name" *ngIf="column.field == 'email'"><a class="contact-cursor-pointer" (click)="gotoLink('email', dataItem);" title="send email to {{dataItem.email}}" *ngIf="dataItem.email"><i class="email_new"><img src="../../../assets/email_new.svg" /></i></a></div>
                            <div class="customer-name" *ngIf="column.field == 'phone'"><span [innerHTML]="dataItem[column.field] ? dataItem[column.field] : '--'"></span></div>
                            <div class="customer-name" [ngClass]="[column.field=='name' ? 'text-left' : '',  column.field=='companyName' ? 'text-left' : '']" *ngIf="showColumn(column.field)" [hidden]="column.field=='$'">
                                <a class="webkit-any-link" (click)="gotoLink(column.field, dataItem);" [style.color]="column.field == 'userName'? '#1c0dbf':''" *ngIf="column.field == 'name' || column.field == 'userName' || column.field == 'companyName'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</a>
                                <span [hidden]="column.field == 'name' || column.field == 'userName' || column.field == 'companyName' || column.field == 'dtModifiedDisplay' || column.field == 'dtCreatedDisplay'">{{dataItem[column.field] ? dataItem[column.field] : '--'}}</span>
                                <span *ngIf="column.field == 'dtModifiedDisplay' || column.field == 'dtCreatedDisplay'">{{ !dataItem[column.field] ? '--' : dataItem[column.field] | date: dateFormat }}</span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-checkbox-column [width]="45"
                                                [headerClass]="{ 'text-center': true }"
                                                [class]="{ 'text-center': true }"
                                                [resizable]="false"
                                                [columnMenu]="false"
                                                [showSelectAll]="true"></kendo-grid-checkbox-column>
                </kendo-grid>
            </div>

            <div class="wraper-main-section" *ngIf="isSelectAction">
                <div class="global-card-section">
                    <div class="wraper-body-panel" *ngIf="isSelectActionButton">
                        <div class="wraper-body-left">
                            <div class="vertical-center">
                                <div class="admin-row-flex">
                                    <img src="../../../../assets/trans1x1.gif" class="steps3_4" border="0">
                                    <div class="admin-row-colunm">
                                        <h4 class="font-weight-bold">Select Action</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="alert-panel">Please select the type of bulk action that you would like to perform on the selected contacts.</div>
                            <div class="bottom-button-bar">
                                <div class="button-bar-align">
                                    <button class="btn btn-primary" [hidden]="roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator" (click)="editField();">Edit A Field</button>
                                    <button class="btn btn-primary" [hidden]="roleFeaturePermissions?.edit == false && user?.userRole <= eUserRole.Administrator" (click)="transferOwnerField();">Transfer Ownership</button>
                                    <button class="btn btn-outline-danger" [hidden]="roleFeaturePermissions?.delete == false && user?.userRole <= eUserRole.Administrator" (click)="isDeleteContact = true; isSelectActionButton = false" data-toggle="modal" data-target="#bulkContactDeleteModal">Permanently Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wraper-body-panel" *ngIf="isEditField">
                        <div class="wraper-body-left">
                            <!--<div class="alert-panel" *ngIf="!isConfirm && !isEditType">Please select the field you would like to edit.</div>
                            <div class="alert-panel" *ngIf="isEditType">Please select the type of edit you would like to perform on this field.</div>
                            <div class="alert-panel" *ngIf="isConfirm">Please confirm the field and value you would like to edit for all checked contacts.</div>-->
                            <div class="alert-panel">{{EditTypeMessage}}</div>
                            <form [formGroup]="bulkEditFieldForm" (ngSubmit)="updateContacts()">
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <span>Select Field</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <select class="form-control" formControlName="ddField" *ngIf="bulkEditFieldForm.controls.ddField.value == ''" (change)="onChangeField(bulkEditFieldForm.controls.ddField.value);">
                                            <option *ngFor="let item of fieldDropdown;">{{item}}</option>
                                        </select>
                                        <span *ngIf="bulkEditFieldForm.controls.ddField.value != ''">{{bulkEditFieldForm.controls.ddField.value}}</span>
                                    </div>
                                </div>
                                <div class="cards-body-section" *ngIf="bulkEditFieldForm.controls.ddField.value != ''">
                                    <div class="cards-colunm-left">
                                        <span>Edit Type</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <select class="form-control" formControlName="editType" (change)="onChangeEditType($event.target.value)" *ngIf="bulkEditFieldForm.controls.editType.value == -1">
                                            <option value="-1">-Select One-</option>
                                            <option value="0" [hidden]="isTagSelected">Replace</option>
                                            <option value="1" [hidden]="isMoreFieldSelected">Add to</option>
                                            <option value="2" [hidden]="isTagSelected">Clear</option>
                                            <option value="3" [hidden]="!isTagSelected">Remove</option>
                                        </select>
                                        <span *ngIf="bulkEditFieldForm.controls.editType.value == 0">Replace</span>
                                        <span *ngIf="bulkEditFieldForm.controls.editType.value == 1">Add to</span>
                                        <span *ngIf="bulkEditFieldForm.controls.editType.value == 2">Clear</span>
                                        <span *ngIf="bulkEditFieldForm.controls.editType.value == 3">Remove</span>
                                    </div>
                                </div>
                                <div class="cards-body-section" *ngIf="bulkEditFieldForm.controls.editType.value == 0 || bulkEditFieldForm.controls.editType.value == 1">
                                    <div class="cards-colunm-left">
                                        <span>Value</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <input class="form-control" type="text" formControlName="textValue" *ngIf="!isConfirm && bulkEditFieldForm.controls.ddField.value != 'Tag'" [ngClass]="{'has-error': bulkEditFieldForm.controls.textValue.errors && (bulkEditFieldForm.controls.textValue.touched || bulkEditFieldForm.controls.textValue.dirty)}" />
                                        <select class="form-control" formControlName="textValue" *ngIf="bulkEditFieldForm.controls.ddField.value == 'Tag'" (change)="onChangeField(bulkEditFieldForm.controls.ddField.value);">
                                            <option *ngFor="let item of tagListByCompany;" [value]="item?.tagID">{{item?.tagDisplay}}</option>
                                        </select>
                                        <div *ngIf="bulkEditFieldForm.controls.textValue.errors && (bulkEditFieldForm.controls.textValue.touched || bulkEditFieldForm.controls.textValue.dirty)">
                                            <div class="login-error" *ngIf="bulkEditFieldForm.controls.textValue.errors.required">value is required </div>
                                        </div>
                                        <span *ngIf="isConfirm">{{bulkEditFieldForm.controls.textValue.value}}</span>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"></div>
                                    <div class="cards-colunm-right">
                                        <div class="bottom-button-bar">
                                            <button class="btn btn-primary" *ngIf="!isConfirm && (roleFeaturePermissions?.create || roleFeaturePermissions?.edit)" type="button" (click)="isConfirm = true;EditTypeMessage = 'Please confirm the field and value you would like to edit for all checked contacts.'">
                                                Save
                                            </button>
                                            <button class="btn btn-primary" *ngIf="isConfirm && (roleFeaturePermissions?.create || roleFeaturePermissions?.edit)" type="submit">
                                                Confirm
                                            </button>
                                            <button class="btn btn-cancel" type="button" (click)="cancelBulkAction();"><i class="fa fa-times"></i>Cancel {{isConfirm}}{{roleFeaturePermissions?.create}}{{roleFeaturePermissions?.edit}}</button>
                                        </div>
                                    </div>
                                </div>
                                <!--<div class="cards-body-section" *ngIf="bulkEditFieldForm.controls.editType.value == 2">
                                    <div class="cards-colunm-left"></div>
                                    <div class="cards-colunm-right">
                                        <div class="bottom-button-bar">-->
                                <!--<button class="btn btn-primary" *ngIf="!isConfirm && ((roleFeaturePermissions?.create || roleFeaturePermissions?.edit) && user?.userRole <= eUserRole.Administrator)" type="button" (click)="isConfirm = true,isEditType =false">
                                  Save
                                </button>-->
                                <!--<button class="btn btn-primary" *ngIf="!isConfirm && (roleFeaturePermissions?.create || roleFeaturePermissions?.edit)" type="submit" (click)="isEditType = false">
                                    Confirm-->
                                <!--<ng-template [ngIf]="buttonTypeOperation==1">Saving  <span> &nbsp;<i wrapper> </i></span></ng-template>-->
                                <!--</button>

                                            <button class="btn btn-cancel" type="button" (click)="cancelBulkAction();"><i class="fa fa-times"></i>Cancel</button>
                                        </div>
                                    </div>
                                </div>-->
                            </form>
                        </div>
                    </div>
                    <div class="wraper-body-panel" *ngIf="transferOwner">
                        <div class="wraper-body-left">
                            <div class="admin-row-flex">
                                <img border="0" class="steps3_4" src="../../../../assets/trans1x1.gif">
                                <div class="admin-row-colunm">
                                    <h4 class="font-weight-bold">
                                        Select Action
                                    </h4>
                                </div>
                            </div>
                            <div class="alert-panel" *ngIf="!saveConfirmation">Please select the new owner of all checked contacts.</div>
                            <div class="alert-panel" *ngIf="saveConfirmation">You are about to transfer ownership of all checked contacts to {{getNewOwnerName(bulkTransferFieldForm.controls.ddValue.value)}} Please confirm.</div>
                            <form [formGroup]="bulkTransferFieldForm" (ngSubmit)="transferContacts()">
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <span>New Owner</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <select class="form-control" formControlName="ddValue" *ngIf="!saveConfirmation">
                                            <option value="0" hidden>Does not Exist</option>
                                            <option *ngFor="let item of clickUser" [value]="item.id">{{item.text}}</option>
                                        </select>

                                        <span *ngIf="saveConfirmation">{{getNewOwnerName(bulkTransferFieldForm.controls.ddValue.value)}}</span>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <span>Transfer all SFA related data</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <input type="checkbox" class="checkbox" formControlName="trTransferSFA" />
                                        <p>Note: This will include all pending campaign appointments, emails, notes, tasks, triggers, and text messages.</p>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left">
                                        <span>Transfer all contact related data</span>
                                    </div>
                                    <div class="cards-colunm-right">
                                        <input type="checkbox" class="checkbox" formControlName="cbTransferSFA" />
                                        <p>Note: This will include appointments, notes, tasks, leads, etc...that are related to these contacts.</p>
                                    </div>
                                </div>
                                <div class="cards-body-section">
                                    <div class="cards-colunm-left"></div>
                                    <div class="cards-colunm-right">
                                        <div class="button-bar-align">
                                            <button class="btn btn-primary" *ngIf="!saveConfirmation" (click)="saveConfirmation = true" [hidden]="(roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false) && user?.userRole <= eUserRole.Administrator">
                                                Save
                                            </button>
                                            <button class="btn btn-primary" *ngIf="saveConfirmation" type="submit" [hidden]="(roleFeaturePermissions?.create == false || roleFeaturePermissions?.edit == false) && user?.userRole <= eUserRole.Administrator">
                                                Confirm
                                            </button>
                                            <button class="btn btn-cancel" type="button" (click)="cancelBulkAction();"><i class="fa fa-times"></i>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!--<div class="wraper-body-panel" *ngIf="isDeleteContact">
                      <div class="wraper-body-left">
                        <div class="alert-panel">
                          <span>Caution: You are about to permanently delete all checked contacts along with all related items such as appointments, tasks, leads, etc... Please confirm.</span>
                        </div>
                        <div class="bottom-button-bar">
                          <div class="button-bar-align">
                            <button class="btn btn-danger " [style.pointer-events]="showSpinner?'none':''" [disabled]="showSpinner" [hidden]="roleFeaturePermissions?.delete == false" (click)="deleteContacts(); isDeleteContact = false;">
                              <ng-template [ngIf]="buttonTypeOperation!=0">Permanently Delete</ng-template>
                              <ng-template [ngIf]="buttonTypeOperation===0">Deleting  <span> &nbsp;<i wrapper> </i></span></ng-template>
                            </button>
                            <button class="btn btn-cancel" type="button" (click)="isDeleteContact = false; isSelectAction = false"><i class="fa fa-times"></i>Cancel</button>
                          </div>
                        </div>
                      </div>
                    </div>-->
                    <div class="global-padding10">
                        <div class="company-2d-alert">
                            <span *ngIf="!selectAllContacts">Total records selected: {{mySelection.length}}</span>
                            <span *ngIf="selectAllContacts">Total records selected: {{contactsArchiveData.length}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="bulkContactDeleteModal" tabindex="-1" role="dialog" aria-labelledby="bulkContactDeleteModalLabel" aria-hidden="true" *ngIf="isDeleteContact">
        <div class="modal-dialog  modal-common-dialog" role="document">
            <div class="modal-content modal-common-content">
                <div class="modal-header modal-common-background">
                    <h5 class="modal-title modal-common-title">Delete Confirmation</h5>
                </div>
                <div class="modal-body modal-common-body">
                    <h5>Caution: The contacts you selected will be permanently deleted.</h5>
                    <h5>Are you sure you want to delete these contacts?</h5>
                </div>
                <div class="modal-footer">
                    <button type="button" (click)="deleteContacts(); isDeleteContact = false;" data-dismiss="modal" class="btn btn-primary">Confirm</button>
                    <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="isDeleteContact = false; isSelectAction = false"> Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="loader-body" *ngIf="showSpinner || _gridCnfgService.showSpinner">
    <div class="lds-ripple"><div></div><div></div></div>
</div>
